import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Table, Spin, Icon } from 'antd';

import OBModal from '../../common/Modal';
import Input from '../../common/Input';
import Stage from './stage';

import { CreateLeaderboardStage } from '../../../libs/reducers/actions';
import AddIcon from '../../../images/CirclePlus.png';


function stageStandings(props) {
  const {
    intl,
    tournament,
    permissions,
  } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const [participants, setParticipants] = useState([]);

  const editable = permissions.includes('TOURNAMENTS_MANAGE_PLAYERS');

  useEffect(() => {
    setParticipants(tournament.players.filter(player => player.isparticipant));
  }, [tournament]);

  const handleCloseModal = () => setModalVisible(false);
  const { leaderboardStages } = tournament;
  return (
    <div className="stage-standings">
      { editable && (
        <>
          <div className="add-stage" onClick={() => { setModalVisible(true); }}>
            <img src={AddIcon} className="add-stage-icon"/>
            {intl.formatMessage({ id: 'AddLeaderboardStage', defaultMessage: 'Add stage' })}
          </div>
          <AddNewStage
            intl={intl}
            visible={modalVisible}
            onCancel={handleCloseModal}
          />
        </>
      )}
      {(leaderboardStages || []).map((stage, index) => (
        <Stage
          editable={editable}
          key={index}
          intl={intl}
          stage={stage}
          permissions={permissions}
          tournamnentParticipants={participants}
        />
      ))}
    </div>
  );
}

export default React.memo(injectIntl(connect(
  state => ({
    tournament: state.tournaments.tournamentInfo,
    permissions: state.users.permissions ? state.users.permissions.keys : [],
  }), {},
)(stageStandings)));

function addNewStage({
  intl,
  visible,
  onCancel,
  tournament,
  CreateLeaderboardStage,
}) {
  const [stageName, setStageName] = useState('');
  useEffect(() => {
    if (visible === true) {
      setStageName('');
    }
  }, [visible]);
  return (
    <OBModal
      title={intl.formatMessage({ id: 'AddLeaderboardStage', defaultMessage: 'Add stage' })}
      visible={visible}
      onCancel={onCancel}
      onOk={() => {
        CreateLeaderboardStage({
          tournamentid: tournament.id,
          stageName,
        });
        onCancel();
      }}
      closable={false}
    >
      <Input
        placeholder={intl.formatMessage({ id: 'StageName', defaultMessage: 'Stage name' })}
        StageName
        onChangeText={(text) => {
          setStageName(text);
        }}
        value={stageName}
      />
    </OBModal>
  );
}

const AddNewStage = connect(
  state => ({
    tournament: state.tournaments.tournamentInfo,
  }),
  {
    CreateLeaderboardStage,
  },
)(addNewStage);
