import React from 'react';
import './leftWrapper.scss'

const LeftWrapperInvestors = () => {
    return (
        <div className={'left-wrapper'}>
            <div className={'left-wrapper__content'}>
                <div className={'left-wrapper__title'}>
                        <span className={'left-wrapper__title-text'}>
                            TENPINCAM IS INVITING
                            <span className={'left-wrapper_orange'}> INVESTORS!</span>
                        </span>
                    <span className={'left-wrapper__subtitle-text'}>
                            TenPinCam is not yet just another startup company looking for money.
                        </span>
                </div>
                <div className={'left-wrapper__bottom'}>
                    <div className={'left-wrapper__bottom-title'}>
                        WHAT WE HAVE IS:
                    </div>
                    <div className={'left-wrapper__bottom-item'}>
                        <div className={'left-wrapper__bottom-dash'}/>
                        <span className={'left-wrapper__bottom-text'}>Fully working solution </span>
                    </div>
                    <div className={'left-wrapper__bottom-item'}>
                        <div className={'left-wrapper__bottom-dash'}/>
                        <span className={'left-wrapper__bottom-text'}>Proven concept</span>
                    </div>
                    <div className={'left-wrapper__bottom-item'}>
                        <div className={'left-wrapper__bottom-dash'}/>
                        <span className={'left-wrapper__bottom-text'}>Highly motivated team.</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LeftWrapperInvestors;
