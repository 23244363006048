import React from 'react';

const aboutTournamentImg = require('../../../images/elyt/aboutTournament.png');

function AboutTournament() {
  return (
    <div className="aboutTournament">
      <div className="firstBlock">
        <div className="text-block">
          <div className="mainText">
            Have you ever thought you could take part in a European bowling tournament from your home bowling alley? Now you have such a unique opportunity!
          </div>
          <div className="mainText">
            The European Tenpin Bowling Federation (ETBF) is collaborating with TenPinCam to bring you the first European Youth LiveCam Tournament.
          </div>
          <div className="mainText">
            We invite 16 federations to participate. It will be 3-day singles test event for junior athletes - age group 21 & younger.
          </div>
        </div>
        <img className="image" src={aboutTournamentImg}/>
      </div>
      <div className="secondBlock">
        <div>
          <span className="subsection">How is it possiple?</span>
          <div className="sbContent">
            <span className="sbInfo">The tournament will be arranged, based on the TenPinCam technology:</span>
            <ul>
              <li><span>Bowlers will play from their home centers.</span></li>
              <li><span>The game will be registered in the TenPinCam app using the web cameras, installed on the lanes.</span></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="thirdBlock">
        <div className="block-title">Format:</div>
        <div className="info">16 federations (2 boys and 2 girls from each federation)</div>
        <div className="info">32 boys and 32 girls in total</div>
        <div className="info">Each gender is divided (by draw) into two groups as the brackets below.</div>
        <div className="info">Only one athlete per federation per group/gender</div>
        <div className="info">1x1 Match, Best of 3 games</div>
        <div className="info">Entry fee 100 euro per federation</div>
        <div className="info">ETBF medals to 1st and 2nd place for boys and girls</div>
      </div>
    </div>
  );
}

export default AboutTournament;
