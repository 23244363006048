import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Modal from '../../Modal';
import { Button } from '../../Button';
import CheckBox from '../../Checkbox';
import './style.css';

class LeaveBattleModal extends React.Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    buttonCancelText: PropTypes.string.isRequired,
    buttonOkText: PropTypes.string.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired,
    intl: intlShape,
  };

  render() {
    const {
      visible, buttonOkText, buttonCancelText, onOk, onCancel, checked, message, onChange, intl,
    } = this.props;
    const Content = (
      <div>
        <div className="leave-modal">{message}</div>
        <CheckBox
          onChange={(e) => {
            onChange(e);
          }}
          checked={checked}
        >
          {intl.formatMessage({ id: 'AcceptAsSurenessAlias', defaultMessage: 'Accept' })}
        </CheckBox>
      </div>
    );
    const groups = [Content];
    return (
      <Modal
        title={intl.formatMessage({ id: 'SurrenderAsLoseDesireAlias', defaultMessage: 'Surrender' })}
        visible={visible}
        width={395}
        onCancel={() => {
          onCancel();
        }}
        footer={[
          <div key="buttons">
            <Button
              key="closeButton"
              kind="aquamarine"
              bordered="true"
              onClick={() => {
                onCancel();
              }}
            >
              {buttonCancelText}
            </Button>
            <Button
              disabled={!checked ? 'disabled' : null}
              key="OkButton"
              kind="aquamarine"
              onClick={() => {
                onOk();
              }}
            >
              {buttonOkText}
            </Button>
          </div>,
        ]}
      >
        {groups}
      </Modal>
    );
  }
}

export default injectIntl(LeaveBattleModal);
