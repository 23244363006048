/* eslint-disable react/prop-types */
import React from 'react';
import { Button } from '../../Button';

/**
 * @typedef {{
 *  onCancel?: () => any
 *  onAccept?: () => any
 *  intl?: any
 *  buttons?: string[];
 * }} ModalFooterProps
 */
/** @type {React.FC<ModalFooterProps>} */
const ModalFooter = props => {
  const {
    onCancel, onAccept, intl, buttons = [],
  } = props;
  return <div key="buttons" className="dialog__buttons">
    <Button
      kind="aquamarine"
      bordered="true"
      onClick={onCancel}
      className="dialog__button-decline"
    >
      {buttons[0] || intl.formatMessage({ id: 'CancelAsAbortAlias', defaultMessage: 'Cancel' })}
    </Button>
    <Button
      onClick={onAccept}
      kind="aquamarine"
      className="dialog__button-accept"
    >
      {buttons[1] || intl.formatMessage({ id: 'Accept', defaultMessage: 'Accept' })}
    </Button>
  </div>;
};

export default ModalFooter;
