import { put, call } from 'redux-saga/effects';
import config from '../../config';
import { get } from '../Networking';

const {
  SET_VIDEO_ARCHIVE,
  SET_VIDEO_ARCHIVE_PROTESTS,
} = require('../reducers/actiontypes').default;

export function* _getVideoArchive(params) {
  const { squadid, protestid } = params.params;
  try {
    let url = `${config.FETCH.url}/videoarchive/${squadid}`;
    if (protestid) {
      url += `/${protestid}`;
    }
    const result = yield call(get, { url });
    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_VIDEO_ARCHIVE, payload: result.data.data });
    } else {
      yield put({ type: SET_VIDEO_ARCHIVE, error: result.data.error || result.status });
    }
  } catch (err) {
    yield put({ type: SET_VIDEO_ARCHIVE, error: err });
  }
}

export function* _getVideoArchiveProtest(params) {
  const { squadid } = params.params;
  try {
    let url = `${config.FETCH.url}/videoarchive/protests/${squadid}`;
    const result = yield call(get, { url });
    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_VIDEO_ARCHIVE_PROTESTS, payload: result.data.data });
    }
  } catch (err) {
    yield put({ type: SET_VIDEO_ARCHIVE_PROTESTS, payload: [] });
  }
}
