import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.css';

export default class Input extends Component {
  static propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.oneOf(['text', 'password', 'email', 'number']),
    onChangeText: PropTypes.func,
    value: PropTypes.string,
    name: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    type: 'text',
    value: '',
  }

  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      tip: props.value ? this.props.placeholder : '',
      isFocused: false,
    };

    this.textInput = React.createRef();
  }

  updateValue = () => {
    this.setState({ value: this.props.value })
  }

  handleChange = (event) => {
    const tip = event.target.value ? this.props.placeholder : '';
    if (this.props.onChangeText) this.props.onChangeText(event.target.value, event.target.name);
    if (this.props.onChange) this.props.onChange(event);
    this.setState({ tip, value: event.target.value });
  }

  handleFocus = () => {
    this.setState({ isFocused: true });
  }

  handleBlur = () => {
    this.setState({ isFocused: false });
  }

  onLabelClick = () => {
    this.textInput.current.focus();
  }

  componentDidUpdate(prevProps) {
    const { placeholder } = this.props;
    const { tip } = this.state;
    if (prevProps.placeholder !== placeholder && tip) {
      this.setState({ tip: placeholder });
    }
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
      if (!this.props.value) {
        this.setState({ tip: '' });
      }
    }
  }

  render() {
    const {
      type,
      placeholder,
      name,
      error,
      readonly,

      forceUseProps,
    } = this.props;

    const { value, tip, isFocused } = this.state;

    const classname = this.props.className ? `${this.props.className} input__data` : 'input__data';
    const tipClassName = isFocused ? 'input__tip input__tip_focused' : 'input__tip';

    return (
      <div className='input'>
        <label htmlFor={name} className={tipClassName} onClick={this.onLabelClick}>{ tip }</label>
        <input
          ref={this.textInput}
          className={classname}
          type={type}
          placeholder={placeholder}
          value={forceUseProps?this.props.value:value}
          name={name}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          readOnly={readonly}
          accept={this.props.accept}
        />
        { error && <div className='input__error'>{ error }</div> }
      </div>
    );
  }
}
