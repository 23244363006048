import React, {useState} from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import Avatar from '../common/Avatar';
import { Button } from '../common/Button';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import {
  UserByIdFetch,
  setVideoArchiveSquadId, getLeaderBoard,
} from '../../libs/reducers/actions';
import config from '../../config';
import defaultAvatar from '../../images/defaultAvatar.png';
import { ReactComponent as SvgCountry } from '../../images/country.svg';
import { ReactComponent as SvgGender } from '../../images/gender.svg';
import Flag from '../common/Flag';
import './style.scss';


class Leaderboard extends React.Component {
  play = (squadid) => {
    this.props.setVideoArchiveSquadId(squadid);
    this.props.history.push('/videoarchive');
  }

  componentDidMount() {
    const {tournament: {id}, getLeaderBoard} = this.props
    getLeaderBoard({tournamentid: id});
  }

  render() {
    // старый метод сортировки лидерборда
    // const sortedParticipants = (this.props.tournament.players || [])
    // .filter(player => player.isparticipant && player.bestSquad)
    // .sort((a, b) => (b.bestSquad || {}).results || 0 - (a.bestSquad || {}).results || 0);
    const numberPrizesPlace = Array.isArray(this.props.tournament.settings)
      ? (this.props.tournament.settings.find(s => s.tournament_setting_brief === 'COUNT_PRIZES_PLACES') || {}).value_int
      : null;

    const leaderBoard = this.props.leaderBoard

    const columns = [

      {
        title: this.props.intl.formatMessage({ id: 'TournamentPlace', defaultMessage: 'Place' }),
        dataIndex: '',
        align: 'center',
        render: (data, record, index) => ({
          props: {},
          children: (
            <>
              {index + 1}
            </>),
        }),
      },
      {
        title: this.props.intl.formatMessage({ id: 'FullnameTableColumn', defaultMessage: 'Fullname' }),
        dataIndex: '',
        width: '20%',
        render: record => ({
          props: {},
          children: (<>
              <div className="username__container">
                <Avatar
                  uri={record.photo ? `${config.FETCH.img}/${record.photo}` : defaultAvatar}
                  avgscore={record.avgscore}
                  flag={null}
                  tabled
                />
                {`${record.firstname} ${record.lastname}`.trim() || '-'}
              </div>
            </>),
        }),
      },
      {
        title: this.props.intl.formatMessage({ id: 'LoginTableColumn', defaultMessage: 'Login' }),
        dataIndex: '',
        width: '20%',
        render: (data, record, index) => ({
          props: {},
          children: (<>
              <div className="participant__login"><Link to="#" onClick={() => { this.props.UserByIdFetch({ userid: record.userid }); }}>{record.login}</Link></div>
            </>),
        }),
      },

      {
        title: <>
          <svg
            width="20"
            height="19"
            viewBox="0 0 20 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginTop: 3 }}>
            <path d="M10 1.04704L12.6699 5.95934L12.7836 6.16865L13.0179 6.21216L18.5148 7.23339L14.6679 11.2906L14.504 11.4634L14.535 11.6996L15.2624 17.2431L10.2151 14.8383L10 14.7358L9.78494 14.8383L4.73759 17.2431L5.46498 11.6996L5.49598 11.4634L5.33207 11.2906L1.48523 7.23339L6.98214 6.21216L7.21636 6.16865L7.33012 5.95934L10 1.04704Z" stroke="#818589"/>
          </svg>
        </>,
        width: 40,
        dataIndex: 'rating',
        sorter: ((a, b) => (b.bestSquad || {}).results || 0 - (a.bestSquad || {}).results || 0),
        render: (data, record, index) => ({
          props: {},
          children: (<>
              {data
                ? <div className='ratingWrap'><svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 0L12.9389 5.95492L19.5106 6.90983L14.7553 11.5451L15.8779 18.0902L10 15L4.12215 18.0902L5.24472 11.5451L0.489435 6.90983L7.06107 5.95492L10 0Z" fill="#FFB31E"/>
                </svg>
                {data}</div>
                :								<div className='ratingWrap'><svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 1.12978L12.4906 6.1762L12.6069 6.41192L12.867 6.44972L18.4361 7.25895L14.4063 11.187L14.218 11.3705L14.2625 11.6296L15.2138 17.1762L10.2327 14.5574L10 14.4351L9.76733 14.5574L4.78621 17.1762L5.73752 11.6296L5.78196 11.3705L5.59372 11.187L1.56392 7.25895L7.13297 6.44972L7.39311 6.41192L7.50944 6.1762L10 1.12978Z" stroke="#FFB31E"/>
                </svg>
                0</div>
              }
            </>),
        }),
      },
      {
        title: <SvgCountry />,
        dataIndex: 'creatorcountry',
        width: 40,
        align: 'center',
        render: (data, record, index) => ({
          children: (
          <div className="battles__flag">
            <Flag name={record.country} />
          </div>
          ),
          props: {
          },
        }),
        sorter: (a, b) => (a.country_live || a.country).localeCompare(b.country_live || b.country),
      },
      {
        title: <SvgGender />,
        width: 40,
        dataIndex: 'gender',
      },
      {
        title: this.props.intl.formatMessage({ id: 'dob', defaultMessage: 'Date of birth' }),
        dataIndex: '',
        render: (data, record, index) => ({
          props: {},
          children: (<>
            {
              <span key={record.id}>{moment(record.dob).format('DD MMM yyyy')}</span>
            }
          </>),
        }),
      },
      {
        title: 'Cut',
        dataIndex: '',
        align: 'right',
        render: (data, record, index) => ({
          props: {},
          children: (<>
            {
              leaderBoard[this.props.tournament.winners - 1]
                ? record.bestSquad && record.bestSquad.results - leaderBoard[this.props.tournament.winners - 1].bestSquad.results
                : null
              }
            </>),
        }),
      },
      {
        title: this.props.intl.formatMessage({ id: 'ResultTableColumn', formatMessage: 'Result' }),
        dataIndex: '',
        align: 'right',
        render: (data, record, index) => ({
          props: {},
          children: (<span style={{ fontWeight: 'bold' }}>
             {record.bestSquad && record.bestSquad.results}
            </span>),
        }),
      },
      {
        render: (data, record, index) => ({
          width: '25%',
          props: {},
          main: (
            record.bestSquad && record.bestSquad.archive && record.bestSquad.archive.exists
            && (!this.props.tournament.regdateend || moment().isBefore(moment(this.props.tournament.regdateend)))
              ? (
              <div className="play_trash">
                <Button
                  kind={'aquamarine'}
                  icon={'play'}
                  onClick={() => { this.play(record.bestSquad.id) }}
                >
                  {this.props.intl.formatMessage({ id: 'play', defaultMessage: 'Play' })}
                </Button>
              </div>
              )
              : null
          ),
        }),
      },
    ];
    return <Table
      className="competitionsTable"
      columns={columns}
      dataSource={leaderBoard}
      pagination={false}
      rowKey="id"
      locale={{ emptyText: 'No competitors' }}
      rowClassName={(record, index) => {
        let result = 'tournamentRow ';
        if (numberPrizesPlace > index) result += 'favoriteParticipant ';
        if (this.props.user.id == record.userid) result += 'currentUser ';
        return result;
      }}
    />;
  }
}

export default withRouter(connect(
  state => ({
    InfoTournament: state.users.InfoTournament,
    leaderBoard: state.tournaments.InfoTournament
  }),
  {
    UserByIdFetch,
    setVideoArchiveSquadId,
    getLeaderBoard,
  },
)(Leaderboard));
