import React, { useState } from 'react';

import Input from './common/input';
import Radio from './common/radio';
import DatePicker from './common/datepicker';
import FileUploader from './common/fileUploader';

const groupOptions = [
  { value: 'Group A', label: 'Group A', mobileLabel: 'A' },
  { value: 'Group B', label: 'Group B', mobileLabel: 'B' },
];

const sexOptions = [
  { value: 'M', label: 'Boy' },
  { value: 'F', label: 'Girl' },
];

function Participant({
  index,
  onFieldChange,
  onDelete,
}) {
  return (
    <div className="participant">
      <div className="p-h">
        <div>Participant {index}</div>
        <div className="delete" onClick={onDelete}>
          <span className="l-hide-m">Delete participant</span>
          <span className="l-hide-d">Delete</span>
        </div>
      </div>
      <div className="p-d1">
        <Radio label="Gender" options={sexOptions} onChange={(value) => { onFieldChange('gender', value); }}/>
        <Radio label="Group" options={groupOptions} onChange={(value) => { onFieldChange('group', value); }}/>
      </div>
      <div className="p-d2">
        <Input label="Name" onChange={(value) => { onFieldChange('name', value); }}/>
        <Input label="Surname" onChange={(value) => { onFieldChange('surname', value); }}/>
      </div>
      <DatePicker onChange={(value) => { onFieldChange('birthday', value); }}/>
      <FileUploader onChange={(value) => { onFieldChange('photo', value); }}/>
    </div>
  );
}

export default Participant;
