import { call, put } from 'redux-saga/effects'
import config from '../../config'
import { post as methodPOST } from '../Networking'

export const hasExpectedGamesAction = (params) => ({ type: 'HAS_EXPECTED_GAMES', params })
export const setExpectedGamesModalAction = (params) => ({ type: 'SET_EXPECTED_GAMES_MODAL', params })
export const closeExpectedGamesModalAction = () => ({ type: 'CLOSE_EXPECTED_GAMES_MODAL' })

export const initailGamesExpectedState = {
  squads: [],
  modal: { visible: false },
  okAction: null,
}

export const gamesExpectedReducer = (state = initailGamesExpectedState, action) => {
  switch (action.type) {
    case 'HAS_EXPECTED_GAMES':
      return {
        ...state,
        squads: action.params.squads,
        okAction: state.okAction ? state.okAction : {
          ...action.params.okAction,
          ignoreExpected: true,
        },
        modal: {
          ...state.modal,
          visible: true,
        },
      }
    case 'SET_EXPECTED_GAMES_MODAL':
      return {
        ...state,
        modal: {
          ...state.modal,
          ...action.params,
        },
      }
    case 'CLOSE_EXPECTED_GAMES_MODAL':
      return {
        ...state,
        modal: {
          ...state.modal,
          visible: false,
        },
        okAction: null,
      }
    default:
      return state;
  }
}
/**
 * @typedef {{
 *  tournament: {
 *    id: number;
 *    squadid: number;
 *    name: string;
 *  }
 *  players: {
 *    id: number;
 *    login: string;
 *    playerid: number;
 *  }[]
 * }} IGameInviteParams
 */

/**
 * 
 * @param {{
 *  type: string;
 *  params: IGameInviteParams
 * }} action 
 */
export function* hasExpectedGamesSaga(action) {
  try {
    const { params } = action;
    if (params && params.ignoreExpected) {
      return false;
    }
    const res = yield call(methodPOST, { url: `${config.FETCH.url}/tournament/scheduled_games` });
    if (res.status !== 200 || !res.data || !res.data.success) {
      // TODO: handle error
      return false;
    }
    /** @type {{ squadid: number; datestart: string; type: string; name: string; tournamenttypeid: number; }[]} */
    const squads = res.data.data.filter(s => s.squadid != params.tournament.squadid)
    if (!squads.length) {
      return false;
    }
    yield put(hasExpectedGamesAction({ okAction: action, squads }))
    return true;
  } catch (error) {
    console.log('ERROR', error)
    return false; // TODO: handle error
  }
}
