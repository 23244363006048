import React from 'react';
import IconsSVG from './icons.svg';


/**
  *  Спрайты svg
  * @param  {String} name        Название иконки из файла icons-svg (без icon-)
  * @param  {String} color       Цвет иконки
  * @param  {String} size        Размер иконки (квадрат)
  * @param  {String} className   Класс иконки
  * @return {Svg} icon
  * @abstract
  */
function Icons({ name, color, size, className }) {
  return (
    <svg
      className={className}
      fill={color}
      stroke={color}
      width={size}
      height={size}
    >
      <use xlinkHref={`${IconsSVG}#icon-${name}`} />
    </svg>
  )
}

export default Icons;
