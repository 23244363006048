import React, { Component } from 'react'
import ios from './images/app-store3.png';
import android from './images/google-play3.png';
import huawei from './images/app-gallery-huawei.png';
import samsung from './images/galaxy-store.png';
import google from '../../images/google-play.png';
import qrcodeDownload from './images/qrcode-download.png';
import appGalary from './images/app_galary.png';

import './style.scss'
import './adaptive.scss'

export default class DownloadBlock extends Component {
  onClickDownload = (type) => {
    if (!window.ym) {
      return;
    }

    const ymKey = 52252669;

    switch (type) {
      case 'ios':
        window.ym(ymKey, 'reachGoal', 'DownloadIOS');
        break;
      case 'android':
        window.ym(ymKey, 'reachGoal', 'DownloadAndroid');
        break;
      case 'huawei':
        window.ym(ymKey, 'reachGoal', 'DownloadHuawei');
        break;
      case 'samsung':
        window.ym(ymKey, 'reachGoal', 'DownloadSamsung');
        break;
      default:
        break;
    }
  }

  render() {
    const { intl, history } = this.props;

    return <div className='downloadlinks fullcontainer'>
      <h2>{intl.formatMessage({ id: 'LandingDownloadLinks', defaultMessage: 'Download mobile app' })}</h2>
      <div className="f-download">
        <div className="f-download__qrcode">
          <img src={qrcodeDownload} alt="Download app" />
        </div>
        <div className="links">
          <div>
            <a
              target="blank"
              href="https://apps.apple.com/ru/app/tenpincam/id1481986359?l=en"
              onClick={() => this.onClickDownload('ios')}
            >
              <div className="links__button">
                <img src={ios}/>
              </div>
            </a>
          </div>
          <div>
            <a
              href="https://play.google.com/store/apps/details?id=com.tenpincam.telepresence_bowling_1"
              onClick={() => this.onClickDownload('android')}
            >
              <div className="links__button">
                <img src={google}/>
              </div>
            </a>
          </div>
          <div>
            <a
              target="blank"
              href="https://appgallery.huawei.com/#/app/C105386377"
              onClick={() => this.onClickDownload('huawei')}
            >
              <div className="links__button">
                <img src={huawei}/>
              </div>
            </a>
          </div>
          <div>
            <a
              target="blank"
              href="https://galaxystore.samsung.com/detail/com.tenpincam.telepresence_bowling_1"
              onClick={() => this.onClickDownload('samsung')}
            >
              <div className="links__button">
                <img src={samsung}/>
              </div>
            </a>
          </div>
          {/* <div>
            <a
              href="#"
            >
              <div className="links__button">
                <img src={appGalary}/>
              </div>
            </a>
          </div> */}
        </div>
      </div>
    </div>
  }
}
