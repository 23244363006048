import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {Table} from 'antd';
import {injectIntl, intlShape} from 'react-intl';
import moment from 'moment';
import _ from 'lodash';
import {connect} from 'react-redux';
import {
  UserByIdFetch,
  fetchUserDisk,
  unbindVideoFilesOfUser,
  setVideoArchiveSquadId,
  requestTournamentInfo,
  CancelTournamentBattle,
  getPermissions,
  editTournamentBattle,
  createBattleForTournament,
  setObservableSquad,
  setProtocolSquadId,
  addUserToTournament,
  setVisibilityBattle,
  clearGameInfo,
} from '../../libs/reducers/actions';
import {Button} from '../common/Button';
import Checkbox from '../common/Checkbox';
import ModalSaveBattle from '../common/modals/modalSaveBattle';
import ModalDialog from '../common/modals/modalDialog';
import OBModal from '../common/Modal';
import OBDatePicker from '../common/DatePicker';
import OBTimePicker from '../common/TimePicker';
import './style.scss';
import skittles from '../../images/skittles.png';
import SvgRefereeing from '-!svg-react-loader!../../images/refereeing.svg'; // eslint-disable-line
import {isSolo} from '../../libs/SquadTypeCheck';
import Avatar from "../common/Avatar";
import config from '../../config'
import VisibilityButton from "../../components/visibilityButton/visibilityButton";
import GameResultsModal from "../adminRoot/gameResults/gameResultsModal";
import defaultAvatar from '../../images/defaultAvatar.png';


class CompetitionList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      squadid: null,
      showSaveBattleModal: false,
      showConfirmation: false,
      action: false,
      userdiskBusySpace: props.userdisk.busySpace || null,
      battles: null,
      owned: null,

      participationModal: false,
      rulesAgreement: false,
      feeAgreement: false,
      locationAgreement: false,
      visibleConfirmRegisterModal: false,

      showGameResultsModal: false,
      selectedGame: null,
    };
    this.columnsDataDropIn = [
      {
        title: '#',
        dataIndex: 'id',
        sorter: (a, b) => ((a.id == b.id) ? 0 : (a.id - b.id) / Math.abs(a.id - b.id)),
        render: (data, record, index) => {
          return (<span
            className={record.gameresults.length > 0 ? 'clickableSpan' : '' && (record.players || []).find(p => p.userid === this.props.userid) ? 'mybattle' : ''}
            onClick={() => {
              this.setState({selectedGame: record.id})
              this.setState({showGameResultsModal: record.gameresults.length == 0 ? false : true});
            }}>
              {record.id}
          </span>)
        }
      },
      {
        title: this.props.intl.formatMessage({id: 'competitor', defaultMessage: 'Competitor'}) + ' 1',
        dataIndex: '',
        render: (data, record, index) => {
          return {
            props: {},
            children: (<>
              {record.players.map(player =>
                <div className="competitor-sizer">
                  <div className="competitor__container">
                    <div className="avatar-sizer">
                      <Avatar
                        gamesTable
                        uri={player.photo ? `${config.FETCH.img}/${player.photo}` : defaultAvatar}
                        flag={player.country_live}
                        isOnline={player.active}
                        avgscore={player.avgscore}
                      /></div>

                    <div className='userNameContainer'>
                      <p>{`${player.firstname} ${player.lastname}`.trim() || '-'}</p>
                      <p className='login-text'>{player.login}</p>
                    </div>

                  </div>
                </div>
              )}
            </>),
          }
        },
      },
      {
        title: this.props.intl.formatMessage({id: 'DateStartTournamentCreate', defaultMessage: 'Date start'}),
        dataIndex: 'datestart',
        render: (data, record, index) => {
          return {
            props: {},
            children: (<>
              <div className="battledate">{moment(record.datestart).format('DD MMM - HH:mm')}</div>
            </>)
          }
        },
        sorter: (a, b) => {
          if (!a.datestart && !b.datestart) {
            return a.id - b.id
          }
          if (!a.datestart) {
            return -1
          }
          if (!b.datestart) {
            return 1
          }
          return moment(a.datestart).unix() - moment(b.datestart).unix();
        },
      },
      {
        title: this.props.intl.formatMessage({id: 'ResultTableColumn', defaultMessage: 'Result'}),
        dataIndex: '',
        render: (data, record, index) => {
          const results = record.results ? record.results : {};
          const scoreLeader = Math.max(...(Object.keys(results).map(key => record.results[key])));
          return {
            props: {},
            children: (<>
              {record.closed
                ? <div
                  className={`result__container ${record.with_protest ? 'clickable' : ''}`}
                  onClick={() =>
                    record.with_protest
                      ? this.gotoProtocol(record.id)
                      : null
                  }
                >
                  {record.with_protest
                    ? <SvgRefereeing className='result__refereeing'/>
                    : null
                  }
                  <div>
                    {
                      record.players.map(player =>
                        <div
                          className={`participant__result${scoreLeader === record.results[player.id] ? ' largest' : ' lowest'}`}>{record.results[player.id]}</div>
                      )
                    }
                  </div>
                </div>
                : record.inProgress ?
                  <div className='table_draw_result'>{this.props.intl.formatMessage({
                    id: 'GameInProgress',
                    defaultMessage: 'Game in progress'
                  })}</div> : null}
            </>),
          }
        }
      },
      {
        title: this.props.intl.formatMessage({id: 'GameScoreTotal', defaultMessage: 'Score'}),
        dataIndex: '',
        render: (data, record, index) => {
          return {
            props: {},
            children: (<>
              {(record.closed && record.gameresults && (record.gameresults || []).length)
                ? (
                  <div>
                    {record.gameresults.join(', ')}
                  </div>) : null}
            </>),
          }
        }
      },
      {
        title: this.props.intl.formatMessage({id: 'observe', defaultMessage: 'Observe'}),
        dataIndex: '',
        render: (data, record, index) => {
          const {id: squadid, inProgress, owned, archive} = record;
          const {tournamentclosed} = this.props;

          // if (tournamentclosed) {
          //   return <></>
          // }
          if (inProgress && owned) {
            return (
              <Button
                size="md"
                className="active-game__btn"
                onClick={() => this.props.history.push('/squad')}
              >
                {this.props.intl.formatMessage({
                  id: 'ReturnAtGameCard',
                  defaultMessage: 'Return to game'
                })}
              </Button>
            )
          }
          if (inProgress) {
            return <div class="play_trash">
              <Button
                kind={'aquamarine'}
                onClick={() => {
                  this.props.setObservableSquad(squadid);
                  this.props.history.push('/watch');
                }}
              >
                {this.props.intl.formatMessage({
                  id: 'WatchBattleOfTournament',
                  defaultMessage: 'Watch'
                })}
              </Button>
            </div>
          }
          if (archive && archive.exists) {
            return (
              <div class="play_trash">
                <Button
                  kind={'aquamarine'}
                  icon={'play'}
                  onClick={this.state.action ? () => {
                  } : () => {
                    this.play(squadid);
                  }}
                >
                  {this.props.intl.formatMessage({id: 'play', defaultMessage: 'Play'})}
                </Button>
              </div>
            );
          } else {
            return <></>
          }
        }
      },
    ];
    this.columnsData1x1 = [
      {
        title: '#',
        dataIndex: 'id',
        sorter: (a, b) => ((a.id == b.id) ? 0 : (a.id - b.id) / Math.abs(a.id - b.id)),
        render: (data, record, index) => {
          return (<span
            className={record.gameresults.length > 0 ? 'clickableSpan' : '' && (record.players || []).find(p => p.userid === this.props.userid) ? 'mybattle' : ''}
            onClick={() => {
              this.setState({selectedGame: record.id})
              this.setState({showGameResultsModal: record.gameresults.length == 0 ? false : true});
            }}>
              {record.id}
          </span>)
        }
      },
      {
        title: this.props.intl.formatMessage({id: 'competitor', defaultMessage: 'Competitor'}) + ' 1',
        dataIndex: '',
        render: (data, record, index) => {
          return {
            props: {},
            children: (<>
              {record.players && record.players[0]
                ? (
                  <div className="competitor-sizer">
                    <div className="competitor__container">
                      <div className="avatar-sizer">
                        <Avatar
                          gamesTable
                          uri={record.players[0].photo ? `${config.FETCH.img}/${record.players[0].photo}` : defaultAvatar}
                          flag={record.players[0].country_live}
                          isOnline={record.players[0].active}
                          avgscore={record.players[0].avgscore}
                        /></div>

                      <div className='userNameContainer'>
                        <p>{`${record.players[0].firstname} ${record.players[0].lastname}`.trim() || '-'}</p>
                        <p className='login-text'>{record.players[0].login}</p>
                      </div>

                    </div>
                  </div>
                ) : <></>
              }
            </>),
          }
        },
      },
      {
        title: this.props.intl.formatMessage({id: 'competitor', defaultMessage: 'Competitor'}) + ' 2',
        dataIndex: '',
        render: (data, record, index) => {
          return {
            props: {},
            children: (<>
              {record.players && record.players[1]
                ? (
                  <div className="competitor-sizer">
                    <div className="competitor__container">
                      <div className="avatar-sizer">
                        <Avatar
                          gamesTable
                          uri={record.players[1].photo ? `${config.FETCH.img}/${record.players[1].photo}` : defaultAvatar}
                          flag={record.players[1].country_live}
                          isOnline={record.players[1].active}
                          avgscore={record.players[1].avgscore}
                        /></div>

                      <div className='userNameContainer'>
                        <p>{`${record.players[1].firstname} ${record.players[1].lastname}`.trim() || '-'}</p>
                        <p className='login-text'>{record.players[1].login}</p>
                      </div>

                    </div>
                  </div>
                ) : <></>
              }
            </>),
          }
        },
      },
      // {
      //   title: this.props.intl.formatMessage({ id: 'fee', defaultMessage: 'Fee' }),
      //   dataIndex: 'cost',
      //   sorter: (a, b) => ((a.cost==b.cost) ? 0: (a.cost-b.cost)/Math.abs(a.cost-b.cost)),
      //   render: (data, record, index) => {
      //     return {
      //       props: {},
      //       children:(<>
      //         {record.cost ?
      //           `${record.cost}${record.currencysymbol}`
      //           : 'free'}
      //       </>)
      //     }
      //   }
      // },
      // {
      //   title: this.props.intl.formatMessage({ id: 'table_wins', defaultMessage: 'Wins' }),
      //   dataIndex: 'wincount',
      //   sorter: (a, b) => ((a==b) ? 0: (a-b)/Math.abs(a-b)),
      //   className: 'rightPinned',
      // },
      {
        title: this.props.intl.formatMessage({id: 'DateStartTournamentCreate', defaultMessage: 'Date start'}),
        dataIndex: 'datestart',
        render: (data, record, index) => {
          return {
            props: {},
            children: (<>
              {
                this.props.permissions.includes('TOURNAMENTS_CREATE_BATTLE')
                  ? (
                    <div className="date-group__edit">
                      <OBDatePicker
                        className="date-group__elements__picker"
                        placeholder={this.props.intl.formatMessage({
                          id: 'date',
                          defaultMessage: 'Date'
                        })}
                        value={moment(record.datestart)}
                        onChange={date => this.updateTime(date, record.id)}
                        disabledDate={current => current && current < moment().subtract(1, 'days')}
                        dateFormat="D MMM"
                      />
                      <span className="date-group__del"/>
                      <OBTimePicker
                        className="date-group__elements__picker"
                        placeholder={this.props.intl.formatMessage({
                          id: 'time',
                          defaultMessage: 'Time'
                        })}
                        value={moment(record.datestart)}
                        onChange={date => this.updateTime(date, record.id)}
                      />
                    </div>
                  ) : (
                    <div
                      className="battledate">{moment(record.datestart).format('DD MMM - HH:mm')}</div>
                  )
              }

              {/* {this.props.permissions.includes('TOURNAMENTS_CREATE_BATTLE') ?
            <div className="date-group__pickers">
              <OBDatePicker
                className="date-group__elements__picker"
                placeholder={this.props.intl.formatMessage({ id: 'date', defaultMessage: 'Date' })}
                value={moment(record.datestart)}
                onChange={date => this.updateTime(date, record.id)}
                disabledDate={current => current && current < moment().subtract(1, 'days')}
              />
              <OBTimePicker
                className="date-group__elements__picker"
                placeholder={this.props.intl.formatMessage({ id: 'time', defaultMessage: 'Time' })}
                value={moment(record.datestart)}
                onChange={date => this.updateTime(date, record.id)}
              />
            </div>
            :
            <div className="battledate">{moment(record.datestart).format('DD MMM - HH:mm')}</div>} */}
            </>)
          }
        },
        sorter: (a, b) => {
          if (!a.datestart && !b.datestart) {
            return a.id - b.id
          }
          if (!a.datestart) {
            return -1
          }
          if (!b.datestart) {
            return 1
          }
          return moment(a.datestart).unix() - moment(b.datestart).unix();
        },
      },
      {
        title: this.props.intl.formatMessage({id: 'ResultTableColumn', defaultMessage: 'Result'}),
        dataIndex: '',
        render: (data, record, index) => {
          const scoreLeader = Math.max(...(Object.keys(record.results).map(key => record.results[key])));
          const length = (record.players || []).length;
          return {
            props: {},
            children: (<>
              {record.closed ?
                <div className={`result__container ${record.with_protest ? 'clickable' : ''} `}
                     onClick={() =>
                       record.with_protest
                         ? this.gotoProtocol(record.id)
                         : null
                     }
                >
                  {
                    record.with_protest
                      ? <SvgRefereeing className='result__refereeing'/>
                      : null
                  }
                  {record.players.map((player, idx) =>
                      <span
                        key={idx}
                      >
                    {record.results[player.id]}
                        {idx + 1 != length ? ':' : ''}
                  </span>
                  )}
                </div>
                : record.inProgress ?
                  <div className='table_draw_result'>{this.props.intl.formatMessage({
                    id: 'GameInProgress',
                    defaultMessage: 'Game in progress'
                  })}</div> : null}
            </>),
          }
        }
      },
      {
        title: this.props.intl.formatMessage({id: 'GameScoreTotal', defaultMessage: 'Score'}),
        dataIndex: '',
        render: (data, record, index) => {
          return {
            props: {},
            children: (<>
              {record.closed && record.gameresults ?
                record.gameresults.map(game => <div>
                  {record.players.map(player => game.score[player.id]).join(':')}
                </div>)
                : null}
            </>),
          }
        }
      },
      {
        title: this.props.intl.formatMessage({id: 'observe', defaultMessage: 'Observe'}),
        dataIndex: '',
        render: (data, record, index) => {
          const {id: squadid, inProgress, owned, archive, closed} = record;
          if (owned && inProgress) {
            return (
              <Button
                size="md"
                className="active-game__btn"
                onClick={() => this.props.history.push('/squad')}
              >
                {this.props.intl.formatMessage({
                  id: 'ReturnAtGameCard',
                  defaultMessage: 'Return to game'
                })}
              </Button>
            )
          }
          if (inProgress) {
            return <div class="play_trash">
              <Button
                kind={'aquamarine'}
                onClick={() => {
                  this.props.setObservableSquad(squadid);
                  this.props.history.push('/watch');
                }}
              >
                {this.props.intl.formatMessage({
                  id: 'WatchBattleOfTournament',
                  defaultMessage: 'Watch'
                })}
              </Button>
            </div>
          }
          if (archive.exists) {
            return (
              <div class="play_trash">
                <Button
                  kind={'aquamarine'}
                  icon={'play'}
                  onClick={this.state.action ? () => {
                  } : () => {
                    this.play(squadid);
                  }}
                >
                  {this.props.intl.formatMessage({id: 'play', defaultMessage: 'Play'})}
                </Button>
              </div>
            );
          }

          // Только просмотривать (сохранять пока не нужно)
          if (this.props.permissions.includes('TOURNAMENTS_DELETE_BATTLE') && !closed && !inProgress) {
            return (
              <Button
                kind="aquamarine"
                bordered="true"
                icon={"tray"}
                onClick={() => {
                  this.props.CancelTournamentBattle({
                    squadid: record.id,
                    tournamenttypeid: this.props.tournamentInfo.tournamenttypeid
                  })
                }}
              >
                {this.props.intl.formatMessage({id: 'DeleteAsDestroyAlias', defaultMessage: 'Delete'})}
              </Button>
            );
          }
        }
      },
    ];
    this.adminColumns = [
      {
        title: this.props.intl.formatMessage({id: 'dob', defaultMessage: 'Date of Birth'}),
        dataIndex: '',
        render: (data, record) => {
          return {
            props: {},
            children: (<>
              {record.players.map(player => {
                return <div>{moment(new Date(player.dateofbirth)).format('DD MMM yyyy')}</div>
              })
              }
            </>),
          }
        }
      },
      {
        title: this.props.intl.formatMessage({id: 'Visibility', defaultMessage: 'Visibility'}),
        dataIndex: '',
        render: (data, record) => {
          const {id: inProgress, archive} = record;
          if (this.props.permissions.includes('TOURNAMENTS_MODIFY_TOURNAMENTS') && inProgress && archive) {
            return (
              <VisibilityButton
                record={record}
              />
            )
          }
        }
      }
    ];
  }


  gotoProtocol = squadid => {
    this.props.setProtocolSquadId(squadid);
    this.props.history.push('/protocol');
  }


  getProvisionalColumns = () => {
    return this.props.permissions.includes('TOURNAMENTS_MODIFY_TOURNAMENTS') ? [...this.columnsData1x1, ...this.adminColumns] : [...this.columnsData1x1]
  };


  getQualificationalColumns = () => {
    return this.props.permissions.includes('TOURNAMENTS_MODIFY_TOURNAMENTS') ? [...this.columnsDataDropIn, ...this.adminColumns] : [...this.columnsDataDropIn]
  };


  static getDerivedStateFromProps(nextProps, prevState) {
    let state = {...prevState}
    if (!_.isEqual(nextProps.tournamentInfo.battles, prevState.battles) && nextProps.tournamentInfo) {
      state = {...state, battles: nextProps.tournamentInfo.battles};
    }
    if (!_.isEmpty(nextProps.userdisk) && prevState.userdiskBusySpace == null) {
      state = {...state, userdiskBusySpace: nextProps.userdisk.busySpace};
    }
    if (!_.isEmpty(nextProps.userdisk) && prevState.userdiskBusySpace != null && +nextProps.userdisk.busySpace != +prevState.userdiskBusySpace) {
      nextProps.requestTournamentInfo(nextProps.tournamentInfo.id);
      state = {...state, userdiskBusySpace: nextProps.userdisk.busySpace, action: false};
    }
    return state;
  }

  static
  propTypes = {
    competitions: PropTypes.array,
    intl: intlShape,
  };

  componentDidMount() {
    this.props.fetchUserDisk();
  }

  onDeleteArchive = squadid => {
    this.setState({
      squadid: squadid,
      showConfirmation: true,
      action: true
    });
  }

  deleteArchive = () => {
    const {squadid} = this.state;
    this.props.unbindVideoFilesOfUser({squadid});
    this.setState({
      squadid: null,
      showConfirmation: false
    });
  };

  play = squadid => {
    this.props.setVideoArchiveSquadId(squadid);
    this.props.history.push('/videoarchive');
  }

  updateTime = (date, squadid) => {
    if (moment(date) < moment()) date = new Date();
    this.props.editTournamentBattle({date: moment(date).set('s', 0).toISOString(), squadid});
  }

  renderNotBasttles = () => {
    const {user, tournamentInfo} = this.props;
    let btn = null;
    const player = tournamentInfo && (tournamentInfo.players || []).find(_player => _player.userid === user.id);
    const isParticipant = player && player.isparticipant;
    const tournamentByInvite = ((tournamentInfo.settings || []).find(s => s.tournament_setting_brief === 'BY_INVITATION') || {}).value_bool;
    if (isSolo(tournamentInfo.tournamenttypebrief)) {
      btn = (!user.is_adult && tournamentInfo.cost && <></>)
        || (tournamentByInvite && !isParticipant && <></>)
        || (
          <div
            className="lobby__no_data_found__button create"
            onClick={() => (this.props.tournamentInfo.regdateend
              && moment(moment.now()).isAfter(this.props.tournamentInfo.regdateend))
            || (this.props.tournamentInfo.regdatestart
              && moment(moment.now()).isBefore(this.props.tournamentInfo.regdatestart))
              ? null
              : (this.checkUserTournamentMember()
                  ? this.setState({
                    participationModal: true,
                    rulesAgreement: false,
                    feeAgreement: false,
                    locationAgreement: false,
                  })
                  : this.setState({
                    visibleConfirmRegisterModal: true,
                  })
              )
            }
            style={{backgroundColor: '#20A39E', padding: '9px 79px'}}
          >
            <div className="lobby__no_data_found__button__text">
              {
                (this.props.tournamentInfo.regdateend && moment(moment.now()).isAfter(this.props.tournamentInfo.regdateend) && this.props.intl.formatMessage({
                  id: 'TournamentIsOver',
                  defaultMessage: 'Tournament is over'
                }))
                || (this.props.tournamentInfo.regdatestart && moment(moment.now()).isBefore(this.props.tournamentInfo.regdatestart) && this.props.intl.formatMessage({
                  id: 'TournamentTableStatusSoonStart',
                  defaultMessage: 'Starting soon'
                }))
                || this.props.intl.formatMessage({id: 'PlayGameButton', defaultMessage: 'Play'})
              }
            </div>
          </div>
        ) || <></>;
    }
    return (
      <div className="lobby__empty_container" style={{marginTop: '100px'}}>
        <div className="lobby__no_data_found">
          <div className="lobby__no_data_found__image">
            <img src={skittles} alt=""/>
          </div>
          <div className="lobby__no_data_found__title">
            {this.props.intl.formatMessage({
              id: 'GamesNotYetCreated',
              defaultMessage: 'Games not yeld created'
            })}
          </div>
          {btn}
        </div>
      </div>
    );
  }

  checkUserTournamentMember() {
    const {tournamentInfo, userid} = this.props;
    const {players = []} = tournamentInfo;
    const player = players.find(_player => _player.userid === userid);

    return player && player.isparticipant === 1;
  }

  addUserToTournament() {
    this.props.addUserToTournament({
      userid: this.props.userid,
      tournamentid: this.props.tournamentInfo.id,
      refetch: true,
    });
    this.setState({
      visibleConfirmRegisterModal: false,
      participationModal: true,
      rulesAgreement: false,
      feeAgreement: false,
      locationAgreement: false,
    });
  }

  render(record) {
    const {
      squadid,
      showConfirmation,
      showSaveBattleModal,
      owned,
      showGameResultsModal,
      selectedGame,
      setVideoArchiveSquadId,
      history,
    } = this.state;
    const {intl, tournamentInfo, user, setVisibilityBattle} = this.props;
    const tournamenttypeid = tournamentInfo.tournamenttypeid;
    const player = tournamentInfo && (tournamentInfo.players || []).find(_player => _player.userid === user.id);
    const isParticipant = player && player.isparticipant;
    const tournamentByInvite = ((tournamentInfo.settings || []).find(s => s.tournament_setting_brief === 'BY_INVITATION') || {}).value_bool;

    let columns = [];
    let canCreateBattle = false;

    switch (tournamenttypeid) {
      case 5:
        columns = this.getQualificationalColumns();
        canCreateBattle = false;
        break;
      case 3:
        columns = this.getQualificationalColumns();
        canCreateBattle = true;
        break;
      case 2:
      default:
        columns = this.getProvisionalColumns();
    }

    return (
      <div>
        <GameResultsModal
          intl={intl}
          visible={showGameResultsModal}
          tournament={selectedGame}
          onClose={() => {
            this.setState({showGameResultsModal: false});
            clearGameInfo();
          }}
          onOk={() => {
            this.play(selectedGame);
            this.props.history.push('/videoarchive');
          }}
        />
        {canCreateBattle ?
          <div className='createSquadWrapper'>
            {(this.props.competitions || []).length ? (
              <>
                {(tournamentInfo.regdatestart && moment(moment.now()).isBefore(tournamentInfo.regdatestart) && (
                  <Button
                    kind="aquamarine"
                  >
                    {intl.formatMessage({
                      id: 'TournamentTableStatusSoonStart',
                      defaultMessage: 'Starting soon'
                    })}
                  </Button>
                )) || (tournamentInfo.regdateend && moment(moment.now()).isAfter(tournamentInfo.regdateend) && (
                  <Button
                    kind="aquamarine"
                  >
                    {intl.formatMessage({
                      id: 'TournamentIsOver',
                      defaultMessage: 'Tournament is over'
                    })}
                  </Button>
                )) || (
                  (!user.is_adult && tournamentInfo.cost && <></>)
                  || (tournamentByInvite && !isParticipant && <></>)
                  || (
                    <Button
                      kind="aquamarine"
                      onClick={() => (this.checkUserTournamentMember()
                          ? this.setState({
                            participationModal: true,
                            rulesAgreement: false,
                            feeAgreement: false,
                            locationAgreement: false,
                          })
                          : this.setState({
                            visibleConfirmRegisterModal: true
                          })
                      )}
                    >
                      {intl.formatMessage({id: 'PlayGame', defaultMessage: 'PLay game'})}
                    </Button>
                  )
                )}
              </>
            ) : null}
            <OBModal
              title={'Squad creation'}
              visible={this.state.participationModal}
              width={395}
              onCancel={() => this.setState({participationModal: false})}
              onClose={() => this.setState({participationModal: false})}
              okText={intl.formatMessage({id: 'registration', defaultMessage: 'Registration'})}
              cancelText={intl.formatMessage({id: 'Play', defaultMessage: 'Play'})}
              className='email-verification-dialog'
              footer={<div style={{display: 'flex'}}>
                <Button
                  kind='aquamarine'
                  bordered='true'
                  onClick={() => this.setState({participationModal: false})}
                  className='dialog__button-decline'
                >
                  {intl.formatMessage({id: 'BattleCancelModalOkText', defaultMessage: 'Cancel'})}
                </Button>
                <Button
                  kind='aquamarine'
                  disabled={!this.state.rulesAgreement || !this.state.feeAgreement || !this.state.locationAgreement}
                  onClick={() => {
                    this.setState({
                      participationModal: false
                    }, () => this.props.createBattleForTournament({
                      tournamenttypeid, tournamentid: this.props.tournamentInfo.id
                    }))
                  }}
                  className='dialog__button-decline'
                >
                  {intl.formatMessage({id: 'Play', defaultMessage: 'Play'})}
                </Button>
              </div>}
            >
              <div className='tournament_agreements'>
                      <span className='tournament_explanation'>{intl.formatMessage({
                        id: 'SquadCreateAgreementSummary',
                        defaultMessage: 'By clicking Play button I confirm the following:'
                      })}</span>
                <Checkbox checked={this.state.rulesAgreement}
                          onChange={rulesAgreement => this.setState({rulesAgreement})}>
                  {intl.formatMessage({
                    id: 'SquadCreateAgreementRules',
                    defaultMessage: 'I agree with the tournament rules'
                  })}
                </Checkbox>
              </div>
              <div className='tournament_agreements'>
                <Checkbox checked={this.state.feeAgreement}
                          onChange={feeAgreement => this.setState({feeAgreement})}>
                  {intl.formatMessage({
                    id: 'SquadCreateAgreementPayment',
                    defaultMessage: 'I agree that the participation fee will be debited from my TenPinCam account'
                  })}
                </Checkbox>
              </div>
              <div className='tournament_agreements'>
                <Checkbox checked={this.state.locationAgreement}
                          onChange={locationAgreement => this.setState({locationAgreement})}>
                  {intl.formatMessage({
                    id: 'SquadCreateAgreementLocation',
                    defaultMessage: 'I’m at the TenPinCam enabled alley and ready to start the game immediately'
                  })}
                </Checkbox>
              </div>
            </OBModal>
          </div>
          : null}
        {(this.props.competitions || []).length
          ? (
            <Table
              className="competitionsTable"
              columns={columns}
              dataSource={this.props.competitions || []}
              pagination={false}
              rowKey="id"
              style={{width: '100%'}}
              locale={{emptyText: 'No competitions'}}
              rowClassName={'tournamentRow'}
            />
          ) : (
            <>{this.renderNotBasttles()}</>
          )
        }
        {
          showSaveBattleModal ?
            <ModalSaveBattle
              history={this.props.history}
              visible={showSaveBattleModal}
              squadid={squadid}
              owned={owned}
              onSubmit={() => {
                this.setState({
                  squadid: null,
                  owned: null,
                  showSaveBattleModal: false
                });
              }}
              onCancel={() => {
                this.setState({
                  squadid: null,
                  owned: null,
                  showSaveBattleModal: false
                });
              }}
            />
            : null
        }
        <ModalDialog
          visible={showConfirmation}
          title={intl.formatMessage({id: 'confimation', defaultMessage: 'Сonfimation'})}
          acceptButtonText={intl.formatMessage({id: 'Accept', defaultMessage: 'Accept'})}
          declineButtonText={intl.formatMessage({id: 'Decline', defaultMessage: 'Decline'})}
          onSubmit={this.deleteArchive}
          onClose={() => {
            this.setState({
              squadid: null,
              showConfirmation: false,
              action: false
            });
          }}
        ></ModalDialog>
        <OBModal
          visible={this.state.visibleConfirmRegisterModal}
          title={intl.formatMessage({
            id: 'TournamentInfoRegister',
            defaultMessage: 'Tournament registration'
          })}
          okText={intl.formatMessage({id: 'registration', defaultMessage: 'Registration'})}
          cancelText={intl.formatMessage({id: 'BattleCancelModalOkText', defaultMessage: 'Cancel'})}
          onOk={() => this.addUserToTournament()}
          onCancel={() => this.setState({visibleConfirmRegisterModal: false})}
          width={395}
        >
          <div className="confirm-modal__body">
            {intl.formatMessage({
              id: 'TournamentConfirmRegister',
              defaultMessage: 'Please, register for the Tournament, by clicking the Register button. The Registration is free, no fee will be applied.'
            })}
          </div>
        </OBModal>
      </div>
    );
  }
}

export default injectIntl(withRouter(
  connect(
    state => ({
      isAdmin: state.users.user.isadmin,
      admin: state.admin,
      userdisk: state.userdisk.data || {},
      tournaments: state.tournaments,
      tournamentInfo: state.tournaments.tournamentInfo,
      permissions: state.users.permissions ? state.users.permissions.keys : [],
      userid: state.users.user.id,
      gameCancelSocket: state.events.game.gameCancelSocket,
      user: state.users.user,
      changedVisibility: state.tournaments.tournamentInfo.battles
    }),
    {
      UserByIdFetch,
      fetchUserDisk,
      unbindVideoFilesOfUser,
      setVideoArchiveSquadId,
      requestTournamentInfo,
      CancelTournamentBattle,
      getPermissions,
      createBattleForTournament,
      setObservableSquad,
      setProtocolSquadId,
      editTournamentBattle,
      addUserToTournament,
      setVisibilityBattle,
      clearGameInfo,
    },
  )(CompetitionList),
));
