import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';

import OBDatePicker from '../../common/DatePicker';
import Select from '../../common/Select';
import { FilterInput } from '../../common/Input';
import ModalImageCrop from '../../common/modals/modalImageCrop';
import config from '../../../config';

const defaultAvatar = '/static/media/default.2d4c81eb.png';

class PersonalEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: { value: '', error: '' },
      lastName: { value: '', error: '' },
      countryLive: { value: {}, error: '' },
      countryFederation: { value: {}, error: '' },
      hourshift: { value: {}, error: '' },
      dob: { value: null, error: '' },
      photo: '',
      club: { value: '', error: '' },
      showModalImageCrop: false,
      userPhoto: { value: '', error: '' },
      userPhotoChanged: { value: '', error: '' },
    };
  }

  static propTypes = {
    userInfo: PropTypes.shape(),
    onPressSubmit: PropTypes.func,
    geodata: PropTypes.shape(),
    onPressCancel: PropTypes.func,
    intl: intlShape,
  };

  componentDidMount() {
    const { userInfo = {}, geodata, timezones, intl } = this.props;
    const {
      firstname,
      lastname,
      dob,
      hourshift,
      country_live: countryLive,
      country_federation: countryFederation,
      club = '',
      gender,
    } = userInfo;
    let country = '';
    let federation = '';
    let timezone = '';
    geodata.data.forEach((elem) => {
      if (elem.iso === countryLive) {
        country = { value: elem.iso, label: elem.name };
      }
      if (elem.iso === countryFederation) {
        federation = { value: elem.iso, label: elem.name };
      }
    });
    timezones.data.forEach((elem) => {
      if (elem.id === hourshift) {
        let str = `${elem.name}, `;
        if (elem.rawoffset === 0) {
          str = `${str}+00:00`;
        }
        if (elem.rawoffset > 0) {
          str = `${str}+${elem.rawoffset}`;
        }
        if (elem.rawoffset < 0) {
          str = `${str}${elem.rawoffset}`;
        }
        str = str.replace('.5', ':30');
        str = str.replace('.75', ':45');
        str = str.replace('.25', ':15');
        timezone = { value: elem.id, label: str };
      }
    });
    this.setState({
      firstName: {
        value: firstname,
        error: '',
      },
      lastName: {
        value: lastname,
        error: '',
      },
      dob: {
        value: moment(dob),
        error: '',
      },
      hourshift: {
        value: timezone,
        error: '',
      },
      countryLive: {
        value: country,
        error: '',
      },
      countryFederation: {
        value: federation,
        error: '',
      },
      club: {
        value: club,
        error: '',
      },
      gender: {
        value: gender,
        label: gender==='M'?intl.formatMessage({ id: 'male', defaultMessage: 'M' }):intl.formatMessage({ id: 'female', defaultMessage: 'F' }),
      }
    });
  }

  _firstnameChange = (e) => {
    this.setState({ firstName: { value: e.target.value, error: '' } });
  };

  _lastnameChange = (e) => {
    this.setState({ lastName: { value: e.target.value, error: '' } });
  };

  _dateChange = (date) => {
    this.setState({ dob: { value: date, error: '' } });
  };

  _countryLiveChange = (e) => {
    this.setState({ countryLive: { value: e, error: '' } });
  };

  _hourshiftChange = (e) => {
    this.setState({ hourshift: { value: e, error: '' } });
  };

  _countryFederationChange = (e) => {
    this.setState({ countryFederation: { value: e, error: '' } });
  };

  _clubChange = (e) => {
    const { value = '' } = e.target;
    this.setState({ club: { value, error: value.trim() ? '' : 'empty' } });
  };

  _avatarChange = () => {
    const { userPhoto } = this.state;
    if (userPhoto.value) {
      this.setState({
        showModalImageCrop: true,
      });
    } else {
      this.filePickerRef.click();
    }
  };

  imageChange = (e) => {
    const { intl } = this.props;
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file.size > 10000000) {
      this.setState({
        userPhoto: {
          ...this.state.userPhoto,
          error: intl.formatMessage({ id: 'TooBigFile', defaultMessage: 'Uploaded image is too big. File size cannot exceed 10Mb.' }),
        },
        file: '',
      });
    } else {
      reader.onloadend = () => {
        this.setState(
          {
            userPhoto: {
              error: '',
              value: reader.result,
            },
            file,
          },
          () => {
            this.setState({
              showModalImageCrop: true,
            });
          },
        );
      };
      reader.readAsDataURL(file);
    }
  };

  _submit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { onPressSubmit } = this.props;
    const {
      firstName,
      lastName,
      dob,
      hourshift,
      countryLive,
      countryFederation,
      club,
      userPhotoChanged,
      gender,
    } = this.state;
    if (!gender || !gender.value) {
      this.setState({ genderError: true })
    }
    if (
      !firstName.error
      && !lastName.error
      && !dob.error
      && !hourshift.error
      && !countryLive.error
      && !countryFederation.error
      && !club.error
      && gender && gender.value
    ) {
      onPressSubmit({
        firstName: firstName.value,
        lastName: lastName.value,
        dob: dob.value,
        hourShift: hourshift.value,
        countryLive: countryLive.value,
        countryFederation: countryFederation.value,
        club: club.value,
        userPhoto: userPhotoChanged.value || null,
        gender: gender.value,
      });
    } else {
      console.warn('inValid');
    }
  };

  render() {
    const {
      userInfo = {}, geodata = {}, timezones, intl,
    } = this.props;
    const {
      login, firstname, lastname, gender, photo: propsPhoto,
    } = userInfo;
    const {
      firstName,
      lastName,
      dob,
      hourshift,
      countryLive,
      countryFederation,
      club,
      showModalImageCrop,
      userPhoto,
      userPhotoChanged,
    } = this.state;

    const arrTimeZones = [];
    timezones.data.forEach((elem) => {
      let str = `${elem.name}, `;
      if (elem.rawoffset === 0) {
        str = `${str}+00:00`;
      }
      if (elem.rawoffset > 0) {
        str = `${str}+${elem.rawoffset}`;
      }
      if (elem.rawoffset < 0) {
        str = `${str}${elem.rawoffset}`;
      }
      str = str.replace('.5', ':30');
      str = str.replace('.75', ':45');
      str = str.replace('.25', ':15');
      arrTimeZones.push({ value: elem.id, label: str });
    });
    return [
      <ModalImageCrop
        key={'imagePickerModal'}
        visible={showModalImageCrop}
        image={userPhoto.value}
        onSubmit={(image) => {
          this.setState({
            userPhotoChanged: {
              value: image,
            },
            showModalImageCrop: false,
            file: '',
          });
        }}
        onClose={() => {
          this.setState({
            showModalImageCrop: false,
          });
        }}
      />,
      <div className="personal-outlet" key="personalOutlet">
        <div className="personal-outlet__header">
          <div className="user-info">
            <div className="user-info__avatar">
              <div className="avatar__wrap">
                <img
                  src={
                    userPhotoChanged.value
                    || (propsPhoto ? `${config.FETCH.img}/${propsPhoto}?v=${Math.random()}` : defaultAvatar)
                  }
                  alt=""
                />
              </div>
              <div className="change-avatar">
                <input
                  className="postregpage__fileinput"
                  type="file"
                  ref={(c) => {
                    this.filePickerRef = c;
                  }}
                  onChange={(e) => {
                    this.imageChange(e);
                  }}
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                />
                <div className="half-circle">
                  <span onClick={this._avatarChange}>{intl.formatMessage({ id: 'EditAsChangeAlias', defaultMessage: 'Edit' })}</span>
                </div>
              </div>
            </div>
            <div className="user-info__text">
              <div className="text_full-name">
                <span>{`${firstname} ${lastname}`}</span>
              </div>
              <div className="display_name">
                <span>{login}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="personal-outlet__body">
          <div className="user-data user-data_editable">
            <form action="" className="form" onSubmit={this._submit}>
              <div className="form__group">
                <label className="form__label" htmlFor="">
                  {intl.formatMessage({ id: 'Name', defaultMessage: 'Name' })}
                </label>
                <div className="form__control">
                  <FilterInput placeholder={intl.formatMessage({ id: 'Name', defaultMessage: 'Name' })} value={firstName.value} onChange={this._firstnameChange} />
                </div>
              </div>
              <div className="form__group">
                <label className="form__label" htmlFor="">
                  {intl.formatMessage({ id: 'Surname', defaultMessage: 'Surname' })}
                </label>
                <div className="form__control">
                  <FilterInput placeholder={intl.formatMessage({ id: 'Surname', defaultMessage: 'Surname' })} value={lastName.value} onChange={this._lastnameChange} />
                </div>
              </div>
              <div className="form__group">
                <label className="form__label" htmlFor="">
                  {intl.formatMessage({ id: 'FriendRouterFilterGender', defaultMessage: 'Gender' })}
                </label>
                <div className="form__control">
                  <Select
                    isSearchable
                    placeholder={intl.formatMessage({ id: 'FriendRouterFilterGender', defaultMessage: 'Gender' })}
                    value={gender.value}
                    options={[{value: 'F', label: intl.formatMessage({ id: 'female', defaultMessage: 'F' })},{value: 'M', label: intl.formatMessage({ id: 'male', defaultMessage: 'M' })}]}
                    onChange={gender => this.setState({ gender })}
                    className={`${this.state.genderError?'erroredform':null}`}
                  />
                </div>
              </div>
              <div className="form__group">
                <label className="form__label" htmlFor="">
                  {intl.formatMessage({ id: 'PersonalInfoDOB', defaultMessage: 'Date of birth' })}
                </label>
                <div className="form__control">
                  <OBDatePicker
                    icon
                    className="date-group__elements__picker"
                    placeholder={intl.formatMessage({ id: 'date', defaultMessage: 'Date' })}
                    value={dob.value}
                    onChange={this._dateChange}
                    dateFormat="D MMM YYYY"
                  />
                </div>
              </div>
              <div className="form__group">
                <label className="form__label" htmlFor="">
                  {intl.formatMessage({ id: 'PersonalInfoCountryLiving', defaultMessage: 'Country living' })}
                </label>
                <div className="form__control">
                  <Select
                    isSearchable
                    placeholder="Country live"
                    value={countryLive.value}
                    options={_.map(geodata.data, item => ({ value: item.iso, label: item.name }))}
                    onChange={this._countryLiveChange}
                  />
                </div>
              </div>
              <div className="form__group">
                <label className="form__label" htmlFor="">
                  {intl.formatMessage({ id: 'PersonalInfoTimeZone', defaultMessage: 'Timezone' })}
                </label>
                <div className="form__control">
                  <Select
                    isSearchable
                    placeholder={intl.formatMessage({ id: 'PersonalInfoTimeZone', defaultMessage: 'Timezone' })}
                    value={hourshift.value}
                    options={arrTimeZones}
                    onChange={this._hourshiftChange}
                  />
                </div>
              </div>
              <div className="form__group">
                <label className="form__label" htmlFor="">
                  {intl.formatMessage({ id: 'PersonalInfoFederationCountry', defaultMessage: 'Federation country' })}
                </label>
                <div className="form__control">
                  <Select
                    isSearchable
                    placeholder={intl.formatMessage({ id: 'PersonalInfoFederationCountry', defaultMessage: 'Federation country' })}
                    value={countryFederation.value}
                    options={_.map(geodata.data, item => ({ value: item.iso, label: item.name }))}
                    onChange={this._countryFederationChange}
                  />
                </div>
              </div>
              <div className="form__group">
                <label className="form__label" htmlFor="">
                  {intl.formatMessage({ id: 'PersonalInfoClub', defaultMessage: 'Club' })}
                </label>
                <div className="form__control">
                  <FilterInput placeholder={intl.formatMessage({ id: 'PersonalInfoClub', defaultMessage: 'Club' })} value={club.value} onChange={this._clubChange} />
                </div>
              </div>
              <div className="form__group">
                <button
                  className="row__button form__button_submit1"
                  onClick={() => {
                    this.props.onPressCancel();
                  }}
                >
                  {intl.formatMessage({ id: 'CancelVerbal', defaultMessage: 'Cancel' })}
                </button>
                <button className="row__button form__button_submit" type="submit">
                  {intl.formatMessage({ id: 'SaveAsKeepAlias', defaultMessage: 'Save' })}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>,
    ];
  }
}

export default injectIntl(PersonalEdit);
