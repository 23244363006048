/* eslint-disable import/prefer-default-export */
import React from 'react';
import moment from 'moment';
import RenderUserCard from '../../../common/UserCardTable';
import TariffMarketData from '../ui/tarifMarketData.jsx';

function transformDate(date) {
  return moment(date).format('DD.MM.YYYY');
}

export function getDataSource(list) {
  return list.map(item => {
    const user = item.user ? item.user : { id: null, login: null };
    return {
      userid: user.id,
      username: <RenderUserCard user={user} withLogin={false} />,
      login: user.login,
      tariff_name: item.tariff.name,
      created: transformDate(item.created),
      expires: transformDate(item.expires),
      kind: item.kind ? item.kind : <TariffMarketData item={item} />,
      key: item.id,
    };
  });
}

// const RenderHeader = ({ title }) => <span>{title}</span>;
