/* eslint-disable arrow-parens */
/* eslint-disable consistent-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import OBModal from '../Modal';
import PersonalDataAddComponent from './personal-data-add.component.jsx';
import ModalFooter from './fragments/modal-footer.fragment.jsx';
import { validatePersonalData } from './helpers/validate-personal-data';
import sanitizePersonalData from './helpers/sanitize-personal-data';
import ConfirmationModal from './confimation.modal.jsx';

/**
 * @typedef {import('./personal-data-add.fragment.jsx').IPersonalData} IPersonalData
 * @typedef {import('./personal-data-add.fragment.jsx').PersonalDataAddFragmentProps} AddPersonalDataFragmentProps
 */
/**
 * @typedef {{
 *    isVisible?: boolean;
 *    onCancel?: () => any;
 *    onAdd?: (data: IPersonalData) => any;
 *    setRef?: (ref: { reset: () => void }) => any;
 *    resetOnClose?: boolean;
 *  }
 *  & Omit<AddPersonalDataFragmentProps, 'countries' | 'intl' | 'errors'>
 * } PersonalDataAddModalProps
 */
/**
 * @type {React.FC<PersonalDataAddModalProps>}
 */
const PersonalDataAddModalFn = props => {
  const {
    isVisible, onCancel, intl, onAdd, onChange, onChangeProp, countries, setRef, resetOnClose,
  } = props;
  const {
    firstName, lastName, countryId, dob, personalId, province, postcode, address,
  } = props;
  const [personalData, setPersonalData] = useState({
    firstName, lastName, countryId, dob, personalId, province, postcode, address,
  });
  const [errors, setErrors] = useState({});
  const [isVisibleConfirmation, setIsVisibleConfirmation] = useState(false);
  /** @type {React.MutableRefObject<{ reset: () => void }>} */
  const ref = useRef();
  const noop = () => null;
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange || noop;
  useEffect(() => {
    onChangeRef.current(personalData);
  }, [personalData]);
  const onBeforeClose = () => {
    setIsVisibleConfirmation(true);
  };
  const onClose = () => {
    if (errors) {
      setErrors(null);
    }
    if (onCancel) {
      onCancel();
    }
    if (resetOnClose && ref.current && typeof ref.current.reset === 'function') {
      ref.current.reset();
    }
  };
  const title = intl.formatMessage({ id: 'PleaseEnterYourData', defaultMessage: 'Please enter your data' });
  return <>
    <ConfirmationModal
      title={title}
      isVisible={isVisibleConfirmation}
      onConfirm={() => {
        setIsVisibleConfirmation(false);
        onClose();
      }}
      onReject={() => {
        setIsVisibleConfirmation(false);
      }}
    />
    <OBModal
      title={title}
      visible={isVisible}
      onSubmit={noop}
      onCancel={onBeforeClose}
      footer={[
        <ModalFooter
          key={'footer'}
          onCancel={onBeforeClose}
          onAccept={() => {
            const errors = validatePersonalData(personalData, { countries });
            const isValid = Object.keys(errors).every(key => !errors[key]);
            if (!isValid) {
              setErrors(errors);
              return;
            }
            if (onAdd) {
              onAdd(sanitizePersonalData(personalData, countries));
            }
          }}
          intl={intl}
        />,
      ]}
    >
      <PersonalDataAddComponent
        setRef={(inst) => {
          ref.current = inst;
          if (typeof setRef === 'function') {
            setRef(inst);
          }
        }}
        firstName={firstName}
        lastName={lastName}
        countryId={countryId}
        dob={dob}
        personalId={personalId}
        province={province}
        postcode={postcode}
        address={address}
        onChange={setPersonalData}
        onChangeProp={onChangeProp}
        errors={errors}
      />
    </OBModal>
  </>;
};

class PersonalDataAddModalClass extends React.Component {
  render() {
    return <PersonalDataAddModalFn {...this.props} />;
  }
}

/** @type {typeof PersonalDataAddModalFn} */
const PersonalDataAddModal = injectIntl(
  connect(
    state => ({
      countries: (state.common.geodata || {}).data || [],
    }),
    {},
  )(PersonalDataAddModalClass),
);

export default PersonalDataAddModal;
