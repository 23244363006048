import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl, intlShape} from 'react-intl';

import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import OBModal from '../../Modal';
import {Button} from '../../Button';
import Slider from '../../Slider';

import './style.scss';


class ModalImageCrop extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    image: PropTypes.string,
    intl: intlShape,
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: props.visible,
      position: { x: 0.5, y: 0.5 },
      scale: 1,
      rotate: 0,
      width: 355,
      height: 355,
      image: '',
    };
    this.filePickerRef = null;
  }

  static defaultProps = {
    onSubmit: () => {},
    onClose: () => {},
    image: '',
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = {
      ...prevState,
    };
    if (!prevState.image && prevState.image !== nextProps.image) {
      state = {
        ...state,
        image: nextProps.image,
      };
    }
    return state;
  }

  // componentDidMount() {
  //   const { image } = this.props
  //   console.log(image);
  //   this.setState({ image });
  // }

  setRef = (c) => {
    this.filePickerRef = c;
  };

  handleNewImage = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file.size > 10000000) {
      console.log('To large file');
    } else {
      reader.onloadend = () => {
        this.setState(
          {
            image: reader.result,
            changed: true,
          },
          () => {
            console.log(reader.result);
            console.log(this.state);
          },
        );
      };
      reader.readAsDataURL(file);
    }
  };

  handleScale = (e) => {
    const scale = parseFloat(e.target.value);
    this.setState({ scale });
  };

  handleAllowZoomOut = ({ target: { checked: allowZoomOut } }) => {
    this.setState({ allowZoomOut });
  };

  rotateLeft = (e) => {
    e.preventDefault();

    this.setState({
      rotate: this.state.rotate - 90,
    });
  };

  rotateRight = (e) => {
    e.preventDefault();
    this.setState({
      rotate: this.state.rotate + 90,
    });
  };

  handleXPosition = (e) => {
    const x = parseFloat(e.target.value);
    this.setState({ position: { ...this.state.position, x } });
  };

  handleYPosition = (e) => {
    const y = parseFloat(e.target.value);
    this.setState({ position: { ...this.state.position, y } });
  };

  setEditorRef = (editor) => {
    if (editor) this.editor = editor;
  };

  handlePositionChange = (position) => {
    this.setState({ position });
  };

  handleDrop = (acceptedFiles) => {
    this.setState({ image: acceptedFiles[0] });
  };

  showModal = () => this.setState({ visible: true });

  handleOk = () => {
    const { onSubmit } = this.props;
    const img = this.editor.getImageScaledToCanvas().toDataURL();
    this.setState(
      {
        // position: { x: 0.5, y: 0.5 },
        // scale: 1,
        // rotate: 0,
        // width: 355,
        // height: 355,
        // image: img,
        changed: false,
      },
      () => {
        onSubmit(img);
      },
    );
  };

  handleCancel = () => {
    const { onClose } = this.props;
    this.setState(
      {
        // position: { x: 0.5, y: 0.5 },
        // scale: 1,
        // rotate: 0,
        // width: 355,
        // height: 355,
        image: '',
        changed: false,
      },
      () => {
        onClose();
      },
    );
  };

  onChangeZoom = (value) => {
    this.setState({ scale: value });
  };

  render() {
    const {
      scale, position, rotate, image,
    } = this.state;

    const { visible, intl } = this.props;

    const Crop = (
      <div key="message" className="image-crop">
        <Dropzone className="image-crop__dropzone" onDrop={this.handleDrop} disableClick multiple={false}>
          <div>
            <AvatarEditor
              ref={this.setEditorRef}
              image={image}
              className="image-crop__editor"
              scale={parseFloat(scale)}
              width={305}
              height={305}
              position={position}
              onPositionChange={this.handlePositionChange}
              rotate={parseFloat(rotate)}
              borderRadius={152.5}
            />
          </div>
        </Dropzone>
        <div className="image-crop__params">
          <div className="image-crop__new-image">
            <div className="image-crop__header">{intl.formatMessage({ id: 'NewFile', defaultMessage: 'New File' })}</div>
            <div className="image-crop__input">
              <div />
              <Button
                size="xs"
                onClick={(e) => {
                  e.preventDefault();
                  if (this.filePickerRef) {
                    this.filePickerRef.click();
                  }
                }}
              >
                {intl.formatMessage({ id: 'Load', defaultMessage: 'Load' })}
              </Button>
              <input
                ref={this.setRef}
                name="new-image"
                type="file"
                onChange={this.handleNewImage}
                onClick={(e) => {
                  e.target.value = null;
                }}
              />
            </div>
          </div>
          <div className="image-crop__zoom">
            <div className="image-crop__header">{intl.formatMessage({ id: 'Zoom', defaultMessage: 'Zoom' })}</div>
            <div className="image-crop__input">
              <Slider
                range
                value={[scale]}
                defaultValue={[1]}
                min={0.2}
                step={0.01}
                max={10}
                onChange={this.onChangeZoom}
                tooltipVisible
              />
            </div>
          </div>
          <div className="image-crop__rotate">
            <div className="image-crop__header">{intl.formatMessage({ id: 'RotateImage', defaultMessage: 'Rotate' })}</div>
            <div className="image-crop__input">
              <Button size="xs" onClick={this.rotateLeft}>
                {intl.formatMessage({ id: 'LeftRotate', defaultMessage: 'Left' })}
              </Button>
              <Button size="xs" onClick={this.rotateRight}>
                {intl.formatMessage({ id: 'RightRotate', defaultMessage: 'Right' })}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
    const groups = [Crop];

    return (
      <OBModal
        title={intl.formatMessage({ id: 'AvatarImageCrop', defaultMessage: 'Avator crop' })}
        visible={visible}
        width={395}
        onOk={this.handleOk}
        closable={false}
        className="image-crop"
        footer={[
          <div key="buttons" className="image-crop__buttons">
            <Button
              kind="aquamarine"
              bordered="true"
              onClick={this.handleCancel}
              className="image-crop__button-decline"
            >
              {intl.formatMessage({ id: 'DeclineImageCrop', defaultMessage: 'Decline' })}
            </Button>
            <Button onClick={this.handleOk} kind="aquamarine" className="image-crop__button-accept">
              {intl.formatMessage({ id: 'AcceptImageCrop', defaultMessage: 'Accept' })}
            </Button>
          </div>,
        ]}
      >
        {groups}
      </OBModal>
    );
  }
}

export default injectIntl(ModalImageCrop);
