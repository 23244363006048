import React from 'react';

function Article({
  id,
  title,
  subtitle,
  color,
  children,
  className = '',
}) {
  let colorScheme = 'dark';
  if (color === 'orange') {
    colorScheme = 'orange';
  }
  return (
    <div className={`article ${colorScheme} ${className}`}>
      <div className='article-content'>
        {title && (
          <h2 id={id} className='title' data-toc={title}>
            {title}
            <span className='subtitle'>
              {subtitle}
            </span>
          </h2>
        )}
        {children}
      </div>
    </div>
  );
}

export default Article;
