import React, { Component } from "react";
import benefits from "./images/benefits.png";
import "./style.scss";
import "./adaptive.scss";
import utils from "../../libs/utils";

import player1 from './images/reviews/player-1.jpg';
import player2 from './images/reviews/player-2.jpg';
import player3 from './images/reviews/player-3.jpg';

export default function Reviews({intl}) {
  return (
    <div className="landing__container">
      <div className="reviews">
        <h2>{intl.formatMessage({
          id: 'LandingReviewsTitle',
          defaultMessage: 'Reviews',
        })}</h2>
        <div className="container">
          <div className="reviews__blocks">
            <div className="reviews__block">
              <div className="r-header">
                <div className="r-header__avatar">
                  <div className="r-header__img">
                    <img src={player1} alt="Grant Dugan" />
                  </div>
                  <span className="r-header__flag r-header__flag_gb"/>
                </div>
                <div className="r-header__name">
                  <p>Grant</p>
                  <p>Dugan</p>
                </div>
              </div>
              <div className="reviews__text">
                "{intl.formatMessage({
                  id: 'LandingReviews1',
                  defaultMessage: 'TenPinCam is a welcome revolution to add a new dimension to the game. I was delighted to be approached for the initial testing of the roll-out of the concept, the system has many capabilities and possibilities for both the recreational and competitive bowler.'
                })}"
              </div>
            </div>
            <div className="reviews__block">
              <div className="r-header">
                <div className="r-header__avatar">
                  <div className="r-header__img">
                    <img src={player3} alt="Grant Dugan" />
                  </div>
                  <span className="r-header__flag r-header__flag_gr"/>
                </div>
                <div className="r-header__name">
                  <p>Martina</p>
                  <p>Schuetz</p>
                </div>
              </div>
              <div className="reviews__text">
                "{intl.formatMessage({
                  id: 'LandingReviews2',
                  defaultMessage: 'The concept of TenPinCam is giving you exctly that with one big plus. The main player is not someone holding a phone or a joystick controlling a character in a virtual environment! The actual player is you in a real environment and only the type and concept of competition is virtual. An amazing idea that now with the pandemic with the real competitions not being as many it’s a great opportunity to stay active and competitive!',
                })}"
              </div>
            </div>
            <div className="reviews__block">
              <div className="r-header">
                <div className="r-header__avatar">
                  <div className="r-header__img">
                    <img src={player2} alt="Grant Dugan" />
                  </div>
                  <span className="r-header__flag r-header__flag_gb"/>
                </div>
                <div className="r-header__name">
                  <p>Callum</p>
                  <p>Simons</p>
                </div>
              </div>
              <div className="reviews__text">
                "{intl.formatMessage({
                  id: 'LandingReviews3',
                  defaultMessage: 'TenPinCam is a fantastic concept, and has been executed very well! A few more small refinements and it may be the future of day to day competition. Most tournaments don’t pay out what it costs to play them after travel, hotes etc. This removes the cost without the loss of competition! It really has brought smaller competition levl bowling to the masses! I’m very excited to see where this goes and I’ll be there the whole way.'
                })}"
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

