import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Avatar from '../common/Avatar';
import config from '../../config';
import defaultAvatar from '../../images/defaultAvatar.png';

import Flag from '../common/Flag';

import {
  UserByIdFetch,
  addUserToTournament,
  deleteUserFromTournament,
  getPermissions,
  addReplayToPlayer,
  GameLeave,
  removePlayerRemovedInfo,
  postRemovedPlayerInfo,
} from '../../libs/reducers/actions';
import { Button } from '../common/Button';
import './style.scss';
import { ReactComponent as SvgCountry } from '../../images/country.svg';
import { ReactComponent as SvgGender } from '../../images/gender.svg';
import ButtonRemovePlayer from "../btnRemovePlayerFromTournament/ButtonRemovePlayer";

class CompetitorList extends React.Component {
  static propTypes = {
    competitors: PropTypes.array,
    competitorFilters: PropTypes.array,
    intl: intlShape,
  };

  componentDidMount() {
    const {getPermissions, removePlayerRemovedInfo} = this.props
    getPermissions();
    removePlayerRemovedInfo();
  }

  getProvisionalColumns = () => {
    return [
      {
        title: '#',
        dataIndex: '',
        render: (data, record, index) => {
          return {
            props: {},
            children:(<span className={`playerNumber ${index}`}>{index + 1}</span>)
          }
        }
      },
      {
        title: this.props.intl.formatMessage({ id: 'FullnameTableColumn', defaultMessage: 'Fullname' }),
        dataIndex: '',
        width: 214,
        render: (data, record, index) => {
          return {
            props: {},
            children: (<>
                <div className="username__container">
                  <Avatar
                    uri={record.photo ? `${config.FETCH.img}/${record.photo}` : defaultAvatar}
                    flag={null}
                    tabled
                    isOnline={record.active}
                  />
                  {`${record.firstname} ${record.lastname}`.trim() || '-'}
                </div>
            </>),
          }
        },
      },
      {
        title: this.props.intl.formatMessage({ id: 'LoginTableColumn', defaultMessage: 'Login' }),
        dataIndex: '',
        width: 102,
        render: (data, record, index) => {
          return {
            props: {},
            children: (<>
              <div className="participant__login"><Link to="#" onClick={() => {this.props.UserByIdFetch({userid: record.userid})}}>{record.login}</Link></div>
            </>),
          }
        }
      },
      {
        title: <>
          <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg" style={{
            marginTop: 3}}>
            <path d="M10 1.04704L12.6699 5.95934L12.7836 6.16865L13.0179 6.21216L18.5148 7.23339L14.6679 11.2906L14.504 11.4634L14.535 11.6996L15.2624 17.2431L10.2151 14.8383L10 14.7358L9.78494 14.8383L4.73759 17.2431L5.46498 11.6996L5.49598 11.4634L5.33207 11.2906L1.48523 7.23339L6.98214 6.21216L7.21636 6.16865L7.33012 5.95934L10 1.04704Z" stroke="#818589"/>
          </svg>
        </>,
        width: 62,
        dataIndex: 'rating',
        sorter: (a, b) => ((a.rating==b.rating) ? 0:(a.rating-b.rating)/Math.abs(a.rating-b.rating)),
        render: (data, record, index) => {
          return {
            props: {},
            children: (<>
              {data ?
                <div className='ratingWrap'><svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 0L12.9389 5.95492L19.5106 6.90983L14.7553 11.5451L15.8779 18.0902L10 15L4.12215 18.0902L5.24472 11.5451L0.489435 6.90983L7.06107 5.95492L10 0Z" fill="#FFB31E"/>
                </svg>
                {data}</div>
                :
                <div className='ratingWrap'><svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 1.12978L12.4906 6.1762L12.6069 6.41192L12.867 6.44972L18.4361 7.25895L14.4063 11.187L14.218 11.3705L14.2625 11.6296L15.2138 17.1762L10.2327 14.5574L10 14.4351L9.76733 14.5574L4.78621 17.1762L5.73752 11.6296L5.78196 11.3705L5.59372 11.187L1.56392 7.25895L7.13297 6.44972L7.39311 6.41192L7.50944 6.1762L10 1.12978Z" stroke="#FFB31E"/>
                </svg>
                0</div>
              }
            </>),
          }
        }
      },
      {
        title: this.props.intl.formatMessage({ id: 'TournamentBattlesPlayed', defaultMessage: 'Battle Played' }),
        dataIndex: 'battlesplayed',
        className: 'rightPinned',
        width: 78,
        sorter: (a, b) => ((a.battlesplayed==b.battlesplayed) ? 0:(a.battlesplayed-b.battlesplayed)/Math.abs(a.battlesplayed-b.battlesplayed)),
        render: (data, record, index) => {
          return {
            props: {},
            children: (<>{record.hasOwnProperty('battlesplayed') ? record.battlesplayed : '-'}</>),
          }
        }
      },
      {
        title: this.props.intl.formatMessage({ id: 'TournamentAvgScore', defaultMessage: 'Average game' }),
        dataIndex: 'avgscore',
        className: 'rightPinned',
        width: 96,
      },
      {
        title: <SvgCountry />,
        dataIndex: 'creatorcountry',
        width: 50,
        render: (data, record, index) => ({
          children: (
            <div className="battles__flag">
              <Flag name={record.country} />
            </div>
          ),
          props: {
          },
        }),
        sorter: (a, b) => (a.country_live || a.country).localeCompare(b.country_live || b.country),
      },
        {
        title: <SvgGender />,
        dataIndex: 'gender',
        width: 47,
      },
      {
        title: '',
        dataIndex: '',
        render: (data, record, index) => {
          const onClick = () => {
            this.props.postRemovedPlayerInfo({userid: record.userid, tournamentid: this.props.tournamentid})
          }
          return {
            props: {},
            children: (<>
              { this.props.permissions.includes('TOURNAMENTS_MANAGE_PLAYERS') && record.isparticipant ?
                  <ButtonRemovePlayer onClick={onClick}/>
                  :
                  <Button kind="aquamarine" onClick={() => {this.props.addUserToTournament({userid: record.userid, tournamentid: this.props.tournamentid})}}>{this.props.intl.formatMessage({ id: 'TournamentAddPlayer', defaultMessage: 'Add Player' })}</Button>
                 }
            </>),
          }
        }
      },
    ];
  }

  getQualificationalColumns = () => {
    return [
      {
        title: '#',
        dataIndex: '',
        render: (data, record, index) => {
          return {
            props: {},
            children:(<span className={`playerNumber ${index}`}>{index + 1}</span>)
          }
        }
      },
      {
        title: this.props.intl.formatMessage({ id: 'FullnameTableColumn', defaultMessage: 'Fullname' }),
        dataIndex: '',
        width: 214,
        ellipsis: true,
        render: (data, record, index) => {
          return {
            props: {},
            children: (<>
                <div className="username__container">
                  <Avatar
                    uri={record.photo ? `${config.FETCH.img}/${record.photo}` : defaultAvatar}
                    flag={null}
                    tabled
                    isOnline={record.active}
                  />
                  {`${record.firstname} ${record.lastname}`.trim() || '-'}
                </div>
            </>),
          }
        },
      },
      {
        title: this.props.intl.formatMessage({ id: 'LoginTableColumn', defaultMessage: 'Login' }),
        dataIndex: '',
        width: 102,
        render: (data, record, index) => {
          return {
            props: {},
            children: (<>
              <div className="participant__login"><Link to="#" onClick={() => {this.props.UserByIdFetch({userid: record.userid})}}>{record.login}</Link></div>
            </>),
          }
        }
      },
      {
        title: <>
          <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg" style={{
            marginTop: 3}}>
            <path d="M10 1.04704L12.6699 5.95934L12.7836 6.16865L13.0179 6.21216L18.5148 7.23339L14.6679 11.2906L14.504 11.4634L14.535 11.6996L15.2624 17.2431L10.2151 14.8383L10 14.7358L9.78494 14.8383L4.73759 17.2431L5.46498 11.6996L5.49598 11.4634L5.33207 11.2906L1.48523 7.23339L6.98214 6.21216L7.21636 6.16865L7.33012 5.95934L10 1.04704Z" stroke="#818589"/>
          </svg>
        </>,
        width: 62,
        dataIndex: 'rating',
        sorter: (a, b) => ((a.rating==b.rating) ? 0:(a.rating-b.rating)/Math.abs(a.rating-b.rating)),
        render: (data, record, index) => {
          return {
            props: {},
            children: (<>
              {data ?
                <div className='ratingWrap'><svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 0L12.9389 5.95492L19.5106 6.90983L14.7553 11.5451L15.8779 18.0902L10 15L4.12215 18.0902L5.24472 11.5451L0.489435 6.90983L7.06107 5.95492L10 0Z" fill="#FFB31E"/>
                </svg>
                {data}</div>
                :
                <div className='ratingWrap'><svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 1.12978L12.4906 6.1762L12.6069 6.41192L12.867 6.44972L18.4361 7.25895L14.4063 11.187L14.218 11.3705L14.2625 11.6296L15.2138 17.1762L10.2327 14.5574L10 14.4351L9.76733 14.5574L4.78621 17.1762L5.73752 11.6296L5.78196 11.3705L5.59372 11.187L1.56392 7.25895L7.13297 6.44972L7.39311 6.41192L7.50944 6.1762L10 1.12978Z" stroke="#FFB31E"/>
                </svg>
                0</div>
              }
            </>),
          }
        }
      },
      {
        title: this.props.intl.formatMessage({ id: 'TournamentBattlesPlayed', defaultMessage: 'Battle Played' }),
        dataIndex: 'battlesplayed',
        className: 'rightPinned',
        width: 78,
        sorter: (a, b) => ((a.battlesplayed==b.battlesplayed) ? 0:(a.battlesplayed-b.battlesplayed)/Math.abs(a.battlesplayed-b.battlesplayed)),
        render: (data, record, index) => {
          return {
            props: {},
            children: (<>{record.hasOwnProperty('battlesplayed') ? record.battlesplayed : '-'}</>),
          }
        }
      },
      {
        title: this.props.intl.formatMessage({ id: 'TournamentAvgScore', defaultMessage: 'Average game' }),
        dataIndex: 'avgscore',
        className: 'rightPinned',
        width: 96,
      },
      {
        title: <SvgCountry />,
        width: 50,
        dataIndex: 'creatorcountry',
        render: (data, record, index) => ({
          children: (
            <div className="battles__flag">
              <Flag name={record.country} />
            </div>
          ),
          props: {
          },
        }),
        sorter: (a, b) => a.creatorcountry.localeCompare(b.creatorcountry),
      },
        {
        title: <SvgGender />,
        dataIndex: 'gender',
        width: 47,
      },
      {
        title: '',
        dataIndex: '',
        render: (data, record, index) => {
          return {
            props: {},
            children: (() => {
              const { count_squads, can_replay } = record;
              const { settings } = this.props.tournamentInfo;
              const countReplays = (settings.find(s => s.tournament_setting_brief === 'COUNT_REPLAYS') || {}).value_int;

              return (<>
                { this.props.permissions.includes('TOURNAMENTS_MANAGE_PLAYERS') ? record.isparticipant ? (
                  <div className="participant-actions">
                    <Button kind="aquamarine" bordered="true" onClick={() => {this.props.deleteUserFromTournament({userid: record.userid, tournamentid: this.props.tournamentid})}}>
                      {this.props.intl.formatMessage({ id: 'TournamentDeletePlayer', defaultMessage: 'Delete player' })}
                    </Button>
                    {(+count_squads >= +countReplays && !can_replay && (
                      <Button className="add-replay" kind="aquamarine" onClick={() => this.props.addReplayToPlayer({ playerid: record.playerid, canReplay: true })}>
                        {this.props.intl.formatMessage({ id: 'AddReplayTournament', defaultMessage: 'Add replay' })}
                      </Button>
                    )) || (+count_squads >= +countReplays && can_replay && (
                      <Button bordered="true" className="add-replay" kind="aquamarine" onClick={() => this.props.addReplayToPlayer({ playerid: record.playerid, canReplay: false })}>
                        {this.props.intl.formatMessage({ id: 'RemoveReplayTournament', defaultMessage: 'Remove replay' })}
                      </Button>
                    )) || <></>}

                  </div>
                ) : (
                      <Button kind="aquamarine" onClick={() => {this.props.addUserToTournament({userid: record.userid, tournamentid: this.props.tournamentid})}}>
                        {this.props.intl.formatMessage({ id: 'TournamentAddPlayer', defaultMessage: 'Add player' })}
                      </Button>
                    )
                  : null }
              </>);
            })(),
          }
        }
      },
    ];
  }

  render() {
    let columns = [];

    switch (this.props.tournamenttypeid) {
      case 3:
        columns = this.getQualificationalColumns();
        break;
      case 2:
      default:
        columns = this.getProvisionalColumns();
    }

    return(
      <>
        <Table
          className="competitionsTable"
          columns={columns}
          dataSource={(this.props.competitors || []).filter(c => this.props.competitorFilters.every(filter => filter(c)))}
          pagination={false}
          rowKey="uid"
          style={{ width: '100%' }}
          locale={{ emptyText: 'No competitors' }}
          rowClassName={'tournamentRow'}
        />
      </>

    );
  }
}

export default connect(
  state => ({
    tournamentid: state.tournaments.tournamentId,
    isadmin: state.users.user.isadmin,
    playersUpdate: state.tournaments.fetches,
    permissions: state.users.permissions ? state.users.permissions.keys : [],
    tournamentInfo: state.tournaments.tournamentInfo,
    squadIdRemovedPlayer: state.tournaments.squadIdRemovedPlayer,
  }),
  {
    UserByIdFetch,
    addUserToTournament,
    deleteUserFromTournament,
    getPermissions,
    addReplayToPlayer,
    GameLeave,
    postRemovedPlayerInfo,
    removePlayerRemovedInfo,
  },
)(CompetitorList);
