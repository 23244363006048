import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { findLast } from 'lodash';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';

import {
  TournamentFetch,
  createPrivateRoom,
  setCurrentRoom,
  getPermissions,
  addUserToTournament,
  deleteUserFromTournament,
} from '../../libs/reducers/actions';
import { getNotificationsFetch } from '../../libs/reducers/notifications.reducer';

import Tournaments from './tournaments';
import MyBattles from './myBattles';
import Notifications from '../notifications';
import TournamentsChat from './tournamentsChat/tournamentsChat';
import BattleCreationOfTournament from '../tournamentRoot/battleCreation';

import './style.scss';

import SvgCup from '-!svg-react-loader!../../images/Cup.svg'; // eslint-disable-line
import SvgNotification from '-!svg-react-loader!../../images/Notification.svg'; // eslint-disable-line
import SvgChat from '-!svg-react-loader!../../images/chat.svg'; // eslint-disable-line
import BadgeIcon from '../common/BadgeIcon/BadgeIcon';
import TournamentsTabs from './tournamentsTabs'

export const statusTextDisplay = (record) => {
  let str;
  if (record.cancelled === 0) {
    if (record.closed === null) {
      const now = moment().unix();
      if (now < moment(record.regdatestart).unix()) {
        str = <span>Объявлен</span>;
      }
      if (now >= moment(record.regdatestart).unix() && now < moment(record.regdateend).unix()) {
        str = <span style={{ fontWeight: 'bold' }}>Регистрация</span>;
      }
      if (now >= moment(record.regdateend).unix() && now < moment(record.datestart).unix()) {
        str = <span style={{}}>Регистрация завершена</span>;
      }
      if (now >= moment(record.datestart).unix()) {
        str = <span style={{ fontWeight: 'bold', color: '#20a39e' }}>Игра идет</span>;
      }
    } else {
      str = (
        <span className="safarifixspan" style={{ opacity: 0.5 }}>
          Завершен
        </span>
      );
    }
  } else {
    str = (
      <span className="safarifixspan" style={{ opacity: 0.5 }}>
        Отменен
      </span>
    );
  }
  return str;
};

class TournamentsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCup: true,
      activeNotification: false,
      activeChat: false,
      clickedTournament: {},
      tournaments: [],
      loadingTable: false,
      loadedData: false,
      tournamentInfo: {},
      clickedRow: 0,
      isVisibleStartBattleModal: false,
      opponent: {
        fullname: '',
        nickname: '',
      },
      tournament: {},
      currentRoom: props.currentRoom || null,
      rooms: props.rooms || [],
      tournamentScreen: false,
      tournamenttypeid: 2,
    };
  }

  static propTypes = {
    TournamentFetch: PropTypes.func,
    intl: intlShape,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = {};
    if (nextProps.tournaments.tournaments && nextProps.tournaments.isFetching) {
      state = {
        ...state,
        tournaments: nextProps.tournaments.tournaments,
        clickedTournament: nextProps.tournaments.tournaments[0],
        loadedData: true,
      };
    } else if (nextProps.tournaments && nextProps.tournaments.tournaments && nextProps.tournaments.tournaments.length) {
      state = {
        ...state,
        tournaments: nextProps.tournaments.tournaments,
      };
    }
    if (nextProps.rooms.length > prevState.rooms.length) {
      const newRooms = nextProps.rooms.slice(prevState.rooms.length);
      const room = findLast(newRooms, (r => r.room_name.startsWith('private-room') && r.owner_id === nextProps.users.user.id));
      state = { ...state, rooms: nextProps.rooms };
      if (room && newRooms.length < nextProps.rooms.length) {
        state = { ...state, currentRoom: room.room_name, rightIndex: 2 };
      }
    }
    if (nextProps.currentRoom !== prevState.currentRoom) {
      state = { ...state, currentRoom: nextProps.currentRoom };
      if (nextProps.currentRoom) {
        state = { ...state, rightIndex: 2 };
      }
      state = { ...state, currentRoom: null };
    }
    return state;
  }

  static privateRoomName(userIds) {
    const min = Math.min(...userIds);
    const max = Math.max(...userIds);
    return `private-room-${min}-${max}`;
  }

  componentDidMount() {
    this.props.TournamentFetch();
    this.props.getPermissions();
  }

  handleStartBattle = () => {
    this.setState({
      tournament: {
        type: 'battle',
        date: moment(),
        bet: 10,
        numOfWins: 5,
      },
      opponent: {
        fullname: 'Ivanov Ivan',
        nickname: 'ivanov_21rus',
        flag: 'russia',
      },
      isVisibleStartBattleModal: true,
    });
  };

  onCreateBattleModalClose = () => {
    this.setState({
      isVisibleStartBattleModal: false,
    });
  };

  onStartGameSubmit = () => {
    console.log('Submit');
  };

  onStartGameCancel = () => {
    this.setState({
      isVisibleStartBattleModal: false,
    });
  };


  renderFirstTabPanel () {
    if (this.props.permissions.includes('TOURNAMENTS_CREATE_BATTLE') && this.props.tournamentInfo && this.props.tournamentInfo.tournamenttypeid === 2) {
      return (<BattleCreationOfTournament/>)
    } 
    else {
      return (
        <MyBattles onChat={() => this.setState({ rightIndex: 2 })}/>
      )
    }
  }

  renderFirstTab () {
    return <SvgCup/>
  }

  render() {
    const {
      clickedTournament
    } = this.state;
    const {
      unreadNotifications, rooms, intl, systemMessages
    } = this.props;
    const unreadMessages = rooms.reduce((count, room) => {
      if ((room.room_name.startsWith('squad-chat') || 
        room.room_name.startsWith('private-room') || 
        room.room_name.startsWith('support-room')) && 
        (this.props.currentRoom !== room.room_name) && 
        (room.users && room.users.length > 1)
      ) {
        // const { message: { seen = 0, total = 0 } = {} } = room;
        
        // return count + (total - seen);
        const { message: { seen = 0, total = 0, total_system = 0, seen_system = 0 } = {} } = room;

        return (systemMessages)
          ? count + (total - seen)
          : count + ((total - total_system) - (seen - seen_system));
      }
      return count;
    }, 0);
    return (
      <div
        style={{
          height: '100%',
          backgroundColor: '#fff',
          display: 'flex',
          flexDirection: 'row',
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        }}
      >
      {!this.props.tournamentInfo ?
          <div
            className="tableintabs"
            style={{
              background: 'linear-gradient(to top, #0c131c, #0d141d)',
              flexWrap: 'nowrap',
              flex: 1,
              flexBasis: '75%',
              borderBottom: '1px solid rgba(100, 100, 100, 0.3)',
              overflow: 'auto',
            }}
          >
          <Tabs>
            <TabList>
              <Tab>{this.props.intl.formatMessage({ id: 'tournaments', default: 'Tournaments' })}</Tab>
            </TabList>
            <TabPanel>
            <Tournaments
              intl={this.props.intl}
              onRowClick={(record, index) => {
                this.setState({
                  clickedRow: index,
                  clickedTournament: record,
                });
              }}
              clickedRow={this.state.clickedRow}
              clickedTournament={this.state.clickedTournament}
              switchTournament={tournament => this.setState({ tournamenttypeid: tournament.tournamenttypeid })}
            />
          </TabPanel>
          </Tabs>
          </div>
         :<TournamentsTabs/>
        }
        <div
          style={{
            flexBasis: '25%',
            minWidth: '281px',
            height: '100%',
            background: 'linear-gradient(to top, #0c131c, #0d141d)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          >
            <div
              className="user-menu-tabs"
            >
              <Tabs
                className="react-bottom-tabs"
                style={{ display: 'flex', flexDirection: 'column' }}
                defaultIndex={0}
                selectedIndex={this.state.rightIndex || 0}
                onSelect={(index) => {
                  this.setState({ chatKey: Math.random(), rightIndex: index });
                  this.props.setCurrentRoom(null);
                }}
              >
                <TabPanel style={{ flex: 1 }}>
                  { this.renderFirstTabPanel() }
                </TabPanel>
                <TabPanel style={{ flex: 1 }}>
                  <Notifications />
                </TabPanel>
                <TabPanel style={{ flex: 1 }}>
                  <TournamentsChat
                    key={this.state.chatKey}
                    room={this.state.currentRoom}
                  />
                </TabPanel>
                <TabList style={{ margin: 0 }}>
                  <Tab>{this.renderFirstTab()}</Tab>
                  <Tab>
                    <BadgeIcon count={unreadNotifications} width={42}>
                      <SvgNotification />
                    </BadgeIcon>
                  </Tab>
                  <Tab disabled={false}>
                    <BadgeIcon count={unreadMessages} width={42}>
                      <SvgChat />
                    </BadgeIcon>
                  </Tab>
                </TabList>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(
  withRouter(
    connect(
      state => ({
        user: state.users.user,
        users: state.users,
        tournaments: state.tournaments,
        unreadNotifications: state.notifications.notifications.count,
        currentRoom: state.chat.currentRoom,
        rooms: state.chat.rooms,
        tournamentInfo: state.tournaments.tournamentInfo,
        permissions: state.users.permissions ? state.users.permissions.keys : [],
        systemMessages: state.chat.systemSquadChat,
      }),
      {
        TournamentFetch,
        deleteUserFromTournament,
        addUserToTournament,
        getNotificationsFetch, 
        createPrivateRoom,
        setCurrentRoom,
        getPermissions,
      },
    )(TournamentsPage),
  ),
);
