import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TournamentsTable from './tournamentsTable';
import Modal from '../../common/Modal'
import BreakText from '../../BreakText';
class Tournaments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      selectedRecord: null,
    };
  }

  

  static propTypes = {
    tournaments: PropTypes.array,
    clickedRow: PropTypes.number,
    clickedTournament: PropTypes.object,
    onRowClick: PropTypes.func,
  };

  onInfoClick = (record) => {
    this.setState({ showModal: true, selectedRecord: record });
  }

  render() {
    return (
      <>
        <Modal
          closable
          footer = {false}
          width={600}
          title={this.state.selectedRecord ? this.state.selectedRecord.name: ''}
          visible={this.state.showModal}
          onCancel={() => { this.setState({ showModal: false, selectedRecord: null }); }}
        >
          <div className="description">
            <div> { this.props.intl.formatMessage({ id: 'DescriptionAsAdditionalInfoAlias', default: 'Description' }) }</div>
            <div className="descriptionBody"> <BreakText>{ this.state.selectedRecord ? this.state.selectedRecord.rules : null}</BreakText> </div>
          </div>
        </Modal>
        <TournamentsTable
          tournaments={!this.props.myTournaments ? this.props.tournaments: this.props.userTournaments}
          onRowClick={(record, index) => {
            this.props.onRowClick(record, index);
          }}
          onInfoClick = {this.onInfoClick}
          clickedRow={this.props.clickedRow}
          clickedTournament={this.props.clickedTournament}
          switchTournament={this.props.switchTournament}
        />
      </>
    );
  }
}

export default connect(
  state => ({
    tournaments: state.tournaments.tournaments,
    userTournaments: state.tournaments.accountTournaments, 
  }),
  {},
)(Tournaments);
