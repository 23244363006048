import React from 'react';
import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import { store } from '../../store';
// import { UserLogout } from './reducers/actions';
import { setCommonError, getAgreements } from '../../libs/reducers/commonReducer';
import { UserReg, RegErrorClean, ChangeLang, resetSessionEvent, UserLogout } from '../../libs/reducers/actions';
import globeImage from '../../images/globe.png';

import SocialButtons from '../socialButtons';
import Title from '../common/Title';
import { Button, LoginButton } from '../common/Button';
import Input from '../common/Input';
import Checkbox from '../common/Checkbox';
import logo from '../../images/Logo1.png';
import config from '../../config';

import './style.css';
import { openPolicy } from '../policy';

const logger = {
  log: console.log,
};

class Registration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login: props.users.user.login || '',
      password: '',
      confirmPassword: '',
      email: props.users.user.email || '',
      regcode: '',
      // agreementYO: !!props.users.user.login || false,
      agreementPD: !!props.users.user.login || false,
      agreementOF: !!props.users.user.login || false,
      agreementCA: !!props.users.user.login || false,
      agreementTC: !!props.users.user.login || false,
      showModalPD: false,
      showModalOF: false,
      showModalCA: false,
      showModalTC: false,

      formErrors: {
        login: '',
        email: '',
        password: '',
        confirmPassword: '',
        // agreementYO: '',
        agreementPD: '',
        agreementOF: '',
        agreementCA: '',
        agreementTC: '',
        regcode: '',
      },

      prevLang: this.props.langs.lang || 'en',
      sendToServer: true, // где проводится валидация формы
      referID: undefined,
    };
  }

  static propTypes = {
    intl: intlShape,
    regcode: PropTypes.bool,
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }),
  };

  componentDidMount() {
    store.dispatch(getAgreements(this.props.langs.lang || 'en'));
    const {referID} = this.props.match.params
    if (referID){
      this.setState({referID: Number(referID)})
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { showModalTC } = this.state;
    if (showModalTC && !prevState.showModalTC) {
      openPolicy({ kind: 'TC' });
      this.setState({ showModalTC: false });
      return;
    }
    if (prevProps.users.user && prevProps.users.user.id && !this.props.users.user.id) {
      this.setState({
        login: '',
        password: '',
        confirmPassword: '',
        email: '',
        regcode: '',
        // agreementYO: false,
        agreementPD: false,
        agreementOF: false,
        agreementCA: false,
        agreementTC: false,
      })
    }

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = { ...prevState }
    if ( nextProps.langs.lang !== prevState.prevLang) {
      store.dispatch(getAgreements(nextProps.langs.lang || 'en'));
      state = { ...state, prevLang: nextProps.langs.lang }
    }
    if (
      nextProps.users.loginerror
      && nextProps.users.loginerror !== ''
      && nextProps.users.loginerror !== prevState.formErrors.login
    ) {
      state = { ...state, formErrors: { ...prevState.formErrors, login: nextProps.users.loginerror } };
    }
    if (
      // Не скрывается предыдущая ошибка после перезагрузки страницы
      // nextProps.users.emailerror
      // && nextProps.users.emailerror !== ''
      prevState.sendToServer
      && nextProps.users.emailerror !== prevState.formErrors.email
    ) {
      let emailerror = nextProps.users.emailerror;
      let message = (emailerror)
        ? (nextProps.intl && nextProps.intl.formatMessage({ id: emailerror, defaultMessage: emailerror }) || emailerror)
        : '';
      state = { ...state, formErrors: { ...prevState.formErrors, email: message } };
    }
    if (
      nextProps.users.regcodeerror
      && nextProps.users.regcodeerror !== ''
      && nextProps.users.regcodeerror !== prevState.formErrors.regcode
    ) {
      state = { ...state, formErrors: { ...prevState.formErrors, regcode: nextProps.regcodeerr } };
    }
    if (nextProps.users.reg && nextProps.users.user && !!nextProps.users.user.emailVerified) {
      if (state.referID){
        fetch(`${config.FETCH.url}/user/referral-register`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({referID: state.referID, userid: nextProps.users.user.id})
        }).then((r) => {
          nextProps.history.push('/postregdata', {isReferral: true})
        })
      } else {
        nextProps.history.push('/postregdata')
      }
    }

    return state;
  }

  handleErrors = (errors) => {
    logger.log('handleErrors()');
    const msgList = [];
    let idx = 1;
    for (const key of Object.keys(errors)) {
      if (errors[key]) {
        msgList.push(`${idx}) ${errors[key]}: ${key}`);
        idx += 1;
      }
    }
    const msg = msgList.join('; ');
    logger.log('handleErrors.msg:', msg);
    if (msg) {
      store.dispatch(setCommonError(msg));
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ sendToServer: false });
    this.props._regErrClean();
    const {
      login,
      email,
      password,
      confirmPassword,
      // agreementYO,
      agreementCA,
      agreementOF,
      agreementPD,
      agreementTC,
      regcode,
    } = this.state;
    const errors =
      this.validate({
        login,
        email,
        password,
        confirmPassword,
        // agreementYO,
        agreementCA,
        agreementOF,
        agreementPD,
        agreementTC,
        regcode,
      });
    logger.log('errors:', errors);
    // this.handleErrors(errors); // закомментил, чтоб убрать модалку с ошибками
    let isFormValid = true;
    _.forIn(errors, (fieldErrorMsg) => {
      if (!_.isEmpty(fieldErrorMsg)) isFormValid = false;
    });

    this.setState({ formErrors: errors }, () => {
      if (isFormValid) {
        this.props._reg({
          login: this.state.login,
          email: this.state.email,
          password: this.state.password,
          regcode: this.state.regcode,
        });
        this.setState({ sendToServer: true });
      }
    });
  };

  handleUserInput = (value, name) => this.setState({ [name]: value });

  // eslint-disable-next-line
  validate(params) {
    const fieldValidationErrors = {
      login: '',
      email: '',
      password: '',
      confirmPassword: '',
      // agreementYO: '',
      agreementCA: '',
      agreementOF: '',
      agreementPD: '',
      agreementTC: '',
      regcode: '',
    };
    const { intl, regcode: needRegistrationCode } = this.props;

    const validateFunctions = {
      // agreementYO: (value) => (!value) ? intl.formatMessage({ id: 'InvalidYOCheckbox', defaultMessage: 'You must be 18 years old or older' }) : '',
      agreementCA: (value) => (!value)
        ? intl.formatMessage({ id: 'AgreeWith', defaultMessage: 'You must agree with' })+' '+intl.formatMessage({ id: 'CAagree', defaultMessage: 'removal conditions' }) : '',
      agreementOF: (value) => (!value) 
        ? intl.formatMessage({ id: 'AgreeWith', defaultMessage: 'You must agree with' })+' '+intl.formatMessage({ id: 'OFagree', defaultMessage: 'offer conditions' }) : '',
      agreementPD: (value) => (!value) 
        ? intl.formatMessage({ id: 'AgreeWith', defaultMessage: 'You must agree with' })+' '+intl.formatMessage({ id: 'PDagree', defaultMessage: 'privacy policy' }) : '',
      agreementTC: (value) => (!value) 
        ? intl.formatMessage({ id: 'AgreeWith', defaultMessage: 'You must agree with' })+' '+intl.formatMessage({ id: 'TCagree', defaultMessage: 'terms and condition' }) : '',
      confirmPassword: (value) => {
        if (params.password !== value) {
          return intl.formatMessage({ id: 'InvalidPasswordConfirmationField', defaultMessage: 'Passwords not match' });
        } else if (_.isEmpty(value)) {
          return intl.formatMessage({ id: 'requiredField', defaultMessage: 'Field is required' });
        } else {
          return '';
        }
      },
      login: (value) => {
        const regExpLogin = /^[a-zA-Z][a-zA-Z0-9\_]+$/;

        value = value.trim();
        if (_.isEmpty(value)) {
          return intl.formatMessage({ id: 'requiredField', defaultMessage: 'Field is required' });
        } else if (value && !/^\w+$/.test(value)) {
          return intl.formatMessage({ id: 'alphaNumericFieldLat', defaultMessage: '{fieldName} must contain alphanumeric characters including the underscore'}, 
            { fieldName: intl.formatMessage({ id: 'login4', defaultMessage: 'Login' }) });
        } else if (value && (value.length > 50 || value.length < 4)) {
          return intl.formatMessage({ id: 'lengthRangeError', defaultMessage: '{fieldName} field must contain from {min} to {max} {max, plural, one {symbol} other {symbols}}',}, 
            { fieldName: intl.formatMessage({ id: 'login4', defaultMessage: 'Login' }), min: 4, max: 50 });
        } else if (value && (value.startsWith('_') || value.endsWith('_'))) {
          return intl.formatMessage({ id: 'fieldStartEndError', defaultMessage: '{fieldName} field must not start and / or end with {character} character' }, 
            { fieldName: intl.formatMessage({ id: 'login4', defaultMessage: 'Login' }), character: intl.formatMessage({ id: 'underScoreChar', defaultMessage: 'underscore' }) })
        } else if (!regExpLogin.test(value) || value.length < 4) {
          return intl.formatMessage({
            id: 'NotValidLogin',
            defaultMessage: 'The login must contain at least 4 characters from the Latin alphabet and numbers, including the underscore in the middle of the login and cannot contain the @ symbol' });
        } else {
          return '';
        }
      },
      email: (value) => (_.isEmpty(value)) ? intl.formatMessage({ id: 'requiredField', defaultMessage: 'Field is required' }) : '',
      password: (value) => (_.isEmpty(value)) ? intl.formatMessage({ id: 'requiredField', defaultMessage: 'Field is required' }) : '',
      regcode: (value) => (needRegistrationCode && _.isEmpty(value))
        ? intl.formatMessage({ id: 'requiredField', defaultMessage: 'Field is required' }) : ''
    }

    for(let i in params) {
      if (validateFunctions.hasOwnProperty(i)) {
        fieldValidationErrors[i] = validateFunctions[i](params[i]);
      }
    }

    return fieldValidationErrors;
  }

  renderLanguageSelect = () => {
    const { intl, langs, users } = this.props;
    const lang = langs.lang || users.user.lang || 'EN';
    const { LANGS } = config;
    return (
      <div className="login-language">
        <span className="language-header">
          {intl.formatMessage({ id: 'interfaceLanguage', defaultMessage: 'Language' })}
        </span>
        <div className="language-list">
          {LANGS.map((language, index) => (
            <div key={index} className={`language-en ${lang === language ? 'selected' : ''}`} onClick={() => this.props._changeLang(language)}>
              <img src={globeImage} alt="Globe" className="language-globe-icon" />
              <span className="language-name">{language}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  onValidateSocialBtn = (type) => {
    if (!type) {
      return;
    }
    const socialLink = {
      vk:     () => '/api/login?type=vkontakte',
      fb:     () => '/api/login?type=facebook',
      google: () => '/api/login?type=google',
      apple: () => '/api/login?type=apple'
    }
    const { agreementCA, agreementOF, agreementPD, agreementTC } = this.state;
    const errors = this.validate({ agreementCA, agreementOF, agreementPD, agreementTC });
    let isFormValid = true;
    _.forIn(errors, (fieldErrorMsg) => {
      if (!_.isEmpty(fieldErrorMsg)) isFormValid = false;
    });
    if (socialLink.hasOwnProperty(type) && isFormValid) {
      window.location = socialLink[type]();
    } else {
      this.setState({ formErrors: errors });
    }
  }


  render() {
    const { regcode: needRegistrationCode, intl, agreements } = this.props;
    logger.log('registration.errors:', this.state.formErrors);

    return (
      <div className="registration-page">
        <div className="registration-page__content">
          <div className="registration-page__left-column">
            <div className="registration-page__left-column__content">
              <div>
                <a href='/'><img src={logo} alt="" className="leftcolumn__content__logo" /></a>
                <Title subtitle={intl.formatMessage({ id: 'a_new_player', defaultMessage: 'a new player' })}>
                  {intl.formatMessage({ id: 'register', defaultMessage: 'Register' })}
                </Title>
                <div className="title-description">
                  {intl.formatMessage({
                    id: 'registration_message',
                    defaultMessage: 'After registration you can play with every player all over the world!',
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className="registration-page__right-column">
            <div className="registration-page__right-column__content">
              <span className={'registration-page__right-title'}>
                {intl.formatMessage({ id: 'register', defaultMessage: 'Register' })}
              </span>
              {this.renderLanguageSelect()}
              <div
                className="gray-text"
                style={{
                  paddingTop: 17,
                  paddingBottom: 22,
                }}
              >
                {intl.formatMessage({ id: 'registration_with', defaultMessage: 'Registration with' })}
              </div>

              <SocialButtons 
                vkClick={() => this.onValidateSocialBtn('vk')}
                fbClick={() => this.onValidateSocialBtn('fb')}
                googleClick={() => this.onValidateSocialBtn('google')}
                appleClick={() => this.onValidateSocialBtn('apple')}
              />

              <div className="gray-text" style={{ paddingTop: 20, paddingBottom: 20 }}>
                {intl.formatMessage({ id: 'or', defaultMessage: 'or' })}
              </div>

              <form onSubmit={this.handleSubmit}>
                <div className="input-group">
                  <Input
                    onChangeText={this.handleUserInput}
                    value={this.state.login}
                    placeholder={intl.formatMessage({ id: 'login4', defaultMessage: 'Login' })}
                    name="login"
                    error={this.state.formErrors.login}
                  />
                  <Input
                    // type="email"
                    onChangeText={this.handleUserInput}
                    value={this.state.email}
                    placeholder={intl.formatMessage({ id: 'email', defaultMessage: 'E-mail' })}
                    name="email"
                    error={this.state.formErrors.email}
                  />
                  <Input
                    type="password"
                    onChangeText={this.handleUserInput}
                    value={this.state.password}
                    placeholder={intl.formatMessage({ id: 'password', defaultMessage: 'Password' })}
                    name="password"
                    error={this.state.formErrors.password}
                  />
                  <Input
                    type="password"
                    onChangeText={this.handleUserInput}
                    value={this.state.confirmPassword}
                    placeholder={intl.formatMessage({ id: 'confirm_password', defaultMessage: 'Confirm password' })}
                    name="confirmPassword"
                    error={this.state.formErrors.confirmPassword}
                  />
                  {needRegistrationCode ? (
                    <Input
                      name="regcode"
                      value={this.state.regcode}
                      placeholder={intl.formatMessage({ id: 'reg_code', defaultMessage: 'Registration code' })}
                      onChangeText={this.handleUserInput}
                      error={this.state.formErrors.regcode}
                    />
                  ) : null}
                </div>

                {/* <Checkbox
                  style={{ paddingTop: 18, paddingBottom: 10 }}
                  checked={this.state.agreementYO}
                  onChange={checked => this.setState({ agreementYO: checked })}
                  name="agreementYO"
                >
                  <span>
                    {intl.formatMessage({ id: 'AdultCheck', defaultMessage: 'I\'m 18yo or older' })}
                  </span>
                </Checkbox> */}
                {/* {this.state.formErrors.agreementYO && (
                  <div className="input__error">{this.state.formErrors.agreementYO}</div>
                )} */}
                <div className={'registration-checkbox'}>
                  <Checkbox
                      checked={this.state.agreementOF}
                      onChange={checked => this.setState({ agreementOF: checked })}
                      name="agreementOF"
                  >
                  <span>
                    {intl.formatMessage({ id: 'i_agree_with', defaultMessage: 'I agree with' })}
                    &nbsp;
                    <Link
                        style={{ fontWeight: 'bold' }}
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            showModalOF: true,
                          });
                        }}
                    >
                      {intl.formatMessage({ id: 'OFagree', defaultMessage: 'offer conditions' })}
                    </Link>
                  </span>
                  </Checkbox>
                </div>
                {this.state.formErrors.agreementOF && (
                  <div className="input__error">{this.state.formErrors.agreementOF}</div>
                )}
                <div className={'registration-checkbox'}>
                  <Checkbox
                      checked={this.state.agreementPD}
                      onChange={checked => this.setState({ agreementPD: checked })}
                      name="agreementPD"
                  >
                  <span>
                    {intl.formatMessage({ id: 'i_agree_with', defaultMessage: 'I agree with' })}
                    &nbsp;
                    <Link
                        style={{ fontWeight: 'bold' }}
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            showModalPD: true,
                          });
                        }}
                    >
                      {intl.formatMessage({ id: 'PDagree', defaultMessage: 'privacy policy' })}
                    </Link>
                  </span>
                  </Checkbox>
                </div>
                {this.state.formErrors.agreementPD && (
                  <div className="input__error">{this.state.formErrors.agreementPD}</div>
                )}
                <div className={'registration-checkbox'}>
                  <Checkbox
                      checked={this.state.agreementTC}
                      onChange={checked => this.setState({ agreementTC: checked })}
                      name="agreement"
                  >
                  <span>
                    {intl.formatMessage({ id: 'i_agree_with', defaultMessage: 'I agree with' })}
                    &nbsp;
                    <Link
                        style={{ fontWeight: 'bold' }}
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            showModalTC: true,
                          });
                        }}
                    >
                      {intl.formatMessage({ id: 'TCagree', defaultMessage: 'terms and condition' })}
                    </Link>
                  </span>
                  </Checkbox>
                </div>
                {this.state.formErrors.agreementTC && (
                  <div className="input__error">{this.state.formErrors.agreementTC}</div>
                )}
                <div className={'registration-checkbox'}>
                  <Checkbox
                      checked={this.state.agreementCA}
                      onChange={checked => this.setState({ agreementCA: checked })}
                      name="agreementCA"
                  >
                  <span>
                    {intl.formatMessage({ id: 'i_agree_with', defaultMessage: 'I agree with' })}
                    &nbsp;
                    <Link
                        style={{ fontWeight: 'bold' }}
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            showModalCA: true,
                          });
                        }}
                    >
                      {intl.formatMessage({ id: 'CAagree', defaultMessage: 'removal conditions' })}
                    </Link>
                  </span>
                  </Checkbox>
                </div>
                {this.state.formErrors.agreementCA && (
                  <div className="input__error">{this.state.formErrors.agreementCA}</div>
                )}

                <Button size="s" type="submit" style={{ marginTop: 30, marginBottom: 30 }}  loading={this.props.userFetch}>
                  {intl.formatMessage({ id: 'register', defaultMessage: 'Register' })}
                </Button>

                <LoginButton size="s" onClick={() => this.props.history.push('/login')}>
                  <span>
                    {intl.formatMessage({ id: 'existing_account', defaultMessage: 'Existing account' })}?
                    <span style={{ fontWeight: 'bold', color: '#f65932' }}>
                      {' '}
                      {intl.formatMessage({ id: 'login3', defaultMessage: 'Log in' })}
                    </span>
                  </span>
                </LoginButton>
              </form>
            </div>
          </div>
        </div>

        <Modal
          title={intl.formatMessage({ id: 'PDagreeD', defaultMessage: 'Privacy policy' })}
          visible={this.state.showModalPD}
          closable
          onCancel={() => this.setState({ showModalPD: false })}
          footer={[
            <Button key="submit" onClick={() => this.setState({ showModalPD: false })}>
              OK
            </Button>,
          ]}
        >
          <div className='userAgreementText' dangerouslySetInnerHTML={{ __html: agreements[`PD${(this.props.langs.lang || 'en').toLowerCase()}`] }}/>
        </Modal>
        <Modal
          title={intl.formatMessage({ id: 'OFagreeD', defaultMessage: 'Offer conditions' })}
          visible={this.state.showModalOF}
          closable
          onCancel={() => this.setState({ showModalOF: false })}
          footer={[
            <Button key="submit" onClick={() => this.setState({ showModalOF: false })}>
              OK
            </Button>,
          ]}
        >
          <div className='userAgreementText' dangerouslySetInnerHTML={{ __html: agreements[`OF${(this.props.langs.lang || 'en').toLowerCase()}`] }}/>
        </Modal>
        {/* <Modal
          title={intl.formatMessage({ id: 'TCagreeD', defaultMessage: 'Terms and condition' })}
          visible={this.state.showModalTC}
          closable
          onCancel={() => this.setState({ showModalTC: false })}
          footer={[
            <Button key="submit" onClick={() => this.setState({ showModalTC: false })}>
              OK
            </Button>,
          ]}
        >
          <div className='userAgreementText' dangerouslySetInnerHTML={{ __html: agreements[`TC${(this.props.langs.lang || 'en').toLowerCase()}`] }}/>
        </Modal> */}
        <Modal
          title={intl.formatMessage({ id: 'CAagreeD', defaultMessage: 'removal conditions' })}
          visible={this.state.showModalCA}
          closable
          onCancel={() => this.setState({ showModalCA: false })}
          footer={[
            <Button key="submit" onClick={() => this.setState({ showModalCA: false })}>
              OK
            </Button>,
          ]}
        >
          <div className='userAgreementText' dangerouslySetInnerHTML={{ __html: agreements[`CA${(this.props.langs.lang || 'en').toLowerCase()}`] }}/>
        </Modal>
      </div>
    );
  }
}

export default injectIntl(
  connect(
    state => ({
      users: state.users,
      langs: state.langs,
      regcodeerr: state.common.regcodeerror,
      regcode: !_.isEmpty(state.common.settings.data) ? state.common.settings.data.regcode : false,
      agreements: state.common.agreements,
      userFetch: state.users.userFetch,
      lastLocation: state.redirect.from,
    }),
    dispatch => ({
      _reg: bindActionCreators(UserReg, dispatch),
      _regErrClean: bindActionCreators(RegErrorClean, dispatch),
      _changeLang: bindActionCreators(ChangeLang, dispatch),
      _resetSession: bindActionCreators(resetSessionEvent, dispatch),
      _userLogout: bindActionCreators(UserLogout, dispatch),
    }),
  )(Registration),
);
