import { call, put } from 'redux-saga/effects';
import bugsnagClient from '../../bugsnagClient';
import config from '../../config';
import { get } from '../Networking';

const {
  USER_SEARCH_SET,
} = require('../reducers/actiontypes').default;

export function* _SearchUsers(params) {
  const { payload } = params 
  try {
    const result = yield call(get, {
      url: `${config.FETCH.url}/user/search?query=${payload}`,
    })
    if (result.status === 200 && result.data.success) {
      yield put({ type: USER_SEARCH_SET, data: result.data.data });
    } else {
      yield put({ type: USER_SEARCH_SET, error: result.data.error || result.status });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: '_SearchUsers' });
    console.log('_SearchUsers: ', error);
  }
}