import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';

import moment from 'moment';

import OBModal from '../../Modal';
import Radio from '../../Radio';
import Slider from '../../Slider';
import Select from '../../Select';
import OBDatePicker from '../../DatePicker';
import OBTimePicker from '../../TimePicker';
import TextArea from '../../TextArea';
import Opponent from '../../Opponent';

const intlList = {
  InviteBattle: 'Invite Battle',
  InvalidDateBothValues: 'Must set both values',
  InvalidBetEqOrGtZero: 'Must be equal or greater than 0',
};

const RadioGroup = Radio.Group;

class InviteBattle extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    opponent: PropTypes.shape({
      id: PropTypes.number,
      imageUri: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      login: PropTypes.string,
      dob: PropTypes.string,
      gender: PropTypes.string,
      city: PropTypes.string,
      hourshift: PropTypes.string,
      country_live: PropTypes.string,
      country_federation: PropTypes.string,
      club: PropTypes.string,
    }),
    intl: intlShape,
  };

  static defaultProps = {
    visible: false,
    onSubmit: () => {},
    onClose: () => {},
    opponent: {
      id: 0,
      imageUri: '',
      firstname: 'Firstame',
      lastname: 'Lastname',
      login: 'login',
      dob: '',
      gender: '',
      city: '',
      hourshift: '',
      country_live: '',
      country_federation: '',
      club: '',
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: props.visible,
      betType: 1,
      bet: '',
      winCount: 1,
      dateType: 1,
      date: null,
      time: null,
      comment: '',
      invalidBet: '',
      invalidDate: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.visible !== prevState.visible) return { visible: nextProps.visible };
    return null;
  }

  showModal = () => this.setState({ visible: true });

  handleOk = () => {
    const { intl } = this.props;
    const {
      bet, betType, date, dateType, time, winCount,
    } = this.state;
    if ((bet < 0 && betType === 2) || (dateType === 2 && (!date || !time))) {
      if (bet < 0 && betType === 2) {
        this.setState({
          invalidBet: intl.formatMessage({
            id: 'InvalidBetEqOrGtZero',
            defaultMessage: 'Must be equal or greater than 0',
          }),
        });
      } else {
        this.setState({ invalidBet: '' });
      }
      if (dateType === 2 && (!date || !time)) {
        this.setState({
          invalidDate: intl.formatMessage({ id: 'InvalidDateBothValues', defaultMessage: 'Must set both values' }),
        });
      } else {
        this.setState({ invalidDate: false });
      }
      return;
    }
    let count;
    switch (winCount) {
      case 0:
        count = 1;
        break;
      case 25:
        count = 2;
        break;
      case 50:
        count = 3;
        break;
      case 75:
        count = 4;
        break;
      case 100:
        count = 5;
        break;
      default:
        count = 1;
        break;
    }
    this.props.onSubmit({
      betType: this.state.betType,
      bet: this.state.bet,
      winCount: count,
      dateType: this.state.dateType,
      date: this.state.date,
      time: this.state.time,
      comment: this.state.comment,
      opponent: this.props.opponent,
    });
    this.setState({
      betType: 1,
      bet: '',
      winCount: 0,
      dateType: 1,
      date: null,
      time: null,
      comment: '',
    });
  };

  handleCancel = () => this.props.onClose();

  onChangeBetType = e => this.setState({ betType: e.target.value });

  onChangeDateType = e => this.setState({ dateType: e.target.value });

  onChangeBet = e => this.setState({ bet: e.value });

  onChangeWinCount = value => this.setState({ winCount: value });

  onChangeDate = date => this.setState({ date });

  onChangeTime = time => this.setState({ time });

  onChangeComment = e => this.setState({ comment: e.value });

  render() {
    const { opponent, intl, steps } = this.props;
    const BetGroup = null;
    // const BetGroup = (
    //   <div key="betGroup" className="bet-group">
    //     <span className="bet-group__label">
    //       {intl.formatMessage({ id: 'choose_your_bet', defaultMessage: 'Choose your bet' })}
    //     </span>
    //     <div className="bet-group__elements">
    //       <RadioGroup name="bet" defaultValue={1} onChange={this.onChangeBetType} value={this.state.betType}>
    //         <Radio value={1}>{intl.formatMessage({ id: 'free_game', defaultMessage: 'Fun game' })}</Radio>
    //         <Radio value={2}>{intl.formatMessage({ id: 'choose_fee', defaultMessage: 'Choose fee' })}</Radio>
    //       </RadioGroup>
    //       <Select
    //         noOptionsMessage={() => intl.formatMessage({ id: 'NoFundsPlaceholder', defaultMessage: 'No funds' })}
    //         value={{ value: this.state.bet, label: this.state.bet}}
    //         options={(steps && steps['DEF'] || []).map(step => ({ value: step, label: step }))}
    //         onChange={this.onChangeBet}
    //         className='picker'
    //         />
    //     </div>
    //   </div>
    // );

    const WinCountGroup = (
      <div key="winCountGroup" className="win-count-group">
        <span className="win-count-group__label">
          {intl.formatMessage({ id: 'table_wins', defaultMessage: 'Wins' })}
        </span>
        <Slider
          marks={{
            0: '1',
            25: '2',
            50: '3',
            75: '4',
            100: '5',
          }}
          value={this.state.winCount}
          tipFormatter={null}
          step={null}
          onChange={this.onChangeWinCount}
        />
      </div>
    );

    const DateGroup = (
      <div key="dateGroup" className="date-group">
        <span className="date-group__label">
          {intl.formatMessage({ id: 'select_date', defaultMessage: 'Select date' })}
        </span>
        <div className="date-group__elements">
          <RadioGroup name="date" defaultValue={1} onChange={this.onChangeDateType} value={this.state.dateType}>
            <Radio value={1}>{intl.formatMessage({ id: 'now', defaultMessage: 'Now' })}</Radio>
            <Radio value={2}>{intl.formatMessage({ id: 'select_date', defaultMessage: 'Select date' })}</Radio>
          </RadioGroup>
          <div className="date-group__pickers">
            <OBDatePicker
              className="date-group__elements__picker"
              placeholder={intl.formatMessage({ id: 'date', defaultMessage: 'Date' })}
              value={this.state.date}
              onChange={this.onChangeDate}
              disabled={this.state.dateType === 1}
              disabledDate={current => current && current < moment().subtract(1, 'days')}
            />
            <OBTimePicker
              className="date-group__elements__picker"
              placeholder={intl.formatMessage({ id: 'time', defaultMessage: 'Time' })}
              value={this.state.time}
              onChange={this.onChangeTime}
              disabled={this.state.dateType === 1}
            />
            {this.state.invalidDate ? <div className="input__error">{this.state.invalidDate}</div> : null}
          </div>
        </div>
      </div>
    );

    const OpponentGroup = (
      <div key="opponentGroup" className="opponent-group">
        <div className="opponent-group__header">
          {intl.formatMessage({ id: 'GameOpponent', defaultMessage: 'Opponent' })}
        </div>
        <Opponent
          userid={opponent.id}
          fullname={`${opponent.firstname} ${opponent.lastname}`}
          nickname={opponent.login}
          flag={opponent.country_live ? opponent.country_live.toLowerCase() : ''}
          photo={opponent.photo}
          avgscore={(+opponent.avgscore || 0).toFixed()}
        />
      </div>
    );

    const CommentGroup = (
      <div key="commentGroup" className="comment-group">
        <span className="comment-group__label">{intl.formatMessage({ id: 'comment', defaultMessage: 'Comment' })}</span>
        <div className="comment-group__elements">
          <TextArea
            value={this.state.comment}
            placeholder={intl.formatMessage({
              id: 'create_battle_comment_placeholder',
              defaultMessage: 'You can leave your comment for other players',
            })}
            onChange={this.onChangeComment}
          />
        </div>
      </div>
    );

    const groups = [BetGroup, WinCountGroup, DateGroup, OpponentGroup, CommentGroup];

    return (
      <OBModal
        title={intl.formatMessage({ id: 'InviteBattle', defaultMessage: 'Invite battle' })}
        visible={this.state.visible}
        width={395}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        zIndex={10000}
      >
        {groups}
      </OBModal>
    );
  }
}

export default injectIntl(
  connect(
    state => ({
      steps: state.common.settings.data.bet_step || { DIG: [] },
    }),
    {},
  )(InviteBattle),
);
