/* eslint-disable no-plusplus */
const IBAN_SYMBOLS = '0123456789abcdefghijklmnopqrstuvwxyz'.split('');
const IBAN_REGEX = /^[A-Z]{2}\d{2}[A-Z\d]{1,30}$/;
/**
 * @param {string} value
 */
const validateIbanValue = value => {
  if (typeof value !== 'string' || !value) {
    return false;
  }
  if (!IBAN_REGEX.test(value)) {
    return false;
  }
  const rawIban = value.split('').map(symbol => IBAN_SYMBOLS.findIndex(v => v === symbol.toLowerCase()).toString());
  for (let i = 0; i < 4; ++i) {
    rawIban.push(rawIban.shift());
  }
  let tmp = '';
  while (rawIban.length) {
    tmp += rawIban.shift();
    tmp = (tmp % 97).toFixed(0);
  }
  return tmp === '1';
};

export default validateIbanValue;
