import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TimePicker } from 'antd';
// import locale from 'antd/lib/time-picker/locale/ru_RU';
// import 'moment/locale/ru';

import './style.css';

export default class OBTimePicker extends Component {
  static propTypes = {
    // format: PropTypes.string,
    minuteStep: PropTypes.number,
  };

  static defaultProps = {
    // format: 'HH:mm',
    minuteStep: 1,
  };

  render() {
    const { minuteStep } = this.props;
    return (
      <TimePicker
        allowEmpty={false}
        format="HH:mm"
        minuteStep={minuteStep}
        {...this.props}
        // locale={locale}
      />
    );
  }
}
