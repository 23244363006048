import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import FriendsFilter from '../filter';
import config from '../../../config';
import Flag from '../../common/Flag';
import { Button } from '../../common/Button';
import SvgCountry from '-!svg-react-loader!../../../images/country.svg'; // eslint-disable-line
import SvgGender from '-!svg-react-loader!../../../images/gender.svg'; // eslint-disable-line
import { DeclineFriend } from '../../../libs/reducers/FriendsReducer';
import defaultAvatar from '../../../images/defaultAvatar.png';
import Avatar from '../../common/Avatar';
import { setCurrentRoom, createPrivateRoom } from '../../../libs/reducers/actions';
import makePrivateChat from '../../../libs/makePrivateChat';
import RatingStars from '../../common/RatingStars';

class OutboxApplications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchtext: '',
      selectCountry: '',
      selectGender: '',
      countryOptions: [],
      genderOptions: [
        { value: 'none', label: 'Not selected...' },
        { value: 'M', label: 'M' },
        { value: 'F', label: 'F' },
      ],
    };
  }

  static propTypes = {
    intl: intlShape,
    friends: PropTypes.arrayOf(PropTypes.shape()),
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = null;
    if (!_.isEmpty(nextProps.friends)) {
      const friendsArr = nextProps.friends.filter(friend => friend.confirmed);
      state = { ...state, friends: friendsArr };
    } else {
      state = { ...state, friends: [] };
    }
    if (!_.isEmpty(nextProps.geodata.data)) {
      if (nextProps.friends.length) {
        let countryOptions = [];
        nextProps.geodata.data.forEach((country) => {
          nextProps.friends.forEach((friend) => {
            if (friend.confirmed === 0) {
              if (friend.user.country === country.iso) {
                countryOptions.push({ value: country.iso, label: country.name });
              }
            }
          });
        });
        countryOptions = _.uniq(countryOptions);
        if (countryOptions.length) {
          countryOptions.unshift({ value: 'none', label: 'Not selected...' });
        }
        state = { ...state, countryOptions };
      }
    }
    return state;
  }

  sendMessageClick = (record) => {
    const {
      userid: from, rooms, _setCurrentRoom, _createPrivateRoom,
    } = this.props;
    const { user: { id: to = 0 } = {} } = record || {};
    console.log('sendClick', record);
    makePrivateChat({
      from,
      to,
      rooms,
      setCurrentRoom: _setCurrentRoom,
      createPrivateRoom: _createPrivateRoom,
    });
  };

  render() {
    const {
      searchtext, selectCountry, selectGender, countryOptions, genderOptions,
    } = this.state;
    const { friends, intl } = this.props;

    const columns = [
      {
        title: intl.formatMessage({ id: 'table_fullname', defaultMessage: 'Surname, name' }),
        dataIndex: 'userName',
        render: (data, record) => (
          <div className="username__container">
            <Avatar
              uri={record.user.photo ? `${config.FETCH.img}/${record.user.photo}` : defaultAvatar}
              flag={null}
              tabled
              isOnline={record.user.active}
              avgscore={(+record.user.avgscore || 0).toFixed()}
            />
            <div>
              {`${record.user.firstname} ${record.user.lastname}`.trim() || `${record.user.login}`}
              <RatingStars rating={+record.user.ratingsavg || 0} height={12} width={12} />
            </div>
          </div>
        ),
      },
      {
        title: intl.formatMessage({ id: 'login2', defaultMessage: 'Login' }),
        dataIndex: 'login',
        render: (data, record) => (
          <Link
            to="#"
            onClick={() => {
              this.props.linkClick(record);
            }}
            className="login__link"
          >
            {record.user.login}
          </Link>
        ),
      },
      {
        title: <SvgCountry />,
        dataIndex: 'flag',
        render: (data, record) => (
          <div className="battles__flag">
            <Flag name={record.user.country} />
          </div>
        ),
      },
      {
        title: <SvgGender />,
        dataIndex: 'gender',
        render: (data, record) => {
          if (record.user.gender === 'M') {
            return intl.formatMessage({ id: 'male', defaultMessage: 'M' });
          }
          if (record.user.gender === 'F') {
            return intl.formatMessage({ id: 'female', defaultMessage: 'F' });
          }
          return intl.formatMessage({ id: 'Other', defaultMessage: 'Other' });
        },
      },
      {
        title: '',
        dataIndex: 'actions',
        render: (data, record) => (
          <div className="buttons__container">
            <div className="buttonmessage__container">
              <Button
                size="md"
                kind="aquamarine"
                bordered="true"
                icon="chatmessage"
                onClick={() => {
                  this.sendMessageClick(record);
                }}
              />
            </div>
            <div className="buttondecline__container">
              <Button
                size="md"
                bordered="true"
                loading={record.user.id === this.props.declinedUser}
                disabled={this.props.fetching}
                onClick={() => {
                  this.props.DeclineFriend(record.user.id);
                }}
              >
                {intl.formatMessage({ id: 'CancelAsAbortAlias', defaultMessage: 'Cancel' })}
              </Button>
            </div>
          </div>
        ),
      },
    ];

    let tempfriends = [];
    if (friends.length) {
      tempfriends = [...friends];
      if (searchtext !== '') {
        tempfriends = tempfriends.filter(
          friend => _.startsWith(`${friend.user.firstname} ${friend.user.lastname}`.toLowerCase(), searchtext.toLowerCase())
            || _.startsWith(friend.user.login.toLowerCase(), searchtext.toLowerCase()),
        );
      }
      if (selectCountry !== '') {
        tempfriends = tempfriends.filter(friend => friend.user.country === selectCountry.value);
      }
      if (selectGender !== '') {
        tempfriends = tempfriends.filter(friend => friend.user.gender === selectGender.value);
      }
    }

    return (
      <div>
        <FriendsFilter
          searchtext={searchtext}
          onChangeInput={(e) => {
            this.setState({
              searchtext: e.target.value,
            });
          }}
          selectCountry={selectCountry}
          selectGender={selectGender}
          onChangeCountry={(e) => {
            if (e.value === 'none') {
              this.setState({
                selectCountry: '',
              });
            } else {
              this.setState({
                selectCountry: e,
              });
            }
          }}
          onChangeGender={(e) => {
            if (e.value === 'none') {
              this.setState({
                selectGender: '',
              });
            } else {
              this.setState({
                selectGender: e,
              });
            }
          }}
          countryOptions={countryOptions}
          genderOptions={genderOptions}
        />
        <div className="friends__table-container">
          <Table
            key={this.props.langs}
            className="friends__table"
            rowClassName="friends__table__row"
            columns={columns}
            dataSource={tempfriends}
            pagination={false}
            rowKey="id"
            locale={{ emptyText: intl.formatMessage({ id: 'FriendApplicationsEmpty', defaultMessage: 'No requests' }) }}
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(
  connect(
    state => ({
      geodata: state.common.geodata,
      langs: state.langs.lang,
      userid: state.users.user.id,
      rooms: state.chat.rooms,
      fetching: state.friends.friends.fetching,
      declinedUser: state.friends.friends.declinedUser,
    }),
    {
      DeclineFriend,
      _createPrivateRoom: createPrivateRoom,
      _setCurrentRoom: setCurrentRoom,
    },
  )(OutboxApplications),
);
