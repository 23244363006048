import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Input from '../../common/Input';
import Radio from '../../common/Radio';
import Checkbox from '../../common/Checkbox';
import { Button } from '../../common/Button';
import TextArea from '../../common/TextArea';
import {
  sendNotificationEvent,
} from '../../../libs/reducers/adminReducer';

const RadioGroup = Radio.Group;

class Event extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notification: {
        platform: 'all',
        users: 'test',
        emails: '',
        description: '',
        push: false,
      },
      invalidEmails: '',
      visibleReport: false,
    };
  }

  sendNotification = () => {
    this.setState({
      invalidEmails: '',
    });
    const { notification } = this.state;
    const { intl, sendNotificationEvent } = this.props;

    if (notification.users === 'specific' && !notification.emails) {
      return this.setState({
        invalidEmails: intl.formatMessage({ id: 'requiredField', defaultMessage: 'Required field' }),
      });
    }

    const data = {
      ...notification,
      toTestGroup: notification.users === 'test',
      emails: (notification.emails) ? JSON.stringify(notification.emails.replace(/\s+/g, '').split(',')) : null,
    };
    this.setState({ visibleReport: false });
    sendNotificationEvent(data);

    this.setState({
      notification: {
        platform: 'all',
        users: 'test',
        emails: '',
        description: '',
        push: false,
      },
    })
  }

  render() {
    const {
      notificationEvent,
    } = this.props;
    const { report } = notificationEvent.data || {};
    const {
      notification,
      invalidEmails,
      visibleReport,
    } = this.state;

    return (
      <div className="event__container">
        <div className="ap-row">
          <div className="ap-input__col ap-col">
            <p className="ap-input__label">Os</p>
            <div className="ap-input__elements">
              <RadioGroup name="platform" defaultValue="all" onChange={e => this.setState({ notification: { ...notification, ...{ platform: e.target.value } } })} value={notification.platform}>
                <Radio value="all">To all</Radio>
                <Radio value="android">Android</Radio>
                <Radio value="ios">iOS</Radio>
              </RadioGroup>
            </div>
          </div>
        </div>
        <div className="radio-group-users">
          <p className="ap-input__label">Users</p>
          <div className="ap-row">
            <div className="ap-col ap-input__elements">
              <RadioGroup name="users" defaultValue="test" onChange={e => this.setState({ notification: { ...notification, ...{ users: e.target.value } } })} value={notification.users}>
                <Radio value="test">Test</Radio>
                <Radio value="all">To all</Radio>
                <Radio value="specific">Specific</Radio>
              </RadioGroup>
            </div>
          </div>
        </div>
        { (notification.users === 'specific')
          ? (
            <div className="ap-row emails">
              <div className="ap-col">
                <Input
                  onChangeText={text => this.setState({ notification: { ...notification, ...{ emails: text } } })}
                  value={notification.emails}
                  placeholder="E-mail"
                  name="email"
                />
                <p className="ap-input__description">List of email users separated by commas</p>
                {invalidEmails ? <div className="input__error">{invalidEmails}</div> : null}
              </div>
            </div>
          ) : null
        }
        <div className="ap-row">
          <div className="ap-col">
            <TextArea
              value={notification.description}
              rows={15}
              placeholder="Описание"
              onChange={({ value }) => this.setState({ notification: { ...notification, ...{ description: value } } })}
            />
            <p className="ap-input__description">Description with html tags</p>
          </div>
        </div>
        <div className="ap-row">
          <div className="ap-col">
            <Checkbox
              onChange={check => this.setState({ notification: { ...notification, ...{ push: check } } })}
              checked={notification.push}
            >Send notifications</Checkbox>
          </div>
        </div>
        <div className="ap-btn--center ap-btn--margin-top">
          <Button
            className={'submit'}
            onClick={() => this.sendNotification()}
            loading={notificationEvent.fetching}
            disabled={!notification.push}
          >Send</Button>
        </div>
        {report && (
          <div className="ap-btn--center">
            <Button
              className={'submit'}
              onClick={() => this.setState({ visibleReport: !visibleReport })}
            >{visibleReport ? 'Close' : 'Open'}</Button>
          </div>
        )}
        {visibleReport && (
          <div className="report__container">
            <div className="report__header">
              Report
            </div>
            {(report.success && Array.isArray(report.success.email) && report.success.email.length && (
              <div className="report__section">
                <div className="section__header">
                  Success emails
                </div>
                <div className="section-value__list">
                  <ol>
                    {report.success.email.map((emailReport, index) => (
                      <li key={index}>{emailReport}</li>
                    ))}
                  </ol>
                </div>
              </div>
            )) || ''}
            {(report.success && Array.isArray(report.success.push) && report.success.push.length && (
              <div className="report__section">
                <div className="section__header">
                  Success push
                </div>
                <div className="section-value__list">
                  <ol>
                    {report.success.push.map((pushReport, index) => (
                      <li key={index}>{pushReport}</li>
                    ))}
                  </ol>
                </div>
              </div>
            )) || ''}
            {(report.error && Array.isArray(report.error.email) && report.error.email.length && (
              <div className="report__section">
                <div className="section__header">
                  Failed send emails
                </div>
                <div className="section-value__list">
                  <ol>
                    {report.error.email.map((emailReport, index) => (
                      <li key={index}>{emailReport}</li>
                    ))}
                  </ol>
                </div>
              </div>
            )) || ''}
            {(report.error && Array.isArray(report.error.push) && report.error.push.length && (
              <div className="report__section">
                <div className="section__header">
                  Failed send push
                </div>
                <div className="section-value__list">
                  <ol>
                    {report.error.push.map((pushReport, index) => (
                      <li key={index}>{pushReport}</li>
                    ))}
                  </ol>
                </div>
              </div>
            )) || ''}
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    notificationEvent: state.admin.notificationEvent,
  }),
  {
    sendNotificationEvent,
  }
) (injectIntl(Event));
