const { SET_USER_DISK, FETCH_USER_DISK } = require('./actiontypes').default;

const initialState = {
  data: {},
  error: '',
  isFetching: false,
};

const userDiskReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_DISK:
      return { ...state, error: '', isFetching: true };
    case SET_USER_DISK:
      if (action.error) {
        return { ...state, error: action.error, isFetching: false };
      }
      return { ...state, data: action.payload, isFetching: false };      
    default:
      return state;
  }
};

export default userDiskReducer;