import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Hls from 'hls.js';
import { VideoPlayer } from './index';
import bugsnagClient from '../../../bugsnagClient';

function dummy() {}

class HLSPlayer extends Component {
  constructor(props) {
    super(props);
    if (!Hls.isSupported()) {
      const error = 'Your browser does not support Media Source Extension (MSE)';
      bugsnagClient.notify(new Error(error), { context: 'HLSPlayer' });
    }
    this.state = {};
    this.logger = {
      log: props.debug ? console.log : function log() {},
      error: console.error,
      warn: console.warn,
      info: console.info,
    };
    // this.logger.log('HlsPlayer::ctor', props.url);
    this.ref = React.createRef();
  }

  componentWillUnmount() {
    this._destroyHls();
    // this.logger.log('HlsPlayer::dtor');
  }

  _destroyHls = () => {
    const { hls } = this;
    if (!hls) return;
    hls.stopLoad();
    hls.detachMedia();
    hls.destroy();
  };

  _init = (media, url, paused) => {
    this._destroyHls();
    const hls = new Hls({ debug: false });
    hls.loadSource(url);
    hls.attachMedia(media);
    if (!paused) {
      hls.on(Hls.Events.MANIFEST_PARSED, () => media.play());
    }
    const { range } = this.props;
    const that = this;
    const listener = function listener() {
      // this.logger.log('[+] HLSPlayer::_init() -> setRange');
      that.setRange(media, range);
      media.removeEventListener('durationchange', listener);
    };
    media.addEventListener('durationchange', listener);
    hls.on(Hls.Events.LEVEL_LOADED, () => {
      const [
        {
          details: { totalduration: duration },
        },
      ] = hls.levels;
      // this.logger.log('hls.levels:', hls.levels);
      // this.logger.log('hls.coreComponents:', hls.coreComponents);
      // this.logger.log('hls.audioTracks:', hls.audioTracks);
      // this.logger.log('hls.videoTracks:', hls.videoTracks);
      // this.logger.log(`[+] HLSPlayer::init, media.duration = ${media.duration}, range = ${range}, totalduration = ${duration}`);
      if (typeof this.props.mediaDuration === 'function') {
        this.props.mediaDuration(duration);
      }
    });
    this.hls = hls;
  };

  restart = () => {
    const { media } = this.state;
    const { url, paused } = this.props;
    this._init(media, url, paused);
  };

  setPlayer = (media, url, paused, range) => {
    const { hls } = this;
    if (!media) return;
    if (!hls || (hls.url !== url && url)) {
      this._init(media, url, paused, range);
    } else {
      this.setPaused(media, paused, url);
    }
  };

  isLive = () => {
    const { url } = this.props;
    return url ? !/video-\d+-\d+/.test(url) : true;
  };

  setPaused = (media, paused, url) => {
    // this.logger.log(`[+] setPaused() -> media set paused = ${paused} (${media ? media.paused : undefined}), media.currentTime = ${media ? media.currentTime : undefined}`);
    const { hls } = this;
    if (paused) {
      // this.logger.log('[+] setPaused() -> media.pause()');
      media.pause();
      if (hls) {
        // this.logger.log('[+] setPaused() -> hls.stopLoad()');
        hls.stopLoad();
      }
    } else if (media.paused) {
      if (this.isLive()) {
        // live video
        // this.logger.log('[+] setPaused() -> [live] this._init()');
        this._init(media, url, paused);
        if (media.duration !== Infinity && !isNaN(media.duration)) {
          // this.logger.log(`[+] setPaused() -> [live] hls.startLoad(${media.currentTime})`);
          hls.startLoad(media.currentTime);
        }
      } else if (hls) {
        // archive video
        // this.logger.log(`[+] setPaused() -> [archive] hls.startLoad(${media.currentTime})`);
        hls.startLoad(media.currentTime);
        // this.logger.log('[+] setPaused() -> [archive] media.play()');
        media.play();
      }
    }
    // this.logger.log('_____________________\n[+] setPaused() -> end');
  };

  getCurrentTime = () => {
    const { media } = this.state;
    if (media) {
      return media.currentTime;
    }
    return 0;
  };

  getDuration = () => {
    const { media } = this.state;
    if (media) {
      return media.duration;
    }
    return Infinity;
  };

  onMedia = (media) => {
    this.setState({ media });
  };

  onMuted = muted => this._callPropsFunc('onMuted', muted);

  onPaused = paused => this._callPropsFunc('onPaused', paused);

  onWaiting = (event) => {
    const media = event.target;
    const { hls } = this;
    if (!media.paused && hls) {
      hls.startLoad(media.currentTime);
    }
  };

  setRange = (media, range) => {
    // this.logger.log(`[+] setRange(${range})`);
    const { hls } = this;
    if (
      !hls
      || !media
      || media.duration === Infinity
      || isNaN(media.duration)
      || this.isLive()
      || range === Infinity
      || isNaN(range)
    ) {
      return;
    }
    const x = range / 1000;
    const time = media.duration * x;
    // this.logger.log(`[+] setRange() -> duration = ${media.duration}, time = ${time.toFixed(2)}, range = ${x}`);
    // this.logger.log(`[+] setRange() -> media.paused = ${media.paused}`);
    // this.logger.log(`[+] setRange() -> hls.startLoad(${time.toFixed(2)})`);
    hls.startLoad(time);
    // this.logger.log('[+] setRange() -> set media.currentTime');
    media.currentTime = time;
    // this.logger.log('________________________\n[+] setRange() -> end');
  };

  onVolumeChange = volume => this._callPropsFunc('onVolumeChange', volume);

  onControlsChange = controls => this._callPropsFunc('onControlsChange', controls);

  _callPropsFunc = (func, ...args) => {
    if (typeof this.props[func] === 'function') {
      this.props[func](...args);
    }
  };

  onRangeChange = (value) => {
    const { onRangeChange } = this.props;
    // this.logger.log('[+] HLSPlayer::onRangeChange:', value);
    onRangeChange(value);
  };

  updateRange = (range) => {
    const { updateRange = dummy } = this.props;
    updateRange(range);
  };

  render() {
    const {
      id,
      url,
      paused,
      autoPlay,
      volume,
      muted,
      controls,
      style,
      styleDIV,
      range = 1000,
      hideSlider = false,
      isLive,
      debug,
    } = this.props;
    const { media } = this.state;
    this.setPlayer(media, url, paused);
    this.setRange(media, range);
    return (
      <VideoPlayer
        id={id}
        autoPlay={autoPlay}
        originalUrl={url}
        isLive={isLive}
        muted={muted}
        volume={volume}
        range={range}
        debug={debug}
        controls={controls}
        hideSlider={hideSlider}
        style={style} // <video /> style
        styleDIV={styleDIV} // <div /> style
        updateRange={this.updateRange}
        onMedia={this.onMedia}
        onMuted={this.onMuted}
        onPaused={this.onPaused}
        onWaiting={this.onWaiting}
        onVolumeChange={this.onVolumeChange}
        onControlsChange={this.onControlsChange}
        onRangeChange={this.onRangeChange}
        onReadyForDisplay={this.props.onReadyForDisplay}
        onStreamEnd={this.props.onStreamEnd}
        ref={(c) => {
          if (c && typeof c.getWrappedInstance === 'function') {
            this.ref = c.getWrappedInstance();
          } else {
            this.ref = c;
          }
        }}
      />
    );
  }
}

HLSPlayer.isSupported = Hls.isSupported;
Object.defineProperties(HLSPlayer, {
  name: {
    value: 'hls-player',
    enumerable: true,
  },
  isHLS: {
    value: true,
    enumerable: true,
  },
  isHls: {
    get() {
      return this.isHLS;
    },
    enumerable: true,
  },
});

HLSPlayer.defaultProps = {
  style: { width: '50%' },
  styleDIV: {},
  volume: 0.75,
  muted: false,
  controls: false,
  paused: true,
  autoPlay: true,
};

HLSPlayer.propTypes = {
  id: PropTypes.any,
  url: PropTypes.string,
  range: PropTypes.number,
  muted: PropTypes.bool,
  paused: PropTypes.bool,
  volume: PropTypes.number,
  controls: PropTypes.bool,
  autoPlay: PropTypes.bool,
  style: PropTypes.shape(),
  styleDIV: PropTypes.object,
  onMuted: PropTypes.func,
  onPaused: PropTypes.func,
  onVolumeChange: PropTypes.func,
  onRangeChange: PropTypes.func.isRequired,
};

export default HLSPlayer;
