import React, {useEffect, useRef, useState} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Button } from '../../common/Button';
import moment from 'moment';
import '../../../style.scss';
import Input from '../../common/Input';
import { Table, Modal } from 'antd';
import Radio from '../../common/Radio';
import ImageZoomer from '../imageZoomer';
import TextArea from '../../common/TextArea';
import {
  getUserDocById,
  acceptDocVerification,
  refuseDocVerification,
  getUserDocsAll,
} from '../../../libs/reducers/adminReducer';


const Verification = ({
  admin,
  getUserDocById,
  acceptDocVerification,
  refuseDocVerification,
  intl,
  common,
  getUserDocsAll,
}) => {

  const [tablefilters, setTablefilters] = useState({})
  const [lastfilter, setLastfilter] = useState(null)
  const [filterorder, setFilterorder] = useState('asc')
  const [modals, setModals] = useState({})
  const [docsSearchString, setDocsSearchString] = useState('')
  const [isDocsModalShow, setIsDocsModalShow] = useState(false)
  const [docImages, setDocImages] = useState(admin.userDocById && admin.userDocById.docImages || [])
  const [userImages, setUserImages] = useState(admin.userDocById && admin.userDocById.userImages || [])
  const [isDocVerified, setIsDocVerified] = useState(false)
  const [comment, setComment] = useState('')

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current === undefined ? value : ref.current;
  }
  const prevProps = usePrevious({admin})

  useEffect(() => {
    getUserDocsAll()
  },[])

  useEffect(() => {
    if (prevProps.admin.userDocById && prevProps.admin.userDocById.id !== admin.userDocById.id) {
      setDocImages(admin.userDocById.docImages || [])
      setUserImages(admin.userDocById.userImages || [])
      setIsDocVerified(false)
    }
  },[admin])

  const createTitle = (title, field, sortable, filterable = true, alwaysActive) => {

    return <div className='adminTableHeader'>
      {filterable && <div className={`searchField${tablefilters[field] || alwaysActive ? ' active' : ''}`}>
        <svg onClick={() => setModals({ ...modals, [field]: !modals[field]})} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.45313 13.0938C5.45313 13.3703 5.675 13.5938 5.95 13.5938H10.05C10.325 13.5938 10.5469 13.3703 10.5469 13.0938V10.0312H5.45313V13.0938ZM13.7516 2.40625H2.24844C1.86563 2.40625 1.62656 2.82344 1.81875 3.15625L5.27656 9.03125H10.7266L14.1844 3.15625C14.3734 2.82344 14.1344 2.40625 13.7516 2.40625Z" fill="#f65932" />
        </svg>
      </div>}
      <div className='filterBody' onClick={sortable ? () => lastfilter === field 
            ? setFilterorder(filterorder === 'asc' ? 'desc' : 'asc')
            : setLastfilter(field)
          : () => {}}>
        <span>{title}</span>
        <div className='filterState'>{field === lastfilter ? <div className={filterorder === 'asc' ? 'up' : 'down'} /> : null}</div>
      </div>
    </div>
  }

  const { userDocById } = admin;

  const onSwapImages = (index, type) => {
    let images = type === 'doc' ? docImages : userImages;
    const zero = images[0];
    images[0] = images[index];
    images[index] = zero;
    if (type === 'doc') {
      setDocImages(images)
    } else {
      setUserImages(images)
    }
  }

  let doclistcolumns = [
    {
      title: createTitle(intl.formatMessage({ id: 'applicationPlacementDatetime', defaultMessage: 'Date and time of application placement' }), 'created', false, false),
      col: intl.formatMessage({ id: 'applicationPlacementDatetime', defaultMessage: 'Date and time of application placement' }),
      field: 'created',
      dataIndex: 'date',
      render: (data, record, index) => (
        <div className="user_info">
          <div className="user_name">
            {record.created ? moment(record.created).format('DD.MM.YYYY - HH:mm') : 'Not set'}
          </div>
        </div>
      )
    },
    {
      title: createTitle(intl.formatMessage({ id: 'FirstnameLastnameOfPlayer', defaultMessage: 'First name and last name of player' }), 'username', false, false),
      col: intl.formatMessage({ id: 'FirstnameLastnameOfPlayer', defaultMessage: 'First name and last name of player' }),
      field: 'username',
      render: (data, record, index) => (
        <div className="user_info">
          <div className="user_name">
            {record.name ? record.name : 'Not set'}
          </div>
        </div>
      )
    },
    {
      title: createTitle(intl.formatMessage({ id: 'FriendRouterFilterCountry', defaultMessage: 'Country' }), 'country', false, false),
      col: intl.formatMessage({ id: 'FriendRouterFilterCountry', defaultMessage: 'Country' }),
      field: 'country',
      render: (data, record, index) => (
        <div className='user_info'>
          <div className="user_name">
            {record.country}
          </div>
        </div>
      )
    },
    {
      title: createTitle(intl.formatMessage({ id: 'StatusAsStateAlias', defaultMessage: 'Status' }), 'status', false, false),
      col: intl.formatMessage({ id: 'StatusAsStateAlias', defaultMessage: 'Status' }),
      field: 'status',
      render: (data, record, index) => (
        <div className="user_info">
          <div className="user_name">
            {record.status}
          </div>
        </div>
      )
    },
    {
      title: createTitle(intl.formatMessage({ id: 'lastActivityDatetime', defaultMessage: 'Date and time of the last activity' }), 'lastaction', false, false),
      col: intl.formatMessage({ id: 'lastActivityDatetime', defaultMessage: 'Date and time of the last activity' }),
      field: 'lastaction',
      render: (data, record, index) => (
        <div className="user_info">
          <div className="user_name">
            {record.lastupdated ? moment(record.lastupdated).format('DD.MM.YYYY - HH:mm') : 'Not set'}
          </div>
        </div>
      )
    },
    {
      title: createTitle(intl.formatMessage({ id: 'UserDocsReviewer', defaultMessage: 'Reviewer' }), 'adminid', false, false),
      col: intl.formatMessage({ id: 'UserDocsReviewer', defaultMessage: 'Reviewer' }),
      field: 'adminid',
      render: (data, record, index) => (
        <div className="user_info">
          <div className="user_name">
            {record.adminid ? record.adminid : intl.formatMessage({ id: 'UserDocsRewiewerNotAssigned', defaultMessage: 'Not assigned' })}
          </div>
        </div>
      )
    },
    {
      title: createTitle(intl.formatMessage({ id: 'comment', defaultMessage: 'Comment' }), 'comment', false, false),
      col: intl.formatMessage({ id: 'comment', defaultMessage: 'Comment' }),
      field: 'comment',
      render: (data, record, index) => (
        <div className="user_info">
          <div className="user_name">
            {record.comment.length ? record.comment[0].comment : '-'}
          </div>
        </div>
      )
    },
    {
      title: '',
      col: '',
      field: '',
      render: (data, record, index) => (
        <div className="docs_view">
          <Button
            key="docButton"
            kind="aquamarine"
            style={{ padding: 0, height: 30 }}
            onClick={() => {
              getUserDocById(record.id)
              setIsDocsModalShow(true)
            }}>
            <div style={{
              fontFamily: 'Open Sans',
              fontSize: 14,
              textAlign: 'center',
            }}>{intl.formatMessage({ id: 'CheckApplication', defaultMessage: 'Check application' })}</div>
          </Button>
        </div>
      )
    }
  ]

  let docListData = admin.userDocs && Array.isArray(admin.userDocs) && (admin.userDocs)
    .map(item => {
      let displayStatus = intl.formatMessage({ id: 'UserDocsNewApplication', defaultMessage: 'New' });
      switch (item.status) {
        // case 'new':
        //   displayStatus = intl.formatMessage({ id: 'UserDocsNewApplication', defaultMessage: 'New' })
        //   break;
        case 'bug':
          displayStatus = intl.formatMessage({ id: 'UserDocsNotPassed', defaultMessage: 'Not passed' })
          break;
        case 'verified':
          displayStatus = intl.formatMessage({ id: 'UserDocsPassed', defaultMessage: 'Passed' })
          break;
        default: break;
      }
      item.status = displayStatus;
      const country = common.geodata.data.find(country => country.iso.toLowerCase() === item.country.toLowerCase());
      item.country = country ? country.name : item.country;
      return item;
    })

  const filtered = docListData && docListData.length && docListData.filter(item => (
    moment(item.created).format('DD.MM.YYYY - HH:mm').toLowerCase().includes(docsSearchString.toLowerCase())
    || item.name.toLowerCase().includes(docsSearchString.toLowerCase())
    || item.status.toLowerCase().includes(docsSearchString.toLowerCase())
    || item.country.toLowerCase().includes(docsSearchString.toLowerCase())
    || moment(item.lastupdated).format('DD.MM.YYYY - HH:mm').toLowerCase().includes(docsSearchString.toLowerCase())
    || (item.comment && item.comment.length && item.comment[0].comment.toLowerCase().includes(docsSearchString.toLowerCase()))
    || (item.adminid ? item.adminid : intl.formatMessage({ id: 'UserDocsRewiewerNotAssigned', defaultMessage: 'Not assigned' })).toLowerCase().includes(docsSearchString.toLowerCase())
  ));

  return (
    <div className='admin_panelWrapper'>
      <div className='admin-verification__filter'>
        <div>
          <Input
            type="text"
            name="userDocsFilter"
            onChangeText={value => setDocsSearchString(value)}
            value={docsSearchString}
            placeholder={intl.formatMessage({ id: 'SearchAsActionAlias', defaultMessage: 'Search' })}
          />
          <Button
            style={{ marginLeft: '10px' }}
            key="okButton"
            kind="aquamarine"
            bordered="true"
            onClick={(e) => setDocsSearchString('')}
          >
            {intl.formatMessage({ id: 'reset', defaultMessage: 'Reset' })}
          </Button>
        </div>
      </div>
      <Table
        className="table docslist"
        columns={doclistcolumns}
        dataSource={filtered}
        pagination={false}
        rowKey="id"
        style={{ width: '100%' }}
        locale={{ emptyText: 'No data' }}
        rowClassName={'doc_row'}
      />
      {isDocsModalShow && admin.userDocById !== {} &&
        <Modal
          title={'Verification'}
          visible={isDocsModalShow}
          closable={false}
          className="docs_viewer"
          width={790}
          footer={<div style={{ flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '20px', marginTop: '5px' }}>
              <span style={{
                marginRight: 20,
                fontFamily: 'Oswald',
                fontSize: 16,
                letterSpacing: 0.05,
                color: '#FFFFFF',
              }}>
                {intl.formatMessage({ id: 'MyAccountRouterVerificationTitle', defaultMessage: 'Verification' })}
              </span>
              <Radio
                checked={!isDocVerified}
                onClick={() => setIsDocVerified(false)}
                style={{ margin: 0 }}
              >
                <div
                  style={{
                    marginRight: 20,
                    marginBottom: 0,
                    fontFamily: 'Open Sans',
                    fontSize: 16,
                    color: '#FFFFFF',
                    flex: 0,
                    // display: 'contents',
                  }}>
                  {intl.formatMessage({ id: 'UserDocsNotPassed', defaultMessage: 'Not passed' })}
                </div>
              </Radio>
              <Radio
                checked={isDocVerified}
                onClick={() => setIsDocVerified(true)}
                style={{ margin: 0 }}
              >
                <div
                  style={{
                    marginRight: 20,
                    marginBottom: 0,
                    fontFamily: 'Open Sans',
                    fontSize: 16,
                    color: '#FFFFFF',
                    flex: 0,
                  }}>
                  {intl.formatMessage({ id: 'UserDocsPassed', defaultMessage: 'Passed' })}
                </div>
              </Radio>
            </div>
            <div style={{ display: 'flex' }}>
              <Button
                style={{ flex: '1 1 50%', marginRight: 11 }}
                key="okButton"
                kind="aquamarine"
                bordered="true"
                onClick={() => setIsDocsModalShow( false)}
              >
                {intl.formatMessage({ id: 'close', defaultMessage: 'Close' })}
              </Button>
              <Button
                style={{ flex: '1 1 50%' }}
                key="okButton"
                kind="aquamarine"
                onClick={() => {
                  if (isDocVerified) {
                    acceptDocVerification({ docid: userDocById.id })
                    setIsDocsModalShow(false)
                    setComment('')
                  } else {
                    if (comment.length) {
                      refuseDocVerification({ docid: userDocById.id, comment: comment })
                      setIsDocsModalShow(false)
                      setComment('')
                    } else {
                      alert('COMMENT IS REQUIRE!')
                    }
                  }
                }}
              >
                {intl.formatMessage({ id: 'SaveAsKeepAlias', defaultMessage: 'Save' })}
              </Button>
            </div>
          </div>
          }
        >
          <div className="modal-group">
            <div className="docs_viewer">
              <span>{intl.formatMessage({ id: 'UserDocsData', defaultMessage: 'Document data' })}</span>
              <div className="docs_data">
                {[
                  { name: `${intl.formatMessage({ id: 'IBANNameCol', defaultMessage: 'Name' })} ${intl.formatMessage({ id: 'Surname', defaultMessage: 'Surname' })}`, label: userDocById.name },
                  { name: intl.formatMessage({ id: 'PersonalInfoDOB', defaultMessage: 'Date of birth' }), label: moment(userDocById.dob).format('DD MMM YYYY') },
                  { name: intl.formatMessage({ id: 'DocumentNumber', defaultMessage: 'Document Number' }), label: userDocById.docnum },
                  { name: intl.formatMessage({ id: 'IssuingCountry', defaultMessage: 'Issuing country' }), label: userDocById.country },
                  { name: intl.formatMessage({ id: 'DocumentsShelfLife', defaultMessage: 'Shelf life' }), label: moment(new Date(+userDocById.expiryyear, +userDocById.expirymonth, 28, 0, 0, 0, 0)).format('MMM YYYY') }
                ].map(item => (
                  <div className="docs_viewer_block">
                    <span>{item.name}</span>
                    <div className="docs_viewer_field">
                      <span>{item.label}</span>
                    </div>
                  </div>))}
              </div>
              <span>{intl.formatMessage({ id: 'DocumentPhoto', defaultMessage: 'Document photo ' })}</span>
              <ImageZoomer
                images={docImages || []}
                type={'doc'}
                onSwapImages={onSwapImages}
              />
              <span>{intl.formatMessage({ id: 'PlayerPhoto', defaultMessage: 'Player Photo' })}</span>
              <ImageZoomer
                images={userImages || []}
                type={'user'}
                onSwapImages={onSwapImages}
              />
              {userDocById.comments && userDocById.comments.length
                ? <><span>{intl.formatMessage({ id: 'AdminComment', defaultMessage: 'Admin comment' })}</span>
                  {userDocById.comments.map(comment => (
                    <div className="docs_viewer_comment">
                      <div className="docs_viewer_comment_header">
                        <span>{comment.adminid}</span>
                        <span>{moment(comment.created).format('DD MMM YYYY, HH:mm')}</span>
                      </div>
                      <span>{comment.comment}</span>
                    </div>
                  ))}
                </>
                : null}
              <div className="docs_viewer_comment_write">
                <span>{intl.formatMessage({ id: 'PartyComment', defaultMessage: 'Comment on decision' })}</span>
                <TextArea
                  style={{ height: 74, width: 739, marginTop: 5 }}
                  value={comment}
                  onChange={(e) => setComment(e.value)}
                />
              </div>
            </div>
          </div>
        </Modal>}
    </div>
  )
}

export default injectIntl(withRouter(
  connect(
    state => ({
      admin: state.admin,
      common: state.common,
    }),
    {
      getUserDocById,
      acceptDocVerification,
      refuseDocVerification,
      getUserDocsAll,
    },
  )(Verification),
));
