import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import moment from 'moment';
import { injectIntl, intlShape } from 'react-intl';

import config from '../../../config';
import 'flag-icon-css/css/flag-icon.css';
import Flag from '../../common/Flag';
import PasswordChangeModal from '../../common/modals/modalPassEdit';
import RatingStars from '../../common/RatingStars';
// import toIso from './iso-country';

const defaultAvatar = '/static/media/default.2d4c81eb.png';
class PersonalInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passChange: false,
      passChanged: false,
    };
  }

  static propTypes = {
    userInfo: PropTypes.shape(),
    onPressEdit: PropTypes.func,
    intl: intlShape,
  };

  onPassChange = () => {
    this.setState({
      passChange: true,
    });
  }

  onPassSend = () => {
    this.setState({
      passChange: false,
    });
  }

  render() {
    const {
      userInfo = {}, onPressEdit, geodata, timezones, intl,
    } = this.props;
    const { passChange } = this.state;
    const { onPassChange, onPassSend } = this;
    const {
      login = '',
      firstname,
      lastname,
      dob,
      gender,
      hourshift,
      country_live: countryLive,
      country_federation: countryFederation,
      photo: propsPhoto,
      club,
      ratingsavg,
      avgscore,
    } = userInfo;
    let country = '';
    let federation = '';
    let timezone = '';
    geodata.data.forEach((elem) => {
      if (elem.iso === countryLive) {
        country = elem.name;
      }
      if (elem.iso === countryFederation) {
        federation = elem.name;
      }
    });
    timezones.data.forEach((elem) => {
      if (elem.id === hourshift) {
        let str = `${elem.name}, `;
        if (elem.rawoffset === 0) {
          str = `${str}+00:00`;
        }
        if (elem.rawoffset > 0) {
          str = `${str}+${elem.rawoffset}`;
        }
        if (elem.rawoffset < 0) {
          str = `${str}${elem.rawoffset}`;
        }
        str = str.replace('.5', ':30');
        str = str.replace('.75', ':45');
        str = str.replace('.25', ':15');
        timezone = str;
      }
    });

    // const flag = toIso(countryFederation);
    return (
      <div className="personal-outlet">
        <div className="personal-outlet__header">
          <div className="user-info">
            <div className="user-info__avatar">
              {avgscore ? <div className='avgScore'>{(+avgscore || 0).toFixed()}</div> : null}
              <div className="avatar__wrap">
                <img src={propsPhoto ? `${config.FETCH.img}/${propsPhoto}?v=${Math.random()}` : defaultAvatar} alt="" />
              </div>
              <div className="city-flag">
                <Flag name={countryLive} />
              </div>
            </div>
            <div className="user-info__text">
              <div className="text_full-name">
                <span>{`${firstname} ${lastname}`}</span>
                <RatingStars rating={+ratingsavg || 0} ratingNumberStyle={{ fontSize: '22px' }} />
              </div>
              <div className="display_name">
                <span>{login}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="personal-outlet__body">
          <div className="user-data">
            <div className="row__group">
              <div className="row__title" htmlFor="">
                {intl.formatMessage({ id: 'FriendRouterFilterGender', defaultMessage: 'Gender' })}
              </div>
              <span className="row__text">{gender}</span>
            </div>
            <div className="row__group">
              <div className="row__title" htmlFor="">
                {intl.formatMessage({ id: 'PersonalInfoDOB', defaultMessage: 'Date of birth' })}
              </div>
              <span className="row__text">{moment(dob).format('D MMM YYYY')}</span>
            </div>
            <div className="row__group">
              <div className="row__title" htmlFor="">
                {intl.formatMessage({ id: 'PersonalInfoCountryLiving', defaultMessage: 'Country living' })}
              </div>
              <span className="row__text">{country}</span>
            </div>
            <div className="row__group">
              <div className="row__title" htmlFor="">
                {intl.formatMessage({ id: 'PersonalInfoTimeZone', defaultMessage: 'Timezone' })}
              </div>
              <span className="row__text">{timezone}</span>
            </div>
            <div className="row__group">
              <div className="row__title" htmlFor="">
                {intl.formatMessage({ id: 'PersonalInfoFederationCountry', defaultMessage: 'Federation country' })}
              </div>
              <span className="row__text">{federation}</span>
            </div>
            <div className="row__group">
              <div className="row__title" htmlFor="">
                {intl.formatMessage({ id: 'PersonalInfoClub', defaultMessage: 'Club' })}
              </div>
              <span className="row__text">{club && club !== '' ? club : '-'}</span>
            </div>
            <div className="row__group">
              <button onClick={onPressEdit} className="row__button row__button_submit">
                <Icon type="edit" />
                <span>{intl.formatMessage({ id: 'EditAsChangeAlias', defaultMessage: 'Edit' })}</span>
              </button>
              <button onClick={onPassChange} className="row__button row__button_submit passchange_button">
                {intl.formatMessage({ id: 'PersonalInfoChangePassword', defaultMessage: 'Change password' })}
              </button>
            </div>
          </div>
        </div>
        <PasswordChangeModal
          visible={passChange}
          newpassHandle={onPassSend}
        />
      </div>
    );
  }
}

export default injectIntl(PersonalInfo);
