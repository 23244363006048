import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import _ from 'lodash';
import Frame from '../Frame';

import './style.css';

class GameScore extends Component {
  static propTypes = {
    /** Массив с фреймами */
    frames: PropTypes.arrayOf(
      PropTypes.shape({
        isCurrent: PropTypes.bool,
        scores: PropTypes.arrayOf(
          PropTypes.shape({
            trynumber: PropTypes.number,
            skittles: PropTypes.number,
            flags: PropTypes.string,
          }),
        ),
        frameScore: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
    ),
    currentFrame: PropTypes.number.isRequired,
    /** Результат игрока или соперника */
    isOwner: PropTypes.bool,
    /** Результат для игрока за предыдущие игры */
    previousGameScore: PropTypes.number.isRequired,
    changingFrame: PropTypes.number,
    isEditActive: PropTypes.bool,
    selectedFrame: PropTypes.number,
    onFrameSelect: PropTypes.func,
    tmpFrame: PropTypes.any,
    intl: intlShape,
  };

  static defaultProps = {
    isEditActive: false,
    tmpFrame: null,
  };

  parseFrames = (frames) => {
    const parsedFrames = [];
    for (let i = 0; i < 10; i += 1) {
      if (frames[i]) {
        const scores = _.chain(frames[i])
          .pickBy((item, key) => item !== -1 && /shot\d/.test(key))
          .toArray()
          .value();
        parsedFrames.push({
          ...frames[i],
          scores,
          realScore:
            frames[i].score === 0 && scores.length && scores.every(s => s === 0)
              ? frames[i].score
              : frames[i].score || '',
        });
      } else {
        parsedFrames[i] = {
          framenumber: i + 1,
          realScore: '',
        };
      }
    }
    return parsedFrames;
  };

  getTotalScore = (frames) => {
    let totalScores = '';
    frames.forEach((frame) => {
      if (frame.realScore !== '') {
        totalScores = frame.realScore;
      }
    });
    return totalScores;
  };

  handleFrameSelect = frame => () => {
    const { onFrameSelect } = this.props;
    if (onFrameSelect) {
      onFrameSelect(frame);
    }
  };

  render() {
    const {
      frames,
      isOwner,
      previousGameScore,
      currentFrame,
      isEditActive,
      selectedFrame,
      selectedFrameId,
      changingFrame,
      tmpFrame,
      intl,
      frameScoreSumStyles,
      frameNumberStyles,
      frameStyles,
    } = this.props;
    const parsedFrames = this.parseFrames(frames);
    const totalScores = this.getTotalScore(parsedFrames);
    return (
      <div className={`game-score${isEditActive ? ' edit' : ''}`}>
        {_.map(parsedFrames, (frame, index) => (
          <Frame
            onFrameSelect={this.handleFrameSelect(frame)}
            isOwner={isOwner}
            isCurrent={currentFrame === frame.framenumber}
            tmpScore={tmpFrame}
            number={frame.framenumber}
            scores={frame.scores}
            frameScore={frame.realScore}
            flags={frame.flags}
            ischanging={changingFrame === frame.id}
            key={index}
            isEditing={(frame.id === selectedFrameId || frame.framenumber === selectedFrame) && isEditActive}
            globalEditing={isEditActive}
            scoreSumStyles={frameScoreSumStyles}
            frameNumberStyles={frameNumberStyles}
            frameStyles={frameStyles}
          />
        ))}
        <div className="frame__total" style={frameStyles}>
          <div className="frame__number" style={{ ...frameNumberStyles, ...{ borderRight: 'none' } }}>{intl.formatMessage({ id: 'GameScoreTotal', defaultMessage: 'Total' })}</div>
          <div className="frame__scores">{totalScores}</div>
          <div className="frame__scoresSum" style={{ ...frameScoreSumStyles, ...{ borderRight: 'none' } }} >{totalScores}</div>
        </div>
      </div>
    );
  }
}

export default injectIntl(GameScore);
