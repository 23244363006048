import { put, call } from 'redux-saga/effects';
import config from '../../config';
import { put as putMethod } from '../Networking';

const {
  SUPPORT_SET_MESSAGES,
  SUPPORT_SET_MESSAGE,
} = require('../reducers/actiontypes').default;

export function* _sendSupportMessage(params) {
  try {
    const url = `${config.FETCH.url}/support/send`;
    const result = yield call(putMethod, { url, data: params.payload });
    if (result.status === 200 && result.data.success) {
      yield put({ type: SUPPORT_SET_MESSAGE });
    } else {
      yield put({ type: SUPPORT_SET_MESSAGE, error: result.data.error || result.status });
    }
  } catch (err) {
    yield put({ type: SUPPORT_SET_MESSAGES, error: err });
  }
}