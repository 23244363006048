import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { FilterInput } from '../common/Input';
import Select from '../common/Select';

class FriendsFilter extends React.Component {
  static propTypes = {
    intl: intlShape,
    searchtext: PropTypes.string,
    onChangeInput: PropTypes.func,
    onChangeCountry: PropTypes.func,
    onChangeGender: PropTypes.func,
    selectCountry: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
    selectGender: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
    countryOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
    genderOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
  };

  render() {
    const {
      searchtext,
      onChangeInput,
      selectCountry,
      selectGender,
      onChangeCountry,
      onChangeGender,
      countryOptions,
      genderOptions,
      intl,
    } = this.props;
    return (
      <div className="friends-filter">
        <div className="friends-filter__container">
          <div className="friends-filter__input">
            <FilterInput
              value={searchtext}
              onChange={(e) => {
                onChangeInput(e);
              }}
              placeholder={intl.formatMessage({ id: 'FriendRouterFilterName', defaultMessage: 'Find user' })}
            />
          </div>
          <div className="friends-filter__selectcountry">
            <Select
              isSearchable
              value={selectCountry}
              onChange={(e) => {
                onChangeCountry(e);
              }}
              options={countryOptions}
              placeholder={intl.formatMessage({ id: 'FriendRouterFilterCountry', defaultMessage: 'Country' })}
            />
          </div>
          <div className="friends-filter__selectgender">
            <Select
              value={selectGender}
              onChange={(e) => {
                onChangeGender(e);
              }}
              options={genderOptions}
              placeholder={intl.formatMessage({ id: 'FriendRouterFilterGender', defaultMessage: 'Gender' })}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(FriendsFilter);
