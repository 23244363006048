/**
 * @param {keyof import("../personal-data-add.fragment").IPersonalData} name
 * @param {any} value
 * @param {import("../personal-data-add.fragment").IPersonalData} data
 * @param {{ countries: Array<{ id: number; name: string }>}} [opts]
 */
export const validatePersonalDataItem = (name, value, data, opts = {}) => {
  /** @type {import("../personal-data-add.fragment").IPersonalDataErrors} */
  const errors = {};
  const { countryId } = data;
  const country = countryId && opts.countries ? opts.countries.find(c => c.id === countryId) : null;
  const isLatvia = country && country.name === 'Latvia';
  const PERSONAL_ID_REGEX = /^\d{6}-\d{5}$/;
  switch (name) {
    case 'firstName':
    case 'lastName':
    case 'dob':
      errors[name] = !value;
      break;
    case 'personalId':
      if (isLatvia && !value) {
        errors[name] = true && Boolean(country);
      } else if (isLatvia && value && !PERSONAL_ID_REGEX.test(value)) {
        errors[name] = 'invalid personal id';
      } else {
        errors[name] = false;
      }
      break;
    case 'postcode':
    case 'province':
    case 'address':
      errors[name] = !isLatvia && !value && Boolean(country);
      break;
    case 'countryId':
      if (isLatvia) {
        errors.postcode = false;
        errors.province = false;
        errors.address = false;
      } else {
        errors.personalId = false;
      }
      break;
    default:
      break;
  }
  return errors;
};

/**
 * @param {import("../personal-data-add.fragment").IPersonalData} data
 * @param {{ countries: Array<{ id: number; name: string }> }} [opts]
 */
export const validatePersonalData = (data, opts = {}) => {
  const {
    countryId, firstName, lastName, dob, personalId, postcode, province, address,
  } = data;
  const country = countryId && opts.countries ? opts.countries.find(c => c.id === countryId) : null;
  const isLatvia = country && country.name === 'Latvia';
  /** @type {import("../personal-data-add.fragment").IPersonalDataErrors} */
  const errors = {};
  errors.firstName = !firstName;
  errors.lastName = !lastName;
  errors.dob = !dob;
  errors.countryId = !countryId;
  if (isLatvia) {
    errors.personalId = !personalId && Boolean(country);
    errors.postcode = false;
    errors.province = false;
    errors.address = false;
  } else {
    errors.personalId = false;
    errors.postcode = !postcode && Boolean(country);
    errors.province = !province && Boolean(country);
    errors.address = !address && Boolean(country);
  }
  return errors;
};
