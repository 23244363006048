import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import OBModal from '../../Modal';
import { Button } from '../../Button';
import Select from '../../Select';

import './style.scss';

class ModalProtest extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    text: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    intl: intlShape,
  };

  static defaultProps = {
    text: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: props.visible,
      reasons: props.reasons,
      protestReason: null,
      protestText: '',
      protestFrame: null,
      requireFrame: false,
      afterFrameEdit: props.afterFrameEdit || false,
      frame: props.frame || null,
      framenumber: props.framenumber || null,
      opponentFrames: props.opponentFrames || [],
      noEffect: false
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.visible !== prevState.visible) return { visible: nextProps.visible };
    return null;
  }

  componentDidMount() {
    const { afterFrameEdit, reasons, protestText, onChange, frame } = this.props;
    if (afterFrameEdit) {
      const reason = reasons.find(r => r.after_frame_edit);
      if (reason) {
        this.setState({ protestReason: reason.id, protestFrame: frame });
        onChange(protestText, reason, frame);
      }
    }
  }

  showModal = () => this.setState({ visible: true });

  handleOk = () => {
    const { onSubmit } = this.props;
    onSubmit();
    this.setState({ protestText: '' });
  };

  handleCancel = () => {
    const { onClose } = this.props;
    this.setState({
      visible: false,
    });
    onClose();
    this.setState({ protestText: '' });
  };

  onChange = () => {
    const { onChange } = this.props;
    const { protestText, protestReason, protestFrame, noEffect, protestSide } = this.state;
    onChange(protestText, protestReason, protestFrame, noEffect, protestSide);
  };

  render() {
    const { 
      visible, 
      afterFrameEdit, 
      framenumber, 
      opponentFrames, 
      requireFrame, 
      protestFrame,
      protestReason,
    } = this.state;
    const { text, intl } = this.props;
    let reasons = this.state.reasons.filter(r => {
      return parseInt(r.after_frame_edit) == 0 || parseInt(r.require_frame) == 1 && opponentFrames.length == 0;
    }).map(r => {
      let reason = {
        label: intl.formatMessage({ id: r.name, defaultMessage: r.name }),
        value: r.id,
        require_frame: r.require_frame,
        no_effect: r.no_effect,
        side: r.camera_side
      };
      return reason;
    });
    let frames = [];
    if (opponentFrames.length) {
      frames = opponentFrames.map(f => {
        return {
          label: f.framenumber,
          value: f.id
        };
      });
    }
    const Message = [
      <div key="message" className="protest__form">
        { !afterFrameEdit ?
          <div className="select__box">
            <div className="reason__select">
              <label>{intl.formatMessage({ id: 'ProtestReason', defaultMessage: 'Protest reason' })}</label>
              <Select
                placeholder={intl.formatMessage({ id: 'ProtestReason', defaultMessage: 'Protest reason' })}
                onChange={(e) => {
                  this.setState({ 
                    protestReason: e.value,
                    requireFrame: !!parseInt(e.require_frame), 
                    protestFrame: null, 
                    noEffect: !!parseInt(e.no_effect), 
                    protestSide: parseInt(e.side)
                  }, this.onChange);
                }}
                options={reasons}
              />
            </div>
            {
              requireFrame &&
              <div className="frame__select">
                <label>{intl.formatMessage({ id: 'Frame', defaultMessage: 'Frame' })}</label>
                <Select
                  placeholder={intl.formatMessage({ id: 'number', defaultMessage: '#{number}' }, { number: null })}
                  onChange={(e) => {
                    this.setState({ protestFrame: e.value }, this.onChange);
                  }}
                  options={frames}
                />
              </div>
            }
          </div> :
          <label>
            {
              intl.formatMessage({ 
                id: 'NotAgreeResultOfEditedFrame', 
                defaultMessage: 'Don`t agree with result of editing frame #{framenumber}' },
                { framenumber: framenumber }
              ) 
            }
          </label>
        }
        <textarea
          placeholder={intl.formatMessage({ id: 'ProtestReasonDescribePreambula', defaultMessage: 'Describe reason of protest, judge will receive your message about rules violation' })}
          value={text}
          onChange={(e) => {
            this.setState({ protestText: e.target.value }, this.onChange);
          }}
        />
      </div>,
      <div key="postambula" className="protest__postambula">
        {intl.formatMessage({ id: 'ProtestReasonDescribePostambula', defaultMessage: 'On protest submit, game will not be considered as completed, until judges review or protest cancel' })}
      </div>,
    ];

    return (
      <OBModal
        title={intl.formatMessage({ id: 'ProtestAsGameComplaintAlias', defaultMessage: 'Protest' })}
        visible={visible}
        width={395}
        closable={false}
        footer={[
          <div key="buttons" className="protest__buttons">
            <Button kind="aquamarine" bordered="true" onClick={this.handleCancel} className="protest__button-decline">
              {intl.formatMessage({ id: 'close', defaultMessage: 'Close' })}
            </Button>
            <Button disabled={(requireFrame && protestFrame == null || protestReason == null) ? 'disabled' : ''} onClick={this.handleOk} kind="aquamarine" className="protest__button-accept">
              {intl.formatMessage({ id: 'ProtestSubmitButton', defaultMessage: 'Submit' })}
            </Button>
          </div>,
        ]}
      >
        {Message}
      </OBModal>
    );
  }
}

export default injectIntl(ModalProtest);
