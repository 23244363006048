import React, { useState } from 'react';
import { Avatar } from './common';
import HandicapModal from '../../common/HandicapModal';

import './style.scss';

export default function SquadScore(props) {
  const [handicap, setHandicap] = useState(null);

  const { games, players, isPinfall, intl } = props;
  const total = [0, 0];
  const gamesScore = games.map((game) => {
    return players.map((player, idx) => {
      const score = game.frames[player.id][9].score;
      const score_handicap = game.frames[player.id][9].score_handicap;
      total[idx] += score_handicap || score;
      return ({
        score,
        score_handicap,
        handicap: game.handicap[player.id],
      });
    });
  });
  const isWinnerPlayer1 = isPinfall
    ? total[0] > total[1]
    : players[0].gameswin > players[1].gameswin;
  const renderBody = players.length === 1
    ? (
      <div className="games">
        {gamesScore.map((score, index) => (
          <div key={index} className="game solo">
            {`G${index + 1}`}
            <div
              className={`gameResult playerB ${score[0].score_handicap > score[0].score ? 'handicap' : ''} ${score[0].handicap && score[0].handicap.value ? 'handicap-view' : ''}`}
              onClick={() => (score[0].handicap && score[0].handicap.value && setHandicap(score[0].handicap)) || null}
            >
              {score[0].score_handicap || score[0].score}
            </div>
          </div>
        ))}
        <div className="total">
          TOTAL
          <div className="gameResult playerB">
            { total[0] }
          </div>
        </div>
      </div>
    )
    : (
    <div className="games">
      <div className="avatars__grm">
          <Avatar
            player={players[0]}
            color='green'
          />
          <Avatar
            player={players[1]}
            color='orange'
          />
      </div>
      {gamesScore.map((score, index) => {
        const playerAScore = score[0].score_handicap || score[0].score;
        const playerBScore = score[1].score_handicap || score[1].score;
        const isPlayerAWinner = playerAScore > playerBScore;
        const isDraw = playerAScore === playerBScore;
        return (
          <div key={index} className="game">
            {`G${index + 1}`}
            <div
              className={`gameResult playerA ${isPlayerAWinner && !isDraw ? 'winner' : ''} ${score[0].score_handicap >= score[0].score ? 'handicap' : ''} ${score[0].handicap && score[0].handicap.value ? 'handicap-view' : ''}`}
              onClick={() => (score[0].handicap && score[0].handicap.value && setHandicap(score[0].handicap)) || null}
            >
              {playerAScore}
            </div>
            <div
              className={`gameResult playerB ${!isPlayerAWinner && !isDraw ? 'winner' : ''} ${score[1].score_handicap >= score[1].score ? 'handicap' : ''} ${score[1].handicap && score[1].handicap.value ? 'handicap-view' : ''}`}
              onClick={() => (score[1].handicap && score[1].handicap.value && setHandicap(score[1].handicap)) || null}
            >
              {playerBScore}
            </div>
          </div>
        );
      })}
      <div className="total">
        TOTAL
        <div className={`gameResult playerA ${isWinnerPlayer1 ? 'winner' : ''}`}>{!isPinfall ? players[0].gameswin : total[0]}</div>
        <div className={`gameResult playerB ${!isWinnerPlayer1 ? 'winner' : ''}`}>{!isPinfall ? players[1].gameswin : total[1]}</div>
      </div>
    </div>);
  return (
    <>
      {renderBody}
      <HandicapModal
        visible={!!handicap}
        handicap={handicap}
        onOk={() => setHandicap(null)}
      />
    </>
  );
}
