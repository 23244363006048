/**
 * @param {{ intl: intlShape, code: string }}
 * @return {{ title: string, message: string }}
 */
const getCodeData = ({ intl, code }) => {
  const result = {};
  switch (code) {
    case 'LANE_IN_USE':
      result.title = intl.formatMessage({ id: 'lane_in_use_title', defaultMessage: 'Lane in use' });
      result.message = intl.formatMessage({ id: 'lane_in_use_message', defaultMessage: 'There is already a game on your chosen lane, please select a different lane' });
      break;
    case 'TOURNAMENT_ALREADY_CANCELLED':
      result.title = intl.formatMessage({ id: 'Tournament_already_canceled_title', defaultMessage: 'Battle was deleted' });
      result.message = intl.formatMessage({
        id: 'Tournament_already_canceled_message',
        defaultMessage: 'Unfortunately, the battle you selected would have been deleted by the creator',
      });
      break;
    default:
      break;
  }
  return result;
};

export default getCodeData;
