const {
  LOGOUT,
  GEODATA_TIMEZONES_FETCH,
  GEODATA_TIMEZONES_SET,
  SET_COOKIE_STATE,
  SET_HANDICAP_LIST,
  SAVE_HANDICAP_LIST,
  GET_HANDICAP_LIST,
  FETCH_UPLOAD_APK,
  SET_UPLOAD_APK,
} = require('./actiontypes').default;

export const GEODATA_FETCH = 'GEODATA_FETCH';
export const GEODATA_SET = 'GEODATA_SET';
export const USERGEODATA_SET = 'USERGEODATA_SET';
export const USERGEODATA_FETCH = 'USERGEODATA_FETCH';
export const DEFAULTTIMEZONE_SET = 'DEFAULTTIMEZONE_SET';
export const DEFAULTTIMEZONE_FETCH = 'DEFAULTTIMEZONE_FETCH';
export const GEODATA_CITIES_SET = 'GEODATA_CITIES_SET';
export const GEODATA_CITIES_FETCH = 'GEODATA_CITIES_FETCH';

export const SETTINGS_FETCH = 'SETTINGS_FETCH';
export const SETTINGS_SET = 'SETTINGS_SET';

export const COMMON_ERROR = 'COMMON_ERROR';
export const COMMON_ERROR_FETCH = 'COMMON_ERROR_FETCH';
export const REG_CODE_ERROR = 'REG_CODE_ERROR';
export const APP_LOADED = 'APP_LOADED';
export const REG_CODE_ERROR_DROP = 'REG_CODE_ERROR_DROP';
export const AGREEMENTS_SET = 'AGREEMENTS_SET';
export const AGREEMENTS_FETCH = 'AGREEMENTS_FETCH';
export const SET_CODE_ERROR = 'SET_CODE_ERROR';
export const CLOSE_CODE_ERROR = 'CLOSE_CODE_ERROR';
export const COMMON_NOTIFICATION = 'COMMON_NOTIFICATION';
export const COMMON_NOTIFICATION_PARAMS = 'COMMON_NOTIFICATION_PARAMS';
export const PROCESS_PURCHASE = 'PROCESS_PURCHASE';
export const MOBILE_VERSION_SET = 'MOBILE_VERSION_SET';
export const SET_TIMER_MODAL = 'SET_TIMER_MODAL';

export const GET_ACTIVE_HDC = 'GET_ACTIVE_HDC';
export const SET_ACTIVE_HDC = 'SET_ACTIVE_HDC';
export const FETCH_ACTIVE_HDC = 'FETCH_ACTIVE_HDC';
export const FETCHED_ACTIVE_HDC = 'FETCHED_ACTIVE_HDC';

export const GET_ACTIVE_LANGS = 'GET_ACTIVE_LANGS';
export const SET_ACTIVE_LANGS = 'SET_ACTIVE_LANGS';

export const geodataGet = params => ({ type: GEODATA_FETCH, params });
export const geodataUserGet = params => ({ type: USERGEODATA_FETCH, params });

export const geodataCitiesGet = params => ({ type: GEODATA_CITIES_FETCH, params });

export const getSettings = () => ({ type: SETTINGS_FETCH });

export const setCommonError = params => ({ type: COMMON_ERROR_FETCH, params });
export const resetRegCodeError = () => ({ type: REG_CODE_ERROR_DROP });

export const getAgreements = params =>  ({ type: AGREEMENTS_FETCH, params });

export const appLoaded = () => ({ type: APP_LOADED });

export const setCodeError = (error) => ({ type: SET_CODE_ERROR, paylaod: error });

export const closeCodeError = () => ({ type: CLOSE_CODE_ERROR });

export const setCommonNotification = params => ({ type: COMMON_NOTIFICATION, params });
export const setCommonNotificationWithParams = params => ({ type: COMMON_NOTIFICATION_PARAMS, params });

export const setProcessPurchase = params => ({ type: PROCESS_PURCHASE, params });
export const setTimerModal = params => ({type: SET_TIMER_MODAL, params});

export const getActiveHDC = params => ({ type: GET_ACTIVE_HDC, params });

export const setActiveHDC = params => ({ type: FETCH_ACTIVE_HDC, payload: params });

export const getActiveLangs = () => ({type: GET_ACTIVE_LANGS})

const initialState = {
  geodata: {
    data: [],
    fetching: false,
    error: '',
  },
  cities: {
    data: [],
    fetching: false,
    error: '',
  },
  timezones: {
    data: [],
    fetching: false,
    error: '',
  },
  settings: {
    data: {},
  },
  fetching: false,
  error: '',
  common_error: '',
  code_error: {},
  app_loaded: false,
  agreements: {},
  cookie_agreement: 'initial',
  cookie_intent: false,
  common_notification: '',
  processingPurchase: false, // Процесс покупки Подписок apple
  mobile_version: {
    version: null,
    critical_version: null,
  },
  handicaps: {
    data: [],
    saving: false,
    fetching: false,
    error: null,
  },
  uploadApkFile: {
    fetching: false,
    error: null,
  },
  timerModalVisible: false,
  activeHDC: [],
  fetchActiveHDC: false,
  langs: [
    {
      id: null,
      language: null,
      langFlag: null,
      appLang: null,
    },
  ]
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case AGREEMENTS_SET:
      return { ...state, agreements: action.data }
    case REG_CODE_ERROR:
      return { ...state, reg: false, regcodeerror: action.params };
    case REG_CODE_ERROR_DROP:
      return { ...state, regcodeerror: '' };
    case LOGOUT:
      return { ...initialState, app_loaded: state.app_loaded, langs: state.langs, agreements: state.agreements, cookie_agreement: 0 };
    case GEODATA_FETCH:
      return { ...state, geodata: { ...initialState.geodata, fetching: true } };
    case DEFAULTTIMEZONE_SET:
      return { ...state, defaulttimezone: { data: action.data, fetching: false } };
    case DEFAULTTIMEZONE_FETCH:
      return { ...state, defaulttimezone: { data: null, fetching: true } };
    case USERGEODATA_FETCH:
      return { ...state, usercountry: { data: null, fetching: true } };
    case GEODATA_SET:
      return { ...state, geodata: { data: action.data || [], fetching: false, error: action.error || '' } };
    case USERGEODATA_SET:
      return { ...state, usercountry: { data: action.data, fetching: false, error: action.error || '' } };
    case GEODATA_CITIES_FETCH:
      return { ...state, cities: { ...initialState.cities, fetching: true } };
    case GEODATA_CITIES_SET:
      return { ...state, cities: { data: action.data || [], fetching: false, error: action.error || '' } };
    case GEODATA_TIMEZONES_FETCH:
      return { ...state, timezones: { ...initialState.timezones, fetching: true } };
    case GEODATA_TIMEZONES_SET:
      return { ...state, timezones: { data: action.data || [], fetching: false, error: action.error || '' } };
    case SETTINGS_FETCH:
      return { ...state, settings: { ...initialState.settings, fetching: true } };
    case SETTINGS_SET:
      return { ...state, settings: { data: action.data || [], fetching: false, error: action.error || '' } };
    case COMMON_ERROR:
      return { ...state, common_error: action.data };
    case SET_CODE_ERROR:
      return { ...state, code_error: { ...action.payload } };
    case CLOSE_CODE_ERROR:
      return { ...state, code_error: {} };
    case APP_LOADED:
      return { ...state, app_loaded: true };
    case SET_COOKIE_STATE:
      return { ...state, cookie_agreement: state.cookie_agreement === 1 ? 1 : action.params.state, cookie_intent: action.params.intent }
    case COMMON_NOTIFICATION:
      return { ...state, common_notification: action.params || action.data, common_notification_params: null };
    case COMMON_NOTIFICATION_PARAMS:
      return { ...state, common_notification: action.params.messageType, common_notification_params: action.params.params };
    case PROCESS_PURCHASE:
      return { ...state, processingPurchase: action.params || action.data };
    case MOBILE_VERSION_SET:
      return { ...state, mobile_version: action.params }
    case SET_HANDICAP_LIST:
      return { ...state, handicaps: { ...state.handicaps, data: action.payload, saving: false, fetching: false } }
    case SAVE_HANDICAP_LIST:
      return { ...state, handicaps: { ...state.handicaps, saving: true } }
    case GET_HANDICAP_LIST:
      return { ...state, handicaps: { ...state.handicaps, fetching: true } }
    case FETCH_UPLOAD_APK:
      return { ...state, uploadApkFile: { fetching: true, error: null, } }
    case SET_UPLOAD_APK:
      return { ...state, uploadApkFile: { fetching: false } }
    case SET_TIMER_MODAL:
      return { ...state, timerModalVisible: !state.timerModalVisible }
    case SET_ACTIVE_HDC:
      return { ...state, activeHDC: action.data}
    case FETCH_ACTIVE_HDC:
      return { ...state, fetchActiveHDC: true };
    case FETCHED_ACTIVE_HDC:
      return { ...state, fetchActiveHDC: false };
    case SET_ACTIVE_LANGS:
      return {...state, langs: action.params};
    default:
      return state;
  }
};

export default commonReducer;
