const {
  SOCKET_EVENT_SET_PAUSE,
  SOCKET_EVENT_RAISE_PAUSE,
  CHECK_EXPECTED_GAMES,
  CHECKED_EXPECTED_GAMES,
  CLEAR_EXPECTED_GAMES,
} = require('./actiontypes').default;

export const SQUAD_FRAME_SET = 'SQUAD_FRAME_SET';
export const SQUAD_CHAT_UPDATE = 'SQUAD_CHAT_UPDATE';
export const SOCKET_UPDATE_FRAMES = 'SOCKET_UPDATE_FRAMES';
export const SOCKET_SEND_FRAMEEDIT = 'SOCKET_SEND_FRAMEEDIT';
export const SOCKET_UPDATE_EDIT = 'SOCKET_UPDATE_EDIT';
export const EDIT_CONFIRM_CLEAR = 'EDIT_CONFIRM_CLEAR';
export const CHANGE_FRAME_SET = 'CHANGE_FRAME_SET';
export const SOCKET_PROTEST_CATCH = 'SOCKET_PROTEST_CATCH';
export const PROTEST_REPLY = 'PROTEST_REPLY';
export const SOCKET_EVENT_RESULTCONFIRM = 'SOCKET_EVENT_RESULTCONFIRM';
export const SOCKET_EVENT_CONF_RESET = 'SOCKET_EVENT_CONF_RESET';
export const GAME_PAUSE_TOGGLE = 'GAME_PAUSE_TOGGLE';
export const SOCKET_YELLOW_CARD = 'SOCKET_YELLOW_CARD';
export const SOCKET_YELLOW_CARD_MARKSEEN = 'SOCKET_YELLOW_CARD_MARKSEEN';
export const MANUAL_FRAME_DEADLINE_UPDATE = 'MANUAL_FRAME_DEADLINE_UPDATE';
export const SQUAD_FRAME_INPUT = 'SQUAD_FRAME_INPUT';

export const squadChatUpdate = params => ({ type: SQUAD_CHAT_UPDATE, params });
export const frameEditReq = params => ({ type: SOCKET_SEND_FRAMEEDIT, params });
export const frameEditConfirm = params => ({ type: CHANGE_FRAME_SET, params });
export const clearChangeReq = () => ({ type: EDIT_CONFIRM_CLEAR });
export const protestReply = params => ({ type: PROTEST_REPLY, params });
export const togglePause = params => ({ type: GAME_PAUSE_TOGGLE, params });
export const markYellowCardAsSeen = () => ({ type: SOCKET_YELLOW_CARD_MARKSEEN });
export const updateDeadlineManualy = params => ({ type: MANUAL_FRAME_DEADLINE_UPDATE, params });
export const inputFrame = params => ({ type: SQUAD_FRAME_INPUT, params });

const { LOGOUT } = require('./actiontypes').default;

const initialState = {
  squad: {
    data: {},
    fetching: false,
    error: '',
    editFrame: [],
    pendingProtests: [],
    protestCategories: [],
    protestsUpdate: 0,
    yellowCardEvent: false,
    lastFrameUpdated: null,
  },
  expectedGames: {
    data: null,
    fetching: false,
  }
};

const squadsReduсer = (state = initialState, action) => {
  let newstate;
  switch (action.type) {
    case LOGOUT:
      return { ...initialState };
    case SOCKET_EVENT_CONF_RESET:
      return {
        ...state,
        squad: {
          ...state.squad,
          fetching: true,
          error: '',
        },
      };
    case SOCKET_UPDATE_EDIT:
      newstate = state.squad.editFrame;
      newstate.push(action.params);
      return { ...state, squad: { ...state.squad, editFrame: newstate } };
    case EDIT_CONFIRM_CLEAR:
      newstate = state.squad.editFrame;
      newstate.shift();
      return { ...state, squad: { ...state.squad, editFrame: newstate } };
    case SOCKET_PROTEST_CATCH:
      newstate = [];
      action.params.protests.forEach((protest) => {
        if (state.squad.data.players[0].userid !== protest.userid && parseInt(protest.no_effect) == 0 && !protest.userreply && !state.squad.pendingProtests.includes(protest)) {
          newstate.push(protest);
        }
      });
      return { ...state, squad: { ...state.squad, data: { ...state.squad.data, protests: action.params.protests }, pendingProtests: [ ...newstate ], protestsUpdate: state.squad.protestsUpdate + 1 } };
    case PROTEST_REPLY:
      newstate = [ ...state.squad.pendingProtests ];
      newstate.shift();
      return { ...state, squad: { ...state.squad, pendingProtests: newstate } };
    case SOCKET_EVENT_RESULTCONFIRM:
      newstate = state.squad.data.players;
      newstate.forEach((player) => { player.games[player.games.length - 1].confirmations.push(action.params); });
      return { ...state, squad: { ...state.squad, data: { ...state.squad.data, players: newstate } } };
    case SOCKET_EVENT_SET_PAUSE:
      return { ...state, squad: {...state.squad, data: { ...state.squad.data, pauseState: {onHold: true, pauseStart: action.params.pauseDate, pausedBy: action.params.pausedBy, newTTA: false} } }};
    case SOCKET_EVENT_RAISE_PAUSE:
      return { ...state, squad: {...state.squad, data: { ...state.squad.data, pauseState: {onHold: false, pauseStart: false, pausedBy: false, newTTA: action.params.newTTA} } }};
    // case SOCKET_YELLOW_CARD:
    //   state.squad.data.players.forEach(player => {
    //     if (player.userid == action.params.userid) {
    //       player.delays += 1; 
    //     }
    //   })
    //   if (state.squad.data.id == action.params.squadid) {
    //     const playerIndex = state.squad.data.players.findIndex(p => p.userid === action.params.userid);
    //     const gameIndex = state.squad.data.players[playerIndex].games.findIndex(g => g.id === action.params.gameid);
    //     const frameIndex = state.squad.data.players[playerIndex].games[gameIndex].frames.findIndex(f => f.id === action.params.frameid);
    //     state.squad.data.players[playerIndex].games[gameIndex].frames[frameIndex].deadline = action.params.deadline;
    //     if (action.params.useridFromRedux === action.params.userid) {
    //       return { ...state, squad: { ...state.squad, yellowCardEvent: true } };
    //     } else {
    //       return { ...state, squad: { ...state.squad, competitorYellowCard: true } };
    //     }
    //   }
    //   return state;
    // case SOCKET_YELLOW_CARD_MARKSEEN:
    //   return { ...state, squad: {...state.squad, yellowCardEvent: false} }
    case MANUAL_FRAME_DEADLINE_UPDATE:
      newstate = { ...state }
      newstate.squad.data.players[action.params.player].games[action.params.game].frames[action.params.frame].deadline = action.params.time;
      return newstate;
    case CHECK_EXPECTED_GAMES:
      return {...state, expectedGames: { data: null, fetching: true} }
    case CHECKED_EXPECTED_GAMES:
      return {...state, expectedGames: { data: action.payload, fetching: false} }
    case CLEAR_EXPECTED_GAMES:
      return {...state, expectedGames: { data: null, fetching: false} }
    default:
      return state;
  }
};

export default squadsReduсer;
