import { call, put, select, takeEvery } from 'redux-saga/effects'
import config from '../../config'
import { get } from '../Networking'
import bugsnagClient from '../../bugsnagClient';

/**
 * @typedef {{
 *  id: number;
 *  login: string;
 *  firstname: string;
 *  lastname: string;
 * }} PoolUser
 */

/**
 * @typedef {{
 *  requests: { [userid: string]: { fetching?: boolean; error?: boolean; message?: string; } }
 *  data: { [userid: string]: PoolUser }
 * }} UserPoolState
 */
export const initialUserPoolState = {
  requests: {},
  data: {},
}

/** @param {number} userid */
export const userByUseridRequestingAction = (userid) => ({ type: 'USER_BY_USERID_REQUESTING', params: { userid } })
/** @param {number} userid */
export const userByUseridRequestAction = (userid) => ({ type: 'USER_BY_USERID_REQUEST', params: { userid } })
/** @param {PoolUser} user */
export const userByUseridSuccessAction = (user) => ({ type: 'USER_BY_USERID_SUCCESS', params: { user } })
/** @param {{ userid: number; message?: string; }} [error] */
export const userByUseridFailureAction = (error = {}) => ({ type: 'USER_BY_USERID_FAILURE', params: error })
/** @param {number} userid */
export const userPoolCleanAction = (userid) => ({ type: 'USER_POOL_CLEAN', params: { userid } })

/**
 * 
 * @param {UserPoolState} state
 * @param {*} action
 * @returns {UserPoolState}
 */
export const UserPoolReducer = (state = initialUserPoolState, action) => {
  switch (action.type) {
    case 'USER_POOL_CLEAN':
      if (
        !action.params
        || !action.params.userid
        || (
          state.requests[action.params.userid]
          && state.requests[action.params.userid].fetching
        )
      ) {
        return state;
      }
      return {
        ...state,
        requests: {
          ...state.requests,
          [action.params.userid]: undefined,
        },
        data: {
          ...state.data,
          [action.params.userid]: undefined,
        }
      }
    case 'USER_BY_USERID_REQUEST':
      if (
        !action.params
        || !action.params.userid
        || (
          state.requests[action.params.userid]
          && state.requests[action.params.userid].fetching
        )
      ) {
        return state;
      }
      return {
        ...state,
        requests: {
          ...state.requests,
          [action.params.userid]: {
            ...state.requests[action.params.userid],
            fetching: true,
            error: false,
            message: undefined,
          }
        },
      }
    case 'USER_BY_USERID_SUCCESS':
      if (!action.params || !action.params.user || !action.params.user.id) {
        return state;
      }
      return {
        ...state,
        requests: {
          ...state.requests,
          [action.params.user.id]: undefined,
        },
        data: {
          ...state.data,
          [action.params.user.id]: {
            ...action.params.user,
          }
        }
      }
    case 'USER_BY_USERID_FAILURE':
      if (
        !action.params
        || !action.params.userid
      ) {
        return state;
      }
      return {
        ...state,
        requests: {
          ...state.requests,
          [action.params.userid]: {
            fetching: false,
            error: true,
            message: action.params.message,
          }
        },
      }
    default:
      return state;
  }
}

function* userByUseridSaga(action) {
  const { userid } = action.params || {}
  try {
    const req = yield select(state => state.userPool.requests[userid])
    if (req && req.fetching) {
      return;
    }
    yield put(userByUseridRequestAction(userid))
    const res = yield call(get, { url: `${config.FETCH.url}/user/${userid}` });
    if (res.status === 200) {
      yield put(userByUseridSuccessAction(res.data.data))
    } else {
      bugsnagClient.notify(new Error(`[ERROR] PoolUser by userid get. ${JSON.stringify({ error: res.error || res || '' })}`))
      yield put(userByUseridFailureAction({ userid }))
    }
  } catch (e) {
    bugsnagClient.notify(e, {
      context: 'UserByUserid',
      beforeSend: report => report.updateMetadata('payload', action.params),
    });
    yield put(userByUseridFailureAction({ message: e.message, userid }))
  }
}

export function* watchUserPoolSaga() {
  yield takeEvery('USER_BY_USERID_REQUESTING', userByUseridSaga)
}