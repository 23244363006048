import { put, call, select } from 'redux-saga/effects';
import config from '../../config';
import { get } from '../Networking';

const { SET_USER_DISK } = require('../reducers/actiontypes').default;

const getUser = state => state.users.user;

export default function* _getUserDisk() {
  const { id: userid } = yield select(getUser);
  const user = yield select(getUser);
  if (!userid) {
    yield put({ type: SET_USER_DISK, payload: {} })
    return;
  }
  try {
    const url = `${config.FETCH.url}/userdisk/${userid}`;
    const result = yield call(get, { url });
    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_USER_DISK, payload: result.data.data });
    } else {
      yield put({ type: SET_USER_DISK, error: result.data.error || result.status });
    }
  } catch (err) {
    yield put({ type: SET_USER_DISK, error: err });
  }
}