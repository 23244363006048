import React, {useEffect, useRef, useState} from 'react';
import './rightWrapper.scss'
import axios from "axios";
import config from "../../../../config";

const RightWrapperInvestors = ({setIsStateThanks}) => {
    const [emailValue, setEmailValue] = useState('');
    const [phoneValue, setPhoneValue] = useState('');
    const [contactValue, setContactValue] = useState('');
    const [commentValue, setCommentValue] = useState('');
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [isInvalidEmail, setIsInvalidEmail] = useState(true);

    async function sendMail() {
        await axios.post(`${config.FETCH.url}/users/from-investor`,
            {
                headers: {'X-Requested-With': 'XMLHttpRequest'},
                body: {email: emailValue, phone: phoneValue, contact: contactValue, comment: commentValue}
            })
    }

    function inputHandler(e, setValue) {
        setValue(e.target.value)
    }

    function onClickHandler(e) {
        e.preventDefault();
        if (!emailValue || ! contactValue){
            setIsErrorVisible(true)
            return
        };

        const emailValidRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (!(emailValue.match(emailValidRegex))){
            setIsInvalidEmail(false)
            return;
        };

        if (emailValue.match(emailValidRegex)){
            sendMail()
            setIsStateThanks(true)
        };

        setIsInvalidEmail(true)
        setIsErrorVisible(false);

    }

    return (
        <div className={'right-wrapper'}>
            <div className={'right-wrapper__content'}>
                <div className={'right-wrapper__text'}>
                    Do you want to know what is our big idea & why we are so confident about the growth of our business? Want to become part of the future of bowling?
                </div>
                <div className={'right-wrapper__title'}>
                    Let us know & we will send you our proposal on investment.
                </div>
                <form>
                    <div>
                        <input
                            id={'investor-email'}
                            type={'text'}
                            placeholder={'E-mail*'}
                            onBlur={(e) => inputHandler(e, setEmailValue)}
                            className={isInvalidEmail ? 'required' : 'invalid'}
                        />
                        <input
                            id={'investor-phone'}
                            type={'text'}
                            placeholder={'Your phone'}
                            onBlur={(e) => inputHandler(e, setPhoneValue)}
                        />
                    </div>
                    <input
                        id={'investor-contact'}
                        type={'text'}
                        placeholder={'Contact person*'}
                        className={'required'}
                        onBlur={(e) => inputHandler(e, setContactValue)}
                    />
                    <input
                        id={'investor-additional'}
                        type={'text'}
                        placeholder={'Any additional comments'}
                        onBlur={(e) => inputHandler(e, setCommentValue)}
                    />
                    {isErrorVisible && <span className={'error-message'}> Please, fill all required fields </span>}
                    <button className={'submit-btn'} onClick={onClickHandler}>Submit</button>
                </form>
                <div className={'right-wrapper__bottom-text'}>
                    By pressing Submit you agree with our Personal Data usage policies and other Terms & Conditions
                </div>
            </div>
        </div>
    );
};

export default RightWrapperInvestors;
