import React from 'react';

import Logo from '../images/Logo-TenPinCam.png';

export default class SplashScreen extends React.Component {
  render() {
    return (
      <div className="splashscreen_background">
        <div className="splashscreen">
          <img src={Logo} alt="" />
        </div>
      </div>
    );
  }
}
