import moment from 'moment';
import BolicheDentLogo from '../images/boliche_dent_logo.png';
import ChamartinLogo from '../images/chamartin_logo.png';
import AmbLogo from '../images/amb_logo.png';

export default ({ intl, lang }) => ([
  {
    image: BolicheDentLogo,
    name: '300 Pro Shop Open by TenPinCam',
    bowlingCenter: 'Boliche Dent',
    date: intl.formatMessage({
      id: 'FromTillDateTournamentLanding',
      defaultMessage: 'from {fromDay} of {fromMonth} till {toDay} of {toMonth} {year}',
    }, {
      fromDay: moment('2021-03-29').format(lang === 'EN' ? 'Do' : 'DD'),
      fromMonth: intl.formatMessage({
        id: 'March',
        defaultMessage: 'March',
      }),
      toDay: moment('2021-03-18').format(lang === 'EN' ? 'Do' : 'DD'),
      toMonth: intl.formatMessage({
        id: 'April',
        defaultMessage: 'April',
      }),
      year: 2021,
    }),
    entryFee: intl.formatMessage({
      id: 'EntrySponsorTournamentLanding',
      defaultMessage: 'The participation fee is provided by the sponsor - TenPinCam. The player only covers Bowling center lane expenses.',
    }),
    tournamentFormat: intl.formatMessage({
      id: 'TournamentFormatInfoLanding',
      defaultMessage: 'Registered players will play {countGames} games. All games will be summed up. The number of replays are not limited.',
    }, {
      countGames: 4,
    }),
    prizes: [
      `${intl.formatMessage({ id: '1place', defaultMessage: '1st place' })}
        - ${intl.formatMessage({ id: 'PrizeBallTournamentLanding', defaultMessage: 'Ball OMEGA CRUX from 300Pro' })}`,
      `${intl.formatMessage({ id: '2place', defaultMessage: '2st place' })}
        - ${intl.formatMessage({ id: 'PrizeFree2HrsTournamentLanding', defaultMessage: '2hrs free from Boliche Dent' })}`,
      `${intl.formatMessage({ id: '3place', defaultMessage: '3st place' })}
        - €20 (${intl.formatMessage({ id: 'PrizeOnPlayersTenpincamAccountLanding', defaultMessage: 'on players TenPinCam account' })})`
    ],
    registration: intl.formatMessage({
      id: 'RegistrationIsOpenUntilDateLanding',
      defaultMessage: 'Registration is open until {day} of {month} {year}',
    }, {
      day: moment('2021-04-17').format(lang === 'EN' ? 'Do' : 'DD'),
      month: intl.formatMessage({
        id: 'April',
        defaultMessage: 'April',
      }),
      year: 2021,
    }),
    additionalTerms: [
      `* ${
        intl.formatMessage({
          id: 'AdditionalTermTournament_1',
          defaultMessage: 'Oil dressing should be ordered from the bowling alley by player’s choice',
        })
      }.`,
      `* ${
        intl.formatMessage({
          id: 'AdditionalTermTournament_2',
          defaultMessage: 'All tournament games can be watched by the referee & other participants, as well as the registered TenPinCam users',
        })
      }.`,
      `* ${
        intl.formatMessage({
          id: 'AdditionalTermTournament_3',
          defaultMessage: 'The other conditions are in accordance with the general competition rules and general TenPinCam rules',
        })
      }.`,
    ]
  },
  {
    image: ChamartinLogo,
    name: 'Introductory TenPinCam Cup 2021',
    bowlingCenter: 'Charmartin Bowling',
    date: intl.formatMessage({
      id: 'FromTillDateTournamentLanding',
      defaultMessage: 'from {fromDay} of {fromMonth} till {toDay} of {toMonth} {year}',
    }, {
      fromDay: moment('2021-04-12').format(lang === 'EN' ? 'Do' : 'DD'),
      fromMonth: intl.formatMessage({
        id: 'April',
        defaultMessage: 'April',
      }),
      toDay: moment('2021-05-03').format(lang === 'EN' ? 'Do' : 'DD'),
      toMonth: intl.formatMessage({
        id: 'May',
        defaultMessage: 'May',
      }),
      year: 2021,
    }),
    entryFee: intl.formatMessage({
      id: 'EntrySponsorTournamentLanding',
      defaultMessage: 'The participation fee is provided by the sponsor - TenPinCam. The player only covers Bowling center lane expenses.',
    }),
    tournamentFormat: intl.formatMessage({
      id: 'TournamentFormatInfoLanding',
      defaultMessage: 'Registered players will play {countGames} games. All games will be summed up. The number of replays are not limited.',
    }, {
      countGames: 3,
    }),
    prizes: [
      `${intl.formatMessage({ id: '1place', defaultMessage: '1st place' })}
        - €30 (${intl.formatMessage({ id: 'PrizeOnPlayersTenpincamAccountLanding', defaultMessage: 'on players TenPinCam account' })})`,
      `${intl.formatMessage({ id: '2place', defaultMessage: '2st place' })}
        - €20 (${intl.formatMessage({ id: 'PrizeOnPlayersTenpincamAccountLanding', defaultMessage: 'on players TenPinCam account' })})`,
      `${intl.formatMessage({ id: '3place', defaultMessage: '3st place' })}
        - €10 (${intl.formatMessage({ id: 'PrizeOnPlayersTenpincamAccountLanding', defaultMessage: 'on players TenPinCam account' })})`
    ],
    registration: intl.formatMessage({
      id: 'RegistrationIsOpenUntilDateLanding',
      defaultMessage: 'Registration is open until {day} of {month} {year}',
    }, {
      day: moment('2021-05-30').format(lang === 'EN' ? 'Do' : 'DD'),
      month: intl.formatMessage({
        id: 'May',
        defaultMessage: 'May',
      }),
      year: 2021,
    }),
    additionalTerms: [
      `* ${
        intl.formatMessage({
          id: 'AdditionalTermTournament_1',
          defaultMessage: 'Oil dressing should be ordered from the bowling alley by player’s choice',
        })
      }.`,
      `* ${
        intl.formatMessage({
          id: 'AdditionalTermTournament_2',
          defaultMessage: 'All tournament games can be watched by the referee & other participants, as well as the registered TenPinCam users',
        })
      }.`,
      `* ${
        intl.formatMessage({
          id: 'AdditionalTermTournament_3',
          defaultMessage: 'The other conditions are in accordance with the general competition rules and general TenPinCam rules',
        })
      }.`,
    ]
  },
  {
    // image: ChamartinLogo,
    name: 'UK TenPinCam 2021',
    bowlingCenter: 'Dunstable Bowling Center, Ice Bowl, LA Bowl Ayr, Shipley Lanes',
    date: 'from 14th of June till 11th of July',
    entryFee: 'The participation fee – 10 eur. Reentry – 10 eur. The player also covers Bowling center lane expenses.',
    tournamentFormat: `Registered players will play 5 games. All games will be summed up. The number of replays are not limited.
      The first three games the player gets dynamic handicap, starting with 4th game – static handicap.
    `,
    prizes: [
      'Prize fund 2000 euro',
    ],
    registration: 'Registration is opened until July 11, 2021. ',
    additionalTerms: [
      '* Oil dressing should be ordered from the bowling alley by player’s choice.',
      '* All tournament games can be watched by the referee & other participants, as well as the registered TenPinCam users.',
      '* The other conditions are in accordance with the general competition rules and general TenPinCam rules.',
    ]
  },
  {
    // image: ChamartinLogo,
    name: 'Finland Introductory TenPinCam Cup 2021',
    bowlingCenter: 'Tali Bowling Center ',
    date: 'from 31st May till 21st June 2021',
    entryFee: 'The participation fee is provided by the sponsor - TenPinCam. The player only covers Bowling center lane expenses.',
    tournamentFormat: 'Registered players will play 3 games. All games will be summed up.',
    prizes: [
      '1st place – 30 eur (on players TenPinCam account)',
      '2nd place – 20 eur (on players TenPinCam account)',
      '3nd place – 10 eur (on players TenPinCam account)'
    ],
    registration: 'Registration is open until 21 st June 2021.',
    additionalTerms: [
      '* Oil dressing should be ordered from the bowling alley by player’s choice.',
      '* All tournament games can be watched by the referee & other participants, as well as the registered TenPinCam users.',
      '* The other conditions are in accordance with the general competition rules and general TenPinCam rules.',
    ]
  },
  {
    image: AmbLogo,
    name: 'Estonia Introductory TenPinCam Cup 2021',
    bowlingCenter: 'AMB Akadeemia Bowling',
    date: 'from 31st May till 27st June 2021',
    entryFee: 'The participation fee is provided by the sponsor - TenPinCam. The player only covers Bowling center lane expenses.',
    tournamentFormat: 'Registered players will play 3 games. All games will be summed up.',
    prizes: [
      '1st place – 30 eur (on players TenPinCam account)',
      '2nd place – 20 eur (on players TenPinCam account)',
      '3nd place – 10 eur (on players TenPinCam account)'
    ],
    registration: 'Registration is open until 27th of June 2021.',
    additionalTerms: [
      '* Oil dressing should be ordered from the bowling alley by player’s choice.',
      '* All tournament games can be watched by the referee & other participants, as well as the registered TenPinCam users.',
      '* The other conditions are in accordance with the general competition rules and general TenPinCam rules.',
    ]
  }
]);
