export const GET_NOTIFICATIONS_FETCH = 'GET_NOTIFICATIONS_FETCH';
export const GET_NOTIFICATIONS_SET = 'GET_NOTIFICATIONS_SET';

export const REMOVE_NOTIFICATION_FETCH = 'REMOVE_NOTIFICATION_FETCH';
export const REMOVE_NOTIFICATION_SET = 'REMOVE_NOTIFICATION_SET';
export const SOCKET_NOTIFICATIONS_SET = 'SOCKET_NOTIFICATIONS_SET';
export const SOCKET_NOTIFICATIONS_UPDATE = 'SOCKET_NOTIFICATIONS_UPDATE';
export const READ_NOTIFICATIONS = 'READ_NOTIFICATIONS';

export const getNotificationsFetch = params => ({ type: GET_NOTIFICATIONS_FETCH, params });
export const removeNotificationFetch = params => ({ type: REMOVE_NOTIFICATION_FETCH, params });
export const readNotifications = params => ({ type: READ_NOTIFICATIONS, params });

const { LOGOUT } = require('./actiontypes').default;

const initialState = {
  notifications: { data: [], fetching: false, error: '', count: 0 },
  socketNotifications: [],
};

const notificationsReduсer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return { ...initialState };
    case GET_NOTIFICATIONS_FETCH:
      return { ...state, notifications: { data: [], fetching: true, error: '', count: 0 }, socketNotifications: [] };
    case GET_NOTIFICATIONS_SET:
      return { ...state, notifications: { data: action.data || state.notifications.data, fetching: false, error: action.error || '', count: action.count || 0 } };
    case SOCKET_NOTIFICATIONS_SET:
      return { ...state, socketNotifications: state.socketNotifications.find(note => note.id===action.data.id)?state.socketNotifications:[...state.socketNotifications, action.data] };
    case SOCKET_NOTIFICATIONS_UPDATE:
      return { ...state, socketNotifications: action.data };

    default:
      return state;
  }
};

export default notificationsReduсer;
