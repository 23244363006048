import { call, put } from 'redux-saga/effects'
import bugsnagClient from '../../bugsnagClient';
import config from '../../config';
import { post } from '../Networking'

export const initialTariffsOffState = {
  fetching: false,
  error: false,
  data: [],
}

export const getTariffsOffAction = () => ({ type: 'GET_TARIFFS_OFF' })
export const setTariffsOffAction = (data) => ({ type: 'SET_TARIFFS_OFF', params: data })

export const tariffsOffReducer = (state = initialTariffsOffState, action) => {
  switch (action.type) {
    case 'GET_TARIFFS_OFF':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'SET_TARIFFS_OFF':
      return {
        ...state,
        fetching: false,
        data: action.params,
      }
    default:
      return state;
  }
}

export function* getTariffsOffSaga() {
  try {
    const res = yield call(post, { url: `${config.FETCH.url}/user/tariffsoff` });
    if (res.status === 200 && res.data.success) {
      yield put(setTariffsOffAction(res.data.data))
    } else {
      yield put(setTariffsOffAction([]))
    }
  } catch (e) {
    bugsnagClient.notify(e, { context: 'Get tariffs off' });
    yield put(setTariffsOffAction([]))
  }
}