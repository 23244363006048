import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import './style.scss';
import { sendCookieState, getCookieState } from '../../../libs/reducers/actions';
import Modal from '../Modal';
import { Button } from '../Button';

class CookiePanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      agreement: false,
    };
  }

  agree = () => {
    this.props.sendCookieAgreement(true);
  }

  disagree = () => {
    this.props.sendCookieAgreement(false);
  }

  render() {
    const { intl, activeModal, confirmed } = this.props;
    const { agreement } = this.state;
    return <>
      <div className='CoookiePanel' style={{ bottom: (confirmed || !activeModal) ? -200 : 0 }}>
        <div className='PanelBody'>
          <div className='infoIcon'>i</div>
          <div className='content'>
            <div className='text'>
              {intl.formatMessage({ id: 'CookieSitePreamble', defaultMessage: 'You consent with cookie file usage if you are using this website' })} <a onClick={() => this.setState({ agreement: true })}>{intl.formatMessage({ id: 'CookieMoreInfo', defaultMessage: 'Find out more' })}</a>
            </div>
            <div className='cookiebutton' onClick={this.agree}>
              {intl.formatMessage({ id: 'CookieAgree', defaultMessage: 'I agree' })}
            </div>
          </div>
          <div className='closeButton' onClick={this.disagree}>x</div>
        </div>
      </div>
      <Modal
        title={intl.formatMessage({ id: 'CookieFileUsageTitle', defaultMessage: 'This website uses cookies' })}
        visible={agreement}
        onOk={() => this.setState({ agreement: false })}
        closable={false}
        footer={<Button
          key="closeButton"
          kind="aquamarine"
          onClick={() => this.setState({ agreement: false })}>
          Ok
        </Button>}
      >
        <div>{intl.formatMessage({ id: 'CookieFullText', defaultMessage: 'We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services. You consent to our cookies if you continue to use our website.' })}</div>
      </Modal>
    </>
  }
}

export default injectIntl(connect(
  state => ({
    lang: state.langs.lang,
    confirmed: !!state.cookieAgreement.global.confirmed || !!state.cookieAgreement.user.confirmed,
    activeModal: !!state.cookieAgreement.activeModal,
  }), {
    sendCookieAgreement: (confirmed = false) => ({ type: 'SEND_COOKIE_AGREEMENT', payload: { confirmed } }),
  },
)(CookiePanel));