import React from 'react';
import { injectIntl } from 'react-intl';
import Modal from '../../common/Modal';
import { Button } from '../../common/Button';
import MailPng from '../../../images/send.docs.png';

import './modal_style.scss';

class SuccessRequestModal extends React.Component {
  render() {
    const {
      visible,
      onOk,
      intl,
    } = this.props;

    return (
      <Modal
        visible={visible}
        title={false}
        footer={<Button onClick={onOk} kind="aquamarine">Ok</Button>}
        closable={false}
        width={395}
      >
        <div className="success-modal-body">
          <div className="container-img">
            <img src={MailPng} className="modal__img" />
          </div>
          <div className="modal__text">
            {intl.formatMessage({ id: 'YourRequestWillReviewed', defaultMessage: 'Your request will be reviewed within 48 hours.' })}
          </div>
        </div>
      </Modal>
    );
  }
};

export default injectIntl(SuccessRequestModal);
