import actiontypes from './actiontypes';

const {
  LOGOUT,
  PAYMENTDATA_FETCH,
  PAYMENTDATA_SET,
  PAYMENTTOKEN_FETCH,
  PAYMENTTOKEN_SET,
  USERCARDS_FETCH,
  USERCARDS_SET,
  FETCH_WALLET_HISTORY,
  SET_WALLET_HISTORY,
  SET_TARIFFS,
  SET_TARIFF_MY,
  GET_COMMISSION,
  SET_COMMISSION,
  SET_TARIFF_CALLBACK,
  GET_BILLING_LOANS,
  SET_BILLING_LOANS,
  GET_PAYPAL_ORDER,
  SET_PAYPAL_ORDER,
  CLEAR_PAYPAL_ORDER,
  SET_PAYPAL_ERROR,
} = require("./actiontypes").default;

const initialState = {
  tokenCryptedData: {DATA: null, KEY: null, SIGNATURE: null},
  cryptedData: {DATA: null, KEY: null, SIGNATURE: null},
  userCards: [],
  userIbans: [],
  userPaypals: [],
  walletHistory: [],
  tariffs: [],
  commissions: [],
  loans: {},
  usertariff: false,
  renewal: false,
  payPalOrder: false,
  payPalError: false,

  fetchingPayPal: false,
  fetchingData: false,
  fetchingToken: false,
  fetchingCards: false,
  fetchingHistory: false,
  fetchingCommissions: false,
  fetchingLoans: false,
  tariffCallback: false,

  payseraOrder: {},
  payseraWithdraw: {},
};

const BillingReduсer = (state = initialState, action) => {
  switch (action.type) {
    case PAYMENTDATA_FETCH:
      return {
        ...state,
        cryptedData: { DATA: null, KEY: null, SIGNATURE: null },
        fetchingData: true,
      };
    case PAYMENTDATA_SET:
      return { ...state, cryptedData: action.params, fetchingData: false };
    case PAYMENTTOKEN_FETCH:
      return {
        ...state,
        tokenCryptedData: { DATA: null, KEY: null, SIGNATURE: null },
        fetchingToken: true,
      };
    case PAYMENTTOKEN_SET:
      return {
        ...state,
        tokenCryptedData: action.params,
        fetchingToken: false,
      };
    case USERCARDS_FETCH:
      return { ...state, userCards: [], fetchingCards: true };
    case "IBAN_SAVE":
      return { ...state, fetchingCards: true };
    case USERCARDS_SET:
      return {
        ...state,
        userCards: action.params.cards,
        userIbans: action.params.ibans,
        userPaypals: action.params.paypals,
        fetchingCards: false,
      };
    case FETCH_WALLET_HISTORY:
      return { ...state, fetchingHistory: true };
    case SET_WALLET_HISTORY:
      return { ...state, fetchingHistory: false, walletHistory: action.params };
    case SET_TARIFF_MY:
      return {
        ...state,
        usertariff: action.params.tariff,
        renewal: action.params.renewal,
      };
    case SET_TARIFFS:
      return { ...state, tariffs: [...action.params] };
    case GET_COMMISSION:
      return { ...state, fetchingCommissions: true };
    case SET_COMMISSION:
      return {
        ...state,
        fetchingCommissions: false,
        commissions: action.params,
      };
    case SET_TARIFF_CALLBACK:
      return { ...state, tariffCallback: action.params };
    case GET_BILLING_LOANS:
      return { ...state, fetchingLoans: true };
    case SET_BILLING_LOANS:
      return { ...state, fetchingLoans: false, loans: action.params };
    case GET_PAYPAL_ORDER:
      return {
        ...state,
        fetchingPayPal: true,
        payPalError: false,
        payPalOrder: null,
      };
    case SET_PAYPAL_ORDER:
      return { ...state, payPalOrder: action.params };
    case CLEAR_PAYPAL_ORDER:
      return { ...state, fetchingPayPal: false, payPalOrder: null };
    case SET_PAYPAL_ERROR: {
      return { ...state, fetchingPayPal: false, payPalError: true };
    }
    case "PAYSERA_ORDER_REQUEST":
      return {
        ...state,
        payseraOrder: {
          fetching: true,
          error: false,
          url: undefined,
        },
      };
    case "PAYSERA_ORDER_SUCCESS":
      return {
        ...state,
        payseraOrder: {
          ...state.payseraOrder,
          fetching: false,
          url: action.params.url,
        },
      };
    case "PAYSERA_ORDER_FAILURE":
      return {
        ...state,
        payseraOrder: {
          ...state.payseraOrder,
          fetching: false,
          error: true,
        },
      };
    case "PAYSERA_WITHDRAW_REQUEST":
      return {
        ...state,
        payseraWithdraw: {
          ...state.payseraWithdraw,
          fetching: true,
          error: false,
        },
      };
    case "PAYSERA_WITHDRAW_SUCCESS":
      return {
        ...state,
        payseraWithdraw: {
          ...state.payseraWithdraw,
          fetching: false,
        },
      };
    case "PAYSERA_WITHDRAW_FAILURE":
      return {
        ...state,
        payseraWithdraw: {
          ...state.payseraWithdraw,
          fetching: false,
          error: true,
        },
      };
    case LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};

export default BillingReduсer;
