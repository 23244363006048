import React, { Component } from 'react';
import { isEqual } from 'lodash';
import axios from 'axios';
import moment from 'moment';
import { CamPlayer, Utils } from './player';
import Slider from '../common/Slider';
import config from '../../config';
import playImage from '../../images/playImage.svg';
import pauseImage from '../../images/pauseImage.svg';
import fullscreenImage from '../../images/fullscreenImage.svg';
import screenshotImage from '../../images/screenshotImage.svg';
import volumeNormalImage from '../../images/volumeNormalImage.svg';
import volumeLoudImage from '../../images/volumeLoudImage.svg';
import volumeMutedImage from '../../images/volumeMutedImage.svg';
import bugsnagClient from '../../bugsnagClient';
import { injectIntl, intlShape } from 'react-intl';

import videoPreloader from '../../images/video_preloader.svg';

const { host: videoHost, secure: videoSecure } = config.VIDEOS;
const screenShots = [];

const makeHlsLiveUrl = stream => Utils.makeHlsLiveUrl(videoSecure, videoHost, stream);
const makeMseLiveUrl = stream => Utils.makeMseLiveUrl(videoSecure, videoHost, stream);

const logger = {};
logger.log = config.VIDEOS.debug ? console.log : function log() {};
logger.error = console.error;
logger.warn = console.warn;
logger.info = console.info;

const setinterval = (callback, time, timer) => {
  const run = () => {
    callback();
    timer.timerId = setTimeout(run, time); // eslint-disable-line
  };
  timer.timerId = setTimeout(run, time); // eslint-disable-line
};

const clearinterval = ({ timerId } = {}) => clearTimeout(timerId);

const parseArchiveURL = (url) => {
  if (!url) {
    return null;
  }
  const match = url.match(/video-(\d+)-(\d+)\.m3u8/);
  if (!match || match.length !== 2) {
    return null;
  }
  const start = +match[1];
  const duration = +match[2];
  return { start, duration };
};

const iOS = Utils.iOS();
const liveHls = iOS || (Utils.isSafari() && !config.VIDEOS.mseOnSafari) || Utils.isAndroid();
//const liveHls = true; 
const makeLiveUrl = liveHls ? makeHlsLiveUrl : makeMseLiveUrl;
// P.S. source = 'hostname:port/stream'
const getStream = source => (source ? source.match(/\/([a-zA-Z\-0-9]+)$/)[1] : '');
const downloadImage = async (source) => {
  if (screenShots.indexOf(source) !== -1) {
    logger.log('image is already taken: ', source);
    return;
  }
  screenShots.push(source);
  if (Utils.iOS()) {
    window.open(source);
    return;
  }
  axios
    .request({
      method: 'GET',
      url: source,
      responseType: 'blob',
    })
    .then((response) => {
      const { status } = response;
      if (status !== 200) {
        logger.log('invalid status (while fetching screen shot): ', status);
        return;
      }
      const wURL = window.URL || window.webkitURL;
      const { data: blob } = response;
      const resource = wURL.createObjectURL(blob);
      const link = window.document.getElementById('screen_link');
      link.href = resource;
      link.download = source;
      link.click();
      setTimeout(() => {
        wURL.revokeObjectURL(resource);
        link.href = '';
      });
    })
    .catch((error) => {
      const { response } = error || {};
      logger.log('error (while fetching screen shot): ', error);
      if (response) {
        logger.log('error.response: ', response);
      }
    });
};

const delay = timeout => new Promise(resolve => setTimeout(resolve, timeout));

const findArchive = (archives = [], targetTimestamp = Math.floor(Date.now() / 1000)) => archives.find(({ timestamp, duration }) => {
  const end = timestamp + duration;
  return timestamp <= targetTimestamp && end >= targetTimestamp;
});

const getLast = (array) => {
  if (array && array.length) {
    return array[array.length - 1];
  }
  return null;
};

const makeArchiveURL = (stream, archive) => {
  if (!archive) {
    return makeLiveUrl(stream);
  }
  const url = `${videoSecure ? 'https' : 'http'}://${videoHost}/${stream}/video-${archive.timestamp}-${
    archive.duration
  }.m3u8`;
  return url;
};

class Protest extends Component {
  render() {
    const { timestamp } = this.props; // timestamp in secs
    const time = moment(timestamp * 1000).format('HH:mm:ss');
    return <div className="protest-label">{`${time} - Протест`}</div>;
  }
}

class VideosTab extends Component {
  static ref = (camRef) => {
    const { ref: { vidRef: ref1 = null, ref: { vidRef: ref2 = null } = {} } = {} } = camRef || {};
    const ref = ref1 || ref2;
    return ref;
  };

  state = {
    videoSources: [],
    videoSourcesSorted: [],
    range: undefined,
    isLive: true,
    videoControl: {},
    streamsSorted: [],
    urls: [],
    volume: 10,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { videoSources: videoSourcesNew, videoControl: videoControlNew, archives } = nextProps;
    const { videoSources: videoSourcesOld, videoControl: videoControlOld = {} } = prevState;
    let retval = null;
    if (!isEqual(videoSourcesNew, videoSourcesOld)) {
      const videoSources = videoSourcesNew || [];
      const streamsSorted = videoSources.map(getStream);
      const urls = streamsSorted.map(makeLiveUrl);
      retval = {
        videoSources,
        videoSourcesSorted: videoSources,
        streamsSorted,
        urls,
      };
    }
    if (videoControlOld && videoControlNew && videoControlNew.update !== videoControlOld.update && videoControlNew.targetTime) {
      retval = retval || {};
      const { streamsSorted = prevState.streamsSorted } = retval;
      retval.videoControl = videoControlNew;
      const _date = new Date(videoControlNew.targetTime);
      const timestamp = +_date / 1000;
      const archive = findArchive(archives, timestamp);
      logger.log('archive time update -> archive', archive);
      logger.log('archive time update -> income.timestamp', timestamp, _date.toISOString());
      if (!archive) {
        const diff = Date.now() - _date;
        const day = -Math.floor(diff / (24 * 60 * 60 * 1000) + 1);
        nextProps.fetchVideoArchivesAll([...streamsSorted]);
        // новый апи пока на испытании (2019-07-29)
        // for (const stream of streamsSorted) {
        //   logger.log('archive time update -> fetch archive', { stream, day });
        //   nextProps.fetchVideoArchive({ stream, day });
        // }
      } else {
        retval.isLive = false;
        retval.urls = streamsSorted.map(stream => makeArchiveURL(stream, archive));
        retval.streamsSorted = streamsSorted;
        retval.range = ((timestamp - archive.timestamp) / archive.duration) * 1000;
      }
    }
    return retval;
  }

  componentDidMount() {
    this.initBackgroundLive();
    this.syncVideos();
  }

  componentWillUnmount() {
    window.document.getElementById('screen_link').href = '';
    clearinterval(this.timer);
    cancelAnimationFrame(this.animationTimerId);
  }

  camRefs = [];

  makeArchiveUrl = (stream) => {
    const { archives = [] } = this.props;
    if (archives.length) {
      const item = archives[archives.length - 1];
      return makeArchiveURL(stream, item);
    }
    return makeLiveUrl(stream);
  };

  makeUrl = (stream) => {
    const { isLive } = this.state;
    if (isLive) {
      return makeLiveUrl(stream);
    }
    return this.makeArchiveUrl(stream);
  };

  onClickVideo = index => () => {
    const videoSourcesSorted = [...this.state.videoSourcesSorted];
    if (!videoSourcesSorted.length || videoSourcesSorted.length <= index) {
      return;
    }
    const [tmp] = videoSourcesSorted;
    videoSourcesSorted[0] = videoSourcesSorted[index];
    videoSourcesSorted[index] = tmp;
    const streamsSorted = videoSourcesSorted.map(getStream);
    const urls = streamsSorted.map(this.makeUrl);
    const { isLive } = this.state;
    this.setState({ videoSourcesSorted, streamsSorted, urls });
  };

  takeScreenShot = () => {
    const screenShotUrl = this.camRef.makeScreenShot();
    downloadImage(screenShotUrl);
  };

  onRangeChange = (range) => {
    const { streamsSorted: streams, isLive } = this.state;
    if (isLive) {
      const archive = getLast(this.props.archives);
      const nextUrls = streams.map(stream => makeArchiveURL(stream, archive));
      this.setState({ isLive: false, urls: nextUrls }, () => {
        const duration = this.camRef.getVideoDuration();
        this.setState({ range, duration });
      });
    } else {
      const duration = this.camRef.getVideoDuration();
      this.setState({ range, duration });
    }
  };

  _restart_ = () => {
    for (const ref of this.camRefs) {
      if (typeof ref.restart === 'function') {
        ref.restart();
      }
    }
  };

  goToLive = () => {
    logger.log('[+] go to live');
    const { fetchVideoArchive, fetchVideoArchivesAll } = this.props;
    const { streamsSorted, isLive, range } = this.state;
    // на период испытания оставлю закомментированным старый метод (2019-07-29)
    // if (fetchVideoArchive) {
    //   for (const stream of streamsSorted) {
    //     fetchVideoArchive({ stream });
    //   }
    // }
    if (fetchVideoArchivesAll) {
      fetchVideoArchivesAll([...streamsSorted]);
		}
    if (isLive) {
      this.onRangeChange(range);
      return;
    }
    this.setState({
      isLive: true,
      urls: streamsSorted.map(makeLiveUrl),
    });
  };

  videoPlay = () => this.setPaused(false);

  videoPause = () => this.setPaused(true);

  videoRange = () => {
    const { isLive } = this.state;
    if (isLive) {
      return null;
    }
    const time = this.camRef.getVideoCurrentTime();
    const duration = this.camRef.getVideoDuration();
    const range = (time / duration) * 1000;
    return { range, duration };
  };

  setPaused = (paused) => {
    const { isLive } = this.state;
    if (isLive) {
      return;
    }
    const res = this.videoRange();
    if (!res) {
      return;
    }
    const { range, duration } = res;
    this.setState({ paused, range, duration });
  };

  rangePlus = () => {
    const { range } = this.state;
    this.setState({ range: range + 100 });
  };

  rangeMinus = () => {
    const { range } = this.state;
    this.setState({ range: range - 100 });
  };

  mediaDuration = duration => this.setState({ duration });

  initBackgroundLive = () => {
    this.timer = this.timer || {};
    setinterval(
      () => {
        const { isLive, backLiveComponent } = this.state;
        if (isLive && backLiveComponent) {
          this.setState({ backLiveComponent: null });
          logger.log('live -> remove background player', Date.now() / 1000);
        } else if (!isLive && !backLiveComponent) {
          this.setState({ backLiveComponent: this.renderBackgroundLive() }, () => {
            setTimeout(() => {
              this.setState({ backLiveComponent: null });
              logger.log('archive -> remove background player', Date.now() / 1000);
            }, 5 * 1000);
          });
          logger.log('archive -> start background player', Date.now() / 1000);
        }
      },
      18 * 1000,
      this.timer,
    );
  };

  renderBackgroundLive = () => {
    const { streamsSorted } = this.state;
    const hidden = { display: 'none' };
    return (
      <div style={hidden}>
        {streamsSorted.map(makeLiveUrl).map((url, idx) => (
          <CamPlayer key={idx} id={`camera-${idx}`} url={url} autoPlay muted onRangeChange={() => {}} />
        ))}
      </div>
    );
  };

  requestFullScreen = () => {
    const { ref: { vidRef: ref1 = null, ref: { vidRef: ref2 = null } = {} } = {} } = this.camRef || {};
    const ref = ref1 || ref2;
    const { prototype: proto } = Element || {};
    if (!proto) {
      console.error('Fatal Error: Element.prototype not found');
    }
    if (proto && !proto.requestFullScreen) {
      proto.requestFullScreen = proto.mozRequestFullScreen || proto.webkitRequestFullScreen || proto.msRequestFullScreen;
      if (!proto.requestFullScreen) {
        proto.requestFullScreen = HTMLVideoElement.prototype.webkitEnterFullScreen || HTMLVideoElement.prototype.webkitRequestFullScreen;
      }
      logger.log('Element.prototype.requestFullScreen', proto.requestFullScreen);
    }
    if (!ref) {
      const message = 'html video reference not found, ref: ';
      console.error(message, ref);
      bugsnagClient.notify(new Error(`${message}${ref}`), {
        context: 'VideosTab',
        beforeSend: (report) => {
          report.updateMetaData('action', {
            type: 'requestFullScreen',
          });
        },
      });
    } else if (typeof ref.tagName !== 'string' || ref.tagName.toLowerCase() !== 'video') {
      const message = 'reference is not video tag, tagName: ';
      console.error(message, ref.tagName);
      bugsnagClient.notify(new Error(`${message}${ref.tagName}`), {
        context: 'VideosTab',
        beforeSend: (report) => {
          report.updateMetaData('action', { type: 'requestFullScreen' });
        },
      });
    } else if (typeof ref.requestFullScreen !== 'function') {
      const message = ref.requestFullScreen;
      console.error(message, ref.requestFullScreen);
      bugsnagClient.notify(new Error(`${message}${ref.requestFullScreen}`), {
        context: 'VideosTab',
        beforeSend: (report) => {
          report.updateMetaData('action', { type: 'requestFullScreen' });
        },
      });
    } else if (typeof ref.requestFullScreen === 'function') {
      ref.requestFullScreen();
    } else if (typeof proto.requestFullScreen === 'function') {
      try {
        proto.requestFullScreen.call(ref);
      } catch (error) {
        console.error('requestFullScreen -> error:', error);
        bugsnagClient.notify(error, {
          context: 'VideosTab',
          beforeSend: (report) => {
            report.updateMetaData('action', { type: 'requestFullScreen' });
          },
        });
      }
    } else {
      const message = 'requestFullScreen is not supported';
      console.error(message);
      bugsnagClient.notify(new Error(message), {
        context: 'VideosTab',
        beforeSend: (report) => {
          report.updateMetaData('action', { type: 'requestFullScreen' });
          report.severity = 'warning'; // eslint-disable-line no-param-reassign
        },
      });
    }
  };

  syncVideos = () => {
    let now = performance.now();
    const callback = (time) => {
      const { isLive } = this.state;
      const dif = time - now;
      if (dif > 5000 && !isLive) {
        const refs = this.camRefs.map(VideosTab.ref);
        if (refs.every(r => !!r)) {
          const { currentTime: tm } = refs[0];
          for (let i = 1; i < refs.length; i += 1) {
            logger.log('refs[', i, '].currentTime', refs[i].currentTime, refs[i].duration, tm, refs[0].duration);
            refs[i].currentTime = tm;
          }
          now = time;
        }
      }
      this.animationTimerId = requestAnimationFrame(callback);
    };
    this.animationTimerId = requestAnimationFrame(callback);
  };

  onChangeVolume = (volume) => {
    const res = this.videoRange();
    if (res) {
      const { range, duration } = res;
      this.setState({
        volume,
        muted: false,
        range,
        duration,
      });
    } else {
      this.setState({ volume, muted: false });
    }
  };

  onMuted = () => {
    const { muted } = this.state;
    const res = this.videoRange();
    if (res) {
      const { range, duration } = res;
      this.setState({ muted: !muted, range, duration });
    } else {
      this.setState({ muted: !muted });
    }
  };

  render() {
    const {
      urls, range = 1000, paused, isLive, backLiveComponent, muted = true, volume = 21,
    } = this.state;
    const { videoControl, pauseSwitch, intl, token } = this.props;
    let volumeImage = volumeNormalImage;
    if (muted) {
      volumeImage = volumeMutedImage;
    } else if (volume >= 50) {
      volumeImage = volumeLoudImage;
    }
    let playImg = pauseImage;
    if (isLive) {
      playImg = pauseImage;
    } else {
      playImg = paused ? playImage : pauseImage;
    }
    const controller = (
      <div className="video-controller">
        {!this.props.hideControl ? <div className="video-controller-left">
          <div
            className="video-play"
            onClick={() => {
              if (isLive) {
                this._restart_();
              } else if (paused) {
                this.videoPlay();
              } else {
                this.videoPause();
              }
            }}
          >
            <img src={playImg} />
          </div>
          <div className={`video-live ${isLive ? 'live' : 'archive'}`} onClick={this.goToLive}>
            {isLive ? 'LIVE' : intl.formatMessage({ id: 'archive', defaultMessage: 'Archive' })}
          </div>
        </div> : <div className="video-controller-left"/>}
        <div className="video-controller-rigth">
          <div className="video-screenshot" onClick={this.takeScreenShot}>
            <img src={screenshotImage} />
          </div>
          <div className="video-volume" onClick={this.onMuted}>
            <img src={volumeImage} />
          </div>
          <div className="video-volume-slider">
            <Slider value={volume} min={0} max={100} onChange={this.onChangeVolume} />
          </div>
          <div className="video-fullscreen">
            <img src={fullscreenImage} onClick={this.requestFullScreen} />
          </div>
        </div>
      </div>
    );
    if (this.props.denied) {
      return <div className='videosContainer' style={{ borderWidth: 1, borderStyle: 'solid', borderColor: '#fff8', justifyContent: 'center', alignItems: 'center', color: '#fff8' }}>
        {intl.formatMessage({ id: 'PlayerDeniedObserving', defaultMessage: 'Player resticted observing' })}
      </div>
    }
    return (
      <div className={`videosContainer${this.props.mirrored?' mirrored':''}`}>
        <div className="videosContainer-left">
          {urls.map(
            (videoSource, i) => (i !== 0 ? (
              <div className='videosContainer-video'>
                <div className="videoPreloader">
                  <img src={videoPreloader}/>
                </div>
                <div className="container-cover" key={i} onClick={this.onClickVideo(i)}>
                  <CamPlayer
                    id={`VPlayer-${i}`}
                    key={videoSource+'?token='+token}
                    url={videoSource+'?token='+token}
                    videoControl={videoControl}
                    range={range}
                    paused={paused}
                    volume={volume}
                    muted={muted}
                    debug={config.VIDEOS.debug}
                    autoPlay
                    hideSlider
                    pauseSwitch={pauseSwitch}
                    onStreamEnd={this.goToLive}
                    ref={(c) => {
                      this.camRefs[i] = c;
                    }}
                  />
                </div>
              </div>
            ) : null),
          )}
        </div>
        {urls[0] && (
          <div className="videosContainer-right">
            <div className='videosContainer-video'>
              <div className="videoPreloader">
                <img src={videoPreloader}/>
              </div>
              <div className="container-cover">
                <CamPlayer
                  id="VPlayer-0"
                  key={urls[0]+'?token='+token}
                  url={urls[0]+'?token='+token}
                  videoControl={videoControl}
                  isLive={isLive}
                  autoPlay
                  range={range}
                  paused={paused}
                  volume={volume}
                  muted={muted}
                  hideSlider={this.props.hideControl}
                  onRangeChange={this.onRangeChange}
                  mediaDuration={this.mediaDuration}
                  debug={config.VIDEOS.debug}
                  pauseSwitch={pauseSwitch}
                  ref={(c) => {
                    this.camRef = c;
                    this.camRefs[0] = c;
                  }}
                />
                <div className="videosTab-control-container">{controller}</div>
              </div>
            </div>
          </div>
        )}
        {backLiveComponent}
      </div>
    );
  }
}

export default injectIntl(VideosTab);
