import React from 'react';
import progressSearch from '../../../images/progress-search.svg';
import './style.css';

export default class SerachProgress extends React.Component {
  render() {
    const {
      loading,
      text
    } = this.props;

    const showSearch = loading
      ? <div className="progress-container">
          <div className="progress-container__img">
            <img src={progressSearch} />
          </div>
          <div className="progress-label">
            {text || ''}
          </div>
        </div>
      : '';

    return (
      <>
        {showSearch}
      </>
    );
  }
}