import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Button } from '../common/Button';
import BillingBadge from '../common/BillingBadge';
import Checkbox from '../common/Checkbox';

import closeButton from '../../images/closeButton.svg';

import './style.scss';

class TariffPicker extends React.Component {
  constructor(props) {
    super(props);
  
    this.state = {
      agreementRules: false,
    }
  }

  static propTypes = {
    intl: intlShape,
    tariffs: PropTypes.array,
    onSelect: PropTypes.func,
    onClose: PropTypes.func,
  };

  render() {
    const { intl, tariffs = [] } = this.props;
    return <>
      <div className='tariffPicker'>
        <div className='pickerBody'>
          <div className='pickerBackground'>
            <img className='close' src={closeButton} onClick={() => this.props.onClose()}/>
            <div className='tariffPickerHeader'>{intl.formatMessage({ id: 'SelectTariffVerbose', defaultMessage: 'Choose tariff' })}</div>
            <div className='tariffCarousel'>     
              {tariffs.map(tariff => <>
                <div className='tariffRepresent' style={{background: tariff.bgbody, position: 'relative', top: tariff.best?-5:0}}>
                  <div className='tariffCardHeader' style={{background: tariff.bg}}>{tariff.name}</div>
                  {tariff.best && <div className='tariffIsBest'>{intl.formatMessage({ id: 'BestChoiseAsCommerceTrickery', defaultMessage: 'Best choise' })}</div>}
                  <div className='fullinfo'>
                    <div className='price'>
                      <div className='amount'>{Math.floor(tariff.price)}<small>{(tariff.price%1).toFixed(2).slice(2)}</small></div>
                      <div className='expl'>
                        <span>euro</span>
                        <span>{intl.formatMessage({ id: 'MonthInTimescaleContext', defaultMessage: 'Month' })}</span>
                      </div>
                    </div>
                    <div className='services'>
                      {tariff.services.map((serv, index) => 
                        <div class='amount' key={index}>{intl.formatMessage({ id: serv.name, defaultMessage: serv.name })}</div>
                      )}
                    </div>
                  </div>
                  <Button
                    kind='aquamarine'
                    bordered="true"
                    disabled={!this.state.agreementRules}
                    onClick={() => this.props.onSelect(tariff)}>
                    {intl.formatMessage({ id: 'SelectTariff', defaultMessage: 'Select tariff' })}
                  </Button>
                </div>
              </>)}
            </div>
            <Checkbox checked={this.state.agreementRules} onChange={agreementRules => this.setState({ agreementRules })}>
              <span>
                {intl.formatMessage({ id: 'i_agree_with', defaultMessage: 'I agree with' })}
                &nbsp;
                <a
                  style={{ fontWeight: 'bold' }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.onTnCOpen()
                  }}
                >
                  {intl.formatMessage({ id: 'TCagree', defaultMessage: 'terms and condition' })}
                </a>
              </span>
            </Checkbox>
            <Checkbox checked={this.props.agreementRenewal} onChange={() => this.props.onRenewalChange()}>
              {intl.formatMessage({ id: 'TariffAgreementsRenewal', defaultMessage: 'Automatically renew the tariff plan (Consent to funds charge at the end of the tariff period)' })}
            </Checkbox>
          </div>
        </div>
        <BillingBadge/>
      </div>
    </>
  }
}

export default injectIntl(TariffPicker);
