import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import config from '../../../config';
import defaultAvatar from '../Opponent/images/default.png';
import Avatar from '../Avatar';

import './style.scss';

class PendingGame extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ETAstring: '',
      ETAtime: new Date(),
    };

    this.timer = null;
  }

  static propTypes = {
    userid: PropTypes.number,
    fullname: PropTypes.string,
    login: PropTypes.string,
    photo: PropTypes.string,
    flag: PropTypes.string,
    timestart: PropTypes.number,
    timespan: PropTypes.number,
    bet: PropTypes.number,
    onTrigger: PropTypes.func,
    intl: intlShape,
    avgscore: PropTypes.number,
  };

  componentDidMount() {
    this.PrimeTimer();
  }

  componentDidUpdate(oldprops) {
    if (oldprops.timestart !== this.props.timestart) {
      this.PrimeTimer();
      clearTimeout(this.timer);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  PrimeTimer() {
    const { timestart, timespan, onTrigger } = this.props;
    if (+new Date() < timestart + timespan) {
      const ETAtime = moment.utc(moment.duration(timestart + timespan) - moment.duration(Date.now()))
      const ETAstring = moment.utc(moment.duration(timestart + timespan) - moment.duration(Date.now())).format('mm:ss');

      this.setState({ ETAtime, ETAstring });
      this.timer = setTimeout(() => {
        this.PrimeTimer();
      }, 250);
    } else {
      this.setState({ ETAtime: 0, ETAstring: '00:00' });
      onTrigger();
    }
  }

  render() {
    const {
      flag, photo, fullname, login, intl, avgscore,
    } = this.props;
    const { ETAstring } = this.state;
    return (
      <div className="active_game_card">
        <div className="active_game_card__avatar">
          <Avatar
            uri={photo ? `${config.FETCH.img}/${photo}` : defaultAvatar}
            flag={flag}
            avgscore={avgscore}
          />
          <div className="active_game_card__opponent">
            <div className="active_game_card__fullname">{fullname}</div>
            <div className="active_game_card__login">{login}</div>
          </div>
        </div>
        <div className="active_game_card__footer">
          <div className="active_game_card__wait">{intl.formatMessage({ id: 'MyBattlesWaitRival', defaultMessage: 'Wait for opponent' })}</div>
          <div className="active_game_card__timer">{ETAstring}</div>
        </div>
      </div>
    );
  }
}

export default injectIntl(PendingGame);
