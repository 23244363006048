import React from 'react';
import { isEqual } from 'lodash';
import config from '../../config';

import './style.scss';

const canvasProps = {
  preview: {
    width: 391,
    height: 241,
  },
  lens: {
    width: 108,
    height: 112,
    color: '#fff3',
  },
  zoom: {
    width: 343,
    height: 350,
  },
};

export default class ImageZoomer extends React.Component {
  constructor(props) {
    super(props);
    this.refPreview = React.createRef();
    this.refZoom = React.createRef();

    this.state = {
      selected: null,
      imgPrevWidth: 1,
      imgPrevHeight: 1,
      xCoeff: 1,
      yCoeff: 1,
      left: 0,
      top: 0,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { images } = this.props;
    const { selected } = this.state;

    if (this.props.images.length && prevProps.images !== this.props.images) {
      if (this.props.images) this.setState({ selected: this.props.images[0] });
    }
    if (images && images.length && !isEqual(prevState.selected, selected)) {
      this.getImgSize(`${config.FETCH.url}/userdocs/user/new/${(selected || {}).photo}`);
    }
  }

  onMouseMove = event => {
    const { imgPrevWidth, imgPrevHeight, xCoeff, yCoeff } = this.state;
    const xPos = event.clientX - this.refPreview.current.getBoundingClientRect().left;
    const yPos = this.refPreview.current.getBoundingClientRect().height - this.refPreview.current.getBoundingClientRect().bottom + event.clientY
    const context = this.refPreview.current.getContext('2d');
    context.clearRect(0, 0, this.refPreview.current.getBoundingClientRect().width, this.refPreview.current.getBoundingClientRect().height);
    context.fillStyle = canvasProps.lens.color;
    const xLimit = xPos < canvasProps.lens.width / 2
      ? 0
      : xPos > imgPrevWidth - canvasProps.lens.width / 2
        ? imgPrevWidth - canvasProps.lens.width
        : xPos - canvasProps.lens.width / 2
    const yLimit = yPos < canvasProps.lens.height / 2
      ? 0
      : yPos > imgPrevHeight - canvasProps.lens.height / 2
        ? imgPrevHeight - canvasProps.lens.height
        : yPos - canvasProps.lens.height / 2
    context.fillRect(xLimit, yLimit, canvasProps.lens.width, canvasProps.lens.height);
    this.setState({
      xPos: xLimit / this.refPreview.current.getBoundingClientRect().width,
      yPos: yLimit / this.refPreview.current.getBoundingClientRect().height,
      left: -100 * xLimit / (imgPrevWidth - canvasProps.lens.width),
      top: -100 * yLimit / (imgPrevHeight - canvasProps.lens.height),
    });
  }

  onSwapImages = (index) => {
    const { type, images } = this.props;
    this.props.onSwapImages(index, type)
    this.refPreview = React.createRef();
    this.setState({ selected: images[0] });
    this.getImgSize(`${config.FETCH.url}/userdocs/user/new/${images[0].photo}`);
  }

  getImgSize = (imgSrc) => {
    const newImg = new Image();
    newImg.onload = function () {
      const height = newImg.height;
      const width = newImg.width;
      const canvasHeight = canvasProps.preview.height;
      const canvasWidth = canvasProps.preview.width;
      const minCoeff = Math.min(canvasWidth / width, canvasHeight / height);
      const zoomHeight = canvasProps.zoom.height;
      const zoomWidth = canvasProps.zoom.width;
      const minZoomCoeff = Math.min(zoomWidth / width, zoomHeight / height);
      this.setState({
        imgPrevWidth: width * minCoeff,
        imgPrevHeight: height * minCoeff,
        xCoeff: canvasWidth / width,
        yCoeff: canvasHeight / height
      });
    }.bind(this);
    newImg.src = imgSrc;
  }

  render() {
    const { imgPrevWidth, imgPrevHeight, left, top } = this.state;
    return <>
      <div className="docs_viewer_images">
        <div className="docs_viewer_images__preview">
          <div className="docs_viewer_images__preview_container">
            <img
              src={`${config.FETCH.url}/userdocs/user/new/${(this.state.selected || {}).photo}`}
              style={{
                width: imgPrevWidth,
                height: imgPrevHeight,
              }}
            />
            <canvas
              ref={this.refPreview}
              key={(this.state.selected || {}).photo}
              onMouseMove={this.onMouseMove}
              height={imgPrevHeight}
              width={imgPrevWidth}
            />
          </div>
          {this.props.images.map((image, index) => {
            if (index !== 0) return <img
              src={`${config.FETCH.url}/userdocs/user/new/${image.photo}`}
              onClick={() => { this.onSwapImages(index) }}
            />
          })}
        </div>
        <div className='zoomWrap'>
          <img
            style={{
              position: 'absolute',
              left: `${left}%`,
              top: `${top}%`,
            }}
            src={`${config.FETCH.url}/userdocs/user/new/${(this.state.selected || {}).photo}`}
          />
        </div>
      </div>
    </>
  }
}