import React, { Component } from "react";
import ReactWOW from "react-wow";
import benefits from "./images/benefits.png";
import "./style.scss";
import "./adaptive.scss";
import utils from "../../libs/utils";

export default class Benefits extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    console.log('render benefits')
    const { intl, history } = this.props;
    return (
      <div className="landing__container">
        <div id="benefits" className="benefits">
          <h2>
            {intl.formatMessage({
              id: 'LandingBenefitsTitle1',
              defaultMessage: 'Benefits for',
            })}
            <br />
            {intl.formatMessage({
              id: 'LandingBenefitsTitle2',
              defaultMessage: 'Bowling Center owners',
            })}
          </h2>
          <div className="container">
            <div className="left">
              <img src={benefits} alt="" />
            </div>
            <div className="right">
              <div>
                <ol>
                  {utils.isMobile() ? (
                    <li>
                      <span>01</span>
                      {intl.formatMessage({
                        id: 'LandingBenefits1',
                        defaultMessage: 'Attract more new visitors with TenPinCam',
                      })}
                    </li>
                  ) : (
                    <ReactWOW
                      animation="customFadeInLeft"
                      duration="1.5s"
                      delay="0.5s"
                    >
                      <li>
                        <span>01</span>
                        {intl.formatMessage({
                          id: 'LandingBenefits1',
                          defaultMessage: 'Attract more new visitors with TenPinCam',
                        })}
                      </li>
                    </ReactWOW>
                  )}
                  <li>
                    <span>02</span>
                    {intl.formatMessage({
                      id: 'LandingBenefits2',
                      defaultMessage: 'New entertaining format – play with people from different places using TenPinCam mobile App',
                    })}
                  </li>
                  {/* {utils.isMobile() ? (
                    <li>
                      <span>02</span>
                      New entertaining format – play with people from defferent 
                      places using TenPinCam mobile App
                    </li>
                  ) : (
                    <ReactWOW
                      animation="customFadeInLeft"
                      duration="1.5s"
                      delay="1s"
                    >
                      <li>
                        <span>02</span>
                        New entertaining format – play online with people from
                        other places.
                      </li>
                    </ReactWOW>
                  )} */}
                  {utils.isMobile() ? (
                    <li>
                      <span>03</span>
                      {intl.formatMessage({
                        id: 'LandingBenefits3',
                        defaultMessage: 'More returning customers - Tournament & league players, that play regularly',
                      })}
                    </li>
                  ) : (
                    <ReactWOW
                      animation="customFadeInLeft"
                      duration="1.5s"
                      delay="1.5s"
                    >
                      <li>
                        <span>03</span>
                        {intl.formatMessage({
                          id: 'LandingBenefits3',
                          defaultMessage: 'More returning customers - Tournament & league players, that play regularly',
                        })}
                      </li>
                    </ReactWOW>
                  )}
                </ol>
              </div>
              <div className="benefits__btn">
                <div
                  onClick={() => {
                    this.props.scrollToEquipmentForm();
                  }}
                  className="btn small filled"
                >{intl.formatMessage({
                  id: 'LandingBenefitsBtn',
                  defaultMessage: 'I need it',
                })}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
