/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { Button } from '../../common/Button';
import '../../../style.scss';
import RenderUserCard from '../../common/UserCardTable';
import IconsSVG from '../../iconsSvg';
import SvgButton from '../../common/SvgButton';
import NewTable from '../../common/Table';
import ModalSaveVideo from '../modalSaveVideo';
import GameResultsModal from '../gameResults/gameResultsModal';
import {
  getBattlesList,
  getAdminDiskSize,
  saveAdminBattleListFilters,
} from '../../../libs/reducers/adminReducer';
import {
  setProtocolSquadId,
  unbindVideoFilesOfUser,
  UserByIdFetch,
  getPermissions,
  setVideoArchiveSquadId,
  setObservableSquad,
  clearGameInfo,
} from '../../../libs/reducers/actions';
import VisibilityButton from "../../visibilityButton/visibilityButton";


const BattleList = ({
  admin,
  permissions,
  UserByIdFetch,
  isSearch,
  setProtocolSquadId,
  history,
  setVideoArchiveSquadId,
  unbindVideoFilesOfUser,
  setObservableSquad,
  getBattlesList,
  videofiles,
  user,
  getPermissions,
  intl,
  clearGameInfo,
  saveAdminBattleListFilters,
}) => {

  const [showGameResultsModal, setShowGameResultsModal] = useState(false)
  const [selectedGame, setSelectedGame] = useState(null)
  const [isVisibleModalSaveVideo, setIsVisibleModalSaveVideo] = useState(false)
  const [openSquadId, setOpenSquadId] = useState(null)
  const [isDelete, setIsDelete] = useState(false)
  const [adminDiskSize, setAdminDiskSize] = useState('')
  const [lastParamsBattle, setLastParamsBattle] = useState({
    datestart: [moment().startOf('year').toISOString(), moment(Date.now()).toISOString()],
    limit: 10,
    page: 1,
  })

  const tablefilters = {}
  const battlelistfilter_player = ''
  const battlelistfilter_type = null
  const battlelistfilter_date_from = moment().startOf('month')
  const battlelistfilter_date_to = null
  const battlelistfilter_withmoney = false
  const battlesList = admin.battlesList;
  const battleListData = battlesList.battles;
  const battleListCount = battlesList.count;

  const onGotoProtocol = (squadid) => {
    setProtocolSquadId(squadid);
    history.push('/protocol');
  }
  const onDeleteSaveVideo = (squadid) => {
    unbindVideoFilesOfUser({ squadid, adminSave: 1 });
    setIsDelete(true);
  }
  const onOpenModalSaveVideo = (squadid) => {
    setIsVisibleModalSaveVideo(true)
    setOpenSquadId(squadid)
  }
  const onChangeBattleList = (params) => {
    setLastParamsBattle(params);
    getBattlesList(params)
  }
  const onCloseModalSaveVideo = () => {
    setIsVisibleModalSaveVideo(false);
  }
  const onRebootBattleList = () => {
    getBattlesList(lastParamsBattle);
    getAdminDiskSize();
  }
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current === undefined ? value : ref.current
  }
  const prevProps = usePrevious({
    admin,
    permissions,
    UserByIdFetch,
    isSearch,
    setProtocolSquadId,
    history,
    setVideoArchiveSquadId,
    unbindVideoFilesOfUser,
    setObservableSquad,
    getBattlesList,
    getAdminDiskSize,
    videofiles,
    user,
    getPermissions,
  })

  useEffect(() => {
    getBattlesList(lastParamsBattle)
    getAdminDiskSize();
    if (admin.disckSize && !adminDiskSize) {
      setAdminDiskSize(getTransformSizeDisk(admin.disckSize));
    }
  }, [])

  useEffect(() => {
    if (prevProps.admin.disckSize !== admin.disckSize) {
      // Преобразуем
      setAdminDiskSize(getTransformSizeDisk(admin.disckSize));
    }
    if (prevProps.permissions.fetch !== permissions.fetch && !permissions.fetch && !permissions.keys.includes('ADMINISTRATE_PANEL_VISIBILITY')) {
      document.history.pushState({}, 'back', '/');
    }
    // Если сменился пользователь (может не нужно)
    if (prevProps.user.id !== user.id) {
      getPermissions();
    }
  }, [admin])

  const getTransformSizeDisk = (disckSize) => {
    return (disckSize > 999)
      ? `${(disckSize / 1000).toFixed(2)} Гб`
      : `${disckSize} Мб`;
  }

  const battleColumns = [
    {
      title: '#',
      dataIndex: 'squadid',
      key: 'squadid',
      width: '5%',
      filter: 'search',
      render: (data, record, index) => {
        if (record.hasstarted) {
          return (
            <span className="clickableSpan" onClick={() => {
              if (record.hasstarted)
                setSelectedGame(data)
                setShowGameResultsModal(true)
            }}>{data}</span>
          )
        };
        return data
      }
    },
    {
      title: 'Competitor 1',
      dataIndex: 'battlelistfilter_player',
      key: 'battlelistfilter_player_1',
      width: '200',
      filter: 'search',
      render: (data, record, index) => {
        const { competitors } = record;
        // :TODO Фильтровать в БД
        const competitor = competitors.filter(pl => pl.confirmedbyowner === 1 && pl.confirmedbyuser === 1)[0] || {};
        return (
          <RenderUserCard
            user={competitor}
          />
        )
      }
    },
    {
      title: 'Competitor 2',
      dataIndex: 'battlelistfilter_player',
      key: 'battlelistfilter_player_2',
      width: '200',
      filter: 'search',
      render: (data, record, index) => {
        const { competitors } = record;
        // :TODO Фильтровать в БД
        const competitor = competitors && competitors.filter(pl => pl.confirmedbyowner === 1 && pl.confirmedbyuser === 1)[1];
        return (
          <RenderUserCard
            user={competitor}
          />
        )
      }
    },
    {
      title: 'Possible competitors',
      dataIndex: 'battlelistfilter_player',
      key: 'battlelistfilter_player',
      width: '200',
      filter: 'search',
      render: (data, record, index) => {
        const { competitors } = record;
        // :TODO Фильровать в БД
        const possibleCompetitors = competitors.filter(pl => !(pl.confirmedbyowner === 1 && pl.confirmedbyuser === 1));

        return (
          <div className="battle_competitor_list">
            {possibleCompetitors.map(pl => {
              const { login, firstname, lastname, userid } = pl;
              return (
                <div className="battle_competitor_item">
                  <a onClick={() => UserByIdFetch({ userid })}>{login}</a><span> {`${firstname}${firstname && lastname ? ' ' : ''}${lastname}`}</span>
                </div>
              );
            })}
          </div>
        )
      }
    },
    {
      title: 'Tournament',
      dataIndex: 'tournament',
      key: 'tournament',
      width: '220',
      filter: 'search',
      render: (data, record, index) => {
        const { tournamentname, tournamenttypeid } = record;
        let value = '';

        if (tournamenttypeid === 1) {
          value = 'Battle';
        } else if (tournamenttypeid === 4) {
          value = 'Pinfall Battle';
        } else {
          value = ((tournamentname || '-').length > 22) ? tournamentname.substring(0, 20) + '...' : tournamentname;
        }

        return (<p className="battle_tournament-name">{value}</p>);
      },
    },
    /*{
      title: 'Cost',
      dataIndex: 'cost',
      key: 'cost',
      filter: 'range',
      render: (data, record, index) => {
        const { currency_symbol, tournamenttypebrief, tournamentname } = record;
        let { cost } = record;
        if (tournamenttypebrief === 'OPEN_CHALLENGE') {
          // OPEN_CHALLENGE
          if (tournamentname === 'Open Challenge') {
            cost = record.tournamentcost
          }
        }
        return <>{cost ? cost : 'Free'}{cost ? currency_symbol : ''}</>
      }
    },*/
    {
      title: 'Cur',
      dataIndex: 'cur_symbol',
      key: 'cur_symbol',
      filter: 'filter',
      filterList: [
        {
          text: 'DIG',
          value: 'DIG',
        },
        {
          text: 'EUR',
          value: 'EUR',
        }
      ]
    },
    {
      title: 'Format',
      dataIndex: 'tournamenttypebrief',
      key: 'tournamenttypebrief',
      render(data, record) {
        switch (record.tournamenttypebrief) {
          case 'OPEN_CHALLENGE':
          case 'DROP_IN':
          case 'PINFALL_BATTLE':
            return 'Pinfall';
          default:
            return 'Wins';
        }
      },
      filter: 'filter',
      filterList: [
        {
          text: 'Pinfall',
          value: 'PINFALL_BATTLE',
        },
        {
          text: 'Wins',
          value: 'INDIVIDUAL',
        }
      ]
    },
    {
      title: 'Games (Wins)',
      dataIndex: 'wincount',
      key: 'wincount',
      render(data, record) {
        return ['DROP_IN', 'PINFALL_BATTLE'].includes(record.tournamenttypebrief)
          ? record.wincount
          : `${record.total_games} (${record.wincount})`
      }
    },
    {
      title: 'Date start',
      dataIndex: 'datestart',
      key: 'datestart',
      filter: 'date',
      dateNow: true, // если не указан параметр "To" - заменить текущей датой
      width: '10%',
      render: (data, record, index) => {
        const {
          datestart,
          closed,
        } = record;
        return (
          <div className="battle_date">
            {!datestart && !closed && 'Now'}
            {!datestart && closed && moment(closed).format('DD MMM YYYY, HH:mm')}
            {datestart && moment(datestart).format('DD MMM YYYY, HH:mm')}
          </div>
        )
      }
    },
    {
      title: 'Result',
      dataIndex: 'result',
      key: 'result',
      render: (data, record, index) => {
        const {
          squadid,
          competitors,
          hasstarted,
          tournamenttypebrief,
          with_protest,
          resolved
        } = record;

        if (!competitors && !Array.isArray(competitors) || !competitors.length) return;

        let resultStr = '';
        if (tournamenttypebrief === 'DROP_IN') {
          resultStr = `Pinfall ${competitors[0].total_score || 0}`;
        } else if (tournamenttypebrief === 'PINFALL_BATTLE') {
          resultStr = `(${(competitors[0] && competitors[0].total_score) || 0}:${(competitors[1] && competitors[1].total_score) || 0})`;
        } else {
          resultStr = competitors.filter(pl => pl.confirmedbyowner && pl.confirmedbyuser).map(pl => pl.gameswin).join(' : ');
        }

        // return `(${(competitors[0] && competitors[0].gameswin) || 0}:${(competitors[1] && competitors[1].gameswin) || 0})`;
        const isProtest = with_protest ? true : false;
        const ProtestIcon = () => {
          if (!isProtest) return null;

          return (
            <a
              href="#0"
              className="battle_result_protest"
              onClick={(e) => { e.preventDefault(); onGotoProtocol(squadid) }}
            >
              <IconsSVG
                name="protest"
                color="red"
                size="16"
              />
            </a>
          )
        }

        return (
          <div className="battle_result">
            <ProtestIcon />
            {hasstarted
              ? resultStr
              : 'Not started'}
          </div>
        )
      }
    },
    {
      title: 'Observe',
      render: (data, record, index) => {
        const {
          hasstarted,
          closed,
          archive,
          squadid,
          is_admin_save,
        } = record;
        let buttonWatch = '';
        let buttonSave = '';

        if (hasstarted && !!closed && archive) {
          buttonWatch = (
            <Button
              className={'watch_button'}
              kind={'aquamarine'}
              icon={'play'}
              onClick={() => {
                setVideoArchiveSquadId(squadid);
                history.push('/videoarchive');
              }}>
            </Button>
          );

          if (permissions.indexOf('ARHIVES_MANAGE') + 1) {
            buttonSave = (is_admin_save)
              ? (
                <SvgButton
                  icon='trash'
                  loading={isDelete}
                  onClick={() => onDeleteSaveVideo(squadid)}
                />
              )
              : (
                <Button
                  className={'watch_button'}
                  kind={'aquamarine'}
                  icon='save'
                  onClick={() => onOpenModalSaveVideo(squadid)}
                />
              );
          }

        } else if (!hasstarted) {
          return 'Game not started'
        } else if (hasstarted && !closed) {
          buttonWatch = (
            <Button
              className={'watch_button'}
              kind={'aquamarine'}
              icon={'play'}
              onClick={() => {
                setObservableSquad(squadid);
                history.push('/watch');
              }}
            >
              {/* {intl.formatMessage({ id: 'play', defaultMessage: 'Play' })} */}
            </Button>
          )
        } else {
          return 'Record not available'
        }
        return <div className="battle_row-btns">{buttonWatch} {buttonSave}</div>;
      }
    },
    {
      title: intl.formatMessage({id: 'Visibility', defaultMessage: 'Visibility'}),
      dataIndex: '',
      render: (data, record) => {
        const recordForVisibilityButton = {...record, id: record.squadid}

          return (
            <VisibilityButton
              key={record.squadid}
              record={recordForVisibilityButton}
            />
          )
        }
    },
  ]

  return (
    <div className='admin_panelWrapper'>
      <div className="admin-panel__admin-size">
        <p className="admin-panel__admin-size-p">Total volume of videos saved by the administrator - {adminDiskSize}</p>
      </div>

      <NewTable
        columns={battleColumns}
        dataSource={battleListData || []}
        pagination={true}
        onRow={() => { }}
        loading={isSearch}
        defaultPageSize={10}
        total={battleListCount}
        onChange={onChangeBattleList}
        defaultParam={{ datestart: [moment().startOf('year').toISOString(), moment(Date.now()).toISOString()] }}
      />

      <ModalSaveVideo
        visible={isVisibleModalSaveVideo}
        squadid={openSquadId}
        onClose={onCloseModalSaveVideo}
        onReboot={onRebootBattleList}
        load={videofiles.isFetching}
        cameras={videofiles}
      />

      <GameResultsModal
        intl={intl}
        visible={showGameResultsModal}
        tournament={selectedGame}
        onClose={() => {
          setShowGameResultsModal(false);
          clearGameInfo();
        }}
        onOk={() => {
          saveAdminBattleListFilters({
            tablefilters,
            battlelistfilter_player,
            battlelistfilter_type,
            battlelistfilter_date_from,
            battlelistfilter_date_to,
            battlelistfilter_withmoney,
          });
          setVideoArchiveSquadId(selectedGame);
          history.push('/videoarchive');
        }}
      />
    </div>
  )
}

export default injectIntl(withRouter(
  connect(
    state => ({
      user: state.users.user,
      admin: state.admin,
      permissions: state.users.permissions ? (state.users.permissions.keys || []) : [],
      permissionsfetch: state.users.permissions ? state.users.permissions.fetch : false,
      isSearch: state.admin.processSearch,
      videofiles: state.videofiles,
    }),
    {
      getBattlesList,
      getPermissions,
      UserByIdFetch,
      setVideoArchiveSquadId,
      setObservableSquad,
      getAdminDiskSize,
      setProtocolSquadId,
      unbindVideoFilesOfUser,
      clearGameInfo,
      saveAdminBattleListFilters,
    },
  )(BattleList),
));
