import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { GetFriends, DeleteFriend } from '../../../libs/reducers/FriendsReducer';
import { UserByIdFetch, CreateBattle, setCurrentRoom, createPrivateRoom, setCreateBattleQuery } from '../../../libs/reducers/actions';
import FriendsFilter from '../filter';
import SvgCountry from '-!svg-react-loader!../../../images/country.svg'; // eslint-disable-line
import SvgGender from '-!svg-react-loader!../../../images/gender.svg'; // eslint-disable-line
import Trash from '-!svg-react-loader!../../../images/trash.svg'; // eslint-disable-line
import './style.css';
import config from '../../../config';
import Flag from '../../common/Flag';
import { Button } from '../../common/Button';
import ModalUser from '../../common/modals/modalUser';
import ModalInviteBattle from '../../common/modals/modalInviteBattle';
import defaultAvatar from '../../../images/defaultAvatar.png';
import OBModal from '../../common/Modal';
import Avatar from '../../common/Avatar';
import makePrivateChat from '../../../libs/makePrivateChat';
import SearchProgress from '../../common/SearchProgress';
import SearchMagnifier from '../../../images/SearchMagnifier.png';
import RatingStars from '../../common/RatingStars';

class FriendList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showInviteBattle: false,
      showUserById: false,
      showDeleteModal: false,
      searchtext: '',
      selectCountry: '',
      selectGender: '',
      friends: props.friends.data || [],
      countryOptions: [],
      genderOptions: [
        { value: 'none', label: 'Not selected...' },
        { value: 'M', label: 'M' },
        { value: 'F', label: 'F' },
      ],
      forDelete: null,
      searchingFriend: true,
    };
  }

  static propTypes = {
    intl: intlShape,
    friends: PropTypes.shape(),
    user: PropTypes.shape(),
    userById: PropTypes.shape(),
    CreateBattle: PropTypes.func,
    UserByIdFetch: PropTypes.func,
    GetFriends: PropTypes.func,
    DeleteFriend: PropTypes.func,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = null;
    if (!_.isEmpty(nextProps.friends.data)) {
      const friendsArr = nextProps.friends.data.filter(friend => friend.confirmed);
      state = { ...state, friends: friendsArr };
    } else {
      state = { ...state, friends: [] };
    }
    if (!_.isEmpty(nextProps.geodata.data)) {
      if (nextProps.friends.data.length) {
        let countryOptions = [];
        nextProps.geodata.data.forEach((country) => {
          nextProps.friends.data.forEach((friend) => {
            if (friend.confirmed) {
              if (friend.user.country === country.iso) {
                countryOptions.push({ value: country.iso, label: country.name });
              }
            }
          });
        });
        countryOptions = _.uniq(countryOptions);
        if (countryOptions.length) {
          countryOptions.unshift({ value: 'none', label: 'Not selected...' });
        }
        state = { ...state, countryOptions };
      }
    }
    return state;
  }

  componentDidMount() {
    this.props.GetFriends();
    this.setState({
      searchingFriend: false,
    });
  }

  linkClick = record => (e) => {
    e.preventDefault();
    console.log('Click', record);
    this.props.UserByIdFetch({
      userid: record.user.id,
    });
  };

  sendMessageClick = (record) => {
    const {
      userid: from, rooms, _setCurrentRoom, _createPrivateRoom,
    } = this.props;
    const { user: { id: to = 0 } = {} } = record || {};
    console.log('sendClick', record);
    makePrivateChat({
      from,
      to,
      rooms,
      setCurrentRoom: _setCurrentRoom,
      createPrivateRoom: _createPrivateRoom,
    });
  };

  onInviteBattle = (userid) => {
    const { history, setCreateBattleQuery } = this.props;
    setCreateBattleQuery({ opponent: userid });
    history.replace('/lobby');
  }

  onInviteBattleSubmit = ({
    betType, bet, winCount, dateType, date, time, comment, opponent,
  }) => {
    const sendParams = { name: 'battle' };
    sendParams.wincount = winCount;

    if (betType === 2) {
      sendParams.cost = bet;
    }
    if (dateType === 2) {
      let datestart;
      if (time !== null) {
        datestart = moment(date.set('h', time.hour()).set('m', time.minute()));
      } else {
        datestart = moment(date);
      }
      sendParams.datestart = moment(datestart).toISOString();
    }
    if (comment !== '') {
      sendParams.rules = comment;
    }
    sendParams.userid = opponent.id;
    this.props.CreateBattle({
      ...sendParams,
    });
    this.setState({ showInviteBattle: false });
  };

  render() {
    // const { friends } = this.props;
    const {
      searchtext,
      selectCountry,
      selectGender,
      friends,
      countryOptions,
      genderOptions,
      showUserById,
      showInviteBattle,
      showDeleteModal,
      forDelete,
      searchingFriend,
    } = this.state;
    const { user, userById, intl } = this.props;

    const columns = [
      {
        title: intl.formatMessage({ id: 'table_fullname', defaultMessage: 'Surname, name' }),
        dataIndex: 'userName',
        render: (data, record) => (
          <div className="username__container">
            <Avatar uri={record.user.photo ? `${config.FETCH.img}/${record.user.photo}` : defaultAvatar} flag={null} tabled isOnline={record.user.active} avgscore={(+record.user.avgscore || 0).toFixed()}/>
            <div>
              {`${record.user.firstname} ${record.user.lastname}`.trim() || `${record.user.login}`}
              <RatingStars rating={+record.user.ratingsavg || 0} width={12} height={12} />
            </div>
          </div>
        ),
      },
      {
        title: intl.formatMessage({ id: 'login2', defaultMessage: 'Login' }),
        dataIndex: 'login',
        render: (data, record) => (
          <Link to="#" onClick={this.linkClick(record)} className="login__link">
            {record.user.login}
          </Link>
        ),
      },
      {
        title: <SvgCountry />,
        dataIndex: 'flag',
        render: (data, record) => (
          <div className="battles__flag">
            <Flag name={record.user.country} />
          </div>
        ),
      },
      {
        title: <SvgGender />,
        dataIndex: 'gender',
        render: (data, record) => {
          if (record.user.gender === 'M') {
            return intl.formatMessage({ id: 'male', defaultMessage: 'M' });
          }
          if (record.user.gender === 'F') {
            return intl.formatMessage({ id: 'female', defaultMessage: 'F' });
          }
          return intl.formatMessage({ id: 'Other', defaultMessage: 'Other' });
        },
      },
      {
        title: '',
        dataIndex: 'actions',
        render: (data, record) => (
          <div className="buttons__container">
            <div className="button__container" style={{ marginRight: '10px' }}>
              <Button
                size="md"
                kind="aquamarine"
                icon="chatmessage"
                bordered="true"
                onClick={() => {
                  this.sendMessageClick(record);
                }}
              />
            </div>
            <div className="button__container" style={{ marginRight: '10px' }}>
              <Button
                size="md"
                kind="aquamarine"
                icon="sword"
                bordered="true"
                onClick={() => {
                  this.onInviteBattle(record.user.id);
                }}
              />
            </div>
            <div className="button__container">
              <Button
                size="md"
                kind="aquamarine"
                icon="tray"
                bordered="true"
                onClick={() => {
                  this.setState({ showDeleteModal: true, forDelete: record.user });
                }}
              />
            </div>
          </div>
        ),
      },
    ];

    let tempfriends = [];
    if (friends.length) {
      tempfriends = [...friends];
      if (searchtext !== '') {
        tempfriends = tempfriends.filter(friend => _.startsWith(`${friend.user.firstname} ${friend.user.lastname}`.toLowerCase(), searchtext.toLowerCase())
          || (
            typeof friend.user.login === 'string' && _.startsWith(friend.user.login.toLowerCase(), searchtext.toLowerCase())
          ));
      }
      if (selectCountry !== '') {
        tempfriends = tempfriends.filter(friend => friend.user.country === selectCountry.value);
      }
      if (selectGender !== '') {
        tempfriends = tempfriends.filter(friend => friend.user.gender === selectGender.value);
      }
    }

    const loading = searchingFriend
      ? <div className="friends__table-loading">
          <SearchProgress loading={searchingFriend} text={`${intl.formatMessage({ id: 'PlayerSearchText', default: 'Search is in progress' })}...`}/>
        </div>
      : '';

    const emptyView = <div className="friends__table-notfind">
        <div className="notfind_container">
          <div className="notfind__wrap-img">
            <img className="notfind__img" src={SearchMagnifier} />
          </div>
          <div className="notfind__text">
            {intl.formatMessage({ id: 'YouCanFindAnyPlayer', defaultMessage: 'You can find any player using the search. To do this, enter his Name / Surname / E-mail / Login' })}
          </div>
        </div>
      </div>;

    return (
      <div className="friends-list">
        <FriendsFilter
          searchtext={searchtext}
          onChangeInput={(e) => {
            this.setState({
              searchtext: e.target.value,
            });
          }}
          selectCountry={selectCountry}
          selectGender={selectGender}
          onChangeCountry={(e) => {
            if (e.value === 'none') {
              this.setState({
                selectCountry: '',
              });
            } else {
              this.setState({
                selectCountry: e,
              });
            }
          }}
          onChangeGender={(e) => {
            if (e.value === 'none') {
              this.setState({
                selectGender: '',
              });
            } else {
              this.setState({
                selectGender: e,
              });
            }
          }}
          countryOptions={countryOptions}
          genderOptions={genderOptions}
        />
        <div className="friends__table-container">
          <Table
            key={this.props.langs}
            className="friends__table"
            rowClassName="friends__table__row"
            columns={columns}
            dataSource={tempfriends}
            pagination={false}
            rowKey={record => record.user.id}
            locale={{ emptyText: <></> }}
          />
          { !searchingFriend && !tempfriends.length
            ? emptyView
            : loading
          }
        </div>
        {
          showDeleteModal && forDelete ?
          <OBModal
            title={intl.formatMessage({ id: 'DeleteFromFriendList', defaultMessage: 'Delete from friend list' })}
            visible={showDeleteModal}
            onSubmit={() => { this.props.DeleteFriend(forDelete.id); this.setState({ showDeleteModal: false }); }}
            onCancel={() => this.setState({ showDeleteModal: false })}
            footer={[
              <div
                key='buttons'
                className='dialog__buttons'>
                <Button
                  kind='aquamarine'
                  bordered='true'
                  onClick={() => this.setState({ showDeleteModal: false })}
                  className='dialog__button-decline'
                  >
                  {intl.formatMessage({ id: 'CancelAsAbortAlias', defaultMessage: 'Cancel' })}
                </Button>
                <Button
                  onClick={() => { this.props.DeleteFriend(forDelete.id); this.setState({ showDeleteModal: false }); }}
                  kind='aquamarine'
                  className='dialog__button-accept'
                  >
                  {intl.formatMessage({ id: 'Accept', defaultMessage: 'Accept' })}
                </Button>
              </div>,
            ]}>
              <p className='friend_delete_caption'>{`${intl.formatMessage({ id: 'FriendDeleteConfirmPt1', defaultMessage: 'Are you sure, you want to delete' })} ${`${forDelete.firstname} ${forDelete.lastname}`.trim() || userById.login} ${intl.formatMessage({ id: 'FriendDeleteConfirmPt2', defaultMessage: 'from friend list?' })}`}</p>
          </OBModal> : null
        }
        <ModalInviteBattle
          visible={showInviteBattle}
          onSubmit={this.onInviteBattleSubmit}
          onClose={() => {
            this.setState({
              showInviteBattle: false,
              showUserById: true,
            });
          }}
          opponent={{
            ...userById.data,
            hourshift: (userById.data.hourshift && userById.data.hourshift !== 0
              ? userById.data.hourshift
              : '0'
            ).toString(),
          }}
        />
        <ModalUser
          title={intl.formatMessage({ id: 'FriendListUserCardModalTitle', defaultMessage: 'User card' })}
          onClose={() => {
            this.setState({
              showUserById: false,
            });
          }}
          onChallengeClick={() => {
            this.setState({
              showUserById: false,
              showInviteBattle: true,
            });
          }}
          isChallengeAllowed={user.id !== userById.data.id}
          user={{
            ...userById.data,
            // hourshift: (userById.data.hourshift && userById.data.hourshift !== 0
            //   ? userById.data.hourshift
            //   : '0'
            // ).toString(),
          }}
          visible={showUserById}
        />
      </div>
    );
  }
}

export default injectIntl(withRouter(
  connect(
    state => ({
      user: state.users.user,
      userById: state.users.userById,
      friends: state.friends.friends,
      geodata: state.common.geodata,
      langs: state.langs.lang,
      rooms: state.chat.rooms,
      userid: state.users.user.id,
    }),
    {
      GetFriends,
      DeleteFriend,
      UserByIdFetch,
      CreateBattle,
      _createPrivateRoom: createPrivateRoom,
      _setCurrentRoom: setCurrentRoom,
      setCreateBattleQuery,
    },
  )(FriendList),
));
