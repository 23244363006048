import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import './style.scss';

class AboutPlatform extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { intl } = this.props;
    return (
      <div className="about_platform">
        <div className="about_platform__header">
          <div className="about_platform__title">
            TenPinCams SIA
          </div>
          <div className="about_platform__regnumber">
            <span className="about_platform__regnumber__key">
              {intl.formatMessage({ id: 'RegNumber', defaultMessage: 'Reg. Nr.' })}{' '}
            </span>
            <span className="about_platform__regnumber__value">
              42103088398
            </span>
          </div>
        </div>
        <div className="about_platform__contacts">
          <div className="about_platform__email">
            <span className="about_platform__email__key">
              {intl.formatMessage({ id: 'email', defaultMessage: 'Email' })}:{' '}
            </span>
            <a href="mailto:info@tenpincam.com" className="about_platform__email__value">
              info@tenpincam.com
            </a>
          </div>
          <div className="about_platform__phone">
            <span className="about_platform__phone__key">
              {intl.formatMessage({ id: 'PhoneContact', defaultMessage: 'Phone' })}:{' '}
            </span>
            <span className="about_platform__phone__value">
              +37129215590‬
            </span>
          </div>
        </div>
        <div className="about_platform__text">
          {intl.formatMessage({ id: 'AboutPlatformText', defaultMessage: 'About Platform Text' }).split("\n").map(p => (
            <p>{p}</p>
          ))}
        </div>
      </div>
    );
  }
}

export default injectIntl(AboutPlatform);
