import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Checkbox from '../../../common/Checkbox';
import { getUserSettings, setUserSettingValue } from '../../../../libs/reducers/actions';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';

import './style.scss';

class NotificationSettings extends Component {

  constructor(props) {
    super(props);

    this.state = {
      fetching: false,
      data: {},
      saving: false
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = { ...prevState }
    if (!prevState.fetching && nextProps.usersettings.fetching) {
      state = { ...state, fetching: true }
    }
    if (prevState.fetching && !nextProps.usersettings.fetching) {
      state = { ...state, fetching: false, data: nextProps.usersettings.data }
    }
    if (!prevState.saving && nextProps.usersettings.saving) {
      state = { ...state, saving: true }
    }
    if (prevState.saving && !nextProps.usersettings.saving) {
      state = { ...state, saving: false, data: nextProps.usersettings.data }
    }
    return state;
  }

  componentDidMount() {
    this.props.getUserSettings();
  }

  onChangeEvent = (event, settingid) => {
    this.props.setUserSettingValue({ settingid, value: +event })
  }

  render() {
    const { intl } = this.props;
    const { data } = this.state;
    const { mail_notifications = [] } = data;
    return (
      <Tabs>
        <TabList>
          <Tab onClick={() => this.setState({ activeTab: 0 })}>
            <div>{intl.formatMessage({ id: 'MailNotificationSettingsTitle', defaultMessage: 'Mail notifications' })}</div>
          </Tab>
        </TabList>
        <TabPanel>
          <div className="mail_notifications">
            <div className="mail_notifications__title">
              {intl.formatMessage({ id: 'MailNotificationsTitle', defaultMessage: 'Mail notification settings' })}
            </div>
            <div className="mail_notifications__description">
              {intl.formatMessage({ id: 'MailNotificationsDescription', defaultMessage: 'Notify me by email about...' })}
            </div>
            <div className="mail_notifications__checkbox_container">
              {
                mail_notifications.map(s => (
                  <Checkbox
                    checked={!!+s.value}
                    onChange={(event) => { this.onChangeEvent(event, s.id) }}
                  >
                    {intl.formatMessage({ id: s.name, defaultMessage: s.name })}
                  </Checkbox>
                ))
              }
            </div>
          </div>
        </TabPanel>
      </Tabs>
    );
  }
}

export default injectIntl(
  connect(
    state => ({
      usersettings: state.usersettings
    }),
    {
      getUserSettings, 
      setUserSettingValue,
    },
  )(NotificationSettings),
);
