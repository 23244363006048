import React from 'react';
// import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import OBModal from './common/Modal';
import messageIcon from '../images/message.svg';

import { store } from '../store';
import { setCommonError } from '../libs/reducers/commonReducer';
import {
  UserLogin, 
  SendPasswordResetRequest, 
  SendPasswordResetCode, 
  ChangeLang, 
  setPassordRecoveryStatus, 
  UserErrorClear,
  sendAuthorizationCode,
} from '../libs/reducers/actions';

import globeImage from '../images/globe.png';
import SocialButtons from './socialButtons';
import Title from './common/Title';
import Radio from './common/Radio';
import { Button, LoginButton } from './common/Button';
import Input from './common/Input';
import logo from '../images/Logo1.png';
import config from '../config';

import './registration/style.css';
import './messageModal.scss';

const supportEmail = 'info@tenpincam.com';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login: '',
      password: '',

      formErrors: {
        login: '',
        password: '',
      },

      passwordReset: false,
      recoveryMethod: 1,

      recoveryMail: '',
      recoveryPhone: '',
      recoveryPhoneStage: false,
      recoveryCode: '',
      newPassword: '',
    };
  }

  static propTypes = {
    intl: intlShape,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = { ...prevState }
    if (nextProps.users.user && nextProps.users.accessed) {
      nextProps.history.push(nextProps.lastLocation || '/lobby');
    }
    if (nextProps.users.error && nextProps.users.error !== prevState.formErrors.login) {
      if (nextProps.users.error !== 'Invalid login or password' && !nextProps.users.withoutErrorModal) {
        store.dispatch(setCommonError(nextProps.users.error));
      }
      state = { ...state, formErrors: { ...prevState.formErrors, login: nextProps.users.error } }
    }
    if (!nextProps.users.error && (prevState.formErrors.login || prevState.formErrors.password)) {
      state = { ...state, formErrors: { login: '', password: '' } }
    }
    if (nextProps.users.reg && !_.isEmpty(nextProps.users.user)) {
      if (!!nextProps.users.user.emailVerified) {
        nextProps.history.push('/postregdata');
      } else { nextProps.history.push('/registration'); }
      state = { ...state, userReg: true }
    }

    if (nextProps.users && !nextProps.users.user.reg && nextProps.users.confirmEmail) {
      const getParams = window.location.search;
      if (getParams) {
        nextProps.history.push(`/getemail${getParams}`);
      }
    }
    return state;
  }

  componentDidMount() {
    let query = null;
    if (window.location.search) {
      query = window.location.search.slice(1).split('&').reduce((acc, cur) => {
        const [key, val] = cur.split('=').map(t => t.trim());
        if (key && val) {
          acc[key] = decodeURIComponent(val);
        }
        return acc;
      }, {});
    }
    let params = null;
    if (query && query.code) {
      try {
        params = JSON.parse(atob(query.code));
      } catch (e) {
        console.error('e: ', e);
      }
    }
    if (params && params.code && params.pathname) {
      params.code = atob(params.code);
      params.pathname = atob(params.pathname);
    } else {
      params = null;
    }
    const { _sendAuthorizationCode } = this.props;
    if (params && typeof _sendAuthorizationCode === 'function') {
      _sendAuthorizationCode({
        authorizationCode: encodeURIComponent(params.code),
        redirectURL: encodeURIComponent(params.pathname),
        app: 'web',
      });
    }
  }

  componentDidUpdate(oldprops) {
    if (
      oldprops.users.passResetStatus !== this.props.users.passResetStatus
      && this.props.users.passResetStatus === 'SUCCESS'
    ) {
      this.setState({ passwordReset: false, login: this.props.users.passResetLogin });
    }
    const { passRecoveryStatus } = this.props;
    const { passwordReset } = this.state;
    if (passwordReset === true) {
      if (oldprops.passRecoveryStatus !== passRecoveryStatus && passRecoveryStatus === 'COMPLETE') {
        this.setState({ passwordReset: false, showModal: true });
      }
    }
  }

  handleUserInput = (value, name) => {
    if (name === 'recoveryMail') {
      this.props._setPasswordRecoveryStatus('NONE');
    }
    const { formErrors } = this.state;
    if (formErrors.login || formErrors.password) {
      this.props._clearError();
    }
    this.setState({ [name]: value });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { login, password } = this.state;
    const errors = this.validate(login, password);
    let isFormValid = true;
    _.forIn(errors, (fieldErrorMsg) => {
      if (!_.isEmpty(fieldErrorMsg)) isFormValid = false;
    });

    this.setState({ formErrors: errors }, () => {
      if (isFormValid) {
        this.props._login({ login: this.state.login, password: this.state.password });
      }
    });
  };

  // eslint-disable-next-line
  validate(login, password) {
    const { intl } = this.props;
    const fieldValidationErrors = {
      login: '',
      password: '',
    };

    if (_.isEmpty(login)) {
      fieldValidationErrors.login = intl.formatMessage({ id: 'requiredField', defaultMessage: 'Field is required' });
    }
    if (_.isEmpty(password)) {
      fieldValidationErrors.password = intl.formatMessage({ id: 'requiredField', defaultMessage: 'Field is required' });
    }

    return fieldValidationErrors;
  }

  renderLanguageSelect = () => {
    const { intl, langs, users } = this.props;
    const lang = langs.lang || users.user.lang || 'EN';
    const { LANGS } = config;
    return (
      <div className="login-language">
        <span className="language-header">
          {intl.formatMessage({ id: 'interfaceLanguage', defaultMessage: 'Language' })}
        </span>
        <div className="language-list">
          {LANGS.map((language, index) => (
            <div key={index} className={`language-en ${lang === language ? 'selected' : ''}`} onClick={() => this.props._changeLang(language)}>
              <img src={globeImage} alt="Globe" className="language-globe-icon" />
              <span className="language-name">{language}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  render() {
    const {
      login,
      password,
      passwordReset,
      formErrors,
      recoveryMethod,
      recoveryMail,
      recoveryPhone,
      recoveryPhoneStage,
      recoveryCode,
      showModal = false,
    } = this.state;
    const {
      _reset, _sendcode, users, intl,
    } = this.props;
    let modalContent = null;
    if (showModal) {
      const text = intl.formatMessage({
        id: 'passwordRecoveryLinkSent',
        defaultMessage: 'Password recovery link was sent to your e-mail. Please, check your inbox.',
      });
      modalContent = (
        <div className="message-sent-content">
          <div className="message-icon">
            <img src={messageIcon} />
          </div>
          <div className="message-text">
            <span>{text}</span>
          </div>
        </div>
      );
    }
    return (
      <div className="login-page">
        <OBModal
          className="message-modal"
          visible={showModal}
          title=" "
          onCancel={() => this.setState({ showModal: false })}
          footer={[null, null]}
        >
          {modalContent}
        </OBModal>
        <div className="login-page__content">
          <div className="login-page__left-column">
            <div className="login-page__left-column__content">
              <img src={logo} alt="" className="leftcolumn__content__logo" />

              {!passwordReset ? (
                <Title>{intl.formatMessage({ id: 'login1', defaultMessage: 'Login' })}</Title>
              ) : (
                <Title>{intl.formatMessage({ id: 'PasswordRecovery', defaultMessage: 'Password recovery' })}</Title>
              )}
            </div>
          </div>
          <div className="login-page__right-column">
            {!passwordReset ? (
              <div className="login-page__right-column__content">
                <span className={'registration-page__right-title'}>
                  {intl.formatMessage({ id: 'login1', defaultMessage: 'Login' })}
                </span>
                {this.renderLanguageSelect()}
                <div
                  className="gray-text"
                  style={{
                    paddingTop: 17,
                    paddingBottom: 22,
                  }}
                >
                  {intl.formatMessage({ id: 'login_with', defaultMessage: 'Login with' })}
                </div>

                <SocialButtons />

                <div className="gray-text" style={{ paddingTop: 20, paddingBottom: 20 }}>
                  {intl.formatMessage({ id: 'or', defaultMessage: 'or' })}
                </div>

                <form onSubmit={this.handleSubmit}>
                  <div className="input-group">
                    <Input
                      name="login"
                      onChangeText={this.handleUserInput}
                      value={login}
                      placeholder={intl.formatMessage({ id: 'login2', defaultMessage: 'Login or e-mail' })}
                      error={formErrors.login}
                    />
                    <Input
                      name="password"
                      type="password"
                      onChangeText={this.handleUserInput}
                      value={password}
                      placeholder={intl.formatMessage({ id: 'password', defaultMessage: 'Password' })}
                      error={formErrors.password}
                    />
                  </div>

                  <Link
                    className="forgotPasswordLink"
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ passwordReset: true, password: '' }, () => {
                        if (this.state.login && this.state.login.includes('@')) {
                          this.setState({ recoveryMail: this.state.login });
                        }
                        this.props._setPasswordRecoveryStatus('NONE');
                      });
                    }}
                  >
                    {intl.formatMessage({ id: 'forgotten_password', defaultMessage: 'Forgotten password' })}?
                  </Link>

                  <Button size="s" type="submit" loading={this.props.userFetch}>
                    {intl.formatMessage({ id: 'sign_in', defaultMessage: 'Sign in' })}
                  </Button>
                  <div style={{ marginTop: 30 }} />
                  <LoginButton size="s" onClick={() => this.props.history.push('/registration')}>
                    <span>
                      {intl.formatMessage({ id: 'new_to', defaultMessage: 'New to ...' })}?
                      <span style={{ fontWeight: 'bold', color: '#f65932' }}>
                        {' '}
                        {intl.formatMessage({
                          id: 'register_for_an_account',
                          defaultMessage: 'Register for a new account',
                        })}
                      </span>
                    </span>
                  </LoginButton>
                </form>
                <div className="app-info">
                  <div>{'SIA "TenPinCams"'}</div>
                  <div>Registration Nr. 42103088398</div>
                  <div>Address: Jūrmala, Konkordijas iela 25, LV-2015, Latvia</div>
                  <div>E-mail: <a href={"mailto:"+supportEmail}>{supportEmail}</a></div>
                  <div>Phone: +37129215590‬</div>
                </div>
              </div>
            ) : (
              <div className="login-page__right-column__content password-recovery">
                <span className={'registration-page__right-title'}>
                  {intl.formatMessage({ id: 'PasswordRecovery', defaultMessage: 'Password recovery' })}
                </span>
                <div className="gray-text">{`${intl.formatMessage({ id: 'RecoverPassword', defaultMessage: 'Recover password' })} ${intl.formatMessage({ id: 'via', defaultMessage: 'via' })}:`}</div>
                <Radio.Group
                  name="radiogroup"
                  defaultValue={1}
                  onChange={(e) => {
                    this.setState({ recoveryMethod: e.target.value });
                  }}
                  value={recoveryMethod}
                >
                  <Radio value={1}>E-mail</Radio>
                  {
                    // <Radio value={0} className='disabledRadio'>Phone</Radio>
                  }
                </Radio.Group>
                {recoveryMethod ? (
                  <div className="recover-method mail">
                    <Input
                      key={this.state.login}
                      name="recoveryMail"
                      onChangeText={this.handleUserInput}
                      value={this.state.recoveryMail}
                      placeholder="E-mail"
                      error={this.props.passRecoveryStatus === 'FAILED' ? intl.formatMessage({ id: 'invalidEmail', defaultMessage: 'invalid email' }) : null}
                    />
                    <div className="gray-text spaced">{intl.formatMessage({
                      id: 'RecoveryInstruction',
                      defaultMessage: 'You will receive a mail on specified in personal data mailbox with instructions',
                    })}</div>
                    <Button
                      size="s"
                      className="recover"
                      onClick={() => {
                        _reset({ email: recoveryMail, device: 'web' });
                        // this.setState({ passwordReset: false, showModal: true });
                      }}
                    >
                      {intl.formatMessage({ id: 'RecoverPassword', defaultMessage: 'Recover password' })}
                    </Button>
                  </div>
                ) : (
                  <div className="recover-method phone">
                    {!recoveryPhoneStage
                      ? [
                          <Input
                            key="input"
                            name="recoveryPhone"
                            onChangeText={this.handleUserInput}
                            value={recoveryPhone}
                            placeholder="Phone number"
                          />,
                          <div className="gray-text spaced" key="text">
                            {intl.formatMessage({
                              id: 'VerifyPhoneModalActionProvokeTooltip',
                              defaultMessage: 'You will receive SMS with your verification code on phone entered above',
                            })}
                          </div>,
                          <Button
                            key="button"
                            size="s"
                            className="recover"
                            onClick={() => this.setState({ recoveryPhoneStage: true }, () => _reset({ phone: recoveryPhone, device: 'web' }))
                            }
                          >
                            {intl.formatMessage({ id: 'SendSMS', defaultMessage: 'Send SMS' })}
                          </Button>,
                      ]
                      : [
                          <div key="phone" className="phone-holder">
                            <label>{intl.formatMessage({ id: 'PhoneNumber', defaultMessage: 'Phone number' })}</label>
                            <span className="phone-value">{recoveryPhone} </span>
                            <a onClick={() => this.setState({ recoveryPhoneStage: false })}>Change</a>
                          </div>,
                          <div key="text" className="gray-text">
                            Input code, which we send you via SMS
                          </div>,
                          <Input
                            key="code"
                            name="recoveryCode"
                            onChangeText={this.handleUserInput}
                            value={recoveryCode}
                            placeholder="Validation code"
                            error={users.passResetStatus === 'FAILED' ? intl.formatMessage({ id: 'VerifyPhoneCodeInputError', defaultMessage: 'invalid code' }) : null}
                          />,
                          <div key="repeat" className="gray-text">
                            {"Didn't receive code? "}
                            <a onClick={() => _reset({ phone: recoveryPhone, device: 'web' })}>Try again</a>
                          </div>,
                          <Input
                            key="newpass"
                            name="password"
                            type="password"
                            onChangeText={this.handleUserInput}
                            value={password}
                            placeholder="New password"
                            error={formErrors.password}
                            className="resetedpassword"
                          />,
                          <Button
                            key="button"
                            size="s"
                            className="recover"
                            onClick={() => _sendcode({ key: recoveryCode, phone: recoveryPhone, pass: password })}
                          >
                            {intl.formatMessage({ id: 'RecoverPassword', defaultMessage: 'Recover password' })}
                          </Button>,
                      ]}
                  </div>
                )}
                <Button
                  size="s"
                  onClick={() => this.setState({ passwordReset: false, password: '' })}
                  className="cancel"
                >
                  <div>
                    {`${intl.formatMessage({
                      id: 'RememberPasswordAsBackAlias',
                      defaultMessage: '',
                    })}. `}<span>{intl.formatMessage({ id: 'login1', defaultMessage: 'Login' })}</span>
                  </div>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(
  connect(
    state => ({
      users: state.users,
      langs: state.langs,
      passRecoveryStatus: state.users.passRecoveryStatus,
      lastLocation: state.redirect.from,
      userFetch: state.users.userFetch,
    }),
    dispatch => ({
      _login: bindActionCreators(UserLogin, dispatch),
      _reset: bindActionCreators(SendPasswordResetRequest, dispatch),
      _setPasswordRecoveryStatus: bindActionCreators(setPassordRecoveryStatus, dispatch),
      _sendcode: bindActionCreators(SendPasswordResetCode, dispatch),
      _changeLang: bindActionCreators(ChangeLang, dispatch),
      _clearError: bindActionCreators(UserErrorClear, dispatch),
      _sendAuthorizationCode: bindActionCreators(sendAuthorizationCode, dispatch),
    }),
  )(Login),
);
