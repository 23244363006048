export const initialGamesCancelledState = {}

export const gamesCancelledReducer = (state = initialGamesCancelledState, action) => {
  switch (action.type) {
    case 'OPPONENT_GAME_REFUSE':
      if (action.tournament && action.tournament.tournamentid && action.tournament.squadid) {
        return {
          ...state,
          [action.tournament.squadid]: action.tournament,
        }
      }
      return state;
    default:
      return state;
  }
}
