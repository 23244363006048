import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Spin } from 'antd';
import CheckBox from '../../common/Checkbox';
import Input from '../../common/Input';

import {
  addTournamentSetting,
  deleteTournamentSetting,
  getHandicapList,
} from '../../../libs/reducers/actions';

import './style.scss';
import {Debounce} from "react-throttle";

const STANDART_HANDICAP = 'standart';
const DYNAMIC_HANDICAP = 'dynamic_handicap';
const ACTIVE_STANDART_HANDICAP = 'ACTIVE_STANDART_HANDICAP';
const ACTIVE_DYNAMIC_HANDICAP = 'ACTIVE_DYNAMIC_HANDICAP';

const TournamentHandicapTab = ({
  intl,
  tournamentInfo,
  addTournamentSetting,
  deleteTournamentSetting,
  getHandicapList,
  handicaps,
}) => {
  const {
    fetching,
    data: handicapList,
  } = handicaps;
  const handicapParam = {
    [STANDART_HANDICAP]: {
      name: "Women's handicap",
      setting_key: ACTIVE_STANDART_HANDICAP,
      active: true,
    },
    [DYNAMIC_HANDICAP]: {
      name: 'Dynamic handicap',
      setting_key: ACTIVE_DYNAMIC_HANDICAP,
      active: true,
    },
  };
  const [valueMultiplier, setValueMultiplier] = useState(null)

  useEffect(() => {
    getHandicapList({ tournamenttypeid: tournamentInfo.tournamenttypeid });
    setValueMultiplier((valueMultiplier) => multiplierSettings.length > 0 ? multiplierSettings[0].value_decimal : null)
  }, []);

  if (fetching) {
    return (
      <div className="loading__container">
        <Spin />
      </div>
    );
  }

  const { settings = [] } = tournamentInfo;
  handicapParam[DYNAMIC_HANDICAP].active = (settings.find(s => s.tournament_setting_brief === ACTIVE_DYNAMIC_HANDICAP) || {}).value_bool;
  handicapParam[STANDART_HANDICAP].active = (settings.find(s => s.tournament_setting_brief === ACTIVE_STANDART_HANDICAP) || {}).value_bool;
  // const acitveHandicaps = {
  //   activeStandartHandicap: (settings.find(s => s.tournament_setting_brief === STANDART_HANDICAP) || {}).value_bool,
  //   activeDynamicHandicap: (settings.find(s => s.tournament_setting_brief === DYNAMIC_HANDICAP) || {}).value_bool,
  // };
  const multiplierSettings = tournamentInfo.settings.filter(setting => setting.tournament_setting_id == 8)

  return (
    <>
      <div className="label">{intl.formatMessage({ id: 'Handicap', defaulMessage: 'Handicap' })}</div>
      {(Array.isArray(handicapList) && handicapList.filter(h => handicapParam[h.handicap_type]).map((handicap) => {
        const activeHandicap = handicapParam[handicap.handicap_type].active === undefined
          ? true
          : !!handicapParam[handicap.handicap_type].active;

        return (
          <div key={`${handicap.id}`} className="property">
            <div className="name">
              {handicapParam[handicap.handicap_type].name || handicap.handicap_type}
            </div>
            <CheckBox
              checked={activeHandicap}
              onChange={() => {addTournamentSetting({
                tournamentid: tournamentInfo.id,
                setting_brief: handicapParam[handicap.handicap_type].setting_key,
                value_bool: !activeHandicap,
              })}}
            >
              <div className="checkbox-value">
                Active
              </div>
            </CheckBox>
          </div>
        );
      })) || ''}
      <div className="multiplier_container">
        <p className="multiplier_text">{intl.formatMessage({ id: 'Multiplier', defaulMessage: 'Multiplier:' })}</p>
        <Debounce time="2000" handler="onEdit">
          <Input
            className="editable__input"
            placeholder={`${valueMultiplier}`}
            // value={`${valueMultiplier && valueMultiplier}`}
            onChangeText={(e) => {
              if (`${valueMultiplier}` !== e)
                setValueMultiplier(+e);
              addTournamentSetting({
                tournamentid: tournamentInfo.id,
                setting_brief: 'ACTIVE_MULTIPLIER_HDC',
                value_decimal: e,
              })
            }}
          />
        </Debounce>
      </div>
    </>
  );
};

export default connect(
  state => ({
    tournamentInfo: state.tournaments.tournamentInfo,
    handicaps: state.common.handicaps,
  }),
  {
    addTournamentSetting,
    deleteTournamentSetting,
    getHandicapList,
  }
)(injectIntl(TournamentHandicapTab));
