import React, { Component } from 'react';
import { Modal } from 'antd';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { store } from '../../store';
import { ChangeLang } from '../../libs/reducers/actions';
import { injectIntl } from 'react-intl';
import ReactWOW from 'react-wow';
import scrollToComponent from 'react-scroll-to-component';
import urlParse from 'url-parse';
import { Button } from '../common/Button';
import { getAgreements } from '../../libs/reducers/commonReducer';
import {
  getBowlingCenters,
  getBowlingCentersCoutry,
} from '../../libs/reducers/bowlingCenters.reducer';

import utils from '../../libs/utils';

import './style.scss';
import './adaptive.scss';
import 'animate.css';
import Benefits from './benefits';
import AlleyList from './alleylist';
import Reviews from './review';

import LandingHeader from './header';
import ApplicationShowcase from './applicationshowcase';
import FeatureShowcase from './featureshowcase';
import ScreenshotSlider from './slider';
import MobileApp from './mobileapp';
import LandingRules from './mainrules';
import LandingFooter from './footer';
import DownloadBlock from './download';
import EquipmentForm from './equipmentForm';
import Agreement from './agreement';
import Tournaments from './tournament';
import FirstTournament from './firstTournament';
import { openPolicy } from '../policy';

Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
  get: function() {
    return !!(
      !isNaN(this.duration) &&
      this.currentTime > 0 &&
      !this.paused &&
      !this.ended &&
      this.readyState > 2
    )
  },
})

class Landing extends Component {
  constructor(props) {
    super(props)

    this.state = {
      bowlingcenters: [],
      bowlingcentersFetching: false,
      countries: [],
      countriesFetching: false,
      showModalTC: false,
      firstTouch: false,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // Определяем нужно ли скачивать файл
    const strGET = window.location.search.replace( '?', '');

    if (this.props.lang === 'RU') {
      this.props.ChangeLang('EN');
    }

    // Обработка GET параметров
    if (strGET) {
      const KEY_DOWNLOAD = 'downloadapp';
      const KEY_BCENTER = 'bc_id';
      const params = strGET.split('&').reduce((p,e) => {
        const a = e.split('=');
        p[ decodeURIComponent(a[0])] = decodeURIComponent(a[1]);

        return p;
      }, {});

      // Если есть GET-параметр downloadapp и он равен 1 - скачиваем приложение
      if (params.hasOwnProperty(KEY_DOWNLOAD) && Number(params[KEY_DOWNLOAD])) {
        const bcId = Number(params[KEY_DOWNLOAD]);
        const bcIdRequest = bcId ? `?bc_id=${bcId}` : '';
        let host = (window.location.href.match(/\w+:\/\/[\w:-]+\//) || [''])[0];

        if (host[host.length - 1] !== '/') {
          host += '/';
        }

        window.location.href=`${host}mobileapp${bcIdRequest}`;
      }

      if(params.hasOwnProperty(KEY_BCENTER) && Number(params[KEY_BCENTER])) {
        const keyBcenter = `bcenter${params[KEY_BCENTER]}`;
        const isShowSite = localStorage.getItem(keyBcenter);

        if (!isShowSite) {
          let host = (window.location.href.match(/\w+:\/\/[\w:-]+\//) || [''])[0];

          if (host[host.length - 1] !== '/') {
            host += '/';
          }
          const url = `${host}api/bowlingcenter/add_follow_link`;
          const body = JSON.stringify({ bcId: params[KEY_BCENTER] });

          fetch(url, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body
          })
          localStorage.setItem(keyBcenter, true)
        }
      }
    }


    store.dispatch(getAgreements('en'))
    document
      .getElementById('viewporttag')
      .setAttribute('content', 'width=auto, initial-scale=1')
    if (utils.isMobile()) {
      this.touschStartListener = document.body.addEventListener(
        'touchstart',
        e => {
          if (!this.state.firstTouch) {
            const videos = Array.from(document.getElementsByTagName('video'))
            const noPlaying = videos.filter(v => !v.playing)
            if (noPlaying.length == 0) {
              this.setState({ firstTouch: true })
            } else {
              noPlaying.forEach(video => {
                if (!video.playing) {
                  video.play()
                }
              })
            }
          }
        }
      )
    } else {
      this.clickListener = document.body.addEventListener('click', e => {
        const videos = Array.from(document.getElementsByTagName('video'))
        const noPlaying = videos.filter(v => !v.playing)
        if (noPlaying.length > 0) {
          noPlaying.forEach(video => {
            if (!video.playing) {
              video.play()
            }
          })
        }
      })
    }

    setTimeout(() => {
      document.body.click()
    }, 1000)

    let countryCount = []

    if (
      this.props.bowlingcenters.bowlingcenters.fetching == false &&
      this.props.bowlingcenters.bowlingcenters.data.length == 0
    ) {
      this.props.getBowlingCenters()
    } else {
      this.setState({
        bowlingcenters: this.props.bowlingcenters.bowlingcenters.data,
      })
      this.props.bowlingcenters.bowlingcenters.data.forEach(bc =>
        countryCount.includes(bc.country_name) ? null : countryCount.push(bc.country_name))
    }
    if (
      this.props.bowlingcenters.country.fetching == false &&
      this.props.bowlingcenters.country.data.length == 0 ||
      countryCount.length !== this.props.bowlingcenters.country.data.length
    ) {
      this.props.getBowlingCentersCoutry()
    } else {
      this.setState({ countries: this.props.bowlingcenters.country.data })
    }

    this.parseUrl();
  }

  componentDidUpdate(_, prevState) {
    const { showModalTC } = this.state;
    if (showModalTC && !prevState.showModalTC) {
      openPolicy({ kind: 'TC' });
      this.setState({ showModalTC: false });
    }
  }

  componentWillUnmount() {
    document
      .getElementById('viewporttag')
      .setAttribute(
        'content',
        `width=900, initial-scale=${Math.min(window.outerWidth / 900, 1)}`
      )
    if (utils.isMobile() || utils.isSafari()) {
      document.body.removeEventListener('touchstart', this.touschStartListener)
    } else {
      document.body.removeEventListener('click', this.clickListener)
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = { ...prevState };
    if (
      !prevState.bowlingcentersFetching &&
      nextProps.bowlingcenters.bowlingcenters.fetching
    ) {
      state = { ...state, bowlingcentersFetching: true }
    }
    if (
      prevState.bowlingcentersFetching &&
      !nextProps.bowlingcenters.bowlingcenters.fetching
    ) {
      state = {
        ...state,
        bowlingcentersFetching: false,
        bowlingcenters: nextProps.bowlingcenters.bowlingcenters.data,
      }
    }
    if (
      !prevState.countriesFetching &&
      nextProps.bowlingcenters.country.fetching
    ) {
      state = { ...state, countriesFetching: true }
    }
    if (
      prevState.countriesFetching &&
      !nextProps.bowlingcenters.country.fetching
    ) {
      state = {
        ...state,
        countriesFetching: false,
        countries: nextProps.bowlingcenters.country.data,
      }
    }
    return state;
  }

  parseUrl() {
    const { hash } = urlParse(window.location.href);
    if (hash.includes('#form_equipment')) {
      setTimeout(() => {
        scrollToComponent(this.equipmentForm, {
          offset: -50,
          align: 'top',
          duration: 3000,
        });
      }, 1000);
    } else if (hash.includes('#list_alleys')) {
      setTimeout(() => {
        scrollToComponent(this.alleyList, {
          offset: 0,
          align: 'top',
          duration: 3000,
        })
      }, 1000);
    } else if (hash.includes('#how_to_play')) {
      setTimeout(() => {
        scrollToComponent(this.applicationShowCase, {
          offset: 0,
          align: 'top',
          duration: 3000,
        })
      }, 1000);
    } else if (hash.includes('#benefits')) {
      setTimeout(() => {
        scrollToComponent(this.benefits, {
          offset: -50,
          align: 'top',
          duration: 3000,
        });
      }, 1000)
    } else if (hash.includes('#tournaments')) {
      setTimeout(() => {
        scrollToComponent(this.tournaments, {
          offset: -50,
          align: 'top',
          duration: 3000,
        });
      }, 1000)
    }
  }

  render() {
    const { intl, history, lang, ChangeLang } = this.props;
    const { bowlingcenters, countries } = this.state;

    return (
      <div className="landing">
        <div className="landing__full_container">
          <LandingHeader
            intl={intl}
            history={history}
            lang={lang}
            ChangeLang={ChangeLang}
            scrollToApplicationsShowCase={() => {
              scrollToComponent(this.applicationShowCase, {
                offset: 0,
                align: 'top',
                duration: 1000,
              })
            }}
            scrollToBenefits={() => {
              scrollToComponent(this.benefits, {
                offset: 0,
                align: 'top',
                duration: 3000,
              })
            }}
            scrollToApplicationShowCase={() => {
              scrollToComponent(this.applicationShowCase, {
                offset: 0,
                align: 'top',
                duration: 3000,
              })
            }}
            scrollToDownloadBlock={() => {
              scrollToComponent(this.downloadBlock, {
                offset: 0,
                align: 'top',
                duration: 3000,
              })
            }}
            scrollToEquipmentForm={() => {
              scrollToComponent(this.equipmentForm, {
                offset: 0,
                align: 'top',
                duration: 3000,
              })
            }}
            scrollToAlleyList={() => {
              scrollToComponent(this.alleyList, {
                offset: 0,
                align: 'top',
                duration: 3000,
              })
            }}
          />
          <FirstTournament
            ref={section => {
              this.firstTournament = section
            }}
          />
          {utils.isMobile() ? (
            <ApplicationShowcase
              ref={section => {
                this.applicationShowCase = section
              }}
              intl={intl}
              history={history}
              scrollToEquipmentForm={() => {
                scrollToComponent(this.equipmentForm, {
                  offset: 0,
                  align: 'top',
                  duration: 3000,
                })
              }}
              scrollToAlleyList={() => {
                scrollToComponent(this.alleyList, {
                  offset: 0,
                  align: 'top',
                  duration: 3000,
                })
              }}
              scrollToApplicationShowCase={() => {
                scrollToComponent(this.applicationShowCase, {
                  offset: 0,
                  align: 'top',
                  duration: 3000,
                })
              }}
              isMobile={true}
            />
          ) : (
            <ReactWOW animation="customFadeInDown" duration="1.5s">
              <div>
                <ApplicationShowcase
                  ref={section => {
                    this.applicationShowCase = section
                  }}
                  intl={intl}
                  history={history}
                  scrollToEquipmentForm={() => {
                    scrollToComponent(this.equipmentForm, {
                      offset: 0,
                      align: 'top',
                      duration: 3000,
                    })
                  }}
                  scrollToAlleyList={() => {
                    scrollToComponent(this.alleyList, {
                      offset: 0,
                      align: 'top',
                      duration: 3000,
                    })
                  }}
                  scrollToApplicationShowCase={() => {
                    scrollToComponent(this.applicationShowCase, {
                      offset: 0,
                      align: 'top',
                      duration: 3000,
                    })
                  }}
                  scrollToDownloadBlock={() => {
                    scrollToComponent(this.downloadBlock, {
                      offset: 0,
                      align: 'top',
                      duration: 3000,
                    })
                  }}
                />
              </div>
            </ReactWOW>
          )}

          {utils.isMobile() ? (
            <FeatureShowcase intl={intl} history={history} />
          ) : (
            <ReactWOW animation="customFadeInDown" duration="1.5s">
              <div>
                <FeatureShowcase intl={intl} history={history} />
              </div>
            </ReactWOW>
          )}

          {utils.isMobile() ? (
            <ScreenshotSlider intl={intl} history={history} />
          ) : (
            <ReactWOW animation="customFadeInDown" duration="1.5s">
              <div>
                <ScreenshotSlider intl={intl} history={history} />
              </div>
            </ReactWOW>
          )}

          {utils.isMobile() ? (
            <MobileApp intl={intl} history={history} />
          ) : (
            // <></>
            <ReactWOW animation="customFadeInDown" duration="1.5s">
              <div>
                <MobileApp intl={intl} history={history} />
              </div>
            </ReactWOW>
          )}

          {utils.isMobile() ? (
            <LandingRules
              showModal={() => {
                this.setState({ showModalTC: true })
              }}
              intl={intl}
              history={history}
            />
          ) : (
            <ReactWOW animation="customFadeInDown" duration="1.5s">
              <div>
                <LandingRules
                  showModal={() => {
                    this.setState({ showModalTC: true })
                  }}
                  intl={intl}
                  history={history}
                />
              </div>
            </ReactWOW>
          )}

          <div className="another-bg tournament">
            {
              utils.isMobile() ? (
                <Tournaments
                  ref={section => {
                    this.tournaments = section
                  }}
                  intl={intl}
                  history={history}
                />
              ) : (
                <ReactWOW animation="customFadeInDown" duration="1.5s">
                  <div>
                    <Tournaments
                      ref={section => {
                        this.tournaments = section
                      }}
                      intl={intl}
                      history={history}
                    />
                  </div>
                </ReactWOW>
              )
            }
            {utils.isMobile() ? (
              <Benefits
                ref={section => {
                  this.benefits = section
                }}
                intl={intl}
                history={history}
                scrollToEquipmentForm={() => {
                  scrollToComponent(this.equipmentForm, {
                    offset: 0,
                    align: 'top',
                    duration: 3000,
                  })
                }}
              />
            ) : (
              <ReactWOW animation="customFadeInDown" duration="1.5s">
                <div>
                  <Benefits
                    ref={section => {
                      this.benefits = section
                    }}
                    intl={intl}
                    history={history}
                    scrollToEquipmentForm={() => {
                      scrollToComponent(this.equipmentForm, {
                        offset: 0,
                        align: 'top',
                        duration: 3000,
                      })
                    }}
                  />
                </div>
              </ReactWOW>
            )}

            {utils.isMobile() ? (
              <Reviews
                ref={section => {
                  this.reviews = section
                }}
                intl={intl}
                history={history}
              />
            ) : (
              <ReactWOW animation="customFadeInDown" duration="1.5s">
                <div>
                  <Reviews
                    ref={section => {
                      this.reviews = section
                    }}
                    intl={intl}
                    history={history}
                  />
                </div>
              </ReactWOW>
            )}

            {utils.isMobile() ? (
              <AlleyList
                ref={section => {
                  this.alleyList = section
                }}
                intl={intl}
                history={history}
                bowlingcenters={bowlingcenters}
                countries={countries}
              />
            ) : (
              <AlleyList
                ref={section => {
                  this.alleyList = section
                }}
                intl={intl}
                history={history}
                bowlingcenters={bowlingcenters}
                countries={countries}
              />
            )}

            {utils.isMobile() ? (
              <EquipmentForm
                ref={section => {
                  this.equipmentForm = section
                }}
                intl={intl}
                history={history}
              />
            ) : (
              <ReactWOW animation="customFadeInDown" duration="1.5s">
                <div>
                  <EquipmentForm
                    ref={section => {
                      this.equipmentForm = section
                    }}
                    intl={intl}
                    history={history}
                  />
                </div>
              </ReactWOW>
            )}
          </div>
          {utils.isMobile() ? (
            <DownloadBlock
              intl={intl}
              history={history}
              ref={section => {
                this.downloadBlock = section
              }}
            />
          ) : (
            <ReactWOW animation="customFadeInDown" duration="1.5s">
              <div>
                <DownloadBlock
                  intl={intl}
                  history={history}
                  ref={section => {
                    this.downloadBlock = section
                  }}
                />
              </div>
            </ReactWOW>
          )}
          {utils.isMobile() ? (
            <LandingFooter intl={intl} history={history} />
          ) : (
            <ReactWOW animation="customFadeInDown" duration="1.5s">
              <div>
                <LandingFooter intl={intl} history={history} />
              </div>
            </ReactWOW>
          )}
        </div>
        {/* <Modal
          className="landing__agreement"
          title={intl.formatMessage({
            id: 'TCagreeD',
            defaultMessage: 'Terms and condition',
          })}
          visible={this.state.showModalTC}
          closable
          width="80%"
          onCancel={() => this.setState({ showModalTC: false })}
          footer={[]}
        >
          <Agreement />
        </Modal> */}
      </div>
    )
  }
}

export default withRouter(
  injectIntl(
    connect(
      state => ({
        bowlingcenters: state.bowlingcenters,
        agreements: state.common.agreements,
        lang: state.langs.lang,
      }),
      {
        getBowlingCenters,
        getBowlingCentersCoutry,
        ChangeLang
      }
    )(Landing)
  )
)
