import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Button } from '../../../Button';
import OBModal from '../../../Modal';
import Checkbox from '../../../Checkbox';
import Utils from '../../../../../libs/utils';
import Opponent from '../../../Opponent';
import defaultAvatar from './images/default.png';
import config from '../../../../../config';
// import Flag from '../../../../Opponent/Flag';
import moment from 'moment';
import FeedbackModal from '../feedbackModal';

import '../style.scss';
import { UserByIdFetch, UserSearchFetch } from '../../../../../libs/reducers/actions';

class ModalConfirmBattleTutorial extends Component {

  constructor(props) {

    super(props);

    this.state = {
      suppress: false,
      isopen: false,
    }
  }

  componentDidMount() {
    if (this.props.params) {
      this.props.UserByIdFetch({ userid: this.props.params.competitorId, noModal: true });
    }
  }

  componentDidUpdate(prevProps) {
    const { params, UserByIdFetch } = this.props;
    const { params: prevParams } = prevProps;

    if (params && prevParams && params.competitorId !== prevParams.competitorId) {
      UserByIdFetch({ userid: params.competitorId, noModal: true });
    }
  }

  onRatingChange = (ratingsopen) => {
    this.setState({ isopen: ratingsopen });
  }

  render() {
    const { intl, lang, visible, opponent, params, loading } = this.props;
    const { id, createdby, datestart, cost, currency, wincount } = params || {};
    const { id: userid, firstname, lastname, login, photo, ratings, ratingsavg, country, avgscore } = opponent || {};
    const { suppress } = this.state;
    const translate = {
      title: intl.formatMessage({ id: 'Battle', defaultMessage: 'Battle' }),
      accept: intl.formatMessage({ id: 'Accept', defaultMessage: 'Accept' }),
      decline: intl.formatMessage({ id: 'Decline', defaultMessage: 'Decline' }),
      parameters: intl.formatMessage({ id: 'BattleParameters', defaultMessage: 'Parameters of Battle' }),
      battledate: intl.formatMessage({ id: 'date_of_battle', defaultMessage: 'Date of battle' }),
      fee: intl.formatMessage({ id: 'fee', defaultMessage: 'Fee' }),
      wins: intl.formatMessage({ id: 'table_wins_short', defaultMessage: 'Wins' }),
      description: intl.formatMessage({
        id: 'ConfirmBattleModalDescription',
        defaultMessage: `Player {lastname} {firstname} responded to your Battle.`
      }, { firstname, lastname }),
      ignore: intl.formatMessage({ id: 'SuppressFurtherBattle', defaultMessage: 'Suppress further responses of this Battle' }),
      now: intl.formatMessage({ id: 'now', defaultMessage: 'Now' }),
      free: intl.formatMessage({ id: 'without_bet', defaultMessage: 'Free' }),
      pinfall: 'Pinfall',
    };
    const poly = "M14 1.12978L17.6661 8.55816L17.7825 8.79389L18.0426 8.83169L26.2403 10.0229L20.3084 15.8051L20.1202 15.9886L20.1646 16.2476L21.5649 24.4122L14.2327 20.5574L14 20.4351L13.7673 20.5574L6.43507 24.4122L7.83541 16.2476L7.87984 15.9886L7.69161 15.8051L1.75969 10.0229L9.9574 8.83169L10.2175 8.79389L10.3339 8.55816L14 1.12978Z";
    return (
      <OBModal
        title={translate.title}
        visible={visible}
        width={this.state.isopen ? 790 : 395}
        onOk={() => { }}
        onCancel={() => { }}
        className={`modal-user_info ${this.state.isopen ? ' open' : ''}`}
        closable={false}
        footer={[
          <div className="confirm_battle_modal__buttons">
            <Button
              kind="aquamarine"
              bordered="true"
              loading={loading}
              onClick={() => {
                this.props.onDecline(id, createdby, userid);
              }}
            >
              {translate.decline}
            </Button>
            <Button
              kind="aquamarine"
              loading={loading}
              onClick={() => {
                this.props.onAccept(id, createdby, userid);
              }}
            >
              {translate.accept}
            </Button>
          </div>
        ]}
      >
        <div className={`modal-opponent-info ${this.state.isopen ? ' open' : ''}`}>
        <div className="confirm_battle_modal__opponent">
          {!!opponent.id &&
            <Opponent
              userid={userid}
              fullname={`${lastname} ${firstname}`}
              nickname={login}
              photo={photo}
              rating={+ratingsavg}
              flag={country}
              clickableLogin={false}
              avgscore={avgscore}
              ratings={ratings}
              onRatingChange={this.onRatingChange}
            />
          }
        </div>
        <div className="confirm_battle_modal__parameters">
          <div className="confirm_battle_modal__subtitle">
            {translate.parameters}{':'}
          </div>
          <div className="confirm_battle_modal__table">
            <div className="confirm_battle_modal__parameter">
              <div className="confirm_battle_modal__parameter__key">
                {translate.battledate}
              </div>
              <div className="confirm_battle_modal__parameter__value">
                {datestart ? Utils.formatDateTime(+new Date(datestart), lang, 'DD MMM - HH:mm'): translate.now}
              </div>
            </div>
            {/*<div className="confirm_battle_modal__parameter">
              <div className="confirm_battle_modal__parameter__key">
                {translate.fee}
              </div>
              <div className="confirm_battle_modal__parameter__value">
                {cost ? `${cost}${currency}` : translate.free}
              </div>
            </div>*/}
            <div className="confirm_battle_modal__parameter">
              <div className="confirm_battle_modal__parameter__key">
                {translate.wins}
              </div>
              <div className="confirm_battle_modal__parameter__value">
                {wincount}
              </div>
            </div>
          </div>
        </div>
        <div className="confirm_battle_modal__description">
          {translate.description}
        </div>
        {
          datestart ?
          <div className="confirm_battle_modal__checkbox">
            <Checkbox checked={suppress}
              onChange={(checked) => {
                this.setState({ suppress: checked });
              }}
            >
              {translate.ignore}
            </Checkbox>
          </div> : null
        }
        </div>
        {this.state.isopen ?<FeedbackModal/> : (null)}
      </OBModal>
    );
  }
}

export default injectIntl(
  connect(
    state => ({
      lang: state.langs.lang,
      mybattles: state.battles.mybattles,
      opponent: state.users.userById.data,
      ratings: ((state.users.userById||{}).data||{}).ratings,
      // ratingsopen: state.isopen,
    }),
    {
      UserByIdFetch,
    },
  )(ModalConfirmBattleTutorial),
);
