const {
  SET_VIDEO_ARCHIVES, FETCH_VIDEO_ARCHIVES, SET_VIDEO_ARCHIVES_ALL, FETCH_VIDEO_ARCHIVES_ALL,
} = require('./actiontypes').default;

const initialState = {
  archives: {},
  error: '',
  isFetching: false,
};

const createMap = ({ stream, archives }) => ({ [stream]: archives });

const videoArchivesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VIDEO_ARCHIVES:
    case FETCH_VIDEO_ARCHIVES_ALL:
      return { ...state, error: '', isFetching: true };
    case SET_VIDEO_ARCHIVES:
      if (action.error) {
        return { ...state, error: action.error, isFetching: false };
      }
      return { archives: { ...state.archives, ...createMap(action.payload) }, isFetching: false };
    case SET_VIDEO_ARCHIVES_ALL:
      if (action.error) {
        return { ...state, error: action.error, isFetching: false };
      }
      return { archives: { ...action.payload }, isFetching: false, error: '' };
    default:
      return state;
  }
};

export default videoArchivesReducer;
