/* eslint-disable camelcase */
import React from 'react';
import defaultAvatar from '../../../images/defaultAvatar.png';
import Flag from '../Flag';
import config from '../../../config';

import './style.scss';

export default function UserCardTable({ user, withLogin = true }) {
  const {
    active,
    photo,
    login,
    firstname,
    lastname,
    userid,
    country_live,
    avgscore,
  } = user || {};

  function getName() {
    let name = '';

    if (firstname && lastname) {
      name = `${firstname} ${lastname}`;
    }
    if (!firstname && !lastname) {
      name = 'no user';
    }
    if (!firstname && lastname) {
      name = lastname;
    }
    if (!lastname && firstname) {
      name = firstname;
    }
    return name;
  }

  return user ? (
    <div className="table__competitor">
      <div className="photowrap">
        <div className={`photo${active ? ' active' : ''}`}>
          <img
            src={photo ? `${config.FETCH.img}/${photo}` : defaultAvatar}
            alt=""
          />
        </div>
        {avgscore >= 0 ? (
          <div className="avgscore">{(+avgscore || 0).toFixed(0)}</div>
        ) : null}
        <Flag name={country_live} />
      </div>
      <div className="user_credentials">
        <div className="user_name">{getName()}</div>
        {withLogin && (
          <div className="user_login">
            <a onClick={() => this.props.UserByIdFetch({ userid })}>
              {login || ''}
            </a>
          </div>
        )}
      </div>
    </div>
  ) : null;
}
