import setCookieParser from 'set-cookie-parser';
import cookieManager from '../react-native-cookies'; // модуль ../react-native-cookies.js должен быть создан отдельно на web/src и отдельно на mobile/src
import config from '../config';

const regex = /^(?:(https?)\:)?\/\/(?:([^\:\/\?\#]+)?\:([^\:\/\?\#]+)?@)?(([^\/\?\#\:]+)(?:\:(\d+)?)?)([^\?\#]+)?([^\#]+)?(.*)?$/;
const { token } = config.cookie || {};

const parseurl_cache = new Map();

function parseURL(url) {
  let result = parseurl_cache.get(url);
  if (!result) {
    if (parseurl_cache.size > parseURL.maxSize) {
      const keys = [...parseurl_cache.keys()];
      for (let i = 0; i < 20 && i < keys.length; ++i) {
        parseurl_cache.delete(keys[i]);
      }
    }
    result = _parseURL(url);
    parseurl_cache.set(url, result);
  }
  return result;
}

parseURL.maxSize = 100;

const _parseURL = (url) => {
  if (!url || typeof url !== 'string') {
    return {};
  }
  const match = url.match(regex);
  if (!match) {
    return {};
  }
  return {
    href: match[0],
    protocol: match[1],
    username: match[2],
    password: match[3],
    host: match[4],
    hostname: match[5],
    port: match[6],
    pathname: match[7],
    search: match[8],
    hash: match[9],
    server: `${match[1] || 'http'}://${match[5]}`,
  };
};

const parseSearch = (search) => {
  if (!search || search === '?' || typeof search !== 'string') {
    return {}
  }
  if (search[0] === '?') {
    search = search.slice(1);
  }
  return search.split('&').reduce((acc, cur) => {
    const [key, val] = cur.split('=').map(elm => elm.trim());
    if (key && val) {
      const _key = decodeURIComponent(key);
      const _val = decodeURIComponent(val);
      if (Array.isArray(acc[_key])) {
        acc[_key].push(_val);
      } else if (acc[_key]) {
        acc[_key] = [acc[_key], _val];
      } else {
        acc[_key] = _val;
      }
    }
    return acc;
  }, {});
}

const toSearch = (query) => {
  if (typeof query !== 'object') {
    return '';
  }
  return '?' + Object.keys(query).reduce((acc, key) => {
    const _key = encodeURIComponent(key);
    let _val = query[key];
    if (Array.isArray(_val)) {
      acc.push(..._val.map(v => `${_key}=${encodeURIComponent(v)}`));
    } else {
      acc.push(`${_key}=${encodeURIComponent(_val)}`);
    }
    return acc;
  }, []).join('&');
}

const toURL = ({ protocol, host, pathname, search, hash }) => {
  return `${protocol || 'https'}://${host}${pathname}${search || ''}${hash || ''}`;
};

function MyCookie(cookie = {}, isMilliseconds = true) {
  const { name, value, domain, path, expires, maxAge, secure, httpOnly, sameSite } = cookie;
  this.name = name;
  let _value;
  Object.defineProperty(this, 'value', {
    get: function () {
      return (+this.expires >= Date.now()) ? _value : '';
    },
    set: function (val) { _value = val; },
    enumerable: true,
  });
  this.value = value;
  this.domain = domain;
  this.path = path;
  this.maxAge = isMilliseconds ? maxAge : (maxAge * 1000);
  if (maxAge && !expires) {
    this.expires = new Date(Date.now() + this.maxAge);
  } else if (expires) {
    this.expires = expires instanceof Date ? expires : new Date(expires);
  } else {
    this.expires = new Date(Date.now() + 24 * 3600 * 1000);
  }
  this.secure = secure;
  this.httpOnly = httpOnly;
  this.sameSite = sameSite;
}

MyCookie.prototype.updateValue = function (value) {
  this.value = value;
};

MyCookie.prototype.removeValue = function () {
  this.updateValue('');
};

MyCookie.prototype.isSameDomain = function (url) {
  if (this.domain === url) {
    return true;
  }
  if (!url) {
    return false;
  }
  const { hostname } = parseURL(url);
  return !!hostname && (hostname === this.domain || `.${hostname}` === this.domain);
};

MyCookie.prototype.toString = function (url) {
  if (this.isSameDomain(url)) {
    if (+this.expires < Date.now()) {
      this.removeValue();
    }
    return `${this.name}=${this.value}`;
  }
  return null;
};

MyCookie.prototype.toNative = function (url) {
  const { name, value, domain, path } = this;
  const origin = url ? parseURL(url).server : `https://${domain}`;
  return {
    name,
    value,
    domain,
    origin,
    path: path || '/',
    version: '1',
    expiration: this.expires.toISOString(),
  };
};

MyCookie.prototype.toRedux = function () {
  const { name, value, domain, path, expires, maxAge, secure, httpOnly, sameSite } = this;
  return { name, value, domain, path, expires: expires.toUTCString(), maxAge, secure, httpOnly, sameSite };
};

MyCookie.prototype.toResponseString = function (url) {
  if (!this.isSameDomain(url)) {
    return null;
  }
  return `${this.name}=${this.value}; path=${this.path || '/'}; domain=${this.domain}; expires=${this.expires.toUTCString()}${this.secure ? '; secure' : ''}${this.httpOnly ? '; httpOnly' : ''}`;
};

function MyCookies(cookies = {}) {
  if (typeof cookies === 'string') {
    const splitCookieHeaders = setCookieParser.splitCookiesString(cookies);
    cookies = setCookieParser.parse(splitCookieHeaders, { map: true });
  }
  this.cookies = {};
  for (const name of Object.keys(cookies)) {
    this.cookies[name] = new MyCookie(cookies[name]);
  }
  this.token = token;
  this.cleanAll = this.cleanAll.bind(this);
}

MyCookies.prototype.setFromResponse = function (url, setCookieHeaders) {
  if (Array.isArray(setCookieHeaders) && setCookieHeaders.length === 1) {
    setCookieHeaders = setCookieHeaders[0];
  }
  const splitCookieHeaders = setCookieParser.splitCookiesString(setCookieHeaders);
  const cookies = setCookieParser.parse(splitCookieHeaders, { map: true });
  for (const name of Object.keys(cookies)) {
    cookies[name].domain = cookies[name].domain || parseURL(url).hostname;
    this.cookies[name] = new MyCookie(cookies[name]);
  }
};

MyCookies.prototype.toString = function (server_url) {
  return Object.keys(this.cookies).map(name => {
    if (this.cookies[name] instanceof MyCookie) {
      return this.cookies[name].toString(server_url);
    }
    return null;
  }).filter(cookie => !!cookie).join('; ');
};

MyCookies.prototype.setFromNativeCookies = async function (server_url) {
  const { server } = parseURL(server_url);
  const cookies = await cookieManager.get(server);
  console.log(`[${new Date().toISOString().slice(11, -1)}] native cookies: `, cookies);
  this.cookies = this.cookies || {};
  for (const name of Object.keys(cookies)) {
    if (this.cookies[name] && this.cookies[name].value && !cookies[name]) {
      this.cookies[name].value = '';
    } else if (!this.cookies[name] || (!this.cookies[name].value && cookies[name])) {
      this.cookies[name] = new MyCookie({ name, value: cookies[name], domain: parseURL(server_url).hostname });
    }
  }
};

MyCookies.prototype.format = function () {
  return Object.keys(this.cookies).reduce((acc, name) => {
    acc[name] = this.cookies[name] ? this.cookies[name].value : null;
    return acc;
  }, {});
};

MyCookies.prototype.cleanAll = async function () {
  for (const name of Object.keys(this.cookies)) {
    this.cookies[name].value = '';
    this.cookies[name].expires = new Date(Date.now() - 365 * 24 * 3600 * 1000);
  }
  await cookieManager.clearAll();
};

MyCookies.prototype.updateNativeCookies = async function (server_url) {
  const { server } = parseURL(server_url);
  const rn_cookies = await cookieManager.get(server);
  const promises = [];
  for (const name of Object.keys(this.cookies)) {
    const cookie = this.cookies[name];
    if (rn_cookies[name] !== cookie.value && (rn_cookies[name] || cookie.value)) {
      const promise = cookieManager.set(cookie.toNative(server));
      promises.push(promise);
    }
  }
  await Promise.all(promises);
};

MyCookies.prototype.isEmpty = function () {
  return !Object.keys(this.cookies).length;
};

const myCookies = new MyCookies();
myCookies.token = config.FETCH.xCookieToken;

export {
  MyCookie,
  MyCookies,
  myCookies,
  parseURL,
  parseSearch,
  toSearch,
  toURL,
};
