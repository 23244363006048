import { call, put } from 'redux-saga/effects';
import config from '../../config';
import bugsnagClient from '../../bugsnagClient';
import { get, put as putMethod } from '../Networking';

const {
  SET_USER_SETTINGS,
  SET_HANDICAP_LIST,
} = require('../reducers/actiontypes').default;

export function* _getUserSettings() {
  try {
    const url = `${config.FETCH.url}/user/settings`;
    const result = yield call(get, { url });

    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_USER_SETTINGS, payload: result.data.data });
    } else {
      yield put({ type: SET_USER_SETTINGS, error: result.data.error || result.status });
    }
  } catch (err) {
    bugsnagClient.notify(err, { context: 'getUserSettings' });
    yield put({ type: SET_USER_SETTINGS, error: err });
  }
}

export function* _setUserSettingValue(params) {
  try {
    const url = `${config.FETCH.url}/user/settings`;

    const result = yield call(putMethod, { url, data: params.payload });
    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_USER_SETTINGS, payload: result.data.data });
    } else {
      yield put({ type: SET_USER_SETTINGS, error: result.data.error || result.status });
    }
  } catch (err) {
    bugsnagClient.notify(err, { context: 'setUserSettingValue' });
    yield put({ type: SET_USER_SETTINGS, error: err });
  }
}

export function* _setUserSettingChildValue(params) {
  try {
    const url = `${config.FETCH.url}/user/settings/child`;
    const result = yield call(putMethod, { url, data: params.payload });

    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_USER_SETTINGS, payload: result.data.data });
    } else {
      yield put({ type: SET_USER_SETTINGS, error: result.data.error || result.status });
    }
  } catch (err) {
    bugsnagClient.notify(err, { context: 'setUserSettingValue' });
    yield put({ type: SET_USER_SETTINGS, error: err });
  }
}


