import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import {
  TournamentFetch,
  UserByIdFetch,
  CreateBattle,
  setCreateBattleQuery,
  getUserSettings,
  setUserSettingValue,
  TakePartBattle,
  BattlesResponseToUserFetch,
  BattlesResponseToOwnerFetch,
  setOpponentGameRefuse,
  setObservableTournament,
  setObservableSquad,
  requestSquadList,
} from '../../libs/reducers/actions';
import { newBattlesreset as newBattlesReset } from '../../libs/reducers/SocketEventReducer';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import { Button } from '../common/Button';
import OBModal from '../common/Modal';
import Flag from '../common/Flag';
import defaultAvatar from '../../images/defaultAvatar.png';
import noDataFound from '../../images/no_data_found.png';
import config from '../../config';
import SvgReload from '-!svg-react-loader!../../images/reload.svg'; // eslint-disable-line
import SvgFilter from '-!svg-react-loader!../../images/filter.svg'; // eslint-disable-line
import SvgSwords from '-!svg-react-loader!../../images/sword.svg'; // eslint-disable-line
import SvgNotification from '-!svg-react-loader!../../images/Notification.svg'; // eslint-disable-line
import SvgChat from '-!svg-react-loader!../../images/chat.svg'; // eslint-disable-line
import BadgeIcon from '../common/BadgeIcon/BadgeIcon';
import { FilterInput } from '../common/Input';
import Select from '../common/Select';
import CreateBattleModal from '../common/modals/modalCreateBattle';
import Notifications from '../notifications';
import Chat from '../tournament/tournamentsChat/tournamentsChat';
import Battles from '../tournament/myBattles';
import { CreateBattleTutorialModal, JoinBattleTutorialModal, ConfirmBattleModal } from '../common/modals/lobby';
import './style.scss';
import '../tournament/battles/style.css'
import SvgEye from '-!svg-react-loader!../../images/eye.svg'; // eslint-disable-line
import AllBattlesTab from './AllBattlesTab';
import Challanges from '../tournament/challenges/challangesTable';
import TournamentsTabs from '../tournament/tournamentsTabs';
import NoDataFound from './noDataFound';


function getUA() {
  return window && window.navigator ? window.navigator.userAgent : '';
}
function isSafari() {
  return getUA().indexOf('Safari') !== -1 && !isChrome() && !isAndroid();
}
function isChrome() {
  return getUA().indexOf('Chrome') !== -1;
}
function isAndroid() {
  return getUA().indexOf('Android') !== -1;
}

class Lobby extends Component {

  constructor(props) {
    super(props);

    this.state = {
      battles: [],
      fetching: false,
      showFilters: false,
      userSearch: '', 
      betFrom: null, 
      betTo: null,
      selectedTab: 1,
      showCreateBattleModal: false,
      showCreateBattleTutorialModal: false,
      showReload: false,
      battleCreated: false,
      battleJoined: null,
      createdBattleParams: null,
      joinedBattleParams: null,
      battleForConfirmParams: null,
      showConfirmBattleModal: false,
      initialTournaments: false,
      battleOpponent: null,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = { ...prevState };
    if (!prevState.initialTournaments && nextProps.battles.squads && !nextProps.battles.squadsFetch) {
      state = { ...state, initialTournaments: nextProps.battles.squads.map(t => t.id) }
    }
    if (!prevState.fetching && nextProps.tournaments.isFetching) {
      state = { ...state, fetching: true }
    }
    if (!prevState.showCreateBattleModal && (nextProps.battles.createBattleQuery === true || nextProps.battles.createBattleQuery.opponent)) {
      nextProps.setCreateBattleQuery(false);
      state = { ...state, showCreateBattleModal: true, battleOpponent: nextProps.battles.createBattleQuery.opponent || null }
    }
    if (prevState.fetching && !nextProps.tournaments.isFetching) {
      nextProps.newBattlesReset(false);
      state = { ...state, fetching: false }
    }

    if (prevState.battleJoined && nextProps.battles.battles.length) {
      const battle = nextProps.battles.battles.find(b => b.isConfirmed && b.tournament.id === prevState.battleJoined);
      if (battle) {
        const { datestart, wincount, cost, currencysymbol: currency } = battle.tournament;
        const { active, firstname, lastname, login, photo, rating, country, avgscore } = battle.players.find(p => p.userid != nextProps.user.id) || {};
        state = {
          ...state,
          joinedBattleParams: {
            datestart, 
            wincount, 
            cost, 
            currency,
            opponent: {
              active, 
              firstname, 
              lastname, 
              login, 
              photo, 
              rating: +rating, 
              country,
              avgscore
            }
          },
          showJoinBattleTutorialModal: true,
          battleJoined: null
        }
      }
    }
    if (!nextProps.tournaments.tournaments || nextProps.tournaments.tournaments && !nextProps.tournaments.tournaments.length) {
      state =  { ...state, battles: [] }
    }
    if (nextProps.tournaments.tournaments && nextProps.tournaments.tournaments.length) {
      const tournaments = nextProps.tournaments.tournaments;
      const battles = tournaments.filter(t => {
        let visible = true, confirmedplayers = [];
        if (t.players) {
          t.players.forEach(p => {
            if (p.userid === nextProps.user.id) {
              visible = p.confirmedbyowner !== -1;
            } else if (p.confirmedbyowner === 1) {
              confirmedplayers.push(p);
            }
          });
        }
        return t.cancelled === 0 && t.isbattle === 1 && ((t.started !== 1 && confirmedplayers.length < 2 && visible) || t.started);
      });
      if (prevState.battles.length < battles.length) {
        if (prevState.battleCreated) {
          const createdBattle = battles.find(b => b.createdby === nextProps.user.id && b.tournamenttypeid === 1 && b.players.length === 1);
          if (createdBattle) {
            const { datestart, wincount, currencysymbol: currency, cost } = createdBattle;
            state =  { 
              ...state,
              selectedTab: 1, 
              battles, 
              showCreateBattleTutorialModal: true, 
              createdBattleParams: { 
                datestart, 
                wincount, 
                currency, 
                cost
              },
              battleCreated: false
            }
          }
        } else {
          state = { ...state, battles }
        }
      }
      state = { ...state, battles }
    }
    return state;
  }

  componentDidMount() {
    this.props.requestSquadList();
    this.props.TournamentFetch();
    this.props.getUserSettings();

    // Получаем список чатов при выходе в лобби
    this.props._fetchChatRooms();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.battlesResponseToUser.fetching && !this.props.battlesResponseToUser.fetching) {
      this.setState({
        battleForConfirmParams: null,
        showConfirmBattleModal: false,
      });
    }
    if (this.props.mybattles.length && !this.props.fetchingBattles && !this.props.battlesResponseToUser.fetching) {
      const battleForConfirm = this.props.mybattles.find(
        b => ['INDIVIDUAL', 'PINFALL_BATTLE'].includes(b.tournamenttypebrief)
          && !b.isConfirmed && b.competitors.length > 1
      );
      if (battleForConfirm && battleForConfirm.createdby === this.props.user.id) {
        const { tournamentid: id, createdby, datestart, wincount, cost, currencysymbol: currency } = battleForConfirm;
        const competitor = battleForConfirm.competitors.find(c => c.userid !== this.props.user.id && c.confirmedbyowner === 0 && c.confirmedbyuser === 1);
        if (competitor) {
          if ((prevState.battleForConfirmParams && prevState.battleForConfirmParams.competitorId !== competitor.userid) || !prevState.battleForConfirmParams) {
            const { userid } = competitor;
            this.setState({
              battleForConfirmParams: {
                id,
                createdby,
                datestart,
                wincount,
                cost,
                currency,
                competitorId: userid,
              },
              showConfirmBattleModal: true,
            });
          }
        } else if (!competitor && this.state.showConfirmBattleModal) {
          this.setState({
            battleForConfirmParams: null,
            showConfirmBattleModal: false,
          });
        }
      }
    }
  }

  showProfile = (event, userid) => {
    event.preventDefault();
    this.props.UserByIdFetch({ userid });
  }

  onCreateBattleSubmit = ({ betType, bet, winCount, dateType, date, time, comment, betcurrency, watchmode, opponent, battleTypeBrief }) => {
    const params = { name: 'Battle' };
    params.wincount = winCount;
    if (betType === 2) {
      params.cost = bet;
      params.currency = betcurrency;
    }
    if (dateType === 2) {
      let datestart = null;
      if (time !== null) {
        datestart = moment(date.set('h', time.hour()).set('m', time.minute()));
      } else {
        datestart = moment(date);
      }
      params.datestart = moment(datestart).toISOString();
    }
    if (comment !== '') {
      params.rules = comment;
    }
    // Если передаем opponent, то берем id из него
    params.userid = opponent || this.state.battleOpponent;
    // params.userid = this.state.battleOpponent;
    params.watchmode = watchmode;
    params.battleTypeBrief = battleTypeBrief;
    this.props.CreateBattle({ ...params });
    this.setState({ showCreateBattleModal: false, battleCreated: true });
  }

  onCreateBattleCancel = () => {
    this.setState({ showCreateBattleModal: false });
  }

  _onBattleWillCreate = () => {
    const { setCreateBattleQuery } = this.props;
    setCreateBattleQuery(true);
  }

  createRatingSVG = (rating) => {
    const poly = 'M14 1.12978L17.6661 8.55816L17.7825 8.79389L18.0426 8.83169L26.2403 10.0229L20.3084 15.8051L20.1202 15.9886L20.1646 16.2476L21.5649 24.4122L14.2327 20.5574L14 20.4351L13.7673 20.5574L6.43507 24.4122L7.83541 16.2476L7.87984 15.9886L7.69161 15.8051L1.75969 10.0229L9.9574 8.83169L10.2175 8.79389L10.3339 8.55816L14 1.12978Z';
    const numbers = [1, 2, 3, 4, 5];
    return numbers.map((num, idx) => {
      let fill = 'none';
      let isPartial = false;
      const entropy = Math.random();
      if (rating > num || rating == num) {
        fill = '#FFB31E';
      } else if (num - 1 < rating && rating < num) {
        isPartial = true;
        fill = `url(#partialGradient${entropy})`;
      }
      return (
        <svg
          key={idx}
          className={`${rating === num ? 'selected' : ''}`}
          width="12"
          height="12"
          viewBox="0 0 28 28"
          fill={fill}
          stroke={1}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d={poly} stroke="#FFB31E" />
          {isPartial ? (
            <linearGradient id={`partialGradient${entropy}`}>
              <stop stopOpacity="1" offset={`${(rating - num + 1) * 100}%`} stopColor="#FFB31E" />
              <stop stopOpacity="0" offset="0" />
            </linearGradient>
          ) : null}
        </svg>
      );
    });
  };

  // список игроков которых ожидают в строке
  getListPassedPlayer = (players) => {
    return players.fullname;
  }

  onAcceptToBattle = (userid, tournamentid, isUser) => {
    isUser
      ? this.handleResponseToUser(userid, tournamentid, 1)
      : this.handleResponseToOwner(userid, tournamentid, 1)
  }

  renderTable = (translate, typefilter = []) => {
    const {
      intl,
      squads
    } = this.props;

    return (
      <AllBattlesTab
        translate={translate}
        intl={intl}
        squads={squads}
        user={this.props.user}
        participation={this.props.tournaments.fetches.participation}
        betFrom={this.state.betFrom}
        betTo={this.state.betTo}
        typefilter={typefilter}
        squadsWatcher={this.props.squadsWatcher}
        setObservableSquad={(id) => {
          this.props.setObservableSquad(id);
          this.props.history.push('/watch');
        }}
        UserByIdFetch={UserByIdFetch}
        TakePartBattle={({ tournamentId, squadId }) => {
          this.setState({ battleJoined: tournamentId });
          this.props.TakePartBattle({ tournamentId, squadId });
        }}
        onAcceptToBattle={(userid, tournamentid, isUser) => {
          this.onAcceptToBattle(userid, tournamentid, isUser);
        }}
      />
    );
  }

  renderSidebar = () => {
    const { rooms, unreadNotifications, systemMessages } = this.props;
    const { selectedTab } = this.state;
    const unreadMessages = rooms.reduce((count, room) => {
      if ((room.room_name.startsWith('squad-chat') || 
        room.room_name.startsWith('private-room') || 
        room.room_name.startsWith('support-room')) && 
        (this.props.currentRoom !== room.room_name) && 
        (room.users && room.users.length > 1)
      ) {
        // :TODO передавать в запрос значение systemMessages (чтобы не тащить все значения)
        const { message: { seen = 0, total = 0, total_system = 0, seen_system = 0 } = {} } = room;

        return (systemMessages)
          ? count + (total - seen)
          : count + ((total - total_system) - (seen - seen_system));
      }
      return count;
    }, 0);
    return (
      <div className="lobby__sidebar">
        <div className="lobby__sidebar__content">
          {this.renderSidebarContent()}
        </div>
        <div className="lobby__sidebar__tabs">
          <div className={`lobby__sidebar__tab${selectedTab == 1 ? ' active' : ''}`}
            onClick={() => { this.setState({ selectedTab: 1 }) }}
          >
            <SvgSwords/>
          </div>
          <div className={`lobby__sidebar__tab${selectedTab == 2 ? ' active' : ''}`}
            onClick={() => { this.setState({ selectedTab: 2 }) }}
          >
            <BadgeIcon count={unreadNotifications}>
              <SvgNotification />
            </BadgeIcon>
          </div>
          <div className={`lobby__sidebar__tab${selectedTab == 3 ? ' active' : ''}`}
            onClick={() => { this.setState({ selectedTab: 3 }) }}
          >
            <BadgeIcon count={unreadMessages}>
              <SvgChat />
            </BadgeIcon>
          </div>
        </div>
      </div>
    )
  }

  renderSidebarContent = () => {
    const { selectedTab } = this.state;
    switch (selectedTab) {
      case 1:
        return <Battles  onChat={() => this.setState({ selectedTab: 3 })}/>
      case 2:
        return <Notifications/>
      case 3:
        return <Chat/>
      default: 
        return null;
    }
  }

  handleResponseToUser = (userid, tournamentid, confirmed) => {
    this.props.BattlesResponseToUserFetch({
      userid,
      tournamentid,
      confirmed,
    });
  }

  handleResponseToOwner = (userid, tournamentid, confirmed) => {
    this.props.BattlesResponseToOwnerFetch({
      userid,
      tournamentid,
      confirmed,
    });
  }

  render() {
    const {
      intl,
      newBattles,
      usersettings,
      user,
      opponent_game_status,
      setOpponentGameRefuse,
      squads,
    } = this.props;
    const { 
      fetching, 
      battles, 
      showCreateBattleModal, 
      showCreateBattleTutorialModal, 
      createdBattleParams,
      showJoinBattleTutorialModal,
      joinedBattleParams,
      showConfirmBattleModal,
      battleForConfirmParams,
    } = this.state;
    let createBattleTutorialSetting = {}, createBattleTutorialSuppress = false; 
    let joinBattleTutorialSetting = {}, joinBattleTutorialSuppress = false; 
    let inviteBattleSetting = {}, inviteBattleSettingValue = []; 
    if (usersettings) {
      createBattleTutorialSetting = usersettings.find(s => s.code == 'create_battle_tutorial');
      createBattleTutorialSuppress = !!+createBattleTutorialSetting.value;
      joinBattleTutorialSetting = usersettings.find(s => s.code == 'join_battle_tutorial');
      joinBattleTutorialSuppress = !!+joinBattleTutorialSetting.value;
      inviteBattleSetting = usersettings.find(s => s.code == 'invite_battle');
      try {
        inviteBattleSettingValue = JSON.parse(inviteBattleSetting.value) || [];
      } catch (err) {
        inviteBattleSettingValue = [];
      }
    }
    const translate = {
      observe: intl.formatMessage({ id: 'WatchBattleOfTournament', defaultMessage: 'Watch' }),
      opponent: intl.formatMessage({ id: 'Opponent', defaultMessage: 'Opponent' }),
      country: intl.formatMessage({ id: 'FriendRouterFilterCountry', defaultMessage: 'Country' }),
      gender: intl.formatMessage({ id: 'FriendRouterFilterGender', defaultMessage: 'Gender' }),
      wins: intl.formatMessage({ id: 'table_wins_short', defaultMessage: 'Wins' }),
      games: intl.formatMessage({ id: 'games', defaultMessage: 'Games' }),
      battleDate: intl.formatMessage({ id: 'date_of_battle', defaultMessage: 'Date of Battle' }),
      male: intl.formatMessage({ id: 'GenderMale', defaultMessage: 'Male' }).substr(0, 1),
      female: intl.formatMessage({ id: 'GenderFemale', defaultMessage: 'Female' }).substr(0, 1),
      now: intl.formatMessage({ id: 'now', defaultMessage: 'Now' }),
      withoutBet: intl.formatMessage({ id: 'without_bet', defaultMessage: 'Free' }),
      fee: intl.formatMessage({ id: 'fee', defaultMessage: 'Fee' }),
      filter: intl.formatMessage({ id: 'filter', defaultMessage: 'Filter' }),
      search: intl.formatMessage({ id: 'find_a_player', defaultMessage: 'Find a player' }),
      reset: intl.formatMessage({ id: 'reset', defaultMessage: 'Reset' }),
      from: intl.formatMessage({ id: 'from', defaultMessage: 'From' }),
      to: intl.formatMessage({ id: 'to', defaultMessage: 'To' }),
      no_data_found: {
        title: intl.formatMessage({ id: 'NothingFoundTitle', defaultMessage: 'Nothing found' }),
        description: intl.formatMessage({ id: 'NothingFoundDescription', defaultMessage: 'Change filter parameters' }),
        reset: intl.formatMessage({ id: 'NothingFoundReset', defaultMessage: 'Reset filters' }),
      },
      reload: intl.formatMessage({ id: 'NewBattlesText', defaultMessage: 'New Battles' }),
      sent: intl.formatMessage({ id: 'challenge_sent', defaultMessage: 'Challenge sent' }),
      invited: intl.formatMessage({ id: 'IsInvitedSelf', defaultMessage: 'You are invited' }),
      accept: intl.formatMessage({ id: 'accept_battle', defaultMessage: 'Accept battle' }),
    }

    return (
      <div className="lobby">
        <div className="lobby__container">
          <div className="lobby__content">
            {!this.props.tournamentInfo 
              ? <Tabs>
                  <TabList>
                    <Tab>{this.props.intl.formatMessage({ id: 'TournamentFilterAll', defaultMessage: 'All' })}</Tab>
                    <Tab>Battles</Tab>
                    <Tab>{this.props.intl.formatMessage({ id: 'tournaments', defaultMessage: 'Tournaments' })}</Tab>
                    <Tab>{this.props.intl.formatMessage({ id: 'challenges', default: 'Challanges' })}</Tab>
                  </TabList>
                  <TabPanel>
                    {squads && squads.length
                      ? this.renderTable(translate)
                      : !fetching && <NoDataFound onBattleWillCreate={this._onBattleWillCreate}/>}
                  </TabPanel>
                  <TabPanel>{squads && squads.filter(sq => ['INDIVIDUAL', 'PINFALL_BATTLE', 'OPEN_CHALLENGE'].includes(sq.tournamenttypebrief)).length
                    ? this.renderTable(translate, ['INDIVIDUAL', 'PINFALL_BATTLE', 'OPEN_CHALLENGE'])
                    : !fetching && <NoDataFound onBattleWillCreate={this._onBattleWillCreate}/>}
                  </TabPanel>
                  <TabPanel>{squads && squads.filter(sq => !['INDIVIDUAL', 'PINFALL_BATTLE', 'OPEN_CHALLENGE'].includes(sq.tournamenttypebrief)).length
                    ? this.renderTable(translate, ['BATTLES', 'DROP_IN'])
                    : !fetching && <NoDataFound onBattleWillCreate={this._onBattleWillCreate}/>}
                  </TabPanel>
                  <TabPanel>
                    <Challanges
                      intl={this.props.intl}
                      switchTournament={tournament => this.setState({ tournamenttypeid: tournament.tournamenttypeid })}
                      onBattleWillCreate={this._onBattleWillCreate}
                    />
                  </TabPanel>
                </Tabs>
              : <TournamentsTabs/>
            }
          </div>
          {this.renderSidebar()}
        </div>
        { 
          !createBattleTutorialSuppress && showCreateBattleTutorialModal ?
          <CreateBattleTutorialModal visible
            params={createdBattleParams}
            onClose={(suppress) => {
              if (suppress) {
                this.props.setUserSettingValue({ settingid: createBattleTutorialSetting.id, value: 1 })
              }
              this.setState({ 
                showCreateBattleTutorialModal: false, 
                createdBattleParams: null
              })
            }}
          /> : null
        }
        { 
          !joinBattleTutorialSuppress && showJoinBattleTutorialModal ?
          <JoinBattleTutorialModal visible
            params={joinedBattleParams}
            onClose={(suppress) => {
              if (suppress) {
                this.props.setUserSettingValue({ settingid: joinBattleTutorialSetting.id, value: 1 })
              }
              this.setState({ 
                showJoinBattleTutorialModal: false, 
                joinedBattleParams: null
              })
            }}
          /> : null
        }        
        { 
          battleForConfirmParams
          && !inviteBattleSettingValue.includes(battleForConfirmParams.id)
          && showConfirmBattleModal ?
          <ConfirmBattleModal
            visible
            params={battleForConfirmParams}
            loading={this.props.battlesResponseToUser.fetching}
            onDecline={(tournamentid, createdby, userid) => {
              createdby === user.id
                ? this.handleResponseToUser(userid, tournamentid, -1)
                : this.handleResponseToOwner(userid, tournamentid, -1);
              this.setState({
                showConfirmBattleModal: false,
                battleForConfirmParams: null
              });
            }}
            onAccept={(tournamentid, createdby, userid) => {
              createdby === user.id
                ? this.handleResponseToUser(userid, tournamentid, 1)
                : this.handleResponseToOwner(userid, tournamentid, 1);
              this.setState({
                showConfirmBattleModal: false,
                battleForConfirmParams: null
              });
            }}
            onDone={(tournamentid, suppress) => {
              if (suppress) {
                inviteBattleSettingValue.push(tournamentid);
                this.props.setUserSettingValue({ settingid: inviteBattleSetting.id, value: JSON.stringify(inviteBattleSettingValue) })
              }
              this.setState({
                showConfirmBattleModal: false,
                battleForConfirmParams: null
              })
            }}
          />
          :null
        }
        <OBModal
          title={intl.formatMessage({ id: 'OpponentRefuseModalTitle', defaultMessage: 'Battle did not take place' })}
          visible={opponent_game_status}
          width={395}
          onCancel={() => setOpponentGameRefuse(false)}
          className='opponent-refuse-modal'
          footer={
            <Button
              kind='aquamarine'
              bordered='true'
              onClick={() => setOpponentGameRefuse(false)}
              className='dialog__button-decline'
            >
              Ok
            </Button>}>
          <div className='OpponentRefuseModal' style={{ marginTop: 15 }}>
            {intl.formatMessage({ id: 'OpponentRefuseModalBody', defaultMessage: 'Your opponent has refused to participate in game' })}
          </div>
        </OBModal>
        <CreateBattleModal
          opponent={this.state.battleOpponent}
          visible={showCreateBattleModal}
          onSubmit={this.onCreateBattleSubmit}
          onClose={this.onCreateBattleCancel}
        />
      </div>
    )
  }
}

export default injectIntl(
  withRouter(
    connect(
      state => ({
        tournaments: state.tournaments,
        user: state.users.user,
        lang: state.langs.lang,
        newBattles: state.events.newBattles,
        unreadNotifications: state.notifications.notifications.count,
        rooms: state.chat.rooms,
        battles: state.battles,
        squads: state.battles.squads.filter(s => !s.current),
        usersettings: state.usersettings.data.modals,
        opponent_game_status: state.battles.opponent_game_status,
        currentRoom: state.chat.currentRoom,
        squadsWatcher: state.battles.squadsWatcher,
        battlesResponseToUser: state.battles.battlesResponseToUser,
        mybattles: state.battles.mybattles,
        fetchingBattles: state.battles.fetching,
        systemMessages: state.chat.systemSquadChat,
        tournamentInfo: state.tournaments.tournamentInfo,
      }),
      {
        TournamentFetch,
        UserByIdFetch,
        CreateBattle,
        setCreateBattleQuery,
        newBattlesReset,
        getUserSettings, 
        setUserSettingValue,
        TakePartBattle,
        BattlesResponseToUserFetch,
        BattlesResponseToOwnerFetch,
        setOpponentGameRefuse,
        setObservableTournament,
        setObservableSquad,
        requestSquadList,
        _fetchChatRooms: () => ({ type: 'FETCH_CHAT_ROOMS' }),
      },
    )(Lobby)),
);