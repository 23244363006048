import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import ChatVC from './ChatVC';
import chatSocket from '../../../libs/ChatSocketInstance';
import { fetchChatRoom, updateMessagesSeen, setCurrentRoom, sendSupportMessage } from '../../../libs/reducers/actions';
import OBModal from '../Modal';
import { Button } from '../Button';

function getUA() {
  return window && window.navigator ? window.navigator.userAgent : '';
}
function isSafari() {
  return getUA().indexOf('Safari') !== -1 && !isChrome() && !isAndroid();
}
function isChrome() {
  return getUA().indexOf('Chrome') !== -1;
}
function isAndroid() {
  return getUA().indexOf('Android') !== -1;
}
const MsgSound = require(!isSafari() ? '../../../sound/light.ogg' : '../../../sound/light.m4r');

const debug = false;
const logger = {
  log: debug ? console.log : function () {},
  error: console.error,
};

const IS_SAFARI = isSafari();

class ChatModel extends Component {
  static propTypes = {
    userid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    sessionkey: PropTypes.string.isRequired,
    room: PropTypes.string,
    historyMaxSize: PropTypes.number,
    intl: intlShape,
    _fetchChatRoom: PropTypes.func,
    _updateMessagesSeen: PropTypes.func,
    _setCurrentRoom: PropTypes.func,
    chat: PropTypes.shape({
      currentRoom: PropTypes.oneOfType([PropTypes.string, null]),
      rooms: PropTypes.arrayOf(PropTypes.shape()),
      isFetching: PropTypes.bool,
      error: PropTypes.string,
    }),
  };

  static defaultProps = {
    historyMaxSize: 20,
  };

  static defaultState = {
    room: null,
    isAdmin: false,
    whitelistOnly: false,
    historyMaxSize: 0,
    historySize: 0,
    historyTotal: 0,
  };

  constructor(props) {
    super(props);
    const { userid, room = 'default' } = props;
    this.userid = userid;
    this.chatSocket = chatSocket;
    Object.defineProperty(this, 'socket', {
      /* eslint-disable-next-line */
      get: function() {
        return this.chatSocket.getSocket();
      },
      enumerable: true,
    });

    this.state = {
      room,
      historyMaxSize: 0,
      historySize: 0,
      historyTotal: 0,
      isAdmin: false,
      whitelistOnly: false,
      owner: null,
      messages: [],
      users: [],
      lists: [],
      iOSmodal: isSafari(),
      iOSsound: false,
      soundStream: null,
    };
  }

  componentDidMount() {
    if (this.props.auth) {
      this.init();
    }
  }

  componentDidUpdate(prevProps) {
    const { room } = this.props;
    if (!prevProps.auth && this.props.auth) {
      this.init();
    } else if (prevProps.room !== room) {
      this.roomJoin(room).then(() => {
        setTimeout(this.chatScroll, 300);
      });
    }
  }

  componentWillUnmount() {
    this.destroy();
  }

  init = () => {
    const { room } = this.props;
    this.socket.on('roomJoinedEcho', this.onRoomJoinedEcho);
    this.socket.on('roomMessage', this.onRoomMessage);
    this.fnCall('_setCurrentRoom', room);
    this.fnCall('_updateMessagesSeen', room);
    this.fnCall('_fetchChatRoom', room);
    this.join();
  }

  join = (iter = 0) => {
    const { room } = this.props;
    if (iter > 5) {
      throw new Error('can not connect to chat.room: ', room);
    }
    const timerId = setTimeout(() => this.join(iter + 1), 2000);
    this.timerId = timerId;
    this.roomJoin(room).then(() => {
      clearTimeout(timerId);
      setTimeout(this.chatScroll, 300);
    });
  }

  fnCall = (name, ...args) => {
    const { [name]: fn } = this.props;
    if (typeof fn === 'function') {
      fn(...args);
    }
  }

  destroy = () => {
    clearTimeout(this.timerId);
    this.fnCall('_setCurrentRoom', null);
    if (this.socket) {
      this.socket.off('roomMessage', this.onRoomMessage);
      this.socket.off('roomJoinedEcho', this.onRoomJoinedEcho);
    }
  };

  onRoomJoinedEcho = (room, id, njoined) => {
    logger.log('socket.id:', id, ', njoined:', njoined);
    this.fnCall('_fetchChatRoom', room);
  };

  onRoomMessage = (room, message) => {
    if (room !== this.props.room) {
      return;
    }
    if (!IS_SAFARI) {
      new Audio(MsgSound).play();
    } else if (this.state.soundStream) {
      this.state.soundStream.play();
    }
    logger.log(`roomMessage from room (name = ${room})`);
    const { chat: { currentRoom } = {} } = this.props;
    if (currentRoom === room) {
      this.fnCall('_updateMessagesSeen', room);
    }
    const { messages: msgs } = this.state;
    const messages = [...msgs];
    messages.push(message);
    this.setState({ messages }, this.chatScroll);
  };

  roomGetInfo = () => {
    this.roomGetWhitelistMode();
    this.roomHistoryInfo()
      .then(() => {
        const { historySize } = this.state;
        this.roomHistoryGet(0, historySize);
      })
      .catch(() => {});
    this.roomGetUsers();
    this.roomGetOwner();
  };

  clear = (room = null, cb = () => {}) => {
    this.setState(
      {
        room,
        historyMaxSize: 0,
        historySize: 0,
        historyTotal: 0,
        messages: [],
        users: [],
        isAdmin: false,
        whitelistOnly: false,
        owner: null,
      },
      cb,
    );
  };

  onAddToList = (error, data) => {
    if (error) {
      logger.log('onAddToList.error', error);
      return;
    }
    logger.log('onAddToList.data', data);
  };

  onRemoveFromList = (error, data) => {
    if (error) {
      logger.log('onRemoveFromList.error', error);
      return;
    }
    logger.log('onRemoveToList.data', data);
  };

  onMessageSend = (error, data) => {
    if (error) {
      logger.log('onMessageSend.error', error);
      return;
    }
    logger.log('onMessageSend.data', data);
  };

  onWhitelistMode = (error, data) => {
    if (error) {
      logger.log('onWhitelistMode.error', error);
      return;
    }
    logger.log('onWhitelistMode.data', data);
    this.setState({ whitelistOnly: data });
  };

  onHistoryGet = (error, data) => {
    if (error) {
      logger.log('onHistoryGet.error', error);
      return;
    }
    const messages = data.reverse();
    this.setState({ messages });
  };

  onHistoryInfo = (error, data) => {
    if (error) {
      logger.log('onHistoryInfo.error', error);
      return;
    }
    const { historyMaxSize, historySize, lastMessageId: historyTotal } = data;
    this.setState({ historyMaxSize, historySize, historyTotal });
  };

  onLeave = (error, data) => {
    if (error) {
      logger.log('onLeave.error', error);
      return;
    }
    logger.log('onLeave.data', data);
    this.clear();
  };

  roomAddToList = async (userId, listName) => {
    const { room } = this.state;
    this.socket.emit('roomAddToList', room, listName, [`${userId}`], this.onAddToList);
  };

  roomRemoveFromList = (userId, listName) => {
    const { room } = this.state;
    this.socket.emit('roomRemoveFromList', room, listName, [`${userId}`], this.onRemoveFromList);
  };

  roomMessage = (msg, callback) => {
    const { room } = this.state;
    const { _sendSupportMessage, user } = this.props;
    // const onMessage = typeof callback === 'function' ? callback : this.onMessageSend;
    this.socket.emit('roomMessage', room, msg, (error, data) => {
      if (error) {
        logger.log('onMessageSend.error', error);
        return;
      }
      logger.log('onMessageSend.data', data);
      if (room.indexOf('support-room-', 0) === 0) {
        const { textMessage: message } = msg;
        const { id, firstname, lastname, email } = user;
        const name = `${lastname} ${firstname}`;
        _sendSupportMessage({ message, client: { id, name, email }});
      }
      callback();
    });
  };

  roomSetWhitelistMode = (mode) => {
    const { room } = this.state;
    this.socket.emit('roomSetWhitelistMode', room, mode, (error) => {
      if (error) {
        logger.log('roomSetWhitelistMode.error', error);
        return;
      }
      this.setState({ whitelistOnly: mode });
    });
  };

  roomGetWhitelistMode = () => {
    const { room } = this.state;
    this.socket.emit('roomGetWhitelistMode', room, this.onWhitelistMode);
  };

  roomHistoryGet = (start, end) => {
    const { room } = this.state;
    this.socket.emit('roomHistoryGet', room, +start, +end, this.onHistoryGet);
  };

  roomHistoryInfo = () => {
    const { room } = this.state;
    return new Promise((resolve, reject) => {
      this.socket.emit('roomHistoryInfo', room, (error, ...args) => {
        this.onHistoryInfo(error, ...args);
        return error ? reject(error) : resolve();
      });
    });
  };

  _roomJoin = (room, cb) => new Promise((resolve, reject) => {
    logger.log(`joining room "${room}"...`);
    this.socket.emit('roomJoin', room, (error, data) => {
      if (typeof cb === 'function') {
        cb(error, data);
      }
      if (error) {
        logger.log('onJoin.error', error);
        return reject(error);
      }
      logger.log('onJoin.data', data);
      return this.clear(room, resolve);
    });
  });

  roomJoin = room => this._roomJoin(room)
    .then(this.roomGetInfo)
    .catch(() => {})

  roomLeave = () => {
    const { room } = this.state;
    this.socket.emit('roomLeave', room, this.onLeave);
  };

  roomCreate = (room, mode = true) => {
    this.socket.emit('roomCreate', room, mode, (error, data) => {
      if (error) {
        logger.log('onCreate.error', error);
        return;
      }
      logger.log('onCreate.data', data);
      this.setState({});
    });
  };

  roomGetLists = (lists) => {
    this.setState({ lists });
  };

  onGetOwner = (error, data) => {
    if (error) {
      logger.log('onGetOwner.error', error);
      return;
    }
    logger.log('onGetOwner.data', data);
    const { isAdmin } = this.state;
    logger.log('isAdmin', isAdmin, { userid: this.userid, data });
    this.setState({ owner: { userId: data }, isAdmin: isAdmin || +data === +this.userid });
  };

  roomGetOwner = () => {
    const { room } = this.state;
    this.socket.emit('roomGetOwner', room, this.onGetOwner);
  };

  roomGetAccessList = (room, listName) => new Promise((resolve, reject) => {
    this.socket.emit('roomGetAccessList', room, listName, (error, data) => {
      if (error) {
        logger.log('roomGetAccessList.error', error);
        return reject(error);
      }
      const retval = [];
      for (const userId of data) {
        retval.push({ userId, listName });
      }
      logger.log('roomGetAccessList:', listName, data, this.userid);
      return resolve(retval);
    });
  });

  roomGetUsers = async () => {
    const { room, lists } = this.state;
    const promises = [];
    for (const listName of lists) {
      promises.push(this.roomGetAccessList(room, listName));
    }
    const usersList = await Promise.all(promises);
    const usersMap = {};
    for (const users of usersList) {
      for (const user of users) {
        const { userId } = user;
        if (!usersMap[userId] || usersMap[userId].listName === 'userlist') {
          usersMap[user.userId] = user;
        }
      }
    }
    const users = [];
    for (const userId of Object.keys(usersMap)) {
      users.push(usersMap[userId]);
    }
    const { owner } = this.state;
    const isAdmin = (owner ? +owner.userId === +this.userid : false)
      || users.some(u => +u.userId === +this.userid && u.listName === 'adminlist');
    logger.log('isAdmin: ', isAdmin, users, { currentUserId: this.userid });
    this.setState({ users, isAdmin });
    for (const user of users) {
      this.roomUserSeen(user.userId);
    }
  };

  roomUserSeen = (userId) => {
    const { room } = this.state;
    this.socket.emit('roomUserSeen', room, `${userId}`, (error, data) => {
      if (error) {
        logger.log('roomUserSeen.error', error);
        return;
      }
      const { users } = this.state;
      const nextUsers = users.map((u) => {
        if (+u.userId === +userId) {
          const { timestamp } = data;
          return { ...u, timestamp };
        }
        return { ...u };
      });
      this.setState({ users: nextUsers });
    });
  };

  initRoom = async (room) => {
    const { room: curRoom } = this.state;
    let s = Promise.resolve();
    if (curRoom !== room) {
      s = new Promise(resolve => this.setState({ room }, resolve));
    }
    await s;
    this.socket.emit('roomHistoryInfo', room, (error, data) => {
      this.onHistoryInfo(error, data);
      if (error) {
        return;
      }
      const { lastMessageId: historyTotal } = data;
      const { historyMaxSize } = this.props;
      const start = Math.max(0, historyTotal - historyMaxSize);
      this.roomHistoryGet(start, historyTotal);
      this.roomGetUsers();
    });
  };

  chatScroll = () => {
    if (this.chatRef) {
      this.chatRef.chatScroll();
    }
  };

  handleSafariRejoin = () => {
    this.setState({ iOSmodal: false, soundStream: new Audio(MsgSound) }, () => {
      this.state.soundStream.load()
    });
  }

  render() {
    const {
      room,
      owner = null,
      whitelistOnly,
      historyMaxSize,
      historySize,
      historyTotal,
      messages,
      users,
      isAdmin = false,
      iOSsound,
    } = this.state;
    const {
      intl, lang, fullHeight = false, className, backgroundImage, onClickTime, userid, systemMessages,
    } = this.props;
    const classes = fullHeight ? ['chatVC', 'fullHeight'] : ['chatVC'];
    if (className) {
      classes.push(className);
    }
    return (
      <div className={`chatbody ${classes.join(' ')}`} style={{ backgroundImage: `url(${backgroundImage})` }}>
        {isSafari() && !iOSsound ?
          <Button onClick={() => {this.handleSafariRejoin();this.setState({iOSsound: true})}} kind="aquamarine" className="chat_sound_button">
            Enable sound notifications
          </Button>
        : null}
        <ChatVC
          intl={intl}
          lang={lang}
          room={room}
          owner={owner}
          userid={userid}
          isAdmin={isAdmin}
          whitelistOnly={whitelistOnly}
          historyMaxSize={historyMaxSize}
          historySize={historySize}
          historyTotal={historyTotal}
          messages={messages}
          users={users}
          systemMessages={systemMessages}
          onClickTime={onClickTime}
          roomMessage={this.roomMessage}
          roomAddToList={this.roomAddToList}
          roomRemoveFromList={this.roomRemoveFromList}
          roomSetWhitelistMode={this.roomSetWhitelistMode}
          roomGetWhitelistMode={this.roomGetWhitelistMode}
          roomHistoryGet={this.roomHistoryGet}
          roomHistoryInfo={this.roomHistoryInfo}
          roomGetUsers={this.roomGetUsers}
          roomJoin={this.roomJoin}
          roomLeave={this.roomLeave}
          roomCreate={this.roomCreate}
          roomGetLists={this.roomGetLists}
          ref={(c) => {
            this.chatRef = c;
          }}
          squad={this.props.squad}
        />
      </div>
    );
  }
}

class Chat extends Component {
  static propTypes = {
    intl: intlShape,
    user: PropTypes.shape(),
    room: PropTypes.string.isRequired,
    withHeader: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
    auth: PropTypes.bool,
    _fetchChatRoom: PropTypes.func,
    _updateMessagesSeen: PropTypes.func,
    _setCurrentRoom: PropTypes.func,
    getMessages: PropTypes.func,
  };

  static defaultProps = {
    room: 'default',
    withHeader: true,
  };

  render() {
    const {
      auth,
      userid,
      user,
      intl,
      lang,
      room,
      withHeader,
      className,
      backgroundImage,
      onClickTime,
      _fetchChatRoom,
      _updateMessagesSeen,
      _setCurrentRoom,
      _sendSupportMessage,
      chat,
      containerClass,
      systemMessages,
      squad,
    } = this.props;
    let chatHeader = null;
    if (!!withHeader && typeof withHeader === 'boolean') {
      chatHeader = (<div className="menu-header">
        <div className="header__text">{intl.formatMessage({ id: 'ChatTitle', defaultMessage: 'Chat' })}</div>
      </div>);
    } else if (withHeader) {
      chatHeader = withHeader;
    }
    return (
      <div className={containerClass ? containerClass : 'chat-container'}>
        {chatHeader}
        <ChatModel
          auth={auth}
          userid={userid}
          user={user}
          onClickTime={onClickTime}
          className={className}
          room={room}
          intl={intl}
          lang={lang}
          historyMaxSize={1000}
          systemMessages={systemMessages}
          fullHeight={!withHeader}
          backgroundImage={backgroundImage}
          _fetchChatRoom={_fetchChatRoom}
          _updateMessagesSeen={_updateMessagesSeen}
          _setCurrentRoom={_setCurrentRoom}
          _sendSupportMessage={_sendSupportMessage}
          chat={chat}
          squad={squad}
        />
      </div>
    );
  }
}

export default injectIntl(
  connect(
    state => ({
      userid: state.users.user.id,
      auth: state.events.chatAuth,
      chat: state.chat,
      user: state.users.user,
      lang: state.langs.lang,
    }),
    {
      _fetchChatRoom: fetchChatRoom,
      _updateMessagesSeen: updateMessagesSeen,
      _setCurrentRoom: setCurrentRoom,
      _sendSupportMessage: sendSupportMessage,
    },
  )(Chat),
);
