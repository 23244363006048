import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { Table, Pagination } from 'antd';
import { Button } from '../../common/Button';
import '../../../style.scss';
import Input from '../../common/Input';
import Select from '../../common/Select';
import OBModal from '../../common/Modal';
import Checkbox from '../../common/Checkbox';
import OBDatePicker from '../../common/DatePicker';
import RenderUserCard from '../../common/UserCardTable';
import imgAppleIcon from '../../../images/apple-icon.png';
import {
  getCurrencies,
  getSearchUserTariffs,
  setTariffByUser,
} from '../../../libs/reducers/adminReducer';
import { setCommonNotification } from '../../../libs/reducers/commonReducer';
import { getTariffList } from '../../../libs/reducers/actions';

const TariffsTab = ({
  admin,
  isSearch,
  getSearchUserTariffs,
  setCommonNotification,
  isSave,
  getTariffList,
  getCurrencies,
  tariffs,
  setTariffByUser,
}) => {
  const [isVisibleEditTariff, setIsVisibleEditTariff] = useState(false);
  const [editTariff, setEditTariff] = useState(null);
  const [tariffOptions, setTariffOptions] = useState(null);
  const [adminDiskSize, setAdminDiskSize] = useState('');
  const [timerDelay, setTimerDelay] = useState(false);
  const [formkey, setFormkey] = useState(Math.random());
  const [customTariffs, setCustomTariffs] = useState({
    search: '',
    id: false,
    login: false,
    page: 1,
    limit: 10,
  });

  const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current === undefined ? value : ref.current;
  };
  const prevProps = usePrevious({ admin, isSave, customTariffs });

  const getTransformSizeDisk = disckSize =>
    disckSize > 999 ? `${(disckSize / 1000).toFixed(2)} Гб` : `${disckSize} Мб`;

  const getStorageToGb = value =>
    value ? (value / Math.pow(1000, 3)).toFixed(2) : '0';

  const onToggleModalEditTariff = data => {
    setEditTariff(() => {
      const editTariff = !isVisibleEditTariff
        ? { ...data, isEdit: false }
        : null;

      if (editTariff) {
        // Если дата тарифа прошла обнуляем дату
        if (
          (editTariff.ab_pend && new Date() > editTariff.ab_pend) ||
          !editTariff.is_valid
        ) {
          editTariff.ab_pend = null;
        }
        // Если добавляем новую подписку, убираем автопродление
        if (!editTariff.is_valid) {
          editTariff.tariffid = 0;
          editTariff.tariffrenewal = 1;
        }
      }
      setIsVisibleEditTariff(!isVisibleEditTariff);
      return editTariff;
    });
  };

  const delaySearchTariff = param => {
    const timer = timerDelay;
    let newTimer;
    const DELAY = 200;

    if (timer) {
      clearTimeout(timer);
    }
    newTimer = setTimeout(() => getSearchUserTariffs(param), DELAY);
    setTimerDelay(newTimer);
  };

  const onSetValueTariffs = (type, e) => {
    let value = e;

    if (e.target) {
      value = e.target.value;
    } else if (e.hasOwnProperty('value')) {
      value = e.value;
    }

    if (customTariffs.hasOwnProperty(type)) {
      setCustomTariffs(prevState => {
        const tariffs = { ...prevState };

        tariffs[type] = value;
        tariffs.page = 1;
        delaySearchTariff(tariffs);

        return { ...tariffs };
      });
    }
  };

  const onChangePageTariffs = value => {
    const tariffs = { ...prevProps.customTariffs };

    tariffs.page = value;
    delaySearchTariff(tariffs);
    setCustomTariffs(tariffs);
  };

  const onSaveTariff = () => {
    if (!editTariff.isEdit) {
      setCommonNotification('withoutChanges');

      return;
    }
    const {
      userid,
      tariffrenewal,
      tariffid,
      ab_pend,
      remove_tariff,
    } = editTariff;

    if (!ab_pend || !tariffid) {
      setCommonNotification('withoutChanges');

      return;
    }
    setTariffByUser({
      userid,
      tariffrenewal,
      tariffid,
      ab_pend,
      remove_tariff,
    });
    if (!isSave) {
      setIsVisibleEditTariff(false);
    }
  };

  const onSelectTariff = ({ value, label }) => {
    setEditTariff(prevState => {
      const editTariff = {
        ...prevState,
        tariffid: value,
        tariffname: label,
        isEdit: true,
      };

      return editTariff;
    });
  };

  const onSelectRenewalTariff = value => {
    setEditTariff(prevState => {
      const editTariff = { ...prevState };

      editTariff.tariffrenewal = value;
      editTariff.isEdit = true;

      return editTariff;
    });
  };

  const onSelectDateTariff = date => {
    setEditTariff(prevState => {
      const editTariff = { ...prevState };

      editTariff.ab_pend = date.toISOString();
      editTariff.isEdit = true;

      return editTariff;
    });
  };

  const onSelectDeleteTariff = value => {
    setEditTariff(prevState => {
      const editTariff = { ...prevState };

      editTariff.remove_tariff = value;
      editTariff.isEdit = true;

      return editTariff;
    });
  };

  useEffect(() => {
    getSearchUserTariffs({
      limit: 10,
      page: 1,
    });
    if (!tariffOptions) {
      getTariffList();
    }

    getCurrencies();

    if (admin.disckSize && !adminDiskSize) {
      setAdminDiskSize(getTransformSizeDisk(admin.disckSize));
    }
  }, []);

  useEffect(() => {
    if (!tariffOptions && tariffs && tariffs.length) {
      const tariffOptions = tariffs.map(tariff => ({
        value: tariff.id,
        label: tariff.name,
      }));
      setTariffOptions(tariffOptions);
    }
    if (prevProps.isSave !== isSave && isSave) {
      delaySearchTariff(customTariffs);
    }
  }, [tariffOptions, tariffs, isSave]);

  const tariffsColumns = [
    {
      title: '#',
      className: 'col_admin_40',
      render: (data, record, index) => {
        const { id } = record;
        return <div>{id}</div>;
      },
    },
    {
      title: 'User',
      className: 'col_tariff_user',
      render: (data, record, index) => <RenderUserCard user={record} />,
    },
    {
      title: 'Tariff',
      className: 'col_admin_120',
      render: (data, record, index) => {
        const { tariffname, is_valid } = record;

        return is_valid ? (
          <div className="tariffs__name">
            <p>{tariffname}</p>
          </div>
        ) : null;
      },
    },
    {
      title: 'Date',
      className: 'col_admin_220',
      render: (data, record, index) => {
        const { ab_pstart, ab_pend, is_valid } = record;

        return is_valid ? (
          <div className="tariffs__date">
            <p>
              <span>
                {ab_pstart ? moment(ab_pstart).format('DD.MM.YYYY') : ''} /{' '}
              </span>
              <span>{ab_pend ? moment(ab_pend).format('DD.MM.YYYY') : ''}</span>
            </p>
          </div>
        ) : null;
      },
    },
    {
      title: 'Auto',
      className: 'col_admin_80',
      render: (data, record, index) => {
        const { tariffrenewal, is_valid, receipt_apple_id } = record;

        if (receipt_apple_id && is_valid) {
          return (
            <div className="tariffs__auto">
              <img src={imgAppleIcon} />
            </div>
          );
        }

        return is_valid ? (
          <div className="tariffs__auto">{tariffrenewal ? <div /> : null}</div>
        ) : null;
      },
    },
    {
      title: 'Storage',
      className: 'col_admin_160',
      render: (data, record, index) => {
        const { fullSpace, busySpace, is_valid } = record;

        return is_valid ? (
          <div className="tariffs__storage">
            <p>
              <span>{getStorageToGb(busySpace)}Gb / </span>
              <span>{getStorageToGb(fullSpace)}Gb</span>
            </p>
          </div>
        ) : null;
      },
    },

    {
      title: 'Edit',
      className: 'col_admin_80',
      render: (data, record, index) => {
        const { is_valid, receipt_apple_id } = record;
        const disabled = !!(is_valid && receipt_apple_id);

        return (
          <Button
            disabled={disabled}
            className={'btn--small'}
            kind={'aquamarine'}
            onClick={() => onToggleModalEditTariff(record)}
          >
            Edit
          </Button>
        );
      },
    },
  ];

  return (
    <div className="admin_panelWrapper">
      <div className="ap-tab-container">
        <div className="ap-row">
          <div className="ap-col ap-shift">
            <Input
              className=""
              onChangeText={e => onSetValueTariffs('search', e)}
              value={customTariffs.search}
              key={formkey}
              placeholder="Search"
            />
          </div>
          <div className="ap-input__col ap-col">
            <p className="ap-input__label">Search by</p>
            <div className="ap-input__elements">
              <Checkbox
                checked={customTariffs.id}
                onChange={e => onSetValueTariffs('id', e)}
              >
                id
              </Checkbox>
              <Checkbox
                checked={customTariffs.login}
                onChange={e => onSetValueTariffs('login', e)}
              >
                login
              </Checkbox>
            </div>
          </div>
        </div>
        <hr />
        <p className="ap-input__label">Users</p>
        <div>
          <Table
            className="table tarifflist"
            columns={tariffsColumns}
            dataSource={admin.userTariffList}
            pagination={false}
            rowKey="id"
            style={{ width: '100%' }}
            locale={{ emptyText: 'No data' }}
            rowClassName={'battle_row'}
            onRow={(record, index) => {}}
            loading={isSearch}
          />
          {admin.userTariffCount ? (
            <Pagination
              defaultCurrent={1}
              defaultPageSize={customTariffs.limit}
              total={admin.userTariffCount}
              onChange={onChangePageTariffs}
              current={customTariffs.page}
            />
          ) : null}
        </div>
      </div>

      {editTariff ? (
        <OBModal
          title={`Edit tariff for id #${editTariff.userid}`}
          visible={isVisibleEditTariff}
          okText="Save"
          cancelText="Cancel"
          onCancel={onToggleModalEditTariff}
          onOk={onSaveTariff}
          footer={
            <div className="modal_footer">
              <Button
                className={'btn--small'}
                bordered="true"
                kind={'aquamarine'}
                onClick={onToggleModalEditTariff}
              >
                Cancel
              </Button>
              <Button
                className={'btn--small'}
                kind={'aquamarine'}
                onClick={onSaveTariff}
                loading={isSave}
              >
                Save
              </Button>
            </div>
          }
        >
          <div className="modal-edit">
            <div className="ap-row">
              <div className="ap-col">
                <RenderUserCard user={editTariff} />
              </div>
            </div>
            <div className="ap-row">
              <div className="ap-col">
                <Select
                  value={
                    editTariff.tariffid && {
                      value: editTariff.tariffid,
                      label: editTariff.tariffname,
                    }
                  }
                  placeholder="Tariff"
                  onChange={onSelectTariff}
                  options={tariffOptions}
                />
              </div>
              <div className="ap-col">
                <Checkbox
                  checked={editTariff.tariffrenewal}
                  onChange={onSelectRenewalTariff}
                >
                  Renewal
                </Checkbox>
              </div>
            </div>
            <div className="ap-row">
              <div className="ap-col">
                <OBDatePicker
                  dateFormat="D MMM YYYY"
                  className="date-group__elements__picker"
                  placeholder="End date"
                  value={
                    editTariff.ab_pend && moment(editTariff.ab_pend.toString())
                  }
                  onChange={onSelectDateTariff}
                  disabledDate={current => current && current < moment()}
                />
              </div>
              <div className="ap-col">
                <Checkbox
                  checked={editTariff.remove_tariff}
                  onChange={onSelectDeleteTariff}
                >
                  Delete
                </Checkbox>
              </div>
            </div>
          </div>
        </OBModal>
      ) : null}
    </div>
  );
};

export default injectIntl(
  withRouter(
    connect(
      state => ({
        user: state.users.user,
        admin: state.admin,
        common: state.common,
        tariffs: state.billing.tariffs,
        isSearch: state.admin.processSearch,
        isSave: state.admin.processSetTariff,
      }),
      {
        getCurrencies,
        getSearchUserTariffs,
        getTariffList,
        setCommonNotification,
        setTariffByUser,
      },
    )(TariffsTab),
  ),
);
