import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import Trash from '-!svg-react-loader!../../../images/trash.svg'; // eslint-disable-line

import './style.scss';

const xml = require('xml-parse');

export default class NotificationInList extends Component {
  static propTypes = {
    message: PropTypes.string,
    created: PropTypes.string,
    id: PropTypes.number.isRequired,
    onUserClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
  };

  static defaultProps = {
    type: '',
    message: '',
    created: moment.now().toString(),
    userid: 0,
    onUserClick: () => {},
    onDeleteClick: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      notification: {
        fields: [],
      },
    };
  }

  componentDidMount() {
    const { message } = this.props;
    const notification = new xml.DOM(xml.parse(message));

    const type = notification.document.getElementsByTagName('type')[0].childNodes[0].text;
    let userid = 0;
    if (notification.document.getElementsByTagName('user')[0]) {
      userid = notification.document.getElementsByTagName('user')[0].attributes.id;
    }

    const content = [];
    notification.document.getElementsByTagName('content')[0].childNodes.forEach((child) => {
      if (child.text) {
        content.push({
          type: 'text',
          value: child.text,
        });
      } else {
        content.push({
          type: child.tagName,
          value: child.innerXML,
        });
      }
    });

    this.setState({
      notification: {
        fields: content,
        userid,
        color: this._color(type),
      },
    });
  }

  _color = (type) => {
    switch (type) {
      case 'accept':
      case 'gameAccept':
      case 'battleCancelOwner':
      case 'battleExitOwner':
        return 'green';
      case 'battleCancelCompetitor':
      case 'battleExitCompetitor':
      case 'decline':
        return 'red';
      default:
        return 'white';
    }
  };

  render() {
    const { created, onUserClick, onDeleteClick } = this.props;

    const { notification } = this.state;

    // console.log(this.state.notification);

    return (
      <div className="notification_list">
        <div className={notification.color} />
        <div className="notification_container">
          <div className="notification_header">
            <div className="notification_type">{'Battle'}</div>
            <div className="notification_time_n_trash">
              <div className="notification_time">
                {moment(created)
                  .format('D MMM - h:mm')
                  .toString()}
              </div>
              <Trash style={{ cursor: 'pointer' }} onClick={onDeleteClick} />
            </div>
          </div>
          <div className="notification_text">
            {_.map(notification.fields, (child, index) => (
              <span
                key={`${child.type}_${index}`}
                className={child.type}
                onClick={notification.userid ? onUserClick.bind(this, notification.userid) : () => false}
              >
                {child.value}
              </span>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
