import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FilterInput } from '../common/Input';
import Checkbox from '../common/Checkbox';
import Select from '../common/Select';
import './styles.scss';

const CHAT_LISTS = [
  {
    value: 'userlist',
    label: 'userlist',
  },
  {
    value: 'adminlist',
    label: 'adminlist',
  },
  {
    value: 'whitelist',
    label: 'whitelist',
  },
  {
    value: 'blacklist',
    label: 'blacklist',
  },
];

const pad = (val, len = 2) => `000${val}`.slice(-len);

const timestamp2date = (timestamp) => {
  const date = new Date(timestamp);
  return `${date.getUTCFullYear()}-${pad(date.getUTCMonth() + 1)}-${pad(date.getUTCDate())} ${pad(
    date.getUTCHours(),
  )}:${pad(date.getUTCMinutes())}:${pad(date.getUTCSeconds())}`;
};

class ChatCommonAPI extends Component {
  static propTypes = {
    values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    placeholders: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    callback: PropTypes.func.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    values: [],
    placeholders: [],
    name: 'submit',
    className: 'chat-common-api',
  };

  constructor(props) {
    super(props);
    this.size = props.placeholders.length;
    const values = props.values ? [...props.values] : new Array(this.size);
    this.state = { texts: values };
  }

  onChange = (e) => {
    const idx = +e.target.getAttribute('data-idx');
    const { texts: textsOld } = this.state;
    const texts = [...textsOld];
    texts[idx] = e.target.value;
    this.setState({ texts });
  };

  onClick = () => {
    const { callback } = this.props;
    const { texts } = this.state;
    callback(...texts);
    this.clear();
  };

  clear = () => {
    const texts = [];
    for (let i = 0; i < this.size; i += 1) {
      texts.push('');
    }
    this.setState({ texts });
  };

  render() {
    const { texts } = this.state;
    const { placeholders, name, className } = this.props;
    return (
      <div className={className}>
        {placeholders.map((inp, idx) => (
          <FilterInput
            className={`${className}__input`}
            key={idx}
            data-idx={idx}
            value={texts[idx]}
            type="text"
            placeholder={inp}
            onChange={this.onChange}
          />
        ))}
        <button className={`${className}__submit`} onClick={this.onClick}>
          {name}
        </button>
      </div>
    );
  }
}

class RoomList extends Component {
  static VALUES = [...CHAT_LISTS];

  static propTypes = {
    className: PropTypes.string,
    callback: PropTypes.func.isRequired,
    listName: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  };

  static defaultProps = {
    className: 'default-class',
    listName: RoomList.VALUES[0],
  };

  onChange = (option) => {
    const { callback } = this.props;
    callback(option);
  };

  render() {
    const { className, listName } = this.props;
    return (
      <div className={className}>
        <Select value={listName} onChange={this.onChange} options={RoomList.VALUES} />
      </div>
    );
  }
}

class AdminAPI extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    callback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: 'default-class',
  };

  constructor(props) {
    super(props);
    this.state = { userId: '', listName: RoomList.VALUES[0] };
  }

  onChangeInput = event => this.setState({ userId: event.target.value });

  onChangeSelect = option => this.setState({ listName: option });

  onClick = () => {
    const { callback } = this.props;
    const { userId, listName } = this.state;
    callback(userId, listName.value);
    this.clear();
  };

  clear = () => {
    this.setState({ userId: '' });
  };

  render() {
    const { userId, listName } = this.state;
    const { name, className } = this.props;
    return (
      <div className={className}>
        <FilterInput
          className={`${className}__input`}
          value={userId}
          type="text"
          placeholder="Enter user id"
          onChange={this.onChangeInput}
        />
        <RoomList className={`${className}__select`} listName={listName} callback={this.onChangeSelect} />
        <button className={`${className}__submit`} onClick={this.onClick}>
          {name}
        </button>
      </div>
    );
  }
}

class ChatVC extends Component {
  static propTypes = {
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        textMessage: PropTypes.string.isRequired,
        timestamp: PropTypes.number.isRequired,
        author: PropTypes.string.isRequired,
        authorId: PropTypes.number,
      }),
    ),
    users: PropTypes.arrayOf(
      PropTypes.shape({
        userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        userName: PropTypes.string,
        listName: PropTypes.string,
        lastseen: PropTypes.number,
      }),
    ),
    lists: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
    owner: PropTypes.shape({
      userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      userName: PropTypes.string,
    }),
    room: PropTypes.string,
    whitelistOnly: PropTypes.bool,
    historyMaxSize: PropTypes.number,
    historySize: PropTypes.number,
    historyTotal: PropTypes.number,
    isAdmin: PropTypes.bool,
    textMessage: PropTypes.string,
    renderMessages: PropTypes.func,
    renderUsers: PropTypes.func,
    roomAddToList: PropTypes.func.isRequired,
    roomRemoveFromList: PropTypes.func.isRequired,
    roomMessage: PropTypes.func.isRequired,
    roomSetWhitelistMode: PropTypes.func.isRequired,
    roomGetWhitelistMode: PropTypes.func.isRequired,
    roomHistoryGet: PropTypes.func.isRequired,
    roomHistoryInfo: PropTypes.func.isRequired,
    roomJoin: PropTypes.func.isRequired,
    roomLeave: PropTypes.func.isRequired,
    roomCreate: PropTypes.func.isRequired,
    roomGetUsers: PropTypes.func.isRequired,
    roomGetLists: PropTypes.func,
  };

  static defaultProps = {
    messages: [],
    users: [],
    lists: [...CHAT_LISTS],
    owner: null,
    isAdmin: false,
    whitelistOnly: false,
    historyMaxSize: 0,
    historySize: 0,
    historyTotal: 0,
  };

  constructor(props) {
    super(props);
    const { textMessage } = props;
    this.state = { textMessage };
  }

  componentDidMount() {
    const { roomGetLists } = this.props;
    if (typeof roomGetLists === 'function') {
      roomGetLists(RoomList.VALUES.map(this._mapList));
    }
  }

  _mapList = list => list.value;

  _mapMessage = (msg, i) => {
    const {
      author, authorId, textMessage: text, timestamp,
    } = msg;
    const date = timestamp2date(timestamp);
    return (
      <li
        key={i}
        className="chat-message"
        data-timestamp={timestamp}
        data-author={author}
        data-author-id={authorId}
        data-date={date}
      >
        <span className="chat-message__date">{date}</span>
        <span className="chat-message__author">{author}</span>
        <span className="chat-message__text">{text}</span>
      </li>
    );
  };

  renderMessages = () => {
    const { messages, renderMessages } = this.props;
    if (typeof renderMessages === 'function') {
      return renderMessages(messages);
    }
    return <ul className="message-list">{messages.map(this._mapMessage)}</ul>;
  };

  _onChangeInput = e => this.setState({ textMessage: e.target.value });

  onMessageSubmit = () => {
    const { textMessage } = this.state;
    const { roomMessage } = this.props;
    roomMessage({ textMessage });
  };

  renderInput = () => {
    const { textMessage } = this.state;
    return (
      <div className="message-input">
        <FilterInput
          className="message-input__text"
          value={textMessage}
          type="text"
          placeholder="Enter message here"
          onChange={this._onChangeInput}
        />
        <button className="message-input__submit" onClick={this.onMessageSubmit}>
          submit
        </button>
      </div>
    );
  };

  _mapUser = (user, idx) => {
    const {
      userId, userName, listName = 'userlist', timestamp = 0,
    } = user;
    return (
      <li className={`room-user-${listName}`} key={idx} data-user-name={userName} data-user-id={userId}>
        <span className="room-user__name">{userName}</span>
        <span className="room-user__id">{userId}</span>
        <span className="room-user__lastseen">{timestamp ? timestamp2date(timestamp) : 'never'}</span>
        <span className="room-user__listname">{listName}</span>
      </li>
    );
  };

  renderUsers = () => {
    const { renderUsers, users } = this.props;
    if (typeof renderUsers === 'function') {
      return renderUsers(users);
    }
    return <ul className="user-list">{users.map(this._mapUser)}</ul>;
  };

  renderAdminAPI = () => {
    const { room, roomAddToList, roomRemoveFromList } = this.props;
    return (
      <div className="room-admin-api" data-room={room}>
        <AdminAPI className="room-admin-api__adduser" name="Add user" callback={roomAddToList} />
        <AdminAPI className="room-admin-api__removeuser" name="Remove user" callback={roomRemoveFromList} />
      </div>
    );
  };

  renderCommonAPI = () => {
    const { room } = this.props;
    const className = 'common-api-item';
    const {
      roomHistoryGet,
      roomHistoryInfo,
      roomJoin,
      roomLeave,
      roomCreate,
      roomGetWhitelistMode,
      roomGetUsers,
    } = this.props;
    // TODO: make roomCreate with 2 args: roomName, whitelistMode
    return (
      <div className="common-api" data-room={room}>
        <ChatCommonAPI
          className={className}
          placeholders={['Enter start index', 'Enter end index']}
          values={[0, 20]}
          name="update history"
          callback={roomHistoryGet}
        />
        <ChatCommonAPI className={className} placeholders={[]} name="history info" callback={roomHistoryInfo} />
        <ChatCommonAPI
          className={className}
          placeholders={['Enter room name']}
          name="room create"
          callback={roomCreate}
        />
        <ChatCommonAPI className={className} placeholders={['Enter room name']} name="room join" callback={roomJoin} />
        <ChatCommonAPI className={className} placeholders={[]} name="room leave" callback={roomLeave} />
        <ChatCommonAPI className={className} placeholders={[]} name="get mode" callback={roomGetWhitelistMode} />
        <ChatCommonAPI className={className} placeholders={[]} name="get users" callback={roomGetUsers} />
      </div>
    );
  };

  renderInfo = () => {
    const {
      room, owner, whitelistOnly, historyTotal, historySize, historyMaxSize, roomSetWhitelistMode,
    } = this.props;
    const { userName: ownerName = 'unknown', userId: ownerId = '-1' } = owner || {};
    return (
      <div className="room-info">
        <span className="room-info__name">{room}</span>
        <span className="room-info__ownerid">{ownerId}</span>
        <span className="room-info__ownername">{ownerName}</span>
        <span className="room-info__historysize">{historySize}</span>
        <span className="room-info__historymaxsize">{historyMaxSize}</span>
        <span className="room-info__historytotal">{historyTotal}</span>
        <Checkbox className="room-info__mode" checked={whitelistOnly} small onChange={roomSetWhitelistMode}>
          whitelist mode
        </Checkbox>
      </div>
    );
  };

  render() {
    const { isAdmin } = this.props;
    return (
      <div className="chat-room">
        <div className="chat-room__center">
          {this.renderMessages()}
          {this.renderInput()}
        </div>
        <div className="chat-room__right">
          {this.renderInfo()}
          {isAdmin ? this.renderAdminAPI() : null}
          {this.renderCommonAPI()}
          {this.renderUsers()}
        </div>
      </div>
    );
  }
}

export default ChatVC;
