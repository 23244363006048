import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import defaultAvatar from './images/default.png';
import RussiaFlag from '-!svg-react-loader!../../../images/flags/russia.svg'; // eslint-disable-line
import './style.css';
import Flag from '../Flag';
import { AddFriendButton, Button } from '../Button';
import config from '../../../config';
import { UserByIdFetch, UserSearchFetch } from '../../../libs/reducers/actions';
import { injectIntl } from 'react-intl';
import RatingStars from '../RatingStars';
class Opponent extends Component {
  static propTypes = {
    userid: PropTypes.number,
    fullname: PropTypes.string,
    nickname: PropTypes.string,
    photo: PropTypes.string,
    rating: PropTypes.number,
    flag: PropTypes.oneOf(['russia', '']),
    rejectText: PropTypes.string,
    acceptText: PropTypes.string,
    onRejectClick: PropTypes.func,
    onAcceptClick: PropTypes.func,
    onMessageClick: PropTypes.func,
    onAddClick: PropTypes.func,
    style: PropTypes.shape(),
    fromGameStart: PropTypes.bool,
    avgscore: PropTypes.number,
    ratings: PropTypes.array,
    ratingsopen: PropTypes.bool,
  };

  
  static defaultProps = {
    photo: defaultAvatar,
    fullname: '',
    nickname: '',
    clickableLogin: true
  };
  constructor(props) {
    super(props);

    this.state = {
      visible: props.visible,
      ratingsopen: false,
    };
  };
  
  showModal = () => this.setState({ visible: true });

  handleCancel = () => {
    const { onClose } = this.props;
    this.setState({
      visible: false,
      ratingsopen: false,
    });
    onClose();
  };

  onRatingChange = () => {
    const ratingsopen = !this.state.ratingsopen
    this.setState({ ratingsopen })
    this.props.onRatingChange(ratingsopen)
  }

  render() {
    const {
      userid,
      fullname,
      nickname,
      photo,
      rejectText,
      acceptText,
      onRejectClick,
      onAcceptClick,
      onMessageClick,
      onAddClick,
      clickableLogin,
      style,
      flag,
      rating,
      className,
      fromGameStart,
      avgscore,
      ratings,
      intl,
    } = this.props;
    const messagingDisable = false;
    return (
      <div className="opponent" style={style} tabindex='0'>
        <div className="opponent__info">
          <div>
            <div className="opponent__avatar">
              <img className="opponent__image" src={photo ? `${config.FETCH.img}/${photo}` : defaultAvatar} alt="" />
              <div className="avgscore">{Math.round(+avgscore || 0)}</div>
              {flag && (
                <div className="opponent__flag">
                  <Flag name={flag} />
                </div>
              )}
            </div>
            <div className="opponent__name">
              <div className="opponent__fullname">{fullname}</div>
              <div
                className="opponent__nickname"
                onClick={() => {
                  if (clickableLogin) {
                    this.props.UserByIdFetch({ userid, disableChallenge: !!fromGameStart });
                  }
                }}
              >
                {nickname}
              </div>
              <div className='modal-user__rating' style={{ alignItems: 'flex-start', flexDirection: 'row' }} >
                {(rating !== null && <RatingStars rating={+rating || 0} height={12} width={12} />) || ''}
              {/* {
                ratings
                  ? <div className="opponent__rating">{this.createRatingSVG(rating)}<span className="opponent__rating__avg">{Math.round(rating)}</span>
                    <span className="opponent_feedback">
                      {intl.formatMessage({ id: 'Feedbacks', defaultMessage: 'Feedbacks' })}({ratings.length ? ratings.length : intl.formatMessage({ id: 'No', defaultMessage: 'No' })})
                    </span>
                  </div>
                  : null
              } */}
              </div>
            </div>
            {onMessageClick
              && onAddClick && (
                <div className="opponent__actions">
                  <Button
                    disabled={messagingDisable}
                    size="md"
                    icon="chatmessage"
                    kind="aquamarine"
                    bordered="true"
                    onClick={onMessageClick}
                  />
                  {/* <AddFriendButton size="md" icon="adduser" kind="aquamarine" bordered="true" userid={userid} /> */}
                </div>
            )}
          </div>
        </div>
        {onRejectClick
          && onAcceptClick && (
            <div className="opponent__buttons">
              <Button size="md" onClick={onRejectClick} className="opponent__button" kind="aquamarine" bordered="true" loading={this.props.isFetching}>
                {rejectText}
              </Button>
              <Button size="md" onClick={onAcceptClick} className="opponent__button" kind="aquamarine" loading={this.props.isFetching}>
                {acceptText}
              </Button>
            </div>
        )}
      </div>
    );
  }
}

export default injectIntl(
  connect(
    state => ({
      isFetching: (state.battles.battlesResponseToUser||{}).fetching || (state.battles.battlesResponseToOwner||{}).fetching || state.tournaments.isFetching
    }),
    dispatch => ({
      UserByIdFetch: bindActionCreators(UserByIdFetch, dispatch),
    }),
  )(Opponent),
);
