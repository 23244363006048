import React from 'react'
import { Modal } from 'antd';
import { connect } from 'react-redux';
import { intlShape, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { CheckEmailVerified, SendVerificationCodeEmail, UserLogout, UserUnregister } from '../../../../libs/reducers/actions';

import { Button } from '../../../common/Button';
import Input from '../../../common/Input';

const time = 30 * 1000;

class ModalEmailNotVerified extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: props.user.email,
      timeLeft: 0,
      allowSendEmail: !!this.sendAgain || true,
      sendVerification: false,
      error: '',
    };
  }

  static propTypes = {
    visible: PropTypes.bool.isRequired,
    intl: intlShape,
    timers: { starttime: null }
  };

  componentDidMount() {
    // if (this.props.timers.starttime && this.props.timers.starttime + time < +new Date()) {
    //   this.setState({ allowSendEmail: !!(this.props.timers.starttime && this.props.timers.starttime + time > +new Date()) }, () => {
    //     this.emailTimeout = setTimeout(() => {
    //       this.setState({ allowSendEmail: true });
    //     }, +new Date() - this.props.starttime + time)
    //   });
    // } else {
    //   this.timer();
    //   this.setState({ allowSendEmail: false });
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user && prevProps.user.email !== this.props.user.email) {
      this.setState({ email: this.props.user.email });
    }
    if (this.props.timers.starttime && prevProps.timers.starttime !== this.props.timers.starttime && this.props.timers.starttime + time - +new Date() >= 0) {
      this.timer();
    }
    if (!this.state.sendVerification && this.props.user.email && this.props.user.emailVerified === 0) {
      this.setState({ sendVerification: true });
      // Отправляем сообщение сразу при появлении сообщения (не при регистрации)
      this.props.SendVerificationCodeEmail({ email: this.props.user.email, checkDispatch: true });
    }
    if (prevProps.users.emailerror !== this.props.users.emailerror) {
      const error = this.props.users.emailerror
        ? this.props.intl.formatMessage({ id: 'emailAlreadyClaimed', defaultMessage: 'This email already claimed by another user' })
        : '';
      console.warn(error);
      this.setState({ error });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.emailTimeout);
  }

  // Вроде нигде не применяется
  checkTime = (state) => {
    const lost = Math.floor((this.props.timers.starttime + time - Date.now()) / 1000);
    if (lost >= 0)
      state = { ...state, timeLeft: lost >= 0 && lost }

    return state;
  }

  timer = () => {
    const left = this.props.timers.starttime + time - Date.now();

    if (left >= 0) {
      this.setState({ timeLeft: Math.floor(left / 1000) });
      setTimeout(() => this.timer(), 1000);
    } else {
      this.setState({ timeLeft: 0 });
    }
  }

  render() {
    const {
      intl,
      user,
      users,
      CheckEmailVerified,
      SendVerificationCodeEmail,
      timers,
      UserLogout,
      UserUnregister
    } = this.props;
    const { email } = this.state;
    const sendAgain = this.state.timeLeft > 0 ? false : true;

    return (
      <Modal
        title={intl.formatMessage({ id: 'IBANConfirmationText', defaultMessage: 'Confirmation' })}
        visible={this.props.visible}
        closable={false}
        footer={[
          <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
            <Button
              kind='transparent'
              size='s'
              style={{ color: '#f65932', textDecoration: 'underline', padding: 0 }}
              onClick={() => {
                UserLogout();
              }}
            >
              {intl.formatMessage({ id: 'log_out', defaultMessage: 'Log out' })}
            </Button>
            {users && users.reg && <Button
              kind='transparent'
              size='s'
              style={{ color: '#f65932', textDecoration: 'underline', padding: 0 }}
              onClick={() => {
                UserUnregister({ userid: user.id });
                UserLogout();
              }}
            >
              {intl.formatMessage({ id: 'Unregister', defaultMessage: 'Unregister' })}
            </Button>}
          </div>
        ]}
      >
        <div className='userAgreementText' style={{ paddingBottom: 0 }}>
          {!this.props.verificationError
            ? intl.formatMessage({ id: 'weHaveSendYouEmail', defaultMessage: 'We have sent you an email, please confirm your e-mail address' })
            : intl.formatMessage({ id: 'EmailVerificationFailed', defaultMessage: 'Email verification failed' })}
          <div className='emailVerif' style={{ paddingBottom: 20 }}>
            <Input
              type="email"
              className="emailVerif"
              onChangeText={(text) => { this.setState({ email: text }); }}
              value={this.state.email}
              placeholder={intl.formatMessage({ id: 'email', defaultMessage: 'E-mail' })}
              name="email"
              error={this.state.error}
            />
          </div>
          <div style={{ flexDirection: 'row', height: 40, display: 'flex', justifyContent: 'space-around' }}>
            <Button
              kind='aquamarine'
              key="submit"
              style={{ fontSize: 14, padding: 0 }}
              onClick={() => { CheckEmailVerified({ userid: user.id }); }}
            >
              {intl.formatMessage({ id: 'CheckVerifyCodeAgain', defaultMessage: 'Check' })}
            </Button>
            <Button
              kind='aquamarine'
              bordered="true"
              key="submit"
              style={{ marginLeft: 10, fontSize: 14, padding: 0 }}
              onClick={sendAgain
                ? () => {
                  if (!email || email === '') {
                    this.setState({ error: this.props.intl.formatMessage({ id: 'FieldBeEmpty', defaultMessage: 'The field must not be empty' }) });
                    return;
                  }
                  if (!email.includes('@')) {
                    this.setState({ error: this.props.intl.formatMessage({ id: 'EmailInvalid', defaultMessage: 'Invalid email' }) });
                    return;
                  }
                  SendVerificationCodeEmail({ email });
                }
                : () => { }
              }>
              {sendAgain 
                ? intl.formatMessage({ id: 'SendVerifyCodeAgain', defaultMessage: 'Send again' }) 
                : `${intl.formatMessage({ id: 'RepeatIn', defaultMessage: 'Repeat in' })} ${this.state.timeLeft} ${intl.formatMessage({ id: 'RepeatInSec', defaultMessage: 's' })}`}
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}

export default injectIntl(
  connect(
    state => ({
      timers: state.timers,
      user: state.users.user,
      verificationError: state.users.confirmEmailError,
      users: state.users,
    }),
    {
      CheckEmailVerified,
      SendVerificationCodeEmail,
      UserLogout,
      UserUnregister
    },
  )(ModalEmailNotVerified),
);