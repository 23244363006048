import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  ChangeLang,
  joinAsObserver,
  fetchVideoArchivesAll,
  exitGameObserver,
} from '../../libs/reducers/actions';
import { injectIntl } from 'react-intl';
import './style.scss';
import config from '../../config';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import MatchScore from '../matchScore'; 
import Flag from '../common/Flag';
import GameScore from '../common/GameScore';
import { Button } from '../common/Button';
import Checkbox from '../common/Checkbox';
import { VideosTabs } from '../videos';
import ChatClient from '../common/Chat';
import { changeSystemSquadChat } from '../../libs/reducers/chatRoomsReducer';
import ModalObserverSquadResult from './ModalObserverSquadResult';

class ObserverScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gameIndex: 0,
      bowlingcenters: [{}, {}],
      videoControl: { targetTime: null, update: false },
      updateVideo: Math.random(),
      pauseSwitch: false,
    };
  }

  componentDidMount() {
    if (this.props.tournamentid && this.props.socketState) {
      this.props.joinAsObserver({ tournamentid: this.props.tournamentid });
    }
    if (this.props.squadid && this.props.socketState) {
      this.props.joinAsObserver({ squadid: this.props.squadid });
    }
    if (this.props.squad) {
      this.setState({ gameIndex: this.props.squad.games.length-1 })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.endGame !== this.props.endGame && this.props.endGame) { this.props.history.push('/lobby') }
    if (this.props.socketState && this.props.squadid && ((prevProps.squadid !== this.props.squadid) || (prevProps.socketState !== this.props.socketState))) {
      this.props.joinAsObserver({ squadid: this.props.squadid });
    }
    if (this.props.socketState && this.props.tournamentid && ((prevProps.tournamentid !== this.props.tournamentid) || (prevProps.socketState !== this.props.socketState))) {
      this.props.joinAsObserver({ tournamentid: this.props.tournamentid });
    }
    if (this.props.squadid && ((this.props.userAllowed === true && this.props.position === 1 || this.props.position === 0) || (this.props.position == 1 && this.props.playersConnected && this.props.playersConnected !== prevProps.playersConnected))) {
      this.props.joinAsObserver({ squadid: this.props.squadid });
    }
    if (this.props.tournamentid && ((this.props.userAllowed === true && this.props.position === 1 || this.props.position === 0) || (this.props.position == 1 && this.props.playersConnected && this.props.playersConnected !== prevProps.playersConnected))) {
      this.props.joinAsObserver({ tournamentid: this.props.tournamentid });
    }
    if (this.props.connected && this.props.connected !== prevProps.connected) {
      this.updateArchives();
      setTimeout(() => {
        this.setState({ updateVideo: Math.random() });
      }, 1000);
    }
    if (this.props.squad && !prevProps.squad || this.props.squad && prevProps.squad && this.props.squad.games.length !== prevProps.squad.games.length) {
      this.setState({ gameIndex: this.props.squad.games.length-1 })
    }
  }

  updateArchives = () => {
    const streams = [];
    if (!this.props.squad) return
    this.props.squad.players.forEach(player => {
      player.location.cameras.forEach(camera => {
        streams.push(`/cam${camera.id}-0`)
      })
    })
    this.props.fetchVideoArchivesAll(streams)
  };

  renderCameras = () => <>
    <div className='CameraBox'>
      <VideosTabs
        videoControl={this.state.videoControl}
        key={this.state.updateVideo}
        token={`${encodeURIComponent(config.FETCH.fullUrl)}_${((this.props.user||{}).session||{}).sessionkey}`}
        archives={this.props.videos.archives}
        tournamentstart={this.props.squad.datestart}
        updateArchives={this.updateArchives}
        fetchVideoArchive={this.props.fetchVideoArchive}
        fetchVideoArchivesAll={this.props.fetchVideoArchivesAll}
        pauseSwitch={this.state.pauseSwitch}
        sources={this.props.squad.players.map(player => ({
          denied: player.denied,
          owner: `${player.firstname} ${player.lastname}`,
          streams: player.location.cameras.map(camera => ({
            url: `/cam${camera.id}-0`,
            type: camera.type,
          })),
        }))}
        showAll={this.props.squad.players.length > 0}
        noSwitch={this.props.squad.players.length === 1}
        hideControl
      />
    </div>
  </>

  renderChat = roomid => <>
    <div className="ChatBox">
      <Tabs>
        <TabList>
          <Tab>
            {this.props.intl.formatMessage({ id: 'ChatTitle', defaultMessage: 'Chat' })}
          </Tab>
          <Tab disabled>
            <Checkbox
              checked={this.props.systemSquadChat}
              small
              onChange={(checked) => this.props.changeSystemSquadChat(checked)}
            >
              {this.props.intl.formatMessage({ id: 'SystemMessages', defaultMessage: 'System messages' })}
            </Checkbox>
          </Tab>
        </TabList>
        <TabPanel>
          <ChatClient
            room={roomid}
            className="chat"
            withHeader={false}
            containerClass="chat-container non-battle-chat"
            systemMessages={this.props.systemMessages}
            squad={this.props.squad}
          />
        </TabPanel>
      </Tabs>
    </div>
  </>

  componentWillUnmount() {
    if (this.props.squadid && this.props.socketState) {
      this.props.exitGameObserver({ squadid: this.props.squadid });
    }
    if (this.props.tournamentid && this.props.socketState) {
      this.props.exitGameObserver({ tournamentid: this.props.tournamentid });
    }
  }

  render() {
    const { intl } = this.props;

    if (!this.props.squad) {
      if (this.props.position) {
        return <div>{`${' Your position in queue: ' + this.props.position}`}</div>
      } else {
        return <div>{`Loading...`}</div>
      }
    }

    const { squad } = this.props;
    const squadsWatcher = this.props.squadsWatcher;

    if (!squad.players.length) return <div>Something gone wrong</div>
    const countWatcher = squadsWatcher.length && squadsWatcher.find(s => squad.id === s.squadid).countWatcher
    const lastgame = squad.games.length - 1;

    return <div className='ObserverScreen'>
      <div className="header">
        <div className="left-buttons">
          <Button kind="aquamarine" size="md" onClick={this.props.history.goBack}>
            {intl.formatMessage({ id: 'ReturnToMyBattle', defaultMessage: 'Return to "My Battle"' })}
          </Button>
        </div>
        <div className="tournament-info">
          <div className="tournament-name">
            #{squad.id} {squad.tournamentname}
          </div>
          {squad.cost ?
          <div className="tournament-fee">
            {intl.formatMessage({ id: 'fee', defaultMessage: 'Fee' })} – {squad.cost}{squad.tournamentcurrencysymbol}
          </div>
          : null}
          <div className="tournament-wincount">
            {squad.tournamenttypeid !== 3
              ? intl.formatMessage({ id: 'table_wins_short', defaultMessage: 'Wins' })
              : intl.formatMessage({ id: 'games', defaultMessage: 'Games' })
            } - {squad.wincount}
          </div>
          {squad.prize && squad.tournamenttypeid !== 3 ?
          <div className="tournament-prize">
            {intl.formatMessage({ id: 'Prize', defaultMessage: 'Prize' })} – {squad.prize}{squad.tournamentcurrencysymbol}
          </div>
          : null}
          <div>
            {intl.formatMessage({ id: 'viewersCount', defaultMessage: 'Number of viewers' })} - {countWatcher || 0}
          </div>
        </div>
        <div className="right-buttons">
          <Button
            kind="aquamarine"
            size="md"
            onClick={() => {
              const choosenLang = this.props.lang === 'RU' ? 'EN' : 'RU';
              this.props.ChangeLang(choosenLang);
            }}
            >
            {this.props.lang === 'RU' ? 'EN' : 'RU'}
          </Button>
        </div>
      </div>
      <div className="scoring">
        <MatchScore
          isObserver
          players={squad.players}
        />
            </div>
      <div className="frameGrid">
        <Tabs selectedIndex={this.state.gameIndex} onSelect={index => this.setState({ gameIndex: index })}>
          <TabList>
            {squad.games.map((game, index) => <>
              <Tab key={`tab ${index}`}>
                {intl.formatMessage({ id: 'GameAtGameOrderContext', defaultMessage: 'Game' })} {index + 1}
              </Tab>
            </>)}
          </TabList>
          {squad.games.map((game, index) => <>
            <TabPanel key={`tabpanel ${index}`}>
              <div className={`game${index !== squad.games.length - 1 ? ' disabled' : ''}`}>
                {squad.players.map((player, index) => <>
                  <div className="player">
                    <div className="player-head">
                      <div className="names">
                        <div className="fullname">{`${player.firstname} ${player.lastname}`}</div>
                        <div className="login">{player.login}</div>
                      </div>
                      <div className="lane">
                        
                        {/* {`${player.location.city_name} - 
                        ${player.location.bcname}
                        ${intl.formatMessage({ id: 'LaneNumber', defaultMessage: 'Lane №'})}
                        ${player.location.linenumber}`} */}

												<div className="bowling"><Flag name={(player.location.city_name||'').toLowerCase()}/>{player.location.city_name} - {player.location.bcname}</div>
												<div>{intl.formatMessage({ id: 'LaneNumber', defaultMessage: 'Lane №'})} {player.location.linenumber}</div>
                      </div>
                    </div>
                    <GameScore
                      isOwner={index % 2}
                      onFrameSelect={() => {}}
                      frames={game.frames[player.id]}
                      currentFrame={squad.turnOrder.includes(player.id) ? (game.frames[player.id].find(frame => frame.shot1 === -1) || {}).framenumber : null}
                    />
                  </div>
                </>)}
              </div>
            </TabPanel>
          </>)}
        </Tabs>
      </div>
      {squad.players.length === 1 ?
        <div className={`Telecom`}>
          {!squad.closed ? this.renderCameras() : null}
          {this.renderChat(squad.tournamenttypeid === 3 ? `squad-chat-${squad.id}` : `Observe-squad-chat-${squad.id}`)}
        </div>
      :
        <>
          {!squad.closed ? this.renderCameras() : null}
          {this.renderChat(squad.tournamenttypeid === 3 ? `squad-chat-${squad.id}` : `Observe-squad-chat-${squad.id}`)}
        </>
      }
      <ModalObserverSquadResult
        visible={squad && squad.closed}
        squad={squad}
        onClose={() => this.props.history.goBack()}
        onShare={() => {}}
      />
    </div>
  }
}

export default injectIntl(
  withRouter(
    connect(
      state => ({
        user: state.users.user,
        socketState: state.events.socketConnected,
        tournamentid: state.observer.tournamentid,
        squadid: state.observer.squadid,
        squad: state.observer.squadstate,
        connected: state.observer.connected,
        bowlingcenters: state.bowlingcenters.bowlingcenters,
        videos: state.videos,
        systemMessages: state.chat.systemSquadChat,
        position: state.observer.positioninqueue,
        userAllowed: state.observer.userallowed,
        endGame: state.observer.endgame,
		    squadsWatcher: state.battles.squadsWatcher,
		    playersConnected: state.observer.playersconnected,
      }),
      {
        joinAsObserver,
        fetchVideoArchivesAll,
        ChangeLang,
        exitGameObserver,
        changeSystemSquadChat,
      },
    )(ObserverScreen),
  ),
  );