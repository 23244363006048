import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import Checkbox from '../../common/Checkbox';
import { FilterInput } from '../../common/Input';
import Select from '../../common/Select';
import SvgRefereeing from '-!svg-react-loader!../../../images/refereeing.svg'; // eslint-disable-line

class FilterMyBattles extends React.Component {
  static propTypes = {
    intl: intlShape,
    searchInput: PropTypes.string,
  };

  render() {
    const {
      searchInput,
      onChangeSearchInput,
      filterFrom,
      filterTo,
      selectOptions,
      From,
      To,
      checked,
      with_protest,
      withProtestChange,
      Reset,
      checkedChange,
      intl,
    } = this.props;
    return (
      <div className="account__mybattles__filters">
        <FilterInput
          placeholder={intl.formatMessage({ id: 'find_a_player', defaultMessage: 'Find a player' })}
          value={searchInput}
          onChange={(e) => {
            onChangeSearchInput(e);
          }}
        />
       {/* <div className="account__mybattles__filter__fee">{intl.formatMessage({ id: 'fee', defaultMessage: 'Fee' })}</div>*/}
        <div className="account__mybattles__filter__selects">
          <div className="account__mybattles__filter__selectfrom">
            <Select
              placeholder={intl.formatMessage({ id: 'from', defaultMessage: 'From' })}
              value={filterFrom}
              options={selectOptions}
              onChange={(e) => {
                From(e);
              }}
            />
          </div>
          <div className="account__mybattles__filter__selectto">
            <Select
              placeholder={intl.formatMessage({ id: 'to', defaultMessage: 'To' })}
              value={filterTo}
              options={selectOptions}
              onChange={(e) => {
                To(e);
              }}
            />
          </div>
        </div>
        <div className="account__mybattles__filter__checkbox">
          <Checkbox
            checked={checked}
            onChange={(e) => {
              checkedChange(e);
            }}
            small
          >
            <div className="account__mybattles__filter__checkbox__child">{intl.formatMessage({ id: 'MyBattlesTablePassed', defaultMessage: 'Passed' })}</div>
          </Checkbox>
        </div>
        <div className="account__mybattles__filter__with_protest">
          <Checkbox
            checked={with_protest}
            onChange={(e) => {
              withProtestChange(e);
            }}
            small
          >
            <div className="account__mybattles__filter__with_protest__child">
              <SvgRefereeing className="refereeing-svg"/>
              <span>
                {intl.formatMessage({ id: 'MyBattlesTableWithProtest', defaultMessage: 'With protest' })}
              </span>
            </div>
          </Checkbox>
        </div>
        <div>
          <Link
            className="account__mybattles__filter__link"
            to="#"
            onClick={(e) => {
              e.preventDefault();
              Reset();
            }}
          >
            {intl.formatMessage({ id: 'reset', defaultMessage: 'Reset' })}
          </Link>
        </div>
      </div>
    );
  }
}

export default injectIntl(FilterMyBattles);
