/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable arrow-parens */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { saveIBAN } from '../../../libs/reducers/actions';
import './style.scss';
import PersonalDataAddModal from './personal-data-add.modal.jsx';
import IbanValueCreateModal from './iban-value-create.modal.jsx';
import { toIbanEntity, toPersonalData } from './helpers/iban-helpers';

/**
 * @typedef {import('./helpers/iban-helpers').IbanEntity} IbanEntity
 */

/**
 * @typedef {{
 *  isVisible?: boolean;
 *  onCancel?: () => any;
 *  onClose?: () => any;
 *  iban?: IbanEntity
 *  saveIBAN?: (iban: IbanEntity) => any;
 * } & Pick<import('./iban-value-create.fragment').IbanValueCreateFragmentProps, 'type'>
 * } IbanCreateModalProps
 */

const MODALS = Object.freeze({
  ibanValue: 'ibanValue',
  personalData: 'personalData',
});

/**
 * @template T
 * @param {Extract<T, (...args: any) => any>} [fn]
 */
const call = (fn) => {
  if (typeof fn === 'function') {
    fn();
  }
};

/** @type {React.FC<IbanCreateModalProps>} */
export const IbanCreateModalFn = (props) => {
  const {
    isVisible, iban, type, onCancel, saveIBAN, onClose,
  } = props;
  const [activeModal, setActiveModal] = useState(MODALS.ibanValue);
  const [ibanValue, setIbanValue] = useState(iban && iban.iban);
  const [personalData, setPersonalData] = useState(toPersonalData(iban));
  /** @type {React.MutableRefObject<{ reset: () => void }>} */
  const ibanRef = useRef();
  /** @type {React.MutableRefObject<{ reset: () => void }>} */
  const dataRef = useRef();
  useEffect(() => {
    if (!isVisible) {
      setActiveModal(MODALS.ibanValue);
      if (ibanRef.current && ibanRef.current.reset) {
        ibanRef.current.reset();
      }
      if (dataRef.current && dataRef.current.reset) {
        dataRef.current.reset();
      }
    }
  }, [isVisible]);
  return <>
    <IbanValueCreateModal
      type={type}
      isVisible={isVisible && activeModal === MODALS.ibanValue}
      value={ibanValue}
      setRef={(inst) => {
        ibanRef.current = inst;
      }}
      onAdd={(value) => {
        setIbanValue(value);
        setActiveModal(MODALS.personalData);
      }}
      onCancel={() => {
        call(onCancel);
        call(onClose);
      }}
    />
    <PersonalDataAddModal
      isVisible={isVisible && activeModal === MODALS.personalData}
      resetOnClose
      {...personalData}
      setRef={(inst) => {
        dataRef.current = inst;
      }}
      onAdd={(data) => {
        setPersonalData(data);
        if (saveIBAN) {
          saveIBAN(toIbanEntity(ibanValue, data, type));
        }
        call(onClose);
      }}
      onCancel={() => {
        call(onCancel);
        call(onClose);
      }}
    />
  </>;
};

class IbanCreateModalClass extends React.Component {
  render() {
    return <IbanCreateModalFn {...this.props} />;
  }
}

/** @type {IbanCreateModalFn} */
const IbanCreateModal = connect(
  () => ({}),
  { saveIBAN },
)(IbanCreateModalClass);

export default IbanCreateModal;
