import { call, put } from 'redux-saga/effects';
import config from '../../config';
import { get as methodGET, post as methodPOST } from '../Networking';
import takeLeading from '../saga/tools/takeLeading';

export const rateStatusRequestAction = () => ({ type: 'RATE_STATUS_REQUEST' });
/** @param {boolean} showRate */
export const rateStatusSuccessAction = (showRate) => ({ type: 'RATE_STATUS_SUCCESS', params: { showRate } });
/** @param {string} [error] */
export const rateStatusFailureAction = (error) => ({ type: 'RATE_STATUS_FAILURE', params: error });

export const rateRatingRequestAction = () => ({ type: 'RATE_RATING_REQUEST' });
export const rateRatingSuccessAction = () => ({ type: 'RATE_RATING_SUCCESS' });
/** @param {string} [error] */
export const rateRatingFailureAction = (error) => ({ type: 'RATE_RATING_FAILURE', params: error });

export const rateModalRequestAction = () => ({ type: 'RATE_MODAL_REQUEST' });
/** @param {boolean} visible */
export const rateModalSetVisibleAction = (visible) => ({ type: 'RATE_MODAL_SET_VISIBLE', params: { visible } });
/** @param {boolean} hidden */
export const rateModalSetHiddenAction = (hidden) => ({ type: 'RATE_MODAL_SET_HIDDEN', params: { hidden } });

export const rateDontShowRequestAction = () => ({ type: 'RATE_DONT_SHOW_REQUEST' })
export const rateDontShowSuccessAction = () => ({ type: 'RATE_DONT_SHOW_SUCCESS' })
/** @param {string} [error] */
export const rateDontShowFailureAction = (error) => ({ type: 'RATE_DONT_SHOW_FAILURE', params: error })

export const rateRemindLaterRequestAction = () => ({ type: 'RATE_REMIND_LATER_REQUEST' });
export const rateRemindLaterSuccessAction = () => ({ type: 'RATE_REMIND_LATER_SUCCESS' });
/** @param {string} [error] */
export const rateRemindLaterFailureAction = (error) => ({ type: 'RATE_REMIND_LATER_FAILURE', params: error });

export const initialRateState = {
  status: {
    fetching: false,
    error: false,
    showRate: false,
  },
  rating: {
    fetching: false,
    error: false,
  },
  dontShow: {
    fetching: false,
    error: false,
  },
  remind: {
    fetching: false,
    error: false,
  },
  modal: {
    visible: false,
    hidden: true,
  },
};

/**
 * @returns {typeof initialRateState}
 */
export const rateReducer = (state = initialRateState, action) => {
  switch (action.type) {
    case 'RATE_STATUS_REQUEST':
      return {
        ...state,
        status: {
          ...state.status,
          fetching: true,
          error: false,
        },
      };
    case 'RATE_STATUS_FAILURE':
      return {
        ...state,
        status: {
          ...state.status,
          fetching: false,
          error: true,
        },
      };
    case 'RATE_STATUS_SUCCESS':
      return {
        ...state,
        status: {
          ...state.status,
          fetching: false,
          showRate: action.params.showRate,
        },
      };
    case 'RATE_RATING_REQUEST':
      return {
        ...state,
        rating: {
          ...state.rating,
          fetching: true,
          error: false,
        },
      };
    case 'RATE_RATING_SUCCESS':
      return {
        ...state,
        rating: {
          ...state.rating,
          fetching: false,
        },
      };
    case 'RATE_RATING_FAILURE':
      return {
        ...state,
        rating: {
          ...state.rating,
          fetching: false,
          error: true,
        },
      };
    case 'RATE_DONT_SHOW_REQUEST':
      return {
        ...state,
        dontShow: {
          ...state.dontShow,
          fetching: true,
          error: false,
        },
      };
    case 'RATE_DONT_SHOW_SUCCESS':
      return {
        ...state,
        dontShow: {
          ...state.dontShow,
          fetching: false,
        },
      };
    case 'RATE_DONT_SHOW_FAILURE':
      return {
        ...state,
        dontShow: {
          ...state.dontShow,
          fetching: false,
          error: true,
        },
      };
    case 'RATE_REMIND_LATER_REQUEST':
      return {
        ...state,
        remind: {
          ...state.remind,
          fetching: true,
          error: false,
        },
      };
    case 'RATE_REMIND_LATER_SUCCESS':
      return {
        ...state,
        remind: {
          ...state.remind,
          fetching: false,
        },
      };
    case 'RATE_REMIND_LATER_FAILURE':
      return {
        ...state,
        remind: {
          ...state.remind,
          fetching: false,
          error: true,
        },
      };
    case 'RATE_MODAL_REQUEST':
      return {
        ...state,
        modal: {
          ...state.modal,
          visible: false,
        },
      };
    case 'RATE_MODAL_SET_VISIBLE':
      return {
        ...state,
        modal: {
          ...state.modal,
          visible: action.params.visible,
        },
      };
    case 'RATE_MODAL_SET_HIDDEN':
      return {
        ...state,
        modal: {
          ...state.modal,
          hidden: action.params.hidden,
        },
      };
    default:
      return state;
  }
}

export function* rateStatusSaga() {
  try {
    const res = yield call(methodGET, { url: `${config.FETCH.url}/rate/status` });
    if (res.status === 200 && res.data.success) {
      yield put(rateStatusSuccessAction(res.data.showRate));
      return res.data.showRate;
    } else {
      yield put(rateStatusFailureAction());
    }
  } catch (e) {
    yield put(rateStatusFailureAction(e.message));
  }
  return true;
}

export function* rateModalSaga() {
  try {
    // проверяем статус перед предложением оценить приложение
    const showRate = yield call(rateStatusSaga)
    // если уже оценено, то не показываем модалку
    yield put(rateModalSetVisibleAction(showRate));
  } catch (e) {
    yield put(rateModalSetVisibleAction(false));
  }
}

export function* rateRatingSaga() {
  try {
    // оповещаем сервер, что приложение оценено
    const res = yield call(methodPOST, { url: `${config.FETCH.url}/rate/rating` });
    if (res.status === 200) {
      yield put(rateRatingSuccessAction());
    } else {
      yield put(rateRatingFailureAction());
    }
  } catch (e) {
    yield put(rateRatingFailureAction(e.message));
  }
}

export function* rateDontShowSaga() {
  try {
    // больше никогда не показывать модалку "оценить"
    const res = yield call(methodPOST, { url: `${config.FETCH.url}/rate/dontshow` });
    if (res.status === 200) {
      yield put(rateDontShowSuccessAction());
    } else {
      yield put(rateDontShowFailureAction());
    }
  } catch (e) {
    yield put(rateDontShowFailureAction(e.message));
  }
}

export function* rateRemindLaterSaga() {
  try {
    // напомнить об оценке позже
    const res = yield call(methodPOST, { url: `${config.FETCH.url}/rate/remindlater` });
    if (res.status === 200) {
      yield put(rateRemindLaterSuccessAction());
    } else {
      yield put(rateRemindLaterFailureAction());
    }
  } catch (e) {
    yield put(rateRemindLaterFailureAction(e.message));
  }
}

export function* watchRate() {
  yield takeLeading('RATE_STATUS_REQUEST', rateStatusSaga);
  yield takeLeading('RATE_RATING_REQUEST', rateRatingSaga);
  yield takeLeading('RATE_MODAL_REQUEST', rateModalSaga);
  yield takeLeading('RATE_DONT_SHOW_REQUEST', rateDontShowSaga);
  yield takeLeading('RATE_REMIND_LATER_REQUEST', rateRemindLaterSaga);
}
