import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import './style.scss';

import {getActiveUserCount, getMaxWatcherCount, saveMaxWatcherCount} from '../../../libs/reducers/actions';

import NewTable from "../../common/Table";
import {getAnalyticsBowlingCenter} from "../../../libs/reducers/adminReducer";
import moment from "moment";
import OBDatePicker from "../../common/DatePicker";
import {Button} from "../../common/Button";

const Analytics = ({ getActiveUserCount, getMaxWatcherCount, maxWatcherCount, getAnalyticsBowlingCenter, analyticsBc, intl }) => {

  const [dateFrom, setDateFrom] = useState(null)
  const [dateTo, setDateTo] = useState(null)

 const ref = useRef(Math.random())
  useEffect(() => {
    getAnalyticsBowlingCenter();
  }, []);

  const analyticsColumns = [
    {
      title: 'BC Name',
      dataIndex: 'squadid',
      key: 'squadid',
      width: '300',
      render: (data, record, index) => {
        return (
            <div>
              <p>{record.bc_name || record.ext_bc_name}</p>
            </div>
          )
      }
    },
    {
      title: 'Platform',
      dataIndex: 'battlelistfilter_player',
      key: 'battlelistfilter_player_1',
      width: '200',
      render: (data, record, index) => {
        return (
          <p>{record.ext_bc_name === null && record.bc_name === null ? 'undefined' : record.ext_bc_name === null ? 'TPC' : '-'}</p>
        )
      }
    },
    {
      title: 'Unique Members',
      dataIndex: 'battlelistfilter_player',
      key: 'battlelistfilter_player_1',
      width: '200',
      render: (data, record, index) => {
        return (
          <p>{record.players_all}</p>
        )
      }
    },
    {
      title: 'Ended Game',
      dataIndex: 'battlelistfilter_player',
      key: 'battlelistfilter_player_2',
      width: '200',
      render: (data, record, index) => {
        return (
          <p>{record.gameended}</p>
        )
      }
    },
    {
      title: 'Count Game',
      dataIndex: 'battlelistfilter_player',
      key: 'battlelistfilter_player',
      width: '200',
      render: (data, record, index) => {
        return (
          <p>{record.squad_started}</p>
        )
      }
    },
  ]
  const sendDataAnalytic = () => {
    getAnalyticsBowlingCenter({dateFrom: dateFrom.toISOString(), dateTo: dateTo.toISOString()});
  }

  return (
    <div className='analytic_container'>
      <div className='analyticDate_container'>
        <OBDatePicker
          className="date-group__elements__picker"
          placeholder={'Date From'}
          value={dateFrom}
          onChange={(dateFrom) => setDateFrom(dateFrom)}
        />
        <OBDatePicker
          className="date-group__elements__picker"
          placeholder={'Date To'}
          value={dateTo}
          onChange={(dateTo) => setDateTo(dateTo)}
          disabledDate={current => current && current < dateFrom}
        />
        <Button size={'md'} onClick={() => sendDataAnalytic()} className='btnAnalyticsBc'>
          <p>Send Date</p>
        </Button>
      </div>
      <NewTable
        columns={analyticsColumns}
        dataSource={analyticsBc.results || []}
        pagination={true}
        defaultPageSize={10}
        onChange={() => console.log('')}
      />
      <div className='analyticSum_container'>
        <p className='p_wrapper-UniqueMember'>{analyticsBc.sumPlayer}</p>
        <p className='p_wrapper-EndedGame'>{analyticsBc.sumGameEnded}</p>
        <p className='p_wrapper-CountGame'>{analyticsBc.sumGames}</p>
      </div>
    </div>
  )
}

export default connect(state => ({
    userActiveCount: state.users.userActiveCount,
    maxWatcherCount: state.users.maxWatcherCount,
    analyticsBc: state.admin.analyticsBc
  }),
  {
    getActiveUserCount,
    getMaxWatcherCount,
    saveMaxWatcherCount,
    getAnalyticsBowlingCenter,
  }
)(Analytics);
