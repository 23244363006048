/* eslint-disable react/prop-types */
import React from 'react';
import { injectIntl } from 'react-intl';
import OBModal from '../Modal';
import { Button } from '../Button';

const IbanDeleteModal = ({
  intl, isVisible, onSubmit, onCancel, method,
}) => {
  const modalTitle = method === 'iban' ? intl.formatMessage({ id: 'IBANDeleteModalTitle', defaultMessage: 'IBAN delete' }) : 'PayPal delete';
  const modalText = method === 'iban'
    ? 'Are you sure you want to delete IBAN from your account?'
    : 'Are you sure you want to delete PayPal from your account?';
  return (
    <OBModal
      title={modalTitle}
      visible={isVisible}
      onSubmit={onSubmit}
      onCancel={onCancel}
      footer={[
        <div key="buttons" className="dialog__buttons">
          <Button
            kind="aquamarine"
            bordered="true"
            onClick={onCancel}
            className="dialog__button-decline"
          >
            {intl.formatMessage({
              id: 'CancelAsAbortAlias',
              defaultMessage: 'Cancel',
            })}
          </Button>
          <Button
            onClick={onSubmit}
            kind="aquamarine"
            className="dialog__button-accept"
          >
            {intl.formatMessage({
              id: 'Accept',
              defaultMessage: 'Accept',
            })}
          </Button>
        </div>,
      ]}
    >
      <p className="CreditCardDeleteText">{modalText}</p>
    </OBModal>
  );
};


export default injectIntl(IbanDeleteModal);
