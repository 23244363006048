import React from 'react';

const TextTD = ({text}) => {
    return (
        <div className={'referral-table__text-td'}>
            {text}
        </div>
    );
};

export default TextTD;
