import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import * as _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';
import './style.scss';
import NotificationInList from '../common/NotificationInList';
import Trash from '-!svg-react-loader!../../images/trash.svg'; // eslint-disable-line

import { UserByIdFetch } from '../../libs/reducers/actions';
import { getNotificationsFetch, removeNotificationFetch } from '../../libs/reducers/notifications.reducer';

import noNotifications from '../../images/no_notifications_found.png';

// const xml = require('xml-parse');

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static propTypes = {
    notifications: PropTypes.array,
    intl: intlShape,
  };

  componentDidMount() {
    this.props.getNotificationsFetch({ read: 'all' });
  }

  shouldComponentUpdate(nextProps) {
    return (
      !_.isEqual(this.props.notifications, nextProps.notifications)
      || !_.isEqual(this.props.socketNotifications, nextProps.socketNotifications)
    );
  }

  removeNotification = (id) => {
    // delete notification
    this.props.removeNotificationFetch({ id });
  };

  deleteAllNotifications = () => {
    // clear notifications
    this.props.removeNotificationFetch();
  };

  handleUserClick = (userid) => {
    this.props.UserByIdFetch({ userid });
  };

  onInviteBattleSubmit = ({
    betType, bet, winCount, dateType, date, time, comment, opponent,
  }) => {
    const { _createBattle } = this.props;
    const sendParams = { name: 'battle' };
    sendParams.wincount = winCount;

    if (betType === 2) {
      sendParams.cost = bet;
    }
    if (dateType === 2) {
      let datestart;
      if (time !== null) {
        datestart = moment(date.set('h', time.hour()).set('m', time.minute()));
      } else {
        datestart = moment(date);
      }
      sendParams.datestart = moment(datestart).toISOString();
    }
    if (comment !== '') {
      sendParams.rules = comment;
    }
    sendParams.userid = opponent.id;
    _createBattle({
      ...sendParams,
    });
  };

  render() {
    const { notifications, socketNotifications, intl } = this.props;

    let allNotifications = _.reverse([...notifications, ...socketNotifications]);
    allNotifications = _.uniqBy(allNotifications, 'id');
    return (
      <div className="notifications">
        <div className="menu-header">
          <div className="header_text">{intl.formatMessage({ id: 'NotificationTabHeader', defaultMessage: 'Notifications' })}</div>
          <Trash className="header_trash" onClick={this.deleteAllNotifications} />
        </div>
        <div className="notifications_container">
          {allNotifications.length ? (
            allNotifications.map(notification => (
              <NotificationInList
                key={-notification.id}
                message={notification.message}
                created={notification.created}
                id={notification.id}
                onDeleteClick={this.removeNotification.bind(this, notification.id)}
                onUserClick={this.handleUserClick}
              />
            ))
          ) : (
            <div className="notifications__empty">
              <div className="notifications__no_data_found">
                <div className="notifications__no_data_found__image">
                  <img src={noNotifications} alt="" />
                </div>
                <div className="notifications__no_data_found__description">
                  {intl.formatMessage({ id: 'NotificationTabEmptyContent', defaultMessage: 'You have no notifications yet.' })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl(withRouter(
  connect(
    state => ({
      notifications: state.notifications.notifications.data,
      socketNotifications: state.notifications.socketNotifications,
    }),
    { removeNotificationFetch, getNotificationsFetch, UserByIdFetch },
  )(Notifications),
));
