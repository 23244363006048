/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { FilterInput } from '../../common/Input';
import { Button } from '../../common/Button';
import CreateTournamentModal from '../../common/modals/modalCreateTournament';
import {
  DeleteTournament,
  requestTournamentInfo,
  getPermissions,
  addUserToTournament,
  deleteUserFromTournament,
} from '../../../libs/reducers/actions';
import { useGameCostText } from '../../GameCost/useGameCostText';

function getUA() {
  return window && window.navigator ? window.navigator.userAgent : '';
}
function isSafari() {
  return getUA().indexOf('Safari') !== -1 && !isChrome() && !isAndroid();
}
function isChrome() {
  return getUA().indexOf('Chrome') !== -1;
}
function isAndroid() {
  return getUA().indexOf('Android') !== -1;
}

export const statusTextDisplay = (record, intl) => {
  let str;
  if (record.cancelled === 0) {
    if (record.closed === null) {
      const now = moment().unix();
      if (now < moment(record.regdatestart).unix()) {
        str = <span className="status-soonStarted ">{
          intl.formatMessage({ id: 'TournamentTableStatusSoonStart', defaultMessage: 'Starting soon' })
      }</span>;
      }
      if (now > moment(record.regdatestart).unix() && now < moment(record.regdateend).unix()) {
        str = <div className="status-continious">{
          intl.formatMessage({ id: 'TournamentTableStatusInProcess', defaultMessage: 'The game is on' })
      }</div>;
      }
      if (now > moment(record.regdateend).unix()) {
        str = <span className="status-finished ">{
          intl.formatMessage({ id: 'TournamentTableStatusFinished', defaultMessage: 'Finished' })
        }</span>;
      }
    }
  }
  return str;
};

export const optionsTypes = [
  { value: 'team', label: 'Team' },
  { value: 'personal', label: 'Individual' },
  { value: 'league', label: 'League' },
];

export const optionsStatuses = [
  { value: 'announced', label: 'Announced' },
  { value: 'registration', label: 'Registration' },
  { value: 'registrationOver', label: 'Registration closed' },
  { value: 'gameOn', label: 'The game continues' },
  { value: 'completed', label: 'Finished' },
  { value: 'canceled', label: 'Cancelled' },
];

class TournamentsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tournaments: props.tournaments || [],
      filterType: '',
      filterInput: '',
      filterStatus: '',
      clickedRow: props.clickedRow || 0,
      clickedTournament: props.clickedTournament || 0,
      loadedData: false,
      createBattleModal: false,
      oldinfo: false,
    };
  }

  static propTypes = {
    tournaments: PropTypes.array,
    clickedRow: PropTypes.number,
    clickedTournament: PropTypes.object,
    onRowClick: PropTypes.func,
  };

  proceedText = this.props.intl.formatMessage({ id: 'ProceedToTournamentPage', defaultMessage: 'Go over' });

  columns = [
    {
      title: this.props.intl.formatMessage({ id: 'NameAsLabelAlias', defaultMessage: 'Name' }),
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (data, record, index) => {
        return {
          props: {},
          children: (
            <span class='innerText'>{data}</span>
          ),
        };
      },
    },
    {
      title: this.props.intl.formatMessage({ id: 'TournamentTableDateStart', defaultMessage: 'Date\nstart' }),
      render: (data, record, index) => {
        return {
          props: {},
          children: (
            <div className="date">
              { record.regdatestart ? `${moment(new Date(record.regdatestart)).format('DD MMM - HH:mm')}` : '' }
            </div>
          ),
        }
      },
    },
    {
      title: this.props.intl.formatMessage({ id: 'TournamentTableDateEnd', defaultMessage: 'Date\nend' }),
      render: (data, record, index) => {
        return {
          props: {},
          children: (
            <div className="date">
              { record.regdateend ? `${moment(new Date(record.regdateend)).format('DD MMM - HH:mm')}` : '' }
            </div>
          ),
        }
      },
    },
    {
      title: this.props.intl.formatMessage({ id: 'DescriptionAsAdditionalInfoAlias', defaultMessage: 'Description' }),
      dataIndex: 'rules',
      sorter: (a, b) => {(a.rules || '').localeCompare(b.rules || '')},
      render: (data, record, index) => {
        return {
          props: {},
          children: (
            data ? <div style={{
              maxWidth: '300px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}>
              <div onClick={() => { this.props.onInfoClick(record) }} className='infoPin'>
                info
              </div>
              <span class='innerText'>{data} </span>
            </div> : null
          ),
        };
      },
    },
   /* {
      title: this.props.intl.formatMessage({ id: 'TournamentTableCost', defaultMessage: 'Cost' }),
      width: 80,
      render: (data, record, index) => {
        return {
          props: {},
          children: (
            <>
              {useGameCostText(record)}
            </>
          ),
        }
      },
    },*/
    {
      title: this.props.intl.formatMessage({ id: 'StatusAsStateAlias', defaultMessage: 'Status' }),
      render: (data, record, index) => {
        return statusTextDisplay(record, this.props.intl);
      },
    },
    {
      title: '',
      dataIndex: '',
      width: 300,
      render: (data, record, index) => {
        return {
          children: (
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: 375 }}>
              {/* {record.tournamenttypeid === 3 && !record.players.find(p => p.userid===this.props.user.id) || record.players.find(p => p.userid===this.props.user.id) && record.players.find(p => p.userid===this.props.user.id).confirmedbyuser===0 ?
                <Button
                  kind="aquamarine"
                  size="md"
                  style={{ minWidth: 150 }}
                  onClick={() => this.props.addUserToTournament({ userid: this.props.user.id, tournamentid: record.id, refetch: true })}>
                    JOIN TOURNAMENT
                </Button>
                : null} */}
              {/* {record.tournamenttypeid === 3 && !!record.players.find(p => p.userid===this.props.user.id) && record.players.find(p => p.userid===this.props.user.id).confirmedbyuser===1 ?
                <Button
                  kind="aquamarine"
                  size="md"
                  style={{ minWidth: 150 }}
                  onClick={() => this.props.deleteUserFromTournament({ userid: this.props.user.id, tournamentid: record.id, refetch: true })}>
                    LEAVE TOURNAMENT
                </Button>
                : null} */}
              <Button
                kind="aquamarine"
                size="md"
                style={{ minWidth: 88 }}
                onClick={() => {
                  this.props.requestTournamentInfo(record.id);
                  this.props.switchTournament(record);
                }
              }>
                {this.proceedText}
              </Button>
              {this.props.permissions.includes('TOURNAMENTS_MODIFY_TOURNAMENTS') ? <Button kind="aquamarine" icon={"edit"} bordered="true" onClick={() => {this.setState({ oldinfo: {...record, key:Math.random()}, createBattleModal: true })}}/> : null}
              {this.props.permissions.includes('TOURNAMENTS_DELETE_TOURNAMENTS') ? <Button kind="aquamarine" icon={"tray"} loading={this.props.deleteFetch} bordered="true" onClick={() => {this.props.DeleteTournament({tournamentid: record.id})}}/> : null}
            </div>
          ),
          props: {

          },
        };
      },
    },
  ];

  componentDidMount() {
    this.props.getPermissions();
  }

  componentDidUpdate() {
    this.columns[0].title = this.props.intl.formatMessage({ id: 'NameAsLabelAlias', defaultMessage: 'Name' });
    this.columns[1].title = this.props.intl.formatMessage({ id: 'TournamentTableDateStart', defaultMessage: 'Date\nstart' });
    this.columns[2].title = this.props.intl.formatMessage({ id: 'TournamentTableDateEnd', defaultMessage: 'Date\nend' });
    this.columns[3].title = this.props.intl.formatMessage({ id: 'DescriptionAsAdditionalInfoAlias', defaultMessage: 'Description' });
    /*this.columns[4].title = this.props.intl.formatMessage({ id: 'TournamentTableCost', defaultMessage: 'Cost' });*/
    this.columns[4].title = this.props.intl.formatMessage({ id: 'StatusAsStateAlias', defaultMessage: 'Status' });
    this.proceedText = this.props.intl.formatMessage({ id: 'ProceedToTournamentPage', defaultMessage: 'Go over' });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = { ...prevState }
    if (nextProps.tournaments && !prevState.loadedData) {
      state = {
        ...state,
        tournaments: nextProps.tournaments,
        clickedTournament: nextProps.tournaments[0],
        loadedData: true,
      };
    }
    if (nextProps.clickedRow !== prevState.clickedRow) {
      state = { ...state, clickedRow: nextProps.clickedRow };
    }
    if (
      prevState.filterInput || prevState.filterStatus
    ) {
      let tempTournaments = [...nextProps.tournaments];
      if (prevState.filterInput !== '') {
        tempTournaments = tempTournaments.filter(tournament => tournament.name.toLowerCase().includes(prevState.filterInput.toLowerCase()));
      }
      state = { ...state, tournaments: tempTournaments, clickedTournament: tempTournaments[0] };
    }
    if (nextProps.tournaments && nextProps.tournaments.length) {
      state = { ...state, tournaments: nextProps.tournaments };
    }
    return state;
  }

  onReset = (e) => {
    e.preventDefault();
    this.setState({
      filterInput: '',
      filterStatus: '',
    });
  };

  render() {
    const { intl } = this.props
    return (
      <div>
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid rgba(100, 100, 100, 0.3)',
              paddingBottom: '5px',
              flexWrap: 'wrap',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                marginLeft: 20,
              }}
            >
              <div style={{ marginRight: 20, marginBottom: 5 }}>
                <FilterInput
                  value={this.state.filterInput}
                  style={{ width: '240px' }}
                  placeholder={intl.formatMessage({id: 'MyTournamentsFindTournament', defaultMessage: 'Find a tournament'})}
                  onChange={(e) => {
                    this.setState({
                      filterInput: e.target.value,
                    });
                  }}
                  />
              </div>
            </div>
            <Link
              to="#"
              onClick={this.onReset}
              style={{
                color: '#f65932',
                opacity: 1,
                fontWeight: 200,
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
                marginBottom: 5,
                marginLeft: 20,
                fontSize: 14,
              }}
              >
              {intl.formatMessage({ id: 'reset', defaultMessage: 'Reset' })}
            </Link>
            {this.props.permissions.includes('TOURNAMENTS_CREATE_TOURNAMENTS') ? <><Button
              className='createTourney__button'
              onClick={() => this.setState({ oldinfo: false, createBattleModal: true })}
              >
              {intl.formatMessage({ id: 'CreateTournament', defaultMessage: 'Create tournament' })}
            </Button>
            <CreateTournamentModal
              visible={this.state.createBattleModal}
              onClose={() => {this.setState({ createBattleModal: false, oldinfo: null })}}
              oldinfo={this.state.oldinfo}
              intl={intl}
              /></> : null}
          </div>
          <div className='tournament-table-wrapper'>
            <Table
              className="table"
              columns={this.columns}
              dataSource={
                this.state.tournaments.length
                  ? (this.state.tournaments).filter(tournament => tournament.isbattle === 0 && tournament.tournamenttypeid !== 5)
                  : []
              }
              pagination={false}
              rowKey="id"
              style={{ width: '100%' }}
              locale={{ emptyText: 'No tournaments' }}
              rowClassName={'tournamentRow'}
              onRow={(record, index) => ({
                onClick: () => {
                  this.props.onRowClick(record, index);
                },
              })}
            />
          </div>
        </div>
      </div>
    );
  }
}


export default injectIntl(
  connect(
    state => ({
      lang: state.langs.lang,
      isadmin: state.users.user.isadmin,
      permissions: state.users.permissions ? state.users.permissions.keys : [],
      deleteFetch: state.tournaments.fetches.delete,
      user: state.users.user,
      playersUpdate: state.tournaments.fetches,
    }),
    {
      addUserToTournament,
      deleteUserFromTournament,
      DeleteTournament,
      requestTournamentInfo,
      getPermissions,
    },
  )(TournamentsTable),
);
