import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Button } from '../../../Button';
import OBModal from '../../../Modal';
import Checkbox from '../../../Checkbox';
import Utils from '../../../../../libs/utils';
import image from '../../../../../images/skittles.png';
import SvgArrow from '-!svg-react-loader!./arrow.svg'; // eslint-disable-line

import '../style.scss';

class ModalCreateBattleTutorial extends Component {

  constructor(props) {

    super(props);

    this.state = {
      suppress: false
    }
  }

  render() {
    const { intl, lang, visible, params } = this.props;
    const { datestart, cost, currency, wincount } = params || {};
    const { suppress } = this.state;
    const translate = {
      title: intl.formatMessage({ id: 'CreateBattleTutotialModalTitle', defaultMessage: 'You created a Battle' }),
      ok: intl.formatMessage({ id: 'ButtonOK', defaultMessage: 'OK' }),
      parameters: intl.formatMessage({ id: 'BattleParameters', defaultMessage: 'Parameters of Battle' }),
      battledate: intl.formatMessage({ id: 'date_of_battle', defaultMessage: 'Date of battle' }),
      fee: intl.formatMessage({ id: 'fee', defaultMessage: 'Fee' }),
      wins: intl.formatMessage({ id: 'table_wins_short', defaultMessage: 'Wins' }),
      description: intl.formatMessage({
        id: 'CreateBattleTutotialModalDescription',
        defaultMessage: `Other players see him. 
          Follow the responses of players to participate in the Battle, 
          in the right panel of the “My Battles” tab 
          and select the player you want to play with.`
      }),
      ignore: intl.formatMessage({ id: 'SuppressFurther', defaultMessage: 'Suppress further' }),
      now: intl.formatMessage({ id: 'now', defaultMessage: 'Now' }),
      free: intl.formatMessage({ id: 'without_bet', defaultMessage: 'Free' }),
    }
    return (
      <OBModal
        title={translate.title}
        visible={visible}
        width={395}
        onOk={() => { }}
        onCancel={() => { }}
        zIndex={500}
        className="create_battle_tutotial_modal"
        closable={false}
        footer={[
          <Button style={{ width: '100%' }}
            kind="aquamarine"
            onClick={() => this.props.onClose(suppress)}
          >
            {translate.ok}
          </Button>
        ]}
      >
        <div className="create_battle_tutotial_modal__image">
          <img src={image} alt="" />
        </div>
        <div className="create_battle_tutotial_modal__parameters">
          <div className="create_battle_tutotial_modal__subtitle">
            {translate.parameters}{':'}
          </div>
          <div className="create_battle_tutotial_modal__table">
            <div className="create_battle_tutotial_modal__parameter">
              <div className="create_battle_tutotial_modal__parameter__key">
                {translate.battledate}
              </div>
              <div className="create_battle_tutotial_modal__parameter__value">
                {datestart ? Utils.formatDateTime(+new Date(datestart), lang, 'DD MMM - HH:mm'): translate.now}
              </div>
            </div>
            {/*<div className="create_battle_tutotial_modal__parameter">
              <div className="create_battle_tutotial_modal__parameter__key">
                {translate.fee}
              </div>
              <div className="create_battle_tutotial_modal__parameter__value">
                {cost ? `${cost}${currency}` : translate.free}
              </div>
            </div>*/}
            <div className="create_battle_tutotial_modal__parameter">
              <div className="create_battle_tutotial_modal__parameter__key">
                {translate.wins}
              </div>
              <div className="create_battle_tutotial_modal__parameter__value">
                {wincount}
              </div>
            </div>
          </div>
        </div>
        <div className="create_battle_tutotial_modal__description">
          {translate.description}
        </div>
        <div className="create_battle_tutotial_modal__checkbox">
          <Checkbox checked={suppress}
            onChange={(checked) => {
              this.setState({ suppress: checked });
            }}
          >
            {translate.ignore}
          </Checkbox>
        </div>
        <div className="create_battle_tutotial_modal__arrow">
          <SvgArrow/>
        </div>
      </OBModal>
    );
  }
}

export default injectIntl(
  connect(
    state => ({
      lang: state.langs.lang
    }),
    {},
  )(ModalCreateBattleTutorial),
);
