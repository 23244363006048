import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import NavMenu from './navMenu/index';

import { setTransactionResponse } from '../../libs/reducers/adminReducer';
import {
  setVideoArchiveSquadId,
  clearGameInfo,
} from '../../libs/reducers/actions';

import './style.scss';
import UserList from './userList';
import BattleList from './battleList';
import HandicapsTab from './handicapsTab';
import BannerMainMobile from './bannerMainMobile';
import NotificationTab from './notificationTab';
import TariffsTab from './tariffs';
import Verification from './verification';
import IBAN from './iban';
import Paysera from './paysera';
import SystemAccounts from './systemAccounts';
import PaymentState from './paymentState';
import PaymentHistory from './paymentHistory';
import Transactions from './transactions';
import WatchingUser from './watchingUser';
import Analytics from './analytics';
import ModerationComponent from './moderation';
import ReferralComponent from './referral';
import Subscribers from './subscribers';

const Main = ({ permissions }) => {
  const allowedTabs = [];
  if (permissions.includes('USERS_MANAGE')) {
    allowedTabs.push('userlist');
    allowedTabs.push('watching_user');
    allowedTabs.push('subscribers');
  }
  allowedTabs.push('battlelist');
  if (permissions.includes('PAYMENT_MANUAL_TRANSACTION')) {
    allowedTabs.push('transactions');
  }
  if (permissions.includes('PAYMENT_GET_HISTORY')) {
    allowedTabs.push('paymenthistory');
  }
  if (permissions.includes('PAYMENT_MANUAL_TRANSACTION')) {
    allowedTabs.push('paymentstate');
  }
  if (permissions.includes('PAYMENT_MANUAL_TRANSACTION')) {
    allowedTabs.push('systemaccounts');
  }
  if (permissions.includes('MANAGE_IBAN')) {
    allowedTabs.push('iban');
    allowedTabs.push('paysera');
  }
  if (permissions.includes('USERS_MANAGE_DOCS')) {
    allowedTabs.push('verification');
  }
  allowedTabs.push('notification');
  if (permissions.includes('PAYMENT_MANUAL_TRANSACTION')) {
    allowedTabs.push('tariffs');
  }
  allowedTabs.push('mobilebanner');
  allowedTabs.push('handicaps');
  allowedTabs.push('Analytics');
  if (permissions.includes('ADMINISTRATE_PANEL_VISIBILITY')) {
    allowedTabs.push('moderation');
  }
  if (permissions.includes('USERS_MANAGE')) {
    allowedTabs.push('referral');
  }

  return (
    <div>
      <div className={'navMenuContainer'}>
        <NavMenu allowedTabs={allowedTabs} />
      </div>
      <Switch>
        <Route exact path="/appadmin">
          {<Redirect to="/appadmin/battlelist" />}
        </Route>
        <Route path="/appadmin/userlist" component={UserList} />
        <Route path="/appadmin/battlelist" component={BattleList} />
        <Route path="/appadmin/tariffs" component={TariffsTab} />
        <Route path="/appadmin/transactions" component={Transactions} />
        <Route path="/appadmin/paymenthistory" component={PaymentHistory} />
        <Route path="/appadmin/paymentstate" component={PaymentState} />
        <Route path="/appadmin/systemaccounts" component={SystemAccounts} />
        <Route path="/appadmin/iban" component={IBAN} />
        <Route path="/appadmin/paysera" component={Paysera} />
        <Route path="/appadmin/verification" component={Verification} />
        <Route path="/appadmin/notification" component={NotificationTab} />
        <Route path="/appadmin/mobilebanner" component={BannerMainMobile} />
        <Route path="/appadmin/handicaps" component={HandicapsTab} />
        <Route path="/appadmin/watching_user" component={WatchingUser} />
        <Route path="/appadmin/analytics" component={Analytics} />
        <Route path="/appadmin/moderation" component={ModerationComponent} />
        <Route path="/appadmin/referral" component={ReferralComponent} />
        <Route path="/appadmin/subscribers" component={Subscribers} />
      </Switch>
    </div>
  );
};

export default injectIntl(
  withRouter(
    connect(
      state => ({
        permissions: state.users.permissions
          ? state.users.permissions.keys || []
          : [],
        permissionsfetch: state.users.permissions
          ? state.users.permissions.fetch
          : false,
      }),
      {
        setVideoArchiveSquadId,
        clearGameInfo,
        setTransactionResponse,
      },
    )(Main),
  ),
);
