import React from 'react';
import success from '../../../../images/elyt/success.png';
import notAvailableImage from '../../../../images/elyt/notAvailable.png';

function notAvailable({
  type,
}) {
  let image = null;
  let text = '';
  switch (type) {
    case 'success':
      image = success;
      text = 'Thanks! We’ve got your Data';
      break;
    default:
      image = notAvailableImage;
      text = 'Not available yet';
  }
  return (
    <div className="notAvailable">
      <img src={image}/>
      <div>{text}</div>
    </div>
  );
}

export default notAvailable
