import React, { Component } from 'react'
import './style.scss'
import './adaptive.scss'

export default class Agreement extends Component {

  render() {
    return (
      <div>
        <p><b>TenPinCam. Terms and Conditions of Use of Platform.</b></p>
        <p><b>Version: 05.11.2019.</b></p>
        <ul>
          <li><b>1.Preamble</b>
            <div>TenPinCam Platform has been created to provide game facilities to the ten pin bowling players within the framework of sports and entertainment formats.</div>
            <div>The Platform is designed for the bowling development as a sport, accessible and uniting.</div>
            <div>The principle of TenPinCam project is based on the application of technical aids (video cameras, Internet network, user’s device) for online playing worldwide.</div>
            <div>The game scores depend exclusively on the player’s skills (Game of Skill).</div>
            <div>The users of the platform play bowling on lanes of bowling centres equipped with a set of TenPinCam video cameras, the game scores are recorded and controlled through the TenPinCam platform interface.</div>
            <div>The game is run in compliance with the rules of the World Bowling.</div>
          </li>
          <li>&nbsp;</li>
          <li><b>2.Platform Users</b>
            <ul>
              <li><b>2.1.TenPinCam Platform is designed for:</b>
                <ul>
                  <li>2.1.1.<b>professional players</b> within the framework of participation in tournaments and coaching by the best coaches; </li>
                  <li>2.1.2.<b>amateurs</b> as online play entertainment formats; </li>
                  <li>2.1.3.<b>bowling</b> centres as provision of ancillary services to their clients and clientele expansion; </li>
                  <li>2.1.4.<b>referees</b> within the framework of work on enforcement of compliance with the rules; </li>
                  <li>2.1.5.<b>coaches</b> as provision of instruction, coaching, workshop online services;  </li>
                  <li>2.1.6.<b>tournament</b> administrators within the framework of tournament management.  </li>
                </ul>
              </li>
              <li><b>2.2.</b>Within the framework of game formats, which may imply involvement of funds, there are age limits in reliance upon the laws of the User’s country / state.</li>
            </ul>
          </li>
          <li>&nbsp;</li>
          <li><b>3.Key terms and definitions</b> 
            <ul>
              <li><b>3.1.Platform</b> means the TenPinCam online system with the functionality to offer play facilities for bowling fans within the framework of sport and entertainment formats.  </li>
              <li><b>3.2.User</b> means a person, which has been registered and verified, who uses the Platform in pursuance of the concluded Service Agreement. </li>
              <li><b>3.3.Service</b> means the activity implemented by the Platform as to the User, by applying the available functionality.  </li>
              <li><b>3.4.Subscription</b> means an agreement between the User and the Platform on the Service maintaining.  </li>
              <li><b>3.5.Tariff</b> means a fixed charge within the framework of a certain Subscription.  </li>
              <li><b>3.6.Play</b> means a bowling competition based on display of skills by the participants (game of skill).  </li>
              <li><b>3.7.Game</b> means a play consisting of 10 frames. </li>
              <li><b>3.8.Match</b> means a play consisting of one or several games. </li>
              <li><b>3.9.Gaming session</b> means the status of connection to the system during which one or more matches can be played. </li>
              <li><b>3.10.Fee for game</b> means one-off payment by the User within the framework of the Platform play formats.  </li>
              <li><b>3.11.Alley</b> means the equipped according to the technical specification lane in a bowling centre, which is the Platform User.  </li>
              <li><b>3.12.System Administrator</b> means a responsible person of the TenPinCam Platform, who manages the system operation.  </li>
              <li><b>3.13.Payment System</b> means a third-party system integrated into the Platform through which payments between the Player and the Platform are made.  </li>
              <li><b>3.14.Money Prize</b> – money prize paid by platform the User, according to the corresponding Tournament Schedule. </li>
              <li><b>3.15.Platform Commission</b> – part of money funds, withheld by the platform, calculated as a % from the total amount of the Participation Fees. </li>
            </ul>
          </li>
          <li>&nbsp;</li>
          <li><b>4.Subscription</b>
          <ul>
              <li><b>4.1.</b>The Platform Users are provided with certain subscription parameters depending on the Tariff. </li>
              <li><b>4.2.</b>A user chooses a start type of Subscription at registration.  </li>
              <li><b>4.3.</b>Once the profile has been successfully activated, each user receives a 30-day free access to all full-scale opportunities of the Platform. An accessible disk capacity makes up 10Gb. </li>
              <li><b>4.4.</b>Upon expiry of the 30-day free period the User may choose to purchase one of the service packages, according to the Tariff plans, described below. The User may also decide not to choose any of the Tariffs – he may continue using the platform, without the access to the services, included into one of the Tariffs below. </li>
              <li><b>4.5.</b>A Player may choose one of the offered Subscription type and corresponding Tariffs:   
                <ul>
                  <li><b>4.5.1.</b>Tariff PRO 10Gb: 
                    <ul>
                      <li>4.5.1.1.Save & review records of your games. </li>
                      <li>4.5.1.2.<b>10Gb</b> Available Disk space. </li>
                      <li>4.5.1.3.Access to extended statistics.  </li>
                      <li>4.5.1.4.Tariff price - <b>€9.99</b> per month.  </li>
                    </ul>
                  </li>
                  {/* <li><b>4.5.1.</b>Tariff PRO 3Gb: 
                    <ul>
                      <li>4.5.1.1.Save & review records of your games. </li>
                      <li>4.5.1.2.<b>3Gb</b> Available Disk space. </li>
                      <li>4.5.1.3.Access to extended statistics.  </li>
                      <li>4.5.1.4.Tariff price - <b>€9.99</b> per month.  </li>
                    </ul>
                  </li> */}
                  {/* <li><b>4.5.2.</b>Tariff PRO 10Gb: 
                    <ul>
                      <li>4.5.2.1.Save & review records of your games. </li>
                      <li>4.5.2.2.<b>10Gb</b> Available Disk space. </li>
                      <li>4.5.2.3.Access to extended statistics.  </li>
                      <li>4.5.2.4.Tariff price - <b>€14.99</b> per month.  </li>
                    </ul>
                  </li> */}
                </ul>
              </li>
              <li><b>4.6.</b>A user has the right to choose none of the subscription types, by reserving access to the User Account and email box, furthermore, the opportunity to watch certain plays as a Viewer.  </li>
              <li><b>4.7.</b>A player may cancel any Subscription type any time without restrictions.  </li>
              <li><b>4.8.</b>A player may change Pro 3Gb Tariff to Pro 10Gb Tariff and vice versa, as well as opt-out of Subscription any time, by taking into consideration the restrictions (Refer to Service Cancellation Section).  </li>
              <li><b>4.9.</b>Upon activation of Subscription the funds are debited from the User’s e-wallet for the entire period of using the Service.  </li>
              <li><b>4.10.</b>After expiration of the subscription, it shall be extended automatically, unless the User has chosen another option.  </li>
              <li><b>4.11.</b>The subscription tariffs do not apply to such users as Coach, Referee, Bowling Centre.  </li>
              <li><b>4.12.</b>During the purchase of the Subscription the user may choose the option Automatic Renewal. In such case, the system will work in the following way: 
                <ul>
                  <li>4.12.1.One day prior to the end date of the subscription, the system will send to the user a notification email, informing that the subscription fee for the next month will be automatically deducted from his account.  </li>
                  <li>4.12.2.On the last day of subscription, the system will automatically charge the user account for the next month. </li>
                  <li>4.12.2.1.If the transaction was successful – the user shall receive the email with the confirmation of the extension of the subscription for one more month.  </li>
                  <li>4.12.2.2.If there were not enough funds on user account – the user shall receive a warning email. In two days, the system shall perform another attempt to charge the user. The access to the services will be temporary suspended.  </li>
                  <li>4.12.2.3.System actions at the second attempt: </li>
                  <li>4.12.2.3.1.If the second attempt was successful – the system shall send to the user a confirmation email. The access to the services shall be automatically restored.  </li>
                  <li>4.12.2.3.2.If the second attempt was unsuccessful – the Automatic Renewal service will be cancelled & access to the services will be closed. The user would have to purchase the new subscription to activate the services.  </li>
                </ul>
              </li>
              <li><b>4.13.</b>Subscription Service Cancellation.
                <ul>
                  <li>4.13.1.The user is eligible to cancel the Automatic Renewal service at any time by selecting the option in his Personal Account. In case of the cancellation, the user can access the services until the last day of the paid subscription term. The user may also cancel the Automatic Renewal at any time.  </li>
                  <li>4.13.2.If the user has cancelled the subscription, his data archive will be stored in the system for another 30 days, after this term the data will be automatically deleted. Within 30 days after cancellation the user can purchase the subscription of the same or higher level to restore the access to his data archive.  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>&nbsp;</li>
          <li><b>5.Registration</b>
            <ul>
              <li><b>5.1.</b>To get a User status it is necessary to register on the Platform.  </li>
              <li><b>5.2.</b>At registration, it is necessary to specify all personal data requested by the system (Refer to Personal Data Protection Section), download the User’s photo, also specify the User name in the system and the password.  </li>
              <li><b>5.3.</b>For full – featured registration it is recommended to specify additional data in the player’s questionnaire. </li>
              <li><b>5.4.</b>At registration of a Player it is required to specify restrictions on the maximum one-time fee for participation in plays during one day and one month.  </li>
              <li><b>5.5.</b>To participate in commercial tournaments a Player needs to undergo additional registration in compliance with the Payment System Rules.  </li>
              <li><b>5.6.</b>At registration a User needs to get acquainted with these rules, as well as with the Cancellation & Refund Policy, Privacy Policy and accept thereof.   </li>
              <li><b>5.7.</b>For registration a User may specify own data only. If it is established that a third – party data were used at registration, the System Administrator is eligible to lockdown the profile and void all activities on the Platform.   </li>
              <li><b>5.8.</b>Users are allowed to register one profile only. If it is established that more than one profile belong to the User, the System Administrator shall is eigible to lockdown the profile and void all activities on the Platform.   </li>
              <li><b>5.9.</b>Once all of the necessary data have been completed, the registration inquiry shall be sent for verification (Refer to User Verification Section).  </li>
            </ul>
          </li>
          <li>&nbsp;</li>
          <li><b>6.User Verification</b>
            <ul>
              <li><b>6.1.</b>To complete registration the Player’s application is to be confirmed by the System Administrator.  </li>
              <li><b>6.2.</b>The Player verification process can take up to several working days.  </li>
              <li><b>6.3.</b>Where any doubts appear on truthfulness of information entered, the System Administrator shall be at liberty to request the player to submit additional data. </li>
              <li><b>6.4.</b>The System Administrator reserves the right to refuse verification of a User without assigning any reason.  </li>
              <li><b>6.5.</b>To participate in commercial tournaments a Player needs to undergo additional registration in compliance with the Payment System Rules.   </li>
              <li><b>6.6.</b>Verification of bowling centres (cameras, Internet) is performed by the System Administrator.  </li>
            </ul>
          </li>
          <li>&nbsp;</li>
          <li><b>7.Profile Activation</b>
            <ul>
              <li><b>7.1.</b>Upon successful verification the User profile shall be activated.  </li>
              <li><b>7.2.</b>At activation of the profile the User shall be assigned the initial rating (Refer to Rating Section).  </li>
              <li><b>7.3.</b>All of the Platform opportunities, as per the tariff chosen, become available upon activation of the profile, by taking into consideration the following restrictions: 
                <ul>
                  <li>7.3.1.A player may participate in tournaments with payments in excess of €10.00, only subject to certain conditions: 
                    <ul>
                      <li>- at least 5 plays of the Battle category have been played;</li>
                      <li>- the average personal rating of the player makes up at least 3.5;</li>
                      <li>- there are no pending protests;</li>
                      <li>- the player has left at least 5 comments concerning opponents in the Battle category plays.</li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>&nbsp;</li>
          <li><b>8.User Account</b>
            <ul>
              <li><b>8.1.</b>A User Account page is available to all registered users with an activated profile.  </li>
              <li><b>8.2.</b>A User Account may be used only by the person, who has registered this profile.  </li>
              <li><b>8.3.</b>A user undertakes to keep secret the access password to the User Account and not disclose thereof to third parties.  </li>
              <li><b>8.4.</b>If the profile is hacked, the User is to contact the System Administrator forthwith with a request to lockdown the profile for further investigation.  </li>
              <li><b>8.5.</b>If a password is lost, the User may use the recovery function.  </li>
              <li><b>8.6.</b>A User Account consists of the following information: 
                <ul>
                  <li><i>- Personal data</i> – questionnaire details of the user;</li>
                  <li><i>- My Battles</i> – information concerning Battle plays (archive), pending applications for plays from opponents, current created tournaments.</li>
                  <li><i>- Cash</i> – the status of e-wallet, balance of funds, transactions, funds redemption and e-wallet replenishment functions;</li>
                  <li><i>- Bowling centres</i> – information concerning bowling centres where the Platform opportunities are accessible.</li>
                  <li><i>- Email box</i> – notification system, administrators of tournaments and referees, decisions on protests, communication between the players.</li>
                </ul>
              </li>
            </ul>
          </li>
          <li><b>8.7.</b>The tool for the settlement of accounts between the Player and the Platform is the Payment System.  </li>
          <li><b>8.8.</b>The Platform can use various third-party Payment Systems. The application instructions are determined by the Payment System operator. A user concludes with the operator a separate user agreement in the course of registration of own account with the Payment System.  </li>
          <li>&nbsp;</li>
          <li><b>9.Playing games</b>
            <ul>
              <li><b>9.1.</b>The Platform game window consists of:
                <ul>
                  <li><b>9.1.1.</b>Camera block </li>
                  <li><b>9.1.2.</b>Scoring block </li>
                  <li><b>9.1.3.</b>Chat block </li>
                  <li><b>9.1.4.</b>Settings block </li>
                </ul>
              </li>
              <li><b>9.2.</b>The camera block consists of three images of cameras of an opponent that are directed accordingly at the pinsetter, scores screen and at the tournament area, as well as the same pictures of the Player’s cameras. The block implies a possibility to switch between cameras of the Player and cameras of the fellow player, furthermore, to use a video archive. The scores screen can be integrated into the Platform.  </li>
              <li><b>9.3.</b>The Scoring block represents the tournament results of the Player and his opponent.  </li>
              <li><b>9.4.</b>The Chat block contains notifications of the Platform, furthermore, implies a possibility of interactions between the Players.  </li>
              <li><b>9.5.</b>The gameplay in any format implies an online game, completion of forms of own scores and mutual control between opponents. </li>
              <li><b>9.6.</b>The Players bowl in turns, one frame at a time. While the Player is bowling, the fellow player can watch the process on cameras.  </li>
              <li><b>9.7.</b>Once bowled, the Player has to enter his scores to the relevant block and confirm its correctness by clicking on “Save” button.  </li>
              <li><b>9.8.</b>Where the score was entered wrongly until confirmation, it can be corrected, by re-entering correct data.  </li>
              <li><b>9.9.</b>Where the score is entered wrongly and has been confirmed, it can be corrected at any time of the play, by adding comments, thereafter the fellow player shall be notified of the correction. The fellow player, in his turn, can agree to the correction or disagree (Refer to Protests Section).  </li>
              <li><b>9.10.</b>Once the scores of the Player have been entered and confirmed the turn goes over to the opponent.  </li>
              <li><b>9.11.</b>The sequence of bowling is monitored by the Platform. The Player receives audio and visual notification that the turn has come over to him.  </li>
              <li><b>9.12.</b>For each frame (the 10th frame inclusive) the Player is given the time limit – 5 minutes. During this period the Player must ensure the correctness of the entered score of the preceding frame of the opponent, complete his frame, enter his score and confirm it.  </li>
              <li><b>9.13.</b>Where the Player fails to confirm the frame score in time subject to the rules, upon expiry of the time the Player receives a yellow card. In case of a repeated delay of frame in excess of 5 minutes (after receiving a yellow card in the same frame or in any other subsequent shots of this game) the Player shall receive a defeat. </li>
              <li><b>9.14.</b>Where the Player fails to finish the game in time subject to the rules, upon expiry of the time the Player receives a yellow card and additional 5 minutes to finish the game. If this card is already the second one in this game or the Player fails to finish the game within the additional 5 minutes, the Player shall receive a defeat. </li>
              <li><b>9.15.</b>Where the reason for such delay was a malfunction, the time-out rule shall take effect (Refer to Malfunction Section).  </li>
            </ul>
          </li>
          <li>&nbsp;</li>
          <li><b>10.Alley</b>
            <ul>
              <li><b>10.1.</b>One or several lanes in the bosling alley has to be equipped with the TenPinCam certified camera system subject to the rules. A set of equipment is provided to a bowling centre by the TenPinCam Platform subject to agreement on a case-by-case basis. In a bowling centre on at least one or several lanes the below set of equipment is to be installed:
                <ul>
                  <li><b>10.1.1.</b>The WiFi access point named TenPinCam.com, with the video cameras connected through this point. </li>
                  <li><b>10.1.2.</b>General View camera. </li>
                  <li><b>10.1.3.</b>Pinsetter camera. </li>
                  <li><b>10.1.4.</b>Scoring camera. In case the bowling center scoring system is integrated with the TenPiCam platform, the Scoring Camera is not required.  </li>
                </ul>
              </li>
              <li><b>10.2.</b>The lane oil pattern is not regulated and can be used in whatever configuration at the discretion of the Player.  </li>
              <li><b>10.3.</b>The lane coating cannot be performed during the game session. In the Battle format, which consists of several games, the lane coating cannot be performed between the games.  </li>
              <li><b>10.4.</b>An operative foul line is not a compulsory condition for the alley. Nevertheless, if a foul is noted by a fellow player and can be distinguished on cameras, the result of a shot shall not be counted.  </li>
              <li><b>10.5.</b>The change of the ball surfaces is not regulated and can be used any time of a game at the discretion of the Player.  </li>
              <li><b>10.6.</b>The players uniform is not regulated.  </li>
            </ul>
          </li>
          <li>&nbsp;</li>
          <li><b>11.Malfunction</b>
            <ul>
              <li><b>11.1.</b>Malfunction implies one of the listed below: 
                <ul>
                  <li>11.1.1.Stop (switching off) of a lane </li>
                  <li>11.1.2.Inoperative scoreboard screen  </li>
                  <li>11.1.3.Inoperative camera </li>
                  <li>11.1.4.Lost connection, network failure  </li>
                  <li>11.1.5.Other malfunctions </li>
                </ul>
              </li>
              <li><b>11.2.</b>In case of a technical failure the Player may ask a technical time-out.  </li>
              <li><b>11.3.</b>Upon correction of the malfunction, the match shall be resumed by preserving all standard time limits.  </li>
              <li><b>11.4.</b>In case of a repeated malfunction during one and the same game, such malfunction must be rectified within 5 minutes dedicated to complete the frame.   </li>
              <li><b>11.5.</b>Where it is impossible to rectify a malfunction within the time established, the Player shall receive a defeat. It is not allowed to transfer the game to another lane or replay the match.  </li>
              <li><b>11.6.</b>Responsibility for the technical condition of the alley rests with the Player. The Platform bears no responsibility for the consequences as a result of malfunctions of alleys.  </li>
            </ul>
          </li>
          <li>&nbsp;</li>
          <li><b>12.Disciplinary Responsibility</b>
            <ul>
              <li><b>12.1.</b>During a game session, the Players are prohibited to:
                <ul>
                  <li>12.1.1.Insult an opponent in the chat, also to use rude gestures. </li>
                  <li>12.1.2.Incite the fellow player to cheating, manipulation of scores, any other vioation of game rules.  </li>
                  <li>12.1.3.Disregard other rules of the regulation. </li>
                </ul>
              </li>
              <li><b>12.2.</b>In case of violation of any of the rules the Player shall receive a defeat in the entire match. The Player’s case shall be submitted for consideration to the Disciplinary Committee, where the disqualification matter shall be considered.  </li>
              <li><b>12.3.</b>During the play the Player shall follow the Fair Play principles. </li>
              <li><b>12.4.</b>The Disciplinary Committee consists of the System Administrator, Head of the Referee’s Committee, as well as of all referees of tournaments, if any.  </li>
            </ul>
          </li>
          <li>&nbsp;</li>
          <li><b>13.Protests</b>
            <ul>
              <li><b>13.1.</b>Protests are considered in two modes – online and offline – depending on the tournament settings.  </li>
              <li><b>13.2.</b>Protests are considered in the Battle mode only if the size of fee for game exceeds EUR 10.  </li>
              <li><b>13.3.</b>In the Battle mode, the Player at the time of consent to a play with a certain opponent may choose a play with a referee (implies a possibility to lodge a protest) or without a referee (no possibility to lodge a protest). A play without a referee is possible if confirmed by both opponents. A play with a referee takes place if requested by at least one Player.  </li>
              <li><b>13.4.</b>Where a protest is lodged, the fellow player receives a notification. If a protest concerns correction of the score and the fellow player agrees with the statement, then after correction the Platform offers the Players to confirm the match result again. In the event of a repeated protest or where the protest concerns other matters, the protest is submitted to Referee for consideration.  </li>
              <li><b>13.5.</b>Where a protest is lodged, an amount equal to EUR 10 shall be frozen on the e-wallet account of both players. Upon consideration of the protest, this amount shall be refunded to the Player, who turned to be right in the dispute; this amount shall not be refunded to the Player, who turned to be wrong.  </li>
              <li><b>13.6.</b>Where a protest is considered in an offline mode, the Referee shall take a decision within 48 hours and communicate his decision to the Players.  </li>
              <li><b>13.7.</b>A Player may make an appeal to the Head of the Referee’s Committee. In such event an amount equal to EUR 50 shall be frozen on the e-wallet account. Upon reviewing the appeal, the frozen amount shall be refunded, if the Player turned to be right. The initial protest shall be considered anew by the Referee’s Committee. Where the Player turns to be wrong the frozen amount shall be debited from the e-wallet account.  </li>
              <li><b>13.8.</b>Where a protest is considered in an online mode, the Referee shall take a decision within 15 minutes and communicate his decision to the Players. In such event the Referee’s decision shall not subject to appeal.  </li>
            </ul>
          </li>
          <li>&nbsp;</li>
          <li><b>14.Battle Format</b>
            <ul>
              <li><b>14.1.</b>The Battle format offers an opportunity to two Players to compete against each other.  </li>
              <li><b>14.2.</b>A Player may create own Battle or participate in already created one. </li>
              <li><b>14.3.</b>Just two Players can participate in one Battle.  </li>
              <li><b>14.4.</b>One Player can simultaneously participate in one Battle only.  </li>
              <li><b>14.5.</b>At the creation of a Battle the Player chooses the following parameters:
                <ul>
                  <li>14.5.1. Battle Participation Fee
                    <ul>
                      <li>14.5.1.1.Free games – play without involving the means of payment or for virtual money. The mode is accessible at the time of profile activation, also to persons under legal age; </li>
                      <li>14.5.1.2.Less than €10 –the mode is accessible after players successful qualification; </li>
                      <li>14.5.1.3.In excess of €10 - the mode is accessible upon lifting of restrictions on activation;  </li>
                      <li>14.5.1.4.The participation fee is limited by the profile settings; </li>
                      <li>14.5.1.5.Where a Battle implies the fee-based participation, the winner of the Battle shall receive a money prize. The prize value is calculated by the Platform and is indicated in the details of the battle before confirmation. By creating and accepting the challenge to participate in a fee-based battle, the players automatically accept the participation cost and prize to the winner.  </li>
                      <li>14.5.1.6.The Battle prize Fund is calculated as a sum of the Participation Fees, less the Platform Commission, effective for the date of the creation of the Battle (see Platform Commissions section). </li>
                    </ul>
                  </li>
                  <li>14.5.2. Number of wins is the number of games to be won to win the Battle – from 1 to 5.  </li>
                  <li>14.5.3. Date and time of play
                    <ul>
                      <li>14.5.3.1.Now – the Battle is accessible to opponents immediately after confirmation of settings; </li>
                      <li>14.5.3.2.Choose another date – the Battle is accessible as from the time specified.  </li>
                    </ul>
                  </li>
                  <li>14.5.4.Viewers
                    <ul>
                      <li>14.5.4.1.Accessible for everyone – the play is accessible for all Users to watch; </li>
                      <li>14.5.4.2.Accessible for friends - the play is accessible for all friends of the User to watch; </li>
                      <li>14.5.4.3.Inaccessible – the play is inaccessible for viewers.  </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li><b>14.6.</b>In creation of a Battle the Player has to choose the Bowling Centre and the lane from among the offered list, on which he is going to play.  </li>
              <li><b>14.7.</b>Once the Battle has been created, it appears on the general list of accessible games, also the Battle Game List reflects completed games.  </li>
              <li><b>14.8.</b>The Battle Game List reflects the game creator, his rating, the participation cost, a possible prize amount, the quantity of games and comments of the Battle creator.  </li>
              <li><b>14.9.</b>A Player, who wishes to participate in already created Battle, can choose one Battle from among the general game list and send the invitation.  </li>
              <li><b>14.10.</b>A Player, who has created the Battle, may accept or deny the invitation received. Where the invitation is accepted, the Battle becomes inaccessible for other Players and disappears from the general game list. Where the invitation is rejected, the Battle remains accessible for the Players.  </li>
              <li><b>14.11.</b>Where participation in a Battle is confirmed by two Players, then on the accounts of both Players the amount equal to the participation cost shall be frozen.  </li>
              <li><b>14.12.</b>After completion of a Battle, where none of the Players has lodged a protest, the prize shall be credited to the winner’s e-wallet and debited from the loser’s e-wallet.  </li>
              <li><b>14.13.</b>If a protest is lodged, the frozen amounts shall be withheld until consideration or cancellation of the protest (Refer to Protests Section).  </li>
              <li><b>14.14.</b>In the battle format no tie score is allowed – in case of a tie score in 10 frames the 12th shot shall be cancelled, and the Platform shall appoint a play-off.  </li>
            </ul>
          </li>
          <li>&nbsp;</li>
          <li><b>15.Rating</b>
            <ul>
              <li><b>15.1.</b>Within the Platform framework the basic personal rating (PR) and activity rating (AR) apply to each role of the Users. For certain roles there is also the qualifying rating (QR).  </li>
              <li><b>15.2.</b>Within the Platform framework there is a system of achievements.  </li>
              <li><b>15.3.</b>The Platform affords also informal statistical ratings (average score, wins in tournaments etc.).  </li>
              <li><b>15.4.</b>The personal rating of a Player consists of the average assessment by other Players, from 0 to 5.  </li>
              <li><b>15.5.</b>The personal rating of a Player refers to one of the parameters for admission to play in tournaments with the fees in excess of €10.00. </li>
              <li><b>15.6.</b>After each match a Player may assess the fellow player according to the scale from 0 to 5, by taking into consideration the ethics of play and conduct of the fellow player. At the stage of activation of the Player’s profile the assessment by the opponent is a compulsory condition.  </li>
              <li><b>15.7.</b>The Platform affords the opportunity to leave more complete testimonial on the fellow player after the play, by using a designated from. </li>
            </ul>
          </li>
          <li>&nbsp;</li>
          <li><b>16.Platform Commission</b>
            <ul>
              <li><b>16.1.</b>Platform shall deduct part of money funds, for certain competitions, as a commission, which is calculated as a % from the total amount of the Participation Fees. </li>
              <li><b>16.2.</b>The Platform Commission for the Battles is 5%. </li>
            </ul>
          </li>
        </ul>
      </div>
    )
  }
}