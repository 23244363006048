import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { getUserStats } from '../../../libs/reducers/actions';
import './style.scss';

import statPins from '../../../images/statpins.png';
import statAward from '../../../images/trophy.png';
import statCards from '../../../images/statcards.png';

class UserStatistics extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getUserStats();
  }

  render() {
    const { stats = {}, intl } = this.props;
    return <>
      <div className='userStatsContainer'>
        <div className='containerWrap'>
          <div className='title'>{intl.formatMessage({ id: 'MyAccountRouterStatisticsTitle', defaultMessage: 'Statistics' })}</div>
          <div className='statBlock'>
            <img src={statPins}/>
            <div className='statList'>
              <div className='statItem'><div className='caption'>{intl.formatMessage({ id: 'StatsCommunityRating', defaultMessage: 'Player rating' })}<span style={{ color: '#f65932' }}> ({intl.formatMessage({ id: 'Feedbacks', defaultMessage: 'Feedbacks' })} {stats.ratingamount || 0})</span></div><div className='value'>{+stats.rating || 0}</div></div>
              <div className='statItem'><div className='caption'>{intl.formatMessage({ id: 'StatsAverageScore', defaultMessage: 'Average score' })}</div><div className='value'>{(+stats.avgscore || 0).toFixed()}</div></div>
              <div className='statItem'><div className='caption'>{intl.formatMessage({ id: 'StatsGamesPlayed', defaultMessage: 'Total games played' })}</div><div className='value'>{stats.gamesplayed || 0}</div></div>
              <div className='statItem'><div className='caption'>{intl.formatMessage({ id: 'StatsStrikesAmount', defaultMessage: 'Total strikes amount' })}</div><div className='value'>{stats.strikes || 0}</div></div>
            </div>
          </div>
          <div className='statBlock'>
            <img src={statAward}/>
            <div className='statList'>
              <div className='statItem'><div className='caption'>{intl.formatMessage({ id: 'StatsMatchesTotal', defaultMessage: 'Total matches played' })}</div><div className='value'>{stats.matchplayed || 0}</div></div>
              <div className='statItem'><div className='caption'>{intl.formatMessage({ id: 'StatsMatchesWin', defaultMessage: 'Victories' })}</div><div className='value'>{stats.matchwins || 0}</div></div>
              <div className='statItem'><div className='caption'>{intl.formatMessage({ id: 'StatsMatchesLost', defaultMessage: 'Defeats' })}</div><div className='value'>{stats.matchlose || 0}</div></div>
            </div>
          </div>
          <div className='statBlock'>
            <img src={statCards}/>
            <div className='statList'>
              <div className='statItem'><div className='caption'>{intl.formatMessage({ id: 'StatsYellowCardsTotal', defaultMessage: 'Total yellow cards amount' })}</div><div className='value'>{stats.yellowcards || 0}</div></div>
              <div className='statItem'><div className='caption'>{intl.formatMessage({ id: 'StatsRedCardsTotal', defaultMessage: 'Total red cards amount' })}</div><div className='value'>{stats.redcards || 0}</div></div>
            </div>
          </div>
        </div>
      </div>
    </>
  }
}

export default injectIntl(connect(
  state => ({
    stats: state.users.userStat,
  }), { getUserStats }
)(UserStatistics));