import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { injectIntl, intlShape } from 'react-intl';

import Select from '../../common/Select';
import {
  BattlesFetch,
  BattlesResponseToUserFetch,
  BattlesResponseToOwnerFetch,
  BattlesBattleCancelFetch,
  setCreateBattleQuery,
  setCurrentRoom,
  createPrivateRoom,
} from '../../../libs/reducers/actions';
import { showDialog, closeDialog } from '../../../libs/reducers/SocketEventReducer';
import makePrivateChat from '../../../libs/makePrivateChat';
import Collapsible from '../../common/Collapsible';
import Button from '../../common/Button/Button';
import Opponent from '../../common/Opponent';
import SvgSwords from '-!svg-react-loader!../../../images/sword.svg'; // eslint-disable-line
import no_battles from '../../../images/no_battles_found.png';

import './style.scss';

class MyBattles extends Component {
  static propTypes = {
    BattlesFetch: PropTypes.func.isRequired,
    battles: PropTypes.array,
    users: PropTypes.object.isRequired,
    BattlesResponseToUserFetch: PropTypes.func.isRequired,
    BattlesBattleCancelFetch: PropTypes.func.isRequired,
    intl: intlShape,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    this.state = {
      filter: 'any',
      exposedBattles: {},
    }
  }

  componentDidMount() {
    this.props.BattlesFetch();
  }

  componentDidUpdate(prevProps) {
    const tournamentid = this.state.tournamentIdToClose;
    const { dialog, closeDialog } = prevProps;
    const { dialog: newDialog } = this.props;
    if (dialog && dialog.result === 0 && newDialog.result !== 0) {
      if (newDialog.result === -1) {
        this.setState({
          tournamentIdToClose: 0,
        });
      } else if (newDialog.result === 1) {
        this.props.BattlesBattleCancelFetch({
          tournamentid,
        });
      }
      closeDialog({
        result: 0,
      });
    }
  }

  handleMessageToOpponent = (opponent) => {
    const from = this.props.users.user.id;
    const {
      rooms, setCurrentRoom, createPrivateRoom,
    } = this.props;
    const to = opponent.userid;
    makePrivateChat({
      from,
      to,
      rooms,
      setCurrentRoom,
      createPrivateRoom,
    });
    this.props.onChat();
  };

  handleAddOpponent = (opponent) => {
    console.log('Add: ', opponent);
  };

  handleResponseToUser = (userid, tournamentid, confirmed) => {
    this.props.BattlesResponseToUserFetch({
      userid,
      tournamentid,
      confirmed,
    });
  };

  handleResponseToOwner = (userid, tournamentid, confirmed) => {
    this.props.BattlesResponseToOwnerFetch({
      userid,
      tournamentid,
      confirmed,
    });
  };

  onCreateBattle = () => {
    const { location, history, setCreateBattleQuery } = this.props;
    const { pathname } = location;
    setCreateBattleQuery(true);
    if (pathname != '/lobby') {
      history.replace('/lobby');
    }
  }

  render() {
    const { battles, intl, users } = this.props;
    const { exposedBattles } = this.state;
    const filters = [
      {label: intl.formatMessage({ id: 'AnyAsAllVariationsAlias', defaultMessage: 'Any' }), value: 'any'},
    ]
    const listOfBattles = battles;

    return (
      <div className="my-battles">
        <div className="menu-header">
          {intl.formatMessage({ id: 'myBattlesListHeader', defaultMessage: 'My Battles' })}
        </div>
        <div className="my-battles__wrapper">
          <div className="my-battles-filter">
            <span>{intl.formatMessage({ id: 'StatusAsStateAlias', defaultMessage: 'Status' })}</span>
            <Select
              key={intl.formatMessage({ id: 'AnyAsAllVariationsAlias', defaultMessage: 'Any' })}
              value={filters.find(filter => {return filter.value === this.state.filter})}
              options={[{label: intl.formatMessage({ id: 'AnyAsAllVariationsAlias', defaultMessage: 'Any' }), value: 'any'}]}
              onChange={(e) => {
                this.setState({
                  filter: e,
                });
              }}
            />
          </div>
          {
            listOfBattles.length ?
            <div className="my-battles__battles_container">{
            listOfBattles.map((battle, index) => {
              let additiveClassName = '';
              switch (battle.tournamenttypebrief) {
                case 'BATTLES':
                  additiveClassName = 'tournament-type';
                  break;
                case 'DROP_IN':
                case 'OPEN_CHALLENGE':
                  additiveClassName = 'qualification-type';
                  break;
                case 'INDIVIDUAL':
                case 'PINFALL_BATTLE':
                default:
                  additiveClassName = 'battle-type';
                  break;
              }
              let zIndex = 0;
              let status = intl.formatMessage({ id: 'SelectedOpponent', defaultMessage: 'Opponent chosen' });

              if (battle.competitors.filter(competitor => competitor.confirmedbyuser === 1 && competitor.confirmedbyowner !== -1).length < 2) {
                if (index === 0) zIndex = 501;
                status = (battle.isExpectation)
                  ? intl.formatMessage({ id: 'IBANConfirmation', defaultMessage: 'Confirmation' })
                  : intl.formatMessage({ id: 'MyBattlesWaitRival', defaultMessage: 'Wait for opponent' });
                // status = intl.formatMessage({ id: 'MyBattlesWaitRival', defaultMessage: 'Wait for opponent' });
              }
              else if (['INDIVIDUAL', 'PINFALL_BATTLE'].includes(battle.tournamenttypebrief) && !battle.isConfirmed && battle.competitors.length > 1) { status = intl.formatMessage({ id: 'SelectOpponent', defaultMessage: 'Selecting opponent' })}
              else if (battle.tournamenttypebrief === 'BATTLES' || battle.isConfirmed) { status = intl.formatMessage({ id: 'WaitingForStart', defaultMessage: 'Wait for start' });}
              return <>
              <div style={{ zIndex, position: 'relative' }} className={`my-battles__battle ${additiveClassName}`}>
                <div className='my-battles__cardheader'>
                  <div className='my-battles__name'>
                    #{battle.id} {battle.name}
                  </div>
                  <div className='my-battles__buttons'>
                  {battle.inProgress ? (
                      <div className="cancel_button_placeholder">
                        {intl.formatMessage({ id: 'GameCancelButtonBlock', defaultMessage: 'In progress' })}
                      </div>
                    ) : (
                      <>{((battle.tournamenttypebrief === 'INDIVIDUAL')
                        || (battle.tournamenttypebrief === 'PINFALL_BATTLE'))
                        && <Button
                          size="xs"
                          bordered="true"
                          loading={this.props.battlesBattleCancelFetch}
                          onClick={() => {
                            this.setState({
                              tournamentIdToClose: battle.tournamentid,
                            });
                            this.props.showDialog({
                              declineButtonText: intl.formatMessage({
                                id: 'BattleCancelModalRejectText',
                                defaultMessage: 'Keep',
                              }),
                              acceptButtonText: intl.formatMessage({
                                id: 'BattleCancelModalOkText',
                                defaultMessage: 'Cancel',
                              }),
                              title: intl.formatMessage({
                                id: 'BattleCancelModalTitle',
                                defaultMessage: 'Battle cancel',
                              }),
                              children: (
                                <span>
                                  {intl.formatMessage({
                                    id: 'BattleCancelConfirmationText',
                                    defaultMessage: 'Are you sure you want to cancel the Battle?',
                                  })}
                                </span>
                              ),
                            });
                          }}
                        >
                          {intl.formatMessage({ id: 'CancelAsAbortAlias', defaultMessage: 'Cancel' })}
                        </Button>}
                      </>
                    )}
                  </div>
                </div>
                <div className="my-battles__inner">
                  <div className="my-battles__info">
                    <div className="my-battles__date_bet">
                      <div className="my-battles__headers">
                        <div className="my-battles__header">
                          {intl.formatMessage({ id: 'date_of_battle', defaultMessage: 'Date of battle' })}
                        </div>
                        <div className="my-battles__header">
                          {/*{intl.formatMessage({ id: 'fee', defaultMessage: 'Fee' })}*/}
                        </div>
                        <div className="my-battles__header">
                          {intl.formatMessage({ id: 'StatusAsStateAlias', defaultMessage: 'Status' })}
                        </div>
                      </div>
                      <div className="my-battles__values">
                        <div className="my-battles__text">
                          <b>
                            {battle.datestart
                              ? moment(battle.datestart)
                                .format('D MMM - HH:mm')
                                .toString()
                              : intl.formatMessage({ id: 'now', defaultMessage: 'Now' })}
                          </b>
                        </div>
                        <div className="my-battles__text">
                          {/*{battle.cost === 0
                            ? intl.formatMessage({ id: 'without_bet', defaultMessage: 'Free' })
                            : `${battle.cost}${battle.currencysymbol || ''}`
                          }*/}
                        </div>
                        <div className="my-battles__text">
                          {status}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!(['INDIVIDUAL', 'PINFALL_BATTLE'].includes(battle.tournamenttypebrief) &&
                 !battle.isConfirmed &&
                 (battle.competitors.find(c => users.user.id === c.userid)||{}).confirmedbyowner===1 &&
                 (battle.competitors.find(c => users.user.id === c.userid)||{}).confirmedbyuser===0) &&
                 <>{((['INDIVIDUAL', 'PINFALL_BATTLE'].includes(battle.tournamenttypebrief) && battle.isConfirmed) || (battle.tournamenttypeid == 2)) && <>
                  {battle.competitors.map(player => {
                    if (player.confirmedbyowner === 1 && player.confirmedbyuser === 1 && player.userid !== users.user.id) {
                      return <Opponent
                        key={player.userid}
                        userid={player.userid}
                        fullname={`${player.firstname} ${player.lastname}`}
                        nickname={player.login}
                        photo={player.photo}
                        rating={+player.ratingsavg || 0}
                        flag={player.country.toLowerCase()}
                        onMessageClick={() => this.handleMessageToOpponent(player)}
                        onAddClick={() => this.handleAddOpponent(player)}
                        avgscore={(+player.avgscore || 0).toFixed()}
                      />;
                    }
                    return null;
                  })}
                </>}
                {(['INDIVIDUAL', 'PINFALL_BATTLE'].includes(battle.tournamenttypebrief)
                  && !battle.isConfirmed && battle.createdby !== users.user.id) && <>
                  {battle.competitors.map((player) => {
                    if (player.userid === battle.createdby) {
                      return <Opponent
                        key={player.userid}
                        userid={player.userid}
                        fullname={`${player.firstname} ${player.lastname}`}
                        nickname={player.login}
                        photo={player.photo}
                        rating={+player.ratingsavg || 0}
                        flag={player.country.toLowerCase()}
                        onMessageClick={() => this.handleMessageToOpponent(player)}
                        onAddClick={() => this.handleAddOpponent(player)}
                        avgscore={(+player.avgscore || 0).toFixed()}
                      />;
                    }
                    return null;
                  })}
                </>}</>}
                {['INDIVIDUAL', 'PINFALL_BATTLE'].includes(battle.tournamenttypebrief)
                  && !battle.isConfirmed
                  && battle.competitors.filter(competitor => competitor.confirmedbyuser === 1 && competitor.confirmedbyowner !== -1).length > 1
                  && users.user.id === battle.createdby
                  && <>
                  <div className={`my-battles_extender ${exposedBattles[battle.id] ? 'down' : 'up'}`}>
                    <Button
                      size="xs"
                      bordered="true"
                      kind="aquamarine"
                      className={`my-battles_collapsable ${exposedBattles[battle.id] ? 'down' : 'up'}`}
                      onClick={() => {
                        const newExposedBattles = { ...exposedBattles };
                        newExposedBattles[battle.id] = !exposedBattles[battle.id];
                        this.setState({ exposedBattles: newExposedBattles });
                      }}
                      >
                      <p>{intl.formatMessage({ id: 'MyBattlesChooseRival', defaultMessage: 'Choose rival' })}</p>
                      <span>{battle.competitors.filter(competitor => competitor.confirmedbyuser === 1 && competitor.confirmedbyowner !== -1).length - 1}</span>
                    </Button>
                  </div>
                  <Collapsible isCollapsed={!exposedBattles[battle.id]}>
                      {battle.competitors.map((player) => {
                        if (player.userid !== users.user.id && player.confirmedbyuser === 1 && player.confirmedbyowner !== -1) {
                          return (
                            <Opponent
                              key={player.userid}
                              userid={player.userid}
                              fullname={`${player.firstname} ${player.lastname}`}
                              nickname={player.login}
                              photo={player.photo}
                              flag={player.country.toLowerCase()}
                              rejectText={intl.formatMessage({ id: 'Decline', defaultMessage: 'Decline' })}
                              acceptText={intl.formatMessage({ id: 'Accept', defaultMessage: 'Accept' })}
                              rating={+player.ratingsavg || 0}
                              onRejectClick={
                                battle.createdby === users.user.id
                                  ? () => this.handleResponseToUser(player.userid, battle.tournamentid, -1)
                                  : () => this.handleResponseToOwner(player.userid, battle.tournamentid, -1)
                              }
                              onAcceptClick={
                                battle.createdby === users.user.id
                                  ? () => this.handleResponseToUser(player.userid, battle.tournamentid, 1)
                                  : () => this.handleResponseToOwner(player.userid, battle.tournamentid, 1)
                              }
                              onMessageClick={() => this.handleMessageToOpponent(player)}
                              onAddClick={() => this.handleAddOpponent(player)}
                              avgscore={(+player.avgscore || 0).toFixed()}
                            />
                          );
                        }
                        return null;
                      })}
                    </Collapsible>
                </>}
                {['INDIVIDUAL', 'PINFALL_BATTLE'].includes(battle.tournamenttypebrief)
                  && !battle.isConfirmed
                  && (battle.competitors.find(c => users.user.id === c.userid) || {}).confirmedbyowner === 1
                  && (battle.competitors.find(c => users.user.id === c.userid) || {}).confirmedbyuser === 0
                  && <>
                  <Collapsible isCollapsed={false}>
                      {battle.competitors.map((player) => {
                        if (player.userid !== users.user.id) {
                          return (
                            <Opponent
                              key={player.userid}
                              userid={player.userid}
                              fullname={`${player.firstname} ${player.lastname}`}
                              nickname={player.login}
                              photo={player.photo}
                              rating={+player.ratingsavg || 0}
                              flag={player.country.toLowerCase()}
                              rejectText={intl.formatMessage({ id: 'Decline', defaultMessage: 'Decline' })}
                              acceptText={intl.formatMessage({ id: 'Accept', defaultMessage: 'Accept' })}
                              onRejectClick={
                                battle.createdby === users.user.id
                                  ? () => this.handleResponseToUser(player.userid, battle.tournamentid, -1)
                                  : () => this.handleResponseToOwner(player.userid, battle.tournamentid, -1)
                              }
                              onAcceptClick={
                                battle.createdby === users.user.id
                                  ? () => this.handleResponseToUser(player.userid, battle.tournamentid, 1)
                                  : () => this.handleResponseToOwner(player.userid, battle.tournamentid, 1)
                              }
                              onMessageClick={() => this.handleMessageToOpponent(player)}
                              onAddClick={() => this.handleAddOpponent(player)}
                              avgscore={(+player.avgscore || 0).toFixed()}
                            />
                          );
                        }
                        return null;
                      })}
                    </Collapsible>
                </>}
              </div>
            </>})}</div>
            :
            <div className="my-battles__empty_container">
              <div className="my-battles__no_data_found">
                <div className="my-battles__no_data_found__image">
                  <img src={no_battles} alt=""/>
                </div>
                <div className="my-battles__no_data_found__description">
                  {intl.formatMessage({ id: 'NoBattlesFoundAlterDescription', defaultMessage: 'You do not have Battle. You can create a battle or respond to an existing one.' })}
                </div>
                <div className="my-battles__no_data_found__button create" onClick={this.onCreateBattle}>
                  <div className="my-battles__no_data_found__button__icon">
                    <SvgSwords/>
                  </div>
                  <div className="my-battles__no_data_found__button__text">
                    {intl.formatMessage({ id: 'create_battle', defaultMessage: 'Create Battle' })}
                  </div>
                </div>
              </div>
            </div>
          }

        </div>
      </div>
    );
  }
}

export default injectIntl(withRouter(
  connect(
    state => ({
      users: state.users,
      battles: state.battles.mybattles,
      dialog: state.events.dialog,
      currgame: state.events.game.gamedata,
      battlesBattleCancelFetch: (state.battles.battlesBattleCancel||{}).fetching || state.tournaments.isFetching,
      rooms: state.chat.rooms,
    }),
    {
      BattlesFetch,
      BattlesResponseToUserFetch,
      BattlesResponseToOwnerFetch,
      BattlesBattleCancelFetch,
      showDialog,
      closeDialog,
      setCreateBattleQuery,
      createPrivateRoom,
      setCurrentRoom,
    },
  )(MyBattles),
));
