import React, {useEffect, useState} from 'react';
import axios from "axios";
import config from '../../../config'
import ReferralTR from "./referral_components/table/referralTR";
import './syle.scss'
import HeaderTR from "./referral_components/table/headerTR";
import 'antd/lib/upload/style/index.css';

const ReferralComponent = () => {
    const [reserveData, setReserveData] = useState([]);
    const [renderData, setRenderData] = useState([]);

    async function getData() {
       const response = await axios.get(`${config.FETCH.url}/users/referral_data`, {headers: {'X-Requested-With': 'XMLHttpRequest'}})
        response.data.data.reverse()
        setReserveData(response.data.data)
        setRenderData(response.data.data)
    }

    useEffect(() => {
        getData()
    }, []);

    function bonusPaidSwitch(id, state) {
        setRenderData(renderData.map(data => {
            if(data.id === id){
                data.is_awarded = state
                return data
            }
            return data
        }))
        setReserveData((reserveData.map(data => {
            if(data.id === id){
                data.isAwarded = state
                return data
            }
            return data
        })))
    }

    return (
            <table className={'referral_table_container'}>
                <tbody className={'referral-table'}>
                <HeaderTR setRenderData={setRenderData} reserveData={reserveData} renderData={renderData}/>
                {renderData.map((data) => <ReferralTR key={data.id} trData={data} bonusPaidSwitch={bonusPaidSwitch}/>)}
                </tbody>
            </table>
    );
};

export default ReferralComponent;
