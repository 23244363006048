import { call, put, select } from 'redux-saga/effects';
import bugsnagClient from '../../bugsnagClient';
import config from '../../config';
import { get, post } from '../Networking';

const {
  SET_COOKIE_AGREEMENT,
} = require('../reducers/actiontypes').default;

export const getUser = state => state.users.user;

export function* fetchCookieAgreement() {
  try {
    // получение данных о согласии с куками
    const result = yield call(get, { url: `${config.FETCH.url}/user/cookie_agreement` });
    const user = yield select(getUser);
    // если пользователь залогинен (user.id не пустой)
    if (result.status === 200 && result.data.success && user.id) {
      // то обновить состояние
      yield put({
        type: SET_COOKIE_AGREEMENT,
        payload: {
          userid: user.id,
          confirmed: result.data.confirmed,
        },
      });
    }
  } catch (error) {
    bugsnagClient.notify(error);
  }
}

/**
 * 
 * @param {{
 *   type: string,
 *   payload: {
 *     confirmed: boolean,
 *   },
 * }} action 
 */
export function* sendCookieAgreement(action) {
  try {
    console.log(`[${new Date().toISOString().slice(11, -1)}] sendCookieAgreement.action.payload: `, action.payload);
    // отправить согласие / отказ с соглашение об использовании куков
    const result = yield call(post, { url: `${config.FETCH.url}/user/cookie_agreement`, data: action.payload });
    console.log(`[${new Date().toISOString().slice(11, -1)}] sendCookieAgreement.result: `, { data: result.data, status: result.status }, result.status === 200 && result.data.success);
    // в случае удачной обработки запроса
    if (result.status === 200 && result.data.success) {
      const user = yield select(getUser);
      // обновить состояние согласно переданным данным (action.payload)
      console.log(`[${new Date().toISOString().slice(11, -1)}] sendCookieAgreement, SET_COOKIE_AGREEMENT: `, action.payload);
      yield put({
        type: SET_COOKIE_AGREEMENT,
        payload: {
          userid: user.id,
          confirmed: action.payload.confirmed,
        },
      });
    }
  } catch (error) {
    console.log(`[${new Date().toISOString().slice(11, -1)}] error: `, error);
    bugsnagClient.notify(error);
  }
}