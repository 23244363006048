/* eslint-disable */
import React, {useEffect, useState} from "react";
import CheckBox from "../common/Checkbox";
import { connect } from 'react-redux';
import videoPreloader from "../../images/video_preloader.svg";
import config from '../../config';

const StreamToggler = (props) => {
  const {id, bowlingcenters, toggleStream, fetchBowlingCenters, bowlingcentersFetching, toggleStreamFetch} = props;
  const bowlingcenter = (bowlingcenters.filter(bc => bc.id === id) || [])[0];
  const [active, setActive] = useState((bowlingcenter || {}).stream_enabled === 1);
  const [url, setUrl] = useState('')

  useEffect(() => {
    if (bowlingcenters) {
      setActive((bowlingcenter || {}).stream_enabled === 1);
      console.log('bowlingcenter', bowlingcenter)
      setUrl(`${config.FETCH.fullUrl}/stream/${((bowlingcenter || {}).name || '').toLowerCase().replace(/ +/g, "")}`);
    }
  }, [id, bowlingcenters]);

  useEffect(() => {
    if (!toggleStreamFetch) fetchBowlingCenters();
  }, [toggleStreamFetch])

  const onChange = () => {
    if (bowlingcenter) toggleStream(bowlingcenter.id);
  }

  return (
    <div className={"enable_stream__button"}>
      <CheckBox checked={!!active} onChange={() => onChange()}>
        <div className={"enable_button__text"}>Streaming online</div>
      </CheckBox>
      {active && url && <a href={url} className={"stream__link"}> {url} </a>}
      {toggleStreamFetch ||bowlingcentersFetching && <img src={videoPreloader} style={{width: '28px', height: '28px'}}/>}
    </div>
  )
}

export default connect(
  state => (
    {
      bowlingcenters: state.bowlingcenters.bowlingcenters.data,
      bowlingcentersFetching: state.bowlingcenters.bowlingcenters.fetching,
      toggleStreamFetch: state.bowlingcenters.toggleStreamFetch,
    }
  ),
  {
    toggleStream: (id) => ({type: 'TOGGLE_STREAM_FETCH', id}),
    fetchBowlingCenters: () => ({type: 'BOWLING_CENTERS_FETCH'}),
  },
) (StreamToggler)
