const { LOGOUT } = require('./actiontypes').default;

export const FRIENDS_FETCH = 'FRIENDS_FETCH';
export const FRIENDS_SET = 'FRIENDS_SET';
export const FRIEND_DELETE = 'FRIEND_DELETE';
export const FRIEND_ADD_FETCH = 'FRIEND_ADD_FETCH';
export const FRIEND_ADD_SET = 'FRIEND_ADD_SET';
export const FRIEND_ACCEPT = 'FRIEND_ACCEPT';
export const FRIEND_DECLINE = 'FRIEND_DECLINE';
export const FRIEND_SEARCH_FETCH = 'FRIEND_SEARCH';
export const FRIEND_SEARCH_SET = 'FRIEND_SEARCH_SET';

export const GetFriends = () => ({ type: FRIENDS_FETCH });
export const AddFriend = params => ({ type: FRIEND_ADD_FETCH, params });
export const DeleteFriend = params => ({ type: FRIEND_DELETE, params });
export const AcceptFriend = params => ({ type: FRIEND_ACCEPT, params });
export const DeclineFriend = params => ({ type: FRIEND_DECLINE, params });
export const SearchPlayers = params => ({ type: FRIEND_SEARCH_FETCH, params });

const initialState = {
  friends: {
    data: [],
    error: '',
    fetching: false,
  },
  playersearch: {
    data: [],
    error: '',
    fetching: false,
  },
  friendAdd: {
    userid: 0,
    data: [],
    error: '',
    fetching: false,
  },
};

const FriendsReduсer = (state = initialState, action) => {
  switch (action.type) {
    case FRIEND_DELETE:
      return {
        ...state,
        friends: {
          data: state.friends.data.filter(item => item.user.id !== action.params, null, 2),
          error: action.error || '',
          fetching: false,
        },
      };
    case FRIEND_ACCEPT:
      return {
        ...state,
        friends: {
          ...state.friends,
          fetching: true,
          acceptedUser: action.params,
        },
      };
    case FRIEND_DECLINE:
      return {
        ...state,
        friends: {
          ...state.friends,
          fetching: true,
          declinedUser: action.params,
        },
      };
    case FRIENDS_FETCH:
      return {
        ...state,
        friends: {
          data: action.data || [],
          error: '',
          fetching: true,
        },
      };
    case FRIENDS_SET:
      return {
        ...state,
        friends: {
          data: action.data,
          error: action.error || '',
          fetching: false,
        },
      };
    case FRIEND_SEARCH_FETCH:
      return {
        ...state,
        playersearch: {
          data: [],
          error: '',
          fetching: true,
        },
      };
    case FRIEND_SEARCH_SET:
      return {
        ...state,
        playersearch: {
          data: action.data || [],
          error: action.error || '',
          fetching: false,
        },
      };
    case FRIEND_ADD_FETCH:
      const userForAdding = {
        user: {
          id: action.params.id,
          login: action.params.login,
          firstname: action.params.firstname,
          lastname: action.params.lastname,
          country: action.params.country_live,
          gender: action.params.gender,
          photo: action.params.photo,
          active: action.params.active,
          ratingsavg: action.params.ratingsavg,
          avgscore: action.params.avgscore,
        },
        confirmed: 1,
        ordertype: 'in'
      }
      return {
        ...state,
        friends: {
          data: [ ...state.friends.data, userForAdding ],
          error: action.error || '',
          fetching: false,
        },
      };
    case FRIEND_ADD_SET:
      return {
        ...state,
        friendAdd: {
          ...state.friendAdd,
          data: action.data || {},
          error: action.error || '',
          fetching: false,
        },
      };
    case LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};

export default FriendsReduсer;
