import React from 'react';

import './style.scss';

const BreakText = (props) => {
  if (typeof props.children !== 'string') {
    return null;
  }
  return props.children.split(/\n/g).map((text, index, array) => (<>
    <span key={index} className="break-text">{text}</span>
    {index === (array.length - 1) ? null : <br />}
  </>));
};

export default BreakText;
