import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isArray } from 'lodash';
import { Modal } from 'antd';
import { injectIntl, intlShape } from 'react-intl';
import './style.css';

const Group = ({ children }) => <div className="modal-group">{children}</div>;
Group.propTypes = {
  children: PropTypes.element.isRequired,
};

class OBModal extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    width: PropTypes.number,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    okText: PropTypes.string,
    cancelText: PropTypes.string,
    okButtonProps: PropTypes.object,
    cancelButtonProps: PropTypes.object,
    confirmLoading: PropTypes.bool,
    zIndex: PropTypes.number,
    footer: PropTypes.oneOfType(
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ),
    children: PropTypes.oneOfType(
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ),
    closable: PropTypes.bool,
    intl: intlShape,
    style: PropTypes.object,
    noMask: PropTypes.bool,
  };

  static defaultProps = {
    okText: null,
    cancelText: null,
    okButtonProps: { className: 'button button_aquamarine' },
    cancelButtonProps: {
      className: 'button button_transparent button_aquamarine',
    },
    closable: true,
    confirmLoading: false,
  };

  constructor() {
    super();
    this.state = {
      noClose: false,
    };
    this.mouseDown = this.mouseDown.bind(this);
    this.mouseUp = this.mouseUp.bind(this);
  }

  // TODO: find best solution
  componentDidMount() {
    document.addEventListener('mousedown', this.mouseDown, false);
    document.addEventListener('mouseup', this.mouseUp, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.mouseDown, false);
    document.removeEventListener('mouseup', this.mouseUp, false);
  }

  mouseDown = e => {
    const { visible } = this.props;
    if (visible) {
      this.e = e;
      this.setState({ noClose: true });
    }
  };

  mouseUp = e => {
    const { visible } = this.props;
    if (visible && this.e.target !== e.target) {
      this.timer = setTimeout(() => {
        this.setState({ noClose: false });
        clearTimeout(this.timer);
        this.timer = null;
      }, 0);
    } else {
      this.setState({ noClose: false });
    }
  };

  render() {
    const {
      title,
      visible,
      width,
      onOk,
      onCancel,
      okText,
      cancelText,
      okButtonProps,
      cancelButtonProps,
      footer,
      children,
      closable,
      zIndex,
      intl,
      style,
      noMask,
      className,
      forceRender,
      wrapClassName,
      loading, // загрузка контента
      confirmLoading,
    } = this.props;
    const { noClose } = this.state;
    const childArray = isArray(children) ? children : [children];
    return (
      <div>
        <Modal
          wrapClassName={wrapClassName}
          title={title}
          visible={visible}
          forceRender={forceRender}
          width={width}
          onOk={onOk}
          onCancel={noClose ? () => false : onCancel}
          okText={
            okText ||
            intl.formatMessage({ id: 'ModalDefaultOK', defaultMessage: 'OK' })
          }
          cancelText={
            cancelText ||
            intl.formatMessage({
              id: 'ModalDefaultCancel',
              defaultMessage: 'Cancel',
            })
          }
          okButtonProps={okButtonProps}
          cancelButtonProps={cancelButtonProps}
          footer={footer}
          closable={closable}
          zIndex={zIndex}
          style={style}
          mask={!noMask}
          className={className}
          confirmLoading={confirmLoading}
        >
          {childArray.map((GroupItem, index) => (
            <Group key={index}>{GroupItem}</Group>
          ))}
          {/* Загрузка информации в модальном окне */}
          {loading && (
            <div className="loading">
              Loading
              <span className="loading-i">
                <i>.</i>
                <i>.</i>
                <i>.</i>
              </span>
            </div>
          )}
        </Modal>
      </div>
    );
  }
}

export default injectIntl(OBModal);
