import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import OBModal from '../../Modal';
import './style.scss';
import redCardBig from '../../../../images/redCardBig.png';
import { Button } from '../../Button';
import { markRedCardAsSeen } from '../../../../libs/reducers/squadScreenReducer'

class RedCardModal extends React.Component {
  handleClose = () => {
    this.props.markRedCardAsSeen();
  };

  render() {
    const { intl, redCard, markRedCardAsSeen } = this.props;

    return (
      <OBModal
        title={intl.formatMessage({ id: 'RedCard', defaultMessage: 'Red Card!' })}
        visible={redCard}
        width={395}
        onOk={() => this.handleClose()}
        onCancel={() => this.handleClose()}
        zIndex={10002}
        footer={
          <Button
            key="okButton"
            kind="aquamarine"
            onClick={() => {
              this.handleClose()
            }}>Ок</Button>}>
        <img className='red-card-image' src={redCardBig}/>
        <div className='red-card-caption'>
          {intl.formatMessage({
            id: 'RedCardSquadModal',
            defaultMessage: `You received a red card for delaying the game again by more than five minutes.
              You have been awarded a technical defeat.`,
          })}
        </div>
      </OBModal>
    );
  }
}

export default injectIntl(
  connect(
    state => ({
      redCard: state.squadScreen.redCardEvent,
    }),
    {
      markRedCardAsSeen,
    },
  )(RedCardModal),
);
