import { put, call } from 'redux-saga/effects';
import bugsnagClient from '../../bugsnagClient';
import config from '../../config';
import { get } from '../Networking';
import { setVideoArchivesError, setVideoArchivesAll } from '../reducers/actions';

const { SET_VIDEO_ARCHIVES } = require('../reducers/actiontypes').default;

// const getVideos = state => state.videos.archives;

/**
 * @param {{ type: string, payload: string[] }} param0
 */
export function* fetchVideoArchivesAll({ payload: streams }) {
  try {
    let url = `${config.FETCH.url}/flussonic/dvr`;
    const query = streams.map(stream => `stream=${stream}`);
    url += query.length ? `?${query.join('&')}` : '';
    const result = yield call(get, { url });
    if (result.status === 200 && result.data.success) {
      yield put(setVideoArchivesAll(result.data.data));
    } else {
      yield put(setVideoArchivesError(result.data.error || result.status));
    }
  } catch (error) {
    bugsnagClient.notify(error, {
      context: 'fetchVideoArchivesAll',
      beforeSend: (report) => {
        report.updateMetadata('streams', { streams });
      },
    });
    yield put(setVideoArchivesError(error.message))
  }
}

export default function* _getVideoArchives(params) {
  // const archives = yield select(getVideos);
  const { stream, day = 0 } = params.params;
  try {
    const url = `${config.FETCH.url}/flussonic/dvr/${stream}?day=${day}`;
    const result = yield call(get, { url });
    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_VIDEO_ARCHIVES, payload: { stream, archives: result.data.data } });
    } else {
      yield put({ type: SET_VIDEO_ARCHIVES, error: result.data.error || result.status });
    }
  } catch (err) {
    yield put({ type: SET_VIDEO_ARCHIVES, error: err });
  }
}
