import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Select from '../common/Select';
import BowlingCentersCamera from './bowlingCentersCamera';
import './bowlingCentersView.css';
import StreamToggler from "../StreamToggler/StreamToggler";

class BowlingCentersView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bowlingcenter: [null],
      lane: [null],
      lanes: [null],
      bowlingcenters: this.mapBowlingCenters(),
    };
  }

  static propTypes = {
    intl: intlShape,
    bowlingcenters: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      lines: PropTypes.number,
      linesstructure: PropTypes.arrayOf(PropTypes.shape({
        linenumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        cameras: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.number,
          ip: PropTypes.string,
        })),
      })),
    })),
    bowlingcentersFetching: PropTypes.bool,
    bowlingcentersIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    bowlingCenterPermissionsFetching: PropTypes.bool,
    permissions: PropTypes.shape({
      keys: PropTypes.arrayOf(PropTypes.string),
      fetch: PropTypes.bool,
    }),
  }

  static defaultProps = {
    bowlingcenters: [],
    bowlingcentersFetching: false,
    bowlingcentersIds: [],
    bowlingCenterPermissionsFetching: false,
    permissions: { keys: [], fetch: false },
  }

  static filterBowlingCenters = (bowlingcenters, ids) => {
    if (ids.length === 0 || bowlingcenters.length === 0) {
      return [];
    }
    const result = [];
    for (const bc of bowlingcenters) {
      if (ids.includes(bc.id)) {
        result.push(bc);
      }
    }
    return result;
  }

  componentDidMount() {
    const {bowlingcenters, permissions, bowlingcentersFetching} = this.props;
    if (!permissions.fetch && permissions.keys.length === 0) {
      this.props.fetchPermissions();
    }
    if (!bowlingcentersFetching && bowlingcenters.length === 0) {
      this.props.fetchBowlingCenters();
    }
    if (bowlingcenters.length && permissions.keys.length && !this.checkPermissions()) {
      // redirect to Main page
      this.props.history.push('/lobby');
    }
  }

  componentDidUpdate(prevProps) {
    const { permissions, bowlingcentersFetching, bowlingCenterPermissionsFetching } = this.props;
    if ((permissions.keys.length || (prevProps.permissions.fetch && !permissions.fetch)) && !this.checkPermissions()) {
      // redirect to Main page
      this.props.history.push('/lobby');
    }
    const checked = this.checkPermissions();
    if (((
        !permissions.fetch && prevProps.permissions.fetch
    ) || (
        !bowlingcentersFetching && prevProps.bowlingcentersFetching
    ) || (
        !bowlingCenterPermissionsFetching && prevProps.bowlingCenterPermissionsFetching
    )) && checked) {
      const bowlingcenters = this.mapBowlingCenters();
      /*this.autoSelect(bowlingcenters);*/
      this.setState({ bowlingcenters});
    } else if (!checked) {
      // redirect to Main page
      this.props.history.push('/lobby');
    }
  }

  autoSelect = (bowlingcenters) => {
    if (bowlingcenters.length === 1) {
      const bowlingcenter = bowlingcenters[0];
      const lanes = this.mapLanes(bowlingcenter);
      const lane = lanes.length === 1 ? lanes[0] : null;
      console.log('autoselect', bowlingcenter, lane)
      this.setState({
        bowlingcenter: [bowlingcenter], lane: [lane], lanes: [[lanes]],
      });
    }
  }

  autoSelectLane = (bowlingcenter, index) => {
    const lanes = this.state.lanes;
    lanes[index] = this.mapLanes(bowlingcenter);
    const line = this.state.lane;
    line[index] = lanes[index].length === 1 ? lanes[index][0] : null
    this.setState({ lanes, lane: line });
  }

  checkPermissions = () => {
    const { bowlingcentersIds, permissions } = this.props;
    return permissions.keys.includes('WATCH_ALL_BOWLINGCENTERS') || !!bowlingcentersIds.length;
  }

  mapBowlingCenters = () => {
    const { bowlingcenters, bowlingcentersIds, permissions } = this.props;
    let result;
    if (permissions.keys.includes('WATCH_ALL_BOWLINGCENTERS')) {
      result = bowlingcenters;
    } else {
      result = BowlingCentersView.filterBowlingCenters(bowlingcenters, bowlingcentersIds);
    }
    return result.map(bc => ({ value: bc.id, label: bc.name, ...bc }));
  }

  mapLanes = (bowlingcenter) => {
    const { linesstructure } = bowlingcenter;
    return linesstructure.map(lane => ({ value: lane.linenumber, label: lane.linenumber, ...lane }));
  }

  _onChangeBowlingCenter = (bowlingcenter, index) => {
    this.autoSelectLane(bowlingcenter, index);
    const lanes = this.state.lanes;
    lanes[index] = bowlingcenter.linesstructure.map(lane => ({
      value: lane.linenumber,
      label: lane.linenumber, ...lane
    }));
    const centers = this.state.bowlingcenter;
    centers[index] = bowlingcenter;
    this.setState({
      bowlingcenter: [...centers],
      lanes: [...lanes],
    });
  }

  _onChangeLane = (lane, index) => {
    const lanes = this.state.lane;
    lanes[index] = lane;
    this.setState({ lane: [...lanes] });
  }

  render() {
    const { intl } = this.props;
    const {
      bowlingcenters = [],
      lanes = [[]],
      bowlingcenter = [],
      lane = [],
    } = this.state;

    console.log('lane', lanes, bowlingcenter)

    const { permissions } = this.props;
    return <div className="bowlingcenters_container">
      <div className={"bowlingcenters_header"}>
        <div className="bowlingcenters_watch">
          {bowlingcenter.map((item, index) =>
              <div className={"bowlingcenters_header"}>
                <Select
                    className="bowlingcenters_list"
                    placeholder={intl.formatMessage({
                      id: 'BowlingCenterSelectPreamubla',
                      defaultMessage: 'Select bowling center'
                    })}
                    value={bowlingcenter[index]}
                    options={bowlingcenters}
                    onChange={data => this._onChangeBowlingCenter(data, index)}
                />
                { lanes[0] &&
                <Select
                    className="bowlingcenters_lanes"
                    placeholder={intl.formatMessage({ id: 'BowlingCenterLine', defaultMessage: 'Lane' })}
                    value={lane[index]}
                    options={lanes[index]}
                    onChange={data => this._onChangeLane(data, index)}
                />
                }

                {permissions.keys.includes('MODIFY_BC_STREAM') && bowlingcenter.length !== 0 && bowlingcenter[index] !== null &&
                <StreamToggler id={bowlingcenter[index].id}/>}
              </div>
          )}
        </div>
      </div>
      <div className='bowlingcenters_control'>
        <div className='bowlingcenters_add'
             onClick={() => this.setState({
               bowlingcenter: [ ...this.state.bowlingcenter, null ],
               lane: [ ...this.state.lane, null ],
               lanes: [ ...this.state.lanes, [] ],
             })}
        >+</div>
        <div className='bowlingcenters_add'
             onClick={() => this.setState({
               bowlingcenter: this.state.bowlingcenter.splice(0, Math.max(this.state.bowlingcenter.length - 1, 1)),
               lane: this.state.lane.splice(0, Math.max(this.state.lane.length - 1, 1)),
               lanes: this.state.lanes.splice(0, Math.max(this.state.lanes.length - 1, 1)),
             })}
             style={{
               position: 'relative',
               bottom: 4,
             }}
        >-
        </div>
      </div>
      {lane.map((line, index) => {
        if (line) {
          const sortCameraByType = (a, b) => {
            if (a.cameravideotypeid > b.cameravideotypeid) return 1;
            if (a.cameravideotypeid == b.cameravideotypeid) return 0;
            if (a.cameravideotypeid < b.cameravideotypeid) return -1;
          }
          console.log('line', line.cameras)
          const bowlCenterName = bowlingcenter[0].label
          const cameras = Array.isArray(line.cameras)
              ? bowlCenterName.includes('STRIKEHOUSE') || bowlCenterName.includes('LA Bowl Ayr')
                  ? line.cameras
                  : line.cameras.sort(sortCameraByType)
              : [];
          return (
              <div className="bowlingcenters_name">
                {`${bowlingcenter[index].label}, ${intl.formatMessage({
                  id: 'BowlingCenterLine',
                  defaultMessage: 'line'
                }).toLowerCase()} ${line.label}`}
                <div className="bowlingcenters_cameras">
                  {cameras.map((camera, idx) => (<BowlingCentersCamera
                      key={`${camera.id}-${camera.cameratypeid}`}
                      intl={intl}
                      className="bowlingcenters_camera_container"
                      idx={idx}
                      bowlingcenterId={bowlingcenter[index].id}
                      laneId={line.id}
                      camera={camera}
                      user={this.props.user}
                  />))}
                </div>
              </div>
          );
        }
      })}
    </div>
  }
}


export default injectIntl(withRouter(
    connect(
        state => (
            {
              langs: state.langs,
              bowlingcenters: state.bowlingcenters.bowlingcenters.data,
              bowlingcentersFetching: state.bowlingcenters.bowlingcenters.fetching,
              permissions: state.users.permissions,
              bowlingcentersIds: state.users.bowlingCenterPermissions ? (state.users.bowlingCenterPermissions.ids || []) : [],
              bowlingCenterPermissionsFetching: state.users.bowlingCenterPermissions ? !!state.users.bowlingCenterPermissions.fetching : false, // eslint-disable-line
              user: state.users.user,
            }
        ),
        {
          toggleStream: (id) => ({type: 'TOGGLE_STREAM_FETCH', id}),
          fetchBowlingCenters: () => ({type: 'BOWLING_CENTERS_FETCH'}),
          fetchBowlingCenterPermissions: () => ({type: 'GET_BOWLINGCENTER_PERMISSIONS'}),
          fetchPermissions: () => ({type: 'GET_PERMISSIONS'}),
        },
    )
    (BowlingCentersView)));
