import React, {useEffect, useState} from 'react';
import config from "../config";

const InviteFriendsScreen = (props) => {
    const [tpcUrl, setTpcUrl] = useState(null);
    let currUrlParam = undefined
    if (window.location.href.split('?').length === 2) currUrlParam = window.location.href.split('?').pop()

   async function getReferralLink() {
       try {
           const url = `${config.FETCH.fullUrl}/referral`;
           const result = await fetch(url, {
               method: 'POST',
               headers: {
                   'Content-Type': 'application/json',
               },
           });

           if (result.ok) {
               const json = await result.json();
               setTpcUrl(json.url.TPC)
           }
       }catch (e) {
           console.error('/REFERRAL error: ',e)
       }
    }

    useEffect(()=> {
        getReferralLink()
    },[])

    useEffect(()=> {
        if (tpcUrl && currUrlParam){
            props.history.push(`/registration/${currUrlParam}`)
        }
    },[tpcUrl])

    return (
        <div>
        </div>
    );
};

export default InviteFriendsScreen;