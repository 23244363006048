import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter, Link } from 'react-router-dom';
import _ from 'lodash';
import { Button } from '../../common/Button';
import Avatar from '../../common/Avatar';
import OBModal from '../../common/Modal';
import { 
  UserByIdFetch
} from '../../../libs/reducers/actions';

import './style.scss';

class ModalProtocolDecision extends Component {

  constructor(props) {

    super(props);

    this.state = {

    }

  }

  showUser = function (userid) {
    this.props.UserByIdFetch({
      userid: userid,
    });
  }

  render() {
    const { intl, failed, winner, loser, winner_score, loser_score } = this.props;
    const isDraw = winner_score == loser_score;
    const isFail = failed;
    const translate = {
      title: intl.formatMessage({ id: 'MakeResolve', defaultMessage: 'Made decision' }),
      cancel: intl.formatMessage({ id: 'CancelAsAbortAlias', defaultMessage: 'Cancel' }),
      ok: intl.formatMessage({ id: 'Yes', defaultMessage: 'Yes' }),
      draw: intl.formatMessage({ id: 'DecisionResultDraw', defaultMessage: 'Draw, end review?' }),
      fail: intl.formatMessage({ id: 'DecisionResultFail', defaultMessage: 'Game unhelded, end review?' }),
      win: intl.formatMessage({ id: 'DecisionResultWin', defaultMessage: 'Player {firstname} {lastname} will become a winner, end review?' }, {
        firstname: winner.userfirstname,
        lastname: winner.userlastname
      }),
    }
    return (
      <OBModal
        title={translate.title}
        visible
        width={394}
        onOk={() => {}}
        onCancel={() => {}}
        className="protocol_decision_modal"
        closable={false}
        children={[
          <div className="scores">
            <div className="player winner">
              <div className="player_name">
                <div className="player_fullname">
                  {`${winner.userfirstname} ${winner.userlastname}`} 
                </div>
                <div className="player_login">
                  <Link to="#" onClick={(e) => {
                      e.preventDefault();
                      this.showUser(winner.userid)
                    }}
                  >
                    {winner.userlogin} 
                  </Link>
                </div>
              </div>
              <div className="player_avatar">
                <Avatar 
                  uri={winner.userphoto} 
                  flag={winner.usercountry}
                />
              </div>
              <div className="player_score">
                {winner_score}
              </div>
            </div>
            {loser &&
              <div className="player loser">
                <div className="player_name">
                  <div className="player_fullname">
                    {`${loser.userfirstname} ${loser.userlastname}`} 
                  </div>
                  <div className="player_login">
                    <Link to="#" onClick={(e) => {
                      e.preventDefault();
                      this.showUser(winner.userid)
                    }}
                    >
                      {loser.userlogin} 
                    </Link>
                  </div>
                </div>
                <div className="player_avatar">
                  <Avatar 
                    uri={loser.userphoto} 
                    flag={loser.usercountry}
                    />
                </div>
                <div className="player_score">
                  {loser_score}
                </div>
              </div>
            }
          </div>,
          <div className="decision-result">
            { isFail ? translate.fail : isDraw ? translate.draw : translate.win }
          </div>
        ]}
        footer={[
          <div>
            <Button style={{ width: '172px' }}
              kind="aquamarine"
              bordered="true"
              onClick={() => { this.props.onReject() }}
            >
              {translate.cancel}
            </Button>
            <Button style={{ width: '172px' }}
              kind="aquamarine"
              onClick={() => { this.props.onAccept() }}
            >
              {translate.ok}
            </Button>
          </div>
        ]}
      />
    );
  }
}

export default injectIntl(withRouter(
  connect(
    state => ({ 
      
    }),
    { 
      UserByIdFetch
    },
  )(ModalProtocolDecision),
));
