/* eslint-disable import/prefer-default-export */

import config from '../../config';
import { store } from '../../store';

/**
 * @typedef {{ kind?: 'TC' }} IPolicyOptions
 */

/** @param {IPolicyOptions} [options] */
export const getPolicyURL = (options) => {
  const { kind = 'TC' } = options || {};
  const { langs } = store.getState();
  const lang = (langs.lang || 'en').toLowerCase() === 'ru' ? 'ru' : 'en';
  const url = `${config.FETCH.img}/agreements/${kind}${lang}.pdf`;
  return url;
};

/** @param {IPolicyOptions} [options] */
export const openPolicy = (options) => {
  const url = getPolicyURL(options);
  window.open(url);
};
