
const {
  ADD_MODAL,
  REMOVE_MODAL,
  OPEN_MODAL,
  CLOSE_MODAL,
  CHECK_ACTIVE_MODAL,
  CHECK_NEXT_MODAL,
} = require('./actiontypes').default;

const initialState = {
  stack: [],
  visibles: [],
  opened: [],
  name: null,
  checking: false,
  nextChecking: false,
};

/** @param {string} name */
export const modalOpenAction = (name) => ({ type: 'MODAL_OPEN_V2', params: { name } })
/** @param {string} name */
export const modalOpenedAction = (name) => ({ type: 'MODAL_OPENED_V2', params: { name } })

/** @param {string} name */
export const modalCloseAction = (name) => ({ type: 'MODAL_CLOSE_V2', params: { name } })
/** @param {string} name */
export const modalClosedAction = (name) => ({ type: 'MODAL_CLOSED_V2', params: { name } })

export const modalFlushAction = () => ({ type: 'MODAL_FLUSH_V2' })

/** @return {string[]} */
export const selectModalShown = (state) => state.modals.shown
/** @return {string[]} */
export const selectModalVisibles = (state) => state.modals.visibles

export default (state = initialState, action) => {
  const { type, payload } = action;
  let name;
  if (typeof payload === 'string') {
    name = payload;
  } else if (payload) {
    ({ name } = payload);
  }
  const { stack } = state;
  let index;
  switch (type) {
    case 'CLEAN_MODAL':
      return JSON.parse(JSON.stringify(initialState));
    case ADD_MODAL:
      return { ...state, stack: [...stack, name].filter((elm, idx, arr) => arr.indexOf(elm) === idx && elm !== state.name), checking: true };
    case OPEN_MODAL:
      return { stack: stack.slice(1), name: stack[0], nextChecking: false, shown: state.shown, visibles: state.visibles };
    case CLOSE_MODAL:
      return { ...state, name: null, checking: false, nextChecking: stack.length > 0 };
    case 'MODAL_OPEN_V2':
      if (state.visibles.includes(action.params.name)) {
        return state;
      }
      return {
        ...state,
        visibles: [...state.visibles, action.params.name].filter((name, index, array) => array.indexOf(name) === index),
      }
    case 'MODAL_CLOSE_V2':
      if (!state.visibles.includes(action.params.name)) {
        return state;
      }
      return {
        ...state,
        visibles: state.visibles.filter(name => name !== action.params.name),
      }
    case 'MODAL_OPENED_V2':
      if (state.opened.includes(action.params.name)) {
        return state;
      }
      return {
        ...state,
        opened: [...state.opened, action.params.name],
      }
    case 'MODAL_CLOSED_V2':
      if (!state.opened.includes(action.params.name)) {
        return state;
      }
      return {
        ...state,
        opened: state.opened.filter(name => name !== action.params.name),
      }
    case 'MODAL_FLUSH_V2':
      if (!state.opened[0] || state.opened[0] === state.visibles[0]) {
        return state;
      }
      return {
        ...state,
        opened: state.opened.slice(1),
      }
    case REMOVE_MODAL:
      index = state.stack.indexOf(name);
      return { ...state, stack: [...stack.slice(0, index), ...stack.slice(index + 1)] };
    case CHECK_ACTIVE_MODAL:
      return { ...state, checking: false };
    case CHECK_NEXT_MODAL:
      return { ...state, nextChecking: false };
    default:
      return state;
  }
}
