import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import config from '../../config';
import { getTokenData } from '../../libs/reducers/actions';

class PaymentForm extends Component {
    constructor(props) {
        super(props);

        this.form = React.createRef();
    }

    static propTypes = {
        iterator: PropTypes.bool,
        destination: PropTypes.string,
        customOrder: PropTypes.string,
        tokenCryptedData: PropTypes.shape(),
    };

    buildFormData() {
      this.props.getTokenData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tokenCryptedData !== this.props.tokenCryptedData && this.form.current &&
            this.props.tokenCryptedData.DATA && this.props.tokenCryptedData.KEY && this.props.tokenCryptedData.SIGNATURE) {
            this.form.current.submit();
        }
        if (prevProps.iterator !== this.props.iterator && this.props.iterator) {
            this.buildFormData();
        }
    }

    render() {
        return (
            <>
            <form action={config.Medoro.url+'token'} method="post" ref={this.form}>
                <input type="hidden" name="INTERFACE" value={config.Medoro.MID} />
                <input type="hidden" name="KEY_INDEX" value={config.Medoro.KEYINDEX} />
                <input type="hidden" name="KEY" value={this.props.tokenCryptedData.KEY} />
                <input type="hidden" name="DATA" value={this.props.tokenCryptedData.DATA} />
                <input type="hidden" name="SIGNATURE" value={this.props.tokenCryptedData.SIGNATURE} />
                <input type="hidden" name="CALLBACK" value={`${config.FETCH.url}/ecom/token_success${!!this.props.destination?`?destination=${this.props.destination}`:''}${this.props.destination&&this.props.customOrder?`&${this.props.customOrder}`:''}${this.props.destination&&this.props.renew?`&renew=${this.props.renew}`:''}`} />
                <input type="hidden" name="ERROR_CALLBACK" value={`${config.FETCH.url}/ecom/token_failure${!!this.props.destination?`?destination=${this.props.destination}`:''}`} />
            </form>
            </>
        )}
}

export default connect(
    state => ({
        tokenCryptedData: state.billing.tokenCryptedData,
    }),
    {getTokenData},
)(PaymentForm);
