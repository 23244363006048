import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  getHandicapList,
  saveHandicapList,
} from '../../../libs/reducers/actions';
import { Button } from '../../common/Button';
import Checkbox from '../../common/Checkbox';

import './style.scss';
import { getActiveHDC, setActiveHDC } from "../../../libs/reducers/commonReducer";

const STANDART = 'standart';
const DYNAMIC_HANDICAP = 'dynamic_handicap';

const HandicapsTab = ({
  handicapList,
  getHandicapList,
  saveHandicapList,
  setActiveHDC,
  getActiveHDC,
  handicapSaving,
  activeHandicap,
}) => {
  const [_handicapList, setHandicapList] = useState(handicapList);
  const [isEdited, setIsEdited] = useState(false);
  const [active, setActive] = useState([]);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const name = {
    [STANDART]: "Women's handicap",
    [DYNAMIC_HANDICAP]: 'Dynamic handicap',
  };

  useEffect(() => {
    getActiveHDC();
  }, [])

  useEffect(() => {
    if (active.length) {
      setHandicapList(_handicapList.map(
        h => (h.id === 1
          ? (active[0] + active[1] + active[2] > 0) ? ({...h, active: 1}) : ({...h, active: 0})
          : ({...h})
        ))
      )
    }
  }, [active[0], active[1], active[2]])

  useEffect(() => {
    if (active.length) {
      setHandicapList(_handicapList.map(
        h => (h.id === 2
          ? (active[3] + active[4] + active[5] > 0) ? ({...h, active: 1}) : ({...h, active: 0})
          : ({...h})
        )
      ))
    }
  }, [active[3], active[4], active[5]])

  useEffect(() => {
    setActive(Array.from(activeHandicap))
  }, [activeHandicap])

  useEffect(() => {
    getHandicapList({ all: 1 });
  }, []);

  useEffect(() => {
    setHandicapList(handicapList);
  }, [handicapList]);

  useEffect(() => {
    if (!handicapSaving) setIsEdited(false);
  }, [handicapSaving]);

  const handleEditVariable = ({ preValue, newValue, handicapId, varId }) => {
    if (preValue === newValue) return;

    setHandicapList(_handicapList.map((h) => {
      if (h.id !== handicapId) return h;
      h.variables = h.variables.map(
        v => (v.id === varId
          ? ({
            ...v,
            value: +newValue,
          })
          : v)
      );

      return h;
    }));
    setIsEdited(true);
  };

  const handleEditValue = ({ preValue, newValue, handicapId, key }) => {
    if (preValue === newValue) return;
    setHandicapList(_handicapList.map(
      h => (h.id === handicapId
        ? ({
          ...h,
          [key]: newValue,
        })
        : h)
    ));
    setIsEdited(true);
  };

  const setActiveArray = (index) => {
    setIsEdited(true)
    let array = active;
    array[index] = array[index] == 0 ? 1 : 0;
    setActive(array);
    forceUpdate();
  }

  const sendCheckboxes = () => {
    setActiveHDC({ payload: `${active[0]}${active[1]}${active[2]}${active[3]}${active[4]}${active[5]}` });
    setIsEdited(false)
  }

  return (
    <div className='admin_panelWrapper'>
      <div className="ap-tab-container-550">

        {(active && _handicapList && _handicapList.map((handicap, index) => (
          <div className="handicap__container" key={handicap.id}>
            <div className="handicap__property">
              <div className="handicap__property-name">
                Name:
              </div>
              <div className="handicap__property-value">
                {name[handicap.handicap_type] || handicap.handicap_type}
              </div>
            </div>
            <div className="handicap__property">
              <div className="handicap__property-name">
                Static value:
              </div>
              <div className="handicap__property-value">
                <EditableValue
                  value={handicap.value || null}
                  onEdit={value => handleEditValue({
                    preValue: handicap.value,
                    newValue: value,
                    handicapId: handicap.id,
                    key: 'value',
                  })}
                />
              </div>
            </div>
            <div className="handicap__property">
              <Checkbox
                checked={!!+active[0 + index * 3]}
                onChange={e => setActiveArray(0 + index * 3)}
              >
                <div className="handicap__property-name">
                  Tournament
                </div>
              </Checkbox>
            </div>
            <div className="handicap__property">
              <Checkbox
                checked={!!+active[1 + index * 3]}
                onChange={e => setActiveArray(1 + index * 3)}
              >
                <div className="handicap__property-name">
                  Battles
                </div>
              </Checkbox>
            </div>
            <div className="handicap__property">
              <Checkbox
                checked={!!+active[2 + index * 3]}
                onChange={e => setActiveArray(2 + index * 3)}
              >
                <div className="handicap__property-name">
                  Quick game & Open Challenges
                </div>
              </Checkbox>
            </div>
            {(handicap.variables.length && (
              <>
                <div className="handicap__subtitle">
                  Variables:
                </div>
                {handicap.variables.map(variable => (
                  <div key={variable.id} className="handicap__property variable">
                    <div className="handicap__property-name">
                      {variable.name}
                    </div>
                    <div className="handicap__property-value">
                      <EditableValue
                        value={variable.value}
                        onEdit={value => handleEditVariable({
                          preValue: variable.value,
                          newValue: value,
                          handicapId: handicap.id,
                          varId: variable.id,
                        })}
                      />
                    </div>
                  </div>
                ))}
              </>
            )) || <></>}
            <hr />
          </div>
        ))) || <></>}
        <Button
          className="handicap-upload__btn"
          onClick={() => {
            sendCheckboxes()
            saveHandicapList({
              handicapList: _handicapList,
            })
          }}
          disabled={!isEdited}
          loading={handicapSaving}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

/**
 * @param {{ value: number | null; onEdit?: (value: number, rawValue?: string) => any} } param0
 */
export const EditableValue = ({
  value,
  onEdit,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [inputRef]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEdit]);

  const handleClickOutside = async (e) => {
    const input = inputRef.current;
    if (input && !input.contains(e.target)) {
      setIsEdit(false);
    }
  };

  const handleOnInput = (e) => {
    if (!e.target.validity.valid) return;

    onEdit(+e.target.value, e.target.value);
  };

  return isEdit
    ? (
      <input
        // pattern="[0-9]*"
        pattern="[0-9]+([,\.][0-9]+)?"
        onInput={handleOnInput}
        ref={inputRef}
        value={value}
        className="editable__input"
      />
    )
    : (
      <div
        onClick={() => setIsEdit(true)}
        className="editable-value"
      >
        {value}
      </div>
    );
};

export default connect(
  state => ({
    handicapList: state.common.handicaps.data,
    handicapSaving: state.common.handicaps.saving,
    activeHandicap: state.common.activeHDC,
  }),
  {
    getHandicapList,
    getActiveHDC,
    setActiveHDC,
    saveHandicapList,
  }
)(HandicapsTab);
