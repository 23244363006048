import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter, Link } from 'react-router-dom';
import _ from 'lodash';
import { Button } from '../../../components/common/Button';
import Frame from '../../../components/common/Frame';
import OBModal from '../../../components/common/Modal';
import SvgArrow from '-!svg-react-loader!./arrow.svg'; // eslint-disable-line
import SvgUpload from '-!svg-react-loader!./upload.svg'; // eslint-disable-line
import SvgTrash from '-!svg-react-loader!./trash.svg'; // eslint-disable-line
import { getProtest, acceptProtest, rejectProtest, } from '../../../libs/reducers/actions';

import './style.scss';

class ModalProtestDecision extends Component {

  constructor(props) {

    super(props);

    this.fileInput = null;

    this.state = {
      file: null,
      filename: null,
      fetching: false,
      protest: null,
      discussion: ''
    }

  }

  componentDidMount = () => {
    const { protestid, getProtest } = this.props;
    getProtest({ protestid });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = { ...prevState }
    if (!prevState.fetching && nextProps.protest.isFetching) {
      state = { ...state, fetching: true }
    }
    if (prevState.fetching && !nextProps.protest.isFetching) {
      if (!nextProps.protest.error) {
        if (nextProps.protest.saved) {
          nextProps.onSubmit();
          return;
        }
        state = { ...state, protest: nextProps.protest.data, fetching: false }
      }
      state = { ...state, protest: null, fetching: false }
    } 
    return state;
  }

  onSubmit = () => {
    const { protestid, accepted, consider_start, newFrame } = this.props;
    const { file, discussion } = this.state;
    let data = new FormData();
    if (file) data.append('file', file);
    data.append('id', protestid);
    data.append('accepted', accepted ? 1 : 0);
    data.append('discussion', discussion);
    data.append('consider_start', consider_start);
    if (accepted) {
      data.append('frame', JSON.stringify(newFrame));
      this.props.acceptProtest(data);
    } else {
      this.props.rejectProtest(data);
    }
  }

  renderFrame = (frame, type) => {
    const { shot1, shot2, shot3, framenumber, flags, score } = frame;
    let result = [ shot1 == 10 ? 'X' : shot1 == 0 ? '-' : shot1 ];
    if (shot2 != -1) {
      result = [ ...result, shot2 == 10 ? 'X' : shot1 + shot2 == 10 ? '/' : shot2 == 0 ? '-' : shot2 ];
    }
    if (shot3 != -1) {
      result = [ ...result, shot3 == 10 ? 'X' : shot2 + shot3 == 10 ? '/' : shot3 == 0 ? '-' : shot3 ];
    }
    return (
      <div className={`frame-${type}-result`}>
        <div className="frame-number">{framenumber}</div>
        <div className="frame-result">
          <div className="frame-result-shots">
            {
              result.map(r => (
                <div className="frame-result-shot">{r}</div>
              ))
            }
          </div>
          { flags && flags.indexOf('f') != -1 ? <div className="frame-result-flag">F</div> : null }
        </div>
        <div className="frame-score">{score}</div>
      </div>
    )
  }

  onChangeFile = () => {
    const file = this.fileInput.files[0];
    if (file) {
      this.setState({ filename: file.name, file });
    } else {
      this.setState({ filename: null, file: null });
    }
  }

  render() {
    const { intl, reason, complaint, oldFrame, newFrame, accepted } = this.props;
    const { file, filename, discussion, fetching } = this.state;
    const translate = {
      title: accepted 
        ? intl.formatMessage({ id: 'ProtestAcceptedTitle', defaultMessage: 'Protest decision' })
        : intl.formatMessage({ id: 'ProtestRejectedTitle', defaultMessage: 'Protest rejected!' }),
      cancel: intl.formatMessage({ id: 'CancelAsAbortAlias', defaultMessage: 'Cancel' }),
      send: intl.formatMessage({ id: 'ProtestSubmitButton', defaultMessage: 'Submit' }),
      protestCategory: intl.formatMessage({ id: 'ProtestCategory', defaultMessage: 'Protest`s category' }),
      protestReason: reason ? intl.formatMessage({ id: reason, defaultMessage: reason }) : null,
      protestComment: intl.formatMessage({ id: 'comment', defaultMessage: 'Comment' }),
      protestDiscussion: intl.formatMessage({ id: 'ProtestDiscussionPlaceholder', defaultMessage: 'Enter protest comment...' }),
      fileLabel: intl.formatMessage({ id: 'AttachFile', defaultMessage: 'Attach file' }),
    };
    return (
      <div>
        <OBModal
          title={translate.title}
          visible
          width={395}
          onOk={() => {}}
          onCancel={() => {}}
          className="protest_decision_modal"
          closable={false}
          footer={[
            <div>
              <Button style={{ width: '172px' }}
                kind="aquamarine"
                bordered="true"
                onClick={() => { this.props.onCancel() }}
                loading={fetching}
              >
                {translate.cancel}
              </Button>
              <Button style={{ width: '172px' }}
                kind="aquamarine"
                onClick={() => { this.onSubmit() }}
                disabled={!!!discussion}
                loading={fetching}
              >
                {translate.send}
              </Button>
            </div>
          ]}
        >
          {
            accepted &&
            <div className="frame-result-container">
              <div className="frame-old-result">
                <Frame
                  isOwner={false}
                  isCurrent={false}
                  number={oldFrame.framenumber}
                  scores={oldFrame.framenumber == 10 ? [oldFrame.shot1, oldFrame.shot2, oldFrame.shot3] : [oldFrame.shot1, oldFrame.shot2]}
                  frameScore={oldFrame.score}
                  flags={oldFrame.flags}
                />
              </div>
              {/* { this.renderFrame(oldFrame, 'old') } */}
              <div className="result-arrow">
                <SvgArrow/>
              </div>
              <div className="frame-new-result">
                <Frame
                  isOwner={true}
                  isCurrent={true}
                  number={newFrame.framenumber}
                  scores={newFrame.framenumber == 10 ? [newFrame.shot1, newFrame.shot2, newFrame.shot3] : [newFrame.shot1, newFrame.shot2]}
                  frameScore={newFrame.score}
                  flags={newFrame.flags}
                />
              </div>
              {/* { this.renderFrame(newFrame, 'new') } */}
            </div> 
          }
          <div className="protest-container">
            <div className="protest">
              <div className="protest-category">
                <span>{translate.protestCategory}:</span>
                <div>{translate.protestReason}</div>
              </div>
              <div className="protest-comment">
                <span>{translate.protestComment}:</span>
                <div>{complaint}</div>
              </div>
            </div>
          </div>
          <div className="discussion-container">
            <textarea
              id="discussion"
              value={discussion}
              className="textarea"
              placeholder={translate.protestDiscussion}
              onChange={(e) => {
                this.setState({ discussion: e.target.value });
              }}
            />
          </div>
          <div className="file-container" 
            onClick={() => {
              if (this.fileInput) {
                if (file) {
                  this.fileInput.value = null;
                  this.setState({ file: null, filename: null });
                } else {
                  this.fileInput.click();
                }
              }
            }}
          >
              <div className="file-label">{translate.fileLabel}:</div>
              <div className="file-input">
                <div className="file-name">{filename}</div>
                <div className="file-upload-icon">
                  { file ? <SvgTrash /> : <SvgUpload/> }
                </div>
              </div>
              <input type="file"
                name="file" 
                style={{ display: 'none' }}
                onChange={this.onChangeFile} 
                ref={(c) => this.fileInput = c}
              />
          </div>
          <div></div> 
        </OBModal>
      </div>
    );
  }
}

export default injectIntl(withRouter(
  connect(
    state => ({ 
      protest: state.protestList
    }),
    { 
      getProtest,
      acceptProtest,
      rejectProtest,
    },
  )(ModalProtestDecision),
));
