export const START_TIMER = 'START_TIMER';

const initialState = {
  starttime: null,
}

const TimerReduсer = (state = initialState, action) => {
  switch (action.type) {
    case START_TIMER: {
      return { ...state, starttime: +new Date() }
    }
    default:
      return state;
  }
}

export default TimerReduсer;