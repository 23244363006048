/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Button, Pagination, Table } from 'antd';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import {
  getSubscribersAction,
  dropSubscribersAction,
  subscribersChangePage,
  subscribersChangeParams,
} from '../../../libs/reducers/adminReducer';
import { getDataSource } from './util';
import TableHeaderCell from '../../common/TableHeaderCell';
import './style.scss';
import DatePicker from '../../common/DatePicker';

const Subscribers = ({
  getSubscribersAction,
  dropSubscribersAction,
  subscribersChangePage,
  subscribersChangeParams,
  fetch_data,
  recurring_count,
  page,
  recurrings,
  fetch_params,
  user_count,
}) => {
  let dateBetweens = [];
  if (fetch_params.active_between) {
    dateBetweens = fetch_params.active_between.split(',');
  }
  const [dataSource, setDataSource] = useState([]);
  const [dateAfter, setDateAfter] = useState(dateBetweens[0] ? moment(dateBetweens[0]) : null);
  const [dateBefore, setDateBefore] = useState(dateBetweens[1] ? moment(dateBetweens[1]) : null);
  const [isFirstRender, setIsFirstRender] = useState(true);

  function updateOptions(params) {
    subscribersChangeParams(params);
  }

  const columns = [
    {
      title: (
        <TableHeaderCell
          title={'id'}
          dataIndex={'userid'}
          withFilter
          output={fetch_params}
          setOutput={updateOptions}
          confirmLoading={fetch_data}
          filterInputType={'text'}
        />
      ),
      dataIndex: 'userid',
    },
    {
      title: (
        <TableHeaderCell
          title={'User'}
          dataIndex={'username'}
          withFilter
          withSort
          output={fetch_params}
          setOutput={updateOptions}
          confirmLoading={fetch_data}
          filterInputType={'text'}
        />
      ),
      dataIndex: 'username',
    },
    {
      title: (
        <TableHeaderCell
          title={'Login'}
          dataIndex={'login'}
          withFilter
          withSort
          output={fetch_params}
          setOutput={updateOptions}
          filterInputType={'text'}
          confirmLoading={fetch_data}
        />
      ),
      dataIndex: 'login',
    },
    {
      title: (
        <TableHeaderCell
          title={'Tariff plan'}
          dataIndex={'tariff_name'}
          withFilter
          confirmLoading={fetch_data}
          output={fetch_params}
          setOutput={updateOptions}
          filterInputType={'text'}
        />
      ),
      dataIndex: 'tariff_name',
    },
    {
      title: (
        <TableHeaderCell
          title={'Activation date'}
          dataIndex={'created'}
          withFilter
          withSort
          output={fetch_params}
          setOutput={updateOptions}
          filterInputType={'date'}
          confirmLoading={fetch_data}
        />
      ),
      dataIndex: 'created',
    },
    {
      title: (
        <TableHeaderCell
          title={'Cancellation date'}
          dataIndex={'expires'}
          withFilter
          withSort
          output={fetch_params}
          setOutput={updateOptions}
          filterInputType={'date'}
          confirmLoading={fetch_data}
        />
      ),
      dataIndex: 'expires',
    },
    {
      title: (
        <TableHeaderCell
          title={'Tariff market'}
          dataIndex={'kind'}
          output={fetch_params}
          setOutput={updateOptions}
          confirmLoading={fetch_data}
          withFilter
          filterInputType={'text'}
        />
      ),
      dataIndex: 'kind',
    },
  ];

  useEffect(() => {
    getSubscribersAction();
    return () => dropSubscribersAction();
  }, []);

  useEffect(() => {
    if (!fetch_data) {
      const newDataSource = getDataSource(recurrings);
      setDataSource(newDataSource);
    }
  }, [recurrings]);

  useEffect(() => {
    setIsFirstRender(false);
    if (!isFirstRender) {
      getSubscribersAction(fetch_params);
    }
  }, [fetch_params]);

  function flattenTime(time) {
    const date = new Date(time);
    date.setUTCHours(0);
    date.setUTCMinutes(0);
    date.setUTCSeconds(0);
    return date;
  }

  function onOkPeriod() {
    const newFetchParams = { ...fetch_params };
    if (!dateAfter && !dateBefore) {
      if (newFetchParams.active_between) {
        delete newFetchParams.active_between;
      }
      updateOptions({ ...newFetchParams });
      return;
    }
    const after = dateAfter
      ? flattenTime(dateAfter).toISOString()
      : new Date(null).toISOString();
    const before = dateBefore
      ? flattenTime(dateBefore).toISOString()
      : new Date().toISOString();

    const result = `${after},${before}`;
    updateOptions({ ...newFetchParams, active_between: result });
  }

  function resetPeriod() {
    setDateAfter(null);
    setDateBefore(null);
  }

  return (
    <div>
      <div className="subscribers__header">
        <div className="subscribers__header__date-container">
          <div className="subscribers__header__date-container_text">Period</div>
          <div className="subscribers__header__date-container_date">
            <DatePicker
              dateFormat="D MMM YYYY"
              className="date-group__elements__picker"
              placeholder="From"
              value={dateAfter}
              onChange={(date, dateStr) => setDateAfter(date)}
            />
          </div>
          <div className="subscribers__header__date-container_date">
            <DatePicker
              dateFormat="D MMM YYYY"
              className="date-group__elements__picker"
              placeholder="To"
              value={dateBefore}
              onChange={(date, dateStr) => setDateBefore(date)}
            />
          </div>
          <Button
            className="button button_green subscribers__btn subscribers__btn_margin"
            onClick={resetPeriod}
          >
            Reset date
          </Button>

          <Button
            className="button button_aquamarine subscribers__btn"
            onClick={onOkPeriod}
          >
            Ok
          </Button>
        </div>
        <div className="subscribers__header__total">
          Total for the period <span>{user_count} users</span>
        </div>
      </div>
      <Table
        columns={columns}
        loading={fetch_data}
        dataSource={dataSource}
        pagination={false}
      />
      <Pagination
        current={page}
        defaultPageSize={20}
        total={recurring_count}
        onChange={value => subscribersChangePage(value)}
        hideOnSinglePage
      />
    </div>
  );
};

export default injectIntl(
  withRouter(
    connect(
      state => ({
        user_count: state.admin.subscribers.user_count,
        recurring_count: state.admin.subscribers.recurring_count,
        recurrings: state.admin.subscribers.recurrings,
        fetch_data: state.admin.subscribers.fetch,
        page: state.admin.subscribers.page,
        fetch_params: state.admin.subscribers.fetch_params,
      }),
      {
        getSubscribersAction,
        dropSubscribersAction,
        subscribersChangePage,
        subscribersChangeParams,
      },
    )(Subscribers),
  ),
);
