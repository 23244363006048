import React from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import {
  addUserToTournament,
  deleteUserFromTournament,
  requestTournamentInfo,
} from '../../../libs/reducers/actions';
import IconIsParticipant from '-!svg-react-loader!../../../images/icon-is-participant.svg'; // eslint-disable-line
import Modal from '../../common/Modal';

import './style.scss';
import BreakText from '../../BreakText';


class TournamentInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timezone: null,
      country: null,
      visibleConfirmRegisterModal: false,
    };
  }

  componentDidMount() {
    const { user, timezoneList, countryList, tournament } = this.props;
    const { hourshift } = user;
    const timezone = timezoneList.find(_timezone => _timezone.id === hourshift);
    if (timezone) {
      this.setState({
        timezone,
      });

      const { countryid } = timezone;
      const country = countryList.find(_country => _country.id === countryid);

      if (country) {
        this.setState({
          country,
        });
      }
    }
  }

  formatStartTime() {
    const { timezone, country } = this.state;
    const { tournament } = this.props;
    if (!tournament) return '';
    if (!timezone) return '';
    const { gmt } = timezone;

    return ` (${moment(tournament.regdatestart).tz('Europe/London').format('HH:mm')} UK)`;
  }

  formatEndTime() {
    const { timezone, country } = this.state;
    const { tournament } = this.props;
    if (!tournament) return '';
    if (!timezone) return '';
    const { gmt } = timezone;

    return ` (${moment(tournament.regdateend).tz('Europe/London').format('HH:mm')} UK)`;
  }

  addUserToTournament() {
    this.props.addUserToTournament({
      userid: this.props.user.id,
      tournamentid: this.props.tournament.id,
      refetch: true,
    });
    this.setState({ visibleConfirmRegisterModal: false });
  }

  render() {
    const {
      tournament,
      intl,
      user,
      tournaments,
    } = this.props;
    const { timezone } = this.state;

    const _tournament = tournaments.find(elem => elem.id === tournament.id);
    const player = _tournament && _tournament.players.find(_player => _player.userid === user.id);
    const isParticipant = player && player.confirmedbyuser === 1;
    const tournamentByInvite = ((tournament.settings || []).find(s => s.tournament_setting_brief === 'BY_INVITATION') || {}).value_bool;

    return (
      <div className="tournament-info_container">
        <div className="container__header">
          <div className="tournament-info-name">
            { tournament.name || '-' }
          </div>
          {
            tournament.tournamenttypeid === 3 && (
              (tournamentByInvite && !isParticipant && (
                <div className="header__button tournamen-isover">
                  { intl.formatMessage({
                    id: 'TournamentByInvitation',
                    defaultMessage: 'Tournament by invitation',
                  }) }
                </div>
              ))
              || (tournament.regdatestart && moment(moment.now()).isBefore(tournament.regdatestart) && (
                <div className="header__button tournamen-isover">
                  { intl.formatMessage({ id: 'TournamentTableStatusSoonStart', defaultMessage: 'Starting soon' }) }
                </div>
              ))
              || (tournament.regdateend && moment(moment.now()).isAfter(tournament.regdateend) && (
                <div className="header__button tournamen-isover">
                  { intl.formatMessage({ id: 'TournamentIsOver', defaultMessage: 'Tournament is over' }) }
                </div>
              ))
              || (isParticipant && <div className="header__button is-participant">
                  <IconIsParticipant />
                  <div> { intl.formatMessage({ id: 'TournamentRegisterFact', defaultMessage: 'You are registered' }) } </div>
                </div>
              )
              || ((!user.is_adult && tournament.cost && (
                  <div className="header__button tournamen-isover">
                    {
                      intl.formatMessage({
                        id: 'YoungPlayerTournamentInfo',
                        defaultMessage: 'Users under the age of 18 are not eligible to participate in paid games'
                      })
                    }
                  </div>
              ))
              || ((
                <div onClick={() => this.setState({ visibleConfirmRegisterModal: true })} className="header__button">
                  { intl.formatMessage({ id: 'TournamentInfoRegister', defaultMessage: 'Tournament registration' }) }
                </div>
              ))
              )
            )
          }

        </div>
        <div className="container__body">
          <div className="body__item">
            <div className="body__item-key">
              { intl.formatMessage({ id: 'TournamentRegDateStart', defaultMessage: 'Tournament start date' }) }
            </div>
            <div className="body__item-value">
              { (tournament.regdatestart && moment(tournament.regdatestart).format('D MMM - HH:mm')) || '-' }
              { (tournament.regdatestart && timezone && this.formatStartTime()) || '' }
            </div>
          </div>
          <div className="body__item">
            <div className="body__item-key">
              { intl.formatMessage({ id: 'TournamentRegDateEnd', defaultMessage: 'Tournament end date' }) }
            </div>
            <div className="body__item-value">
              { (tournament.regdateend && moment(tournament.regdateend).format('D MMM - HH:mm')) || '-' }
              { (tournament.regdateend && timezone && this.formatEndTime()) || '' }
            </div>
          </div>
          {/*<div className="body__item">
            <div className="body__item-key">
              { intl.formatMessage({ id: 'TournamentGameCost', defaultMessage: 'Game cost' }) }
            </div>
            <div className="body__item-value">
            { tournament.cost || 0 } { tournament.currencysymbol || '' }
            </div>
          </div>*/}
          {/*{
            tournament.tournamentid === 3 && (
              <div className="body__item">
                <div className="body__item-key">
                  { intl.formatMessage({ id: 'TournamentReplayСost', defaultMessage: 'Replay cost' }) }
                </div>
                <div className="body__item-value">
                  { tournament.cost || 0 } { tournament.currencysymbol || '' }
                </div>
              </div>
            )
          }*/}
          {/*{(!user.is_adult && tournament.cost && (
            <div className="body__item">
              <div className="body__item-value">
                {intl.formatMessage({ id: 'YoungPlayerTournamentInfo', defaultMessage: 'Users under the age of 18 are not eligible to participate in paid games' })}
              </div>
            </div>
          )) || <></>}*/}
          <div className="body__item">
            <div className="body__item-key">
              { intl.formatMessage({ id: 'DescriptionAsAdditionalInfoAlias', defaultMessage: 'Description' }) }
            </div>
            <div className="body__item-value tournament-description">
              <BreakText>{tournament.rules || ''}</BreakText>
            </div>
          </div>
        </div>
        <Modal
          visible={this.state.visibleConfirmRegisterModal}
          title={intl.formatMessage({ id: 'TournamentInfoRegister', defaultMessage: 'Tournament registration' })}
          okText={intl.formatMessage({ id: 'registration', defaultMessage: 'Registration' })}
          cancelText={intl.formatMessage({ id: 'BattleCancelModalOkText', defaultMessage: 'Cancel' })}
          onOk={() => this.addUserToTournament()}
          onCancel={() => this.setState({ visibleConfirmRegisterModal: false })}
        >
          <div className="confirm-modal__body">
            {intl.formatMessage({
              id: 'TournamentConfirmRegisterCensored',
              defaultMessage: 'Please, register for the Tournament, by clicking the Register button.'
            })}
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect(
  state => ({
    tournament: state.tournaments.tournamentInfo,
    tournaments: state.tournaments.tournaments,
  }),
  {
    addUserToTournament,
    deleteUserFromTournament,
    requestTournamentInfo,
  },
)(TournamentInfo);
