import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment';

import Avatar from '../common/Avatar';
import defaultAvatar from '../../images/defaultAvatar.png';

import { FilterInput } from '../common/Input';
import Slider from '../common/Slider';
import OBDatePicker from '../common/DatePicker';
import OBTimePicker from '../common/TimePicker';
import Select from '../common/Select';
import { Button } from '../common/Button';
import PlayerPicker from './playerPicker';

import {
  createBattleForTournament,  
} from '../../libs/reducers/actions';
import './style.scss';

class BattleCreationOfTournament extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cost: 0,
      time: false,
      date: false,
      invalidBet: false,
      winCount: 0,
      player1: false,
      player2: false,
      invalidPlayers: false,
      invalidDate: false,
    }
  }

  static propTypes = {
    intl: intlShape,
  };

  createBattle = () => {
    const { date, time, winCount, player1, player2 } = this.state;
    const { tournamentid, tournamentInfo, createBattleForTournament } = this.props;
    const { cost } = this.state;
    if (cost < 0) {
      this.setState({ invalidBet: true });
    } else if (this.state.invalidBet) {
      this.setState({ invalidBet: false });
    }
    if (!player1 || !player2) {
      this.setState({ invalidPlayers: true });
    } else if (this.state.invalidPlayers) {
      this.setState({ invalidPlayers: false });
    }
    if (!date || !time) {
      this.setState({ invalidDate: true });
    } else if (this.state.invalidDate) {
      this.setState({ invalidDate: false });
    }
    if (cost >= 0 && player1 && player2 && date && time) {
      let datestart;
      if (time !== null) {
        datestart = moment(date.set('h', time.hour()).set('m', time.minute()));
      } else {
        datestart = moment(date);
      }
      datestart = moment(datestart).set('s', 0).toISOString();
      createBattleForTournament({
        cost,
        datestart,
        wincount: (winCount / 25) + 1,
        players: [player1, player2],
        tournamentid,
        tournamenttypeid: tournamentInfo.tournamenttypeid,
      });
      this.setState({
        cost: 0,
        time: false,
        date: false,
        invalidBet: false,
        winCount: 0,
        player1: false,
        player2: false,
        invalidPlayers: false,
        invalidDate: false,
      });
    }
  }

  render() {
    const { intl, players } = this.props;
    const { player1, player2, invalidPlayers, invalidDate } = this.state;
    return(
      <div className="tournament__createBattle">
        <PlayerPicker 
          selectPlayer={player1 => this.setState({ player1 })}
          label={`${intl.formatMessage({ id: 'PlayerNoun', defaultMessage: 'Player' })} 1`}
          players={players.filter(player => player.isparticipant && player2.userid!=player.userid)}
          selectedPlayer={player1}
          />
        <PlayerPicker 
          selectPlayer={player2 => this.setState({ player2 })}
          label={`${intl.formatMessage({ id: 'PlayerNoun', defaultMessage: 'Player' })} 2`}
          players={players.filter(player => player.isparticipant && player1.userid!=player.userid)}
          selectedPlayer={player2}
          />
        {invalidPlayers && <div className="errorGroup">{intl.formatMessage({ id: 'CreateBattleWrongPlayers', defaultMessage: 'Both players must be selected' })}</div>}
        {/* <div className="elemGroup betWrap">
          <div className="label">{intl.formatMessage({ id: 'FeeAmountUponBattleCreation', defaultMessage: 'Fee amount' })}</div>
          <Select
            noOptionsMessage={() => intl.formatMessage({ id: 'NoFundsPlaceholder', defaultMessage: 'No funds' })}
            value={{ value: this.state.cost, label: this.state.cost}}
            options={[{value: 0, label: 0}, ...this.props.steps['DIG'].map(step => ({ value: step, label: step }))]}
            onChange={e => {this.setState({ cost: e.value })}}
            className='picker'/>
        </div> */}
        <div className="elemGroup">
          <div className="label">
            {intl.formatMessage({ id: 'table_wins', defaultMessage: 'Wins' })}
          </div>
          <Slider
            marks={{
              0: '1',
              25: '2',
              50: '3',
              75: '4',
              100: '5',
            }}
            value={this.state.winCount}
            tipFormatter={null}
            step={null}
            onChange={value => this.setState({ winCount: value })}
          />
        </div>
        <div className="elemGroup">
          <div className="label">{intl.formatMessage({ id: 'CreateBattleDatePicker', defaultMessage: 'Pick a date' })}</div>
          <div className="date-group__pickers">
            <OBDatePicker
              className="date-group__elements__picker"
              placeholder={intl.formatMessage({ id: 'date', defaultMessage: 'Date' })}
              value={this.state.date}
              onChange={date => this.setState({ date })}
              disabledDate={current => current && current < moment().subtract(1, 'days')}
            />
            <OBTimePicker
              className="date-group__elements__picker"
              placeholder={intl.formatMessage({ id: 'time', defaultMessage: 'Time' })}
              value={this.state.time}
              onChange={time => this.setState({ time })}
            />
            {this.state.invalidDate ? <div className="input__error">{this.state.invalidDate}</div> : null}
          </div>
        </div>
        {invalidDate && <div className="errorGroup">{intl.formatMessage({ id: 'CreateBattleInvalidDate', defaultMessage: 'Input full date' })}</div>}
        <div className="elemGroup">
          <Button kind="aquamarine" onClick={() => this.createBattle()}>{intl.formatMessage({ id: 'create_battle', defaultMessage: 'Создать Battle' })}</Button>
        </div>
      </div>
    );
  }
}

export default injectIntl(
  connect(
    state => ({
      players: state.tournaments.tournamentInfo.players,
      tournamentInfo: state.tournaments.tournamentInfo,
      tournamentid: state.tournaments.tournamentId,
      steps: state.common.settings.data.bet_step || { DIG: [] },
      playersUpdate: state.tournaments.fetches,
      tournamentInfo: state.tournaments.tournamentInfo,
    }),
    {
      createBattleForTournament,
    },
  )(BattleCreationOfTournament)
);
