/* eslint-disable */

/**
 * @template T
 * @param {(item: T, index: number, array: T[])} compare
 * @param {T[]} array
 */
export const findLast = (compare, array) => {
  for (let i = array.length - 1; i >= 0; --i) {
    if (compare(array[i], i, array)) {
      return array[i]
    }
  }
  return null;
}

export const ordinals = (num) => {
  switch (num) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      if (num <= 13) {
        return 'th'
      }
      break;
  }
  const rem100 = num % 100
  if (rem100 <= 13) {
    return ordinals(rem100)
  }
  return ordinals(num % 10)
}
