import React, { Component } from 'react';
import moment from 'moment';
import Slider from '../common/Slider';
import SvgButton from '../common/SvgButton';

export default class VideoController extends Component {

  constructor(props) {

    super(props);
    
    this.timer = null;

    this.state = {
      time: props.time || 0,
      stopped: true,
      duration: props.duration,
      muted: false,
      paused: false,
      end: false,
      prevGame: props.prevGame,
      nextGame: props.nextGame
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const nextProps = this.props;
    const nextState = this.state;
    if (nextProps.duration != prevState.duration) {
      this.setState({ duration: nextProps.duration });
    }
    if (!prevState.end && prevState.time < prevState.duration && nextState.time == prevState.duration) {
      this.setState({ end: true });
      nextProps.onEndTimer();
    }
    if (prevState.end && nextState.time < prevState.duration) {
      this.setState({ end: false });
    }
    if (prevState.prevGame != nextProps.prevGame || prevState.nextGame != nextProps.nextGame) {
      this.setState({ time: 0, prevGame: nextProps.prevGame, nextGame: nextProps.nextGame });
    }
    if (nextProps.muted != prevState.muted) {
      this.setState({ muted: nextProps.muted });
    }
  }

  componentWillUnmount () {
    this.clearTimer();
  }

  clearTimer () {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  startTimer() {
    this.clearTimer();
    this.setState({ stopped: false, paused: false });
    this.timer = setInterval(() => {
      const time = this.state.time + 1;
      this.setState({
        time: time
      });
      this.props.onTick(time);
    }, 1000);
  }

  getTime () {
    const { time } = this.state;
    return time;
  }

  pauseTimer() {
    this.clearTimer();
    this.setState({ paused: true, stopped: false });
  }

  stopTimer() {
    this.clearTimer();
    this.setState({ stopped: true, paused: false });
  }

  setTimerValue(value, wait = true) {
    if (value < this.state.duration) {
      this.setState({ time: value });
      this.stopTimer();
      this.props.onChangeTimer(value, false, wait);
    } 
  }

  setMuted(value) {
    this.setState({ muted: value });
  }

  onAfterChange (value) {
    if (value < this.state.duration) {
      this.props.onAfterChangeTimer(value);
    }
  } 

  prettyPrint(seconds) {
    return moment.utc(moment.duration(seconds, 'seconds').asMilliseconds()).format('HH:mm:ss');
  }

  render() {
    const { time, duration, stopped, muted, paused, end, prevGame, nextGame } = this.state;
    const min = 0;
    const step = 1;
    return (
      <div className="video-controller">
        <div className="timer-slider">
          <Slider 
            min={min} 
            max={duration} 
            value={time} 
            step={step} 
            onChange={this.setTimerValue.bind(this)} 
            onAfterChange={this.onAfterChange.bind(this)}
          />
        </div>
        <div className="video-controls">
          <div className="state-control">{
            paused ? 
            <SvgButton icon="play" disabled={stopped || end} onClick={this.props.onPlay}
            /> : 
            <SvgButton icon="pause" disabled={stopped} onClick={this.props.onPause}/>
          }</div>
          <div className="game-control">
            {
              prevGame != null ?
              <div className="prev-game" 
                onClick={() => {
                  this.stopTimer();
                  this.props.onChangeGame(prevGame);
                }}>
                <svg width="32" height="9" viewBox="0 0 32 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="32" y="5" width="30" height="1" transform="rotate(180 32 5)" fill="#F65932"/>
                  <path d="M-1.96701e-07 4.5L6 0.602886L6 8.39711L-1.96701e-07 4.5Z" fill="#F65932"/>
                </svg>
                <span>{`Game ${prevGame + 1}`}</span>
              </div> 
              : null
            }
            <div className="timing">
              <span className="timer">{`${this.prettyPrint(time)}`}</span> / <span className="duration">{`${this.prettyPrint(duration)}`}</span>
            </div>
            {
              nextGame != null ?
              <div className="next-game" 
                onClick={() => {
                  this.stopTimer();
                  this.props.onChangeGame(nextGame);
                }}>
                <span>{`Game ${nextGame + 1}`}</span>
                <svg width="32" height="9" viewBox="0 0 32 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="4" width="30" height="1" fill="#F65932"/>
                  <path d="M32 4.5L26 8.39711L26 0.602885L32 4.5Z" fill="#F65932"/>
                </svg>
              </div> 
              : null
            }
          </div>
          <div className="sound-control">{
            muted ? 
            <SvgButton icon="muted" onClick={this.props.onUnmute}/> : 
            <SvgButton icon="unmuted" onClick={this.props.onMute}/>
          }
          </div>
        </div>
      </div>
    )
  }

}