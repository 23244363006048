import React from 'react'

function SponsorCard({
  image,
  href,
}) {
  return (
    <a href={href} className="sponsor">
      <img src={image}/>
    </a>
  );
}

export default SponsorCard;
