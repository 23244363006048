import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter, Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';
import config from '../../../../config';
import { Button } from '../../Button';
import OBModal from '../../Modal';
import ModalDialog from '../modalDialog';
import ModalProtocol from '../modalProtocol';
import { Table } from 'antd';
import { 
  UserByIdFetch, 
  fetchProtestListOfParty, 
  setVideoArchiveSquadId, 
  getProtocol,
} from '../../../../libs/reducers/actions';
import SvgButton from '../../SvgButton';

import './style.scss';

class ModalProtestList extends Component {

  constructor(props) {

    super(props);

    this.state = {
      data: null,
      fetching: false,
      showConfirmationModal: false,
      showProtocolModal: false,
      isReviewed: false,
      protocolFetching: false
    }

  }

  componentDidMount = () => {
    const { party } = this.props;
    const { id: squadid } = party;
    this.props.fetchProtestListOfParty({ squadid: squadid });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = { ...prevState };
    if (!prevState.fetching && nextProps.protestList.isFetchingParty) {
      state = { ...state, fetching: true }
    }
    if (prevState.fetching && !nextProps.protestList.isFetchingParty && !nextProps.protestList.error) {
      state = { ...state, fetching: false, data: nextProps.protestList.data.party, isReviewed: nextProps.protestList.isReviewed }
    }
    if (!prevState.protocolFetching && nextProps.protocol.isFetching) {
      state = { ...state, protocolFetching: true }
    }
    if (prevState.protocolFetching && !nextProps.protocol.isFetching && !nextProps.protocol.error) {
      nextProps.history.push('/protocol');
    }
    return state;
  }

  cancel() {
    this.setState({ visible: false });
  }

  showUser = function (userid) {
    this.props.UserByIdFetch({
      userid: userid,
    });
  }

  gotoProtest = function (squadid, protestid) {
    this.props.setVideoArchiveSquadId({squadid, protestid});
    this.props.history.push('/refereeing');
  }

  showProtocol = function () {
    const { id: squadid } = this.props.party;
    this.props.getProtocol({ squadid });
  }

  downloadFile = (filename) => {
    const url = `${config.FETCH.url}/protest/download/${filename}`;
    axios({
      url: url,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    });
  } 

  render() {
    const { intl, party } = this.props;
    const { data, showConfirmationModal, isReviewed, showProtocolModal, protocolFetching } = this.state;
    const translate = {
      title: intl.formatMessage(
        { 
          id: 'ProtestListModalTitle', 
          defaultMessage: 'Protests of party #{id} {date, date, short}' ,
        }, 
        { 
          id: party.id, 
          date: new Date(party.date),
        }
      ),
      close: intl.formatMessage({ id: 'CloseAsCancelAlias', defaultMessage: 'Close' }),
      resolve: intl.formatMessage({ id: 'MakeResolve', defaultMessage: 'Made decision' }),
      empty: intl.formatMessage({ id: 'EmptyProtestList', defaultMessage: 'No protests' }),
      gameNumber: intl.formatMessage({ id: 'GameNumber', defaultMessage: '# of game' }),
      player: intl.formatMessage({ id: 'PlayerNoun', defaultMessage: 'Player' }),
      time: intl.formatMessage({ id: 'ProtestTime', defaultMessage: 'Protest`s time' }),
      category: intl.formatMessage({ id: 'ProtestCategory', defaultMessage: 'Protest`s category' }),
      comment: intl.formatMessage({ id: 'comment', defaultMessage: 'Comment' }),
      discussion: intl.formatMessage({ id: 'RefereeComment', defaultMessage: 'Referee`s comment' }),
      file: intl.formatMessage({ id: 'File', defaultMessage: 'File' }),
      accepted: intl.formatMessage({ id: 'ProtestAccepted', defaultMessage: 'Protest accepted by' }),
      rejected: intl.formatMessage({ id: 'ProtestRejected', defaultMessage: 'Protest rejected by' }),
      goto: intl.formatMessage({ id: 'GoToProtest', defaultMessage: 'Go to protest' }),
      refuse: intl.formatMessage({ id: 'RefuseToConsider', defaultMessage: 'Refuse to consider' }),
      status: intl.formatMessage({ id: 'StatusAsStateAlias', defaultMessage: 'Status' }),
    };
    const columns = [
      {
        title: translate.gameNumber,
        dataIndex: 'gamenumber',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => {
          return a.gameNumber - b.gameNumber
        }
      },
      {
        title: translate.player,
        render: (data, record) => {
          if (data && !_.isEmpty(data)) {
            const { player } = record;
            return (
              <Link style={{ whiteSpace: 'nowrap' }} to="#" onClick={(e) => {
                  e.preventDefault();
                  this.showUser(player.userid)
                }}
              >
                {`${player.firstname} ${player.lastname}`}
              </Link>
            )
          }
        }
      },
      {
        title: translate.time,
        dataIndex: 'protestdate',
        render: (data, record) => {
          if (data && !_.isEmpty(data)) {
            return (
              <span>{moment(record.protestdate).format('HH:mm:ss')}</span>
            )
          }
        }
      },
      {
        title: translate.category,
        render: (data, record) => {
          if (data && !_.isEmpty(data)) {
            const { category } = record;
            const { name } = category;
            return (
              <span>{intl.formatMessage({ id: name, defaultMessage: name })}</span>
            )
          }
        }
      },
      {
        title: translate.comment,
        dataIndex: 'complaint',
      },
      {
        title: translate.discussion,
        dataIndex: 'discussion',
      },
      {
        title: translate.file,
        render: (data, record) => {
          if (data && !_.isEmpty(data) && record.filename) {
            return (
              <SvgButton icon="file" onClick={() => {
                this.downloadFile(record.filename);
              }}/>
            )
          }
        }
      },
      {
        title: translate.status,
        render: (data, record) => {
          if (data && !_.isEmpty(data)) {
            const { id, closed, accepted, referee } = record;
            if (closed && accepted) {
              return (
                <div className="protest_list_modal_status">
                  <div>
                    <div style={{ whiteSpace: 'nowrap', marginBottom: '5px' }}>{translate.accepted}</div>
                    <div>              
                      <Link style={{ whiteSpace: 'nowrap' }} to="#" onClick={(e) => {
                          e.preventDefault();
                          this.showUser(referee.userid)
                        }}
                      >
                        {`${referee.firstname} ${referee.lastname}`}
                      </Link>
                    </div>
                  </div>
                  <div className="protest_list_modal_edit">
                      <SvgButton icon="edit" onClick={() => {
                        this.gotoProtest(this.props.party.id, id);
                      }}/>
                  </div>
                </div>
              )
            }
            if (closed && !accepted) {
              return (
                <div className="protest_list_modal_status">
                  <div>
                    <div style={{ whiteSpace: 'nowrap' }}>{translate.rejected}</div>
                    <div>              
                      <Link style={{ whiteSpace: 'nowrap' }} to="#" onClick={(e) => {
                          e.preventDefault();
                          this.showUser(referee.userid)
                        }}
                      >
                        {`${referee.firstname} ${referee.lastname}`}
                      </Link>
                    </div>
                  </div>
                  <div className="protest_list_modal_edit">
                      <SvgButton icon="edit" onClick={() => {
                        this.gotoProtest(this.props.party.id, id);
                      }}/>
                  </div>
                </div>
              )
            }
            return (
              <Button kind="aquamarine" size="md" 
                onClick={() => {
                  this.gotoProtest(this.props.party.id, id);
                }}
              >
                {translate.goto} 
              </Button>
            )
          }
        }
      },
    ];
    return (
      <div>
        <OBModal
          title={translate.title}
          visible
          width={1315}
          onOk={() => {}}
          onCancel={() => {}}
          className="protest_list_modal"
          closable={false}
          footer={[
            <div>
              <Button style={{ width: '236px' }}
                kind="aquamarine"
                bordered="true"
                onClick={() => { 
                  this.setState({ showConfirmationModal: true })
                }}
              >
                {translate.refuse}
              </Button>
              <div style={{ display: 'flex' }}>
                <Button style={{ width: '172px' }}
                  kind="aquamarine"
                  bordered="true"
                  onClick={() => { this.props.onCancel() }}
                  disabled={false}
                >
                  {translate.close}
                </Button>
                { isReviewed && 
                  <Button style={{ width: '172px' }}
                    kind="aquamarine"
                    loading={protocolFetching}
                    onClick={() => { 
                      this.showProtocol()
                    }}
                  >
                    {translate.resolve}
                  </Button>
                }
              </div>
            </div>
          ]}
        >
          <Table
            key={this.props.langs}
            className="protest_list_modal_table"
            rowClassName="protest_list_modal_table_row"
            columns={columns}
            dataSource={data}
            pagination={false}
            rowKey={record => record.id}
            locale={{ emptyText: translate.empty }}
          />
        </OBModal>
        { showProtocolModal && 
          <ModalProtocol
            visible
            onCancel={() => {
              this.setState({ showProtocolModal: false })
            }}
            onDone={() => {
              this.setState({ showProtocolModal: false })
            }}
          />
        }
        <ModalDialog
          visible={showConfirmationModal}
          onSubmit={() => { this.props.onRefuse() }}
          onClose={() => { 
            this.setState({ showConfirmationModal: false })
          }}
          declineButtonText={intl.formatMessage({ id: 'ModalDefaultCancel', defaultMessage: 'Cancel' })}
          acceptButtonText={intl.formatMessage({ id: 'Refuse', defaultMessage: 'Refuse' })}
          title={intl.formatMessage({ id: 'Refuse', defaultMessage: 'Refuse' })}
        >
          {intl.formatMessage({ id: 'RefuseConfirmText', defaultMessage: 'Do you really want to refuse to consider?' })}
        </ModalDialog>
      </div>
    );
  }
}

export default injectIntl(withRouter(
  connect(
    state => ({
      langs: state.langs,
      protestList: state.protestList,
      protocol: state.protocol
    }),
    {
      UserByIdFetch,
      fetchProtestListOfParty,
      setVideoArchiveSquadId,
      getProtocol
    },
  )(ModalProtestList),
));
