import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  getBowlingCenters,
  getBowlingCentersCoutry,
} from '../../../libs/reducers/bowlingCenters.reducer';

function bowlingCenters({
  history,
  bowlingCenters,
  countryes,
  getBowlingCenters,
  getBowlingCentersCoutry,
}) {
  useEffect(() => {
    getBowlingCenters();
    getBowlingCentersCoutry();
  }, []);
  let bowlingCentersInfo = [];
  if (countryes.length && bowlingCenters.length) {
    bowlingCenters.forEach((elem) => {
      if (elem.iso === 'RU') return;
      const city = elem.cityName;
      const country = (countryes.find(country => country.iso === elem.iso) || {}).name;
      bowlingCentersInfo.push({
        country,
        city,
        name: elem.name,
      });
    });
  }
  return (
    <div className="bowlingCenters">
      <div className="row headers">
        <span className="country">Country</span>
        <div className="tbc">
          <div className="town">Town</div>
          <div className="bowlingCenter">Bowling Center</div>
        </div>
      </div>
      {bowlingCentersInfo.map((elem, index) => (
        <div key={index} className="row data">
          <span className="country">{elem.country}</span>
          <div className="tbc">
            <div className="town">{elem.city}</div>
            <div className="bowlingCenter">{elem.name}</div>
          </div>
        </div>
      ))}
      <div className="info-block orange-ib">
        <div className="info">Please indicate which bowling center you would like to see on our platform. Please also leave your contacts. Thank you!</div>
        <div className="info right" onClick={() => history.push('/#form_equipment')}>
          <div>If you want to install TenPinCam equipment – fill the form</div>
          <div className="arrow">
            <svg viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M65 6V65M65 65H6M65 65L1 1" stroke="#F65932" strokeWidth="2"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(
  state => ({
    bowlingCenters: state.bowlingcenters.bowlingcenters.data,
    countryes: state.bowlingcenters.country.data,
  }), {
    getBowlingCenters,
    getBowlingCentersCoutry,
  },
)(bowlingCenters);
