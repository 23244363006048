import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import lodash from 'lodash';
import moment from 'moment';
import '../../../style.scss';
import Select from '../../common/Select';
import { Table } from 'antd';
import OBDatePicker from '../../common/DatePicker';
import {
  getTransactionDiff,
} from '../../../libs/reducers/adminReducer';


const PaymentState = ({
  admin,
  intl,
  getTransactionDiff,
}) => {

  const [currencies, setCurrencies] = useState([])
  const [diffcurrency, setDiffcurrency] = useState(null)
  const [datefrom, setDatefrom] = useState(moment().subtract(1, 'month').startOf('day'))
  const [dateto, setDateto] = useState(moment().endOf('day'))

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current === undefined ? value : ref.current;
  }
  const prevProps = usePrevious({admin})

  useEffect(() => {
    if (admin.currencyList && admin.currencyList.length && !diffcurrency) {
      const currency = admin.currencyList.find(item => item.isocode === 'EUR');
      setDiffcurrency({value: currency.id, label: `${currency.symbol} | ${currency.name}` });
    }
    getTransactionDiff({ from: datefrom, to: dateto });
  }, [])

  useEffect(() => {
    if (!lodash.isEqual(prevProps.admin.currencyList, admin.currencyList)) {
      setCurrencies(
        admin.currencyList.map(curr => {
          return {
            value: curr.id,
            label: `${curr.name}, ${curr.symbol}`,
          }
        })
      )
    }
  }, [admin])

  const statecolumns = [
    {
      title: 'Wallet owner',
      dataIndex: '',
      key: 'id',
      render: (data, record) => <>
        {record.system ? record.system : `${record.login} | ${record.firstname} ${record.lastname} | userid: ${record.userid}`}
      </>
    },
    {
      title: 'from',
      dataIndex: 'from',
      render: (data, record, index) => ({
        props: {},
        children: (
          <>{data}</>
        ),
      }),
    },
    {
      title: 'delta',
      dataIndex: 'delta',
      render: (data, record, index) => ({
        props: {},
        children: (
          <span class='innerText' style={{ color: '#f65932' }}>{data}</span>
        ),
      }),
    },
    {
      title: 'to',
      dataIndex: 'to',
      render: (data, record, index) => ({
        props: {},
        children: (
          <>{data}</>
        ),
      }),
    },
  ]
  const onChangeDateFrom = ({ dateEvent }) => {
    setDatefrom(dateEvent.startOf('day'))
    getTransactionDiff({ from: datefrom, to: dateto })
  }
  const onChangeDateTo = ({ dateEvent }) => {
    setDateto(dateEvent.endOf('day'))
    getTransactionDiff({ from: datefrom, to: dateto })
  }

  return (
    <div className='admin_paymentHistory'>
      <div className='wallet-wrap'>
        <Select
          value={diffcurrency}
          placeholder={'Кошелек'}
          onChange={diffcurrency => setDiffcurrency(diffcurrency)}
          options={admin.currencyList.map(currency => ({
            label: `${currency.symbol} | ${currency.name}`,
            value: currency.id,
          }))} />
        <OBDatePicker
          dateFormat="D MMM YYYY"
          className="date-group__elements__picker"
          placeholder={intl.formatMessage({ id: 'date', defaultMessage: 'Date' })}
          value={datefrom}
          onChange={datefrom => {
              setDatefrom(datefrom.startOf('day'))
              getTransactionDiff({ from: datefrom.startOf('day'), to: dateto })
            }
          }
          disabledDate={current => current && current > moment()}
        />
        <OBDatePicker
          dateFormat="D MMM YYYY"
          className="date-group__elements__picker"
          placeholder={intl.formatMessage({ id: 'date', defaultMessage: 'Date' })}
          value={dateto}
          onChange={dateto => {
              setDateto(dateto.endOf('day'))
              getTransactionDiff({ from: datefrom, to: dateto.endOf('day') })
            }
          }
          disabledDate={current => current && current > moment().add(1, 'days')}
        />
      </div>
      {admin.transactionDiff && admin.transactionDiff.accounts &&
        <>
          <Table
            className="table paymentdiff"
            columns={statecolumns}
            dataSource={[
              admin.transactionDiff.accounts
                .filter(item => diffcurrency && item.currencyid === diffcurrency.value && (item.login || (!item.login && item.system === 'Undefined source')))
                .reduce((res, item) => ({
                  key: 'users',
                  system: 'Users',
                  delta: (+item.delta + +res.delta).toFixed(2),
                  to: (+item.to + +res.to).toFixed(2),
                  from: (+item.from + +res.from).toFixed(2),
                }), {
                  key: 'users',
                  system: 'Users',
                  delta: 0,
                  to: 0,
                  from: 0,
                }),
              ...admin.transactionDiff.accounts
                .filter(item => diffcurrency && item.currencyid === diffcurrency.value && !item.login && item.system !== 'Undefined source')
                .map(item => ({ ...item, key: item.id })),
              ...admin.transactionDiff.summary
                .filter(item => diffcurrency && item.currencyid === diffcurrency.value)
                .map(currency => ({
                  key: 'total',
                  system: 'Total',
                  delta: currency.delta,
                  to: currency.to,
                  from: currency.from,
                }),
                ),
            ]}
            pagination={false}
            rowKey="id"
            style={{ width: '100%' }}
            locale={{ emptyText: 'No data' }}
            rowClassName={'transactionRow'}
            onRow={(record, index) => { }}
          />
        </>}
    </div>
  )
}

export default injectIntl(withRouter(
  connect(
    state => ({
      admin: state.admin,
    }),
    {
      getTransactionDiff,
    },
  )(PaymentState),
));
