import React from 'react';
import { Input, Button } from 'antd';
import { Link } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';

class ResetPass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login: '',
    };
  }

  render() {
    const { intl } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <div>
          <h1>{intl.formatMessage({ id: 'PauseRecoveryVerbose', defaultMessage: 'Password recovery' })}</h1>
          <div>
            <div style={{ maxWidth: '300px' }}>
              <Input
                value={this.state.login}
                onChange={(e) => {
                  this.setState({
                    login: e.target.value,
                  });
                }}
                placeholder="Логин"
              />
              <div>{intl.formatMessage({ id: 'RecoveryInstruction', defaultMessage: 'You will receive a mail on specified in personal data mailbox with instructions' })}</div>
            </div>
          </div>
          <div style={{ padding: '10px', justifyContent: 'center', display: 'flex' }}>
            <Button type="primary">{intl.formatMessage({ id: 'SendPasswordAction', defaultMessage: 'Send password' })}</Button>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ marginRight: '5px' }}>{intl.formatMessage({ id: 'RememberPasswordAsBackAlias', defaultMessage: 'I remember password' })}</div>
          <Link to="/login">{intl.formatMessage({ id: 'LoginCapsed', defaultMessage: 'LOGIN' })}</Link>
        </div>
      </div>
    );
  }
}

export default injectIntl(ResetPass);
