import React from 'react';
import { injectIntl } from 'react-intl';
import Modal from '../Modal';
import { Button } from '../Button';

import './style.scss';

const STANDART = 'standart';
const DYNAMIC_HANDICAP = 'dynamic_handicap';

const HandicapModal = ({
  intl,
  handicap,
  onOk,
  visible,
  modalProps,
}) => {
  const getDescriptionHandicap = () => {
    const handicapType = {
      [STANDART]: () => (
        <div className='container'>
          {intl.formatMessage({
            id: 'HandicapDescriptionGender',
            defaultMessage: 'According to the generally accepted rules of bowling, women are given a handicap of +8 pins.',
          })}
        </div>
      ),
      [DYNAMIC_HANDICAP]: () => {
        const base = (handicap.variables.find(v => v.name.toUpperCase() === 'BASE') || {}).value || 0;
        const avgScore = (handicap.variables.find(v => v.name.toUpperCase() === 'AVG_SCORE') || {}).value || 0;
        const n = (handicap.variables.find(v => v.name.toUpperCase() === 'N') || {}).value || 0;
        const k = (handicap.variables.find(v => v.name.toUpperCase() === 'K') || {}).value || 0;
        const minN = 3;

        return (
          <div className='container'>
            <div className='formula'>
              HDC = (Base – Average (N games)) * K
            </div>
            <div className='variable__container'>
              <div className='variable__label'>
                Handicap HDC
              </div>
              <div className='variable__value'>
                = {handicap.value || 0}
              </div>
            </div>
            <div className='variable__container'>
              <div className='variable__label'>
                Base
              </div>
              <div className='variable__value'>
                {' '}- {intl.formatMessage({ id: 'BaselineScore', defaultMessage: 'Baseline score' })}
                {' '}{base}
              </div>
            </div>
            <div className='variable__container'>
              <div className='variable__label'>
                Average
              </div>
              <div className='variable__value'>
                {' '}- {intl.formatMessage({ id: 'AverageScoreBasedOnRecentGames', defaultMessage: 'Average score based on recent games' })}:
                {' '}{handicap.games_score && handicap.games_score.join(', ')} ({avgScore})
              </div>
            </div>
            <div className='variable__container'>
              <div className='variable__label'>
                N
              </div>
              <div className='variable__value'>
                {' '}- {intl.formatMessage({ id: 'NumberOfGamesCalculated', defaultMessage: 'Number of games calculated' })}
                {' '}{n}
              </div>
            </div>
            <div className='variable__container'>
              <div className='variable__label'>
                K
              </div>
              <div className='variable__value'>
                {' '}- {intl.formatMessage({ id: 'HandicapRatio', defaultMessage: 'Handicap ratio' })}
                {' '}– {k} ({k * 100}%)
              </div>
            </div>
          </div>
        )
      },
    }

    return (handicapType[handicap && handicap.handicap_type]) 
      ? handicapType[handicap.handicap_type]()
      : <div>
          {
            intl.formatMessage({
              id: 'NoDescription',
              defaultMessage: 'No description',
            })
          }
        </div>;
  };

  return (
    <Modal
      {...modalProps}
      title={intl.formatMessage({ id: 'Handicap', defaultMessage: 'Handicap' })}
      visible={visible}
      onOk={() => onOk()}
      onCancel={() => onOk()}
      footer={[
        <div
          key='buttons'
          className='dialog__buttons'>
          <Button
            kind='aquamarine'
            onClick={() => onOk()}
            >
            OK
          </Button>
        </div>,
      ]}
    >
      {getDescriptionHandicap()}
    </Modal>
  );
};

export default injectIntl(HandicapModal);
