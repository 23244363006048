import { createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { sagaInit } from './libs/saga/saga';
import UserReducer, {userInitialState} from './libs/reducers/UsersReduсer';
import ToutnamentReducer from './libs/reducers/TournamentsReducer';
import LangReducer from './libs/reducers/LangReducer';
import SocketEventReducer from './libs/reducers/SocketEventReducer';
import BattlesReduсer from './libs/reducers/BattlesReducer';
import squadsReduсer from './libs/reducers/squads.reducer';
import appSettingsReduсer from './libs/reducers/appSettings.reducer';
import squadIdReduсer from './libs/reducers/squadId.reducer';
import notificationsReduсer from './libs/reducers/notifications.reducer';
import commonReducer from './libs/reducers/commonReducer';
import bowlingCentersReducer from './libs/reducers/bowlingCenters.reducer';
import FriendsReducer from './libs/reducers/FriendsReducer';
import videoArchivesReducer from './libs/reducers/videoArchivesReducer';
import videoFilesReducer from './libs/reducers/videoFilesReducer';
import chatRoomsReducer from './libs/reducers/chatRoomsReducer';
import adminReducer from './libs/reducers/adminReducer';
import userDiskReducer from './libs/reducers/userDiskReducer';
import cookiesReducer from './libs/reducers/cookiesReducer';
import keyboardReducer from './libs/reducers/keybordEventReducer';
import videoArchiveReducer from './libs/reducers/videoArchiveReducer';
import BillingReducer from './libs/reducers/billingReducer';
import protestReducer from './libs/reducers/protestReducer';
import protocolReducer from './libs/reducers/protocolReducer';
import modalReducer from './libs/reducers/modal.reducer';
import redirectReducer from './libs/reducers/redirectReduser';
import userSettingsReducer from './libs/reducers/usetSettingsReducer';
import supportReducer from './libs/reducers/supportReducer';
import timestampReducer from './libs/reducers/TimestampReducer';
import serverStatusReducer from './libs/reducers/serverStatusReducer';
import cookieAgreementReducer from './libs/reducers/cookeAgreement.reducer';
import UserSearchReduсer from './libs/reducers/userSearch';
import logMessages from './libs/reducers/logMessages';
import observerReducer from './libs/reducers/observerReducer';
import TimerReducer from './libs/reducers/TimerReducer';
import UserDocsReducer from './libs/reducers/userDocs';
import squadScreenReducer from './libs/reducers/squadScreenReducer';
import gameInfoReducer from './libs/reducers/gameInfoReducer';
import FilterIconReducer from './libs/reducers/filterIcon.reducer';
import { UserDeleteReducer } from './libs/reducers/userDelete.reducks';
import { reglamentReducer } from './libs/reducers/reglament.reducks';
import { statisticReducer} from './libs/reducers/statistic.reducer'
import {statisticFilterOptions} from "./libs/reducers/statisticFilterOptions.reducer";
import { rateReducer } from './libs/reducers/rate.reducks';
import { gameInviteReducer } from './libs/reducers/gameInvite.reducks';
import { gamesExpectedReducer } from './libs/reducers/gamesExpected.reducks';
import { gamesCancelledReducer } from './libs/reducers/gamesCancelled.reducks';
import { persistentEmitter } from './libs/persistentEmitter';
import { GameKeyboardsReducer } from './libs/reducers/gameKeyboards.reducks';
import { ModalPremiumReducer } from './libs/reducers/modalPremium.reducks';
import { tariffsOffReducer } from './libs/reducers/tariffsOff.reducks';
import { TenpinToolkitReducer } from './libs/reducers/tenpinToolkit.reducks';
import { UserPoolReducer } from './libs/reducers/userPool.reducks';

const reducers = combineReducers({
  users: UserReducer,
  userSearch: UserSearchReduсer,
  tournaments: ToutnamentReducer,
  battles: BattlesReduсer,
  langs: LangReducer,
  events: SocketEventReducer,
  squads: squadsReduсer,
  appSettings: appSettingsReduсer,
  squadId: squadIdReduсer,
  notifications: notificationsReduсer,
  common: commonReducer,
  bowlingcenters: bowlingCentersReducer,
  friends: FriendsReducer,
  videos: videoArchivesReducer,
  chat: chatRoomsReducer,
  admin: adminReducer,
  videofiles: videoFilesReducer,
  userdisk: userDiskReducer,
  cookies: cookiesReducer,
  keyboard: keyboardReducer,
  videoarchive: videoArchiveReducer,
  billing: BillingReducer,
  protestList: protestReducer,
  protocol: protocolReducer,
  modals: modalReducer,
  redirect: redirectReducer,
  usersettings: userSettingsReducer,
  support: supportReducer,
  cookieAgreement: cookieAgreementReducer,
  timestamp: timestampReducer, // get diff timestamp between server and client
  server: serverStatusReducer,
  usersearch: UserSearchReduсer,
  log: logMessages,
  observer: observerReducer,
  userdocs: UserDocsReducer,
  squadScreen: squadScreenReducer,
  timers: TimerReducer,
  gameInfo: gameInfoReducer,
  userDelete: UserDeleteReducer,
  filterIcon: FilterIconReducer,
  reglaments: reglamentReducer,
  rate: rateReducer,
  gameInvite: gameInviteReducer,
  gamesExpected: gamesExpectedReducer,
  gamesCancelled: gamesCancelledReducer,
  gameKeyboards: GameKeyboardsReducer,
  modalPremium: ModalPremiumReducer,
  tariffsOff: tariffsOffReducer,
  statistic: statisticReducer,
  statisticOptions: statisticFilterOptions,
  tenpinToolkit: TenpinToolkitReducer,
  userPool: UserPoolReducer,
});

const eventTransform = createTransform(
  (inboundState, key) => {
    return { ...inboundState };
  },
  (outboundState, key) => {
    return { ...outboundState, chatAuth: false, socketConnected: false };
  },
  { whitelist: ['events'] },
);

const tournamentsTransform = createTransform(
  (inboundState, key) => {
    return { ...inboundState };
  },
  (outboundState, key) => {
    return { ...outboundState, needAgreeForDebeting: false };
  },
  { whitelist: ['tournaments'] },
);

const appSettings = createTransform(
  (inboundState, key) => {
    return { ...inboundState };
  },
  (outboundState, key) => {
    return { ...outboundState, walkthroughIsActive: false };
  },
  { whitelist: ['appSettings'] },
)

const usersTransform = createTransform(
  (inboundState) => ({ ...inboundState }),
  (outboundState) => ({ ...outboundState, registration_complete: false, referral: outboundState.referral || userInitialState.referral }),
  { whitelist: ['users'] }
)

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    // 'tournaments',
    'battles',
    'squads',
    'squadId',
    'notifications',
    'bowlingcenters',
    'friends',
    'videos',
    'chat',
    'videofiles',
    'userdisk',
    'videoarchive',
    'protestList',
    'protocol',
    'modals',
    'redirect',
    'usersettings',
    'support',
    'timestamp',
    'usersearch',
    'log',
    'squadScreen',
    'filterIcon',
    'userDelete',
    'reglaments',
    'rate',
    'gameInvite',
    'gamesExpected',
    'gamesCancelled',
    'gameKeyboards',
    'modalPremium',
    'tariffsOff',
    'tenpinToolkit',
    'userPool',
  ],
  transforms: [ eventTransform, tournamentsTransform, appSettings, usersTransform ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const sagaMiddleware = createSagaMiddleware();

const logger = store => next => action => {
  // console.log('ACTION:', action.type)
  if (action.type === 'SOCKET_EVENT_GAME_INVITE') {
    persistentEmitter.emit('SOCKET_EVENT_GAME_INVITE', action.params)
  }
  next(action)
}
/**
 * see, https://github.com/zalmoxisus/redux-devtools-extension#13-use-redux-devtools-extension-package-from-npm
 */
const composeEnhancers = composeWithDevTools({
  actionsBlacklist: ['HTTP_SERVER_STATUS', 'REFRESH_TOURNAMENT_INFO'],
})
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware, logger)),
);
const persistor = persistStore(store, null, () => {
  const state = store.getState();
  if (state.billing && !state.billing.payseraOrder) {
    state.billing.payseraOrder = {}
  }
  if (state.billing && !state.billing.payseraWithdraw) {
    state.billing.payseraWithdraw = {}
  }
});

sagaMiddleware.run(sagaInit, store);

// раскомментировать, если нужно принудительно разлогинить
// setTimeout(() => store.dispatch({ type: 'USER_LOGOUT' }), 5000);
// persistor.purge()

export { persistor, store, storage };
