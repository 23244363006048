import React from 'react';
import NewVersion from './newVersion';
import Radio from '../../common/Radio';
import Event from './event';

import './style.scss';

const RadioGroup = Radio.Group;

class NotificationTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTypeValue: 'newVersion',
    }
  }

  render() {
    const { activeTypeValue } = this.state;

    return (
      <div className='admin_panelWrapper'>
        <div className="ap-tab-container-550">
          <div className="radio-group">
            <div className="radio-group__label">Type message</div>
            <RadioGroup className="radio-group__container" name="typeNotification" defaultValue="newVersion" onChange={e => this.setState({ activeTypeValue: e.target.value })} value={activeTypeValue}>
              <Radio value="newVersion">New version</Radio>
              <Radio value="event">Event</Radio>
            </RadioGroup>
          </div>
          {activeTypeValue === 'newVersion' && <NewVersion />}
          {activeTypeValue === 'event' && <Event />}
        </div>
      </div>
    );
  }
}

export default NotificationTab;