/* eslint-disable */
import React, { useRef } from 'react';

import './adaptive.css';
import arrow from "./images/arrow-left-circle.png";
import {Carousel} from "antd";
import './carousel.css';

import { useBanners } from './useBanners';

/**
 * @typedef {import('./useBanners').BannerDto} BannerDto
 */

/** @type {React.FC<BannerDto & { vertical: boolean }> */
const Banner = (props) => {
  const { image_with_domain: image, title, link_with_domain: link, vertical } = props;
  return (
  <a href={link}>
    <div className={vertical ? 'banner-vertical' : 'banner'}>
        <img className={vertical ? 'banner_image-vertical' : 'banner_image'} alt={'Banner'} src={image}/>
        <div className='banner_description'>{title}</div>
    </div>
  </a>);
}

const CAROUSEL_SETTINGS = {
  className: "center",
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  swipeToSlide: true,
  dots: false,
  autoplay: false,
  vertical: true,
  speed: 2000,
  responsive: [
    {
      breakpoint: 1150,
      settings: {
        autoplay: false,
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 1060,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        vertical: false,
        autoplay: false,
      }
    },
    {
      breakpoint: 740,
      settings: {
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: false,
      }
    },
  ]
}

/**
 * @type {React.FC<{ vertical: boolean }>} 
 */
const Banners = (props) => {
  const { vertical } = props
  const slider = useRef();
  const { banners, fetching, error } = useBanners();
  return (
    <div className={vertical ? 'carousel_container-vertical' : 'carousel_container'}>
      <button onClick={()=> slider.current.prev()} className={'btn-carousel'} >
        <img className={vertical ? 'arrow-up' : 'arrow-left' } src={arrow} alt={"Previous slide"} />
      </button>
      <div className={vertical ? 'carousel_slide_place-vertical' : 'carousel_slide_place'}>
        <Carousel {...CAROUSEL_SETTINGS} vertical={vertical} ref={ref => { slider.current = ref; }}>
          {banners.map((banner, index) => <Banner key={index} {...banner} vertical={vertical} />)}
        </Carousel>
      </div>
      <button onClick={() => slider.current.next()} className={'btn-carousel'}>
        <img className={vertical ? 'arrow-down' : 'arrow-right' } src={arrow} alt={"Next slide"} />
      </button>
    </div>
  );
};


export default Banners;
