import React from 'react';
import SvgButton from '../common/SvgButton';
import AppleLogoPng from '../../images/appleid_button_white.png';

export default class SocialButtons extends React.Component {
  vkClick = () => {
    if (this.props.vkClick) {
      this.props.vkClick();
    } else {
      window.location = '/api/login?type=vkontakte';
    }
  };

  instClick = () => {
    if (this.props.instClick) {
      this.props.instClick();
    } else {
      window.location = '/api/login?type=instagram';
    }
  };

  fbClick = () => {
    if (this.props.fbClick) {
      this.props.fbClick();
    } else {
      window.location = '/api/login?type=facebook';
    }
  };

  googleClick = () => {
    if (this.props.googleClick) {
      this.props.googleClick();
    } else {
      window.location = '/api/login?type=google';
    }
  }

  appleClick = () => {
    if (this.props.appleClick) {
      this.props.appleClick();
    } else {
      window.location = '/api/login?type=apple';
    }
  }

  render() {
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-start' }} onClick={this.props.onClick}>
        <SvgButton icon="facebook" onClick={this.fbClick} />
        {/* <SvgButton icon="vk" onClick={this.vkClick}/> */}
        <SvgButton icon="google" onClick={this.googleClick} />
        {/* <SvgButton icon="instagram" onClick={this.instClick} style={{ marginLeft: '38px' }}/> */}
        <SvgButton icon="apple" onClick={this.appleClick} />
      </div>
    );
  }
}
