const {
  USER_BY_ID_SET,
  USER_LOGIN,
  USER_SET,
  USER_BY_ID_FETCH,
  ACCESSED,
  LOGOUT,
  REG,
  SOCIAL_ACCOUNT,
  REG_ERROR_CLEAN,
  REG_LOGIN_ERROR,
  REG_EMAIL_ERROR,
  VERIFICATED_PHONE,
  PHONE_SEND,
  USER_EMAIL_CODE_FETCH,
  USER_EMAIL_CODE_SET,
  PHONE_CODE_SEND,
  USER_EDIT_FETCH,
  USER_EDIT_SET,
  USER_SET_DATA,
  SOCKET_EVENT_GAME_END,
  SOCKET_EVENT_GAME_START,
  USER_SUCCESS_RESET,
  USER_FAILED_RESET,
  USER_PASSRESET_CODE,
  USER_PASSWORD_UPDATE,
  USER_PASSWORD_UPDATE_COMPLETE,
  USER_PASSWORD_UPDATE_FAILED,
  USER_PASSRESET_STATUS,
  PASS_CHANGE_FETCH,
  PASS_RESPONSE,
  USER_ERROR_CLEAR,
  SET_RELOGIN,
  FIRST_ENTER,

  GET_PERMISSIONS,
  SET_PERMISSIONS,
  GET_BOWLINGCENTER_PERMISSIONS,
  SET_BOWLINGCENTER_PERMISSIONS,
  GET_ACTIVE_USER_COUNT,

  CHECK_LOGIN_STRING,
  SET_LOGIN_CHECK_RESULT,
  FETCH_USERSTATS,
  SET_USERSTATS,
  EMAIL_CODE_CHECK,
  CONFIRM_EMAIL,
  REG_EMAIL_SEND,
  USER_EMAIL_CODE_ERROR,

  CHANGE_HELP_STATUS,
  FETCH_HELP_STATUS,
  SET_HELP_STATUS,
  SET_COUNT_USERS_REGISTERED,
  SET_ACTIVE_USERS,
  SET_MAX_WATCHER_COUNT,
  SAVE_MAX_WATCHER_COUNT,
  SET_DISABLE_SHOW_MODAL_SEND_APPLICATION,
  GET_REFERRAL_LINK,
  SET_REFERRAL_LINK,
  SET_NEW_USER,
} = require('./actiontypes').default;

 const initialState = {
  userById: {
    fetching: false,
    error: '',
    data: {},
    modal: true,
    disableChallenge: false,
  },
  userEdit: { fetching: false, error: '', data: {} },
  passwordEdit: { fetching: false, error: '', data: {} },
  user: {},
  userStat: { fetch: false },
  permissions: { keys: [], fetch: false },
  passResetStatus: 'NONE',
  passResetLogin: null,
  passUpdateStatus: '',
  passRecoveryStatus: 'NONE',
  relogin: false,
  reloginCount: 0,
  checkLoginString: {
    loading: false,
    success: 1,
    error: '',
  },
  socialReg: {},
  withoutErrorModal: false,
  bowlingCenterPermissions: { fetching: false, ids: [], error: null },
  firstEnter: false,
  confirmEmail: false,
  confirmEmailError: false,
  codeSended: false,
  countUsersRegistered: null,
  accessed: false,
  snuser: {},
  userFetch: false,
  loginerror: '',
  emailerror: '',
  userActiveCount: 0,
  maxWatcherCountUpdate: '',
  disableShowModalSendApplication: false,
  referral:{
    referralFetch: false,
    url: {}
  },
  newUser: 1,
};

const UserReduсer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PERMISSIONS:
      return { ...state, permissions: { ...state.permissions, fetch: true } }
    case SET_PERMISSIONS:
      return { ...state, permissions: { keys: action.data, fetch: false } }
    case GET_BOWLINGCENTER_PERMISSIONS:
      return {
        ...state,
        bowlingCenterPermissions: {
          ids: state.bowlingCenterPermissions && state.bowlingCenterPermissions.ids || [],
          fetching: true,
        },
      };
    case SET_BOWLINGCENTER_PERMISSIONS:
      return {
        ...state,
        bowlingCenterPermissions: {
          ids: action.error ? [] : action.payload,
          error: action.error instanceof Error ? action.error.message : action.error,
          fetching: false,
        },
      };
    case SET_ACTIVE_USERS:
      return {
        ...state,
        userActiveCount: action.params.data,
      };
    case SET_MAX_WATCHER_COUNT:
      return {
        ...state,
        maxWatcherCount: action.params.data,
      };
    case SAVE_MAX_WATCHER_COUNT:
      console.log('SAVE_MAX_WATCHER_COUNT', action);
      return {
        ...state,
        maxWatcherCountUpdate: { maxWatcherCount: action.payload },
      };
    case USER_SUCCESS_RESET:
      return {
        ...state,
        passResetStatus: 'SUCCESS',
        passResetLogin: action.data.login,
      };
    case 'USER_REG':
      return {
        ...state,
        reg_loading: true,
      }
    case 'USER_REG_DONE':
      return {
        ...state,
        reg_loading: false,
      }
    case USER_FAILED_RESET:
      return {
        ...state,
        passResetStatus: 'FAILED',
      };
    case USER_PASSRESET_STATUS:
      return {
        ...state,
        passRecoveryStatus: action.data,
      };
    case USER_PASSRESET_CODE:
      return {
        ...state,
        passResetStatus: 'FETCHING',
        passResetLogin: null,
      };
    case USER_PASSWORD_UPDATE:
      return {
        ...state,
        passUpdateStatus: 'LOADING',
        passUpdatePayload: '',
      };
    case USER_PASSWORD_UPDATE_COMPLETE:
      return {
        ...state,
        passUpdateStatus: 'COMPLETE',
        passUpdatePayload: action.data.login,
      };
    case USER_PASSWORD_UPDATE_FAILED:
      return {
        ...state,
        passUpdateStatus: 'FAILED',
        passUpdatePayload: action.data.error,
      };
    case PASS_CHANGE_FETCH:
      return {
        ...state,
        passwordEdit: {
          fetching: true,
          error: '',
          data: {},
        },
      };
    case PASS_RESPONSE:
      return {
        ...state,
        passwordEdit: {
          fetching: false,
          error: '',
          data: action.data,
        },
      };
    case CHECK_LOGIN_STRING:
      return {
        ...state,
        checkLoginString: {
          ...state.checkLoginString,
          loading: true,
          error: '',
        },
      };
    case SET_LOGIN_CHECK_RESULT:
      return {
        ...state,
        checkLoginString: {
          loading: false,
          success: !!action.payload.success,
          error: action.payload.error,
        },
      };
    case USER_BY_ID_FETCH:
      return {
        ...state,
        userById: {
          ...initialState.userById,
          fetching: true,
          modal: !action.params.noModal,
          disableChallenge: !!action.params.disableChallenge
        },
      };
    case USER_BY_ID_SET:
      return {
        ...state,
        userById: {
          ...initialState.userById,
          modal: state.userById.modal,
          disableChallenge: !!state.userById.disableChallenge,
          error: action.error || '',
          data: action.data || {},
        },
      };
    case USER_LOGIN:
      return { ...state, userFetch: true }
    case USER_SET:
      return {
        ...state,
        userFetch: false,
        user: action.params || {},
        error: action.error || '',
        withoutErrorModal: !!action.withoutErrorModal,
      };
    case ACCESSED:
      return {
        ...state,
        accessed: action.params,
        // стираем статус успешной регистрации при выходе из аккаунта
        registration_complete: action.params ? state.registration_complete : false,
      };
    case 'REGISTRATION_COMPLETE_SET':
      return {
        ...state,
        registration_complete: action.params,
      }
    case LOGOUT:
      return { ...initialState };
    case REG_ERROR_CLEAN:
      return {
        ...state,
        loginerror: '',
        emailerror: '',
      };
    case USER_ERROR_CLEAR:
      return { ...state, error: '' }
    case REG:
      return {
        ...state,
        reg: action.params,
        loginerror: '',
        emailerror: '',
        // добавил новое поле, чтобы сохранить статус завершения регистрации
        registration_complete: state.registration_complete || (state.reg && !action.params && state.accessed),
      };
    case FIRST_ENTER:
      return {
        ...state,
        firstEnter: action.payload,
      };
    case SOCIAL_ACCOUNT:
      return {
        ...state,
        snuser: {
          ...action.payload,
        },
      };
    case REG_LOGIN_ERROR:
      return { ...state, reg: false, loginerror: action.params };
    case REG_EMAIL_ERROR:
      return { ...state, reg: state.reg || false, emailerror: action.params };
    case REG_EMAIL_SEND:
      return { ...state, emailerror: '', codeSended: true};
    case VERIFICATED_PHONE:
      return { ...state, phone: action.params.phone };
    case PHONE_SEND:
      return { ...state, phonesuccess: action.params.success };
    case PHONE_CODE_SEND:
      return { ...state, phonecodesuccess: action.params.success };
    case USER_SET_DATA:
    case USER_EDIT_FETCH:
      return { ...state, userEdit: { ...state.userEdit, error: '', fetching: true } };
    case USER_EDIT_SET:
      return { ...state, userEdit: { error: action.error || '', fetching: false, data: action.data || {} } };
    case USER_EMAIL_CODE_FETCH:
      return { ...state, confirmEmailError: false, user: { ...state.user, emailSuccess: false } };
    case USER_EMAIL_CODE_SET:
      return {
        ...state,
        emailerror: '',
        user: {
          ...state.user,
          emailSuccess: true,
        },
      };
    case USER_EMAIL_CODE_ERROR:
      return {
        ...state,
        emailerror: action.params,
      };
    case SOCKET_EVENT_GAME_END:
      return { ...state, user: { ...state.user, squadid: null } };
    case SOCKET_EVENT_GAME_START:
      return {
        ...state,
        user: {
          ...state.user,
          squadid: action.params,
        },
      };
    case SET_RELOGIN:
      return {
        ...state,
        relogin: action.payload,
        reloginCount: action.payload ? (state.reloginCount || 0) + 1 : (state.reloginCount || 0),
      };
    case FETCH_USERSTATS:
      return { ...state, userStat: { fetch: true } }
    case SET_USERSTATS:
      return { ...state, userStat: { ...action.params, fetch: false } }
    case EMAIL_CODE_CHECK:
      return {
        ...state,
        user: {
          ...state.user,
          emailVerification: action.params,
        },
      };
    case CONFIRM_EMAIL:
      return {
        ...state,
        codeSended: false,
        emailerror: false,
        confirmEmail: action.params
      }
    case 'FETCH_EMAIL_VERIFIED':
      return { ...state, userFetch: true }
    case 'SET_EMAIL_VERIFIED':
      return {
        ...state,
        userFetch: false,
        confirmEmailError: false,
        user: {
          ...state.user,
          emailVerified: action.payload.emailVerified
        }
      }
    case 'FAILURE_EMAIL_VERIFIED':
      return { ...state, userFetch: false, confirmEmailError: true, user: { ...state.user, emailVerified: 0 } }

    case CHANGE_HELP_STATUS:
      return state;
    case FETCH_HELP_STATUS:
      return { ...state, userFetch: true }
    case SET_HELP_STATUS:
      return { ...state, userFetch: false, user: { ...state.user, helpWatched: action.payload.helpWatched }}
    case SET_COUNT_USERS_REGISTERED:
      return { ...state, countUsersRegistered: action.params }
    case SET_DISABLE_SHOW_MODAL_SEND_APPLICATION:
      return  { ...state, disableShowModalSendApplication: action.params }

    case   GET_REFERRAL_LINK:
      return {...state, referral: {referralFetch: true}}
    case   SET_REFERRAL_LINK:
      return {...state, referral: {referralFetch: false, url: {...action.params}}}
    case SET_NEW_USER:
      return {...state, newUser: action.params}
    default:
      return state;
  }
};

export default UserReduсer;
export const userInitialState = initialState
