import React, { Component } from 'react';
import image from './images/landing_tournament.png';
import TournamentDropDown from './tournamentDropDown';

import './style.scss';
import './adaptive.scss';

export default class LandingRules extends Component {
  render() {
    const { intl, history } = this.props;

    return (
    <div className="landing__container">
      <div className='tournamentBody'>
        <h2>{intl.formatMessage({ id: 'LandingTournamentEveryMonth', defaultMessage: 'New tournament every month!' })}</h2>
        <div id="tournament" className='tournament'>
          <div className='text'>
            <p className="description">
              {intl.formatMessage({
                id: 'LandingTournamentP1',
                defaultMessage: 'The history of bowling tournaments is more than 50 years. Bowlers got used to the traditional live formats - singles, leagues etc. The technology is changing the world & now the time came to reinvent the bowling tournaments.',
              })}
            </p>
            <br />
            <p className="description">
              <b>
                {intl.formatMessage({ id: 'LandingTournamentP21', defaultMessage: 'We are offering a revolutionary way for the bowling tournaments.' })}
              </b> {intl.formatMessage({ id: 'LandingTournamentP22', defaultMessage: 'It combines the real bowling game with the digital experience.' })}
            </p>
            <br />
            <p className="description">
              {
                intl.formatMessage({
                  id: 'LandingTournamentP3',
                  defaultMessage: 'You don’t need to wait for the tournaments, spend money on the travel & accommodation. All you need, is to go to the nearest bowling center, which is connected to the TenPinCam network, install our app & join one of our tournaments from your home alley.',
                })
              }
            </p>
          </div>
          <div className='image'>
            <img src={image}/>
          </div>
        </div>
        <div className='oswald_text'>
          {
            intl.formatMessage({
              id: 'LandingTournamentSubTitle',
              defaultMessage: 'Join the new format of the tournaments - you are going to love it!'
            })
          }
        </div>
        <TournamentDropDown />
        <div className="tournament-btn">
          <a
            href="/howtoinstall"
            onClick={() => {
              if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
                if (window.ym) {
                  const ymKey = 52252669;
                  window.ym(ymKey, 'reachGoal', 'MobileClickDownload');
                }
              }
            }}
            className="btn big filled"
          >
            {
              intl.formatMessage({
                id: 'LandingTournamentBtn',
                defaultMessage: 'Sing in to join the tournament',
              })
            }
          </a>
        </div>
      </div>
    </div>
    );
  }
}
