import React, { Component } from 'react';
import { Table } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import Select from '../../common/Select';
import { Button } from '../../common/Button';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import moment from 'moment';
import { 
  UserByIdFetch,
  fetchProtestList,
  refereeConsider,
  refereeRefuse,
  unsetVideoArchiveSquadId,
  getProtocol,
  setProtocolSquadId,
} from '../../../libs/reducers/actions';
import ModalProtestList from '../../common/modals/modalProtestList';
import RefereeingUserMenu from './usermenu';
import Notifications from '../../notifications';
import TournamentsChat from '../tournamentsChat/tournamentsChat';

import './style.scss';
import ModalDialog from '../../common/modals/modalDialog';
import SvgEye from '-!svg-react-loader!../../../images/eye.svg'; // eslint-disable-line
import SvgRefereeing from '-!svg-react-loader!../../../images/refereeing.svg'; // eslint-disable-line
import SvgNotification from '-!svg-react-loader!../../../images/Notification.svg'; // eslint-disable-line
import SvgChat from '-!svg-react-loader!../../../images/chat.svg'; // eslint-disable-line
import BadgeIcon from '../../common/BadgeIcon/BadgeIcon';

class RefereeingTable extends Component {

  constructor(props) {

    super(props);

    this.state = {
      data: null,
      fetching: false,
      status: null,
      type: null,
      action: false,
      party: null,
      showProtestListModal: false,
      showErrorModal: false,
      error: null,
      selectedSidebar: 1,
    };
    
  }

  componentDidUpdate(prevProps, prevState) {
    const nextProps = this.props;
    if (!prevState.fetching && nextProps.protestList.isFetching) {
      this.setState({ fetching: true });
    }
    if (prevState.fetching && !nextProps.protestList.isFetching) {
      this.setState({ fetching: false, data: nextProps.protestList.data });
      if (prevState.action && prevState.party) {
        this.setState({ fetching: false, data: nextProps.protestList.data });
        nextProps.setProtocolSquadId(prevState.party.id)
        nextProps.history.push('/protocol');
      }
    }
    if (prevState.action && nextProps.protestList.error) {
      this.setState({ fetching: false, action: false, showErrorModal: true, error: nextProps.protestList.error });
    }
  }

  componentDidMount() {
    this.props.fetchProtestList();
  }

  showUser = function (userid) {
    this.props.UserByIdFetch({
      userid: userid,
    });
  }

  onCancel = function () {
    this.setState({ showProtestListModal: false });
  }

  onResolve = function () {
    this.setState({ showProtestListModal: false });
  }

  onRefuse = function () {
    this.props.refereeRefuse({ squadid: this.state.party.id });
    this.setState({ showProtestListModal: false });
  }

  gotoProtocol = function (squadid) {
    this.props.setProtocolSquadId(squadid);
    this.props.history.push('/protocol');
  }

  renderSidebar = () => {
    const { selectedSidebar } = this.state;
    switch (selectedSidebar) {
      case 1: 
        return <RefereeingUserMenu/>
      case 2:
        return <Notifications/>
      case 3:
        return <TournamentsChat/>
      default: 
        return null;
    }
  } 

  onClickSidebarItem = (index) => {
    const { selectedSidebar } = this.state;
    if (selectedSidebar == index) {
      this.setState({ selectedSidebar: null })
    } else {
      this.setState({ selectedSidebar: index }) 
    }
  }

  render () {
    const { intl, rooms, unreadNotifications } = this.props;
    const { type, status, data, action, showProtestListModal, party, showErrorModal, error, selectedSidebar } = this.state;
    let unreadMessages = 0;
    for (const room of rooms) {
      const { message: { total = 0, seen } = {} } = room;
      unreadMessages += (total - (seen || 0));
    }
    const typeOptions = [
      { value: 1, label: intl.formatMessage({ id: 'IndividualBattle', defaultMessage: 'Individual battle' }) },
      { value: 2, label: intl.formatMessage({ id: 'BattlesOfTournament', defaultMessage: 'Battle of tournament' }) },
    ];
    const statusOptions = [
      { value: 1, label: intl.formatMessage({ id: 'NotReviewedStatus', defaultMessage: 'Not reviewed' }) },
      { value: 2, label: intl.formatMessage({ id: 'OnReviewStatus', defaultMessage: 'On review' }) },
      { value: 3, label: intl.formatMessage({ id: 'ReviewedStatus', defaultMessage: 'Reviewed' }) },
      { value: 4, label: intl.formatMessage({ id: 'DecidedStatus', defaultMessage: 'Decided' }) },
      { value: 5, label: intl.formatMessage({ id: 'ExpiredReview', defaultMessage: 'Expired review' }) },
    ];
    let list = [];
    if (data) {
      list = [ ...data.all ];
      if (!_.isEmpty(list)) {
        if (type !== null) {
          list = list.filter(i => i.tournamenttypeid == type.value);
        }
        if (status !== null) {
          list = list.filter(i => i.status == status.value);
        }
      }
    }
    const columns = [
      {
        title: intl.formatMessage({ id: 'PartyNumber', defaultMessage: '# of party' }),
        dataIndex: 'squadid',
      },
      {
        title: intl.formatMessage({ id: 'Tournament', defaultMessage: 'Tournament' }),
        render: (data, record, index) => {
          switch (record.tournamenttypeid) {
            case 1:
              return 'Battle';
            case 4:
              return 'Pinfall Battle';
            default:
              return record.tournamentname;
          }
        }
      },
      {
        title: intl.formatMessage({ id: 'Opponents', defaultMessage: 'Opponents' }),
        render: (data, record, index) => {
          if (!record) {
            return (
              <span></span>
            )
          }
          const { players } = record;
          return (
            <div className="refereeing_table_players">
              <Link to="#" onClick={(e) => {
                  e.preventDefault();
                  if (players[0]) this.showUser(players[0].userid)
                }}
              >
                {`${(players[0]||{}).firstname} ${(players[0]||{}).lastname}`}
              </Link><br/>
              {
                players[1] 
                ? (
                  <Link to="#" onClick={(e) => {
                      e.preventDefault();
                      this.showUser(players[1].userid)
                    }}
                  >
                    {`${(players[1]||{}).firstname} ${(players[1]||{}).lastname}`}
                  </Link>
                )
                : null
              }
            </div>
          )
        }
      },
      {
        title: intl.formatMessage({ id: 'PartyStartDate', defaultMessage: 'Party start date' }),
        render: (data, record, index) => {
          if (!record) {
            return (
              <span></span>
            )
          } 
          return (
            <span>{moment(record.startdate).format('YYYY-MM-DD')}</span>
          )
        }
      },
      {
        title: intl.formatMessage({ id: 'Prize', defaultMessage: 'Prize' }),
        render: (data, record, index) => {
          if (!record) {
            return (
              <span></span>
            )
          } 
          return (
            <span>{`${record.tournamentprize ? `${record.tournamentprize}${record.currencysymbol}` : ''}`}</span>
          )
        }
      },
      {
        title: intl.formatMessage({ id: 'CountOfProtests', defaultMessage: 'Count of protests' }),
        align: 'right',
        render: (data, record, index) => {
          if (!record) {
            return (
              <span></span>
            )
          } 
          return (
            <span>{record.protestCount}</span>
          )
        }
      },
      // {
      //   title: intl.formatMessage({ id: 'ReviewPeriod', defaultMessage: 'Review period' }),
      //   dataIndex: 'daysleft',
      //   align: 'right',
      //   render: (data, record, index) => {
      //     if (!record) {
      //       return (
      //         <span></span>
      //       )
      //     }
      //     return ( 
      //       <span>{intl.formatMessage({ id: 'DayPlural', defaultMessage: '{count} {count, plural, one {day} other {days}}' }, { count: record.daysleft })}</span>
      //     )
      //   }
      // },
      {
        title: intl.formatMessage({ id: 'StatusAsStateAlias', defaultMessage: 'Status' }),
        defaultSortOrder: 'ascend',
        sorter: (a, b) => { 
          return a.status - b.status;
        },
        render: (data, record, index) => {
          if (!record) {
            return (
              <span></span>
            )
          }
          const { status, referee, squadid, startdate } = record;
          if (status == 1) {
            return (
              <Button loading={action} kind="aquamarine" size="md" onClick={() => {
                this.setState({ action: true, party: { id: squadid, date: startdate } });
                this.props.refereeConsider({ squadid });
              }}>
                {intl.formatMessage({ id: 'StartReview', defaultMessage: 'Start review' })} 
              </Button>
            )
          }
          if (status == 2 || status == 3) {
            return (
              <div>{intl.formatMessage({ id: 'OnReviewdBy', defaultMessage: 'On review by referee' })} <br/>
                <Link to="#" onClick={(e) => {
                    e.preventDefault();
                    this.showUser(referee.userid)
                  }}
                >
                  {`${referee.firstname} ${referee.lastname}`}
                </Link>
              </div>
            )
          }
          if (status == 3) {
            return (
              <div className="refereeing_table_closed">{intl.formatMessage({ id: 'Reviewed', defaultMessage: 'Reviewed by' })} <br/>
                <Link to="#" onClick={(e) => {
                    e.preventDefault();
                    this.showUser(referee.userid)
                  }}
                >
                  {`${referee.firstname} ${referee.lastname}`}
                </Link>
              </div>
            )
          }
          if (status == 4) {
            return (
              <div className="refereeing_table_resolved">
                <div className="refereeing_table_resolved__icon" onClick={() => {
                  this.gotoProtocol(squadid);
                }}>
                  <SvgEye/>
                </div>
                <div className="refereeing_table_resolved__link">{intl.formatMessage({ id: 'Decided', defaultMessage: 'Decided by' })} <br/>
                  <Link to="#" onClick={(e) => {
                      e.preventDefault();
                      this.showUser(referee.userid)
                    }}
                  >
                    {`${referee.firstname} ${referee.lastname}`}
                  </Link>
                </div>
              </div>
            )
          }
          if (status == 5) {
            return (
              <span className="refereeing_table_expired">
                {intl.formatMessage({ id: 'ExpiredReview', defaultMessage: 'Expired review' })}
              </span>
            )
          }
          return null;
        }
      }
    ];
    return (
      <div className="refereeing_table">
        <div className="refereeing_table__container">
          <div className="refereeing_table__content">
            <div className="refereeing_table_filter_container">
              <div className="refereeing_table_type_container">
                <div className="refereeing_table_type_label">
                  {intl.formatMessage({ id: 'TournamentType', defaultMessage: 'Tournament type' })}
                </div>
                <div className="refereeing_table_type_select_container">
                  <Select
                    placeholder="Все"
                    value={type}
                    options={typeOptions}
                    onChange={(e) => {
                      this.setState({ type: e });
                    }}
                  />
                </div>
              </div>
              <div className="refereeing_table_status_container">
                <div className="refereeing_table_status_label">
                  {intl.formatMessage({ id: 'StatusAsStateAlias', defaultMessage: 'Status' })}
                </div>
                <div className="refereeing_table_status_select_container">
                  <Select
                    placeholder="Все"
                    value={status}
                    options={statusOptions}
                    onChange={(e) => {
                      this.setState({ status: e });
                    }}
                  />
                </div>
              </div>
              <Link
                className="refereeing_table_reset_container"
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ 
                    status: null,
                    type: null
                  });
                }}
              >
                {intl.formatMessage({ id: 'reset', defaultMessage: 'Reset' })}
              </Link>
            </div>
            <Table
              key={this.props.langs}
              className="refereeing_table_table"
              rowClassName="refereeing_table_table_row"
              columns={columns}
              dataSource={list}
              pagination={false}
              rowKey={record => record.squadid}
              locale={{ emptyText: intl.formatMessage({ id: 'EmptyProtestList', defaultMessage: 'No protests' }) }}
            />
          </div>
          <div className="refereeing_table__aside">
            <div className="refereeing_table__aside__body">
                {this.renderSidebar()}
            </div>
            <div className="refereeing_table__aside__footer">
              <div className="refereeing_table__aside__items">
                <div className={`refereeing_table__aside__item${selectedSidebar == 1 ? ' active' : ''}`}
                  onClick={() => {
                    this.onClickSidebarItem(1)
                  }}
                >
                  <SvgRefereeing/>
                </div>
                <div className={`refereeing_table__aside__item${selectedSidebar == 2 ? ' active' : ''}`}
                  onClick={() => {
                    this.onClickSidebarItem(2)
                  }}
                >
                  <BadgeIcon count={unreadNotifications}>
                    <SvgNotification />
                  </BadgeIcon>
                </div>
                <div className={`refereeing_table__aside__item${selectedSidebar == 3 ? ' active' : ''}`}
                  onClick={() => {
                    this.onClickSidebarItem(3)
                  }}
                >
                  <BadgeIcon count={unreadMessages}>
                    <SvgChat />
                  </BadgeIcon>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          showProtestListModal && 
          <ModalProtestList 
            visible
            party={party}
            onCancel={this.onCancel.bind(this)}
            onResolve={this.onResolve.bind(this)}
            onRefuse={this.onRefuse.bind(this)}
          />
        }
        { error &&
          <ModalDialog
            visible={showErrorModal}
            isAcceptButton={false}
            onClose={() => { 
              this.setState({ showErrorModal: false })
            }}
            declineButtonText={intl.formatMessage({ id: 'ModalDefaultOK', defaultMessage: 'OK' })}
            title={intl.formatMessage({ id: 'Error', defaultMessage: 'Error' })}
          >
            {intl.formatMessage({ id: error, defaultMessage: error })}
          </ModalDialog>
        }
      </div>
    );
  }

}

export default injectIntl(withRouter(
  connect(
    state => ({
      langs: state.langs,
      protestList: state.protestList,
      videoarchive: state.videoarchive,
      protocol: state.protocol,
      unreadNotifications: state.notifications.notifications.count,
      rooms: state.chat.rooms,
    }),
    {
      UserByIdFetch,
      fetchProtestList,
      refereeConsider,
      refereeRefuse,
      unsetVideoArchiveSquadId,
      getProtocol,
      setProtocolSquadId,
    }
  )(RefereeingTable)
));