/**
 * @typedef {(...args: any[]) => void} Callback
 */

class EventEmitter {
  constructor() {
    /**
     * @protected
     * @type {Record<string, Callback[]>}
     */
    this._listeners = {}
  }

  /**
   * @param {string} event
   * @param  {any[]} args
   */
  emit(event, ...args) {
    const listeners = this._listeners[event]
    if (!listeners) {
      return
    }
    for (const callback of listeners) {
      try {
        callback(...args)
      } catch (e) {
        this.log('error', e)
      }
    }
  }

  /**
   * @param {string} event
   * @param {Callback} callback
   */
  on(event, callback) {
    const listeners = this._listeners[event] = this._listeners[event] || []
    if (listeners.indexOf(callback) !== -1) {
      return
    }
    listeners.push(callback)
    return { remove: () => this.off(event, callback) };
  }

  /**
   * @param {string} event
   * @param {Callback} callback
   */
  off(event, callback) {
    const listeners = this._listeners[event]
    if (!listeners) {
      return
    }
    const index = listeners.indexOf(callback)
    if (index === -1) {
      return
    }
    listeners.splice(index, 1)
  }

  /** @param {string} event */
  hasListener(event) {
    return this.countListeners(event) > 0
  }

  /** @param {string} event */
  countListeners(event) {
    const listeners = this._listeners[event]
    return Array.isArray(listeners) ? listeners.length : 0
  }

  /** @private */
  log(...args) {
    console.log('[EventEmitter]', ...args)
  }
}

export { EventEmitter }
