const {
  BOWLING_CENTER_SEND_REQUEST,
  BOWLING_CENTER_AFTER_SEND_REQUEST,
  LOGOUT,
  TOGGLE_STREAM_FETCH,
  TOGGLE_STREAM_FETCHED,
} = require('./actiontypes').default;

export const BOWLING_CENTERS_FETCH = 'BOWLING_CENTERS_FETCH';
export const BOWLING_CENTERS_SET = 'BOWLING_CENTERS_SET';
export const BOWLING_CENTERS_COUNTRY_FETCH = 'BOWLING_CENTERS_COUNTRY_FETCH';
export const BOWLING_CENTERS_COUNTRY_SET = 'BOWLING_CENTERS_COUNTRY_SET';
export const BOWLING_CENTERS_CITIES_FETCH = 'BOWLING_CENTERS_CITIES_FETCH';
export const BOWLING_CENTERS_CITIES_SET = 'BOWLING_CENTERS_CITIES_SET';
export const BOWLING_CENTER_BY_LOCATION_FETCH = 'BOWLING_CENTER_BY_LOCATION_FETCH';
export const BOWLING_CENTER_BY_LOCATION_SET = 'BOWLING_CENTER_BY_LOCATION_SET';

export const getBowlingCenters = () => ({ type: BOWLING_CENTERS_FETCH });
export const getBowlingCentersCoutry = () => ({ type: BOWLING_CENTERS_COUNTRY_FETCH });
export const getBowlingCentersCities = params => ({ type: BOWLING_CENTERS_CITIES_FETCH, params });
export const getBowlingCenterFromLocation = () => ({ type: BOWLING_CENTER_BY_LOCATION_FETCH });

export const toggleStream = (payload) => ({type: TOGGLE_STREAM_FETCH, payload});

const initialState = {
  bowlingcenters: {
    data: [],
    location: { fetching: false },
    fetching: false,
    error: '',
  },
  country: {
    data: [],
    fetching: false,
    error: '',
  },
  cities: {
    data: [],
    fetching: true,
    error: '',
  },
  request: {
    sending: false,
    error: ''
  },
  toggleStreamFetch: false,
};

const bowlingCentersReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_STREAM_FETCH: {
      return { ...state, toggleStreamFetch: true}
    }
    case TOGGLE_STREAM_FETCHED: {
      return { ...state, toggleStreamFetch: false}
    }
    case BOWLING_CENTERS_FETCH:
      return {
        ...state,
        bowlingcenters: {
          ...state.bowlingcenters,
          data: [],
          fetching: true,
          error: '',
        },
      };
    case BOWLING_CENTERS_SET:
      return {
        ...state,
        bowlingcenters: {
          ...state.bowlingcenters,
          data: action.data || [],
          fetching: false,
          error: action.error || '',
        },
      };
    case BOWLING_CENTERS_COUNTRY_FETCH:
      return {
        ...state,
        country: {
          data: [],
          fetching: true,
          error: '',
        },
      };
    case BOWLING_CENTERS_COUNTRY_SET:
      return {
        ...state,
        country: {
          data: action.data || [],
          fetching: false,
          error: action.error || '',
        },
      };
    case BOWLING_CENTERS_CITIES_FETCH:
      return {
        ...state,
        cities: {
          data: [],
          fetching: true,
          error: '',
        },
      };
    case BOWLING_CENTERS_CITIES_SET:
      return {
        ...state,
        cities: {
          data: action.data || [],
          fetching: false,
          error: action.error || '',
        },
      };
    case BOWLING_CENTER_BY_LOCATION_FETCH:
      return { ...state, bowlingcenters: { ...state.bowlingcenters, location: {fetching: true} }};
    case BOWLING_CENTER_BY_LOCATION_SET:
      return { ...state, bowlingcenters: { ...state.bowlingcenters, location: {...action.data, fetching: false} }};
    case BOWLING_CENTER_SEND_REQUEST:
      return { ...state, request: { sending: true, error: '' } };
    case BOWLING_CENTER_AFTER_SEND_REQUEST:
      if (action.error) {
        return { ...state, request: { sending: false, error: action.error } };
      }
      return { ...state, request: { sending: false, error: '' } };
    case LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};

export default bowlingCentersReducer;
