const initialState = {
  global: {
    confirmed: false,
  },
  user: {
    confirmed: false,
  },
  activeModal: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'REG':
      // если регистрация, то активируем модалку, иначе оставляем как было
      return {
        ...state,
        activeModal: action.params ? true : state.activeModal,
      };
    case 'LOGOUT':
      // при выходе деактивируем модалку и сбрасываем состояние пользователя
      return {
        ...state,
        user: { confirmed: false },
        activeModal: true,
      };
    case 'SEND_COOKIE_AGREEMENT':
      // деактивируем модалку, когда отправляется запрос на согласие / отказ
      // P.S. после SEND_COOKIE_AGREEMENT следует SET_COOKIE_AGREEMENT
      return {
        ...state,
        activeModal: false,
      };
    case 'SET_COOKIE_AGREEMENT_MODAL':
      return {
        ...state,
        activeModal: action.payload,
      };
    case 'SET_COOKIE_AGREEMENT':
      if (action.payload.userid) {
        return { ...state, user: { ...action.payload } };
      }
      return { ...state, global: { confirmed: action.payload.confirmed } };
    case 'RESET_COOKIE_AGREEMENT':
      return { ...initialState };
    default:
      return state;
  }
};
