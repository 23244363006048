import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';

import './style.scss';
import Opponent from '../common/Opponent';

class PlayerPicker extends React.Component {
  static propTypes = {
    intl: intlShape,
    selectPlayer: PropTypes.func,
    label: PropTypes.string,
    players: PropTypes.array,
    selectedPlayer: PropTypes.object,
  };

  render() {
    const { intl, selectPlayer, selectedPlayer, label, players } = this.props;
    return(
      <div className="playerPicker">
        <div className="label">{label}</div>
        <div className="selectedPlayer">
          {selectedPlayer ?
          <Opponent
            userid={selectedPlayer.userid}
            fullname={`${selectedPlayer.firstname} ${selectedPlayer.lastname}`.trim()}
            nickname={selectedPlayer.login}
            photo={selectedPlayer.photo}
            flag={selectedPlayer.country}
            rating={+selectedPlayer.rating || 0}
            avgscore={+selectedPlayer.avgscore || 0}
            />
          :
          <Opponent
            userid={0}
            fullname={intl.formatMessage({ id: 'CreateBattleChooseParticipant', defaultMessage: 'Choose participant' })}
            avgscore={0}
            />
          }
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="close" onClick={() => {selectPlayer(false)}}>
              <g opacity="0.4"><path d="M11.7147 5.05926L10.9718 4.31641L8.00042 7.28783L5.02899 4.31641L4.28613 5.05926L7.25756 8.03069L4.28613 11.0021L5.02899 11.745L8.00042 8.77355L10.9718 11.745L11.7147 11.0021L8.74328 8.03069L11.7147 5.05926Z" fill="white"/></g>
              <rect opacity="0.4" x="0.5" y="0.5" width="15" height="14.9544" stroke="white"/>
            </svg>
            <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg" className='dropDownButton'>
              <path opacity="0.4" d="M7 6.66797L0.0717958 0.16797L13.9282 0.167969L7 6.66797Z" fill="white"/>
            </svg>
            <div className="shutter"/>
            <div className="dropDownMenu">
               {players.sort((a, b) => {
                   a = a.firstname
                   b = b.firstname
                   /*if (b.firstname > a.firstname) return -1
                   if (b.firstname < a.firstname) return 1*/
                   return a.toLowerCase().localeCompare(b.toLowerCase());
               }).map(player =>
                   <div className='player' onClick={() => {selectPlayer(player)}}>
                       <Opponent
                           userid={player.userid}
                           fullname={`${player.firstname} ${player.lastname}`.trim()}
                           nickname={player.login}
                           photo={player.photo}
                           flag={player.country}
                           rating={+player.rating || 0}
                           avgscore={+player.avgscore || 0}
                       />
                   </div>

              )}
            </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(
  connect(
    state => ({
      playersUpdate: state.tournaments.fetches,
    }),
    {
    },
  )(PlayerPicker)
);
