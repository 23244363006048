import React from 'react'
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import skittles from '../../images/skittles.png';
import SvgSwords from '-!svg-react-loader!../../images/sword.svg'; // eslint-disable-line


const NoDataFound = ({onBattleWillCreate, intl}) => {
  
  return (
    <div className="lobby__empty_container">
      <div className="lobby__no_data_found">
        <div className="lobby__no_data_found__image">
          <img src={skittles} alt=""/>
        </div>
        <div className="lobby__no_data_found__title">
          {intl.formatMessage({ id: 'NoBattlesFoundTitle', defaultMessage: 'Battles not yet created' })}
        </div>
        <div className="lobby__no_data_found__description">
          {intl.formatMessage({ id: 'NoBattlesFoundDescription', defaultMessage: 'Create Battle and players of all world will can play with you' })}
        </div>
        <div className="lobby__no_data_found__button create" onClick={onBattleWillCreate}>
          <div className="lobby__no_data_found__button__icon">
            <SvgSwords/>
          </div>
          <div className="lobby__no_data_found__button__text">
            {intl.formatMessage({ id: 'create_battle', defaultMessage: 'Create Battle' })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(withRouter((NoDataFound)))