import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Input extends Component {
    static propTypes = {
        value: PropTypes.string,
        placeholder: PropTypes.string,
        eventEmitter: PropTypes.object,
        eventType: PropTypes.string,
        onSubmit: PropTypes.func,
        showSubmitBtn: PropTypes.bool,
    }
    static defaultProps = {
        showSubmitBtn: false,
    }
    constructor(props) {
        super(props);
        this.state = { value: props.value }
    }
    componentDidMount() {
        const { eventEmitter, eventType } = this.props;
        if (eventEmitter && eventType) {
            eventEmitter.on('submit', this.submit);
        }
    }
    componentWillUnmount() {
        const { eventEmitter, eventType } = this.props;
        if (eventEmitter && eventType) {
            eventEmitter.off('submit', this.submit);
        }
    }
    render() {
        const { value } = this.state;
        const { name, placeholder, showSubmitBtn, style } = this.props;
        return (<div>
            <input
                type="text"
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={this.onChange}
                style={style}
            />
            {showSubmitBtn && <button onClick={this.submit}>submit</button>}
        </div>);
    }
    onChange = (e) => {
        this.setState({ value: e.target.value })
    }
    submit = () => {
        if (typeof this.props.onSubmit === 'function') {
            this.props.onSubmit(this.props.name, this.state.value);
        }
    }
}

