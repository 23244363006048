import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

export default class Timer extends Component {
  static propTypes = {
    format: PropTypes.string,
    targetDate: PropTypes.instanceOf(Date),
    updateFreq: PropTypes.number,
    inverse: PropTypes.bool,
    log: PropTypes.bool,
    paused: PropTypes.bool,
  };

  static defaultProps = {
    inverse: false,
    format: 'mm:ss',
    updateFreq: 500,
  };

  render() {
    const {
      format: timeFormat,
      targetDate,
      updateFreq,
      inverse,
      paused
    } = this.props;
    if (!targetDate) {
      return null;
    }
    let time = '';
    if (
      (
        +new Date(targetDate) > +new Date(new Date().toISOString())
        && !inverse
      )
      || (
        +new Date(targetDate) < +new Date(new Date().toISOString())
        && inverse
      )
    ) {
      time = moment.utc(
        moment(inverse ? new Date().toISOString() : targetDate)
          .diff(moment(inverse ? targetDate : new Date().toISOString())))
        .format(timeFormat);
      if (!paused) {
        setTimeout(() => this.forceUpdate(), updateFreq);
      }
    } else {
      time = '00:00'
    }
    return (
        <div className='turn-timer-container'>{time}</div>
    );
  }
}
