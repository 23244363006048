const { 
  SET_REDIRECT
} = require('./actiontypes').default;

const initialState = {
  from: null
};

const redirectReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REDIRECT:
      return { from: action.payload };
    default:
      return state;
  }
};

export default redirectReducer;