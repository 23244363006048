import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';

import bugsnagClient from '../../../bugsnagClient';
import config from '../../../config';
import { _TournamentsGet } from '../Tournaments';
import { get, put as PUT } from '../../Networking';
import { requestSquadListCount } from '../../reducers/actions';

const getUsers = state => state.users;

const {
  BATTLES_SET,
  BATTLES_RESPONSE_TO_USER_SET,
  BATTLES_RESPONSE_TO_OWNER_SET,
  BATTLES_BATTLE_CANCEL_SET,
  SET_ACCOUNT_BATTLES,
  BATTLES_BATTLE_INVITE_SET,
  UPDATE_USER_DATA,
  MYBATTLES_SET,
  SQUAD_LIST_SET,
  SQUAD_LIST_FETCH,
  ACCOUNT_BATTLES_HISTORY_SET,
  SQUAD_GETINFO_SET,
  SET_PROTEST_COUNT,
  OPENCHALLENGES_SET,
  CLOSED_OPENCHALLENGES_FETCHED,
  ACTIVE_SQUADS_COUNT_SET,
  ACTIVE_SQUADS_COUNT_GET,
} = require('../../reducers/actiontypes').default;
const { COMMON_ERROR, COMMON_NOTIFICATION_PARAMS } = require('../../reducers/commonReducer');

export function* _BattlesGet() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/battle` });
    const mybattles = yield call(get, { url: `${config.FETCH.url}/battle/my?type=all` });
    const users = yield select(getUsers);
    if (result.status === 200) {
      const battles = [];
      if (result.data) {
        result.data.data.forEach((battle) => {
          // TODO: Вернуть логику по приглашениям
          if (_.find(battle.players, pl => pl.id === users.user.id
            && pl.confirmedbyowner > -1 && pl.confirmedbyuser > -1)) {
            const players = [];
            let numOfConfirmedPlayers = 0;
            battle.players.forEach((player) => {
              numOfConfirmedPlayers = (((player.confirmedbyowner === 1) && player.confirmedbyuser === 1)
                ? numOfConfirmedPlayers + 1
                : numOfConfirmedPlayers);
              if (player.confirmedbyowner > -1 && player.confirmedbyuser > -1) {
                players.push(player);
              }
            });
            let isInvited = 0;
            battle.players.forEach((player) => {
              if ((player.id === users.user.id) && (player.confirmedbyowner === 1 && player.confirmedbyuser === 0)) {
                isInvited += 1;
              } else if (player.confirmedbyowner === 1 && player.confirmedbyuser === 1) {
                isInvited += 1;
              } else if (player.confirmedbyowner === 1 && player.confirmedbyuser === 0) {
                isInvited -= 1;
              }
            });
            const privateBattle = battle.players.some(p => p.confirmedbyowner === 1 && p.confirmedbyuser === 0);
            battles.push({
              ...battle,
              players,
              isConfirmed: (privateBattle ? (isInvited !== 2) : (numOfConfirmedPlayers >= 2 || battle.tournament.createdby !== users.user.id)),
            });
          }
        });
      }
      yield put({ type: BATTLES_SET, params: battles });
      yield put({ type: SQUAD_LIST_FETCH });
      yield put(requestSquadListCount({ excludeQG: true }))
      if (mybattles.status === 200 && !mybattles.error) {
        yield put({ type: MYBATTLES_SET, params: mybattles.data.data });
      }
    }
  } catch (err) {
    bugsnagClient.notify(err, { context: 'BattlesGet' });
    console.error('Battles get: ', err);
  }
}

export function* _SquadListFetch({ payload }) {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/battle/list`, params: payload });
    if (result.status === 200) {
      yield put({ type: SQUAD_LIST_SET, params: result.data.data });
    } else {
      throw new Error(`Failed to get squad list ${(result && JSON.stringify({ data: result.data || {}, status: result.status || {} })) || ''}`) ;
    }
  } catch(error) {
    const errorMessage = `Error. SquadListFetch() ${error.message}`;
    bugsnagClient.notify(new Error(errorMessage));
    console.error(errorMessage)
  }
}

export function* _ActiveSquadCount({ payload }) {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/battle/count`, params: payload });
    if (result.status === 200) {
      yield put({ type: ACTIVE_SQUADS_COUNT_SET, params: result.data.data });
    } else {
      throw new Error(`Failed to get squad count ${(result && JSON.stringify({ data: result.data || {}, status: result.status || {} })) || ''}`) ;
    }
  } catch(error) {
    const errorMessage = `Error. ActiveSquadCount() ${error.message}`;
    bugsnagClient.notify(new Error(errorMessage));
    console.error(errorMessage)
  }
}

export function* _BattlesBattleCancel({ params = {} }) {
  try {
    const result = yield call(PUT, {
      url: `${config.FETCH.url}/battle/cancel`,
      data: params,
    });
    if (result.status === 200) {
      yield put({ type: BATTLES_BATTLE_CANCEL_SET, data: result.data });
      // TODO: объединить запросы
      yield call(_BattlesGet);
      yield call(_TournamentsGet);
    } else {
      yield put({ type: BATTLES_BATTLE_CANCEL_SET, error: result });
      bugsnagClient.notify(result, {
        context: 'BattlesBattleCancel',
        beforeSend: (report) => {
          report.errorMessage = 'Failed to cancel battle';
          report.severity = 'error';
        },
      });
      console.error('_BattlesGet: ', result);
    }
    yield put({ type: UPDATE_USER_DATA });
  } catch (error) {
    bugsnagClient.notify(error, { context: 'BattlesBattleCancel' });
    console.error('_BattlesBattleCancel: ', error);
    yield put({ type: BATTLES_BATTLE_CANCEL_SET, error });
  }
}

export function* _BattlesBattleInvite({ params = {} }) {
  try {
    const result = yield call(PUT, {
      url: `${config.FETCH.url}/battle/invite`,
      data: params,
    }, true);
    if (result.status === 200) {
      if (result.data.success === 0) {
        if (result.data.error === 'ageLimit') {
          yield put({ type: COMMON_NOTIFICATION_PARAMS, params: {
            messageType: result.data.error,
          }});
        } else {
          yield put({ type: COMMON_ERROR, data: result.data.error });
        }
      } 
      yield put({ type: BATTLES_BATTLE_INVITE_SET, data: result.data });
      // TODO: объединить запросы
      yield call(_BattlesGet);
      yield call(_TournamentsGet);
    } else {
      yield put({ type: BATTLES_BATTLE_INVITE_SET, error: result });      
      bugsnagClient.notify(result, {
        context: 'BattlesBattleInvite',
        beforeSend: (report) => {
          report.severity = 'error';
          report.errorMessage = 'Failed to invite user to battle';
          // report.updateMetadata(''); // TODO extract extra data from request's payload
        },
      });
      console.error('_BattlesBattleInvite: ', result);
    }
    yield put({ type: UPDATE_USER_DATA });
  } catch (error) {
    bugsnagClient.notify(error, { context: 'BattlesBattleInvite' });
    console.error('_BattlesBattleInvite: ', error);
    yield put({ type: BATTLES_BATTLE_INVITE_SET, error });
  }
}

export function* _BattlesResponseToUser({ params = {} }) {
  try {
    const result = yield call(PUT, {
      url: `${config.FETCH.url}/battle/responsetouser`,
      data: params,
    });
    if (result.status === 200) {
      // TODO: объединить запросы
      yield call(_BattlesGet);
      yield call(_TournamentsGet);
      yield put({ type: BATTLES_RESPONSE_TO_USER_SET, data: result.data });
    } else {
      yield put({ type: BATTLES_RESPONSE_TO_USER_SET, error: result });
      bugsnagClient.notify(result, {
        context: 'BattlesResponseToUser',
        beforeSend: (report) => {
          report.severity = 'error';
          report.errorMessage = 'Failed to response to user';
        },
      });
      console.error('_BattlesResponseToUser: ', result);
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'BattlesResponseToUser' });
    console.error('_BattlesResponseToUser: ', error);
    yield put({ type: BATTLES_RESPONSE_TO_USER_SET, error });
  }
}

export function* _BattlesResponseToOwner({ params = {} }) {
  try {
    const result = yield call(PUT, {
      url: `${config.FETCH.url}/battle/responsetoowner`,
      data: params,
    });
    if (result.status === 200) {
      yield put({ type: BATTLES_RESPONSE_TO_OWNER_SET, data: result.data });
      // TODO: объединить запросы
      yield call(_BattlesGet);
      yield call(_TournamentsGet);
    } else {
      yield put({ type: BATTLES_RESPONSE_TO_OWNER_SET, error: result });
      bugsnagClient.notify(result, {
        context: 'BattlesResponseToOwner',
        beforeSend: (report) => {
          report.severity = 'error';
          report.errorMessage = 'Failt to response to owner';
        },
      });
      console.error(result);
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'BattlesResponseToOwner' });
    console.error('_BattlesResponseToOwner: ', error);
    yield put({ type: BATTLES_RESPONSE_TO_OWNER_SET, error });
  }
}

export function* _AccountBattlesGet() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/battle/my` });
    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_ACCOUNT_BATTLES, params: result.data.data });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'AccountBattlesGet' });
    console.error('_AccountBattlesGet: ', error);
  }
}

export function* _GetAccountBattleHistory() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/battle/my?type=history` });
    if (result.status === 200 && result.data.success === 1) {
      yield put({ type: ACCOUNT_BATTLES_HISTORY_SET, params: result.data.data });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'AccountBattleHistory' });
    console.error('AccountBattleHistory: ', error);
  }
}

export function* _GetGameInfo({ params }) {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/battle/info/${params.squadid}` });
    if (result.status === 200 && result.data.success === 1) {
      yield put({ type: SQUAD_GETINFO_SET, params: result.data.data });
    }
  } catch (error) {
    bugsnagClient.notify(new Error(`Get game info error ${JSON.stringify({ error })}`), { context: 'GetGameInfo' });
    console.error('GetGameInfo: ', error);
  }
}

export function* _GetProtestCount({ params }) {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/battle/protestCount/${params.squadid}` });
    if (result.status === 200 && result.data.success === 1) {
      yield put({ type: SET_PROTEST_COUNT, params: result.data.data });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'GetProtestCount' });
    console.error('GetProtestCount: ', error);
  }
}
export function* _OpenChallengesGet() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/battle/openchallenge` });
    if (result.status === 200 && result.data.success === 1) {
      yield put({ type: OPENCHALLENGES_SET, params: result.data.data });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'OpenChallengesGet' });
    console.error('_OpenChallengesGet: ', error.response.data);
  }
}
export function* _ClosedOpenChallengesGet() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/battle/closedopenchallenge` });
    if (result.status === 200 && result.data.success === 1) {
      yield put({ type: CLOSED_OPENCHALLENGES_FETCHED, params: result.data.data });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'OpenChallengesGet' });
    console.error('_OpenChallengesGet: ', error.response.data);
  }
}