import React from 'react';
import { connect } from 'react-redux';

import PersonalEdit from './personalEdit';
import PersonalInfo from './personalInfo';
import { showDialog } from '../../../libs/reducers/SocketEventReducer';
import { UserSetData } from '../../../libs/reducers/actions';
import './personal.style.scss';

class Personal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { userEdit: prevUserEdit } = prevProps;
    const { userEdit } = this.props;
    if (prevUserEdit.fetching && !userEdit.fetching) {
      if (userEdit.data.success === 1) {
        this.setState({ editing: false });
      } else {
        // console.error(userEdit.error);
      }
    }
  }

  _onPressEdit = () => {
    this.setState({ editing: true });
  };

  _onPressCancel = () => {
    this.setState({ editing: false });
  };

  _onPressSubmit = (params) => {
    const { userInfo } = this.props;
    console.log({ ...userInfo, ...params });
    this.props.UserSetData({ ...userInfo, ...params });
  };

  _onPassChange = () => {
    this.setState({ passChange: true });
  };

  render() {
    const { userInfo, geodata, timezones } = this.props;
    return this.state.editing ? (
      <PersonalEdit
        onPressSubmit={this._onPressSubmit}
        onPressCancel={this._onPressCancel}
        userInfo={userInfo}
        geodata={geodata}
        timezones={timezones}
      />
    ) : (
      <PersonalInfo
        onPressEdit={this._onPressEdit}
        userInfo={userInfo}
        geodata={geodata}
        timezones={timezones}/>
    );
  }
}

export default connect(
  state => ({
    userInfo: state.users.user,
    userEdit: state.users.userEdit,
    geodata: state.common.geodata,
    timezones: state.common.timezones,
  }),
  {
    UserSetData,
    showDialog,
  },
)(Personal);
