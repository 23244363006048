import React, { Component } from 'react'
import { Form, Input, Select } from 'antd'
import ArrowSvg from '-!svg-react-loader!./images/icons/arrow.svg' // eslint-disable-line
import './style.scss'
import './select.css'
import './adaptive.scss'

const { Option } = Select

export default class AlleyList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      country: '',
      address: '',
      center: '',
    }

    this.select = null
  }

  onFocus = e => {
    let { target } = e || {}
    if (!target) target = this.select.rcSelect.rootRef
    target.offsetParent.setAttribute('focused', true)
  }

  onBlur = e => {
    let { target } = e || {}
    if (!target) target = this.select.rcSelect.rootRef
    target.offsetParent.removeAttribute('focused', true)
  }

  render() {
    const { bowlingcenters, countries, intl } = this.props
    const { country, address, center } = this.state
    let _list = []
    bowlingcenters.forEach((c, i) => {
      const _country = (countries.find(cntr => cntr.iso === c.iso) || {}).name
      const _address = c.address
      if (c.iso !== 'RU') {
        if (_list[_country] === undefined) {
          _list[_country] = { addressList: [], centerList: [] }
        }
        _list[_country].addressList.push(_address)
        _list[_country].centerList.push(c.name)
        _list[_country].country = _country
      }
    })
    _list = Object.values(_list)
    if (country !== '') {
      _list = _list.filter(item => item.country == country)
    }
    if (address !== '') {
      _list = _list.filter(
        item =>
          item.addressList.filter(a =>
            a.toLowerCase().includes(address.toLowerCase())
          ).length
      )
    }
    if (center !== '') {
      _list = _list.filter(
        item =>
          item.centerList.filter(c =>
            c.toLowerCase().includes(center.toLowerCase())
          ).length
      )
    }
    !!_list.length && _list.sort(function(a, b) {
      return ((a.country || '').toString() || '').localeCompare(b.country)
    })
    return (
      <div id="list_of_alleys" className="landing__container">
        <div className="alley-list">
          <div className="bubble" />
          <div className="bubble" />
          <div className="bubble" />
          <h2>{intl.formatMessage({
            id: 'LandingAlleysTitle',
            defaultMessage: 'List of Alleys',
          })}</h2>
          <div className="filters">
            <Form layout="inline" onSubmit={this.handleSubmit}>
              <div className="row">
                <Form.Item label={intl.formatMessage({ id: 'BowlingcenterFilterByCountry', defaultMessage: 'Country' })}>
                  <Select
                    ref={r => (this.select = r)}
                    onChange={value => this.setState({ country: value })}
                    suffixIcon={<ArrowSvg />}
                    placeholder={intl.formatMessage({ id: 'BowlingcenterFilterByCountry', defaultMessage: 'Country' })}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    dropdownClassName="landing-select"
                    onDropdownVisibleChange={() => {}}
                  >
                    <Option key={`0`} value="">
                      {'All'}
                    </Option>
                    {countries
                      .filter(c => c.iso !== 'RU')
                      .map(c => (
                        <Option key={`${c.id}`} value={c.name}>
                          {c.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item label={intl.formatMessage({ id: 'TitleAddressBowlingCenterList', defaultMessage: 'Address' })}>
                  <Input
                    placeholder={intl.formatMessage({ id: 'SearchAsActionAlias', defaultMessage: 'Search' })}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onChange={e => {
                      const { value } = e.target
                      this.setState({ address: value })
                    }}
                  />
                </Form.Item>
                <Form.Item label={intl.formatMessage({ id: 'BowlingCenter', defaultMessage: 'Bowling center' })}>
                  <Input
                    placeholder={intl.formatMessage({ id: 'SearchAsActionAlias', defaultMessage: 'Search' })}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onChange={e => {
                      const { value } = e.target
                      this.setState({ center: value })
                    }}
                  />
                </Form.Item>
              </div>
            </Form>
          </div>
          <div className="divider"></div>
          {_list.map(item => {
            return (
              <div className="country">
                <div className="country__name">{item.country}</div>
                <div className="bc__details">
                  {item.addressList.map((a, i) => (
                    <div className="bc__details__item">
                      <div className="country__address">{a}</div>
                      <div className="country__center">
                        {item.centerList[i]}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}
