import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Url from 'url';
import bugsnagClient from '../../../bugsnagClient';
import {
  Utils, FLUPlayer, HLSPlayer, WebRTCPlayer, VideoPlayer,
} from './index';
import config from '../../../config';

const { host: videoHost, secure: videoSecure } = config.VIDEOS;

function dummy() {}

function getPlayerByUrl(url) {
  if (url.includes('.m3u8')) {
    if (Utils.iOS() || (Utils.isSafari() && config.VIDEOS.nativeHlsOnSafari && !config.VIDEOS.mseOnSafari)) {
      return VideoPlayer;
    }
    if (HLSPlayer.isSupported()) {
      return HLSPlayer;
    }
    const error = `Your browser does not support Media Source Extension (MSE),
    and you try to open '*.m3u8' source`;
    throw new Error(error);
  } else if (url.includes('/mse_ld')) {
    if (FLUPlayer.isSupported()) {
      return FLUPlayer;
    }
    // if (HLSPlayer.isSupported()) {
    //   return HLSPlayer;
    // }
    const error = `Your browser does not support Media Source Extension (MSE),
    and you try to open 'mse_ld' source`;
    throw new Error(error);
  } else if (url.includes('/webrtc')) {
    if (WebRTCPlayer.isSupported()) {
      return WebRTCPlayer;
    }
    const error = `Your browser does not support WebRTC,
    and you try to open 'webrtc' source`;
    throw new Error(error);
  }
  return VideoPlayer;
}

class VPlayer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.logger = {};
    this.logger.log = props.debug ? console.log : function log() {};
    this.logger.error = console.error;
    this.logger.warn = console.warn;
    this.logger.info = console.info;
  }

  componentDidUpdate(oldprops) {
    const newprops = this.props;
    if (oldprops.pauseSwitch !== newprops.pauseSwitch) {
      this.pause();
    }
    if (oldprops.videoControl && oldprops.videoControl.update !== newprops.videoControl.update) {
      if (newprops.videoControl.targetTime) {
        this.postfix = `video-${Math.floor(+new Date(newprops.videoControl.targetTime) / 1000)}-30.m3u8`;
        this.protocol = `${this.secure ? 'https' : 'http'}`;
      } else {
        this.postfix = Utils.isSafari() || Utils.iOS() ? 'index.m3u8' : 'mse_ld';
        this.protocol = Utils.isSafari() || Utils.iOS() ? 'http' : 'ws';
      }
      this.setState({ fullUrl: '' }, () => this.setState({ fullUrl: `${this.protocol}://${newprops.url}/${this.postfix}` }));
    }
  }

  isLive = () => {
    const { url } = this.props;
    return url ? !/video-\d+-\d+/.test(url) : true;
  };

  getVideoTime = () => {
    const { url } = this.props;
    const { media } = this;
    if (!media || !url || this.isLive()) {
      return Date.now() - 10 * 1000;
    }
    const match = url.match(/video-(\d+)-(\d+)/);
    const currentTime = this.ref.getCurrentTime();
    const start = +match[1];
    return Math.floor((start + currentTime) * 1000);
  };

  getVideoCurrentTime = () => {
    if (this.ref && typeof this.ref.getCurrentTime === 'function') {
      this.ref.getCurrentTime();
    }
    return 0;
  }

  getVideoDuration = () => {
    if (this.ref && typeof this.ref.getDuration === 'function') {
      this.ref.getDuration();
    }
    return 1;
  }

  makeScreenShot = () => {
    const { url } = this.props;
    const { pathname } = Url.parse(url);
    const stm = pathname.match(/^\/([^/]+)/)[1];
    const msec = this.getVideoTime();
    const date = new Date(msec);
    const {
      year, month, day, hours, minutes, seconds,
    } = Utils.getDate(date);
    const { pad: p } = Utils;
    const source = `${videoSecure ? 'https' : 'http'}://${videoHost}/${stm}`;
    const screenShotUrl = `${source}/${year}/${p(month)}/${p(day)}/${p(hours)}/${p(minutes)}/${p(seconds)}-preview.jpg`;
    const { screenShot } = this.props;
    if (typeof screenShot === 'function') {
      screenShot(screenShotUrl);
    }
    return screenShotUrl;
  };

  onRangeChange = (value) => {
    const { onRangeChange } = this.props;
    if (typeof onRangeChange === 'function') {
      this.logger.log('[+] CamPlayer::onRangeChange:', value);
      onRangeChange(value);
    }
  };

  mediaDuration = (duration) => {
    if (typeof this.props.mediaDuration === 'function') {
      this.props.mediaDuration(duration);
    }
  };

  pause = () => {
    if (this.ref && typeof this.ref.setPaused === 'function') {
      this.ref.setPaused(null, true);
    }
  }

  restart = () => {
    if (this.ref && typeof this.ref.restart === 'function') {
      this.ref.restart();
    }
  };

  updateRange = (range) => {
    const { updateRange = dummy } = this.props;
    updateRange(range);
  };

  render() {
    const {
      id,
      url,
      autoPlay,
      style,
      styleDIV,
      range = 1000,
      paused = false,
      hideSlider = false,
      debug,
      volume,
      muted,
      isLive,
    } = this.props;
    const { range: stRange = range } = this.state;
    try {
      const Player = getPlayerByUrl(url);
      this.logger.log(Player);
      this.logger.log('url: ', url);
      return (<div style={{ ...style, position: 'relative' }}>
          <Player
            id={id}
            url={url}
            autoPlay={autoPlay}
            isLive={isLive}
            muted={muted}
            volume={volume}
            range={stRange}
            debug={debug}
            paused={paused}
            hideSlider={hideSlider}
            updateRange={this.updateRange}
            onRangeChange={this.onRangeChange}
            onDurationChange={this.mediaDuration}
            mediaDuration={this.mediaDuration}
            onStreamEnd={this.props.onStreamEnd}
            style={style} // <video /> style
            styleDIV={styleDIV} // <div /> style
            ref={(c) => {
              this.ref = c;
            }}
          />
        </div>
      );
    } catch (error) {
      bugsnagClient.notify(error, {
        context: 'CamPlayer',
      });
      return null;
    }
  }
}

VPlayer.defaultProps = {
  autoPlay: true,
  style: { width: '100%' },
  isFullUrl: false,
  styleDIV: {},
};

VPlayer.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  isFullUrl: PropTypes.bool,
  range: PropTypes.number,
  autoPlay: PropTypes.bool,
  style: PropTypes.shape(),
  styleDIV: PropTypes.shape(),
  screenShot: PropTypes.func,
  onRangeChange: PropTypes.func.isRequired,
};

export default VPlayer;
