import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';

import { getBowlingCentersCoutry, getBowlingCentersCities } from '../../../libs/reducers/bowlingCenters.reducer';
import { UserSetData } from '../../../libs/reducers/actions';
import { Button } from '../../common/Button';
import { FilterInput } from '../../common/Input';
import Select from '../../common/Select';
import TrashSvg from '-!svg-react-loader!../../../images/trash.svg'; // eslint-disable-line
// import ModalBCRequest from '../../common/modals/modalBCRequest';
import ModalDialog from '../../common/modals/modalDialog';
import RequestBCModal from './requestBCModal';
import './style.css';
import BowlingCenterList from './bowlingCenterList';
import SuccessRequestModal from './successRequestModal';

class BowlingCenters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bowlingCenters: [],
      searchtext: '',
      countrySelect: '',
      citySelect: '',
      countryOptions: [],
      showRequestModal: false,
      showDialog: false
    };
  }

  static propTypes = {
    users: PropTypes.shape(),
    bowlingcenters: PropTypes.shape(),
    UserSetData: PropTypes.func,
    geodata: PropTypes.shape(),
    cities: PropTypes.shape(),
    settings: PropTypes.shape(),
    intl: intlShape,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = null;
    const { intl } = nextProps;
    if (!_.isEmpty(nextProps.country)) {
      const countryOptions = [];
      if (nextProps.country.length) {
        nextProps.country.forEach((elem) => {
          countryOptions.push({ value: elem.iso, label: elem.name });
        });
        countryOptions.unshift({ value: 'none', label: intl.formatMessage({ id: 'NullSelector', defaultMessage: 'Not selected...' }) });
      }
      state = { ...state, countryOptions };
    }
    if (!_.isEmpty(nextProps.cities)) {
      const citiesOptions = [];
      if (nextProps.cities.length) {
        nextProps.cities.forEach((elem) => {
          citiesOptions.push({ value: elem.id, label: elem.name });
        });
        citiesOptions.unshift({ value: 'none', label: intl.formatMessage({ id: 'NullSelector', defaultMessage: 'Not selected...' }) });
      }
      state = { ...state, citiesOptions };
    }

    return state;
  }

  componentDidMount() {
    this.props.getBowlingCentersCoutry();
  }

  linkClick = elem => (e) => {
    e.preventDefault();
    this.props.UserSetData({ bowlingcenterid: elem.id });
  };

  deleteBowlingCenter = () => {
    this.props.UserSetData({ bowlingcenterid: null });
  };

  onSuccessRequest = () => {
    this.setState({ showRequestModal: false, showDialog: true });
  }

  buttonClick = () => {
    // как было, пока осталяем закомментированным
    // const { settings } = this.props;
    // console.log('Button click');
    // if (settings.bc_create_request_email) {
    //   document.location = `mailto:${settings.bc_create_request_email}?subject=${
    //     settings.bc_create_request_subject ? settings.bc_create_request_subject : ''
    //   }`;
    // }
    this.setState({ showRequestModal: true })
  };

  render() {
    const {
      countrySelect, citySelect, searchtext, countryOptions, showRequestModal, showDialog
    } = this.state;
    const { bowlingcenters, users, cities, intl } = this.props;

    const citiesOptions = [];
    cities.forEach((city) => {
      citiesOptions.push({ value: city.id, label: city.name });
    });
    if (cities.length) {
      citiesOptions.unshift({ value: 'none', label: intl.formatMessage({ id: 'NullSelector', defaultMessage: 'Not selected...' }) });
    }

    let myBowlingCenters = {};

    bowlingcenters.forEach((elem) => {
      if (elem.id === users.user.bowlingcenterid) {
        myBowlingCenters = elem;
      }
    });

    let displayBowlingCenters = [...bowlingcenters];
    if (bowlingcenters.length) {
      if (searchtext !== '') {
        displayBowlingCenters = displayBowlingCenters.filter(
          elem => (elem.name || '').toLowerCase().includes(searchtext.toLowerCase())
            || (elem.address || '').toLowerCase().includes(searchtext.toLowerCase())
            || intl.formatMessage({ id: 'BowlingCenterNullAddress', defaultMessage: 'No address' }).includes(searchtext.toLowerCase()),
        );
      }
      if (countrySelect !== '') {
        displayBowlingCenters = displayBowlingCenters.filter(elem => elem.iso === countrySelect.value);
      } else {
        displayBowlingCenters = [];
      }
      if (citySelect !== '') {
        displayBowlingCenters = displayBowlingCenters.filter(elem => elem.city === citySelect.value);
      } else {
        displayBowlingCenters = [];
      }
    }

    return (
      <div className="bowlingcenterspage">
        <div className="bowlingcenterspage__bowlingcenters__container">
          <div className="bowlingcenterspage__bowlingcenters__header">{intl.formatMessage({ id: 'BowlingCenterWithTenpincamInstalled', defaultMessage: 'Bowling Centers with TenPinCam installed' })}</div>
          {/* <div className="bowlingcenterspage__bowlingcenters__subheader"></div> */}
          <div className="bowlingcenterspage__bowlingcenters__button">
            <Button size="md" onClick={this.buttonClick}>
              {intl.formatMessage({ id: 'BowlingCenterRegisterNew', defaultMessage: 'Order equipment install' })}
            </Button>
          </div>
          <div className="bowlingcenterspage__bowlingcenters__list">
            <BowlingCenterList
              bowlingCenterList={bowlingcenters}
              intl={intl}
            />
          </div>
        </div>
        <RequestBCModal
          visible={showRequestModal}
          onCancel={() => {
            this.setState({ showRequestModal: false });
          }}
          onSubmit={() => {
            this.onSuccessRequest();
          }}
        />
        {/* <ModalDialog
          visible={showDialog}
          title={intl.formatMessage({ id: 'notice', defaultMessage: 'Notice' })}
          isAcceptButton={false}
          declineButtonText={intl.formatMessage({ id: 'ModalDefaultOK', defaultMessage: 'OK' })}
          onClose={() => { this.setState({  showDialog: false }) }}
        >
          {intl.formatMessage({ id: 'RequestSuccessSent', defaultMessage: 'Your request success sent!' })}
        </ModalDialog> */}
        <SuccessRequestModal
          visible={showDialog}
          onOk={() => { this.setState({ showDialog: false }) }}
        />
      </div>
    );
  }
}

export default injectIntl(connect(
  state => ({
    users: state.users,
    bowlingcenters: state.bowlingcenters.bowlingcenters.data,
    country: state.bowlingcenters.country.data,
    cities: state.bowlingcenters.cities.data,
    settings: state.common.settings,
  }),
  { getBowlingCentersCoutry, getBowlingCentersCities, UserSetData },
)(BowlingCenters));
