import { put, call } from 'redux-saga/effects';
import config from '../../../config';
import { get, put as putMethod } from '../../Networking';

import { _AccountBattlesGet } from '../Battles/battles.handler';

const { 
  SET_VIDEO_FILES,
  FETCH_USER_DISK,
  RECORDING_PROCESS,
} = require('../../reducers/actiontypes').default;

export function* _getVideoFiles(params) {
  const { squadid } = params.params;
  try {
    const url = `${config.FETCH.url}/videofiles/${squadid}`;
    const result = yield call(get, { url });
    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_VIDEO_FILES, payload: { squadid, files: result.data.data } });
    } else {
      yield put({ type: SET_VIDEO_FILES, error: result.data.error || result.status });
    }
  } catch (err) {
    yield put({ type: SET_VIDEO_FILES, error: err });
  }
}

export function* _bindVideoFiles(params) {
  try {
    yield put({ type: RECORDING_PROCESS, payload: true });
    const url = `${config.FETCH.url}/videofiles/bind`;
    const result = yield call(putMethod, { url, data: { ...params.params } });

    if (result.status === 200 && result.data.success) {
      yield put({ type: FETCH_USER_DISK });
      yield put({ type: SET_VIDEO_FILES, clear: true });

      // Обновляем список battles.accountbattles в store
      if (!params.notUpdateBattlesList) {
        yield call(_AccountBattlesGet);
      }
    } else {
      yield put({ type: SET_VIDEO_FILES, error: result.data.error || result.status });
    }
    yield put({ type: RECORDING_PROCESS, payload: false });
  } catch (err) {
    yield put({ type: SET_VIDEO_FILES, error: err });
    yield put({ type: RECORDING_PROCESS, payload: false });
  }
}

export function* _unbindVideoFiles(params) {
  try {
    yield put({ type: RECORDING_PROCESS, payload: true });
    const url = `${config.FETCH.url}/videofiles/unbind`;
    const result = yield call(putMethod, { url, data: { ...params.params } });

    if (result.status === 200 && result.data.success) {
      yield put({ type: FETCH_USER_DISK });
      yield put({ type: SET_VIDEO_FILES, clear: true });

      // Обновляем список battles.accountbattles в store
      if (!params.notUpdateBattlesList) {
        yield call(_AccountBattlesGet);
      }
    } else {
      yield put({ type: SET_VIDEO_FILES, error: result.data.error || result.status });
    }
    yield put({ type: RECORDING_PROCESS, payload: false });
  } catch (err) {
    yield put({ type: SET_VIDEO_FILES, error: err });
    yield put({ type: RECORDING_PROCESS, payload: false });
  }
}
