/**
 *
 * @param {object} param
 * @param {number} param.from
 * @param {number} param.to
 * @param {object[]} param.rooms
 */
export default function makePrivateChat({
  from, to, rooms = [], setCurrentRoom, createPrivateRoom,
}) {
  const min = Math.min(+from, +to);
  const max = Math.max(+from, +to);
  if (min === max || !min || !max) {
    return;
  }
  const privateRoom = `private-room-${min}-${max}`;
  const room = rooms.find(r => r.room_name === privateRoom);
  if (room) {
    setCurrentRoom(privateRoom);
  } else {
    createPrivateRoom(+to);
  }
}
