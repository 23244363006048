import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';

import { Button } from '../../Button';
import Modal from '../../Modal';
import './style.scss';

import { ChangePassword } from '../../../../libs/reducers/actions';

class PasswordChangeModal extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    newpassHandle: PropTypes.func.isRequired,
    intl: intlShape,
  }

  constructor(props) {
    super(props);
    this.state = {
      oldpass: '',
      newpass: '',
      confirm: '',
      oldpasserr: false,
      newpasserr: false,
      confirmerr: false,
      isSuccess: false,
    };
  }

  componentDidUpdate(oldprops) {
    if (oldprops.visible !== this.props.visible) {
      this.setState({
        oldpass: '',
        newpass: '',
        confirm: '',
        oldpasserr: false,
        newpasserr: false,
        confirmerr: false,
        isSuccess: false,
      });
    }
    if (oldprops.passwordState !== this.props.passwordState && !this.props.passwordState.fetching) {
      if (this.props.passwordState.data.success) {
        this.setState({ isSuccess: true });
      } else if (this.props.passwordState.data.errorcode && this.props.passwordState.data.errorcode === 'oldpass') {
        this.setState({ oldpasserr: true });
      }
    }
  }

  inputOldpass = (e) => {
    if (e && e.target) {
      const { value: oldpass } = e.target;
      this.setState({ oldpass });
    }
  }

  inputNewpass = (e) => {
    if (e && e.target) {
      const { value: newpass } = e.target;
      this.setState({ newpass });
    }
  }

  inputConfirm = (e) => {
    if (e && e.target) {
      const { value: confirm } = e.target;
      this.setState({ confirm });
    }
  }

  handleCancel = () => {
    this.props.newpassHandle();
  }

  handleSubmit = () => {
    const { newpass, confirm, oldpass } = this.state;
    if (newpass === confirm) {
      this.props.ChangePassword({ newpass, oldpass });
    } else {
      this.setState({ confirmerr: true });
    }
  }

  render() {
    const { visible, intl } = this.props;
    const { handleCancel, handleSubmit } = this;
    const {
      newpass, oldpass, confirm,
      newpasserr, oldpasserr, confirmerr,
      isSuccess,
    } = this.state;

    return (
      <Modal
        title='Change password'
        visible={visible}
        width={395}
        footer={
          !isSuccess
            ? [<div
              key='buttons'
              className='dialog__buttons'>
              <Button
                kind='aquamarine'
                bordered='true'
                onClick={() => { handleCancel(); }}
                className='dialog__button-decline'
                >
                {intl.formatMessage({ id: 'CancelAsAbortAlias', defaultMessage: 'Cancel' })}
              </Button>
              <Button
                kind='aquamarine'
                className='dialog__button-accept'
                onClick={() => { handleSubmit(); }}
                >
                {intl.formatMessage({ id: 'PasswordResetSubmit', defaultMessage: 'Submit' })}
              </Button>
            </div>]
            : [<div
              key='buttons'
              className='dialog__buttons'>
              <Button
                kind='aquamarine'
                onClick={() => { handleCancel(); }}
                className='dialog__button-accept'
                >
                {intl.formatMessage({ id: 'ModalDefaultOK', defaultMessage: 'OK' })}
              </Button>
            </div>]
          }
      >
        {
          isSuccess
            ? <div className='passwoed-edit-dialog__succ'>{intl.formatMessage({ id: 'PasswordResetSuccess', defaultMessage: 'Your password changed successfuly' })}</div>
            : <div className='passwoed-edit-dialog'>
              <div className='password-edit-dialog__container'>
                <label htmlFor="oldpass" className={`password-edit-dialog__label${oldpasserr ? ' err' : ''}`}>
                  {intl.formatMessage({ id: 'PasswordResetOldpass', defaultMessage: 'Previous password' })}
                </label>
                <input
                  type="password"
                  id="oldpass"
                  className={`password-edit-dialog__input${oldpasserr ? ' err' : ''}`}
                  value={oldpass}
                  onChange={this.inputOldpass}
                />
                <div className='password-edit-dialog__error'>
                {intl.formatMessage({ id: 'PasswordResetOldpassErr', defaultMessage: 'Wrong password' })}
                </div>
              </div>
              <div className='password-edit-dialog__container'>
                <label htmlFor="newpass" className={`password-edit-dialog__label${newpasserr ? ' err' : ''}`}>
                  {intl.formatMessage({ id: 'PasswordResetNewpass', defaultMessage: 'New password' })}
                </label>
                <input
                  type="password"
                  id="newpass"
                  className={`password-edit-dialog__input${newpasserr ? ' err' : ''}`}
                  value={newpass}
                  onChange={this.inputNewpass}
                />
                <div className='password-edit-dialog__error'>
                {intl.formatMessage({ id: 'PasswordResetNewpassErr', defaultMessage: 'Password didn\'t match criteria' })}
                </div>
              </div>
              <div className='password-edit-dialog__container'>
                <label htmlFor="confirm" className={`password-edit-dialog__label${confirmerr ? ' err' : ''}`}>
                  {intl.formatMessage({ id: 'PasswordResetConfirm', defaultMessage: 'Confirm password' })}
                </label>
                <input
                  type="password"
                  id="confirm"
                  className={`password-edit-dialog__input${confirmerr ? ' err' : ''}`}
                  value={confirm}
                  onChange={this.inputConfirm}
                />
                <div className='password-edit-dialog__error'>
                  {intl.formatMessage({ id: 'PasswordResetConfirmErr', defaultMessage: 'Passwords didn\'t match' })}
                </div>
              </div>
            </div>
          }
      </Modal>
    );
  }
}

export default injectIntl(connect(
  state => ({
    passwordState: state.users.passwordEdit,
  }),
  { ChangePassword },
)(PasswordChangeModal));
