import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import Tabs from './Tabs';

import TournamentSettingsTab from './TournamentSettingsTab';
import TournamentHandicapTab from './TournamentHandicapTab';
import TournamentCardSettingsTab from "./TournamentCardSettingsTab";

import './style.scss';

const Settings = (props) => {
  const {
    intl,
  } = props;
  const [tabKey, setTabKey] = useState('');

  /**
   * Массив ключей табов
   * @type {[{
   *  name: string,
   *  key: string,
   *  component: JSX.Element
   * }]}
   */
  const TAB_LIST = [
    {
      name: intl.formatMessage({
        id: 'TournamentSettings',
        defaultMessage: 'Tournament settings',
      }),
      key: 'settings_tournament',
      component: TournamentSettingsTab,
    },
    {
      name: intl.formatMessage({ id: 'Handicap', defaultMessage: 'Handicap' }),
      key: 'handicap',
      component: TournamentHandicapTab,
    },
    {
      name: intl.formatMessage({ id: 'CardSettings', defaultMessage: 'Card setting' }),
      key: 'CardSettings',
      component: TournamentCardSettingsTab,
    },
  ];


  useEffect(() => {
    setTabKey(TAB_LIST[0].key);
  }, []);

  if (!tabKey) return <></>;

  const TabComponent = (TAB_LIST.find(tab => tab.key === tabKey) || {}).component || <></>;

  return (
    <div className="tournament__settings">
      <div className="container">
        <div className="section tabs">
          <Tabs
            intl={intl}
            tabList={TAB_LIST}
            activeTabKey={tabKey}
            onClickTab={key => setTabKey(key)}
          />
        </div>
        <div className="section tab-panel">
          <TabComponent
            intl={intl}
          />
        </div>
      </div>
    </div>
  );
};

export default injectIntl(
  connect(
    state => ({
      tournamentInfo: state.tournaments.tournamentInfo,
      bowlingcenters: state.bowlingcenters.bowlingcenters.data,
    }), {},
  )(Settings),
);
