import React from 'react';
import { Avatar, InfoText } from './common';
import { injectIntl, intlShape } from 'react-intl';
import './style.scss';

const getGender = (gender, intl) => {
  switch (gender) {
    case 'M':
      return intl.formatMessage({ id: 'GenderMale', defaultMessage: 'Male' });
    case 'F':
      return intl.formatMessage({ id: 'GenderFemale', defaultMessage: 'Female' });
    default:
      return '';
  }
};

function PlayerInfo(props) {
  const { player, intl } = props;
  return (
    <div className='player' key={player.userid}>
      <InfoText
        label={intl.formatMessage({ id: 'Gender', defaultMessage: 'Gender' })}
        text={getGender(player.gender, intl)}
      />
      <InfoText
        label={intl.formatMessage({ id: 'table_wins', defaultMessage: 'Victories' })}
        text={player.victories}
      />
      <InfoText
        label={intl.formatMessage({ id: 'BowlingCenter', defaultMessage: 'Bowling Center' })}
        text={`${player.location.bcname}, ${intl.formatMessage({ id: 'BowlingCenterLine', defaultMessage: 'Line' }).toLowerCase()} №${player.location.linenumber}`}
        nounderline
      />
    </div>
  );
}

function OnePlayerCard(props) {
  const {
    player,
    onLoginClick,
    intl,
    games,
  } = props;
  const totalScore = games.reduce((sum, elem) => { return sum + (elem.frames[player.id][9].score_handicap || elem.frames[player.id][9].score); }, 0);
  return (
    <div className="onePlayerInfo">
      <div className="score">
        <div className="result">{totalScore}</div>
        <Avatar noScore player={player} color="orange"/>
        <div>
          <div className="name__grm">{`${player.firstname} ${player.lastname}`}</div>
          <div className="login__grm" onClick={() => onLoginClick({ userid: player.userid })}>{`${player.login}`}</div>
        </div>
      </div>
      <PlayerInfo intl={intl} player={player}/>
    </div>
  );
}

function TwoPlayerCard(props) {
  const {
    players,
    onLoginClick,
    intl,
    isPinfall,
    games,
  } = props;
  const totalScore = [0, 0];
  const estimatedResult = [players[0].gameswin, players[1].gameswin];
  if (isPinfall) {
    games.forEach((game) => {
      players.forEach((player, idx) => {
        totalScore[idx] += game.frames[player.id][9].score_handicap || game.frames[player.id][9].score;
      });
    });
    estimatedResult[0] = totalScore[0];
    estimatedResult[1] = totalScore[1];
  }
  return <>
    <div className="twoPlayersScore">
      <div className="leftPart">
        <div className="playerData">
          <div className="name__grm">{`${players[0].firstname} ${players[0].lastname}`}</div>
          <div className="login__grm" onClick={() => onLoginClick({ userid: players[0].userid })}>{`${players[0].login}`}</div>
        </div>
        <Avatar noScore player={players[0]} color="green"/>
      </div>
      <div className="result">
        <span
          className={players[0].gameswin > players[1].gameswin ? 'winner' : ''}
        >
          {estimatedResult[0]}
        </span>
        :
        <span
          className={players[0].gameswin < players[1].gameswin ? 'winner' : ''}
        >
          {estimatedResult[1]}
        </span>
      </div>
      <div className="rightPart">
        <Avatar noScore player={players[1]} color="orange"/>
        <div className="playerData">
          <div className="name__grm">{`${players[1].firstname} ${players[1].lastname}`}</div>
          <div className="login__grm" onClick={() => onLoginClick({ userid: players[1].userid })}>{`${players[1].login}`}</div>
        </div>  
      </div>
    </div>
    <div className="twoPlayersInfo">
      {players.map(elem => <PlayerInfo intl={intl} player={elem}/>)}
    </div>
  </>;
}

function Players(props) {
  const { players, onLoginClick, games, intl, isPinfall} = props;

  return (
    <div className='players'>
    {
      players.length === 1
        ? <OnePlayerCard player={players[0]} onLoginClick={onLoginClick} intl={intl} games={games}/>
        : <TwoPlayerCard players={players} onLoginClick={onLoginClick} intl={intl} isPinfall={isPinfall} games={games}/>
    }
    </div>
  );
}


export default Players;