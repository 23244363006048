import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { store, persistor } from './store';
import './styles/style.css';
import bugsnagClient from './bugsnagClient';
import ErrorHandler from './components/errorHandler';
import 'moment/locale/ru';
import './app-events';

const ErrorBoundary = bugsnagClient.getPlugin('react');

ReactDOM.render(
  <ErrorBoundary
    beforeSend={(report) => {
      /* eslint-disable no-param-reassign */
      report.severity = 'error'; // one of values: info, warning, error
      /* eslint-enable no-param-reassign */
    }}
    FallbackComponent={ErrorHandler}
  >
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
