import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { VPlayer } from './player';
import VIDEO_URLS from './videourls';

// console.log('player: ', VPlayer.name);

class Videos extends Component {
  constructor(props) {
    // console.log('Videos::ctor', props.urls);
    super(props);
    const { urls } = props;
    this.state = {};
    this.urls = [...urls.flu, ...urls.hls, ...urls.webrtc, ...urls.archive];
  }

  //   componentWillUnmount() {
  //     console.log('Videos::dtor');
  //   }

  onClickArchive = (e) => {};

  extractUrls = (urls) => {
    if (VPlayer.isFlu) {
      return urls.flu;
    }
    if (VPlayer.isHTMLMedia || VPlayer.isHls) {
      return urls.hls;
    }
    if (VPlayer.isWebRTC) {
      return urls.webrtc || [];
    }
    return [];
  };

  renderArchives = (archives) => {
    archives.map((arch, idx) => (
      <div key={idx}>
        <button onClick={this.onClickArchive} data-archive={arch}>
          archive {idx}
        </button>
      </div>
    ));
  };

  render() {
    const { urls, autoPlay } = this.props;
    const _urls = this.extractUrls(urls);
    return (
      <div>
        {_urls.map((url, idx) => [
          <VPlayer key={`VPlayer-${idx}`} url={url} autoPlay={autoPlay} urls={this.urls} />,
          <div key={`Download-links-${idx}`}>
            <code>Download links</code>
          </div>,
          urls.download.map((link, i) => {
            const date = new Date(+link.match(/archive\-(\d+)/)[1] * 1000);
            return (
              <div key={`Urls-${idx}-${i}`}>
                <button data-url={link}>
                  <code>
                    {' '}
                    <a href={link} style={{ textDecoration: 'none' }}>
                      download
                    </a>
                  </code>
                </button>
                <code> {date.toLocaleString()}</code>
                <code>
                  {' '}
                  <a href={link}>{link}</a>
                </code>
              </div>
            );
          }),
        ])}
      </div>
    );
  }
}

Videos.defaultProps = {
  urls: {
    flu: VIDEO_URLS.flu,
    hls: VIDEO_URLS.hls,
    webrtc: [], // VIDEO_URLS.webrtc,// bag, play - pause
    archive: VIDEO_URLS.archive,
    download: VIDEO_URLS.download,
  },
  autoPlay: true,
};

Videos.propTypes = {
  urls: PropTypes.shape({
    flu: PropTypes.arrayOf(PropTypes.string).isRequired,
    hls: PropTypes.arrayOf(PropTypes.string).isRequired,
    webrtc: PropTypes.arrayOf(PropTypes.string),
    archive: PropTypes.arrayOf(PropTypes.string),
    download: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  autoPlay: PropTypes.bool,
};

export default Videos;
