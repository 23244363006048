import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import { injectIntl } from 'react-intl';

import { ReactComponent as SvgShare } from '../../images/share.svg';
import { ReactComponent as SvgSettings } from '../../images/settings.svg';
import chatbg from '../../images/chat-bg.svg';
import soundEnabled from '../../images/soundEnabled.png';
import soundDisabled from '../../images/soundDisabled.png';
import videoPreloader from '../../images/video_preloader.svg';

import { Button } from '../../components/common/Button';
import Checkbox from '../../components/common/Checkbox';
import YellowCardModal from '../../components/common/modals/modalYellowCard';
import RedCardModal from '../../components/common/modals/modalRedCard';
import MatchScore from '../../components/matchScore';
import GameScore from '../../components/common/GameScore';
import Flag from '../../components/common/Flag';
import { VideosTabs } from '../../components/videos'
import config from '../../config';
import ChatClient from '../../components/common/Chat';
import ProtestList from '../../components/protestList';
import Timer from '../../components/common/Timer';
import FrameEditModalConfirmation from '../../components/common/modals/modalFrameEditConfirmation';
import ProtestModal from '../../components/common/modals/modalProtest';
import ModalOpponentProtest from '../../components/common/modals/modalOpponentProtest';
import ProtestReportModal from '../../components/common/modals/modalProtestReport';
import ModalPauseCountdown from '../../components/pauseCountdown';
import Modal from '../../components/common/Modal';
import ModalSaveBattle from '../../components/common/modals/modalSaveBattle';
import ModalGameResult from '../../components/common/modals/modalGameResult';
import LeaveBattleModal from '../../components/common/modals/modalLeaveBattle';
import { changeSystemSquadChat } from '../../libs/reducers/chatRoomsReducer';

import ResultInput from './resultInput';
import ReadinessInput from './readinessInput';

import {
	setGameStatus,

	GameLeave,

	joinAsPlayer,
	fetchVideoArchive,
	fetchVideoArchivesAll,
	SendProtest,
	ProtestCancel,
	ChangeLang,

	setCurrentRoom,
	createSupportRoom,
	GameAccepted,

	setCreateBattleQuery,
	GameExit,
	UpdateUserData,
} from '../../libs/reducers/actions';
import {
	protestReply,
	inputFrame,
	clearChangeReq,
	frameEditConfirm,
	togglePause,
} from '../../libs/reducers/squads.reducer';
import {
	resetFailedPause,
} from '../../libs/reducers/SocketEventReducer';
import {
	isSolo
} from '../../libs/SquadTypeCheck';
import './style.scss';

function getUA() {
	return window && window.navigator ? window.navigator.userAgent : '';
}
function isSafari() {
	return getUA().indexOf('Safari') !== -1 && !isChrome() && !isAndroid();
}
function isChrome() {
	return getUA().indexOf('Chrome') !== -1;
}
function isAndroid() {
	return getUA().indexOf('Android') !== -1;
}
const MsgSound = require(!isSafari() ? '../../sound/light.ogg' : '../../sound/light.m4r');
const IS_SAFARI = isSafari();

class SquadPage extends React.Component {
	constructor(props) {
		super(props);

		this.ResultInput = React.createRef();

		this.state = {
			selectedGame: 0,

			updateVideo: Math.random(),

			pauseModal: false,

			showModalSaveBattle: false,

			currentProtest: false,

			gameEndProtest: false,
			showProtestModal: false,
			protestText: '',
			protestReason: null,
			protestFrame: null,
			protestAfterFrameEdit: false,
			editedFrame: null,
			editedFrameNumber: null,
			protestNoEffect: false,

			currentProtestInfo: {},

			inputLock: false,

			archives: {},

			videoControl: { targetTime: null, update: false },

			selectedFrame: null,
			selectedOpponent: null,
			editMode: false,
			tmpFrame: {
				shot1: -1,
				shot2: -1,
				shot3: -1,
				flags: '',
			},

			showLeaveModal: false,
			leaveChecked: false,

			audioStream: null,
		}
	}

	static playSound = audioStream => {
		if (!IS_SAFARI) {
			new Audio(MsgSound).play();
		} else if (audioStream) {
			audioStream.play();
		}
	};

	createAudioStreamForSafari = () => {
		if (!this.state.audioStream) {
			this.setState({
				audioStream: new Audio(MsgSound),
			}, () => {
				if (this.state.audioStream) this.state.audioStream.load();
			});
		} else {
			this.setState({ audioStream: null })
		}
	}

	updateArchives = () => {
		const streams = [];
		if (!this.props.squad) return
		this.props.squad.players.forEach(player => {
			player.location.cameras.forEach(camera => {
				streams.push(`/cam${camera.id}-0`)
			})
		})
		this.props.fetchVideoArchivesAll(streams)
	};

	componentDidMount() {
		if (this.props.squadId && this.props.socketState) {
			this.props.joinAsPlayer(this.props.squadId);
		}
		this.forceDisconnect = setTimeout(() => {
			this.props.history.push('/lobby');
		}, 10000)  //Принудительно выбрасываем пользователя из экрана сквада, на случай, если он попал на него не имея активного сквада. Таймаут нужен, т.к. редирект на экран может происходить одновременно с записью ид сквада в редюсер.
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.socketState && this.props.squadId && (this.props.squadId !== prevProps.squadId || this.props.socketState !== prevProps.socketState)) {
			this.props.joinAsPlayer(this.props.squadId);
		}
		if (this.props.squad && (!prevProps.squad || this.props.squad.games.length !== prevProps.squad.games.length)) {
			this.setState({ selectedGame: this.props.squad.games.length - 1 })
		}
		if (this.state.selectedGame !== prevState.selectedGame) {
			this.setState({ selectedFrame: null, selectedOpponent: null });
		}
		if (this.state.selectedFrame !== prevState.selectedFrame) {
			this.setState({
				editMode: false,
				tmpFrame: {
					shot1: -1,
					shot2: -1,
					shot3: -1,
					flags: '',
				},
			})
		}
		if (this.props.squad !== prevProps.squad) {
			if (this.forceDisconnect) clearTimeout(this.forceDisconnect);
			this.updateArchives();
			this.setState({ inputLock: false, editMode: false })
		}
		if (this.props.pauseFail == "notframeowner" && (this.props.squad !== prevProps.squad)) {
			this.props.resetFailedPause();
			this.props.togglePause({ squadid: this.props.squad.id, state: true });
		}
		if (!prevProps.squad && this.props.squad && this.props.squad.pauseState.onHold || prevProps.squad && this.props.squad && prevProps.squad.pauseState !== this.props.squad.pauseState) {
			this.setState({ pauseModal: this.props.squad.pauseState.onHold })
		}
		if (this.props.videos && this.props.videos.archives !== prevProps.videos.archives) {
			this.setState({ archives: { ...this.state.archives, ...this.props.videos.archives } })
		}
		if (this.state.currentProtest !== prevState.currentProtest) {
			if (!this.state.currentProtest) this.setState({ currentProtestInfo: {} })
			else this.setState({ currentProtestInfo: this.props.squad.protests.find(protest => this.state.currentProtest === protest.id) || {} })
		}
		if ((prevProps.squad && JSON.stringify(prevProps.squad.turnOrder) !== JSON.stringify(this.props.squad.turnOrder) || !prevProps.squad) && this.props.squad && this.props.squad.turnOrder.includes((this.getCompetitor()||{}).id)) {
			SquadPage.playSound(this.state.audioStream);
		}
	}

	createAudioStreamForSafari = () => {
		if (!this.state.audioStream) {
			this.setState({
				audioStream: new Audio(MsgSound),
			}, () => {
				const { audioStream } = this.state;
				if (audioStream) {
					audioStream.load();
				}
			});
		} else {
			this.setState({ audioStream: null })
		}
	}

	protestHandle = () => {
		const {
			protestText,
			protestReason,
			protestFrame,
			protestNoEffect,
			protestSide,
			playerCurrentFrame,
			protestAfterFrameEdit,
			editedFrame
		} = this.state;
		const { squad } = this.props;
		const playerid = this.getCompetitor().playerid;
		let frameid = null;
		if (protestAfterFrameEdit) {
			frameid = editedFrame;
		} else {
			if (protestFrame) {
				frameid = protestFrame;
			} else {
				if (squad.players.length === 1) {
					frameid = squad.games[squad.games.length - 1]
						.frames[squad.players[0].id][9].id;
				} else if (protestSide == 1) {
					frameid = (
						squad.games[squad.games.length - 1]
							.frames[squad.players.find(player => player.playerid !== playerid).id].find(frame => frame.shot1 === -1)
							||
						squad.games[squad.games.length - 1]
							.frames[squad.players.find(player => player.playerid !== playerid).id][9]
					).id;
				} else {
					frameid = playerCurrentFrame.id;
				}
			}
		}
		this.props.SendProtest({
			frameid,
			complaint: protestText,
			playerid,
			protestReason,
			protestNoEffect
		});
		this.setState({
			gameEndProtest: false,
			showProtestModal: false,
			protestText: '',
			protestReason: null,
			protestFrame: null,
			protestAfterFrameEdit: false,
			editedFrame: null,
			editedFrameNumber: null,
			protestNoEffect: false,
		});
	  };

	protestReject = () => {
		this.setState({
			gameEndProtest: false,
			showProtestModal: false,
			protestText: '',
			protestReason: null,
			protestFrame: null,
			protestAfterFrameEdit: false,
			editedFrame: null,
			editedFrameNumber: null,
			protestNoEffect: false,
		});
	};

	openSupportChat = () => {
		const { users, rooms, setCurrentRoom, createSupportRoom } = this.props;
		const supportRoom = `support-room-${users.user.id}`;
		const room = rooms.find(r => r.room_name === supportRoom);
		if (room) {
			setCurrentRoom(supportRoom);
		} else {
			createSupportRoom();
		}
	}

	getCompetitor = () => {
		const { squad, users } = this.props;
		return squad.players.find(player => player.userid === users.user.id);
	}

	onFrameSave = input => {
		const { selectedFrame } = this.state;
		const { squad } = this.props;
		if (this.state.selectedGame !== squad.games.length - 1) return;
		const score = {
			shot1: -1,
			shot2: -1,
			shot3: -1,
		};
		input.forEach((shot, index) => {
			score[`shot${index+1}`] = shot;
		})
		this.props.inputFrame({
			squadid: squad.id,
			frameid: selectedFrame ? selectedFrame.id : (squad.games[squad.games.length - 1].frames[this.getCompetitor().id].find(frame => frame.shot1 === -1) || { id: null }).id,
			score,
			flags: '',
		})
		this.onFrameInput([]);
		this.setState({ inputLock: true, selectedFrame: null, selectedOpponent: null, editMode: false})
	}

	onFrameInput = input => {
		const tmpFrame = {
			shot1: -1,
			shot2: -1,
			shot3: -1,
			flags: '',
		};
		input.forEach((shot, index) => {
			tmpFrame[`shot${index+1}`] = shot;
		})
		this.setState({ tmpFrame })
	}

	render() {
		const { systemMessages } = this.props;
		const {
			squad,
			connected,
			intl,
			users,
			rooms,
		} = this.props;
		const {
			selectedFrame
		} = this.state;
		if (!connected || !squad) {
			return <img src={videoPreloader}/>
		}
		const { tournamenttypebrief } = squad;

		let unseenSupportChat = 0;
		let unseenSquadChat = 0;
		let supportRoom;
		let squadRoom;
		if (rooms) {
			supportRoom = rooms.find(r => r.room_name == `support-room-${users.user.id}`);
			squadRoom = rooms.find(r => r.room_name == `squad-chat-${squad.id}`);
		}
		if (supportRoom) {
			unseenSupportChat = +supportRoom.message.total - +supportRoom.message.seen;
		}
		if (squadRoom) {
			unseenSquadChat = +squadRoom.message.total - +squadRoom.message.seen
			if (!systemMessages) unseenSquadChat -= squadRoom.message.system || 0
		}
		if (unseenSupportChat < 0) unseenSupportChat = 0;
		if (unseenSquadChat < 0) unseenSquadChat = 0

		const cost = squad.tournamenttypeid === 1 ? squad.tournamentcost : squad.squadcost;
		const prize = squad.prize;
		const pendingProtestList = squad.protests.filter(protest => !protest.no_effect && protest.frameowner === users.user.id && !protest.userreply);

		return <div className='SquadScreen'>
			<YellowCardModal/>
			<RedCardModal />
			<div className='header'>
				<div className='control'>
					<div className='squadControl'>
						<Button
							kind="aquamarine"
							size="md"
							onClick={() => this.props.history.push('/lobby')}>
							{intl.formatMessage({ id: 'CancelToLobby', defaultMessage: 'Leave to lobby' })}
						</Button>
						<Button
							kind="aquamarine"
							size="md"
							onClick={() => this.setState({ showLeaveModal: true, leaveChecked: false })}>
							{intl.formatMessage({ id: 'SurrenderAsLoseDesireAlias', defaultMessage: 'Surrender' })}
						</Button>
						<LeaveBattleModal
							visible={this.state.showLeaveModal}
							buttonCancelText={intl.formatMessage({ id: 'CancelAsAbortAlias', defaultMessage: 'Cancel' })}
							buttonOkText={intl.formatMessage({ id: 'SurrenderAsLoseDesireAlias', defaultMessage: 'Surrender' })}
							onOk={() => {
								this.props.GameLeave({ tournamentid: squad.tournamentid, squadid: squad.id });
								this.setState({ showLeaveModal: false });
							}}
							onCancel={() => {
								this.setState({ showLeaveModal: false });
							}}
							message={intl.formatMessage({ id: 'SurrenderConfirmation', defaultMessage: 'Are you sure you want to surrender?' })}
							checked={this.state.leaveChecked}
							onChange={leaveChecked => this.setState({ leaveChecked })}
						/>
					</div>
					<div className='squadMeta'>
						<div className='meta'>
							<div className='name'>#{squad.id} {squad.tournamentname}</div>
							{/*{
								<div className='prize'>{intl.formatMessage({ id: 'fee', defaultMessage: 'Fee' })} – {squad.cost}{squad.tournamentcurrencysymbol}</div>
							}*/}
							<div className='winCount'>
								{(isSolo(tournamenttypebrief) && intl.formatMessage({ id: 'games', defaultMessage: 'Games' }))
									|| (tournamenttypebrief === 'PINFALL_BATTLE' && 'Pinfall')
									|| intl.formatMessage({ id: 'table_wins_short', defaultMessage: 'Wins' })
								} - {squad.wincount}
							</div>
							{
								cost && squad.tournamenttypeid !== 3
									? <div className='prize'>{intl.formatMessage({ id: 'Prize', defaultMessage: 'Prize' })} – {prize}{squad.tournamentcurrencysymbol}</div>
									: null
							}
							<div className="squad-count-watcher">{intl.formatMessage({ id: 'viewersCount', defaultMessage: 'Number of viewers' })} – {this.props.countWatcher || 0}</div>
						</div>
					</div>
					<div className='appControl'>
						<Button
							kind="aquamarine"
							size="md"
							onClick={() => {
								const choosenLang = this.props.lang === 'RU' ? 'EN' : 'RU';
								this.props.ChangeLang(choosenLang);
							}}>
							{this.props.lang === 'RU' ? 'EN' : 'RU'}
						</Button>
						<Button disabled="disabled" kind="aquamarine" size="md">
							<SvgShare />
						</Button>
						<Button disabled="disabled" kind="aquamarine" size="md">
							<SvgSettings />
						</Button>
					</div>
				</div>
				<div className='opponents'>
					<MatchScore
						players={squad.players}
					/>
				</div>
			</div>
			<div className='results'>
				<Tabs selectedIndex={this.state.selectedGame} onSelect={index => this.setState({ selectedGame: index })}>
					<TabList>
					{squad.games.map((game, index) => <>
						<Tab key={`tab ${index}`}>
							{intl.formatMessage({ id: 'GameAtGameOrderContext', defaultMessage: 'Game' })} {index + 1}
						</Tab>
					</>)}
					</TabList>
					{squad.games.map((game, index) => <>
						<TabPanel key={`tabpanel ${index}`}>
							<div className={`game${index !== squad.games.length - 1 ? ' disabled' : ''}`}>
								{squad.players.map(player => <>
									<div className="player">
										<div className="player-head">
											<div className="names">
												<div className="fullname">{`${player.firstname} ${player.lastname}`}</div>
												<div className="login">{player.login}</div>
											</div>
											<div className="lane">
												{/* <Flag
													name={(player.location.country||'').toLowerCase()}
												/>
												<span className="lane-name">
													{`${player.location.city_name} -
													${player.location.bcname}`}
												</span>
													{`| ${intl.formatMessage({ id: 'LaneNumber', defaultMessage: 'Lane №'})}
													${player.location.linenumber}`} */}
												<div className="bowling"><Flag name={(player.location.country||'').toLowerCase()}/>{player.location.city_name} - {player.location.bcname}</div>
												<div>{intl.formatMessage({ id: 'LaneNumber', defaultMessage: 'Lane №'})} {player.location.linenumber}</div>
											</div>
										</div>
										<GameScore
											selectedFrameId={this.state.editMode && this.state.selectedFrame ? this.state.selectedFrame.id : null}
											changingFrame={this.state.selectedFrame ? this.state.selectedFrame.id : null}
											isEditActive={this.state.editMode}
											onFrameSelect={frame => {
												if (this.state.selectedFrame && this.state.selectedFrame.id === frame.id) this.setState({ selectedFrame: null, selectedOpponent: false })
												else this.setState({ selectedFrame: game.frames[player.id].find(fr => fr.id === frame.id && fr.shot1 !== -1), selectedOpponent: game.frames[player.id].find(fr => fr.id === frame.id && fr.shot1 !== -1) ? player : false })
											}}
											isOwner={this.props.users.user.id === player.userid}
											frames={game.frames[player.id]}
											currentFrame={squad.turnOrder.includes(player.id) ? (game.frames[player.id].find(frame => frame.shot1 === -1) || {}).framenumber : null}
											tmpFrame={(this.state.editMode || !this.state.selectedFrame) && this.props.users.user.id === player.userid ? this.state.tmpFrame : false}
										/>
									</div>
								</>)}
							</div>
						</TabPanel>
					</>)}
				</Tabs>
			</div>
			<div className='gui'>
				<div className="cameras">
					<VideosTabs
						videoControl={this.state.videoControl}
						token={`${encodeURIComponent(config.FETCH.fullUrl)}_${((users.user||{}).session||{}).sessionkey}`}
						key={this.state.updateVideo}
						sources={squad.players.map(player => ({
							ownerId: player.id,
							owner: `${player.firstname} ${player.lastname}`,
							streams: player.location.cameras.map(camera => ({
								url: `/cam${camera.id}-0`,
								type: camera.type,
							})),
						}))}
						currentPlayer={(squad.turnOrder || [])[0]}
						archives={this.state.archives}
						tournamentstart={squad.datestart}
						updateArchives={this.updateArchives}
						fetchVideoArchive={this.props.fetchVideoArchive}
						fetchVideoArchivesAll={this.props.fetchVideoArchivesAll}
					/>
				</div>
				<div className='right'>
					<div className={`adding-results${IS_SAFARI ? ' issafari' : ''}`}>
						<div className="addingResults">
							<div className="addingHeader">
								{intl.formatMessage({ id: 'SquadResultsTitle', defaultMessage: 'Results input' })}
								<div className="addingHeader-right">
									{this.state.endGameEdit || selectedFrame ?
										<div
											className="edit-canceler"
											onClick={() => {
												this.setState({
													endGameEdit: false,
													editMode: false,
													tmpFrame: null,
													selectedFrame: null,
													selectedOpponent: null,
												})
											}}>
												{intl.formatMessage({ id: 'CancelVerbal', defaultMessage: 'Cancel' })}
										</div>
										: null}
									{squad.turnOrder.length && squad.turnOrder.length === 1 ?
										<Timer
											paused={squad.pauseState.onHold}
											targetDate={squad.games[squad.games.length-1].frames[squad.turnOrder[0]].find(item => item.shot1 === -1).deadline}
										/>
										: null}
								</div>
							</div>
							{squad.turnOrder.includes((squad.players.find(player => player.userid === users.user.id)||{}).id) || this.state.endGameEdit || selectedFrame ?
								(!this.state.inputLock || selectedFrame ? <ResultInput
									ref={this.ResultInput}
									selectedFrame={this.state.selectedFrame}
									selectedGame={this.state.selectedGame}
									onSave={this.onFrameSave}
									onInput={this.onFrameInput}
									isEditActive={this.state.editMode}
									endGameEdit={this.state.endGameEdit}
									onEdit={() => this.setState({ editMode: !this.state.editMode })}
									onFinishEdit={() => this.setState({ editMode: false, tmpFrame: null, selectedFrame: null, selectedOpponent: null })}
									isOpponentSelection={this.state.selectedOpponent && this.state.selectedOpponent.userid !== users.user.id}
								/> : <img src={videoPreloader}/>) : (
								squad.turnOrder.length === 0 ?
								<ReadinessInput
									game={squad.games[squad.games.length-1]}
									players={squad.players}
									Accepted={() => {
										const { squad, users } = this.props;
										this.props.GameAccepted({
											gameid: squad.games[squad.games.length-1].id,
											playerid: (squad.players.find(player => player.userid === users.user.id) || {}).playerid,
										});
									}}
									Protested={() => this.setState({ showProtestModal: true })}
									Edited={() => this.setState({ endGameEdit: true })}
								/>
								: <>
										{squad.tournamenttypeid === 3 ? '' : <div className='keyboardLock'>
											<div className='title'>{intl.formatMessage({ id: 'OpponentTurnAwaitTitleTURNBASED', defaultMessage: 'Opponents turn' })}</div>
											<div className='content'>{intl.formatMessage({ id: 'OpponentTurnAwaitTURNBASED', defaultMessage: 'Wait for your opponent finish their turn' })}</div>
										</div>}
									</>
								)
							}
							{squad.frameEditRequests.length ? (
								<FrameEditModalConfirmation
									visible={!!(squad.frameEditRequests[0] && squad.frameEditRequests[0].userid !== users.user.id)}
									oldframe={squad.frameEditRequests[0].oldvalue}
									newframe={squad.frameEditRequests[0].newvalue}
									username={squad.frameEditRequests[0].useralias}
									framenumber={squad.frameEditRequests[0].framenumber}
									reason={squad.frameEditRequests[0].reason}
									explanation={squad.frameEditRequests[0].comments}
									changeid={squad.frameEditRequests[0].id}
									frameid={squad.frameEditRequests[0].datapointid}
									onEdit={(params) => {
										if (params.confirmed == -1) {
											this.setState({
												showProtestModal: true,
												protestAfterFrameEdit: true,
												editedFrame: params.frameid,
												editedFrameNumber: params.framenumber
											});
										}
										this.props.frameEditConfirm(params);
										this.props.clearChangeReq();
									}}
									isFree={!cost}
								/>
							) : null}
						</div>
					</div>
					<div className="chat-wrapper">
						<Tabs>
							<TabList>
								<Tab>
									{intl.formatMessage({ id: 'ChatTitle', defaultMessage: 'Chat' })}
									{unseenSquadChat > 0
										? <span className="badge">{unseenSquadChat}</span>
										: null}
								</Tab>
								<Tab onClick={() => { this.openSupportChat() }}>
									{intl.formatMessage({ id: 'Support', defaultMessage: 'Support' })}
									{unseenSupportChat > 0
										? <span className="badge">{unseenSupportChat}</span>
										: null}
								</Tab>
								<Tab disabled style={{ flex: '1 1 100%' }}>
									<Checkbox
										checked={systemMessages}
										onChange={(checked) => this.props.changeSystemSquadChat(checked)}
									>
										{intl.formatMessage({ id: 'SystemMessages', defaultMessage: 'System messages' })}
									</Checkbox>
								</Tab>
								{IS_SAFARI ? <img style={{ flex: '0 0 auto', marginRight: 20, alignSelf: 'center' }} className="audio-activation-btn" src={this.state.audioStream ? soundEnabled : soundDisabled} onClick={this.createAudioStreamForSafari}/> : null}
							</TabList>
							<TabPanel>
								<ChatClient
									room={`squad-chat-${this.props.squad.id}`}
									className="chat"
									withHeader={false}
									containerClass="chat-container non-battle-chat"
									backgroundImage={chatbg}
									systemMessages={systemMessages}
									onClickTime={(timestamp) => {
										this.setState(prevState => ({
											videoControl: { update: !prevState.videoControl.update, targetTime: timestamp },
										}));
									}}
									squad={this.props.squad}
								/>
							</TabPanel>
							<TabPanel>
								<ChatClient
									room={`support-room-${this.props.users.user.id}`}
									withHeader={false}
									className="chat"
									containerClass="chat-container non-battle-chat"
								/>
							</TabPanel>
						</Tabs>
					</div>
				</div>
			</div>
			<div className='footer'>
				<div className="left-buttons">
					<Button
						size="md"
						kind="orange"
						bordered="true"
						onClick={!squad.pauseState.onHold ? () => this.props.togglePause({ squadid: squad.id, state: true }) : () => this.setState({ pauseModal: true })}
						disabled={false}>
						{intl.formatMessage({ id: 'PauseAsTimebreakAlias', defaultMessage: 'Timeout' })}
						{squad.pauseState.onHold ?
							<>
							{' - '}
							<Timer targetDate={+new Date(squad.pauseState.pauseStart) + +this.props.pauseQuota}/>
							</>
							: null}
					</Button>
					<ProtestList
						players={squad.players}
						onClickHandle={() => this.setState({ showProtestModal: true })}
						onSelect={currentProtest => this.setState({ currentProtest })}
						protests={squad.protests.filter(p => !(+p.no_effect) || p.userid == users.user.id)}>
						{intl.formatMessage({ id: 'ProtestAsGameComplaintAlias', defaultMessage: 'Protest' })}
					</ProtestList>
					<ModalPauseCountdown
						intl={intl}
						timespan={500}
						pauseDuration={this.props.pauseQuota}
						timestamp={+new Date(squad.pauseState.pauseStart)}
						onCancel={() => this.setState({ pauseModal: false })}
						visible={this.state.pauseModal}
						onPauseStop={() => this.props.togglePause({ squadid: squad.id, state: false })}
						isOwner={squad.pauseState.pausedBy == users.user.id}
						bodyText={squad.pauseState.pausedBy === users.user.id
							? intl.formatMessage({ id: 'TimeoutOwnerText', defaultMessage: 'You can took only two {time} minute Timeouts per Battle.' }, {time: '5'})
							: intl.formatMessage({ id: 'TimeoutOpponentText', defaultMessage: 'Your opponent took 5 minute Timeout.' }, { time: '5' })}
					/>
					<Modal
						title={intl.formatMessage({ id: 'PauseAsTimebreakAlias', defaultMessage: 'Timeout' })}
						width={395}
						visible={this.props.pauseFail}
						onCancel={() => {
							this.props.resetFailedPause();
						}}
						footer={<Button
							onClick={() => {
								this.props.resetFailedPause();
							}}
							kind="aquamarine">{this.props.pauseFail === 'notframeowner'?intl.formatMessage({ id: 'ModalDefaultCancel', defaultMessage: 'Cancel' }):'Ok'}</Button>}
						>
						<div className='pauseError'>{this.props.pauseFail ? intl.formatMessage({ id: this.props.pauseFail, default: 'unknown' }) : null}</div>
					</Modal>
					<ModalGameResult
						isEnd={!!this.props.squad.closed}
						withProtest={!!squad.protests.length}
						status={squad.players.length === 1 || this.props.gamestatus == 'none' || !this.props.gamestatus ? (() => {
							const players = [...squad.players];
							let pscore = [];
							if (players.length === 1) {
								return 'solo'
							}
							if (squad.games.length === 1) {
								const game = squad.games[0];
								pscore = players.map(player => ({ score: game.frames[player.id][game.frames[player.id].length - 1].score, competitorid: player.id }));
							} else {
								pscore = players.map(player => ({ score: player.gameswin, competitorid: player.id }))
							}
							pscore.sort((a, b) => b.score - a.score);
							if (pscore.length < 2) return 'win'
							if (pscore[0].score === pscore[1].score) return 'draw'
							else if (pscore[0].competitorid == (this.getCompetitor() || {}).id) return 'win'
							else return 'lose'
						})() : this.props.gamestatus}
						visible={!this.state.showModalSaveBattle && this.props.squad.closed && !this.props.redCardEvent}
						onOk={() => this.setState({ showModalSaveBattle: true }, () => this.props.setGameStatus('none'))}
					/>
					<ModalGameResult
						isEnd={!!this.props.squad.closed}
						withProtest={squad.protests.length}
						visible={this.props.gamestatus === 'draw' && !this.props.squad.closed}
						status={this.props.gamestatus || 'none'}
						onOk={() => this.props.setGameStatus('none')}
					/>
					{this.state.showModalSaveBattle ? <ModalSaveBattle
						history={this.props.history}
						visible={true}
						owned={true}
						squadid={squad.id}
						onSubmit={() => {
							this.props.UpdateUserData();
							this.props.GameExit();
							this.props.history.push('/lobby');
						}}
						onCancel={() => {
							this.props.UpdateUserData();
							this.props.GameExit();
							this.props.history.push('/lobby');
						}}
					/> : null}
					{this.state.showProtestModal &&
						<ProtestModal
							visible={this.state.showProtestModal}
							onSubmit={this.protestHandle}
							onClose={this.protestReject}
							afterFrameEdit={this.state.protestAfterFrameEdit}
							framenumber={this.state.editedFrameNumber}
							frame={this.state.editedFrame}
							text={this.state.protestText}
							reasons={squad.protestCategories}
							opponentFrames={
								Object.keys(squad.games[squad.games.length - 1].frames)
									.filter(competitorid => competitorid != this.getCompetitor().id)
									.map(competitorid => squad.games[squad.games.length - 1].frames[competitorid].map(frame => ({ ...frame, owner: squad.players.find(player => player.id == competitorid) })))
									.reduce((acc, arr) => [...acc, ...arr], [])
									.filter(frame => frame.shot1 !== -1)
							}
							onChange={(protestText, protestReason, protestFrame, protestNoEffect, protestSide) => this.setState({ protestText, protestReason, protestFrame, protestNoEffect, protestSide })}
					/>}
					{this.state.currentProtest ? <ProtestReportModal
						visible
						width={395}
						closable
						onCancel={() => this.setState({ currentProtest: null })}
						info={this.state.currentProtestInfo}
						footer={
							this.state.currentProtestInfo.protestCreatorUserid !== users.user.id
								? [
									<Button
										key="closeButton"
										kind="aquamarine"
										onClick={() => this.setState({ currentProtest: null })}
										className="protest__button-accept">
										{intl.formatMessage({ id: 'close', defaultMessage: 'Close' })}
									</Button>,
								]
								: [
									<div key="buttons" className="protest__buttons">
										<Button
											kind="aquamarine"
											bordered="true"
											onClick={() => this.setState({ currentProtest: null })}
											className="protest__button-decline">
											{intl.formatMessage({ id: 'KeepProtest', defaultMessage: 'Keep protest' })}
										</Button>
										<Button
											onClick={() => {
												this.props.ProtestCancel({
													id: this.state.currentProtestInfo.id,
													frameid: this.state.currentProtestInfo.frameid,
												});
												this.setState({ currentProtest: null });
											}}
											kind="aquamarine"
											className="protest__button-accept">
											{intl.formatMessage({ id: 'RecallProtest', defaultMessage: 'Cancel protest' })}
										</Button>
									</div>,
								]
						}
					/> : null}
					{pendingProtestList.length ? (
						<ModalOpponentProtest
							visible
							onSubmit={params => this.props.protestReply({
								userreply: params,
								id: pendingProtestList[0].id,
								frameid: pendingProtestList[0].frameid,
							})}
							protestId={pendingProtestList[0].id}
							protestText={pendingProtestList[0].complaint}
							protestReason={pendingProtestList[0].reason}
							protestRequireFrame={!!+pendingProtestList[0].require_frame}
							protestFrameNumber={pendingProtestList[0].framenumber}
						/>
						) : null}
				</div>
				<Timer format={'HH:mm:ss'} inverse targetDate={squad.datestart} />
			</div>
		</div>
	}
}

export default injectIntl(
	withRouter(
	  connect(
		state => ({
			lang: state.langs.lang,

			users: state.users,
			squad: state.squadScreen.data,
			squadId: state.squadId,
			socketState: state.events.socketConnected,
			connected: state.squadScreen.connected,
			fetch: state.squadScreen.fetch,
			videos: state.videos,
			rooms: state.chat.rooms,
			frameEditRequest: state.squads.squad.editFrame,

			pauseQuota: state.common.settings.data.pause_quota,

			pauseFail: state.events.failedPause,

			game: state.events.game,
			gamestatus: state.events.gamestatus,
			redCardEvent: state.squadScreen.redCardEvent,
			countWatcher:
				(state.battles.squadsWatcher.find(squadWatcher => +squadWatcher.squadid === +state.squadId)
				&& (state.battles.squadsWatcher.find(squadWatcher => +squadWatcher.squadid === +state.squadId)).countWatcher)
				|| 0,
			systemMessages: state.chat.systemSquadChat,
		}),
		{
			GameLeave,

			setGameStatus,
			setCreateBattleQuery,
			GameExit,
			UpdateUserData,

			inputFrame,

			joinAsPlayer,
			fetchVideoArchive,
			fetchVideoArchivesAll,
			SendProtest,
			protestReply,
			ProtestCancel,
			ChangeLang,

			GameAccepted,

			togglePause,
			resetFailedPause,

			setCurrentRoom,
			createSupportRoom,

			clearChangeReq,
			frameEditConfirm,
			changeSystemSquadChat,
		},
	  )(SquadPage),
	),
  );
