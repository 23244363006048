const {
	SQUAD_GETINFO_FETCH,
	SQUAD_GETINFO_SET,
	SQUAD_GETINFO_CLEAR,
	GET_PROTEST_COUNT,
	SET_PROTEST_COUNT,
	LOGOUT,
} = require('./actiontypes').default;

const initialState = {
	data: false,
	fetch: false,
}

const gameInfoReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOGOUT:
		case SQUAD_GETINFO_CLEAR:
			return { ...initialState }
		case SQUAD_GETINFO_SET:
			return { ...state, data: action.params, fetch: false }
		case SQUAD_GETINFO_FETCH:
			const { squadid: actionSquadId } = action.params || {};
			const { id: stateSquadId } = state.data || {};
			let fetchingStatus = true;
			if (actionSquadId === stateSquadId) {
			  fetchingStatus = false;
			}
			return { ...state, fetch: fetchingStatus }
		case SET_PROTEST_COUNT:
			const data = state.data;
			return { ...state, data: { ...data, ...action.params } }
		default:
			return state
	}
};

export default gameInfoReducer;