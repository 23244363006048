import React from 'react';
import { connect } from 'react-redux';
import * as lodash from 'lodash';

import 'flag-icon-css/css/flag-icon.css';

const Flag = ({ name, geo }) => {
  let _name = name;
  if (geo.data && name) {
    const tmp = lodash.find(geo.data, item => item.name.toLowerCase() === _name.toLowerCase());
    if (tmp) {
      _name = tmp.iso.toLowerCase();
    } else {
      _name = _name.toLowerCase();
    }
  }
  return <span className={`flag-icon-squared flag-icon flag-icon-${_name}`} />;
};

export default connect(
  state => ({
    geo: state.common.geodata,
  }),
  {},
)(Flag);
