import React from 'react'

function MemberCard({
  fullname,
  role,
  email,
  organization,
  image
}
) {
  return (
    <div className="card">
      <img src={image}/>
      <span className="fullname">{fullname}</span>
      <span className="role">{role}</span>
      <span className="email">{email}</span>
      <span className="organization">{organization}</span>
    </div>
  );
}

export default MemberCard;
