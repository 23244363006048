import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import ModalPlayers from './modalPlayers';
import { Button } from '../Button';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { RateUser } from '../../../libs/reducers/actions';

class ModalGameResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: 0,
      comment: '',
    };
  }

  static propTypes = {
    intl: intlShape,
    visible: PropTypes.bool,
    status: PropTypes.string,
    okText: PropTypes.string,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    isEnd: PropTypes.bool,
  };

  handleOKBtn = () => {
    const { onOk } = this.props;
    this.sendRating();
    this.setState({ rating: 0, comment: '' });
    onOk();
  }

  renderOKBtn = () => {
    const { intl } = this.props;
    const { okText = intl.formatMessage({ id: 'ModalDefaultOK', defaultMessage: 'OK' }) } = this.props;
    return (
      <Button key="okButton" kind="aquamarine" onClick={this.handleOKBtn}>
        {okText}
      </Button>
    );
  };

  gameStatus = () => {
    const { status, intl, isEnd, withProtest } = this.props;
    if (withProtest && isEnd) {
      return {
        titleID: 'GameResultWithProtest',
        titleDefault: 'Result will be decided by the referee!',
        text: intl.formatMessage({
          id: 'GameResultWithProtestDescription',
          defaultMessage: 'Your game still has pending protests. You will receive notification after referee review.',
        }),
        showStats: false,
        footer: [this.renderOKBtn()],
      };
    }
    switch (status) {
      case 'draw':
        return {
          titleID: 'GameResultDraw',
          titleDefault: 'Draw',
          text: isEnd
            ? intl.formatMessage({
                id: 'GameResultDrawDescriptionGameEnd',
                defaultMessage: 'Game result is draw, you need to replay the last frame',
              })
            : intl.formatMessage({
                id: 'GameResultDrawDescription',
                defaultMessage: 'Game result is draw, you need to replay the last frame',
              }),
          showStats: true,
          footer: [this.renderOKBtn()],
        };
      case 'technicalWin':
        return {
          titleID: 'GameResultWin',
          titleDefault: 'You win!',
          text: intl.formatMessage({
            id: 'GameResultTechnicalWinDescription',
            defaultMessage: 'Your opponnect surrenders. You win!',
          }),
          showStats: false,
          footer: [this.renderOKBtn()],
        };
      case 'technicalLose':
        return {
          titleID: 'GameResultLose',
          titleDefault: 'You lose!',
          text: intl.formatMessage({
            id: 'GameResultLoseDescription',
            defaultMessage: 'You lose. You can try another time with this parameters:',
          }),
          showStats: true,
          footer: [this.renderOKBtn()],
        };
      case 'redCardWin':
        return {
          titleID: 'GameResultWin',
          titleDefault: 'You win!',
          text: intl.formatMessage({
            id: 'GameResultRedCardWinDescription',
            defaultMessage: 'Your opponent got a red card. You win!',
          }),
          showStats: false,
          footer: [this.renderOKBtn()],
        };
      case 'redCardLose':
        return {
          titleID: 'GameResultLose',
          titleDefault: 'You lose!',
          text: intl.formatMessage({
            id: 'GameResultRedCardLoseDescription',
            defaultMessage: 'You lose, because of getting a red card',
          }),
          showStats: true,
          footer: [this.renderOKBtn()],
        };
      case 'win':
        return {
          titleID: 'GameResultWin',
          titleDefault: 'You win!',
          text: intl.formatMessage({
            id: 'GameResultWinDescription',
            defaultMessage: 'You opponent win!. You can try another time with this parameters:',
          }),
          showStats: true,
          footer: [this.renderOKBtn()],
        };
      case 'lose':
        return {
          titleID: 'GameResultLose',
          titleDefault: 'You lose!',
          text: intl.formatMessage({
            id: 'GameResultLoseDescription',
            defaultMessage: 'You lose. You can try another time with this parameters:',
          }),
          showStats: true,
          footer: [this.renderOKBtn()],
        };
      case 'solo':
        return {
          titleID: 'GameOverText',
          titleDefault: 'Game Over',
          footer: this.renderSoloFooter(),
          hideText: true,
          hideRatingBody: true,
        }
      default:
        return {
        };
    }
  };

  sendRating = () => {
    const { rating, comment } = this.state;
    const { squad, user } = this.props;
    if (rating) {
      this.props.RateUser({
        userid: (squad.players.find(player => player.userid !== user.id) || {}).userid,
        rating: +rating,
        createdby: user.id,
        comment,
        gameid: squad.games[squad.games.length - 1].id,
      })
    }
  }

  renderSoloFooter() {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button bordered style={{ backgroundColor: 'transparent', border: '1px solid #00bfbf' }} key="okButton" kind="aquamarine" onClick={this.handleOKBtn}>
          {this.props.intl.formatMessage({ id: 'close', defaultMessage: 'Close' })}
        </Button>
				{/* Скрываем, пока нет функционало Поделиться */}
        {/* {this.renderSharedBtn()} */}
      </div>
    )
  }

  renderSharedBtn() {
    const { intl } = this.props;
    const { shareText = intl.formatMessage({ id: 'ShareResult', defaultMessage: 'Share result' }) } = this.props;
    return (
      <Button key="cancelButton" kind="aquamarine" onClick={() => {}}>
        {shareText}
      </Button>
    );
  }

  renderPrize = () => {
    const { intl, isEnd, status, squad, withProtest } = this.props;
    if (withProtest && isEnd) {
      return null; // hide prize funds if protest exists
    }
    if (+squad.prize && (((status == 'technicalWin')  || (status == 'redCardWin') || (status == 'win')) && isEnd)) {
      return <div className='prize_showcase'>
        {`${intl.formatMessage({ id: 'prizeShowcaseCaption', defaultMessage: 'Prize funds' })} - ${+squad.prize}${squad.tournamentcurrencysymbol}`}
      </div>
    } else {
      return null
    }
  }

  renderRating = () => {
    const { rating, comment } = this.state;
    const { intl, isEnd } = this.props;
    const poly = "M14 1.12978L17.6661 8.55816L17.7825 8.79389L18.0426 8.83169L26.2403 10.0229L20.3084 15.8051L20.1202 15.9886L20.1646 16.2476L21.5649 24.4122L14.2327 20.5574L14 20.4351L13.7673 20.5574L6.43507 24.4122L7.83541 16.2476L7.87984 15.9886L7.69161 15.8051L1.75969 10.0229L9.9574 8.83169L10.2175 8.79389L10.3339 8.55816L14 1.12978Z";

    if (isEnd) {
      return (<div className="rating_body">
        <div className="opponent_rating">
          <div className="opponent_rating_stars">
            <span>{intl.formatMessage({ id: 'OpponentRatingPreambula', defaultMessage: 'Rate your opponents playing quality' })}</span>
            <div className='opponent_rating_stars_container'>
              {
                [1, 2, 3, 4, 5].reverse().map(num => {
                  return <svg className={`${rating === num ? 'selected' : ''}`} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => this.setState({rating: num})}>
                    <path d={poly} stroke="#FFB31E"/>
                  </svg>
                })
              }
            </div>
          </div>
          <div className="opponent_rating_form">
            <textarea
              value={comment}
              onChange={(e) => {
                this.setState({ comment: e.target.value });
              }}
              placeholder={intl.formatMessage({ id: 'CommentRequestPlaceholder', defaultMessage: 'You can leave commentary here' })}
            />
          </div>
        </div>
      </div>)
    } else {
      return null
    }
  }

  render() {
    const {
      visible, onOk, status,
    } = this.props;
    const {
      titleID, titleDefault, text, showStats, footer, hideRatingBody, hideText,
    } = this.gameStatus();
    
    return (
      <ModalPlayers
        visible={visible}
        titleID={titleID}
        titleDefault={titleDefault}
        text={text}
        footer={footer}
        showStats={showStats}
        onOk={onOk}
        status={status}
        ratingBody={[this.renderPrize(), this.renderRating()]}
        hideRatingBody={hideRatingBody}
        hideText={hideText}
        />
    );
  }
}

export default injectIntl(withRouter(
  connect(
    state => ({
      squad: state.squadScreen.data,
      user: state.users.user,
    }),
    { RateUser },
  )(ModalGameResult),
));
