/* eslint-disable */
import React, {useRef, useState} from 'react';

import './adaptive.css';
import arrow from "./images/arrow-left-circle.png";
import {Carousel} from "antd";
import './carousel.css';

import Northern from './images/Northern_lights_open.jpg'
import OskuBanner2 from './images/Osku_Palermaa_Challenge_2.jpeg'
import SpringPlatinum from './images/Spring TPC Platinum.jpg'
import SpringGold from './images/Spring TPC Gold.jpg'
import SpringSilver from './images/Spring TPC Silver.jpg'
import March from './images/March_Open.jpg'
import StatisticSoon from './images/StatisticSoonImg.jpg'



const TemporaryBanner = ({trackView}) => {

  const [horizontal] = useState(trackView.length === 1 )
  const slider = useRef();

  const Banner = ({image, description, link}) => (
    <a href={link}>
    <div className={trackView.length === 1 ? 'banner-vertical' : 'banner'}>
        <img className={trackView.length === 1 ? 'banner_image-vertical' : 'banner_image'} alt={'Banner'} src={image}/>
        <div className='banner_description'>{description}</div>
    </div>
</a>
  );

  const settings = {
    className: "center",
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    swipeToSlide: true,
    dots: false,
    autoplay: false,
    vertical: horizontal,
    speed: 2000,
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          autoplay: false,
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 1060,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          vertical: false,
          autoplay: false,
        }
      },
      {
        breakpoint: 740,
        settings: {
          autoplay: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          vertical: false,
        }
      },
    ]
  };


  return (
    <div className={trackView.length === 1 ? 'carousel_container-vertical' : 'carousel_container'}>
      <button onClick={()=> slider.current.prev()} className={'btn-carousel'} >
        <img className={trackView.length === 1 ? 'arrow-up' : 'arrow-left' } src={arrow} alt={"Previous slide"} />
      </button>
      <div className={trackView.length === 1 ? 'carousel_slide_place-vertical' : 'carousel_slide_place'}>
        <Carousel {...settings} ref={ref => {
          slider.current = ref;
        }}>
          <Banner image={Northern} description={'Northern Lights Open'} link={'/#tournaments'}/>
          <Banner image={OskuBanner2} description={'Osku Palermaa Challenge'} link={'/#tournaments'}/>
          <Banner image={SpringPlatinum} description={'Spring TPC Platinum'} link={'/#tournaments'}/>
          <Banner image={SpringGold} description={'Spring TPC Gold'} link={'/#tournaments'}/>
          <Banner image={SpringSilver} description={'Spring TPC Silver'} link={'/#tournaments'}/>
          <Banner image={March} description={'March Open'} link={'/#tournaments'}/>
          <Banner image={StatisticSoon} description={'Coming soon...'} />
        </Carousel>
      </div>
      <button onClick={() => slider.current.next()} className={'btn-carousel'}>
        <img className={trackView.length === 1 ? 'arrow-down' : 'arrow-right' } src={arrow} alt={"Next slide"} />
      </button>
    </div>
  );
};


export default TemporaryBanner;
