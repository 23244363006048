import React from 'react';
import {ReactComponent as FilterIcon} from '../../../../../images/filterIcon.svg'
import {ReactComponent as FilterFilled} from '../../../../../images/filterIconFilled.svg'
import arrowIcon from '../../../../../images/arrow-down.png'

const ReferralTH = (
    {
        isFiltered = false,
        title = '',
        withFilter = false,
        onClickFilter = () => {},
        onClickSort = () => {},
        withSort = false,
        sortMethod = 0,
        modal,
    }
) => {

    const RenderFilter = ({withFilter}) => {
        if (withFilter){
            return (
                <div className={'referral-table__header-filter'} onClick={onClickFilter}>
                    {isFiltered ? <FilterFilled /> : <FilterIcon />}
                </div>
            )
        }
        return <></>
    }

    const RenderSort = ({withSort}) => {
        if (withSort){
            return (
                <div className={'referral-table__header-sort'} onClick={onClickSort}>
                    {(sortMethod === 1 || sortMethod === 0) && <img src={arrowIcon} alt={'arrow'} className={'referral-table__sort-up'}/>}
                    {(sortMethod === 2 || sortMethod === 0) && <img src={arrowIcon} alt={'arrow'} className={'referral-table__sort-down'}/>}
                </div>
            )
        }
        return <></>
    }

    return (
        <>
            <th>
                <div className={'referral-table__header-container'}>
                    <RenderFilter withFilter={withFilter}/>
                    <span>{title}</span>
                    <RenderSort withSort={withSort}/>
                </div>
                {modal()}
            </th>
        </>
    );
};

export default ReferralTH;
