import { GEODATA_CITIES_FETCH } from './commonReducer';
import { START_TIMER } from './TimerReducer';
import { FETCH_USER_DOCS, SEND_USER_DOCS, CLEAR_USER_DOCS } from './userDocs';

const {
  USER_BY_ID_FETCH,
  USER_EDIT_FETCH,
  USER_LOGIN,
  USER_REG,
  USER_SEARCH_FETCH,
  REG_ERROR_CLEAN,
  USER_LOGOUT,
  USER_SET_DATA,
  USER_SET,
  PHONE_FETCH,
  USER_PHONE_PUT,
  USER_PHONE_GETCODE,
  USER_PHONE_CHECKCODE,
  USER_EMAIL_CODE_FETCH,
  USER_PASSRESET,
  USER_PASSRESET_STATUS,
  USER_PASSRESET_CODE,
  USER_PASSWORD_UPDATE,
  USER_ERROR_CLEAR,
  PASS_CHANGE_FETCH,
  ACCOUNT_TOURNAMENTS_FETCH,
  TOURNAMENTS_FETCH,
  BATTLES_FETCH,
  ACCOUNT_BATTLES_FETCH,
  ACCOUNT_BATTLES_HISTORY_FETCH,
  BATTLES_RESPONSE_TO_USER_FETCH,
  BATTLES_RESPONSE_TO_OWNER_FETCH,
  BATTLES_BATTLE_CANCEL_FETCH,
  SOCKET_EVENT,
  SOCKET_EMIT,
  TOURNAMENTS_BATTLE_TAKEPART,
  TOURNAMENTS_CREATE_BATTLES_FETCH,
  SOCKET_EVENT_GAME_CANCEL_FETCH,
  SOCKET_EVENT_GAME_CONFIRM_FETCH,
  SOCKET_EVENT_GAME_STATUS,
  SOCKET_DISCONNECT,
  GAME_EXIT,
  GAME_LEAVE,
  GAME_ACCEPT,
  SEND_PROTEST,
  GEODATA_FETCH,
  USERGEODATA_FETCH,
  DEFAULTTIMEZONE_FETCH,
  GEODATA_TIMEZONES_FETCH,
  PROTEST_CANCEL,
  TRANSLATIONS_FETCH,
  CHANGE_LANG,
  FETCH_VIDEO_ARCHIVES,
  FETCH_VIDEO_ARCHIVES_ALL,
  SET_VIDEO_ARCHIVES_ALL,
  FETCH_VIDEO_FILES,
  FETCH_USER_DISK,
  UPDATE_USER_DISK,
  BIND_VIDEO_FILES,
  UNBIND_VIDEO_FILES,
  FETCH_CHAT_ROOMS,
  FETCH_CHAT_ROOM,
  SET_CUR_ROOM,
  UPDATE_MESSAGES_SEEN,
  CREATE_PRIVATE_ROOM,
  CREATE_SUPPORT_ROOM,
  RATE_USER_POST,
  UPDATE_USER_DATA,
  TOURNAMENTS_CREATE_TOURNAMENT_FETCH,
  TOURNAMENTS_UPDATE_INFO_FETCH,
  TOURNAMENTS_DELETE_FETCH,
  WATCH_TOURNAMENT,
  TOURNAMENTINFO_FETCH,
  TOURNAMENTINFO_SET,
  SET_COOKIES_FETCH,
  CLEAR_COOKIES_FETCH,
  REG,
  SOCIAL_REG,

  ADD_MODAL,
  REMOVE_MODAL,
  OPEN_MODAL,
  CLOSE_MODAL,
  CHECK_ACTIVE_MODAL,
  CHECK_NEXT_MODAL,
  GET_VIDEO_ARCHIVE,
  SET_VIDEO_ARCHIVE_SQUAD_ID,
  UNSET_VIDEO_ARCHIVE_SQUAD_ID,
  ADD_USER_TO_TOURNAMENT_FETCH,
  DELETE_USER_FROM_TOURNAMENT_FETCH,
  CREATE_BATTLE_IN_TOURNAMENT,
  PAYMENTDATA_FETCH,
  PAYMENTTOKEN_FETCH,
  CANCEL_TOURNAMENT_BATTLE,
  FETCH_PROTEST_LIST,
  REFEREE_CONSIDER,
  REFEREE_REFUSE,
  FETCH_PROTEST_LIST_OF_PARTY,
  GET_PROTEST,
  ACCEPT_PROTEST,
  REJECT_PROTEST,
  GET_PROTOCOL,
  MAKE_RESOLVE,
  SET_PROTOCOL_SQUAD_ID,
  UNSET_PROTOCOL_SQUAD_ID,

  USERCARDS_FETCH,
  USERCARDS_DEL,
  IBAN_SAVE,
  IBAN_DEL,
  CREATE_IBAN_PAYMENT,

  GET_PERMISSIONS,
  GET_BOWLINGCENTER_PERMISSIONS,
  FETCH_WALLET_HISTORY,
  SET_TARIFF_CALLBACK,

  SET_RELOGIN,

  SET_REDIRECT,

  BOWLING_CENTER_SEND_REQUEST,

  GET_USER_SETTINGS,
  SET_USER_SETTING_VALUE,
  SET_USER_SETTING_CHILD_VALUE,

  GET_TARIFF_MY,
  GET_TARIFFS,
  DROP_TARIFF,
  SWITCH_TARIFF_RENEWAL,

  SEND_AUTHORIZATION_CODE,
  SEND_VERIFICATION_CODE,

  GET_COMMISSION,

  SUPPORT_SEND_MESSAGE,

  BATTLES_SET_CREATE_BATTLE_QUERY,

  FETCH_TIMESTAMP,
  CLEAN_TIMSTAMP_ERROR,
  CHECK_LOGIN_STRING,

  FETCH_GET_COOKIE_STATE,
  FETCH_COOKIE_STATE,

  FETCH_USERSTATS,

  SEND_FIREBASE_TOKEN,

  SET_SESSION_EVENT,

  GET_BILLING_LOANS,

  OPPONENT_GAME_REFUSE,

  SET_OBSERVABLE_SQUAD,
  SET_OBSERVABLE_TOURNAMENT,
  FETCH_JOIN_AS_OBSERVER,
  FETCH_JOIN_AS_PLAYER,
  FETCH_EXIT_OBSERVER,

  SEND_APPLEID,
  EDIT_TOURNAMENT_BATTLE,
  SQUAD_LIST_FETCH,
  SQUAD_GETINFO_FETCH,

  RECORDING_PROCESS,  // Поцесс записи
  SQUAD_GETINFO_CLEAR,
  SOCIAL_EMAIL_CODE_FETCH,
  CHECK_SOCIAL_EMAIL_CODE_FETCH,
  CONFIRM_EMAIL,
  TOURNAMENT_INFO_FOR_EDIT_FETCH,
  TOURNAMENT_INFO_FOR_EDIT_SET,
  FETCH_TOURNAMENT_TYPE_LIST,
  SET_BATTLE_FOR_DELETE,
  MOBILE_VERSION_FETCH,
  GET_COUNT_USERS_REGISTERED,
  SQUAD_LIST_SET,
  FETCH_HELP_STATUS,
  CHANGE_HELP_STATUS,
  TOURNAMNET_BIND_BC_FETCH,
  TOURNAMNET_UNBIND_BC_FETCH,
  GET_HANDICAP_LIST,
  SET_VIDEO_ARCHIVE,
  CREATE_PROTEST_ARCHIVE,
  CANCEL_PROTEST_ARCHIVE,
  REPLY_PROTEST_ARCHIVE,
  GET_PAYPAL_ORDER,
  SET_PAYPAL_ORDER,
  CLEAR_PAYPAL_ORDER,
  CONNECT_TO_SOCKET,
  GET_PROTEST_COUNT,
  SET_PROTEST_COUNT,
  CREATE_QUICK_GAME,
  CREATE_OPEN_CHALLENGE,
  UPDATE_QUICK_GAME_TO_OPEN_CHALLENGE,
  SET_USER_TYPE,
  ADD_TOURNAMENT_SETTING,
  DELETE_TOURNAMENT_SETTING_FETCH,
  SET_TOURNAMENT_PLAYER_RESULT,
  SAVE_HANDICAP_LIST,
  SEND_INVITE_TOURNAMENT_NOTIFICATION,
  SET_MODAL_DEBETING_MONEY_FOR_PARTICIPATION,
  CLEAR_MODAL_DEBETING_MONEY_FOR_PARTICIPATION,
  CONFIRM_TOURNAMENT_RESULTS,
  FETCH_UPLOAD_APK,
  ADD_REPLAY_PLAYER,
  CREATE_LEADERBOARD_TABLE_STAGE,
  DELETE_LEADERBOARD_TABLE_STAGE,
  SAVE_LEADERBOARD_TABLE_STAGE,
  SHOW_HELPMODAL,
  HIDE_HELPMODAL,
  SHOW_FILTERMODAL,
  HIDE_FILTERMODAL,
  OPENCHALLENGES_FETCH,
  CHANGE_KEYBOARD_TYPE,
  GET_FLUSSONIC_API,
  UPLOAD_CARD_DECORATION_FETCH,
  UPLOAD_CARD_DECORATION,
  IN_BATTLES,
  GET_ACTIVE_USERS,
  GET_MAX_WATCHER_COUNT,
  SAVE_MAX_WATCHER_COUNT,
  CLOSED_OPENCHALLENGES_FETCH,
  GET_LEADER_BOARD,
  ACTIVE_SQUADS_COUNT_GET,
  UPDATE_GAME_COUNT_FETCH,
  UPDATE_GAME_COUNT_FETCHED,
  SET_VISIBILITY_BATTLE,
  GET_BC_NOT_PLATFORM,
  CREATE_GAME_NOT_PLATFORM,
  GET_STATISTIC_MAIN,
  GET_STATISTIC_AVERAGE,
  GET_STATISTIC_HIGH,
  GET_STATISTIC_CLEAN,
  GET_STATISTIC_SERIES,
  SET_STATISTIC_OPTIONS,
  CHECK_EXPECTED_GAMES,
  CLEAR_EXPECTED_GAMES,
  GET_SQUAD_ID_BY_USER_ID,
  REMOVE_SQUAD_ID_BY_USER_ID,
  POST_REMOVED_PLAYER_INFO,
  REMOVE_PLAYER_REMOVED_INFO,
  GET_STATISTIC_FRAMES_MAIN,
  GET_STATISTIC_FRAMES_FIRST,
  GET_STATISTIC_FRAMES_AVERAGE,
  GET_STATISTIC_FRAMES_STRIKES,
  GET_STATISTIC_FRAMES_SPLITS,
  GET_REFERRAL_LINK,
  SET_REFERRAL_LINK,
  SET_NEW_USER
} = require('./actiontypes').default;

export const setBattleForDelete = params => ({ type: SET_BATTLE_FOR_DELETE, params});

export const setTariffCallback = params => ({ type: SET_TARIFF_CALLBACK, params });

export const UserByIdFetch = params => ({ type: USER_BY_ID_FETCH, params });
export const UserEditFetch = params => ({ type: USER_EDIT_FETCH, params });
export const UpdateUserData = () => ({ type: UPDATE_USER_DATA });
export const UserLogin = params => ({ type: USER_LOGIN, params });
export const UserReg = params => ({ type: USER_REG, params });
export const UserSearchFetch = payload => ({ type: USER_SEARCH_FETCH, payload })
export const RegErrorClean = () => ({ type: REG_ERROR_CLEAN });
export const UserErrorClear = () => ({ type: USER_ERROR_CLEAR });
export const ResetReg = () => ({ type: REG, params: false })
export const UserLogout = params => ({ type: USER_LOGOUT, params });
export const UserSetData = params => ({ type: USER_SET_DATA, params });
export const checkLoginString = login => ({ type: CHECK_LOGIN_STRING, login });
export const UserDropData = () => ({ type: USER_SET });
export const GetVerificatedPhone = params => ({ type: PHONE_FETCH, params });
export const SetUserPhone = params => ({ type: USER_PHONE_PUT, params });
export const SendVerificationCodePhone = params => ({ type: USER_PHONE_GETCODE, params });
export const CheckVerificationCodePhone = params => ({ type: USER_PHONE_CHECKCODE, params });
export const SendVerificationCodeEmail = params => ({ type: USER_EMAIL_CODE_FETCH, params });
export const SendPasswordResetRequest = params => ({ type: USER_PASSRESET, params });
export const setPassordRecoveryStatus = params => ({ type: USER_PASSRESET_STATUS, data: params });
export const SendPasswordResetCode = params => ({ type: USER_PASSRESET_CODE, params });
export const sendPasswordUpdateRequest = params => ({ type: USER_PASSWORD_UPDATE, params });
export const ChangePassword = params => ({ type: PASS_CHANGE_FETCH, params });
export const RateUser = params => ({ type: RATE_USER_POST, params });
export const SendVerifSocialCodeToEmail = params => ({ type: SOCIAL_EMAIL_CODE_FETCH, params });
export const CheckVerificationCodeEmail = params => ({ type: CHECK_SOCIAL_EMAIL_CODE_FETCH, params });
export const CheckConfirmEmail = params => ({ type: CONFIRM_EMAIL, params });
export const CreateLeaderboardStage = params => ({ type: CREATE_LEADERBOARD_TABLE_STAGE, params });
export const DeleteLeaderboardStage = params => ({ type: DELETE_LEADERBOARD_TABLE_STAGE, params });
export const SaveLeaderboardStage = params => ({ type: SAVE_LEADERBOARD_TABLE_STAGE, params });
/**
 * @param {object} data
 * @param {string} data.reason
 * @param {boolean} data.closed
 * @param {boolean} data.visible
 */
export const setSessionEventModal = (data) => ({ type: SET_SESSION_EVENT, payload: data });
export const closeSessionEventModal = () => ({ type: SET_SESSION_EVENT, payload: { visible: false } });
export const resetSessionEvent = () => ({ type: SET_SESSION_EVENT, payload: { visible: false, closed: false, reason: '' } });

export const TournamentFetch = params => ({ type: TOURNAMENTS_FETCH, params });
export const AccountTournamentFetch = params => ({ type: ACCOUNT_TOURNAMENTS_FETCH, params });
export const BattlesFetch = params => ({ type: BATTLES_FETCH, params });
export const BattlesResponseToUserFetch = params => ({ type: BATTLES_RESPONSE_TO_USER_FETCH, params });
export const BattlesResponseToOwnerFetch = params => ({ type: BATTLES_RESPONSE_TO_OWNER_FETCH, params });
export const BattlesBattleCancelFetch = params => ({ type: BATTLES_BATTLE_CANCEL_FETCH, params });
export const TakePartBattle = params => ({ type: TOURNAMENTS_BATTLE_TAKEPART, params });
export const CreateBattle = params => ({ type: TOURNAMENTS_CREATE_BATTLES_FETCH, params });
export const CreateTournament = params => ({ type: TOURNAMENTS_CREATE_TOURNAMENT_FETCH, params });
export const UpdateTournament = params => ({ type: TOURNAMENTS_UPDATE_INFO_FETCH, params });
export const DeleteTournament = params => ({ type: TOURNAMENTS_DELETE_FETCH, params });
export const AccountBattlesFetch = params => ({ type: ACCOUNT_BATTLES_FETCH, params });
export const AccountBattleHistoryFetch = params => ({ type: ACCOUNT_BATTLES_HISTORY_FETCH, params });
export const CancelTournamentBattle = params => ({ type: CANCEL_TOURNAMENT_BATTLE, params });

export const addUserToTournament = params => ({ type: ADD_USER_TO_TOURNAMENT_FETCH, params });
export const deleteUserFromTournament = params => ({ type: DELETE_USER_FROM_TOURNAMENT_FETCH, params });

export const SocketEmit = params => ({ type: SOCKET_EMIT, params });
export const SocketEvent = params => ({ type: SOCKET_EVENT, params });
export const SocketDisconnect = () => ({ type: SOCKET_DISCONNECT });

export const BattleInviteCanceled = params => ({ type: SOCKET_EVENT_GAME_CANCEL_FETCH, params });
export const BattleInviteConfirmed = params => ({ type: SOCKET_EVENT_GAME_CONFIRM_FETCH, params });
export const setGameStatus = gamestatus => ({ type: SOCKET_EVENT_GAME_STATUS, params: gamestatus });

export const GameExit = () => ({ type: GAME_EXIT });
export const GameLeave = params => ({ type: GAME_LEAVE, params });
export const GameAccepted = params => ({ type: GAME_ACCEPT, params });
export const SendProtest = params => ({ type: SEND_PROTEST, params });

export const GetGeodata = () => ({ type: GEODATA_FETCH });
export const GetUserCountry = () => ({ type: USERGEODATA_FETCH });
export const GetDefaultTimezone = params => ({ type: DEFAULTTIMEZONE_FETCH, params });
export const GetCities = params => ({ type: GEODATA_CITIES_FETCH, params });
export const GetTimeZones = () => ({ type: GEODATA_TIMEZONES_FETCH });

export const ProtestCancel = params => ({ type: PROTEST_CANCEL, params });

export const GetTranslations = params => ({ type: TRANSLATIONS_FETCH, params });

export const ChangeLang = params => ({ type: CHANGE_LANG, params });

export const fetchVideoArchive = params => ({ type: FETCH_VIDEO_ARCHIVES, params });

export const fetchVideoArchivesAll = payload => ({ type: FETCH_VIDEO_ARCHIVES_ALL, payload });

export const setVideoArchivesAll = payload => ({ type: SET_VIDEO_ARCHIVES_ALL, payload });

export const setVideoArchivesError = error => ({ type: SET_VIDEO_ARCHIVES_ALL, error });
export const fetchVideoFiles = params => ({ type: FETCH_VIDEO_FILES, params });
export const fetchUserDisk = () => ({ type: FETCH_USER_DISK });
export const bindVideoFilesToUser = (params) => ({ type: BIND_VIDEO_FILES, params });
export const unbindVideoFilesOfUser = (params) => ({ type: UNBIND_VIDEO_FILES, params });

export const fetchChatRoom = payload => ({ type: FETCH_CHAT_ROOM, payload });
export const fetchChatRooms = () => ({ type: FETCH_CHAT_ROOMS });
export const setCurrentRoom = payload => ({ type: SET_CUR_ROOM, payload });
export const updateMessagesSeen = payload => ({ type: UPDATE_MESSAGES_SEEN, payload });
export const createPrivateRoom = payload => ({ type: CREATE_PRIVATE_ROOM, payload });
export const createSupportRoom = payload => ({ type: CREATE_SUPPORT_ROOM, payload });
export const requestTournamentInfo = params => ({ type: WATCH_TOURNAMENT, params });
export const createBattleForTournament = params => ({ type: CREATE_BATTLE_IN_TOURNAMENT, params });

export const setCookies = payload => ({ type: SET_COOKIES_FETCH, payload });
export const clearCookies = () => ({ type: CLEAR_COOKIES_FETCH });

export const getVideoArchive = params => ({ type: GET_VIDEO_ARCHIVE, params });
export const setVideoArchive = params => ({ type: SET_VIDEO_ARCHIVE, params });
export const setVideoArchiveSquadId = payload => ({ type: SET_VIDEO_ARCHIVE_SQUAD_ID, payload });
export const unsetVideoArchiveSquadId = () => ({ type: UNSET_VIDEO_ARCHIVE_SQUAD_ID });

export const encodePaymentData = params => ({ type: PAYMENTDATA_FETCH, params });
export const getTokenData = () => ({ type: PAYMENTTOKEN_FETCH });
export const getUserCards = () => ({ type: USERCARDS_FETCH });
export const deleteCreditCard = params => ({ type: USERCARDS_DEL, params });
export const saveIBAN = params => ({ type: IBAN_SAVE, params });
export const deleteIBAN = params => ({ type: IBAN_DEL, params });
export const createIBANpayment = params => ({ type: CREATE_IBAN_PAYMENT, params });

export const fetchProtestList = params => ({ type: FETCH_PROTEST_LIST, params });
export const refereeConsider = params => ({ type: REFEREE_CONSIDER, params });
export const refereeRefuse = params => ({ type: REFEREE_REFUSE, params });
export const fetchProtestListOfParty = params => ({ type: FETCH_PROTEST_LIST_OF_PARTY, params });
export const getProtest = params => ({ type: GET_PROTEST, params });
export const acceptProtest = params => ({ type: ACCEPT_PROTEST, params });
export const rejectProtest = params => ({ type: REJECT_PROTEST, params });
export const getProtocol = params => ({ type: GET_PROTOCOL, params });
export const setProtocolSquadId = payload => ({ type: SET_PROTOCOL_SQUAD_ID, payload });
export const unsetProtocolSquadId = () => ({ type: UNSET_PROTOCOL_SQUAD_ID });
export const makeResolve = params => ({ type: MAKE_RESOLVE, params });

export const addModal = payload => ({ type: ADD_MODAL, payload });
export const removeModal = payload => ({ type: REMOVE_MODAL, payload });
export const closeModal = payload => ({ type: CLOSE_MODAL, payload });
export const openModal = payload => ({ type: OPEN_MODAL, payload });
export const checkActiveModal = () => ({ type: CHECK_ACTIVE_MODAL });

export const checkNextModal = payload => ({ type: CHECK_NEXT_MODAL, payload });

export const getPermissions = () => ({ type: GET_PERMISSIONS });
export const getBowlingCenterPermissions = () => ({ type: GET_BOWLINGCENTER_PERMISSIONS });
export const getWalletHistory = () => ({ type: FETCH_WALLET_HISTORY });
export const getUserTariff = () => ({ type: GET_TARIFF_MY });
export const getTariffList = () => ({ type: GET_TARIFFS });
export const dropTariff = () => ({ type: DROP_TARIFF });
export const switchRenewal = () => ({ type: SWITCH_TARIFF_RENEWAL });

export const setRelogin = payload => ({ type: SET_RELOGIN, payload });

export const setRedirect = payload => ({ type: SET_REDIRECT, payload });

export const sendBowlingCenterRequest = payload => ({ type: BOWLING_CENTER_SEND_REQUEST, payload })

export const sendAuthorizationCode = payload => ({ type: SEND_AUTHORIZATION_CODE, payload });
export const sendAppleId = payload => ({ type: SEND_APPLEID, payload });
export const sendVerificationCode = payload => ({ type: SEND_VERIFICATION_CODE, payload });

export const getUserSettings = () => ({ type: GET_USER_SETTINGS });
export const setUserSettingValue = payload => ({ type: SET_USER_SETTING_VALUE, payload });
export const setUserSettingChildValue = payload => ({ type: SET_USER_SETTING_CHILD_VALUE, payload });

export const getCommission = () => ({ type: GET_COMMISSION });

export const sendSupportMessage = payload => ({ type: SUPPORT_SEND_MESSAGE, payload });

export const setCreateBattleQuery = payload => ({ type: BATTLES_SET_CREATE_BATTLE_QUERY, payload });

export const fetchTimestamp = () => ({ type: FETCH_TIMESTAMP });
export const cleanTimestampError = () => ({ type: CLEAN_TIMSTAMP_ERROR });

export const getCookieState = () => ({ type: FETCH_GET_COOKIE_STATE });
export const sendCookieState = payload => ({ type: FETCH_COOKIE_STATE, payload });
export const getUserStats = () => ({ type: FETCH_USERSTATS });
export const sendFirebaseToken = payload => ({ type: SEND_FIREBASE_TOKEN, payload });

export const fetchBillingLoans = () => ({ type: GET_BILLING_LOANS });

export const setOpponentGameRefuse = params => ({ type: OPPONENT_GAME_REFUSE, params });

export const setObservableSquad = params => ({ type: SET_OBSERVABLE_SQUAD, params });
export const setObservableTournament = params => ({ type: SET_OBSERVABLE_TOURNAMENT, params });
export const joinAsObserver = params => ({ type: FETCH_JOIN_AS_OBSERVER, params });
export const joinAsPlayer = params => ({ type: FETCH_JOIN_AS_PLAYER, params });

export const fetchUserDocs = () => ({ type: FETCH_USER_DOCS });
export const sendUserDocs = params => ({ type: SEND_USER_DOCS, params });
export const clearUserDocs = () => ({ type: CLEAR_USER_DOCS });
export const exitGameObserver = params => ({ type: FETCH_EXIT_OBSERVER, params });

export const editTournamentBattle = params => ({ type: EDIT_TOURNAMENT_BATTLE, params });

export const requestSquadList = payload => ({ type: SQUAD_LIST_FETCH, payload });
export const requestSquadListCount = payload => ({ type: ACTIVE_SQUADS_COUNT_GET, payload });

export const CheckEmailVerified = params => ({ type: 'FETCH_EMAIL_VERIFIED', params });
export const UserUnregister = params => ({ type: 'USER_UNREGISTER', params });

export const startTimer = () => ({ type: START_TIMER })
export const requestGameInfo = params => ({ type: SQUAD_GETINFO_FETCH, params })
export const clearGameInfo = params => ({ type: SQUAD_GETINFO_CLEAR, params })
export const getTournamentInfoForEdit = params => ({ type: TOURNAMENT_INFO_FOR_EDIT_FETCH, params });
export const clearTournamentInfoForEdit = () => ({ type: TOURNAMENT_INFO_FOR_EDIT_SET, params: null });
export const getTournamentTypeList = () => ({ type: FETCH_TOURNAMENT_TYPE_LIST });
export const getMobileVersion = () => ({ type: MOBILE_VERSION_FETCH });

export const fetchHelpStatus = params => ({ type: FETCH_HELP_STATUS, params });
export const changeHelpStatus = params => ({ type: CHANGE_HELP_STATUS, params});
export const bindBowlingcenter = params => ({ type: TOURNAMNET_BIND_BC_FETCH, params});
export const unbindBowlingcenter = params => ({ type: TOURNAMNET_UNBIND_BC_FETCH, params});
export const getCountUsersRegistered = () => ({ type: GET_COUNT_USERS_REGISTERED });

export const squadListSet = params => ({ type: SQUAD_LIST_SET, params });

export const getHandicapList = params => ({ type: GET_HANDICAP_LIST, params });

export const createProtestInArchive = params => ({ type: CREATE_PROTEST_ARCHIVE, params});
export const cancelProtestInArchive = params => ({ type: CANCEL_PROTEST_ARCHIVE, params});
export const replyProtestInArchive = params => ({ type: REPLY_PROTEST_ARCHIVE, params});

export const getPayPalOrder = payload => ({ type: GET_PAYPAL_ORDER, payload });
export const setPayPalOrder = params => ({ type: SET_PAYPAL_ORDER, params });
export const clearPayPalOrder = () => ({ type: CLEAR_PAYPAL_ORDER });
export const setUserType = params => ({ type: SET_USER_TYPE, params });
export const connectToSocket = params => ({ type: CONNECT_TO_SOCKET, params });

/** @param {{ amount: number }} params */
export const payseraOrderRequest = (params) => ({ type: 'PAYSERA_ORDER_REQUEST', params })
/** @param {string} url */
export const payseraOrderSuccess = (url) => ({ type: 'PAYSERA_ORDER_SUCCESS', params: { url } })
export const payseraOrderFailure = () => ({ type: 'PAYSERA_ORDER_FAILURE' });

/** @param {{ amount: number; type: 'bank' | 'paysera' | 'payza' | 'webmoney'; beneficiaryid: number; }} params */
export const payseraWithdrawRequestAction = (params) => ({ type: 'PAYSERA_WITHDRAW_REQUEST', params });
export const payseraWithdrawSuccessAction = () => ({ type: 'PAYSERA_WITHDRAW_SUCCESS' });
export const payseraWithdrawFailureAction = (error) => ({ type: 'PAYSERA_WITHDRAW_FAILURE', params: error });

export const getProtestCount = params => ({ type: GET_PROTEST_COUNT, params });
export const createQuickGame = params => ({ type: CREATE_QUICK_GAME, params });
export const createOpenChallenge = params => ({ type: CREATE_OPEN_CHALLENGE, params});
export const updateQuickGameToOpenChallenge = params => ({ type: UPDATE_QUICK_GAME_TO_OPEN_CHALLENGE, params });

export const confirmTournamentResults = params => ({ type: CONFIRM_TOURNAMENT_RESULTS, params });
export const confirmTournamentResultsRequestAction = tournamentid => ({ type: 'CONFIRM_TOURNAMENT_RESULTS_REQUEST', params: { tournamentid } });
export const confirmTournamentResultsSuccessAction = () => ({ type: 'CONFIRM_TOURNAMENT_RESULTS_SUCCESS' });
export const confirmTournamentResultsFailureAction = (error) => ({ type: 'CONFIRM_TOURNAMENT_RESULTS_FAILURE', params: error });

export const addTournamentSetting = params => ({ type: ADD_TOURNAMENT_SETTING, params });
export const deleteTournamentSetting = params => ({ type: DELETE_TOURNAMENT_SETTING_FETCH, params });
export const setTournamentPlayerResult = params => ({ type: SET_TOURNAMENT_PLAYER_RESULT, params });
export const saveHandicapList = params => ({ type: SAVE_HANDICAP_LIST, params });
export const SendInviteTournamentNotification = params => ({type: SEND_INVITE_TOURNAMENT_NOTIFICATION, params})

export const setModalForAgreeOnDebitingMoney = params => ({ type: SET_MODAL_DEBETING_MONEY_FOR_PARTICIPATION, params });
export const clearModalForAgreeOnDebitingMoney = () => ({ type: CLEAR_MODAL_DEBETING_MONEY_FOR_PARTICIPATION });
export const uploadApkFile = params => ({ type: FETCH_UPLOAD_APK, params });
export const addReplayToPlayer = params => ({ type: ADD_REPLAY_PLAYER, params });
export const getOpenChallenges = params => ({type: OPENCHALLENGES_FETCH, params});
export const getOpenClosedChallenges = params => ({type: CLOSED_OPENCHALLENGES_FETCH, params});
export const uploadCardDecoration = params => ({type: UPLOAD_CARD_DECORATION_FETCH, params});
export const inBattle = payload => ({type: IN_BATTLES, payload});

export const getActiveUserCount = payload => ({type: GET_ACTIVE_USERS, payload});

export const getMaxWatcherCount = payload => ({type: GET_MAX_WATCHER_COUNT, payload});

export const saveMaxWatcherCount = payload => ({type: SAVE_MAX_WATCHER_COUNT, payload});

export const getLeaderBoard = params => ({type: GET_LEADER_BOARD, params})

export const changeKeyboardType = params => ({ type: CHANGE_KEYBOARD_TYPE, params});
export const setVisibilityBattle = params => ({type: SET_VISIBILITY_BATTLE, params})

export const updateGameCount = params => ({type: UPDATE_GAME_COUNT_FETCH, params});

export const checkExpectedGames = params => ({ type: CHECK_EXPECTED_GAMES, params});

export const clearExpectedGames = () => ({ type: CLEAR_EXPECTED_GAMES });

export const getBcNotPlatform = params => ({type: GET_BC_NOT_PLATFORM, params})

export const createGameNonPlatform = params => ({ type: CREATE_GAME_NOT_PLATFORM, params });


export const getSquadIdByUserId = params => ({type: GET_SQUAD_ID_BY_USER_ID, params });
export const removeSquadIdByUserId = () => ({type: REMOVE_SQUAD_ID_BY_USER_ID });
export const removePlayerRemovedInfo = () => ({type: REMOVE_PLAYER_REMOVED_INFO });

export const postRemovedPlayerInfo = params => ({type: POST_REMOVED_PLAYER_INFO, params})

export const getFlussonicApi = () => {
  return {type: GET_FLUSSONIC_API}
};

export const getStatisticMain = params => ({type: GET_STATISTIC_MAIN, params})
export const getStatisticAverage = params => ({type: GET_STATISTIC_AVERAGE, params})
export const getStatisticHigh = params => ({type: GET_STATISTIC_HIGH, params})
export const getStatisticClean = params => ({type: GET_STATISTIC_CLEAN, params})
export const getStatisticSeries = params => ({type: GET_STATISTIC_SERIES, params})

export const getStatisticFramesMain = params => ({type: GET_STATISTIC_FRAMES_MAIN, params})
export const getStatisticFramesFirst = params => ({type: GET_STATISTIC_FRAMES_FIRST, params})
export const getStatisticFramesAverage = params => ({type: GET_STATISTIC_FRAMES_AVERAGE, params})
export const getStatisticFramesStrikes = params => ({type: GET_STATISTIC_FRAMES_STRIKES, params})
export const getStatisticFramesSplits = params => ({type: GET_STATISTIC_FRAMES_SPLITS, params})

export const setStatisticOptions = params => ({type: SET_STATISTIC_OPTIONS, params})

export const getReferralLink = params => ({type: GET_REFERRAL_LINK, params})
export const setReferralLink = params => ({type: SET_REFERRAL_LINK, params})

export const setNewUser = params => ({type: SET_NEW_USER, params})


