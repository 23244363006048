import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment';

import OBModal from '../../Modal';
import TextArea from '../../TextArea';
import { FilterInput } from '../../Input';
import { Button } from '../../Button';
import Radio from '../../Radio';
import { CreateTournament, UpdateTournament, getTournamentInfoForEdit, clearTournamentInfoForEdit } from '../../../../libs/reducers/actions';
import { getCurrencies } from '../../../../libs/reducers/adminReducer';
import OBDatePicker from '../../DatePicker';
import OBTimePicker from '../../TimePicker';
import Select from '../../Select';
import Slider from '../../Slider';
import Checkbox from '../../Checkbox';

import './style.scss';

const defaultMinCountDropInGame = 1;
const defaultMaxCountDropInGame = 6;

class CreateTournamentModal extends React.Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    intl: intlShape,
    oldinfo: PropTypes.object,
  };

  constructor(props) {
    super(props);
    const { settings } = this.props;
    const countGame = +settings.max_count_dropin_game && +settings.max_count_dropin_game > 0
      ? +settings.max_count_dropin_game : defaultMaxCountDropInGame;

    this.state = {
      tournamentcost: 0,
      tournamentcurrency: null,
      tournamentname: '',
      description: '',
      type: null,
      startdate: null,
      enddate: null,
      starttime: null,
      endtime: null,
      viewError: false,
      viewTimeError: false,
      timezoneId: null,
      marksSlider: {},
      countGame,
      isTest: false, // Турнир является тестовым
      isEdit: false, // Редактирование турнира
    };
  }

  componentDidMount() {
    this.props.getCurrencies();
    this.calcMarksSlider();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isEdit !== this.state.isEdit && this.state.isEdit) {
      this.setState({tournamentcurrency: this.state.currencyid})
    }
    if (this.props.oldinfo && prevProps.oldinfo !== this.props.oldinfo) {
      this.setState({ tournamentname: (this.props.oldinfo || {name: ''}).name, description: (this.props.oldinfo || {rules: ''}).rules });
      this.props.getTournamentInfoForEdit(this.props.oldinfo.id);
    }
    // if (prevProps.currencies !== this.props.currencies && this.props.currencies.length) {
    //   this.setState({ tournamentcurrency: this.props.currencies[0].id })
    // }
    if (prevProps.visible && !this.props.visible && this.props.tournamentInfoForEdit) {
      this.props.clearTournamentInfoForEdit();
      this.clearData();
    }
    if (!prevProps.tournamentInfoForEdit && this.props.tournamentInfoForEdit) {
      this.initState();
    }
    if (prevProps.visible && !this.props.visible) {
      // Снимаем  флаг редактирования
      this.setState({ isEdit: false, });
    }
  }

  initState() {
    const { tournamentInfoForEdit } = this.props;
    const {
      name,
      description,
      datestart,
      dateend,
      type,
      is_test,
      cost,
      currencyid,
    } = tournamentInfoForEdit;

    this.setState({
      tournamentname: name || '',
      description: description || '',
      startdate: (datestart && moment(datestart)) || null,
      enddate: (dateend && moment(dateend)) || null,
      starttime: (datestart && moment(datestart)) || null,
      endtime: (dateend && moment(dateend)) || null,
      type: type === 'qualification tournament' ? 'qualificational' : 'provisional',
      isTest: is_test,
      isEdit: true,
      cost: /*cost ||*/ 0,
      currencyid: /*currencyid || */null,
    });
  }

  sendRequest = () => {
    const {
      tournamentname, description, type, tournamentcurrency, tournamentcost,
      startdate, enddate, starttime, endtime, isTest, cost,
    } = this.state;

    if (!tournamentname) {
      return this.setState({ viewError: true });
    }

    if ((startdate && !enddate) || (!startdate && enddate)) {
      return this.setState({ viewError: true });
    }

    this.setState({ viewError: false, viewTimeError: false });
    let datestart = null;
    let dateend = null;

    if (starttime) {
      datestart = moment(startdate.set('h', starttime.hour()).set('m', starttime.minute()));
    } else {
      if (startdate) {
        const nowDate = new Date();

        startdate.hours(nowDate.getHours());
        startdate.minutes(nowDate.getMinutes());
        startdate.seconds(nowDate.getSeconds());

        datestart = moment(startdate);
      }
      // datestart = moment(startdate).utc();
    }
    if (endtime) {
      dateend = moment(enddate.set('h', endtime.hour()).set('m', endtime.minute()));
    } else {
      if (enddate) {
        enddate.hours(0);
        enddate.minutes(0);
        enddate.seconds(0);

        dateend = moment(enddate);
      }
      // dateend = moment(enddate).utc();
    }

    if ((type === 'qualificational') && datestart && dateend && (moment(datestart.toLocaleString()).isSame(moment(dateend.toLocaleString()), 'day') && (!starttime || !endtime))) {
      return this.setState({ viewTimeError: true });
    }

    if (this.props.oldinfo && this.props.oldinfo.id) {
      this.props.UpdateTournament({
        name: tournamentname,
        description,
        datestart,
        dateend,
        id: this.props.oldinfo.id,
        isTest,
        currencyid: tournamentcurrency,
        cost,
      });
    } else {
      this.props.CreateTournament({
        name: tournamentname,
        description,
        currencyid: /*type === 'provisional' || type === 'qualificational' ?*/ 1/* : tournamentcurrency*/,
        cost: /*type === 'provisional' || type === 'qualificational' ?*/ 0 /*: tournamentcost*/,
        type: type || 'provisional' || 'qualificational' ,
        datestart,
        dateend,
        wincount: this.state.countGame,
        isTest,
      });
    }
    this.clearData();
    this.props.onClose();
  }

  clearData() {
    this.setState({
      tournamentname: '',
      description: '',
      startdate: null,
      enddate: null,
      starttime: null,
      endtime: null,
      viewError: false,
      viewTimeError: false,
      timezoneId: null,
      type: null,
      tournamentcost: 0,
      tournamentcurrency: 0,
      isTest: false,
      cost: '',
    });
  }

  /**
   * Функция блокировки выбора часов
   * @param {string} time переменная принимает либо строку 'start' либо 'end' для вычисления блокировки часов
   * @returns {Array<number>} возвращает массив недоступных часов
   */
  disabledHoursTimePicker(time) {
    const { starttime, endtime, startdate, enddate } = this.state;
    const hours = [];
    let datesIsEqual = false;

    if (startdate && enddate) {
      datesIsEqual = moment(startdate.toLocaleString()).isSame(moment(enddate.toLocaleString()), 'date');
    }

    if (!datesIsEqual) return hours;

    if ((time === 'end' && starttime) || (time === 'start' && endtime)) {
      for (let i = (time === 'end' ? 0 : 24); (time === 'end' ? i < moment(starttime).hours() : i > moment(endtime).hours()); (time === 'end' ? i++ : --i)) {
        hours.push(i);
      }
    }
    return hours;
  }

  /**
   * Функция блокировки минут от выбранного часа
   * @param {string} time переменная принимает либо строку 'start' либо 'end' для вычисления блокировки минут
   * @param {number | null} hour выбранный час
   * @returns {Array<number>} возвращает массив заблокированных минут
   */
  disabledMinutes(time, hour) {
    const { starttime, endtime, startdate, enddate } = this.state;
    const minutes = [];
    let datesIsEqual = false;

    if (startdate && enddate) {
      datesIsEqual = moment(startdate.toLocaleString()).isSame(moment(enddate.toLocaleString()), 'date');
    }

    if (!datesIsEqual) return minutes;

    if (((time === 'end' && starttime) || (time === 'start' && endtime)) && +hour != null) {
      const end = (time === 'end')
        ? (moment(starttime).hours() === hour)
          ? moment(starttime).minutes() : 0
        : (moment(endtime).hours() === hour)
          ? moment(endtime).minutes() : 60;
      const start = (time === 'end' ? 0 : 60);

      if (start === end) return minutes;
      for (let i = start; (time === 'end' ? i < end : i > end); (time === 'end' ? i += 1 : i -= 1)) {
        minutes.push(i);
      }
    }
    return minutes;
  }

  /**
   * Функция вычисления шага слайдера выбора количества игр
   */
  calcMarksSlider() {
    const { settings } = this.props;
    const min = +settings.min_count_dropin_game && +settings.min_count_dropin_game > 0
      ? +settings.min_count_dropin_game : defaultMinCountDropInGame;
    const max = +settings.max_count_dropin_game && +settings.max_count_dropin_game > 0
      ? +settings.max_count_dropin_game : defaultMaxCountDropInGame;
    const step = 100 / (max - 1);
    const marks = {};
    let curStep = 0;

    for (let i = min; i <= max; i += 1) {
      marks[curStep.toFixed(0)] = i;
      curStep += step;
    }
    this.setState({ marksSlider: marks });
  }

  onChangeEndTime(endtime) {
    const { timezoneId, starttime, enddate, startdate } = this.state;
    const { timezones, tournamentInfoForEdit } = this.props;

    if (tournamentInfoForEdit && tournamentInfoForEdit.last_game) {
      const { last_game: { dateend } } = tournamentInfoForEdit;
      if (!moment(enddate.toLocaleString()).isSame(moment(dateend.toLocaleString()), 'date')) {
        return this.setState({
          endtime: moment(endtime)
            .utcOffset((timezoneId && (timezones.find(timezone => timezone.id === timezoneId)).rawoffset * 60) || 0),
        });
      }
      return this.setState({
        endtime: (dateend && moment(dateend).isAfter(endtime))
          ? moment(dateend)
            .utcOffset((timezoneId && (timezones.find(timezone => timezone.id === timezoneId)).rawoffset * 60) || 0)
          : moment(endtime)
            .utcOffset((timezoneId && (timezones.find(timezone => timezone.id === timezoneId)).rawoffset * 60) || 0),
      });
    }
    if (!moment(startdate.toLocaleString()).isSame(moment(enddate.toLocaleString()), 'date')) {
      return this.setState({
        endtime: moment(endtime)
          .utcOffset((timezoneId && (timezones.find(timezone => timezone.id === timezoneId)).rawoffset * 60) || 0),
      });
    }
    if (!starttime) {
      return this.setState({
        endtime: moment(endtime)
          .utcOffset((timezoneId && (timezones.find(timezone => timezone.id === timezoneId)).rawoffset * 60) || 0),
      });
    }
    if (starttime) {
      return this.setState({
        endtime: !moment(starttime).isBefore(endtime)
          ? moment(starttime)
          : moment(endtime)
            .utcOffset((timezoneId && (timezones.find(timezone => timezone.id === timezoneId)).rawoffset * 60) || 0),
      });
    }
  }

  onChangeEndDate(enddate) {
    const { tournamentInfoForEdit } = this.props || {};
    if (!tournamentInfoForEdit || !tournamentInfoForEdit.last_game) {
      return this.setState({
        enddate,
      });
    }

    const { last_game: { dateend } } = tournamentInfoForEdit;
    this.setState({
      enddate: (dateend && moment(dateend).isAfter(enddate, 'date'))
        ? moment(dateend)
        : moment(enddate),
    });
  }

  onChangeStartTime(starttime) {
    const { timezoneId, endtime } = this.state;
    const { timezones } = this.props;

    this.setState({
      starttime: (endtime && !moment(starttime).isBefore(endtime))
        ? moment(endtime)
        : moment(starttime)
          .utcOffset((timezoneId && (timezones.find(timezone => timezone.id === timezoneId)).rawoffset * 60) || 0),
    });
  }

  render() {
    const {
      tournamentname,
      tournamentcost,
      description,
      viewError,
      viewTimeError,
      timezoneId,
      countGame,
      marksSlider,
      type,
      cost,
    } = this.state;
    const { intl, oldinfo, timezones, settings, geodata, tournamentInfoForEdit } = this.props;
    window.moment = moment;
    const onCancel = () => {
      this.props.onClose()
      this.clearData()
    }
    return (
        <OBModal
          title={oldinfo && oldinfo.id ? intl.formatMessage({id: 'TournamentEditAsVerb', defaultMessage: 'Tournament edit'}) : intl.formatMessage({id: 'TournamentCreationAsVerb', defaultMessage: 'Tournament creation'})}
          visible={this.props.visible}
          onCancel={onCancel}
          className="create-tournament-modal"
          wrapClassName="create-tournament-modal"
          width={520}
          footer={<div className="modal-footer">
            {(viewError || viewTimeError) && <div className="error-message-footer__container">{intl.formatMessage({ id: 'NotAllRequiredFieldsFilled', defaultMessage: 'Not all fields are filled' })}</div>}
            <div className='buttons_wrap'>
                <Button kind="aquamarine" bordered="true" onClick={onCancel}>
                  {intl.formatMessage({id: 'CloseAsCancelAlias', defaultMessage: 'Cancel'})}
                </Button>
                <Button kind="aquamarine" onClick={this.sendRequest}
                  disabled={oldinfo && oldinfo.id ? false : !this.state.type /*|| this.state.type === 'qualificational' && !this.state.tournamentcurrency*/}>
                    {oldinfo && oldinfo.id ? intl.formatMessage({id: 'Edit', defaultMessage: 'Edit'}) : intl.formatMessage({id: 'CreateAsMakeNewAlias', defaultMessage: 'Create'})}
                </Button>
            </div>
          </div>
          }
          >
          <div key='nameInput' className='tournamentCreate_name'>
              <label for='nameInput'>{intl.formatMessage({id: 'TournamentTitleAsNameAlias', defaultMessage: 'Tournament title'})}</label>
              <FilterInput
                className={viewError && !this.state.tournamentname ? 'error' : ''}
                value={tournamentname}
                onChange={({ target }) => this.setState({ tournamentname: target.value })}
                id='nameInput'
                type='text'
                invalid={(viewError && !(tournamentname.trim()) && intl.formatMessage({ id: 'requiredField', defaultMessage: 'Required field' })) || false}
              />
          </div>
          <div key='descriptionInput' className='tournamentCreate_desc'>
            <div className="tournament-create__timezone">
              <label>
                {intl.formatMessage({ id: 'PersonalInfoTimeZone', defaultMessage: 'Timezone' })}
              </label>
              <Select
                placeholder=""
                options={timezones.map(timezone => ({ label: `${timezone.name} ${(timezone.rawoffset >= 0 && `+${timezone.rawoffset}`) || timezone.rawoffset}`, value: timezone.id }))}
                onChange={(timezone) => {
                  this.setState({ timezoneId: +timezone.value });
                }}
              />
            </div>
            <div className='datepickers'>
              <div className='dategroup'>
                <label>{intl.formatMessage({ id: 'DateStartTournamentCreate', defaultMessage: 'Date start' })}</label>
                <OBDatePicker
                  containerClassName="datepicker"
                  className={['datepicker', viewError && !this.state.startdate && type === 'qualificational' ? ' error-border' : '']}
                  placeholder={this.props.intl.formatMessage({ id: 'date', defaultMessage: 'Date' })}
                  value={this.state.startdate}
                  onChange={startdate => this.setState({ startdate })}
                  disabledDate={current => (current && current < moment().subtract(1, 'days')) || (this.state.enddate && current >= this.state.enddate)}
                  disabled={tournamentInfoForEdit && tournamentInfoForEdit.last_game}
                />
                <OBTimePicker
                  className={['timepicker', viewTimeError && !this.state.starttime && type === 'qualificational' ? ' error-border' : '']}
                  placeholder={intl.formatMessage({ id: 'time', defaultMessage: 'Time' })}
                  value={this.state.starttime}
                  onChange={starttime => this.onChangeStartTime(starttime)}
                  disabledHours={() => this.disabledHoursTimePicker('start')}
                  disabledMinutes={selectedHour => this.disabledMinutes('start', selectedHour)}
                  format={`HH:mm (Z ${(timezoneId && (geodata.find(geo => geo.id === (timezones.find(timezone => timezone.id === timezoneId)).countryid) || {}).iso) || 'UK'})`}
                  disabled={tournamentInfoForEdit && tournamentInfoForEdit.last_game}
                />
              </div>
              <div className='dategroup'>
                <label>{intl.formatMessage({ id: 'DateEndTournamentCreate', defaultMessage: 'Date end' })}</label>
                <OBDatePicker
                  className={['datepicker', viewError && !this.state.enddate && type === 'qualificational' ? 'error-border' : '']}
                  datePickerClassName={viewError && !this.state.enddate ? 'error-border' : ''}
                  placeholder={this.props.intl.formatMessage({ id: 'date', defaultMessage: 'Date' })}
                  value={this.state.enddate}
                  onChange={enddate => this.onChangeEndDate(enddate)}
                  disabledDate={current => (current && current < moment().subtract(1, 'days')) || (this.state.startdate && current <= this.state.startdate)}
                />
                <OBTimePicker
                  className={['timepicker', viewTimeError && !this.state.endtime && type === 'qualificational' ? ' error-border' : '']}
                  placeholder={intl.formatMessage({ id: 'time', defaultMessage: 'Time' })}
                  value={this.state.endtime}
                  onChange={endtime => this.onChangeEndTime(endtime)}
                  disabledHours={() => this.disabledHoursTimePicker('end')}
                  disabledMinutes={selectedHour => this.disabledMinutes('end', selectedHour)}
                  format={`HH:mm (Z ${(timezoneId && (geodata.find(geo => geo.id === (timezones.find(timezone => timezone.id === timezoneId)).countryid) || {}).iso) || 'UK'})`}
                />
              </div>
            </div>
            <label for='descriptionInput'>{intl.formatMessage({id: 'TournamentDescription', defaultMessage: 'Tournament description'})}</label>
            <TextArea
              value={description}
              onChange={({value}) => this.setState({ description: value })}
              id='descriptionInput'/>
          </div>

          <div className='tournamentCreate_desc'>
            <Checkbox
              small
              color
              style={{ alignItems: 'flex-start', width: '40%'}}
              checked={this.state.isTest}
              onChange={(value) => {
                // Если Турнир редактируется,не даем редактировать
                // if (this.state.isEdit) {
                //   return;
                // }
                this.setState({ isTest: value });
              }}
            >
              {intl.formatMessage({ id: 'TestTournament', defaultMessage: 'Test tournament' })}
            </Checkbox>
          </div>

          {/*{this.state.isEdit && <div className='tournamentCreate_desc'>
            <label htmlFor='costUpdate'>{intl.formatMessage({
              id: 'cost',
              defaultMessage: 'cost',
            })}</label>
            <FilterInput
              value={cost}
              onChange={({ target }) => this.setState({ cost: target.value })}
              id='costUpdate'
              type='number'/>
            <Radio.Group
              style={{ marginTop: 8 }}
              name="wallet"
              onChange={e => this.setState({ tournamentcurrency: e.target.value })}
              value={this.state.tournamentcurrency}>
              {this.props.currencies.map(currency => <Radio value={currency.id}>{currency.symbol} ({currency.name})</Radio>)}
            </Radio.Group>
          </div>}*/}

          {this.props.oldinfo && this.props.oldinfo.id ? null : <><div key='descriptionInput' className='tournamentCreate_desc'>
            <label for='descriptionInput'>{intl.formatMessage({ id: 'TournamentType', defaultMessage: 'Tournament type' })}</label>
            <Radio.Group
              name="type"
              onChange={e => this.setState({ type: e.target.value })}
              value={this.state.type}>
              <Radio value={'provisional'}>{intl.formatMessage({ id: 'Battle', defaultMessage: 'Battle' })}</Radio>
              <Radio value={'qualificational'}>{intl.formatMessage({ id: 'DropIn', defaultMessage: 'DropIn' })}</Radio>
            </Radio.Group>
          </div>
          {this.state.type === 'qualificational' && this.props.currencies && this.props.currencies.length ? <div key='descriptionInput' className='tournamentCreate_desc'>
            {/*<div>
              <label for='descriptionInput'>{intl.formatMessage({ id: 'TournamentCost', defaultMessage: 'Tournament cost' })}</label>
              <FilterInput
                value={tournamentcost}
                onChange={({ target }) => this.setState({ tournamentcost: target.value })}
                id='costInput'
                type='number'/>
              <Radio.Group
                name="wallet"
                onChange={e => this.setState({ tournamentcurrency: e.target.value })}
                value={this.state.tournamentcurrency}>
                {this.props.currencies.map(currency => <Radio value={currency.id}>{currency.symbol} ({currency.name})</Radio>)}
              </Radio.Group>
            </div>*/}
            <div className="win-count-group">
              <label>
                {intl.formatMessage({ id: 'NumberOfGames', defaultMessage: 'Number of games' })}
              </label>
              <Slider
                marks={marksSlider}
                onChange={value => this.setState({ countGame: marksSlider[value] })}
                step={null}
                tipFormatter={null}
                defaultValue={Object.keys(marksSlider).find(key => marksSlider[key] === countGame)}
                value={Object.keys(marksSlider).find(key => marksSlider[key] === countGame)}
              />
            </div>
          </div> : null}</>}
        </OBModal>
      )
  }
}

export default injectIntl(
  connect(
    state => ({
      currencies: state.admin.currencyList,
      timezones: state.common.timezones.data,
      settings: state.common.settings.data,
      geodata: state.common.geodata.data,
      tournamentInfoForEdit: state.tournaments.tournamentInfoForEdit && state.tournaments.tournamentInfoForEdit.data,
    }),
    {
      CreateTournament,
      UpdateTournament,
      getCurrencies,
      getTournamentInfoForEdit,
      clearTournamentInfoForEdit,
    },
  )(CreateTournamentModal),
);
