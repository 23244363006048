import React from 'react';
import './style.scss';

const poly = 'M14 1.12978L17.6661 8.55816L17.7825 8.79389L18.0426 8.83169L26.2403 10.0229L20.3084 15.8051L20.1202 15.9886L20.1646 16.2476L21.5649 24.4122L14.2327 20.5574L14 20.4351L13.7673 20.5574L6.43507 24.4122L7.83541 16.2476L7.87984 15.9886L7.69161 15.8051L1.75969 10.0229L9.9574 8.83169L10.2175 8.79389L10.3339 8.55816L14 1.12978Z';

const RatingStars = ({
  rating,
  width = 20,
  height = 20,
  displayRatingNumber = true,
  ratingNumberClass,
  ratingNumberStyle,
  starsContainerStyle,
  containerStyle,
  starStyle,
}) => (
  <div className="rating__container" style={containerStyle}>
    <div className="stars__container" style={starsContainerStyle}>
      {
        [1, 2, 3, 4, 5].map((num) => {
          let fill = 'none';
          let isPartial = false;
          const entropy = Math.random();
          if (rating > num || rating === num) {
            fill = '#FFB31E';
          } else if ((num - 1) < rating && rating < num) {
            isPartial = true;
            fill = `url(#partialGradient${entropy})`;
          }
          return (
            <div className="star" style={starStyle} key={num}>
              <svg width={width} height={height} viewBox="0 0 28 28" fill={fill} xmlns="http://www.w3.org/2000/svg">
                  <path d={poly} stroke="#FFB31E"/>
                  {isPartial
                    ? <linearGradient id={`partialGradient${entropy}`}>
                        <stop stopOpacity="1" offset={`${(rating - num + 1) * 100}%`} stopColor="#FFB31E" />
                        <stop stopOpacity="0" offset="0" />
                      </linearGradient>
                    : null
                  }
                </svg>
            </div>
          );
        })
      }
    </div>
    {displayRatingNumber && <div className={`rating ${ratingNumberClass}`} style={ratingNumberStyle}>
      {rating.toFixed(1)}
    </div>}
  </div>
);

export default RatingStars;
