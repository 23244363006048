import React from 'react';
import Timer from './timer';

const tenpincam = require('../../../images/Logo-TenPinCam.png');
const etbf = require('../../../images/elyt/etbf.png');
const tournamentLogo = require('../../../images/elyt/tournamentLogo.png');

function Header() {
  return (
    <div className="header-container">
      <Timer/>
      <div className="header">
        <div className="header-h">
          <div className="icons">
            Presented by
            <a href="/">
              <img className="tpc" src={tenpincam}/>
            </a>
            <a href="http://etbf.eu/">
              <img className="etbf" src={etbf}/>
            </a>
          </div>
          <div
            className="hh-btn"
            onClick={() => {
              document.querySelector('#forms').scrollIntoView({
                behavior: 'smooth',
              });
            }}
          >
            Registration
          </div>
        </div>
        <div className="header-b">
          <img src={tournamentLogo}/>
        </div>
        <div className="header-f">
          <div>
            <span className="number">16</span>
            <span className="word">federations</span>
            <span className="number">32</span>
            <span className="word">boys</span>
            <span className="number">32</span>
            <span className="word">girl</span>
          </div>
          <div>
            <span className="number">23.07-25.07</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
