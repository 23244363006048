import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import FriendsFilter from '../filter';
import config from '../../../config';
import { SearchPlayers } from '../../../libs/reducers/FriendsReducer';
import {
  UserByIdFetch, CreateBattle, setCurrentRoom, createPrivateRoom,
} from '../../../libs/reducers/actions';
import Flag from '../../common/Flag';
import { Button, AddFriendButton } from '../../common/Button';
import ModalUser from '../../common/modals/modalUser';
import ModalInviteBattle from '../../common/modals/modalInviteBattle';
import SvgCountry from '-!svg-react-loader!../../../images/country.svg'; // eslint-disable-line
import SvgGender from '-!svg-react-loader!../../../images/gender.svg'; // eslint-disable-line
import defaultAvatar from '../../../images/defaultAvatar.png';
import Avatar from '../../common/Avatar';
import makePrivateChat from '../../../libs/makePrivateChat';
import SearchProgress from '../../common/SearchProgress';
import SearchMagnifier from '../../../images/SearchMagnifier.png';
import RatingStars from '../../common/RatingStars';

import './style.css';

class PlayerSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showInviteBattle: false,
      showUserById: false,
      searchtext: '',
      selectCountry: '',
      selectGender: '',
      countryOptions: [],
      genderOptions: [
        { value: 'none', label: 'Not selected...' },
        { value: 'M', label: 'M' },
        { value: 'F', label: 'F' },
      ],
      searchingPlayers: false,
      isSeacrh: false,
    };

    this.searching = _.debounce(this.searching, 3000, {
      leading: false,
      trailing: true,
    });
  }

  static propTypes = {
    user: PropTypes.shape(),
    userById: PropTypes.shape(),
    playersearch: PropTypes.shape(),
    CreateBattle: PropTypes.func,
    UserByIdFetch: PropTypes.func,
    intl: intlShape,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = null;
    if (nextProps.geodata.length) {
      const countryOptions = [];
      nextProps.geodata.forEach((country) => {
        countryOptions.push({ value: country.iso, label: country.name });
      });
      if (countryOptions.length) {
        countryOptions.unshift({ value: 'none', label: 'Not selected...' });
      }
      state = { ...state, countryOptions };
    }
    return state;
  }

  onInviteBattleSubmit = ({
    betType, bet, winCount, dateType, date, time, comment, opponent,
  }) => {
    const sendParams = { name: 'battle' };
    sendParams.wincount = winCount;

    if (betType === 2) {
      sendParams.cost = bet;
    }
    if (dateType === 2) {
      let datestart;
      if (time !== null) {
        datestart = moment(date.set('h', time.hour()).set('m', time.minute()));
      } else {
        datestart = moment(date);
      }
      sendParams.datestart = moment(datestart).toISOString();
    }
    if (comment !== '') {
      sendParams.rules = comment;
    }
    sendParams.userid = opponent.id;
    this.props.CreateBattle({
      ...sendParams,
    });
    this.setState({ showInviteBattle: false });
  };

  linkClick = (record) => {
    this.props.UserByIdFetch({
      userid: record.id,
    });
  };

  searching = (searchtext, selectCountry, selectGender) => {
    this.setState({
      isSeacrh: true,
    });
    const searchobj = {};
    searchobj.name = searchtext;
    searchobj.country_live = selectCountry.value;
    searchobj.gender = selectGender.value;

    for (const key in searchobj) {
      if (
        searchobj[key] === null
        || searchobj[key] === undefined
        || searchobj[key] === ''
        || searchobj[key] === 'none'
      ) {
        delete searchobj[key];
      }
    }
    if (!_.isEmpty(searchobj)) {
      this.props.SearchPlayers(this.getSearchtext(searchobj));
    } else {
      this.props.SearchPlayers('name=""');
    }
    this.setState({
      searchingPlayers: false,
    });
  };

  getSearchtext = (params) => {
    const keyList = ['name', 'country_live', 'gender'];
    let result = '';
    let val;
    for (const key in params) {
      if (!keyList.length || keyList.indexOf(key) !== -1) {
        val = params[key];
        if (Array.isArray(val) && val.length > 0) {
          result += `${key}=${val.join(',')}&`;
        } else {
          result += `${key}=${val}&`;
        }
      }
    }
    if (result.length > 0) {
      result = result.slice(0, -1);
    }
    console.log('searchTextResult', result);
    return result;
  };

  sendMessageClick = (record) => {
    const {
      userid: from, rooms, _setCurrentRoom, _createPrivateRoom,
    } = this.props;
    const { id: to = 0 } = record || {};
    console.log('sendClick', record);
    makePrivateChat({
      from,
      to,
      rooms,
      setCurrentRoom: _setCurrentRoom,
      createPrivateRoom: _createPrivateRoom,
    });
  };

  render() {
    const {
      searchtext,
      selectCountry,
      selectGender,
      countryOptions,
      genderOptions,
      showInviteBattle,
      showUserById,
      searchingPlayers,
      isSeacrh,
    } = this.state;

    const {
      user, userById, playersearch, intl,
    } = this.props;

    let tempplayersearch = [];
    if (playersearch.length) {
      tempplayersearch = [...playersearch];
      tempplayersearch = tempplayersearch.filter(elem => elem.id !== user.id);
    }

    const columns = [
      {
        title: intl.formatMessage({ id: 'table_fullname', defaultMessage: 'Surname, name' }),
        dataIndex: 'userName',
        render: (data, record) => (
          <div className="username__container">
            <Avatar
              uri={record.photo ? `${config.FETCH.img}/${record.photo}` : defaultAvatar}
              flag={null}
              tabled
              isOnline={record.active}
              avgscore={(+record.avgscore || 0).toFixed()}
            />
            <div>
              {`${record.firstname} ${record.lastname}`.trim() || `${record.login}`}
              <RatingStars rating={+record.ratingsavg || 0} height={12} width={12} />
            </div>
          </div>
        ),
      },
      {
        title: intl.formatMessage({ id: 'login2', defaultMessage: 'Login' }),
        dataIndex: 'login',
        render: (data, record) => (
          <Link
            to="#"
            onClick={() => {
              this.linkClick(record);
            }}
            className="login__link"
          >
            {record.login}
          </Link>
        ),
      },
      {
        title: <SvgCountry />,
        dataIndex: 'flag',
        render: (data, record) => (
          <div className="battles__flag">
            <Flag name={record.country_live} />
          </div>
        ),
      },
      {
        title: <SvgGender />,
        dataIndex: 'gender',
        render: (data, record) => {
          if (record.gender === 'M') {
            return intl.formatMessage({ id: 'male', defaultMessage: 'M' });
          }
          if (record.gender === 'F') {
            return intl.formatMessage({ id: 'female', defaultMessage: 'F' });
          }
          return intl.formatMessage({ id: 'Other', defaultMessage: 'Other' });
        },
      },
      {
        title: '',
        dataIndex: 'actions',
        render: (data, record) => (
          <div className="buttons__container">
            <div className="buttonchatmessage__container">
              <Button
                size="md"
                kind="aquamarine"
                bordered="true"
                icon="chatmessage"
                onClick={() => {
                  this.sendMessageClick(record);
                }}
              />
            </div>
            <div className="buttonaddfriend__container">
              <AddFriendButton
                size="md"
                icon="adduser"
                kind="aquamarine"
                bordered="true"
                user={record}
              />
            </div>
          </div>
        ),
      },
    ];

    const loading = searchingPlayers
      ? <div className="friends__table-loading">
          <SearchProgress loading={searchingPlayers} text={`${intl.formatMessage({ id: 'PlayerSearchText', defaultMessage: 'Search is in progress' })}...`}/>
        </div>
      : '';

    const firstFindInfo = <div className="friends__table-notfind">
        <div className="notfind_container">
          <div className="notfind__wrap-img">
            <img className="notfind__img" src={SearchMagnifier} />
          </div>
          <div className="notfind__text">
            {intl.formatMessage({ id: 'YouCanFindAnyPlayer', defaultMessage: 'You can find any player using the search. To do this, enter his Name / Surname / E-mail / Login' })}
          </div>
        </div>
      </div>;

    return (
      <div className="player-search">
        <FriendsFilter
          searchtext={searchtext}
          onChangeInput={(e) => {
            this.setState({
              searchtext: e.target.value,
              searchingPlayers: true,
            });
            this.searching(e.target.value, selectCountry, selectGender);
          }}
          selectCountry={selectCountry}
          selectGender={selectGender}
          onChangeCountry={(e) => {
            if (e.value === 'none') {
              this.setState({
                selectCountry: '',
                searchingPlayers: true,
              });
              this.searching(searchtext, e, selectGender);
            } else {
              this.setState({
                selectCountry: e,
                searchingPlayers: true,
              });
              this.searching(searchtext, e, selectGender);
            }
          }}
          onChangeGender={(e) => {
            if (e.value === 'none') {
              this.setState({
                selectGender: '',
                searchingPlayers: true,
              });
              this.searching(searchtext, selectCountry, e);
            } else {
              this.setState({
                selectGender: e,
                searchingPlayers: true,
              });
              this.searching(searchtext, selectCountry, e);
            }
          }}
          countryOptions={countryOptions}
          genderOptions={genderOptions}
        />
        <div className="friends__table-container">
          <Table
            key={this.props.langs}
            className="friends__table"
            rowClassName="friends__table__row"
            columns={columns}
            dataSource={searchingPlayers ? [] : tempplayersearch}
            pagination={false}
            rowKey="id"
            locale={{
              emptyText: !tempplayersearch.length && !searchingPlayers && isSeacrh
                ? intl.formatMessage({ id: 'PlayerSearchNoResult', defaultMessage: 'Players not found' })
                : <></>,
            }}
          />
          { !isSeacrh && !searchingPlayers && !tempplayersearch.length
            ? firstFindInfo
            : loading
          }
        </div>
        <ModalInviteBattle
          visible={showInviteBattle}
          onSubmit={this.onInviteBattleSubmit}
          onClose={() => {
            this.setState({
              showInviteBattle: false,
              showUserById: true,
            });
          }}
          opponent={{
            ...userById.data,
            hourshift: (userById.data.hourshift && userById.data.hourshift !== 0
              ? userById.data.hourshift
              : '0'
            ).toString(),
          }}
        />
        <ModalUser
          title="User card"
          onClose={() => {
            this.setState({
              showUserById: false,
            });
          }}
          onChallengeClick={() => {
            this.setState({
              showUserById: false,
              showInviteBattle: true,
            });
          }}
          isChallengeAllowed={user.id !== userById.data.id}
          user={{
            ...userById.data,
            // hourshift: (userById.data.hourshift && userById.data.hourshift !== 0
            //   ? userById.data.hourshift
            //   : '0'
            // ).toString(),
          }}
          visible={showUserById}
        />
      </div>
    );
  }
}

export default injectIntl(
  connect(
    state => ({
      user: state.users.user,
      userById: state.users.userById,
      geodata: state.common.geodata.data,
      playersearch: state.friends.playersearch.data,
      langs: state.langs.lang,
      rooms: state.chat.rooms,
      userid: state.users.user.id,
    }),
    {
      SearchPlayers,
      UserByIdFetch,
      CreateBattle,
      _createPrivateRoom: createPrivateRoom,
      _setCurrentRoom: setCurrentRoom,
    },
  )(PlayerSearch),
);
