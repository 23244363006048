import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';

import { UserSetData, GetGeodata, GetTimeZones, GetUserCountry, GetDefaultTimezone } from '../libs/reducers/actions';

import { store } from '../store';
import { setCommonError } from '../libs/reducers/commonReducer';

import Title from './common/Title';
import ModalImageCrop from './common/modals/modalImageCrop';
import Input from './common/Input';
import Select from './common/Select';
import DatePicker from './common/DatePicker';
import Radio from './common/Radio';
import { Button } from './common/Button';
import logo from '../images/Logo1.png';
import boy from '../images/boy.svg';
import './style.css';
import UAParser from 'ua-parser-js'

const useNeg = true;
const useGreek = true;

const nameRegExpBasic = /^[\wа-яА-Я]+$/;
const nameRegExpGreek = /^[\wа-яА-Яα-ωΑ-Ω]+$/;
const nameRegExpNeg = /^[^\s!@#$%^&*()+=\\/~"';:,?`|{}[\]_№<>]+$/; // Neg значит negative
const nameRegExpNegSymbols = ' !@#$%^&*()+=\\/~"\';:,?`|{}[]_№<>';

const NAME_REGEXP = useNeg ? nameRegExpNeg : (useGreek ? nameRegExpGreek : nameRegExpBasic); // eslint-disable-line no-nested-ternary, max-len
const LOGIN_REGEXP = /^\w+$/;

const RadioGroup = Radio.Group;

const customStyle = {
  valueContainer: base => ({
    ...base,
    paddingLeft: 0,
  }),
  singleValue: base => ({
    ...base,
    marginLeft: 0,
    color: '#fff',
    fontFamily: 'Open Sans',
    fontWeight: 300,
  }),
  control: base => ({
    ...base,
    backgroundColor: 'transparent',
    borderColor: '#f65932',
    ':hover': {
      borderColor: '#f65932',
    },
    borderRadius: 0,
    minHeight: 30,
    boxShadow: 'none',
    cursor: 'pointer',
    borderStyle: 'unset',
    borderBottom: '1px solid #f65932',
    paddingLeft: 0,
  }),
  placeholder: base => ({
    ...base,
    color: '#6a6869',
    fontSize: 16,
    fontWeight: 400,
    marginLeft: 0,
    paddingLeft: 0,
  }),
  option: (base, { isSelected }) => ({
    ...base,
    backgroundColor: isSelected ? '#20a39e' : '#6d7177',
    ':hover': {
      backgroundColor: '#20a39e',
    },
    color: '#fff',
    fontSize: 14,
    fontWeight: 300,
    padding: '6px 10px',
    cursor: 'pointer',
  }),
};

const logger = {
  log: console.log,
};

class PostRegData extends React.Component {
  constructor(props) {
    super(props);
    const isSocial = !_.isEmpty(props.users.snuser);
    this.state = {
      firstName: (isSocial ? props.users.user.firstname : '') || '',
      lastName: (isSocial ? props.users.user.lastname : '') || '',
      login: (isSocial ? props.users.user.login : '') || '',
      isSocial,
      isDefaultLogin: PostRegData.isDefaultLogin(props.users.user.login, props.users.snuser),
      userPhoto: '',
      file: '',
      dob: null,
      dobday: '',
      dobmonth: '',
      dobyear: '',
      photo: boy,
      gender: (isSocial ? props.users.user.gender : '') || '?', // '?',
      countryLive: '',
      hourShift: '',
      countryFederation: '',
      club: '',
      showModalImageCrop: false,
      formErrors: {
        firstName: '',
        lastName: '',
        login: '',
        dob: '',
        gender: '',
        countryLive: '',
        hourShift: '',
        userPhoto: '',
      },
      countryArray: [],
      hourShifts: [],
      dayArray: [],
      monthArray: [],
      yearArray: [],
      isReferral: false,
    };
    this.checkLogin = _.throttle(this.checkLogin, 1000, { trailing: true, leading: false });
    this.filePickerRef = null;
  }

  static propTypes = {
    users: PropTypes.object,
    GetGeodata: PropTypes.func,
    UserSetData: PropTypes.func,
    GetUserCountry: PropTypes.func,
    GetDefaultTimezone: PropTypes.func,
    intl: intlShape,
    checkLogin: PropTypes.func,
  };


  async setDays(max) {
    const days = []
    for (let count=1; count<max+1; count++) {
      days.push({value: count, label: count});
    }
    await this.setState({dayArray: days})

  }

  componentDidMount() {
    const { intl } = this.props;
    this.props.GetGeodata();
    this.props.GetUserCountry();
    this.setDays(31);
    const months = [
      {value: 'January', label: intl.formatMessage({id: 'January', defaultMessage: 'January'}), days: 31, index: 0},
      {value: 'February', label: intl.formatMessage({id: 'February', defaultMessage: 'February'}), days: 28, index: 1},
      {value: 'March', label: intl.formatMessage({id: 'March', defaultMessage: 'March'}), days: 31, index: 2},
      {value: 'April', label: intl.formatMessage({id: 'April', defaultMessage: 'April'}), days: 30, index: 3},
      {value: 'May', label: intl.formatMessage({id: 'May', defaultMessage: 'May'}), days: 31, index: 4},
      {value: 'June', label: intl.formatMessage({id: 'June', defaultMessage: 'June'}), days: 30, index: 5},
      {value: 'July', label: intl.formatMessage({id: 'July', defaultMessage: 'July'}), days: 31, index: 6},
      {value: 'August', label: intl.formatMessage({id: 'August', defaultMessage: 'August'}), days: 31, index: 7},
      {value: 'September', label: intl.formatMessage({id: 'September', defaultMessage: 'September'}), days: 30, index: 8},
      {value: 'October', label: intl.formatMessage({id: 'October', defaultMessage: 'October'}), days: 31, index: 9},
      {value: 'November', label: intl.formatMessage({id: 'November', defaultMessage: 'November'}), days: 30, index: 10},
      {value: 'December', label: intl.formatMessage({id: 'December', defaultMessage: 'December'}), days: 31, index: 11},
    ];
    const years = [];
    const currYear = new Date().getFullYear();
    for (let count = 0; count < 71; count += 1) {
      years.push({ value: currYear - count, label: currYear - count });
    }
    this.setState({
      monthArray: months,
      yearArray: years,
    });
    this.isReferralHandler()
  }

  isReferralHandler = () => {
    let isReferral = false
    if (this.props.history.location.state) isReferral = this.props.history.location.state.isReferral
    this.setState({isReferral: isReferral})
  }

  componentDidUpdate(oldprops, prevState) {
    const newprops = this.props;
    const { users, intl } = this.props;
    if (oldprops.usercountry !== newprops.usercountry && (newprops.usercountry||{}).data) {
      this.setState({
        countryLive: { value: newprops.usercountry.data.iso, label: newprops.usercountry.data.name },
        countryFederation: { value: newprops.usercountry.data.iso, label: newprops.usercountry.data.name },
      }, () => { this.props.GetDefaultTimezone(newprops.usercountry.data.iso); });
    }
    if (!_.isEmpty(this.props.users.snuser) && !this.state.isSocial) {
      this.setState({ isSocial: true });
    }
    if (!users.checkLoginString.loading && oldprops.users.checkLoginString.loading) {
      const { isDefaultLogin, formErrors } = this.state;
      if (!users.checkLoginString.success && !isDefaultLogin) {
        this.setState({
          formErrors: { ...formErrors, login: intl.formatMessage({ id: 'loginAlreadyInUse', defaultMessage: 'This login is already in use' }) },
        });
      }
    }
    if (oldprops.defaulttimezone !== newprops.defaulttimezone && newprops.defaulttimezone.data) {
      const elem = newprops.defaulttimezone.data;
      let str = `${elem.name}, `;
      if (elem.rawoffset === 0) {
        str = `${str}+00:00`;
      }
      if (elem.rawoffset > 0) {
        str = `${str}+${elem.rawoffset}`;
      }
      if (elem.rawoffset < 0) {
        str = `${str}${elem.rawoffset}`;
      }
      str = str.replace('.5', ':30');
      str = str.replace('.75', ':45');
      str = str.replace('.25', ':15');
      this.setState({
        hourShift: { value: elem.id, label: str },
      });
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    // if (_.isEmpty(nextProps.users.user)) {
    //   nextProps.history.push('/login');
    // }
    if (nextProps.users.accessed && nextProps.users.reg === false && !nextProps.isFirstEnter) {
      nextProps.setFirstEnter(true);
      if (prevState.isReferral){
        nextProps.history.push('/howtoinstall');
      } else {
        nextProps.history.push('/lobby');
      }
    }
    let state = null;
    if (!_.isEmpty(nextProps.geodata.data)) {
      const arr = [];
      nextProps.geodata.data.forEach((elem) => {
        arr.push({ value: elem.iso, label: elem.name });
      });
      state = {
        ...state,
        countryArray: arr,
      };
    }
    if (nextProps.timezones && nextProps.timezones.data && !_.isEmpty(nextProps.timezones.data)) {
      const arrTimeZones = [];
      nextProps.timezones.data.forEach((elem) => {
        let str = `${elem.name}, `;
        if (elem.rawoffset === 0) {
          str = `${str}+00:00`;
        }
        if (elem.rawoffset > 0) {
          str = `${str}+${elem.rawoffset}`;
        }
        if (elem.rawoffset < 0) {
          str = `${str}${elem.rawoffset}`;
        }
        str = str.replace('.5', ':30');
        str = str.replace('.75', ':45');
        str = str.replace('.25', ':15');
        arrTimeZones.push({ value: elem.id, label: str });
      });
      state = {
        ...state,
        hourShifts: arrTimeZones,
      };
    }
    return state;
  }

  updateLoginStatus = (login) => {
    const isDefaultLogin = PostRegData.isDefaultLogin(login, this.props.users.snuser);
    this.setState({ isDefaultLogin });
  }

  // eslint-disable-next-line react/sort-comp
  static isDefaultLogin = (login, snuser) => {
    let isDefaultLogin = false;
    if (!_.isEmpty(snuser)) {
      const match = (login || '').match(/^(vkontakte|facebook|google|instagram)?(?:-|_)(\d+)$/);
      isDefaultLogin = !!match && match[2] === `${snuser.id}`;
    }
    return isDefaultLogin;
  }

  checkLogin = (login) => {
    const { checkLogin } = this.props;
    if (login && typeof checkLogin === 'function') {
      checkLogin(login);
    }
  }

  handleErrors = (errors) => {
    logger.log('handleErrors() -> errors:', errors);
    const msgList = [];
    let idx = 1;
    const { intl } = this.props;
    for (const key of Object.keys(errors)) {
      if (errors[key]) {
        let fieldname = '';
        // eslint-disable-next-line default-case
        switch (key) {
          case 'login':
            fieldname = intl.formatMessage({ id: 'login4', defaultMessage: 'Login' });
            break;
          case 'firstName':
            fieldname = intl.formatMessage({ id: 'Name', defaultMessage: 'Name' });
            break;
          case 'lastName':
            fieldname = intl.formatMessage({ id: 'Surname', defaultMessage: 'Surname' });
            break;
          case 'dob':
            fieldname = intl.formatMessage({ id: 'PersonalInfoDOB', defaultMessage: 'Date of birth' });
            break;
          case 'countryLive':
            fieldname = intl.formatMessage({ id: 'PersonalInfoCountryLiving', defaultMessage: 'Country living' });
            break;
          case 'hourShift':
            fieldname = intl.formatMessage({ id: 'PersonalInfoTimeZone', defaultMessage: 'Timezone' });
            break;
          case 'userPhoto':
            fieldname = intl.formatMessage({ id: 'PhotoAsUploadedAvatarAlias', defaultMessage: 'Photo' });
            break;
        }
        msgList.push(<div style={{margin: '20px 0'}}>{`${idx}) ${errors[key]}: ${fieldname}`}</div>);
        idx += 1;
      }
    }
    if (msgList.length) {
      store.dispatch(setCommonError(msgList.map(message => ({ message }))));
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      firstName, lastName, dob, gender, countryLive, hourShift, userPhoto, countryFederation, club, login, isSocial,
    } = this.state;
    const errors = this.validate(firstName, lastName, dob, gender, countryLive, hourShift, userPhoto, login);
    // this.handleErrors(errors); // закомментил, чтобы убрать модалку
    let isFormValid = true;
    _.forIn(errors, (fieldErrorMsg) => {
      if (!_.isEmpty(fieldErrorMsg)) isFormValid = false;
    });

    this.setState({ formErrors: errors }, () => {
      if (isFormValid) {
        const userData = {
          userPhoto,
          firstName,
          lastName,
          dob,
          gender,
          countryLive,
          hourShift,
          countryFederation,
          club,
          lang: this.props.lang,
        };
        if (isSocial) {
          userData.login = login;
        }
        this.props.UserSetData(userData);
      }
    });
  };

  validate(firstName, lastName, dob, gender, countryLive, hourShift, userPhoto, login) {
    const fieldValidationErrors = {
      firstName: '',
      lastName: '',
      dob: '',
      gender: '',
      countryLive: '',
      hourShift: '',
      userPhoto: '',
      login: '',
    };
    const { intl } = this.props;
    const { formErrors, isSocial, isDefaultLogin } = this.state;

    if (isSocial) {
      let loginError;
      if (!login) {
        loginError = intl.formatMessage({ id: 'LoginEmpty', defaultMessage: 'Login field is empty' });
      }
      if (!loginError && login && !LOGIN_REGEXP.test(login)) {
        const loginField = intl.formatMessage({ id: 'login4', defaultMessage: 'Login' });
        loginError = intl.formatMessage({
          id: 'alphaNumericFieldLat',
          defaultMessage: '{fieldName} must contain alphanumeric characters including the underscore',
        }, { fieldName: loginField });
      }
      if (!loginError && login && (login.length > 50 || login.length < 4)) {
        const loginField = intl.formatMessage({ id: 'login4', defaultMessage: 'Login' });
        loginError = intl.formatMessage({
          id: 'lengthRangeError__',
          defaultMessage: '{fieldName} field must contain from {min} to {max, plural, one {# symbol} other {# symbols}}',
        }, { fieldName: loginField, min: 4, max: 50 });
      }
      if (!loginError && login && (login.startsWith('_') || login.endsWith('_'))) {
        const loginField = intl.formatMessage({ id: 'login4', defaultMessage: 'Login' });
        loginError = intl.formatMessage({
          id: 'fieldStartEndError',
          defaultMessage: '{fieldName} field must not start and / or end with {character} character',
        }, {
          fieldName: loginField,
          character: intl.formatMessage({ id: 'underScoreChar', defaultMessage: 'underscore' }),
        });
      }
      if (!loginError && login && isDefaultLogin) {
        loginError = intl.formatMessage({
          id: 'defaultLoginError',
          defaultMessage: 'Don\'t use default login value',
        });
      }
      if (loginError && loginError !== formErrors.login) {
        fieldValidationErrors.login = loginError;
      } else if (!loginError && formErrors.login) {
        fieldValidationErrors.login = formErrors.login;
      }
    }
    let firstNameError;
    if (!firstName) {
      firstNameError = intl.formatMessage({ id: 'emptyFirstNameError', defaultMessage: 'First name field must not be empty' });
    } else if (firstName.length > 50) {
      firstNameError = intl.formatMessage({
        id: 'lengthRangeError2',
        defaultMessage: '{fieldName} field must not contain more than {max} {max, plural, one {symbol} other {symbols}}',
      }, {
        fieldName: intl.formatMessage({ id: 'Name', defaultMessage: 'Name' }),
        max: 50,
      });
    } else if (!NAME_REGEXP.test(firstName)) {
      if (useNeg) {
        firstNameError = intl.formatMessage({
          id: 'invalidSymbolInNameError',
          defaultMessage: 'Field {fieldName} must not contain following symbols "{symbols}"',
        }, {
          fieldName: intl.formatMessage({ id: 'Name', defaultMessage: 'Name' }),
          symbols: nameRegExpNegSymbols,
        });
      } else {
        firstNameError = intl.formatMessage({
          id: 'alphaNumericField',
          defaultMessage: '{fieldName} must contain alphanumeric characters including the underscore',
        }, {
          fieldName: intl.formatMessage({ id: 'Name', defaultMessage: 'Name' }),
        });
      }
    }
    if (firstNameError) {
      fieldValidationErrors.firstName = firstNameError;
    }
    let lastNameError;
    if (!lastName) {
      lastNameError = intl.formatMessage({ id: 'emptyLastNameError', defaultMessage: 'Last name field must not be empty' });
    } else if (lastName.length > 50) {
      lastNameError = intl.formatMessage({
        id: 'lengthRangeError2',
        defaultMessage: '{fieldName} field must not contain more than {max} {max, plural, one {symbol} other {symbols}}',
      }, {
        fieldName: intl.formatMessage({ id: 'Surname', defaultMessage: 'Surname' }),
        max: 50,
      });
    } else if (!NAME_REGEXP.test(lastName)) {
      if (useNeg) {
        lastNameError = intl.formatMessage({
          id: 'invalidSymbolInNameError',
          defaultMessage: 'Field {fieldName} must not contain following symbols "{symbols}"',
        }, {
          fieldName: intl.formatMessage({ id: 'Surname', defaultMessage: 'Surname' }),
          symbols: nameRegExpNegSymbols,
        });
      } else {
        lastNameError = intl.formatMessage({
          id: 'alphaNumericField',
          defaultMessage: '{fieldName} must contain alphanumeric characters including the underscore',
        }, {
          fieldName: intl.formatMessage({ id: 'Surname', defaultMessage: 'Surname' }),
        });
      }
    }
    if (lastNameError) {
      fieldValidationErrors.lastName = lastNameError;
    }
    if (_.isEmpty(dob)) {
      fieldValidationErrors.dob = intl.formatMessage({ id: 'requiredField', defaultMessage: 'Field is required' });
    }
    if (_.isEmpty(countryLive)) {
      fieldValidationErrors.countryLive = intl.formatMessage({ id: 'requiredField', defaultMessage: 'Field is required' });
    }
    if (_.isEmpty(hourShift)) {
      fieldValidationErrors.hourShift = intl.formatMessage({ id: 'requiredField', defaultMessage: 'Field is required' });
    }
    return fieldValidationErrors;
  }

  setRef = (c) => {
    this.filePickerRef = c;
  };

  imageChange = (e) => {
    const { formErrors } = this.state;
    const { intl } = this.props;
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file.size > 10240000) {
      this.setState({
        formErrors: {
          ...formErrors,
          userPhoto: intl.formatMessage({ id: 'UploadToBulk', defaultMessage: `Uploaded image is too big. File mustn't be larger than 10 Mb.` }),
        },
        file: '',
        userPhoto: '',
      });
    } else {
      reader.onloadend = () => {
        this.setState(
          {
            userPhoto: reader.result,
            file,
          },
          () => {
            this.setState({
              showModalImageCrop: true,
            });
          },
        );
      };
      reader.readAsDataURL(file);
    }
  };

  setDOB = () => {
    const { dobyear, dobmonth, dobday } = this.state;
    if (typeof dobyear.value === 'number' && typeof dobmonth.index === 'number' && typeof dobday.value === 'number') {
      this.setState({
        dob: new Date(dobyear.value, dobmonth.index, dobday.value).toISOString(),
      });
    } else {
      this.setState({ dob: null });
    }
  }

  render() {
    const {
      showModalImageCrop,
      userPhoto,
      countryArray,
      hourShifts,
      countryFederation,
      club,
      formErrors,
      photo,
      firstName,
      lastName,
      login,
      dob,
      gender,
      countryLive,
      hourShift,
      dobday,
      dobmonth,
      dobyear,
      dayArray,
      monthArray,
      yearArray,
      isSocial,
    } = this.state;
    const { intl, users: { snuser = {}, checkLoginString = {} } } = this.props;
    return (
      <div className="postregpage">
        <input
          className="postregpage__fileinput"
          type="file"
          accept=".jpg, .jpeg, .png, .gif, .bmp"
          ref={this.setRef}
          onChange={(e) => {
            this.imageChange(e);
          }}
          onClick={(e) => {
            e.target.value = null;
          }}
        />
        {showModalImageCrop && (
          <ModalImageCrop
            visible={showModalImageCrop}
            image={userPhoto}
            onSubmit={(image) => {
              this.setState({
                userPhoto: image,
                showModalImageCrop: false,
                file: '',
              });
            }}
            onClose={() => {
              this.setState({
                showModalImageCrop: false,
              });
            }}
          />
        )}
        <div className="postregpage__content">
          <div className="postregpage__leftcolumn">
            <div className="postregpage__leftcolumn__content__logos">
              <div className="postregpage__leftcolumn__content">
                <img src={logo} alt="" className="postregpage__leftcolumn__content__logo" />
                <Title subtitle={`${intl.formatMessage({ id: 'DataNoun', defaultMessage: 'data' })}`}>{intl.formatMessage({ id: 'PersonalAdjective', defaultMessage: 'Personal' })}</Title>
                {/*<div className="postregpage__leftcolumn__content__message">
                  {intl.formatMessage({ id: 'EmailAcceptProceedRegister', defaultMessage: 'Your e-mail verified. Please, fill your personal data to finish registration.' })}
                </div>*/}
              </div>
            </div>
          </div>

          <div className="postregpage__rightcolumn">
            <div className="postregpage__rightcolumn__content">
              <span className={'registration-page__right-title'}>
                {intl.formatMessage({ id: 'PersonalAdjective', defaultMessage: 'Personal' })} {intl.formatMessage({ id: 'DataNoun', defaultMessage: 'data' })}
              </span>
              <form onSubmit={this.handleSubmit}>
                <div className="input-group">
                  <div className="postregpage__rightcolumn__content__photo__maincontainer">
                    <div className="postregpage__rightcolumn__content__photo">
                      <div className="postregpage__rightcolumn__content__img">
                        {userPhoto !== '' ? (
                          <img src={userPhoto} alt="" className="postregpage__rightcolumn__content__userphoto" />
                        ) : (
                          <img src={photo} alt="" className="postregpage__rightcolumn__content__defaultphoto" />
                        )}
                      </div>
                      <div className="postregpage__rightcolumn__content__photo__container">
                        <div className="postregpage__rightcolumn__content__photo__text">
                          {intl.formatMessage({ id: 'UploadPhotoToConfirmIdentity', defaultMessage: 'Upload your photo. Your face must be visible on the photo for your later identification' })}
                        </div>
                        <div className="postregpage__rightcolumn__content__photo__text__button">
                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              if (this.filePickerRef) {
                                this.filePickerRef.click();
                              }
                            }}
                            size="xs"
                          >
                            {intl.formatMessage({ id: 'UploadAsFileSendAlias', defaultMessage: 'Upload' })}
                          </Button>
                        </div>
                      </div>
                    </div>
                    {formErrors.userPhoto ? <div className="postregpage_inputerror">{formErrors.userPhoto}</div> : null}
                  </div>
                  <div className="postregpage__rightcolumn__content__inputs">
                    {isSocial && !_.isEmpty(snuser) && <div>
                      <Input
                        name={intl.formatMessage({ id: 'login4', defaultMessage: 'Login' })}
                        onChangeText={(text) => {
                          this.updateLoginStatus(text);
                          const { formErrors } = this.state;
                          if (formErrors.login) {
                            this.setState({ formErrors: { ...formErrors, login: '' } });
                          }
                          this.setState({ login: text });
                          this.checkLogin(text);
                        }}
                        value={login}
                        placeholder={intl.formatMessage({ id: 'login4', defaultMessage: 'Login' })}
                        error={formErrors.login}
                      />
                    </div>}
                    <div className="postregpage__rightcolumn__content__input">
                      <Input
                        name={intl.formatMessage({ id: 'Name', defaultMessage: 'Name' })}
                        onChangeText={(text) => {
                          this.setState({
                            firstName: text,
                            formErrors: { ...formErrors, firstName: '' },
                          });
                        }}
                        value={firstName}
                        placeholder={intl.formatMessage({ id: 'Name', defaultMessage: 'Name' })}
                        error={formErrors.firstName}
                      />
                    </div>
                    <div className="postregpage__rightcolumn__content__input">
                      <Input
                        name={intl.formatMessage({ id: 'Surname', defaultMessage: 'Surname' })}
                        onChangeText={(text) => {
                          this.setState({
                            lastName: text,
                            formErrors: { ...formErrors, lastName: '' },
                          });
                        }}
                        value={lastName}
                        placeholder={intl.formatMessage({ id: 'Surname', defaultMessage: 'Surname' })}
                        error={formErrors.lastName}
                      />
                    </div>
                    <div className="input__tip before_datepicker">{intl.formatMessage({ id: 'PersonalInfoDOB', defaultMessage: 'Date of birth' })}</div>
                    <div className="postregpage__rightcolumn__content__datepicker">
                      <div className="postregpage__rightcolumn__content__input__datepicker">
                        <Select
                          isSearchable
                          value={dobday}
                          placeholder={intl.formatMessage({ id: 'DayInTimescaleContext', defaultMessage: 'Day' })}
                          onChange={(e) => {
                            this.setState({ dobday: e, formErrors: { ...formErrors, dob: '' } }, this.setDOB);
                          }}
                          options={dayArray}
                          customStyle={customStyle}
                        />
                        <Select
                          isSearchable
                          value={dobmonth}
                          placeholder={intl.formatMessage({ id: 'MonthInTimescaleContext', defaultMessage: 'Month' })}
                          onChange={(e) => {
                            this.setState({ dobmonth: e });
                            const days = (!(dobyear.value % 4) && e.days === 28) ? (e.days + 1) : e.days;
                            this.setDays(days).then(() => {
                              if (dobday.value > days) {
                                this.setState({ dobday: dayArray[days - 1], formErrors: { ...formErrors, dob: '' } }, this.setDOB);
                              } else {
                                this.setDOB();
                              }
                            });
                          }}
                          options={monthArray}
                          customStyle={customStyle}
                        />
                        <Select
                          isSearchable
                          value={dobyear}
                          placeholder={intl.formatMessage({ id: 'YearInTimescaleContext', defaultMessage: 'Year' })}
                          onChange={(e) => {
                            this.setState({ dobyear: e })
                            const days = (!(e.value % 4) && dobmonth.days === 28) ? (dobmonth.days + 1) : dobmonth.days;
                            this.setDays(days).then(() => {
                              if (dobday.value > days) {
                                this.setState({ dobday: dayArray[days - 1], formErrors: { ...formErrors, dob: '' } }, this.setDOB);
                              } else {
                                this.setDOB();
                              }
                            });
                          }}
                          options={yearArray}
                          customStyle={customStyle}
                        />
                      </div>
                      {!!formErrors.dob && <div className="input__error">{formErrors.dob}</div>}
                    </div>
                    <div className="postregpage__rightcolumn__content__radiogroup">
                      <span className="postregpage__rightcolumn__content__radiogroupname">{intl.formatMessage({ id: 'Gender', defaultMessage: 'Gender' })}</span>
                      <RadioGroup
                        className="postregpage__rightcolumn__content__radios"
                        name={intl.formatMessage({ id: 'Gender', defaultMessage: 'Gender' })}
                        onChange={(e) => {
                          this.setState({
                            gender: e.target.value,
                          });
                        }}
                        value={gender}
                      >
                        <Radio className="postregpage__rightcolumn__content__radio1" value="M">
                          {intl.formatMessage({ id: 'GenderMale', defaultMessage: 'Male' })}
                        </Radio>
                        <Radio className="postregpage__rightcolumn__content__radio2" value="F">
                        {intl.formatMessage({ id: 'GenderFemale', defaultMessage: 'Female' })}
                        </Radio>
                      </RadioGroup>
                    </div>
                    <div className="postregpage__rightcolumn__content__input">
                      <div className="postregpage__rightcolumn__content__select__container">
                        <Select
                          isSearchable
                          value={countryLive}
                          placeholder={intl.formatMessage({ id: 'PersonalInfoCountryLiving', defaultMessage: 'Country living' })}
                          onChange={(e) => {
                            this.props.GetTimeZones();
                            this.setState({
                              countryLive: e,
                              formErrors: { ...formErrors, countryLive: '' },
                            });
                            this.props.GetDefaultTimezone(e.value);
                            if (!this.state.countryFederation) {
                              this.setState({ countryFederation: e, formErrors: { ...formErrors, countryFederation: '' } });
                            }
                          }}
                          options={countryArray}
                          customStyle={customStyle}
                          error={formErrors.countryLive}
                        />
                      </div>
                    </div>
                    <div className="postregpage__rightcolumn__content__select__container hourshift">
                      <Select
                        isSearchable
                        value={hourShift}
                        placeholder={intl.formatMessage({ id: 'PersonalInfoTimeZone', defaultMessage: 'Timezone' })}
                        onChange={(e) => {
                          this.setState({
                            hourShift: e,
                            formErrors: { ...formErrors, hourShift: '' },
                          });
                        }}
                        options={hourShifts}
                        customStyle={customStyle}
                        error={formErrors.hourShift}
                      />
                    </div>
                    {/*<div className="postregpage__rightcolumn__content__additionaldata">{intl.formatMessage({ id: 'AdditionDataInPostReg', defaultMessage: 'Additional data' })}</div>
                    <div className="postregpage__rightcolumn__content__select__container">
                      <Select
                        isSearchable
                        value={countryFederation}
                        placeholder={intl.formatMessage({ id: 'FederationCountry', defaultMessage: 'Federation country' })}
                        onChange={(e) => {
                          this.setState({
                            countryFederation: e,
                          });
                        }}
                        options={countryArray}
                        customStyle={customStyle}
                      />
                    </div>
                    <Input
                      name={intl.formatMessage({ id: 'ClubParticipant', defaultMessage: 'Club participation' })}
                      onChangeText={(text) => {
                        this.setState({
                          club: text,
                        });
                      }}
                      value={club}
                      placeholder={intl.formatMessage({ id: 'ClubParticipant', defaultMessage: 'Club participation' })}
                    />*/}
                    <div className="postregpage__rightcolumn__content__button">
                      <Button type="submit" size="md" disabled={checkLoginString.loading ? 'disabled' : ''}>
                        {intl.formatMessage({ id: 'SaveAsKeepAlias', defaultMessage: 'Save' })}
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(withRouter(
  connect(
    state => ({
      users: state.users,
      geodata: state.common.geodata,
      timezones: state.common.timezones,
      usercountry: state.common.usercountry,
      defaulttimezone: state.common.defaulttimezone,
      lang: state.langs.lang || 'EN',
      isFirstEnter: state.users.firstEnter,
    }),
    {
      UserSetData,
      GetGeodata,
      GetTimeZones,
      GetUserCountry,
      GetDefaultTimezone,
      checkLogin: login => ({ type: 'CHECK_LOGIN_STRING', login }),
      setFirstEnter: (isFirstEnter = false) => ({ type: 'FIRST_ENTER', payload: isFirstEnter }),
    },
  )(PostRegData),
));
