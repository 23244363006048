import React, { useState, useEffect } from 'react';
import moment from 'moment';

const TournamentDate = moment.utc('2021-07-23T00:00:00');

const initialDiffTime = TournamentDate.diff(moment());
const initialDuration = moment.duration(initialDiffTime);

function Timer() {
  const [days, setDays] = useState(initialDuration.asDays().toFixed());
  const [hours, setHours] = useState(initialDuration.hours());
  const [minutes, setMinutes] = useState(initialDuration.minutes());
  const [seconds, setSeconds] = useState(initialDuration.seconds());
  const [timerShow, setTimerShow] = useState(!moment().isAfter(TournamentDate));
  useEffect(() => {
    const intervel = setInterval(() => {
      if (moment().isAfter(TournamentDate)) {
        setTimerShow(false);
        clearInterval(intervel);
      } else {
        const diffTime = TournamentDate.diff(moment());
        const duration = moment.duration(diffTime);
        setDays(duration.asDays().toFixed());
        setHours(duration.hours());
        setMinutes(duration.minutes());
        setSeconds(duration.seconds());
      }
    }, 1000);
    return () => {
      clearInterval(intervel);
    };
  }, []);
  return (
    timerShow && <div className="timer-container">
      <div className="timer">
        <div>Starts in</div>
        <div className="date">
          <div className="a">
            <span className="time-number">{days}</span>
            <span className="time-text">Days</span>
          </div>
          <span className="time-semicolon">:</span>
          <div className="a">
            <span className="time-number">{hours}</span>
            <span className="time-text">Hours</span>
          </div>
          <div className="time-semicolon">:</div>
          <div className="a">
            <span className="time-number">{minutes}</span>
            <span className="time-text">Minutes</span>
          </div>
          <div className="time-semicolon">:</div>
          <div className="a">
            <span className="time-number">{seconds}</span>
            <span className="time-text">Seconds</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Timer;
