import keyMirror from "key-mirror";

export default keyMirror({
  APP_INIT: null,

  USER_FETCH: null,
  USER_SET: null,
  USER_BY_ID_FETCH: null,
  USER_BY_ID_SET: null,
  USER_EDIT_FETCH: null,
  USER_EDIT_SET: null,
  USER_SEARCH_FETCH: null,
  USER_SEARCH_SET: null,
  USER_LOGIN: null,
  USER_LOGOUT: null,
  USER_REG: null,
  USER_SET_DATA: null,
  ACCESSED: null,
  LOGOUT: null,
  REG: null,
  SOCIAL_ACCOUNT: null,
  VERIFICATED_PHONE: null,
  PHONE_FETCH: null,
  USER_PHONE_PUT: null,
  PHONE_SEND: null,
  USER_PHONE_GETCODE: null,
  USER_PHONE_CHECKCODE: null,
  PHONE_CODE_SEND: null,
  USER_EMAIL_CODE_FETCH: null,
  USER_EMAIL_CODE_SET: null,
  USER_PASSRESET: null,
  USER_PASSRESET_CODE: null,
  USER_PASSRESET_STATUS: null,
  USER_PASSWORD_UPDATE: null,
  USER_PASSWORD_UPDATE_COMPLETE: null,
  USER_PASSWORD_UPDATE_FAILED: null,
  PASS_CHANGE_FETCH: null,
  PASS_RESPONSE: null,
  USER_SUCCESS_RESET: null,
  USER_FAILED_RESET: null,
  PROTEST_REPLY: null,
  RATE_USER_POST: null,
  CHECK_LOGIN_STRING: null,
  SET_LOGIN_CHECK_RESULT: null,
  FIRST_ENTER: null,
  SET_SESSION_EVENT: null,

  ACCOUNT_TOURNAMENTS_FETCH: null,
  ACCOUNT_TOURNAMENTS_SET: null,
  TOURNAMENTS_FETCH: null,
  TOURNAMENTS_FETCHING: null,
  TOURNAMENTS_SET: null,
  TOURNAMENTS_BATTLE_TAKEPART: null,
  TOURNAMENTS_BATTLE_LOADING: null,
  TOURNAMETS_SET_PLAYERS: null,
  TOURNAMENTS_CREATE_BATTLES: null,
  TOURNAMENTS_CREATE_BATTLES_FETCH: null,
  SEND_INVITE_TOURNAMENT_NOTIFICATION: null,
  SEND_INVITE_TOURNAMENT_NOTIFICATION_FETCHED: null,

  BATTLES_FETCH: null,
  ACCOUNT_BATTLES_FETCH: null,
  SET_ACCOUNT_BATTLES: null,
  BATTLES_SET: null,
  BATTLES_RESPONSE_TO_USER_SET: null,
  BATTLES_RESPONSE_TO_USER_FETCH: null,
  BATTLES_RESPONSE_TO_OWNER_SET: null,
  BATTLES_RESPONSE_TO_OWNER_FETCH: null,
  BATTLES_BATTLE_CANCEL_SET: null,
  BATTLES_BATTLE_CANCEL_FETCH: null,
  BATTLES_BATTLE_INVITE_SET: null,
  BATTLES_BATTLE_INVITE_FETCH: null,
  MYBATTLES_SET: null,

  LANG_TRANSLATION_SET: null,
  LANG_SET: null,
  LANG_SET_ALL: null,

  SOCKET_EMIT: null,
  SOCKET_DISCONNECT: null,
  SOCKET_EVENT: null,
  SOCKET_EVENT_GAME_INVITE: null,
  SOCKET_EVENT_GAME_CANCEL: null,
  SOCKET_EVENT_GAME_START: null,
  SOCKET_EVENT_GAME_CANCEL_FETCH: null,
  SOCKET_EVENT_GAME_CONFIRM_FETCH: null,
  SOCKET_EVENT_SOCKET_CONNECTED: null,
  SOCKET_EVENT_GAME_END: null,
  SOCKET_EVENT_GAME_ENDING: null,
  SOCKET_EVENT_GAME_DRAW: null,
  SOCKET_EVENT_GAME_STATUS: null,
  SOCKET_EVENT_NOTIFICATION: null,
  SOCKET_EVENT_NEW_BATTLES: null,
  SOCKET_EVENT_GAME_NEXT: null,
  GAME_EXIT: null,
  GAME_LEAVE: null,
  GAME_ACCEPT: null,
  SEND_PROTEST: null,
  PROTEST_CANCEL: null,
  REG_ERROR_CLEAN: null,
  USER_ERROR_CLEAR: null,
  REG_LOGIN_ERROR: null,
  REG_EMAIL_ERROR: null,
  REG_CODE_ERROR: null,
  REG_EMAIL_SEND: null,
  EVENT_SHOW_DIALOG: null,
  EVENT_CLOSE_DIALOG: null,
  GEODATA_FETCH: null,
  USERGEODATA_FETCH: null,
  DEFAULTTIMEZONE_FETCH: null,
  DEFAULTTIMEZONE_SET: null,
  GEODATA_CITIES_FETCH: null,
  GEODATA_TIMEZONES_FETCH: null,
  GEODATA_TIMEZONES_SET: null,
  TRANSLATIONS_FETCH: null,
  CHANGE_LANG: null,

  SET_VIDEO_ARCHIVES: null,
  SET_VIDEO_ARCHIVES_ALL: null, // set archives for all streams
  FETCH_VIDEO_ARCHIVES_ALL: null, // fetch archives for all streams of battle by one request
  FETCH_VIDEO_ARCHIVES: null,
  FETCH_VIDEO_FILES: null,
  SET_VIDEO_FILES: null,
  CLEAR_VIDEO_FILES: null,
  BIND_VIDEO_FILES: null,
  UNBIND_VIDEO_FILES: null,
  VIDEO_FILES_BIND_ERROR: null,
  UPDATE_USER_DISK: null,
  FETCH_USER_DISK: null,
  SET_USER_DISK: null,
  CHAT_SOCKET_AUTH: null,

  FETCH_CHAT_ROOMS: null,
  FETCH_CHAT_ROOM: null,
  SET_CHAT_ROOMS: null,
  SET_CHAT_ROOM: null,
  SET_CUR_ROOM: null,
  UPDATE_MESSAGES_SEEN: null,
  SET_MESSAGES_SEEN: null,
  SET_MESSAGES_TOTAL: null,
  SET_MESSAGES_LAST: null,
  CREATE_PRIVATE_ROOM: null,
  CREATE_SUPPORT_ROOM: null,
  CHAT_ROOM_ERROR: null,

  UPDATE_USER_DATA: null,

  SOCKET_EVENT_SET_PAUSE: null,
  SOCKET_EVENT_RAISE_PAUSE: null,
  SOCKET_YELLOW_CARD: null,
  SOCKET_EVENT_FAILED_PAUSE: null,
  TOURNAMENTS_CREATE_TOURNAMENT_FETCH: null,
  TOURNAMENTS_UPDATE_INFO_FETCH: null,
  TOURNAMENTS_DELETE_FETCH: null,
  TOURNAMENTS_CREATE_TOURNAMENT_SET: null,
  TOURNAMENTS_UPDATE_INFO_SET: null,
  TOURNAMENTS_DELETE_SET: null,
  WATCH_TOURNAMENT: null,
  TOURNAMENTINFO_FETCH: null,
  TOURNAMENTINFO_SET: null,

  ADD_USER_TO_TOURNAMENT_FETCH: null,
  DELETE_USER_FROM_TOURNAMENT_FETCH: null,
  UPDATE_TOURNAMENT_PLAYERS: null,
  CREATE_BATTLE_IN_TOURNAMENT: null,

  SET_COOKIES: null,
  SET_COOKIES_FETCH: null,
  CLEAR_COOKIES: null,
  CLEAR_COOKIES_FETCH: null,
  SET_COOKIE_DATA: null,
  CLEAR_COOKIE_DATA: null,

  KEYBOARD_DID_SHOW: null,
  KEYBOARD_DID_HIDE: null,

  GET_VIDEO_ARCHIVE: null,
  SET_VIDEO_ARCHIVE: null,
  SET_VIDEO_ARCHIVE_SQUAD_ID: null,
  UNSET_VIDEO_ARCHIVE_SQUAD_ID: null,

  PAYMENTDATA_FETCH: null,
  PAYMENTDATA_SET: null,
  PAYMENTTOKEN_FETCH: null,
  PAYMENTTOKEN_SET: null,
  USERCARDS_FETCH: null,
  USERCARDS_SET: null,
  USERCARDS_DEL: null,
  IBAN_SAVE: null,
  IBAN_DEL: null,
  CREATE_IBAN_PAYMENT: null,

  CANCEL_TOURNAMENT_BATTLE: null,
  FETCH_PROTEST_LIST: null,
  SET_PROTEST_LIST: null,
  REFEREE_CONSIDER: null,
  REFEREE_REFUSE: null,
  FETCH_PROTEST_LIST_OF_PARTY: null,
  SET_PROTEST_LIST_OF_PARTY: null,
  GET_PROTEST: null,
  SET_PROTEST: null,
  ACCEPT_PROTEST: null,
  REJECT_PROTEST: null,
  GET_PROTOCOL: null,
  SET_PROTOCOL: null,
  MAKE_RESOLVE: null,
  SET_PROTOCOL_SQUAD_ID: null,
  UNSET_PROTOCOL_SQUAD_ID: null,

  ADD_MODAL: null,
  REMOVE_MODAL: null,
  CLOSE_MODAL: null,
  CHECK_ACTIVE_MODAL: null,
  CHECK_NEXT_MODAL: null,

  OPEN_MODAL: null,
  AGREEMENTS_FETCH: null,

  SET_REDIRECT: null,

  BOWLING_CENTER_SEND_REQUEST: null,
  BOWLING_CENTER_AFTER_SEND_REQUEST: null,

  GET_PERMISSIONS: null,
  SET_PERMISSIONS: null,

  GET_BOWLINGCENTER_PERMISSIONS: null,
  SET_BOWLINGCENTER_PERMISSIONS: null,

  SET_RELOGIN: null,
  SOCKET_FORCE_CONNECT: null,

  SEND_AUTHORIZATION_CODE: null,
  SEND_VERIFICATION_CODE: null,

  GET_USER_SETTINGS: null,
  SET_USER_SETTINGS: null,
  SET_USER_SETTING_VALUE: null,
  SET_USER_SETTING_CHILD_VALUE: null,

  FETCH_WALLET_HISTORY: null,
  SET_WALLET_HISTORY: null,

  GET_TARIFF_MY: null,
  SET_TARIFF_MY: null,
  GET_TARIFFS: null,
  SET_TARIFFS: null,
  DROP_TARIFF: null,
  SWITCH_TARIFF_RENEWAL: null,

  GET_COMMISSION: null,
  SET_COMMISSION: null,

  SET_TARIFF_CALLBACK: null,

  SUPPORT_SEND_MESSAGE: null,
  SUPPORT_SET_MESSAGE: null,

  BATTLES_SET_CREATE_BATTLE_QUERY: null,

  FETCH_TIMESTAMP: null,
  SET_TIMESTAMP: null,
  CLEAN_TIMESTAMP_ERROR: null,
  SET_CODE_ERROR: null,
  CLOSE_CODE_ERROR: null,

  ACCEPTING_GAME_START: null,
  SET_GAME_START_ACCEPTING_ERROR: null,
  SET_GAME_START_ACCEPTING_COMPLETE: null,
  SET_GAME_START_NON_PLATFORM_ACCEPTING_COMPLETE: null,

  SET_HTTP_SERVER_STATUS: null,
  GET_HTTP_SERVER_STATUS: null,

  SHOW_NOFUND: null,
  HIDE_NOFUND: null,

  FETCH_GET_COOKIE_STATE: null,
  FETCH_COOKIE_STATE: null,
  SET_COOKIE_STATE: null,

  FETCH_USERSTATS: null,
  SET_USERSTATS: null,

  SET_COOKIE_AGREEMENT: null,

  SEND_FIREBASE_TOKEN: null,
  STOP_HTTP_SERVER_MONITOR: null,

  GET_BILLING_LOANS: null,
  SET_BILLING_LOANS: null,

  OPPONENT_GAME_REFUSE: null,

  SET_OBSERVABLE_SQUAD: null,
  SET_OBSERVABLE_TOURNAMENT: null,
  FETCH_JOIN_AS_OBSERVER: null,
  FETCH_EXIT_OBSERVER: null,
  SET_JOIN_AS_OBSERVER: null,
  OBSERVER_UPDATE_FRAME: null,

  FETCH_JOIN_AS_PLAYER: null,
  SET_JOIN_AS_PLAYER: null,
  SET_SQUAD_STATE: null,

  SEND_APPLEID: null,
  EDIT_TOURNAMENT_BATTLE: null,

  SQUAD_LIST_SET: null,
  SQUAD_LIST_FETCH: null,
  SET_SQUAD_WATCHER_COUNT: null,

  SOCIAL_EMAIL_CODE_FETCH: null, // Отправить код для подтверждения email
  CHECK_SOCIAL_EMAIL_CODE_FETCH: null, // Сравнить код подтверждения email
  EMAIL_CODE_CHECK: null, // Прошла ли проверка сравнения кода подтверждения email
  CONFIRM_EMAIL: null, // Нужно потверждение email (открывает /getemail)

  ACCOUNT_BATTLES_HISTORY_SET: null,
  ACCOUNT_BATTLES_HISTORY_FETCH: null,
  SET_RED_CARD: null,
  SET_RED_CARD_SEEN: null,

  SQUAD_GETINFO_FETCH: null,
  SQUAD_GETINFO_SET: null,

  RECORDING_PROCESS: null,
  ADD_BATTLE_TO_TOURNAMENT: null,
  CANCEL_BATTLE_TOURNAMENT: null,
  SQUAD_GETINFO_CLEAR: null,

  ADMIN_BATTLELIST_FILTERS_SAVE: null,

  SYSTEM_SQUAD_CHAT: null, // Системные сообщения в чате
  TOURNAMENT_INFO_FOR_EDIT_FETCH: null,
  TOURNAMENT_INFO_FOR_EDIT_SET: null,
  DELETE_GAME_TOURNAMENT: null,
  MOBILE_VERSION_FETCH: null,

  SET_GAME_CANCEL_SOCKET: null, // Установка значения event.game.gameCancelSocket
  USER_EMAIL_CODE_ERROR: null,
  FETCH_TOURNAMENT_TYPE_LIST: null, // получение списка типом турина
  SET_TOURNAMENT_TYPE_LIST: null, // установка списка типо турнира
  SET_BATTLE_FOR_DELETE: null,
  SET_SHOW_HELP_TIMEOUT_MODAL: null,
  LAST_FRAME_UPDATED: null,

  CLEAR_ACCOUNT_BATTLES: null, // Очищаем список accountbattles (Мои баттлы)
  CHANGE_HELP_STATUS: null,
  FETCH_HELP_STATUS: null,
  SET_HELP_STATUS: null,
  TOURNAMNET_BIND_BC_FETCH: null,
  TOURNAMNET_UNBIND_BC_FETCH: null,
  TOURNAMNET_BIND_END: null,
  TOURNAMNET_UNBIND_END: null,
  SET_COUNT_USERS_REGISTERED: null,
  GET_COUNT_USERS_REGISTERED: null,

  GET_HANDICAP_LIST: null, // Список стандартных гандикапов
  SET_HANDICAP_LIST: null,

  CREATE_PROTEST_ARCHIVE: null,
  CANCEL_PROTEST_ARCHIVE: null,
  REPLY_PROTEST_ARCHIVE: null,

  GET_VIDEO_ARCHIVE_PROTESTS: null,
  SET_VIDEO_ARCHIVE_PROTESTS: null,

  GET_PROTEST_COUNT: null,
  SET_PROTEST_COUNT: null,

  GET_PAYPAL_ORDER: null,
  SET_PAYPAL_ORDER: null,
  CLEAR_PAYPAL_ORDER: null,
  SET_PAYPAL_ERROR: null,

  CONNECT_TO_SOCKET: null,
  TOURNAMENT_GAME_ENDED: null,

  CREATE_QUICK_GAME: null,
  CREATE_OPEN_CHALLENGE: null,
  UPDATE_QUICK_GAME_TO_OPEN_CHALLENGE: null,
  ADD_TOURNAMENT_SETTING: null,
  SET_TOURNAMENT_SETTING: null,
  DELETE_TOURNAMENT_SETTING_FETCH: null,
  DELETE_TOURNAMENT_SETTING_SET: null,
  SET_TOURNAMENT_PLAYER_RESULT: null,
  REFRESH_PROTOCOL: null,
  SET_USER_TYPE: null,
  SAVE_HANDICAP_LIST: null,

  SET_MODAL_DEBETING_MONEY_FOR_PARTICIPATION: null,
  CLEAR_MODAL_DEBETING_MONEY_FOR_PARTICIPATION: null,
  FETCH_UPLOAD_APK: null,
  SET_UPLOAD_APK: null,
  ADD_REPLAY_PLAYER: null,
  SET_PLAYER_REPLAY: null,

  CONFIRM_TOURNAMENT_RESULTS: null,
  CREATE_LEADERBOARD_TABLE_STAGE: null,
  DELETE_LEADERBOARD_TABLE_STAGE: null,
  SAVE_LEADERBOARD_TABLE_STAGE: null,
  SET_LEADERBOARD_TABLE_STAGES: null,

  SHOW_HELPMODAL: null,
  HIDE_HELPMODAL: null,
  SHOW_FILTERMODAL: null,
  HIDE_FILTERMODAL: null,

  OPENCHALLENGES_FETCH: null,
  OPENCHALLENGES_SET: null,

  TOURNAMENTS_BATTLE_TAKEPART_FINISH: null,
  SET_TIMER_MODAL: null,

  REFRESH_TOURNAMENT_INFO: null,

  CHANGE_KEYBOARD_TYPE: null,
  QUEUE_POSITION_UPDATED: null,
  PASS_USER_TO_WATCH: null,
  END_GAME_KICK_OBSERVER: null,
  PLAYERS_CONNECTED: null,
  UPLOAD_CARD_DECORATION_FETCH: null,
  UPLOAD_CARD_DECORATION: null,
  IN_BATTLES: null,

  GET_ACTIVE_USERS: null,
  SET_ACTIVE_USERS: null,

  GET_MAX_WATCHER_COUNT: null,
  SET_MAX_WATCHER_COUNT: null,
  SAVE_MAX_WATCHER_COUNT: null,

  GET_LEADER_BOARD: null,
  SET_LEADER_BOARD: null,

  CLOSED_OPENCHALLENGES_FETCH: null,
  CLOSED_OPENCHALLENGES_FETCHED: null,
  GET_FLUSSONIC_API: null,
  SET_FLUSSONIC_API: null,

  SET_VISIBILITY_BATTLE: null,
  SET_VISIBILITY_BATTLE_FETCHED: null,

  ACTIVE_SQUADS_COUNT_SET: null,
  ACTIVE_SQUADS_COUNT_GET: null,

  UPDATE_GAME_COUNT_FETCH: null,
  UPDATE_GAME_COUNT_FETCHED: null,

  GET_STATISTIC_MAIN: null,
  GET_STATISTIC_AVERAGE: null,
  GET_STATISTIC_HIGH: null,
  GET_STATISTIC_CLEAN: null,
  GET_STATISTIC_SERIES: null,

  SET_STATISTIC_MAIN: null,
  SET_STATISTIC_AVERAGE: null,
  SET_STATISTIC_HIGH: null,
  SET_STATISTIC_CLEAN: null,
  SET_STATISTIC_SERIES: null,

  SET_STATISTIC_OPTIONS: null,

  SET_FILTERED: null,
  RESET_FILTERED: null,

  CHECK_EXPECTED_GAMES: null, // Проверка на наличие отложенных игр
  CHECKED_EXPECTED_GAMES: null,

  CLEAR_EXPECTED_GAMES: null,

  GET_SQUAD_ID_BY_USER_ID: null,
  GET_SQUAD_ID_BY_USER_ID_FETCHED: null,
  REMOVE_SQUAD_ID_BY_USER_ID: null,
  REMOVE_PLAYER_REMOVED_INFO: null,
  POST_REMOVED_PLAYER_INFO: null,

  GET_BC_NOT_PLATFORM: null,
  SET_BC_NOT_PLATFORM_FETCHED: null,
  CLEAR_EVENT_SQUAD_DATA: null,
  CREATE_GAME_NOT_PLATFORM: null,

  // включение\выключение ссылки для трансляции
  TOGGLE_STREAM_FETCH: null,
  TOGGLE_STREAM_FETCHED: null,

  SET_DISABLE_SHOW_MODAL_SEND_APPLICATION: null,

  GET_STATISTIC_FRAMES_MAIN: null,
  GET_STATISTIC_FRAMES_FIRST: null,
  GET_STATISTIC_FRAMES_AVERAGE: null,
  GET_STATISTIC_FRAMES_STRIKES: null,
  GET_STATISTIC_FRAMES_SPLITS: null,

  SET_STATISTIC_FRAMES_MAIN: null,
  SET_STATISTIC_FRAMES_FIRST: null,
  SET_STATISTIC_FRAMES_AVERAGE: null,
  SET_STATISTIC_FRAMES_STRIKES: null,
  SET_STATISTIC_FRAMES_SPLITS: null,

  GET_REFERRAL_LINK: null,
  SET_REFERRAL_LINK: null,

  SET_NEW_USER: null,
  GET_SUBSCRIBERS: null,
  SET_SUBSCRIBERS: null,
  SUBSCRIBERS_CHANGE_PAGE: null,
  DROP_SUBSCRIBERS: null,
  SUBSCRIBERS_CHANGE_PARAMS: null,
  SUBSCRIBERS_CHANGE_TARIF_FETCH: null,
});
