import React, { Component } from 'react';
import PropTypes from 'prop-types';
import socketio from 'socket.io-client';
import { connect } from 'react-redux';
import ChatVC from './ChatVC';
import config from '../../config';

class ChatSocket {
  constructor(url, query) {
    this.socket = null;
    this.connected = false;
    this.url = url;
    this.query = query;
  }

  onConnect = () => {
    this.connected = true;
  };

  onDisconnect = () => {
    this.connected = false;
  };

  reconnect = () => {
    const { connected, socket } = this;
    if (socket && connected) {
      socket.off('connect', this.onConnect);
      socket.off('disconnect', this.onDisconnect);
    }
    this.connect();
  };

  connect = () => {
    const { query } = this;
    this.socket = socketio.connect(
      this.url,
      { query, rejectUnauthorized: false, transports: ['websocket'] },
    );
    this.socket.on('connect', this.onConnect);
    this.socket.on('disconnect', this.onDisconnect);
  };

  disconnect = () => {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
      this.connected = false;
    }
  };

  getSocket = () => this.socket;
}

class ChatModel extends Component {
  static propTypes = {
    userid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    sessionkey: PropTypes.string.isRequired,
    room: PropTypes.string,
    historyMaxSize: PropTypes.number,
  };

  static defaultProps = {
    historyMaxSize: 20,
  };

  static defaultState = {
    room: null,
    isAdmin: false,
    whitelistOnly: false,
    historyMaxSize: 0,
    historySize: 0,
    historyTotal: 0,
  };

  constructor(props) {
    super(props);
    const { userid, sessionkey, room = 'default' } = props;
    const url = config.FETCH.chatUrl;
    const query = `userid=${userid}&token=${sessionkey}`;
    this.userid = userid;
    this.chatSocket = new ChatSocket(url, query);
    Object.defineProperty(this, 'socket', {
      /* eslint-disable-next-line */
      get: function() {
        return this.chatSocket.getSocket();
      },
      enumerable: true,
    });

    this.state = {
      room,
      historyMaxSize: 0,
      historySize: 0,
      historyTotal: 0,
      isAdmin: false,
      whitelistOnly: false,
      owner: null,
      messages: [],
      users: [],
      lists: [],
    };
  }

  componentDidMount() {
    this.chatSocket.connect();
    this.socket.on('loginConfirmed', (userId) => {
      this.setState({ authorized: true, authError: null, userId: +userId });
      console.log('loginConfirmed, user', userId);
    });
    this.socket.on('loginRejected', (error) => {
      this.setState({ authorized: false, authError: error });
      console.error('loginRejected', error);
    });
    this.socket.on('roomMessage', (room, msg) => {
      console.log(`roomMessage from room (name = ${room})`);
      const { messages: msgs } = this.state;
      const messages = [...msgs];
      messages.push(msg);
      this.setState({ messages });
    });
    const { room } = this.state;
    console.log('componentDidMount.room', room);
    this.roomJoin(room);
  }

  componentWillUnmount() {
    this.chatSocket.disconnect();
  }

  roomGetInfo = () => {
    this.roomGetWhitelistMode();
    this.roomHistoryInfo()
      .then(() => {
        const { historySize } = this.state;
        this.roomHistoryGet(0, historySize);
      })
      .catch(() => {});
    this.roomGetUsers();
    this.roomGetOwner();
  };

  clear = (room = null, cb = () => {}) => {
    this.setState(
      {
        room,
        historyMaxSize: 0,
        historySize: 0,
        historyTotal: 0,
        messages: [],
        users: [],
        isAdmin: false,
        whitelistOnly: false,
        owner: null,
      },
      cb,
    );
  };

  onAddToList = (error, data) => {
    if (error) {
      console.log('onAddToList.error', error);
      return;
    }
    console.log('onAddToList.data', data);
  };

  onRemoveFromList = (error, data) => {
    if (error) {
      console.log('onRemoveFromList.error', error);
      return;
    }
    console.log('onRemoveToList.data', data);
  };

  onMessageSend = (error, data) => {
    if (error) {
      console.log('onMessageSend.error', error);
      return;
    }
    console.log('onMessageSend.data', data);
  };

  onWhitelistMode = (error, data) => {
    if (error) {
      console.log('onWhitelistMode.error', error);
      return;
    }
    console.log('onWhitelistMode.data', data);
    this.setState({ whitelistOnly: data });
  };

  onHistoryGet = (error, data) => {
    if (error) {
      console.log('onHistoryGet.error', error);
      return;
    }
    const messages = data.reverse();
    this.setState({ messages });
  };

  onHistoryInfo = (error, data) => {
    if (error) {
      console.log('onHistoryInfo.error', error);
      return;
    }
    const { historyMaxSize, historySize, lastMessageId: historyTotal } = data;
    this.setState({ historyMaxSize, historySize, historyTotal });
  };

  onLeave = (error, data) => {
    if (error) {
      console.log('onLeave.error', error);
      return;
    }
    console.log('onLeave.data', data);
    this.clear();
  };

  roomAddToList = async (userId, listName) => {
    const { room } = this.state;
    this.socket.emit('roomAddToList', room, listName, [`${userId}`], this.onAddToList);
  };

  roomRemoveFromList = (userId, listName) => {
    const { room } = this.state;
    this.socket.emit('roomRemoveFromList', room, listName, [`${userId}`], this.onRemoveFromList);
  };

  roomMessage = (msg) => {
    const { room } = this.state;
    this.socket.emit('roomMessage', room, msg, this.onMessageSend);
  };

  roomSetWhitelistMode = (mode) => {
    const { room } = this.state;
    this.socket.emit('roomSetWhitelistMode', room, mode, (error) => {
      if (error) {
        console.log('roomSetWhitelistMode.error', error);
        return;
      }
      this.setState({ whitelistOnly: mode });
    });
  };

  roomGetWhitelistMode = () => {
    const { room } = this.state;
    this.socket.emit('roomGetWhitelistMode', room, this.onWhitelistMode);
  };

  roomHistoryGet = (start, end) => {
    const { room } = this.state;
    this.socket.emit('roomHistoryGet', room, +start, +end, this.onHistoryGet);
  };

  roomHistoryInfo = () => {
    const { room } = this.state;
    return new Promise((resolve, reject) => {
      this.socket.emit('roomHistoryInfo', room, (error, ...args) => {
        this.onHistoryInfo(error, ...args);
        return error ? reject(error) : resolve();
      });
    });
  };

  _roomJoin = room => new Promise((resolve, reject) => {
    this.socket.emit('roomJoin', room, (error, data) => {
      if (error) {
        console.log('onJoin.error', error);
        return reject(error);
      }
      console.log('onJoin.data', data);
      return this.clear(room, resolve);
    });
  });

  roomJoin = room => this._roomJoin(room)
    .then(this.roomGetInfo)
    .catch(() => {});

  roomLeave = () => {
    const { room } = this.state;
    this.socket.emit('roomLeave', room, this.onLeave);
  };

  roomCreate = (room, mode = true) => {
    this.socket.emit('roomCreate', room, mode, (error, data) => {
      if (error) {
        console.log('onCreate.error', error);
        return;
      }
      console.log('onCreate.data', data);
      this.setState({});
    });
  };

  roomGetLists = (lists) => {
    this.setState({ lists });
  };

  onGetOwner = (error, data) => {
    if (error) {
      console.log('onGetOwner.error', error);
      return;
    }
    console.log('onGetOwner.data', data);
    const { isAdmin } = this.state;
    console.log('isAdmin', isAdmin, { userid: this.userid, data });
    this.setState({ owner: { userId: data }, isAdmin: isAdmin || +data === +this.userid });
  };

  roomGetOwner = () => {
    const { room } = this.state;
    this.socket.emit('roomGetOwner', room, this.onGetOwner);
  };

  roomGetAccessList = (room, listName) => new Promise((resolve, reject) => {
    this.socket.emit('roomGetAccessList', room, listName, (error, data) => {
      if (error) {
        console.log('roomGetAccessList.error', error);
        return reject(error);
      }
      const retval = [];
      for (const userId of data) {
        retval.push({ userId, listName });
      }
      console.log('roomGetAccessList:', listName, data, this.userid);
      return resolve(retval);
      /*
      const { users } = this.state;
      const curUsers = [...users];
      const usersMap = {};
      for (const usr of curUsers) {
        usersMap[usr.userId] = usr;
      }
      for (const userId of data) {
        const usr = usersMap[userId];
        if (usr) {
          usr.listName = listName;
        } else {
          curUsers.push({
            userId,
            listName,
          });
        }
      }
      return this.setState({ users: curUsers }, resolve);
      */
    });
  });

  roomGetUsers = async () => {
    const { room, lists } = this.state;
    const promises = [];
    for (const listName of lists) {
      promises.push(this.roomGetAccessList(room, listName));
    }
    const usersList = await Promise.all(promises);
    const usersMap = {};
    for (const users of usersList) {
      for (const user of users) {
        const { userId } = user;
        if (!usersMap[userId] || usersMap[userId].listName === 'userlist') {
          usersMap[user.userId] = user;
        }
      }
    }
    const users = [];
    for (const userId of Object.keys(usersMap)) {
      users.push(usersMap[userId]);
    }
    const { owner } = this.state;
    const isAdmin = (owner ? +owner.userId === +this.userid : false) || users.some(u => +u.userId === +this.userid && u.listName === 'adminlist');
    console.log('isAdmin: ', isAdmin, users, { currentUserId: this.userid });
    this.setState({ users, isAdmin });
    for (const user of users) {
      this.roomUserSeen(user.userId);
    }
  };

  roomUserSeen = (userId) => {
    const { room } = this.state;
    this.socket.emit('roomUserSeen', room, `${userId}`, (error, data) => {
      if (error) {
        console.log('roomUserSeen.error', error);
        return;
      }
      const { users } = this.state;
      const nextUsers = users.map((u) => {
        if (+u.userId === +userId) {
          const { timestamp } = data;
          return { ...u, timestamp };
        }
        return { ...u };
      });
      this.setState({ users: nextUsers });
    });
  };

  initRoom = async (room) => {
    const { room: curRoom } = this.state;
    let s = Promise.resolve();
    if (curRoom !== room) {
      s = new Promise(resolve => this.setState({ room }, resolve));
    }
    await s;
    this.socket.emit('roomHistoryInfo', room, (error, data) => {
      this.onHistoryInfo(error, data);
      if (error) {
        return;
      }
      const { lastMessageId: historyTotal } = data;
      const { historyMaxSize } = this.props;
      const start = Math.max(0, historyTotal - historyMaxSize);
      this.roomHistoryGet(start, historyTotal);
      this.roomGetUsers();
    });
  };

  render() {
    const {
      room,
      owner = null,
      whitelistOnly,
      historyMaxSize,
      historySize,
      historyTotal,
      messages,
      users,
      isAdmin = false,
    } = this.state;
    return (
      <div className='chatVC'>
        <ChatVC
          room={room}
          owner={owner}
          isAdmin={isAdmin}
          whitelistOnly={whitelistOnly}
          historyMaxSize={historyMaxSize}
          historySize={historySize}
          historyTotal={historyTotal}
          messages={messages}
          users={users}
          roomMessage={this.roomMessage}
          roomAddToList={this.roomAddToList}
          roomRemoveFromList={this.roomRemoveFromList}
          roomSetWhitelistMode={this.roomSetWhitelistMode}
          roomGetWhitelistMode={this.roomGetWhitelistMode}
          roomHistoryGet={this.roomHistoryGet}
          roomHistoryInfo={this.roomHistoryInfo}
          roomGetUsers={this.roomGetUsers}
          roomJoin={this.roomJoin}
          roomLeave={this.roomLeave}
          roomCreate={this.roomCreate}
          roomGetLists={this.roomGetLists}
        />
      </div>
    );
  }
}

class Chat extends ChatModel {
  render() {
    const { user } = this.props;
    const userid = user ? user.id : 1;
    const sessionkey = user && user.session ? user.session.sessionkey : 'token';
    return <ChatModel userid={userid} sessionkey={sessionkey} room="default" historyMaxSize={20} />;
  }
}

export default connect(
  state => ({
    user: state.users.user,
  }),
  {},
)(Chat);
