import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Spin, Icon } from 'antd';
import icons from './icons';
import './style.css';

const antIcon = <Icon type="loading" style={{ fontSize: 14 }} spin />;

export default class SvgButton extends Component {
  static propTypes = {
    className: PropTypes.string,
    icon: PropTypes.string.isRequired,
  }

  render() {
    const {
      className,
      icon,
      disabled,
      loading,
      active,
    } = this.props;
    let buttonClassName = className ? `${className} svg-button` : 'svg-button';
    const SvgIcon = icons[icon] ? icons[icon] : 'span';

    buttonClassName += (active) ? ' active' : '';

    return (
      <button
        { ...this.props }
        className={buttonClassName}
        disabled={disabled || loading}
      >
        {loading 
          ? (
            <div>
              <Spin indicator={antIcon} />
            </div>
          )
          : (
            <SvgIcon />
          )
        }
      </button>
    );
  }
}
