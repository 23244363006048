const {
  GET_STATISTIC_MAIN,
  GET_STATISTIC_AVERAGE,
  GET_STATISTIC_HIGH,
  GET_STATISTIC_CLEAN,
  GET_STATISTIC_SERIES,
  SET_STATISTIC_MAIN,
  SET_STATISTIC_AVERAGE,
  SET_STATISTIC_HIGH,
  SET_STATISTIC_CLEAN,
  SET_STATISTIC_SERIES,
  SET_FILTERED,
  RESET_FILTERED,
  SET_STATISTIC_FRAMES_MAIN,
  SET_STATISTIC_FRAMES_FIRST,
  SET_STATISTIC_FRAMES_AVERAGE,
  SET_STATISTIC_FRAMES_STRIKES,
  SET_STATISTIC_FRAMES_SPLITS,
  GET_STATISTIC_FRAMES_MAIN,
  GET_STATISTIC_FRAMES_FIRST,
  GET_STATISTIC_FRAMES_AVERAGE,
  GET_STATISTIC_FRAMES_STRIKES,
  GET_STATISTIC_FRAMES_SPLITS,
} = require('./actiontypes').default;


export const initialState = {
  gameMain: {
    data: {},
    fetching: false
  },
  gameAverage: {
    data: {},
    fetching: false
  },
  gameHigh: {
    data: {},
    fetching: false
  },
  gameClean: {
    data: {},
    fetching: false
  },
  gameSeries: {
    data: {},
    fetching: false
  },
  frameMain: {
    data: {},
    fetching: false
  },
  frameFirst: {
    data: {},
    fetching: false
  },
  frameAverage: {
    data: {},
    fetching: false
  },
  frameStrikes: {
    data: {},
    fetching: false
  },
  frameSplits: {
    data: {},
    fetching: false
  },
  filtered: false
};


export const statisticReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATISTIC_MAIN:
      return {
        ...state,
        gameMain: {...state.gameMain, data: {}, fetching: true}
      };
    case GET_STATISTIC_AVERAGE:
      return {
        ...state,
        gameAverage: {...state.gameAverage, data: {}, fetching: true},
      };
    case GET_STATISTIC_HIGH:
      return {
        ...state,
        gameHigh: {...state.gameHigh, data: {}, fetching: true},
      };
    case GET_STATISTIC_CLEAN:
      return {
        ...state,
        gameClean: {...state.gameClean, data: {}, fetching: true},
      };
    case GET_STATISTIC_SERIES:
      return {
        ...state,
        gameSeries: {...state.gameSeries, data: {}, fetching: true},
      };
    case SET_STATISTIC_MAIN:
      return {
        ...state,
        gameMain: {...state.gameMain, data: action.params, fetching: false}
      };
    case SET_STATISTIC_AVERAGE:
      return {
        ...state,
        gameAverage: {...state.gameAverage, data: action.params, fetching: false},
      };
    case SET_STATISTIC_HIGH:
      return {
        ...state,
        gameHigh: {...state.gameHigh, data: action.params, fetching: false},
      };
    case SET_STATISTIC_CLEAN:
      return {
        ...state,
        gameClean: {...state.gameClean, data: action.params, fetching: false},
      };
    case SET_STATISTIC_SERIES:
      return {
        ...state,
        gameSeries: {...state.gameSeries, data: action.params, fetching: false},
      };
    case GET_STATISTIC_FRAMES_MAIN:
      return {
        ...state,
        frameMain: {...state.frameMain, data: {}, fetching: true}
      };
    case GET_STATISTIC_FRAMES_FIRST:
      return {
        ...state,
        frameFirst: {...state.frameFirst, data: {}, fetching: true}
      };
    case GET_STATISTIC_FRAMES_AVERAGE:
      return {
        ...state,
        frameAverage: {...state.frameAverage, data: {}, fetching: true}
      };
    case GET_STATISTIC_FRAMES_STRIKES:
      return {
        ...state,
        frameStrikes: {...state.frameStrikes, data: {}, fetching: true}
      };
    case GET_STATISTIC_FRAMES_SPLITS:
      return {
        ...state,
        frameSplits: {...state.frameSplits, data: {}, fetching: true}
      };
    case SET_STATISTIC_FRAMES_MAIN:
      return {
        ...state,
        frameMain: {...state.frameMain, data: action.params, fetching: false}
      };
    case SET_STATISTIC_FRAMES_FIRST:
      return {
        ...state,
        frameFirst: {...state.frameFirst, data: action.params, fetching: false}
      };
    case SET_STATISTIC_FRAMES_AVERAGE:
      return {
        ...state,
        frameAverage: {...state.frameAverage, data: action.params, fetching: false}
      };
    case SET_STATISTIC_FRAMES_STRIKES:
      return {
        ...state,
        frameStrikes: {...state.frameStrikes, data: action.params, fetching: false}
      };
    case SET_STATISTIC_FRAMES_SPLITS:
      return {
        ...state,
        frameSplits: {...state.frameSplits, data: action.params, fetching: false}
      };
    case SET_FILTERED:
      return {
        ...state,
        filtered: true
      }
    case RESET_FILTERED:
      return {
        ...state,
        filtered: false
      }
    default:
      return state;
  }
};
