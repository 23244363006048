import React, {useState} from 'react';
import TextTD from "./textTD";
import LoginTD from "./loginTD";
import DateTD from "./dateTD";
import CheckBoxTD from "./checkBoxTD";
import axios from "axios";
import config from "../../../../../config";

const ReferralTR = ({trData, bonusPaidSwitch}) => {
    const {refer, invited} = trData
    const [localChecked, setLocalChecked] = useState(!!trData.is_awarded);

   async function onChangeRewarded() {
        setLocalChecked(!localChecked)
        bonusPaidSwitch(trData.id, !localChecked)
        await axios.post(`${config.FETCH.url}/users/referral_reward`,
            {
                headers: {'X-Requested-With': 'XMLHttpRequest'},
                body: {isRewarded: !localChecked, referralRowId: trData.id}
            })
    }

    return (
        <tr>
            <td>
                <TextTD text={trData.id}/>
            </td>
            <td>
                <TextTD text={`${refer.firstname} ${trData.refer.lastname}`}/>
            </td>
            <td>
                <LoginTD photo={refer.photo} login={trData.refer.login}/>
            </td>

            <td>
                <TextTD text={`${invited.firstname} ${trData.invited.lastname}`}/>
            </td>
            <td>
                <LoginTD photo={invited.photo} login={trData.invited.login}/>
            </td>
            <td>
                <DateTD date={invited.register_date}/>
            </td>
            <td>
                <DateTD date={invited.first_game_date}/>
            </td>
            <td>
                <TextTD text={invited.last_game.bc_name}/>
            </td>
            <td>
                <TextTD text={invited.games_count}/>
            </td>
            <td>
                <CheckBoxTD checked={localChecked} onChange={() => onChangeRewarded()}/>
            </td>
        </tr>
    );
};

export default ReferralTR;
