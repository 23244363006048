const initialState = {
  messages: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLOSE_LOG_MESSAGES':
      return {
        ...state,
        messages: [],
      };
    case 'ADD_LOG_MESSAGE':
      if (Array.isArray(action.payload)) {
        return {
          ...state,
          messages: [...state.messages, ...action.payload],
        };
      } else {
        return {
          ...state,
          messages: [...state.messages, action.payload],
        };
      }
    default:
      return state;
  }
}
