import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { hideNoFundModal } from '../../../../libs/reducers/SocketEventReducer';
import { Button } from '../../Button';

import OBModal from '../../Modal';

class NoFundsModal extends Component {
  static propTypes = {
    intl: intlShape,
  };

  handleClose = () => {
    this.props.markYellowCardAsSeen();
  };

  render() {
    const { intl } = this.props;

    return (
      <OBModal
        title={intl.formatMessage({ id: 'unableToJoinNoFundsTitle', defaultMessage: 'Unable to join the Battle' })}
        visible={this.props.nofundmodal}
        footer={
          <Button
            key="okButton"
            kind="aquamarine"
            closable={false}
            onClick={() => {
              this.props.hideNoFundModal()
            }}
          >
            OK
          </Button>}
        >
        <div style={{ padding: '20px 0' }}>
          {intl.formatMessage({ id: 'unableToJoinNoFundsFull', defaultMessage: 'Insufficient amount of funds for participation in selected Battle, please select another one, or replenish your account.' })}
        </div>
      </OBModal>
    );
  }
}

export default injectIntl(
  connect(
    state => ({
      nofundmodal: state.events.showNofund,
    }),
    {hideNoFundModal},
  )(NoFundsModal),
);
