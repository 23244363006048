import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import Chat from '../../common/Chat';
import TournamentsChatRooms from './tournamentsChatRooms';
import ArrowImg from '-!svg-react-loader!../../../images/arrow-right.svg'; // eslint-disable-line
import { setCurrentRoom } from '../../../libs/reducers/actions';
import config from '../../../config';
import RatingStars from '../../common/RatingStars';

const defaultAvatar = '/static/media/default.2d4c81eb.png';

class TournamentsChat extends Component {
  static propTypes = {
    currentRoom: PropTypes.oneOfType([PropTypes.string, null]),
    userid: PropTypes.number,
    rooms: PropTypes.arrayOf(PropTypes.shape()),
    intl: intlShape,
  };

  renderPrivateHeader = (room) => {
    if (!room) {
      return true;
    }
    const { userid } = this.props;
    const roomUser = room.users.find(u => +u.userId !== +userid);
    if (!roomUser) {
      return true;
    }
    return (
      <div className="header">
        <div className="chat-back-arrow" onClick={() => this.props.setCurrentRoom(null)} >
          <ArrowImg/>
        </div>
        <div className="header__text">
          <div className="user-photo__container">
            <img className="user-photo" src={roomUser.userPhoto ? `${config.FETCH.img}/${roomUser.userPhoto}` : defaultAvatar}/>
            <div className='avgScore'>{(+roomUser.avgscore || 0).toFixed()}</div>
          </div>
          <div className="user-name">
            {roomUser.userName}
            <RatingStars rating={roomUser.rating || 0} width={12} height={12} ratingNumberStyle={{ fontSize: '14px' }} />
          </div>
        </div>
      </div>
    );
  };

  renderSupportHeader = () => {
    const { intl } = this.props;
    return (
      <div className="header">
        <div className="chat-back-arrow" onClick={() => this.props.setCurrentRoom(null)} >
          <ArrowImg/>
        </div>
        <div className="header__text">
          <div className="user-name">{intl.formatMessage({ id: 'Support', defaultMessage: 'Support' })}</div>
        </div>
      </div>
    );
  };

  renderCommonHeader = () => {
    const { intl } = this.props;
    return (
      <div className="header">
        <div className="chat-back-arrow" onClick={() => this.props.setCurrentRoom(null)} >
          <ArrowImg/>
        </div>
        <div className="header__text">
          <div className="user-name">{intl.formatMessage({ id: 'ChatTitle', defaultMessage: 'Chat' })}</div>
        </div>
      </div>
    );
  };

  render() {
    const { currentRoom, userid, rooms } = this.props;
    let withHeader = true;
    if (currentRoom && currentRoom.startsWith('private-room')) {
      withHeader = this.renderPrivateHeader(rooms.find(r => r.room_name === currentRoom));
    } else if (currentRoom && currentRoom.startsWith('support-room')) {
      withHeader = this.renderSupportHeader();
    } else if (currentRoom) {
      withHeader = this.renderCommonHeader();
    }
    return currentRoom ? (
        <Chat room={currentRoom} withHeader={withHeader} containerClass="chat-container non-battle-chat" systemMessages={this.props.systemMessages} />
      ) : (
        <TournamentsChatRooms rooms={rooms} userid={userid} />
    );
  }
}

export default injectIntl(
  connect(
    state => ({
      rooms: state.chat.rooms,
      userid: state.users.user.id,
      currentRoom: state.chat.currentRoom,
      systemMessages: state.chat.systemSquadChat,
    }),
    {
      setCurrentRoom,
    },
  )(TournamentsChat),
);
