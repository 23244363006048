import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const Title = ({ subtitle, children }) => {
  const Subtitle = subtitle ? <span className='subtitle'>{ subtitle }</span> : null;
  return (
    <h1>{ children || 'Title' } { Subtitle }</h1>
  );
};
Title.propTypes = {
  subtitle: PropTypes.string,
  children: PropTypes.string.isRequired,
};

export default Title;
