import actiontypes from "./actiontypes";

export const GET_ALIKE_USER_FETCH = "GET_ALIKE_USER_FETCH";
export const GET_CURRENCY_LIST_FETCH = "GET_CURRENCY_LIST_FETCH";
export const GET_ALIKE_USER_SET = "GET_ALIKE_USER_SET";
export const GET_CURRENCY_LIST_SET = "GET_CURRENCY_LIST_SET";
export const SEND_MANUAL_TRANSACTION = "SEND_MANUAL_TRANSACTION";
export const SET_TRANSACTION_RESPONSE = "SET_TRANSACTION_RESPONSE";
export const GET_TRANSACTION_HISTORY = "GET_TRANSACTION_HISTORY";
export const SET_TRANSACTION_HISTORY = "SET_TRANSACTION_HISTORY";
export const GET_TRANSACTION_DIFF = "GET_TRANSACTION_DIFF";
export const SET_TRANSACTION_DIFF = "SET_TRANSACTION_DIFF";
export const GET_ALL_USERS_FETCH = "GET_ALL_USERS_FETCH";
export const GET_ALL_USERS_SET = "GET_ALL_USERS_SET";
export const EDIT_USER_AS_ADMIN = "EDIT_USER_AS_ADMIN";
export const SET_EDITED_USER = "SET_EDITED_USER";
export const GET_SYSTEM_ACCOUNTS = "GET_SYSTEM_ACCOUNT";
export const SET_SYSTEM_ACCOUNTS = "SET_SYSTEM_ACCOUNTS";
export const REQUEST_TRANSFER = "REQUEST_TRANSFER";
export const GET_BATTLES_LIST = "GET_BATTLES_LIST";
export const SET_BATTLES_LIST = "SET_BATTLES_LIST";
export const SORT_USERS = "SORT_USERS";
export const GET_IBAN_REQUESTS = "GET_IBAN_REQUESTS";
export const SET_IBAN_REQUESTS = "SET_IBAN_REQUESTS";
export const SORT_IBAN = "SORT_IBAN";
export const UPDATE_IBAN = "UPDATE_IBAN";
export const GET_USER_DOCS_ALL = "GET_USER_DOCS_ALL";
export const GET_USER_DOCS_ALL_SET = "GET_USER_DOCS_ALL_SET";
export const GET_USER_DOC_BY_ID = "GET_USER_DOC_BY_ID";
export const GET_USER_DOC_BY_ID_SET = "GET_USER_DOC_BY_ID_SET";
export const SEND_USER_DOC_COMMENT = "SEND_USER_DOC_COMMENT";
export const ACCEPT_DOC_VERIFICATION = "ACCEPT_DOC_VERIFICATION";
export const REFUSE_DOC_VERIFICATION = "REFUSE_DOC_VERIFICATION";
export const SORT_BATTLES = "SORT_BATTLES";

export const REPORT_NOTIFICATION_CLEAR = "REPORT_NOTIFICATION_CLEAR";
export const REPORT_NOTIFICATION_UPDATE = "REPORT_NOTIFICATION_UPDATE"; // Отчет об отправке уведомлений об обновлении
export const SEND_NOTIFICATION_UPDATE = "SEND_NOTIFICATION_UPDATE";
export const SET_SEARCH_USER_TARIFFS = "SET_SEARCH_USER_TARIFFS"; // Получить список пользователей
export const GET_SEARCH_USER_TARIFFS = "GET_SEARCH_USER_TARIFFS"; // Получить список тарифов пользователей
export const PROCESS_SEARCH = "PROCESS_SEARCH"; // Процесс поиска (Общий для всех видов поиска, для экономии переенных 8) )
export const SET_TARIFF_BY_USER = "SET_TARIFF_BY_USER"; // Запись изменений для тарифа пользователя
export const PROCESS_SET_TARIFFS = "PROCESS_SET_TARIFFS"; // Процесс записи

export const ADMIN_BATTLELIST_FILTERS_SAVE = "ADMIN_BATTLELIST_FILTERS_SAVE";
export const SEND_NOTIFICATION_EVENT = "SEND_NOTIFICATION_EVENT"; // Отправка уведомления с событием
export const SET_NOTIFICATION_EVENT = "SET_NOTIFICATION_EVENT";
export const UPLOAD_MAIN_BANNERS = "UPLOAD_MAIN_BANNERS"; // Загрузка картинок
export const RESULT_MAIN_BANNERS = "RESULT_MAIN_BANNERS"; // Установка списка баннеров
export const GET_ADMIN_DISK_SIZE = "GET_ADMIN_DISK_SIZE"; // Объем, сохраненых админом, видеозаписей
export const SET_ADMIN_DISK_SIZE = "SET_ADMIN_DISK_SIZE";
export const UPLOAD_POSTER = "UPLOAD_POSTER";
export const GET_ANALYTICS_BOWLING_CENTER = "GET_ANALYTICS_BOWLING_CENTER";
export const SET_ANALYTICS_BOWLING_CENTER = "SET_ANALYTICS_BOWLING_CENTER";

export const UPLOAD_BC_ADMIN_BANNERS = "UPLOAD_BC_ADMIN_BANNERS"; // З// агрузка картинок админов боулинг центров
export const RESULT_BC_ADMIN_BANNERS = "RESULT_BC_ADMIN_BANNERS";
export const GE_BC_ADMIN_BANNERS = "GE_BC_ADMIN_BANNERS";
export const PUBLICATION_BC_BANNER = "PUBLICATION_BC_BANNER";

export const GET_SUBSCRIBERS = "GET_SUBSCRIBERS";
export const SET_SUBSCRIBERS = "SET_SUBSCRIBERS";
export const DROP_SUBSCRIBERS = "DROP_SUBSCRIBERS";
export const SUBSCRIBERS_CHANGE_PAGE = "SUBSCRIBERS_CHANGE_PAGE";
export const SUBSCRIBERS_CHANGE_PARAMS = "SUBSCRIBERS_CHANGE_PARAMS";
export const SUBSCRIBERS_CHANGE_TARIF_FETCH = "SUBSCRIBERS_CHANGE_TARIF_FETCH";

export const uploadBCBanners = (params) => ({
  type: UPLOAD_BC_ADMIN_BANNERS,
  params,
});
export const resultBCBanners = (params) => ({
  type: RESULT_BC_ADMIN_BANNERS,
  params,
});
export const getBCAdminBanners = (params) => ({
  type: GE_BC_ADMIN_BANNERS,
  params,
});

export const getAlikeUsers = (params) => ({
  type: GET_ALIKE_USER_FETCH,
  params,
});
export const getCurrencies = () => ({ type: GET_CURRENCY_LIST_FETCH });
export const manualTransaction = (params) => ({
  type: SEND_MANUAL_TRANSACTION,
  params,
});
export const setTransactionResponse = (params) => ({
  type: SET_TRANSACTION_RESPONSE,
  params,
});
export const getTransactionHistory = (params) => ({
  type: GET_TRANSACTION_HISTORY,
  params,
});
export const getTransactionDiff = (params) => ({
  type: GET_TRANSACTION_DIFF,
  params,
});
export const applyUsersSort = (params) => ({ type: SORT_USERS, params });
export const getAllUsers = () => ({ type: GET_ALL_USERS_FETCH });
export const editUserAsAdmin = (params) => ({
  type: EDIT_USER_AS_ADMIN,
  params,
});
export const getSystemAccounts = () => ({ type: GET_SYSTEM_ACCOUNTS });
export const requestTransfer = (params) => ({ type: REQUEST_TRANSFER, params });
// export const getAllBattles = () => ({ type: GET_ALL_BATTLES_FETCH });
export const getBattlesList = (params) => ({ type: GET_BATTLES_LIST, params });
export const getIBANrequests = () => ({ type: GET_IBAN_REQUESTS });
export const applyIBANSort = (params) => ({ type: SORT_IBAN, params });
export const applyBattleSort = (params) => ({ type: SORT_BATTLES, params });
export const updateIBAN = (params) => ({ type: UPDATE_IBAN, params });
export const getUserDocsAll = () => ({ type: GET_USER_DOCS_ALL });
export const getUserDocById = (params) => ({
  type: GET_USER_DOC_BY_ID,
  params,
});
export const onCommentSend = (params) => ({
  type: SEND_USER_DOC_COMMENT,
  params,
});
export const acceptDocVerification = (params) => ({
  type: ACCEPT_DOC_VERIFICATION,
  params,
});
export const refuseDocVerification = (params) => ({
  type: REFUSE_DOC_VERIFICATION,
  params,
});
export const sendNotificationUpdate = (params) => ({
  type: SEND_NOTIFICATION_UPDATE,
  params,
});
export const getSearchUserTariffs = (params) => ({
  type: GET_SEARCH_USER_TARIFFS,
  params,
});
export const setTariffByUser = (params) => ({
  type: SET_TARIFF_BY_USER,
  params,
});
export const saveAdminBattleListFilters = (params) => ({
  type: ADMIN_BATTLELIST_FILTERS_SAVE,
  params,
});
export const sendNotificationEvent = (params) => ({
  type: SEND_NOTIFICATION_EVENT,
  params,
});
export const uploadMainBanners = (params) => ({
  type: UPLOAD_MAIN_BANNERS,
  params,
});
export const uploadPoster = (params) => ({ type: UPLOAD_POSTER, params });
export const publicationBCBanner = (params) => ({
  type: PUBLICATION_BC_BANNER,
  params,
});
export const getAdminDiskSize = () => ({ type: GET_ADMIN_DISK_SIZE });

export const payseraRequestsRequestAction = () => ({
  type: "PAYSERA_REQUESTS_REQUEST",
});
export const payseraRequestsSuccessAction = (requests) => ({
  type: "PAYSERA_REQUESTS_SUCCESS",
  params: requests,
});
export const payseraRequestsFailureAction = (error) => ({
  type: "PAYSERA_REQUESTS_FAILURE",
  params: error,
});
/** @param {{ id: number; intent: 'approve' | 'decline' }} params */
export const payseraRequestsUpdateRequestAction = (params) => ({
  type: "PAYSERA_REQUESTS_UPDATE_REQUEST",
  params,
});
export const payseraRequestsUpdateSuccessAction = (requests) => ({
  type: "PAYSERA_REQUESTS_UPDATE_SUCCESS",
  params: requests,
});
export const payseraRequestsUpdateFailureAction = (error) => ({
  type: "PAYSERA_REQUESTS_UPDATE_FAILURE",
  params: error,
});

export const getAnalyticsBowlingCenter = (params) => ({
  type: SET_ANALYTICS_BOWLING_CENTER,
  params,
});

export const getSubscribersAction = (params) => ({
  type: GET_SUBSCRIBERS,
  params,
});
export const setSubscribersAction = (params) => ({
  type: SET_SUBSCRIBERS,
  params,
});
export const subscribersChangePage = (params) => ({
  type: SUBSCRIBERS_CHANGE_PAGE,
  params,
});
export const dropSubscribersAction = (params) => ({
  type: DROP_SUBSCRIBERS,
  params,
});
export const subscribersChangeParams = (params) => ({
  type: SUBSCRIBERS_CHANGE_PARAMS,
  params,
});

export const subscribersChangeTariffFetch = (params) => ({
  type: SUBSCRIBERS_CHANGE_TARIF_FETCH,
  params,
});

const initialState = {
  userPicker: [],
  currencyList: [],
  userList: [],
  battlesList: [],
  battlesListCount: null,
  tariffList: [],
  ibanrequests: [],
  permissionsList: [],
  modalResponse: [],
  transactionHistory: [],
  transactionDiff: {},
  systemAccounts: [],
  systemAccountsHistory: [],
  groupList: [],
  bowlingcenters: [],
  userDocs: [],
  userDocById: {},
  updatekey: Math.random(),
  reportNotificationUpdate: null,
  userTariffList: [],
  userTariffCount: null,
  processSearch: false,
  processSetTariff: false,
  battleListFilters: null,
  notificationEvent: {
    fetching: false,
    data: null,
    error: null,
  },
  processMainBanners: {},
  payserarequests: { data: [] },
  analyticsBc: [],
  bcAdminBanners: [],
  bcAdminBannersFetch: false,
  subscribers: {
    fetch: true,
    recurring_count: null,
    requrrings: [],
    user_count: null,
    page: 1,
    fetch_params: {},
  },
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_IBAN_REQUESTS:
      return { ...state, ibanrequests: [] };
    case SET_IBAN_REQUESTS:
      return { ...state, ibanrequests: action.params || [] };
    case "PAYSERA_REQUESTS_REQUEST":
    case "PAYSERA_REQUESTS_UPDATE_REQUEST":
      return {
        ...state,
        payserarequests: {
          ...state.payserarequests,
          data: (state.payserarequests && state.payserarequests.data) || [],
          fetching: true,
          error: false,
        },
      };
    case "PAYSERA_REQUESTS_UPDATE_SUCCESS":
    case "PAYSERA_REQUESTS_SUCCESS":
      return {
        ...state,
        payserarequests: {
          ...state.payserarequests,
          fetching: false,
          data: action.params || [],
        },
      };
    case "PAYSERA_REQUESTS_UPDATE_FAILURE":
    case "PAYSERA_REQUESTS_FAILURE":
      return {
        ...state,
        payserarequests: {
          ...state.payserarequests,
          fetching: false,
          error: true,
        },
      };
    case GET_ALIKE_USER_SET:
      return { ...state, userPicker: action.params.users || [] };
    case GET_CURRENCY_LIST_SET:
      return { ...state, currencyList: action.params.currencies };
    case SET_TRANSACTION_RESPONSE:
      return { ...state, modalResponse: action.params };
    case GET_TRANSACTION_HISTORY:
      return { ...state, transactionHistory: [] };
    case SET_TRANSACTION_HISTORY:
      return { ...state, transactionHistory: action.params };
    case GET_TRANSACTION_DIFF:
      return { ...state, transactionDiff: {} };
    case SET_TRANSACTION_DIFF:
      return { ...state, transactionDiff: action.params };
    case GET_ALL_USERS_SET:
      return {
        ...state,
        userList: action.params.users,
        permissionsList: action.params.permissions.map((datum) => ({
          value: datum.itemkey,
          label: datum.name,
        })),
        tariffList: action.params.tariffs.map((datum) => ({
          value: datum.id,
          label: datum.name,
        })),
        groupList: action.params.groups.map((datum) => ({
          value: datum.name,
          label: datum.name,
        })),
        bowlingcenters: action.params.bowlingcenters,
      };
    case SORT_USERS:
      return {
        ...state,
        userList: action.params,
        updatekey: Math.random(),
      };
    case SORT_IBAN:
      return {
        ...state,
        ibanrequests: action.params,
        updatekey: Math.random(),
      };
    case SORT_BATTLES:
      return {
        ...state,
        battlesList: action.params,
        updatekey: Math.random(),
      };
    case SET_EDITED_USER:
      const newList = [...state.userList];
      const editIndex = newList.findIndex(
        (user) => user.id === action.params.id
      );
      newList[editIndex] = action.params;
      return {
        ...state,
        userList: newList,
        updatekey: Math.random(),
      };
    case SET_BATTLES_LIST:
      return {
        ...state,
        battlesList: action.params,
        battlesListCount: action.params.count,
      };
    case SET_SYSTEM_ACCOUNTS:
      return {
        ...state,
        systemAccounts: action.params.data || [],
        systemAccountsHistory: action.params.history || [],
        updatekey: Math.random(),
      };
    case GET_USER_DOCS_ALL:
      return { ...state, userDocs: [] };
    case GET_USER_DOCS_ALL_SET:
      return {
        ...state,
        userDocs: action.params.data,
        updatekey: Math.random(),
      };
    case GET_USER_DOC_BY_ID:
      return { ...state, userDocById: {} };
    case GET_USER_DOC_BY_ID_SET:
      return {
        ...state,
        userDocById: action.params.data || {},
        updatekey: Math.random(),
      };
    case REFUSE_DOC_VERIFICATION:
      return { ...state, userDocs: [], updatekey: Math.random() };
    case ACCEPT_DOC_VERIFICATION:
      return { ...state, userDocs: [], updatekey: Math.random() };
    case REPORT_NOTIFICATION_UPDATE:
      return {
        ...state,
        reportNotificationUpdate: action.params,
      };
    case REPORT_NOTIFICATION_CLEAR:
      return {
        ...state,
        reportNotificationUpdate: null,
      };

    case SET_SEARCH_USER_TARIFFS:
      return {
        ...state,
        userTariffList: action.params.tariffs,
        userTariffCount: action.params.count,
      };
    case PROCESS_SEARCH:
      return { ...state, processSearch: action.params };
    case PROCESS_SET_TARIFFS:
      return { ...state, processSetTariff: action.params };
    case ADMIN_BATTLELIST_FILTERS_SAVE:
      return { ...state, battleListFilters: action.params };
    case SEND_NOTIFICATION_EVENT:
      return {
        ...state,
        notificationEvent: { data: null, error: null, fetching: true },
      };
    case SET_NOTIFICATION_EVENT:
      return {
        ...state,
        notificationEvent: { ...action.params, fetching: false },
      };
    case RESULT_MAIN_BANNERS:
      return { ...state, processMainBanners: { ...action.params } };
    case UPLOAD_BC_ADMIN_BANNERS:
      return { ...state, bcAdminBannersFetch: true };
    case RESULT_BC_ADMIN_BANNERS:
      return {
        ...state,
        bcAdminBanners: [...action.params],
        bcAdminBannersFetch: false,
      };
    case SET_ADMIN_DISK_SIZE:
      return {
        ...state,
        disckSize: (action.params && action.params.data) || 0,
      };
    case GET_ANALYTICS_BOWLING_CENTER:
      return { ...state, analyticsBc: action.params || [] };
    case GET_SUBSCRIBERS:
      return { ...state, subscribers: { ...state.subscribers, fetch: true } };
    case SET_SUBSCRIBERS:
      return {
        ...state,
        subscribers: { ...state.subscribers, ...action.params, fetch: false },
      };
    case DROP_SUBSCRIBERS:
      return { ...state, subscribers: initialState.subscribers };
    case SUBSCRIBERS_CHANGE_PAGE:
      return {
        ...state,
        subscribers: { ...state.subscribers, page: action.params },
      };
    case SUBSCRIBERS_CHANGE_PARAMS:
      return {
        ...state,
        subscribers: { ...state.subscribers, fetch_params: action.params, page: 1 },
      };
    default:
      return state;
  }
};

export default adminReducer;
