import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TournamentsTable from './myTournamentsTable';
import { AccountTournamentFetch } from '../../../libs/reducers/actions';

class MyTournaments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static propTypes = {
    tournaments: PropTypes.arrayOf(
      PropTypes.shape({}),
    ).isRequired,
    fetching: PropTypes.bool.isRequired,
    AccountTournamentFetch: PropTypes.func,
  };

  componentDidMount() {
    this.props.AccountTournamentFetch();
  }

  render() {
    const { tournaments } = this.props;
    return (
      <TournamentsTable tournaments={tournaments} />
    );
  }
}

export default connect(
  state => ({
    tournaments: state.tournaments.accountTournaments,
    fetching: state.tournaments.accountTournamentsFetching,
  }),
  {
    AccountTournamentFetch,
  },
)(MyTournaments);
