import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import Modal from '../../Modal';
import { Button } from '../../Button';
import { closeSessionEventModal } from '../../../../libs/reducers/actions';

import './modalSessionEvent.css';

class ModalSessionEvent extends React.Component {
  close = () => {
    const { _closeSessionEventModal } = this.props;
    _closeSessionEventModal();
  }

  render() {
    const { visible, intl } = this.props;
    return <Modal
      key='session'
      title={intl.formatMessage({ id: 'session', defaultMessage: 'Session' })}
      visible={visible}
      closable={false}
      width={395}
      className="modal-session-event"
      footer={<Button key="okButton" kind="aquamarine" onClick={this.close} >{'OK'}</Button>}
      zIndex={10000}
    >
      <div className='modal-session-event-body'>
        <span>{intl.formatMessage({ id: 'singleSessionDesc', defaultMessage: 'Your account is used to log in to another device' })}</span>
      </div>
    </Modal>;
  }
}

ModalSessionEvent.propTypes = {
  visible: PropTypes.bool,
  intl: intlShape,
  _closeSessionEventModal: PropTypes.func,
};

export default connect(
  state => ({
    visible: state.events.session.visible,
    closed: state.events.session.closed,
    reason: state.events.session.reason,
  }),
  {
    _closeSessionEventModal: closeSessionEventModal,
  },
)(injectIntl(ModalSessionEvent));
