const {
  SET_OBSERVABLE_SQUAD,
  SET_OBSERVABLE_TOURNAMENT,
  FETCH_JOIN_AS_OBSERVER,
  OBSERVER_UPDATE_FRAME,
  FETCH_EXIT_OBSERVER,
  LOGOUT,
  QUEUE_POSITION_UPDATED,
  PASS_USER_TO_WATCH,
  END_GAME_KICK_OBSERVER,
  PLAYERS_CONNECTED,
} = require('./actiontypes').default;

const initialState = {
  squadid: null,
  tournamentid: null,
  connected: false,
	squadstate: null,
	positioninqueue: null,
  userallowed: false,
  endgame: false,
  playersconnected: false,
};

const ObserverReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OBSERVABLE_SQUAD:
      return { ...initialState, squadid: action.params, tournamentid: null };
    case SET_OBSERVABLE_TOURNAMENT:
      return { ...initialState, tournamentid: action.params, squadid: null };
    case FETCH_JOIN_AS_OBSERVER:
      return { ...state, connected: false, userallowed: false }
    case FETCH_EXIT_OBSERVER:
      return { ...state, connected: false }
    case OBSERVER_UPDATE_FRAME:
      return { ...state, connected: true, squadstate: action.params }
    case QUEUE_POSITION_UPDATED:
      return { ...state, positioninqueue: action.params }
    case PASS_USER_TO_WATCH:
      return { ...state, userallowed: action.params }
    case END_GAME_KICK_OBSERVER:
      return { ...state, endgame: true }
    case PLAYERS_CONNECTED:
      return { ...state, playersconnected: true }
    case LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};

export default ObserverReducer;
