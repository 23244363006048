/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */

/**
 * @typedef {{
 *  cost?: number
 *  pro_tariff?: 0 | 1 | null
 *  currencysymbol?: string
 * }} IGameCostProps
 */

/**
 * @param {IGameCostProps} props
 */
export const useGameCostText = (props) => {
  const { cost, currencysymbol, pro_tariff } = props;
  if (pro_tariff) {
    return 'PREMIUM Tariff';
  }
  if (cost) {
    return `${cost}${currencysymbol}`;
  }
  return 'FREE';
};
