import React from 'react';
import PropTypes from 'prop-types';
import { Table, Spin } from 'antd';
import moment from 'moment';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';
import {
  UserByIdFetch,
  CreateBattle,
  fetchUserDisk,
  unbindVideoFilesOfUser,
  setVideoArchiveSquadId,
  setProtocolSquadId,
  AccountBattleHistoryFetch,
  AccountBattlesFetch,
} from '../../../libs/reducers/actions';
import FilterMyBattles from './filter';
import Flag from '../../common/Flag';
import avatar from '../../../images/defaultAvatar.png';
import config from '../../../config';
import SvgCountry from '-!svg-react-loader!../../../images/country.svg'; // eslint-disable-line
import SvgGender from '-!svg-react-loader!../../../images/gender.svg'; // eslint-disable-line
import './style.css';
import ModalUser from '../../common/modals/modalUser';
import ModalInviteBattle from '../../common/modals/modalInviteBattle';
import Avatar from '../../common/Avatar';
import { Button } from '../../common/Button';
import SvgButton from '../../common/SvgButton';
import ModalDialog from '../../common/modals/modalDialog';
import ModalSaveBattle from '../../common/modals/modalSaveBattle';
import SvgRefereeing from '-!svg-react-loader!../../../images/refereeing.svg'; // eslint-disable-line
import RatingStars from '../../common/RatingStars';
import { isPinfall } from '../../../libs/SquadTypeCheck';
class MyBattlesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      battles: props.battles,
      searchInput: '',
      valueFrom: '',
      valueTo: '',
      filterChecked: true,
      filterWithProtest: false,
      showInviteBattle: false,
      showUserById: false,
      squadid: null,
      showConfirmation: false,
      showDummyModal: false,
      showSaveBattleModal: false,
      action: false,
    };
  }

  static propTypes = {
    users: PropTypes.object,
    battles: PropTypes.array,
    intl: intlShape,
  };

  componentDidMount() {
    this.props.AccountBattlesFetch();
    // this.props.AccountBattleHistoryFetch();
    this.props.fetchUserDisk();
  }

  linkClick = record => (e) => {
    e.preventDefault();
    this.props.UserByIdFetch({
      userid: record,
    });
  };

  onDeleteArchive = squadid => {
    this.setState({
      squadid: squadid,
      showConfirmation: true,
      action: true
    });
  }

  deleteArchive = () => {
    const { squadid } = this.state;
    this.props.unbindVideoFilesOfUser({ squadid: squadid });
    this.setState({
      squadid: null,
      showConfirmation: false
    });
  };

  play = squadid => {
    this.props.setVideoArchiveSquadId(squadid);
    this.props.history.push('/videoarchive');
  }

  gotoProtocol = squadid => {
    this.props.setProtocolSquadId(squadid);
    this.props.history.push('/protocol');
  }

  battles = () => {
    const {
      battles,
    } = this.props;
    const {
      searchInput, valueFrom, valueTo, filterChecked, filterWithProtest,
    } = this.state;
    let tempBattles = [...(battles || [])];
    if (searchInput !== '') {
      tempBattles = tempBattles.filter((battle) => {
        const creator = {};
        if (battle.players.length === 1) {
          creator.login = battle.players[0].login;
          creator.firstname = battle.players[0].firstname;
          creator.lastname = battle.players[0].lastname;
        } else {
          battle.players.forEach((player) => {
            if (player.userid === battle.createdby) {
              creator.login = player.login;
              creator.firstname = player.firstname;
              creator.lastname = player.lastname;
            }
          });
        }

        // Не совсем верная проверка, но в данном случае (всегда присутствуют firstname lastname login) пойдет
        return (creator.firstname && creator.lastname && creator.login)
          ? (
            _.startsWith(`${creator.firstname} ${creator.lastname}`.toLowerCase(), searchInput.toLowerCase())
            || _.startsWith(creator.login.toLowerCase(), searchInput.toLowerCase())
          ) : false;
      });
    }
    if (valueFrom !== '') {
      tempBattles = tempBattles.filter(battle => battle.cost >= valueFrom.value);
    }
    if (valueTo !== '') {
      tempBattles = tempBattles.filter(battle => battle.cost <= valueTo.value);
    }
    if (!filterChecked) {
      tempBattles = tempBattles.filter(
        battle => battle.datestart === null || moment(battle.datestart).unix() >= moment().unix(),
      );
    }
    if (filterWithProtest) {
      tempBattles = tempBattles.filter(
        battle => !!battle.with_protest
      );
    }
    return tempBattles;
  };

  onInviteBattleSubmit = ({
    betType, bet, winCount, dateType, date, time, comment, opponent,
  }) => {
    const sendParams = { name: 'battle' };
    sendParams.wincount = winCount;

    if (betType === 2) {
      sendParams.cost = bet;
    }
    if (dateType === 2) {
      let datestart;
      if (time !== null) {
        datestart = moment(date.set('h', time.hour()).set('m', time.minute()));
      } else {
        datestart = moment(date);
      }
      sendParams.datestart = moment(datestart).toISOString();
    }
    if (comment !== '') {
      sendParams.rules = comment;
    }
    sendParams.userid = opponent.id;
    this.props.CreateBattle({
      ...sendParams,
    });
    this.setState({ showInviteBattle: false });
  };

  render() {
    const { intl } = this.props;

    const columns = [
      {
        title: '#',
        dataIndex: 'squadid',
      },
      {
        title: intl.formatMessage({ id: 'table_fullname', defaultMessage: 'Surname, name' }),
        dataIndex: 'userName',
        // eslint-disable-next-line
        render: (data, record) => {
          const { user } = this.props;
          const opponent = _.find(record.players, p => p.userid !== user.id && p.confirmedbyowner == 1);
          if (opponent) {
            const { firstname, lastname, login, photo, active, avgscore, country_live } = opponent;
            return (
              <div style={{ alignItems: 'center', display: 'flex' }}>
                <Avatar uri={photo ? `${config.FETCH.img}/${photo}` : avatar} flag={country_live} flagStyle={{ width: '20px', height: '20px' }} tabled isOnline={active} avgscore={(+avgscore || 0).toFixed()}/>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {`${firstname} ${lastname}`.trim() || login}
                  <RatingStars containerStyle={{ alignItems: 'flex-end' }} starsContainerStyle={{ marginRight: '3px' }} rating={opponent.rating || 0} height={12} width={12} />
                </div>
              </div>
            );
          }
          if (record.players && record.players.length === 1) {
            const player = record.players[0];
            const { firstname, lastname, login, photo, active, country_live } = player;
            return (
              <div style={{ alignItems: 'center', display: 'flex' }}>
                <Avatar uri={photo ? `${config.FETCH.img}/${photo}` : avatar} flag={country_live} flagStyle={{ width: '20px', height: '20px' }} tabled isOnline={active}/>
                {`${firstname} ${lastname}`.trim() || login}
              </div>
            )
          }
          return <div></div>;
        },
        sorter: (a, b) => {
          const { user } = this.props;
          const opponentA = {};
          a.players.forEach((player) => {
            if (player.userid !== user.id) {
              opponentA.firstname = player.firstname;
              opponentA.lastname = player.lastname;
            }
          });

          const opponentB = {};
          b.players.forEach((player) => {
            if (player.userid !== user.id) {
              opponentB.firstname = player.firstname;
              opponentB.lastname = player.lastname;
            }
          });

          const str1 = `${opponentA.firstname || ''} ${opponentA.lastname || ''}`;
          const str2 = `${opponentB.firstname || ''} ${opponentB.lastname || ''}`;
          return str1.localeCompare(str2);
        },
      },
      {
        title: intl.formatMessage({ id: 'Opponent', defaultMessage: 'Opponent' }),
        dataIndex: 'creatorlogin',
        // eslint-disable-next-line
        render: (data, record, index) => {
          const { user } = this.props;
          const opponent = _.find(record.players, p => p.userid !== user.id && p.confirmedbyowner == 1);
          if (record.players && record.players.length === 1) {
            return 'Solo';
          }
          if (!opponent) return null;
          return (
            <Link to="#" onClick={this.linkClick(opponent.userid)}>
              {opponent.login}
            </Link>
          );
        },
        sorter: (a, b) => {
          const opponentA = {};
          a.players.forEach((player) => {
            if (player.userid !== user.id) {
              opponentA.login = player.login;
            }
          });
          const opponentB = {};
          b.players.forEach((player) => {
            if (player.userid !== user.id) {
              opponentB.login = player.login;
            }
          });
          return opponentA.login.localeCompare(opponentB.login);
        },
      },
      // {
      //   title: <SvgCountry />,
      //   dataIndex: 'creatorcountry',
      //   // eslint-disable-next-line
      //   render: (data, record) => {
      //     const { user } = this.props;
      //     const opponent = _.find(record.players, p => p.userid !== user.id && p.confirmedbyowner == 1);
      //     if (!opponent) return null;
      //     return (
      //       <div className="battles__flag">
      //         <Flag name={opponent.country} />
      //       </div>
      //     );
      //   },
      //   sorter: (a, b) => {
      //     const { user } = this.props;
      //     const opponentA = {};
      //     a.players.forEach((player) => {
      //       if (player.userid !== user.id) {
      //         opponentA.country = player.country_live;
      //       }
      //     });
      //     const opponentB = {};
      //     b.players.forEach((player) => {
      //       if (player.userid !== user.id) {
      //         opponentB.country = player.country_live;
      //       }
      //     });
      //     return opponentA.country.localeCompare(opponentB.country);
      //   },
      // },
      {
        title: <SvgGender />,
        dataIndex: 'creatorgender',
        render: (data, record) => {
          const { user } = this.props;
          const opponent = _.find(record.players, p => p.userid !== user.id && p.confirmedbyowner == 1);
          if (record.players && record.players.length === 1) {
            const player = record.players[0];
            return player.gender;
          }
          if (!opponent) return null;
          return opponent.gender;
        },
        sorter: (a, b) => {
          const { user } = this.props;
          const opponentA = {};
          a.players.forEach((player) => {
            if (player.userid !== user.id) {
              opponentA.gender = player.gender;
            }
          });
          const opponentB = {};
          b.players.forEach((player) => {
            if (player.userid !== user.id) {
              opponentB.gender = player.gender;
            }
          });
          return opponentA.gender.localeCompare(opponentB.gender);
        },
      },
      {
        title: intl.formatMessage({ id: 'Format', defaultMessage: 'Format' }),
        className: 'tableColumnNumbers',
        render(data, record) {
          const { players, tournamenttypebrief } = record;

          if (!Array.isArray(players)) return '';

          if (isPinfall(tournamenttypebrief)) return 'Pinfall';

          return 'Wins';
        },
      },
      /*{
        title: intl.formatMessage({ id: 'fee', defaultMessage: 'Fee' }),
        dataIndex: 'cost',
        className: 'tableColumnNumbers',
        render: (data, record) => (data === 0 ? intl.formatMessage({ id: 'without_bet', defaultMessage: 'Free' }) : `${data}${record.currencysymbol || ''}`),
        sorter: (a, b) => a.cost - b.cost,
      },*/
      {
        title: `${intl.formatMessage({ id: 'games', defaultMessage: 'Games' })} (${intl.formatMessage({ id: 'Wins', defaultMessage: 'Wins' })})`,
        className: 'tableColumnNumbers',
        render(data, record) {
          const { total_games = 0, wincount = 0, players, tournamenttypebrief } = record;

          // if (players && players.length === 1) return wincount;
          if (isPinfall(tournamenttypebrief)) return wincount;

          return `${total_games} (${wincount})`;
        },
      },
      // {
      //   title: intl.formatMessage({ id: 'table_wins', defaultMessage: 'Wins' }),
      //   dataIndex: 'wincount',
      //   className: 'tableColumnNumbers',
      //   sorter: (a, b) => a.wincount - b.wincount,
      // },
      {
        title: intl.formatMessage({ id: 'date_of_battle', defaultMessage: 'Date of battle' }),
        dataIndex: 'datestart',
        defaultSortOrder: 'descend',
        render: data => (data ? moment(data).format('DD MMM - HH:mm') : <b>{intl.formatMessage({ id: 'now', defaultMessage: 'Now' })}</b>),
        sorter: (a, b) => {
          const date1 = moment(a.datestart);
          const date2 = moment(b.datestart);
          if (!a.datestart && !b.datestart) {
            return a.id - b.id;
          }
          if (!a.datestart) {
            return 1;
          }
          if (!b.datestart) {
            return -1;
          }
          return date1.unix() - date2.unix();
        },
      },
      {
        title: intl.formatMessage({ id: 'MyBattlesTableResults', defaultMessage: 'Game result' }),
        dataIndex: 'result',
        render: (data, record) => {
          const { user } = this.props;
          const { tournamenttypebrief } = record;
          let str = null;
          if (record.players && record.players.length === 1) {
            const player = record.players[0];
            return `Pinfall ${((player.total_score > 0) && player.total_score) || '-'}`;
          }

          if (record.players.length !== 2 || (record.players[0].gameswin === 0 && record.players[1].gameswin === 0)) {
            return null;
          }

          let userScore = 0;
          let opponentScore = 0;
          if (record.resolved === 0) {
            record.players.forEach((player) => {
              if (player.userid === user.id) {
                userScore = tournamenttypebrief === 'PINFALL_BATTLE' ? player.total_score : player.gameswin;
              } else {
                opponentScore = tournamenttypebrief === 'PINFALL_BATTLE' ? player.total_score : player.gameswin;
              }
            });
          } else {
            record.players.forEach((player) => {
              if (player.userid === user.id && player.id === record.winner) {
                userScore = record.winner_score;
              } else if (player.userid === user.id && player.id !== record.winner) {
                opponentScore = record.winner_score;
              }
              if (player.userid === user.id && player.id === record.loser) {
                userScore = record.loser_score;
              } else if (player.userid === user.id && player.id !== record.loser) {
                opponentScore = record.loser_score;
              }
            });
          }
          if (record.resolved === 0 && record.with_protest === 1) {
            return (
              <div className="account__mybattles_result wait">
                <SvgRefereeing className="refereeing-svg"/>
                <span>{`${intl.formatMessage({ id: 'MyBattlesTableResultsWait', defaultMessage: 'Waiting of referee' })}`}</span>
              </div>
            );
          }
          if (record.resolved === 1 && record.failed === 1) {
            return (
              <div className={`account__mybattles_result${record.resolved == 1 ? ' resolved ' : ' '}fail`}
                onClick={() => {
                  if (record.resolved === 1) {
                    this.gotoProtocol(record.squadid);
                  }
                }}
              >
                <SvgRefereeing className="refereeing-svg"/>
                <span>{`${intl.formatMessage({ id: 'MyBattlesTableResultsFail', defaultMessage: 'Game failed' })}`}</span>
              </div>
            );
          }
          if (userScore > opponentScore) {
            return (
              <div className={`account__mybattles_result${record.resolved === 1 ? ' resolved ' : ' '}win`}
                onClick={() => {
                  if (record.resolved === 1) {
                    this.gotoProtocol(record.squadid);
                  }
                }}
              >
                {record.with_protest === 1 ? <SvgRefereeing className="refereeing-svg"/> : null}
                <span>{`${intl.formatMessage({ id: 'MyBattlesTableResultsWin', defaultMessage: 'Win' })} (${opponentScore}:${userScore})`}</span>
              </div>
            );
          }
          if (userScore < opponentScore) {
            return (
              <div className={`account__mybattles_result${record.resolved == 1 ? ' resolved ' : ' '}lose`}
                onClick={() => {
                  if (record.resolved === 1) {
                    this.gotoProtocol(record.squadid);
                  }
                }}
              >
                {record.with_protest === 1 ? <SvgRefereeing className="refereeing-svg"/> : null}
                <span>{`${intl.formatMessage({ id: 'MyBattlesTableResultsDefeat', defaultMessage: 'Defeat' })} (${opponentScore}:${userScore})`}</span>
              </div>
            );
          }

          return (
            <div className={`account__mybattles_result${record.resolved == 1 ? ' resolved ' : ' '}draw`}
              onClick={() => {
                if (record.resolved === 1) {
                  this.gotoProtocol(record.squadid);
                }
              }}
            >
              {record.with_protest === 1 ? <SvgRefereeing className="refereeing-svg"/> : null}
              <span>{`${intl.formatMessage({ id: 'GameResultDraw', defaultMessage: 'Draw' })} (${opponentScore}:${userScore})`}</span>
            </div>
          );
        },
      },
      {
        title: intl.formatMessage({ id: 'MyBattlesTableVideoArchive', defaultMessage: 'Video archive' }),
        dataIndex: 'result',
        width: '100%',
        align: 'left',
        render: (data, record) => {
          const { squadid, saved, started, closed, archive_exists } = record;
          if (!!archive_exists && !!saved && !!started && !!closed) {
            return (
              <div class="account__mybattles_play_trash">
                <Button
                  kind={'aquamarine'}
                  icon={'play'}
                  onClick={action ? () => {} : () => { this.play(squadid); }}
                >
                  {intl.formatMessage({ id: 'play', defaultMessage: 'Play' })}
                </Button>
                <SvgButton
                  icon={'trash'}
                  onClick={action ? () => {} : () => { this.onDeleteArchive(squadid); }}
                />
              </div>
            );
          }
          if (!!archive_exists && !!!saved && !!started && !!closed) {
            const { squadid } = record;
            return (
              <Button
                kind={'orange'}
                icon={'save'}
                onClick={action ? () => {} : () => {
                  this.setState({
                    squadid: squadid,
                    showSaveBattleModal: true
                  });
                }}
              >
                {intl.formatMessage({ id: 'SaveAsKeepAlias', defaultMessage: 'Save' })}
              </Button>
            );
          }
          if (!!!archive_exists && !!started && !!closed) {
            return (
              <div class="account__mybattles_archive_not_exists">{intl.formatMessage({ id: 'record_deleted', defaultMessage: 'Record deleted' })}</div>
            );
          }
        },
      },
    ];

    const {
      searchInput,
      valueFrom,
      valueTo,
      filterChecked,
      filterWithProtest,
      battles,
      showInviteBattle,
      showUserById,
      showConfirmation,
      showDummyModal,
      showSaveBattleModal,
      squadid,
      action
    } = this.state;

    const { user, userById, fetching } = this.props;
    const tempBattles = battles || [];
    let selectOptions = [];
    if (tempBattles.length) {
      selectOptions = [...tempBattles];
      selectOptions = selectOptions.map(option => option.cost);
      selectOptions = _.uniq(selectOptions);
      selectOptions = _.sortBy(selectOptions, e => e);
      selectOptions = selectOptions.map(option => ({ value: option, label: option }));
      selectOptions.unshift({ value: 'none', label: intl.formatMessage({ id: 'NullSelector', defaultMessage: 'Not selected...' }) });
    }

    return (
      <div className="account__mybattles">
        <div className="account__mybattles__filter">
          <div className="account__mybattles__filter__input">
            <FilterMyBattles
              searchInput={searchInput}
              onChangeSearchInput={(e) => {
                this.setState({
                  searchInput: e.target.value,
                });
              }}
              selectOptions={selectOptions}
              filterFrom={valueFrom}
              filterTo={valueTo}
              From={(e) => {
                if (e.value === 'none') {
                  this.setState({
                    valueFrom: '',
                  });
                } else {
                  this.setState({
                    valueFrom: e,
                  });
                }
              }}
              To={(e) => {
                if (e.value === 'none') {
                  this.setState({
                    valueTo: '',
                  });
                } else {
                  this.setState({
                    valueTo: e,
                  });
                }
              }}
              checked={filterChecked}
              checkedChange={(e) => {
                this.setState({
                  filterChecked: e,
                });
              }}
              with_protest={filterWithProtest}
              withProtestChange={(e) => {
                this.setState({
                  filterWithProtest: e,
                });
              }}
              Reset={() => {
                this.setState({
                  searchInput: '',
                  valueFrom: '',
                  valueTo: '',
                  filterChecked: true,
                });
              }}
            />
          </div>
        </div>
        { (fetching && <Spin className="mybattles-spin" size="large"/>)
          || <Table
            key={this.props.langs}
            columns={columns}
            dataSource={this.battles()}
            pagination={false}
            rowKey="squadid"
            locale={{ emptyText: intl.formatMessage({ id: 'no_tournaments', defaultMessage: 'No tournaments' }) }}
          />
        }
        <ModalInviteBattle
          visible={showInviteBattle}
          onSubmit={this.onInviteBattleSubmit}
          onClose={() => {
            this.setState({
              showInviteBattle: false,
              showUserById: true,
            });
          }}
          opponent={{
            ...userById.data,
            hourshift: (userById.data.hourshift && userById.data.hourshift !== 0
              ? userById.data.hourshift
              : '0'
            ).toString(),
          }}
        />
        <ModalDialog
          visible={showDummyModal}
          title={intl.formatMessage({ id: 'notice', defaultMessage: 'Notice' })}
          isAcceptButton={false}
          declineButtonText={intl.formatMessage({ id: 'Accept', defaultMessage: 'Accept' })}
          onClose={() => { this.setState({  showDummyModal: false }) }}
        >
          <div>{intl.formatMessage({ id: 'in_develop_message', defaultMessage: 'Sorry, this functionality is still in development.' })}</div>
        </ModalDialog>
        <ModalDialog
          visible={showConfirmation}
          title={intl.formatMessage({ id: 'confimation', defaultMessage: 'Сonfimation' })}
          acceptButtonText={intl.formatMessage({ id: 'Accept', defaultMessage: 'Accept' })}
          declineButtonText={intl.formatMessage({ id: 'Decline', defaultMessage: 'Decline' })}
          onSubmit={this.deleteArchive}
          onClose={() => {
            this.setState({
              squadid: null,
              showConfirmation: false,
              action: false
            });
          }}
        >
          <div>{intl.formatMessage({ id: 'confirm_delete', defaultMessage: 'Are you sure you want to delete the record?' })}</div>
        </ModalDialog>
        {
          showSaveBattleModal ?
          <ModalSaveBattle
            visible={showSaveBattleModal}
            owned={true}
            squadid={squadid}
            history={this.props.history}
            onSubmit={()=>{
              // Перерисовываем список
              this.props.AccountBattleHistoryFetch();
              this.setState({
                squadid: null,
                showSaveBattleModal: false
              });
            }}
            onCancel={()=>{
              this.setState({
                squadid: null,
                showSaveBattleModal: false
              });
            }}
          />
          : null
        }
        <ModalUser
          title={intl.formatMessage({ id: 'FriendListUserCardModalTitle', defaultMessage: 'User card' })}
          onClose={() => {
            this.setState({
              showUserById: false,
            });
          }}
          onChallengeClick={() => {
            this.setState({
              showUserById: false,
              showInviteBattle: true,
            });
          }}
          isChallengeAllowed={user.id !== userById.data.id}
          user={{
            ...userById.data,
            // hourshift: (userById.data.hourshift && userById.data.hourshift !== 0
            //   ? userById.data.hourshift
            //   : '0'
            // ).toString(),
          }}
          visible={showUserById}
        />
      </div>
    );
  }
}

export default injectIntl(withRouter(
  connect(
    state => ({
      users: state.users,
      user: state.users.user,
      userById: state.users.userById,
      langs: state.langs.lang,
      userdisk: state.userdisk.data,
      battles: state.battles.accountbattles,
      fetching: state.battles.fetching,
    }),
    {
      AccountBattleHistoryFetch,
      UserByIdFetch,
      CreateBattle,
      fetchUserDisk,
      unbindVideoFilesOfUser,
      setVideoArchiveSquadId,
      setProtocolSquadId,
      AccountBattlesFetch,
    },
  )(MyBattlesTable),
));
