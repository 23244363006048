const {
  SET_STATISTIC_OPTIONS
} = require('./actiontypes').default;


export const initialState = {
  options: {}
};

export const statisticFilterOptions = (state = initialState, action) => {
  switch (action.type) {
    case SET_STATISTIC_OPTIONS:
      return {
        ...action.params
      };

    default:
      return state;
  }
};

