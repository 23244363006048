import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FlussonicMsePlayer from '@flussonic/flussonic-mse-player';
import bugsnagClient from '../../../bugsnagClient';
import { VideoPlayer } from './index';

function dummy() {}

async function FlussonicReady() {
  // const { FlussonicMsePlayer = null } = window;
  const promise = {
    res: dummy,
    rej: dummy,
  };
  const t = new Promise((res, rej) => {
    promise.res = res;
    promise.rej = rej;
  });
  if (FlussonicMsePlayer) {
    promise.res(FlussonicMsePlayer);
    return t;
  }
  await DOMReady();
  let script = document.querySelector('script#flussonic-mse-player');
  const onload = () => {
    if (window.FlussonicMsePlayer) {
      promise.res(window.FlussonicMsePlayer);
    } else {
      promise.rej(new Error('FlussonicMsePlayer not found'));
    }
  };
  if (script) {
    script.addEventListener('load', onload);
    return t;
  }
  script = document.createElement('script');
  script.id = 'flussonic-mse-player';
  script.src = 'https://cdn.jsdelivr.net/npm/@flussonic/flussonic-mse-player/dist/FlussonicMsePlayer.min.js';
  const { head } = document;
  head.appendChild(script);
  script.addEventListener('load', onload);
  return t;
}

function DOMReady() {
  const promise = {
    res: dummy,
    rej: dummy,
  };
  const t = new Promise((res, rej) => {
    promise.res = res;
    promise.rej = rej;
  });
  switch (document.readyState) {
    case 'loading':
      document.addEventListener('DOMContentLoaded', promise.res);
      break;
    case 'interactive':
    case 'complete':
      promise.res();
      break;
    default:
      promise.rej();
  }
  return t;
}
class FLUPlayer extends Component {
  constructor(props) {
    super(props);
    if (window.FlussonicMsePlayer && !window.FlussonicMsePlayer.isSupported()) {
      const error = 'Your browser does not support Media Source Extension (MSE)';
      bugsnagClient.notify(new Error(error), {
        context: 'FLUPlayer',
      });
    }
    this.state = {};
    this.logger = {
      log: props.debug ? console.log : function log() {},
      error: console.error,
      warn: console.warn,
      info: console.info,
    };
    DOMReady().then(() => this.setState({ domready: true }));
    FlussonicReady().then(() => this.setState({ fluready: true }));
    this.logger.log('FluPlayer::ctor', props.url);
  }

  componentWillUnmount() {
    const { player } = this;
    if (player) {
      player.stop();
    }
    this.logger.log('FluPlayer::dtor');
  }

  setPlayer = (_media, url, paused) => {
    const { domready, fluready } = this.state;
    if (!domready) {
      return;
    }
    if (!fluready) {
      return;
    }
    // const media = _media;
    const { vidRef: media = _media } = this.ref || {};
    let { player } = this;
    const _this = this;
    if (!media) return;
    if (!player && media && url) {
      this.logger.log('====> media:', media);
      if (!media.parentNode) {
        this.logger.log('~~~~~~~~~~~~~~~~~> error, media.parent', media.parentNode);
      }
      this.logger.log('=====> url:', url);
      player = new FlussonicMsePlayer(media, url, {
        // eslint-disable-next-line
        onMediaInfo: function(metadata) {
          _this.logger.log('====> metadata:', metadata);
        },
      });

      this.player = player;
      this.logger.log('=====> player:', this.player);
      if (this.props.autoPlay) {
        this.setPaused(player, false);
        return;
      }
    }
    if (player.url !== url && url) {
      player.stop().then(this._resetPlayerUrl);
    } else if (url) {
      this.setPaused(player, paused);
    }
  };

  _resetPlayerUrl = () => {
    const { url, paused } = this.props;
    const { player } = this;
    player.url = url;
    this.setPaused(player, paused);
  };

  getDuration = () => {
    if (this.ref && typeof this.ref.getDuration === 'function') {
      return this.ref.getDuration();
    }
    return Infinity;
  };

  setPaused = (player, paused) => {
    player = player || this.player;
    if (!player) {
      return
    }
    if (paused) {
      if (player.playing) {
        player.pause();
      }
    } else if (!player.playing) {
      player.play();
    }
  };

  onMedia = media => this.setState({ media });

  onMuted = muted => this._callPropsFunc('onMuted', muted);

  onPaused = paused => this._callPropsFunc('onPaused', paused);

  onVolumeChange = volume => this._callPropsFunc('onVolumeChange', volume);

  onControlsChange = controls => this._callPropsFunc('onControlsChange', controls);

  onRangeChange = (value) => {
    const { onRangeChange } = this.props;
    onRangeChange(value);
  };

  isLive = () => {
    const { url } = this.props;
    return url ? !/video-\d+-\d+/.test(url) : true;
  };

  _callPropsFunc = (func, ...args) => {
    if (typeof this.props[func] === 'function') {
      this.props[func](...args);
    }
  };

  onLoadStart = (...args) => {
    const { onLoadStart } = this.props;
    if (typeof onLoadStart === 'function') {
      onLoadStart(...args);
    }
  };

  onProgress = (...args) => {
    const { onProgress } = this.props;
    if (typeof onProgress === 'function') {
      onProgress(...args);
    }
  };

  render() {
    if (!this.state.domready) {
      return null;
    }
    const { media } = this.state;
    const {
      id,
      url,
      autoPlay,
      paused,
      volume,
      muted,
      controls,
      style,
      styleDIV,
      range = 1000,
      debug,
      hideSlider,
      isLive,
    } = this.props;
    this.setPlayer(media, url, paused);
    return (
      <VideoPlayer
        url={url}
        id={id}
        key={id}
        autoPlay={autoPlay}
        isLive={isLive}
        volume={volume}
        range={range}
        muted={muted}
        controls={controls}
        style={style}
        debug={debug}
        hideSlider={hideSlider}
        styleDIV={styleDIV}
        onMedia={this.onMedia}
        onMuted={this.onMuted}
        onPaused={this.onPaused}
        onVolumeChange={this.onVolumeChange}
        onControlsChange={this.onControlsChange}
        onRangeChange={this.onRangeChange}
        onLoadStart={this.onLoadStart}
        onProgress={this.onProgress}
        ref={(c) => {
          if (c && (typeof c.getWrappedInstance === 'function')) {
            this.ref = c.getWrappedInstance();
          } else {
            this.ref = c;
          }
        }}
      />
    );
  }
}

FLUPlayer.isSupported = FlussonicMsePlayer.isSupported;
Object.defineProperties(FLUPlayer, {
  name: {
    value: 'flussonic-mse-player',
    enumerable: true,
  },
  isFLU: {
    value: true,
    enumerable: true,
  },
  isFlu: {
    get() {
      return this.isFLU;
    },
    enumerable: true,
  },
});

FLUPlayer.defaultProps = {
  style: { width: '50%' },
  volume: 0.75,
  muted: false,
  controls: false,
  paused: true,
};

FLUPlayer.propTypes = {
  id: PropTypes.any,
  url: PropTypes.string,
  volume: PropTypes.number,
  range: PropTypes.number,
  muted: PropTypes.bool,
  controls: PropTypes.bool,
  autoPlay: PropTypes.bool,
  style: PropTypes.shape(),
  onMuted: PropTypes.func,
  onPaused: PropTypes.func,
  onVolumeChange: PropTypes.func,
  onControlsChange: PropTypes.func,
  onRangeChange: PropTypes.func.isRequired,
};

export default FLUPlayer;
