import React, { Component } from 'react';

import './style.scss';

export default class ErrorHandler extends Component {
  constructor(props) {
    super(props);

    this.state = {
      feedback: '',
      feedbackPromise: null,
    }
  }

  cleanStorage = (storage) => {
    if (!storage) {
      return;
    }
    let i, key;
    for (i = 0; i < storage.length; ++i) {
      key = storage.key(i);
      storage.removeItem(key);
    }
  }

  ReloadApp = () => {
    this.cleanStorage(window.localStorage);
    this.cleanStorage(window.sessionStorage);
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    window.location.replace(window.location.origin);
  }

  render() {
    return (
      <div className='bugsplat'>
        <svg className="reloadersvg" xmlns="http://www.w3.org/2000/svg" width="100" height="98" fill="none" viewBox="0 0 100 98">
          <path fill="#6E747E" d="M49.348 92.552c4.867 2.71 10.888 4.593 16.843 4.593 18.642 0 33.809-15.167 33.809-33.81 0-6.76-2.297-13.089-5.56-18.483-.892-1.476-20.943-37.004-41.37-40.782a3.39 3.39 0 0 0-3.859 2.343l-1.88 6.15a27.684 27.684 0 0 0-2.642-3.047C39.89 4.733 30.772-.789 14.59.094a3.39 3.39 0 0 0-3.12 2.626c-.198.86-4.03 18.012 1.112 29.926l-8.98-.56A3.39 3.39 0 0 0 .273 36.8c.166.386 4.14 9.6 12.097 21.09 7.37 10.644 20.129 25.28 36.98 34.66zM8.992 39.214l9.886.618a3.389 3.389 0 0 0 2.6-5.788c-6.02-5.98-5.13-19.943-3.898-27.263a42.1 42.1 0 0 1 .412-.002c22.798 0 26.683 14.868 26.838 15.504a3.39 3.39 0 0 0 6.547.241l3.353-10.971c7.767 2.903 16.002 11.418 22.583 19.855a33.658 33.658 0 0 0-11.122-1.881 33.63 33.63 0 0 0-12.66 2.46c-2.244-1.64-10.903-7.173-23.27-5.218a3.39 3.39 0 0 0-2.84 2.991c-.069.65-1.558 15.697 6.289 24.185a33.718 33.718 0 0 0-1.328 9.39c0 3.25.462 6.392 1.321 9.368-12.6-12.162-20.872-26.159-24.711-33.489zM36.79 46.66c-2.393-4.408-2.762-10.16-2.741-13.486l.082-.005c5.52-.289 9.852 1.227 12.546 2.574a34.061 34.061 0 0 0-9.887 10.917zm29.4 43.706c-14.903 0-27.028-12.125-27.028-27.03 0-14.903 12.125-27.029 27.029-27.029s27.03 12.126 27.03 27.03c0 14.904-12.126 27.03-27.03 27.03z"/>
          <path fill="#6E747E" d="M66.191 55.731a3.802 3.802 0 1 0 0-7.605 3.802 3.802 0 0 0 0 7.605zM81.401 55.731a3.802 3.802 0 1 0 0-7.605 3.802 3.802 0 0 0 0 7.605zM73.796 67.138a3.802 3.802 0 1 0 0-7.604 3.802 3.802 0 0 0 0 7.604z"/>
        </svg>
        <h2 style={{marginBottom: 30, textAlign: 'center'}}>App has stopped responding<br/>Please, press button below to continue or refresh page manually</h2>
        <button className="appreloader" onClick={this.ReloadApp}>Reload</button>
      </div>
    );
  }
}
