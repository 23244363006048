import React, { useState } from 'react';
import { Upload } from 'antd';
import paperclip from '../../../../images/elyt/Paperclip.png';
import close from '../../../../images/elyt/close.png';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

function FileUploader({ onChange }) {
  const [fileList, setFileList] = useState([]);
  const [error, setError] = useState(null);

  const onChangeImage = async (info) => {
    const { file, fileList } = info;
    if (file.status === 'uploading') {
      file.base64 = await getBase64(file.originFileObj);
      onChange(file.base64);
    }
    if (file.size > 1000000) {
      setError('Uploaded image is too big. Try again');
      return;
    }
    setFileList([file]);
  };

  return (
    <div className="l-upload">
      <Upload
        accept="image/jpeg,image/png"
        multiple={false}
        fileList={fileList}
        onChange={onChangeImage}
        showUploadList={false}
      >
        <div className="l-upload">
          <img src={paperclip}/>
          { fileList.length
            ? ''
            : <span>{error || 'Upload file'}</span>
          }
        </div>
      </Upload>
      {fileList.map(file => (
        <div key={file.name} className="l-file">
          <div>{file.name}</div>
          <img onClick={() => { setFileList(fileList.filter(elem => elem.name !== file.name)); }} src={close}/>
        </div>
      ))}
    </div>
  );
}

export default FileUploader;
