import React, { Component } from 'react';

import mobileframe from './images/mobileframe.png';
import ball from './images/icons/ball.png';
import camera from './images/icons/camera.png';
import frame from './images/icons/frame.png';
import currency from './images/icons/currency.png';

import videoframe from './images/videoframe.png';
import warn from './images/overlays/warn.png';

import result_webm from './videos/result.webm';
import result_mp4 from './videos/result.mp4';
import win2_webm from './videos/win2.webm';
import win2_mp4 from './videos/win2.mp4';
import b1_webm from './videos/b1.webm';
import b1_mp4 from './videos/b1.mp4';

import featurebg from './images/featurebg.png';

import arrowleft from './images/arrowleft.png';
import arrowright from './images/arrowright.png';

import './style.scss'

export default class FeatureShowcase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: 0,
      autoswitch: true,
      appear: true,
      started: false
    }

    this.timers = [5000, 10067, 14032, 8517]

    this.videos = {}
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.started) {
      if (Object.values(this.videos).every(v => v.playing)) {
        this.onSwitch(2)
        this.setState({ started: true })
      } else {
        setTimeout(() => {
          this.forceUpdate()
        }, 500)
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onSwitch(active) {
    clearInterval(this.interval)
    if (active < 0) active = 3
    if (active >= 4) active = 0
    this.setState({ active })
    let next = active + 1
    let prev = active - 1
    if (prev < 0) prev = this.timers.length - 1
    if (next == 4) next = 0
    for (let i in this.videos) {
      this.videos[i].pause()
      this.videos[i].currentTime = 0
    }
    const timer = this.timers[active]
    if (this.videos[active]) {
      this.videos[active].currentTime = 0
      this.videos[active].play()
    }
    this.interval = setInterval(() => {
      this.onSwitch(next)
    }, timer)
  }

  renderItems = () => {
    const { intl, history } = this.props
    const { active } = this.state
    return (
      <>
        <div className={`item ${active === 0 ? 'active' : ''}`} onClick={() => { this.onSwitch(0) }}>
          <div className='imgwrap'><img src={ball} /></div>
          <div className='textwrap'>
            <span className='title'>{intl.formatMessage({ id: 'LandingFeature1', defaultMessage: 'Make your shot' })}</span>
            <span className='text'>{intl.formatMessage({ id: 'LandingFeature1t', defaultMessage: 'Make your shots – Your opponent will see you on his phone.' })}</span>
          </div>
        </div>
        <div className={`item ${active === 1 ? 'active' : ''}`} onClick={() => { this.onSwitch(1) }}>
          <div className='imgwrap'><img src={frame} /></div>
          <div className='textwrap'>
            <span className='title'>{intl.formatMessage({ id: 'LandingFeature2', defaultMessage: 'Type your result' })}</span>
            <span className='text'>{intl.formatMessage({ id: 'LandingFeature2t', defaultMessage: 'Input your frame result' })}</span>
          </div>
        </div>
        <div className={`item ${active === 2 ? 'active' : ''}`} onClick={() => { this.onSwitch(2) }}>
          <div className='imgwrap'><img src={camera} /></div>
          <div className='textwrap'>
            <span className='title'>{intl.formatMessage({ id: 'LandingFeature3', defaultMessage: 'Watch for opponent' })}</span>
            <span className='text'>{intl.formatMessage({ id: 'LandingFeature3t', defaultMessage: 'Watch your opponent’s shots on your screen.' })}</span>
          </div>
        </div>
        <div className={`item ${active === 3 ? 'active' : ''}`} onClick={() => { this.onSwitch(3) }}>
          <div className='imgwrap'><img src={currency} /></div>
          <div className='textwrap'>
            <span className='title'>{intl.formatMessage({ id: 'LandingFeature4', defaultMessage: 'Win money' })}</span>
          </div>
        </div>
      </>
    )
  }

  beginTouch = e => {
    this.setState({ beginTouch: e.clientX || e.changedTouches[0].clientX, touched: true })
  }

  startTouch = e => {
    if (this.state.touched) {
      this.setState({ moved: true })
    }
  }

  endTouch = e => {
    const { beginTouch, active, touched, moved } = this.state
    if (!touched && !moved) return
    const endTouch = e.clientX || e.changedTouches[0].clientX
    if (Math.abs(beginTouch - endTouch) < 50) return 
    if (beginTouch > endTouch) {
      this.onSwitch(+active - 1)
    }
    if (beginTouch < endTouch) {
      this.onSwitch(+active + 1)
    }
    this.setState({ touched: false, moved: false })
  }

  render() {
    const { intl } = this.props;
    const { active, appear, touched } = this.state
    if (appear) this.setState({ appear: false })
    let zIndex = 0
    switch (active) {
      case 1: 
      case 2: 
        zIndex = 4
        break
      default: 
        zIndex = 2
    }
    return (
      <div className={`features fullcontainer${touched ? ' touched' : ''}`}
        onMouseDown={this.beginTouch}
        onMouseMove={this.startTouch}
        onMouseUp={this.endTouch}
        onMouseLeave={this.endTouch}
        onTouchStart={this.beginTouch}
        onTouchMove={this.startTouch}
        onTouchEnd={this.endTouch}
      >
        <h2>{intl.formatMessage({ id: 'LandingHowItWorks', defaultMessage: 'How it works?' })}</h2>
        <div className='containerbox'>
          <div className='representation'>
            <div className='bubble' />
            <div className='bubble' />
            <div className='underlay'>
              <div style={{ width: 352 }}></div>
              {(() => {
                let body = <></>;
                switch (active) {
                  case 0:
                  case 1:
                  case 2:
                    body = <img src={featurebg} />
                    break;
                }
                return body;
              })()}
            </div>
            <img className={`frameimg ${[0, 1, 2].includes(active) ? 'toned' : ''}`} src={mobileframe} />
            <div className='overlay'>
              {(() => {
                let body = <></>;
                switch (active) {
                  case 0:
                    body = <img key={'0'} src={warn} style={{ top: '9%', width: '130%' }} className={appear ? 'appear' : ''} />
                    break;
                  case 1:
                    break;
                  case 2:
                    body = 
                    <div className={`content ${appear ? 'appear' : ''}`} style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'flex-end', bottom: '21%' }}>
                      <img style={{ width: '150%' }} key={active} src={videoframe} />
                    </div>
                    break;
                  default:
                  case 3:
                    body = <></>
                    break;
                }
                return body;
              })()}
            </div>
            <div className='overlay' style={{zIndex}}>
              <video ref={ref => { this.videos[3] = ref }} 
                className={`item ${active == 3 ? 'active' : ''}`} key={`3`} 
                muted loop webkit-playsinline="true" playsinline="true" autoplay
              >
                <source src={win2_webm} />
                <source src={win2_mp4} />
              </video>
              <video ref={ref => { this.videos[1] = ref }} key={'1'} 
                className={`result item ${active == 1 ? 'active' : ''}`} 
                muted loop webkit-playsinline="true" playsinline="true" autoplay 
                style={{ 
                  bottom: active == 1 ? '4%' : 0,
                  width: '112%', 
                  borderRadius: 5,
                  padding: 0,
                  height: 'auto' 
                }}
              >
                <source src={result_webm} />
                <source src={result_mp4} />
              </video>
              <video ref={ref => { this.videos[2] = ref }} key={`2`}
                className={`b1 item ${active == 2 ? 'active' : ''}`} 
                muted loop webkit-playsinline="true" playsinline="true" autoplay 
                style={{ 
                  position: 'absolute',
                  width: '130.7%',
                  borderRadius: 10,
                  bottom: active == 2 ? '24.5%' : 0,
                  padding: 0,
                  height: 'auto'
                }}>
                <source src={b1_webm} />
                <source src={b1_mp4} />
              </video>
            </div>
          </div>
          <div className='captions'>
            {this.renderItems()}
          </div>
          <div className="mobile-captions">
            {this.renderItems()}
          </div>
          <div className="slider">
            <div className='control'>
              <div className='left' onClick={() => { this.onSwitch(+active - 1) }}><img src={arrowleft} /></div>
              <div className="center">
                <span className='numcur'>{(active + 1).toString().padStart(2, '0')}</span>
                <span className='numall'>/04</span>
              </div>
              <div className='right' onClick={() => { this.onSwitch(+active + 1) }}><img src={arrowright} /></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}