import React from 'react';
import { Tooltip } from 'antd';

// import 'antd/lib/tooltip/style';
import './style.scss';

const TooltipComponent = (props) => {
  return (
    <Tooltip
      {...props}
    >
      {props.children}
    </Tooltip>
  );
};

export default TooltipComponent;
