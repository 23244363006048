import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import './style.scss';
import Checkbox from '../../common/Checkbox';
import {Button} from "../../common/Button";
import {getActiveUserCount, getMaxWatcherCount, saveMaxWatcherCount} from '../../../libs/reducers/actions';
import SquadCount from "./SquadCount";
import {EditableValue} from "../handicapsTab";

const WatchingUser = ({
                        userActiveCount,
                        getActiveUserCount,
                        getMaxWatcherCount,
                        maxWatcherCount,
                        saveMaxWatcherCount
                      }) => {
  const [stateWatcherCount, setMaxWatcherCount] = useState(maxWatcherCount == '9999' ? 100 : maxWatcherCount);
  const [isEdited, setIsEdited] = useState(true);
  const [checked, setChecked] = useState(maxWatcherCount == '9999'? false : true);

  const unblockButtonInEdit = () => {
    setIsEdited(false);
  };
  useEffect(() => {
    getActiveUserCount();
    getMaxWatcherCount();
  }, [getActiveUserCount, getMaxWatcherCount]);

  return (
    <div className='watching_user container'>
      <p className='textLabel'>Active users</p>
      <div className='watchingUserProperty'>
        <p>Total number of active users</p>
        <p>{userActiveCount}</p>
      </div>
      <div className='watchingUserProperty'>
        <p>Total number of viewers</p>
        <SquadCount/>
      </div>
      <p className='textGrey'>Maximum number of viewers of online games</p>
      <Checkbox
        checked={(checked)}
        onChange={e => setChecked(!checked)}
      >Limit the number of viewers</Checkbox>
      {checked && <div className='watchingUserProperty'>
        <p>Maximum number of viewers</p>
        <EditableValue className={'countName'}
                       value={stateWatcherCount}
                       onEdit={value => setMaxWatcherCount(value, unblockButtonInEdit())}
        />
      </div>}
      <Button className='btnSave'
              onClick={() => saveMaxWatcherCount(checked ? stateWatcherCount : '9999')}
              disabled={!checked ? !isEdited : isEdited}
      >
        Save
      </Button>
    </div>
  )
}

export default connect(state => ({
    userActiveCount: state.users.userActiveCount,
    maxWatcherCount: state.users.maxWatcherCount,
  }),
  {
    getActiveUserCount,
    getMaxWatcherCount,
    saveMaxWatcherCount,
  }
)(WatchingUser);