import React from 'react';
import NotAvailable from './common/notAvailable';

function Participants({
  history,
}) {
  return (
    <div>
      <NotAvailable/>
      <div className="info-block white-ib">
        <div className="info">To be able to participate in the Tournament, the federations willing to join should ensure they have a bowling center, equipped by TenPinCam.</div>
        <div className="info right" onClick={() => history.push('/#form_equipment')}>
          <div>If you want to install TenPinCam equipment – fill the form</div>
          <div className="arrow">
            <svg viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M65 6V65M65 65H6M65 65L1 1" stroke="#FFFFFF" strokeWidth="2"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Participants;
