import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { Table } from 'antd';
import { getWalletHistory } from '../../../libs/reducers/actions';
import Select from '../../common/Select';
import moment from 'moment';
import './style.scss';

import battleIn from '../../../images/transactionIcons/battleIn.svg';
import battleOut from '../../../images/transactionIcons/battleOut.svg';
import externalIn from '../../../images/transactionIcons/externalIn.svg';
import externalOut from '../../../images/transactionIcons/externalOut.svg';
import tariff from '../../../images/transactionIcons/tariff.svg';
import refund from '../../../images/transactionIcons/refund.svg';
import ibanIn from '../../../images/transactionIcons/iban_blue.svg';
import ibanOut from '../../../images/transactionIcons/iban_red.svg';
import ibanRefund from '../../../images/transactionIcons/iban_x.svg';

class WalletHistory extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      wallet: false,
    }
  }

  componentDidMount() {
    const { user } = this.props;
    this.props.getWalletHistory();
    const walletObj = user.is_adult
      ? this.props.walletHistory.find(w => w.currency === 'EUR')
      : this.props.walletHistory.find(w => w.currency === 'DIG');

    if (walletObj) {
      this.setState({ wallet: { label: walletObj.currency, value: walletObj.id } });
    }
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    if (prevProps.walletFetch !== this.props.walletFetch) {
      const walletObj = user.is_adult
        ? this.props.walletHistory.find(w => w.currency === 'EUR')
        : this.props.walletHistory.find(w => w.currency === 'DIG');

      if (walletObj) {
        this.setState({ wallet: { label: walletObj.currency, value: walletObj.id } });
      }
    }
  }

  render() {
    const { walletHistory, intl } = this.props;
    const { wallet } = this.state;

    const columns = [
      {
        title: 'ID',
        dataIndex: 'transactionid',
        render: data => <>#{data}</>
      },
      {
        title: intl.formatMessage({ id: 'WalletHistoryOperationType', defaultMessage: 'Operation' }),
        dataIndex: 'type',
        render: (data, record) => <>
          <div className='transactionIcon'><img src={(() => {
            switch (record.paymentsystem) {
              case 'IBAN':
                switch (record.type) {
                  case 'intake-refund':
                  case 'withdraw-refund':
                    return ibanRefund;
                  case 'manual':
                  case 'external':
                  case 'withdraw':
                  case 'intake':
                    return record.incomeaccountid == this.state.wallet.value ? ibanIn:ibanOut;
                  case 'recurring':
                    return tariff;
                }
              default:
                switch (record.type) {
                  case 'intake-refund':
                  case 'withdraw-refund':
                    return refund;
                  case 'manual':
                  case 'external':
                  case 'withdraw':
                  case 'intake':
                    return record.incomeaccountid == this.state.wallet.value ? externalIn:externalOut;
                  case 'battle_create':
                  case 'battle_join':
                  case 'battle_reject':
                  case 'battle_end':
                  case 'battle_failed':
                  case 'battle_resolved':
                    return record.incomeaccountid == this.state.wallet.value ? battleIn:battleOut;
                  case 'recurring':
                    return tariff;
                }
            }
          })()}/></div>
        </>,
      },
      {
        title: intl.formatMessage({ id: 'date', defaultMessage: 'Date' }),
        dataIndex: 'created',
        render: (data, record) => <>
          {moment(record.created).format('DD.MM.YY HH:mm')}
        </>
      },
      {
        title: intl.formatMessage({ id: 'WalletHistoryAmount', defaultMessage: 'Amount' }),
        dataIndex: 'amount',
        render: (data, record) => 
          record.incomeaccountid == this.state.wallet.value ?
            <div className='align-right'>+{record.amount}{record.symbol}</div>
          :
            <div className='align-right'>-{record.amount}{record.symbol}</div>,
      },
      {
        title: intl.formatMessage({ id: 'CommissionHistoryTable', defaultMessage: 'Commission' }),
        dataIndex: 'commission',
        render: (data, record) => 
          record.incomeaccountid == this.state.wallet.value ?
            <div className='align-right'>{record.commission ? `${record.commission}${record.symbol}` : ''}</div>
          :
            <div className='align-right'>{record.commission ? `${record.commission}${record.symbol}` : ''}</div>,
      },
      {
        title: intl.formatMessage({ id: 'ActionAsNoun', defaultMessage: 'Action' }),
        dataIndex: 'type',
        render: (data, record) => <>
          {(() => {
            const cardsystems = ['LPB'];
            let battleId = '';
            if (['battle_create', 'battle_join', 'battle_reject', 'battle_end', 'battle_failed', 'battle_resolved'].includes(record.type)) {
              battleId = record.comment ? ` #${record.comment}` : '';
            }
            switch (record.type) {
              case 'intake-refund':
              case 'withdraw-refund':
                return (record.paymentsystem?`(${cardsystems.includes(record.paymentsystem)?'CARD':record.paymentsystem})`:'')+
                       (record.incomeaccountid == this.state.wallet.value ? intl.formatMessage({ id: 'TransactionRefundOut', defaultMessage: 'Withdraw transaction refund:' }) : intl.formatMessage({ id: 'TransactionRefundIn', defaultMessage: 'Intake transaction refund:' }))+
                       (` ${record.amount.toFixed(2)} ${record.currency}`)+
                       (record.creditcard?` (${record.creditcard})`:'');
              case 'manual':
              case 'external':
              case 'withdraw':
              case 'intake':
                return (record.paymentsystem?`(${cardsystems.includes(record.paymentsystem)?'CARD':record.paymentsystem}) `:'')+
                       (record.incomeaccountid == this.state.wallet.value ? intl.formatMessage({ id: 'FundFromCard', defaultMessage: 'Funds replenishment:' }) : intl.formatMessage({ id: 'FundToCard', defaultMessage: 'Funds withdraw:' }))+
                       (` ${record.amount.toFixed(2)} ${record.currency}`)+
                       (record.creditcard?` (${record.creditcard})`:'');
              case 'battle_create':
                return intl.formatMessage({ id: 'BattleCreationAsNoun', defaultMessage: 'Battle creation' });
              case 'battle_join':
                return intl.formatMessage({ id: 'BattleParticipationAsNoun', defaultMessage: 'Blocked due to participation in the Battle' }) + battleId;
              case 'battle_reject':
                return intl.formatMessage({ id: 'BattleLeaveAsNoun', defaultMessage: 'Cancelation from Battle' }) + battleId;
              case 'battle_end':
                return record.incomeaccountid == this.state.wallet.value 
                  ? intl.formatMessage({ id: 'BattleWinAsNoun', defaultMessage: 'Win in Battle' }) + ' #' + record.comment
                  : intl.formatMessage({ id: 'BattleParticipationAsNoun', defaultMessage: 'Blocked due to participation in the Battle' }) + ' #' + record.comment;
              case 'battle_failed':
              case 'battle_resolved':
                return intl.formatMessage({ id: 'RefereeChangesOfTransactionAsNoun', defaultMessage: 'Deductions in accordance with the decision of the judge' });
              case 'recurring':
                return record.incomeaccountid == this.state.wallet.value ? intl.formatMessage({ id: 'TariffPaybackAsNoun', defaultMessage: 'Compensation for unused days according to tariff' }):intl.formatMessage({ id: 'TariffPayAsNoun', defaultMessage: 'Tariff buy' });
              default:
            }
          })()}
        </>,
      },
      {
        title: () => <div className='currencyPicker'>{intl.formatMessage({ id: 'Currency', defaultMessage: 'Currency' })} <Select
                  value={this.state.wallet}
                  onChange={e => this.setState({ wallet: e })}
                  options={(this.props.walletHistory || []).filter(w => this.props.user.is_adult || w.currency !== 'EUR').map(w => ({ label: w.currency, value: w.id }))}
                  /></div>,
        dataIndex: '',
        render: () => <></>,
      }
    ];

    return <>
      <Table
        className="walletHistoryTable"
        columns={columns}
        dataSource={(((walletHistory||[]).find(w => w.id===wallet.value)||{}).transactions||[]).sort((a,b)=>b.transactionid-a.transactionid)}
        pagination={false}
        rowKey="id"
        style={{ width: '100%' }}
        locale={{ emptyText: intl.formatMessage({ id: 'EmptyTransactionHistory', defaultMessage: 'History is empty' }) }}
        rowClassName={'walletHistoryTransaction'}
      />
    </>
  }
}

export default injectIntl(connect(
  state => ({
    walletHistory: state.billing.walletHistory,
    user: state.users.user,
    walletFetch: state.billing.fetchingHistory,
  }), { getWalletHistory }
)(WalletHistory));