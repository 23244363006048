import React, { Component } from 'react';
import { Player, ControlBar, Shortcut, BigPlayButton, FullscreenToggle } from 'video-react';
import HLSSource from './HLSSource.js';
import Utils from '../../libs/utils';
import config from '../../config';

export default class VideoArchivePlayer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      src: props.src + `?token=${encodeURIComponent(config.FETCH.fullUrl)}_${((props.user||{}).session||{}).sessionkey}`,
      width: props.width,
      height: props.height,
      muted: props.muted || false,
      aspectRatio: props.aspectRatio || 'auto',
    }

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = { ...prevState }
    if (nextProps.src != prevState.src) {
      state = { ...state, src: `${nextProps.src}?token=${encodeURIComponent(config.FETCH.fullUrl)}_${((nextProps.user||{}).session||{}).sessionkey}` };
    }
    if (nextProps.width != prevState.width || nextProps.height != prevState.height) {
      state = { ...state, width: nextProps.width, height: nextProps.height };
    }
    if (nextProps.muted != prevState.muted) {
      state = { ...state, muted: nextProps.muted };
    }
    return state;
  }

  render () {
    const { src, width, height, muted, aspectRatio} = this.state;

    // Чтобы наверняка отправлять токен
    if (!src || !this.props.user) {
      return <></>
    }

    if (Utils.isSafari() || Utils.iOS()) {
      return (
        <Player 
          ref="player"
          fluid={!(!!width && !!height)} 
          width={width} height={height}
          aspectRatio={aspectRatio}
          autoPlay={Utils.iOS()}
          volume={100}
          muted={muted}
          playsInline
        >
          <HLSSource
            isVideoChild
            src={src}
          />
          <ControlBar disableCompletely/>
          <Shortcut clickable={false}/>
          <BigPlayButton position="center"/>
        </Player>
      );
    } else {
      return (
        <Player 
          ref="player"
          fluid={!(!!width && !!height)} 
          width={width} height={height}
          aspectRatio={aspectRatio}
          volume={100}
          muted={muted}
        >
          <ControlBar disableCompletely/>
          <Shortcut clickable={false}/>
          <BigPlayButton position="center"/>
          <HLSSource
            isVideoChild
            src={src}
          />
        </Player>
      );
    }
  }
  
};