import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from '../../common/Slider';
import './slider.scss';

class VideoSlider extends Component {
  static propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    min: 0,
    max: 1000,
    value: 0,
  };

  render() {
    const { min, max, value } = this.props;
    return <Slider {...this.props} min={min} max={max} value={value} className="video-slider" />;
  }
}

export default VideoSlider;
