import { put, call } from 'redux-saga/effects';
import config from '../../config';
import { get, post, put as putMethod } from '../Networking';

const {
  SET_PROTOCOL,
} = require('../reducers/actiontypes').default;

export function* _getProtocol(params) {
  const { squadid } = params.params;
  try {
    const url = `${config.FETCH.url}/protest/protocol/${squadid}`;
    const result = yield call(get, { url });
    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_PROTOCOL, payload: result.data.data });
    } else {
      yield put({ type: SET_PROTOCOL, error: result.data.error || result.status });
    }
  } catch (err) {
    yield put({ type: SET_PROTOCOL, error: err });
  }
}

export function* _makeResolve(params) {
  const { params: data } = params;
  try {
    const url = `${config.FETCH.url}/protest/resolve`;
    const result = yield call(putMethod, { url, data });
    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_PROTOCOL, payload: result.data.data });
    } else {
      yield put({ type: SET_PROTOCOL, error: result.data.error || result.status });
    }
  } catch (err) {
    yield put({ type: SET_PROTOCOL, error: err });
  }
}