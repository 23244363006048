import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

const SquadCount = ({ count  = 0 }) => {

  const [watchers, setWatchers] = useState(0);
  useEffect(() => {
    let sum = 0;
    count.map(countWatcher => {
      sum += +countWatcher.countWatcher;
    });
    setWatchers(sum);
  }, [count]);

  return (
    <p>{watchers}</p>
  )
};

export default connect(state => ({
  count: state.battles.squadsWatcher
}), {})(SquadCount)