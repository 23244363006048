import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import './style.css';

export default class LoginButton extends Button {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.string,
    ]).isRequired,
    loading: PropTypes.bool,
  }

  render() {
    const { children } = this.props;

    return (
      <Button { ...this.props } className='button login-button'>
        {children}
      </Button>
    );
  }
}
