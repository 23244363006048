import React, { Component } from 'react';
import {FormattedMessage} from 'react-intl';

import person from './images/icons/person.png';
import pin from './images/icons/pin.png';
import prize from './images/icons/prize.png';
import terra from './images/icons/terra.png';
import currency from './images/icons/currency.png';

import mobileframe from './images/mobileframe.png';

import battle_webm from './videos/battle.webm'
import battle_mp4 from './videos/battle.mp4'
import bowcen_webm from './videos/bowcen.webm'
import bowcen_mp4 from './videos/bowcen.mp4'
import game3_webm from './videos/game3.webm'
import game3_mp4 from './videos/game3.mp4'
import splash_webm from './videos/splash.webm'
import splash_mp4 from './videos/splash.mp4'
import win_webm from './videos/win.webm'
import win_mp4 from './videos/win.mp4'

import arrowleft from './images/arrowleft.png';
import arrowright from './images/arrowright.png';

import './style.scss'
import './adaptive.scss'

export default class ApplicationShowcase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: 0,
      autoswitch: true,
      appear: true,
      started: false,
      touched: false, 
      beginTouch: 0, 
      endTouch: 0,
      moved: false
    }

    this.videos = []
    this.top = []
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.started) {
      if (this.videos.every(v => v.playing)) {
        this.onSwitch(0)
        this.setState({ started: true })
      } else {
        setTimeout(() => {
          this.forceUpdate()
        }, 500);
      }
    }
  }

  onSwitch(active) {
    clearInterval(this.interval)
    if (active < 0) active = this.videos.length - 1
    if (active >= this.videos.length) active = 0
    this.setState({ active })
    let prev = active - 1
    let next = active + 1
    if (prev < 0) prev = this.videos.length - 1
    if (next == this.videos.length) next = 0
    this.videos.forEach(video => {
      video.pause()
      video.currentTime = 0
    })
    const timer = this.videos[active].duration * 1000
    this.videos[active].currentTime = 0
    this.videos[active].play()
    this.interval = setInterval(() => {
      this.onSwitch(next)
    }, timer)
  }

  onClickDownload = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
      let host = (window.location.href.match(/\w+:\/\/[\w:-]+\//) || [''])[0];

      if (host[host.length - 1] !== '/') {
        host += '/';
      }

      window.location.href=`${host}mobileapp`;
    } else {
      this.props.scrollToDownloadBlock();
    }

    if (window.ym) {
      const ymKey = 52252669;
      window.ym(ymKey, 'reachGoal', 'MobileClickDownload');
    }
  }

  renderItems = () => {
    const { intl, history } = this.props;
    const { active } = this.state;

    return (
      <>
        <div className={`item ${active === 0 ? 'active' : ''}`} onClick={() => { this.onSwitch(0) }}>
          <div className='imgwrap'><img src={person} /></div>
          <div className='textwrap'>
            {this.props.isMobile
              ? (
                <>
                  <span className='title'>
                    <img src={person} />
                    {intl.formatMessage({
                      id: 'LandingDownloadMobileApp',
                      defaultMessage: 'Download TenPinCam mobile App',
                    })}
                  </span>
                  <span className='text'>
                    <a
                      href="/howtoinstall"
                      onClick={this.onClickDownload}
                      className="btn small filled"
                      style={{ width: 200 }}
                    >{intl.formatMessage({
                      id: 'LandingDownload',
                      defaultMessage: 'Download'
                    })}</a>
                  </span>
                </>
              ) : (
                <div className='textwrap'>
                  <span className='title'>
                    <img src={person} />
                    {intl.formatMessage({ id: 'LandingFeature1B', defaultMessage: 'Register on TenPinCam' })}
                  </span>
                  <span className='text'>
                    <div onClick={() => { history.push('/registration') }} className="btn small filled" style={{ width: 200 }}>{intl.formatMessage({ id: 'LandingRegister', defaultMessage: 'Register' })}</div>
                  </span>
                </div>
              )
            }
          </div>
        </div>
        <div className={`item ${active === 1 ? 'active' : ''}`} onClick={() => { this.onSwitch(1) }}>
          <div className='imgwrap'><img src={terra} /></div>
          <div className='textwrap'>
            <span className='title'>
              <img src={terra} />
              <FormattedMessage
                id='LandingGoToAlley'
                defaultMessage='Go to alley with {link} and choose opponent'
                values={{ link: <a href="/#list_of_alleys" onClick={(e) => { e.preventDefault(); this.props.scrollToAlleyList(); }}>
                  {intl.formatMessage({
                    id: 'LandingTenPinCamEquipment',
                    defaultMessage: 'LandingTenPinCamEquipment',
                  })}
                </a> }}
              />
            </span>
            <span className='text'>{intl.formatMessage({ id: 'LandingFeature2tB', defaultMessage: 'Challenge someone you know, or find a new opponent in our TenPinCam bowler community' })}</span>
          </div>
        </div>
        <div className={`item ${active === 2 ? 'active' : ''}`} onClick={() => { this.onSwitch(2) }}>
          <div className='imgwrap'><img src={prize} /></div>
          <div className='textwrap'>
            <span className='title'>
              <img src={prize} />
              {intl.formatMessage({ id: 'LandingPlayBattleTournament', defaultMessage: 'Play the Battle or Join the Tournament' })}
            </span>
            <span className='text'>{intl.formatMessage({ id: 'LandingFeature3tB', defaultMessage: 'Play Battles from your bowling Alley with the partner with the opponent in another city.' })}</span>
          </div>
        </div>
        <div className={`item ${active === 3 ? 'active' : ''}`} onClick={() => { this.onSwitch(3) }}>
          <div className='imgwrap'><img src={currency} /></div>
          <div className='textwrap'>
            <span className='title'>
              <img src={currency} />
              {intl.formatMessage({ id: 'LandingWinPrizes', defaultMessage: 'Win prizes' })}
            </span>
            <span className='text'>{intl.formatMessage({ id: 'LandingFeature4tB', defaultMessage: 'Play for free with your friends, or win Money Prizes' })}</span>
          </div>
        </div>
        <div className={`item ${active === 4 ? 'active' : ''}`} onClick={() => { this.onSwitch(4) }}>
          <div className='imgwrap'><img src={pin} /></div>
          <div className='textwrap'>
            <span className='title'>
              <img src={pin} />
              {intl.formatMessage({ id: 'LandingFeature5B', defaultMessage: 'Where to play' })}
            </span>
            <div className='text'>
              <div onClick={() => { this.props.scrollToEquipmentForm() }} className="btn small filled">{intl.formatMessage({ id: 'LandingFeature5Bb1', defaultMessage: 'Install tenpincam in your alley' })}</div>
              <div onClick={() => { this.props.scrollToAlleyList() }} className="btn small bordered">{intl.formatMessage({ id: 'LandingFeature5Bb2', defaultMessage: 'See list of TenPinCam enabled Alleys' })}</div>
            </div>
            <span className='text' style={{ display: 'none' }}>
              <a onClick={() => { history.push('/login') }}>{intl.formatMessage({ id: 'LandingFeature5Bb3', defaultMessage: 'Learn more how to install TenPinCam in your Alley' })}</a>
            </span>
          </div>
        </div>
      </>
    )
  }

  beginTouch = e => {
    this.setState({ beginTouch: e.clientX || e.changedTouches[0].clientX, touched: true })
  }

  startTouch = e => {
    if (this.state.touched) {
      this.setState({ moved: true })
    }
  }

  endTouch = e => {
    const { beginTouch, active, touched, moved } = this.state
    if (!touched && !moved) return
    const endTouch = e.clientX || e.changedTouches[0].clientX
    if (Math.abs(beginTouch - endTouch) < 50) return 
    if (beginTouch > endTouch) {
      this.onSwitch(+active - 1)
    }
    if (beginTouch < endTouch) {
      this.onSwitch(+active + 1)
    }
    this.setState({ touched: false, moved: false })
  }

  render() {
    console.log('render applicationshowcase')
    const { intl } = this.props;
    const { active, appear, touched } = this.state;
    if (appear) this.setState({ appear: false })
    switch (+active) {
      case 1:
        this.top = [200, 0, -200, 0, 0]
        break;
      case 2:
        this.top = [0, 200, 0, -200, 0]
        break;
      case 3:
        this.top = [0, 0, 200, 0, -200]
        break;
      case 4:
        this.top = [-200, 0, 0, 200, 0]
        break;
      default:
        this.top = [0, 0, 0, 0, 0]
        break;
    }
    return (
      <div
        id="playing_ease"
        className={`application fullcontainer${touched ? ' touched' : ''}`}
        onMouseDown={this.beginTouch}
        onMouseMove={this.startTouch}
        onMouseUp={this.endTouch}
        onMouseLeave={this.endTouch}
        onTouchStart={this.beginTouch}
        onTouchMove={this.startTouch}
        onTouchEnd={this.endTouch}
      >
        <h2>{intl.formatMessage({ id: 'LandingFeature', defaultMessage: 'Playing TenPinCam is easy' })}</h2>
        <div className='containerbox'>
          <div className='captions'>
            {this.renderItems()}
          </div>
          <div className='representation'>
            <div className='bubble' />
            <div className='underlay'>
              <div style={{ width: 352 }}></div>
              <video id="splash_video" ref={ref => this.videos[0] = ref} key={'0'} className={active == 0 ? 'active' : ''} style={{ top: this.top[0] }} muted loop webkit-playsinline="true" playsinline="true" autoplay>
                <source src={splash_webm} />
                <source src={splash_mp4} />
              </video>
              <video ref={ref => this.videos[1] = ref} key={'1'} className={active == 1 ? 'active' : ''} style={{ top: this.top[1] }} muted loop webkit-playsinline="true" playsinline="true" autoplay>
                <source src={battle_webm} />
                <source src={battle_mp4} />
              </video>
              <video ref={ref => this.videos[2] = ref} key={'2'} className={active == 2 ? 'active' : ''} style={{ top: this.top[2] }} muted loop webkit-playsinline="true" playsinline="true" autoplay>
                <source src={game3_webm} />
                <source src={game3_mp4} />
              </video>
              <video ref={ref => this.videos[3] = ref} key={'3'} className={active == 3 ? 'active' : ''} style={{ top: this.top[3] }} muted loop webkit-playsinline="true" playsinline="true" autoplay>
                <source src={win_webm} />
                <source src={win_mp4} />
              </video>
              <video ref={ref => this.videos[4] = ref} key={'4'} className={active == 4 ? 'active' : ''} style={{ top: this.top[4] }} muted loop webkit-playsinline="true" playsinline="true" autoplay>
                <source src={bowcen_webm} />
                <source src={bowcen_mp4} />
              </video>
            </div>
            <img className={`frameimg ${[].includes(active) ? 'toned' : ''}`} src={mobileframe} />
          </div>
          <div className="mobile-captions">
            {this.renderItems()}
          </div>
          <div className="slider">
            <div className='control'>
              <div className='left' onClick={() => { this.onSwitch(+active - 1) }}><img src={arrowleft} /></div>
              <div className="center">
                <span className='numcur'>{(active + 1).toString().padStart(2, '0')}</span>
                <span className='numall'>/05</span>
              </div>
              <div className='right' onClick={() => {this.onSwitch(+active + 1)}}><img src={arrowright} /></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}