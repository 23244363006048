import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Button } from '../../components/common/Button';
import Avatar from '../../components/common/Avatar';

class ReadinessInput extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			agreed: false,
		}
	}

	componentDidMount() {
		this.checkAgreement();
	}
	componentDidUpdate(prevProps) {
		if (prevProps.game !== this.props.game) {
			this.checkAgreement();
		}
	}

	checkAgreement() {
		const competitor = this.props.players.find(player => player.userid === this.props.users.user.id);
		if (!competitor) return
		if (this.props.game.confirmations[competitor.id]) this.setState({ agreed: true })
		else this.setState({ agreed: false })
	}

	render() {
		const { players, game, Accepted, Protested, Edited, intl } = this.props;

		return (
			<div className="finish__inner">
				<div className="finish__readiness">
					<div className="finish__text">
						{intl.formatMessage({ id: 'EndGameConfirms', defaultMessage: 'Players confirmations' })}
					</div>
					{players.map(player => {
						return <>
							<div className={`finish__playercard${player.userid === this.props.users.user.id ? ' owner' : ''}`}>
								{ !game.confirmations[player.id]
									? <Avatar uri={player.photo} isOnline={player.active}/>
									: <div className="finish__check-opponent">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" fill="none" viewBox="0 0 16 13">
										<path fill="#fff" fillRule="evenodd" d="M15.633.226a1 1 0 0 1 .14 1.407l-8.999 11a1 1 0 0 1-1.514.04l-5-5.5a1 1 0 0 1 1.48-1.346l4.22 4.643L14.226.367a1 1 0 0 1 1.407-.14z" clipRule="evenodd"/>
										</svg>
									</div>
								}
								<span>{ `${player.firstname} ${player.lastname}`.trim() || player.login }</span>
							</div>
						</>
					})}
				</div>
				{!this.state.agreed?<div className="finish__info">
					<div className="finish__text">
						{intl.formatMessage({ id: 'EndgameSurvey', defaultMessage: 'Do you agree with the game results?' })}
					</div>
					<div className="finish__buttons">
						<div className="finish__buttonaccess">
							<Button size="md" glowing onClick={() => {Accepted();this.setState({ agreed: true })}}>
							{intl.formatMessage({ id: 'AgreeAsConfirmationAlias', defaultMessage: 'Agree' })}
							</Button>
						</div>
						<div className="finish__buttonedit">
							<Button size="md" bordered="true" onClick={() => Edited()}>
								{intl.formatMessage({ id: 'EditAsCorrectionAlias', defaultMessage: 'Edit' })}
							</Button>
							<Button size="md" bordered="true" onClick={() => Protested()}>
								{intl.formatMessage({ id: 'ProtestAsGameComplaintAlias', defaultMessage: 'Protest' })}
							</Button>
						</div>
					</div>
				</div>:null}
			</div>
		);
	}
}

export default injectIntl(
	withRouter(
	  connect(
		state => ({
			users: state.users,
		}),
		{
		},
	  )(ReadinessInput),
	),
  );