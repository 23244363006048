import React from 'react';
import {
  Switch, Route, Redirect, withRouter,
} from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { parse, DOM } from 'xml-parse';
import 'intl';// https://stackoverflow.com/questions/41736735/react-native-and-intl-polyfill-required-on-android-device
import { IntlProvider, addLocaleData } from 'react-intl';
import localeRu from 'react-intl/locale-data/ru';
import localeEn from 'react-intl/locale-data/en';
import localeEs from 'react-intl/locale-data/es';
// import Main from './components/main';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import Login from './components/login';
import Landing from './components/landing'
import ResetPass from './components/resetpass';
import Registration from './components/registration';
import PostRegData from './components/postregdata';
import TournamentsPage from './components/tournament/tournamentsPage';
import VideoArchivePage from './components/videoArchiveRoot';
import RefereeingPage from './components/refereeingRoot';
import BowlingCentersView from './components/bowlingCentersView';
import ProtocolPage from './components/protocolRoot';
import SquadPage from './screens/squad';
import CustomHeader from './components/customheader';
import { Videos } from './components/videos';
import Translations from './components/translations';
import AuthError from './components/authError';
import WebApi from './components/webapi';
import Test from './components/testPage';
import NoFundsModal from './components/common/modals/modalNoFunds';
import ModalStartGame from './components/common/modals/modalStartGame';
import ModalDialog from './components/common/modals/modalDialog';
import {
  BattleInviteCanceled,
  BattleInviteConfirmed,
  UserByIdFetch,
  CreateBattle,
  GetTranslations,
  setCurrentRoom,
  createPrivateRoom,
  setRedirect,
} from './libs/reducers/actions';


import { setCommonNotification } from './libs/reducers/commonReducer';
import NotificationPopup from './components/common/NotificationPopup';
import { closeDialog } from './libs/reducers/SocketEventReducer';
import { setCommonError } from './libs/reducers/commonReducer';
import { AccountPage } from './components/accountRoot';
import AboutPage from './components/aboutPage';
import ObserverPage from './components/observerPage';
import { Page404 } from './components/page404';
import PasswdRecovery from './components/passwdRecovery';
import SecureRoute from './components/common/SecureRoute';
import SplashScreen from './components/splashscreen';
import ModalUser from './components/common/modals/modalUser';
import ErrorModal from './components/common/modals/modalError';
import ChatClient from './components/chat-demo';
import Lobby from './components/lobby';
import ModalCodeError from './components/common/modals/modalCodeError';
import RefereeingTable from './components/tournament/refereeing';
import CreateBattleModal from './components/common/modals/modalCreateBattle';
import CookiePanel from './components/common/CookiePanel';
import ModalServerInactive from './components/common/modals/modalServerInactive';
import ModalTrial from './components/common/modals/modalTrial';
import ModalSessionEvent from './components/common/modals/modalSessionEvent';
import NotificationModal from './components/common/modals/modalNotification';
import ModalEmailNotVerified from './components/common/modals/modalEmailNotVerified';
import GetEmail from './components/GetEmail'; // Если нужна почта для авторизацию через соцсети
import InstallGuide from './components/landing/installGuide'
import AppAdmin from '../src/components/adminRoot/'

import EYLT2021 from './components/EuropeanYouthTournament/index';

import './style.scss';
import bugsnagClient from './bugsnagClient';
import TabletPage from "./components/TabletPage";
import StreamPage from './components/Stream/StreamPage'
import BowlingCentersAdmin from "./components/bowlingCentersView/bowlingCentersAdmin";
import InviteFriendsScreen from "./components/InviteFriendsScreen";
import { ChatModal } from './components/common/modals/chat-modal/chat-modal';

addLocaleData([...localeRu, ...localeEn, ...localeEs]);

const lang = window.navigator.language;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.addNotification = this.addNotification.bind(this);
    this.notificationDOMRef = React.createRef();
    this.state = {
      logged: false,
      showInviteModal: false,
      invite: false,
      gameStart: false,
      gameData: {},
      startGame: false,
      isGameOverVisible: false,
      isPlayAgainVisible: false,
      notifications: [],
      showInviteBattle: false,
      showUserById: false,
      showNoEmailVerifiedModal: false,
      redirected: false,
      // lang: props.langs.lang || props.users.user.lang || lang.slice(0, 2),
      lang: props.langs.lang || props.users.user.lang || 'EN',
      // lang: 'en',
      langFetching: false,
      unAuthPath: null,
    };
    window.addNotification = this.addNotification;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = { ...prevState }
    if (nextProps.users && nextProps.users.accessed && !prevState.logged) {
      state = { ...state, logged: true, unAuthPath: '/login' };
    }
    if (
      !prevState.redirected
      && nextProps.users
      && !_.isEmpty(nextProps.users.user)
      && !!nextProps.users.user.emailVerified
      && (!nextProps.users.user.firstname
        || !nextProps.users.user.lastname
        || !nextProps.users.user.dob
        || !nextProps.users.user.country_live
        || !nextProps.users.user.hourshift)
    ) {
      nextProps.history.push('/postregdata');
      state = { ...state, redirected: true }
    }
    if (
      !prevState.redirected
      && nextProps.users
      && !_.isEmpty(nextProps.users.user)
      && !nextProps.users.user.emailVerified
      && (!nextProps.users.user.firstname
        || !nextProps.users.user.lastname
        || !nextProps.users.user.dob
        || !nextProps.users.user.country_live
        || !nextProps.users.user.hourshift)
    ) {
      nextProps.history.push('/registration');
      state = { ...state, redirected: true }
    }
    if (nextProps.users.user && nextProps.users.accessed) {
      state = { ...state, unAuthPath: null }
    }
    if (nextProps.users && !nextProps.users.accessed && prevState.logged) {
      nextProps.history.push('/login');
      state = { ...state, logged: false,  unAuthPath: '/login' }
    }
    if (
      !nextProps.langs.lang
      && nextProps.users
      && nextProps.users.user
      && nextProps.users.user.lang
      && nextProps.users.user.lang !== prevState.lang
    ) {
      state = { ...state, lang: nextProps.users.user.lang }
    }

    if (nextProps.langs.lang && nextProps.langs.lang !== prevState.lang) {
      state = { ...state, lang: nextProps.langs.lang }
    }

    if (nextProps.langs.locale.fetching !== prevState.langFetching) {
      state = { ...state, langFetching: nextProps.langs.locale.fetching }
    }

    if (nextProps.events && nextProps.events.game && !!nextProps.events.game.invite !== !!prevState.showInviteModal) {
      if (nextProps.events && nextProps.events.game && nextProps.events.game.start !== prevState.gameStart) {
        if (nextProps.events.game.start) {
          nextProps.history.push('/squad');
        }
      }
      console.log(`=====[${new Date().toISOString().slice(11, -1)}] setState.showInviteModal: `, !!nextProps.events.game.invite);
      state = {
        ...state,
        showInviteModal: nextProps.events.game.invite,
        invite: !nextProps.events.game.acceptStatus.accepted,
        gameData: nextProps.events.game.gamedata,
        startGame: nextProps.events.game.start,
      };
    }
    if (nextProps.user && !!nextProps.user.id && nextProps.user.hasOwnProperty('emailVerified') && !nextProps.user.emailVerified) {
      if (nextProps.history.location.pathname === '/') {
        state = {
          ...state,
          showNoEmailVerifiedModal: false,
        }
      } else {
        state = {
          ...state,
          showNoEmailVerifiedModal: true,
        }
      }
    }
    if (nextProps.user && !nextProps.user.id) {
      state = {
        ...state,
        showNoEmailVerifiedModal: false,
      }
    }
    if (nextProps.user && nextProps.user.id && nextProps.user.emailVerified) {
      state = {
        ...state,
        showNoEmailVerifiedModal: false,
      }
    }
    return state;
  }

  static propTypes = {
    BattleInviteCanceled: PropTypes.func,
    BattleInviteConfirmed: PropTypes.func,
    events: PropTypes.shape({
      game: PropTypes.shape({
        gamedata: PropTypes.object,
      }),
    }),
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }),
    users: PropTypes.shape(),
    dialog: PropTypes.shape({
      visible: PropTypes.bool.isRequired,
      declineButtonText: PropTypes.string,
      acceptButtonText: PropTypes.string,
      title: PropTypes.string,
      children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
      ])
    }),
  };

  componentDidMount() {
    if (['/', '/login', '/logout', '/registration', '/postregdata'].indexOf(this.props.location.pathname) == -1) {
      this.props.setRedirect(this.props.location.pathname);
    }
    moment.locale(this.state.lang.toLowerCase());
    this.props.GetTranslations(this.state.lang);
    if (this.props.user && this.props.user.id) {
      bugsnagClient.user = {
        id: this.props.user.id,
        name: `${this.props.user.firstname || ''} ${this.props.user.lastname || ''}`,
        email: this.props.user.email || 'Not email',
      };
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (['/', '/login', '/logout', '/registration', '/postregdata'].indexOf(this.props.location.pathname) == -1) {
      this.props.setRedirect(this.props.location.pathname);
    }
    const { squadId, events, userById = {}, eventsGameAcceptStatus, user } = this.props;
    if (user && user.id && (!prevProps.user || !prevProps.user.id || !prevProps.user.id === user.id)) {
      bugsnagClient.user = {
        id: user.id,
        name: `${user.firstname || ''} ${user.lastname || ''}`,
        email: user.email || 'Not email',
      };
    }
    if (prevProps.user && prevProps.user.id && (!user || !user.id || user.id !== prevProps.user.id)) {
      bugsnagClient.user = {};
    }


    const { lang: currentLang } = this.state;
    if (currentLang !== prevState.lang) {
      moment.locale(currentLang.toLowerCase());
      this.props.GetTranslations(currentLang);
    }
    if (squadId !== prevProps.squadId && squadId) {
      // history.push('/squad');
    }
    if (events.notification && prevProps.events.notification) {
      const notification = !_.isEqual(events.notification, prevProps.events.notification) ? events.notification : {};
      if (!_.isEmpty(notification)) {
        this.addNotification(notification.id, notification.message, notification.created);
      }
    }
    if (prevProps.userById.fetching && !userById.fetching && userById.modal) {
      this.setState({
        showUserById: true,
      });
    }
    if (!eventsGameAcceptStatus.loading && prevProps.eventsGameAcceptStatus.loading) {
      if (eventsGameAcceptStatus.error.code) {
        console.log(`[${new Date().toISOString().slice(11, -1)}] events.game.acceptStatus.error: `, eventsGameAcceptStatus.error);
        if (this.modalStartGame && typeof this.modalStartGame.resetBCLane === 'function') {
          console.log(`[${new Date().toISOString().slice(11, -1)}] resetBCLane`);
          this.modalStartGame.resetBCLane(() => {
            this.props.cleanGameStartAcceptingError();
          });
        }
      } else {
        console.log(`[${new Date().toISOString().slice(11, -1)}] events.game.acceptStatus: `, eventsGameAcceptStatus);
        this.props.cleanGameStartAcceptingError();
        this.setState({ invite: false });
      }
    }
    if (prevProps.user && prevProps.user.id && this.props.user && !this.props.user.id && prevProps.users.reg) {
      this.props.history.push('/');
    }
  }

  addNotification(notificationid, message, created, type) {
    const { notifications } = this.state;
    const notification = new DOM(parse(message));
    const userid = notification.document.getElementsByTagName('user')[0]
      && notification.document.getElementsByTagName('user')[0].attributes.id;

    let temp = [];
    if (notifications.length) {
      temp = [...notifications];
    }
    temp.push({ id: `${notificationid}`, userid });
    this.setState({
      notifications: temp,
    });
    this.notificationDOMRef.current.addNotification({
      // id: `${notificationid}`,
      type: 'success',
      insert: 'bottom',
      container: 'top-right',
      animationIn: ['animated', 'fadeIn'],
      // animationOut: ['animated', 'fadeOut'],
      dismiss: { duration: 5000 },
      content: <NotificationPopup message={message} created={created} type={type} />,
      dismissable: { click: true, touch: true },
    });
  }

  onCreateBattleSubmit = ({ betType, bet, winCount, dateType, date, time, comment, betcurrency, watchmode }) => {
    const params = { name: 'Battle' };
    params.wincount = winCount;
    if (betType === 2) {
      params.cost = bet;
      params.currency = betcurrency;
    }
    if (dateType === 2) {
      let datestart = null;
      if (time !== null) {
        datestart = moment(date.set('h', time.hour()).set('m', time.minute()));
      } else {
        datestart = moment(date);
      }
      params.datestart = moment(datestart).toISOString();
    }
    if (comment !== '') {
      params.rules = comment;
    }
    params.watchmode = watchmode;

    this.props.CreateBattle({ ...params });
    this.setState({ showInviteBattle: false });
  }

  onCreateBattleCancel = () => {
    this.setState({
      showInviteBattle: false,
      showUserById: true,
    });
  }

  closeCodeError = () => {
    const { closeCodeError } = this.props;
    if (typeof closeCodeError === 'function') {
      closeCodeError();
    }
  }

  getRef = (ref) => {
    let reference = ref;
    while (reference) {
      if (typeof reference.getWrappedInstance === 'function') {
        reference = reference.getWrappedInstance();
      } else {
        break;
      }
    }
    return reference;
  }

  render() {
    const { user, userById, langs, commonError, notificationType } = this.props;
    const { showInviteBattle, showUserById, unAuthPath } = this.state;
    const { dialog } = this.props.events;
    const { pathname } = this.props.history.location;
    const pages = ['/registration', '/login', '/squad', '/postregdata', '/videoarchive', '/refereeing', '/protocol', '/watch', '/', '/getemail', '/passwd/recovery', '/EYLT2021', '/howtoinstall', '/tablet'];
    const dynamicPages = ['/stream'];
    const isShowHeader = !dynamicPages.some(page => pathname.includes(page)) && !pages.some(page => page === pathname);

    const opponent = {};
    const datestart = this.state.gameData && this.state.gameData.tournament && this.state.gameData.tournament.datestart
      ? this.state.gameData.tournament.datestart
      : '';
    const bet = this.state.gameData && this.state.gameData.tournament && this.state.gameData.tournament.cost
      ? this.state.gameData.tournament.cost
      : '';
    const wins = this.state.gameData && this.state.gameData.tournament && this.state.gameData.tournament.wincount
      ? this.state.gameData.tournament.wincount
      : '';
    const prize = this.state.gameData && this.state.gameData.tournament && this.state.gameData.tournament.prize;
    const { gameData: { tournament: { currencysymbol = '?' } = {} } = {} } = this.state;
    if (!this.state.startGame) {
      if (!_.isEmpty(this.state.gameData) && this.state.gameData.players) {
        this.state.gameData.players.forEach((player) => {
          if (player.id !== this.props.users.user.id) {
            opponent.fullname = `${player.firstname} ${player.lastname}`;
            opponent.nickname = player.login;
            opponent.flag = (player.country_live || '').toLowerCase();
            opponent.photo = player.photo;
            opponent.rating = +player.ratings || 0;
            opponent.id = player.id;
            opponent.avgscore = ((+player.avgscore || 0).toFixed());
          }
        });
      }
    }
    const { tournament } = this.props.events.game.gamedata;
    return (
      <IntlProvider
        // key={this.state.lang} //Требуется дополнительное долгосрочное тестирование всех компонентов, использующих провайдер строк переводов
        locale={this.state.lang}
        // defaultLocale="EN"
        defaultLocale={this.state.lang}
        messages={langs.locale.data[this.state.lang]}
      >
        <div className='appWrap'>
          {/*<ModalTrial*/}
          {/*  visible={this.props.users.firstEnter && !this.props.users.reg}*/}
          {/*  onClose={() => this.props.setFirstEnter(false)}*/}
          {/*/>*/}
          <ModalCodeError
            code={this.props.codeError.code}
            data={this.props.codeError.data}
            onOk={this.closeCodeError}
          />
          <ErrorModal
            visible={!!commonError}
            buttonText="OK"
            onOk={() => {
              this.props.setCommonError('');
            }}
            errorMessage={commonError}
          />

          {/* Окно уведомлений Прнимает Тип Уведомления (задается в компоненте) */}
          <NotificationModal
            notificationType={notificationType}
          />

          <ModalServerInactive />
          {this.state.showInviteModal
            && this.state.invite && tournament && (
              <ModalStartGame
                visible
                onSubmit={({ bowlingcenterid, ...rest }) => {
                  this.props.BattleInviteConfirmed({
                    tournamentid: (tournament && tournament.id),
                    bowlingcenterid,
                    squadid: tournament.squadid,
                    ...rest,
                  });
                  // this.setState({ invite: false }); // disable immediate closing of modal in order to wait before BattleInviteConfirmed completes
                }}
                ref={(connectedComponent) => {
                  this.modalStartGame = this.getRef(connectedComponent);
                }}
                onClose={() => {
                  this.props.BattleInviteCanceled({
                    tournamentId: (tournament && tournament.id),
                    squadid: tournament.squadid,
                  });
                }}
                tournament={{
                  type: 'Battle',
                  date: datestart === '' ? moment() : moment(datestart),
                  bet,
                  numOfWins: wins,
                  prize,
                  currencysymbol,
                  tournamenttypeid: tournament && tournament.tournamenttypeid,
                  tournamenttypebrief: tournament && tournament.tournamenttypebrief,
                }}
                opponent={opponent}
                title="Start game"
                additionalInfo="If you give up the game, the opponent is automatically awarded a victory."
                declineButtonText="Decline"
                acceptButtonText="Accept"
                titleId="StartGame"
                additionalInfoId="GameGiveUpInfo"
                declineButtonTextId="Decline"
                acceptButtonTextId="Accept"
              />
          )}
          {this.state.isPlayAgainVisible && (
            <ModalStartGame
              visible
              onSubmit={() => {}}
              onClose={() => {}}
              tournament={{
                type: 'Battle',
              }} // информация о турнире
              opponent={{
                fullname: '',
                nickname: '',
                flag: '',
              }} // информация о создателе
              title="Play again"
              additionalInfo="The opponent may refuse a revanche without losing any cash."
              declineButtonText="Decline"
              acceptButtonText="Accept"
              titleId="PlayAgain"
              additionalInfoId="GamePlayAgainInfo"
              declineButtonTextId="Decline"
              acceptButtonTextId="Accept"
            />
          )}
          <ModalDialog
            {...dialog}
            onSubmit={() => this.props.closeDialog({
              result: 1,
            })
            }
            onClose={() => this.props.closeDialog({
              result: -1,
            })
            }
          />
          <ModalSessionEvent />
          <ReactNotification
            onNotificationRemoval={(id, removedBy) => {
              let temp = [];
              const { notifications } = this.state;
              if (notifications.length) {
                temp = [...notifications];
              }
              if (removedBy === 2 || removedBy === 3) {
                temp.forEach((elem) => {
                  if (elem.userid && elem.id === id) {
                    this.props.UserByIdFetch({
                      userid: +elem.userid,
                    });
                  }
                });
              }
              temp = temp.filter(elem => elem.id !== id);
              this.setState({
                notifications: temp,
              });
            }}
            ref={this.notificationDOMRef}
          />
          <CreateBattleModal
            visible={showInviteBattle}
            onSubmit={this.onCreateBattleSubmit}
            onClose={this.onCreateBattleCancel}
          />
          {/* <ModalInviteBattle
            visible={showInviteBattle}
            onSubmit={this.onInviteBattleSubmit}
            onClose={() => {
              this.setState({
                showInviteBattle: false,
                showUserById: true,
              });
            }}
            opponent={{
              ...userById.data,
              hourshift: (userById.data.hourshift && userById.data.hourshift !== 0
                ? userById.data.hourshift
                : '0'
              ).toString(),
            }}
          /> */}
          <NoFundsModal/>
          <ModalUser
            title="User card"
            titleIntlId="FriendListUserCardModalTitle"
            onClose={() => {
              this.setState({
                showUserById: false,
              });
            }}
            onChallengeClick={() => {
              this.setState({
                showUserById: false,
                showInviteBattle: true,
              });
            }}
            onMessageClick={() => {
              const { userById: { data: interlocutor = null } = {}, rooms = [], user: curUser = null } = this.props;
              if (!interlocutor || !curUser) {
                return;
              }
              const max = Math.max(interlocutor.id, curUser.id);
              const min = Math.min(interlocutor.id, curUser.id);
              const roomName = `private-room-${min}-${max}`;
              const room = rooms.find(r => r.room_name === roomName);
              if (room) {
                this.props.setCurrentRoom(roomName);
              } else {
                this.props.createPrivateRoom(interlocutor.id);
              }
              const isAdminPage = window.location.pathname.indexOf('/appadmin') === 0;
              this.setState({ showUserById: false, showChatModal: isAdminPage });
            }}
            isChallengeAllowed={user.id !== userById.data.id}
            user={{
              ...userById.data,
              // hourshift: (userById.data.hourshift && userById.data.hourshift !== 0
              //   ? userById.data.hourshift
              //   : '0'
              // ).toString(),
            }}
            visible={showUserById}
          />
          <ChatModal
            visible={this.state.showChatModal}
            onClose={() => this.setState({ showChatModal: false })}
          />
          {this.state.showNoEmailVerifiedModal && <ModalEmailNotVerified
            timers={this.props.timers}
            visible
          />}
          {this.props.appSettings.appinit ? (
            <>
              {isShowHeader && <CustomHeader logged={this.state.logged} />}
              <div className={'auth-pages__wrap'}>
                <Switch>
                  <Route exact path="/" component={Landing} />
                  {/* <Route exact path="/" render={() => <Redirect to="/login" />} /> */}
                  <SecureRoute unAuthPath={unAuthPath} path="/lobby" component={Lobby} />
                  {/* <SecureRoute path="/landing" component={Landing} /> */}
                  <SecureRoute unAuthPath={unAuthPath} path="/tournaments" component={TournamentsPage} />
                  <SecureRoute unAuthPath={unAuthPath} path="/videoarchive" component={VideoArchivePage} />
                  <SecureRoute unAuthPath={unAuthPath} path="/referee" component={RefereeingTable} />
                  <SecureRoute unAuthPath={unAuthPath} path="/refereeing" component={RefereeingPage} />
                  <SecureRoute unAuthPath={unAuthPath} path="/protocol" component={ProtocolPage} />
                  <SecureRoute unAuthPath={unAuthPath} path="/squad" component={SquadPage} />
                  <SecureRoute unAuthPath={unAuthPath} path="/bowlingcenters" component={BowlingCentersAdmin} />
                  <Route path="/postregdata" component={PostRegData} />
                  <Route path="/tablet" component={TabletPage} />
                  <Route path="/login" component={Login} />
                  <Route path="/resetpass" component={ResetPass} />
                  <Route path="/registration/:referID" component={Registration} />
                  <Route path="/registration" component={Registration} />
                  <Route path="/logout" render={() => <Redirect to="/" />} />
                  {/*<Route path="/videos" component={Videos} />*/}
                  <Route path="/error" component={AuthError} />
                  <Route path="/passwd/recovery" component={PasswdRecovery}/>
                  <Route path="/chat-demo" component={ChatClient} />
                  <Route path="/getemail" component={GetEmail} />
                  <Route path="/EYLT2021" component={EYLT2021}/>
                  <Route path="/howtoinstall" component={InstallGuide} />
                  <Route path={"/stream/:bcname"} component={StreamPage}/>
                  <Route path={"/inviteFriends"} component={InviteFriendsScreen}/>
                  <SecureRoute unAuthPath={unAuthPath} path="/webapi" component={WebApi} />
                  <SecureRoute unAuthPath={unAuthPath} path="/translations" component={Translations} />
                  <SecureRoute unAuthPath={unAuthPath} path="/account" component={AccountPage} />
                  <SecureRoute unAuthPath={unAuthPath} path="/about" component={AboutPage} />
                  <SecureRoute unAuthPath={unAuthPath} path="/watch" component={ObserverPage} />
                  <SecureRoute unAuthPath={unAuthPath} path="/test" component={Test} />
                  <SecureRoute unAuthPath={unAuthPath} path="/appadmin" component={AppAdmin} />
                  <SecureRoute unAuthPath={unAuthPath} path="*" component={Page404} />
                </Switch>
              </div>
            </>
          ) : (
            <SplashScreen />
          )}
          {!window.location.href.includes('tablet') && <CookiePanel/>}
        </div>
      </IntlProvider>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      user: state.users.user,
      userById: state.users.userById,
      users: state.users,
      emailVerification: state.users && state.users.user && state.users.user.emailVerification || false,
      langs: state.langs,
      events: state.events,
      squadId: state.squadId,
      appSettings: state.appSettings,
      commonError: state.common.common_error,
      codeError: state.common.code_error || {},
      rooms: state.chat.rooms,
      lastLocation: state.redirect.from,
      eventsGameAcceptStatus: state.events.game.acceptStatus,
      notificationType: state.common.common_notification,
      timers: state.timers,
    }),
    {
      BattleInviteCanceled,
      BattleInviteConfirmed,
      closeDialog,
      UserByIdFetch,
      CreateBattle,
      GetTranslations,
      setCommonError,
      setCurrentRoom,
      createPrivateRoom,
      setRedirect,
      closeCodeError: () => ({ type: 'CLOSE_CODE_ERROR' }),
      cleanGameStartAcceptingError: () => ({ type: 'SET_GAME_START_ACCEPTING_COMPLETE' }),
      setFirstEnter: (isFirstEnter = false) => ({ type: 'FIRST_ENTER', payload: isFirstEnter }),
      setCommonNotification
    },
  )(App),
);
