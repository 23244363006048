import React, {Component} from "react";
import {connect} from "react-redux";
import {Button} from "../common/Button";
import {injectIntl, intlShape} from "react-intl";
import {
  getSquadIdByUserId,
  removeSquadIdByUserId,
  deleteUserFromTournament,
  removePlayerRemovedInfo,
} from "../../libs/reducers/actions";
import ModalDialog from "../common/modals/modalDialog";

class ButtonRemovePlayer extends Component {
  static propTypes = {
    intl: intlShape,
  }

  constructor(props) {
    super(props);
    this.state = {
      squadIdRemovedPlayer: null,
      showConfirmation: null,
      fetching: false,
    }
  }

  componentDidMount() {
    const { removeSquadIdByUserId } = this.props;
    removeSquadIdByUserId();
  }

  componentDidUpdate(prevProps) {
    const { squadIdRemovedPlayer, fetchingSquadIdRemovedPlayer, fetchingPlayerRemovedInfo, removedPlayerInfo} = this.props
    const { fetching } = this.state

    if (fetching && prevProps.fetchingPlayerRemovedInfo && prevProps.fetchingPlayerRemovedInfo === true && fetchingPlayerRemovedInfo === false ) {
      if ((removedPlayerInfo || {}).userid && (removedPlayerInfo || {}).tournamentid) this.getSquadIdRemovedPlayer()
    }

    if (prevProps.fetchingSquadIdRemovedPlayer && prevProps.fetchingSquadIdRemovedPlayer === true && fetchingSquadIdRemovedPlayer === false) {
      if ((squadIdRemovedPlayer || []).length !== 0) {
        this.setState({ showConfirmation: true })
      } else {
        this.setState({ activityIndicator: false, fetching: false })
        this.removePlayerFromTournament()

      }
    }
  }

  removePlayerFromTournament() {
    const { squadIdRemovedPlayer, deleteUserFromTournament, removedPlayerInfo, removeSquadIdByUserId, removePlayerRemovedInfo } = this.props;
    deleteUserFromTournament({userid: removedPlayerInfo.userid, tournamentid: removedPlayerInfo.tournamentid, squadIdRemovedPlayer})
    removeSquadIdByUserId();
    removePlayerRemovedInfo();
  }

  getSquadIdRemovedPlayer() {
    const {getSquadIdByUserId, removedPlayerInfo} = this.props;
    getSquadIdByUserId({tournamentid: removedPlayerInfo.tournamentid ,userid: removedPlayerInfo.userid})
  }

  modalPressRemovePlayer() {
    const { removeSquadIdByUserId, removePlayerRemovedInfo, } = this.props

    this.removePlayerFromTournament()
    this.setState({showConfirmation: false})
    removeSquadIdByUserId();
    removePlayerRemovedInfo();
    this.setState({fetching: false})
  }

  modalPressDecline() {
    const { removeSquadIdByUserId, removePlayerRemovedInfo } = this.props

    this.setState({ showConfirmation: false })
    removeSquadIdByUserId();
    removePlayerRemovedInfo();
    this.setState({fetching: false})
  }
  onPress() {
    const {onClick, fetchingSquadIdRemovedPlayer} = this.props;
    if (fetchingSquadIdRemovedPlayer) {
      onClick();
      this.setState({fetching: true})
    }
  }

  render() {
    const { showConfirmation, fetching } = this.state
    const {  intl, fetchingSquadIdRemovedPlayer } = this.props

    return (
      <div>
        <Button kind="aquamarine" bordered="true" onClick={() => this.onPress()}>
          {!(fetchingSquadIdRemovedPlayer && fetching) && intl.formatMessage({ id: 'TournamentRemovePlayer', defaultMessage: 'Remove Player' }) ||
          intl.formatMessage({ id: 'Wait', defaultMessage: 'Wait...' })}
        </Button>
        <ModalDialog
          visible={fetching && showConfirmation}
          title={intl.formatMessage({id: 'removeConfirmation', defaultMessage: 'Remove Сonfirmation'})}
          acceptButtonText={intl.formatMessage({id: 'Accept', defaultMessage: 'Accept'})}
          declineButtonText={intl.formatMessage({id: 'Decline', defaultMessage: 'Decline'})}
          onSubmit={() => this.modalPressRemovePlayer()}
          onClose={() => this.modalPressDecline()}>
          <p>
            {intl.formatMessage({id: 'removeConfirmationText', defaultMessage: 'This user has an active game, if the user is removed from the tournament, his game will be closed.'})}
          </p>
        </ModalDialog>
      </div>
    )
  }
}

export default injectIntl(connect(state => ({
  squadIdRemovedPlayer: state.tournaments.squadIdRemovedPlayer,
  removedPlayerInfo: state.tournaments.playerRemovedInfo,
  fetchingSquadIdRemovedPlayer: state.tournaments.fetches.checkSquadIdRemovedPlayer,
  fetchingPlayerRemovedInfo: state.tournaments.fetches.checkPlayerRemovedInfo,
}), {
    removeSquadIdByUserId,
    getSquadIdByUserId,
    deleteUserFromTournament,
    removePlayerRemovedInfo,
}
  )(ButtonRemovePlayer)
)
