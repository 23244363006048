import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const SecureRoute = ({ component: Component, isAllowed, unAuthPath, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      return (isAllowed ? <Component {...props} /> : <Redirect to={unAuthPath || "/"} />);
    }}
  />
);

export default connect(state => ({
  isAllowed: state.users.accessed,
}))(SecureRoute);
