/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line object-curly-newline
import { bool, func, object, string } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Input from '../Input';
import DatePicker from '../DatePicker';
import Modal from '../Modal';
import './style.scss';

const propTypes = {
  withFilter: bool,
  withSort: bool,
  title: string,
  dataIndex: string,
  output: object,
  setOutput: func,
  confirmLoading: bool,
  filterInputType: 'text' || 'date',
};

const TableHeaderCell = ({
  withFilter,
  withSort,
  title,
  dataIndex,
  output = {},
  setOutput = () => {},
  confirmLoading,
  filterInputType,
}) => {
  const [visibleModal, setvisibleModal] = useState(false);
  const [dateAfter, setDateAfter] = useState(null);
  const [dateBefore, setDateBefore] = useState(null);
  const [prevConfirmLoading, setPrevConfirmLoading] = useState(confirmLoading);
  const [textInputValue, settextInputValue] = useState(
    typeof output[dataIndex] === 'string' ? output[dataIndex] : '',
  );
  const sortIndex = output.sort_by ? output.sort_by.split('.')[0] : null;
  const sortMethod = output.sort_by ? output.sort_by.split('.')[1] : null;
  let isActiveIcon = false;
  if (filterInputType === 'text') {
    isActiveIcon = !!output[dataIndex];
  }
  if (filterInputType === 'date') {
    isActiveIcon = !!output[`${dataIndex}_after`] || !!output[`${dataIndex}_before`];
  }
  function isRenderSortIcon(method) {
    if (sortIndex === dataIndex && sortMethod !== method) {
      return false;
    }
    return true;
  }
  function onCancel() {
    setvisibleModal(false);
  }
  function openModal() {
    setvisibleModal(true);
  }
  function onOk() {
    if (filterInputType === 'text') {
      // const text = textInputRef.current.textInput.current.value;
      setOutput({ ...output, [dataIndex]: textInputValue });
    }

    if (filterInputType === 'date') {
      const datesObj = {};
      const newOutput = { ...output };
      if (dateAfter) {
        datesObj[`${dataIndex}_after`] = dateAfter.toISOString();
      }
      if (dateBefore) {
        datesObj[`${dataIndex}_before`] = dateBefore.toISOString();
      }

      if (!dateAfter && newOutput[`${dataIndex}_after`]) {
        delete newOutput[`${dataIndex}_after`];
      }
      if (!dateBefore && newOutput[`${dataIndex}_before`]) {
        delete newOutput[`${dataIndex}_before`];
      }
      setOutput({ ...newOutput, ...datesObj });
    }
  }

  function onSort() {
    if (!output.sort_by || sortIndex !== dataIndex) {
      setOutput({ ...output, sort_by: `${dataIndex}.asc` });
    }
    if (sortIndex === dataIndex) {
      if (sortMethod === 'asc') {
        setOutput({ ...output, sort_by: `${dataIndex}.desc` });
      }
      if (sortMethod === 'desc') {
        const newOutput = { ...output };
        delete newOutput.sort_by;
        setOutput(newOutput);
      }
    }
  }

  useEffect(() => {
    setPrevConfirmLoading(confirmLoading);
    if (prevConfirmLoading && !confirmLoading) {
      setvisibleModal(false);
    }
  }, [confirmLoading]);

  return (
    <>
      <div className="with-icons">
        <div className="with-icons__left">
          {withFilter && (
            <div className="with-icons__left-icon" onClick={openModal}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.45313 13.0938C5.45313 13.3703 5.675 13.5938 5.95 13.5938H10.05C10.325 13.5938 10.5469 13.3703 10.5469 13.0938V10.0312H5.45313V13.0938ZM13.7516 2.40625H2.24844C1.86563 2.40625 1.62656 2.82344 1.81875 3.15625L5.27656 9.03125H10.7266L14.1844 3.15625C14.3734 2.82344 14.1344 2.40625 13.7516 2.40625Z"
                  fill={isActiveIcon ? '#f65932' : '#f6593266'}
                />
              </svg>
            </div>
          )}
          <div>{title}</div>
        </div>
        {withSort && (
          <div className="filterState" onClick={() => onSort()}>
            {isRenderSortIcon('asc') && <div className="up" />}
            {isRenderSortIcon('desc') && <div className="down" />}
          </div>
        )}
      </div>
      <Modal
        title={`filter ${title}`}
        visible={visibleModal}
        onCancel={onCancel}
        onOk={onOk}
        className={'modal'}
        mask={false}
        destroyOnClose
        centered
        confirmLoading={confirmLoading}
      >
        <div className="modal__body-container">
          {filterInputType === 'text' && (
            <Input
              // ref={textInputRef}
              value={textInputValue}
              onChange={e => settextInputValue(e.target.value)}
            />
          )}
          {filterInputType === 'date' && (
            <div className="table-header-cell__date-group">
              <DatePicker
                dateFormat="D MMM YYYY"
                className="date-group__elements__picker"
                placeholder="From"
                value={dateAfter}
                onChange={(date, dateStr) => setDateAfter(date)}
              />
              <DatePicker
                dateFormat="D MMM YYYY"
                className="date-group__elements__picker"
                placeholder="To"
                value={dateBefore}
                onChange={(date, dateStr) => setDateBefore(date)}
              />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default TableHeaderCell;

TableHeaderCell.propTypes = propTypes;
