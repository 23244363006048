import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Debounce } from 'react-throttle';
import { Table, Pagination } from 'antd';
import moment from 'moment';
import Input from '../../common/Input';
import Select from '../../common/Select';
import Checkbox from '../../common/Checkbox';
import config from '../../../config';
import { Button } from '../../common/Button';
import OBDatePicker from '../../common/DatePicker';
import OBModal from '../../common/Modal';
import defaultAvatar from '../../../images/defaultAvatar.png';
import verifyimg from '../../../images/check1.svg';
import '../../../style.scss';
import PillBox from '../../common/PillBox';
import PillBoxHost from '../../common/PillBox/host';
import {
  getAllUsers,
  editUserAsAdmin,
  applyUsersSort,
} from '../../../libs/reducers/adminReducer';
import { UserByIdFetch } from '../../../libs/reducers/actions';

const UserList = ({
  admin,
  common,
  editUserAsAdmin,
  UserByIdFetch,
  bowlingcenters,
  getAllUsers,
}) => {
  const [page, setPage] = useState(1);
  const [tablefilters, setTablefilters] = useState({});
  const [update, setUpdate] = useState(0);
  const [lastfilter, setLastfilter] = useState(null);
  const [filterorder, setFilterorder] = useState('asc');
  const [modals, setModals] = useState({});
  const [tmpfilterval, setTmpfilterval] = useState('');
  const [pillboxstate, setPillboxstate] = useState({
    pillboxopts: [],
    pillboxval: [],
    pillboxvisible: false,
    pillboxtitle: null,
    pillboxonchange: () => {},
  });
  const [activecolusers, setActivecolusers] = useState({
    '#': true,
    Login: true,
    Firstname: true,
    Lastname: true,
    Email: true,
    Language: true,
    'Users country': true,
    Groups: true,
    Permissions: true,
    'Register date': true,
    Deleted: true,
  });

  const applySorting = (lastfilter, filterorder) => {
    applyUsersSort(
      ...admin.userList.sort((a, b) => {
        let vala = a;
        let valb = b;
        const datefields = [
          'ab_pend',
          'created',
          'processed',
          'deleted_at',
          'registere_date',
        ];
        lastfilter.split('.').forEach(filter => {
          if (!filter.includes('field-iso') && !datefields.includes(filter)) {
            vala = vala[filter];
            valb = valb[filter];
          } else if (datefields.includes(filter)) {
            vala = +new Date(vala[filter] || 0);
            valb = +new Date(valb[filter] || 0);
          } else {
            filter = filter.replace('field-iso', '');
            vala = (vala.find(wallet => wallet.isocode === filter) || {})
              .amount;
            valb = (valb.find(wallet => wallet.isocode === filter) || {})
              .amount;
          }
        });
        let res = 0;
        if (vala > valb) {
          res = 1;
        } else if (vala < valb) {
          res = -1;
        } else {
          res = 0;
        }
        if (filterorder === 'asc') {
          return res;
        }
        return res * -1;
      }),
    );
  };

  const createTitle = (
    title,
    field,
    sortable,
    filterable = true,
    alwaysActive,
  ) => (
    <div className="adminTableHeader">
      {filterable && (
        <div
          className={`searchField${
            tablefilters[field] || alwaysActive ? ' active' : ''
          }`}
        >
          <svg
            onClick={() => (
              setModals({ ...modals, [field]: !modals[field] }),
              setTmpfilterval('')
            )}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.45313 13.0938C5.45313 13.3703 5.675 13.5938 5.95 13.5938H10.05C10.325 13.5938 10.5469 13.3703 10.5469 13.0938V10.0312H5.45313V13.0938ZM13.7516 2.40625H2.24844C1.86563 2.40625 1.62656 2.82344 1.81875 3.15625L5.27656 9.03125H10.7266L14.1844 3.15625C14.3734 2.82344 14.1344 2.40625 13.7516 2.40625Z"
              fill="#f65932"
            />
          </svg>
        </div>
      )}
      <div
        className="filterBody"
        onClick={
          sortable
            ? () =>
                lastfilter === field
                  ? (setFilterorder(filterorder === 'asc' ? 'desc' : 'asc'),
                    applySorting(field, filterorder === 'asc' ? 'desc' : 'asc'))
                  : (setLastfilter(field), applySorting(field, filterorder))
            : () => {}
        }
      >
        <span>{title}</span>
        <div className="filterState">
          {field === lastfilter ? (
            <div className={filterorder === 'asc' ? 'up' : 'down'} />
          ) : null}
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    getAllUsers();
  }, []);

  const customStyle = st => ({
    multiValue: base => ({
      ...base,
      backgroundColor: '#51565E',
    }),
    multiValueLabel: base => ({
      ...base,
      color: '#fff',
    }),
    multiValueRemove: base => ({
      ...base,
      color: '#B1B3B6',
      ':hover': {
        backgroundColor: '#733C36',
        color: '#fff',
      },
    }),
    valueContainer: base => ({
      ...base,
      maxHeight: st ? 100 : 45,
      overflow: 'auto',
    }),
    singleValue: base => ({
      ...base,
      marginLeft: 0,
      color: '#fff',
      fontFamily: 'Open Sans',
      fontWeight: 300,
    }),
    control: base => ({
      ...base,
      backgroundColor: 'transparent',
      ':hover': {
        borderBottom: '1px solid #fff6',
      },
      borderRadius: 0,
      minHeight: 30,
      boxShadow: 'none',
      cursor: 'pointer',
      borderStyle: 'unset',
      borderBottom: 'transparent',
      paddingLeft: 0,
    }),
    placeholder: base => ({
      ...base,
      color: '#6a6869',
      fontSize: 16,
      fontWeight: 400,
      marginLeft: 0,
      paddingLeft: 0,
    }),
    option: (base, { isSelected }) => ({
      ...base,
      backgroundColor: isSelected ? '#20a39e' : '#6d7177',
      ':hover': {
        backgroundColor: '#20a39e',
      },
      color: '#fff',
      fontSize: 14,
      fontWeight: 300,
      padding: '6px 10px',
      cursor: 'pointer',
    }),
  });

  bowlingcenters = bowlingcenters.map(bc => ({ label: bc.name, value: bc.id }));
  const countries = common.geodata.data.map(item => ({
    value: item.iso,
    label: item.name,
  }));

  let userlistcolumns = [
    {
      title: createTitle('#', 'id', true),
      col: '#',
      field: 'id',
      dataIndex: 'id',
    },
    {
      title: createTitle('Register date', 'register_date', true),
      col: 'Register date',
      field: 'register_date',
      render: (data, record, index) => {
        const { id, lastname } = record;
        return (
          <div className="user_info">
            <div className="user_name">
              {record.register_date
                ? moment(record.register_date).format('DD.MM.YY HH:mm')
                : 'Not set'}
            </div>
          </div>
        );
      },
    },
    {
      title: createTitle('Login', 'login', true),
      col: 'Login',
      field: 'login',
      render: (data, record, index) => {
        const { photo, active, id, login } = record;
        return (
          <div className="loginWrapper">
            <div className={`photo${active ? ' active' : ''}`}>
              <img
                src={photo ? `${config.FETCH.img}/${photo}` : defaultAvatar}
                alt=""
              />
            </div>
            <div
              className="user_login"
              onClick={() => {
                UserByIdFetch({ userid: id });
              }}
            >
              {login}
            </div>
          </div>
        );
      },
    },
    {
      title: createTitle('Firstname', 'firstname', true),
      col: 'Firstname',
      field: 'firstname',
      render: (data, record, index) => {
        const { id, firstname } = record;
        return (
          <div className="user_info">
            <div className="user_name">
              <Debounce time="1500" handler="onChangeText">
                <Input
                  onChangeText={text =>
                    editUserAsAdmin({ userid: id, firstname: text })
                  }
                  value={firstname}
                  placeholder={''}
                />
              </Debounce>
            </div>
          </div>
        );
      },
    },
    {
      title: createTitle('Lastname', 'lastname', true),
      col: 'Lastname',
      field: 'lastname',
      render: (data, record, index) => {
        const { id, lastname } = record;
        return (
          <div className="user_info">
            <div className="user_name">
              <Debounce time="1500" handler="onChangeText">
                <Input
                  onChangeText={text =>
                    editUserAsAdmin({ userid: id, lastname: text })
                  }
                  value={lastname}
                  placeholder={''}
                />
              </Debounce>
            </div>
          </div>
        );
      },
    },
    {
      title: createTitle('Gender', 'gender', true),
      col: 'Gender',
      field: 'gender',
      render: (data, record, index) => {
        const { id, gender } = record;
        return (
          <div className="user_info">
            <div className="infoblock">
              <Select
                isSearchable
                placeholder=""
                value={{ value: gender, label: gender }}
                options={[
                  { label: 'F', value: 'F' },
                  { label: 'M', value: 'M' },
                ]}
                onChange={pick =>
                  editUserAsAdmin({ userid: id, gender: pick.value })
                }
                customStyle={customStyle(0)}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: createTitle('Date of birth', 'dob', true),
      col: 'Date of birth',
      field: 'dob',
      render: (data, record, index) => {
        const { id, dob } = record;
        return (
          <div className="user_info">
            <div className="infoblock">
              <OBDatePicker
                dateFormat="D MMM YYYY"
                className="date-user_info"
                placeholder={''}
                value={dob ? moment(dob) : null}
                onChange={newdob =>
                  editUserAsAdmin({ userid: id, dob: newdob })
                }
              />
            </div>
          </div>
        );
      },
    },
    {
      title: createTitle('Email', 'email', false),
      col: 'Email',
      field: 'email',
      render: (data, record, index) => {
        const { id, email } = record;
        return (
          <div className="user_info">
            <div className="infoblock checked">
              <Debounce time="1500" handler="onChangeText">
                <Input
                  onChangeText={text =>
                    editUserAsAdmin({ userid: id, email: text })
                  }
                  value={email.value}
                  placeholder={''}
                />
              </Debounce>
              {!!email.confirmation && (
                <img className="check" src={verifyimg} />
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: createTitle('Phone', 'phone', false),
      col: 'Phone',
      field: 'phone',
      render: (data, record, index) => {
        const { id, phone } = record;
        return (
          <div className="user_info">
            <div className="infoblock checked">
              <Debounce time="1500" handler="onChangeText">
                <Input
                  onChangeText={text =>
                    editUserAsAdmin({ userid: id, phone: text })
                  }
                  value={phone.value}
                  placeholder={''}
                />
              </Debounce>
              {!!phone.confirmation && (
                <img className="check" src={verifyimg} />
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: createTitle('Club', 'club', true),
      col: 'Club',
      field: 'club',
      render: (data, record, index) => {
        const { id, club } = record;
        return (
          <div className="user_info">
            <div className="infoblock">
              <Debounce time="1500" handler="onChangeText">
                <Input
                  onChangeText={text =>
                    editUserAsAdmin({ userid: id, club: text })
                  }
                  value={club}
                  placeholder={''}
                />
              </Debounce>
            </div>
          </div>
        );
      },
    },
    {
      title: createTitle('Language', 'lang', true),
      col: 'Language',
      field: 'lang',
      render: (data, record, index) => {
        const { id, lang } = record;
        return (
          <div className="user_info">
            <div className="infoblock">
              <Select
                isSearchable
                placeholder=""
                value={{ value: lang, label: lang }}
                options={[
                  { value: 'EN', label: 'EN' },
                  { value: 'RU', label: 'RU' },
                  { value: 'FI', label: 'FI' },
                ]}
                onChange={pick =>
                  editUserAsAdmin({ userid: id, lang: pick.value })
                }
                customStyle={customStyle(0)}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: createTitle('Users country', 'country_live', true),
      col: 'Users country',
      field: 'country_live',
      render: (data, record, index) => {
        const { id, country_live } = record;
        return (
          <div className="user_info">
            <div className="infoblock">
              <Select
                isSearchable
                placeholder=""
                value={{
                  value: country_live,
                  label: (
                    common.geodata.data.find(
                      item => item.iso === country_live,
                    ) || {}
                  ).name,
                }}
                options={countries}
                onChange={pick =>
                  editUserAsAdmin({ userid: id, country_live: pick.value })
                }
                customStyle={customStyle(0)}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: createTitle('Federation country', 'country_federation', true),
      col: 'Federation country',
      field: 'country_federation',
      render: (data, record, index) => {
        const { id, country_federation } = record;
        return (
          <div className="user_info">
            <div className="infoblock">
              <Select
                isSearchable
                placeholder=""
                value={{
                  value: country_federation,
                  label: (
                    common.geodata.data.find(
                      item => item.iso === country_federation,
                    ) || {}
                  ).name,
                }}
                options={countries}
                onChange={pick =>
                  editUserAsAdmin({
                    userid: id,
                    country_federation: pick.value,
                  })
                }
                customStyle={customStyle(0)}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: createTitle('Permissions', 'permissions', false, false),
      col: 'Permissions',
      field: 'permissions',
      render: (data, record, index) => {
        const { id, permissions } = record;
        return (
          <div className="user_info" style={{ maxWidth: 400, minWidth: 400 }}>
            <PillBox
              color="#fff6"
              noCog
              placeholder=""
              value={permissions.map(datum => ({
                value: datum,
                label: (
                  admin.permissionsList.find(item => item.value === datum) || {}
                ).label,
              }))}
              options={admin.permissionsList}
              onOpen={() => {
                setPillboxstate({
                  pillboxopts: admin.permissionsList,
                  pillboxval: permissions.map(datum => ({
                    value: datum,
                    label: (
                      admin.permissionsList.find(
                        item => item.value === datum,
                      ) || {}
                    ).label,
                  })),
                  pillboxvisible: true,
                  pillboxtitle: 'Permissions',
                  pillboxonchange: change => {
                    setPillboxstate({
                      ...pillboxstate,
                      pillboxval: change.map(permission => ({
                        value: permission,
                        label: permission,
                      })),
                    });
                    editUserAsAdmin({ userid: id, permissions: change });
                    setUpdate(update + 1);
                  },
                });
              }}
            />
          </div>
        );
      },
    },
    {
      title: createTitle('Groups', 'groups', false, false),
      col: 'Groups',
      field: 'groups',
      render: (data, record, index) => {
        const { id, groups } = record;
        return (
          <div className="user_info" style={{ maxWidth: 400, minWidth: 200 }}>
            <PillBox
              color="#fff6"
              noCog
              placeholder=""
              value={groups.map(datum => ({
                value: datum,
                label: (
                  admin.groupList.find(item => item.value === datum) || {}
                ).label,
              }))}
              options={admin.groupList}
              onOpen={() => {
                setPillboxstate({
                  pillboxopts: admin.groupList,
                  pillboxval: groups.map(datum => ({
                    value: datum,
                    label: (
                      admin.groupList.find(item => item.value === datum) || {}
                    ).label,
                  })),
                  pillboxvisible: true,
                  pillboxtitle: 'User groups',
                  pillboxonchange: change => {
                    setPillboxstate({
                      ...pillboxstate,
                      pillboxval: change.map(group => ({
                        value: group,
                        label: group,
                      })),
                    });
                    editUserAsAdmin({ userid: id, groups: change });
                    setUpdate(update + 1);
                  },
                });
              }}
            />
          </div>
        );
      },
    },
    {
      title: createTitle('Bowling centers', 'bc', false, false),
      col: 'Bowling centers',
      field: 'bc',
      render: (data, record, index) => {
        const { id, bc } = record;
        return (
          <div className="user_info" style={{ maxWidth: 400, minWidth: 200 }}>
            <PillBox
              color="#fff6"
              noCog
              placeholder=""
              value={bc.map(datum => ({ value: datum.id, label: datum.name }))}
              options={bowlingcenters}
              onOpen={() => {
                setPillboxstate({
                  pillboxopts: bowlingcenters,
                  pillboxval: bc.map(datum => ({
                    value: datum.id,
                    label: datum.name,
                  })),
                  pillboxvisible: true,
                  pillboxtitle: 'Bowling centers',
                  pillboxonchange: change => {
                    setPillboxstate({
                      ...pillboxstate,
                      pillboxval: change.map(group => ({
                        value: group,
                        label: group,
                      })),
                    });
                    editUserAsAdmin({ userid: id, bc: change });
                    setUpdate(update + 1);
                  },
                });
              }}
            />
          </div>
        );
      },
    },
    {
      title: createTitle('Active tariff plan', 'tariff', false, true),
      col: 'Active tariff plan',
      field: 'tariff',
      render: (data, record, index) => {
        const { tariff } = record;
        return (
          <div className="user_info">
            <div className="infoblock">
              <Input readonly value={tariff.name} placeholder={''} />
            </div>
          </div>
        );
      },
    },
    {
      title: createTitle('Active tariff date', 'tariff.ab_pend', true, true),
      col: 'Active tariff date',
      field: 'tariff.ab_pend',
      render: (data, record, index) => {
        const { tariff } = record;
        return (
          <div className="user_info">
            <div className="infoblock">
              <Input
                readonly
                value={
                  tariff.ab_pend
                    ? moment(tariff.ab_pend).format('DD.MM.YYYY')
                    : ''
                }
                placeholder={''}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: createTitle('Active account', 'disabled', true),
      col: 'Active account',
      field: 'disabled',
      render: (data, record, index) => {
        const { disabled } = record;
        return (
          <div className="user_info" style={{ maxWidth: 400 }}>
            <Checkbox
              checked={!disabled}
              onChange={checked =>
                editUserAsAdmin({ userid: record.id, disabled: !checked })
              }
            />
          </div>
        );
      },
    },
    {
      title: createTitle('Home Bowling Center', 'disabled', false, false),
      col: 'Home Bowling Center',
      field: 'disabled',
      render: (data, record, index) => {
        const { homeBowlingCenter } = record;
        if (!homeBowlingCenter) return;

        const {
          bowlingCenterId,
          bowlingCenterName,
          cityName,
          countryName,
        } = homeBowlingCenter;
        return (
          <div className="user_info" style={{ maxWidth: 600 }}>
            {bowlingCenterName || ''} {countryName || ''} {cityName || ''}
          </div>
        );
      },
    },
    {
      title: createTitle('Deleted', 'deleted_at', true),
      col: 'Deleted',
      field: 'deleted_at',
      render(_, record) {
        return (
          <div className="user_info">
            <div className="user_name">
              {record.deleted_at
                ? moment(record.deleted_at).format('DD.MM.YY HH:mm')
                : ''}
            </div>
          </div>
        );
      },
    },
  ];

  userlistcolumns = [
    ...userlistcolumns,
    ...admin.currencyList.map(currency => ({
      title: createTitle(
        `${currency.isocode} Accounts`,
        `accounts.field-iso${currency.isocode}`,
        true,
        false,
      ),
      field: `accounts.field-iso${currency.isocode}`,
      col: `${currency.isocode} Accounts`,
      render: (data, record, index) => {
        const { accounts } = record;
        const account = accounts.find(
          account => account.isocode === currency.isocode,
        ) || { amount: 0 };
        return (
          <div className="user_info">
            <div className="infoblock">
              <Input
                readonly
                value={`${account.amount} ${currency.symbol}`}
                placeholder={''}
              />
            </div>
          </div>
        );
      },
    })),
  ];

  const userListData = (admin.userList || []).filter(item => {
    let res = true;
    Object.keys(tablefilters).forEach(filter => {
      const filterKeys = filter.split('.').length;
      if (tablefilters[filter] && tablefilters[filter] !== '') {
        if (filterKeys <= 1) {
          if (!item[filter]) return (res = false);
        }
        // Work only for tariff date
        if (filterKeys === 2) {
          const pattern = new RegExp('[0-9]{4}.[0-9]{2}.[0-9]{2}$');
          if (pattern.test(tablefilters[filter])) {
            if (
              moment(tablefilters[filter]).isBefore(item.tariff.ab_pend) ||
              !item.tariff.ab_pend
            ) {
              return (res = false);
            }
            return (res = true);
          }
        }

        const fieldtype = Array.isArray(item[filter])
          ? 'array'
          : typeof item[filter];

        switch (fieldtype) {
          case 'array':
            if (item[filter] && item[filter].length) {
              if (typeof item[filter][0] === 'object') {
                if (
                  tablefilters[filter]
                    .split(/[\s\,]/g)
                    .filter(f => !(f.match(/\s/) || f == ''))
                    .forEach(filterItem => {
                      if (
                        !item[filter].find(
                          datum =>
                            datum.name.toLowerCase() ===
                            filterItem.toLowerCase(),
                        )
                      ) {
                        res = false;
                      }
                    })
                );
              } else if (
                tablefilters[filter]
                  .split(/[\s\,]/g)
                  .filter(f => !(f.match(/\s/) || f == ''))
                  .forEach(filterItem => {
                    switch (filter) {
                      case 'permissions':
                        if (
                          !item[filter].find(datum =>
                            (
                              admin.permissionsList.find(
                                perm => perm.value === datum,
                              ) || {}
                            ).label
                              .toLowerCase()
                              .includes(filterItem.toLowerCase()),
                          )
                        ) {
                          res = false;
                        }
                        return;
                      default:
                        if (
                          !item[filter].find(
                            datum =>
                              datum.toLowerCase() === filterItem.toLowerCase(),
                          )
                        ) {
                          res = false;
                        }
                    }
                  })
              );
            } else {
              res = false;
            }
            return;
          case 'object':
            if (item[filter].isocode) {
              if (
                tablefilters[filter]
                  .split(/[\s\,]/g)
                  .filter(f => !(f.match(/\s/) || f == ''))
                  .forEach(filterItem => {
                    if (
                      !item[filter] ||
                      !item[filter].isocode
                        .toLowerCase()
                        .includes(filterItem.toLowerCase())
                    ) {
                      res = false;
                    }
                  })
              );
            } else if (item[filter].name) {
              if (
                tablefilters[filter]
                  .split(/[\s\,]/g)
                  .filter(f => !(f.match(/\s/) || f == ''))
                  .forEach(filterItem => {
                    if (
                      !item[filter] ||
                      !item[filter].name
                        .toLowerCase()
                        .includes(filterItem.toLowerCase())
                    ) {
                      res = false;
                    }
                  })
              );
            } else if (item[filter].value) {
              if (
                tablefilters[filter]
                  .split(/[\s\,]/g)
                  .filter(f => !(f.match(/\s/) || f == ''))
                  .forEach(filterItem => {
                    if (
                      !item[filter] ||
                      !item[filter].value
                        .toString()
                        .toLowerCase()
                        .includes(filterItem.toLowerCase())
                    ) {
                      res = false;
                    }
                  })
              );
            } else {
              res = false;
            }
            return;
          case 'string':
            const pattern = new RegExp('[0-9]{4}.[0-9]{2}.[0-9]{2}$');
            if (pattern.test(tablefilters[filter])) {
              if (moment(tablefilters[filter]).isBefore(item[filter])) {
                res = false;
              }
            }
          case 'number':
          default:
            switch (filter) {
              case 'country_live':
              case 'country_federation':
                const country = countries.find(
                  country =>
                    country.value.toLowerCase() === item[filter].toLowerCase(),
                );
                if (
                  tablefilters[filter]
                    .split(/[\s\,]/g)
                    .filter(f => !(f.match(/\s/) || f == ''))
                    .forEach(filterItem => {
                      if (
                        !item[filter] ||
                        !country ||
                        !(
                          country.label
                            .toLowerCase()
                            .includes(filterItem.toLowerCase()) ||
                          country.value
                            .toLowerCase()
                            .includes(filterItem.toLowerCase())
                        )
                      ) {
                        res = false;
                      }
                    })
                );
                return;
              default:
                if (
                  tablefilters[filter]
                    .split(/[\s\,]/g)
                    .filter(f => !(f.match(/\s/) || f == ''))
                    .forEach(filterItem => {
                      if (
                        !item[filter] ||
                        !item[filter]
                          .toString()
                          .toLowerCase()
                          .includes(filterItem.toLowerCase())
                      ) {
                        res = false;
                      }
                    })
                );
            }
        }
      }
    });
    return res;
  });

  return (
    <div className="admin_panelWrapper">
      <div className="filters">
        <PillBox
          color="#fff6"
          noShowcase
          placeholder="Columns"
          value={Object.keys(activecolusers)
            .filter(col => activecolusers[col])
            .map(col => ({ value: col, label: col }))}
          options={userlistcolumns.map(col => ({
            value: col.col,
            label: col.col,
          }))}
          onOpen={() => {
            setPillboxstate({
              pillboxopts: userlistcolumns.map(col => ({
                value: col.col,
                label: col.col,
              })),
              pillboxval: Object.keys(activecolusers)
                .filter(col => activecolusers[col])
                .map(col => ({ value: col, label: col })),
              pillboxvisible: true,
              pillboxtitle: 'Visible columns',
              pillboxonchange: change => {
                setPillboxstate({
                  ...pillboxstate,
                  pillboxval: change.map(col => ({ value: col, label: col })),
                });
                setUpdate(update + 1);
                setTablefilters({});
                setActivecolusers(
                  change.reduce((acc, val) => ({ ...acc, [val]: true }), {}),
                );
              },
            });
          }}
        />
      </div>

      <Table
        className="table userlist"
        columns={userlistcolumns.filter(
          col =>
            Object.keys(activecolusers).includes(col.col) &&
            activecolusers[col.col],
        )}
        dataSource={userListData.slice((page - 1) * 20, page * 20)}
        pagination={false}
        rowKey="id"
        style={{ width: '100%' }}
        locale={{ emptyText: 'No data' }}
        rowClassName={'user_row'}
      />
      {userlistcolumns
        .filter(
          col =>
            Object.keys(activecolusers).includes(col.col) &&
            activecolusers[col.col] &&
            modals[col.field],
        )
        .map(col => (
          <>
            <OBModal
              title={col.col}
              visible
              closable={false}
              footer={
                <Button
                  key="okButton"
                  kind="aquamarine"
                  onClick={() => (
                    setModals({ ...modals, [col.field]: !modals[col.field] }),
                    setTablefilters({
                      ...tablefilters,
                      [col.field]: tmpfilterval,
                    }),
                    setTmpfilterval(false),
                    setUpdate(update + 1),
                    setPage(1)
                  )}
                >
                  OK
                </Button>
              }
            >
              <Input
                onChangeText={e => setTmpfilterval(e)}
                value={tmpfilterval}
                placeholder={'Filter'}
              />
            </OBModal>
          </>
        ))}
      <Pagination
        defaultCurrent={1}
        defaultPageSize={20}
        total={userListData.length}
        onChange={value => setPage(value)}
        current={page}
      />
      <PillBoxHost
        onChange={pillboxstate.pillboxonchange}
        visible={pillboxstate.pillboxvisible}
        options={pillboxstate.pillboxopts}
        title={pillboxstate.pillboxtitle}
        values={pillboxstate.pillboxval}
        onClose={() => {
          setPillboxstate({
            pillboxvisible: false,
            pillboxopts: [],
            pillboxval: [],
            pillboxtitle: null,
            pillboxonchange: () => {},
          });
        }}
      />
    </div>
  );
};

export default injectIntl(
  withRouter(
    connect(
      state => ({
        user: state.users.user,
        admin: state.admin,
        permissions: state.users.permissions
          ? state.users.permissions.keys || []
          : [],
        permissionsfetch: state.users.permissions
          ? state.users.permissions.fetch
          : false,
        common: state.common,
        bowlingcenters: state.bowlingcenters.bowlingcenters.data,
      }),
      {
        getAllUsers,
        UserByIdFetch,
        editUserAsAdmin,
        applyUsersSort,
      },
    )(UserList),
  ),
);
