import { setCookies } from './reducers/actions';
import { store, storage } from '../store';
import OPTIONS from '../config';
import Utils from './utils';

const { getFramework } = Utils;
const framework = getFramework();
/**
 * 
 * @param {string} url
 * @param {*} response - axios response object
 */
export const handleResponseCookies = (url, response) => {
  if (framework !== 'ReactNative' || !OPTIONS.saveNativeCookies) {
    return;
  }
  const { 'set-cookie': setcookies = '' } = response.headers;
  const action = setCookies({ url, setcookies });
  store.dispatch(action);
}

const parseState = (value) => {
  const parsedState = JSON.parse(value) || {};
  const props = Object.keys(parsedState);
  for (const prop of props) {
    parsedState[prop] = JSON.parse(parsedState[prop]);
  }
  return parsedState;
};

export const getStateSync = () => store.getState();

export const getStateAsync = async () => {
  const state = await storage.getItem('persist:root');
  return parseState(state);
};

export const isAppLoaded = () => store.getState().common.app_loaded;

export const selectCookiesSync = (state) => state.cookies;

export const selectCookiesAsync = () => getStateAsync().then(selectCookiesSync)

/**
 * 
 * @param {object} param0
 * @param {string} param0.origin - origin of request url
 * @param {object} param0.options - shared options
 * @param {object} param0.state - shared options
 * @param {object} param0.options.headers - shared headers
 * @return {object}
 */
export const addCookiesToHeaders = ({ origin, options, state }) => {
  if (!OPTIONS.saveNativeCookies) {
    return { ...options };
  }
  const { cookies } = state;
  const cookie = cookies[origin];
  if (!cookie || !cookie.stringCookies) {
    return { ...options };
  }
  return {
    ...options,
    headers: {
      ...options.headers,
      Cookie: cookie.stringCookies,
    },
  };
}
