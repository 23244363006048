import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { markYellowCardAsSeen } from '../../../../libs/reducers/squads.reducer';
import { Button } from '../../Button';

import OBModal from '../../Modal';
import './style.scss';
import yellowCardBig from '../../../../images/yellowCardBig.png';

class YellowCardModal extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    intl: intlShape,
  };

  handleClose = () => {
    this.props.markYellowCardAsSeen();
  };

  render() {
    const { intl } = this.props;
    return (
      <OBModal
        title={intl.formatMessage({ id: 'YellowCard', defaultMessage: 'Yellow Card!' })}
        visible={this.props.yellowCard}
        width={395}
        onOk={this.handleClose}
        onCancel={this.handleClose}
        footer={
          <Button
            key="okButton"
            kind="aquamarine"
            onClick={() => {
             this.handleClose();
            }}>Ок</Button>}>
        <img className='yellowCardImage' src={yellowCardBig}/>
        <div className='yellowCardCaption'>{intl.formatMessage({ id: 'YellowCardFull', defaultMessage: 'You got yellow card for delaying game more than 5 minutes' })}</div>
      </OBModal>
    );
  }
}

export default injectIntl(
  connect(
    state => ({
      yellowCard: state.squadScreen.yellowCardEvent,
    }),
    {markYellowCardAsSeen},
  )(YellowCardModal),
);
