import { EventEmitter } from './tools/events';

/**
 * @typedef {'login' | 'launch_app' | 'user' | 'logout' | 'registration' | 'start_game' | 'end_game' | 'subscribe' | 'purchase'} LocalEventName
 */

/**
 * @typedef {{
 *  id?: string | number;
 *  login?: string;
 *  firstName?: string;
 *  lastName?: string;
 *  gender?: 'f' | m' | '?'
 *  email?: string;
 *  country?: string;
 *  city?: string;
 *  birthdate?: string;
 *  provider?: 'email' | 'google' | 'apple' | 'facebook' | 'instagram'
 * }} LocalUser
 */

/**
 * @typedef {{
 *  id: number;
 *  firstname: string;
 *  lastname: string;
 *  gender: string;
 *  dob: string;
 *  login: string;
 *  email: string;
 *  photo?: string;
 *  emailVerified: boolean | 0 | 1;
 *  country_live_name: string;
 * }} ReduxUser
 */
/**
 * @template T
 * @typedef {T extends 'login' | 'user'
 *  ? LocalUser
 *  : T extends 'logout' | 'launch_app'
 *  ? undefined
 *  : T extends 'registration'
 *  ? { user: LocalUser; registration_method: string }
 *  : T extends 'start_game' | 'end_game'
 *  ? { squad_id?: number | string }
 *  : T extends 'subscribe' | 'purchase'
 *  ? { price?: number; currency?: string }
 *  : never
 * } LocalEventParams
 */
/**
 * @template TName
 * @typedef {(params: LocalEventParams<TName>) => any} LocalEventListener
 */

const emitter = new EventEmitter();

export const LocalEvents = {
  /**
   * @template T
   * @param {Extract<T, LocalEventName> | LocalEventName} event
   * @param {LocalEventListener<T>} listener
   * @returns
   */
  on: (event, listener) => {
    return emitter.on(event, listener);
  },
  /**
   * @template T
   * @param {Extract<T, LocalEventName> | LocalEventName} event
   * @param {(...args: any[]) => any} listener
   */
  off: (event, listener) => {
    return emitter.off(event, listener);
  },
  /**
   *
   * @template T
   * @param {Extract<T, LocalEventName> | LocalEventName} event
   * @param {LocalEventParams<T>} params
   */
  emit: (event, params) => {
    return emitter.emit(event, params);
  },

  /**
   * @param {ReduxUser} user
   * @param {string} [provider]
   * @returns {LocalUser}
   */
  toUser: (user, provider) => {
    user = user || {}
    return {
      id: user.id,
      firstName: user.firstname,
      lastName: user.lastname,
      login: user.login,
      gender: user.gender && user.gender.toLowerCase ? user.gender.toLowerCase() : undefined,
      email: user.email,
      country: user.country_live_name,
      birthdate: user.dob,
      provider,
    };
  },
  /**
   * @param {string} [currency]
   */
  toCurrency: (currency) => {
    if (!currency || currency.length > 3) {
      return 'EUR';
    }
    return currency.toUpperCase()
  },
};
