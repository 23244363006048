import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route, NavLink, withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import './style.scss';
import { MyTournaments } from './myTournaments';
import { Personal } from './personal';
import { VerificationScreen } from './verification';
import BowlingCenters from './bowlingCenters';

import FriendList from './friendList';
import FriendsApplications from './friendsApplications';
import PlayerSearch from './playerSearch';
import CashOperations from './cash';
import MyBattlesTable from './myBattles';
import UserTariff from './tariffs';
import UserStatistics from './statistics';

import SvgNotification from '-!svg-react-loader!../../images/Notification.svg'; // eslint-disable-line
import SvgChat from '-!svg-react-loader!../../images/chat.svg'; // eslint-disable-line
import SvgSwords from '-!svg-react-loader!../../images/tabSword.svg'; // eslint-disable-line
import BadgeIcon from '../common/BadgeIcon/BadgeIcon';
import MyBattles from '../tournament/myBattles';
import Notifications from '../notifications';
import TournamentsChat from '../tournament/tournamentsChat/tournamentsChat';
import NotificationSettings from './settings/notifications';

import OBModal from '../common/Modal';
import { Button } from '../common/Button';
import {
  getUserTariff,
  setTariffCallback,
} from '../../libs/reducers/actions';

class AccountPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      routes: [],
      selectedSidebar: null,
      sidebarShown: false,
      currentRoom: props.currentRoom
    }
  }

  static propTypes = {
    intl: intlShape,
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    match: PropTypes.shape({
      isExact: PropTypes.bool.isRequired,
      path: PropTypes.string.isRequired,
    }),
    unreadNotifications: PropTypes.number,
    rooms: PropTypes.arrayOf(PropTypes.shape()),
  };

  componentDidMount() {
    this.props.getUserTariff();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { match, history, location } = nextProps;
    const { isExact, path } = match;
    const routes = [
      {
        alias: 'personal',
        title: nextProps.intl.formatMessage({ id: 'MyAccountRouterPersonalTitle', defaultMessage: 'Personal' }),
        component: Personal
      },
      // {
      //   alias: 'verification',
      //   title: nextProps.intl.formatMessage({ id: 'MyAccountRouterVerificationTitle', defaultMessage: 'Verification' }),
      //   component: VerificationScreen
      // },
      {
        alias: 'tournaments',
        title: nextProps.intl.formatMessage({ id: 'MyAccountRouterTournamentsTitle', defaultMessage: 'My tournaments' }),
        component: MyTournaments
      },
      {
        alias: 'battles',
        title: nextProps.intl.formatMessage({ id: 'MyAccountRouterBattlesTitle', defaultMessage: 'My Battles' }),
        component: MyBattlesTable
      },
      {
        alias: 'cash',
        title: nextProps.intl.formatMessage({ id: 'MyAccountRouterCashTitle', defaultMessage: 'Cash' }),
        component: CashOperations
      },
      // {
      //   alias: 'statistics',
      //   title: nextProps.intl.formatMessage({ id: 'MyAccountRouterStatisticsTitle', defaultMessage: 'Statistics' }),
      //   component: nextProps.usertariff?UserStatistics:() => <OBModal
      //     title={nextProps.intl.formatMessage({ id: 'AccessDeny', defaultMessage: 'No access' })}
      //     visible={true}
      //     width={395}
      //     onCancel={() => nextProps.history.push('/account')}
      //     footer={[
      //       <Button
      //         kind="aquamarine"
      //         bordered="true"
      //         onClick={() => {
      //           nextProps.setTariffCallback(`OpenStatistics`);
      //           history.push('/account/tariffs')}
      //         }>
      //         {nextProps.intl.formatMessage({ id: 'StatTariffOffer', defaultMessage: 'Buy PRO' })}
      //       </Button>
      //     ]}
      //     >
      //     <div style={{ padding: '15px 0' }}>{nextProps.intl.formatMessage({ id: 'StatTariffOfferText', defaultMessage: 'You need to enable PRO tariff to gain access to the Statistics' })}</div>
      //   </OBModal>,
      // },
      {
        alias: 'bowlingcenters',
        title: nextProps.intl.formatMessage({ id: 'MyAccountRouterBowlingCentersTitle', defaultMessage: 'Bowling Centers' }),
        component: BowlingCenters
      },
      /*{
        alias: 'refereeing',
        title: nextProps.intl.formatMessage({ id: 'MyAccountRouterRefereeingTitle', defaultMessage: 'Refereeing' }),
        component: () => <span>refereeing</span>
      },
      {
        alias: 'my teams',
        title: nextProps.intl.formatMessage({ id: 'my_teams', defaultMessage: 'My teams' }),
        component: () => <span>my teams</span>
      },
      {
        alias: 'rating',
        title: nextProps.intl.formatMessage({ id: 'rating', defaultMessage: 'Rating' }),
        component: () => <span>rating</span>
      },*/
      {
        alias: 'friends',
        title: nextProps.intl.formatMessage({ id: 'FriendsRouterTitle', defaultMessage: 'Friends' }),
        childs: [
          {
            alias: 'list',
            title: nextProps.intl.formatMessage({ id: 'FriendRouterTitle', defaultMessage: 'Friend list' }),
            component: FriendList,
          },
          // {
          //   alias: 'applications',
          //   title: nextProps.intl.formatMessage({ id: 'FriendApplicationsRouterTitle', defaultMessage: 'Applications' }),
          //   component: FriendsApplications,
          // },
          {
            alias: 'search',
            title: nextProps.intl.formatMessage({ id: 'SearchUserRouterTitle', defaultMessage: 'Search player' }),
            component: PlayerSearch,
          },
        ]
      },
      {
        alias: 'settings',
        title: nextProps.intl.formatMessage({ id: 'SettingsRouterTitle', defaultMessage: 'Settings' }),
        childs: [
          {
            alias: 'notifications',
            title: nextProps.intl.formatMessage({ id: 'NotificationTabHeader', defaultMessage: 'Notifications' }),
            component: NotificationSettings,
          },
        ]
      },
      // {
      //   alias: 'tariffs',
      //   component: UserTariff,
      //   title: nextProps.intl.formatMessage({ id: 'tariffs', defaultMessage: 'Tariffs' }),
      // },
    ];
    const [ route ] = routes;
    const { alias } = route;
    if (isExact) {
      history.push(`${path}/${alias}`);
    } else {
      const { pathname } = location;
      const parseUrl = pathname.match(/(\w+)/g);
      const parentRouteAlias = parseUrl.pop();
      const parentRoute = routes.find(r => r.alias == parentRouteAlias);
      if (parentRoute) {
        const { childs = [] } = parentRoute;
        if (childs.length) {
          const [childRoute] = childs;
          const { alias: childRouteAlias } = childRoute;
          history.push(`${path}/${parentRouteAlias}/${childRouteAlias}`);
        }
      }
    }
    if (nextProps.currentRoom !== prevState.currentRoom) {
      return { currentRoom: nextProps.currentRoom, selectedSidebar: 3, sidebarShown: true, routes };
    }
    return { routes };
  }

  renderSidebar = () => {
    const { selectedSidebar } = this.state;
    switch (selectedSidebar) {
      case 1: 
        return <MyBattles onChat={() => this.setState({ selectedSidebar: 3, sidebarShown: true })}/>
      case 2:
        return <Notifications/>
      case 3:
        return <TournamentsChat/>
      default: 
        return null;
    }
  } 

  onClickSidebarItem = (index) => {
    const { selectedSidebar, sidebarShown } = this.state;
    if (selectedSidebar == index && sidebarShown) {
      this.setState({ selectedSidebar: null, sidebarShown: false })
    } else {
      this.setState({ selectedSidebar: index, sidebarShown: true }) 
    }
  }

  renderMenu = () => {
    const { match } = this.props;
    const { routes } = this.state;
    let links = [];
    routes.map(({ alias, title, childs }) => {
      if (!childs) {
        links.push(
          <NavLink
            to={`${match.url}/${alias}`}
            className="account_page__menu_item"
            activeClassName="active"
          >
            {title}
          </NavLink>
        );
      } else {
        links.push(
          <NavLink
            to={`${match.url}/${alias}`}
            className="account_page__menu_item parent"
            activeClassName="active"
          >
            <div className="account_page__menu_item__title">
              <span>{title}</span>
            </div>
            <div className="account_page__menu_item__childs">
              {
                childs.map(child => (
                  <NavLink
                    to={`${match.url}/${alias}/${child.alias}`}
                    className="account_page__menu_item child"
                    activeClassName="active"
                  >
                    {child.title}
                  </NavLink>
                ))
              }
            </div>
          </NavLink>
        );
      }
    })
    return links;
  } 

  renderRouters = () => {
    const { match } = this.props;
    const { routes } = this.state;
    let routers = [];
    routes.map(({ alias, childs, component }) => {
      if (!childs) {
        routers.push(
          <Route key={alias} path={`${match.path}/${alias}`} component={component} />
        );
      } else {
        childs.map(child => {
          routers.push(
            <Route key={child.alias} path={`${match.path}/${alias}/${child.alias}`} component={child.component} />
          )
        })
      }
    })
    return routers;
  }

  render() {
    const { unreadNotifications, rooms, match, systemMessages } = this.props;
    const { selectedSidebar, sidebarShown } = this.state;

    // let unreadMessages = 0;
    // for (const room of rooms) {
    //   const { message: { total = 0, seen } = {} } = room;
    //   unreadMessages += (total - (seen || 0));
    // }
    const unreadMessages = rooms.reduce((count, room) => {
      if ((room.room_name.startsWith('squad-chat') || 
        room.room_name.startsWith('private-room') || 
        room.room_name.startsWith('support-room')) && 
        (this.props.currentRoom !== room.room_name) && 
        (room.users && room.users.length > 1)
      ) {
        const { message: { seen = 0, total = 0, total_system = 0, seen_system = 0 } = {} } = room;

        return (systemMessages)
          ? count + (total - seen)
          : count + ((total - total_system) - (seen - seen_system));
      }
      return count;
    }, 0);

    return (
      <div className="account_page">
        <div className="account_page__container">
          <div className="account_page__left_sidebar">
            {this.renderMenu()}
          </div>
          <div className="account_page__content">
            { this.renderRouters() }
          </div>
          <div className={`account_page__right_sidebar${sidebarShown ? ' shown' : ''}`}>
            { this.renderSidebar() }
          </div>
        </div>
        <div className="account_page__footer">
          <div className="account_page__sidebar_items">
            <div className={`account_page__sidebar_item${selectedSidebar == 1 && sidebarShown ? ' active' : ''}`}
              onClick={() => {
                this.onClickSidebarItem(1)
              }}
            >
              <SvgSwords/>
            </div>
            <div className={`account_page__sidebar_item${selectedSidebar == 2 && sidebarShown ? ' active' : ''}`}
              onClick={() => {
                this.onClickSidebarItem(2)
              }}
            >
              <BadgeIcon count={unreadNotifications}>
                <SvgNotification />
              </BadgeIcon>
            </div>
            <div className={`account_page__sidebar_item${selectedSidebar == 3 && sidebarShown ? ' active' : ''}`}
              onClick={() => {
                this.onClickSidebarItem(3)
              }}
            >
              <BadgeIcon count={unreadMessages}>
                <SvgChat />
              </BadgeIcon>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(withRouter(
  connect(
    state => ({
      unreadNotifications: state.notifications.notifications.count,
      rooms: state.chat.rooms,
      currentRoom: state.chat.currentRoom,
      usertariff: state.billing.usertariff,
      systemMessages: state.chat.systemSquadChat,
    }),
    { getUserTariff, setTariffCallback },
  )(AccountPage),
));
