import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import '../../../style.scss';
import { Debounce } from 'react-throttle';
import Input from '../../common/Input';
import Select from '../../common/Select';
import { Table } from 'antd';
import lodash from 'lodash';
import {
  getAlikeUsers,
  getTransactionHistory,
} from '../../../libs/reducers/adminReducer';

const PaymentHistory = ({
  admin,
  getTransactionHistory,
  intl,
  getAlikeUsers,
}) => {

  const [userid, setUserid] = useState(null)
  const [userquery, setUserquery] = useState(null)
  const [currencies, setCurrencies] = useState([])
  const [userlist, setUserlist] = useState([])
  const [account, setAccount] = useState(null)

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current === undefined ? value : ref.current;
  }
  const prevProps = usePrevious({ admin })

  useEffect(() => {
    setUserlist(
      admin.userPicker.map(usr => {
        return {
          value: usr.id,
          label: `id: ${usr.id}, login: ${usr.login}, name: ${usr.firstname} ${usr.lastname}, email: ${usr.email}`,
        }
      })
    )
    setCurrencies(
      admin.currencyList.map(curr => {
        return {
          value: curr.id,
          label: `${curr.name}, ${curr.symbol}`,
        }
      })
    )
  }, [])

  useEffect(() => {
    if (!lodash.isEqual(prevProps.admin.userPicker, admin.userPicker)) {
      setUserlist(
        admin.userPicker.map(usr => {
          return {
            value: usr.id,
            label: `id: ${usr.id}, login: ${usr.login}, name: ${usr.firstname} ${usr.lastname}, email: ${usr.email}`,
          }
        })
      )
      setCurrencies(
        admin.currencyList.map(curr => {
          return {
            value: curr.id,
            label: `${curr.name}, ${curr.symbol}`,
          }
        })
      )
      }
  }, [admin])

  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      sorter: (a, b) => (a.id - b.id),
      render: (data, record, index) => ({
        props: {},
        children: (
          <span class='innerText'>{data}</span>
        ),
      }),
    },
    {
      title: intl.formatMessage({ id: 'date', defaultMessage: 'Date' }),
      dataIndex: 'created',
      render: (data, record) => <>
        {moment(record.created).format('DD.MM.YY HH:mm')}
      </>
    },
    {
      title: 'type',
      dataIndex: 'type',
      sorter: (a, b) => a.type.localeCompare(b.type),
      render: (data, record, index) => ({
        props: {},
        children: (
          <span class='innerText' style={{ color: '#f65932' }}>{data}</span>
        ),
      }),
    },
    {
      title: () => <div className='action'>
        <div className='id'>id</div>
        <div className='type'>type</div>
        <div className='backlog'>backlog</div>
        <div className='amount'>amount</div>
      </div>,
      dataIndex: 'actions',
      render: (data, record, index) => ({
        props: {},
        children: (<div className='actions'>
          {data.map(action => <>
            <div className='action' key={action.id}>
              <div className='id'>{action.id}</div>
              <div className='type' style={{ color: '#f65932' }}>{action.actiontype}</div>
              <div className='backlog'>{action.outowner || 'SYSTEM'} <span style={{ color: '#f65932', margin: '15px 0' }}>{`=>`}</span> {action.inowner || 'SYSTEM'}</div>
              <div className='amount'>{action.amount}</div>
            </div>
          </>)}
        </div>),
      })
    },
  ]
  const accountObj = account ? (admin.transactionHistory || []).find(acc => acc.id === account.value) : false;

  return (
    <div className='admin_paymentHistory'>
      <div className='user-wrap'>
        <Debounce time="3000" handler="onChangeText">
          <Input
            onChangeText={(e) => (setUserquery(e), getAlikeUsers(e))}
            value={userquery}
            placeholder={intl.formatMessage({ id: 'AdminPageUserSearch', defaultMessage: 'User search' })}
          />
        </Debounce>
        <Select
          isSearchable
          value={userid}
          placeholder={intl.formatMessage({ id: 'AdminPageUserInput', defaultMessage: 'User' })}
          onChange={(e) => (setUserid(e), getTransactionHistory(e))}
          options={userlist}
        />
      </div>
      <div className='wallet-wrap'>
        <Select
          value={account}
          placeholder={'Кошелек'}
          onChange={(e) => (setAccount(e))}
          options={(admin.transactionHistory || []).map(acc => ({
            label: `id: ${acc.id}, currency: ${acc.symbol}(${acc.currency}), amount: ${acc.amount}`,
            value: acc.id,
          }))} />
      </div>
      {accountObj && <>
        <Table
          className="table"
          columns={columns}
          dataSource={accountObj.transactions}
          pagination={false}
          rowKey="id"
          style={{ width: '100%' }}
          locale={{ emptyText: 'No transactions' }}
          rowClassName={'transactionRow'}
          onRow={(record, index) => { }}
        />
      </>}
    </div>
  )
}

export default injectIntl(withRouter(
  connect(
    state => ({
      admin: state.admin,
    }),
    {
      getAlikeUsers,
      getTransactionHistory,
    },
  )(PaymentHistory),
));
