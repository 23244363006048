// eslint-disable-next-line import/prefer-default-export
export const TEST_BANNERS = [
  {
    name: 'Spring TPC League',
    title: 'Spring TPC League',
    image:
      '/upload/resize_cache/iblock/4a5/500_300_1/em1caoiskp2g5hdcgdw5irxpp2vmigx6.jpg',
    image_with_domain:
      'https://tenpincam.com/upload/resize_cache/iblock/4a5/500_300_1/em1caoiskp2g5hdcgdw5irxpp2vmigx6.jpg',
    link: '/en/tournaments/',
    link_with_domain: 'https://tenpincam.com/en/tournaments/',
  },
  {
    name: 'Apr-May Open',
    title: 'Apr-May Open',
    image:
      '/upload/resize_cache/iblock/fd6/500_300_1/p7mptr7ko532c2wajo4zik0uns9d8fdl.jpg',
    image_with_domain:
      'https://tenpincam.com/upload/resize_cache/iblock/fd6/500_300_1/p7mptr7ko532c2wajo4zik0uns9d8fdl.jpg',
    link: '/en/tournaments/',
    link_with_domain: 'https://tenpincam.com/en/tournaments/',
  },
  {
    name: 'TPC Practice 6',
    title: 'TPC Practice 6',
    image:
      '/upload/resize_cache/iblock/c77/500_300_1/ktia28bov1j1ks8g7bj0rbn5zuw1kc3o.jpg',
    image_with_domain:
      'https://tenpincam.com/upload/resize_cache/iblock/c77/500_300_1/ktia28bov1j1ks8g7bj0rbn5zuw1kc3o.jpg',
    link: '/en/tournaments/tenpincam-practice-6/',
    link_with_domain:
      'https://tenpincam.com/en/tournaments/tenpincam-practice-6/',
  },
  {
    name: 'Youth TPC Practice 3',
    title: 'Youth TPC Practice 3',
    image:
      '/upload/resize_cache/iblock/5a2/500_300_1/j6zfdhkhj41xiuflhq68toja3krmeo8q.jpg',
    image_with_domain:
      'https://tenpincam.com/upload/resize_cache/iblock/5a2/500_300_1/j6zfdhkhj41xiuflhq68toja3krmeo8q.jpg',
    link: '/en/tournaments/youth-tenpincam-practice-3/',
    link_with_domain:
      'https://tenpincam.com/en/tournaments/youth-tenpincam-practice-3/',
  },
];
