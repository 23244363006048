import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.css';
import check from './images/check.png';
import smallcheck from './images/checkbox.png';

export default class Checkbox extends Component {
  static propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    style: PropTypes.shape(),
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  };

  static defaultProps = {
    checked: false,
  };

  handleInputChange = (event) => {
    const { checked } = event.target;
    this.props.onChange(checked);
  };

  render() {
    const {
      checked,
      children,
      small,
      style,
      color,
      disabled,
    } = this.props;
    let checkBoxElementClass = 'checkbox__checkmark checkbox__checked';
    let checkBoxSmall = 'checkbox__checkmark';
    let checkBoxSmallDisabled = 'checkbox__checkmark__disabled';

    if (small) {
      if (color) {
        checkBoxElementClass = 'checkbox__checkmark__small__color checkbox__checked__small__color';
        checkBoxSmall = 'checkbox__checkmark__small__color';
      } else {
        checkBoxElementClass = 'checkbox__checkmark__small checkbox__checked__small';
        checkBoxSmall = 'checkbox__checkmark__small';
      }
    }

    let CheckboxElement = checked ? (
      <span className={checkBoxElementClass}>
        <img className={small ? 'image__small' : ''} src={small && !color ? smallcheck : check} alt="" />
      </span>
    ) : (
      <span className={checkBoxSmall} />
    );
    if (disabled){
      CheckboxElement = (
          <span className={checkBoxSmallDisabled} />
      )
    }
    return (
      <label className="checkbox" style={style}>
        {CheckboxElement}
        {children}
        <input disabled={disabled} className="checkbox__input" type="checkbox" checked={checked} onChange={this.handleInputChange} />
      </label>
    );
  }
}
