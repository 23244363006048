/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route, NavLink, withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { getAgreements } from '../../libs/reducers/commonReducer';
import SvgNotification from '-!svg-react-loader!../../images/Notification.svg'; // eslint-disable-line
import SvgChat from '-!svg-react-loader!../../images/chat.svg'; // eslint-disable-line
import SvgSwords from '-!svg-react-loader!../../images/tabSword.svg'; // eslint-disable-line
import BadgeIcon from '../common/BadgeIcon/BadgeIcon';
import MyBattles from '../tournament/myBattles';
import Notifications from '../notifications';
import TournamentsChat from '../tournament/tournamentsChat/tournamentsChat';
import AboutPlatform from './about';

import './style.scss';
import { openPolicy } from '../policy';

class AboutPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSidebar: null,
      prevLang: this.props.langs.lang || 'en',
      sidebarShown: false,
      currentRoom: props.currentRoom,
    }
  }

  static propTypes = {
    intl: intlShape,
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    match: PropTypes.shape({
      isExact: PropTypes.bool.isRequired,
      path: PropTypes.string.isRequired,
    }),
    unreadNotifications: PropTypes.number,
    rooms: PropTypes.arrayOf(PropTypes.shape()),
  };

  componentDidMount() {
    this.props.getAgreements(this.props.langs.lang || 'en');
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { match, history, agreements, intl, langs } = nextProps;
    if (prevState.prevLang !== langs.lang) { nextProps.getAgreements(langs.lang || 'en') }
    const routes = [
      {
        alias: 'about',
        title: intl.formatMessage({ id: 'AboutApplication', defaultMessage: 'About application' }),
        component: AboutPlatform,
      },
      {
        alias: 'tc',
        title: intl.formatMessage({ id: 'TCagreeD', defaultMessage: 'Terms and condition' }),
        component: () => null,
        // component: () => <>
        //   <div className='userAgreementNav'>{((agreements[`TC${(langs.lang || 'en').toLowerCase()}`]||'').match(/id='(ol[0-9]+)'((.|\n|\r)*?)<\//gi)||[]).map((headlink, index) => <>
        //     <div className='headlink' onClick={() => {
        //       document.querySelector(`#${headlink.match(/ol[0-9]+/)}`).scrollIntoView({ behavior: 'smooth', block: 'start' });
        //     }}>{index+1}. {(headlink.match(/>([а-яА-Яa-zA-Z\s]+)<\//)||['',''])[1]}</div>
        //   </>)}</div>
        //   <div className='userAgreementText' dangerouslySetInnerHTML={{ __html: agreements[`TC${(langs.lang || 'en').toLowerCase()}`] }}/>
        // </>
      },
      {
        alias: 'pd',
        title: intl.formatMessage({ id: 'PDagreeD', defaultMessage: 'Privacy policy' }),
        component: () => <>
          <div className='userAgreementNav'></div>
          <div className='userAgreementText' dangerouslySetInnerHTML={{ __html: agreements[`PD${(langs.lang || 'en').toLowerCase()}`] }}/>
        </>
      },
      {
        alias: 'of',
        title: intl.formatMessage({ id: 'OFagreeD', defaultMessage: 'Contract offer' }),
        component: () => <>
          <div className='userAgreementNav'></div>
          <div className='userAgreementText' dangerouslySetInnerHTML={{ __html: agreements[`OF${(langs.lang || 'en').toLowerCase()}`] }}/>
        </>
      },
      {
        alias: 'rc',
        title: intl.formatMessage({ id: 'CAagreeD', defaultMessage: 'Refund and Cancellation policy' }),
        component: () => <>
          <div className='userAgreementNav'></div>
          <div className='userAgreementText' dangerouslySetInnerHTML={{ __html: agreements[`CA${(langs.lang || 'en').toLowerCase()}`] }}/>
        </>
      },
      // Скрываеам вкладку
      // {
      //   alias: 'wbrules',
      //   title: 'World Bowling Playing Rules',
      //   component: () => <>
      //     <div className="world_bowling_playing_rules">
      //       <a
      //         href="https://www.worldbowling.org/wp-content/uploads/2019/11/World-Bowling-Statutes-and-Playing-Rules-as-of-Sept-2019-web.pdf"
      //         target="_blank"
      //         rel="noopener noreferrer"
      //       >
      //         World Bowling Statutes and Playing Rules
      //       </a>
      //     </div>
      //   </>,
      // },
    ];
    const { isExact, path } = match;
    const [route] = routes;
    const { alias } = route;
    if (isExact) {
      history.push(`${path}/${alias}`);
    }
    if (nextProps.currentRoom !== prevState.currentRoom) {
      return { currentRoom: nextProps.currentRoom, selectedSidebar: 'chat', routes, prevLang: nextProps.langs.lang };
    }
    return { routes, prevLang: nextProps.langs.lang };
  }

  renderSidebar = () => {
    const { selectedSidebar } = this.state;
    switch (selectedSidebar) {
      case 1: 
        return <MyBattles onChat={() => this.setState({ selectedSidebar: 3, sidebarShown: true })}/>
      case 2:
        return <Notifications/>
      case 3:
        return <TournamentsChat/>
      default: 
        return null;
    }
  } 

  onClickSidebarItem = (index) => {
    const { selectedSidebar, sidebarShown } = this.state;
    if (selectedSidebar == index && sidebarShown) {
      this.setState({ selectedSidebar: null, sidebarShown: false })
    } else {
      this.setState({ selectedSidebar: index, sidebarShown: true }) 
    }
  }

  render() {
    const { unreadNotifications, rooms, match } = this.props;
    const { selectedSidebar, sidebarShown, routes } = this.state;
    let unreadMessages = 0;
    for (const room of rooms) {
      const { message: { total = 0, seen } = {} } = room;
      unreadMessages += (total - (seen || 0));
    }
    return (
      <div className="about_page">
        <div className="about_page__container">
          <div className="about_page__left_sidebar">
            {
              routes.map(({ alias, title }) => (
                <NavLink
                  to={`${match.url}/${alias}`}
                  className="about_page__menu_item"
                  activeClassName="active"
                  onClick={(e) => {
                    if (alias === 'tc') {
                      e.preventDefault()
                      e.stopPropagation()
                      openPolicy({ kind: 'TC' })
                    }
                  }}
                >
                  {title}
                </NavLink>
              ))
            }
          </div>
          <div className="about_page__content">
            {
              routes.map(({ alias, component }) => (
                <Route key={alias} path={`${match.path}/${alias}`} component={component} />
              ))
            }
          </div>
          <div className={`about_page__right_sidebar${sidebarShown ? ' shown' : ''}`}>
              { this.renderSidebar() }
          </div>
        </div>
        <div className="about_page__footer">
          <div className="about_page__sidebar_items">
            <div className={`about_page__sidebar_item${selectedSidebar == 1 && sidebarShown ? ' active' : ''}`}
              onClick={() => {
                this.onClickSidebarItem(1)
              }}
            >
              <SvgSwords/>
            </div>
            <div className={`about_page__sidebar_item${selectedSidebar == 2 && sidebarShown ? ' active' : ''}`}
              onClick={() => {
                this.onClickSidebarItem(2)
              }}
            >
              <BadgeIcon count={unreadNotifications}>
                <SvgNotification />
              </BadgeIcon>
            </div>
            <div className={`about_page__sidebar_item${selectedSidebar == 3 && sidebarShown ? ' active' : ''}`}
              onClick={() => {
                this.onClickSidebarItem(3)
              }}
            >
              <BadgeIcon count={unreadMessages}>
                <SvgChat />
              </BadgeIcon>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(withRouter(
  connect(
    state => ({
      unreadNotifications: state.notifications.notifications.count,
      rooms: state.chat.rooms,
      currentRoom: state.chat.currentRoom,
      langs: state.langs,
      agreements: state.common.agreements,
    }),
    {getAgreements},
  )(AboutPage),
));
