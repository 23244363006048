/* eslint-disable arrow-parens */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import IbanValueCreateFragment from './iban-value-create.fragment.jsx';
import validateEmailValue from './helpers/validate-email-value';
import validateIbanValue from './helpers/validate-iban-value';

/**
 * @typedef {import('./iban-value-create.fragment').IbanValueCreateFragmentProps} IbanValueCreateFragmentProps
 */
/**
 * @typedef {Omit<IbanValueCreateFragmentProps, 'intl'>} IbanValueCreateComponentProps
 */
/**
 * @type {React.FC<IbanValueCreateComponentProps>}
 */
const IbanValueCreateComponent = props => {
  const { onChange, type, setRef } = props;
  const [error, setError] = useState(props.error);
  useEffect(() => {
    setError(props.error);
  }, [props.error]);
  return <>
    <IbanValueCreateFragment
      value={props.value}
      setRef={setRef}
      onChange={value => {
        if (!value) {
          setError(true);
        } else if (type === 'iban' && !validateIbanValue(value)) {
          setError('invalid iban');
        } else if (type === 'paypal' && !validateEmailValue(value)) {
          setError('invalid email');
        } else {
          setError(false);
        }
        return onChange && onChange(value);
      }}
      error={error}
      type={type}
    />
  </>;
};

export default IbanValueCreateComponent;
