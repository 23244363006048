import {
  call, put, spawn, select,
} from 'redux-saga/effects';
import bugsnagClient from '../../bugsnagClient';
import config from '../../config';
import { _socketSaga } from './Socket';
import { getCookies } from './saga';
import { get, post, put as putMethod } from '../Networking';
import { SQUAD_ID_SET } from '../reducers/squadId.reducer';
import { _GetGeoData, _GetSettings, _GetUserCountry, _GetTimeZones } from './Common';
import { _unreadNotificationsCount } from './notifications.saga';
import { _GetBowlingCenters } from './bowlingCenters.saga';
import Url from 'url';
import { myCookies, MyCookies } from '../MyCookies';
import { fetchCookieAgreement } from './cookieAgreement.saga';
import { START_TIMER } from '../reducers/TimerReducer';
import { _GetAllTranslations } from './Langs';
import {setReferralLink} from "../reducers/actions";
import { LocalEvents } from '../local-events';

const getUser = state => state.users.user;
const getLang = state => state.langs.lang;

const {
  USER_SET,
  USER_BY_ID_SET,
  ACCESSED,
  LOGOUT,
  REG,
  SOCIAL_ACCOUNT,
  LANG_SET,
  SOCKET_DISCONNECT,
  REG_LOGIN_ERROR,
  REG_EMAIL_ERROR,
  REG_CODE_ERROR,
  REG_EMAIL_SEND,
  VERIFICATED_PHONE,
  PHONE_SEND,
  USER_EMAIL_CODE_SET,
  PHONE_CODE_SEND,
  USER_EDIT_SET,
  USER_SUCCESS_RESET,
  USER_FAILED_RESET,
  PASS_RESPONSE,
  RATE_USER_POST,
  USER_PASSWORD_UPDATE_COMPLETE,
  USER_PASSWORD_UPDATE_FAILED,
  USER_PASSRESET_STATUS,
  UPDATE_USER_DATA,
  SET_PERMISSIONS,
  SET_BOWLINGCENTER_PERMISSIONS,
  GET_BOWLINGCENTER_PERMISSIONS,
  FETCH_GET_COOKIE_STATE,
  FETCH_COOKIE_STATE,
  SET_COOKIE_STATE,
  CHECK_LOGIN_STRING,
  SET_LOGIN_CHECK_RESULT,
  SET_USERSTATS,
  EMAIL_CODE_CHECK,
  CONFIRM_EMAIL,
  USER_EMAIL_CODE_ERROR,
  SET_ACTIVE_USERS,
  SET_LEADER_BOARD,
  SET_HELP_STATUS,
  SET_COUNT_USERS_REGISTERED,
  SET_MAX_WATCHER_COUNT,
  SAVE_MAX_WATCHER_COUNT,
  GET_REFERRAL_LINK,
  SET_REFERRAL_LINK,
} = require('../reducers/actiontypes').default;
const { COMMON_NOTIFICATION } = require('../reducers/commonReducer');

export function* _UserGetPermissions() {
  try {
    const result = yield call(get, {
      url: `${config.FETCH.url}/user/permissions`,
    });
    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_PERMISSIONS, data: result.data.data });
    } else {
      bugsnagClient.notify(new Error(`Error. User get permissions. ${JSON.stringify({ error: result.error || result || '' })}`))
      yield put({ type: SET_PERMISSIONS, data: [] });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'GetPermissions' });
    console.error('Get Permissions: ', error);
  }
}

export function* _UserGetBowlingCenterPermissions() {
  try {
    const response = yield call(get, { url: `${config.FETCH.url}/user/bowlingcenterpermissions` });
    const payload = response.status === 200 && response.data.success ? response.data.data : [];
    yield put({ type: SET_BOWLINGCENTER_PERMISSIONS, payload });
  } catch (error) {
    yield put({ type: SET_BOWLINGCENTER_PERMISSIONS, error });
    bugsnagClient.notify(error);
    console.error('Error, Get BowlingCenter Permissions: ', error);
  }
}

export function* _UserByIdGet({ params = {} }) {
  try {
    // запрос на получение из бд
    const result = yield call(get, { url: `${config.FETCH.url}/user/${params.userid}` });
    if (result.status === 200) {
      yield put({ type: USER_BY_ID_SET, data: result.data.data });
    } else {
      bugsnagClient.notify(new Error(`Error. User by id get. ${JSON.stringify({ error: result.error || result || '' })}`))
      yield put({ type: USER_BY_ID_SET, error: result.error || result || '' });
    }
  } catch (error) {
    bugsnagClient.notify(error, {
      context: 'UserByIdGet',
      beforeSend: (report) => {
        report.updateMetadata('payload', params);
      },
    });
    console.error('_UserGet: ', error);
  }
}

const getvalue = (obj) => {
  if (typeof obj !== 'object') {
    return obj || undefined;
  }
  const { value } = obj;
  return value || undefined;
}

export const selectUser = state => state.users.user;

export const selectUsers = state => state.users;

export function* _UserSet(params) {
  const users = yield select(selectUsers);
  const { user, snuser } = users;

  try {
    const sendParams = {
      photo: params.params.userPhoto,
      firstname: params.params.firstName,
      lastname: params.params.lastName,
      dob: params.params.dob,
      gender: params.params.gender === 'null' ? '?' : params.params.gender,
      country_live: getvalue(params.params.countryLive),
      country_federation: getvalue(params.params.countryFederation),
      hourshift: getvalue(params.params.hourShift),
      club: params.params.club,
      bowlingcenterid: params.params.bowlingcenterid,
      lang: params.params.lang,
    };
    const isRegistration = !user.firstname || !user.lastname || !user.dob || !user.gender || !user.country_live;
    // if snuser not empty
    if (snuser && snuser.id) {
      // send login too
      sendParams.login = params.params.login;
    }
    const result = yield call(putMethod, { url: `${config.FETCH.url}/user/${user.id}`, data: sendParams });
    if (result.status === 200 && result.data.success) {
      yield spawn(_socketSaga, {
        eventName: 'auth',
        params: { id: result.data.data.id, sessionkey: result.data.data.session.sessionkey },
      });
      if (isRegistration) {
        const registration_method = snuser && snuser.id ? snuser.type : 'email'
        const user = result.data.data
        LocalEvents.emit('registration', {
          user: LocalEvents.toUser(user),
          registration_method,
        })
      }
      yield put({ type: USER_SET, params: result.data.data });
      yield put({ type: USER_EDIT_SET, data: result.data });
      yield put({ type: LANG_SET, params: result.data.data.lang });
      yield put({ type: FETCH_GET_COOKIE_STATE });
      if (!(Object.keys(params.params).length === 1 && Object.keys(params.params).includes('lang'))) {
        yield put({ type: ACCESSED, params: true });
        yield put({ type: REG, params: false });
        yield put({ type: SOCIAL_ACCOUNT, payload: null });
      }
    } else {
      bugsnagClient.notify(new Error(`Error. User set. ${JSON.stringify({ error: result.error || result || '' })}`))
      yield put({ type: USER_EDIT_SET, error: result.error });
    }
  } catch (error) {
    yield put({ type: USER_EDIT_SET, error });
    bugsnagClient.notify(error, { context: 'UserSet' });
    console.error('sagaSet err: ', error);
  }
}

export function* _UserLogin(params) {
  try {
    const lang = yield select(getLang);
    const sessionType = config.platform
    const result = yield call(post, {
      url: `${config.FETCH.url}/login?type=local`,
      data: { login: params.params.login, password: params.params.password, lang, sessionType },
    }, true, [400, 401, 403]);

    if (result.status === 200) {
      yield spawn(_socketSaga, {
        eventName: 'auth',
        params: { id: result.data.id, sessionkey: result.data.session.sessionkey, sessionType },
      });
      yield put({ type: SOCIAL_ACCOUNT, payload: null });
      yield put({ type: USER_SET, params: result.data });
      yield put({ type: LANG_SET, params: result.data.lang });
      if (!result.data.firstname || !result.data.lastname || !result.data.dob || !result.data.gender || !result.data.country_live) {
        yield put({ type: ACCESSED, params: false });
        yield put({ type: REG, params: true });
      } else {
        yield put({ type: ACCESSED, params: true });
        yield put({ type: GET_BOWLINGCENTER_PERMISSIONS });
        LocalEvents.emit('login', LocalEvents.toUser(result.data, 'email'))
      }
      yield put({ type: SQUAD_ID_SET, params: result.data.squadid });
      yield call(fetchCookieAgreement);
      //yield put({ type: 'SET_COOKIE_AGREEMENT_MODAL', payload: true }); // активировать модалку
      yield call(_GetGeoData);
      yield call(_GetUserCountry);
      yield call(_GetBowlingCenters);
      yield call(_unreadNotificationsCount);
      yield call(_GetTimeZones);
      yield call(_GetSettings);
    } else {
      bugsnagClient.notify(new Error(`Error. User login. ${JSON.stringify({ error: result.error || result || '' })}`))
    }
    if (result.status === 400) {
      yield put({ type: USER_SET, error: result.data.error.response.data, withoutErrorModal: true });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'UserLogin' });
    console.error('_UserLogin: ', error);
  }
}

function* UserData(params) {
  try {
    const result = yield call(post, {
      url: `${config.FETCH.url}/login?type=local`,
      data: { login: params.params.login, password: params.params.password },
    });
    if (result.status === 200) {
      yield put({ type: USER_SET, params: result.data });
    }
    if (result.status === 400){
      yield put({ type: USER_SET, error: 217 });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'UserData' });
    console.error('UserData: ', error);
  }
}

export function* _UpdateUser(params = {}) {
  try {
    const key = yield call(getCookies, 'the_cookie');
    const user = yield call(post, { url: `${config.FETCH.url}/getuserbysession`, key });
    if (user !== null) {
      yield put({ type: USER_SET, params: { ...user.data, ...params } });
      LocalEvents.emit('user', LocalEvents.toUser({ ...user.data, ...params }))
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'UpdateUser' });
    console.error('UpdateUser: ', error);
  }
}

export function* _UserReg(params) {
  try {
    const lang = yield select(state => state.langs.lang);
    const result = yield call(post, {
      url: `${config.FETCH.url}/sign_up`,
      data: {
        login: params.params.login,
        email: params.params.email,
        password: params.params.password,
        regcode: params.params.regcode,
        lang: lang || 'EN',
        mobile: !!params.params.mobile,
      },
    });

    if (result.status === 200 && result.data.success) {
      yield call(UserData, { params: { login: result.data.data.login, password: result.data.data.password } });
      const userDataError =  yield select(state => state.users.error)
      if (userDataError === 217){
        yield put({ type: REG_LOGIN_ERROR, params: `Login ${params.params.login} already exist` });
      }else {
      yield put({ type: SOCIAL_ACCOUNT, payload: null });
      yield put({ type: REG, params: true });
      }
    } else {
      if (result.data.loginError && result.data.loginError !== '') {
        yield put({ type: REG_LOGIN_ERROR, params: result.data.loginError });
      }
      if (result.data.emailError && result.data.emailError !== '') {
        // Передаем Код сообщения перевода или сообщение
        yield put({ type: REG_EMAIL_ERROR, params: result.data.emailMessageCode || result.data.emailError });
      }
      if (result.data.regcodeError && result.data.regcodeError !== '') {
        yield put({ type: REG_CODE_ERROR, params: result.data.regcodeError });
      }
    }
    yield put({ type: 'USER_REG_DONE' })
  } catch (error) {
    bugsnagClient.notify(error, { context: 'UserReg' });
    console.error('_UserReg: ', error);
    yield put({ type: 'USER_REG_DONE' })
  }
}

const cleanLocalStorage = () => {
  let localStorage;
  try {
    ({ localStorage = null } = window || global);
  } catch (err) {
    console.error(err);
  }
  if (!localStorage) {
    return;
  }
  for (let i = 0; i < localStorage.length; i += 1) {
    localStorage.removeItem(localStorage.key(i));
  }
};

export function* UserExit() {
  try {
    const date = new Date().toISOString();
    yield put({ type: SOCKET_DISCONNECT });
    if (typeof document !== 'undefined') {
      document.cookie = `the_cookie=; expires=${date}`;
    } else {
      yield call(myCookies.cleanAll);
    }
    yield put({ type: ACCESSED, params: false });
    yield put({ type: LOGOUT });
    yield call(_GetSettings);
  } catch (e) {
    console.log('UserExit error', e);
  }
}

export function* SNUserFetchSaga() {
  try {
    const res = yield call(get, { url: `${config.FETCH.url}/user/snuser` });
    const { snuser } = res.data || {};
    yield put({ type: SOCIAL_ACCOUNT, payload: snuser });
  } catch (e) {
    yield put({ type: 'SOCIAL_ACCOUT_FETCH_FAILED' })
  }
}

export function* _UserLogout() {
  const date = new Date();
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/logout` });

    if (result) {
      yield put({ type: SOCKET_DISCONNECT });
      if (typeof document !== 'undefined') {
        document.cookie = `the_cookie=; expires=${date.toUTCString()}`;
      } else {
        yield call(myCookies.cleanAll);
      }
      // закомментил, чтобы куки из персиста не стерлись
      // cleanLocalStorage();
      yield put({ type: ACCESSED, params: false });
      //yield put({ type: 'SET_COOKIE_AGREEMENT', payload: { userid: -1, confirmed: false } });
      yield put({ type: LOGOUT });
      yield call(_GetSettings);
    }

    // const result = yield call(get, { url: `${config.FETCH.url}/logout` });
    //
    // if (result) {
    //   yield put({ type: SOCKET_DISCONNECT });
    //   if (typeof document !== 'undefined') {
    //     document.cookie = `the_cookie=; expires=${date.toUTCString()}`;
    //   } else {
    //     yield call(myCookies.cleanAll);
    //   }
    //   // закомментил, чтобы куки из персиста не стерлись
    //   // cleanLocalStorage();
    //   yield put({ type: ACCESSED, params: false });
    //   yield put({ type: 'SET_COOKIE_AGREEMENT', payload: { userid: -1, confirmed: false } });
    //   yield put({ type: LOGOUT });
    //   yield call(_GetSettings);
    // }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'Logout' });
    console.error('Logout: ', error);
  }
}

export function* _UserGetPhone() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/user/phone` });
    if (result.status === 200 && result.data.success) {
      yield put({ type: VERIFICATED_PHONE, params: { phone: result.data.phone } });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'Get User Phone' });
    console.log('Get user phone: ', error);
  }
}

export function* _UserPutPhone(params) {
  try {
    yield put({ type: PHONE_SEND, params: { success: -1 } });
    const result = yield call(putMethod, { url: `${config.FETCH.url}/user/phone`, data: { phone: params.params } });
    if (result.status === 200 && result.data.success) {
      yield put({ type: PHONE_SEND, params: { success: result.data.success } });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'Put User Phone' });
    console.log('Put user phone: ', error);
  }
}

export function* _UserGetCodePhone() {
  try {
    yield call(get, { url: `${config.FETCH.url}/user/phoneverify` });
  } catch (error) {
    bugsnagClient.notify(error, { context: 'User Get Phone verification code' });
    console.log('Send verification code: ', error);
  }
}

export function* _UserCheckCodePhone(params) {
  try {
    yield put({ type: PHONE_CODE_SEND, params: { success: -1 } });
    const result = yield call(putMethod, {
      url: `${config.FETCH.url}/user/phoneverify`,
      data: { code: params.params },
    });
    if (result.status === 200) {
      yield put({ type: PHONE_CODE_SEND, params: { success: result.data.success } });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'User check Phone verification code' });
    console.log('Check verification code: ', error);
  }
}

export function* _UserSendCodeEmail(params) {
  try {
    const { email, mobile = 0, checkDispatch } = params.params;
    const mobileText = !!mobile
      ? email
        ? '&mobile=1'
        : '?mobile=1'
      : '';

    if (checkDispatch && !email) {
      return;
    }
    const checkText = checkDispatch ? '&check=1' : '';
    const url = `${config.FETCH.url}/user/email/send${email ? `?email=${email}` : ''}${mobileText}${checkText}`
    const ignoreError = true;
    const result = yield call(get, { url }, ignoreError);

    if (result.status === 200) {
      if (result.data.error && result.data.emailMessageCode === 'EmailAlreadyClaimed') {
        yield put({ type: USER_EMAIL_CODE_ERROR, params: result.data.emailMessageCode || result.data.emailError });
      } else {
        yield put({ type: START_TIMER });
        yield put({
          type: USER_EMAIL_CODE_SET,
          data: {
            email,
          },
        });
        yield put({
          type: UPDATE_USER_DATA,
        })
      }
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'User send Email verification code' });
    console.log('Email verification not sent: ', error);
  }
}

export function* _UserPassworUpdate({ params }) {
  const { token, password } = params;
  try {
    console.log('UpdatePassword: ', params);
    const { data } = yield call(putMethod, {
      url: `${config.FETCH.url}/user/password/update`,
      data: { token, password },
    });
    const { success } = data;
    if (!success) {
      yield put({ type: USER_PASSWORD_UPDATE_FAILED, data: { error: data.error } });
    } else {
      yield put({ type: USER_PASSWORD_UPDATE_COMPLETE, data: { login: data.data } });
    }
  } catch (error) {
    yield put({ type: USER_PASSWORD_UPDATE_FAILED, data: { error: `${error}` } });
  }
}

export function* _UserPasswordReset(params) {
  const { phone, email, device } = params.params;
  try {
    const { data: { success, error } = {} } = yield call(post, {
      url: `${config.FETCH.url}/user/password/reset`,
      data: { phone, email, device },
    }, true);
    if (!success || error) {
      yield put({ type: USER_PASSRESET_STATUS, data: 'FAILED' });
    } else {
      yield put({ type: USER_PASSRESET_STATUS, data: 'COMPLETE' });
    }
  } catch (error) {
    console.log('Password reset not requested: ', error);
  }
}

export function* _UserPasswordResetPhone(params) {
  const { key, phone, pass } = params.params;
  try {
    const result = yield call(putMethod, {
      url: `${config.FETCH.url}/user/password/reset`,
      data: { key, phone, pass },
    });
    if (result.status === 200 && result.data.success) {
      yield put({ type: USER_SUCCESS_RESET, data: { login: result.data.login } });
    } else if (result.status === 200) {
      yield put({ type: USER_FAILED_RESET });
    }
  } catch (error) {
    console.log('Verification code not sent');
  }
}

export function* _UserPasswordChange(params) {
  const { newpass, oldpass } = params.params;
  try {
    const result = yield call(putMethod, {
      url: `${config.FETCH.url}/user/password/change`,
      data: { newpass, oldpass },
    });
    yield put({ type: PASS_RESPONSE, data: { success: result.data.success, errorcode: result.data.errorcode } });
  } catch (error) {
    console.log('Password update failed');
  }
}

export function* _rateUserPost(params) {
  const {
    userid, rating, createdby, comment, gameid,
  } = params.params;
  try {
    const result = yield call(post, {
      url: `${config.FETCH.url}/user/${userid}/ratings`,
      data: {
        userid,
        rating,
        createdby,
        comment,
        gameid,
      },
    });
    if (!result.data.success) {
      console.error('User rating post: server return zero success field');
    }
  } catch (error) {
    console.log('Failed to rate user!');
  }
}

const isDefaultLogin = (login, snuser) => {
  const match = (login || '').match(/^(vkontakte|facebook|google|instagram)?(?:-|_)(\d+)$/);
  const result = !!match && match[2] === `${snuser.id}`;
  return result;
};

export function* _sendFirebaseToken({ payload }) {
  try {
    const request = yield call(post, { url: `${config.FETCH.url}/notifications/firebase_token`, data: payload });
    if (request.status !== 200 || !request.data.success) {
      console.log('Failed to send Firebase token');
    }
  } catch (error) {
    console.log('Failed to send Firebase token');
  }
}

const time = () => `[${new Date().toISOString().slice(11, -1)}]`;

export function* _sendVerificationCode({ payload }) {
  try {
    const { verificationCode } = payload;
    yield call(get, { url: `${config.FETCH.url}/user/email/verify/?code=${verificationCode}` })
  } catch (error) {
    console.log('Failed to send verification code, error: ', error);
  }
}

export function* _sendAppleAuth({ payload }) {
  try {
    const request = yield call(post, { url: `${config.FETCH.url}/apple-auth`, data: payload });
    if (request.status === 200) {
      yield _UpdateUser();
      if (request.data.success && request.data.payload.isReg) {
        yield put({ type: REG, params: true });
        return;
      }
      const user = yield select(selectUser);
      yield spawn(_socketSaga, {
        eventName: 'auth',
        params: { id: user.id, sessionkey: user.session.sessionkey },
      });
      LocalEvents.emit('login', LocalEvents.toUser(user, 'apple'))
      yield put({ type: USER_SET, params: user });
      yield put({ type: LANG_SET, params: user.lang });
      yield put({ type: ACCESSED, params: true });
      yield put({ type: GET_BOWLINGCENTER_PERMISSIONS });
      yield put({ type: SQUAD_ID_SET, params: user.squadid });
      yield call(_GetGeoData);
      yield call(_GetUserCountry);
      yield call(_GetBowlingCenters);
      yield call(_unreadNotificationsCount);
      yield call(_GetTimeZones);
      yield call(_GetSettings);
      yield put({ type: 'FETCH_COOKIE_AGREEMENT' });
    }
  } catch (error) {
    console.log('Failed to auth with appleid, error: ', error);
  }
}

export function* _sendAuthorizationCode({ payload }) {
  try {
    const { authorizationCode, redirectURL: _pathname, app = 'mobile' } = payload;
    const { pathname } = Url.parse(_pathname);
    const lang = yield select(state => state.langs.lang);
    const url = `${config.FETCH.url}${decodeURIComponent(pathname)}?code=${decodeURIComponent(authorizationCode)}&app=${app}&lang=${lang || 'EN'}`;
    console.log(time(), 'SEND_AUTHORIZATION_CODE/request: ', { url, method: 'get', code: authorizationCode, redirect: pathname });
    const { status, data } = yield call(get, { url });
    console.log(time(), 'SEND_AUTHORIZATION_CODE/response: ', { status, data });
    if (status === 200 && data.payload) {
      // Если нет почты после авторизации через соцсети, то перенаправляем на страницу верификации email
      if (data.payload.isConfirm) {
        yield put({ type: CONFIRM_EMAIL, params: true });

        return;
      }

      yield put({ type: SOCIAL_ACCOUNT, payload: data.payload.snuser });
      yield _UpdateUser();
      if (data.success && (data.payload.isReg || isDefaultLogin(data.payload.snuser.login, data.payload.snuser))) {
        yield put({ type: REG, params: true });
        return;
      }
      const user = yield select(selectUser);
      yield spawn(_socketSaga, {
        eventName: 'auth',
        params: { id: user.id, sessionkey: user.session.sessionkey },
      });
      LocalEvents.emit('login', LocalEvents.toUser(user, data.payload.snuser.type))
      yield put({ type: USER_SET, params: user });
      yield put({ type: LANG_SET, params: user.lang });
      yield put({ type: ACCESSED, params: true });
      yield put({ type: GET_BOWLINGCENTER_PERMISSIONS });
      yield put({ type: SQUAD_ID_SET, params: user.squadid });
      yield call(_GetGeoData);
      yield call(_GetUserCountry);
      yield call(_GetBowlingCenters);
      yield call(_unreadNotificationsCount);
      yield call(_GetTimeZones);
      yield call(_GetSettings);
      yield put({ type: 'FETCH_COOKIE_AGREEMENT' });
    }
    if (status === 400) {
      yield put({ type: USER_SET, error: data.error.response.data });
    }
  } catch (error) {
    console.log('Failed to send authorization code, error: ', error);
  }
}

export function* _UserCheckLoginString(action) {
  try {
    const { status, data } = yield call(putMethod, {
      url: `${config.FETCH.url}/user/checklogin`,
      data: { login: action.login },
    });
    if (status === 200 && data.success) {
      const { payload } = data;
      yield put({ type: SET_LOGIN_CHECK_RESULT, payload: { success: payload.success, error: payload.error || '' } });
    } else {
      yield put({ type: SET_LOGIN_CHECK_RESULT, payload: { success: false, error: data.error } });
    }
  } catch (error) {
    yield put({ type: SET_LOGIN_CHECK_RESULT, payload: { success: false, error: error.message } });
    bugsnagClient.notify(error);
    console.log('[UserCheckLoginString] error: ', error);
  }
}

export function* _getStats() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/user/stats/${yield select(state => state.users.user.id)}` });
    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_USERSTATS, params: result.data.stats });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'Get User Stats' });
    console.log('Get user stats: ', error);
  }
}

export function* _checkMailVerification({ params }) {
  try {
    const { userid } = params;
    const result = yield call(get, { url: `${config.FETCH.url}/user/mailverified/${userid}` });
    if (result.status === 200 && result.data.success && !result.data.confirmed) {
      yield put({ type: 'FAILURE_EMAIL_VERIFIED', payload: { success: false, emailVerified: 0 } })
    } else {
      yield put({ type: 'SET_EMAIL_VERIFIED', payload: { success: result.data.success, emailVerified: result.data.confirmed } })
    }
  } catch (error) {
    yield put({ type: 'FAILURE_EMAIL_VERIFIED', payload: { success: false, emailVerified: 0 } })
  }
}

export function* _checkHelpWatched({ params }) {
  try {
    const { userid } = params;
    const result = yield call(get, { url: `${config.FETCH.url}/user/checkHelp/${userid}` });
    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_HELP_STATUS, payload: { helpWatched: result.data.result } })
    }
  } catch (error) {
    console.log(error);
  }
}

export function* _changeHelpWatched({ params }) {
  try {
    const { userid } = params;
    const result = yield call(post, { url: `${config.FETCH.url}/user/setCheckHelp/${userid}`, data: params });
    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_HELP_STATUS, payload: { helpWatched: result.data.result } })
    }
  } catch (error) {
    console.log(error);
  }
}

export function* _userUnregister({ params }) {
  const date = new Date();
  try {
    const { userid } = params;
    const result = yield call(get, { url: `${config.FETCH.url}/user/unregister/${userid}` });

    if (result.status === 200 && result.data.success) {
      yield put({ type: USER_SET, payload: { data: {} } });
      yield put({ type: SOCKET_DISCONNECT });
      yield put({ type: ACCESSED, params: false });
      yield put({ type: LOGOUT });
      yield call(_GetSettings);
    } else {
      yield put({ type: USER_SET, payload: { success: 0, error: result.data.error } })
    }
    if (typeof document !== 'undefined') {
      document.cookie = `the_cookie=; expires=${date.toUTCString()}`;
    } else {
      yield call(myCookies.cleanAll);
    }
  } catch (error) {
    console.log('Unregister user error:', error)
  }
}


export function* _SocialSendCodeEmail({ params }) {
  try {
    // :TODO Нет времени жизни проверочного кода 8( (Как вариант сделать столбец с временем проверки, и при проверке кода проверять еще и время)
    const email = (params.email) ? params.email : '';
    const lang = (params.lang) ? params.lang : 'EN';
    const ignoreError = true;
    const result = yield call(get, { url: `${config.FETCH.url}/user/email/send_social?email=${email}&lang=${lang}` }, ignoreError);
    if (result.data.error) {
      console.log('Email verification not sent: ', result.data.error);
      yield put({ type: REG_EMAIL_ERROR, params: result.data.emailMessageCode || result.data.emailError });
      return;
    }
    yield put({ type: START_TIMER });
    yield put({ type: REG_EMAIL_SEND });
    yield put({ type: COMMON_NOTIFICATION, params: 'weHaveSendYouEmail' });
  } catch (error) {
    console.log('Email verification not sent: ', error);
    yield put({ type: REG_EMAIL_ERROR, params: 'CommonErrorOccuredSafe' });
  }
}

export function* _CheckSocialSendCodeEmail({ params }) {
  const { code, email } = params;

  if (!code || !email) {
    console.log('Error. Not found Code or Email');
    return;
  }

  try {
    const result = yield call(get, { url: `${config.FETCH.url}/user/email/check_code?code=${code}&email=${email}` });

    if (result.status === 200 && result.data.success) {
      const url = `${config.FETCH.url}/getemail/verify`;
      const data = yield call(post, {
        url,
        data: { email }
      });

      if (data.status === 200 && data.data.success && data.data.payload) {
        const { payload, success } = data.data;

        yield put({ type: SOCIAL_ACCOUNT, payload: payload.snuser });
        yield _UpdateUser();
        const user = yield select(selectUser);

        // Если в БД есть пользователь с данным email, верифицируем почту
        yield call(get, { url: `${config.FETCH.url}/user/email/verify_at_email?code=${code}&email=${email}` });
        // Устанавливаем значение, что верификация верна
        yield put({ type: 'SET_EMAIL_VERIFIED', payload: { success: 1, emailVerified: 1 } });
        yield put({ type: EMAIL_CODE_CHECK, params: true });
        if (success && (payload.isReg || isDefaultLogin(payload.snuser.login, payload.snuser))) {
          yield put({ type: REG, params: true });
          return;
        }
        yield spawn(_socketSaga, {
          eventName: 'auth',
          params: { id: user.id, sessionkey: user.session.sessionkey },
        });
        yield put({ type: USER_SET, params: user });
        yield put({ type: LANG_SET, params: user.lang });
        yield put({ type: ACCESSED, params: true });
        yield put({ type: GET_BOWLINGCENTER_PERMISSIONS });
        yield put({ type: SQUAD_ID_SET, params: user.squadid });
        yield call(_GetGeoData);
        yield call(_GetUserCountry);
        yield call(_GetBowlingCenters);
        yield call(_unreadNotificationsCount);
        yield call(_GetTimeZones);
        yield call(_GetSettings);
        yield put({ type: 'FETCH_COOKIE_AGREEMENT' });
      }
    } else {
      yield put({ type: REG_CODE_ERROR, params: 'CommonErrorOccuredSafe' });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'User send Email verification code' });
    console.log('Email verification not sent: ', error);
  }
}

export function* _GetCountUsersRegistered() {
  try {
    const { data, status } = yield call(get, { url: `${config.FETCH.url}/users/count_registered` });

    if (status === 200 && data.success) {
      yield put({ type: SET_COUNT_USERS_REGISTERED, params: data.countUsersRegistered });
    } else {
      console.error('Error GetTotalUsersRegistered \n', data.error);
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'Get total count users registered' });
    console.error('Error GetTotalUsersRegistered \n', error);
  }
}

export function* _SetUserType({ params }) {
  /**
   * Метод установки типа пользователя.
   * Тип пользователя может быть либо 'player' либо 'watcher'
   */
  try {
    const url = `${config.FETCH.url}/user/setUserType`;
    const sendData = { userid: params.userid, type: params.userType };
    const { data, status } = yield call(post, { url, data: sendData });

    if (status === 200 && data.success) {
      yield put({ type: USER_SET, params: data.user });
    } else {
      console.error('Error. Set user type.');
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'Set user type.' });
    console.error('Error. Set user type. ', error);
  }
}

//не используемый(первый) вариант смены типа клавиатуры
export function* _ChangeKeyboardType({ params }) {
  try {
    const url = `${config.FETCH.url}/user/setKeyboardType`;
    const { data, status } = yield call(post, { url, data: { keyboardType: params.keyboardType } });
    if (status === 200 && data.success) {
      yield put({ type: USER_SET, params: data.user });
    } else {
      console.log('Error. Set keyboard type');
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'Set keyboard type' });
    console.error('Error. Set user type. ', error);
  }
}
export function* _getActiveUsers({ params }) {
  /**
   * Получение числа активных пользователей
   * */
  const url = `${config.FETCH.url}/users/activeUserCount`;
  try {
    const request = yield call(get, { url });
    const { data, status } = request;
    if (status === 200 && data.success) {
      yield put({ type: SET_ACTIVE_USERS, params: data });
    } else {
      console.error('Error. Get active users.');
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'Get user type.' });
    console.error('Error. Get user type. ', error);
  }
}

export function* _getMaxWatcherCount({ params }) {
  /**
   * Получение максимального числа зрителей
   * */
  const url = `${config.FETCH.url}/users/maxWatcherCount`;
  try {
    const request = yield call(get, { url });
    const { data, status } = request;
    if (status === 200 && data.success) {
      yield put({ type: SET_MAX_WATCHER_COUNT, params: data });
    } else {
      console.error('Error. Get max watcher count.');
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'Get max watcher count.' });
    console.error('Error. Get max watcher count. ', error);
  }
}

export function* _saveMaxWatcherCount({ payload }) {
  /**
   * Обновление максимального количества зрителей
   * */
  console.log(payload)
  const url = `${config.FETCH.url}/users/maxWatcherCountUpdate`;
  try {
    const request = yield call(post, { url, data: { value: payload } });
    const { data, status } = request;
    if (status === 200 && data.success) {
      yield put({ type: SET_MAX_WATCHER_COUNT, payload: data });
    } else {
      console.error('Error. Set max watcher count.');
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'Set max watcher count.' });
    console.error('Error. Set max watcher count. ', error);
  }
}

export function* onAccess(action) {
  try {
    if (action.params) {
      const lang = yield select(state => state.users.user.lang)
      if (lang) {
        yield put({ type: 'TRANSLATIONS_FETCH', params: lang })
      } else {
        yield call(_GetAllTranslations)
      }
    }
  } catch (e) {
    bugsnagClient.notify(e, { context: 'Set max watcher count.' });
    console.error('Error. Set max watcher count. ', e);
  }
}


export function* _getLeaderBoard({ params }) {

  const url = `${config.FETCH.url}/tournament/getLeaderBoard`;

  try {

    const request = yield call(post, { url, data: { tournamentid: params.tournamentid } });
    const { data, status } = request;

    if (status == 200 && data.success) {
      yield put({ type: SET_LEADER_BOARD, data: data.data });
    } else {
      console.error('Error. Get leaderBoard.')
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'Get leaderBoard  JSON.' });
    console.error('Error. Get leaderBoard.', error)
  }
}

export function* _getReferral({params}) {
  const url = `${config.FETCH.url}/user/referral`;
  try {
    const request = yield call(post, { url, data: params })
    const {success} = request.data
    const {url: referralUrl} = request.data
    if (success){
      yield put(setReferralLink({...referralUrl}))
    }
  } catch (e) {
    console.error('_getReferral Error: ', e)
  }
}
