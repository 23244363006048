const { 
  SET_VIDEO_FILES, 
  FETCH_VIDEO_FILES,
  RECORDING_PROCESS
} = require('./actiontypes').default;

const initialState = {
  files: {},
  error: '',
  isFetching: false,
  isSave: false,
};

const createMap = ({ squadid, files }) => ({ [squadid]: files });

const videoFilesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VIDEO_FILES:
      return { ...state, error: '', isFetching: true };
    case SET_VIDEO_FILES:
      if (action.error) {
        return { ...initialState, error: action.error };
      }
      if (action.clear) {
        return { ...initialState };
      }
      return { ...state, files: { ...createMap(action.payload) }, isFetching: false };
    case RECORDING_PROCESS:
      return { ...state, isSave: action.payload };
    default:
      return state;
  }
};

export default videoFilesReducer;
