import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from './common/Modal';
import { Button } from './common/Button';
import { SocketEmit, SocketEvent } from '../libs/reducers/actions';
import EditResultsModal from './common/modals/modalEditResult';
import ProtestReportModal from './common/modals/modalProtestReport';

class Test extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.users.user,
      showModal: false,
      showEditModal: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.events.socketEvent === 1 && !prevState.showModal) {
      return { showModal: true };
    }
    return null;
  }

  render() {
    return (
      <div>
        <Button
          size="md"
          kind="aquamarine"
          onClick={() => {
            this.props.SocketEmit({
              eventName: 'testModal',
              params: { userCreate: this.state.user.id, userRequire: this.state.user.id + 1 },
            });
          }}
        >
          Open
        </Button>
        <Button
          onClick={() => {
            this.setState({
              showEditModal: true,
            });
          }}
        >
          EditModal
        </Button>
        <EditResultsModal
          title="Редактирование результатов"
          visible={this.state.showEditModal}
          scores={{
            prev: [5, 0],
            next: [5, 4],
          }}
          onSubmit={() => {
            // func
          }}
          onCancel={() => {
            // func
          }}
          scoreNumber={2}
          frameScores={{ prev: 8, next: 9 }}
          declineButtonText="Decline"
          acceptButtonText="Accept"
          additionalInfo="Иванов Иван изменил результаты фрейма.
          Дайте согласие на изменение результатов."
        />
        <Modal
          title="Test"
          visible={this.state.showModal}
          onClose={() => {
            this.props.SocketEvent(0);
            this.setState({
              showModal: false,
            });
          }}
          onOk={() => {
            this.props.SocketEvent(0);
            this.setState({
              showModal: false,
            });
          }}
          onCancel={() => {
            this.props.SocketEvent(0);
            this.setState({
              showModal: false,
            });
          }}
        >
          <div>{this.state.user.id}</div>
          <div>{this.state.user.id + 1}</div>
        </Modal>
        <ProtestReportModal
          // title={'Text'}
          visible
          width={395}
          closable
          info={{}}
          footer={[
            <div key="buttons" className="protest__buttons">
              <Button
                kind="aquamarine"
                bordered="true"
                onClick={() => {
                  console.log('cancel Click');
                }}
                className="protest__button-decline"
              >
                Отозвать протест
              </Button>
              <Button
                onClick={() => {
                  console.log('ok Click');
                }}
                kind="aquamarine"
                className="protest__button-accept"
              >
                Отправить протест
              </Button>
            </div>,
          ]}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      users: state.users,
      langs: state.langs,
      events: state.events,
    }),
    { SocketEmit, SocketEvent },
  )(Test),
);
