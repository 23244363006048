import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Spin, Icon } from 'antd';
import _ from 'lodash';
import icons from './icons';
import './style.css';

const antIcon = <Icon type="loading" style={{ fontSize: 14 }} spin />;

export default class Button extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    size: PropTypes.oneOf(['xs', 'md', 'lg', 's']),
    bordered: PropTypes.oneOf(['true', 'false']).isRequired,
    kind: PropTypes.oneOf(['orange', 'aquamarine']),
    icon: PropTypes.oneOf(['sword', 'adduser', 'chatmessage', 'save', 'play', 'watch', 'send', 'friends',]),
    disabled: PropTypes.string,
    loading: PropTypes.bool,
    loadingtext: PropTypes.string,
    onClick: PropTypes.func,
    glowing: PropTypes.bool,
  };

  static defaultProps = {
    bordered: 'false',
    kind: 'orange',
    loading: false,
    loadingtext: '',
    loadingProp: false,
    glowing: false
  };

  onClick = (e) => {
    const { loading, disabled } = this.props;
    if (!disabled && !loading && _.isFunction(this.props.onClick)) {
      this.props.onClick(e);
    }
  };

  render() {
    const {
      className, size, bordered, icon, children, disabled, loading, loadingtext, glowing, kind
    } = this.props;
    const isChildren = !!children;
    let buttonClassName = className ? `${className} button` : 'button';
    if (size) buttonClassName += ` button-size_${size}`;
    if (bordered === 'true') buttonClassName += ' button_transparent';
    if (!isChildren) buttonClassName += ' button_square';
    if (disabled === 'disabled' || disabled === true) buttonClassName += ' button_disabled';
    if (loading) buttonClassName += ' button_loading';
    if (
      !(disabled === 'disabled' || disabled === true) && 
      !(bordered === 'true') && 
      kind == 'orange' &&
      !loading && 
      glowing
    ) {
      buttonClassName += ' button_glowing';
    }
    const BtnIcon = icons[icon];

    return (
      <button
        {...this.props}
        onClick={this.onClick.bind(this)}
        className={`${buttonClassName} button_${this.props.kind}`}
      >
        {icon && icons[icon] && children ? <BtnIcon className="button__icon button__icon__withtext" /> : null}
        {icon && icons[icon] && !children ? <BtnIcon className="button__icon" /> : null}
        {icon && !icons[icon] ? <Icon type="check" className="button__icon" /> : null}
        {loading && (
          <div>
            <Spin indicator={antIcon} /> {loadingtext}
          </div>
        )}
        {!loading && <div>{children || null}</div>}
      </button>
    );
  }
}
