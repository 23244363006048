import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ChangedFlag from '-!svg-react-loader!../../../images/changed.svg'; // eslint-disable-line
import RefereedFlag from '-!svg-react-loader!../../../images/refereed.svg'; // eslint-disable-line
import FoulFlag from '-!svg-react-loader!../../../images/foul.svg'; // eslint-disable-line

import _ from 'lodash';

import './style.css';

export default class frame extends Component {
  static propTypes = {
    /** Фрейм принадлежит игроку или сопернику */
    isOwner: PropTypes.bool,
    /** Является ли фрейм текущим */
    isCurrent: PropTypes.bool,
    /** Номер фрейма */
    number: PropTypes.number.isRequired,
    /** Изменяется ли фрейм */
    ischanging: PropTypes.bool,
    /** Изменялся ли фрейм */
    flags: PropTypes.string,
    /** Массив очков фрейма */
    scores: PropTypes.arrayOf(PropTypes.number),
    /** Сумма очков фреймов на этот фрейм */
    frameScore: PropTypes.number,
    onFrameSelect: PropTypes.func,
    isEditing: PropTypes.bool,
    globalEditing: PropTypes.bool,
    tmpScore: PropTypes.object,
    scoreSumStyles: PropTypes.object,
    /** Очки редактируемого фрейма */
  };

  static defaultProps = {
    isowner: false,
    iscurrent: false,
    ischanging: false,
    flags: '',
    isEditing: false,
    tmpScore: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      scores: props.scores,
    };
  }

  parseScores = (scores) => {
    const skittles = [];
    const {
      isCurrent, tmpScore, ischanging, isEditing, globalEditing,
    } = this.props;

    if ((isCurrent && tmpScore && !globalEditing) || (isEditing && ischanging && tmpScore)) {
      if (tmpScore.shot1 !== -1) {
        if (tmpScore.shot1 === 10) {
          skittles.push('X');
        } else if (tmpScore.shot1 !== 0) {
          skittles.push(tmpScore.shot1);
        } else {
          skittles.push('-');
        }
      }
      if (tmpScore.shot2 !== -1) {
        if ((tmpScore.shot2 + tmpScore.shot1) === 10 && tmpScore.shot2!==0) {
          skittles.push('/');
        } else if (tmpScore.shot2 === 10) {
          skittles.push('X');
        } else if (tmpScore.shot2 !== 0) {
          skittles.push(tmpScore.shot2);
        } else {
          skittles.push('-');
        }
      }
      if (tmpScore.shot3 !== -1) {
        if (tmpScore.shot3 === 0) {
          skittles.push('-');
        } else if (tmpScore.shot3 === 10) {
          skittles.push('X');
        } else if ((tmpScore.shot1 === 10) && (tmpScore.shot2 + tmpScore.shot3 === 10) && (tmpScore.shot3 !== 0)) {
          skittles.push('/');
        } else if (tmpScore.shot3 !== 0) {
          skittles.push(tmpScore.shot3);
        } else {
          skittles.push('-');
        }
      }
    } else if ((scores && !isCurrent) || !tmpScore || tmpScore.shot1 === -1) {
      scores.forEach((score, index) => {
        if (typeof score !== 'number') return
        const prevScore = scores[index - 1];
        if (index !== 2) {
          if (score === 10 && prevScore !== 0) {
            skittles.push('X');
          } else if (prevScore && score !== 0 && prevScore + score === 10) {
            skittles.push('/');
          } else if (prevScore === 0 && score === 10) {
            skittles.push('/');
          } else if (score === 0) {
            skittles.push('-');
          } else if (score !== -1) {
            skittles.push(score);
          }
        } else {
          // eslint-disable-next-line
          if (score === 10) { 
            skittles.push('X');
          } else if (scores[0] === 10 && prevScore + score === 10 && score !== 0) {
            skittles.push('/');
          } else if (score !== 0) {
            skittles.push(score);
          } else {
            skittles.push('-');
          }
        }
      });
    }
    return skittles;
  };

  render() {
    const {
      isOwner,
      isCurrent,
      number,
      scores,
      frameScore,
      flags,
      ischanging,
      onFrameSelect,
      isEditing,
      scoreSumStyles,
      frameNumberStyles,
      frameStyles,
    } = this.props;
    const arrayFlags = (flags || '').split('');
    const className = `frame${isOwner ? ' owner' : ''}${isCurrent ? ' current' : ''}${number === 10 ? ' last' : ''}${
      ischanging ? ' changing' : ''
    }${isEditing ? ' editing' : ''}`;
    const skittles = this.parseScores(scores);
    return (
      <div
        className={className}
        onClick={onFrameSelect}
        style={frameStyles}
      >
        <div
          className="frame__number"
          style={frameNumberStyles}
        >
          <div>{number}</div>
        </div>
        <div
          className="frame__scores">
          <div
            className='frame__flags'>
              {
                arrayFlags.indexOf('r') != -1
                  ? <div key={'r'} className="frame__flag refereed"><RefereedFlag/></div>
                  : null
              }
              {
                arrayFlags.indexOf('f') != -1
                  ? <div key={'f'} className="frame__flag foul"><FoulFlag/></div>
                  : null
              }
              {
                arrayFlags.indexOf('c') != -1
                  ? <div key={'c'} className="frame__flag changed"><ChangedFlag/></div>
                  : null
              }
              {/* {
                _.map(arrayFlags, flag => (
                  <div
                    key={flag} className="frame__flag">{flag.toUpperCase()}</div>
                ))
              } */}
          </div>
          {_.map(skittles, (skittle, index) => (
            <div
              key={`${number} ${index}`} className="frame__score">
              {skittles[index]}
            </div>
          ))}
        </div>
        <div
          className="frame__scoresSum"
          style={scoreSumStyles}
        >
          <div>{frameScore}</div>
        </div>
      </div>
    );
  }
}
