
const {
  BATTLES_FETCH,
  BATTLES_SET,
  BATTLES_RESPONSE_TO_USER_SET,
  BATTLES_RESPONSE_TO_USER_FETCH,
  BATTLES_RESPONSE_TO_OWNER_SET,
  BATTLES_RESPONSE_TO_OWNER_FETCH,
  BATTLES_BATTLE_CANCEL_SET,
  BATTLES_BATTLE_CANCEL_FETCH,
  BATTLES_BATTLE_INVITE_SET,
  BATTLES_BATTLE_INVITE_FETCH,
  ACCOUNT_BATTLES_HISTORY_SET,
  ACCOUNT_BATTLES_HISTORY_FETCH,
  ACCOUNT_BATTLES_FETCH,
  SET_ACCOUNT_BATTLES,
  MYBATTLES_SET,
  LOGOUT,
  BATTLES_SET_CREATE_BATTLE_QUERY,
  OPPONENT_GAME_REFUSE,
  SQUAD_LIST_FETCH,
  SQUAD_LIST_SET,
  SET_SQUAD_WATCHER_COUNT,
  SET_BATTLE_FOR_DELETE,
  CLEAR_ACCOUNT_BATTLES,
  OPENCHALLENGES_SET,
  CLOSED_OPENCHALLENGES_FETCHED,
  CLOSED_OPENCHALLENGES_FETCH,
  ACTIVE_SQUADS_COUNT_SET,
  UPDATE_GAME_COUNT_FETCHED,
  UPDATE_GAME_COUNT_FETCH,
} = require('./actiontypes').default;

const initPayload = {
  data: {},
  fetching: false,
  error: ''
};

const initialState = {
  battlesResponseToUser: initPayload,
  battles: [],
  mybattles: [],
  squads: [],
  squadsFetch: false,
  fetching: false,  // Процесс получения списка батлов
  createBattleQuery: false,
  opponent_game_status: false,
  squadsWatcher: [],
  battlehistory: [],
  openChallenges: [],
  closedOpenChallenges: {my: [], other: []},
  fetchingClosedOpenchallenges: false,
  accountbattles: [], // Список баттлов в видеоархиве
  count: 0,
  updateGameCountFetch: false,
};

const BattlesReduсer = (state = initialState, action) => {
  switch (action.type) {
    case SQUAD_LIST_FETCH:
      return { ...state, squadsFetch: true };
    case SQUAD_LIST_SET:
      return { ...state, squads: action.params, squadsFetch: false };
    case BATTLES_FETCH:
      return { ...state, fetching: true };
    case MYBATTLES_SET:
      return { ...state, mybattles: action.params, fetching: false };
    case BATTLES_SET:
      return { ...state, battles: action.params, fetching: false };
    case BATTLES_RESPONSE_TO_USER_FETCH:
      return {
        ...state,
        battlesResponseToUser: {
          ...initPayload,
          fetching: true,
        },
      };
    case BATTLES_RESPONSE_TO_USER_SET:
      return {
        ...state,
        battlesResponseToUser: {
          data: action.data || {},
          fetching: false,
          error: action.error || '',
        },
      };
    case BATTLES_RESPONSE_TO_OWNER_FETCH:
      return {
        ...state,
        battlesResponseToOwner: {
          ...initPayload,
          fetching: true,
        },
      };
    case BATTLES_RESPONSE_TO_OWNER_SET:
      return {
        ...state,
        battlesResponseToOwner: {
          data: action.data || {},
          fetching: false,
          error: action.error || '',
        },
      };
    case BATTLES_BATTLE_CANCEL_FETCH:
      return {
        ...state,
        battlesBattleCancel: {
          ...initPayload,
          fetching: true,
        },
      };
    case BATTLES_BATTLE_CANCEL_SET:
      return {
        ...state,
        battlesBattleCancel: {
          data: action.data || {},
          fetching: false,
          error: action.error || '',
        },
      };
    case BATTLES_BATTLE_INVITE_FETCH:
      return {
        ...state,
        battlesBattleInvite: {
          ...initPayload, fetching: true,
        },
      };
    case BATTLES_BATTLE_INVITE_SET:
      return {
        ...state,
        battlesBattleInvite: {
          data: action.data || {}, fetching: false, error: action.error || '',
        },
      };
    case ACCOUNT_BATTLES_FETCH:
      return { ...state, fetching: true };
    case ACCOUNT_BATTLES_HISTORY_FETCH:
      return {  ...state, fetching: true };
    case LOGOUT:
      return { ...initialState };
    case ACCOUNT_BATTLES_HISTORY_SET:
      return { ...state, fetching: false, battlehistory: action.params }
    // Записываем в store список accountbattles
    case SET_ACCOUNT_BATTLES:
      return { ...state, accountbattles: action.params, fetching: false };
    // Очищаенм список accountbattles
    case CLEAR_ACCOUNT_BATTLES:
      return { ...state, accountbattles: [] }
    case BATTLES_SET_CREATE_BATTLE_QUERY:
      return { ...state, createBattleQuery: action.payload };
    case OPPONENT_GAME_REFUSE:
      return { ...state, opponent_game_status: action.params }
    case SET_SQUAD_WATCHER_COUNT:
      return { ...state, squadsWatcher: action.params }
    case SET_BATTLE_FOR_DELETE:
      return { ...state, battleForDelete: action.params }
    case OPENCHALLENGES_SET:
      return { ...state, openChallenges: action.params }
    case CLOSED_OPENCHALLENGES_FETCH:
      return {...state, fetchingClosedOpenchallenges: true}
    case CLOSED_OPENCHALLENGES_FETCHED:
      return { ...state, closedOpenChallenges: action.params, fetchingClosedOpenchallenges: false }
    case ACTIVE_SQUADS_COUNT_SET:
      return { ...state, count: action.params.count }
    case UPDATE_GAME_COUNT_FETCH:
      return { ...state, updateGameCountFetch: true }
    case UPDATE_GAME_COUNT_FETCHED:
      return { ...state, updateGameCountFetch: false }
    default:
      return state;
  }
};

export default BattlesReduсer;
