import { call, fork, put, select, take, takeLatest } from 'redux-saga/effects'
import { hasExpectedGamesSaga } from './gamesExpected.reducks'

export const gameInviteRequestAction = (gameInviteAction) => ({ type: 'GAME_INVITE_REQUEST', params: gameInviteAction })
export const gameInviteWaitEndAction = (gameInviteAction) => ({ type: 'GAME_INVITE_WAIT_END', params: gameInviteAction })
export const setGameInviteModalAction = (modal) => ({ type: 'SET_GAME_INVITE_MODAL', params: modal })
export const closeGameInviteModalAction = () => ({ type: 'CLOSE_GAME_INVITE_MODAL' })
export const openGameInviteModalAction = (gameInviteAction) => ({ type: 'OPEN_GAME_INVITE_MODAL', params: gameInviteAction })
export const setSurrenderedGameInviteAction = (game) => ({ type: 'SURRENDER_GAME_INVITE', params: game })

export const initialGameInviteState = {
  gameInviteAction: null,
  modal: {
    visible: false,
  },
  /** @type {{ squadid: number; tournamentid: number; }} */
  game: null,
  /** @type {{ squadid: number; tournamentid: number; }} */
  surrender: null,
  waiting: false,
  /** @type {{ squadid: number; tournamentid: number; }} */
  waitingGame: null,
}

export const gameInviteReducer = (state = initialGameInviteState, action) => {
  switch (action.type) {
    case 'GAME_INVITE_WAIT_END':
      return {
        ...state,
        waiting: true,
        waitingGame: {
          squadid: action.params.params.tournament.squadid,
          tournamentid: action.params.params.tournament.id,
        },
      }
    case 'SOCKET_EVENT_GAME_END':
      return {
        ...state,
        waiting: false,
        waitingGame: null,
      }
    case 'OPEN_GAME_INVITE_MODAL':
      return {
        ...state,
        gameInviteAction: action.params,
        game: {
          squadid: action.params.params.tournament.squadid,
          tournamentid: action.params.params.tournament.id,
        },
        modal: {
          ...state.modal,
          visible: true,
        },
      }
    case 'CLOSE_GAME_INVITE_MODAL':
      return {
        ...state,
        modal: {
          ...state.modal,
          visible: false,
        },
        game: null,
        gameInviteAction: null,
      }
    case 'SURRENDER_GAME_INVITE':
      // case 'GAME_LEAVE':
      return {
        ...state,
        surrender: action.params,
      }
    case 'SET_GAME_INVITE_MODAL':
      return {
        ...state,
        modal: {
          ...state.modal,
          ...action.params,
        },
      }
    default:
      return state;
  }
}

const takeLeading = (patternOrChannel, saga, ...args) => fork(function* () {
  while (true) {
    const action = yield take(patternOrChannel);
    yield call(saga, ...args.concat(action));
  }
})

export function* gameInviteSaga(action) {
  const { params: gameInviteAction } = action;
  try {
    const isInBattle = yield select(state => state.events.inBattle)
    if (isInBattle) {
      // есть активная игра
      const { tournament: { squadid } } = gameInviteAction.params
      const game = yield select(state => state.gameInvite.game)
      if (game && game.squadid >= squadid) {
        // если прилетело больше одного приглашения, то игнорим те, к-рые созданы раньше
        return
      }
      yield put(openGameInviteModalAction(gameInviteAction))
      return;
    }
    // если нет активных игр
    const hasExpectedGames = yield call(hasExpectedGamesSaga, gameInviteAction)
    // и нет предстоящих игр в теч. 30 мин
    if (!hasExpectedGames) {
      yield put(gameInviteAction)
    }
  } catch (e) {
    console.log('gameInvite error', e)
    // TODO: handle error
    yield put(gameInviteAction)
  }
}

export function* gameInviteWaitEndSaga(action) {
  const { params: gameInviteAction } = action;
  try {
    yield take('SOCKET_EVENT_GAME_END')
    yield put(gameInviteAction)
  } catch (e) {
    console.log('pendingGameInvite error', e)
    yield put(gameInviteAction)
  }
}

export function* watchGameInvite() {
  yield takeLeading('GAME_INVITE_REQUEST', gameInviteSaga)
  yield takeLatest('GAME_INVITE_WAIT_END', gameInviteWaitEndSaga)
}
