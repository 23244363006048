import sword from '-!svg-react-loader!../../../../images/sword.svg'; // eslint-disable-line
import adduser from '-!svg-react-loader!../../../../images/adduser.svg'; // eslint-disable-line
import chatmessage from '-!svg-react-loader!../../../../images/chatmessage.svg'; // eslint-disable-line
import tray from '-!svg-react-loader!../../../../images/tray.svg'; // eslint-disable-line
import edit from '-!svg-react-loader!../../../../images/edit.svg'; // eslint-disable-line
import save from '-!svg-react-loader!../../../../images/save.svg'; // eslint-disable-line
import play from '-!svg-react-loader!../../../../images/play.svg'; // eslint-disable-line
import watch from '-!svg-react-loader!../../../../images/watch.svg'; // eslint-disable-line
import send from '-!svg-react-loader!../../../../images/send.svg'; // eslint-disable-line
import friends from '-!svg-react-loader!../../../../images/friends.svg'; // eslint-disable-line

export default {
  sword,
  adduser,
  chatmessage,
  tray,
  edit,
  save,
  play,
  watch,
  send,
  friends,
};
