import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment';

import AddDocsScreen from './addDocsScreen'

import ModalPhoneVerify from './modalPhoneVerification';
import ModalEmailVerify from './modalEmailVerification';
import {
  clearUserDocs,
  GetVerificatedPhone, 
  UpdateUserData,
  fetchUserDocs,
  sendUserDocs
} from '../../../libs/reducers/actions';
import { Button } from '../../common/Button';

import clockIcon from '../../../images/clock-icon.png';
import verifyimg from '../../../images/check1.svg';

import './style.scss';

class VerificationScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      phoneFetch: false,
      emailFetch: false,
      gotoAddDocs: false,
      index: 0,
      status: false,
      comment:false,
      form: {
        docImages: props.userdocs.data.length ? props.userdocs.data[0].docImages : [],
        userImages: props.userdocs.data.length ? props.userdocs.data[0].userImages : [],
        name: props.userdocs.data.length ? props.userdocs.data[0].name : `${props.users.user.firstname} ${props.users.user.lastname}`,
        dob: props.users.user ? props.users.user.dob : moment(this.maxDate(18)),
        docNumber: props.userdocs.data.length ? props.userdocs.data[0].docnum : null,
        country: props.userdocs.data.length ? {value: props.userdocs.data[0].country, label: this.getCountryNameByIso(props.userdocs.data[0].country)} : {value: props.users.user.country_live, label: this.getCountryNameByIso(props.users.user.country_live)},
        shelfLife: props.userdocs.data.length ? (new Date(+props.userdocs.data[0].expiryyear, +props.userdocs.data[0].expirymonth, 1, 0, 0, 0, 0)) : moment(new Date())
      }
    };
  }

  static propTypes = {
    intl: intlShape,
  };

  componentDidMount() {
    this.props.clearUserDocs();
    this.props.fetchUserDocs();
    this.props.UpdateUserData();
    this.props.GetVerificatedPhone();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.geodata.data.length && this.props.geodata.data.length && this.props.userdocs.data.length) {
      this.setState({
        ...this.state,
        form: {
          ...this.state.form,
          country: { ...this.state.country, value: this.props.userdocs.data[this.state.index].country, label: this.getCountryNameByIso(this.props.userdocs.data[this.state.index].country)},
        }
      })
    }
    if (
      this.props.users.user &&
      this.props.userdocs.data.length &&
      this.props.geodata.data.length &&
      (!prevProps.userdocs.data.length || (prevProps.userdocs.data && prevProps.userdocs.data.status !== this.props.userdocs.data.status) || prevState.index !== this.state.index) ||
      prevState.gotoAddDocs !== this.state.gotoAddDocs
    )
      this.updateForm();
  }

  updateForm = () => {
    const { index } = this.state;
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        docImages: this.props.userdocs.data[index] ? this.props.userdocs.data[index].docImages : [],
        userImages: this.props.userdocs.data[index] ? this.props.userdocs.data[index].userImages : [],
        name: this.props.userdocs.data[index] ? this.props.userdocs.data[index].name : `${this.props.users.user.firstname} ${this.props.users.user.lastname}`,
        dob: this.props.userdocs.data[index] ? this.props.userdocs.data[index].dob : this.props.users.user.dob,
        docNumber: this.props.userdocs.data[index] ? this.props.userdocs.data[index].docnum : '',
        country: this.props.userdocs.data[index] ? { ...this.state.country, value: this.props.userdocs.data[index].country, label: this.getCountryNameByIso(this.props.userdocs.data[index].country)} : {value: this.props.users.user.country_live, label: this.getCountryNameByIso(this.props.users.user.country_live)},
        shelfLife: this.props.userdocs.data[index] ? (new Date(+this.props.userdocs.data[index].expiryyear, +this.props.userdocs.data[index].expirymonth, 1, 0, 0, 0, 0)) : moment(new Date())
      }
  })
  }

  getCountryNameByIso = (iso) => {
    const {geodata} = this.props;
    const country = geodata.data ? geodata.data.find(d => d.iso === iso) : {};
    return country ? country.name : '';
  }

  maxDate = (age) => {
    let date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    return new Date(year - age, month, day);
  }

  AddPhone = () => {
    this.setState({ phoneFetch: true });
  }

  AddEmail = () => {
    this.setState({ emailFetch: true });
  }

  phoneModalSuccess = () => {
    this.setState({ phoneFetch: false });
    this.props.GetVerificatedPhone();
    console.log('Phone success');
  }

  phoneModalClose = () => {
    this.setState({ phoneFetch: false });
  }

  emailModalSuccess = () => {
    this.setState({ emailFetch: false });
    console.log('email success');
  }

  emailModalClose = () => {
    this.setState({ emailFetch: false });
  }

  renderEmail = () => {
    const { user } = this.props.users;
    const { email, emailVerified } = user;
    const { intl } = this.props;
    if (!email) {
      return (
        <>
          <span>E-mail</span>
          <a onClick={this.AddEmail}>{intl.formatMessage({ id: 'AddAsSpecifyAlias', defaultMessage: 'Add' })}</a>
        </>
      );
    }
    return (
      <>
        <span>E-mail</span>
        <p>
          {emailVerified
            ? (<img className="verified" src={verifyimg}/>)
            : (<a onClick={this.AddEmail}>{intl.formatMessage({ id: 'VerifyVerbal', defaultMessage: 'Verify' })}</a>)}
          {` ${email}`}
        </p>
      </>
    );
  }

  onScreenChange = (index = 0) => {
    const { gotoAddDocs } = this.state;
    this.setState({ gotoAddDocs: !gotoAddDocs, index })
  }

  SendDocs = (params) => {
    this.props.sendUserDocs(params);
  }

  renderDocsBlock = (index) => {
    const { intl, userdocs } = this.props;
    const { data: userDocsData } = userdocs;
    return (<>
      <div className="verification-block docs-add">
        <span>{intl.formatMessage({id: 'PersonalDocuments', defaultMessage: 'Personal documents'})}</span>
        {userDocsData.length && userDocsData[index] && userDocsData[index].status
          ? userDocsData[index].status === 'new' || userDocsData[index].status === 'bug'
            ? <div className="docs-add__pending">
              <img src={clockIcon} />
              <a onClick={() => this.onScreenChange(index)}>{intl.formatMessage({ id: 'PendingDocuments', defaultMessage: 'Pending documents' })}</a>
            </div>
            : <div className="docs-add__pending">
              <img src={verifyimg} />
              <span>{intl.formatMessage({ id: 'DocumentsVerifyed', defaultMessage: 'Verifyed' })}</span>
            </div>
          : <a onClick={() => this.onScreenChange(index)}>{intl.formatMessage({id: 'AddAsSpecifyAlias', defaultMessage: 'Add'})}</a>
        }
      </div>
      {userDocsData && userDocsData.length && userDocsData[index] && userDocsData[index].comment && userDocsData[index].comment.length && userDocsData[index].status === 'bug'
      ? <div className="docs__comments">
        <span className="docs__comments__title">
          {intl.formatMessage({ id: 'AdminComment', defaultMessage: 'Admin comment' })}
        </span>
          <br />
          <span className="docs__comments__comment">{userDocsData[index].comment}</span>
        <Button
          bordered={false}
          onClick={() => this.onScreenChange(index)}
        >
          {intl.formatMessage({ id: 'CorrectDocuments', defaultMessage: 'Correct' })}
        </Button>
      </div>
      : null}
    </>)
  }

  render() {
    const { users, intl, userdocs } = this.props;
    const { data: userDocsData } = userdocs;
    const { gotoAddDocs, form, index } = this.state
    const { phone } = users;
    return (
      <div className="verification-screen">
        {!gotoAddDocs
        ?<> 
        <div className="verification-block">
          <h2>{intl.formatMessage({ id: 'VerificationPageContactsBlock', defaultMessage: 'Verify contact details' })}</h2>
          <div className="_verification-field">
            {this.renderEmail()}
          </div>
          {/* phone
            ? (
              <div className="_verification-field">
                <span>{intl.formatMessage({ id: 'PhoneContact', defaultMessage: 'Phone' })}</span>
                <p><img className="verified" src={verifyimg}/> {phone}</p>
              </div>
            )
            : (
              <div className="_verification-field">
                <span>{intl.formatMessage({ id: 'PhoneContact', defaultMessage: 'Phone' })}</span>
                <a onClick={this.AddPhone}>{intl.formatMessage({ id: 'AddAsSpecifyAlias', defaultMessage: 'Add' })}</a>
              </div>
            )
            */}
        </div>
        <div className="verification-block docs">
          <h2>{intl.formatMessage({ id: 'PersonalDocsVerification', defaultMessage: 'Personal documents verification' })}</h2>
          <p>{intl.formatMessage({id: 'ForPossibilityWithdrawingFunds', defaultMessage: 'For the possibility of withdrawing funds to bank cards'})}</p>
          
          {this.renderDocsBlock(0)}
          {userDocsData && (userDocsData.length === 1 || userDocsData.length === 2) ? this.renderDocsBlock(1) : null}
        </div>
        </>
         : <AddDocsScreen
            intl={intl}
            goBack={this.onScreenChange}
            geodata={this.props.geodata}
            userdocs={this.props.userdocs}
            data={userDocsData.length ? userDocsData : false}
            status={userDocsData.length ? userDocsData.status : 'null'}
            comment={userDocsData.length ? userDocsData.comment : ''}
            form={form}
            SendDocs={this.SendDocs.bind(this)}
            maxDate={this.maxDate}
            id={this.props.userdocs.data.id || null}
            index={index}   //1st doc
         />
        }
        <ModalPhoneVerify
          onClose = {this.phoneModalClose}
          onSubmit = {this.phoneModalSuccess}
          visible = {this.state.phoneFetch}
          />
        {this.state.emailFetch && (
          <ModalEmailVerify
            visible
            onClose = {this.emailModalClose}
            onSubmit = {this.emailModalSuccess}
          />
        )}
      </div>
    );
  }
}

export default injectIntl(connect(
  state => ({
    users: state.users,
    geodata: state.common.geodata,
    timezones: state.common.timezones,
    userdocs: state.userdocs,
  }),
  {
    GetVerificatedPhone,
    UpdateUserData,
    fetchUserDocs,
    clearUserDocs,
    sendUserDocs,
  },
)(VerificationScreen));