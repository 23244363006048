import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';
import {
  SendVerifSocialCodeToEmail,
  CheckVerificationCodeEmail,
  CheckConfirmEmail,
  ChangeLang,
  UserLogout,
} from '../libs/reducers/actions';

import Title from './common/Title';
import Input from './common/Input';
import { Button } from './common/Button';
import logo from '../images/Logo1.png';
import globeImage from '../images/globe.png';
import config from '../config';
import './style.css';

const time = 15 * 1000;

class PostRegData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      code: '',
      formErrors: {
        email:   '',
        code:    ''
      },
      timeLeft: 0,
      isSendEmailVerif: false,
    }
  }

  static propTypes = {
    emailVerification:           PropTypes.bool,
    SendVerifSocialCodeToEmail:  PropTypes.func,
    CheckVerificationCodeEmail:  PropTypes.func,
    intl:                        intlShape,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    // if (!nextProps.emailVerification && nextProps.users.reg === false) {
    //   document.location.href = '/lobby';
    // }
    if (nextProps.users.accessed && nextProps.users.reg === false) {
      document.location.href = '/lobby';
    }

    if (nextProps.regcodeerror && !prevState.formErrors.code) {
      const formErrors = { ...prevState.formErrors };
      formErrors.code = nextProps.intl.formatMessage({ id: 'emailConfCodeError', defaultMessage: 'Incorrect code' });
      return { formErrors };
    }
    if (nextProps.emailerror && !prevState.formErrors.email) {
      const formErrors = { ...prevState.formErrors };
      formErrors.email = nextProps.intl.formatMessage({ id: 'emailAlreadyClaimed', defaultMessage: 'This email already claimed by another user' });
      return { formErrors };
    }
    if (nextProps.users.reg && !_.isEmpty(nextProps.users.user)) {
      nextProps.history.push('/postregdata');
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.timers.starttime && prevProps.timers.starttime !== this.props.timers.starttime && this.props.timers.starttime + time - +new Date() >= 0) {
      this.timer();
    }
    if (!this.props.emailerror && prevProps.emailerror) {
      const formErrors = { ...prevState.formErrors };
      formErrors.email = '';
      this.setState({ formErrors });
    }
  }

  timer = () => {
    const left = this.props.timers.starttime + time - Date.now();

    if (left >= 0) {
      this.setState({ timeLeft: Math.floor(left / 1000) });
      setTimeout(() => this.timer(), 1000);
    } else {
      this.setState({ timeLeft: 0 });
    }
  }

  onSendCodeToEmail = (e) => {
    e.preventDefault();

    const { email } = this.state;

    if (!this.checkEmailValue(email)) {
      return;
    }
    this.props.SendVerifSocialCodeToEmail({ email, lang: this.props.langs.lang });
    this.setState({ isSendEmailVerif: true });
  }

  checkEmailValue = (value) => this.checkValue('email', value);
  
  checkCodeValue = (value) => this.checkValue('empty', value);
  
  checkValue = (type, value) => {
    let errorMessage = '';
    if (!value || value === '') {
      errorMessage = this.props.intl.formatMessage({ id: 'FieldBeEmpty', defaultMessage: 'The field must not be empty' });
    } else if (type === 'email' && !value.includes('@')) {
      errorMessage = this.props.intl.formatMessage({ id: 'EmailInvalid', defaultMessage: 'Invalid email' });
    }
    const formErrors = { ...this.state.formErrors };

    formErrors[type] = errorMessage;
    this.setState({ formErrors });

    return errorMessage === '';
  }

  onCheckByCodes = (e) => {
    e.preventDefault();

    const { code, email } = this.state;

    if (!this.checkEmailValue(email)) {
      return;
    }
    if (!this.checkCodeValue(code)) {
      return;
    }

    // :TODO Может это не нужно
    let query = null;

    if (window.location.search) {
      query = window.location.search.slice(1).split('&').reduce((acc, cur) => {
        const [key, val] = cur.split('=').map(t => t.trim());
        if (key && val) {
          acc[key] = decodeURIComponent(val);
        }
        return acc;
      }, {});
    }
    let params = null;

    if (query && query.code) {
      try {
        params = JSON.parse(atob(query.code));
      } catch (e) {
        console.error('e: ', e);
      }
    }

    if (params && params.code && params.pathname) {
      params.code = atob(params.code);
      params.pathname = atob(params.pathname);
    } else {
      params = null;
    }
    
    this.props.CheckVerificationCodeEmail({
      code,
      email,
      authorizationCode: encodeURIComponent(params.code),
      redirectURL: encodeURIComponent(params.pathname),
      app: 'web',
     });
  }

  onCancelConfirmEmail = (e) => {
    e.preventDefault();
    this.props.CheckConfirmEmail(false);
    document.location.href = '/login';

  }

  renderLanguageSelect = () => {
    const { intl, langs, users } = this.props;
    const lang = langs.lang || users.user.lang || 'EN';
    const { LANGS } = config;

    return (
      <div className="login-language">
        <span className="language-header">
          {intl.formatMessage({ id: 'interfaceLanguage', defaultMessage: 'Language' })}
        </span>
        <div className="language-list">
          {LANGS.map((language, index) => (
            <div key={index} className={`language-en ${lang === language ? 'selected' : ''}`} onClick={() => this.props._changeLang(language)}>
              <img src={globeImage} alt="Globe" className="language-globe-icon" />
              <span className="language-name">{language}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }

  render() {
    const { email, code, formErrors, isSendEmailVerif } = this.state;
    const { intl } = this.props;

    return (
      <div className="postregpage">
        <div className="postregpage__content">
          <div className="postregpage__leftcolumn">
            <div className="postregpage__leftcolumn__content__logos">
              <div className="postregpage__leftcolumn__content">
                <img src={logo} alt="" className="postregpage__leftcolumn__content__logo" />
                <Title>Email</Title>
                <div className="title-description">
                  {intl.formatMessage({
                    id: 'ConfirmEmailForRegistration',
                    defaultMessage: 'Confirm your email for further registration'
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="postregpage__rightcolumn">
            <div className="postregpage__rightcolumn__content">
              {this.renderLanguageSelect()}
              <form>
                <div className="input-group">
                  <div className="postregpage__rightcolumn__content__inputs">
                    <div className="postregpage__rightcolumn__content__input">
                      <Input
                        name="Email"
                        onChangeText={(text) => {
                          this.setState({
                            email: text,
                            formErrors: { ...formErrors, email: '' },
                          });
                        }}
                        value={email}
                        placeholder="Email"
                        error={formErrors.email}
                      />
                    </div>

                    {/* Повторная отправка через 15 сек */}
                    <div className="postregpage__rightcolumn__content__button">
                      {
                        (this.state.timeLeft !== 0 && isSendEmailVerif )
                          ? <div 
                              className="gray-text" 
                              style={{ marginBottom: '7px', }}
                            >{intl.formatMessage({ id: 'ReSendingCode', defaultMessage: 'Re-sending the code will be available later' })}: 
                              <span className="white-text" style={{ width: '30px' }}> { this.state.timeLeft }</span></div>
                          : null
                      }
                      <Button size="md" disabled={email === '' || this.state.timeLeft !== 0} onClick={this.onSendCodeToEmail}>
                        {intl.formatMessage({ id: 'SendCode', defaultMessage: 'Send code' })}
                      </Button>
                    </div>
                    {
                      (isSendEmailVerif && this.props.codeSended)
                        ? (
                          <>
                            <div className="postregpage__rightcolumn__content__input">
                              <Input
                                name="Code"
                                onChangeText={(text) => {
                                  this.setState({
                                    code: text,
                                    formErrors: { ...formErrors, code: '' },
                                  });
                                }}
                                value={code}
                                placeholder="Code"
                                error={formErrors.code}
                              />
                            </div>
                            <div className="postregpage__rightcolumn__content__button">
                              <Button size="md" onClick={this.onCheckByCodes} disabled={code === ''}>
                                {intl.formatMessage({ id: 'Check', defaultMessage: 'Check' })}
                              </Button>
                            </div>
                          </>
                        ) : null
                    }
                    <div className="postregpage__rightcolumn__content__button">
                      <Button size="md" onClick={this.onCancelConfirmEmail}>
                        {intl.formatMessage({ id: 'ModalDefaultCancel', defaultMessage: 'Cancel' })}
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(withRouter(
  connect(
    state => ({
      langs: state.langs,
      users: state.users,
      emailVerification: state.users && state.users.user && state.users.user.emailVerification || false,
      regcodeerror: state.common.regcodeerror,
      codeSended: state.users.codeSended,
      emailerror: state.users.emailerror,
      timers: state.timers,
    }),
    {
      SendVerifSocialCodeToEmail,
      CheckVerificationCodeEmail,
      CheckConfirmEmail,
      ChangeLang,
      UserLogout,
    },
  )(PostRegData),
));
