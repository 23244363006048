import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ChangeLang } from '../../libs/reducers/actions';
import Select from '../common/Select';
import { injectIntl } from 'react-intl'
import { components } from 'react-select';
import SelectArrowDown from './images/select_arrow_down.png';
import SelectArrowUp from './images/select_arrow_up.png';
import appStoreIcon from "./images/app-store2.png";
import googlePlayIcon from "./images/google-play2.png";
import logo from "./images/logo.png";
import ESFlag from './images/lang_flags/ES.png';
import GBFlag from './images/lang_flags/GB.png';
import tournamentBadge from "./images/tournamentBadge.png";
import MobileMenu from './mobileMenu';
import SelectLang from './selectLang';

import AppleLogo from '-!svg-react-loader!./images/apple-logo-menu.svg'; // eslint-disable-line
import AndroidLogo from '-!svg-react-loader!./images/android-logo-menu.svg'; // eslint-disable-line
import HuaweiLogo from '-!svg-react-loader!./images/huawei-logo-menu.svg'; // eslint-disable-line
import SamsungLogo from '-!svg-react-loader!./images/samsung-logo-menu.svg'; // eslint-disable-line
import EyltLabel from '-!svg-react-loader!./images/eylt_label.svg'; // eslint-disable-line

import AppStoreBtn from '../../images/appstore.png';
import GooglePlayBtn from '../../images/googleplay.png';
import AppGalleryBtn from '../../images/appgallery.png';
import GalaxyStoreBtn from '../../images/galaxystore.png';


const MenuBurger = ({
  onClick = () => {}
}) => {
  return (
    <div className="burger" onClick={() => onClick()}>
      <div />
      <div />
      <div />
    </div>
  );
};

const HeaderTop = ({
  lang,
  ChangeLang,
  intl,
  showButton = true,
  scrollToBenefits,
  scrollToEquipmentForm,
  scrollToAlleyList,
  scrollToApplicationShowCase,
  hiddenLang = false,
  hiddenHeaderCenter = false,
  switchInvestorModalVisible = () => {},
}) => {
  const [isDropDown, setIsDropDown] = useState(false)
  const [visibleSideMenu, setVisibleSideMenu] = useState(false);


  const listener = document.body.addEventListener("click", e => {
    if (isDropDown && !e.target.classList.contains("dropdown"))
    setIsDropDown(false)
  });

  useEffect(() => {
    document.body.removeEventListener("click", listener);
  }, [listener]);

  const openDropDown = () => {
    setIsDropDown(!isDropDown)
  }

  const onClickDownload = (type) => {
    if (!window.ym) {
      return;
    }

    const ymKey = 52252669;

    switch (type) {
      case 'ios':
        window.ym(ymKey, 'reachGoal', 'DownloadIOS');
        break;
      case 'android':
        window.ym(ymKey, 'reachGoal', 'DownloadAndroid');
        break;
      default:
        break;
    }
  }

  const renderOptionLabel = (locale) => {
    const flags = {
      EN: (props) => <img {...props} src={GBFlag} />,
      ES: (props) => <img {...props} src={ESFlag} />,
      // EN: () => <GbFlag style={{ height: '100%', width: '100%' }} />,
      // ES: () => <EsFlag />,
      // RU: () => <RuFlag />,
    };

    return (
      <div className="lang-option__container">
        <div className="lang-option__img">
          {locale && flags[locale.value.toUpperCase()] && flags[locale.value.toUpperCase()]({ style: { width: '100%', height: '100%', objectFit: 'cover' } })}
        </div>
        <span className="lang-option__text">
          {locale.clearLabel.toUpperCase()}
        </span>
      </div>
    );
  }

  return (
    <div className="landing__header__top">
      <div className="header-left">
        <div className="header-left__container">
          <div className="logo">
            <a href='/'><img src={logo} alt="" /></a>
          </div>
          {(hiddenLang && <></>) || (
            <div className="header-lang__container">
              <SelectLang />
            </div>
          )}
        </div>
        {/*{(hiddenHeaderCenter && <></>) || (*/}
        {/*  <div className="header-center">*/}
        {/*    <div className="header-center__img__container">*/}
        {/*      <a href="/EYLT2021">*/}
        {/*        <EyltLabel />*/}
        {/*      </a>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
      <div className="links" >
        <div className={'for-investors'} onClick={() => switchInvestorModalVisible()}>
            For investors
        </div>
          {showButton
            ? <a className={`btn install${(window.location.pathname.includes('/howtoinstall') && ' active') || ''}`} href="/howtoinstall">HOW TO INSTALL</a>
            : null
          }
        <div
          onClick={() => {
            scrollToBenefits();
          }}
          className="btn"
        >
          {intl.formatMessage({
            id: "ForBowlingCenter",
            defaultMessage: "For bowling center"
          })}
        </div>
        <a className="sign-in btn small bordered" href="/signin">
          {intl.formatMessage({
            id: "SignIn",
            defaultMessage: "Sign in"
          })}
        </a>
        <div
          className="dropdown btn small filled"
          onClick={openDropDown}
        >
          {intl.formatMessage({
            id: "DownloadMobileApp",
            defaultMessage: "Download mobile app"
          })}
          {isDropDown && (
            <div className="dropdown__menu">
              <div className="linkContainer">
                <a
                  className="dropdown__menu__item"
                  target="blank"
                  href='https://apps.apple.com/ru/app/tenpincam/id1481986359?l=en'
                  onClick={() => onClickDownload('ios')}
                >
                  <img src={AppStoreBtn} />
                </a>
              </div>
              <div className="linkContainer">
                <a
                  className="dropdown__menu__item"
                  target="blank"
                  href="https://play.google.com/store/apps/details?id=com.tenpincam.telepresence_bowling_1"
                  onClick={() => onClickDownload('android')}
                >
                  <img src={GooglePlayBtn} />
                </a>
              </div>
              <div className="linkContainer">
                <a
                  className="dropdown__menu__item"
                  target="blank"
                  href='https://appgallery.huawei.com/#/app/C105386377'
                  onClick={() => onClickDownload('ios')}
                >
                  <img src={AppGalleryBtn} />
                </a>
              </div>
              <div className="linkContainer">
                <a
                  className="dropdown__menu__item"
                  target="blank"
                  href='https://galaxystore.samsung.com/detail/com.tenpincam.telepresence_bowling_1'
                  onClick={() => onClickDownload('ios')}
                >
                  <img src={GalaxyStoreBtn} />
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
      <MenuBurger onClick={() => setVisibleSideMenu(true)} />
      <MobileMenu
        visible={visibleSideMenu}
        onClose={() => setVisibleSideMenu(false)}
        intl={intl}
        scrollToEquipmentForm={scrollToEquipmentForm}
        scrollToAlleyList={scrollToAlleyList}
        scrollToApplicationShowCase={scrollToApplicationShowCase}
        switchInvestorModalVisible={switchInvestorModalVisible}
      />
    </div>
  );
};

export default withRouter(
  injectIntl(
    connect(
      state => ({
        lang: state.langs.lang,
      }),
      {
        ChangeLang
      }
    )(HeaderTop)
  )
)