/* eslint-disable */
import React from 'react';
import Chat from '../../../tournament/tournamentsChat/tournamentsChat';
import OBModal from '../../Modal';
import './chat-modal.scss'

/** @type {React.FC<{ visible?: boolean; onClose?: () => void }>} */
export const ChatModal = (props) => {
  const { visible, onClose } = props;
  return <OBModal
    key='main'
    title={'Chat'}
    visible={visible}
    width={395}
    onCancel={onClose}
    className="chat-modal"
    footer={null}
    zIndex={10000}
  >
    <Chat />
  </OBModal>
};
