const {
  SOCKET_EVENT,
  SOCKET_EVENT_GAME_INVITE,
  SOCKET_EVENT_GAME_CANCEL,
  SOCKET_EVENT_GAME_START,
  SOCKET_EVENT_SOCKET_CONNECTED,
  SOCKET_EVENT_GAME_END,
  SOCKET_EVENT_GAME_ENDING,
  SOCKET_EVENT_GAME_STATUS,
  SOCKET_EVENT_GAME_DRAW,
  SOCKET_EVENT_NOTIFICATION,
  SOCKET_EVENT_NEW_BATTLES,
  SOCKET_EVENT_GAME_NEXT,
  GAME_EXIT,
  LOGOUT,
  EVENT_SHOW_DIALOG,
  EVENT_CLOSE_DIALOG,
  CHAT_SOCKET_AUTH,
  USER_EVENT_CONF_RESET,
  SOCKET_EVENT_CONF_RESET,
  SOCKET_EVENT_RESULTCONFIRM,
  SOCKET_EVENT_FAILED_PAUSE,
  SOCKET_EVENT_RAISE_PAUSE,
  SOCKET_EVENT_SET_PAUSE,
  SHOW_NOFUND,
  HIDE_NOFUND,
  SHOW_HELPMODAL,
  HIDE_HELPMODAL,
  SHOW_FILTERMODAL,
  HIDE_FILTERMODAL,
  ACCEPTING_GAME_START,
  SET_GAME_START_ACCEPTING_ERROR,
  SET_GAME_START_ACCEPTING_COMPLETE,
  SET_SESSION_EVENT,
  SET_GAME_CANCEL_SOCKET,
  IN_BATTLES,
  SET_FLUSSONIC_API,
  CLEAR_EVENT_SQUAD_DATA,
  SET_GAME_START_NON_PLATFORM_ACCEPTING_COMPLETE,
} = require('./actiontypes').default;

const initialState = {
  game: {
    nonplatform: false,
    invite: false,
    start: false,
    gamedata: {},
    squadid: null,
    end: false,
    lastDraw: 0,
    acceptStatus: {
      accepted: false,
      loading: false,
      error: {},
    },
    gameCancelSocket: false,
  },
  gameending: false,
  socketConnected: false,
  chatAuth: false,
  notification: {},
  newBattles: false,
  gamestatus: 'none',
  dialog: {
    result: 0,
    visible: false,
    declineButtonText: 'Decline',
    acceptButtonText: 'Accept',
    title: 'Dialog',
    children: null,
  },
  failedPause: false,
  showNofund: false,
  session: {
    closed: false,
    reason: '',
    visible: false,
  },
  inBattle: false,
  config: {
    flussonicApi: '',
  }
};

export const newBattlesreset = params => ({ type: SOCKET_EVENT_NEW_BATTLES, params });
export const showDialog = params => ({ type: EVENT_SHOW_DIALOG, params });
export const closeDialog = params => ({ type: EVENT_CLOSE_DIALOG, params });
export const resetFailedPause = () => ({ type: SOCKET_EVENT_FAILED_PAUSE, params: {errorcode: false} });
export const hideNoFundModal = () => ({ type: HIDE_NOFUND });
export const hideHelpModal = () => ({ type: HIDE_HELPMODAL });
export const showHelpModal = () => ({ type: SHOW_HELPMODAL });
export const hideFilterModal = () => ({ type: HIDE_FILTERMODAL });
export const showFilterModal = () => ({ type: SHOW_FILTERMODAL });

const SocketEventReducer = (state = initialState, action) => {
  switch (action.type) {
    case SOCKET_EVENT:
      return { ...state, socketEvent: action.params };
    case SOCKET_EVENT_GAME_INVITE:
      return {
        ...state,
        game: {
          ...state.game,
          invite: true,
          gamedata: action.params,
          gameCancelSocket: true
        }
      };
    case SOCKET_EVENT_GAME_CANCEL:
      // Полностью закрываем баттл только когда закрываем через сокет
      const gameCancelSocket = action.params && action.params.hasOwnProperty('gameCancelSocket') && action.params.gameCancelSocket || true;

      return {
        ...state,
        game: {
          ...state.game,
          invite: false,
          gamedata: {},
          acceptStatus: {
            accepted: false,
            loading: false,
            error: {},
          },
          gameCancelSocket
        }
      };
    case SOCKET_EVENT_GAME_END:
      return {
        ...state,
        gameending: false,
        game: {
          ...state.game,
          squadid: null,
          start: false,
          end: true,
          gamedata: action.params,
          acceptStatus: {
            accepted: false,
            loading: true,
            error: {},
          }
        },
      };
    case SOCKET_EVENT_GAME_STATUS:
      return {
        ...state,
        gamestatus: action.params,
      };
    case GAME_EXIT:
      return {
        ...state,
        game: {
          ...state.game,
          end: false,
          gamedata: {},
        },
      };
    case ACCEPTING_GAME_START:
      return {
        ...state,
        game: {
          ...state.game,
          acceptStatus: {
            accepted: false,
            loading: true,
            error: {},
          }
        },
      };
    case SET_GAME_START_ACCEPTING_COMPLETE:
      return {
        ...state,
        game: {
          ...state.game,
          acceptStatus: {
            accepted: true,
            loading: false,
            error: {},
          },
        },
      };
  case SET_GAME_START_NON_PLATFORM_ACCEPTING_COMPLETE:
    return {
      ...state,
      game: {
        ...state.game,
        nonplatform: true,
        acceptStatus: {
          accepted: true,
          loading: false,
          error: {},

        },
      },
    };
    case SET_GAME_START_ACCEPTING_ERROR:
      return {
        ...state,
        game: {
          ...state.game,
          acceptStatus: {
            accepted: false,
            loading: false,
            error: { ...action.payload },
          },
        },
      };
    case SOCKET_EVENT_GAME_START:
      return {
        ...state,
        game: {
          ...state.game,
          invite: false,
          end: false,
          start: true,
          squadid: action.params,
        },
      };
    case EVENT_SHOW_DIALOG:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          ...action.params,
          visible: true,
        },
      };
    case EVENT_CLOSE_DIALOG:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          ...action.params,
          visible: false,
        },
      };
    case SOCKET_EVENT_NOTIFICATION:
      return {
        ...state,
        notification: action.params,
      };
    case LOGOUT:
      return { ...initialState, session: { ...state.session } };
    case SOCKET_EVENT_SOCKET_CONNECTED:
      return {
        ...state,
        socketConnected: action.params,
      };
    case SOCKET_EVENT_NEW_BATTLES:
      return {
        ...state,
        newBattles: action.params,
      };
    case SOCKET_EVENT_GAME_ENDING:
      return { ...state, gameending: true };
    case SOCKET_EVENT_GAME_DRAW:
      return { ...state, gameending: false, game: {...state.game, lastDraw: action.params} };
    case SOCKET_EVENT_GAME_NEXT:
      return { ...state, gameending: false };
    case CHAT_SOCKET_AUTH:
      return { ...state, chatAuth: action.params };
    case SOCKET_EVENT_RAISE_PAUSE:
    case SOCKET_EVENT_SET_PAUSE:
      return { ...state, failedPause: null };
    case SOCKET_EVENT_FAILED_PAUSE:
      return { ...state, failedPause: action.params.errorcode };
    case SHOW_NOFUND:
      return { ...state, showNofund: true }
    case HIDE_NOFUND:
      return { ...state, showNofund: false }
    case SHOW_HELPMODAL:
      return { ...state, showHelpModal: true }
    case HIDE_HELPMODAL:
      return { ...state, showHelpModal: false }
    case SHOW_FILTERMODAL:
      return { ...state, showFilterModal: true }
    case HIDE_FILTERMODAL:
      return { ...state, showFilterModal: false }
    case SET_SESSION_EVENT:
      return {
        ...state,
        session: { ...state.session, ...action.payload },
      };
    case IN_BATTLES:
      return {
        ...state, inBattle: action.payload,
      };
    case SET_GAME_CANCEL_SOCKET:
      state.game.gameCancelSocket = action.params;
      return state;
    case SET_FLUSSONIC_API:
      return {
        ...state, config: {
          ...state.config,
          flussonicApi: action.params
        }
      };
  case CLEAR_EVENT_SQUAD_DATA:
    return {...state, game: { ...state.game, squadid: {}}}
    default:
      return state;
  }
};

export default SocketEventReducer;
