import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import { CSSTransitionGroup } from 'react-transition-group';

export default class Collapsible extends Component {
  static propTypes = {
    /** Свернуто */
    isCollapsed: PropTypes.bool.isRequired,
    /** Дочерние элементы */
    children: Component,
    /** Анимировано */
    isAnimated: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    isCollapsed: false,
    isAnimated: true,
  };

  render() {
    const { isCollapsed, isAnimated, children } = this.props;

    return (
      <div className="collapsible">
        <CSSTransitionGroup
        transitionName='collapsible'
        transitionEnter={isAnimated}
          transitionLeave={isAnimated}
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300} >
          {
            !isCollapsed
            && <div className="collapsible__content">
              {children || ''}
            </div>
          }
        </CSSTransitionGroup>
      </div>
    );
  }
}
