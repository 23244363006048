/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import videoPreloader from "../../images/video_preloader.svg";
import BowlingCentersCamera from "../bowlingCentersView/bowlingCentersCamera";
import { injectIntl } from "react-intl";
import logo from '../../images/Logo-TenPinCam.png'
import TemporaryBanner from "./TemporaryBanners";

import config from '../../config';

import './adaptive.css'

import QRCode from "react-qr-code";
import Banners from './Banners';

const flattenArray = array => [...array].reduce((acc, el) => {
  el.map(obj => acc.push(obj))
  return acc;
}, []);

const getUniqueElemByIdForArray = (array) => [...array].reduce((acc, el) => {
  if (!acc.some(item => item.id === el.id)) acc.push(el);
  return acc;
}, []);

const StreamPage = (props, ref, slider) => {
  const { bcname } = props.match.params;
  const { bowlingCenters, fetchBowlingCenters, user, intl } = props;
  const [trackView, setTrackView] = useState([]);
  const [name, setName] = useState('');
  const [loaded, setLoaded] = useState(false);
  const { data: bowlingdata, fetching } = bowlingCenters;


  useEffect(() => {
    if (bowlingdata.length !== 0) {
      fetchBowlingCenters();
    }
  }, []);

  useEffect(() => {
    if (bowlingdata.length !== 0 && !fetching) {
      const currentBC = bowlingdata.filter(bc => bc.name.toLowerCase().replace(/ +/g, "") === bcname)[0];
      if (currentBC) {
        if (currentBC.stream_enabled !== 1) props.history.push("/")
        setName(currentBC.name);
        const linesstructure = currentBC.linesstructure;
        const overviewCameras = flattenArray(linesstructure.map(line => line.cameras))
          .filter(camera => (camera || {}).cameravideotypeid === 1);
        const uniqueCameras = getUniqueElemByIdForArray(overviewCameras);
        setTrackView(uniqueCameras);
      } else {
        props.history.push("/");
      }
      setLoaded(true);
    }
  }, [fetching]);

  if (!loaded) return <img src={videoPreloader} style={{ width: '100vw', height: '100vh' }}/>

  return (
    <div className={"stream_page"}>
      <div className={"stream__header"}>
        <a href={'/'}>
          <img className={"bc_stream_logo__header"} src={logo}/>
        </a>

        <div className={"bc_name__text"}>{name.toUpperCase()}</div>
        <QRCode value={`${config.FETCH.url}/settings/app`} bgColor={'#0A101A'} fgColor={'#fff'} size={80}
                className={"bc_link_to_download__header"}/>
      </div>
      <div className={"bc_name__text-mobile"}>{name.toUpperCase()}</div>
      <div className={"stream__content"}>
        <BCView trackView={trackView} intl={intl} user={user}/>
      </div>
    </div>
  )
}

const BCView = ({ trackView, intl, user }) => (
  <div className={trackView.length === 1 ? 'content_container-vertical' : ''}>
    <div className={trackView.length === 1 ? 'camera_container-vertical' : 'camera_container'}>
      {trackView.map((camera, idx) => (
        <BowlingCentersCamera
          key={`${camera.id}-${camera.cameratypeid}`}
          intl={intl}
          controls
          className="bc_camera"
          idx={idx}
          bowlingcenterId={0}
          laneId={0}
          camera={camera}
          user={user}
          muted
        />))}
    </div>
    <div>
      <Banners vertical={trackView.length === 1} />
      {/* <TemporaryBanner trackView={trackView} horizontal={trackView.length !== 1}/> */}
    </div>
  </div>
)

export default injectIntl(connect((state) => ({
  user: state.users.user,
  bowlingCenters: state.bowlingcenters.bowlingcenters,
}), {
  fetchBowlingCenters: () => ({ type: 'BOWLING_CENTERS_FETCH' }),
})(StreamPage));
