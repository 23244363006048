import React from 'react';

function Input({
  label,
  error,
  errorMessage,
  onChange,
}) {
  return (
    <div>
      <div className="s-label">{label}</div>
      <input onChange={e => onChange(e.target.value)} className={`l-input ${error ? 'l-error' : ''}`}/>
      {
        error && (
          <div className="s-error">{errorMessage}</div>
        )
      }
    </div>
  );
}

export default Input;
