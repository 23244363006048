import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from './Button';
import { AddFriend as AddFriendConnect } from '../../../libs/reducers/FriendsReducer';

class AddFriendButton extends Component {
  state = {
    loading: false,
    accepted: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { loading } = prevState;
    const { data, userid } = nextProps;
    if (data) {
      const { fetching, error, userid: dataUserId } = data;
      if (userid === dataUserId && loading && !fetching) {
        return {
          loading: false,
          accepted: error === '',
        };
      }
    }
    return null;
  }

  handleAdd = () => {
    const { loading, accepted } = this.state;
    if (loading || accepted) {
      return;
    }
    this.setState(
      {
        loading: true,
      },
      () => {
        const { user, data, AddFriend } = this.props;
        if (!data.fetching) {
          AddFriend(user);
        }
      },
    );
  };

  render() {
    const { accepted, loading } = this.state;
    const { data, user, userid, friends } = this.props;
    const { fetching } = data;
    let isFriend = false;
    const _userid = (user && user.id) || userid;
    if (friends) {
      isFriend = friends.find(f => f.user.id === _userid) !== undefined;
    }
    if (!accepted) {
      if (isFriend) {
        return (
          <Button {...this.props} disabled icon="adduser" />
        )
      } else {
        return (
          <Button {...this.props} disabled={this.props.disabled||fetching} loading={loading} onClick={this.handleAdd} icon="adduser" />
        )
      }
    } else {
      return (
        <Button {...this.props} onClick={this.handleAdd} disabled icon="check" />
      )
    }
  }
}

export default connect(
  state => ({
    data: state.friends.friendAdd,
    friends: state.friends.friends.data,
  }),
  {
    AddFriend: AddFriendConnect,
  },
)(AddFriendButton);
