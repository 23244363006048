import {
  all,
  call,
  fork,
  put,
  select,
  spawn,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import bugsnagClient from "../../bugsnagClient";
import config from "../../config";
import { api, post, get } from "../Networking";
import { getCookie } from "../../cookieManager";
import {
  _getActiveUsers,
  _getMaxWatcherCount,
  _saveMaxWatcherCount,
  _changeHelpWatched,
  _checkHelpWatched,
  _checkMailVerification,
  _CheckSocialSendCodeEmail,
  _GetCountUsersRegistered,
  _getStats,
  _rateUserPost,
  _sendAppleAuth,
  _sendAuthorizationCode,
  _sendFirebaseToken,
  _sendVerificationCode,
  _SetUserType,
  _SocialSendCodeEmail,
  _UpdateUser,
  _UserByIdGet,
  _UserCheckCodePhone,
  _UserCheckLoginString,
  _UserGetBowlingCenterPermissions,
  _UserGetCodePhone,
  _UserGetPermissions,
  _UserGetPhone,
  _UserLogin,
  _UserLogout,
  _UserPasswordChange,
  _UserPasswordReset,
  _UserPasswordResetPhone,
  _UserPassworUpdate,
  _UserPutPhone,
  _UserReg,
  _UserSendCodeEmail,
  _UserSet,
  _userUnregister,
  _getLeaderBoard,
  _ChangeKeyboardType,
  SNUserFetchSaga,
  onAccess,
  _getReferral,
} from "./Users";
import { _SearchUsers } from "./userSearch";
import {
  _AccountTournamentsGet,
  _addReplayToPlayer,
  _addToTournament,
  _bindBowlingcenter,
  _cancelTournamentBattle,
  _confirmTournamentResults,
  _CreateBattle,
  _createBattleForTournament,
  _createLeaderboardStage,
  _createOpenChallenge,
  _createQuickGame,
  _CreateTournament,
  _deleteLeaderboardStage,
  _editTournamentBattle,
  _GameAccepted,
  _GameCanceled,
  _GetTournament,
  _GetTournamentInfoForEdit,
  _getTournamentTypeList,
  _removeFromTournament,
  _saveLeaderboardStage,
  _setVisibilityBattle,
  _TakePartTournament,
  _TournamentDelete,
  _tournamentGameEnded,
  _tournamentInfoRefresh,
  _TournamentsGet,
  _unbindBowlingcenter,
  _updateGameCount,
  _updateQuickGameToOpenChallenge,
  _UpdateTournament,
  _updateTournamentTable,
  _uploadTournamentCardDecoration,
  _getBcNotPlatform,
  _createGameNonPlatform,
  _SendInviteTournamentNotification,
  _getSquadIdByUserId,
} from "./Tournaments";
/*
import {
  _BattlesGet,
  _BattlesResponseToUser,
  _BattlesResponseToOwner,
  _BattlesBattleCancel,
  _AccountBattlesGet,
  _GetAccountBattleHistory,
  _SquadListFetch,
  _GetGameInfo,
} from './Battles';
*/
import { _BattlesGet, _GetGameInfo } from "./Battles/battles.handler";
import {
  payseraRequestsRequestSaga,
  payseraRequestsUpdateRequestSaga,
  _acceptDocVerification,
  _AlikeUsersGet,
  _CurrencyListGet,
  _EditUserAsAdmin,
  _getAdminDiskSize,
  _getAllUserDocs,
  _getAllUsers,
  _getBattlesList,
  _GetIBANrequests,
  _getSearchUserTariffs,
  _getSystemAccounts,
  _getTransactionDiff,
  _getTransactionHistory,
  _getUserDocById,
  _ManualTransaction,
  _refuseDocVerification,
  _requestTransfer,
  _sendNotificationEvent,
  _sendNotificationUpdate,
  _setTariffByUser,
  _UpdateIBANrequest,
  _uploadMainBanners,
  _uploadBCBanners,
  _uploadPoster,
  _getAnalyticsBowlingCenter,
  _getBCBannersб,
  _getBCBanners,
  _publicationBCBanners,
  _getSubscribers,
  _changeTariffFetch,
} from "./admin";
import {
  ACCEPT_DOC_VERIFICATION,
  EDIT_USER_AS_ADMIN,
  GET_ADMIN_DISK_SIZE,
  GET_ALIKE_USER_FETCH,
  GET_ALL_USERS_FETCH,
  GET_BATTLES_LIST,
  GET_CURRENCY_LIST_FETCH,
  GET_IBAN_REQUESTS,
  GET_SEARCH_USER_TARIFFS,
  GET_SYSTEM_ACCOUNTS,
  GET_TRANSACTION_DIFF,
  GET_TRANSACTION_HISTORY,
  GET_USER_DOC_BY_ID,
  GET_USER_DOCS_ALL,
  REFUSE_DOC_VERIFICATION,
  REQUEST_TRANSFER,
  SEND_MANUAL_TRANSACTION,
  SEND_NOTIFICATION_EVENT,
  SEND_NOTIFICATION_UPDATE,
  SET_TARIFF_BY_USER,
  UPDATE_IBAN,
  UPLOAD_MAIN_BANNERS,
  UPLOAD_POSTER,
  GET_ANALYTICS_BOWLING_CENTER,
  SET_ANALYTICS_BOWLING_CENTER,
  UPLOAD_BC_ADMIN_BANNERS,
  GE_BC_ADMIN_BANNERS,
  PUBLICATION_BC_BANNER,
  GET_SUBSCRIBERS,
  SUBSCRIBERS_CHANGE_PAGE,
} from "../reducers/adminReducer";
import {
  _createPrivateRoom,
  _createSupportRoom,
  _exitGameObserver,
  _fetchChatRoom,
  _fetchChatRooms,
  _GameAcceptedResults,
  _GameLeave,
  _joinAsObserver,
  _joinAsPlayer,
  _SendProtest,
  _SendProtestReport,
  _SocketDisconnect,
  _SocketEmit,
  _socketSaga,
  _updateMessagesSeen,
} from "./Socket";
import _getVideoArchives, { fetchVideoArchivesAll } from "./videoArchives";
// import { _getVideoFiles, _bindVideoFiles, _unbindVideoFiles } from './VideoFiles/videofiles.handler';
import _getUserDisk from "./userDisk";
import { _ChangeLang, _GetTranslationsByLang } from "./Langs";
import { _checkExpectedGames, _WatchSquads } from "./squads.saga";
import { SQUAD_ID_SET } from "../reducers/squadId.reducer";
import {
  _unreadNotificationsCount,
  _WatchNotifications,
} from "./notifications.saga";
import {
  payseraOrderRequestSaga,
  payseraWithdrawRequestSaga,
  _cookieAgree,
  _cookieGet,
  _createIBANpayment,
  _DeleteCreditCard,
  _DeleteIBAN,
  _DropTariff,
  _encryptData,
  _getActiveHDC,
  _getActiveLangs,
  _GetAgreements,
  _GetBillingLoans,
  _GetCities,
  _GetCommission,
  _GetCreditCards,
  _GetDefaultTimezone,
  _GetGeoData,
  _getHandicapList,
  _GetMobileVersion,
  _GetPayPalOrder,
  _GetSettings,
  _GetTariffs,
  _GetTimeZones,
  _GetUserCountry,
  _GetUserTariff,
  _GetWalletHistory,
  _retrivePaymentTokenRequest,
  _saveHandicapList,
  _SaveIBAN,
  _setActiveHDC,
  _SwitchTariff,
  _uploadApkFile,
  _WatchCommon,
} from "./Common";
import {
  _sendBowlingCenterRequest,
  _WatchBowlingCenters,
} from "./bowlingCenters.saga";
import { _WatchFriends } from "./Friends";
import { watchCookies } from "./Cookies.saga";
import { keyboardSaga } from "./Keyboard.saga";
import { _WatchBattles } from "./Battles/battles.saga";
import { _WatchVideoFiles } from "./VideoFiles/videofiles.saga";
import { _getVideoArchive, _getVideoArchiveProtest } from "./videoArchive";
import {
  _cancelProtestArchive,
  _closeProtest,
  _considerProtest,
  _createProtestArchive,
  _getProtest,
  _getProtestList,
  _getProtestListOfParty,
  _refuseProtest,
  _replyProtestArchive,
} from "./protest";
import { _getProtocol, _makeResolve } from "./protocol";
import {
  _getUserSettings,
  _setUserSettingChildValue,
  _setUserSettingValue,
} from "./userSettings";
import { _sendSupportMessage } from "./support";
import { _onAddModal, _onCloseModal } from "./Modals";
import { _fetchTimestamp } from "./Timestamp";
import { MyCookie, myCookies } from "../MyCookies";
import {
  fetchCookieAgreement,
  sendCookieAgreement,
} from "./cookieAgreement.saga";
import {
  BOWLING_CENTERS_COUNTRY_FETCH,
  BOWLING_CENTERS_FETCH,
} from "../reducers/bowlingCenters.reducer";
import { FETCH_USER_DOCS, SEND_USER_DOCS } from "../reducers/userDocs";
import { _getUserDocs, _sendUserDocs } from "./userDocs";
import {
  _AddTournamentSetting,
  _DeleteTournamentSetting,
} from "./TournamentSettings";

import {
  FETCH_ACTIVE_HDC,
  GET_ACTIVE_HDC,
  GET_ACTIVE_LANGS,
} from "../reducers/commonReducer";
import { watchUserDeletion } from "../reducers/userDelete.reducks";
import { UserExit } from "./Users";
import { watchReglament } from "../reducers/reglament.reducks";
import { watchRate } from "../reducers/rate.reducks";
import {
  gameInviteSaga,
  watchGameInvite,
} from "../reducers/gameInvite.reducks";
import {
  getAnalyticsBowlingCenter,
  getSquadIdByUserId,
} from "../reducers/actions";
import { getTariffsOffSaga } from "../reducers/tariffsOff.reducks";

import {
  _getStatisticMain,
  _getStatisticAverage,
  _getStatisticHigh,
  _getStatisticClean,
  _getStatisticSeries,
  _getStatisticFramesMain,
  _getStatisticFramesFirst,
  _getStatisticFramesAverage,
  _getStatisticFramesStrikes,
  _getStatisticFramesSplits,
} from "./statistic";
import { watchTenpinToolkitSaga } from "../reducers/tenpinToolkit.reducks";
import { watchUserPoolSaga } from "../reducers/userPool.reducks";
import { LocalEvents } from "../local-events";

const takeLeading = (patternOrChannel, saga, ...args) =>
  fork(function* () {
    while (true) {
      const action = yield take(patternOrChannel);
      yield call(saga, ...args.concat(action));
    }
  });

const logAppInit = false;
const logger = {
  log: logAppInit ? console.log : function () {},
};

const {
  APP_INIT,
  USER_BY_ID_FETCH,
  USER_SET,
  USER_EDIT_FETCH,
  USER_LOGIN,
  USER_LOGOUT,
  USER_REG,
  USER_SEARCH_FETCH,
  USER_SET_DATA,
  USER_PASSRESET_CODE,
  PASS_CHANGE_FETCH,
  PHONE_FETCH,
  ACCESSED,
  ACCOUNT_TOURNAMENTS_FETCH,
  TOURNAMENTS_FETCH,
  BATTLES_FETCH,
  ACCOUNT_BATTLES_FETCH,
  ACCOUNT_BATTLES_HISTORY_FETCH,
  BATTLES_RESPONSE_TO_USER_FETCH,
  BATTLES_RESPONSE_TO_OWNER_FETCH,
  BATTLES_BATTLE_CANCEL_FETCH,
  SOCKET_EVENT_GAME_CANCEL,
  SOCKET_EVENT_GAME_NEXT,
  LANG_SET,
  SOCKET_EMIT,
  TOURNAMENTS_BATTLE_TAKEPART,
  TOURNAMENTS_CREATE_BATTLES_FETCH,
  TOURNAMENTS_CREATE_TOURNAMENT_FETCH,
  TOURNAMENTS_DELETE_FETCH,
  SOCKET_EVENT_GAME_CANCEL_FETCH,
  SOCKET_EVENT_GAME_CONFIRM_FETCH,
  SOCKET_DISCONNECT,
  USER_PHONE_PUT,
  USER_PHONE_GETCODE,
  USER_PHONE_CHECKCODE,
  USER_EMAIL_CODE_FETCH,
  GAME_LEAVE,
  GAME_ACCEPT,
  SEND_PROTEST,
  GEODATA_FETCH,
  GEODATA_CITIES_FETCH,
  GEODATA_TIMEZONES_FETCH,
  DEFAULTTIMEZONE_FETCH,
  USER_PASSRESET,
  USER_PASSWORD_UPDATE,
  PROTEST_CANCEL,
  TRANSLATIONS_FETCH,
  CHANGE_LANG,
  USERGEODATA_FETCH,
  FETCH_VIDEO_ARCHIVES,
  FETCH_VIDEO_ARCHIVES_ALL,
  FETCH_VIDEO_FILES,
  FETCH_USER_DISK,
  FETCH_CHAT_ROOM,
  FETCH_CHAT_ROOMS,
  BIND_VIDEO_FILES,
  UNBIND_VIDEO_FILES,
  UPDATE_MESSAGES_SEEN,
  CREATE_PRIVATE_ROOM,
  CREATE_SUPPORT_ROOM,
  RATE_USER_POST,
  UPDATE_USER_DATA,
  TOURNAMENTS_UPDATE_INFO_FETCH,
  ADD_USER_TO_TOURNAMENT_FETCH,
  DELETE_USER_FROM_TOURNAMENT_FETCH,
  WATCH_TOURNAMENT,
  CREATE_BATTLE_IN_TOURNAMENT,
  GET_VIDEO_ARCHIVE,
  GAME_EXIT,
  CANCEL_TOURNAMENT_BATTLE,
  PAYMENTDATA_FETCH,
  PAYMENTTOKEN_FETCH,
  FETCH_PROTEST_LIST,
  REFEREE_CONSIDER,
  REFEREE_REFUSE,
  FETCH_PROTEST_LIST_OF_PARTY,
  GET_PROTEST,
  GET_PROTOCOL,
  ACCEPT_PROTEST,
  REJECT_PROTEST,
  AGREEMENTS_FETCH,
  USERCARDS_FETCH,
  USERCARDS_DEL,
  IBAN_SAVE,
  IBAN_DEL,
  CREATE_IBAN_PAYMENT,
  MAKE_RESOLVE,
  BOWLING_CENTER_SEND_REQUEST,
  GET_PERMISSIONS,
  SOCKET_FORCE_CONNECT,
  GET_USER_SETTINGS,
  SET_USER_SETTING_VALUE,
  SET_USER_SETTING_CHILD_VALUE, // Запись группы настроек
  FETCH_WALLET_HISTORY,
  SET_WALLET_HISTORY,
  GET_TARIFF_MY,
  GET_TARIFFS,
  DROP_TARIFF,
  SWITCH_TARIFF_RENEWAL,
  SEND_AUTHORIZATION_CODE,
  SEND_VERIFICATION_CODE,
  GET_COMMISSION,
  SUPPORT_SEND_MESSAGE,
  FETCH_TIMESTAMP,
  CHECK_LOGIN_STRING,
  ADD_MODAL,
  GET_BOWLINGCENTER_PERMISSIONS,
  CLOSE_MODAL,
  FETCH_GET_COOKIE_STATE,
  FETCH_COOKIE_STATE,
  SOCIAL_ACCOUNT,
  REG,
  FETCH_USERSTATS,
  SET_HTTP_SERVER_STATUS,
  GET_HTTP_SERVER_STATUS,
  SEND_FIREBASE_TOKEN,
  GET_BILLING_LOANS,
  FETCH_JOIN_AS_OBSERVER,
  FETCH_JOIN_AS_PLAYER,
  SEND_APPLEID,
  EDIT_TOURNAMENT_BATTLE,
  SQUAD_LIST_FETCH,
  FETCH_EXIT_OBSERVER,
  SQUAD_GETINFO_FETCH,
  SOCIAL_EMAIL_CODE_FETCH,
  CHECK_SOCIAL_EMAIL_CODE_FETCH,
  TOURNAMENT_INFO_FOR_EDIT_FETCH,
  FETCH_TOURNAMENT_TYPE_LIST,
  MOBILE_VERSION_FETCH,
  CHANGE_HELP_STATUS,
  FETCH_HELP_STATUS,
  TOURNAMNET_BIND_BC_FETCH,
  TOURNAMNET_UNBIND_BC_FETCH,
  TOURNAMNET_BIND_OPERATION_END,
  GET_COUNT_USERS_REGISTERED,
  GET_HANDICAP_LIST,
  CREATE_PROTEST_ARCHIVE,
  CANCEL_PROTEST_ARCHIVE,
  REPLY_PROTEST_ARCHIVE,
  GET_PAYPAL_ORDER,
  CONNECT_TO_SOCKET,
  TOURNAMENT_GAME_ENDED,
  GET_VIDEO_ARCHIVE_PROTESTS,
  CREATE_QUICK_GAME,
  CREATE_OPEN_CHALLENGE,
  UPDATE_QUICK_GAME_TO_OPEN_CHALLENGE,
  CONFIRM_TOURNAMENT_RESULTS,
  SET_USER_TYPE,
  ADD_TOURNAMENT_SETTING,
  DELETE_TOURNAMENT_SETTING_FETCH,
  SAVE_HANDICAP_LIST,
  FETCH_UPLOAD_APK,
  ADD_REPLAY_PLAYER,
  CREATE_LEADERBOARD_TABLE_STAGE,
  DELETE_LEADERBOARD_TABLE_STAGE,
  SAVE_LEADERBOARD_TABLE_STAGE,
  REFRESH_TOURNAMENT_INFO,
  CHANGE_KEYBOARD_TYPE,
  UPLOAD_CARD_DECORATION_FETCH,
  GET_LEADER_BOARD,
  GET_FLUSSONIC_API,
  SET_FLUSSONIC_API,
  GET_ACTIVE_USERS,
  GET_MAX_WATCHER_COUNT,
  SAVE_MAX_WATCHER_COUNT,
  UPDATE_GAME_COUNT_FETCH,
  SET_VISIBILITY_BATTLE,
  SEND_INVITE_TOURNAMENT_NOTIFICATION,
  CHECK_EXPECTED_GAMES,
  GET_STATISTIC_MAIN,
  GET_STATISTIC_AVERAGE,
  GET_STATISTIC_HIGH,
  GET_STATISTIC_CLEAN,
  GET_STATISTIC_SERIES,
  GET_STATISTIC_FRAMES_MAIN,
  GET_STATISTIC_FRAMES_FIRST,
  GET_STATISTIC_FRAMES_AVERAGE,
  GET_STATISTIC_FRAMES_STRIKES,
  GET_STATISTIC_FRAMES_SPLITS,
  GET_SQUAD_ID_BY_USER_ID,
  GET_BC_NOT_PLATFORM,
  CREATE_GAME_NOT_PLATFORM,
  TOGGLE_STREAM_FETCH,
  TOGGLE_STREAM_FETCHED,
  GET_REFERRAL_LINK,
  SUBSCRIBERS_CHANGE_TARIF_FETCH,
} = require("../reducers/actiontypes").default;

export const getCookies = async (name) => {
  if (typeof document === "undefined") {
    if (myCookies.isEmpty()) {
      await myCookies.setFromNativeCookies(config.FETCH.url);
    }
    if (myCookies.cookies[name] instanceof MyCookie) {
      return myCookies.cookies[name].value;
    }
    return null;
  }
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1")}=([^;]*)`
    ) // eslint-disable-line
  );
  return matches ? decodeURIComponent(matches[1]) : null;
};

export function* connectSockets() {
  try {
    const key = yield call(getCookie, "the_cookie");
    const user = yield call(post, {
      url: `${config.FETCH.url}/getuserbysession`,
      key,
    });
    if (user) {
      const {
        id,
        session: { sessionkey },
      } = user.data;
      yield call(_socketSaga, {
        eventName: "auth",
        params: { id, sessionkey },
      });
      yield put({ type: USER_SET, params: user.data });
    }
  } catch (error) {
    bugsnagClient.notify(error);
  }
}

const isDefaultLogin = (login, snuser) => {
  if (!snuser || !snuser.id) {
    return false;
  }
  const match = (login || "").match(
    /^(vkontakte|facebook|google|instagram)?(?:-|_)(\d+)$/
  );
  return !!match && match[2] === `${snuser.id}`;
};

/** @typedef {{ id: number, userid: number, login: string, accessKey: string }} SNUserType */

export function* _InitApp() {
  try {
    let key = yield call(getCookies, "the_cookie");
    logger.log("[APP_INIT] the_cookie.value = ", key, typeof key);
    const lang = yield select((state) => state.langs.lang);
    yield call(_GetTranslationsByLang, { params: lang || "EN" });
    if (!lang) {
      yield put({ type: LANG_SET, params: "EN" });
    }
    let user = {};
    if (key !== null) {
      user = yield call(post, {
        url: `${config.FETCH.url}/getuserbysession`,
        key,
      });
      logger.log(
        "[APP_INIT] get user by session ok, user = ",
        user && user.data
      );
      if (user !== null) {
        logger.log("[APP_INIT] spawn socket-saga..");
        yield spawn(_socketSaga, {
          eventName: "auth",
          params: {
            id: user.data.id,
            sessionkey: user.data.session.sessionkey,
          },
        });
        logger.log("[APP_INIT] set lang ", user.data.lang);
        yield put({ type: LANG_SET, params: user.data.lang });
        logger.log("[APP_INIT] _USER_SET.params: ", user.data);
        yield put({ type: USER_SET, params: user.data });
        /** @type {{ data: { success: number, snuser: SNUserType } }} */
        const { data: { snuser } = {} } = yield call(api.get, {
          url: `${config.FETCH.url}/user/snuser`,
        });
        logger.log("[APP_INIT] snuser: ", snuser);
        yield put({ type: SOCIAL_ACCOUNT, payload: snuser });
        logger.log("[APP_INIT] snuser.login: ", snuser && snuser.login);
        logger.log(
          "[APP_INIT] snuser.login isDefault: ",
          snuser && snuser.login
        );
        const isReg =
          (!!snuser && isDefaultLogin(snuser.login, snuser)) ||
          (user.data &&
            (!user.data.firstname ||
              !user.data.lastname ||
              !user.data.gender ||
              !user.data.dob ||
              !user.data.country_live));
        logger.log("[APP_INIT] isReg: ", isReg);
        if (
          user.data &&
          user.data.firstname &&
          user.data.lastname &&
          user.data.dob &&
          user.data.country_live &&
          user.data.hourshift &&
          !isReg
        ) {
          logger.log("[APP_INIT] set ACCESSED = true");
          yield put({ type: ACCESSED, params: true });
          yield put({ type: GET_BOWLINGCENTER_PERMISSIONS });
          LocalEvents.emit('user', LocalEvents.toUser(user.data))
        } else {
          yield put({ type: ACCESSED, params: false });
          logger.log("[APP_INIT] set ACCESSED failed");
        }
        yield put({ type: REG, params: isReg });
        logger.log("[APP_INIT] set squad_id = ", user.data.squadid);
        yield put({ type: SQUAD_ID_SET, params: user.data.squadid });
        logger.log("[APP_INIT] count unread notifications..");
        yield call(_unreadNotificationsCount);
      }
    } else {
      yield put({ type: SOCIAL_ACCOUNT, payload: null });
      yield put({ type: REG, params: false });
      logger.log("[APP_INIT] _USER_SET.params: ", null);
      yield put({ type: USER_SET }); // drop state.users.user
      logger.log(
        "[APP_INIT] get user by session failed, due to cookie is empty"
      );
    }
    logger.log("[APP_INIT] fetching app settings..");
    yield call(_GetSettings);
    yield call(_GetGeoData);
    yield put({ type: BOWLING_CENTERS_FETCH });
    yield put({ type: BOWLING_CENTERS_COUNTRY_FETCH });
    // yield call(_GetBowlingCenters);
    // yield call(_GetBowlingCentersCountry);
    yield call(_GetTimeZones);
    yield call(fetchCookieAgreement);
    const cookieAgreement = yield select((state) => state.cookieAgreement);
    // если при открытии приложения куки не подтверждены, то активируем модалку
    // if (!cookieAgreement.global.confirmed && !cookieAgreement.user.confirmed) {
    //   yield put({ type: 'SET_COOKIE_AGREEMENT_MODAL', payload: true });
    // }
    yield put({ type: APP_INIT });
    LocalEvents.emit('launch_app')
  } catch (error) {
    bugsnagClient.notify(error, { context: "saga InitApp" });
  }
}

export function* _toggleStream(bc) {
  try {
    const result = yield call(post, {
      url: `${config.FETCH.url}/bowlingcenter/toggle_stream`,
      data: { bc_id: bc.id },
    });
    if (result.status === 200 && result.data.success) {
      yield put({ type: TOGGLE_STREAM_FETCHED });
    }
  } catch (e) {
    console.error(e);
  }
}

export function* _waitPersist() {
  try {
    yield takeLatest("persist/REHYDRATE", _InitApp);
  } catch (error) {
    console.log("persist/REHYDRATE error", error);
  }
}

export function* _WatchActions() {
  try {
    yield takeLatest(USER_BY_ID_FETCH, _UserByIdGet);
    yield takeLatest(USER_EDIT_FETCH, _UserSet);
    yield takeLatest(USER_LOGIN, _UserLogin);
    yield takeLeading(USER_LOGOUT, _UserLogout);
    yield takeLeading("USER_EXIT", UserExit);
    yield takeLeading("SOCIAL_ACCOUNT_FETCH_REQUEST", SNUserFetchSaga);
    yield takeLatest(USER_PASSRESET, _UserPasswordReset);
    yield takeLatest(USER_PASSWORD_UPDATE, _UserPassworUpdate);
    yield takeLatest(USER_PASSRESET_CODE, _UserPasswordResetPhone);
    yield takeLatest(PASS_CHANGE_FETCH, _UserPasswordChange);
    yield takeLatest(USER_REG, _UserReg);
    yield takeLatest(ACCOUNT_TOURNAMENTS_FETCH, _AccountTournamentsGet);
    yield takeLatest(TOURNAMENTS_FETCH, _TournamentsGet);
    // yield takeLatest(BATTLES_FETCH, _BattlesGet);
    // yield takeLatest(ACCOUNT_BATTLES_HISTORY_FETCH, _GetAccountBattleHistory);
    // yield takeLatest(ACCOUNT_BATTLES_FETCH, _AccountBattlesGet);
    // yield takeLatest(BATTLES_RESPONSE_TO_USER_FETCH, _BattlesResponseToUser);
    // yield takeLatest(BATTLES_RESPONSE_TO_OWNER_FETCH, _BattlesResponseToOwner);
    // yield takeLatest(BATTLES_BATTLE_CANCEL_FETCH, _BattlesBattleCancel);
    yield takeLatest(SOCKET_FORCE_CONNECT, connectSockets);
    yield takeLatest(SOCKET_EMIT, _SocketEmit);
    yield takeLatest(SOCKET_DISCONNECT, _SocketDisconnect);
    yield takeLatest(TOURNAMENTS_BATTLE_TAKEPART, _TakePartTournament);
    yield takeLatest(TOURNAMENTS_CREATE_BATTLES_FETCH, _CreateBattle);
    yield takeLatest(TOURNAMENTS_CREATE_TOURNAMENT_FETCH, _CreateTournament);
    yield takeLatest(TOURNAMENTS_UPDATE_INFO_FETCH, _UpdateTournament);
    yield takeLatest(SOCKET_EVENT_GAME_CANCEL_FETCH, _GameCanceled);
    yield takeLatest(SOCKET_EVENT_GAME_CANCEL, _BattlesGet);
    yield takeLatest(SOCKET_EVENT_GAME_CANCEL, _TournamentsGet);
    yield takeLatest(SOCKET_EVENT_GAME_CONFIRM_FETCH, _GameAccepted);
    yield takeLatest(USER_SET_DATA, _UserSet);
    yield takeLatest(PHONE_FETCH, _UserGetPhone);
    yield takeLatest(USER_PHONE_PUT, _UserPutPhone);
    yield takeLatest(USER_PHONE_GETCODE, _UserGetCodePhone);
    yield takeLatest(USER_PHONE_CHECKCODE, _UserCheckCodePhone);
    yield takeLatest(USER_EMAIL_CODE_FETCH, _UserSendCodeEmail);
    yield takeLatest(GEODATA_FETCH, _GetGeoData);
    yield takeLatest(USERGEODATA_FETCH, _GetUserCountry);
    yield takeLatest(DEFAULTTIMEZONE_FETCH, _GetDefaultTimezone);
    yield takeLatest(GEODATA_CITIES_FETCH, _GetCities);
    yield takeLatest(GEODATA_TIMEZONES_FETCH, _GetTimeZones);
    yield takeLatest(GAME_LEAVE, _GameLeave);
    yield takeLatest(SEND_PROTEST, _SendProtest);
    yield takeLatest(GAME_ACCEPT, _GameAcceptedResults);
    yield takeLatest(PROTEST_CANCEL, _SendProtestReport);
    yield takeLatest(TRANSLATIONS_FETCH, _GetTranslationsByLang);
    yield takeLeading(ACCESSED, onAccess);
    yield takeLatest(CHANGE_LANG, _ChangeLang);
    yield takeEvery(FETCH_VIDEO_ARCHIVES, _getVideoArchives);
    yield takeLatest(FETCH_VIDEO_ARCHIVES_ALL, fetchVideoArchivesAll);
    // yield takeEvery(FETCH_VIDEO_FILES, _getVideoFiles);
    // yield takeEvery(BIND_VIDEO_FILES, _bindVideoFiles);
    // yield takeEvery(UNBIND_VIDEO_FILES, _unbindVideoFiles);
    yield takeEvery(FETCH_USER_DISK, _getUserDisk);
    yield takeLatest(FETCH_CHAT_ROOM, _fetchChatRoom);
    yield takeLatest(FETCH_CHAT_ROOMS, _fetchChatRooms);
    yield takeLatest(UPDATE_MESSAGES_SEEN, _updateMessagesSeen);
    yield takeLatest(CREATE_PRIVATE_ROOM, _createPrivateRoom);
    yield takeLatest(CREATE_SUPPORT_ROOM, _createSupportRoom);
    yield takeLatest(RATE_USER_POST, _rateUserPost);
    yield takeLatest(GET_ALIKE_USER_FETCH, _AlikeUsersGet);
    yield takeLatest(GET_ALL_USERS_FETCH, _getAllUsers);
    // yield takeLatest(GET_ALL_BATTLES_FETCH, _getAllBattles);
    yield takeLatest(GET_BATTLES_LIST, _getBattlesList);
    yield takeEvery(EDIT_USER_AS_ADMIN, _EditUserAsAdmin);
    yield takeLatest(GET_TRANSACTION_DIFF, _getTransactionDiff);
    yield takeLatest(GET_TRANSACTION_HISTORY, _getTransactionHistory);
    yield takeLatest(GET_SYSTEM_ACCOUNTS, _getSystemAccounts);
    yield takeEvery(REQUEST_TRANSFER, _requestTransfer);
    yield takeLatest(GET_CURRENCY_LIST_FETCH, _CurrencyListGet);
    yield takeLatest(SEND_MANUAL_TRANSACTION, _ManualTransaction);
    yield takeLatest(UPDATE_USER_DATA, _UpdateUser);
    yield takeLatest(TOURNAMENTS_DELETE_FETCH, _TournamentDelete);
    yield takeLatest(WATCH_TOURNAMENT, _GetTournament);
    yield takeLatest(ADD_USER_TO_TOURNAMENT_FETCH, _addToTournament);
    yield takeLatest(DELETE_USER_FROM_TOURNAMENT_FETCH, _removeFromTournament);
    yield takeLatest(CREATE_BATTLE_IN_TOURNAMENT, _createBattleForTournament);
    yield takeEvery(GET_VIDEO_ARCHIVE, _getVideoArchive);
    yield takeLatest(PAYMENTDATA_FETCH, _encryptData);
    yield takeLatest(PAYMENTTOKEN_FETCH, _retrivePaymentTokenRequest);
    yield takeEvery(FETCH_PROTEST_LIST, _getProtestList);
    yield takeEvery(FETCH_PROTEST_LIST_OF_PARTY, _getProtestListOfParty);
    yield takeEvery(REFEREE_CONSIDER, _considerProtest);
    yield takeLatest(AGREEMENTS_FETCH, _GetAgreements);
    yield takeEvery(REFEREE_REFUSE, _refuseProtest);
    yield takeEvery(GET_PROTEST, _getProtest);
    yield takeEvery(ACCEPT_PROTEST, _closeProtest);
    yield takeEvery(REJECT_PROTEST, _closeProtest);
    yield takeEvery(GET_PROTOCOL, _getProtocol);
    yield takeEvery(MAKE_RESOLVE, _makeResolve);
    yield takeLatest(GAME_EXIT, _updateTournamentTable);
    yield takeLatest(CANCEL_TOURNAMENT_BATTLE, _cancelTournamentBattle);
    yield takeLatest(USERCARDS_FETCH, _GetCreditCards);
    yield takeEvery(USERCARDS_DEL, _DeleteCreditCard);
    yield takeEvery(IBAN_SAVE, _SaveIBAN);
    yield takeEvery(IBAN_DEL, _DeleteIBAN);
    yield takeEvery(CREATE_IBAN_PAYMENT, _createIBANpayment);
    yield takeLatest(BOWLING_CENTER_SEND_REQUEST, _sendBowlingCenterRequest);
    yield takeLatest(GET_PERMISSIONS, _UserGetPermissions);
    yield takeLatest(
      GET_BOWLINGCENTER_PERMISSIONS,
      _UserGetBowlingCenterPermissions
    );
    yield takeLatest(FETCH_WALLET_HISTORY, _GetWalletHistory);
    yield takeLatest(GET_IBAN_REQUESTS, _GetIBANrequests);
    yield takeEvery(UPDATE_IBAN, _UpdateIBANrequest);
    yield takeLatest(GET_TARIFF_MY, _GetUserTariff);
    yield takeLatest(GET_TARIFFS, _GetTariffs);
    yield takeLeading("GET_TARIFFS_OFF", getTariffsOffSaga);
    yield takeLatest(DROP_TARIFF, _DropTariff);
    yield takeEvery(SWITCH_TARIFF_RENEWAL, _SwitchTariff);
    yield takeLeading(SEND_AUTHORIZATION_CODE, _sendAuthorizationCode);
    yield takeLeading(SEND_APPLEID, _sendAppleAuth);
    yield takeLatest(SEND_VERIFICATION_CODE, _sendVerificationCode);
    yield takeLatest(GET_USER_SETTINGS, _getUserSettings);
    yield takeLatest(SET_USER_SETTING_VALUE, _setUserSettingValue);
    yield takeLatest(SET_USER_SETTING_CHILD_VALUE, _setUserSettingChildValue);
    yield takeLatest(GET_COMMISSION, _GetCommission);
    yield takeLatest(GET_BILLING_LOANS, _GetBillingLoans);
    yield takeLatest(SUPPORT_SEND_MESSAGE, _sendSupportMessage);
    yield takeLatest(FETCH_TIMESTAMP, _fetchTimestamp, api);
    yield takeLatest(ADD_MODAL, _onAddModal);
    yield takeLatest(CLOSE_MODAL, _onCloseModal);
    yield takeLatest(CHECK_LOGIN_STRING, _UserCheckLoginString);
    yield takeLatest(FETCH_GET_COOKIE_STATE, _cookieGet);
    yield takeLatest(FETCH_COOKIE_STATE, _cookieAgree);
    yield takeLatest(FETCH_USERSTATS, _getStats);
    yield takeLeading("FETCH_COOKIE_AGREEMENT", fetchCookieAgreement);
    yield takeLeading("SEND_COOKIE_AGREEMENT", sendCookieAgreement);
    yield takeLatest(USER_SEARCH_FETCH, _SearchUsers);
    yield takeEvery(SEND_FIREBASE_TOKEN, _sendFirebaseToken);
    yield takeLatest("FETCH_EMAIL_VERIFIED", _checkMailVerification);
    yield takeEvery("USER_UNREGISTER", _userUnregister);
    yield takeLatest(FETCH_JOIN_AS_OBSERVER, _joinAsObserver);
    yield takeLatest(FETCH_JOIN_AS_PLAYER, _joinAsPlayer);
    yield takeLatest(EDIT_TOURNAMENT_BATTLE, _editTournamentBattle);
    yield takeLatest(SEND_NOTIFICATION_UPDATE, _sendNotificationUpdate);
    // yield takeLatest(SQUAD_LIST_FETCH, _SquadListFetch);
    yield takeLatest(GET_SEARCH_USER_TARIFFS, _getSearchUserTariffs);
    yield takeLatest(SET_TARIFF_BY_USER, _setTariffByUser);
    yield takeLatest(FETCH_USER_DOCS, _getUserDocs);
    yield takeLatest(SEND_USER_DOCS, _sendUserDocs);
    yield takeLatest(GET_USER_DOCS_ALL, _getAllUserDocs);
    yield takeLatest(GET_USER_DOC_BY_ID, _getUserDocById);
    yield takeLatest(ACCEPT_DOC_VERIFICATION, _acceptDocVerification);
    yield takeLatest(REFUSE_DOC_VERIFICATION, _refuseDocVerification);
    yield takeLatest(FETCH_EXIT_OBSERVER, _exitGameObserver);
    yield takeLatest(SQUAD_GETINFO_FETCH, _GetGameInfo);
    yield takeLatest(SOCIAL_EMAIL_CODE_FETCH, _SocialSendCodeEmail);
    yield takeLatest(CHECK_SOCIAL_EMAIL_CODE_FETCH, _CheckSocialSendCodeEmail);
    yield takeLatest(TOURNAMENT_INFO_FOR_EDIT_FETCH, _GetTournamentInfoForEdit);
    yield takeLatest(FETCH_TOURNAMENT_TYPE_LIST, _getTournamentTypeList);
    yield takeLatest(MOBILE_VERSION_FETCH, _GetMobileVersion);
    yield takeLatest(SEND_NOTIFICATION_EVENT, _sendNotificationEvent);
    yield takeLatest(UPLOAD_MAIN_BANNERS, _uploadMainBanners);
    yield takeLatest(GET_ADMIN_DISK_SIZE, _getAdminDiskSize);
    yield takeLatest(FETCH_HELP_STATUS, _checkHelpWatched);
    yield takeLatest(CHANGE_HELP_STATUS, _changeHelpWatched);
    yield takeLatest(TOURNAMNET_BIND_BC_FETCH, _bindBowlingcenter);
    yield takeLatest(TOURNAMNET_UNBIND_BC_FETCH, _unbindBowlingcenter);
    yield takeLatest(GET_COUNT_USERS_REGISTERED, _GetCountUsersRegistered);
    yield takeLatest(GET_HANDICAP_LIST, _getHandicapList);
    yield takeLatest(GET_PAYPAL_ORDER, _GetPayPalOrder);
    yield takeLatest("PAYSERA_ORDER_REQUEST", payseraOrderRequestSaga);
    yield takeLatest("PAYSERA_WITHDRAW_REQUEST", payseraWithdrawRequestSaga);
    yield takeLatest(UPLOAD_POSTER, _uploadPoster);
    yield takeLatest(CREATE_PROTEST_ARCHIVE, _createProtestArchive);
    yield takeLatest(CANCEL_PROTEST_ARCHIVE, _cancelProtestArchive);
    yield takeLatest(REPLY_PROTEST_ARCHIVE, _replyProtestArchive);
    yield takeLatest(CONNECT_TO_SOCKET, _socketSaga);
    yield takeLatest(TOURNAMENT_GAME_ENDED, _tournamentGameEnded);
    yield takeLatest(GET_VIDEO_ARCHIVE_PROTESTS, _getVideoArchiveProtest);
    yield takeLatest(CREATE_QUICK_GAME, _createQuickGame);
    yield takeLatest(CREATE_OPEN_CHALLENGE, _createOpenChallenge);
    yield takeLatest(
      UPDATE_QUICK_GAME_TO_OPEN_CHALLENGE,
      _updateQuickGameToOpenChallenge
    );
    yield takeLatest(CONFIRM_TOURNAMENT_RESULTS, _confirmTournamentResults);
    yield takeLatest(SET_USER_TYPE, _SetUserType);
    yield takeLatest(ADD_TOURNAMENT_SETTING, _AddTournamentSetting);
    yield takeLatest(
      SEND_INVITE_TOURNAMENT_NOTIFICATION,
      _SendInviteTournamentNotification
    );
    yield takeLatest(DELETE_TOURNAMENT_SETTING_FETCH, _DeleteTournamentSetting);
    yield takeLatest(SAVE_HANDICAP_LIST, _saveHandicapList);
    yield takeLatest(FETCH_UPLOAD_APK, _uploadApkFile);
    yield takeLatest(ADD_REPLAY_PLAYER, _addReplayToPlayer);
    yield takeLatest(CREATE_LEADERBOARD_TABLE_STAGE, _createLeaderboardStage);
    yield takeLatest(DELETE_LEADERBOARD_TABLE_STAGE, _deleteLeaderboardStage);
    yield takeLatest(SAVE_LEADERBOARD_TABLE_STAGE, _saveLeaderboardStage);
    yield takeLatest(REFRESH_TOURNAMENT_INFO, _tournamentInfoRefresh);
    yield takeLatest(CHANGE_KEYBOARD_TYPE, _ChangeKeyboardType);
    yield takeLatest(
      UPLOAD_CARD_DECORATION_FETCH,
      _uploadTournamentCardDecoration
    );
    yield takeLatest(GET_ACTIVE_HDC, _getActiveHDC);
    yield takeLatest(FETCH_ACTIVE_HDC, _setActiveHDC);
    yield takeLatest(GET_LEADER_BOARD, _getLeaderBoard);
    yield takeLatest(GET_ACTIVE_LANGS, _getActiveLangs);

    yield takeLatest("PAYSERA_REQUESTS_REQUEST", payseraRequestsRequestSaga);
    yield takeLatest(
      "PAYSERA_REQUESTS_UPDATE_REQUEST",
      payseraRequestsUpdateRequestSaga
    );

    yield takeLatest(GET_FLUSSONIC_API, _getFlussonicApi);
    yield takeLatest(GET_ACTIVE_USERS, _getActiveUsers);
    yield takeLatest(GET_MAX_WATCHER_COUNT, _getMaxWatcherCount);
    yield takeLatest(SAVE_MAX_WATCHER_COUNT, _saveMaxWatcherCount);

    yield takeLatest(UPDATE_GAME_COUNT_FETCH, _updateGameCount);

    yield takeLatest(SET_VISIBILITY_BATTLE, _setVisibilityBattle);

    yield takeLatest(GET_STATISTIC_MAIN, _getStatisticMain);
    yield takeLatest(GET_STATISTIC_AVERAGE, _getStatisticAverage);
    yield takeLatest(GET_STATISTIC_HIGH, _getStatisticHigh);
    yield takeLatest(GET_STATISTIC_CLEAN, _getStatisticClean);
    yield takeLatest(GET_STATISTIC_SERIES, _getStatisticSeries);

    yield takeLatest(GET_STATISTIC_FRAMES_MAIN, _getStatisticFramesMain);
    yield takeLatest(GET_STATISTIC_FRAMES_FIRST, _getStatisticFramesFirst);
    yield takeLatest(GET_STATISTIC_FRAMES_AVERAGE, _getStatisticFramesAverage);
    yield takeLatest(GET_STATISTIC_FRAMES_STRIKES, _getStatisticFramesStrikes);
    yield takeLatest(GET_STATISTIC_FRAMES_SPLITS, _getStatisticFramesSplits);

    yield takeLeading(CHECK_EXPECTED_GAMES, _checkExpectedGames);
    yield takeLeading(CHECK_EXPECTED_GAMES, _checkExpectedGames);

    yield takeLatest(GET_BC_NOT_PLATFORM, _getBcNotPlatform);
    yield takeLatest(CREATE_GAME_NOT_PLATFORM, _createGameNonPlatform);

    yield takeLatest(GET_SQUAD_ID_BY_USER_ID, _getSquadIdByUserId);
    yield takeLatest(TOGGLE_STREAM_FETCH, _toggleStream);
    yield takeLatest(SET_ANALYTICS_BOWLING_CENTER, _getAnalyticsBowlingCenter);
    yield takeLatest(GET_REFERRAL_LINK, _getReferral);

    yield takeLatest(UPLOAD_BC_ADMIN_BANNERS, _uploadBCBanners);
    yield takeLatest(GE_BC_ADMIN_BANNERS, _getBCBanners);
    yield takeLatest(PUBLICATION_BC_BANNER, _publicationBCBanners);
    yield takeLatest(GET_SUBSCRIBERS, _getSubscribers);
    yield takeLatest(SUBSCRIBERS_CHANGE_PAGE, _getSubscribers);
    yield takeLatest(SUBSCRIBERS_CHANGE_TARIF_FETCH, _changeTariffFetch);
  } catch (error) {
    bugsnagClient.notify(error, { context: "saga WatchActions" });
    console.error("===WatchAction Error: ", error);
  }
}

export const delay = (timeout = 1000) =>
  new Promise((resolve) => setTimeout(resolve, timeout));

export function* _getHttpServerStatus(api) {
  try {
    const result = yield call(api.post, {
      url: `${config.FETCH.url}/ping`,
      data: {},
      timeout: 15000,
    });
    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_HTTP_SERVER_STATUS, payload: "active" });
    } else {
      yield put({ type: SET_HTTP_SERVER_STATUS, payload: "inactive" });
    }
  } catch (error) {
    bugsnagClient.notify(error);
    console.error("[sagaPing] error: ", error);
    yield put({ type: SET_HTTP_SERVER_STATUS, payload: "inactive" });
  }
}

export function* sagaHttpServerStatus() {
  while (true) {
    yield put({ type: GET_HTTP_SERVER_STATUS });
    yield call(_getHttpServerStatus, api);
    yield call(delay, 15000);
  }
}

export function* _getFlussonicApi() {
  try {
    const payload = yield call(get, {
      url: `${config.FETCH.url}/flussonic/getURL`,
    });
    if (payload.data.success === 1) {
      yield put({ type: SET_FLUSSONIC_API, params: payload.data.url });
    } else {
      bugsnagClient.notify(`Get Flussonic Api error`);
    }
  } catch (error) {
    bugsnagClient.notify(error);
  }
}

export function* sagaInit(store) {
  try {
    yield all([
      _waitPersist(),
      sagaHttpServerStatus(),
      watchReglament(),
      watchTenpinToolkitSaga(),
      watchRate(),
      watchGameInvite(),
      watchCookies(store),
      _WatchActions(store),
      _WatchSquads(store),
      _WatchNotifications(store),
      _WatchCommon(store),
      _WatchFriends(store),
      _WatchBowlingCenters(store),
      _WatchBattles(store),
      _WatchVideoFiles(store),
      watchUserDeletion(),
      keyboardSaga(),
      watchUserPoolSaga(),
    ]);
  } catch (error) {
    console.error("Saga init failed: ", error);
  }
}
