/* eslint-disable react/prop-types */
/* eslint-disable arrow-parens */
import React from 'react';
import { injectIntl } from 'react-intl';
import OBModal from '../Modal';
import ModalFooter from './fragments/modal-footer.fragment.jsx';

/**
 * @typedef {{
 *  isVisible?: boolean;
 *  onConfirm?: () => any;
 *  onReject?: () => any;
 *  title?: string;
 * }} ConfirmationModalProps
 */

const BUTTONS = Object.freeze({
  NO: (intl) => intl.formatMessage({ id: 'No', defaultMessage: 'No' }),
  YES: (intl) => intl.formatMessage({ id: 'Yes', defaultMessage: 'Yes' }),
});
const noop = () => null;
/** @type {React.FC<ConfirmationModalProps>} */
const ConfirmationModalFn = (props) => {
  const {
    isVisible, title, onConfirm, onReject, intl,
  } = props;
  const buttons = [BUTTONS.NO(intl), BUTTONS.YES(intl)];
  const content = intl.formatMessage({ id: 'AreYouSureToCancel', defaultMessage: 'Are you sure you want to cancel without saving?' });
  return <OBModal
    title={title}
    visible={isVisible}
    onSubmit={noop}
    onCancel={onReject}
    footer={[
      <ModalFooter
        key={'footer'}
        onCancel={onReject}
        onAccept={onConfirm}
        intl={intl}
        buttons={buttons}
      />,
    ]}
  >
    <div className="confirmation-modal-content">{content}</div>
  </OBModal>;
};

class ConfirmationModalClass extends React.Component {
  render() {
    return <ConfirmationModalFn {...this.props} />;
  }
}

/** @type {ConfirmationModalFn} */
const ConfirmationModal = injectIntl(ConfirmationModalClass);

export default ConfirmationModal;
