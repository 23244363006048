import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import config from '../../config';
import { encodePaymentData } from '../../libs/reducers/actions';

class PaymentForm extends Component {
    constructor(props) {
        super(props);

        this.form = React.createRef();
    }

    static propTypes = {
        orderId: PropTypes.string,
        orderAmount: PropTypes.number,
        notification: PropTypes.string,
        is3d: PropTypes.bool,
        descriptor: PropTypes.string,
        description: PropTypes.string,
        accountid: PropTypes.string,
        iterator: PropTypes.bool,
    };

    buildFormData() {
        this.props.encodePaymentData({
            mode: this.props.is3d && !config.Medoro.non3d?5:3,
            descriptor: this.props.descriptor,
            orderAmount: this.props.orderAmount,
            description: this.props.description,
            notification: this.props.notification,
            accountid: this.props.accountid,
            tokenid: this.props.tokenid,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.cryptedData !== this.props.cryptedData && this.form.current &&
            this.props.cryptedData.DATA && this.props.cryptedData.KEY && this.props.cryptedData.SIGNATURE) {
            this.form.current.submit();
        }
        if (prevProps.iterator !== this.props.iterator && this.props.iterator) {
            this.buildFormData();
        }
    }

    render() {
        return (
            <>
            <form action={config.Medoro.url} method="post" ref={this.form}>
                <input type="hidden" name="INTERFACE" value={config.Medoro.MID} />
                <input type="hidden" name="KEY_INDEX" value={config.Medoro.KEYINDEX} />
                <input type="hidden" name="KEY" value={this.props.cryptedData.KEY} />
                <input type="hidden" name="DATA" value={this.props.cryptedData.DATA} />
                <input type="hidden" name="SIGNATURE" value={this.props.cryptedData.SIGNATURE} />
                <input type="hidden" name="CALLBACK" value={`${config.FETCH.url}/ecom/ecom_success`} />
                <input type="hidden" name="ERROR_CALLBACK" value={`${config.FETCH.url}/ecom/ecom_failure`} />
            </form>
            </>
        )}
}

export default connect(
    state => ({
        cryptedData: state.billing.cryptedData,
    }),
    {encodePaymentData},
)(PaymentForm);
