import {call, put, select} from 'redux-saga/effects';
import bugsnagClient from '../../bugsnagClient';
import config from '../../config';
import {get, post, put as putMethod} from '../Networking';
import { confirmTournamentResultsFailureAction, confirmTournamentResultsRequestAction, confirmTournamentResultsSuccessAction } from '../reducers/actions';
import { gameInviteRequestAction } from '../reducers/gameInvite.reducks';
import { premiumTournamentAccessRestrictedAction } from '../reducers/modalPremium.reducks';
import {_BattlesBattleInvite, _BattlesGet, _OpenChallengesGet} from './Battles/battles.handler';



const {
  ACCOUNT_TOURNAMENTS_SET,
  TOURNAMENTS_SET,
  TOURNAMETS_SET_PLAYERS,
  TOURNAMENTS_FETCH,
  TOURNAMENTS_FETCHING,
  SOCKET_EVENT_GAME_CANCEL,
  UPDATE_USER_DATA,
  TOURNAMENTS_DELETE_SET,
  TOURNAMENTS_CREATE_TOURNAMENT_SET,
  TOURNAMENTS_UPDATE_INFO_SET,
  TOURNAMENTINFO_FETCH,
  TOURNAMENTINFO_SET,
  UPDATE_TOURNAMENT_PLAYERS,
  WATCH_TOURNAMENT,
  TOURNAMENTS_BATTLE_LOADING,
  SET_CODE_ERROR,
  SOCKET_EVENT_GAME_INVITE,
  DELETE_GAME_TOURNAMENT,
  SHOW_NOFUND,
  ADD_BATTLE_TO_TOURNAMENT,
  CANCEL_BATTLE_TOURNAMENT,
  TOURNAMENT_INFO_FOR_EDIT_FETCH,
  TOURNAMENT_INFO_FOR_EDIT_SET,
  SET_GAME_CANCEL_SOCKET,
  SET_TOURNAMENT_TYPE_LIST,
  TOURNAMNET_BIND_END,
  TOURNAMNET_UNBIND_END,
  SET_PLAYER_REPLAY,
  SET_LEADERBOARD_TABLE_STAGES,
  TOURNAMENTS_BATTLE_TAKEPART_FINISH,
  REFRESH_TOURNAMENT_INFO,
  UPLOAD_CARD_DECORATION,
  SET_VISIBILITY_BATTLE_FETCHED,
  UPDATE_GAME_COUNT_FETCHED,
  SEND_INVITE_TOURNAMENT_NOTIFICATION_FETCHED,
  GET_SQUAD_ID_BY_USER_ID_FETCHED,
  GAME_LEAVE,
  SET_BC_NOT_PLATFORM_FETCHED,
  CLEAR_EVENT_SQUAD_DATA,
  SOCKET_EVENT_GAME_START,
  GET_BC_NOT_PLATFORM,
} = require('../reducers/actiontypes').default;
const { COMMON_ERROR, COMMON_NOTIFICATION_PARAMS } = require('../reducers/commonReducer')

const types = require('../reducers/actiontypes').default;

const getTournaments = state => state.tournaments.tournaments;
const getObservable = state => state.tournaments.tournamentId;
const getTournamentInfo = state => state.tournaments.tournamentInfo;

export function* _editTournamentBattle({ params }) {
  try {
    const result = yield call(post, { url: `${config.FETCH.url}/tournament/editbattle`, data: params });
    if (result.status === 200 & result.data.success) {
      yield put({ type: WATCH_TOURNAMENT, params: yield select(getObservable) });
    }
  } catch (error) {
    bugsnagClient.notify(error, {
      context: 'Cancel tournament battle failed',
      beforeSend: (report) => {
        report.updateMetadata('tournament', { ...params });
      },
    });
    console.error('Cancel tournament battle failed: ', error);
  }
}

export function * _SendInviteTournamentNotification({params}) {
  try {
    const result = yield call(post,{ url: `${config.FETCH.url}/tournament/invite_notification`, data: params,});
    if (result.status === 200 && result.data.success === 1) {
      yield put({type: SEND_INVITE_TOURNAMENT_NOTIFICATION_FETCHED, params: result.data.tournamentInfo})
    } else {
      console.error('_SendInviteTournamentNotification error: ', result.data);
    }

  } catch (error) {
    bugsnagClient.notify(error, {
      context: 'Send push notification failed',
      beforeSend: (report) => {
        report.updateMetadata('tournament', { ...params });
      },
    });
    console.error('Send push notification failed error: ', error);
  }
}
export function* _cancelTournamentBattle({ params }) {
  try {
    const result = yield call(post, { url: `${config.FETCH.url}/tournament/delbattle`, data: params });
    if (result.status === 200 & result.data.success) {
      if (params.tournamenttypeid && params.tournamenttypeid === 2) {
        yield put({ type: CANCEL_BATTLE_TOURNAMENT, params })
        const tournamentid = yield select(getObservable)
        const tournament = yield call(fetchTournament, tournamentid)
        if (tournament) {
          yield put({ type: TOURNAMENTINFO_SET, params: tournament })
        }
      } else {
        yield put({ type: WATCH_TOURNAMENT, params: yield select(getObservable) });
      }
    }
  } catch (error) {
    bugsnagClient.notify(error, {
      context: 'Cancel tournament battle failed',
      beforeSend: (report) => {
        report.updateMetadata('tournament', { ...params });
      },
    });
    console.error('Cancel tournament battle failed: ', error);
  }
}

/**
 * @param {number} tournamentid
 */
export async function fetchTournament(tournamentid) {
  const result = await get({ url: `${config.FETCH.url}/tournament/getbattles/${tournamentid}` })
  if (result.status === 200 && result.data.success === 1) {
    const { tournament } = result.data;
    tournament.battles = tournament.battles.filter(b => !b.deleted)
    return tournament
  }
  return null
}

export function* _GetTournament({ params }) {
  yield put({ type: TOURNAMENTINFO_FETCH })
  try {
    if (!params) {
      yield put({ type: TOURNAMENTINFO_SET, params: false })
      return
    }
    const tournament = yield call(fetchTournament, params)
    if (tournament) {
      yield put({ type: TOURNAMENTINFO_SET, params: tournament })
    } else {
      yield put({ type: TOURNAMENTINFO_SET, params: false })
    }
  } catch (error) {
    bugsnagClient.notify(error, {
      context: 'TournamentInfo get',
      beforeSend: (report) => {
        report.updateMetadata('tournament', { id: params });
      },
    });
    console.error('TournamentInfo get: ', error);
  }
}

export function* _GetTournamentInfoForEdit({ params }) {
  try {
    if (!params) {
      yield put({ type: TOURNAMENT_INFO_FOR_EDIT_SET, params: null });
    }
    const result = yield call(get, { url: `${config.FETCH.url}/tournament/getforeditinfo/${params}` });
    if (result.status === 200 && result.data.success === 1) {
      yield put({ type: TOURNAMENT_INFO_FOR_EDIT_SET, params: result.data.tournament })
    } else {
      yield put({ type: TOURNAMENT_INFO_FOR_EDIT_SET, params: null })
    }
  } catch (error) {
    bugsnagClient.notify(error, {
      context: 'TournamentInfoForEdit get',
      beforeSend: (report) => {
        report.updateMetadata('tournament', { id: params });
      },
    });
    console.error('TournamentInfoForEdit get: ', error);
  }
}

export function* _TournamentsGet() {
  yield put({ type: TOURNAMENTS_FETCHING, params: true });
  try {
    const result = yield call(post, { url: `${config.FETCH.url}/gettournaments` });
    if (result.status === 200 && result.data.success === 1) {
      yield put({ type: TOURNAMENTS_SET, params: result.data.data });
    }

    // if (typeof document !== 'undefined') {
    //   // проверяем есть ли открытые баттлы в турнирах
    //   // только для веба
    //   const squadNotClosed = yield call(post, { url: `${config.FETCH.url}/tournament/checking_open` });
    //   const gameCancelSocket = squadNotClosed.data.hasOwnProperty('result') ? squadNotClosed.data.result : false;
    //   yield put({ type: SET_GAME_CANCEL_SOCKET, params: gameCancelSocket });
    // }
  } catch (err) {
    bugsnagClient.notify(err, { context: 'Tournaments get' });
    console.error('Tournaments get: ', err);
  } finally {
    yield put({ type: TOURNAMENTS_FETCHING, params: false });
  }
}

export function* _TakePartTournament(params) {
  try {
    const ignoreError = true;
    const result = yield call(putMethod, {
      url: `${config.FETCH.url}/battle/join`,
      data: { tournamentid: params.params.tournamentId, squadid: params.params.squadId},
    }, ignoreError);
    if (result.status === 200 && result.data.success) {
      yield put({ type: TOURNAMENTS_BATTLE_LOADING, params: { id: params.params, loading: false } });
      let tournaments = yield select(getTournaments);
      tournaments = tournaments.map((elem) => {
        const newElem = elem;
        if (elem.id === result.data.data.tournamentid) {
          newElem.players = result.data.data.players;
        }
        return newElem;
      });
      // TODO: объединить запросы
      yield put({ type: TOURNAMETS_SET_PLAYERS, params: tournaments }, ignoreError);
      yield call(_BattlesGet);
      yield put({ type: UPDATE_USER_DATA });
    } else {
      if (result.data.errorcode) {
        const { errorcode: code , errordata: data } = result.data;
        yield put({ type: SET_CODE_ERROR, payload: { code , data } }); // this action opens global modal
      } else {
        throw new Error(result.data.error);
      }
      yield put({ type: TOURNAMENTS_BATTLE_LOADING, params: { id: params.params, loading: false } });
    }
  } catch (error) {
    yield put({ type: TOURNAMENTS_BATTLE_LOADING, params: { id: params.params, loading: false } });
    bugsnagClient.notify(error, {
      context: 'TakePartTournament',
      beforeSend: (report) => {
        if (report && typeof report.updateMetadata === 'function') {
          report.updateMetadata('tournament', { id: params && params.params });
        }
      },
    });
    console.error('TakePartTournament: ', error);
    yield call(_BattlesGet);
    yield call(_TournamentsGet);
  } finally {
    yield put({ type: TOURNAMENTS_BATTLE_TAKEPART_FINISH });
  }
}

export function* _TournamentDelete({ params }) {
  console.log('tournament delete')
  try {
    const result = yield call(post, { url: `${config.FETCH.url}/tournament/delete`, data: params });

    if (result.status === 200 & result.data.success) {
      yield put({ type: TOURNAMENTS_DELETE_SET, params: result.data });
      yield call(_TournamentsGet);
    } else {
      yield put({ type: TOURNAMENTS_DELETE_SET });
    }
  } catch (error) {
    bugsnagClient.notify(error, {
      context: 'Tournament delete',
      beforeSend: (report) => {
        report.updateMetadata('tournament', { id: params });
      },
    });
    console.error('Tournament delete: ', error);
  }
}

export function* _CreateTournament({ params }) {
  try {
    const result = yield call(post, { url: `${config.FETCH.url}/tournament/create`, data: params });
    if (result.status === 200 & result.data.success) {
      yield put({ type: TOURNAMENTS_CREATE_TOURNAMENT_SET, params: result.data });
      yield call(_TournamentsGet);
    }
  } catch (error) {
    bugsnagClient.notify(error, {
      context: 'Create tournament failed',
      beforeSend: (report) => {
        const data = Array.isArray(params) ? [...params] : (typeof params === 'object' ? { ...params } : params);
        report.updateMetadata('tournament', data);
      },
    });
    console.error('Create tournament failed: ', error);
  }
}

export function* _UpdateTournament({ params }) {
  try {
    const result = yield call(post, { url: `${config.FETCH.url}/tournament/update`, data: params });
    if (result.status === 200 & result.data.success) {
      yield put({ type: TOURNAMENTS_UPDATE_INFO_SET, params: result.data });
      yield call(_TournamentsGet);
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'Create tournament failed' });
    console.error('Create tournament failed: ', error);
  }
}

export function* _CreateBattle({ params }) {
  const sendParams = {
    url: `${config.FETCH.url}/battle`,
    data: params,
  };
  try {
    const result = yield call(post, sendParams);
    if (result && result.status === 200 && result.data.success) {
      // TODO: объединить запросы
      yield call(_TournamentsGet);
      yield call(_BattlesGet);

      if (params.userid) {
        const inviteResult = yield call(_BattlesBattleInvite, {
          params: {
            squadid: result.data.data.squadid,
            tournamentid: result.data.data.tournamentid,
            userid: params.userid,
          },
        });
        console.log('_CreateBattle invite result: ', inviteResult);
        if (inviteResult && (inviteResult.status !== 200 || !inviteResult.data.success)) {
          bugsnagClient.notify(inviteResult.error, { context: 'Create Battle' });
          console.error('Create Battle: ', inviteResult.error);
        }
      } else {
        // Если баттл не приватный, то, в зависимости от разрешений, отправляем push и уведомление
        // const resultSend = yield call(post, {
        //   url: `${config.FETCH.url}/battle/push`,
        // });
        // if (resultSend && (resultSend.status !== 200 || !resultSend.data.success)) {
        //   bugsnagClient.notify(resultSend.error, { context: 'Send Push Battle' });
        //   console.error('Send Push Battle: ', resultSend.error);
        // }
      }
      yield put({ type: UPDATE_USER_DATA });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'Create Battle' });
    console.error('Create Battle: ', error);
  }
}

export function* _GameCanceled({ params: { tournamentId, squadid, closeModal = true } }) {
  try {
    const sendParams = {
      url: `${config.FETCH.url}/battle/confirm`,
      data: { tournamentid: tournamentId, squadid, confirmed: -1 },
    };
    const result = yield call(putMethod, sendParams);
    if (closeModal && result.status === 200 && !result.data.success) {
      console.log('closing modal');
      // TODO: объединить запросы
      yield call(_BattlesGet);
      yield call(_TournamentsGet);
    }
    const tournamentInfo = yield select(getTournamentInfo);
    if (tournamentInfo && tournamentInfo.id === tournamentId) {
      yield put({ type: DELETE_GAME_TOURNAMENT, params: { squadid } })
    }
    yield put({ type: UPDATE_USER_DATA });
  } catch (error) {
    bugsnagClient.notify(error, { context: 'Game canceled' });
    console.error('Game canceled: ', error);
  } finally {
    yield put({ type: SOCKET_EVENT_GAME_CANCEL, params: {} });
  }
}

export function* _GameAccepted({ params }) {
  try {
    const {
      linenumber,
      name,
      city,
      squadid,
      tournamentid,
      bowlingcenterid,
      observers,
    } = params;
    const sendParams = {
      url: `${config.FETCH.url}/battle/confirm`,
      data: {
        tournamentid,
        bowlingcenterid,
        linenumber,
        name,
        city,
        squadid,
        confirmed: 1,
        observers,
      },
    };
    const ignoreError = true;
    yield put({ type: types.ACCEPTING_GAME_START });
    const result = yield call(putMethod, sendParams, ignoreError);
    if (result.status === 200 && !result.data.success) {
      if (result.data.errorcode) {
        const { errorcode: code , errordata: data } = result.data;
        yield put({ type: SET_CODE_ERROR, payload: { code , data } }); // this action opens global modal
        yield put({ type: types.SET_GAME_START_ACCEPTING_ERROR, payload: { code, data } });
      } else {
        yield put({ type: types.SET_GAME_START_ACCEPTING_COMPLETE });
        yield put({ type: SOCKET_EVENT_GAME_CANCEL, params: result.data.data });
      }
      // TODO: объединить запросы
      yield call(_BattlesGet);
      yield call(_TournamentsGet);
    } else if (result.status === 200 && result.data.success) {
      yield put({ type: types.SET_GAME_START_ACCEPTING_COMPLETE });
    }
    yield put({ type: UPDATE_USER_DATA });
  } catch (error) {
    yield put({ type: types.SET_GAME_START_ACCEPTING_COMPLETE });
    bugsnagClient.notify(error, { context: 'Game accepted' });
    console.error('Game accepted: ', error);
  }
}

export function* _AccountTournamentsGet() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/tournament/my` });
    if (result.status === 200 && result.data.success === 1) {
      yield put({ type: ACCOUNT_TOURNAMENTS_SET, params: result.data.data });
    }
  } catch (error) {
    bugsnagClient.notify(error, {
      context: 'AccountTournamentsGet',
      beforeSend: (report) => {
        report.updateMetadata('response', error.response.data)
      },
    });
    console.log('AccountBattles: ', error.response.data);
  }
}

export function* _addToTournament({ params }) {
  try {
    const result = yield call(post, { url: `${config.FETCH.url}/tournament/adduser`, data: params }, true);
    if (params.refetch) yield put({ type: TOURNAMENTS_FETCH })
    if (result.status === 200 & result.data.success) {
      const playerId = result.data.playerId;
      params.add = 1;
      yield put({ type: UPDATE_TOURNAMENT_PLAYERS, params: { ...params, playerId } });
      const leaderboardStagesResult = yield call(
        post,
        { url: `${config.FETCH.url}/tournament/getLeaderboardStage`, data: params
        });
      const { data } = leaderboardStagesResult;
      if (data.success && data.data.length) {
        yield put({ type: SET_LEADERBOARD_TABLE_STAGES, params: data.data });
      }
    } else {
      if (result.data.error === 'ageLimit') {
        yield put({ type: COMMON_NOTIFICATION_PARAMS, params: {
            messageType: result.data.error,
          }});
      } else {
        yield put({ type: COMMON_ERROR, data: result.data.error });
      }
    }
  } catch (error) {
    bugsnagClient.notify(error, {
      context: 'Add user to tournament failed',
      beforeSend: (report) => {
        report.updateMetadata('payload', params);
      },
    });
    console.error('Add user to tournament failed: ', error);
  }
}

export function* _removeFromTournament({ params }) {
  try {
    const result = yield call(post, { url: `${config.FETCH.url}/tournament/removeuser`, data: params });
    if (params.refetch) yield put({ type: TOURNAMENTS_FETCH })
    if (result.status === 200 & result.data.success) {
      params.add = 0;
      yield put({ type: UPDATE_TOURNAMENT_PLAYERS, params });
      const leaderboardStagesResult = yield call(
        post,
        { url: `${config.FETCH.url}/tournament/getLeaderboardStage`, data: params
        });
      const { data } = leaderboardStagesResult;
      if (data.success && data.data.length) {
        yield put({ type: SET_LEADERBOARD_TABLE_STAGES, params: data.data });
      }
    }
  } catch (error) {
    bugsnagClient.notify(error, {
      context: 'Remove user from tournament failed',
      beforeSend: (report) => {
        report.updateMetadata('payload', params);
      },
    });
    console.error('Add user to tournament failed: ', error);
  }
}

export function* _createOpenChallenge(action) {
  const { params } = action;
  try {
    const result = yield call(post, { url: `${config.FETCH.url}/tournament/createOpenChallenge`, data: params });
    if (result.status === 200 && result.data.success) {
      yield call(_TournamentsGet);
      yield call(_BattlesGet);
      yield call(_OpenChallengesGet);
      yield put({ type: UPDATE_USER_DATA });
    }
  } catch (error) {
    bugsnagClient.notify(error, {
      context: 'Failed to create Open Challange',
      beforeSend: (report) => {
        report.updateMetadata('payload', params);
      },
    });
    console.error('Failed to Open Challange: ', error);
  }
}

export function* _createQuickGame(action) {
  const { params } = action;
  try {
    const quickGameResult = yield call(post, { url: `${config.FETCH.url}/tournament/createQuickGame`, data: params });
    yield put({type: CLEAR_EVENT_SQUAD_DATA})
    if (quickGameResult.status === 200 && quickGameResult.data.success) {
      const gameInviteAction = { type: SOCKET_EVENT_GAME_INVITE, params: quickGameResult.data.newBattle };
      yield put(gameInviteRequestAction(gameInviteAction))
    }
  } catch (error) {
    bugsnagClient.notify(error, {
      context: 'Failed to create Quick Game',
      beforeSend: (report) => {
        report.updateMetadata('payload', params);
      },
    });
    console.error('Failed to Quick Game: ', error);
  }
}

export function* _updateQuickGameToOpenChallenge({ params }) {
  try {
    const result = yield call(post, { url: `${config.FETCH.url}/tournament/updateQuickGame`, data: params });
    if (result.status === 200 && result.data.success) {
      yield call(_TournamentsGet);
      yield call(_BattlesGet);
      yield call(_OpenChallengesGet);
      yield put({ type: UPDATE_USER_DATA });
    }
  } catch (error) {
    bugsnagClient.notify(error, {
      context: 'Update Quick Game to Open Challenge',
      beforeSend: (report) => {
        report.updateMetadata('payload', params);
      },
    });
    console.error('Failed to update Quick Game to Open Challenge: ', error);
  }
}

export function* _updateGameCount({ params }) {
  try {
    const result = yield call(post, { url: `${config.FETCH.url}/tournament/updateQuickGameCount`, data: params });
    if (result.status === 200 && result.data.success) {
      yield put({ type: UPDATE_GAME_COUNT_FETCHED });
    }
  } catch (error) {
    console.error('Failed to update Quick Game Games Count: ', error);
  }
}

export function* _createBattleForTournament({ params }) {
  try {
    let ignoreError = false;
    if (params.tournamenttypeid === 3) {
      ignoreError = true;
    }
    // if ([3, 5].includes(params.tournamenttypeid)) {
    //   const squadNotClosed = yield  call(post, { url: `${config.FETCH.url}/tournament/checking_open` });
    //   if (squadNotClosed.data.error) {
    //     console.error(squadNotClosed.error);
    //     return;
    //   }
    //   const result = squadNotClosed.data.result;
    //   if (result.limitExist) {
    //     yield put({ type: COMMON_NOTIFICATION_PARAMS, params: {
    //         messageType: result.messageType,
    //         params: {
    //           tournamentName: result.tournamentName,
    //           squadDateStart: result.dateStart,
    //         }
    //       }});
    //     return;
    //   }
    // }

    const tournament = yield call(fetchTournament, params.tournamentid)
    if (tournament && tournament.pro_tariff) {
      const userTariff = yield select(state => state.billing.usertariff)
      if (!userTariff || userTariff.name.toLowerCase() !== 'premium') {
        yield put(premiumTournamentAccessRestrictedAction())
        return;
      }
    }

    const result = yield call(post, { url: `${config.FETCH.url}/tournament/newbattle`, data: params }, ignoreError);
    if (result.status === 200 && result.data.success) {
      if (!params.tournamenttypeid || [3, 5].includes(params.tournamenttypeid)) yield put(gameInviteRequestAction({ type: SOCKET_EVENT_GAME_INVITE, params: result.data.newBattle }));
      if (params.tournamenttypeid && params.tournamenttypeid === 2 && result.data.newBattle) {
        yield put({ type: ADD_BATTLE_TO_TOURNAMENT, params: result.data.newBattle });
      } else {
        yield put({ type: WATCH_TOURNAMENT, params: yield select(getObservable) });
      }
    }
    if (!result.data.success && (params.tournamenttypeid === 3) && result.data.error === 'failed_transaction') {
      yield put({ type: SHOW_NOFUND });
    } else if (!result.data.success && result.data.error === 'premium_tournament_access_restricted') {
      yield put(premiumTournamentAccessRestrictedAction())
    } else {
      yield put({ type: COMMON_ERROR, data: result.data.error });
    }
  } catch (error) {
    bugsnagClient.notify(error, {
      context: 'Failed to create tournament',
      beforeSend: (report) => {
        report.updateMetadata('payload', params);
      },
    });
    console.error('Failed to create tournament: ', error);
  }
}

export function* _updateTournamentTable() {
  yield put({ type: WATCH_TOURNAMENT, params: yield select(getObservable) });
}

export function* _getTournamentTypeList() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/tournamenttypes` });
    if (result.status !== 200 || !result.data.success) {
      yield null;
    }

    const { tournamentTypeList } = result.data;
    yield put({ type: SET_TOURNAMENT_TYPE_LIST, params: tournamentTypeList });
  } catch (error) {
    console.error('Get tournament type list error');
  }
}

export function* _bindBowlingcenter({params}) {
  try {
    const result = yield call(post, { url: `${config.FETCH.url}/tournament/bind_bc`, data: params });
    if (result.status === 200) {
      yield put({ type: TOURNAMNET_BIND_END, params: result.data.data.bowlingcenter });
    }
  } catch (error) {
    console.error('Bind bowlingcenter error');
  }
}

export function* _unbindBowlingcenter({params}) {
  try {
    const result = yield call(post, { url: `${config.FETCH.url}/tournament/unbind_bc`, data: params });

    if (result.status === 200) {
      yield put({ type: TOURNAMNET_UNBIND_END, params });
    }
  } catch (error) {
    console.error('Unbind bowlingcenter error');
  }
}

export function* _tournamentGameEnded({ params }) {
  const { tournament_id } = params;
  yield put({ type: REFRESH_TOURNAMENT_INFO, params: { tournamentid: tournament_id }});
}

export function* _tournamentInfoRefresh({ params }) {
  const { tournamentid } = params;
  const currentTournamentId = yield select(state => state.tournaments.tournamentInfo && state.tournaments.tournamentInfo.id) || null;

  if (+tournamentid && +currentTournamentId && +currentTournamentId === +tournamentid) {
    yield put({ type: WATCH_TOURNAMENT, params: +tournamentid });
    yield put({ type: UPDATE_USER_DATA });
  }
}

export function* _confirmTournamentResults({ params }) {
  try {
    const { tournamentid } = params;
    yield put(confirmTournamentResultsRequestAction(tournamentid));
    const result = yield call(post, { url: `${config.FETCH.url}/tournament/confirmTournamentResults`, data: params });
    yield put(confirmTournamentResultsSuccessAction());
  } catch (error) {
    yield put(confirmTournamentResultsFailureAction(error.message));
    console.error('Confirm Tournament Results');
  }
}

export function* _addReplayToPlayer({ params }) {
  try {
    const result = yield call(post, { url: `${config.FETCH.url}/tournament/add_replay_player`, data: params });

    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_PLAYER_REPLAY, params: result.data.player });
    }
  } catch (error) {
    console.error('Add player replay error: ', error);
    bugsnagClient.notify(new Error(`Error. Add player replay ${JSON.stringify({ error })}`));
  }
}

export function* _createLeaderboardStage({ params }) {
  try {
    const result = yield call(post, { url: `${config.FETCH.url}/tournament/createLeaderboardStage`, data: params });
    const { data } = result;
    if (data.success) {
      yield put({ type: SET_LEADERBOARD_TABLE_STAGES, params: data.data });
    }
  } catch (error) {
    console.error('_createLeaderboardStage', error.message);
  }
}

export function* _deleteLeaderboardStage({ params }) {
  try {
    const { stageid} = params;
    const result = yield call(post, { url: `${config.FETCH.url}/tournament/deleteLeaderboardStage`, data: params });
    const { data } = result;
    if (data.success) {
      yield put({ type: SET_LEADERBOARD_TABLE_STAGES, params: data.data });
    }
  } catch (error) {
    console.error('_deleteLeaderboardStage', error.message);
  }
}

export function* _saveLeaderboardStage({ params }) {
  try {
    const result = yield call(post, { url: `${config.FETCH.url}/tournament/saveLeaderboardStage`, data: params });
    const { data } = result;
    if (data.success) {
      yield put({ type: SET_LEADERBOARD_TABLE_STAGES, params: data.data });
    }
  } catch (error) {
    console.error('_saveLeaderboardStage', error.message);
  }
}

export function* _uploadTournamentCardDecoration({ params }) {
  try {
    const result = yield call(post, { url: `${config.FETCH.url}/tournament/decorations`, data: params });
    const { data } = result;
    if (data.success) {
      yield put({ type: UPLOAD_CARD_DECORATION, params: data.data });
    } else {
      console.error('_uploadTournamentCardDecoration', data);
    }
  } catch (error) {
    console.error('_uploadTournamentCardDecoration', error.message);
  }
}

export function* _setVisibilityBattle({ params, data }) {
  try {
    const result = yield call(post, { url: `${config.FETCH.url}/tournament/visible`, data: params });
    const { data } = result;
    if (data.success) {
      yield put({ type: SET_VISIBILITY_BATTLE_FETCHED, params: data.data })
    } else {
      console.error('_setVisibilityBattle', data);
    }
  } catch (error) {
    console.error('_setVisibilityBattle', error.message);
  }
}

export function* _getSquadIdByUserId({params, data}) {
  try {
    const { data } = yield call(get, { url: `${config.FETCH.url}/tournament/getsquadidnotclosed`, params });
    if (data.success) {
      yield put({ type: GET_SQUAD_ID_BY_USER_ID_FETCHED, params: data.squadIdNotClosed })
    } else {
      yield put({ type: GET_SQUAD_ID_BY_USER_ID_FETCHED, params: [] })
      console.error('error: _getSquadIdByUserId', data)
    }
  } catch (error) {
    console.error('_getSquadIdByUserId', error.message)
  }
}

export function* _getBcNotPlatform({ params, data }) {
  try {
    const { data: getExtBcName } = yield call(post, { url: `${config.FETCH.url}/tournament/lastextbcname`});
    if (getExtBcName.success) {
      yield put({ type: SET_BC_NOT_PLATFORM_FETCHED, payload: getExtBcName.data })
    } else {
      console.error('_setVisibilityBattle', getExtBcName);
    }
  } catch (error) {
    console.error('_setVisibilityBattle', error.message);
    bugsnagClient.notify(error, {
      context: 'Failed to get External Bowlin Center Name',
      beforeSend: (report) => {
        report.updateMetadata('payload', params);
      },
    });
  }
}

export function* _createGameNonPlatform({ params }) {
  try {
    // const squadNotClosed = yield  call(post, { url: `${config.FETCH.url}/tournament/checking_open` });
    // if (squadNotClosed.data.error) return console.error(squadNotClosed.error);

    // const result = squadNotClosed.data.result;
    // if (result.limitExist) {
    //   yield put({ type: COMMON_NOTIFICATION_PARAMS, params: {
    //       messageType: result.messageType,
    //       params: {
    //         tournamentName: result.tournamentName,
    //         squadDateStart: result.dateStart,
    //       }
    //     }});
    //   return;
    // }
    const { status, data } = yield call(post, { url: `${config.FETCH.url}/tournament/createGameNotPlatform`, data: params });
    if (status === 200 && data.success) {
      yield put({ type: SOCKET_EVENT_GAME_INVITE, params: data.newBattle });
      yield put({ type: types.ACCEPTING_GAME_START });
      yield put({ type: types.SET_GAME_START_NON_PLATFORM_ACCEPTING_COMPLETE });
      yield put({ type: types.SET_GAME_START_ACCEPTING_COMPLETE });
      yield call(_BattlesGet);
      yield call(_TournamentsGet);
    }
    yield put({ type: UPDATE_USER_DATA });
    const ignoreError = true;
    yield put({ type: types.ACCEPTING_GAME_START });

    yield put({ type: UPDATE_USER_DATA });
  } catch (error) {

    bugsnagClient.notify(error, { context: 'Game accepted' });
    console.error('Game accepted: ', error);
    bugsnagClient.notify(error, {
      context: 'Failed to create Game non platform',
      beforeSend: (report) => {
        report.updateMetadata('payload', params);
      },
    });
    console.error('Failed to Game non platform: ', error);
  }
}


