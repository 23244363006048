import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import OBModal from '../../../common/Modal';
import { Button } from '../../../common/Button';
import {
  SendVerificationCodeEmail,
} from '../../../../libs/reducers/actions';

import './style.scss';

class ModalEmailVerify extends React.Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    intl: intlShape,
  };

  constructor(props) {
    super(props);
    this.state = {
      email: props.users.user.email || '',
    };
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.users.user.emailSuccess) {
      nextProps.onSubmit();
    }
    return null;
  }

  handleOk = () => {
    const { email } = this.state;
    this.props.SendVerificationCodeEmail({ email });
  }

  handleCancel = () => {
    const { onClose } = this.props;
    onClose();
  }

  handleChange = (e) => {
    if (e && e.target) {
      const { value: email } = e.target;
      this.setState({ email });
    }
  }

  renderFooter = () => (
    <div
      className='dialog__buttons'>
      <Button
        kind='aquamarine'
        bordered='true'
        onClick={this.handleCancel}
        className='dialog__button-decline'
      >
        {this.props.intl.formatMessage({ id: 'CancelAsAbortAlias', defaultMessage: 'Cancel' })}
      </Button>
      <Button
        onClick={this.handleOk}
        kind='aquamarine'
        className='dialog__button-accept'
      >
        {this.props.intl.formatMessage({ id: 'Accept', defaultMessage: 'Accept' })}
      </Button>
    </div>
  )

  renderInput = () => {
    const { email } = this.state;
    const { intl } = this.props;
    return (
      <>
        <label htmlFor="email-verification" className="email-verification-dialog__label">
          E-mail
        </label>
        <input
          type="text"
          id="email-verification"
          className="email-verification-dialog__input"
          value={email}
          onChange={this.handleChange}
        />
        <span className="email-verification-dialog__label">
          {intl.formatMessage({ id: 'EmailVerificationTooltip', defaultMessage: 'You will receive letter with verification link' })}
        </span>
      </>
    );
  }


  render() {
    const { visible, intl } = this.props;

    return (
      <OBModal
        title={intl.formatMessage({ id: 'EmailVerificationModalTitle', defaultMessage: 'E-mail verify' })}
        visible={visible}
        width={395}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        className='email-verification-dialog'
        footer={this.renderFooter()}
      >
        {this.renderInput()}
      </OBModal>
    );
  }
}

export default injectIntl(connect(
  state => ({
    users: state.users,
  }),
  {
    SendVerificationCodeEmail,
  },
)(ModalEmailVerify));
