import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {Upload} from 'antd';
import moment from "moment";
import ModalImageCrop from "../../common/modals/modalImageCrop";
import Checkbox from "../../common/Checkbox";
import costIcon from '../../../images/euro-icon.png';
import calendar from '../../../images/calendarIcon.png';
import upload from "../../../images/upload.png";
import './style.scss';
import {uploadCardDecoration} from "../../../libs/reducers/actions";
import config from '../../../config';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

function toDataURL(url, callback) {
  const xhr = new XMLHttpRequest();
  xhr.onload = function() {
    const reader = new FileReader();
    reader.onloadend = function() {
      callback(reader.result);
    }
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}


const initialDecorations = {
  cardType: 0,
  bgImage: '',
  color: '',
  logo: '',
}

const TournamentCardTab = ({intl, tournament, uploadCardDecoration, decorations }) => {
    const [cardType, setCardType] = useState(tournament.cardType);
    const [useLogo, setUseLogo] = useState(false);

    const [logoPhoto, setLogoPhoto] = useState({value: '', error: ''});
    const [logoFile, setLogoFile] = useState({value: '', error: ''});
    const [logoPhotoChanged, setLogoPhotoChanged] = useState({value: '', error: ''});

    const [bgFile, setBgFile] = useState('');
    const [error, setError] = useState('');

    const [colorStart, setColorStart] = useState('')
    const [colorEnd, setColorEnd] = useState('');
    const [colorHex, setColorHex] = useState('');

    const [showModalImageCrop, setShowModalImageCrop] = useState(false);

    const filePickerRef = useRef(null);

    useEffect(() => {
      const {decorations} = tournament;
      const {logo, color, bgImage, cardType: decorationCardType} = decorations || initialDecorations;
      setBgFile(bgImage);
      setCardType(+decorationCardType);
      setColorStart(color);
      setColorEnd(color.replace('0.4', '0'));
      setLogoPhotoChanged({value: logo})
      setUseLogo(!!logo)
    }, [])


    let cost;

    const onColor = (hex) => {
      let color = {
        r: parseInt(hex.slice(1, 3), 16),
        g: parseInt(hex.slice(3, 5), 16),
        b: parseInt(hex.slice(5, 7), 16),
      }
      setColorStart(`rgba(${color.r}, ${color.g}, ${color.b}, 0.4)`);
      setColorEnd(`rgba(${color.r}, ${color.g}, ${color.b}, 0)`);
      setColorHex(hex);
    }

    if (tournament.cost !== 0) {
      cost = `${tournament.cost} ${tournament.currencysymbol}`
    } else {
      cost = intl.formatMessage({id: 'Free', defaultMessage: 'Free'})
    }


    const changeLogo = () => {
      if (logoPhoto.value) {
        setShowModalImageCrop(true)
      } else {
        filePickerRef.current.click();
      }
    };

    const imageChange = (e) => {
      const reader = new FileReader();
      const file = e.target.files[0];
      if (file.size > 1000000) {
        setLogoPhoto({
          ...logoPhoto,
          error: intl.formatMessage({
            id: 'TooBigFile',
            defaultMessage: 'Uploaded image is too big. File size cannot exceed 1Mb.'
          }),
        });
        setLogoFile('')
      } else {
        reader.onloadend = () => {
          setLogoPhoto({error: '', value: reader.result,});
          setLogoFile(file)
          setShowModalImageCrop(true)
        };
        reader.readAsDataURL(file);
      }
    }

    const onChangeImage = async (info) => {
      const {file} = info;
      if (file.size < 1000000) {
        if (file.status === 'uploading') {
          file.base64 = await getBase64(file.originFileObj);
          setBgFile(file.base64);
        }
      } else {
        setError('Uploaded image is too big. Try again');
      }
    };

    const submit = async () => {
      const params = {
        cardType,
        logo:  useLogo ? logoPhotoChanged.value  : '',
        bgImage: bgFile,
        color: colorStart,
        tournamentId: tournament.id
      }
        uploadCardDecoration(params);
    }
    const background = {background: `linear-gradient(to left, ${colorStart}, ${colorEnd})`}
    return (
      <>
        <ModalImageCrop
          key={'imagePickerModal'}
          visible={showModalImageCrop}
          image={logoPhoto.value}
          onSubmit={(image) => {
            setLogoPhotoChanged({value: image})
            setShowModalImageCrop(false)
            setLogoFile('')
          }}
          onClose={() => {
            setShowModalImageCrop(false)
          }}
        />
        <div className="label">{intl.formatMessage({id: 'CardSettings', defaultMessage: 'Card settings'})}</div>
        <div className="cardContainer">
          <div className={"container"} style={cardType === 1 ? background : null}>
            <div className={'image'}
                 style={cardType === 2 ? {backgroundImage: `url(${bgFile})`, backgroundSize: 'cover'} : null}/>


            <div className="cardTextContainer">
              <div className="tournamentCardItem">
                <p
                  className="tournamentCardLabel">{tournament.name}</p>
              </div>
              <div className="tournamentCardItem">
                <img src={calendar} alt="calendar" className="icon"/>
                <p
                  className="tournamentCardtext">{moment(tournament.datestart).format('DD MMM, HH:mm')} - {moment(tournament.regdateend).format('DD MMM, HH:mm')}</p>
              </div>
              <div className="tournamentCardItem">
                {/*<img src={costIcon} alt="costIcon" className="icon"/>
                <p className="tournamentCardText tournamentCardItem">{cost}</p>*/}
              </div>
            </div>
            <div className="logoContainer">
              {useLogo ? <img
                className="logo"
                src={
                  logoPhotoChanged.value ?
                    logoPhotoChanged.value.indexOf('img/') + 1 ? `${config.FETCH.img}/${logoPhotoChanged.value}` :
                      logoPhotoChanged.value : null
                }
                alt=""
              /> : null}
            </div>
          </div>
        </div>
        <div className="cardBgText">{intl.formatMessage({
          id: 'CardBackgrpund',
          defaultMessage: 'Card Background'
        })}</div>
        <div className="buttonContainer">
          <div className={`button ${cardType === 0 ? 'active' : null}`} onClick={() => setCardType(0)}>
            {intl.formatMessage({id: 'Standard', defaultMessage: 'Standard'})}
          </div>
          <div className={`button ${cardType === 1 ? 'active' : null}`} onClick={() => setCardType(1)}>
            {intl.formatMessage({id: 'Color Gradient', defaultMessage: 'Color Gradient'})}
          </div>
          <div className={`button ${cardType === 2 ? 'active' : null}`} onClick={() => setCardType(2)}>
            {intl.formatMessage({id: 'Photo', defaultMessage: 'Photo'})}
          </div>
        </div>

        <div className="tournamentCardSettingsCheck">
          <Checkbox
            checked={useLogo}
            onChange={() => setUseLogo(!useLogo)}>
            <div className="checkbox-value">
              {intl.formatMessage({id: 'AddLogo', defaultMessage: 'Add Logo'})}
            </div>
          </Checkbox>
        </div>

        {useLogo ?
          <div className='uploadButton' onClick={changeLogo}>
            <input
              className="postregpage__fileinput"
              type="file"
              ref={filePickerRef}
              onChange={(e) => {
                imageChange(e);
              }}
              onClick={(e) => {
                e.target.value = null;
              }}
            />
            <img className='upload' src={upload} alt='upload'/>
            <div>
              <div className="text">
                {intl.formatMessage({id: 'UploadTournamentLogo', defaultMessage: 'Upload logo in square format'})}
              </div>
              <div className="text">
                {intl.formatMessage({id: 'LogoSCircleCrop', defaultMessage: 'Logo will crop to circle'})}
              </div>
            </div>

          </div>
          : null}

        {cardType === 1 ? <div className="tournamentColorPickerContainer">
          <input type='color' className="picker" value={colorHex} onChange={e => onColor(e.target.value)}/>
          <div className="text">
            {intl.formatMessage({id: 'ChooseGradientColor', defaultMessage: 'Choose gradient color'})}
          </div>
        </div> : null}

        {cardType === 2 ?
          <Upload
            accept="image/jpeg,image/png"
            multiple={false}
            onChange={onChangeImage}
            showUploadList={false}
          >
            <div className="uploadButton">
              <img className='upload' src={upload} alt='upload'/>
              <div className="text">
                {intl.formatMessage({id: 'UploadPhoto', defaultMessage: 'Upload Photo'})}
              </div>
            </div>
          </Upload>
          : null}

        <div className={decorations ? 'disabled-button' : 'button'}  onClick={() => decorations ? null : submit()}>
          { decorations ? (intl.formatMessage({id: 'loading', defaultMessage: 'loading..'})) : (intl.formatMessage({id: 'Save', defaultMessage: 'Save'}))}
        </div>
      </>
    );
  }
;

export default connect(
  state => ({
    tournament: state.tournaments.tournamentInfo,
    decorations: state.tournaments.fetches.decorations
  }),
  {
    uploadCardDecoration
  }
)(injectIntl(TournamentCardTab))
