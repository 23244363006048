import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {injectIntl, intlShape} from 'react-intl';
import config from '../../../../config';
import Avatar from '../../Avatar';
import OBModal from '../../Modal';
import {AddFriendButton, Button} from '../../Button';
import defaultAvatar from '../../../../images/defaultAvatar.png';
import Flag from '../../Flag';
import {setCreateBattleQuery} from '../../../../libs/reducers/actions';
import CalculatorIcon from '-!svg-react-loader!../../../../images/calculator.svg'; // eslint-disable-line
import HandicapModal from '../../HandicapModal';

import './style.scss';

const padding = (value, len = 2) => `00000000${value}`.slice(-len);
const timezoneSerialize = (timezone) => {
  if (!timezone) {
    return 'unknown';
  }
  const plus = timezone.rawoffset >= 0;
  const absoffset = Math.abs(timezone.rawoffset);
  const hours = Math.floor(absoffset);
  const minutes = Math.floor((absoffset - hours) * 60);
  return `${timezone.name}, ${plus ? '+' : '-'}${padding(hours)}:${padding(minutes)}`;
};

class ModalUser extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onMessageClick: PropTypes.func,
    onChallengeClick: PropTypes.func,
    onClose: PropTypes.func,
    isChallengeAllowed: PropTypes.bool,
    user: PropTypes.shape({
      id: PropTypes.number,
      imageUri: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      login: PropTypes.string,
      dob: PropTypes.string,
      gender: PropTypes.string,
      hourshift: PropTypes.string,
      country_live: PropTypes.string,
      country_federation: PropTypes.string,
      club: PropTypes.string,
      ratings: PropTypes.array,
    }),
    intl: intlShape,
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: props.visible,
      ratingsopen: false,
      handicapModalVisible: false,
    };
  }

  static defaultProps = {
    onAddClick: () => {},
    onMessageClick: () => {},
    onChallengeClick: () => {},
    onClose: () => {},
    user: {
      id: 0,
      imageUri: '',
      firstname: 'Firstame',
      lastname: 'Lastname',
      login: 'login',
      dob: '',
      gender: '',
      hourshift: '',
      country_live: '',
      country_federation: '',
      club: '',
      ratings: [],
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.visible !== prevState.visible) return { visible: nextProps.visible };
    return null;
  }

  showModal = () => this.setState({ visible: true });

  handleCancel = () => {
    const { onClose } = this.props;
    this.setState({
      visible: false,
      ratingsopen: false,
    });
    onClose();
  };

  onChallengeClick = (opponent) => {
    const { location, history, setCreateBattleQuery } = this.props;
    const { pathname } = location;
    setCreateBattleQuery({ opponent });
    if (pathname != '/lobby') {
      history.replace('/lobby');
    }
    this.props.onClose();
  }

  render() {
    let {
      titleIntlId,
      user,
      timezones,
      geodata,
      onMessageClick,
      isChallengeAllowed,
      disableChallenge,
      intl,
    } = this.props;

    const poly = "M14 1.12978L17.6661 8.55816L17.7825 8.79389L18.0426 8.83169L26.2403 10.0229L20.3084 15.8051L20.1202 15.9886L20.1646 16.2476L21.5649 24.4122L14.2327 20.5574L14 20.4351L13.7673 20.5574L6.43507 24.4122L7.83541 16.2476L7.87984 15.9886L7.69161 15.8051L1.75969 10.0229L9.9574 8.83169L10.2175 8.79389L10.3339 8.55816L14 1.12978Z";
    isChallengeAllowed = !disableChallenge
    const { title } = typeof titleIntlId === 'undefined'
      ? this.props
      : { title: intl.formatMessage({ id: titleIntlId, defaultMessage: title }) };

    const { visible } = this.state;
    const timezone = timezones.data.find(tz => tz.id === +user.hourshift);
    const countryFederation = (Array.isArray(geodata.data) ? geodata.data : []).find(gd => gd.iso === user.country_federation);
    const countryLive = user.country_federation === user.country_live
      ? countryFederation
      : geodata.data.find(gd => gd.iso === user.country_live);

    let MainInfo = <div></div>
    if (user.id) {
      MainInfo = (
        <div key="main-info" className="modal-user__main-info">
          <Avatar
            isOnline={user.active}
            uri={user.photo ? `${config.FETCH.img}/${user.photo}` : defaultAvatar}
            flag={user.country_live ? user.country_live.toLowerCase() : ''}
          />
          <div className="modal-user__name">
            <div className="modal-user__fullname">{`${user.firstname} ${user.lastname}`}</div>
            <div className="modal-user__login">{user.login}</div>
            <div className='modal-user__rating' onClick={() => {this.setState({ ratingsopen: !this.state.ratingsopen })}}>
              <span className='modal-user__ratingcaption'>
                {intl.formatMessage({ id: 'Feedbacks', defaultMessage: 'Feedbacks' })}(
                  {user.ratings.length 
                    ? (user.ratings.length > 99 && '99+' || user.ratings.length)
                    : intl.formatMessage({ id: 'No', defaultMessage: 'No' })})
              </span>
              <div className='modal-user__ratingstar'>
                {
                  [1, 2, 3, 4, 5].map(num => {
                    let fill = 'none';
                    let isPartial = false;
                    const entropy = Math.random();
                    if (+user.ratingsavg > num || +user.ratingsavg == num) {
                      fill = '#FFB31E';
                    } else if ((num - 1) < +user.ratingsavg && +user.ratingsavg < num) {
                      isPartial = true;
                      fill=`url(#partialGradient${entropy})`;
                    }
                    return <svg width="20" height="20" viewBox="0 0 28 28" fill={fill} xmlns="http://www.w3.org/2000/svg">
                            <path d={poly} stroke="#FFB31E"/>
                            {isPartial ?
                              <linearGradient id={`partialGradient${entropy}`}>
                                <stop stop-opacity="1" offset={`${(+user.ratingsavg-num+1)*100}%`} stop-color="#FFB31E"></stop>
                                <stop stop-opacity="0" offset="0"></stop>
                              </linearGradient>
                              : null
                            }
                          </svg>
                  })
                }
              </div>
            </div>
          </div>
        </div>
      );
    }

    const Numerical = (
      <div key="numerical-info" className="modal-user__numeric-info">
        <div className="modal-user__numeric">
          <div className="modal-user__numeric-value">
            {(+user.avgscore || 0).toFixed()}
          </div>
          <div className="modal-user__numeric-caption">
            {intl.formatMessage({ id: 'StatsAverageScore', defaultMessage: 'Average score' })}
          </div>
        </div>
        <div className="modal-user__numeric">
          {(user.handicap && user.handicap.handicap_type && (
            <div className="modal-user__numeric-value handicap">
              +{user.handicap.value || 0}
              <CalculatorIcon onClick={() => this.setState({ handicapModalVisible: true })} className="calculator-icon" />
            </div>
          )) || (
            <div className="modal-user__numeric-value">
              0
            </div>
          )}
          <div className="modal-user__numeric-caption">
            {intl.formatMessage({ id: 'HDC', defaultMessage: 'HDC' })}
          </div>
        </div>
        <div className="modal-user__numeric">
          <div className="modal-user__numeric-value">
            {user.matchplayed || 0}
          </div>
          <div className="modal-user__numeric-caption">
            {intl.formatMessage({ id: 'StatsMatchesAmount', defaultMessage: 'Matches played' })}
          </div> 
        </div>
        <div className="modal-user__numeric">
          <div className="modal-user__numeric-value">
            {user.matchwins || 0}
          </div>
          <div className="modal-user__numeric-caption">
            {intl.formatMessage({ id: 'StatsMatchesWin', defaultMessage: 'Victories' })}
          </div>
        </div>
      </div>
    )

    const AdditionalInfo = (
      <div key="additional-info" className="modal-user__additional-info">
        <div className="modal-user__birth-date">
          <div className="modal-user__header">
            {intl.formatMessage({ id: 'PersonalInfoDOB', defaultMessage: 'Birth date' })}
          </div>
          <div className="modal-user__text">
            {moment(user.dob)
              .format('D MMM YYYY')
              .toString()}
          </div>
        </div>
        <div className="modal-user__gender">
          <div className="modal-user__header">
            {intl.formatMessage({ id: 'FriendRouterFilterGender', defaultMessage: 'Gender' })}
          </div>
          <div className="modal-user__text">{user.gender}</div>
        </div>
        <div className="modal-user__country-live">
          <div className="modal-user__header">
            {intl.formatMessage({ id: 'PersonalInfoCountryLiving', defaultMessage: 'Country live' })}
          </div>
          <div className="modal-user__text">{countryLive ? countryLive.name : 'unknown'}</div>
        </div>
        <div className="modal-user__time-zone">
          <div className="modal-user__header">
            {intl.formatMessage({ id: 'PersonalInfoTimeZone', defaultMessage: 'Time zone' })}
          </div>
          <div className="modal-user__text">{timezoneSerialize(timezone)}</div>
        </div>
        <div className="modal-user__country-federation">
          <div className="modal-user__header">
            {intl.formatMessage({ id: 'PersonalInfoFederationCountry', defaultMessage: 'Country Federation' })}
          </div>
          <div className="modal-user__text">{countryFederation ? countryFederation.name : 'unknown'}</div>
        </div>
        <div className="modal-user__club">
          <div className="modal-user__header">
            {intl.formatMessage({ id: 'PersonalInfoClub', defaultMessage: 'Club' })}
          </div>
          <div className="modal-user__text">{user.club}</div>
        </div>
      </div>
    );
    
    const Buttons = (
      <div key="buttons" className="modal-user__buttons">
        <div className="modal-user__chat-add">
          <Button size="md" icon="chatmessage" kind="aquamarine" bordered="true" onClick={onMessageClick} disabled={this.props.client.id === user.id}/>
          <AddFriendButton size="md" icon="adduser" kind="aquamarine" bordered="true" user={user} disabled={this.props.client.id === user.id}/>
        </div>
        <Button
          icon="sword"
          kind="aquamarine"
          disabled={isChallengeAllowed && (this.props.client.id !== user.id) ? '' : 'disabled'}
          className="modal-user__button-challenge"
          onClick={() => { this.onChallengeClick(user.id) }}
        >
          {intl.formatMessage({ id: 'ChallengeToBattle', defaultMessage: 'Challenge to battle' })}
        </Button>
      </div>
    );

    const groups = [MainInfo, Numerical, AdditionalInfo, Buttons];
    return (
      <OBModal
        key='main'
        title={title}
        visible={visible}
        width={this.state.ratingsopen ? 790 : 395}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        className="modal-user"
        footer={null}
        zIndex={10000}
      >
        <div className='modal-user_wrapper'>
          <div className={`modal-user_info${this.state.ratingsopen ? ' open' : ''}`}>
            {groups}
          </div>
          {this.state.ratingsopen ?
            <div className='modal-user_rating'>
              {user.ratings && user.ratings.length ?
              user.ratings.map((rate) => {return (
                <div className='modal-user_singlerate'>
                  <div className='preambula'>
                    <div className="user">
                      <img className="user__photo" src={rate.createdbyavatar ? `${config.FETCH.img}/${rate.createdbyavatar}` : defaultAvatar} />
                      {rate.createdbycountry && (
                        <div className="user__flag_container">
                          <div className="user__flag">
                            <Flag name={rate.createdbycountry} />
                          </div>
                        </div>
                      )}
                      <div className="user__name">
                        <span className="fullname">{rate.createdbyusername}</span>
                        <span className="nickname">{rate.createdbyuserlogin}</span>
                      </div>
                    </div>
                    <div className='daterate'>
                      <span>{moment(rate.created).format('DD.MM.YY')}</span>
                      <div className="stars">
                      {
                        [1, 2, 3, 4, 5].map(num => {
                          let fill = 'none';
                          if (rate.rating >= num) {
                            fill = '#FFB31E';
                          }
                          return <svg width="14" height="14" viewBox="0 0 28 28" fill={fill} xmlns="http://www.w3.org/2000/svg">
                                  <path d={poly} stroke="#FFB31E"/>
                                </svg>
                        })
                      }
                      </div>
                    </div>
                  </div>
                  {rate.comment ?
                    [<span key='label' className='ratelabel'>{intl.formatMessage({ id: 'Feedback', defaultMessage: 'Feedback' })}</span>,
                     <span key='content' className='content'>{rate.comment}</span>]
                  :null}
                </div>
              )})
              : null}
            </div>
          :null}
        </div>
        <HandicapModal
          visible={this.state.handicapModalVisible}
          handicap={user.handicap}
          onOk={() => this.setState({ handicapModalVisible: false })}
          modalProps={{
            zIndex: 10001,
          }}
        />
      </OBModal>
    );
  }
}

export default injectIntl(withRouter(
  connect(
    state => ({
      timezones: state.common.timezones,
      geodata: state.common.geodata,
      client: state.users.user,
      disableChallenge: state.users.userById.disableChallenge
    }),
    {
      setCreateBattleQuery
    },
  )(ModalUser),
));
