import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { intlShape } from 'react-intl';
import { FilterInput } from '../common/Input';
import Select from '../common/Select';

const pad = (val, len = 2) => `0000${val}`.slice(-len);

const formatTmz = (offset) => {
  const sign = offset >= 0 ? '+' : '-';
  const time = Math.abs(offset);
  const hours = parseInt(time, 10);
  const minutes = parseInt((time - hours) * 60, 10);
  return `${sign}${pad(hours, 2)}:${pad(minutes, 2)}`;
};

const genderListIntl = {
  none: {
    id: 'notSelected',
    defaultMessage: 'Not selected...',
  },
  m: {
    id: 'GenderMale',
    defaultMessage: 'Male',
  },
  f: {
    id: 'GenderFemale',
    defaultMessage: 'Female',
  },
};

const participantListIntl = {
  none: {
    id: 'notSelected',
    defaultMessage: 'Not selected...',
  },
  1: {
    id: 'participant',
    defaultMessage: 'Participant',
  },
  0: {
    id: 'notParticipant',
    defaultMessage: 'Not participant',
  },
};

const _1day = 24 * 3600 * 1000;
const _1year = 365 * _1day;

export default class CompetitorFilter extends React.Component {
  static propTypes = {
    players: PropTypes.arrayOf(PropTypes.shape({
      photo: PropTypes.string,
      login: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      country: PropTypes.string,
      gender: PropTypes.string,
      dob: PropTypes.string,
      hourshift: PropTypes.number,
      isparticipant: PropTypes.number,
    })),
    isadmin: PropTypes.bool, // different logic for admin and non admin users
    countryList: PropTypes.arrayOf(PropTypes.shape()), // TODO: define country shape
    timezoneList: PropTypes.arrayOf(PropTypes.shape()), // TODO: define timezone shape
    onChange: PropTypes.func,
    intl: intlShape,
  }

  static defaultProps = {
    players: [],
    countryList: [],
    timezoneList: [],
    isadmin: false,
  }

  static filterByName = (player, name) => {
    const _name = (name || '').trim().toLowerCase();
    if (!_name) {
      return true;
    }
    const { login, firstname, lastname } = player;
    const _login = (login || '').toLowerCase();
    const _firstname = (firstname || '').toLowerCase();
    const _lastname = (lastname || '').toLowerCase();
    const names = _name.split(/\s+/g);
    if (names.length > 1) {
      let _fnFound;
      let _lnFound;
      for (const item of names) {
        if (_fnFound) {
          _lnFound = _lnFound || _lastname.indexOf(item) !== -1;
        } else {
          _fnFound = _fnFound || _firstname.indexOf(item) !== -1;
        }
      }
      return _fnFound && _lnFound;
    }
    if (_firstname.indexOf(_name) !== -1) return true;
    if (_lastname.indexOf(_name) !== -1) return true;
    if (_login.indexOf(_name) !== -1) return true;
    return false;
  }

  static filterByGender = (player, gender) => {
    if (!gender) {
      return true;
    }
    const { gender: playerGender } = player;
    return gender.value.toLowerCase() === playerGender.toLowerCase();
  }

  static filterByCountry = (player, countryISO) => {
    if (!countryISO) {
      return true;
    }
    const { country: iso } = player;
    return iso.toLowerCase() === countryISO.value.trim().toLowerCase();
  }

  static filterByTimezone = (player, timezoneID) => {
    if (!timezoneID) {
      return true;
    }
    const { hourshift } = player;
    return hourshift === timezoneID.value;
  }

  static filterByAge = (player, ageFrom, ageTo) => {
    if (!ageFrom && !ageTo) {
      return true;
    }
    const { dob: birthday } = player; // YYYY-MM-DD format
    const playerAge = (Date.now() - moment(birthday).unix() * 1000) / _1year;
    if (!ageTo) {
      return playerAge >= +ageFrom;
    }
    if (!ageFrom) {
      return playerAge <= +ageTo;
    }
    return +ageFrom <= playerAge && playerAge <= +ageTo;
  }

  static filterByParticipant = (player, participant) => {
    if (!participant || participant.value === 'none') return true;
    return +participant.value === player.isparticipant;
  }

  constructor(props) {
    super(props);
    const { players } = props;
    // props.timezoneList contains all timezones
    // props.countryList contains all countries
    const {
      intl, isadmin, countryList, timezoneList,
    } = props;
    const allCountryList = (isadmin ? countryList : countryList.filter(c => players.find(p => p.country === c.iso)))
      .map(c => ({ ...c, label: c.name, value: c.iso }));
    const allTimezoneList = (isadmin ? timezoneList
      : timezoneList.filter(tz => allCountryList.find(c => tz.countryid === c.id)))
      .map(tz => ({ ...tz, label: `${tz.name} ${formatTmz(tz.rawoffset)}`, value: tz.id }));
    const participantList = ['none', 1, 0].map(key => ({
      value: key,
      label: intl.formatMessage(participantListIntl[key]),
    }));
    this.state = {
      filtered: players.map(p => p.userid),
      name: null,
      country: null, // country iso
      gender: null,
      ageFrom: null,
      ageTo: null,
      timezone: null, // timezone id
      participant: participantList[1],
      genderList: ['none', 'm', 'f'].map(key => ({
        value: key,
        label: intl.formatMessage(genderListIntl[key]),
      })),
      participantList,
      allTimezoneList, // readonly
      timezoneList: allTimezoneList, // mutable
      countryList: allCountryList, // readonly
    };
    this.numRegEx = /^\d+$/;
  }

  onChangeName = (e) => {
    const { value: name } = e.target;
    this.setState({ name }, this.filter);
  }

  onChangeCountry = (e) => {
    const { countryList, allTimezoneList } = this.state;
    const { value: country } = e;
    if (!country || country === 'none') {
      this.setState({ country: '', timezone: '', timezoneList: allTimezoneList }, this.filter);
      return null;
    }
    const { id: countryId } = countryList.find(c => c.iso === country);
    const filteredTimezoneList = allTimezoneList.filter(tz => tz.countryid === countryId);
    this.setState({ country: e, timezone: '', timezoneList: filteredTimezoneList }, this.filter);
    return null;
  }

  onChangeTimezone = (e) => {
    this.setState({ timezone: e }, this.filter);
  }

  onChangeGender = (e) => {
    const { value: gender = '' } = e;
    const _gender = gender.toLowerCase();
    this.setState({ gender: _gender === 'none' ? '' : e }, this.filter);
  }

  onChangeAgeFrom = (e) => {
    const { value } = e.target;
    if (value && !this.numRegEx.test(value)) return;
    this.setState({ ageFrom: value }, this.filter);
  }

  onChangeAgeTo = (e) => {
    const { value } = e.target;
    if (value && !this.numRegEx.test(value)) return;
    this.setState({ ageTo: value }, this.filter);
  }

  onChangeParticipant = (e) => {
    const { value } = e;
    this.setState({ participant: value === 'none' ? '' : e }, this.filter);
  }

  onChange = () => {
    const { filtered } = this.state;
    const { onChange } = this.props;
    onChange(filtered);
  }

  onReset = () => {
    this.setState({
      name: '',
      gender: '',
      country: '',
      timezone: '',
      ageFrom: '',
      ageTo: '',
      participant: null,
    }, this.filter);
  }

  componentDidMount() {
    this.filter();
  }

  componentDidUpdate(prevProps) {
    const { intl } = this.props;
    if (intl.locale !== prevProps.intl.locale) {
      this.setState({
        genderList: ['none', 'm', 'f'].map(key => ({
          value: key,
          label: intl.formatMessage(genderListIntl[key]),
        })),
      });
    }
  }

  filter = () => {
    const { players } = this.props;
    const {
      name, country, gender, ageFrom, ageTo, timezone, participant,
    } = this.state;
    
    const filtered = [
      player => CompetitorFilter.filterByName(player, name),
      player => CompetitorFilter.filterByCountry(player, country),
      player => CompetitorFilter.filterByGender(player, gender),
      player => CompetitorFilter.filterByAge(player, ageFrom, ageTo),
      player => CompetitorFilter.filterByTimezone(player, timezone),
      player => CompetitorFilter.filterByParticipant(player, participant),
    ]

    /*const filtered = players
      .filter(player => CompetitorFilter.filterByName(player, name))
      .filter(player => CompetitorFilter.filterByCountry(player, country))
      .filter(player => CompetitorFilter.filterByGender(player, gender))
      .filter(player => CompetitorFilter.filterByAge(player, ageFrom, ageTo))
      .filter(player => CompetitorFilter.filterByTimezone(player, timezone))
      .filter(player => CompetitorFilter.filterByParticipant(player, participant))
      .map(player => player.userid);*/
    this.setState({ filtered }, this.onChange);
  }

  render() {
    const { intl, isadmin } = this.props;
    const {
      name,
      country,
      gender,
      ageFrom,
      ageTo,
      timezone,
      participant,
      timezoneList,
      countryList,
      genderList,
      participantList,
    } = this.state;
    return <div className="player-filter" id="tournament-player-filter">
      <div className="player-filter-name">
        <FilterInput
          value={name}
          onChange={this.onChangeName}
          placeholder={intl.formatMessage({ id: 'FriendRouterFilterName', defaultMessage: 'Find user' })}
        />
      </div>
      <div className="player-filter-country">
        <Select
          isSearchable
          value={country}
          options={countryList}
          placeholder={intl.formatMessage({ id: 'FriendRouterFilterCountry', defaultMessage: 'Country' })}
          onChange={this.onChangeCountry}
        />
      </div>
      <div className="player-filter-timezone">
        <Select
          value={timezone}
          options={timezoneList}
          placeholder={intl.formatMessage({ id: 'PersonalInfoTimeZone', defaultMessage: 'Timezone' })}
          onChange={this.onChangeTimezone}
        />
      </div>
      <div className="player-filter-gender">
        <Select
          value={gender}
          options={genderList}
          placeholder={intl.formatMessage({ id: 'FriendRouterFilterGender', defaultMessage: 'Gender' })}
          onChange={this.onChangeGender}
        />
      </div>
      <div className="player-filter-age">
        <span className="player-age-text">{intl.formatMessage({ id: 'UserAge', defaultMessage: 'Age' })}</span>
        <div className="player-filter-age-from">
          <FilterInput
            value={ageFrom}
            onChange={this.onChangeAgeFrom}
            placeholder={intl.formatMessage({ id: 'from', defaultMessage: 'From' })}
          />
        </div>
        <div className="player-filter-age-to">
          <FilterInput
            value={ageTo}
            onChange={this.onChangeAgeTo}
            placeholder={intl.formatMessage({ id: 'to', defaultMessage: 'To' })}
          />
        </div>
      </div>
      {!!isadmin && <div className="player-filter-participant">
        <Select
          value={participant}
          options={participantList}
          onChange={this.onChangeParticipant}
        />
      </div>}
      <div className="player-filter-reset">
        <span onClick={this.onReset}>{intl.formatMessage({ id: 'reset', defaultMessage: 'Reset' })}</span>
      </div>
    </div>;
  }
}
