import React, { Component } from 'react';
import SelectBase, { components } from 'react-select';
import array from './images/polygon.png';

// eslint-disable-next-line
const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <img src={array} alt="" />
      </components.DropdownIndicator>
    )
  );
};

const IndicatorSeparator = () => null;

export default class Select extends Component {
  render() {
    let customStyles = {
      control: base => ({
        ...base,
        backgroundColor: 'transparent',
        borderColor: 'rgba(255,255,255,0.4)',
        ':hover': {
          borderColor: 'rgba(255,255,255,0.4)',
        },
        borderRadius: 0,
        minHeight: 30,
        maxHeight: 30,
        boxShadow: 'none',
        cursor: 'pointer',
      }),
      placeholder: base => ({
        ...base,
        color: 'rgba(255,255,255,0.4)',
        fontSize: 14,
        fontWeight: 300,
      }),
      singleValue: base => ({
        ...base,
        color: 'rgba(255,255,255,1)',
        fontSize: 14,
        fontWeight: 300,
      }),
      menu: base => ({
        ...base,
        borderRadius: 0,
        margin: 0,
        backgroundColor: '#6d7177',
      }),
      menuList: base => ({
        ...base,
        padding: 0,
        maxHeight: 200
      }),
      option: (base, { isSelected }) => ({
        ...base,
        backgroundColor: isSelected ? '#20a39e' : '#6d7177',
        ':hover': {
          backgroundColor: '#20a39e',
        },
        color: '#fff',
        fontSize: 14,
        fontWeight: 300,
        padding: '6px 10px',
        cursor: 'pointer',
      }),
      noOptionsMessage: base => ({
        ...base,
        color: '#fff',
        fontSize: 14,
        fontWeight: 300,
        padding: '6px 10px',
        backgroundColor: '#6d7177',
      }),
    };

    if (this.props.customStyle) {
      customStyles = { ...customStyles, ...this.props.customStyle };
    }
    const _components = { DropdownIndicator, IndicatorSeparator };
    const { error, isSearchable } = this.props;
    return (
      <div className={this.props.className ? `${this.props.className}__container` : ""}>
        <SelectBase
          {...this.props}
          components={!this.props.components ? _components : this.props.components}
          isSearchable={isSearchable}
          captureMenuScroll={false}
          styles={customStyles}
        />
        {error ? <div className="postregpage_inputerror">{error}</div> : null}
      </div>
    );
  }
}
