import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import { connect } from 'react-redux';
import localeEn from 'antd/lib/date-picker/locale/en_US';
import localeRu from 'antd/lib/date-picker/locale/ru_RU';

import './style.scss';

class OBDatePicker extends Component {
  static propTypes = {
    dateFormat: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    dateFormat: 'DD.MM.YY',
  };

  render() {
    const { dateFormat, error, icon } = this.props;
    return (
      <div className={icon && 'with-icon'}>
        <DatePicker
          {...this.props}
          allowClear={false}
          format={dateFormat}
          locale={this.props.lang === 'EN' ? localeEn : localeRu}
          showToday={false} />
        {error ? <div className="postregpage_inputerror">{error}</div> : null}
      </div>
    );
  }
}

export default connect(
  state => ({
    lang: state.langs.lang,
  }),
  {},
)(OBDatePicker);
