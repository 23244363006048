import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import { injectIntl, intlShape } from 'react-intl';
import { VideosTab } from '.';
import './style.css';

const getStream = source => (source ? source.match(/\/([a-zA-Z\-0-9]+)$/)[1] : '');
const filterArchives = (archives, streams) => {
  if (!archives) {
    return [];
  }
  const sortedArchives = streams.map((stm) => {
    const item = archives[stm] || [];
    return item.map(it => ({ ...it }));
  });
  let result = null;
  for (let i = 0; i < streams.length; i += 1) {
    const archiveList = sortedArchives[i];
    if (!result) {
      result = archiveList;
      continue; // eslint-disable-line
    }
    for (const { timestamp, duration } of archiveList) {
      const end = timestamp + duration;
      const item = result.find((t) => {
        const tend = t.timestamp + t.duration;
        return t.timestamp <= timestamp && tend >= end;
      });
      if (item) {
        // update result
        item.timestamp = timestamp;
        item.duration = duration;
      } else {
        // insert new
        result.push({ timestamp, duration });
      }
    }
  }
  if (!result) {
    return [];
  }
  for (const item of result) {
    item.dateStart = new Date(item.timestamp * 1000).toISOString();
    Object.defineProperty(item, 'sufix', {
      // eslint-disable-next-line
      get: function() {
        return `video-${this.timestamp}-${this.duration}.m3u8`;
      },
      enumerable: true,
    });
    // item.sufix = `video-${item.timestamp}-${item.duration}.m3u8`;
  }
  return result;
};

class VideosTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
    };
  }

  static proptTypes = {
    rivalVideoSources: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    myVideoSources: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    intl: intlShape,
  };

  componentDidMount() {
    if (this.props.currentPlayer) this.setState({ selectedIndex: this.props.sources.findIndex(source => source.ownerId === this.props.currentPlayer) })
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentPlayer && prevProps.currentPlayer !== this.props.currentPlayer) this.setState({ selectedIndex: this.props.sources.findIndex(source => source.ownerId === this.props.currentPlayer) })
  }

  render() {
    if (this.props.sources) {
      const {
        sources,
        intl,
        videoControl,
        archives,
        tournamentstart,
        updateArchives,
        fetchVideoArchive,
        fetchVideoArchivesAll,
        pauseSwitch,
        showAll,
        hideControl,
        token,
        noSwitch,
      } = this.props;
    
      const streamMap = sources.map(player => player.streams.map(stream => '/'+getStream(stream.url)));
      const archiveMap = streamMap.map(stream => filterArchives(archives, stream));

    return <Tabs
        className="videosTabs"
        selectedIndex={this.state.selectedIndex}
        onSelect={selectedIndex => this.setState({ selectedIndex })}
      >
        <TabList className="videosTabs-list">
          <div className="videosTabs-separator1" />
          <span className="videosTabs-tab">
            {intl.formatMessage({ id: 'GameCameraList', defaultMessage: 'Cameras:' })}
          </span>
          {showAll ? 
            <Tab className="videosTabs-tab" selectedClassName="videosTabs-active">
              {!noSwitch ? 'All' : sources[0].owner }
            </Tab>
          : null}
          {!noSwitch ? sources.map(player => <Tab className="videosTabs-tab" selectedClassName="videosTabs-active">
            {player.owner ? player.owner : intl.formatMessage({ id: 'OpponentCamera', defaultMessage: 'Opponents' })}
          </Tab>) : null}
        </TabList>
        {showAll ? 
          <TabPanel className="videosAllContainer">
            {sources.map((player, index) => <>
              <VideosTab
                denied={player.denied}
                className="videosTabs-tab-content"
                videoControl={videoControl}
                videoSources={streamMap[index]}
                archives={archiveMap[index]}
                startTime={Math.round(+new Date(tournamentstart || undefined) / 1000)}
                updateArchives={updateArchives}
                fetchVideoArchive={fetchVideoArchive}
                fetchVideoArchivesAll={fetchVideoArchivesAll}
                pauseSwitch={pauseSwitch}
                onRangeChange={this.props.onRangeChange}
                hideControl={hideControl}
                token={token}
                mirrored={index%2}
              />
            </>)}
          </TabPanel>
        : null}
        {!noSwitch ? sources.map((player, index) => <>
          <TabPanel>
            <VideosTab
              denied={player.denied}
              className="videosTabs-tab-content"
              videoControl={videoControl}
              videoSources={streamMap[index]}
              archives={archiveMap[index]}
              startTime={Math.round(+new Date(tournamentstart || undefined) / 1000)}
              updateArchives={updateArchives}
              fetchVideoArchive={fetchVideoArchive}
              fetchVideoArchivesAll={fetchVideoArchivesAll}
              pauseSwitch={pauseSwitch}
              onRangeChange={this.props.onRangeChange}
              hideControl={hideControl}
              token={token}
            />
          </TabPanel>
        </>) : null}
      </Tabs>
    } else {
      const {
        rivalVideoSources,
        myVideoSources,
        intl,
        videoControl,
        archives,
        tournamentstart,
        updateArchives,
        fetchVideoArchive,
        fetchVideoArchivesAll,
        pauseSwitch,

        protagName,
        antagName,
        showAll,
        hideControl,

        deniedRival,
        deniedOwner,

        token,
			} = this.props;

      const myStreams = myVideoSources.map(getStream);
      const rivalStreams = rivalVideoSources.map(getStream);
      const myArchives = filterArchives(archives, myStreams);
      const rivalArchives = filterArchives(archives, rivalStreams);
      return (
        <Tabs
          className="videosTabs"
          selectedIndex={this.state.selectedIndex}
          onSelect={selectedIndex => this.setState({ selectedIndex })}
        >
          <TabList className="videosTabs-list">
            <div className="videosTabs-separator1" />
            <span className="videosTabs-tab">
              {intl.formatMessage({ id: 'GameCameraList', defaultMessage: 'Cameras:' })}
            </span>
            {showAll ? 
              <Tab className="videosTabs-tab" selectedClassName="videosTabs-active">
                All
              </Tab>
            : null}
            <Tab className="videosTabs-tab" selectedClassName="videosTabs-active">
              {protagName ? protagName : intl.formatMessage({ id: 'OpponentCamera', defaultMessage: 'Opponents' })}
            </Tab>
            <Tab className="videosTabs-tab" selectedClassName="videosTabs-active">
              {antagName ? antagName : intl.formatMessage({ id: 'UserCamera', defaultMessage: 'Yours' })}
            </Tab>
          </TabList>
          {showAll ? 
            <TabPanel>
							 {/* className="videosTabs-tab" selectedClassName="videosTabs-active" */}
              <VideosTab
                className="videosTabs-tab-content"
                videoControl={videoControl}
                videoSources={rivalVideoSources}
                denied={deniedRival}
                archives={rivalArchives}
                startTime={Math.round(+new Date(tournamentstart || undefined) / 1000)}
                updateArchives={updateArchives}
                fetchVideoArchive={fetchVideoArchive}
                fetchVideoArchivesAll={fetchVideoArchivesAll}
                pauseSwitch={pauseSwitch}
                onRangeChange={this.props.onRangeChange}
                hideControl={hideControl}
                token={token}
              />
              <VideosTab
                className="videosTabs-tab-content"
                videoControl={videoControl}
                videoSources={myVideoSources}
                denied={deniedOwner}
                archives={myArchives}
                startTime={Math.round(+new Date(tournamentstart || undefined) / 1000)}
                updateArchives={updateArchives}
                fetchVideoArchive={fetchVideoArchive}
                fetchVideoArchivesAll={fetchVideoArchivesAll}
                pauseSwitch={pauseSwitch}
                onRangeChange={this.props.onRangeChange}
                hideControl={hideControl}
                mirrored
                token={token}
              />
            </TabPanel>
          : null}
          <TabPanel>
            <VideosTab
              className="videosTabs-tab-content"
              videoControl={videoControl}
              videoSources={rivalVideoSources}
              archives={rivalArchives}
              denied={deniedRival}
              startTime={Math.round(+new Date(tournamentstart || undefined) / 1000)}
              updateArchives={updateArchives}
              fetchVideoArchive={fetchVideoArchive}
              fetchVideoArchivesAll={fetchVideoArchivesAll}
              pauseSwitch={pauseSwitch}
              onRangeChange={this.props.onRangeChange}
              hideControl={hideControl}
              token={token}
            />
          </TabPanel>
          <TabPanel>
            <VideosTab
              className="videosTabs-tab-content"
              videoControl={videoControl}
              videoSources={myVideoSources}
              denied={deniedOwner}
              archives={myArchives}
              startTime={Math.round(+new Date(tournamentstart || undefined) / 1000)}
              updateArchives={updateArchives}
              fetchVideoArchive={fetchVideoArchive}
              fetchVideoArchivesAll={fetchVideoArchivesAll}
              pauseSwitch={pauseSwitch}
              onRangeChange={this.props.onRangeChange}
              hideControl={hideControl}
              token={token}
            />
          </TabPanel>
        </Tabs>
      );
    }
  }
}

export default injectIntl(VideosTabs);
