import React, { Component } from 'react';
import urlParse from 'url-parse';
import footerlogo from './images/footer-logo.png';
import TermsAndConditionModal from './termsAndConditionModal';
import PrivacyPolicyModal from './privacyPolicyModal';
import EuroUnionImg from '../../images/EuropeanUnion-logo.png';
import BowlExpoImg from '../../images/bowlexpo.png';

import './style.scss';
import './adaptive.scss';


const linkFacebook = 'https://www.facebook.com/Ten-Pin-Cam-462493900984021';
const linkInstagram = 'https://www.instagram.com/tenpincam';
const linkYouTube = 'https://www.youtube.com/channel/UC7-FHGtIJHiKHj-NgA9VKow';
export default class LandingFooter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleTermsAndConditionModal: false,
      visiblePrivacyPolicy: false,
    };
  }

  componentDidMount() {
    this.parseUrl();
  }
 
  parseUrl() {
    const { hash } = urlParse(window.location.href);
    if (hash.includes('#privacy_policy_modal')) {
      this.setState({ visiblePrivacyPolicy: true });
    } else if (hash.includes('#terms_and_condition_modal')) {
      this.setState({ visibleTermsAndConditionModal: true });
    }
  }

  onClickSocial = (type) => {
    if (!window.ym) {
      return;
    }

    const ymKey = 52252669;

    switch (type) {
      case 'facebook':
        window.ym(ymKey, 'reachGoal', 'Click_fb');
        break;
      case 'instagram':
        window.ym(ymKey, 'reachGoal', 'Click_Instagram');
        break;
      case 'youTube':
        window.ym(ymKey, 'reachGoal', 'Click_YouTube');
        break;
      default:
        break;
    }
  }

  render() {
    const { intl } = this.props;
    const {
      visibleTermsAndConditionModal,
      visiblePrivacyPolicy,
    } = this.state;

    return <div className="footer-extended fullcontainer">
      <span className="footer-description">
      TenPinCam project is supported financially by European Regional Development Fund,<br />
      as a part of International Сompetitiveness Promotion program.<br />
      Based on Agreement between TenPinCams SIA with Latvian Investment & Development Agency Nr. SKV-L-2022/46 dated 01.02.2022.
      </span>
      <img src={EuroUnionImg} />
      <span className="footer-description2">
        Intl Bowl Expo 2022 exhibitor - booth #1155.
      </span>
      <img src={BowlExpoImg} />
      <div className='footer fullcontainer'>
      <div>
        <img src={footerlogo}/>
        <span>{intl.formatMessage({ id: 'LandingLegals', defaultMessage: '© 2021 Tenpincams. All rights reserved' })}</span>
      </div>
      <div>
        <div className="f-social">
          <a
            className="f-social__icon"
            href={linkFacebook}
            target="_blank"
            onClick={() => this.onClickSocial('facebook')}
          >
            <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.875 0H3.125C1.40156 0 0 1.40156 0 3.125V21.875C0 23.5984 1.40156 25 3.125 25H12.5V16.4062H9.375V12.5H12.5V9.375C12.5 6.78594 14.5984 4.6875 17.1875 4.6875H20.3125V8.59375H18.75C17.8875 8.59375 17.1875 8.5125 17.1875 9.375V12.5H21.0938L19.5312 16.4062H17.1875V25H21.875C23.5984 25 25 23.5984 25 21.875V3.125C25 1.40156 23.5984 0 21.875 0Z" fill="white"/>
            </svg>
          </a>
          <a
            className="f-social__icon"
            href={linkInstagram}
            target="_blank" 
            onClick={() => this.onClickSocial('instagram')}
          >
            <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.1006 0H6.89891C3.09485 0 0 3.095 0 6.89906V18.1008C0 21.905 3.09485 24.9999 6.89891 24.9999H18.1006C21.905 24.9999 24.9999 21.9049 24.9999 18.1008V6.89906C25 3.095 21.905 0 18.1006 0ZM22.7819 18.1008C22.7819 20.6819 20.6819 22.7817 18.1008 22.7817H6.89891C4.31792 22.7819 2.21811 20.6819 2.21811 18.1008V6.89906C2.21811 4.31806 4.31792 2.21811 6.89891 2.21811H18.1006C20.6818 2.21811 22.7817 4.31806 22.7817 6.89906V18.1008H22.7819Z" fill="white"/>
              <path d="M12.5002 6.05713C8.9481 6.05713 6.05835 8.94688 6.05835 12.499C6.05835 16.0509 8.9481 18.9405 12.5002 18.9405C16.0523 18.9405 18.942 16.0509 18.942 12.499C18.942 8.94688 16.0523 6.05713 12.5002 6.05713ZM12.5002 16.7222C10.1713 16.7222 8.27646 14.8277 8.27646 12.4988C8.27646 10.1698 10.1712 8.27509 12.5002 8.27509C14.8292 8.27509 16.7239 10.1698 16.7239 12.4988C16.7239 14.8277 14.829 16.7222 12.5002 16.7222Z" fill="white"/>
              <path d="M19.2121 4.17725C18.7847 4.17725 18.3649 4.35026 18.0631 4.6534C17.7598 4.95506 17.5854 5.37502 17.5854 5.80386C17.5854 6.23136 17.7599 6.65118 18.0631 6.95432C18.3647 7.25598 18.7847 7.43047 19.2121 7.43047C19.6409 7.43047 20.0594 7.25598 20.3625 6.95432C20.6656 6.65118 20.8387 6.23121 20.8387 5.80386C20.8387 5.37502 20.6656 4.95506 20.3625 4.6534C20.0608 4.35026 19.6409 4.17725 19.2121 4.17725Z" fill="white"/>
            </svg>
          </a>
          <a
            className="f-social__icon_wide"
            href={linkYouTube}
            target="_blank" 
            onClick={() => this.onClickSocial('youTube')}
          >
            <svg viewBox="0 0 30 25" xmlns="http://www.w3.org/2000/svg">
+            <path d="M29.4173 4.92034C29.0824 3.62107 28.1007 2.59687 26.8558 2.24701C24.5816 1.59766 15.4845 1.59766 15.4845 1.59766C15.4845 1.59766 6.38775 1.59766 4.11352 2.22239C2.89259 2.57188 1.887 3.62125 1.5521 4.92034C0.953613 7.29342 0.953613 12.2149 0.953613 12.2149C0.953613 12.2149 0.953613 17.1611 1.5521 19.5094C1.88736 20.8085 2.86864 21.8327 4.1137 22.1825C6.4117 22.8321 15.4849 22.8321 15.4849 22.8321C15.4849 22.8321 24.5816 22.8321 26.8558 22.2073C28.1009 21.8577 29.0824 20.8335 29.4176 19.5344C30.0159 17.1611 30.0159 12.2399 30.0159 12.2399C30.0159 12.2399 30.0399 7.29342 29.4173 4.92034ZM12.5882 16.7615V7.66826L20.1529 12.2149L12.5882 16.7615Z" fill="white"/></svg>
          </a>
        </div>
        <span onClick={() => this.setState({ visibleTermsAndConditionModal: true })} className="terms">{intl.formatMessage({ id: 'TCagreeD', defaultMessage: 'Terms and condition' })}</span>
        <span onClick={() => this.setState({ visiblePrivacyPolicy: true })} className="terms">{intl.formatMessage({ id: 'privacy_policy2', defaultMessage: 'Privacy Policy' })}</span>
      </div>
      <div>
        <span>{intl.formatMessage({ id: 'LandingFooterSIA', defaultMessage: 'TenPinCams SIA' })}</span>
        <span>{intl.formatMessage({ id: 'LandingFooterReg', defaultMessage: 'Reg. Nr. 42103088398' })}</span>
        <span>{intl.formatMessage({ id: 'LandingFooterAdr', defaultMessage: 'Address: Jūrmala, Konkordijas iela 25, LV-2015, Latvia' })}</span>
        <span>{intl.formatMessage({ id: 'LandingFooterEmail', defaultMessage: 'E-mail: info@tenpincam.com' })}</span>
        <span>{intl.formatMessage({ id: 'LandingFooterPhone', defaultMessage: 'Phone: +37129215590‬' })}</span>
      </div>
      <TermsAndConditionModal visible={visibleTermsAndConditionModal} onClose={() => this.setState({ visibleTermsAndConditionModal: false })} />
      <PrivacyPolicyModal visible={visiblePrivacyPolicy} onClose={() => this.setState({ visiblePrivacyPolicy: false })} />
    </div>
    </div>;
  }
}
