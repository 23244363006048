import React from 'react';
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { Button } from '../../common/Button';

import closeIcon from '../../../images/close.white.png'
import plusOrange from '../../../images/plus.orange.png'
import './style.scss';
import config from '../../../config';

export default class PhotoList extends React.Component {
  static propTypes = {
    intl: intlShape,
    onDeletePhoto: PropTypes.func.isRequired,
    onAddPhoto: PropTypes.func,
    photos: PropTypes.arrayOf(PropTypes.object),
    isListEditable: PropTypes.bool,
    limitNumber: PropTypes.number,
    type: PropTypes.string,
  };

  static defaultProps = {
    photos: [],
    isListEditable: true,
  };

  componentDidMount() {
  }

  _onDeletePhoto = (index, type) => {
    this.props.onDeletePhoto(index, type)
  }

  renderList() {
    const { photos, isListEditable, type } = this.props;
    return photos.map((photo, index) => {
      return(
        <div className="photo-item" key={index}>
          <div className="photo-item_image">
            <img src={photo.photo.search('image/png|image/jpeg|image/jpg') !== -1 ? photo.photo : `${config.FETCH.url}/userdocs/user/new/${photo.photo}`}/>
            {/* <img src={photo.photo}/> */}
            {isListEditable && <button className="button_white" onClick={() => this._onDeletePhoto(index, type)}>
              <img src={closeIcon} />
            </button>}
          </div>
          <p>{photo.name}</p>
        </div>
      )
    })
  }

  renderAddPhoto() {
    const { intl, onAddPhoto, type } = this.props;

    return(
      <label className="button_transparent">
        <div className="photo_item_add">
          <img src={plusOrange} />
          <div>
            {intl.formatMessage({ id: 'UploadPhotoButtonText', defaultMessage: 'Upload photo' })}
          </div>
        </div>
        <input
          type="file"
          onChange={(e) => {
            onAddPhoto(e, type)
          }}
        />
      </label>
    )
  }

  render() {
    const { photos, onAddPhoto = {}, isListEditable , limitNumber = 2 } = this.props;
    return(<>
      {this.renderList()}
      {onAddPhoto && isListEditable && limitNumber > photos.length
        ? this.renderAddPhoto()
        : null
      }
      </>
    )
  }

}