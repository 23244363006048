import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../Modal';
import { Button } from '../../Button';
import { injectIntl, intlShape } from 'react-intl';
import './style.scss'
import bugsnagClient from '../../../../bugsnagClient';

export default injectIntl(class ErrorModal extends React.Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    buttonText: PropTypes.string.isRequired,
    onOk: PropTypes.func.isRequired,
    errorMessage: PropTypes.string.isRequired,
    intl: intlShape,
  };

  render() {
    const {
      visible, buttonText, onOk, errorMessage, intl,
    } = this.props;

    return (
      <Modal
        title="Error"
        visible={ visible }
        closable={false}
        width={395}
        footer={
          <Button
            key="okButton"
            kind="aquamarine"
            onClick={() => {
              onOk();
              if (Array.isArray(errorMessage) && errorMessage[1] && errorMessage[1].type === 'SafeError') return
              bugsnagClient.notify(new Error(errorMessage), {
                context: 'CommonError',
              });
            }}
          >
            {buttonText}
          </Button>
        }
      >
        <div className='commonerror'>
          <span>
            {(Array.isArray(errorMessage) && errorMessage[1] && errorMessage[1].type === 'SafeError')
            ? intl.formatMessage({id: 'CommonErrorOccuredSafe', defaultMessage: 'An unhandled error has occured'})
            : intl.formatMessage({id: 'CommonErrorOccured', defaultMessage: 'An unhandled error has occured'})}
          </span>
        </div>
        <div className='commonerror trace'>{ Array.isArray(errorMessage) ? errorMessage.map(line => <p>{line.message}</p>) : errorMessage}</div>
      </Modal>
    );
  }
});
