const {
  SET_COOKIES_FETCH,
  SET_COOKIES,
  CLEAR_COOKIES,
  SET_COOKIE_DATA,
  CLEAR_COOKIE_DATA,
} = require('./actiontypes').default;

const initialState = {
  isFetching: false,
  data: {},
  cleanCallback: false,
};

const cookies2string = (cookies = {}) => {
  const names = Object.keys(cookies);
  const list = [];
  for (const name of names) {
    const { value } = cookies[name];
    list.push(`${name}=${value}`);
  }
  return list.join('; ');
}

const clearCookies = (state, { name, origin } = {}) => {
  if (!name && !origin) {
    return { ...initialState };
  }
  if (!name) {
    // delete all cookies for origins
    const origins = Array.isArray(origin) ? origin : [origin];
    const cookiesState = { ...state };
    for (const orig of origins) {
      delete cookiesState[orig];
    }
    return cookiesState;
  }
  if (!origin) {
    // delete cookies by name
    const cookiesState = { ...state };
    const origins = Object.keys(cookiesState).filter(orig => orig !== 'isFetching');
    const names = Array.isArray(name) ? name : [name];
    for (const orig of origins) {
      cookiesState[orig].cookies = { ...state[orig].cookies };
      for (const nam of names) {
        if (cookiesState[orig].cookies[nam]) {
          delete cookiesState[orig].cookies[nam];
          cookiesState[orig].stringCookies = cookies2string(cookiesState[orig].cookies);
        }
      }
    }
  }
  // delete cookies of origin %origin%
  const cookiesState = { ...state };
  if (!cookiesState[origin]) {
    return cookiesState;
  }
  cookiesState[origin].cookies = { ...state[origin].cookies };
  const names = Object.keys(cookiesState[origin].cookies);
  for (const nam of names) {
    if (cookiesState[origin].cookies[nam]) {
      delete cookiesState[origin].cookies[nam];
      cookiesState[origin].stringCookies = cookies2string(cookiesState[origin].cookies);
    }
  }
  return cookiesState;
}

const setCookies = (state, { origin, cookies }) => {
  const oldCookies = state[origin] ? state[origin].cookies : {};
  const originCookies = {
    ...oldCookies,
    ...cookies,
  };
  // preserve cookie
  /*
  const names = Object.keys(originCookies);
  for (const name of names) {
    const oldval = oldCookies[name] && oldCookies[name].value || '';
    const newval = cookies[name] && cookies[name].value || '';
    if (oldval && !newval) {
      originCookies[name] = { ...oldCookies[name] };
    }
  }
  */
  return {
    ...state,
    [origin]: {
      cookies: originCookies,
      stringCookies: cookies2string(originCookies),
    },
    isFetching: false,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_COOKIE_DATA:
      return {
        ...state,
        data: { ...state.data, ...payload },
      };
    case CLEAR_COOKIE_DATA:
      if (Object.keys(state.data).length === 0) {
        return { ...state, cleanCallback: false };
      } else {
        return { ...state, data: {}, cleanCallback: true };
      }
    case SET_COOKIES_FETCH:
      return { ...state, isFetching: true };
    case SET_COOKIES:
      return setCookies(state, payload);
    case CLEAR_COOKIES:
      return clearCookies(state, payload);
    default:
      return state;
  }
}
