/* eslint-disable arrow-parens */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import OBModal from '../Modal';
import ConfirmationModal from './confimation.modal.jsx';
import ModalFooter from './fragments/modal-footer.fragment.jsx';
import validateEmailValue from './helpers/validate-email-value';
import validateIbanValue from './helpers/validate-iban-value';
import IbanValueCreateComponent from './iban-value-create.component.jsx';

/**
 * @typedef {import('./iban-value-create.fragment').IbanValueCreateFragmentProps} IbanValueCreateFragmentProps
 */
/**
 * @typedef {{
 *    isVisible?: boolean;
 *    onCancel?: () => any;
 *    onAdd?: (value: string) => any;
 *    setRef?: (ref: { reset: () => void }) => any;
 *  }
 *  & Omit<IbanValueCreateFragmentProps, 'intl' | 'error'>
 * } IbanValueCreateModalProps
 */

const TITLES = Object.freeze({
  IBAN: (intl) => intl.formatMessage({ id: 'IBANSaveModalTitle', defaultMessage: 'Add IBAN' }),
  PayPal: (intl) => intl.formatMessage({ id: 'PayPalSaveModalTitle', defaultMessage: 'Add PayPal' }),
});
/**
 * @type {React.FC<IbanValueCreateModalProps>}
 */
const IbanValueCreateModalFn = props => {
  const {
    isVisible, onCancel, intl, onAdd, onChange, type, setRef,
  } = props;
  const [value, setValue] = useState(props.value);
  const [error, setError] = useState();
  const [isVisibleConfirmation, setIsVisibleConfirmation] = useState(false);
  /** @type {React.MutableRefObject<{ reset: () => any }>} */
  const ref = useRef();
  const noop = () => null;
  const title = type === 'iban' ? TITLES.IBAN(intl) : TITLES.PayPal(intl);
  const onBeforeClose = () => setIsVisibleConfirmation(true);
  const onClose = () => {
    if (error) {
      setError(false);
    }
    if (onCancel) {
      onCancel();
    }
    if (ref.current && ref.current.reset) {
      ref.current.reset();
    }
  };
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange || noop;
  useEffect(() => {
    onChangeRef.current(value);
  }, [value, type]);
  return <>
    <ConfirmationModal
      title={title}
      isVisible={isVisibleConfirmation}
      onConfirm={() => {
        setIsVisibleConfirmation(false);
        onClose();
      }}
      onReject={() => {
        setIsVisibleConfirmation(false);
      }}
    />
    <OBModal
      title={title}
      visible={isVisible}
      onSubmit={noop}
      onCancel={onBeforeClose}
      footer={[
        <ModalFooter
          key={'footer'}
          onCancel={onBeforeClose}
          onAccept={() => {
            if (type === 'iban' && !validateIbanValue(value)) {
              setError(!value ? true : 'invalid iban');
              return;
            }
            if (type === 'paypal' && !validateEmailValue(value)) {
              setError(!value ? true : 'invalid email');
              return;
            }
            if (onAdd) {
              onAdd(value);
            }
          }}
          intl={intl}
        />,
      ]}
    >
      <IbanValueCreateComponent
        value={value}
        onChange={setValue}
        setRef={(inst) => {
          ref.current = inst;
          if (typeof setRef === 'function') {
            setRef(inst);
          }
        }}
        error={error}
        type={type}
      />
    </OBModal>
  </>;
};

class IbanValueCreateModalClass extends React.Component {
  render() {
    return <IbanValueCreateModalFn {...this.props} />;
  }
}

/** @type {typeof IbanValueCreateModalFn} */
const IbanValueCreateModal = injectIntl(IbanValueCreateModalClass);

export default IbanValueCreateModal;
