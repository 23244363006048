import { put, call } from 'redux-saga/effects';
import config from '../../config';
import { get, post, put as putMethod } from '../Networking';

const getProtest = state => state.protestList.data;

const {
  SET_PROTEST,
  SET_PROTEST_LIST,
  FETCH_PROTEST_LIST,
  SET_PROTEST_LIST_OF_PARTY,
  GET_VIDEO_ARCHIVE,
  GET_VIDEO_ARCHIVE_PROTESTS,
  REFRESH_PROTOCOL,
} = require('../reducers/actiontypes').default;

export function* _getProtestList() {
  try {
    const url = `${config.FETCH.url}/protest/list`;
    const result = yield call(get, { url });
    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_PROTEST_LIST, payload: result.data.data });
    } else {
      yield put({ type: SET_PROTEST_LIST, error: result.data.error || result.status });
    }
  } catch (err) {
    yield put({ type: SET_PROTEST_LIST, error: err });
  }
}

export function* _getProtestListOfParty(params) {
  const { squadid } = params.params;
  try {
    const url = `${config.FETCH.url}/protest/list/party/${squadid}`;
    const result = yield call(get, { url });
    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_PROTEST_LIST_OF_PARTY, payload: result.data.data });
    } else {
      yield put({ type: SET_PROTEST_LIST_OF_PARTY, error: result.data.error || result.status });
    }
  } catch (err) {
    yield put({ type: SET_PROTEST_LIST_OF_PARTY, error: err });
  }
}

export function* _considerProtest(params) {
  try {
    const url = `${config.FETCH.url}/protest/consider`;
    const result = yield call(putMethod, { url, data: params.params }, true);
    if (result.status === 200 && result.data.success) {
      yield put({ type: FETCH_PROTEST_LIST });
    } else {
      yield put({ type: SET_PROTEST_LIST, error: result.data.error || result.status });
    }
  } catch (err) {
    yield put({ type: SET_PROTEST_LIST, error: err });
  }
}

export function* _refuseProtest(params) {
  try {
    const url = `${config.FETCH.url}/protest/refuse`;
    const result = yield call(putMethod, { url, data: params.params });
    if (result.status === 200 && result.data.success) {
      yield put({ type: FETCH_PROTEST_LIST });
    } else {
      yield put({ type: SET_PROTEST_LIST, error: result.data.error || result.status });
    }
  } catch (err) {
    yield put({ type: SET_PROTEST_LIST, error: err });
  }
}

export function* _getProtest(params) {
  const { protestid } = params.params;
  try {
    const url = `${config.FETCH.url}/protest/${protestid}`;
    const result = yield call(get, { url });
    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_PROTEST, payload: result.data.data });
    } else {
      yield put({ type: SET_PROTEST, error: result.data.error || result.status });
    }
  } catch (err) {
    yield put({ type: SET_PROTEST, error: err });
  }
}

export function* _closeProtest(params) {
  try {
    const url = `${config.FETCH.url}/protest/close`;
    const result = yield call(post, { url, data: params.params });
    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_PROTEST, payload: result.data.data, saved: true });
    } else {
      yield put({ type: SET_PROTEST, error: result.data.error || result.status });
    }
  } catch (err) {
    yield put({ type: SET_PROTEST, error: err });
  }
}

export function* _createProtestArchive(params) {
  try {
    const url = `${config.FETCH.url}/protest/createProtest`;
    const result = yield call(post, { url, data: params.params });
    if (result.status === 200 && result.data.success) {
      yield put({ type: GET_VIDEO_ARCHIVE, params: { squadid: params.params.protest.squadid }});
      yield put({ type: REFRESH_PROTOCOL });
    } else {
      console.log('Error');
    }
  } catch (error) {
    console.log('Error');
  }
}

export function* _cancelProtestArchive(params) {
  try {
    const url = `${config.FETCH.url}/protest/cancelProtest`;
    const result = yield call(post, { url, data: params.params });
    if (result.status === 200 && result.data.success) {
      yield put({ type: GET_VIDEO_ARCHIVE, params: { squadid: params.params.squadid }});
      yield put({ type: REFRESH_PROTOCOL });
    } else {
      console.log('Error');
    }
  } catch (error) {
    console.log('Error');
  }
}

export function* _replyProtestArchive(params) {
  try {
    const url = `${config.FETCH.url}/protest/replyProtest`;
    const result = yield call(post, { url, data: params.params });
    if (result.status === 200 && result.data.success) {
      
    } else {
      console.log('Error');
    }
  } catch (error) {
    console.log('Error');
  }
}