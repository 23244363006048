import React from 'react'
import tournamentLogo from './images/euro-tournament-logo.png'
import tournamentDate from './images/euro-tournament-date.png'
import arrow from './images/euro-tournament-arrow.png'

const FirstTournament = ({ }) => {

  const messages = {
    header: 'First LiveCam Tournament ',
    weArePround: 'We are proud to announce, that we will be arranging the tournament in new Live format together with ETBF in July.',
    juniorBowlers: 'Junior bowlers from 16 nations will compete for the ETBF medals, playing from the home bowling centers. How this is possible? The Tournament will use unique TenPinCam technology, using the web cameras & the mobile app.',
    newFormat: 'Yes, this is an absolutely new tournament format.',
    theBowlers: 'Yes, it\'s not something the bowlers are used to.',
    everyoneCan: 'Yes, everyone can choose his favourite oil pattern.',
    competitions: 'Yes, we are defining new normal for bowling competitions.',
    youLoveIt: 'Yes, we are sure that you are going to love it.',
    newLevel: 'Yes, we believe that the bowling will advance to the new level with our technology.',
    footerMsg: 'Be one of the limited number of nations in Europe to join this tournament.',
    footerLink: 'Visit the tournament\'s web-page and know more',
  }

  return (
    <div className='firstTournament'>
      <div className='container'>
        <div className='headerBlock'>
          <h2 className='headerTitle'>{messages.header}</h2>
          <div className='headerSmallMsgBlock'>
            <div className='headerSmallMessage'>in the history</div>
            <div className='headerSmallMessage'>of ten pin bowling</div>
            <div className='headerSmallMessage mobile'>in the history of ten pin bowling</div>
          </div>
        </div>
        <div className='logoBlock'>
          <img src={tournamentLogo}className='logo'/>
          <img src={tournamentDate} className='date'/>
        </div>
        <div className='msgFirstBlock'>
          <div className='message'>{messages.weArePround}</div>
          <div className='message'>{messages.juniorBowlers}</div>
        </div>
        <div className='msgSecondBlock'>
          <div className='message'>{messages.newFormat}</div>
          <div className='message'>{messages.theBowlers}</div>
          <div className='message'>{messages.everyoneCan}</div>
          <div className='message'>{messages.competitions}</div>
          <div className='message'>{messages.youLoveIt}</div>
          <div className='message'>{messages.newLevel}</div>
        </div>
        <div className='footerBlock'>
          <h3 className='footerTitle'>{messages.footerMsg}</h3>
          <a className='footerLinkBlock' href="/EYLT2021">
            <div className='footerLinkMsg'>{messages.footerLink}</div>
            <div className='footerLink'>
              <img src={arrow} className='footerArrow' title='coming soon' alt='arrow'/>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}


export default FirstTournament
