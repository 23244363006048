/* eslint-disable */
import React, { useState } from 'react'
// import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { injectIntl } from 'react-intl'
import cupYellow from '../../images/cup.yellow.png'
import cupGray from '../../images/cup.gray.png'
// import { fontMaker } from '../../utils/font.maker'
import { LiveCutModal } from './LiveCut.modal'
import { ordinals } from './tools'
import { useLiveCut } from './useLiveCut'
import { connect } from 'react-redux'
require('./style.scss')

/**
 * @typedef {{
 *  locale: string
 *  formatMessage: (message: { id: string; defaultMessage?: string; }, values?: Object) => string
 * }} IntlShape
 */

/**
 * @typedef {{
 *  style?: import('react-native').StyleProp<import('react-native').ViewStyle>
 *  intl: IntlShape
 * }} ILiveCutProps
 */

/**
 * @type {React.FC<ILiveCutProps>}
 */
const LiveCut = (props) => {
  const { style, intl, playerSquad, observer, leaderBoard, tournamentInfo } = props
  const [visible, setVisible] = useState(false)
  const openModal = () => setVisible(true)
  const closeModal = () => setVisible(false)
  const liveCut = useLiveCut({
    intl,
    playerSquad,
    observer,
    leaderBoard,
    tournamentInfo,
  })
  if (!liveCut) {
    return null;
  }
  const { curPlace, countPrizePlaces, modalText, nextScore, cut } = liveCut
  const onClick = (e) => {
    e.stopPropagation()
  }

  return <div className="live-cut-wrapper" onClick={onClick}>
    {typeof countPrizePlaces === 'number' ? (
      <div className="content">
        <img className="cup" src={curPlace <= countPrizePlaces ? cupYellow : cupGray} resizeMode="contain" />
        <div className="text-wrapper" onClick={openModal}>
          <span className="text" >
            {`${countPrizePlaces}${ordinals(countPrizePlaces)}: CUT ${cut}`}
          </span>
          <span className="text text-small">
            {`#${curPlace}`}
            {curPlace === 1 ? '' : ` (${nextScore} to #${curPlace - 1})`}
          </span>
        </div>
      </div>
    ) : (
      <div className="content flex-start">
        <div className="content flex-undefined" onPress={openModal}>
          <img className="cup cup-small" src={cupYellow} resizeMode="contain" />
          <span className="text ml2">
            {`#${curPlace}`}
            {curPlace === 1 ? '' : ` (${nextScore} to #${curPlace - 1})`}
          </span>
        </div>
      </div>
    )}
    <LiveCutModal
      visible={visible}
      onClose={closeModal}
      intl={intl}
      text={modalText}
    />
  </div>
}

const LiveCutIntl = connect((state) => ({
  playerSquad: state.squadScreen.data,
  observer: state.observer,
  leaderBoard: state.tournaments.InfoTournament,
  tournamentInfo: state.tournaments.tournamentInfo,
}))(injectIntl(LiveCut))

export { LiveCutIntl as LiveCut }