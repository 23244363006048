import React, { useState, useEffect } from 'react';
import Select from './select';

const monthOptions = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' },
];

const daysInMonth = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

const isLeapYear = (year) => {
  if (year % 4) return false;
  if (year % 100) return true;
  if (year % 400) return false;
  return true;
};

const yearOptions = [];
for (let i = 2021; i >= 1900; i--) {
  yearOptions.push({ value: i, label: i });
}

const daysOptions = [];
for (let i = 1; i < 32; i++) {
  daysOptions.push({ value: i, label: i });
}

function Datepicker({
  onChange,
}) {
  const [day, setDay] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  let dayOptions = daysOptions;
  if (month) {
    dayOptions = daysOptions.filter(elem => elem.value <= daysInMonth[month.value - 1]);
    if (month.value === 2 && year && !isLeapYear(year.value)) {
      dayOptions = daysOptions.filter(elem => elem.value <= 28);
    }
  }
  useEffect(() => {
    if (day && month && year) {
      onChange(new Date(Date.UTC(year.value, month.value, day.value)));
    }
  }, [day, month, year]);
  return (
    <div className="l-datepicker">
      <Select
        value={day}
        options={dayOptions}
        placeholder="-"
        label="Day of birth"
        onChange={(value) => { setDay(value); }}
      />
      <Select
        value={month}
        options={monthOptions}
        placeholder="-"
        label="Month of birth"
        onChange={(value) => {
          setMonth(value);
          if (day && day.value > daysInMonth[value.value - 1]) {
            setDay(null);
          }
        }}
      />
      <Select
        value={year}
        className="year"
        options={yearOptions}
        placeholder="-"
        label="Year of birth"
        onChange={(value) => {
          setYear(value);
          if (month && month.value === 2 && day && day.value === 29) {
            if (!isLeapYear(value.value)) setDay(null);
          }
        }}
      />
    </div>
  );
}

export default Datepicker;
