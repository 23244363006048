import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Utils, VideoPlayer } from './index';
import bugsnagClient from '../../../bugsnagClient';

const clog = Utils.clog;

function WebRTCHelper() {
  if (!WebRTCHelper.isSupported()) {
    const error = 'WebRTC is not supported by your browser';
    throw new Error(error);
  }
  this.media = null;
  this.socket = null;
  this.socketID = 0;
  this.peerConnection = null;

  this.onMessage = this.onMessage.bind(this);
  this.gotRemoteTrack = this.gotRemoteTrack.bind(this);
  this.gotIceCandidate = this.gotIceCandidate.bind(this);
  this.initPeerConnection = this.initPeerConnection.bind(this);
}
WebRTCHelper.AMOUNT = 0;
WebRTCHelper.isSupported = function isSupportedWebRTC() {
  const { navigator } = window;
  return !!(navigator.getUserMedia
    || navigator.webkitGetUserMedia
    || navigator.mozGetUserMedia
    || navigator.msGetUserMedia
    || window.RTCPeerConnection);
};

WebRTCHelper.prototype.open = function open(url) {
  this.socketID = WebRTCHelper.AMOUNT++;
  const socket = new WebSocket(url);
  socket.addEventListener('open', this.initPeerConnection);
  socket.addEventListener('message', this.onMessage);
  this.socket = socket;
  this.url = url;
};
WebRTCHelper.prototype.close = function close() {
  const { socket } = this;
  if (!socket) return;
  socket.removeEventListener('open', this.initPeerConnection);
  socket.removeEventListener('message', this.onMessage);
  this.socket = null;
  this.socketID = null;
  this.media = null;
};
WebRTCHelper.prototype.setMedia = function setMedia(media) {
  this.media = media;
};
WebRTCHelper.prototype.initPeerConnection = function initPeerConnection() {
  const peerConnection = new window.RTCPeerConnection(null);
  peerConnection.stream_id = `remote${this.socketID}`;
  peerConnection.addEventListener('icecandidate', this.gotIceCandidate);
  peerConnection.addEventListener('track', this.gotRemoteTrack);
  return this.peerConnection = peerConnection;
};
WebRTCHelper.prototype.sendMessage = function sendMessage(data) {
  this.socket.send(JSON.stringify(data));
};
WebRTCHelper.prototype.gotIceCandidate = function gotIceCandidate(event) {
  const candidate = event.candidate;
  if (!candidate) return;
  this.sendMessage({
    type: 'candidate',
    stream_id: `local${this.socketID}`,
    label: candidate.sdpMLineIndex,
    id: candidate.sdpMid,
    candidate,
  });
};
WebRTCHelper.prototype.gotRemoteTrack = function gotRemoteTrack(event) {
  if (event.track.kind === 'video') {
    this.media.srcObject = event.streams[0];
  }
};
WebRTCHelper.prototype.onMessage = function onMessage(event) {
  let message = null;
  const _this2 = this;
  try {
    message = JSON.parse(event.data);
  } catch (error) {
    bugsnagClient.notify(error, { context: 'WebRTCPlayer' });
    console.error('error: ', error);
  }
  console.log('onMessage.message: ', message.type, message);
  switch (message.type) {
    case 'offer':
      console.log('on offer');
      const description = new window.RTCSessionDescription(message);
      _this2.peerConnection.setRemoteDescription(description)
        .then(() => {
          console.log('on offer: remote description has been set');
          return _this2.peerConnection.createAnswer();
        })
        .then((answer) => {
          console.log('on offer: answer (aka local description) has been created');
          return _this2.peerConnection.setLocalDescription(answer);
        })
        .then(() => {
          console.log('on offer: answer (aka local description) has been set');
          _this2.sendMessage(_this2.peerConnection.localDescription);
        })
        .then(() => {
          console.log('on offer: answer (aka local description) was sent');
        })
        .catch((error) => {
          console.log('error on offer: ', error);
        });
      break;
    case 'candidate':
      let candidate = null;
      try {
        candidate = new window.RTCIceCandidate(message.candidate);
        _this2.peerConnection.addIceCandidate(candidate);
      } catch (error) {
        bugsnagClient.notify(error, { context: 'WebRTCPlayer' });
        console.error('error: ', error);
      }
      break;
    default:
      break;
  }
};

class WebRTCPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.webrtc = new WebRTCHelper();
    clog('WebRTCPlayer::ctor', props.url);
  }

  componentWillUnmount() {
    this.webrtc.close();
    clog('WebRTCPlayer::dtor');
  }

  render() {
    const { media } = this.state;
    const {
      id, url, autoPlay, paused, volume, muted, controls, style, styleDIV,
    } = this.props;
    this.setPlayer(media, url, paused);
    return (
      <VideoPlayer
        id={id}
        autoPlay={autoPlay}
        muted={muted}
        volume={volume}
        controls={controls}
        style={style}// <video /> style
        styleDIV={styleDIV}// <div /> style
        onMedia={this.onMedia}
        onMuted={this.onMuted}
        onPaused={this.onPaused}
        onVolumeChange={this.onVolumeChange}
        onControlsChange={this.onControlsChange}
      />);
  }

  _init = (media, url, paused) => {
    this.webrtc.close();
    this.webrtc.open(url);
    this.webrtc.setMedia(media);
    this.setPaused(paused);
  }

  setPlayer = (media, url, paused) => {
    const { webrtc } = this;
    if (!media) return;
    if (!webrtc.url || (webrtc.url != url && url)) {
      this._init(media, url, paused);
    } else {
      this.setPaused(paused);
    }
  }

  setPaused = (paused) => {
    const { media } = this.state;
    if (!media) return;
    if (paused) {
      this._mediaReady(media).then(this._pause);
    } else {
      this._mediaReady(media).then(this._play);
    }
  }

  _mediaReady = (media) => {
    if (media.readyState >= 2) {
      return Promise.resolve(media);
    }
    return new Promise((resolve, reject) => {
      const handler = function (e) {
        if (this.readyState >= 2) {
          this.removeEventListener('loadedmetadata', handler);
          resolve(this);
        }
      };
      media.addEventListener('loadedmetadata', handler);
    });
  }

  _pause = media => (!media.paused) && media.pause();

  _play = media => media.paused && media.play();

  onMedia = (media) => {
    this.setState({ media });
  }

  onMuted = muted => this._callPropsFunc('onMuted', muted);

  onPaused = paused => this._callPropsFunc('onPaused', paused);

  onVolumeChange = volume => this._callPropsFunc('onVolumeChange', volume);

  onControlsChange = controls => this._callPropsFunc('onControlsChange', controls);

  _callPropsFunc = (func, ...args) => {
    if (typeof this.props[func] === 'function') {
      this.props[func](...args);
    }
  }
}
WebRTCPlayer.isSupported = WebRTCHelper.isSupported;
Object.defineProperties(WebRTCPlayer, {
  name: {
    value: 'webrtc-player',
    enumerable: true,
  },
  isWebRTC: {
    value: true,
    enumerable: true,
  },
});

WebRTCPlayer.defaultProps = {
  style: { width: '50%' },
  styleDIV: {},
  volume: 0.75,
  muted: false,
  controls: false,
  paused: true,
};

WebRTCPlayer.propTypes = {
  id: PropTypes.any,
  url: PropTypes.string,
  muted: PropTypes.bool,
  paused: PropTypes.bool,
  volume: PropTypes.number,
  controls: PropTypes.bool,
  autoPlay: PropTypes.bool,
  style: PropTypes.shape(),
  styleDIV: PropTypes.object,
  onMuted: PropTypes.func,
  onPaused: PropTypes.func,
  onVolumeChange: PropTypes.func,
};

export default WebRTCPlayer;
