const { 
  GET_PROTOCOL,
  SET_PROTOCOL,
  SET_PROTOCOL_SQUAD_ID,
  UNSET_PROTOCOL_SQUAD_ID,
  REFRESH_PROTOCOL,
} = require('./actiontypes').default;

const initialState = {
  squadid: null,
  data: null,
  error: null,
  isFetching: false,
  needToRefresh: false,
};

const protocolReduser = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROTOCOL_SQUAD_ID:
      return { ...initialState, squadid: action.payload };
    case UNSET_PROTOCOL_SQUAD_ID:
      return { ...initialState };
    case GET_PROTOCOL:
        return {  ...state, isFetching: true, needToRefresh: false };
    case SET_PROTOCOL:
      if (action.error) {
        return { ...state, error: action.error, isFetching: false };
      }
      return { ...state, data: action.payload, isFetching: false };
    case REFRESH_PROTOCOL:
      if (!state.data) {
        return state;
      }
      return { ...state, needToRefresh: true };
    default:
      return state;
  }
};

export default protocolReduser;