const { 
  GET_PROTEST,
  SET_PROTEST,
  FETCH_PROTEST_LIST,
  SET_PROTEST_LIST,
  FETCH_PROTEST_LIST_OF_PARTY,
  SET_PROTEST_LIST_OF_PARTY,
  ACCEPT_PROTEST,
  REJECT_PROTEST,
} = require('./actiontypes').default;

const initialState = {
  data: null,
  error: null,
  isFetching: false,
  isFetchingParty: false,
  saved: false,
  isReviewed: false,
};

const protestReduser = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROTEST: case ACCEPT_PROTEST: case REJECT_PROTEST:
        return {  ...state, isFetching: true, saved: false };
    case SET_PROTEST:
      if (action.error) {
        return { ...state, error: action.error, isFetching: false };
      }
      return { ...state, data: action.payload, isFetching: false, saved: action.saved || false };
    case FETCH_PROTEST_LIST:
        return {  ...state, isFetching: true };
    case FETCH_PROTEST_LIST_OF_PARTY:
      return { ...state, isFetchingParty: true };
    case SET_PROTEST_LIST:
      if (action.error) {
        return { ...state, error: action.error };
      }
      return { ...state, data: action.payload, isFetching: false };
    case SET_PROTEST_LIST_OF_PARTY:
      if (action.error) {
        return { ...state, error: action.error };
      }
      return { ...state, data: { ...state.data, party: action.payload }, isFetchingParty: false, isReviewed: !action.payload.find(p => !p.closed) };
    default:
      return state;
  }
};

export default protestReduser;