import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import config from '../../../config';
import defaultAvatar from '../Opponent/images/default.png';
import Flag from '../Flag';
import { Button } from '../Button';
import Avatar from '../Avatar';

import './style.scss';

class PendingGame extends React.Component {
  static propTypes = {
    userid: PropTypes.number,
    fullname: PropTypes.string,
    login: PropTypes.string,
    photo: PropTypes.string,
    flag: PropTypes.string,
    timestart: PropTypes.string,
    bet: PropTypes.number,
    onTrigger: PropTypes.func,
    intl: intlShape,
    avgscore: PropTypes.number,
  };

  render() {
    const {
      flag, photo, fullname, login, onTrigger, intl, avgscore,
    } = this.props;
    return (
      <div className="active_game_card">
        <div className="active_game_card__avatar">
          <Avatar
            uri={photo ? `${config.FETCH.img}/${photo}` : defaultAvatar}
            flag={flag}
            avgscore={avgscore}
          />
          <div className="active_game_card__opponent">
            <div className="active_game_card__fullname">{fullname}</div>
            <div className="active_game_card__login">{login}</div>
          </div>
        </div>
        <Button className="active_game_card__button"
          onClick={onTrigger}
          size="md"
        >
          {intl.formatMessage({ id: 'ReturnAtGameCard', defaultMessage: 'Return to game' })}
        </Button>
      </div>
    );
  }
}

export default injectIntl(
  connect(
    () => {},
    {},
  )(PendingGame),
);
