import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';

import { Button } from '../../Button';
import Checkbox from '../../Checkbox';
import OBModal from '../../Modal';
import UserDiskDiagram from '../../UserDiskDiagram';
import {
  fetchVideoFiles,
  fetchUserDisk,
  bindVideoFilesToUser,
  getUserTariff,
  setTariffCallback,
} from '../../../../libs/reducers/actions';
import { setCommonNotification } from '../../../../libs/reducers/commonReducer';

import './style.css';

class ModalSaveBattle extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    intl: intlShape,
    squadid: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {

      visible: props.visible,
      owned: props.owned,

      allChecked: false,
      ownAllChecked: false,
      opponentAllChecked: false,

      players: [],
      cameras: [],

      fullSpace: 0,
      freeSpace: 0,
      busySpace: 0,
      needSpace: 0,

      diskLoaded: false,
      filesLoaded: false,

      saving: false,
      saved: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.saving && !prevState.saved && prevState.busySpace < nextProps.userdisk.data.busySpace) {
      nextProps.onSubmit();
      return { saved: true, saving: false };
    }
    return null;
  }

  componentDidMount = () => {
    this.props.fetchUserDisk();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      squadid,
      userdisk,
      videofiles,
      user,
    } = this.props;

    if (
      squadid
      && !prevState.diskLoaded
      && prevProps.userdisk !== userdisk
      && !userdisk.isFetching
      && !userdisk.error
      // && !_.isEmpty(userdisk.data)
    ) {
      if (!_.isEmpty(userdisk.data)) {
        const { fullSpace, busySpace, freeSpace } = userdisk.data;

        this.setState({
          fullSpace,
          freeSpace,
          busySpace,
          diskLoaded: true,
        });
      }
      if (!prevState.filesLoaded) {
        this.props.fetchVideoFiles({ squadid });
      }
    }
    if (
      squadid
      && !_.isEmpty(user)
      && !prevState.filesLoaded
      && prevProps.videofiles !== videofiles
      && !videofiles.isFetching
      && !videofiles.error
      && !_.isEmpty(videofiles.files)
    ) {
      const files = videofiles.files[squadid];
      if (files) {
        const players = [];
        files.forEach((f) => {
          if (!players.find(p => p.id === f.playerid)) players.push({ id: f.playerid, name: `${f.firstname} ${f.lastname}` });
        });
        let cameras = [];

        files.forEach((file) => {
          let usercamera = cameras.find(camera => camera.userid === file.userid);
          if (!usercamera) {
            usercamera = {
              userid: file.userid,
              playerid: file.playerid,
              name: `${file.firstname} ${file.lastname}`,
              login: file.login,
            };
            cameras.push(usercamera);
          }
          usercamera[file.type] = {
            id: file.id,
            size: file.size,
            savedId: file.savedid,
            checked: !!file.savedid,
          };
        });
        this.setState({ players, cameras, filesLoaded: true });
      }
    }
  }

  cancel = () => {
    this.props.visible = false;
    this.setState({ visible: false });
  };

  submit = () => {
    const { cameras } = this.state;
    const files = {
      forInsert: [],
      forRestore: [],
      forDelete: [],
    };
    const storageMy = this.props.userdisk.data.freeSpace;
    let storageInsert = 0;
    let storageSave = 0;

    cameras.forEach((camera) => {
      const cams = [camera.skittles, camera.track, camera.scoring];
      cams.forEach((cam) => {
        const { id, checked, savedId } = cam;

        if (id && checked && !savedId) {
          storageInsert += cam.size;
          files.forInsert.push(id);
        }
        if (id && checked && savedId) {
          storageSave += cam.size;
          files.forRestore.push(savedId);
        }
        if (id && !checked && savedId) {
          files.forDelete.push(savedId);
        }
      });
    })
    this.props.bindVideoFilesToUser({ files });
    this.setState({ saving: true });
  };

  selectDisk = () => {
    this.props.visible = false;
    this.setState({ visible: false });
  }

  getFullSize = (playerid) => {
    let fullsize = 0;
    this.state.cameras.filter(camera => (playerid ? (camera.playerid === playerid) : true)).forEach((camera) => {
      fullsize += camera.track && camera.track.size || 0;
      fullsize += camera.scoring && camera.scoring.size || 0;
      fullsize += camera.skittles && camera.skittles.size || 0;
    });
    return fullsize;
  }

  calcSize = (playerid) => {
    let fullsize = 0;
    this.state.cameras.filter(camera => (playerid ? (camera.playerid === playerid) : true))
      .forEach((camera) => {
        ['track', 'scoring', 'skittles']
          .forEach((type) => { fullsize += camera[type].checked && camera[type].savedId ? camera[type].size : 0; });
      });
    return fullsize;
  }

  toGigaBytes = (size) => {
    let _size = _.round(size / (1000 ** 3), 2);
    if (_size === 0) {
      _size = _.round(size / (1000 ** 3), 3);
    }
    return [_size, 'Gb'];
  }

  toMegaBytes = (size) => {
    const _size = _.round(size / (1000 ** 2));
    return [_size, 'Mb'];
  }

  renderCompetitor = (cameras) => {
    const { user, intl } = this.props;

    return (
      <div className="save_battle">
        <div>
          <Checkbox
            checked={cameras.track.checked && cameras.skittles.checked && cameras.scoring.checked}
            onChange={() => this.checkAll(cameras.playerid)}
          >
            {
              cameras.userid === user.id
                ? intl.formatMessage({ id: 'save_battle_own_cameras', defaultMessage: 'My' })
                : cameras.login
            }
            <span className="save_battle__space">&nbsp;({this.toGigaBytes(this.getFullSize(cameras.playerid)).join('')})</span>
          </Checkbox>
        </div>
        <div className="save_battle__camera_list">
          <div className="save_battle__structure">
            <svg width="16" height="78" viewBox="0 0 16 78" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect y="77" width="16" height="1" fill="#25292C" />
              <rect y="47" width="16" height="1" fill="#25292C" />
              <rect y="17" width="16" height="1" fill="#25292C" />
              <rect width="1" height="77" fill="#25292C" />
            </svg>
          </div>
          <div className="save_battle__checkbox_list">
            <div>
              <Checkbox
                checked={cameras.track.checked}
                onChange={() => this.checkOne(cameras.playerid, 'track')}
              >
                {intl.formatMessage({ id: 'save_battle_track_camera', defaultMessage: 'Track' })}
                <span className="save_battle__space">&nbsp;({this.toGigaBytes(cameras.track.size).join('')})</span>
              </Checkbox>
            </div>
            <div>
              <Checkbox
                checked={cameras.scoring.checked}
                onChange={() => this.checkOne(cameras.playerid, 'scoring')}
              >
                {intl.formatMessage({ id: 'save_battle_scoring_camera', defaultMessage: 'Scoring' })}
                <span className="save_battle__space">&nbsp;({this.toGigaBytes(cameras.scoring.size).join('')})</span>
              </Checkbox>
            </div>
            <div>
              <Checkbox
                checked={cameras.skittles.checked}
                onChange={() => this.checkOne(cameras.playerid, 'skittles')}
              >
                {intl.formatMessage({ id: 'save_battle_skittles_camera', defaultMessage: 'Skittles' })}
                <span className="save_battle__space">&nbsp;({this.toGigaBytes(cameras.skittles.size).join('')})</span>
              </Checkbox>
            </div>
          </div>
        </div>
      </div>
    );
  }

  checkOne = (playerid, type) => {
    const cameras = [ ...this.state.cameras ];
    const cameraIndex = cameras.findIndex(camera => camera.playerid === playerid);
    const value = !cameras[cameraIndex][type].checked;
    let needSpace = +this.state.needSpace;

    if (value) {
      needSpace += cameras[cameraIndex][type].size;
    } else {
      needSpace -= cameras[cameraIndex][type].size;
    }
    cameras[cameraIndex][type].checked = value;

    this.setState({ cameras, needSpace });
  }

  checkAll = (value, playerid) => {
    const types = ['skittles', 'scoring', 'track'];
    let needSpace = +this.state.needSpace;
    const cameras = [ ...this.state.cameras ].map(camera => {
      if (!(playerid && playerid !== camera.playerid)) {
        types.forEach(type => {
          if (value && !camera[type].checked) {
            needSpace += camera[type].size;
          }
          if (!value && camera[type].checked) {
            needSpace -= camera[type].size;
          }
          camera[type].checked = value;
        });
      }

      return camera;
    });

    this.setState({ cameras, needSpace });

    /* const cameras = [ ...this.state.cameras ];
    const types = ['skittles', 'scoring', 'track'];

    let targetState = null;
    if (!playerid) targetState = !cameras.every(camera => types.every(type => camera[type].checked))
    cameras.filter(camera => playerid ? camera.playerid === playerid : true).forEach((camera, index) => {
      let cameraIndex = index;
      if (playerid) targetState = !types.every(type => camera[type].checked);
      // else cameraIndex = cameras.findIndex(camera => camera.playerid === playerid);
      types.forEach(type => {
        cameras[cameraIndex][type].checked = targetState;
      })
    })
    this.setState({ cameras }); */
  }

  render() {
    const types = ['skittles', 'scoring', 'track'];
    const {
      visible,
      intl,
      onCancel,
      usertariff,
      squadid,
      history,
      setTariffCallback,
    } = this.props;
    const {
      cameras,
      fullSpace,
      freeSpace,
      busySpace,
      needSpace,
      saving,
      players,
    } = this.state;
    const overflow = freeSpace - needSpace < 0;
    const All = (
      <div className="save_battle__select_cameras">
        <span>{intl.formatMessage({ id: 'save_battle_select_cameras', defaultMessage: 'Select cameras to save' })}</span>
        {players.length === 1 ? '' : <Checkbox
          checked={cameras.every(camera => types.every(type => camera[type].checked))}
          onChange={(value) => this.checkAll(value)}
        >
          {intl.formatMessage({ id: 'save_battle_all_cameras', defaultMessage: 'All cameras' })}
          <span className="save_battle__space">&nbsp;({this.toGigaBytes(this.getFullSize()).join('')})</span>
        </Checkbox>}
      </div>
    );
    const Groups = [];
    Groups.push(
      <div className="save_battle__subtitle">
        {
          intl.formatMessage({
            id: 'save_battle_description',
            defaultMessage: 'Save video game recording to your archive? You can view or save entries in “My Battles”',
          })
        }
      </div>,
    );
    if (this.props.usertariff) {
      Groups.push(
        <div class="save_battle__checkbox_container">
          {All}
          <div class="save_battle__flexbox">
            {cameras.map(this.renderCompetitor)}
          </div>
        </div>
      );
      Groups.push(
        <div class="save_battle__info">
          <div class="save_battle__space_info">
            {
              fullSpace !== 0
                ? <div className="save_battle__space_info_mh">
                  <div className="save_battle__all_space">
                    {intl.formatMessage({ id: 'save_battle_full_space', defaultMessage: 'Your disk' })}
                    <span>&#58;&nbsp;{this.toGigaBytes(fullSpace)}</span>
                  </div>
                  <div className="save_battle__free_space">
                    {intl.formatMessage({ id: 'save_battle_free_space', defaultMessage: 'Free' })}
                    <span>&#58;&nbsp;{this.toGigaBytes(freeSpace)}</span>
                  </div>
                  <div className="save_battle__need_space">
                    {intl.formatMessage({ id: 'save_battle_need_space', defaultMessage: 'New video' })}
                    <span>&#58;&nbsp;{this.toGigaBytes(needSpace)}</span>
                  </div>
                  {
                    overflow
                      ? <div className="save_battle__overflow_space">
                        {intl.formatMessage({ id: 'save_battle_overflow_space', defaultMessage: 'Not enough disk space' })}
                      </div>
                      : null
                  }
                </div>
                : <div className="save_battle__no_space">
                  {intl.formatMessage({ id: 'save_battle_no_disk', defaultMessage: 'You do not have a save disk' })}
                </div>
            }
          </div>
          {
            fullSpace !== 0
              ? <div className="save_battle__space_diagram">
                <div className="save_battle__space_indicator">
                  {this.toGigaBytes(fullSpace)[0]}
                  <span>{this.toGigaBytes(fullSpace)[1]}</span>
                </div>
                <UserDiskDiagram
                  busy={_.round((busySpace / fullSpace) * 100)}
                  need={_.round((needSpace / fullSpace) * 100)}
                />
              </div>
              : <div className="save_battle__select_btn">
                <Button onClick={this.selectDisk} kind="aquamarine">
                  {intl.formatMessage({ id: 'save_battle_select', defaultMessage: 'Select' })}
                </Button>
              </div>
          }
          {
            fullSpace !== 0 && fullSpace < 10000000000 && overflow
              ? <div className="save_battle_no_tariff">
              <div className="save_battle_offer_text">
                {intl.formatMessage({ id: 'SaveModalNoSpace', defaultMessage: 'Not enough disk space for selected records, please enable PRO tariff with sufficient volume.' })}
              </div>
              <Button
                kind="aquamarine"
                onClick={
                  () => {
                    setTariffCallback(`OpenSaveModal-${squadid}`);
                    history.push('/account/tariffs');
                  }
                }
                loading={saving}>
                {intl.formatMessage({ id: 'BuyPROshort', defaultMessage: 'Buy PRO' })}
              </Button>
            </div>
              : null
          }
        </div>,
      );
    } else {
      Groups.push(<div className="save_battle_no_tariff">
        <div className="save_battle_offer_text">
          {intl.formatMessage({ id: 'SaveModalNoTariff', defaultMessage: 'You have no active PRO subscription for saving the video' })}
        </div>
        <Button
          kind="aquamarine"
          onClick={
            () => {
              setTariffCallback(`OpenSaveModal-${squadid}`);
              history.push('/account/tariffs');
            }
          }
          loading={saving}>
          {intl.formatMessage({ id: 'BuyPROshort', defaultMessage: 'Buy PRO' })}
        </Button>
      </div>);
    }
    return (
      <OBModal
        title={intl.formatMessage({ id: 'save_battle_title', defaultMessage: 'Save video' })}
        visible={visible}
        width={395}
        onOk={() => {}}
        onCancel={() => {}}
        className={'save_battle__modal'}
        okText={intl.formatMessage({ id: 'SaveAsKeepAlias', defaultMessage: 'Close' })}
        cancelText={intl.formatMessage({ id: 'CloseAsCancelAlias', defaultMessage: 'Close' })}
        closable={false}
        footer={[
          <div key="buttons">
            <Button
              kind="aquamarine"
              bordered="true"
              onClick={onCancel}
              disabled={saving ? 'disabled' : ''}
            >
              {intl.formatMessage({ id: 'save_battle_cancel', defaultMessage: 'Don`t save' })}
            </Button>
            <Button
              kind="aquamarine"
              onClick={this.submit}
              disabled={!usertariff || fullSpace === 0 || overflow || needSpace === 0}
              loading={saving}>
              {intl.formatMessage({ id: 'save_battle_save', defaultMessage: 'Save' })}
            </Button>
          </div>,
        ]}
      >
        {Groups}
      </OBModal>
    );
  }
}

export default injectIntl(
  connect(
    state => ({
      videofiles: state.videofiles,
      user: state.users.user,
      userdisk: state.userdisk,
      usertariff: state.billing.usertariff,
    }),
    {
      fetchVideoFiles,
      fetchUserDisk,
      bindVideoFilesToUser,
      getUserTariff,
      setTariffCallback,
      setCommonNotification,
    },
  )(ModalSaveBattle),
);
