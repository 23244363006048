import React, {useEffect, useState} from 'react';
import UploadList, {getBase64} from "../adminRoot/bannerMainMobile/components/UploadList";
import {connect} from "react-redux";
import {uploadBCBanners, getBCAdminBanners} from "../../libs/reducers/adminReducer";
import BowlingCentersTable from "./bowlingCenterComponents/BowlingCentersTable";
import {store} from '../../store'

const BowlingCenterBanners = (props) => {
  const {
    allBowlingCenters,
    uploadBCBanners,
    bcBannersBack = [],
    user,
    bowlingCenterPermissionsIds,
  } = props

  const [visibleBanners, setVisibleBanners] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [activeBC, setActiveBC] = useState({});
  const dispatch = store.dispatch
  const [maxImagesCount, setMaxImagesCount] = useState(0);
  const [bcNamesArray, setBcNamesArray] = useState([]);
  const [bowlingCenters, setBowlingCenters] = useState([]);
  const [isSaved, setIsSaved] = useState(false);
  const [isWarningVisible, setIsWarningVisible] = useState(false);

  function getBCArray() {
    // получаем массив ключей объекта activeBC
    const arrayFromKeys = Object.keys(activeBC)
    // и фильтруем так что-бы на выходе получился массив ключей у которых значение true
    return arrayFromKeys.filter((name)=> activeBC[name])
  }

  useEffect(()=> {
    if (allBowlingCenters && bowlingCenterPermissionsIds){
      setBowlingCenters(allBowlingCenters.filter(bc =>  bowlingCenterPermissionsIds.some(pbc => pbc === bc.id)))
    }
  },[allBowlingCenters, bowlingCenterPermissionsIds])

  useEffect(()=> {
    const tempBCNamesArray = []
    bowlingCenters.forEach(bc => tempBCNamesArray.push(bc.name))
    setBcNamesArray(tempBCNamesArray)
  }, [bowlingCenters])


  useEffect(()=> {
    if (bcNamesArray.length){
      dispatch(getBCAdminBanners({bowlingCenters: bcNamesArray}))
    }
  },[bcNamesArray.length])

  useEffect(() => {
    setVisibleBanners([...bcBannersBack])
    if (bcBannersBack.length || bcNamesArray.length){
      activeBCInit()
    }
  },[bcBannersBack, bcNamesArray.length])

  useEffect(() => {
    // newCount()  для динамическокого максимального числа баннеров
    if (visibleBanners){
      setMaxImagesCount(newCount())
    }
  }, [visibleBanners, activeBC])

  function newCount () {
    let activeBCArr = bcNamesArray.some(name => {
      if(activeBC[name]) return activeBC[name].visible
    })
    if (!activeBCArr) return visibleBanners.length + 2
    let currentCount = bcNamesArray.some(name => {
      if (activeBC[name]){
        return  activeBC[name].visible && activeBC[name].count >= 2
      }
    })
    if (currentCount) return visibleBanners.length
    return visibleBanners.length + 1
  }


  function activeBCInit (){
    let tempActiveBc = {}

    function addBC(name) {
      let count = 0
      if (bcBannersBack.length){
        bcBannersBack.forEach(banner => {
          banner.bc_name.forEach(bannerName => {
            if (bannerName === name) {
              count += 1
            }
          })
        })
      }

      if (bcBannersBack.length){
        tempActiveBc[name] = {
          count: count,
          visible: !!count
        };

      } else {
        tempActiveBc[name] = {
          count: count,
          visible: true
        };
      }
    }

    bcNamesArray.forEach(name => {
      addBC(name)
    });
    setActiveBC({...tempActiveBc});
  }


  async function onSaveSuccess() {
    setIsWarningVisible(false)

    function resetIsSaved (){
      setIsSaved(false)
    }

    setIsSaved(true);
    setTimeout(resetIsSaved, 5000);
  }

  function changeCheck() {
    return  visibleBanners.some(banner => banner.status === 'changed')
  }


  useEffect(() => {
    if (changeCheck()){
      setIsWarningVisible(true)
    }
  }, [visibleBanners]);

  const onSave = async () => {
    setSelectedImage(null);
    const filesNew = [];
    const filesOld = [];
    let base64List = [];
    onSaveSuccess()

    visibleBanners.map((banner) => {
      if ((banner.bc_name.length || banner.status === 'deleted') && banner.status !== 'done'){
        if (banner.status === 'uploading') {
          filesNew.push(banner);
        } else {
          const urlArr = banner.url.split('/');
          const lengthArr = urlArr.length;

          const nameFile = (lengthArr > 1)
              ? `${urlArr[lengthArr - 2]}/${urlArr[lengthArr - 1]}`
              : null;

          filesOld.push({
            name: nameFile,
            link: banner.link,
            clickable: banner.clickable,
            linkType: banner.linkType,
            appScreenNavOpt: banner.appScreenNavOpt,
            navigationUrl: banner.navigationUrl,
            bc_name: banner.bc_name,
            creator: user.id,
            status: banner.status
          });
        }
      }
    });

    if (filesNew.length) {
      const base64PromiseList = filesNew.map(async (file) => {
        const base64 = await getBase64(file.originFileObj);
        return {
          base64,
          link: file.link,
          clickable: !!file.clickable,
          linkType: file.linkType,
          bc_name: file.bc_name,
          creator: user.id,
          status: file.status
        };
      });

      base64List = await Promise.all(base64PromiseList);
    }

    if (base64List.length || filesOld.length){
      uploadBCBanners({
        base64: base64List,
        files: filesOld,
        bowlingCenters: bcNamesArray
      });
    }
  };


  return (
    <div className={'bc_banners_container'}>
      <div className={'banners_bowling_centers_container'}>
        <div className={'textGray'}>Bowling Center </div>
        <BowlingCentersTable
          bowlingCenters={bowlingCenters}
          activeBC={activeBC}
          setActiveBC={setActiveBC}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          visibleBanners={visibleBanners}
        />
      </div>
      <UploadList
        visibleBanners={visibleBanners}
        setVisibleBanners={setVisibleBanners}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
        maxImages={maxImagesCount}
        adminType={'AdminBC'}
        activeBCNames={getBCArray()}
        activeBC={activeBC}
        onSave={onSave}
        isSaved={isSaved}
        isWarningVisible={isWarningVisible}
        setIsWarningVisible={setIsWarningVisible}
      />

    </div>
  );
};

export default connect((state) => ({
  allBowlingCenters: state.bowlingcenters.bowlingcenters.data,
  bowlingCenterPermissionsIds: state.users.bowlingCenterPermissions.ids,
  bcBannersBack: state.admin.bcAdminBanners,
  user: state.users.user,
  bcBannersFetch: state.admin.bcAdminBannersFetch
}),
  {
    uploadBCBanners
  }
  )(BowlingCenterBanners);
