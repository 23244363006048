import React from 'react';
import { connect } from 'react-redux';

import './style.scss';

const Tabs = ({
  intl,
  tabList = [],
  activeTabKey,
  onClickTab = () => {},
}) => {
  return (
    <ul className="tab__container">
      {tabList.map((tab, index) => (
        <li
          onClick={() => onClickTab(tab.key)}
          className={`tab__elem ${activeTabKey === tab.key ? 'active' : ''}`}
          key={tab.key || index}
        >
          {tab.name}
        </li>
      ))}
    </ul>
  )
};

export default connect(
  state => ({}),
  {}
)(Tabs);
