import React, { useEffect, useState } from 'react';
import isemail from 'isemail';
import axios from 'axios';

import Success from './common/notAvailable';
import Select from './common/select';
import Input from './common/input';
import Radio from './common/radio';

import ParticipantForm from './participantForm';

import TermsAndConditionModal from '../../landing/termsAndConditionModal';
import PrivacyPolicyModal from '../../landing/privacyPolicyModal';

import config from '../../../config';

const countries = [
  'Azerbaijan',
  'Belarus',
  'Belgium',
  'Bulgaria',
  'Catalonia',
  'Croatia',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'England',
  'Estonia',
  'Finland',
  'France',
  'Germany',
  'Gibraltar',
  'Greece',
  'Hungary',
  'Iceland',
  'Ireland',
  'Israel',
  'Italy',
  'Jersey',
  'Kosovo',
  'Latvia',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'Netherlands',
  'N. Ireland',
  'Norway',
  'Poland',
  'Portugal',
  'Romania',
  'Russia',
  'San Marino',
  'Scotland',
  'Serbia',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sweden',
  'Switzerland',
  'Turkey',
  'Ukraine',
  'Wales',
];

const radioOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

const countryOptions = countries.map(courty => ({ value: courty, label: courty }));

const errorMessage = 'Error';

function FormB() {
  const [country, setCountry] = useState(null);
  const [sended, setSended] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [canSend, setCanSend] = useState(false);
  const [error, setError] = useState(null);
  
  const [visibleTermsAndCondition, setVisibleTermsAndCondition] = useState(false);
  const [visiblePrivacyPolicy, setVisiblePrivacyPolicy] = useState(false);
  
  const checkCanSend = () => {
    let result = true;
    if (!participants.length) {
      result = false;
    } else {
      result = !participants.some(participant => !participant.gender
        || !participant.group
        || !participant.name
        || !participant.surname
        || !participant.birthday
        || !participant.photo);
    }
    setCanSend(result);
  };

  useEffect(() => {
    checkCanSend();
  }, [participants]);

  return (
    <div className="formB">
      <div className="form-title">Form B</div>
      <div className="form">
        {
          sended
            ? <Success type="success"/>
            : <div className="formA-body">
              <Select label="Country" options={countryOptions} onChange={value => setCountry(value)}/>
              {
                country && (
                  <div className="participants">
                    {
                      participants.map((participant, index) => <ParticipantForm
                        index={index + 1}
                        key={participant.innerId}
                        onFieldChange={(filedName, value) => {
                          participants[index][filedName] = value;
                          checkCanSend();
                          setParticipants([...participants]);
                        }}
                        onDelete={() => {
                          setParticipants(participants.filter((p, i) => index !== i));
                        }}
                        participants={participants}
                      />)
                    }
                    { participants.length < 4 && (
                      <div
                        className="add-participant-btn"
                        onClick={() => {
                          setParticipants([...participants, { innerId: +new Date() }]);
                        }}
                      >
                        + Add Participant
                      </div>
                    )}
                    <div className="submit">
                      <div className="agreenemt">
                        {'Clicking Submit I agree with the '}
                        <span className="link" onClick={() => setVisibleTermsAndCondition(true)}>
                          terms and condition
                        </span>
                        {' and the '}
                        <span className="link" onClick = {() => setVisiblePrivacyPolicy(true)}>
                          privacy policy
                        </span>
                      </div>
                      <button
                        className={`l-btn ${!canSend ? 'disabled' : ''}`}
                        disabled={!canSend}
                        onClick={() => {
                          const participantsByGender = {
                            M: [],
                            F: [],
                          };
                          participants.forEach((p) => {
                            participantsByGender[p.gender].push(p);
                          });
                          if (participantsByGender.M.length > 2 || participantsByGender.F.length > 2) {
                            setError(errorMessage);
                            return;
                          }
                          if (participantsByGender.M.length === 2) {
                            if (participantsByGender.M[0].group === participantsByGender.M[1].group) {
                              setError(errorMessage);
                              return;
                            }
                          }
                          if (participantsByGender.F.length === 2) {
                            if (participantsByGender.F[0].group === participantsByGender.F[1].group) {
                              setError(errorMessage);
                              return;
                            }
                          }
                          axios.post(`${config.FETCH.url}/EYLT2021/formB`, {
                            federation: country.value,
                            participants,
                          }).then(() => setSended(true));
                        }}
                      >
                        Submit
                      </button>
                    </div>
                    <div>{error}</div>
                  </div>
                )
              }
            </div>
        }
      </div>
      <TermsAndConditionModal visible={visibleTermsAndCondition} onClose={() => setVisibleTermsAndCondition(false)} />
      <PrivacyPolicyModal visible={visiblePrivacyPolicy} onClose={() => setVisiblePrivacyPolicy(false)} />
    </div>
  );
}

function FormA() {
  const [federation, setFederation] = useState('');
  const [sendingTeam, setSendingTeam] = useState('');
  const [payer, setPayer] = useState('');
  const [adress, setAdress] = useState('');
  const [reg, setReg] = useState('');
  const [bank, setBank] = useState('');
  const [swift, setSwift] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState({
    vat: false,
    swift: false,
    email: false,
  });
  const [sended, setSended] = useState(false);
  const buttonADisabled = !federation || (sendingTeam === '') || (sendingTeam && (!payer || !adress || !reg || !bank || !swift || !accountNumber || !email));
  const onFormConfirm = () => {
    if (sendingTeam) {
      const emailError = !isemail.validate(email);
      if (!emailError) {
        setError({
          vat: false,
          swift: false,
          email: false,
        });
        axios.post(`${config.FETCH.url}/EYLT2021/formA`, {
          federation: federation.value,
          sendingTeam,
          payer,
          adress,
          reg,
          bank,
          swift,
          accountNumber,
          email,
        }).then(() => setSended(true));
      } else {
        setError({
          vat: false,
          swift: false,
          email: emailError,
        });
      }
    } else {
      axios.post(`${config.FETCH.url}/EYLT2021/formA`, {
        federation: federation.value,
        sendingTeam,
        payer,
        adress,
        reg,
        bank,
        swift,
        accountNumber,
        email,
      });
      setSended(true);
    }
  };
  return (
    <div className="formA">
      <div className="form-title">Form A <span className="form-subtitle">deadline - June 10, 2021</span></div>
      {
        sended
          ? <Success type="success"/>
          : (
            <div className="formA-body">
              <div className="row">
                <Select
                  label="Federation"
                  options={countryOptions}
                  onChange={value => setFederation(value)}
                />
                <Radio
                  label="We will send a team?"
                  options={radioOptions}
                  onChange={(value) => {
                    setSendingTeam(value);
                    if (!value) {
                      setPayer('');
                      setAdress('');
                      setReg('');
                      setBank('');
                      setSwift('');
                      setAccountNumber('');
                      setEmail('');
                    }
                  }}
                />
              </div>
              {
                (federation && sendingTeam) && (
                  <>
                    <Input label="Payer" onChange={value => setPayer(value)}/>
                    <Input label="Adress" onChange={value => setAdress(value)}/>
                    <div className="row">
                      <Input label="REG.Number" onChange={value => setReg(value)}/>
                      <Input label="Bank" onChange={value => setBank(value)}/>
                    </div>
                    <div className="row">
                      <Input label="SWIFT" onChange={value => setSwift(value)}/>
                      <Input label="Account Number" onChange={value => setAccountNumber(value)}/>
                    </div>
                    <Input label="E-mail" onChange={value => setEmail(value)} error={error.email} errorMessage="Invalid email"/>
                  </>
                )
              }
              <button
                className={`l-btn ${buttonADisabled ? 'disabled' : ''}`}
                disabled={buttonADisabled}
                onClick={() => onFormConfirm()}
              >
                Submit form A
              </button>
            </div>
          )
      }
    </div>
  );
}

function Forms() {
  return (
    <div>
      <FormA/>
      {/* <FormB/> */}
    </div>
  );
}

export default Forms;
