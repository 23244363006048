const {
  TOURNAMENTS_SET,
  LOGOUT,
  TOURNAMENTS_BATTLE_TAKEPART,
  TOURNAMETS_SET_PLAYERS,
  TOURNAMENTS_FETCHING,
  ACCOUNT_TOURNAMENTS_FETCH,
  ACCOUNT_TOURNAMENTS_SET,
  TOURNAMENTS_CREATE_TOURNAMENT_FETCH,
  TOURNAMENTS_UPDATE_INFO_FETCH,
  TOURNAMENTS_DELETE_FETCH,
  TOURNAMENTS_CREATE_TOURNAMENT_SET,
  TOURNAMENTS_UPDATE_INFO_SET,
  TOURNAMENTS_DELETE_SET,
  WATCH_TOURNAMENT,
  TOURNAMENTINFO_FETCH,
  TOURNAMENTINFO_SET,
  UPDATE_TOURNAMENT_PLAYERS,
  TOURNAMENTS_BATTLE_LOADING,
  ADD_BATTLE_TO_TOURNAMENT,
  CANCEL_BATTLE_TOURNAMENT,
  TOURNAMENT_INFO_FOR_EDIT_FETCH,
  TOURNAMENT_INFO_FOR_EDIT_SET,
  DELETE_GAME_TOURNAMENT,
  SET_TOURNAMENT_TYPE_LIST,
  FETCH_TOURNAMENT_TYPE_LIST,
  TOURNAMNET_BIND_BC_FETCH,
  TOURNAMNET_UNBIND_BC_FETCH,
  TOURNAMNET_BIND_END,
  TOURNAMNET_UNBIND_END,
  ADD_TOURNAMENT_SETTING,
  SET_TOURNAMENT_SETTING,
  DELETE_TOURNAMENT_SETTING_FETCH,
  DELETE_TOURNAMENT_SETTING_SET,
  SET_TOURNAMENT_PLAYER_RESULT,
  SET_MODAL_DEBETING_MONEY_FOR_PARTICIPATION,
  CLEAR_MODAL_DEBETING_MONEY_FOR_PARTICIPATION,
  CONFIRM_TOURNAMENT_RESULTS,
  SET_PLAYER_REPLAY,
  SET_LEADERBOARD_TABLE_STAGES,
  TOURNAMENTS_BATTLE_TAKEPART_FINISH,
  UPLOAD_CARD_DECORATION_FETCH,
  UPLOAD_CARD_DECORATION,
  SET_LEADER_BOARD,
  SET_VISIBILITY_BATTLE,
  SEND_INVITE_TOURNAMENT_NOTIFICATION,
  SEND_INVITE_TOURNAMENT_NOTIFICATION_FETCHED,
  GET_SQUAD_ID_BY_USER_ID_FETCHED,
  REMOVE_SQUAD_ID_BY_USER_ID,
  POST_REMOVED_PLAYER_INFO,
  REMOVE_PLAYER_REMOVED_INFO,
} = require('./actiontypes').default;

const initialState = {
  accountTournamentsFetching: false,
  tournaments: [],
  accountTournaments: [],
  tournamentInfo: false,
  tournamentId: false,
  InfoTournament: [],
  fetches: {
    create: false,
    update: false,
    delete: false,
    tournamentInfo: false,
    playersUpdate: false,
    participation: false,
    addSetting: false,
    deleteSetting: false,
    checkSquadIdRemovedPlayer: false,
    checkPlayerRemovedInfo: false,
    logo: '',
    bgImage: '',
    cardType: 0,
    color: '',
  },
  confirmTournamentResults: {},
  loading: {},
  tournamentInfoForEdit: {
    fetching: false,
    data: null,
  },
  tournamentTypeList: {
    data: [],
    fetching: false,
  },
  needAgreeForDebeting: null,
  squadIdRemovedPlayer: null,
  playerRemovedInfo: null,
};

const TournamentReducer = (state = initialState, action) => {
  switch (action.type) {
    case WATCH_TOURNAMENT:
      return {...state, tournamentId: action.params}
    case TOURNAMENTINFO_FETCH:
      // убрал `tournamentInfo: false`, потому что из-за этого состояние терялось при обновлении `tournamentInfo`
      // return {...state, tournamentInfo: false, fetches: {...state.fetches, tournamentInfo: true}}
      return {...state, fetches: {...state.fetches, tournamentInfo: true}}
    case TOURNAMENTINFO_SET:
      return {...state, tournamentInfo: action.params, fetches: {...state.fetches, tournamentInfo: false}}
    case TOURNAMENTS_FETCHING:
      return {...state, isFetching: action.params};
    case TOURNAMENTS_SET:
      return {...state, tournaments: action.params};
    case TOURNAMETS_SET_PLAYERS:
      return {...state, tournaments: action.params};
    case TOURNAMENTS_BATTLE_TAKEPART: {
      return {
        ...state,
        fetches: {...state.fetches, participation: true},
        loading: {...state.loading, [action.params.id]: true}
      };
    }
    case TOURNAMENTS_BATTLE_TAKEPART_FINISH: {
      return {...state, fetches: {...state.fetches, participation: false}};
    }
    case TOURNAMENTS_BATTLE_LOADING: {
      if (!action.params.loading) {
        delete state.loading[action.params.id];
      } else {
        state.loading[action.params.id] = true;
      }
      return {...state, loading: {...state.loading}};
    }
    case UPDATE_TOURNAMENT_PLAYERS:
      let _isParticipant = state.tournamentInfo.isParticipant;
      if (state.tournamentInfo && state.tournamentInfo.players && state.tournamentInfo.players.find(player => player.userid == action.params.userid)) {
        _isParticipant = !!action.params.add;
        const player = state.tournamentInfo.players.find(player => player.userid == action.params.userid);
        player.isparticipant = action.params.add;
        if (action.params.playerId) {
          player.playerid = action.params.playerId;
        }
      }
      if (state.tournamentInfo) {
        if (action.params.add) {
          state.tournamentInfo.playerscount++;
        } else {
          state.tournamentInfo.playerscount--;
        }
      }
      return {
        ...state,
        tournamentInfo: {
          ...state.tournamentInfo,
          isParticipant: _isParticipant,
        },
        fetches: {...state.fetches, playersUpdate: !state.fetches.playersUpdate}
      }
    case ACCOUNT_TOURNAMENTS_FETCH:
      return {...state, accountTournaments: [], accountTournamentsFetching: true};
    case ACCOUNT_TOURNAMENTS_SET:
      return {...state, accountTournaments: action.params, accountTournamentsFetching: false};
    case TOURNAMENTS_CREATE_TOURNAMENT_FETCH:
      return {...state, fetches: {...state.fetches, create: true}};
    case TOURNAMENTS_CREATE_TOURNAMENT_SET:
      return {...state, fetches: {...state.fetches, create: false}};
    case TOURNAMENTS_UPDATE_INFO_FETCH:
      return {...state, fetches: {...state.fetches, update: true}};
    case TOURNAMENTS_UPDATE_INFO_SET:
      return {...state, fetches: {...state.fetches, update: false}};
    case TOURNAMENTS_DELETE_FETCH:
      return {...state, fetches: {...state.fetches, delete: true}};
    case TOURNAMENTS_DELETE_SET:
      return {...state, fetches: {...state.fetches, delete: false}};
    case TOURNAMNET_BIND_BC_FETCH:
      return {...state, fetches: {...state.fetches, update: true}};
    case TOURNAMNET_UNBIND_BC_FETCH:
      return {...state, fetches: {...state.fetches, update: true}};
    case TOURNAMNET_BIND_END:
      return {
        ...state,
        tournamentInfo: {
          ...state.tournamentInfo,
          bindedBowlingcenters: [...state.tournamentInfo.bindedBowlingcenters, {...action.params}]
        },
        fetches: {...state.fetches, update: false}
      };
    case TOURNAMNET_UNBIND_END:
      return {
        ...state,
        tournamentInfo: {
          ...state.tournamentInfo,
          bindedBowlingcenters: state.tournamentInfo.bindedBowlingcenters.filter(item => item.bowlingcenterid !== action.params.bowlingcenterid)
        },
        fetches: {...state.fetches, update: false}
      };
    case ADD_BATTLE_TO_TOURNAMENT:
      if (!state.tournamentInfo || !state.tournamentInfo.battles || !Array.isArray(state.tournamentInfo.battles)) {
        return state;
      }
      return {
        ...state,
        tournamentInfo: {
          ...state.tournamentInfo,
          battles: [action.params, ...state.tournamentInfo.battles],
        },
      };
    case DELETE_GAME_TOURNAMENT:
      return {
        ...state,
        tournamentInfo: {
          ...state.tournamentInfo,
          battles: (Array.isArray(state.tournamentInfo.battles) && state.tournamentInfo.battles.filter(battle => battle.id !== action.params.squadid)) || state.tournamentInfo.battles,
        }
      }
    case CANCEL_BATTLE_TOURNAMENT:
      return {
        ...state,
        tournamentInfo: {
          ...state.tournamentInfo,
          battles: state.tournamentInfo.battles.map(battle => {
            if (battle.id === action.params.squadid) {
              return {...battle, closed: new Date().toISOString()}
            }

            return battle;
          })
        }
      }
    case TOURNAMENT_INFO_FOR_EDIT_FETCH:
      return {
        ...state,
        tournamentInfoForEdit: {
          ...state.tournamentInfoForEdit,
          fetching: true,
        }
      }
    case TOURNAMENT_INFO_FOR_EDIT_SET:
      return {
        ...state,
        tournamentInfoForEdit: {
          data: action.params || null,
          fetching: false,
        }
      }
    case FETCH_TOURNAMENT_TYPE_LIST:
      return {
        ...state,
        tournamentTypeList: {
          ...state.tournamentTypeList,
          fetching: true,
        }
      }
    case SET_TOURNAMENT_TYPE_LIST:
      return {
        ...state,
        tournamentTypeList: {
          data: action.params,
          fetching: false
        }
      }
    case ADD_TOURNAMENT_SETTING:
      return {
        ...state,
        fetches: {
          ...state.fetches,
          addSetting: true,
        }
      }
    case SET_TOURNAMENT_SETTING:
      if (!state.tournamentInfo) return {
        ...state,
        fetches: {
          ...state.fetches,
          addSetting: false,
        },
      }

      return {
        ...state,
        tournamentInfo: {
          ...state.tournamentInfo,
          settings: (state.tournamentInfo.settings
            && Array.isArray(state.tournamentInfo.settings)
            && [...state.tournamentInfo.settings.filter(s => s.id !== action.params.id), action.params]) || [],
        },
        fetches: {
          ...state.fetches,
          addSetting: false,
        },
      }
    case DELETE_TOURNAMENT_SETTING_FETCH:
      return {
        ...state,
        fetches: {
          ...state.fetches,
          deleteSetting: true,
        }
      }
    case DELETE_TOURNAMENT_SETTING_SET:
      if (!state.tournamentInfo) return {
        ...state,
        fetches: {
          ...state.fetches,
          deleteSetting: false,
        },
      }

      return {
        ...state,
        tournamentInfo: {
          ...state.tournamentInfo,
          settings: (state.tournamentInfo.settings
            && Array.isArray(state.tournamentInfo.settings)
            && state.tournamentInfo.settings.filter(s => s.id !== action.params.setting_id)) || [],
        },
        fetches: {
          ...state.fetches,
          deleteSetting: false,
        }
      }
    case SET_TOURNAMENT_PLAYER_RESULT:
      return {
        ...state,
        tournamentInfo: {
          ...state.tournamentInfo,
          players: state.tournamentInfo.players.map(p => {
            if (p.playerid === action.params.id) {
              p.result = action.params.result;
            }

            return p;
          }),
        }
      }
    case LOGOUT:
      return {...initialState};
    case SET_MODAL_DEBETING_MONEY_FOR_PARTICIPATION: {
      return {...state, needAgreeForDebeting: action.params}
    }
    case CLEAR_MODAL_DEBETING_MONEY_FOR_PARTICIPATION: {
      return {...state, needAgreeForDebeting: null}
    }
    case 'CONFIRM_TOURNAMENT_RESULTS_REQUEST':
      return {
        ...state,
        confirmTournamentResults: {
          ...state.confirmTournamentResults,
          fetching: true,
          error: false,
        },
      }
    case 'CONFIRM_TOURNAMENT_RESULTS_SUCCESS':
      return {
        ...state,
        confirmTournamentResults: {
          ...state.confirmTournamentResults,
          tournamentid: action.params && action.params.tournamentid || state.confirmTournamentResults.tournamentid,
          fetching: false,
        }
      }
    case 'CONFIRM_TOURNAMENT_RESULTS_FAILURE':
      return {
        ...state,
        confirmTournamentResults: {
          ...state.confirmTournamentResults,
          fetching: false,
          error: true,
        }
      }
    case CONFIRM_TOURNAMENT_RESULTS: {
      if (!state.tournamentInfo || state.tournamentInfo.id !== +action.params.tournamentid) {
        return state;
      }
      return {
        ...state,
        tournamentInfo: {
          ...state.tournamentInfo,
          resultsConfirmed: true,
        },
      };
    }
    case SET_LEADERBOARD_TABLE_STAGES: {
      return {
        ...state,
        tournamentInfo: {
          ...state.tournamentInfo,
          leaderboardStages: action.params
        },
      }
    }
    case SET_PLAYER_REPLAY:
      return {
        ...state,
        tournamentInfo: {
          ...state.tournamentInfo,
          players: ((state.tournamentInfo || {}).players || []).map(p => {
            if (+p.playerid !== +action.params.playerid) return p;

            return {...p, can_replay: action.params.can_replay};
          }),
        }
      };
    case UPLOAD_CARD_DECORATION_FETCH:
      return {
        ...state,
        fetches: {
          ...state.fetches,
          decorations: true
        },
      };
    case UPLOAD_CARD_DECORATION:
      return {
        ...state,
        fetches: {
          ...state.fetches,
          decorations: false
        }
      }
    case SET_LEADER_BOARD:
      return {
        ...state,
        InfoTournament: action.data
      };
    case SEND_INVITE_TOURNAMENT_NOTIFICATION:
      return {
        ...state,
        fetches: {
          ...state.fetches,
          pushNotificationSending: true
        }
      }
    case SEND_INVITE_TOURNAMENT_NOTIFICATION_FETCHED:
      return {
        ...state,
        fetches: {
          ...state.fetches,
          pushNotificationSending: false
        },
        tournamentInfo: {
          ...state.tournamentInfo,
          push_sent: action.params.push_sent
        }
      }
  case REMOVE_SQUAD_ID_BY_USER_ID:
    return { ...state, squadIdRemovedPlayer: null,
    fetches: { ...state.fetches, checkSquadIdRemovedPlayer: true }}
  case GET_SQUAD_ID_BY_USER_ID_FETCHED:
    return { ...state, squadIdRemovedPlayer: action.params,
      fetches: { ...state.fetches, checkSquadIdRemovedPlayer: false }}
  case REMOVE_PLAYER_REMOVED_INFO:
    return { ...state, playerRemovedInfo: null,
    fetches: { ...state.fetches, checkPlayerRemovedInfo: true }}
  case POST_REMOVED_PLAYER_INFO:
    return { ...state, playerRemovedInfo: action.params,
      fetches: { ...state.fetches, checkPlayerRemovedInfo: false }}
    default:
      return state;
  }
};

export default TournamentReducer;
