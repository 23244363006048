import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { intlShape } from 'react-intl';
import { FilterInput } from '../Input';
import Checkbox from '../Checkbox';
import { Button } from '../Button';
import Select from '../Select';
import './styles.scss';
import Utils from '../../../libs/utils';

const CHAT_LISTS = [
  {
    value: 'userlist',
    label: 'userlist',
  },
  {
    value: 'adminlist',
    label: 'adminlist',
  },
  {
    value: 'whitelist',
    label: 'whitelist',
  },
  {
    value: 'blacklist',
    label: 'blacklist',
  },
];

const pad = (val, len = 2) => `000${val}`.slice(-len);

const timestamp2date = (timestamp) => {
  const date = new Date(timestamp);
  return `${date.getUTCFullYear()}-${pad(date.getUTCMonth() + 1)}-${pad(date.getUTCDate())} ${pad(
    date.getUTCHours(),
  )}:${pad(date.getUTCMinutes())}:${pad(date.getUTCSeconds())}`;
};

const timestamp2time = (timestamp) => {
  const date = new Date(timestamp);
  return `${pad(date.getUTCHours())}:${pad(date.getUTCMinutes())}`;
};

class ChatCommonAPI extends Component {
  static propTypes = {
    values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    placeholders: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    callback: PropTypes.func.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    values: [],
    placeholders: [],
    name: 'submit',
    className: 'chat-common-api',
  };

  constructor(props) {
    super(props);
    this.size = props.placeholders.length;
    const values = props.values ? [...props.values] : new Array(this.size);
    this.state = { texts: values };
  }

  onChange = (e) => {
    const idx = +e.target.getAttribute('data-idx');
    const { texts: textsOld } = this.state;
    const texts = [...textsOld];
    texts[idx] = e.target.value;
    this.setState({ texts });
  };

  onClick = () => {
    const { callback } = this.props;
    const { texts } = this.state;
    callback(...texts);
    this.clear();
  };

  clear = () => {
    const texts = [];
    for (let i = 0; i < this.size; i += 1) {
      texts.push('');
    }
    this.setState({ texts });
  };

  render() {
    const { texts } = this.state;
    const { placeholders, name, className } = this.props;
    return (
      <div className={className}>
        {placeholders.map((inp, idx) => (
          <FilterInput
            className={`${className}__input`}
            key={idx}
            data-idx={idx}
            value={texts[idx]}
            type="text"
            placeholder={inp}
            onChange={this.onChange}
          />
        ))}
        <button className={`${className}__submit`} onClick={this.onClick}>
          {name}
        </button>
      </div>
    );
  }
}

class RoomList extends Component {
  static VALUES = [...CHAT_LISTS];

  static propTypes = {
    className: PropTypes.string,
    callback: PropTypes.func.isRequired,
    listName: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  };

  static defaultProps = {
    className: 'default-class',
    listName: RoomList.VALUES[0],
  };

  onChange = (option) => {
    const { callback } = this.props;
    callback(option);
  };

  render() {
    const { className, listName } = this.props;
    return (
      <div className={className}>
        <Select value={listName} onChange={this.onChange} options={RoomList.VALUES} />
      </div>
    );
  }
}

class AdminAPI extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    callback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: 'default-class',
  };

  constructor(props) {
    super(props);
    this.state = { userId: '', listName: RoomList.VALUES[0] };
  }

  onChangeInput = event => this.setState({ userId: event.target.value });

  onChangeSelect = option => this.setState({ listName: option });

  onClick = () => {
    const { callback } = this.props;
    const { userId, listName } = this.state;
    callback(userId, listName.value);
    this.clear();
  };

  clear = () => {
    this.setState({ userId: '' });
  };

  render() {
    const { userId, listName } = this.state;
    const { name, className } = this.props;
    return (
      <div className={className}>
        <FilterInput
          className={`${className}__input`}
          value={userId}
          type="text"
          placeholder="Enter user id"
          onChange={this.onChangeInput}
        />
        <RoomList className={`${className}__select`} listName={listName} callback={this.onChangeSelect} />
        <button className={`${className}__submit`} onClick={this.onClick}>
          {name}
        </button>
      </div>
    );
  }
}

class ChatVC extends Component {
  static propTypes = {
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        textMessage: PropTypes.string.isRequired,
        timestamp: PropTypes.number.isRequired,
        author: PropTypes.string.isRequired,
        authorId: PropTypes.number,
      }),
    ),
    users: PropTypes.arrayOf(
      PropTypes.shape({
        userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        userName: PropTypes.string,
        listName: PropTypes.string,
        lastseen: PropTypes.number,
      }),
    ),
    lists: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
    owner: PropTypes.shape({
      userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      userName: PropTypes.string,
    }),
    room: PropTypes.string,
    whitelistOnly: PropTypes.bool,
    historyMaxSize: PropTypes.number,
    historySize: PropTypes.number,
    historyTotal: PropTypes.number,
    isAdmin: PropTypes.bool,
    textMessage: PropTypes.string,
    renderMessages: PropTypes.func,
    renderUsers: PropTypes.func,
    roomAddToList: PropTypes.func.isRequired,
    roomRemoveFromList: PropTypes.func.isRequired,
    roomMessage: PropTypes.func.isRequired,
    roomSetWhitelistMode: PropTypes.func.isRequired,
    roomGetWhitelistMode: PropTypes.func.isRequired,
    roomHistoryGet: PropTypes.func.isRequired,
    roomHistoryInfo: PropTypes.func.isRequired,
    roomJoin: PropTypes.func.isRequired,
    roomLeave: PropTypes.func.isRequired,
    roomCreate: PropTypes.func.isRequired,
    roomGetUsers: PropTypes.func.isRequired,
    roomGetLists: PropTypes.func,
    intl: intlShape,
  };

  static defaultProps = {
    messages: [],
    users: [],
    lists: [...CHAT_LISTS],
    owner: null,
    isAdmin: false,
    whitelistOnly: false,
    historyMaxSize: 0,
    historySize: 0,
    historyTotal: 0,
  };

  constructor(props) {
    super(props);
    const { textMessage } = props;
    this.state = { textMessage };
  }

  componentDidMount() {
    const { roomGetLists } = this.props;
    if (typeof roomGetLists === 'function') {
      roomGetLists(RoomList.VALUES.map(this._mapList));
    }
  }

  _mapList = list => list.value;

  _parseTextMessage = (textMessage) => {
    const parsedMessage = textMessage.split('|');
    const data = {};
    const { intl } = this.props;
    for (let i = 1; i < parsedMessage.length; i += 1) {
      const elem = parsedMessage[i];
      const temparr = elem.split(':');
      for (let k = 2; temparr.length > 2 && k < temparr.length; k += 1) {
          temparr[1] += temparr[k];
      }
      data[temparr[0]] = temparr[1];
    }
    const { messageText, messageComment } = Utils.parseChatMessage(intl, { ...data, event: parsedMessage[0] });
    return { event: parsedMessage[0], text: messageText, username: data.username, userid: data.userid, comment: messageComment };
  };

  onClickTime = (event) => {
    const { onClickTime } = this.props;
    if (typeof onClickTime !== 'function') {
      return;
    }
    let { target } = event;
    while (target && !target.classList.contains('chat-message')) {
      target = target.parentNode;
    }
    if (target.tagName === 'LI') {
      const timestamp = +target.getAttribute('data-timestamp');
      console.log('onClick chat-time:', timestamp, moment(timestamp).format('YYYY-MM-DD HH:mm:ss'));
      onClickTime(timestamp);
    }
  }

  _renderDateHeader = timestamp => (<li key={Math.random()} className="chat-message chat-date"><span>{Utils.formatDateTime(timestamp, this.props.lang, 'DD MMMM')}</span></li>)

  renderDateHeader = (prevMsg, msg) => {
    const { timestamp } = msg;
    if (!prevMsg || prevMsg === msg) {
      return this._renderDateHeader(timestamp);
    }
    const { timestamp: prevTimestamp } = prevMsg || {};
    const dif = timestamp - prevTimestamp;
    const day = 24 * 3600 * 1000;
    const prevDay = new Date(prevTimestamp).getUTCDate();
    const curDay = new Date(timestamp).getUTCDate();
    if (dif > day || (curDay - prevDay) > 0) {
      return this._renderDateHeader(timestamp);
    }
    return null;
  }

  _mapMessage = (msg, i) => {
    const {
      author, authorId, textMessage, timestamp,
    } = msg;
    const { systemMessages, squad } = this.props;
    const { tournamenttypeid } = squad || {};
    const time = moment(timestamp).format('HH:mm');
    const date = moment(timestamp).format('YYYY-MM-DD HH:mm:ss');
    const { event, text, username, userid, comment } = this._parseTextMessage(textMessage || '');
    const isSystemMessage = event != 'supportMessage' ? !!(username || userid || textMessage !== text) : false;
    const { intl } = this.props;
    let user = `${author} ${tournamenttypeid && tournamenttypeid === 3
      ? `(${((squad.players.find(player => player.userid === authorId) && intl.formatMessage({ id: 'PlayerNoun', defaultMessage: 'Player' }))
        || intl.formatMessage({ id: 'Observer', defaultMessage: 'Observer' }))})`
      : ''}: `;
    let systemClass = '';
    if (isSystemMessage) {
      user = `${username || userid}: `;
      systemClass = 'chat-message__system';
    }
    if (event === 'supportMessage') {
      user = `${username}: `;
    }
    const dateClass = this.props.onClickTime ? 'chat-message__date-pointer' : '';
    const isMe = +authorId === this.props.userid || (userid && +userid === this.props.userid);
    if (!systemMessages && isSystemMessage) {
      return null;
    }
    return (
      <li
        key={i}
        className={`chat-message${isMe ? ' me' : ''} ${systemClass}`}
        data-timestamp={timestamp}
        data-author={author}
        data-author-id={authorId}
        data-date={date}
      >
        <div className="chat-message-date">
          <span className={`${dateClass}`} title={date} onClick={this.onClickTime}>{time}</span>
        </div>
        <span className={`chat-message__wrapper`}>
          {/* <span className={`chat-message__date ${dateClass}`} title={date} onClick={this.onClickTime}>{time}</span> */}
          <span className={`chat-message__author`}>
            {user}
          </span>
          <span className="chat-message__text">{text}</span>
          { comment &&
            <span>
              <span className="chat-message__comment_label">{' '}{intl.formatMessage({ id: 'comment', defaultMessage: 'Comment' })}:{' '}</span>
              <span className="chat-message__comment">{comment}</span>
            </span>
          }
        </span>
      </li>
    );
  };

  renderMessages = () => {
    const { messages, renderMessages } = this.props;
    if (typeof renderMessages === 'function') {
      return renderMessages(messages);
    }
    const messageList = [];
    let prevMsg = null;
    for (let i = 0, message; i < messages.length; i += 1) {
      message = messages[i];
      const dateHeader = this.renderDateHeader(prevMsg, message);
      if (dateHeader) {
        messageList.push(dateHeader);
      }
      messageList.push(this._mapMessage(message, i));
      prevMsg = message;
    }
    return (
      <ul
        className="message-list"
        ref={(c) => {
          this.msgRef = c;
        }}
      >
        {messageList}
      </ul>
    );
  };

  _onChangeInput = e => this.setState({ textMessage: e.target.value });

  chatScroll = () => {
    if (this.msgRef) {
      this.msgRef.scrollTop = 9999999999;
    }
  };

  onMessageSubmit = () => {
    const { textMessage } = this.state;
    this.setState({ textMessage: '' });
    if (!textMessage || !textMessage.trim()) {
      return;
    }
    const { roomMessage } = this.props;
    roomMessage({ textMessage }, this.chatScroll);
  };

  onInputFocus = () => this.setState({ inputFocused: true });

  onInputBlur = () => this.setState({ inputFocused: false });

  onKeySubmit = (e) => {
    if (e.keyCode === 13 && this.state.inputFocused) {
      this.onMessageSubmit();
    }
  }

  renderInput = () => {
    const { textMessage } = this.state;
    const { intl } = this.props;
    return (
      <div className="message-input" onKeyDown={this.onKeySubmit}>
        <FilterInput
          className="message-input__text"
          value={textMessage}
          type="text"
          placeholder={intl.formatMessage({ id: 'ChatInputPlaceholder', defaultMessage: 'Type message' })}
          onChange={this._onChangeInput}
          onFocus={this.onInputFocus}
          onBlur={this.onInputBlur}
        />
        <Button className="message-input__submit"
            onClick={this.onMessageSubmit}
            size="md"
            kind="aquamarine"
            icon="send"
        />
        {/* <button className="message-input__submit" onClick={this.onMessageSubmit}>
          {intl.formatMessage({ id: 'ChatInputAction', defaultMessage: 'Send' })}
        </button> */}
      </div>
    );
  };

  _mapUser = (user, idx) => {
    const {
      userId, userName, listName = 'userlist', timestamp = 0,
    } = user;
    return (
      <li className={`room-user-${listName}`} key={idx} data-user-name={userName} data-user-id={userId}>
        <span className="room-user__name">{userName}</span>
        <span className="room-user__id">{userId}</span>
        <span className="room-user__lastseen">{timestamp ? timestamp2date(timestamp) : 'never'}</span>
        <span className="room-user__listname">{listName}</span>
      </li>
    );
  };

  renderUsers = () => {
    const { renderUsers, users } = this.props;
    if (typeof renderUsers === 'function') {
      return renderUsers(users);
    }
    return <ul className="user-list">{users.map(this._mapUser)}</ul>;
  };

  renderAdminAPI = () => {
    const { room, roomAddToList, roomRemoveFromList } = this.props;
    return (
      <div className="room-admin-api" data-room={room}>
        <AdminAPI className="room-admin-api__adduser" name="Add user" callback={roomAddToList} />
        <AdminAPI className="room-admin-api__removeuser" name="Remove user" callback={roomRemoveFromList} />
      </div>
    );
  };

  renderCommonAPI = () => {
    const { room } = this.props;
    const className = 'common-api-item';
    const {
      roomHistoryGet,
      roomHistoryInfo,
      roomJoin,
      roomLeave,
      roomCreate,
      roomGetWhitelistMode,
      roomGetUsers,
    } = this.props;
    // TODO: make roomCreate with 2 args: roomName, whitelistMode
    return (
      <div className="common-api" data-room={room}>
        <ChatCommonAPI
          className={className}
          placeholders={['Enter start index', 'Enter end index']}
          values={[0, 20]}
          name="update history"
          callback={roomHistoryGet}
        />
        <ChatCommonAPI className={className} placeholders={[]} name="history info" callback={roomHistoryInfo} />
        <ChatCommonAPI
          className={className}
          placeholders={['Enter room name']}
          name="room create"
          callback={roomCreate}
        />
        <ChatCommonAPI className={className} placeholders={['Enter room name']} name="room join" callback={roomJoin} />
        <ChatCommonAPI className={className} placeholders={[]} name="room leave" callback={roomLeave} />
        <ChatCommonAPI className={className} placeholders={[]} name="get mode" callback={roomGetWhitelistMode} />
        <ChatCommonAPI className={className} placeholders={[]} name="get users" callback={roomGetUsers} />
      </div>
    );
  };

  renderInfo = () => {
    const {
      room, owner, whitelistOnly, historyTotal, historySize, historyMaxSize, roomSetWhitelistMode,
    } = this.props;
    const { userName: ownerName = 'unknown', userId: ownerId = '-1' } = owner || {};
    return (
      <div className="room-info">
        <span className="room-info__name">{room}</span>
        <span className="room-info__ownerid">{ownerId}</span>
        <span className="room-info__ownername">{ownerName}</span>
        <span className="room-info__historysize">{historySize}</span>
        <span className="room-info__historymaxsize">{historyMaxSize}</span>
        <span className="room-info__historytotal">{historyTotal}</span>
        <Checkbox className="room-info__mode" checked={whitelistOnly} small onChange={roomSetWhitelistMode}>
          whitelist mode
        </Checkbox>
      </div>
    );
  };

  render() {
    // const { isAdmin } = this.props;
    return (
      <div className="chat-room">
        <div className="chat-room__center">
          {this.renderMessages()}
          {this.renderInput()}
        </div>
        {/* <div className="chat-room__right">
          {this.renderInfo()}
          {isAdmin ? this.renderAdminAPI() : null}
          {this.renderCommonAPI()}
          {this.renderUsers()}
        </div> */}
      </div>
    );
  }
}

export default ChatVC;
