import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Avatar from '../common/Avatar';
import config from '../../config';
import { UserByIdFetch } from '../../libs/reducers/actions';

import './style.css';
import './style.scss';
import yellowCard from '../../images/yellowCard.png';
import { isPinfall } from '../../libs/SquadTypeCheck';
import { LiveCut } from '../LiveCut/LiveCut';
class matchScore extends Component {
  static propTypes = {
    players: PropTypes.arrayOf(PropTypes.shape()),
  };

  state = {
    leader: [],
  };

  componentDidMount() {
    this.UpdateScore();
  }

  componentDidUpdate(oldprops) {
    if (this.props.players !== oldprops.players) {
      this.UpdateScore();
    }
  }

  UpdateScore() {
    const maxValue = Math.max(...this.props.players.map(p => p.gameswin));
    if (this.props.players.every(p => p.gameswin === maxValue)) {
      this.setState({ leader: [] })
    } else {
      this.setState({ leader: this.props.players.filter(p => p.gameswin === maxValue).map(p => p.id) })
    }
  }

  createRatingSVG = (rating = 0) => {
    rating = +rating;
    const poly = 'M14 1.12978L17.6661 8.55816L17.7825 8.79389L18.0426 8.83169L26.2403 10.0229L20.3084 15.8051L20.1202 15.9886L20.1646 16.2476L21.5649 24.4122L14.2327 20.5574L14 20.4351L13.7673 20.5574L6.43507 24.4122L7.83541 16.2476L7.87984 15.9886L7.69161 15.8051L1.75969 10.0229L9.9574 8.83169L10.2175 8.79389L10.3339 8.55816L14 1.12978Z';
    const numbers = [1, 2, 3, 4, 5];
    return numbers.map((num, idx) => {
      let fill = 'none';
      let isPartial = false;
      const entropy = Math.random();
      if (rating > num || rating === num) {
        fill = '#FFB31E';
      } else if (num - 1 < rating && rating < num) {
        isPartial = true;
        fill = `url(#partialGradient${entropy})`;
      }
      return (
        <svg
          key={idx}
          className={`${rating === num ? 'selected' : ''}`}
          width="20"
          height="20"
          viewBox="0 0 28 28"
          fill={fill}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d={poly} stroke="#FFB31E" />
          {isPartial ? (
            <linearGradient id={`partialGradient${entropy}`}>
              <stop stopOpacity="1" offset={`${(rating - num + 1) * 100}%`} stopColor="#FFB31E" />
              <stop stopOpacity="0" offset="0" />
            </linearGradient>
          ) : null}
        </svg>
      );
    });
  };

  render() {
    const { squad, observerSquad, isObserver, isReferee } = this.props;
    const currentGame = isObserver
      ? observerSquad && observerSquad.games[observerSquad.games.length - 1]
      : squad && squad.games[squad.games.length - 1];
    return (
      <div className={'match-scoreboard'}>
        {this.props.players.map((player, index) => (
          <div
            key={index}
            className={`competitor ${ !this.props.isObserver 
              ? this.props.user.id === player.userid ? 'owner' : ''
              : index % 2 ? 'owner' : ''}`
            }
            onClick={() => this.props.UserByIdFetch({ userid: player.userid })}
          >
            {new Array(player.delays).fill(0).map(() => <img src={yellowCard} className='delayCard'/>)}
            <div className="name">
              <span className="full">{`${player.firstname} ${player.lastname}`}</span>
              <span className="login">{player.login}</span>
              <div className="ratings">
                <div className="rating-stars">{this.createRatingSVG((player.ratingsavg && +player.ratingsavg) || 0)}</div>
                <div className="rating-value">{(player.ratingsavg && (+player.ratingsavg).toFixed(2)) || 0}</div>
              </div>
            </div>
            <Avatar
              checked={currentGame && currentGame.confirmations[player.id]}
              uri={player.photo ? `${config.FETCH.img}/${player.photo}` : null}
              flag={(player.country_live||'').toLowerCase()}
              avgscore={(+player.avgscore || 0).toFixed()}
            />
            <div className={`score${this.state.leader.includes(player.id) ? ' leader' : ''}`}>
              {
                isReferee
                ? player.gameswin || 0
                : isPinfall(squad.tournamenttypebrief)
                  ? player.total_score || 0
                  : player.gameswin 
                    ? squad.closed ? player.gameswin : player.gameswin + (player.id === squad.tentativeWinner ? 1 : 0)
                    : player.id === squad.tentativeWinner ? 1 : 0 || 0
              }
            </div>
            <LiveCut />
          </div>
        ))}
      </div>
    );
  }
}

export default connect(
  state => ({
    user: state.users.user,
    squad: state.squadScreen.data,
    observerSquad: state.observer.squadstate,
  }),
  dispatch => ({
    UserByIdFetch: bindActionCreators(UserByIdFetch, dispatch),
  }),
)(matchScore);
