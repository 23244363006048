import React, {useEffect, useState} from 'react';
import CheckBox from "../../common/Checkbox";
import {store} from '../../../store'
import '../bowlingCentersView.css'
import {ReactComponent as ArrowUp} from '../../../images/arrow-up-bc.svg'
import {ReactComponent as ArrowDown} from '../../../images/arrow-down-bc.svg'

const BowlingCentersTable = (props) => {
  const {
      selectedImage,
      bowlingCenters = [],
      activeBC = {},
      setActiveBC,
  } = props

  const [isFullTable, setIsFullTable] = useState(false);
  const [activeBCSlave, setActiveBCSlave] = useState({...activeBC});

    useEffect(()=> {
        if (!selectedImage){
            setActiveBCSlave(activeBC)
        }
    },[activeBC])


useEffect(()=> {
    //если есть selectedImage переопределяем activeBCSlave
  let tempObj = {}
  if (selectedImage){
    bowlingCenters.forEach(bc => {
        tempObj[bc.name] = {visible: false}
    })
  if (selectedImage && selectedImage.bc_name){
      selectedImage.bc_name.forEach(name => {
          tempObj[name] = {visible: true}
      })
  }
      setActiveBCSlave(tempObj)
      return
  }
  setActiveBCSlave(activeBC)
},[selectedImage])

    function onSwitchBC ({type, name}) {
        let tempObjMaster = activeBC
        let tempObjSlave = activeBCSlave
    switch (type) {
        case 'selected':
            if (tempObjSlave[name].visible){
                tempObjMaster[name].count -= 1
                selectedImage.bc_name = selectedImage.bc_name.filter(bcName => bcName != name)
            }

            if (!tempObjSlave[name].visible){
                tempObjMaster[name].count += 1
                if (selectedImage.bc_name) {
                    selectedImage.bc_name.push(name)
                } else {
                    selectedImage.bc_name = [name]
                }
            }

            if (selectedImage.status === 'done') selectedImage.status = 'changed'

            tempObjSlave[name].visible = !tempObjSlave[name].visible
            tempObjMaster[name].visible = tempObjSlave[name].visible
            setActiveBC({...tempObjMaster})
            setActiveBCSlave({...tempObjSlave})
            break
        case '!selected':
            tempObjMaster[name].visible = !tempObjMaster[name].visible

            setActiveBC({...tempObjMaster})
            break
    }
    }



  const CheckBoxWidthBCName = ({bc}) => {
        let disabled = false
        if (selectedImage && activeBC[bc.name]) disabled = activeBC[bc.name].count >= 2 && !activeBCSlave[bc.name].visible
      let twoBanners = false
      if (activeBC[bc.name] && activeBC[bc.name].count >= 2) twoBanners = true

      let nameTextStyle = 'bc_names'
      if (disabled || twoBanners) nameTextStyle = 'bc_names_disabled'
      return (
          <div key={bc.id} className={'CheckBoxContainer'}>
              {activeBCSlave[bc.name] &&
              <CheckBox
                  checked={activeBCSlave[bc.name].visible}
                  onChange={() => {
                      if (selectedImage){
                          onSwitchBC({type:'selected', name: bc.name})
                          return
                      }
                      onSwitchBC({type:'!selected', name: bc.name})
                  }}
                  disabled={disabled}
              >
                  <div className={nameTextStyle} key={bc.id}>{bc.name}</div>
              </CheckBox>
              }
          </div>
      )
  }

  let buttonSpoilerText = 'More'
    if (isFullTable) buttonSpoilerText = 'Hide'

  return (
      <div>
        <div className={'banners_bowling_centers'}>
          {bowlingCenters.map((bc, index) => {
            if (!isFullTable){
              if (index < 6) return <CheckBoxWidthBCName bc={bc} />
            } else return <CheckBoxWidthBCName bc={bc}  />
          })}
        </div>
          {bowlingCenters.length > 6 && <div className={'banners_spoiler_container'}>
              <div onClick={() => setIsFullTable(!isFullTable)} className={'banners_spoiler'}>
                  {buttonSpoilerText}
              </div>
              {isFullTable ? <ArrowUp/> : <ArrowDown/>}
          </div>}
      </div>
  );
};

export default BowlingCentersTable;
