import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import tournamentData from './tournamentData';
import DropDownItem from './DropDownItem';

import './style.scss';

const DropDown = ({
  intl,
  lang,
}) => {
  return (
    <div className="dropdown-tournaments">
      {tournamentData({ intl, lang }).map((t, index) => (
        <DropDownItem
          key={index.toString()}
          tournament={t}
        />
      ))}
    </div>
  );
};

export default connect(
  state => ({
    lang: state.langs.lang,
  }),
  {}
) (injectIntl(DropDown));
