const {
  SUPPORT_SEND_MESSAGE,
  SUPPORT_SET_MESSAGE,
} = require('./actiontypes').default;

const initialState = {
  messages: [],
  fetching: false,
  sending: false,
  error: null,
};

const supportReduser = (state = initialState, action) => {
  switch (action.type) {
    case SUPPORT_SEND_MESSAGE:
      return {  ...state, sending: true };
    case SUPPORT_SET_MESSAGE:
      if (action.error) {
        return { ...state, error: action.error, sending: false };
      }
      return { ...state, sending: false };
    default:
      return state;
  }
};

export default supportReduser;