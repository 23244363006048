import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment';
import { debounce } from 'lodash';
import FilterInput from '../../common/Input/FilterInput';
// import Input from '../../common/Input/Input';
import './tournamentsChat.scss';
import { setCurrentRoom, createSupportRoom } from '../../../libs/reducers/actions';
import config from '../../../config';
import SupportSvg from '-!svg-react-loader!../../../images/support.svg'; // eslint-disable-line
import Utils from '../../../libs/utils';
import no_chats from '../../../images/no_open_chat.png';
import squadchaticon from '../../../images/squadchaticon.png';
import RatingStars from '../../common/RatingStars';

const defaultAvatar = '/static/media/default.2d4c81eb.png';

class TournamentsChatRooms extends Component {
  static propTypes = {
    intl: intlShape,
    rooms: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {};

  state = {
    searchText: '',
  };

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  componentWillUnmount() {}

  onRoomClick = (e) => {
    const { currentTarget: target } = e;
    const roomName = target.getAttribute('data-roomname');
    if (roomName) {
      this.props.setCurrentRoom(roomName);
    } else {
      console.error('onRoomClick.error -> invalid target', target);
    }
  };

  renderRoom = (room, index) => {
    if (room.room_name.startsWith('support-room-') || room.room_name.startsWith('squad-chat-') || room.room_name.startsWith('Observe-squad-chat-') || !room || !room.users || room.users.length <= 1) {
      return null;
    }
    const isPrivate = room.users.length === 2;
    let retval;
    if (isPrivate) {
      const { message: { last: lastMessage, total, seen } = {} } = room;
      const { authorId, textMessage, timestamp } = lastMessage || {};
      const { lang } = this.props;
      const user = room.users.find(u => u.userId !== this.props.userid) || {};
      const count = total - (seen || 0);
      let text = textMessage || '';
      if (text.length > 20) {
        text = `${textMessage.slice(0, 20)}...`;
      }
      // P.S. <authorId> не всегда равно <user.user_id>
      retval = (
        <div className="chat-room__item" key={index} data-roomname={room.room_name} onClick={this.onRoomClick}>
          <div className={`chat-room__item-userphoto${user.active ? ' active' : ''}`}>
            <img src={user.userPhoto ? `${config.FETCH.img}/${user.userPhoto}` : defaultAvatar} />
             <div className='avgScore'>{(+user.avgscore || 0).toFixed()}</div>
          </div>
          <div
            className="chat-room__item-message"
            data-userid={user.user_id}
            data-username={user.userName}
            data-timestamp={timestamp || 0}
          >
            <div className="chat-room__item-message-top">
              <div className="chat-room__item-message-user">
                {user.userName} <br/>
                <RatingStars width={12} height={12} rating={user.rating || 0} />
              </div>
              {lastMessage ? (
                <span className="chat-room__item-message-date">{Utils.formatDateTime(timestamp, lang, 'DD MMMM')}</span>
              ) : null}
            </div>
            <div className="chat-room__item-message-bottom" data-authorid={authorId}>
              {lastMessage ? <span className="chat-room__item-message-text">{text}</span> : null}
              {count ? <span className="badge">{count > 999 ? '999+' : count}</span> : null}
            </div>
          </div>
        </div>
      );
    }
    return retval;
  };

  renderHeader = () => {
    const { intl } = this.props;
    return (
      <div className="menu-header">
        <div className="header__text">{intl.formatMessage({ id: 'ChatTitle', defaultMessage: 'Chat' })}</div>
      </div>
    );
  };

  makeDeboundSearch = debounce(() => {
    const { searchText } = this.state;
    this.makeSearch(searchText);
  }, 200);

  handleSearch = (e) => {
    const { value: searchText } = e.target;
    this.setState({ searchText }, () => {
      this.makeDeboundSearch(searchText);
    });
  };

  renderSearch = () => {
    const { intl } = this.props;
    return (
      <div className="search">
        <FilterInput
          placeholder={intl.formatMessage({ id: 'SearchAsActionAlias', defaultMessage: 'Search' })}
          onChange={this.handleSearch}
          className="input"
          value={this.state.searchText}
        />
      </div>
    );
  };

  makeSearch = (searchText) => {
    const { rooms } = this.props;
    if (!searchText || !searchText.trim()) {
      this.setState({ rooms });
      return rooms;
    }
    const result = [];
    const searchs = searchText
      .toLowerCase()
      .split(/\s+/g)
      .filter(txt => txt && txt.trim());
    const matchLen = searchs.length;
    const isStr = val => typeof val === 'string';
    for (const room of rooms) {
      const { users } = room;
      let found;
      for (const user of users) {
        let { lastName = '', firstName = '', login = '' } = user;
        lastName = isStr(lastName) ? lastName : '';
        firstName = isStr(firstName) ? firstName : '';
        login = isStr(login) ? login : '';
        const names = [lastName.toLowerCase(), firstName.toLowerCase(), login.toLowerCase()];
        found = 0;
        for (const text of searchs) {
          found += !!names.find(name => name.includes(text));
        }
        if (found === matchLen) {
          result.push(room);
          break;
        }
      }
    }
    this.setState({ rooms: result });
    return result;
  };

  openSupportChat = () => {
    const { userid: from, rooms, setCurrentRoom, createSupportRoom } = this.props;
    const supportRoom = `support-room-${from}`;
    const room = rooms.find(r => r.room_name === supportRoom);
    if (room) {
      setCurrentRoom(supportRoom);
    } else {
      createSupportRoom();
    }
  }

  render() {
    const { rooms, intl, squadid, systemMessages } = this.props;
    const { rooms: stRooms = rooms } = this.state;
    let roomList = rooms;

    if (stRooms.length < rooms.length) {
      roomList = stRooms;
    }
    let supportUnseen = 0;
    const supportRoom = roomList.find(r => r.room_name.startsWith('support-room-'));
    if (supportRoom) {
      const { seen = 0, total = 0 } = supportRoom.message;
      supportUnseen = +total - +seen;
    }
    if (supportUnseen < 0) {
      supportUnseen = 0;
    }

    // let battleUnseen = 0;
    // const battleRoom = roomList.find(r => r.room_name.startsWith('squad-chat-'));

    // if (battleRoom) {
    //   const { seen = 0, total = 0 } = battleRoom.message;
    //   battleUnseen = +total - +seen;
    // }
    // if (battleUnseen < 0) {
    //   battleUnseen = 0;
    // }

    const battleUnseen = (roomList || []).reduce((count, room) => {
      if ((room.room_name.startsWith('squad-chat') || 
        room.room_name.startsWith('private-room') || 
        room.room_name.startsWith('support-room')) && 
        (this.props.currentRoom !== room.room_name) && 
        (room.users && room.users.length > 1)
      ) {
        const { message: { seen = 0, total = 0, total_system = 0, seen_system = 0 } = {} } = room;

        return (systemMessages)
          ? count + (total - seen)
          : count + ((total - total_system) - (seen - seen_system));
      }
      return count;
    }, 0);


    return (
      <div className="chat-container non-battle-chat">
        {this.renderHeader()}
        {this.renderSearch()}
        <div className="chat-room__container">
          <div className="chat-room__list">
            <div className="chat-room__item support" onClick={() => this.openSupportChat()}>
              <div className="chat-room__item-userphoto">
                <SupportSvg/>
              </div>
              <div className="chat-room__item-message">
                <div className="chat-room__item-message-top">
                  <span className="chat-room__item-message-user">
                    {intl.formatMessage({ id: 'Support', defaultMessage: 'Support' })}
                  </span>
                </div>
                <div className="chat-room__item-message-bottom">
                  <span className="chat-room__item-message-text">
                    {intl.formatMessage({ id: 'WriteToSupport', defaultMessage: 'Write to support' })}
                  </span>
                  {
                    supportUnseen > 0 
                      ? <span className="badge">{supportUnseen ? supportUnseen : ''}</span>
                      : null
                  }
                  
                </div>
              </div>
            </div>
            {squadid && <div className="chat-room__item battle" onClick={() => this.props.setCurrentRoom(`squad-chat-${squadid}`)}>
              <div className="chat-room__item-userphoto" style={{ borderRadius: 0 }}>
                <img src={squadchaticon} alt="" style={{ borderRadius: 0, width: 25, height: 25, marginLeft: 5, marginRight: 6 }}/>
              </div>
              <div className="chat-room__item-message">
                <div className="chat-room__item-message-top">
                  <span className="chat-room__item-message-user">
                    Battle
                  </span>
                </div>
                {
                  battleUnseen > 0 
                    ? <span className="badge">{battleUnseen ? battleUnseen : ''}</span>
                    : null
                }
              </div>
            </div>}
            { roomList.filter(room => {
              return (
                room.room_name.startsWith('support-room-') ||
                room.room_name.startsWith('squad-chat-') ||
                room.room_name.startsWith('Observe-squad-chat-') ||
                !room ||
                !room.users ||
                room.users.length <= 1)
            }).length ?
              <div className="chat-room__private_rooms">
                {roomList.map(this.renderRoom)}
              </div> : 
              <div className="chat-room__empty_container">
                <div className="chat-room__no_data_found">
                  <div className="chat-room__no_data_found__image">
                    <img src={no_chats} alt=""/>
                  </div>
                  <div className="chat-room__no_data_found__description">
                    {intl.formatMessage({ id: 'NoChatsFoundDescription', defaultMessage: 'You have no active chats' })}
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(
  connect(
    state => ({
      lang: state.langs.lang,
      userid: state.users.user.id,
      rooms: state.chat.rooms,
      squadid: state.squadId,
      systemMessages: state.chat.systemMessages
    }),
    {
      setCurrentRoom,
      createSupportRoom,
    },
  )(TournamentsChatRooms),
);
