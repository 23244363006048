import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { injectIntl } from 'react-intl';
import { Button } from '../common/Button';
import { getAgreements } from '../../libs/reducers/commonReducer';
// import Modal from '../common/Modal';

import './style.scss';

class PrivacyPolicyModal extends React.Component {
  componentDidMount() {
    if (!['EN', 'RU'].includes(this.props.langs.lang)) {
      this.props.getAgreements('EN');
    } else {
      this.props.getAgreements(this.props.langs.lang || 'EN');
    }
  }

  componentDidUpdate(prevProps) {
    const { langs: { lang } } = this.props;
    if (prevProps.langs.lang !== lang) {
      if (['EN', 'RU'].includes(lang)) {
        this.props.getAgreements(lang);
      } else {
        this.props.getAgreements('EN');
      }
    }
  }

  renderFooter() {
    return (
      <div className="tc-content__footer-container">
        <Button onClick={() => this.props.onClose()} className="tc-content__footer-btn" kind="orange">
          {this.props.intl.formatMessage({ id: 'GotItThanks', defaultMessage: 'Got it, thanks' })}
        </Button>
      </div>
    );
  }

  render() {
    const {
      visible,
      onClose = () => {},
      agreements,
      langs,
      intl,
    } = this.props;

    let pd = agreements[`PD${(langs.lang || 'en').toLowerCase()}`];
    if (!pd && !['EN', 'RU'].includes(this.props.langs.lang)) {
      pd = agreements.PDen;
    }

    return (
      <Modal
        title={null}
        footer={this.renderFooter()}
        visible={visible}
        onCancel={onClose}
        onOk={onClose}
        className="terms-and-condition__modal"
        bodyStyle={{ backgroundColor: '#fff', overflowY: 'auto', height: '100%' }}
        width={600}
        closeIcon={null}
        closable={false}
      >
        <div className="tc-content__container">
          <div className="tc-content__header">{intl.formatMessage({ id: 'privacy_policy2', defaultMessage: 'Privacy Policy' })}</div>
          <div className="tc-content__text" dangerouslySetInnerHTML={{ __html: pd }} />
        </div>
      </Modal>
    );
  }
}

export default connect(
  state => ({
    agreements: state.common.agreements,
    langs: state.langs,
  }),
  { getAgreements }
) (injectIntl(PrivacyPolicyModal));
