import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import moment from 'moment';

import { GetFriends } from '../../../libs/reducers/FriendsReducer';
import InboxApplications from './inboxApplications';
import OutboxApplications from './outboxApplications';
import ModalUser from '../../common/modals/modalUser';
import ModalInviteBattle from '../../common/modals/modalInviteBattle';
import { UserByIdFetch, CreateBattle } from '../../../libs/reducers/actions';
import './style.css';

class FriendsApplications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      friends: [],
      showInviteBattle: false,
      showUserById: false,
    };
  }

  static propTypes = {
    user: PropTypes.shape(),
    userById: PropTypes.shape(),
    CreateBattle: PropTypes.func,
    UserByIdFetch: PropTypes.func,
    GetFriends: PropTypes.func,
    intl: intlShape,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.friends.data) {
      let friendsArr = [];
      if (nextProps.friends.data.length) {
        friendsArr = nextProps.friends.data.filter(friend => friend.confirmed === 0);
      }
      return { friends: friendsArr };
    }
    return null;
  }

  componentDidMount() {
    this.props.GetFriends();
  }

  onInviteBattleSubmit = ({
    betType, bet, winCount, dateType, date, time, comment, opponent,
  }) => {
    const sendParams = { name: 'battle' };
    sendParams.wincount = winCount;

    if (betType === 2) {
      sendParams.cost = bet;
    }
    if (dateType === 2) {
      let datestart;
      if (time !== null) {
        datestart = moment(date.set('h', time.hour()).set('m', time.minute()));
      } else {
        datestart = moment(date);
      }
      sendParams.datestart = moment(datestart).toISOString();
    }
    if (comment !== '') {
      sendParams.rules = comment;
    }
    sendParams.userid = opponent.id;
    this.props.CreateBattle({
      ...sendParams,
    });
    this.setState({ showInviteBattle: false });
  };

  linkClick = (record) => {
    this.props.UserByIdFetch({
      userid: record.user.id,
    });
  };

  render() {
    const { friends, showInviteBattle, showUserById } = this.state;
    const { user, userById, intl } = this.props;

    return (
      <div>
        <Tabs>
          <TabList>
            <Tab>
              <div>{intl.formatMessage({ id: 'FriendListInvitesInbox', defaultMessage: 'INBOX' })}</div>
            </Tab>
            <Tab>
              <div>{intl.formatMessage({ id: 'FriendListInvitesOutbox', defaultMessage: 'OUTBOX' })}</div>
            </Tab>
          </TabList>
          <TabPanel>
            <InboxApplications
              linkClick={this.linkClick}
              friends={friends.length ? friends.filter(friend => friend.ordertype === 'in') : []}
            />
          </TabPanel>
          <TabPanel>
            <OutboxApplications
              linkClick={(record) => {
                this.linkClick(record);
              }}
              friends={friends.length ? friends.filter(friend => friend.ordertype === 'out') : []}
            />
          </TabPanel>
        </Tabs>
        <ModalInviteBattle
          visible={showInviteBattle}
          onSubmit={this.onInviteBattleSubmit}
          onClose={() => {
            this.setState({
              showInviteBattle: false,
              showUserById: true,
            });
          }}
          opponent={{
            ...userById.data,
            hourshift: (userById.data.hourshift && userById.data.hourshift !== 0
              ? userById.data.hourshift
              : '0'
            ).toString(),
          }}
        />
        <ModalUser
          title={intl.formatMessage({ id: 'FriendListUserCardModalTitle', defaultMessage: 'User card' })}
          onClose={() => {
            this.setState({
              showUserById: false,
            });
          }}
          onChallengeClick={() => {
            this.setState({
              showUserById: false,
              showInviteBattle: true,
            });
          }}
          isChallengeAllowed={user.id !== userById.data.id}
          user={{
            ...userById.data,
            // hourshift: (userById.data.hourshift && userById.data.hourshift !== 0
            //   ? userById.data.hourshift
            //   : '0'
            // ).toString(),
          }}
          visible={showUserById}
        />
      </div>
    );
  }
}

export default injectIntl(
  connect(
    state => ({
      user: state.users.user,
      userById: state.users.userById,
      friends: state.friends.friends,
    }),
    { GetFriends, CreateBattle, UserByIdFetch },
  )(FriendsApplications),
);
