/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment';
import BillingBadge from '../../common/BillingBadge';
import { Button } from '../../common/Button';
import Checkbox from '../../common/Checkbox';
import TariffPicker from '../../tariffPicker';
import CreditCardPicker from '../../common/CardPicker';
import OBModal from '../../common/Modal';
import config from '../../../config';

import {
  getUserTariff,
  getTariffList,
  dropTariff,
  fetchUserDisk,
  switchRenewal,
  setTariffCallback,
  requestTournamentInfo,
} from '../../../libs/reducers/actions';
import { getAgreements } from '../../../libs/reducers/commonReducer';
import ModalSaveBattle from '../../common/modals/modalSaveBattle';

import './style.scss';
import { openPolicy } from '../../policy';

class UserTariff extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      autopay: true,
      tariffPick: false,
      selectedTariff: false,
      selectedCard: false,
      accountid: false,
      dropConfirm: false,
      newrenewal: false,
    }
  }

  static propTypes = {
    intl: intlShape,
  };

  componentDidMount() {
    this.props.getUserTariff();
    this.props.fetchUserDisk();
    this.props.getAgreements(this.props.langs.lang || 'en');
    if (document.location.search && document.location.search.slice(1).split('&').find(item => item==='from=bank')) {
      if (this.props.tariffCallback) {
        const elem = this.props.tariffCallback.split('-')[0];
        const params = this.props.tariffCallback.split('-')[1];
        switch (elem) {
          case 'OpenSaveModal':
            this.setState({ openSaveModal: params });
            this.props.setTariffCallback();
            break;
          case 'OpenStatistics':
            this.props.history.push('/account/statistics');
            this.props.setTariffCallback();
            break;
          default:
            return
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { showModalTC } = this.state;
    if (!prevState.showModalTC && showModalTC) {
      openPolicy({ kind: 'TC' });
      this.setState({ showModalTC: false });
      return;
    }
    if (!this.state.accountid && this.props.accounts) {
      const account = this.props.accounts.find(acc => !acc.currencyisvirtual);
      if (account) {
        this.setState({ accountid: { value: account.accountid, label: account.currencysymbol } })
      }
    }
    if (prevProps.tariff !== this.props.tariff) {
      this.props.fetchUserDisk();
    }
    if (prevProps.langs.lang !== this.props.langs.lang) {
      this.props.getAgreements(this.props.langs.lang || 'en');
    }
  }


  render() {
    const { intl, tariff, tariffs=[] } = this.props;
    const { autopay, tariffPick, selectedTariff, selectedCard, accountid, dropConfirm } = this.state;
    const pallette = ((tariff||{}).designscheme||'').split(',');

    return <>
      <div className="tariffScreen">
        <ModalSaveBattle
          visible={!!this.state.openSaveModal}
          owned={true}
          squadid={this.state.openSaveModal}
          history={this.props.history}
          onSubmit={()=>{ 
            this.setState({
              openSaveModal: false,
            });
          }} 
          onCancel={()=>{ 
            this.setState({
              openSaveModal: false,
            });
          }} 
        />
        <div className="screenName">{intl.formatMessage({ id: 'CurrentUserTariff', defaultMessage: 'Current tariff' })}</div>
        {!tariff ? <>
          <div className="noTariff">
            <div className='header'>{intl.formatMessage({ id: 'NoUserTariff', defaultMessage: 'Tariff not selected' })}</div>
            <div className='explanation'>
              {intl.formatMessage({ id: 'TariffExplanation', defaultMessage: 'Free versions does not provide access to Statistics, Videoarchives nor Disk Space. To access this features enable tariff' })} <b>PRO</b>
            </div>
            <Button
              kind='aquamarine'
              bordered={false}
              onClick={() => {
                this.props.getTariffList();
                this.setState({tariffPick: true});
                }}>
              {intl.formatMessage({ id: 'SelectTariff', defaultMessage: 'Select tariff' })} PRO
            </Button>
          </div>
        </> : <>
          <div className="activeTariff">
            <div className='tariffCard'>
              <div className='header' style={{ background: `linear-gradient(262.91deg, ${pallette[0]} 0%, ${pallette[1]} 100%)` }}><span>{tariff.name}</span><span>{1+moment(tariff.ab_pend).diff(moment(), 'days')}<small>{intl.formatMessage({ id: 'DayPluralAlt', defaultMessage: '{count, plural, one {day} other {days}}' }, { count: 1+moment(tariff.ab_pend).diff(moment(), 'days')})}</small></span></div>
              <div className='content' style={{ background: `linear-gradient(262.91deg, ${pallette[0]}64 0%, ${pallette[1]}64 100%)` }}>
                {this.props.userdisk.data && <>
                  <div className='barName'>
                    {intl.formatMessage({ id: 'UsersDiskAsSpaceShowcaseAlias', defaultMessage: 'Your disk' })} {(this.props.userdisk.data.fullSpace/(Math.pow(1000, 3))).toFixed(0)}Gb
                  </div>
                  <div className='bar'>
                    <div className='elem' style={{ flexBasis: `${100*(1-this.props.userdisk.data.freeSpace/this.props.userdisk.data.fullSpace)}%` }}/>
                  </div>
                  <div className='barSummary'>
                    {intl.formatMessage({ id: 'FreeDiskSpacePrefix', defaultMessage: 'Free:' })} {(this.props.userdisk.data.freeSpace/(Math.pow(1000, 3))).toFixed(2)}Gb {intl.formatMessage({ id: 'xOfAmount', defaultMessage: 'of' })} {(this.props.userdisk.data.fullSpace/(Math.pow(1000, 3))).toFixed(0)}Gb
                  </div>
                </>}
              </div>
            </div>
            {tariff.recurred && <> {/*tariff.recurred возвращает 1, если на сервере существует подписка для повторного платежа с этим тарифом*/}
              <Checkbox checked={this.props.renewal} onChange={() => this.props.switchRenewal()}>
                {intl.formatMessage({ id: 'ProlongTariffCheckboxLabel', defaultMessage: 'Prolong tariff plan automatically' })}
              </Checkbox>
              <div className='checkboxAddition'>
                {intl.formatMessage({ id: 'ProlongTariffExplanation', defaultMessage: 'Agreement for {amount} euro charge upon tariff period ending'}, {amount: (tariff.services||[{}]).reduce((sum, item) => sum+item.amount, 0)} )}
                {this.props.renewal && this.props.userCards && this.props.userCards.length
                  ? `. ${intl.formatMessage({ id: 'DebitFromTheCard', defaultMessage: 'Debit will occur from the card'})} ${this.props.userCards[0].mask.replace(/X/g, '*')}`
                  : null
                }
              </div>
            </>}
            <div className='buttons'>
              {tariff.recurred
              ?
              <>
              {/*<Button
                kind='aquamarine'
                bordered="true"
                onClick={() => this.setState({dropConfirm: true})}>
                {intl.formatMessage({ id: 'CancelSubscribtionAsForceEndAlias', defaultMessage: 'Cancel subscribtion' })}   //Функционал временно отключен
              </Button>*/}
              <Button
                kind='aquamarine'
                bordered="true"
                onClick={() => {
                  this.props.getTariffList();
                  this.setState({tariffPick: true});
                  }}>
                {intl.formatMessage({ id: 'ChangeSubscribtionAsReselectAlias', defaultMessage: 'Change tariff' })}
              </Button>
              </>
              :
              <Button
                kind='aquamarine'
                bordered={false}
                onClick={() => {
                  this.props.getTariffList();
                  this.setState({tariffPick: true});
                  }}>
                {intl.formatMessage({ id: 'SelectTariff', defaultMessage: 'Select tariff' })} PRO
              </Button>
              }
            </div>
          </div>
        </>}
        {tariffPick && <TariffPicker
          tariffs={(() => {
            let bestIndex = 0;
            let bestPrice = 0;
            tariffs.forEach((t, index) => {
              if (bestPrice<(t.services||[{}]).reduce((sum, item) => sum+item.amount, 0)) {bestPrice=(t.services||[{}]).reduce((sum, item) => sum+item.amount, 0);bestIndex=index}
            });
            return tariffs.map((t, index) => {
              const pallette = (t.designscheme||'').split(',');
              return {
                id: t.id,
                name: t.name,
                price: (t.services||[{}]).reduce((sum, item) => sum+item.amount, 0),
                services: (t.services||[{}]),
                bg: `linear-gradient(256.06deg, ${pallette[0]} 0%, ${pallette[1]} 100%)`,
                bgbody: `linear-gradient(256.06deg, ${pallette[0]}64 0%, ${pallette[1]}64 100%)`,
                best: index==bestIndex,
              }
            })})()}
          onTnCOpen={() => this.setState({ showModalTC: true, newrenewal: false })}
          agreementRenewal={this.state.newrenewal}
          onRenewalChange={() => this.setState({ newrenewal: !this.state.newrenewal })}
          onSelect={tariff => {this.setState({ tariffPick: false, selectedTariff: tariff.id })}}
          onClose={() => this.setState({ tariffPick: false })}
        />}
        <BillingBadge/>
        <OBModal
          title={intl.formatMessage({ id: 'CardSelectHeader', defaultMessage: 'Pick a credit card' })}
          visible={!!selectedTariff}
          width={395}
          onCancel={() => this.setState({selectedTariff: false, selectedCard: false})}
          footer={[
            <div className="dropTariffButtons">
              <Button kind="aquamarine" bordered="true" onClick={() => this.setState({selectedTariff: false, selectedCard: false})}>
                {intl.formatMessage({ id: 'CloseAsCancelAlias', defaultMessage: 'Close' })}
              </Button>
              <Button
                  kind='aquamarine'
                  bordered={false}
                  onClick={() => {}}>
                  <a
                    onClick={() => this.setState({ undergoingPayment: true }, () =>{
                      setTimeout(() => {
                        window.location.href = `${config.FETCH.url}/ecom/ecom_subscribe/${selectedCard}/${selectedTariff}/${(accountid || {value: null}).value}/${this.state.newrenewal?'1':'0'}`
                      }, 1000)
                    })}
                    className={`buttonlike${!(selectedCard && selectedTariff)?' disabled':''}`}>
                  {intl.formatMessage({ id: 'PayAsSpendCashAlias', defaultMessage: 'Pay' })}</a>
                </Button>
            </div>,
          ]}
          >
          <CreditCardPicker onSelect={e => this.setState({selectedCard: e.value&&e.type=='card'?e.value.id:null})} notNull destination={'tariff'} customOrder={`tariffid=${selectedTariff}&accountid=${(accountid || {value: null}).value}`} renew={this.state.newrenewal?'1':'0'}/>

        </OBModal>
        <OBModal
          title={intl.formatMessage({ id: 'PaymentProcessingTitle', defaultMessage: 'Payment processing' })}
          visible={this.state.undergoingPayment}
          noClose
          onOk={() => {}}
          closable={false}
          width={395}
          onCancel={() => this.setState({dropConfirm: false})}
          footer={[]}
          >
          <div className='paymentText'>
            {intl.formatMessage({ id: 'PaymentProcessingText', defaultMessage: 'Please wait, payment being under process' })}
          </div>
        </OBModal>
        {/* <OBModal
          title={intl.formatMessage({ id: 'TCagreeD', defaultMessage: 'Terms and condition' })}
          visible={this.state.showModalTC}
          closable
          onCancel={() => this.setState({ showModalTC: false })}
          zIndex={999999999}
          footer={[
            <Button key="submit" onClick={() => this.setState({ showModalTC: false })}>
              OK
            </Button>,
          ]}>
          <div className='paymentText'>
          <div className='userAgreementText' dangerouslySetInnerHTML={{ __html: this.props.agreements[`TC${(this.props.langs.lang || 'en').toLowerCase()}`] }}/>
          </div>
        </OBModal> */}
        <OBModal
          title={intl.formatMessage({ id: 'CancelSubscribtionAsForceEndAlias', defaultMessage: 'Cancel subscribtion' })}
          visible={dropConfirm}
          width={395}
          onCancel={() => this.setState({dropConfirm: false})}
          footer={[
            <div key="buttons" className="dropTariffButtons">
              <Button kind="aquamarine" bordered="true" onClick={() => this.setState({dropConfirm: false})}>
              {intl.formatMessage({ id: 'CloseAsCancelAlias', defaultMessage: 'Close' })}
              </Button>
              <Button
                  kind='aquamarine'
                  bordered={false}
                  onClick={() => {this.props.dropTariff();this.setState({dropConfirm: false})}}>
                  {intl.formatMessage({ id: 'CancelSubscribtionAsForceEndAlias', defaultMessage: 'Cancel subscribtion' })}
                </Button>
            </div>,
          ]}
          >
          <div className='reversalText'>
            <span className='reversalTextBig'>{intl.formatMessage({ id: 'CancelSubscribtionConfirmation', defaultMessage: 'Are you sure you want to cancel subscribtion?' })}</span>
            <span className='reversalTextSmall'>{intl.formatMessage({ id: 'CancelSubscribtionConsequences', defaultMessage: 'On cancelation you will get refund for unused full days' })}</span>
          </div>
        </OBModal>
      </div>
    </>
  }
}

export default injectIntl(
  connect(
    state => ({
      tariff: state.billing.usertariff,
      tariffs: state.billing.tariffs,
      renewal: state.billing.renewal,
      accounts: state.users.user.currencies,
      userdisk: state.userdisk,
      agreements: state.common.agreements,
      langs: state.langs,
      tariffCallback: state.billing.tariffCallback,
      userCards: state.billing.userCards,
    }),
    {
      getTariffList,
      getUserTariff,
      dropTariff,
      switchRenewal,
      fetchUserDisk,
      getAgreements,
      setTariffCallback,
    },
  )(UserTariff),
);
