import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Modal from '../../Modal';
import getCodeData from './getCodeData';
import { Button } from '../../Button';
import './modalCodeError.css';

class ModalCodeError extends React.Component {
  static propTypes = {
    code: PropTypes.string,
    data: PropTypes.shape(),
    intl: intlShape,
    okText: PropTypes.string,
    onOk: PropTypes.func,
  }

  static defaultProps = {
    code: '',
    data: null,
  }

  onOk = () => {
    const { onOk } = this.props;
    if (typeof onOk === 'function') {
      onOk();
    }
  }

  handleOK = () => {
    this.onOk();
  }

  renderFooter = () => {
    const { intl } = this.props;
    const { okText = intl.formatMessage({ id: 'ModalDefaultOK', defaultMessage: 'OK' }) } = this.props;
    return (
      <Button key="okButton" kind="aquamarine" onClick={this.handleOK}>
        {okText}
      </Button>
    );
  }

  render() {
    const { code, intl, data } = this.props;
    const { title, message } = getCodeData({ intl, code, data });
    return <Modal
      title={title}
      visible={!!code}
      width={395}
      onOk={this.onOk}
      onCancel={null}
      footer={this.renderFooter()}
    >
      <div className="modal_code_error_message">
        {message}
      </div>
    </Modal>;
  }
}

export default injectIntl(ModalCodeError);
