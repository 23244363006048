import Utils from '../../libs/utils';
// usage:
// VIDEO_URLS.hls - array of hls streams
// VIDEO_URLS.flu - array of streams for flussonic-mse-player
// VIDEO_URLS.webrtc - array of webrtc streams
const VIDEO_URLS = {
  secure: true, // http: -> false, https: -> true
  // host: 'bokus1.ru.fvds.ru:8080',
  // host: '92.63.107.238:8080',
  // host: '92.63.107.238:443',
  get host() {
    // return `bokus1.ru.fvds.ru:${this.secure ? '443' : '8080'}`;
    return `tenpincam.com:${this.secure ? '8081' : '8080'}`;
  },
  desktop: {
    streams: ['cam1-0', 'cam4-0', 'cam7-0'],
  },
  mobile: {
    streams: ['cam1-1', 'cam4-1', 'cam7-1'],
  },
  duration: 5 * 60, // 5 min
  get origin() {
    return `http${this.secure ? 's:' : ':'}//${this.host}`;
  },
  // get download() {
  //     const now = Math.floor(Date.now()/1000);
  //     const { time } = this;
  //     return this._getUrls('_download', `archive-${now - time}-${time}.mp4`);
  // },
  get download() {
    if (this._download) {
      return this._download;
    }
    const now = Math.floor(Date.now() / 1000);
    const start = now - 3 * 60 * 60; // -3 hours
    const { duration } = this;
    this._download = [];
    for (let i = 0; i < 3; ++i) {
      this._download = [
        ...this._download,
        ...this._getUrls(`_download${i}`, `archive-${start + i * 60 * 60}-${duration}.mp4`),
      ];
    }
    return this._download;
  },
  get archive() {
    if (this._archive) {
      return this._archive;
    }
    return (this._archive = this.download.map(d => d.replace(/archive\-(\d+\-\d+)\.mp4$/, 'video-$1.m3u8')));
  },
  get hls() {
    return this._getUrls('_hls', 'index.m3u8');
  },
  get flu() {
    return this._getUrls('_flu', 'mse_ld');
  },
  get webrtc() {
    return this._getUrls('_webrtc', 'webrtc');
  },
  _getUrls(propName, suffix) {
    this[propName] = this[propName] || this.streams.map(stm => this._fullUrl(`/${stm}/${suffix}`));
    return this[propName];
  },
  get streams() {
    this._streams = this._streams || (Utils.isMobile() ? this.mobile.streams : this.desktop.streams);
    return this._streams;
  },
  _fullUrl(path) {
    let proto;
    if ((path && path.includes('.m3u8')) || path.includes('.mp4') || path.includes('.jpg')) {
      proto = this.secure ? 'https:' : 'http:';
    } else {
      proto = this.secure ? 'wss:' : 'ws:';
    }
    return `${proto}//${this.host}${path}`;
  },
};

// console.log(VIDEO_URLS);

export default VIDEO_URLS;
