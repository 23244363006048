import {
  put, call, takeEvery, select,
} from 'redux-saga/effects';
import _ from 'lodash';
import bugsnagClient from '../../bugsnagClient';
import config from '../../config';
import { get, put as PUT } from '../Networking';
import {
  GET_NOTIFICATIONS_SET,
  GET_NOTIFICATIONS_FETCH,
  REMOVE_NOTIFICATION_SET,
  REMOVE_NOTIFICATION_FETCH,
  SOCKET_NOTIFICATIONS_UPDATE,
  SOCKET_NOTIFICATIONS_SET,
  READ_NOTIFICATIONS
} from '../reducers/notifications.reducer';

const getNotifications = state => state.notifications;

export function* _getNotifications({ params }) {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/notifications` });
    if (result.status === 200) {
      yield put({ type: GET_NOTIFICATIONS_SET, data: result.data.data });
      if (params.read === 'all') {
        yield call(_readAllNotifications);
      } else {
        yield call(_readNotifications, { params: _.map(result.data.data, 'id') })
      }
    } else {
      yield put({ type: GET_NOTIFICATIONS_SET, data: [] });
    }
  } catch (err) {
    bugsnagClient.notify(err, { context: 'getNotifications' });
    console.error('_getNotifications: ', err);
  }
}

export function* _readAllNotifications() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/notifications/readall` });
    yield call(_unreadNotificationsCount)
  } catch (err) {
    bugsnagClient.notify(err, { context: '_readAllNotifications' });
    console.error('_readAllNotifications: ', err);
  }
}

export function* _readNotifications({ params }) {
  try {
    const result = yield call(PUT, { url: `${config.FETCH.url}/notifications/read`, data: params });
    yield call(_unreadNotificationsCount)
  } catch (err) {
    bugsnagClient.notify(err, { context: 'readNotifications' });
    console.error('_readNotifications: ', err);
  }
}

export function* _unreadNotificationsCount() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/notifications/unread` });
    if (result.status === 200) {
      yield put({ type: GET_NOTIFICATIONS_SET, count: result.data.data });
    }
  } catch (err) {
    bugsnagClient.notify(err, { context: 'unreadNotificationsCount' });
    console.error('_unreadNotificationsCount: ', err);
  }
}

export function* _removeNotification({ params }) {
  const notifications = yield select(getNotifications);
  yield put({
    type: GET_NOTIFICATIONS_SET,
    data: params && params.id ? _.filter(notifications.notifications.data, item => item.id !== params.id) : [],
  });
  yield put({
    type: SOCKET_NOTIFICATIONS_UPDATE,
    data: params && params.id ? _.filter(notifications.socketNotifications, item => item.id !== params.id) : [],
  });
  try {
    const result = yield call(PUT, {
      url: `${config.FETCH.url}/notifications/${params && params.id ? params.id : 'all'}`,
    });
    if (result.status === 200) {
      yield put({ type: REMOVE_NOTIFICATION_SET, data: result.data.data });
    } else {
      yield put({ type: REMOVE_NOTIFICATION_SET, data: result.error || result.data.error || 'ERROR' });
    }
  } catch (err) {
    bugsnagClient.notify(err, { context: 'removeNotification' });
    console.error('Squad get: ', err);
  }
}

export function* _WatchNotifications() {
  try {
    yield takeEvery(GET_NOTIFICATIONS_FETCH, _getNotifications);
    yield takeEvery(REMOVE_NOTIFICATION_FETCH, _removeNotification);
    yield takeEvery(SOCKET_NOTIFICATIONS_SET, _unreadNotificationsCount);
    yield takeEvery(READ_NOTIFICATIONS, _readNotifications);
  } catch (error) {
    bugsnagClient.notify(error, { context: 'saga WatchNotifications' });
    console.error('===WatchNotifications Error: ', error);
  }
}
