import React, { Component } from 'react';
import _ from 'lodash';
import './style.css';

export default class UserDiskDiagram extends Component {

  constructor(props) {
    super(props);
    this.state = {
      size: props.size,
      strokeWidth: props.strokeWidth,
      freeGrad: props.freeGrad,
      busyGrad: props.busyGrad,
      needGrad: props.needGrad,
      overGrad: props.overGrad,
      busy: props.busy,
      need: props.need,
    }
  }

  static defaultProps = {
    size: 100,
    strokeWidth: 15,
    busy: 0,
    need: 0,
    freeGrad: ['0%', '#25292C', '100%', '#25292C'],
    busyGrad: ['0%', '#01677E', '100%', '#20A39E'],
    needGrad: ['0%', '#CC3535', '100%', '#F65932'],
    overGrad: ['0%', '#E11D40', '100%', '#E11D40']
  };

  render() {

    const {
      size,
      strokeWidth,
      busy,
      need,
      freeGrad,
      busyGrad,
      needGrad,
      overGrad
    } = this.props;

    const radius = size / 2 - strokeWidth / 2;
    const diametr = radius * 2;
    const viewbox = `0 0 ${size} ${size}`;
    const length = _.round(Math.PI * diametr, 2);
    const quarter = _.round(length / 4, 2);
    const cxy = _.round(size / 2, 2);

    let busyPerc = busy;
    let needPerc = need;

    const overflow = busyPerc + needPerc > 100;

    if (overflow) {
      needPerc = 100 - busyPerc;
    }

    let circles = [];

    circles[0] = {};
    circles[0].gradId = _.uniqueId('freeGrad_');
    circles[0].grad = `url(#${circles[0].gradId})`;
    circles[0].dashArray = [length, 0];
    circles[0].dashOffset = 0;

    circles[1] = {};
    circles[1].gradId = _.uniqueId('busyGrad_');
    circles[1].grad = `url(#${circles[1].gradId})`;
    circles[1].dashArray = [];
    circles[1].dashArray[0] = _.round(length / 100 * busyPerc, 2);
    circles[1].dashArray[1] = _.round(length - circles[1].dashArray[0], 2);
    circles[1].dashOffset = _.round(quarter - circles[1].dashArray[1], 2);

    circles[2] = {};
    circles[2].gradId = [];
    circles[2].gradId[0] = _.uniqueId('overGrad_');
    circles[2].gradId[1] = _.uniqueId('needGrad_');
    circles[2].grad = [];
    circles[2].grad[0] = `url(#${circles[2].gradId[0]})`;
    circles[2].grad[1] = `url(#${circles[2].gradId[1]})`;
    circles[2].dashArray = [];
    circles[2].dashArray[0] = _.round(length / 100 * needPerc, 2);
    circles[2].dashArray[1] = _.round(length - circles[2].dashArray[0], 2);
    circles[2].dashOffset = _.round(circles[2].dashArray[0] + circles[1].dashOffset, 2);

    return (
      <svg width={size} height={size} viewbox={viewbox} xmlns="http://www.w3.org/2000/svg">
        <linearGradient id={circles[0].gradId}>
          <stop offset={freeGrad[0]} stop-color={freeGrad[1]}></stop>
          <stop offset={freeGrad[2]} stop-color={freeGrad[3]}></stop>
        </linearGradient>
        <linearGradient id={circles[1].gradId}>
          <stop offset={busyGrad[0]} stop-color={busyGrad[1]}></stop>
          <stop offset={busyGrad[2]} stop-color={busyGrad[3]}></stop>
        </linearGradient>
        <linearGradient id={circles[2].gradId[1]}>
          <stop offset={needGrad[0]} stop-color={needGrad[1]}></stop>
          <stop offset={needGrad[2]} stop-color={needGrad[3]}></stop>
        </linearGradient>
        <linearGradient id={circles[2].gradId[0]}>
          <stop offset={overGrad[0]} stop-color={overGrad[1]}></stop>
          <stop offset={overGrad[2]} stop-color={overGrad[3]}></stop>
        </linearGradient>
        <circle class="free-circle" cx={cxy} cy={cxy} r={radius} stroke={circles[0].grad} stroke-width={strokeWidth} fill="none" stroke-dasharray={circles[0].dashArray.join(' ')} stroke-dashoffset={circles[0].dashOffset}></circle>
        <circle class="busy-circle" cx={cxy} cy={cxy} r={radius} stroke={circles[1].grad} stroke-width={strokeWidth} fill="none" stroke-dasharray={circles[1].dashArray.join(' ')} stroke-dashoffset={circles[1].dashOffset}></circle>
        <circle class="need-circle" cx={cxy} cy={cxy} r={radius} stroke={overflow ? circles[2].grad[0] : circles[2].grad[1]} stroke-width={strokeWidth} fill="none" stroke-dasharray={circles[2].dashArray.join(' ')} stroke-dashoffset={circles[2].dashOffset}></circle>
      </svg>
    );
  }
}
