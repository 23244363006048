import React, { Component } from 'react';
import Select from '../../common/Select';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import moment from 'moment';
import { Button } from '../../common/Button';
import ModalProtestList from '../../common/modals/modalProtestList';
import ModalDialog from '../../common/modals/modalDialog';
import { 
  refereeRefuse, 
  setProtocolSquadId,
  fetchProtestList,
} from '../../../libs/reducers/actions';

import './style.scss';

class RefereeingUserMenu extends Component {

  constructor(props) {

    super(props);

    this.state = {
      data: null,
      fetching: false,
      status: null,
      party: null,
      action: null,
      showProtestListModal: false,
      showConfirmationModal: false,
      isReviewed: false,
      protocolFetching: false
    };

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = { ...prevState }
    if (!prevState.fetching && nextProps.protestList.isFetching) {
      state = { ...state, fetching: true }
    }
    if (prevState.fetching && !nextProps.protestList.isFetching && !nextProps.protestList.error) {
      state = { ...state, fetching: false, data: nextProps.protestList.data, action: false };
    }
    if (!prevState.protocolFetching && nextProps.protocol.isFetching) {
      state = { ...state, protocolFetching: true }
    }
    if (prevState.protocolFetching && !nextProps.protocol.isFetching && !nextProps.protocol.error) {
      nextProps.history.push('/protocol');
    }
    return state;
  }

  componentDidMount () {
    this.props.fetchProtestList();
  }

  componentDidUpdate(prevProps, prevState) {
    const nextProps = this.props;
    const nextState = this.state;
    if (!prevState.fetching && nextProps.protestList.isFetching) {
      this.setState({ fetching: true });
    }
    if (prevState.fetching && !nextProps.protestList.isFetching && !nextProps.protestList.error) {
      this.setState({ fetching: false, data: nextProps.protestList.data, action: false });
    }
  }

  gotoProtocol = function (squadid) {
    this.props.setProtocolSquadId(squadid);
    this.props.history.push('/protocol');
  }

  renderBlock () {
    const { data, status, action } = this.state;
    const { intl } = this.props;
    return data.own.map(i => {
      if ((i.status == 2 || i.status == 3 || i.status == 4) && (status == null || status == i.status)) {
        let status = null;
        let buttons = null;
        if (i.status == 2) {
          status = <strong>{intl.formatMessage({ id: 'OnReviewStatus', defaultMessage: 'On review' })}</strong>;
          buttons = 
          <div>
            <div className="refereeing-usermenu-block-row">
              <Button kind="aquamarine" size="md" loading={action} onClick={() => {
                this.gotoProtocol(i.squadid);
              }}>
                {intl.formatMessage({ id: 'GoToReview', defaultMessage: 'Go to review' })}
              </Button>
            </div>
            <div className="refereeing-usermenu-block-row">
              <Button kind="aquamarine" size="md" bordered="true" loading={action} onClick={() => {
                this.setState({ showConfirmationModal: true, party: { id: i.squadid, date: i.startdate } })
              }}>
                {intl.formatMessage({ id: 'RefuseToConsider', defaultMessage: 'Refuse to consider' })}
              </Button>
            </div>
          </div>;
        }
        if (i.status == 3) {
          status = <strong style={{ color: '#20A39E' }}>{intl.formatMessage({ id: 'ReviewedStatus', defaultMessage: 'Reviewed' })}</strong>;
          buttons = 
          <div>
            <div className="refereeing-usermenu-block-row">
              <Button kind="aquamarine" size="md" loading={action} onClick={() => {
                this.gotoProtocol(i.squadid);
              }}>
                {intl.formatMessage({ id: 'GoToReview', defaultMessage: 'Go to review' })}
              </Button>
            </div>
            {/* <div className="refereeing-usermenu-block-row">
              <Button kind="aquamarine" size="md" loading={protocolFetching} onClick={() => {
                this.gotoProtocol(i.squadid);
              }}>
                {intl.formatMessage({ id: 'MakeResolve', defaultMessage: 'Made decision' })}
              </Button>
            </div> */}
            <div className="refereeing-usermenu-block-row">
              <Button kind="aquamarine" size="md" bordered="true" loading={action} onClick={() => {
                this.setState({ showConfirmationModal: true, party: { id: i.squadid, date: i.startdate } })
              }}>
                {intl.formatMessage({ id: 'RefuseToConsider', defaultMessage: 'Refuse to consider' })}
              </Button>
            </div>
          </div>;
        } 
        if (i.status == 4) {
          status = <strong style={{ color: '#20A39E' }}>{intl.formatMessage({ id: 'DecidedStatus', defaultMessage: 'Decided' })}</strong>;
        } 
        return(
          <div className="refereeing-usermenu-block">
            {/* row 1 */}
            <div className="refereeing-usermenu-block-row">
              <div className="refereeing-usermenu-block-column">
                <div className="refereeing-usermenu-block-column-label">
                  {intl.formatMessage({ id: 'PartyNumber', defaultMessage: '# of party' })}
                </div>
                <div className="refereeing-usermenu-block-column-value">
                  <strong>{i.squadid}</strong>
                </div>
              </div>
              <div className="refereeing-usermenu-block-column">
                <div className="refereeing-usermenu-block-column-label">
                  {intl.formatMessage({ id: 'StatusAsStateAlias', defaultMessage: 'Status' })}
                </div>
                <div className="refereeing-usermenu-block-column-value">
                  {status}
                </div>
              </div>
            </div>
            {/* row 2 */}
            <div className="refereeing-usermenu-block-row">
              <div className="refereeing-usermenu-block-column">
                <div className="refereeing-usermenu-block-column-label">
                  {intl.formatMessage({ id: 'Tournament', defaultMessage: 'Tournament' })}
                </div>
                <div className="refereeing-usermenu-block-column-value">
                  {i.tournamentname}
                </div>
              </div>
              <div className="refereeing-usermenu-block-column">
                <div className="refereeing-usermenu-block-column-label">
                  {intl.formatMessage({ id: 'PartyStartDate', defaultMessage: 'Party start date' })}
                </div>
                <div className="refereeing-usermenu-block-column-value">
                  {moment(i.startdate).format('DD.MM.YYYY HH:mm')}
                </div>
              </div>
            </div>
            {/* row 3 */}
            <div className="refereeing-usermenu-block-row">
              <div className="refereeing-usermenu-block-column">
                <div className="refereeing-usermenu-block-column-label">
                  {intl.formatMessage({ id: 'CountOfProtests', defaultMessage: 'Count of protests' })}
                </div>
                <div className="refereeing-usermenu-block-column-value">
                  {i.protestCount}
                </div>
              </div>
              <div className="refereeing-usermenu-block-column">
                <div className="refereeing-usermenu-block-column-label">
                  {intl.formatMessage({ id: 'ReviewPeriod', defaultMessage: 'Review period' })}
                </div>
                <div className="refereeing-usermenu-block-column-value">
                  {intl.formatMessage({ id: 'DayPlural', defaultMessage: '{count} {count, plural, one {day} other {days}}' }, { count: i.daysleft } )}
                </div>
              </div>
            </div>
            { buttons }
          </div>
        );
      }
    });
  }

  onCancel = function () {
    this.setState({ showProtestListModal: false });
  }

  onResolve = function () {
    this.setState({ showProtestListModal: false });
  }

  onRefuse = function () {
    this.props.refereeRefuse({ squadid: this.state.party.id });
    this.setState({ showProtestListModal: false });
  }

  onAccept = function () {
    this.props.refereeRefuse({ squadid: this.state.party.id });
    this.setState({ action: true, showConfirmationModal: false });
  }

  onDecline = function () {
    this.setState({ showConfirmationModal: false });
  }

  render () {
    const { intl } = this.props;
    const { data, status, showProtestListModal, showConfirmationModal, party } = this.state;
    const statusOptions = [
      { value: null, label: intl.formatMessage({ id: 'AnyAsAllVariationsAlias', defaultMessage: 'Any' }) },
      { value: 2, label: intl.formatMessage({ id: 'OnReviewStatus', defaultMessage: 'On review' }) },
      { value: 3, label: intl.formatMessage({ id: 'ReviewedStatus', defaultMessage: 'Reviewed' }) },
      { value: 4, label: intl.formatMessage({ id: 'DecidedStatus', defaultMessage: 'Decided' }) },
    ];
    return (
      <div className="refereeing-usermenu">
        <div className="refereeing-usermenu-header">
          {intl.formatMessage({ id: 'MyRefereeing', defaultMessage: 'My refereeing' })}
        </div>
        <div className="refereeing-usermenu-filter">
          <div className="refereeing-usermenu-filter-label">
            {intl.formatMessage({ id: 'StatusAsStateAlias', defaultMessage: 'Status' })}
          </div>
          <div className="refereeing-usermenu-filter-select">
            <Select
              key={intl.formatMessage({ id: 'AnyAsAllVariationsAlias', defaultMessage: 'Any' })}
              value={statusOptions.find(o => o.value == status)}
              options={statusOptions}
              onChange={(e) => {
                this.setState({ status: e.value });
              }}
            />
          </div>
        </div>
        <div className="refereeing-usermenu-content">
            { data && this.renderBlock() }
        </div>
        {
          showProtestListModal && 
          <ModalProtestList 
            visible
            party={party}
            onCancel={this.onCancel.bind(this)}
            onResolve={this.onResolve.bind(this)}
            onRefuse={this.onRefuse.bind(this)}
          />
        }
        <ModalDialog
          visible={showConfirmationModal}
          onSubmit={this.onAccept.bind(this)}
          onClose={this.onDecline.bind(this)}
          declineButtonText={intl.formatMessage({ id: 'ModalDefaultCancel', defaultMessage: 'Cancel' })}
          acceptButtonText={intl.formatMessage({ id: 'Refuse', defaultMessage: 'Refuse' })}
          title={intl.formatMessage({ id: 'Refuse', defaultMessage: 'Refuse' })}
          >
          {intl.formatMessage({ id: 'RefuseConfirmText', defaultMessage: 'Do you really want to refuse to consider?' })}
        </ModalDialog>
      </div>
    )
  }

}

export default injectIntl(withRouter(
  connect(
    state => ({
      user: state.users.user,
      lang: state.langs.lang,
      protestList: state.protestList,
      protocol: state.protocol
    }),
    {
      refereeRefuse,
      setProtocolSquadId,
      fetchProtestList,
    }
  )(RefereeingUserMenu)
));