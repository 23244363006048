const { LOGOUT, APP_INIT } = require('./actiontypes').default;

export const TOURNAMENTS_ACTIVE_TAB_INDEX_SET = 'TOURNAMENTS_ACTIVE_TAB_INDEX_SET';
export const CHANGE_WALKTHROUGH_STATE = 'CHANGE_WALKTHROUGH_STATE';
export const SET_SHOW_FOOTER = 'SET_SHOW_FOOTER';
export const SET_FOOTER_HEIGHT = 'SET_FOOTER_HEIGHT';

export const tournamentsActiveTabIndexSet = payload => ({ type: TOURNAMENTS_ACTIVE_TAB_INDEX_SET, payload });
export const setShowFooter = payload => ({ type: SET_SHOW_FOOTER, payload });
export const changeWalkthroughState = payload => ({ type: CHANGE_WALKTHROUGH_STATE, payload });
export const setFooterHeight = (height) => ({ type: SET_FOOTER_HEIGHT, payload: height })

const initialState = {
  tournamentsActiveTabIndex: 1, // after add all tabs, change 0
  appinit: false,
  showFooter: true, // Скрывать/показвать футер
  walkthroughIsActive: false,
  footerHeight: 68,
};

const squadsReduсer = (state = initialState, action) => {
  switch (action.type) {
    case APP_INIT:
      return { ...state, appinit: true };
    case LOGOUT:
      return { ...initialState, appinit: true };
    case TOURNAMENTS_ACTIVE_TAB_INDEX_SET:
      return { ...state, tournamentsActiveTabIndex: action.payload || 0 };
    case SET_SHOW_FOOTER:
      return { ...state, showFooter: action.payload };
    case SET_FOOTER_HEIGHT:
      return { ...state, footerHeight: action.payload };
    case CHANGE_WALKTHROUGH_STATE:
      return { ...state, walkthroughIsActive: action.payload}
      
    default:
      return state;
  }
};

export default squadsReduсer;
