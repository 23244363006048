const {
  USER_SEARCH_FETCH,
  USER_SEARCH_SET,
} = require('./actiontypes').default;

const initialState = {
  data: [],
  error: '',
  fetching: false,
}

const UserSearchReduсer = (state = initialState, action) => {
  switch (action.type) {
    case USER_SEARCH_FETCH: 
      return { ...initialState, fetching: true }
    case USER_SEARCH_SET: 
        return { ...state, data: action.data || [], error: action.error || '', fetching: false }
    default:
      return state;
  }
};

export default UserSearchReduсer;
