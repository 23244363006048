import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EventEmitter from 'wolfy87-eventemitter';
import { Input, InputPairList } from './InputPair';
import bugsnagClient from '../../bugsnagClient';

const fetch = window.fetch;
const HEAD_CSS = { marginBottom: 10, fontWeight: 600 };
const CONTAINER_CSS = { margin: 20 };

class WebApi extends Component {
    static defaultProps = {
      method: 'GET',
      url: 'https://bokus1.ru.fvds.ru:8002/battle',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
      },
      data: {},
    }

    static propTypes = {
      method: PropTypes.string,
      url: PropTypes.string,
      headers: PropTypes.objectOf(
        PropTypes.string,
      ),
      data: PropTypes.objectOf(
        PropTypes.string,
      ),
    }

    constructor(props) {
      super(props);
      const {
        method, url, headers, data,
      } = props;
      this.state = {
        method,
        url,
        headers: { ...headers },
        data: { ...data },
      };
      this.submitFn = {
        data: value => this.onSubmit('data', value),
        headers: value => this.onSubmit('headers', value),
      };
      this.resp = React.createRef();
      this.respHeads = React.createRef();
      this.ee = new EventEmitter();
    }

    // componentDidMount() {
    // //   console.log('this.elm: ', this.elm);
    // }

    componentWillUnmount() {
      this.ee.removeEvent('submit');
    }

    render() {
      return (
            <div>
                {this.renderInput('method')}
                {this.renderInput('url')}
                {this.renderMap('headers')}
                {this.renderMap('data')}
                <button onClick={this.submit}>submit</button>
                {this.renderResponse()}
            </div>);
    }

    renderResponse = () => {
      const {
        error, response, responseHeaders, _row,
      } = this.state;
      const _error = (response && response.error) || error;
      return (<div style={CONTAINER_CSS}>
            <div style={HEAD_CSS}><code>RESPONSE</code></div>
            <InputPairList
              data={response}
              extraLine={false}
              ref={resp => this.resp = resp}
            />
            <div style={HEAD_CSS}><code>RESPONSE HEADERS</code></div>
            <InputPairList
              data={responseHeaders}
              extraLine={false}
              ref={respHeads => this.respHeads = respHeads}
            />
            {_row && (
            <div style={{ ...HEAD_CSS, marginTop: 10 }}><code>Response: {_row}</code></div>
            )}
            {_error && (
            <div style={{ ...HEAD_CSS, color: 'red', marginTop: 10 }}><code>ERROR: {_error}</code></div>
            )}
        </div>);
    }

    renderInput = prop => (<div style={CONTAINER_CSS}>
            <div style={HEAD_CSS}><code>{prop && prop.toUpperCase()}</code></div>
            <Input
              name={prop}
              value={this.props[prop]}
              placeholder={`Enter ${prop}`}
              onSubmit={this.onSubmit}
              eventType="submit"
              eventEmitter={this.ee}
              style={{ minWidth: 500 }}
            />
        </div>)

    renderMap = prop => (<div style={CONTAINER_CSS}>
            <div style={HEAD_CSS}><code>{prop && prop.toUpperCase()}</code></div>
            <InputPairList
              data={this.props[prop]}
              onSubmit={this.submitFn[prop]}
              eventType="submit"
              eventEmitter={this.ee}
              ref={elm => this.elm = elm}
            />
            <code>{JSON.stringify(this.state[prop], null, 2)}</code>
        </div>)

    onSubmit = (prop, data) => this.setState({ [prop]: data })

    submit = () => {
      this.ee.trigger('submit');
      setTimeout(this.makeRequest, 200);
    }

    makeRequest = () => {
      const {
        method, url, headers, data,
      } = this.state;
      const body = Object.keys(data).length ? JSON.stringify(data) : null;
      //   console.log('fetch: ', {
      //     method, url, headers, body,
      //   });
      _fetch(url, {
        method,
        body,
        headers,
      })
        .then((response) => {
          this.setState({
            status: response.status,
            statusText: response.statusText,
            response: response.json,
            _row: response._row,
            responseHeaders: { ...response.headers },
            error: '',
          }, () => {
            // console.log('===> fetch.response: ', this.state.response);
            this.resp.update();
            this.respHeads.update();
          });
        })
        .catch((error) => {
          console.error('error while fetch: ', error.message);
          bugsnagClient.notify(error, { context: 'WebApi' });
          this.setState({
            error: error.message,
            _row: null,
          });
        });
    }
}

function _fetch(url, options) {
  return new Promise((resolve, reject) => {
    const xhr = new window.XMLHttpRequest();
    xhr.open(options.method, url, true);
    let key;
    for (key in options.headers) {
      xhr.setRequestHeader(key, options.headers[key]);
    }
    xhr.onload = () => {
      const ct = xhr.getResponseHeader('Content-Type') || '';
      let json = xhr.response;
      if (ct && ct.toLowerCase().includes('application/json')) {
        try {
          json = JSON.parse(xhr.response);
        } catch (err) {
          bugsnagClient.notify(err, { context: 'WebApi' });
          console.error(err);
        }
      }
      resolve({
        _row: xhr.response, json, headers: _parseHeaders(xhr.getAllResponseHeaders()), status: xhr.status, statusText: xhr.statusText,
      });
    };
    xhr.error = () => {
      const error = 'network error';
      reject(new Error(error));
    };
    xhr.send(options.body);
  });
}

function _parseHeaders(_headers) {
  let s;
  const headers = {};
  _headers.split(/\s?\r?\n/g).forEach((element) => {
    s = element.split(/\s?\:\s?/);
    if (s[0] && s[1]) {
      headers[s[0]] = s[1];
    }
  });
  return headers;
}

export default WebApi;

