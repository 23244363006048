/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { array, func, object } from 'prop-types';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from '../../../common/Button';
import Modal from '../../../common/Modal';
import RenderUserCard from '../../../common/UserCardTable';
import Select from '../../../common/Select';
import Checkbox from '../../../common/Checkbox';
import OBDatePicker from '../../../common/DatePicker';
import { getTariffList } from '../../../../libs/reducers/actions';
import { subscribersChangeTariffFetch } from '../../../../libs/reducers/adminReducer';
import './styles.scss';

const propTypes = {
  item: object,
  getTariffList: func,
  tariffs: array,
  subscribersChangeTariffFetch: func,
};

const SubscribersTariffMarketData = ({
  subscribersChangeTariffFetch,
  getTariffList,
  tariffs,
  item,
}) => {
  const [tariffOptions, setTariffOptions] = useState([]);
  const [selectedTariff, setSelectedTariff] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isDeleteTariff, setIsDeleteTariff] = useState(false);
  const [changeTariffParams, setChangeTariffParams] = useState({
    ab_pend: null,
    remove_tariff: false,
    tariffid: null,
    tariffrenewal: 0,
    userid: item.user.id,
  });


  useEffect(() => {
    getTariffList();
    setSelectedTariff({
      label: item.tariff.name,
      value: item.tariff.id,
    });
    setSelectedDate(moment(item.expires));
  }, []);

  useEffect(() => {
    setTariffOptions(tariffs.map(tariff => ({
      value: tariff.id,
      label: tariff.name,
    })));
  }, [tariffs]);

  const [visibleModal, setVisibleModal] = useState(false);

  function onCancel() {
    setVisibleModal(false);
  }
  function onOk() {
    const tariffParams = { ...changeTariffParams };
    tariffParams.ab_pend = selectedDate.toISOString();
    tariffParams.remove_tariff = isDeleteTariff;
    tariffParams.tariffid = selectedTariff.value;
    subscribersChangeTariffFetch(tariffParams);
    onCancel();
  }
  function openModal() {
    setVisibleModal(true);
  }

  function onSelectTariff(params) {
    setSelectedTariff(params);
  }


  function onSelectDateTariff(params) {
    setSelectedDate(params);
  }

  function onSelectDeleteTariff() {
    setIsDeleteTariff(!isDeleteTariff);
  }

  return (
    <div className='subscribers-tariff-market-data'>
      <div className='subscribers-tariff-market-data__name'>{typeof item.kind === 'string' ? item.kind : 'Admin'}</div>
      <Button icon="edit" kind="aquamarine" bordered="true" size={'xs'} onClick={openModal} />
      <Modal
        title={'Change Tariff'}
        okText='Save'
        visible={visibleModal}
        onCancel={onCancel}
        onOk={onOk}
        className={'modal'}
        mask={false}
        destroyOnClose
        centered
      >
        <div>
          <div className="modal-edit">
              <div className="ap-row">
                <div className="ap-col">
                  <RenderUserCard
                    user={item.user}
                  />
                </div>
              </div>
              <div className="ap-row">
                <div className="ap-col">
                  <Select
                    value={selectedTariff}
                    placeholder='Tariff'
                    onChange={onSelectTariff}
                    options={tariffOptions}
                  />
                </div>
                <div className="ap-col" />
              </div>
              <div className="ap-row">
                <div className="ap-col">
                  <OBDatePicker
                    dateFormat="D MMM YYYY"
                    className="date-group__elements__picker"
                    placeholder='End date'
                    value={selectedDate}
                    onChange={onSelectDateTariff}
                    disabledDate={current => current && current < moment()}
                  />
                </div>
                <div className="ap-col">
                  <Checkbox
                    checked={isDeleteTariff}
                    onChange={onSelectDeleteTariff}
                  >Delete</Checkbox>
                </div>
              </div>
            </div>
        </div>
      </Modal>
    </div>
  );
};


export default injectIntl(
  withRouter(
    connect(
      state => ({
        tariffs: state.billing.tariffs,
      }),
      {
        getTariffList,
        subscribersChangeTariffFetch,
      },
    )(SubscribersTariffMarketData),
  ),
);

SubscribersTariffMarketData.propTypes = propTypes;
