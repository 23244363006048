import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import OBModal from '../../Modal';
import Radio from '../../Radio';
import Select from '../../Select';
import Checkbox from '../../Checkbox';
import Slider from '../../Slider';
import OBDatePicker from '../../DatePicker';
import OBTimePicker from '../../TimePicker';
import TextArea from '../../TextArea';
import Opponent from '../../Opponent';
// import Avatar from '../../Avatar';
import defaultAvatar from '../../../../images/defaultAvatar.png';
import config from '../../../../config';
import Flag from '../../Flag';
import SelectBlock from '../../SelectBlock';
import {
  UserByIdFetch,
  getTournamentTypeList,
} from '../../../../libs/reducers/actions';
import RatingStars from '../../RatingStars';
import Tooltip from '../../../common/Tooltip';

import './style.scss';

const RadioGroup = Radio.Group;

const TYPE_BATTLE_CODESTRING = {
  INDIVIDUAL: {
    textKey: 'QuantityOfWins',
    defaultMessage: 'Quantity of wins',
    titleKeySlider: 'table_wins',
    defaultTitleSlider: 'Number of wins',
    toolTipCode: 'IndividualBattleInfo',
    toolTipDefault: 'The winner is determined by the number of games won in the match',
  },
  PINFALL_BATTLE: {
    textKey: 'PointsTotal',
    defaultMessage: 'Points total',
    titleKeySlider: 'NumberOfGames',
    defaultTitleSlider: 'Number of games',
    toolTipCode: 'PinfallBattleInfo',
    toolTipDefault: 'The winner is determined by the number of points scored in the match',
  },
};


function RenderPlayerItem({ user, index }) {
  const isOnline = user.active;
  const avatar = user.photo ? `${config.FETCH.img}/${user.photo}` : defaultAvatar;
  const flag = user.country ? user.country.toLowerCase() : '';

  return (
    <div key={index} className="select-user__main-info">
      <div className="select-user__avatar">
        <div className="select-user__mask">
          {isOnline
            ? <svg viewbox="0 0 38 38"><circle cx="50%" cy="50%" r="48%" stroke="#35C844" fill="transparent" strokeWidth="3"/></svg>
            : null}
          <img src={avatar} alt="avatar" />
          <div className='avgScore'>{(+user.avgscore || 0).toFixed()}</div>
        </div>
        {flag
          ? (
            <div className="select-user__flag">
              <Flag name={flag} />
            </div>
          )
          : null}
      </div>
      <div className="select-user__name">
        <div className="select-user__fullname">
          {(`${user.firstname} ${user.lastname}`).trim()}
          <RatingStars rating={+user.ratingsavg || 0} height={12} width={12} />
        </div>
        <div className="select-user__login">{user.login}</div>
      </div>
    </div>
  );
}

class CreateBattle extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    intl: intlShape,
    opponent: PropTypes.object,
    friends: PropTypes.shape(),
  };

  constructor(props) {
    super(props);

    this.state = {
      betType: 1,
      winCount: 1,
      dateType: 1,
      date: null,
      time: null,
      comment: '',
      invalidBet: false,
      invalidDate: false,
      invalidConsest: false,
      wallet: null,
      bets: {},
      watchmode: null,
      consestToBlockMoney: false,
      friendForBattle: null,
      friends: props.friends ? props.friends.data : [],
      invalidRival: false,
      rivalType: 1,
      battleTypeBrief: '',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = null;
    const {
      opponent, userById, visible, UserByIdFetch,
    } = nextProps;
    if (visible && opponent && !userById.fetching && (!userById.data || userById.data.id !== opponent)) {
      UserByIdFetch({ userid: opponent, noModal: true });
    }
    if (!_.isEmpty(nextProps.friends.data)) {
      const friendsArr = nextProps.friends.data.filter(friend => friend.confirmed);
      state = { ...state, friends: friendsArr };
    } else {
      state = { ...state, friends: [] };
    }
    // return null;
    return state;
  }

  componentDidUpdate(prevProps) {
    const { visible, tournamentTypeList, getTournamentTypeList } = this.props;
    const { data: dataList, fetching } = tournamentTypeList || {  };
    const { battleTypeBrief } = this.state;

    if (!prevProps.visible && visible) {
      if ((!dataList || (dataList && !dataList.length)) && !fetching) {
        getTournamentTypeList();
      }
    }

    if (dataList && dataList.length && !battleTypeBrief) {
      this.setState({ battleTypeBrief: dataList[0].brief });
    }
  }


  showModal = () => this.setState({ visible: true });

  handleOk = () => {
    const {
      wallet,
      bets,
      betType,
      dateType,
      date,
      time,
      rivalType,
      friendForBattle,
      consestToBlockMoney,
      winCount,
      comment,
      watchmode,
      battleTypeBrief,
    } = this.state;
    const { intl, onSubmit, wallets } = this.props;
    const bet = wallet ? bets[wallets[wallet - 1].currencyisocode] : 0;

    if (
      (!(+bet > 0) && betType === 2)
      || ((betType === 2) && (!consestToBlockMoney))
      || (dateType === 2 && (!date || !time))
      || (rivalType === 2 && !Number.isInteger(friendForBattle))
    ) {
      if (!(+bet > 0) && betType === 2) {
        this.setState({ invalidBet: intl.formatMessage({ id: 'EntryFeeError', defaultMessage: 'You must choose entry fee' }) });
      } else {
        this.setState({ invalidBet: false });
      }
      if ((+bet > 0) && !consestToBlockMoney) {
        this.setState({
          invalidConsest: intl.formatMessage({
            id: 'ConsestToBlockMoneyError',
            defaultMessage: 'You must conset to block money',
          }),
        });
      } else {
        this.setState({ invalidConsest: false });
      }
      if (dateType === 2 && (!date || !time)) {
        this.setState({
          invalidDate: intl.formatMessage({
            id: 'InvalidDateBothValues',
            defaultMessage: 'Must set both values',
          }),
        });
      } else {
        this.setState({ invalidDate: false });
      }
      // Проверка если выбран пункт "Из списка друзей" то выбран и друг
      if (rivalType === 2 && !Number.isInteger(friendForBattle)) {
        this.setState({ invalidRival: 'Must set both values' });
      } else {
        this.setState({ invalidRival: false });
      }

      return;
    }
    let count;
    switch (winCount) {
      case 0:
        count = 1;
        break;
      case 25:
        count = 2;
        break;
      case 50:
        count = 3;
        break;
      case 75:
        count = 4;
        break;
      case 100:
        count = 5;
        break;
      default:
        count = 1;
        break;
    }

    if (!Number.isInteger(friendForBattle)) {
      onSubmit({
        betType,
        bet,
        betcurrency: wallet ? wallets[wallet - 1].currencyid : null,
        winCount: count,
        dateType,
        date,
        time,
        comment,
        watchmode,
        battleTypeBrief,
      });
    } else {
      onSubmit({
        betType,
        bet,
        betcurrency: wallet ? wallets[wallet - 1].currencyid : null,
        winCount: count,
        dateType,
        date,
        time,
        comment,
        opponent: friendForBattle,
        battleTypeBrief,
      });
    }
    this.setState({
      betType: 1,
      bet: '',
      wallet: null,
      bets: {},
      winCount: 0,
      dateType: 1,
      date: null,
      time: null,
      comment: '',
      invalidConsest: false,
      invalidBet: false,
      invalidDate: false,
      watchmode: null,
      consestToBlockMoney: false,
      friendForBattle: null,
      invalidRival: false,
      rivalType: 1,
      rivalIdxSelected: 0,
      battleTypeBrief: this.props.tournamentTypeList.data[0].brief,
    });
  };

  handleCancel = () => {
    this.setState({
      invalidBet: false,
      invalidDate: false,
      invalidRival: false,
      invalidConsest: false,
      consestToBlockMoney: false,
    });
    this.props.onClose();
  };

  onChangeBetType = e => this.setState({
    betType: e.target.value,
    wallet: e.target.value !== 1 ? this.state.wallet : null,
  });

  onChangeDateType = e => this.setState({ dateType: e.target.value });

  onChangeBet = (e, currencyisocode) => {
    const betsarr = this.state.bets;
    const wallet = this.props.wallets.find((wallet) => { return wallet.currencyisocode === currencyisocode; });
    betsarr[currencyisocode] = e.value > wallet.amount ? wallet.amount : e.value;
    this.setState({ bets: betsarr });
  }

  onChangeWinCount = value => this.setState({ winCount: value });

  onChangeDate = date => this.setState({ date });

  onChangeTime = time => this.setState({ time });

  onChangeComment = e => this.setState({ comment: e.value });

  onLinkToSearch = () => {
    this.props.history.push('/account/friends/search');
  }

  onChangeRivalType = (e) => {
    const { value } = e.target;

    if (value === 1) {
      this.onSelectRival(null);
    } else if (value === 2) {
      this.onSelectRival(0);
    }
    this.setState({ rivalType: e.target.value });
  }

  onSelectRival = (idx) => {
    const { friends } = this.state;
    const friendForBattle = (Number.isInteger(idx))
      ? (friends[idx] && friends[idx].user.id)
      : null;

    this.setState({ friendForBattle, rivalIdxSelected: idx });
  }

  render() {
    const bet = this.state.wallet ? this.state.bets[this.props.wallets[this.state.wallet - 1].currencyisocode] : 0;
    const {
      intl,
      wallets,
      steps,
      opponent,
      visible,
      tournamentTypeList
    } = this.props;
    const currencyisocode = this.state.wallet ? this.props.wallets[this.state.wallet - 1].currencyisocode : null;
    const { data: typeList } = tournamentTypeList || {};
    let betOptions = [];
    if (this.state.wallet){
      if (steps.hasOwnProperty(currencyisocode)) {
        betOptions = steps[currencyisocode];
      } else {
        betOptions = steps['DEF'];
      }
    }
    const BetGroup = null;
    // const BetGroup = (
    //   <div key="betGroup" className="bet-group">
    //     <span className="bet-group__label">
    //       {intl.formatMessage({ id: 'choose_your_bet', defaultMessage: 'Choose your bet' })}
    //     </span>
    //     <div className="bet-group__elements">
    //       <RadioGroup name="bet" defaultValue={1} onChange={this.onChangeBetType} value={this.state.betType}>
    //         <Radio value={1}>{intl.formatMessage({ id: 'free_game', defaultMessage: 'Fun game' })}</Radio>
    //         <Radio style={{ marginLeft: '20px' }} value={2} disabled={!wallets || !wallets.length} >{intl.formatMessage({ id: 'choose_fee', defaultMessage: 'Choose fee' })}</Radio>
    //       </RadioGroup>
    //       { this.state.betType === 2 && !!wallets.length
    //         ? <>
    //           <RadioGroup name="wallet" defaultValue={1} onChange={(e) => { this.setState({ wallet: e.target.value }); } } value={this.state.wallet}>
    //             <table className="wallets">
    //               <thead>
    //                 <tr className="wallet-container">
    //                   <th className="wallet-name">{intl.formatMessage({ id: 'MoneyAsItemAlias', defaultMessage: 'Money' })}</th>
    //                   <th className="wallet-value">{intl.formatMessage({ id: 'BalanceAsMoneyAmountAlias', defaultMessage: 'Balance' })}</th>
    //                   <th className="wallet-fee">{intl.formatMessage({ id: 'FeeAmount', defaultMessage: 'Fee Amount' })}</th>
    //                 </tr>
    //               </thead>
    //               <tbody>
    //               {
    //                 wallets.filter(w => this.props.user.is_adult || w.currencyisocode !== 'EUR').map((wallet, index) => (
    //                   <tr key={index} className="wallet-container">
    //                     <td className="wallet-name">
    //                       <Radio value={index + 1}>{_.upperFirst(_.toLower(wallet.currencyname))}</Radio>
    //                     </td>
    //                     <td className="wallet-value">
    //                       {wallet.amount} {wallet.currencysymbol}
    //                     </td>
    //                     <td className="wallet-fee">
    //                       <Select
    //                         isDisabled={wallet.currencyisocode !== currencyisocode}
    //                         noOptionsMessage={() => intl.formatMessage({ id: 'NoFundsPlaceholder', defaultMessage: 'No funds' })}
    //                         value={
    //                           {
    //                             value: this.state.bets[wallet.currencyisocode],
    //                             label: this.state.bets[wallet.currencyisocode],
    //                           }
    //                         }
    //                         options={
    //                           betOptions
    //                             .filter(step => step <= wallet.amount).map(step => ({ value: step, label: step }))
    //                         }
    //                         onChange={(e) => { this.onChangeBet(e, wallet.currencyisocode); }}
    //                         className='picker'/>
    //                     </td>
    //                   </tr>
    //                 ))
    //               }
    //               </tbody>
    //             </table>
    //           </RadioGroup>
    //           {(+bet > 0) && (
    //             <div key="consestGroup" className="consest-Group">
    //               <Checkbox
    //                 small
    //                 color
    //                 style={{ alignItems: 'flex-start' }}
    //                 checked={this.state.consestToBlockMoney}
    //                 onChange={(value) => {
    //                   this.setState({ consestToBlockMoney: value });
    //                 }}>
    //                 {intl.formatMessage({
    //                   id: 'ConsestToBlockMessage 1',
    //                   defaultMessage: 'The amount of',
    //                 })} {bet} {this.props.wallets[this.state.wallet - 1].currencysymbol}
    //                 {intl.formatMessage({
    //                   id: 'ConsestToBlockMessage 2',
    //                   defaultMessage: ' will be debited from your account for participating in the game',
    //                 })}
    //               </Checkbox>
    //             </div>
    //           )}
    //           { this.state.invalidConsest ? <div className="input__error" style={{ marginBottom: '15px' }}>{this.state.invalidConsest}</div> : null }
    //           { this.state.invalidBet ? <div className="input__error" style={{ marginBottom: '15px' }}>{this.state.invalidBet}</div> : null }
    //         </>
    //         : null}
    //     </div>

    //   </div>
    // );

    const { battleTypeBrief } = this.state;
    const FormatBattleGroup = (
      <>
        <div className="format-battle-group">
          <span className="format-battle-group__label">
            {intl.formatMessage({ id: 'SelectTheBattleFormat', defaultMessage: 'Select the Battle format' })}
          </span>
          {typeList && typeList.length && (
            <RadioGroup value={this.state.battleTypeBrief} name="typeBattle" defaultValue={typeList[0].brief} onChange={e => this.setState({ battleTypeBrief: e.target.value })}>
              {typeList.map(type => (type.isbattle && (
                <div className="format-battle-group__item">
                  <Radio key={type.id} value={type.brief}>
                    {intl.formatMessage({
                      id: TYPE_BATTLE_CODESTRING[type.brief].textKey,
                      defaultMessage: TYPE_BATTLE_CODESTRING[type.brief].defaultMessage,
                    })}
                  </Radio>
                  <Tooltip
                    title={intl.formatMessage({
                      id: TYPE_BATTLE_CODESTRING[type.brief].toolTipCode,
                      defaultMessage: TYPE_BATTLE_CODESTRING[type.brief].toolTipDefault,
                    })}
                    trigger="click"
                    placement="bottom"
                    overlayClassName="format-battle__tooltip"
                  >
                    <div className="format-battle__tooltip__btn">Info</div>
                  </Tooltip>
                </div>
              )) || <></>)}
            </RadioGroup>
          )}
        </div>
        <div key="winCountGroup" className="win-count-group">
          <span className="win-count-group__label">
            {battleTypeBrief && intl.formatMessage({
              id: TYPE_BATTLE_CODESTRING[battleTypeBrief].titleKeySlider,
              defaultMessage: TYPE_BATTLE_CODESTRING[battleTypeBrief].defaultTitleSlider,
            })}
          </span>
          <Slider
            marks={{
              0: '1',
              25: '2',
              50: '3',
              75: '4',
              100: '5',
            }}
            value={this.state.winCount}
            tipFormatter={null}
            step={null}
            onChange={this.onChangeWinCount}
          />
        </div>
      </>
    );

    const DateGroup = (
      <div key="dateGroup" className="date-group">
        <span className="date-group__label">
          {intl.formatMessage({ id: 'select_date', defaultMessage: 'Select date' })}
        </span>
        <div className="date-group__elements">
          <RadioGroup name="date" defaultValue={1} onChange={this.onChangeDateType} value={this.state.dateType}>
            <Radio value={1}>{intl.formatMessage({ id: 'now', defaultMessage: 'Now' })}</Radio>
            <Radio value={2}>{intl.formatMessage({ id: 'selecting_date', defaultMessage: 'Select date' })}</Radio>
          </RadioGroup>
          <div className="date-group__pickers">
            <OBDatePicker
              className="date-group__elements__picker"
              placeholder={intl.formatMessage({ id: 'date', defaultMessage: 'Date' })}
              value={this.state.date}
              onChange={this.onChangeDate}
              disabled={this.state.dateType === 1}
              disabledDate={current => current && current < moment().subtract(1, 'days')}
            />
            <OBTimePicker
              className="date-group__elements__picker"
              placeholder={intl.formatMessage({ id: 'time', defaultMessage: 'Time' })}
              value={this.state.time}
              onChange={this.onChangeTime}
              disabled={this.state.dateType === 1}
            />
          </div>
        </div>
        {this.state.invalidDate ? <div style= {{marginTop: '10px'}} className="input__error">{this.state.invalidDate}</div> : null}
      </div>
    );

    const CommentGroup = (
      <div key="commentGroup" className="comment-group">
        <span className="comment-group__label">{intl.formatMessage({ id: 'comment', defaultMessage: 'Comment' })}</span>
        <div className="comment-group__elements">
          <TextArea
            value={this.state.comment}
            placeholder={intl.formatMessage({
              id: 'create_battle_comment_placeholder',
              defaultMessage: 'You can leave your comment for other players',
            })}
            onChange={this.onChangeComment}
          />
        </div>
      </div>
    );
    const user = { ...this.props.userById.data };
    const OpponentGroup = !!opponent ? (
      <div key="opponentGroup" className="opponent-group">
        <div className="opponent-group__header">
          {intl.formatMessage({ id: 'GameOpponent', defaultMessage: 'Opponent' })}
        </div>
        <Opponent
          userid={user.id}
          fullname={`${user.firstname} ${user.lastname}`}
          nickname={user.login}
          flag={user.country_live ? user.country_live.toLowerCase() : ''}
          photo={user.photo}
          rating={+user.ratingsavg}
          ratings={[]}
          avgscore={user.avgscore}
        />
      </div>
    ) : null;

    // Выбор соперника
    const FriendsListInBlock = this.state.friends.map((friend, idx) => (
        <RenderPlayerItem
          key={idx}
          user={friend.user}
          index={idx}
          intl={intl}
        />
    ));

    const ChooseFriends = () => {
      const { friends } = this.state;

      if (this.state.rivalType === 2) {
        return (friends && friends.length > 0)
          ? (<div
            style={{
              marginTop: '10px',
              marginBottom: '15px',
              width: '100%',
              flexGrow: 1,
            }}>
              <SelectBlock
                upByDropDown="true"
                countShow={4}
                valueIdx={this.state.rivalIdxSelected}
                onChange={this.onSelectRival}
              >{ FriendsListInBlock }</SelectBlock>
            </div>)
          : (<div
            className="btn-modal-link"
            onClick={this.onLinkToSearch}
            >
              { intl.formatMessage({ id: 'SearchUserRouterTitle', defaultMessage: 'Search player' }) }
          </div>);
      }
      return null;
    };

    // const RivalGroup = null;
    const RivalGroup = !opponent
      ? (
        <div key="rivalGroup" className="bet-group">
          <span className="bet-group__label">
            {intl.formatMessage({ id: 'GameOpponent', defaultMessage: 'Opponent' })}
          </span>
          <div className="bet-group__elements">
            <RadioGroup name="rival" defaultValue={1} onChange={this.onChangeRivalType} value={this.state.rivalType}>
              <div>
                <Radio value={1}>{intl.formatMessage({ id: 'AnyAsAllVariationsAlias', defaultMessage: 'Any' })}</Radio>
              </div>
              <div>
                <Radio value={2}>{intl.formatMessage({ id: 'FriendPlayers', defaultMessage: 'From friend list' })}</Radio>
              </div>
            </RadioGroup>
            <ChooseFriends />
            {this.state.invalidRival ? <div className="input__error" style={{ marginBottom: '15px' }}>{this.state.invalidRival}</div> : null}
          </div>
        </div>
      ) : null;

    const groups = [BetGroup, FormatBattleGroup, DateGroup, OpponentGroup, RivalGroup, CommentGroup];

    return (
      <OBModal
        title={intl.formatMessage({ id: 'creting_battle', defaultMessage: 'Create a battle' })}
        visible={visible}
        width={395}
        onOk={this.handleOk}
        className="create-battle-modal"
        wrapClassName="create-battle-modal"
        onCancel={this.handleCancel}>
        {groups}
      </OBModal>
    );
  }
}

export default injectIntl(withRouter(
  connect(
    state => ({
      wallets: state.users.user.currencies,
      steps: state.common.settings.data.bet_step || { DIG: [] },
      userById: state.users.userById,
      friends: state.friends.friends,
      tournamentTypeList: state.tournaments.tournamentTypeList,
      user: state.users.user,
    }),
    {
      UserByIdFetch,
      getTournamentTypeList,
    },
  )(CreateBattle),
));
