import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import _ from 'lodash';

import { Button } from '../common/Button';
import Checkbox from '../common/Checkbox';
import OBModal from '../common/Modal';
import {
  fetchVideoFiles,
  bindVideoFilesToUser,
} from '../../libs/reducers/actions';

import './modalSaveVideo.scss';

class ModalSaveVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allChecked: false,
      ownAllChecked: false,
      opponentAllChecked: false,

      players: [],
      cameras: [],

      filesLoaded: false,

      overflow: false,

      load: false,
      saving: false,
    };
  }

  componentDidMount() {
    this.setState({ load: true });
    this.props.fetchVideoFiles({ squadid: this.props.squadid });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      visible,
      squadid,
      videofiles,
    } = this.props;

    // Если передаем новый squadid обновляем список архивов, показываем пролоадер во время загрузки
    if (visible && squadid && prevProps.visible !== visible && !videofiles.files[squadid]) {
      this.setState({ load: true });
      this.props.fetchVideoFiles({ squadid });
    }
    if (this.state.load && !videofiles.isFetching) {     
      const files = videofiles.files[squadid];

      if (files) {
        const cameras = [];
        const usersFiles = {};

        files.forEach(file => {
          if (!usersFiles.hasOwnProperty(file.userid)) {
            usersFiles[file.userid] = {
              userid:    file.userid,
              playerid:  file.playerid,
              name:      `${file.firstname} ${file.lastname}`,
              login:     file.login,
            }
          }
          usersFiles[file.userid][file.type] = {
            id:        file.id,
            size:      file.size,
            savedId:   file.savedid,
            checked:   !!file.savedid,
          };
        });
        for (let i in usersFiles) {
          cameras.push(usersFiles[i]);
        }
        this.setState({ cameras });
      }

      this.setState({ load: false });
    }
    // Если предудущее значение true и текущее false - запись закончилась
    if (!this.props.videofiles.isSave && prevProps.videofiles.isSave) {
      this.setState({
        saving: false,
        cameras: []
      });
      this.props.onClose();
      this.props.onReboot();
    }
  }

  onSubmit = () => {
    const { cameras } = this.state;
    const files = {
      forInsert: [],
      forRestore: [],
      forDelete: [],
    };

    cameras.forEach((camera) => {
      const cams = [camera.skittles, camera.track, camera.scoring];
      cams.forEach((cam) => {
        const { id, checked } = cam;

        if (id && checked) {
          files.forInsert.push(id);
        }
      });
    });

    this.setState({ saving: true });
    this.props.bindVideoFilesToUser({ files, adminSave: true });
  };

  getFullSize = (playerid) => {
    let fullsize = 0;
    this.state.cameras.filter(camera => (playerid ? (camera.playerid === playerid) : true)).forEach((camera) => {
      fullsize += camera.track.size;
      fullsize += camera.scoring.size;
      fullsize += camera.skittles.size;
    });
    return fullsize;
  }

  calcSize = (playerid) => {
    let fullsize = 0;
    this.state.cameras.filter(camera => (playerid ? (camera.playerid === playerid) : true))
      .forEach((camera) => {
        ['track', 'scoring', 'skittles']
          .forEach((type) => { fullsize += camera[type].checked && camera[type].savedId ? camera[type].size : 0; });
      });
    return fullsize;
  }

  toGigaBytes = (size) => {
    let _size = _.round(size / (1000 ** 3), 2);
    if (_size === 0) {
      _size = _.round(size / (1000 ** 3), 3);
    }
    return [_size, 'Gb'];
  }

  toMegaBytes = (size) => {
    const _size = _.round(size / (1000 ** 2));
    return [_size, 'Mb'];
  }

  renderCompetitor = (cameras, idx) => {
    const { intl } = this.props;
    return (
      <div className="save_battle" key={idx}>
        <div>
          <Checkbox
            checked={cameras.track.checked && cameras.skittles.checked && cameras.scoring.checked}
            onChange={() => this.checkAll(cameras.playerid)}
          >
            { cameras.login }
            <span className="save_battle__space">&nbsp;({this.toGigaBytes(this.getFullSize(cameras.playerid)).join('')})</span>
          </Checkbox>
        </div>
        <div className="save_battle__camera_list">
          <div className="save_battle__structure">
            <svg width="16" height="78" viewBox="0 0 16 78" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect y="77" width="16" height="1" fill="#25292C" />
              <rect y="47" width="16" height="1" fill="#25292C" />
              <rect y="17" width="16" height="1" fill="#25292C" />
              <rect width="1" height="77" fill="#25292C" />
            </svg>
          </div>
          <div className="save_battle__checkbox_list">
            <div>
              {
               cameras.track 
                ? (<Checkbox
                    checked={cameras.track.checked}
                    onChange={() => this.checkOne(cameras.playerid, 'track')}
                  >
                    {intl.formatMessage({ id: 'save_battle_track_camera', defaultMessage: 'Track' })}
                    <span className="save_battle__space">&nbsp;({this.toGigaBytes(cameras.track.size).join('')})</span>
                  </Checkbox>) : null
              }
            </div>
            <div>
              {
                cameras.scoring
                  ? (<Checkbox
                    checked={cameras.scoring.checked}
                    onChange={() => this.checkOne(cameras.playerid, 'scoring')}
                  >
                    {intl.formatMessage({ id: 'save_battle_scoring_camera', defaultMessage: 'Scoring' })}
                    <span className="save_battle__space">&nbsp;({this.toGigaBytes(cameras.scoring.size).join('')})</span>
                  </Checkbox>) : null
              }
            </div>
            <div>
              {
                cameras.skittles
                  ? (<Checkbox
                      checked={cameras.skittles.checked}
                      onChange={() => this.checkOne(cameras.playerid, 'skittles')}
                    >
                      {intl.formatMessage({ id: 'save_battle_skittles_camera', defaultMessage: 'Skittles' })}
                      <span className="save_battle__space">&nbsp;({this.toGigaBytes(cameras.skittles.size).join('')})</span>
                    </Checkbox>) : null
              }
            </div>
          </div>
        </div>
      </div>
    );
  }

  checkOne = (playerid, type) => {
    const cameras = [...this.state.cameras];
    const cameraIndex = cameras.findIndex(camera => camera.playerid === playerid);

    cameras[cameraIndex][type].checked = !cameras[cameraIndex][type].checked;
    this.setState({ cameras });
  }

  checkAll = (playerid) => {
    const cameras = [...this.state.cameras];
    const types = ['skittles', 'scoring', 'track'];
    let targetState = null;
    const processingCameras = cameras.filter(camera => (playerid ? camera.playerid === playerid : true));

    targetState = !processingCameras.every(camera => types.every(type => camera[type].checked));
    processingCameras.forEach((camera) => {
      const cameraIndex = cameras.indexOf(camera);

      types.forEach((type) => {
        cameras[cameraIndex][type].checked = targetState;
      });
    });
    this.setState({ cameras });
  }

  render() {
    const types = ['skittles', 'scoring', 'track'];
    const {
      visible,
      intl,
      onClose,
    } = this.props;
    const {
      cameras,
      load,
      saving,
    } = this.state;

    const All = (
      <div className="save_battle__select_cameras">
        <span>{intl.formatMessage({ id: 'save_battle_select_cameras', defaultMessage: 'Select cameras to save' })}</span>
        <Checkbox
          checked={cameras.every(camera => types.every(type => camera[type].checked))}
          onChange={() => this.checkAll()}
        >
          {intl.formatMessage({ id: 'save_battle_all_cameras', defaultMessage: 'All cameras' })}
          <span className="save_battle__space">&nbsp;({this.toGigaBytes(this.getFullSize()).join('')})</span>
        </Checkbox>
      </div>
    );
    
    return (
      <OBModal
        title={intl.formatMessage({ id: 'save_battle_title', defaultMessage: 'Save video' })}
        visible={visible}
        width={395}
        onOk=''
        onCancel={onClose}
        className='save_battle__modal'
        okText=''
        cancelText=''
        closable={true}
        loading={load}
        footer={[
          <div key="buttons">
            <Button
              kind="aquamarine"
              bordered="true"
              onClick={onClose}
              disabled={saving ? 'disabled' : ''}
            >
              {intl.formatMessage({ id: 'CloseAsCancelAlias', defaultMessage: 'Close' })}
            </Button>
            <Button
              kind="aquamarine"
              onClick={this.onSubmit}
              loading={saving}
            >
              {intl.formatMessage({ id: 'save_battle_save', defaultMessage: 'Save' })}
            </Button>
          </div>
        ]}
      >
        <div className="save_battle__subtitle">Save video game recording to your archive?</div>
        <div className="save_battle__checkbox_container">
          <div className="save_battle__select_cameras">
            <span>{intl.formatMessage({ id: 'save_battle_select_cameras', defaultMessage: 'Select cameras to save' })}</span>
            <Checkbox
              checked={cameras.every(camera => types.every(type => camera[type].checked))}
              onChange={() => this.checkAll()}
            >
              {intl.formatMessage({ id: 'save_battle_all_cameras', defaultMessage: 'All cameras' })}
              <span className="save_battle__space">&nbsp;({this.toGigaBytes(this.getFullSize()).join('')})</span>
            </Checkbox>
          </div>
          <div className="save_battle__flexbox">
            {cameras.map(this.renderCompetitor)}
          </div>
        </div>
      </OBModal>
    );
  }
}

export default injectIntl(
  connect(
    state => ({
      videofiles: state.videofiles,
      user: state.users.user,
    }),
    {
      fetchVideoFiles,
      bindVideoFilesToUser,
    },
  )(ModalSaveVideo),
);
