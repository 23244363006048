import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { injectIntl, intlShape } from 'react-intl';
import VideoSlider from './videoSlider';
import { Utils } from './index';
import config from '../../../config';
import bugsnagClient from '../../../bugsnagClient';

// Example of onMuted, onPaused, onVolumeChange, onControlsChange usage:
// <VideoPlayer
//     onVolumeChange={(volume) => {
//         console.log('user has changed sound volume to ', volume);
//     }}
//     onMuted={(muted) => {
//         if (muted) {
//             console.log('user has muted sound');
//         } else {
//             console.log('user has unmuted sound');
//         }
//     }}
//     onPaused={(paused) => {
//         if (paused) {
//             console.log('user has paused media');
//         } else {
//             console.log('user has unpaused media');
//         }
//     }}
//     onControlsChange={(controls) => {
//         if (controls) {
//             console.log('user has switched media controls on');
//         } else {
//             console.log('user has switched media controls off');
//         }
//     }}
// />

function dummy() {}

const parseArchiveURL = (url) => {
  if (!url) {
    return null;
  }
  const match = url.match(/video-(\d+)-(\d+)\.m3u8/);
  if (!match || match.length !== 3) {
    return null;
  }
  const start = +match[1];
  const duration = +match[2];
  return { start, duration };
};

const once = (media, event, callback) => {
  if (!media) {
    return;
  }
  const listener = () => {
    callback();
    media.removeEventListener(event, listener);
  };
  media.addEventListener(event, listener);
};

class _Protest extends Component {
  static propTypes = {
    intl: intlShape,
  };

  render() {
    const { intl } = this.props;
    const { timestamp } = this.props; // timestamp in secs
    const time = moment(timestamp * 1000).format('HH:mm');
    const text = intl.formatMessage({ id: 'ProtestAsGameComplaintAlias', defaultMessage: 'Protest' });
    return (
      <div className="protest-label">
        <span>{`${time} - ${text}`}</span>
      </div>
    );
  }
}

const Protest = injectIntl(_Protest);

class Point extends Component {
  render() {
    return (
      <div className="protest-point">
        <div />
      </div>
    );
  }
}

class VideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.isSafari = Utils.isSafari();
    this.iOS = Utils.iOS();
    this.logger = {
      log: props.debug ? console.log : function log() {},
      error: console.error,
      warn: console.warn,
      info: console.info,
    };
    this.REF = React.createRef();
    this.media = null;
  }

  componentDidMount() {
    const { id } = this.props;
    const media = this._getHTMLMedia(id);
    if (!media) {
      const error = 'HTMLMediaElement not found';
      bugsnagClient.notify(new Error(error), { context: 'VideoPlayer' });
    }
    this._callPropsFunc('onMedia', media);
    this.media = media;
    //this.setState({ media });
    this.initMedia(media);
  }

  componentDidUpdate(oldProps, oldState) {
    const { range: oldRange } = oldProps;
    const { range } = this.props;
    if (range !== oldRange && range && oldRange) {
      this.setState({ range });
    }
  }
  componentWillUnmount() {
    if (this.vidRef) {
      this.vidRef.src = null;
    }
  }

  getCurrentTime = () => {
    const { media } = this;
    return media ? media.currentTime : 0;
  };

  getDuration = () => {
    const { media } = this;
    return media ? media.duration : 0;
  };

  setPaused = (media, paused) => {
    // used by iOS
    if (!media) return;
    const { url, range } = this.props;
    if (paused) {
      if (!media.paused) {
        media.pause();
      }
    } else if (media.paused) {
      if (url && !Utils.isLiveUrl(url)) {
        once(media, 'loadeddata', () => {
          media.currentTime = (media.duration * range) / 1000;
          this.logger.log(`media.currentTime = ${(media.duration * range) / 1000}`);
          this.logger.log(`media.currentTime = ${media.currentTime}`);
        });
      } else if (url && Utils.isLiveUrl(url)) {
        media.src = url;
        media.load();
      }
      media.play();
    }
  };

  destroy = () => {
    const { vidRef: video } = this || {};
    if (!video) {
      return;
    }
    try {
      video.pause();
    } catch (err) {
      console.error(err);
    }
    video.src = null;
  };

  start = () => {
    const { vidRef: video } = this || {};
    if (!video) {
      return;
    }
    const { url } = this.props;
    video.src = url;
    try {
      video.load();
      video.play();
    } catch (er) {
      console.error(er);
    }
  };

  restart = () => {
    this.destroy();
    setTimeout(this.start, 300);
  };

  _setMediaProp = (media, prop, value) => {
    if (media[prop] !== value) {
      media[prop] = value;
    }
  };

  setMuted = (media, muted) => this._setMediaProp(media, 'muted', muted);

  setVolume = (media, volume) => this._setMediaProp(media, 'volume', volume);

  setControls = (media, controls) => this._setMediaProp(media, 'controls', controls);

  _getHTMLMedia = id => window.document.getElementById(id);

  _callPropsFunc = (func, ...args) => {
    if (typeof this.props[func] === 'function') {
      this.props[func](...args);
    }
  };

  onPaused = (event, paused) => {
    if (!event.isTrusted) return;
    this._callPropsFunc('onPaused', paused);
  };

  onWaiting = (event) => {
    this._callPropsFunc('onWaiting', event);
  };

  onDurationChange = (event) => {
    this._callPropsFunc('onDurationChange', event.target.duration);
  };

  init = (media, range) => {
    if (!media) {
      return;
    }
    const status = media.getAttribute('data-status');
    if (status === 'inited') {
      return;
    }
    media.setAttribute('data-status', 'inited');
    const that = this;
    const listener = function listener() {
      this.logger.log('[+] HLSPlayer::_init() -> setRange');
      that.setRange(media, range);
      const { duration } = media;
      if (typeof that.props.mediaDuration === 'function' && duration !== 0) {
        that.props.mediaDuration(duration);
      }
      media.removeEventListener('durationchange', listener);
    };
    media.addEventListener('durationchange', listener);
  };

  _onPlay = event => this.onPaused(event, false);

  _onPause = event => this.onPaused(event, true);

  onVolumeChange = (event) => {
    if (!event.isTrusted) return;
    const media = event.target;
    const { volume, muted } = this.props;
    if (media.volume !== volume) {
      this._callPropsFunc('onVolumeChange', media.volume);
    }
    if (media.muted !== muted) {
      this._callPropsFunc('onMuted', media.muted);
    }
  };

  onRangeChange = (value) => {
    const { onRangeChange } = this.props;
    this.logger.log('videoplayer.onRangeChange:', value);
    onRangeChange(value);
  };

  getMediaState = (event) => {
    const media = event ? event.target : this.media;
    const { controls } = this.props;
    if (media.controls !== controls) {
      this._callPropsFunc('onControlsChange', media.controls);
    }
  };

  isLive = () => {
    const { url } = this.props;
    return Utils.isLiveUrl(url);
  };

  setRange = (media, range) => {
    if (!media || media.duration === Infinity || isNaN(media.duration) || this.isLive()) {
      return;
    }
    const { currentTime, duration, paused } = media;
    const y = Math.round((currentTime / duration) * 1000);
    const dif = Math.abs(range - y);
    if (dif < 5) {
      return;
    }
    const x = range / 1000;
    const time = media.duration * x;
    media.currentTime = time;
    if (paused) {
      media.pause();
    } else {
      media.play();
    }
  };

  listen = (event) => {
    const { target } = event;
    this.logger.log('-----> event: ', event.type, target.duration, target.currentTime);
  };

  names = [
    'abort',
    'canplay',
    'canplaythrough',
    'durationchange',
    'emptied',
    'encrypted',
    'ended',
    'error',
    'interruptbegin',
    'interruptend',
    'loadeddata',
    'loadedmetadata',
    'loadstart',
    'pause',
    'play',
    'playing',
    'progress',
    'ratechange',
    'seeked',
    'seeking',
    'stalled',
    'suspend',
    'timeupdate',
    'volumechange',
    'waiting',
  ];

  addListeners = (media) => {
    this.names.forEach((key) => {
      media.addEventListener(key, this.listen);
    });
  };

  removeListeners = (media) => {
    this.names.forEach((key) => {
      media.removeEventListener(key, this.listen);
    });
  };

  updateRange = (e) => {
    const { target: media } = e;
    if (!media) {
      return;
    }
    const { currentTime, duration } = media;
    if (duration) {
      const range = Math.round((currentTime * 1000) / duration);
      this.setState({ range });
      const { updateRange = dummy } = this.props;
      updateRange(range);
    }
  };

  createProtestMarks = () => {
    const { protests, originalUrl: url, isLive } = this.props;
    if (!protests || !protests.length || isLive) {
      return {};
    }
    const archive = parseArchiveURL(url);
    if (!archive) {
      return {};
    }
    const { start, duration } = archive;
    const end = start + duration;
    const marks = {};
    for (const { created } of protests) {
      const timestamp = Math.round(+new Date(created) / 1000);
      if (timestamp >= start && timestamp <= end) {
        const time = timestamp - start;
        const range = Math.round((time / duration) * 1000);
        marks[range] = <Protest timestamp={timestamp} />;
      }
    }
    return marks;
  };

  tipFormatter = (range) => {
    const { originalUrl: url, isLive } = this.props;
    if (isLive) {
      return null;
    }
    const archive = parseArchiveURL(url);
    if (!archive) {
      return null;
    }
    const { start, duration } = archive;
    const time = Math.round(range / 1000 * duration) + start;
    return moment(time * 1000).format('HH:mm');
  }

  onProgress = (e) => {
    const { buffered } = e.target;
    if (buffered.length) {
      const { onProgress } = this.props;
      const end = buffered.end(buffered.length - 1);
      if (typeof onProgress === 'function') {
        onProgress(end);
      }
    }
  };

  onLoadStart = () => {
    const { onLoadStart } = this.props;
    const time = Date.now();
    if (typeof onLoadStart === 'function') {
      onLoadStart(time);
    }
  };

  onStreamEnd = () => {
    const { onStreamEnd } = this.props;
    if (typeof onStreamEnd === 'function') {
      onStreamEnd();
    }
  }

  initMedia = () => {
    const { ref: media = this.vidRef } = this;
    if (!media) return;
    media.addEventListener('loadstart', this.onLoadStart);
    media.addEventListener('progress', this.onProgress);
    media.addEventListener('ended', this.onStreamEnd);
    media.addEventListener('play', this._onPlay);
    media.addEventListener('pause', this._onPause);
    media.addEventListener('waiting', this.onWaiting);
    media.addEventListener('timeupdate', this.getMediaState);
    media.addEventListener('volumechange', this.onVolumeChange);
    media.addEventListener('timeupdate', this.updateRange);
    media.addEventListener('durationchange', this.onDurationChange);
    if (this.addListeners && this.props.debug && config.VIDEOS.showAllEvents) {
      this.addListeners(media);
    }
  };

  clearMedia = () => {
    const { ref: media = this.vidRef } = this;
    if (!media) return;
    media.removeEventListener('loadstart', this.onLoadStart);
    media.removeEventListener('progress', this.onProgress);
    media.removeEventListener('ended', this.onStreamEnd);
    media.removeEventListener('play', this._onPlay);
    media.removeEventListener('pause', this._onPause);
    media.removeEventListener('waiting', this.onWaiting);
    media.removeEventListener('timeupdate', this.getMediaState);
    media.removeEventListener('volumechange', this.onVolumeChange);
    media.removeEventListener('timeupdate', this.updateRange);
    media.removeEventListener('durationchange', this.onDurationChange);
    if (this.removeListeners && this.props.debug && config.VIDEOS.showAllEvents) {
      this.removeListeners(media);
    }
  };

  readyState = () => {
    const { media } = this;
    return media ? media.readyState : 0;
  };

  render() {
    const { media } = this;
    const { range } = this.state;
    const {
      id,
      url,
      paused,
      autoPlay,
      volume,
      muted,
      controls,
      style,
      styleDIV,
      hideSlider,
      isLive,
    } = this.props;
    if (media) {
      this.setMuted(media, muted);
      this.setControls(media, controls);
      if (!muted) {
        this.setVolume(media, volume * 0.01);
      }
    }
    const defStyle = { width: '100%' };
    const marks = hideSlider ? null : this.createProtestMarks();
    const slider = hideSlider ? null : (
      <VideoSlider
        className="video-slider"
        min={0}
        max={1000}
        tipFormatter={this.tipFormatter}
        tooltipVisibile
        step={1}
        value={isLive ? 1000 : range}
        onChange={this.onRangeChange}
        marks={marks}
      />
    );
    if (url && ((this.isSafari && config.VIDEOS.nativeHlsOnSafari && !config.VIDEOS.mseOnSafari) || this.iOS)) {
      if (this.props.range === range) {
        this.setRange(media, range);
      }
      if (Utils.isLiveUrl(url)) {
        this.setPaused(media, false, url);
      } else {
        this.setPaused(media, paused, url);
      }
      return (
        <div style={styleDIV}>
          <video
            key={id}
            id={id}
            style={{ ...style, ...defStyle }}
            autoPlay={autoPlay}
            src={url}
            muted
            controls={!this.isLive()}
            playsInline
            ref={(c) => {
              this.vidRef = c;
            }}
          />
          {slider}
        </div>
      );
    }
    return (
      <div style={styleDIV}>
        <video
          key={id}
          id={id}
          style={{ ...style, ...defStyle }}
          autoPlay={autoPlay}
          ref={(c) => {
            this.vidRef = c;
          }}
        />
        {slider}
      </div>
    );
  }
}

VideoPlayer.isSupported = () => true;

Object.defineProperties(VideoPlayer, {
  name: {
    value: 'video-player',
    enumerable: true,
  },
  isHTMLMedia: {
    value: true,
    enumerable: true,
  },
});

VideoPlayer.defaultProps = {
  styleDIV: { width: '50%', position: 'relative' },
  style: { width: '100%', position: 'absolute' },
  range: 1000,
  volume: 0.75,
  hideSlider: false,
  muted: false,
  controls: false,
  autoPlay: true,
  paused: true,
};

VideoPlayer.propTypes = {
  id: PropTypes.string,
  muted: PropTypes.bool,
  hideSlider: PropTypes.bool,
  url: PropTypes.string,
  paused: PropTypes.bool,
  autoPlay: PropTypes.bool,
  volume: PropTypes.number,
  controls: PropTypes.bool,
  range: PropTypes.number,
  style: PropTypes.shape(),
  styleDIV: PropTypes.shape(),
  onMedia: PropTypes.func,
  onMuted: PropTypes.func,
  onPaused: PropTypes.func,
  onWaiting: PropTypes.func,
  onVolumeChange: PropTypes.func,
  onControlsChange: PropTypes.func,
  onRangeChange: PropTypes.func.isRequired,
  onStreamEnd: PropTypes.func,
};

export default connect(
  state => ({
    protests: state.squads.squad.data.protests,
  }),
  {},
  null,
  { withRef: true },
)(VideoPlayer);
