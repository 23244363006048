import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import {
  setCreateBattleQuery,
  requestSquadList,
} from '../../libs/reducers/actions';
import SvgSwords from '-!svg-react-loader!../../images/sword.svg'; // eslint-disable-line
import SvgFriends from '-!svg-react-loader!../../images/friends.svg'; // eslint-disable-line

import './style.scss';

class BattleInfoPanel extends Component {

  constructor(props) {

    super(props);

    this.state = {
      ignore: false
    }
  }

  componentDidMount() {
    this.props.requestSquadList();
  }

  onCreateBattle = () => {
    const { location, history, setCreateBattleQuery } = this.props;
    const { pathname } = location;
    setCreateBattleQuery(true);
    if (pathname != '/lobby') {
      history.replace('/lobby');
    }
  }

  render() {
    const { intl, squads, history, user } = this.props;
    const translate = {
      create: intl.formatMessage({ id: 'create_battle', defaultMessage: 'Create Battle' }),
      pendingBattles: intl.formatMessage({ id: 'PendingBattles', defaultMessage: 'pending Battles' }),
      playWithFriend: intl.formatMessage({ id: 'PlayWithFriend', defaultMessage: 'Play with friend' }),
    }
    let pendingBattles = 0;
    if (user && squads) {
      const battles = squads.filter(sq => sq.joinable && !sq.joined);
      pendingBattles = battles.length;
    }
    return (
      <div className="battle_info_panel">
        <div className="battle_info_panel__create_button" onClick={this.onCreateBattle}>
          <div className="battle_info_panel__create_button__icon">
            <SvgSwords/>
          </div>
          <div className="battle_info_panel__create_button__text">
            {translate.create}
          </div>
        </div>
        <div className="battle_info_panel__aside">
          <div className="battle_info_panel__button pending_battles" onClick={() => { history.replace('/lobby') }}>
            <div className="battle_info_panel__button_icon">
              {pendingBattles}
            </div>
            <div className="battle_info_panel__button_text">
              {translate.pendingBattles}
            </div>
          </div>
          <div className="battle_info_panel__button play_with_friend" onClick={() => { history.replace('/account/friends/list') }}>
            <div className="battle_info_panel__button_icon">
              <SvgFriends/>
            </div>
            <div className="battle_info_panel__button_text">
              {translate.playWithFriend}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(withRouter(
  connect(
    state => ({
      user: state.users.user,
      squads: state.battles.squads,
      lang: state.langs.lang,
    }),
    {
      setCreateBattleQuery,
      requestSquadList,
    },
  )(BattleInfoPanel),
));
