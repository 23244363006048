import React, {useEffect, useReducer, useState} from "react";
import gear from "../../../../images/GearWhite.png";
import Trash from "../../../../images/trash.png";
import {Input, Radio, Select, Upload} from "antd";
import plus from "../../../../images/whitePlus.png";
import 'antd/lib/upload/style/index.css';
import '../style.css';
import {Button} from "../../../common/Button";
import moment from "moment";

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const UploadList = (props) => {
const {
  visibleBanners = [],
  currentBanner,
  selectedImage,
  setSelectedImage,
  maxImages,
  tournamentOptions = [],
  appPages = [],
  adminType,
  setVisibleBanners,
    activeBC,
    onSave,
    isSaved,
    isWarningVisible,
    setIsWarningVisible
} = props

  const [error, setError] = useState(false);
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  let activeBCNamesArr = Object.keys(activeBC).filter(name => activeBC[name].visible)

  let underSaveText = 'The publication will be in the App after moderation by the administrator.'

  const onDelete = (file) => {
    if (file === selectedImage) {
      setSelectedImage(null);
    }
    setIsWarningVisible(true)
    file.bc_name.forEach(name => activeBC[name].count -= 1)

    let tempVisibleBanners = visibleBanners.map(banner => {
      if (banner.name === file.name ){
        if ( banner.status === 'done' || banner.status === 'changed'){
          return {...banner, status: 'deleted', bc_name: []}
        }
        return
      }
      return banner
    }).filter(banner => !!banner)

    // file.bc_name.forEach(name => {
    //   activeBC[name].count -= 1
    // })

    setVisibleBanners([...tempVisibleBanners])
  }

  const onClearError = () => {
    setError(false);
  }

  const onChangeImage = async (info) => {
    onClearError();


    const { file, fileList } = info;

    if (file.status === 'uploading') {
      const types = ['image/jpeg', 'image/png'];

      if (!types.some(type => type === file.type)) {
        setError('It\'s not an Image');
        return;
      }
      if (file.size > 5242880) {
        setError('Uploaded image is too big.');
        return;
      }
      file.base64 = await getBase64(file.originFileObj);
      file.bc_name = activeBCNamesArr
    }
    activeBCNamesArr.forEach(name => activeBC[name].count += 1)
    // setBCBannersFromFrontEnd([...bcBannersFromFrontEnd, file]);
    setVisibleBanners([...visibleBanners, file])
  };


  const onPreviewFile = async (file) => {
    const thumb = await getBase64(file)
    return thumb;
  };

  function isVisible(file) {
    let result =  file.bc_name.some(name => {
      if (activeBC[name]) return activeBC[name].visible
    })
    if (!file.bc_name.length ){
      return true
    }
    return result
  }
  return (
    <div className={'upload-list-container'}>
      <div className="bcAdmin-upload__list">
        <div className={'bcAdmin-upload__banners'}>
          {
            visibleBanners.map((file) => {
                  if (file && file.status !== 'deleted' && isVisible(file)){
                    return (
                        <div>
                          <div className={`upload-item ${file === selectedImage ? 'selected' : ''}`} key={file.url || file.base64}>
                            <img className="upload-item-image" src={file.url || file.base64}/>
                            <div className="howerImage">
                              {currentBanner !== 'Poster' && (
                                  <div
                                      className="howerImageButtom"
                                      onClick={() => {
                                        if (file === selectedImage) {
                                          setSelectedImage(null)
                                        } else {
                                          setSelectedImage(file)}
                                      }
                                      }
                                  >
                                    <img src={gear}/>
                                  </div>
                              )}
                              <div className="howerImageButtom" onClick={() => onDelete(file)}>
                                <img src={Trash}/>
                              </div>
                            </div>
                          </div>
                          {file.creator && <div className={'banner_data_container'}>
                            <div
                                className={'banner_data_text orange_text'}>{file.moderation_agreed ? 'Published' : 'Waiting for moderation'}</div>
                            <div
                                className={'banner_data_text'}>Administrator: {file.creator.firstname} {file.creator.lastname} / {file.creator.login}</div>
                            <div className={'banner_data_text'}>{moment(file.lastUpdated).format("DD MMM YYYY")} </div>
                          </div>}
                        </div>
                    );
                  }
                }
            )}
          {
            visibleBanners.length < maxImages
                ? <div>
                  {error && <div>{error}</div>}
                  <Upload
                      listType="picture"
                      fileList={[ ...visibleBanners ]}
                      className="admin-upload-conatiner"
                      customRequest={() => {}}
                      onChange={props => {
                        onChangeImage(props);
                      }}
                      previewFile={onPreviewFile}
                      showUploadList={false}
                  >
                    <div className="upload-item">
                      <img className="plus" src={plus}/>
                      {adminType === 'AdminBC'&& <div className={'uploadImagePlaceholder'}>jpeg/png, 1280*720, max 4Mb</div>}
                    </div>
                  </Upload>
                </div>
                : null
          }
        </div>
        {
          (selectedImage && currentBanner !== 'Poster') && (
              <div className="item-datail">
                <div className="admin-upload__input">
                  <Radio.Group
                      value={selectedImage.clickable || false}
                      onChange={(e) => {
                        selectedImage.clickable = e.target.value;
                        selectedImage.link = null;
                        forceUpdate();
                        if (selectedImage.status === 'done') {
                          selectedImage.status = 'changed'
                        }
                        setVisibleBanners([...visibleBanners])
                      }}
                  >
                    <Radio.Button value={true}>Clickable</Radio.Button>
                    <Radio.Button value={false}>Unclickable</Radio.Button>
                  </Radio.Group>
                </div>
                {selectedImage.clickable && adminType === 'AdminBokus' && (
                    <div>
                      <div className="radio-button-label">Leads to</div>
                      <div className="admin-upload__input">
                        <Radio.Group value={selectedImage.linkType} onChange={(e) => {
                          selectedImage.linkType = e.target.value;
                          selectedImage.appScreen = null;
                          selectedImage.navigationUrl = null;
                          forceUpdate();
                        }}>
                          <Radio.Button disabled={currentBanner==='Login'} value={'InApp'}>App Page</Radio.Button>
                          <Radio.Button value={'URL'}>URL</Radio.Button>
                        </Radio.Group>
                      </div>
                      { selectedImage.linkType === 'InApp' &&
                      <div className="select-container">
                        <Select value={selectedImage.appScreen} onChange={value => {selectedImage.appScreen = value; forceUpdate();}} placeholder="Select page">
                          {appPages.map(item => <Select.Option value={item.value} key={item.label} >{item.label}</Select.Option>)}
                        </Select>
                      </div>
                      }
                      {
                        (selectedImage.appScreen === 'Tournaments' || selectedImage.appScreen === 'OpenChallenges') &&
                        <div className="select-container">
                          <Select value={selectedImage.appScreenNavOpt} onChange={value => {selectedImage.appScreenNavOpt = value; forceUpdate();}} placeholder="Tournament">
                            {tournamentOptions.map(item => <Select.Option value={item.value} key={item.label}>{item.label}</Select.Option>)}
                          </Select>
                        </div>
                      }
                      { selectedImage.linkType === 'URL' &&
                      <Input placeholder="enter URL" value={selectedImage.navigationUrl} onChange={(e) => { selectedImage.navigationUrl = e.target.value; forceUpdate(); }}/>
                      }
                    </div>
                )}
                {selectedImage.clickable && adminType === 'AdminBC' &&
                <Input placeholder="enter URL" value={selectedImage.link} onChange={(e) => {
                  selectedImage.link = e.target.value;
                  selectedImage.linkType = 'URL'
                  forceUpdate();
                  setIsWarningVisible(true);
                  if (selectedImage.status === 'done') selectedImage.status = 'changed'
                }}
                />
                }
              </div>
          )
        }
        {isWarningVisible && <div className={'item-detail__warning-container'}>
          <p className={'item-detail__warning-text'}>CLICK "SAVE" TO SAVE YOUR CHANGES</p>
        </div>}
      </div>


      <div className="bc-admin-upload__save">
      {isSaved && <div className={'bc-admin-upload__save_text'}>Changes saved</div>}
        <Button className="admin-upload__save_button" onClick={onSave}>
          Save
        </Button>
      </div>
      <div className={'textGray'}>{underSaveText}</div>
    </div>
  );
};

export default UploadList;
