import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table } from 'antd';
import Button from './common/Button/Button';
import { FilterInput } from './common/Input';
import Modal from './common/Modal';

class Translations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      langInput: '',
      translationInput: '',
      newLangId: 0,
      showModal: false,
      showModalNewTag: false,
      modalTag: '',
      modalVal: '',
      data: [
        {
          id: 1,
          codestring: 'enter',
          textvalue: 'Вход',
          langs: {
            en: 'Enter',
            ru: 'Вход',
          },
        },
        {
          id: 2,
          codestring: 'registration',
          textvalue: 'Регистрация',
          langs: {
            en: 'Registration',
            ru: 'Регистрация',
          },
        },
      ],
    };
  }

  ClickSave = (record) => {
    console.log(record);
    // сохранять изменения
  };

  ClickAdd = (record) => {
    this.setState({
      newLangId: record.id,
      showModal: true,
    });
  };

  AddNewTag = () => {
    this.setState({
      showModalNewTag: true,
    });
  };

  onChangeInput = (type, data) => (e) => {
    let arr = [...this.state.data];
    arr = arr.map((elem) => {
      const newElem = { ...elem };
      if (elem.id === data.id) {
        newElem[type] = e.target.value;
      }
      return newElem;
    });
    this.setState({
      data: arr,
    });
  };

  render() {
    const newTag = (
      <div>
        <FilterInput
          placeholder="Tag"
          value={this.state.modalTag}
          onChange={(e) => {
            this.setState({
              modalTag: e.target.value,
            });
          }}
        />
        <FilterInput
          placeholder="Значение(на русском)"
          value={this.state.modalVal}
          onChange={(e) => {
            this.setState({
              modalVal: e.target.value,
            });
          }}
        />
      </div>
    );

    const newTagArr = [newTag];

    const columns = [
      {
        key: 'codestring',
        title: 'Tag',
        dataIndex: 'codestring',
      },
      {
        key: 'textvalue',
        title: 'Значение(на русском)',
        dataIndex: 'textvalue',
        render: (data, record) => (
          <FilterInput key={record.id} value={data} onChange={this.onChangeInput('textvalue', record)} />
        ),
      },
      {
        key: 'langs',
        title: 'Переводы',
        dataIndex: 'langs',
        render: (data, record) => {
          const langs = [];
          Object.keys(record.langs || {}).forEach((key) => {
            langs.push(
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  margin: '2px',
                }}
                key={key}
              >
                <span
                  style={{
                    flex: '0 0 30px',
                  }}
                >
                  {key}:
                </span>
                <FilterInput
                  value={record.langs[key]}
                  onChange={(e) => {
                    let arr = [...this.state.data];
                    arr = arr.map((elem) => {
                      const newElem = { ...elem };
                      if (elem.id === record.id) {
                        newElem.langs[key] = e.target.value;
                      }
                      return newElem;
                    });
                    this.setState({
                      data: arr,
                    });
                  }}
                />
              </div>,
            );
          });
          return langs;
        },
      },
      {
        key: 'action',
        title: 'Действия',
        render: (data, record) => (
          <div style={{ dispalay: 'flex', flexDirection: 'column' }}>
            <Button size="xs" kind="aquamarine" onClick={() => this.ClickSave(record)}>
              Сохранить
            </Button>
            <div style={{ margin: '2px' }} />
            <Button
              size="xs"
              kind="aquamarine"
              onClick={() => {
                this.ClickAdd(record);
              }}
            >
              Добавить новый язык
            </Button>
          </div>
        ),
      },
    ];

    return (
      <div>
        <Modal
          onClose={() => {
            this.setState({
              langInput: '',
              translationInput: '',
              showModal: false,
            });
          }}
          title="Добавить новый язык"
          visible={this.state.showModal}
          onOk={() => {
            let arr = [...this.state.data];
            arr = arr.map((elem) => {
              const newElem = { ...elem };
              if (elem.id === this.state.newLangId) {
                newElem.langs[this.state.langInput] = this.state.translationInput;
              }
              return newElem;
            });
            this.setState({
              data: arr,
              langInput: '',
              translationInput: '',
              showModal: false,
            });
          }}
          onCancel={() => {
            this.setState({
              langInput: '',
              translationInput: '',
              showModal: false,
            });
          }}
        >
          <FilterInput
            placeholder="Iso языка"
            value={this.state.langInput}
            onChange={(e) => {
              this.setState({
                langInput: e.target.value.slice(0, 2),
              });
            }}
          />
          <FilterInput
            placeholder="Перевод"
            value={this.state.translationInput}
            onChange={(e) => {
              this.setState({
                translationInput: e.target.value,
              });
            }}
          />
        </Modal>
        <Modal
          onClose={() => {
            this.setState({
              showModalNewTag: false,
            });
          }}
          title="Добавить новый Tag"
          visible={this.state.showModalNewTag}
          onOk={() => {
            const arr = [...this.state.data];
            arr.push({
              id: this.state.data.length + 1,
              codestring: this.state.modalTag,
              textvalue: this.state.modalVal,
              langs: { ru: this.state.modalVal },
            });
            this.setState({
              data: arr,
              showModalNewTag: false,
            });
          }}
          onCancel={() => {
            this.setState({
              showModalNewTag: false,
            });
          }}
        >
          {newTagArr}
        </Modal>
        <div style={{ maxWidth: '15%' }}>
          <Button size="md" kind="aquamarine" onClick={this.AddNewTag}>
            Добавить новый Tag
          </Button>
        </div>
        <div style={{ borderBottom: '1px solid #252b32' }} />
        <Table
          key={this.props.lang}
          className="table"
          columns={columns}
          dataSource={this.state.data}
          pagination={false}
          rowKey="id"
          style={{ width: '100%' }}
          locale={{ emptyText: 'Нет данных' }}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      users: state.users,
      langs: state.langs,
      lang: state.langs.lang,
    }),
    {},
  )(Translations),
);
