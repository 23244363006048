import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Icon } from 'antd';

import ModalDialog from '../../components/common/modals/modalDialog';
import FrameEditModal from '../../components/common/modals/modalFrameEdit';

class ResultInput extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			input: [],
			isFoal: false,
			editModal: false,
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.selectedFrame !== this.props.selectedFrame) {
			this.setState({ input: [] });
		}
		if (prevState.input !== this.state.input) {
			console.log(this.state.input);
			this.props.onInput(this.state.input);
		}
	}

	isLastFrame = () => {
		const { squad, selectedFrame } = this.props;
		const userid = this.props.users.user.id;
		const competitor = squad.players.find(player => player.userid === userid);
		if (selectedFrame) return selectedFrame.framenumber === 10
		else return (squad.games[this.props.selectedGame || squad.games.length-1].frames[competitor.id].find(frame => frame.shot1 === -1) || { framenumber: 10 }).framenumber === 10;
	}

	handleSelect = (amount, isFoal) => {
		const state = {};
		if (isFoal) state.isFoal = true;
		state.input = [ ...this.state.input, amount ];
		this.setState({ ...state });
	}

	isButtonDisabled = value => {
		if (this.props.isOpponentSelection || this.props.selectedFrame && !this.props.isEditActive || this.props.endGameEdit && (!this.props.selectedFrame || this.props.isOpponentSelection)) return true
		if (this.isLastFrame()) {
			if (+this.state.input[0] === 10 && this.state.input.length < 3) {
				return (((this.state.input[0] + this.state.input[1]) % 10) + value > 10);
			} else if ((this.state.input[0] + (this.state.input[1] || 0)) === 10 && this.state.input.length === 2) {
				return false
			} else if (this.state.input.length < 2) {
				return ((this.state.input[0] + value) > 10);
			} else {
				return true
			}
		} else {
			if (this.state.input[0] === 10) {
				return true
			} else if (this.state.input.reduce((acc, val) => acc + val, 0) + value <= 10 && this.state.input.length < 2) {
				return false
			} else {
				return true
			}
		}
	}

	getSpareScore = () => {
		return 10 - this.state.input.reduce((acc, val) => acc + val, 0) % 10;
	}

	isSaveDisabled = () => {
		if (!!this.props.isOpponentSelection) return true
		if (!this.isLastFrame()) {
			if (this.state.input.reduce((acc, val) => acc + val, 0) >= 10 || this.state.input.length == 2) return false
		} else {
			if (this.state.input.length === 3 || this.state.input.length === 2 && (this.state.input[0] + this.state.input[1]) < 10) return false
		}
		return true
	}

	isStrikeDisabled = () => {
		if (this.props.isOpponentSelection || this.props.selectedFrame && !this.props.isEditActive || this.props.endGameEdit && (!this.props.selectedFrame || this.props.isOpponentSelection)) return true
		if (this.isLastFrame()) {
			return (this.state.input.length > 2 || (this.state.input[0] + this.state.input[1] < 10) && this.state.input.length < 3); 
		} else {
			return (this.state.input[0] === 10 || this.state.input.length > 1);
		}
	}

	getStrikeString = () => {
		if (!this.state.input.length || this.state.input.reduce((acc, val) => acc + val, 0) % 10 === 0 && this.state.input[this.state.input.length - 1] !== 0) return 'Strike'
		else return 'Spare'
	}

	handleSave = () => {
		if (this.props.isEditActive) {
			this.setState({ editModal: true });
		} else {
			this.props.onSave(this.state.input);
		}
	}

	ResetFrame = () => {
		this.setState({ input: [] })
	}
	
	render() {
		const {
			selectedFrame,
			intl,
		} = this.props;
		return <div className="addingBtnContainer">
			{new Array(10).fill(1).map((item, index) => (
				<button
					onClick={() => this.handleSelect( index!==9 ? index+1 : 0 )}
					className="addingBtnContainer-btn"
					disabled={this.isButtonDisabled( index!==9 ? index+1 : 0 )}
					key={index}>
					{ index!==9 ? index+1 : 0 }
				</button>
			))}
			<button
				onClick={this.handleSave}
				className="addingBtnContainer-btn addingBtnContainer-btn-big"
				disabled={this.isSaveDisabled()}>
				{intl.formatMessage({ id: 'SaveAsKeepAlias', defaultMessage: 'Save' })}
			</button>
			<button
				disabled={this.isStrikeDisabled()}
				className="addingBtnContainer-btn"
				onClick={() => this.handleSelect(this.getSpareScore())}>
				{this.getStrikeString()}
			</button>
			<button
				disabled={this.isButtonDisabled(0)}
				className="addingBtnContainer-btn"
				onClick={() => this.handleSelect(0, true)}>
				{intl.formatMessage({ id: 'FoalAsBadFrameAlias', defaultMessage: 'Foul' })}
			</button>
			<button
				className="addingBtnContainer-btn addingBtnContainer-btn-small"
				onClick={() => this.props.onEdit()}
				disabled={!this.props.selectedFrame || this.props.isOpponentSelection}>
				<Icon type="edit" />
			</button>
			<button
				disabled={!this.state.input.length}
				className="addingBtnContainer-btn addingBtnContainer-btn-small"
				onClick={() => this.setState({ input: this.state.input.slice(0, -1) })}>
				<Icon type="delete" />
			</button>
			<div className="addingSeparator2" />
			{this.state.editModal ?
				<FrameEditModal
					visible={true}
					oldframe={this.props.selectedFrame}
					newframe={{
						shot1: this.state.input.length > 0 ? this.state.input[0] : -1,
						shot2: this.state.input.length > 1 ? this.state.input[1] : -1,
						shot3: this.state.input.length > 2 ? this.state.input[2] : -1,
						flags: this.props.selectedFrame.flags,
					}}
					framenumber={selectedFrame.framenumber}
					onEdit={() => {
						this.props.onFinishEdit();
						this.setState({ editModal: false, input: [] });
					}}
					onClose={() => {
						this.setState({ editModal: false, input: [] });
						this.props.onFinishEdit();
					}}
				/>
			: null}
		</div>
	}
}

export default injectIntl(
	withRouter(
	  connect(
		state => ({
			users: state.users,
			squad: state.squadScreen.data,
		}),
		{
		},
	  )(ResultInput),
	),
  );