import React, { Component } from 'react'
import ReactWOW from 'react-wow'
import phoneshowcase from './images/phoneshowcase.png';
import phoneshowcasealt from './images/phoneshowcasealt.png';

import './style.scss'

export default class MobileApp extends Component {
  constructor(props) {

    super(props);

    this.state = {
      ptMod: 1,
      slide: 1,
      touched: false, 
      beginTouch: 0, 
      endTouch: 0,
      moved: false
    }

    this.delay = 5000
    this.timeout = null
  }

  componentDidMount() {
    window.addEventListener('resize', () => { this.widthRecalc() });
    this.widthRecalc();
    this.switchSlide(1)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => { this.widthRecalc() });
    clearTimeout(this.timeout)
  }

  widthRecalc() {
    const width = window.innerWidth;
    if (width > (627)) { // (380 / 4.875)*10
      this.setState({ ptMod: 0.8 });
    } else {
      this.setState({ ptMod: (width / 627)*0.8 });
    }
  }

  switchSlide (index) {
    if (this.timeout) clearTimeout(this.timeout)
    const next = index == 1 ? 2 : 1
    this.setState({ slide: index})
    this.timeout = setTimeout(() => {
      this.switchSlide(next)
    }, this.delay)
  }

  beginTouch = e => {
    this.setState({ beginTouch: e.clientX || e.changedTouches[0].clientX, touched: true })
  }

  startTouch = e => {
    if (this.state.touched) {
      this.setState({ moved: true })
    }
  }

  endTouch = e => {
    const { beginTouch, slide, touched, moved } = this.state
    if (!touched && !moved) return
    const endTouch = e.clientX || e.changedTouches[0].clientX
    if (Math.abs(beginTouch - endTouch) < 50) return 
    if (beginTouch > endTouch && slide == 2) {
      this.switchSlide(1)
    }
    if (beginTouch < endTouch && slide == 1) {
      this.switchSlide(2)
    }
    this.setState({ touched: false, moved: false })
  }

  render() {
    const { intl } = this.props;
    const { ptMod, slide, touched } = this.state;
    return (
      <div className={`mobileapp fullcontainer${touched ? ' touched' : ''}`}
        onMouseDown={this.beginTouch}
        onMouseMove={this.startTouch}
        onMouseUp={this.endTouch}
        onMouseLeave={this.endTouch}
        onTouchStart={this.beginTouch}
        onTouchMove={this.startTouch}
        onTouchEnd={this.endTouch}
      >
        <h2>{intl.formatMessage({ id: 'LandingMobileApp', defaultMessage: 'Mobile app' })}</h2>
        <h3>{intl.formatMessage({ id: 'LandingMobileAppSubtitle', defaultMessage: 'How you see your opponent’s game' })}</h3>
        <div className={`background greenone${slide == 1 ? ' active' : ''}`}>
          <div>
            <div className='circleone' />
            <div className='circletwo' />
            <div className='circlethree' />
          </div>
        </div>
        <div className={`background cyanone${slide == 2 ? ' active' : ''}`}>
          <div>
            <div className='circleone' />
            <div className='circletwo' />
            <div className='circlethree' />
          </div>
        </div>
        <div className='slidewrap'>
          <div className={`phonebox ${slide == 2 ? 'active' : ''}`} style={{ padding: `${ptMod * 70}px 0` }}>
            <img src={phoneshowcasealt} />
            <div className='pointers' style={{ top: ptMod * 70 }}>
              <div className='pointerBox' style={{ top: ptMod * 50, right: `calc(50% + ${ptMod * 260}px)` }}>{intl.formatMessage({ id: 'LandingMobileBattles', defaultMessage: 'Battles' })}</div>
              <div className='ponterLine l b' style={{ top: ptMod * 50 + 58, left: `calc(50% - ${ptMod * 260}px)`, bottom: ptMod * 480, right: `calc(50% + ${ptMod * 100}px)` }} />
              <div className='pointerDot' style={{ bottom: ptMod * 475, right: `calc(50% + ${ptMod * 90}px)` }} />
              <div className='pointerBox' style={{ bottom: ptMod * 458, left: `calc(50% + ${ptMod * 215}px)` }}>{intl.formatMessage({ id: 'LandingMobileYourWallet', defaultMessage: 'Your wallet' })}</div>
              <div className='ponterLine r t' style={{ top: ptMod * 130, right: `calc(50% - ${ptMod * 215}px)`, bottom: ptMod * 458, left: `calc(50% + ${ptMod * 100}px)` }} />
              <div className='pointerDot' style={{ bottom: ptMod * 632, left: `calc(50% + ${ptMod * 95}px)` }} />
              <div className='pointerBox' style={{ bottom: ptMod * 218, right: `calc(50% + ${ptMod * 190}px)` }}>{intl.formatMessage({ id: 'LandingMobileTournaments', defaultMessage: 'Tournaments' })}</div>
              <div className='ponterLine r b' style={{ top: ptMod * 442.5, left: `calc(50% - ${ptMod * 190}px)`, bottom: ptMod * 218, right: `calc(50% + ${ptMod * 113.5}px)` }} />
              <div className='pointerDot' style={{ top: ptMod * 432.5, right: `calc(50% + ${ptMod * 107.5}px)` }} />
              <div className='pointerBox' style={{ top: ptMod * 580 - 58, left: `calc(50% + ${ptMod * 270}px)` }}>{intl.formatMessage({ id: 'LandingMobileChat', defaultMessage: 'Chat' })}</div>
              <div className='ponterLine l t' style={{ top: ptMod * 580, right: `calc(50% - ${ptMod * 270}px)`, bottom: ptMod * 115, left: `calc(50% - ${ptMod * 40}px)` }} />
              <div className='pointerDot' style={{ bottom: ptMod * 115, left: `calc(50% - ${ptMod * 45}px)` }} />
            </div>
            {/* for mobile */}
            <div className='mobile-pointers' style={{ top: ptMod * 70 }}>
              <div className='pointerBox' style={{ bottom: 'calc(100% - 1px)', right: `calc(50% + ${ptMod * 200}px)` }}>{intl.formatMessage({ id: 'LandingMobileBattles', defaultMessage: 'Battles' })}</div>
              <div className='ponterLine l b' style={{ top: ptMod * 0, left: `calc(50% - ${ptMod * 200}px)`, bottom: ptMod * 515, right: `calc(50% + ${ptMod * 70}px)` }} />
              <div className='pointerDot' style={{ bottom: ptMod * 513, right: `calc(50% + ${ptMod * 70}px)` }} />
              <div className='pointerBox' style={{ bottom: ptMod * 450, left: `calc(50% + ${ptMod * 165}px)` }}>{intl.formatMessage({ id: 'LandingMobileYourWallet', defaultMessage: 'Your wallet' })}</div>
              <div className='ponterLine r t' style={{ top: ptMod * 126, right: `calc(50% - ${ptMod * 165}px)`, bottom: ptMod * 450, left: `calc(50% + ${ptMod * 100}px)` }} />
              <div className='pointerDot' style={{ top: ptMod * 124, left: `calc(50% + ${ptMod * 97}px)` }} />
              <div className='pointerBox' style={{ bottom: ptMod * 341, right: `calc(50% + ${ptMod * 150}px)` }}>{intl.formatMessage({ id: 'LandingMobileTournaments', defaultMessage: 'Tournaments' })}</div>
              <div className='ponterLine r t' style={{ top: ptMod * 427, left: `calc(50% - ${ptMod * 150}px)`, bottom: ptMod * 308, right: `calc(50% + ${ptMod * 87}px)` }} />
              <div className='pointerDot' style={{ top: ptMod * 460, right: `calc(50% + ${ptMod * 85}px)` }} />
              <div className='pointerBox' style={{ top: Math.floor(ptMod * 580 - 59), left: `calc(50% + ${ptMod * 170}px)` }}>{intl.formatMessage({ id: 'LandingMobileChat', defaultMessage: 'Chat' })}</div>
              <div className='ponterLine l t' style={{ top: Math.floor(ptMod * 580), right: `calc(50% - ${ptMod * 170}px)`, bottom: Math.floor(ptMod * 115), left: `calc(50% - ${ptMod * 30}px)` }} />
              <div className='pointerDot' style={{ bottom: Math.floor(ptMod * 115), left: `calc(50% - ${ptMod * 30}px)` }} />
            </div>
          </div>

          <div className={`phonebox ${slide == 1 ? 'active' : ''}`} style={{ padding: `${ptMod * 70}px 0` }}>
            <img src={phoneshowcase} />
            <div className='pointers' style={{ top: ptMod * 70 }}>
              <div className='pointerBox' style={{ top: ptMod * 110 - 58, right: `calc(50% + ${ptMod * 285}px)` }}>{intl.formatMessage({ id: 'LandingMobileFriends', defaultMessage: 'Friends' })}</div>
              <div className='ponterLine l b' style={{ top: ptMod * 110, left: `calc(50% - ${ptMod * 285}px)`, bottom: ptMod * 440, right: `calc(50% + ${ptMod * 80}px)` }} />
              <div className='pointerDot' style={{ bottom: ptMod * 435, right: `calc(50% + ${ptMod * 80}px)` }} />
              <div className='pointerBox' style={{ top: ptMod * 190 - 58, left: `calc(50% + ${ptMod * 245}px)` }}>{intl.formatMessage({ id: 'LandingMobileArchive', defaultMessage: 'Archive' })}</div>
              <div className='ponterLine r b' style={{ top: ptMod * 190, right: `calc(50% - ${ptMod * 245}px)`, bottom: ptMod * 430, left: `calc(50% + ${ptMod * 100}px)` }} />
              <div className='pointerDot' style={{ bottom: ptMod * 425, left: `calc(50% + ${ptMod * 100}px)` }} />
              <div className='pointerBox' style={{ bottom: ptMod * 196, right: `calc(50% + ${ptMod * 140}px)` }}>{intl.formatMessage({ id: 'LandingMobileBowlingCenters', defaultMessage: 'Bowling centers' })}</div>
              <div className='ponterLine b r' style={{ top: ptMod * 455, left: `calc(50% - ${ptMod * 140}px)`, bottom: ptMod * 196, right: `calc(50% + ${ptMod * 50}px)` }} />
              <div className='pointerDot' style={{ top: ptMod * 450, right: `calc(50% + ${ptMod * 45}px)` }} />
              <div className='pointerBox' style={{ bottom: ptMod * 138, left: `calc(50% + ${ptMod * 240}px)` }}>{intl.formatMessage({ id: 'LandingMobileStatistics', defaultMessage: 'Statistics' })}</div>
              <div className='ponterLine l b' style={{ top: ptMod * 510, right: `calc(50% - ${ptMod * 240}px)`, bottom: ptMod * 138, left: `calc(50% + ${ptMod * 140}px)` }} />
              <div className='pointerDot' style={{ top: ptMod * 505, left: `calc(50% + ${ptMod * 135}px)` }} />
            </div>
            {/* for mobile */}
            <div className='mobile-pointers' style={{ top: ptMod * 70 }}>
              <div className='pointerBox' style={{ top: Math.floor(ptMod * 1 - 59), right: `calc(50% + ${Math.floor(ptMod * 230)}px)` }}>{intl.formatMessage({ id: 'LandingMobileFriends', defaultMessage: 'Friends' })}</div>
              <div className='ponterLine l b' style={{ top: Math.floor(ptMod * 2), left: `calc(50% - ${Math.floor(ptMod * 232)}px)`, bottom: Math.floor(ptMod * 440), right: `calc(50% + ${Math.floor(ptMod * 80)}px)` }} />
              <div className='pointerDot' style={{ bottom: Math.floor(ptMod * 438), right: `calc(50% + ${Math.floor(ptMod * 80)}px)` }} />
              <div className='pointerBox' style={{ top: Math.floor(ptMod * 190 - 58), left: `calc(50% + ${Math.floor(ptMod * 200)}px)` }}>{intl.formatMessage({ id: 'LandingMobileArchive', defaultMessage: 'Archive' })}</div>
              <div className='ponterLine r b' style={{ top: Math.floor(ptMod * 190), right: `calc(50% - ${Math.floor(ptMod * 200)}px)`, bottom: Math.floor(ptMod * 430), left: `calc(50% + ${Math.floor(ptMod * 100)}px)` }} />
              <div className='pointerDot' style={{ bottom: Math.floor(ptMod * 428), left: `calc(50% + ${Math.floor(ptMod * 100)}px)` }} />
              <div className='pointerBox' style={{ bottom: Math.floor(ptMod * 196), right: `calc(50% + ${Math.floor(ptMod * 95)}px)` }}>{intl.formatMessage({ id: 'LandingMobileBowlingCenters', defaultMessage: 'Bowling centers' })}</div>
              <div className='ponterLine b r' style={{ top: Math.floor(ptMod * 455), left: `calc(50% - ${Math.floor(ptMod * 95)}px)`, bottom: Math.floor(ptMod * 196), right: `calc(50% + ${Math.floor(ptMod * 50)}px)` }} />
              <div className='pointerDot' style={{ top: Math.floor(ptMod * 450), right: `calc(50% + ${Math.floor(ptMod * 50) - 2}px)` }} />
              <div className='pointerBox' style={{ bottom: Math.floor(ptMod * 138), left: `calc(50% + ${Math.floor(ptMod * 200)}px)` }}>{intl.formatMessage({ id: 'LandingMobileStatistics', defaultMessage: 'Statistics' })}</div>
              <div className='ponterLine l b' style={{ top: Math.floor(ptMod * 510), right: `calc(50% - ${Math.floor(ptMod * 200)}px)`, bottom: Math.floor(ptMod * 138), left: `calc(50% + ${Math.floor(ptMod * 140)}px)` }} />
              <div className='pointerDot' style={{ top: Math.floor(ptMod * 505), left: `calc(50% + ${Math.floor(ptMod * 140 - 1)}px)` }} />
            </div>
          </div>
        </div>
        <div className="switcher">
          <div className={`switch${slide == 2 ? ' active' : ''}`} onClick={() => { this.switchSlide(2) }}>
            <div className="fill" style={slide == 2 ? { animation: `fillbg ${this.delay}ms linear` } : {}}></div>
          </div>
          <div className={`switch${slide == 1 ? ' active' : ''}`} onClick={() => { this.switchSlide(1) }}>
            <div className="fill" style={slide == 1 ? { animation: `fillbg ${this.delay}ms linear` } : {}}></div>
          </div>
        </div>
      </div>
    )
  }
}