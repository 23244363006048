import React  from 'react';
import CheckBox from '../../../../common/Checkbox'
import '../../syle.scss'

const CheckBoxTD = ({checked, onChange}) => {

    return (
        <div className={'referral-table__check-td'}>
            <CheckBox
                onChange={onChange}
                checked={checked}
            />
        </div>
    );
};

export default CheckBoxTD;
