import './style.scss'
import React from 'react';
import { Button } from '../Button';
import Checkbox from '../Checkbox';

import OBModal from '../Modal';

export default class PillBoxHost extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			val: {},
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.values !== prevProps.values) {
			this.setState({ val: this.props.values.reduce((acc, val) => ({ ...acc, [val.value]: true }), {})});
		}
	}

	render() {
		const {
			onChange,
			visible,
			options,
			onClose,
			title,
		} = this.props
		return (
		<OBModal
			title={title||'Select elements'}
			visible={visible}
			closable={true}
			onCancel={() => onClose()}
			footer={
			<Button
				key="okButton"
				kind="aquamarine"
				onClick={() => {
					onChange(Object.keys(this.state.val).filter(val => this.state.val[val]));
					onClose();
				}}>
				Save
			</Button>}
			>
			<div className='pillBoxOptions' style={{ padding: '20px 0' }}>
				{options.map(option => <>
					<Checkbox checked={this.state.val[option.value]} onChange={newval => this.setState({ val: {...this.state.val, [option.value]: newval } })}>{option.label}</Checkbox>
				</>)}
			</div>
		</OBModal>
		);
	}
}