/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { connect } from 'react-redux';
import Select from '../Select';
import OBDatePicker from '../DatePicker';
import TextInput from './fragments/text-input.fragment.jsx';

/**
 * @typedef {{
 *  countryId?: number;
 *  onChange?: (countryId: number) => any;
 *  error?: boolean;
 *  countries: Array<{ id: number; name: string }>;
 *  intl?: any;
 * }} CountrySelectorProps
 */
/**
 * @type {React.FC<CountrySelectorProps>}
 */
const CountrySelector = props => {
  const {
    intl, countryId, onChange, error, countries,
  } = props;
  const errorMessage = intl.formatMessage({
    id: 'IBANEmptyFieldError',
    defaultMessage: 'Must be filled',
  });
  const labelText = intl.formatMessage({
    id: 'BowlingcenterFilterByCountry',
    defaultMessage: 'Country',
  });
  const placeholder = intl.formatMessage({
    id: 'BowlingcenterFilterByCountry',
    defaultMessage: 'Country',
  });
  const options = countries.map(c => ({ value: c.id, label: c.name }));
  return (
    <>
      <label>{labelText}</label>
      <Select
        isSearchable
        value={
          countryId
            ? { value: countryId, label: countries.find(c => c.id === countryId).name }
            : null
        }
        placeholder={placeholder}
        customStyle={{
          container: base => ({ position: 'relative' }),
          menu: base => ({ position: 'fixed', zIndex: 999999 }),
        }}
        onChange={item => onChange && onChange(item.value)}
        options={options}
      />
      {error ? <p className="error">{errorMessage}</p> : null}
    </>
  );
};

/**
 * @typedef {{
 *  value?: string | Date;
 *  onChange?: (value: string | Date) => any;
 *  error?: boolean;
 *  intl?: any;
 * }} DobPickerProps
 *
 */

/**
 * @type {React.FC<DobPickerProps>}
 */
const DobPicker = props => {
  const {
    intl, value, onChange, error,
  } = props;
  const labelText = intl.formatMessage({
    id: 'PersonalInfoDOB',
    defaultMessage: 'Date of birth',
  });
  const placeholder = intl.formatMessage({
    id: 'PersonalInfoDOB',
    defaultMessage: 'Date of birth',
  });
  const errorMessage = intl.formatMessage({
    id: 'IBANEmptyFieldError',
    defaultMessage: 'Must be filled',
  });
  return (
    <>
      <label>{labelText}</label>
      <OBDatePicker
        dateFormat="D MMM YYYY"
        className="date-group__elements__picker"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabledDate={current => current && current > moment()}
      />
      {error ? <p className="error">{errorMessage}</p> : null}
    </>
  );
};

/**
 * @typedef {{
 *  firstName?: string;
 *  lastName?: string;
 *  countryId?: number;
 *  dob?: string | Date;
 *  personalId?: string;
 *  province?: string;
 *  postcode?: string;
 *  address?: string;
 * }} IPersonalData
 */
/**
 * @typedef {{ [K in keyof IPersonalData]: string }} IPersonalDataErrors
 */
/**
 * @typedef {{
 *  onChange?: (data: IPersonalData) => any;
 *  onChangeProp?: (name: keyof IPersonalData, value: IPersonalData[typeof name], data: IPersonalData) => any;
 *  errors?: IPersonalDataErrors;
 *  setRef?: (ref: { reset: () => any }) => any;
 * } & IPersonalData} PersonalDataAddFragmentProps
 */
/**
 * @type {React.FC<PersonalDataAddFragmentProps>}
 */
const PersonalDataAddFragmentFn = props => {
  const {
    intl, errors, onChange, onChangeProp, setRef,
  } = props;
  /** @type {Array<{ id: number; name: string }>} */
  const countries = props.countries || [];
  const [firstName, setFirstName] = useState(props.firstName);
  const [lastName, setLastName] = useState(props.lastName);
  const [countryId, setCountryId] = useState(props.countryId);
  const [dob, setDob] = useState(props.dob);
  const [personalId, setPersonalId] = useState(props.personalId);
  const [province, setProvince] = useState(props.province);
  const [postcode, setPostcode] = useState(props.postcode);
  const [address, setAddress] = useState(props.address);
  const reset = () => {
    setFirstName('');
    setLastName('');
    setCountryId('');
    setDob('');
    setPersonalId('');
    setPostcode('');
    setProvince('');
    setAddress('');
  };
  const ref = useRef({ reset });
  if (typeof setRef === 'function') {
    setRef(ref.current);
  }
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;
  const onChangePropRef = useRef(onChangeProp);
  onChangePropRef.current = onChangeProp;
  useEffect(() => {
    if (onChangeRef.current) {
      onChangeRef.current({
        firstName,
        lastName,
        countryId,
        dob,
        personalId,
        province,
        postcode,
        address,
      });
    }
  }, [
    firstName,
    lastName,
    countryId,
    dob,
    personalId,
    province,
    postcode,
    address,
  ]);
  const data = {
    firstName, lastName, countryId, dob, personalId, province, postcode, address,
  };
  useEffect(() => {
    if (onChangePropRef.current) {
      onChangePropRef.current('firstName', firstName, data);
    }
  }, [firstName]);
  useEffect(() => {
    if (onChangePropRef.current) {
      onChangePropRef.current('lastName', lastName, data);
    }
  }, [lastName]);
  useEffect(() => {
    if (onChangePropRef.current) {
      onChangePropRef.current('countryId', countryId, data);
    }
  }, [countryId]);
  useEffect(() => {
    if (onChangePropRef.current) {
      onChangePropRef.current('dob', dob, data);
    }
  }, [dob]);
  useEffect(() => {
    if (onChangePropRef.current) {
      onChangePropRef.current('personalId', personalId, data);
    }
  }, [personalId]);
  useEffect(() => {
    if (onChangePropRef.current) {
      onChangePropRef.current('province', province, data);
    }
  }, [province]);
  useEffect(() => {
    if (onChangePropRef.current) {
      onChangePropRef.current('postcode', postcode, data);
    }
  }, [postcode]);
  useEffect(() => {
    if (onChangePropRef.current) {
      onChangePropRef.current('address', address, data);
    }
  }, [address]);
  const country = countryId ? countries.find(c => c.id === countryId) : null;
  return (
    <div className="IBANmodal">
      <TextInput
        label={intl.formatMessage({ id: 'Name', defaultMessage: 'Name' })}
        value={firstName}
        onChange={setFirstName}
        error={errors && errors.firstName}
        intl={intl}
      />
      <TextInput
        label={intl.formatMessage({ id: 'Surname', defaultMessage: 'Surname' })}
        value={lastName}
        onChange={setLastName}
        error={errors && errors.lastName}
        intl={intl}
      />
      <div className="IBANinput double">
        <div className="piece">
          <CountrySelector
            countryId={countryId}
            onChange={setCountryId}
            countries={countries}
            error={errors && errors.countryId}
            intl={intl}
          />
        </div>
        <div className="piece">
          <DobPicker
            value={dob}
            onChange={setDob}
            error={errors && errors.dob}
            intl={intl}
          />
        </div>
      </div>
      {country && country.name === 'Latvia' ? (
        <TextInput
          label={intl.formatMessage({ id: 'personalid', defaultMessage: 'Personal ID' })}
          value={personalId}
          onChange={setPersonalId}
          error={errors && errors.personalId}
          intl={intl}
        />
      ) : null}
      {country && country.name !== 'Latvia' ? (
        <>
          <div className="IBANinput double">
            <div className="piece">
              <TextInput
                label={intl.formatMessage({ id: 'province', defaultMessage: 'State/Province' })}
                value={province}
                onChange={setProvince}
                error={errors && errors.province}
                intl={intl}
              />
            </div>
            <div className="piece">
              <TextInput
                label={intl.formatMessage({ id: 'Postcode', defaultMessage: 'Postcode' })}
                value={postcode}
                onChange={setPostcode}
                error={errors && errors.postcode}
                intl={intl}
              />
            </div>
          </div>
          <TextInput
            label={intl.formatMessage({ id: 'TitleAddressBowlingCenterList', defaultMessage: 'Address' })}
            value={address}
            onChange={setAddress}
            error={errors && errors.address}
            intl={intl}
          />
        </>
      ) : null}
    </div>
  );
};

class PersonalDataAddFragmentClass extends React.Component {
  render() {
    return <PersonalDataAddFragmentFn {...this.props} />;
  }
}

/** @type {typeof PersonalDataAddFragmentFn} */
const PersonalDataAddFragment = injectIntl(
  connect(
    state => ({
      countries: (state.common.geodata || {}).data || [],
    }),
    {},
  )(PersonalDataAddFragmentClass),
);
export default PersonalDataAddFragment;
