import { call, put, select } from "redux-saga/effects";
import _ from "lodash";
import config from "../../config";
import { get, post, put as putMethod } from "../Networking";
import { resultBCBanners } from "../reducers/adminReducer";

const { SETTINGS_FETCH } = require("../reducers/commonReducer");

const {
  GET_ALIKE_USER_SET,
  GET_CURRENCY_LIST_SET,
  SET_TRANSACTION_RESPONSE,
  SET_TRANSACTION_HISTORY,
  SET_TRANSACTION_DIFF,
  GET_ALL_USERS_SET,
  SET_BATTLES_LIST,
  SET_EDITED_USER,
  GET_SYSTEM_ACCOUNTS,
  SET_SYSTEM_ACCOUNTS,
  SET_IBAN_REQUESTS,
  GET_USER_DOCS_ALL_SET,
  GET_USER_DOC_BY_ID_SET,
  REPORT_NOTIFICATION_UPDATE,
  REPORT_NOTIFICATION_CLEAR,
  SET_SEARCH_USER_TARIFFS,
  PROCESS_SEARCH,
  PROCESS_SET_TARIFFS,
  SET_NOTIFICATION_EVENT,
  RESULT_MAIN_BANNERS,
  SET_ADMIN_DISK_SIZE,
  GET_ANALYTICS_BOWLING_CENTER,
  payseraRequestsFailureAction,
  payseraRequestsSuccessAction,
  payseraRequestsUpdateFailureAction,
  payseraRequestsUpdateSuccessAction,
  UPLOAD_BC_ADMIN_BANNERS,
  RESULT_BC_ADMIN_BANNERS,
  getBCAdminBanners,
  getSubscribersAction,
  setSubscribersAction,
} = require("../reducers/adminReducer");

export function* payseraRequestsRequestSaga(action) {
  try {
    const res = yield call(get, { url: `${config.FETCH.url}/paysera/request` });
    if (res.status === 200 && res.data.success) {
      yield put(payseraRequestsSuccessAction(res.data.data));
    } else {
      yield put(payseraRequestsFailureAction());
    }
  } catch (e) {
    yield put(payseraRequestsFailureAction(e.message));
  }
}

export function* payseraRequestsUpdateRequestSaga(action) {
  try {
    const { params } = action;
    const res = yield call(putMethod, {
      url: `${config.FETCH.url}/paysera/request/${params.id}`,
      data: params,
    });
    if (res.status === 200 && res.data.success) {
      yield put(payseraRequestsUpdateSuccessAction(res.data.data));
    } else {
      yield put(payseraRequestsUpdateFailureAction());
    }
  } catch (e) {
    yield put(payseraRequestsUpdateFailureAction(e.message));
  }
}

export function* _UpdateIBANrequest({ params }) {
  try {
    const result = yield call(post, {
      url: `${config.FETCH.url}/billing/iban_update`,
      data: params,
    });
    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_IBAN_REQUESTS, params: result.data.data });
    } else {
      yield put({ type: SET_IBAN_REQUESTS, params: [] });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* _GetIBANrequests() {
  try {
    const result = yield call(get, {
      url: `${config.FETCH.url}/billing/iban_requests`,
    });
    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_IBAN_REQUESTS, params: result.data.data });
    } else {
      yield put({ type: SET_IBAN_REQUESTS, params: [] });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* _getTransactionHistory({ params }) {
  try {
    const result = yield call(get, {
      url: `${config.FETCH.url}/billing/history/${params.value}`,
    });
    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_TRANSACTION_HISTORY, params: result.data.result });
    } else {
      yield put({ type: SET_TRANSACTION_HISTORY, params: [] });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* _getSystemAccounts() {
  try {
    const result = yield call(get, {
      url: `${config.FETCH.url}/billing/system_accounts`,
    });
    if (result.status === 200 && result.data.success) {
      delete result.data.success;
      yield put({ type: SET_SYSTEM_ACCOUNTS, params: result.data });
    } else {
      yield put({
        type: SET_SYSTEM_ACCOUNTS,
        params: { history: [], data: [] },
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* _requestTransfer({ params }) {
  try {
    const result = yield call(post, {
      url: `${config.FETCH.url}/billing/currency_transfer`,
      data: params,
    });
    if (result.status === 200 && result.data.success) {
      yield put({ type: GET_SYSTEM_ACCOUNTS });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* _getTransactionDiff({ params }) {
  try {
    const result = yield call(post, {
      url: `${config.FETCH.url}/billing/history`,
      data: params,
    });
    if (result.status === 200 && result.data.success) {
      delete result.data.success;
      yield put({ type: SET_TRANSACTION_DIFF, params: result.data });
    } else {
      yield put({ type: SET_TRANSACTION_DIFF, params: {} });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* _getAllUsers() {
  try {
    const result = yield call(get, {
      url: `${config.FETCH.url}/user/admin/getall`,
    });
    if (result.status === 200 && result.data.success) {
      yield put({
        type: GET_ALL_USERS_SET,
        params: {
          users: result.data.users,
          permissions: result.data.permissions,
          tariffs: result.data.tariffs,
          groups: result.data.groups,
          bowlingcenters: result.data.bowlingcenters,
        },
      });
    } else {
      yield put({
        type: GET_ALL_USERS_SET,
        params: {
          users: [],
          permissions: [],
          tariffs: [],
          groups: [],
          bowlingcenters: [],
        },
      });
    }
  } catch (error) {
    console.error(error);
  }
}

// export function* _getAllBattles() {
export function* _getBattlesList({ params }) {
  try {
    yield put({ type: PROCESS_SEARCH, params: true });

    const result = yield call(post, {
      url: `${config.FETCH.url}/battle/admin/list`,
      data: params,
    });
    if (result.status === 200 && result.data.success) {
      const { battles, count } = result.data;

      yield put({ type: SET_BATTLES_LIST, params: { battles, count } });
    } else {
      yield put({ type: SET_BATTLES_LIST, params: { battles: [], count: 0 } });
    }
    yield put({ type: PROCESS_SEARCH, params: false });
  } catch (error) {
    console.error("Error", error.message);
    yield put({ type: PROCESS_SEARCH, params: false });
  }
}

export function* _AlikeUsersGet({ params }) {
  try {
    if (!params) {
      params = "-";
    }
    const result = yield call(get, {
      url: `${config.FETCH.url}/user/alike/${params}`,
    });
    if (result.status === 200 && result.data.success) {
      yield put({ type: GET_ALIKE_USER_SET, params: result.data });
    } else {
      yield put({ type: GET_ALIKE_USER_SET, params: [] });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* _EditUserAsAdmin({ params }) {
  try {
    const reqbody = { ...params };
    delete reqbody.userid;
    const result = yield call(putMethod, {
      url: `${config.FETCH.url}/user/admin/edit/${params.userid}`,
      data: reqbody,
    });
    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_EDITED_USER, params: result.data.user });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* _CurrencyListGet() {
  try {
    const result = yield call(get, {
      url: `${config.FETCH.url}/billing/currencies`,
    });
    if (result.status === 200) {
      yield put({ type: GET_CURRENCY_LIST_SET, params: result.data });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* _ManualTransaction({ params }) {
  try {
    const result = yield call(post, {
      url: `${config.FETCH.url}/billing/manualtransaction`,
      data: params,
    });
    if (result.status === 200) {
      yield put({
        type: SET_TRANSACTION_RESPONSE,
        params: result.data.success
          ? ["Success"]
          : result.data.errorcode
          ? [result.data.errorcode]
          : [result.data.error],
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* _getAllUserDocs() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/userdocs/all` });
    if (result.status === 200) {
      yield put({ type: GET_USER_DOCS_ALL_SET, params: result.data });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* _getUserDocById({ params }) {
  try {
    const result = yield call(get, {
      url: `${config.FETCH.url}/userdocs/${params}`,
    });
    if (result.status === 200) {
      yield put({ type: GET_USER_DOC_BY_ID_SET, params: result.data });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* _acceptDocVerification({ params }) {
  try {
    const result = yield call(post, {
      url: `${config.FETCH.url}/userdocs/accept`,
      data: params,
    });
    if (result.status === 200) {
      yield call(_getAllUserDocs);
    }
  } catch (error) {
    console.error(error);
  }
}

export function* _refuseDocVerification({ params }) {
  try {
    const result = yield call(post, {
      url: `${config.FETCH.url}/userdocs/refuse`,
      data: params,
    });
    if (result.status === 200) {
      yield call(_getAllUserDocs);
    }
  } catch (error) {
    console.error(error);
  }
}

export function* _sendNotificationUpdate({ params }) {
  try {
    yield put({ type: REPORT_NOTIFICATION_CLEAR });
    const result = yield call(post, {
      url: `${config.FETCH.url}/settings/get_users_update`,
      data: params,
    });

    if (result.status === 200 && result.data.success) {
      yield put({
        type: REPORT_NOTIFICATION_UPDATE,
        params: { ...result.data, send: 1 },
      });
    } else {
      yield put({ type: REPORT_NOTIFICATION_UPDATE, params: { send: 0 } });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* _getSearchUserTariffs({ params }) {
  try {
    yield put({ type: PROCESS_SEARCH, params: true });

    const result = yield call(post, {
      url: `${config.FETCH.url}/user/tariff/search`,
      data: params,
    });
    const data = result.data.data;

    if (result.status === 200 && result.data.success) {
      yield put({
        type: SET_SEARCH_USER_TARIFFS,
        params: { tariffs: data.tariffs, count: data.count },
      });
    } else {
      yield put({
        type: SET_SEARCH_USER_TARIFFS,
        params: { tariffs: [], count: 0 },
      });
    }
  } catch (error) {
    console.error("Error. ", error);
  } finally {
    yield put({ type: PROCESS_SEARCH, params: false });
  }
}

export function* _setTariffByUser({ params }) {
  try {
    yield put({ type: PROCESS_SET_TARIFFS, params: true });
    yield call(post, {
      url: `${config.FETCH.url}/user/tariff/edit`,
      data: params,
    });
  } catch (error) {
    console.error("Error. _setTariffByUser ", error);
  } finally {
    yield put({ type: PROCESS_SET_TARIFFS, params: false });
  }
}

export function* _sendNotificationEvent({ params }) {
  try {
    const result = yield call(post, {
      url: `${config.FETCH.url}/notifications/send_event`,
      data: params,
    });
    if (result.status === 200 && result.data.success) {
      const { report } = result.data || {};
      yield put({
        type: SET_NOTIFICATION_EVENT,
        params: { data: { report: report || {} }, error: null },
      });
    } else {
      yield put({
        type: SET_NOTIFICATION_EVENT,
        params: { data: null, error: result.data.error },
      });
    }
  } catch (error) {
    console.error("Send notification event error: ", error);
  }
}

export function* _uploadMainBanners({ params }) {
  try {
    yield put({ type: RESULT_MAIN_BANNERS, params: {} });

    const urlUpload = `${config.FETCH.url}/file/upload_banners`;
    const urlSave = `${config.FETCH.url}/file/save_banners`;
    let newFiles = [];
    let result = {};

    if (params.base64 && params.base64.length) {
      const resultUpload = yield call(post, {
        url: urlUpload,
        data: { files: params.base64 },
      });

      if (resultUpload.data.error) {
        result["error"] = resultUpload.data.error;
      }

      if (resultUpload.status === 200 && resultUpload.data.success) {
        newFiles = resultUpload.data.result;
      }
    }

    const resultSave = yield call(post, {
      url: urlSave,
      data: {
        banner: params.banner,
        timer: params.timer,
        files: [...params.files, ...newFiles],
      },
    });

    yield put({
      type: RESULT_MAIN_BANNERS,
      params: { ...resultSave.data, ...result },
    });
  } catch (error) {
    console.error("Error. При загрузке Баннеров произошла ошибка. ", error);
  }
}
export function* _getBCBanners({ params }) {
  try {
    const url = `${config.FETCH.url}/file/get_bc_banners`;
    const result = yield call(post, { url: url, data: { ...params } });
    const bcBanners = result.data.result;

    const imgList = (bcBanners || []).map((img, idx) => {
      const name = img.image.match(/(banners\/)([\w-]+)(.\w+)/)[2];

      return {
        uid: "-" + (idx + 1),
        name,
        link: img.link,
        clickable: !!img.link,
        linkType: img.link_type,
        appScreen: img.appScreen,
        appScreenNavOpt: img.appScreenNavOpt,
        navigationUrl: img.navigationUrl,
        weight: img.weight,
        status: "done",
        url: `${config.FETCH.img}/${img.image}`,
        bc_name: img.bc_name,
        lastUpdated: img.lastupdated,
        creator: { ...img.creator },
        moderation_agreed: img.agreed,
      };
    });

    yield put({ type: RESULT_BC_ADMIN_BANNERS, params: imgList });
  } catch (e) {
    console.error("_getBCBanners Error: ", e);
  }
}

export function* _uploadBCBanners({ params }) {
  try {
    // yield put({type: RESULT_BC_ADMIN_BANNERS, params: []})
    const urlUpload = `${config.FETCH.url}/file/upload_bc_banners`;
    const urlSave = `${config.FETCH.url}/file/save_bc_banners`;
    let newFiles = [];
    let result = {};
    const bowlingCenters = params.bowlingCenters;
    if (params.base64 && params.base64.length) {
      const resultUpload = yield call(post, {
        url: urlUpload,
        data: { files: params.base64 },
      });

      if (resultUpload.data.error) {
        result["error"] = resultUpload.data.error;
      }

      if (resultUpload.status === 200 && resultUpload.data.success) {
        newFiles = resultUpload.data.result;
      }
    }
    const resultSave = yield call(post, {
      url: urlSave,
      data: {
        files: [...params.files, ...newFiles],
      },
    });

    if (resultSave.data.success && bowlingCenters) {
      yield call(_getBCBanners, { params: { bowlingCenters: bowlingCenters } });
    }
  } catch (error) {
    console.error("Error. При загрузке Баннеров произошла ошибка. ", error);
  }
}

export function* _publicationBCBanners({ params }) {
  try {
    const url = `${config.FETCH.url}/file/publication_bc_banner`;

    if (params) {
      let result = yield call(post, { url: url, data: { ...params } });

      if (result.data.success) {
        yield call(_getBCBanners, {});
      }
    }
  } catch (e) {
    console.error("_publicationBCBanners Error: ", e);
  }
}

export function* _uploadPoster({ params }) {
  try {
    yield put({ type: RESULT_MAIN_BANNERS, params: {} });
    const url = `${config.FETCH.url}/file/upload_poster`;
    const resultUpload = yield call(post, {
      url,
      data: { file: params.base64 },
    });
    if (resultUpload.status === 200 && resultUpload.data.success) {
      yield put({
        type: RESULT_MAIN_BANNERS,
        params: { success: resultUpload.data.success },
      });
    }
  } catch (error) {
    console.error("Error. При загрузке Баннеров произошла ошибка. ", error);
  }
}

export function* _getAdminDiskSize() {
  try {
    const result = yield call(get, {
      url: `${config.FETCH.url}/videofiles/admin/size_disk`,
    });

    if (result.status === 200 && result.data.success) {
      const size = result.data;

      yield put({ type: SET_ADMIN_DISK_SIZE, params: size });
    } else {
      yield put({ type: SET_ADMIN_DISK_SIZE, params: 0 });
    }
  } catch (error) {
    console.error("Error. ", error);
    yield put({ type: SET_ADMIN_DISK_SIZE, params: 0 });
  }
}

export function* _getAnalyticsBowlingCenter({ params }) {
  try {
    const result = yield call(get, {
      url: `${config.FETCH.url}/bowlingcenter/analyticsbc`,
      params: params,
    });
    const data = result.data.data;
    if (result.status === 200 && result.data.success) {
      yield put({ type: GET_ANALYTICS_BOWLING_CENTER, params: data });
    }
  } catch (error) {
    console.error("Error. ", error);
  }
}
function flattenTime(time) {
  const date = new Date(time);
  date.setUTCHours(0);
  date.setUTCMinutes(0);
  date.setUTCSeconds(0);
  return date;
}

function checkValidParams(params) {
  const newParams = { ...params };
  const objKeys = Object.keys(newParams);
  objKeys.forEach((key) => {
    if (!newParams[key]) {
      delete newParams[key];
    }
  });

  if (newParams.sort_by) {
    let sortField = newParams.sort_by.split(".")[0];
    const sortMethod = newParams.sort_by.split(".")[1];
    if (sortField === "username") {
      sortField = "firstname";
      return { ...newParams, sort_by: `${sortField}.${sortMethod}` };
    }
  }

  const paramsCount = Object.keys(newParams)
    .filter((key) => key !== "sort_by").length;
  if (!paramsCount) {
    return { ...newParams, expires_after: flattenTime(new Date()) };
  }

  return newParams;
}

export function* _getSubscribers() {
  const limit = 20;
  const page = yield select((state) => state.admin.subscribers.page);
  const fetch_params = yield select(
    (state) => state.admin.subscribers.fetch_params
  );
  const offset = limit * (page - 1);
  let validParams = {};
  if (fetch_params) {
    validParams = checkValidParams(fetch_params);
  }
  // console.log(validParams);
  try {
    const result = yield call(get, {
      url: `${config.FETCH.url}/recurrings`,
      params: { limit, offset, ...validParams },
    });
    const data = result.data.data;
    yield put(setSubscribersAction(data));
  } catch (error) {
    console.error("Error: ", error);
  }
}

export function* _changeTariffFetch({ params }) {
  try {
    const result = yield call(post, {
      url: `${config.FETCH.url}/user/tariff/edit`,
      data: { ...params },
    });
  } catch (error) {
    console.error("Error: ", error);
  }
}
