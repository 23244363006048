/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import moment from 'moment';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import { Button } from '../common/Button';
import MatchScore from '../matchScore';
import GameScore from '../common/GameScore';
import VideoArchivePlayer from './player';
import { getVideoArchive, ChangeLang } from '../../libs/reducers/actions';
import VideoController from './controller';
import ChatMessage from './message';
import novideo from './no-video.png';
import notexists from './removed-video.png';
import ModalSaveBattle from '../common/modals/modalSaveBattle';
import Flag from '../common/Flag';

import './style.scss';
import '../../../node_modules/video-react/dist/video-react.css';

class VideoArchivePage extends React.Component {
  constructor(props) {
    super(props);

    this.videoplayers = [];

    this.state = {
      data: null,
      prevGame: null,
      currentGame: null,
      gameTime: null,
      nextGame: null,
      canPlay: true,
      dataFetching: false,
      dataLoaded: false,
      needSeekPlayers: false,
      videoPlayersState: [],
      videoPlayersBinded: false,
      videoPlayersReady: false,
      videoPlayersSeeking: false,
      videoOfCurrentPlayer: true,
      videoPlayersMuted: false,
      videoPlayersWaiting: false,
      showModalSaveBattle: false,
      offset: 0,
    };
  }

  componentDidMount() {
    const { squadid } = this.props.videoarchive;
    if (!squadid) {
      this.props.history.goBack();
    } else {
      this.props.getVideoArchive({ squadid });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const nextProps = this.props;
    const nextState = this.state;
    if (
      !nextState.dataFetching 
      && nextProps.videoarchive.isFetching
    ) {
      this.setState({ dataFetching: true });
    }
    if (
      prevState.dataFetching &&
      prevProps.videoarchive.isFetching &&
      !nextProps.videoarchive.isFetching &&
      !prevState.dataLoaded
    ) {
      const { data } = nextProps.videoarchive;
      const videoPlayersState = [
        {
          exists: !!data.streams.current.scoring && data.streams.current.scoring.saved,
          ready: false,
          width: 0,
          height: 0,
          ratio: 1.777777777777778
        },
        {
          exists: !!data.streams.current.skittles && data.streams.current.skittles.saved,
          ready: false,
          width: 0,
          height: 0,
          ratio: 1.777777777777778
        },
        {
          exists: !!data.streams.current.track && data.streams.current.track.saved,
          ready: false,
          width: 0,
          height: 0,
          ratio: 1.777777777777778
        }
      ];
      if (data.streams.current.skittles2) {
        videoPlayersState.push({
          exists: !!data.streams.current.skittles2 && data.streams.current.skittles2.saved,
          ready: false,
          width: 0,
          height: 0,
          ratio: 1.777777777777778
        })
      }
      const gameTime = +new Date(data.games[0].gamestarted);
      this.setState({ 
        dataFetching: false,
        dataLoaded: true,
        data,
        currentGame: 0,
        prevGame: null,
        nextGame: data.games[1] ? 1 : null,
        videoPlayersState,
        gameTime
      });
    }
    if (nextState.dataLoaded) {
      if (
        (
          prevState.dataFetching &&
          !nextState.dataFetching &&
          !prevState.dataLoaded &&
          nextState.dataLoaded &&
          !prevState.videoPlayersBinded
        ) || (
          prevState.videoPlayersBinded &&
          !nextState.videoPlayersBinded
        )
      ) {
        this.bindPlayers();
      }
      if (
        prevState.videoOfCurrentPlayer !== nextState.videoOfCurrentPlayer &&
        prevState.videoPlayersBinded && !nextState.videoPlayersBinded
      ) {
        this.bindPlayers();
      }
      if (
        prevState.videoPlayersBinded &&
        !prevState.videoPlayersReady &&
        !nextState.videoPlayersReady &&
        nextState.videoPlayersState.every((nextStatePlayer, index) => {
          return nextStatePlayer.ready || !prevState.videoPlayersState[index].exists;
        })
      ) {
        this.setState({ videoPlayersReady: true });
      }
      if (!prevState.videoPlayersWaiting && !prevState.videoPlayersReady && nextState.videoPlayersReady) {
        this.play();
      }
      if (
        prevState.videoPlayersSeeking &&
        nextState.videoPlayersSeeking &&
        nextState.videoPlayersState.every((nextStatePlayer, index) => {
          return nextStatePlayer.ready || !prevState.videoPlayersState[index].exists;
        })
      ) {
        this.setState({ videoPlayersSeeking: false });
      }
      if (
        !prevState.videoPlayersWaiting && 
        prevState.videoPlayersSeeking &&
        !nextState.videoPlayersSeeking
      ) {
        this.play();
      }
      if (
        prevState.videoPlayersWaiting && 
        !nextState.videoPlayersWaiting && 
        !nextState.videoPlayersSeeking
      ) {
        this.play();
      }
      if (prevState.currentGame != null && prevState.currentGame !== nextState.currentGame) {
        this.onChangeTimer(0, true, false);
      }
    }
  }

  bindPlayers () {
    for (let i = 0; i < this.videoplayers.length; i += 1) {
      if (this.state.videoPlayersState.length && 
        this.state.videoPlayersState[i] && 
        this.state.videoPlayersState[i].exists) {
          this.videoplayers[i].subscribeToStateChange(this.playerStateChangeHandler.bind(this, i));
      }
    }
    this.setState({ videoPlayersBinded: true });
  }

  playerStateChangeHandler(index, state, prevState) {
    if (state.duration !== prevState.duration && state.duration) {
      this.seek(index);
    }
    if (state.readyState === 4 && prevState.readyState < 4) {
      const _state = { ...this.state };
      _state.videoPlayersState[index].ready = true;
      _state.videoPlayersState[index].ratio = state.videoWidth / state.videoHeight;
      this.setState({ ..._state });
    }
    if (!state.seeking && prevState.seeking) {
      const _state = { ...this.state };
      _state.videoPlayersState[index].ready = true;
      this.setState({ ..._state });
    }
    if (!prevState.seeking && state.seeking || !prevState.waiting && state.waiting) {
      const _state = { ...this.state };
      _state.videoPlayersState[index].ready = false;
      if (this.controller) {
        this.controller.stopTimer();
      }
      this.setState({ ..._state, videoPlayersReady: false });
    }
  }

  switchVideo() {
    if (this.controller) {
      this.controller.stopTimer();
    }
    const { streams } = this.state.data;
    const { videoOfCurrentPlayer } = this.state;
    const videoPlayersState = [
      {
        exists: videoOfCurrentPlayer 
          ? !!streams.opponent.scoring && streams.opponent.scoring.saved 
          : !!streams.current.scoring && streams.current.scoring.saved,
        ready: false,
        width: 0,
        height: 0,
        ratio: 1.777777777777778,
      },
      {
        exists: videoOfCurrentPlayer 
          ? !!streams.opponent.skittles && streams.opponent.skittles.saved
          : !!streams.current.skittles && streams.current.skittles.saved,
        ready: false,
        width: 0,
        height: 0,
        ratio: 1.777777777777778,
      },
      {
        exists: videoOfCurrentPlayer 
          ? !!streams.opponent.track && streams.opponent.track.saved 
          : !!streams.current.track && streams.current.track.saved,
        ready: false,
        width: 0,
        height: 0,
        ratio: 1.777777777777778,
      }
    ];
    if (streams.opponent.skittles2 || streams.current.skittles2) {
      videoPlayersState.push({
        exists: videoOfCurrentPlayer 
          ? !!streams.opponent.skittles2 && streams.opponent.skittles2.saved
          : !!streams.current.skittles2 && streams.current.skittles2.saved,
        ready: false,
        width: 0,
        height: 0,
        ratio: 1.777777777777778,
      })
    }
    this.setState({ 
      videoOfCurrentPlayer: !videoOfCurrentPlayer,
      videoPlayersReady: false,
      videoPlayersBinded: false,
      videoPlayersState,
      needSeekPlayers: true,
    });
  }

  changeGameTime(timestamp) {
    const { data, currentGame: index } = this.state;
    const gamestarted = +new Date(data.games[index].gamestarted);
    let time = 0;
    time += Math.round((+timestamp - gamestarted) / 1000);
    if (this.controller) {
      this.controller.setTimerValue(time, false);
    }
  }

  onChangeTimer(time, newGame = false, wait = true) {
    if (this.controller) {
      const _state = { ...this.state }; 
      const index = _state.currentGame;
      let offset = 0;
      if (index > 0) {
        for (let i = 0; i < index; i += 1) {
          offset += _state.data.games[i].duration;
        }
      }
      for (let i = 0; i < this.videoplayers.length; i += 1) {
        if (this.videoplayers[i]) {
          this.videoplayers[i].pause();
          this.videoplayers[i].seek(time + offset);
        }
        _state.videoPlayersState[i].ready = false;
      }
      const gameTime = +new Date(_state.data.games[_state.currentGame].gamestarted);
      if (!newGame) {
        if (wait) {
          this.pause();
        }
        this.setState({
           ..._state, 
           videoPlayersSeeking: true, 
           videoPlayersWaiting: wait, 
           gameTime: gameTime + time * 1000 });
      } else {
        this.setState({ ..._state, videoPlayersSeeking: true, gameTime });
      }
    }
  }
  
  onAfterChangeTimer () {
    this.setState({ videoPlayersWaiting: false });
  }

  onMute () {
    this.setState({ videoPlayersMuted: true });
  }

  onUnmute () {
    this.setState({ videoPlayersMuted: false });
  }

  onTick () {
    const gameTime = this.state.gameTime + 1000;
    this.setState({ gameTime });
  }

  onFrameSelect = (frame) => {
    if (!frame.lastupdated) return;
    const { currentGame, data } = this.state;
    const { frames } = data.games[currentGame];

    let frames1 = frames.current;
    let frames2 = frames.opponent;
    const curFrameIndex = frame.framenumber - 1;
    let time;
    if (frames.current[curFrameIndex].id !== frame.id) {
      [frames1, frames2] = [frames2, frames1];
    }
    if (frame.lastupdated > frames2[curFrameIndex].lastupdated) {
      time = frames2[curFrameIndex].lastupdated;
    } else if (curFrameIndex === 0) {
      time = data.games[currentGame].gamestarted;
    } else {
      time = frames2[curFrameIndex - 1].lastupdated;
    }
    this.changeGameTime(new Date(time));
  };

  play () {
    if (this.state.canPlay) {
      for (const vp of this.videoplayers) {
        if (vp) vp.play();
      }
      this.controller.startTimer();
    }
  }

  seek(index) {
    const gameIndex = this.state.currentGame;
    let offset = 0;
    if (gameIndex > 0) {
      for (let i = 0; i < gameIndex; i += 1) {
        offset += this.state.data.games[i].duration;
      }
    }
    const time = this.controller.getTime();
    this.videoplayers[index].seek(time + offset);
    this.setState({ videoPlayersSeeking: true, needSeekPlayers: false });
  }

  pause() {
    this.controller.pauseTimer();
    for (const vp of this.videoplayers) {
      if (vp) vp.pause();
    }
  }

  onEndTimer() {
    // переключаем на следующую игру, если она есть
    const gamesCount = (this.state.data.games || []).length;
    const currentGame = this.state.currentGame + 1;

    if (currentGame < gamesCount) {
      this.onChangeGame(currentGame);

      return;
    }

    this.setState({ canPlay: false });
    this.pause();
  }

  onChangeGame(index) {
    const { games } = this.state.data; 
    const currentGame = index;
    const prevGame = games[index - 1] ? index - 1 : null;
    const nextGame = games[index + 1] ? index + 1 : null;
    this.setState({
      currentGame,
      prevGame,
      nextGame,
      canPlay: true,
    });
  }

  renderVideoPlayer = (stream, index) => {
    const { videoPlayersMuted, videoPlayersState } = this.state;
    const { intl } = this.props;
    let videoTypeText = null;
    switch (index) {
      case 0 :
        videoTypeText = intl.formatMessage({ id: 'save_battle_scoring_camera', defaultMessage: 'Scoring' });
        break;
      case 1 :
        videoTypeText = intl.formatMessage({ id: 'save_battle_skittles_camera', defaultMessage: 'Pin deck' });
        break;
      case 2 :
        videoTypeText = intl.formatMessage({ id: 'save_battle_track_camera', defaultMessage: 'Track' });
        break;
      default:
        videoTypeText = intl.formatMessage({ id: 'save_battle_skittles_camera', defaultMessage: 'Pin deck' });
        break;
    }
    const videoNotExistsText = intl.formatMessage({ id: 'VideoNotExists', defaultMessage: 'Sorry, but video "{videotype}"\nwas removed' }, { videotype: videoTypeText });
    const noSavedVideoText = intl.formatMessage({ id: 'NoSaveVideo', defaultMessage: 'Save video "{videotype}"?'}, { videotype: videoTypeText });
    const videoarhiveData = this.props.videoarchive && this.props.videoarchive.data || null;
    const isTournamentWatch = videoarhiveData && !videoarhiveData.is_tournamentclosed && videoarhiveData.tournamenttypeid !== 1;

    if (stream) {
      if (isTournamentWatch || stream.saved) {
        return (
          <VideoArchivePlayer 
            width={videoPlayersState[index].width} 
            height={videoPlayersState[index].height}
            ref={(c) => {  this.videoplayers[index] = c ? c.refs.player || null : null }}
            src={stream.url}
            muted={videoPlayersMuted}
            aspectRatio="16:9"
            user={this.props.user}
          />
        )
      } 
      return (
        <div 
          className="dummy" 
          onClick={() => {
            this.pause();
            this.setState({ showModalSaveBattle: true });
          }}>
          <div className="no-video">
            <img src={ novideo }/>
            <div className="no-video-text">
              {noSavedVideoText}
            </div>
          </div>
        </div>
      )
    } 
      return (
        <div className="dummy">
          <div className="no-video">
            <img src={ notexists }/>
            <div className="no-video-text">
              {videoNotExistsText}
            </div>
          </div>
        </div>
      )
    
  }

  render() {
    const {
      data,
      prevGame,
      currentGame,
      gameTime,
      nextGame,
      dataLoaded,
      videoOfCurrentPlayer,
      videoPlayersMuted,
      showModalSaveBattle
    } = this.state;
    const { intl, lang, videoarchive } = this.props;
    const { squadid } = videoarchive;
    const translates = {
      prize: intl.formatMessage({ id: 'Prize', defaultMessage: 'Prize' }),
      trackN: intl.formatMessage({ id: 'LaneNumber', defaultMessage: 'Lane №' }),
    };
    if (dataLoaded) {
      const { 
        streams,
        players,
        games,
        tournamentcost,
        tournamentname,
        currencysymbol,
        tournamentprize,
        tournamentwincount,
        owned,
        tournamenttypeid,
      } = data;
      const { current: currentPlayer, opponent: opponentPlayer } = players;
      const playersArray = [{
        login: currentPlayer.userlogin,
        firstname: currentPlayer.userfirstname,
        lastname: currentPlayer.userlastname,
        country_live: currentPlayer.usercountry,
        ratingsavg: +currentPlayer.userrating,
        photo: currentPlayer.userphoto,
        id: currentPlayer.userid,
        userid: currentPlayer.userid,
        gameswin: (tournamenttypeid !== 3
          ? currentPlayer.playergameswin
          : games.reduce((sum, elem) => { return sum + (elem.frames.current[9].score_handicap || elem.frames.current[9].score); }, 0)),
        delays: 0,
      }];
      if (currentPlayer.playerid !== opponentPlayer.playerid) {
        playersArray.push({
          login: opponentPlayer.userlogin,
          firstname: opponentPlayer.userfirstname,
          lastname: opponentPlayer.userlastname,
          country_live: opponentPlayer.usercountry,
          ratingsavg: +opponentPlayer.userrating,
          photo: opponentPlayer.userphoto,
          id: opponentPlayer.userid,
          userid: opponentPlayer.userid,
          gameswin: opponentPlayer.playergameswin,
          delays: 0,
        });
      }
      const { messages } = games[currentGame];
      const date = games[0].gamestarted;
      const formattedDate = `
        ${moment(date).format('DD')} 
        ${moment(date).format('MMM').substr(0, 3).toLowerCase()} 
        ${moment(date).format('YYYY - HH:mm')}`;
      return (
        <div className="videoarchive">
          <div className="header">
            <div className="header-top">
              <div className="left-buttons">
                <Button kind="aquamarine" size="md" onClick={this.props.history.goBack}>
                  {intl.formatMessage({ id: 'ReturnToMyBattle', defaultMessage: 'Return to "My Battle"' })}
                </Button>
              </div>
              <div className="tournament-info">
                <div className="tournament-name">
                  #{squadid} {tournamentname}
                </div>
                {
                  tournamentcost ?
                  <div className="tournament-fee">
                    {intl.formatMessage({ id: 'fee', defaultMessage: 'Fee' })} – {tournamentcost}{currencysymbol}
                  </div>
                  : null
                }
                <div className="tournament-wincount">
                  {tournamenttypeid !== 3
                    ? intl.formatMessage({ id: 'table_wins_short', defaultMessage: 'Wins' })
                    : intl.formatMessage({ id: 'games', defaultMessage: 'Games' })
                  } - {tournamentwincount}
                </div>
                {
                  tournamentcost && tournamenttypeid !== 3 ?
                  <div className="tournament-prize">
                    {intl.formatMessage({ id: 'Prize', defaultMessage: 'Prize' })} – {tournamentprize}{currencysymbol}
                  </div>
                  : null
                }
              </div>
              <div className="right-buttons">
                <span className="start-datetime">
                  {intl.formatMessage({ id: 'GameArchive', defaultMessage: 'Game archive from'})}{' '}{formattedDate}
                </span>
                <Button
                  kind="aquamarine"
                  size="md"
                  onClick={() => {
                    const choosenLang = this.props.lang === 'RU' ? 'EN' : 'RU';
                    this.props.ChangeLang(choosenLang);
                  }}
                >
                  {this.props.lang === 'RU' ? 'EN' : 'RU'}
                </Button>
              </div>
            </div>
            <div className="header-bottom">
              <MatchScore
                players={playersArray}
              />
            </div>
          </div>
          <div className="content">
            <div className="games">
              <Tabs selectedIndex={currentGame}>
                <TabList>
                  {
                    games.map((game, index) => (
                      <Tab key={index} onClick={() => { this.onChangeGame(index) }}>{`Game ${index + 1}`}</Tab>
                    ))
                  }
                </TabList>
                {
                  games.map((game, index) => (
                    <TabPanel key={index}>
                      <div className="game">
                        {opponentPlayer.playerid !== currentPlayer.playerid ? <div className="player competitor">
                          <div className="player-head">
                          <div className="names">
                              <div className="fullname">{`${currentPlayer.userfirstname} ${currentPlayer.userlastname}`}</div>
                              <div className="login">{currentPlayer.userlogin}</div>
                            </div>
                            <div className="lane">
															<div className="bowling"><Flag name={currentPlayer.bowlingcenterflag}/> {currentPlayer.bowlingcenter_city_name} - {currentPlayer.bowlingcenter}</div>
															<div>{intl.formatMessage({ id: 'LaneNumber', defaultMessage: 'Lane №'})} {currentPlayer.playerlinenumber}</div>
                            </div>
                          </div>
                          <GameScore
                            currentFrame={null}
                            isOwner
                            changingFrame={null}
                            previousGameScore={0}
                            onFrameSelect={this.onFrameSelect}
                            frames={game.frames.current.map(frame => ({
                              ...frame,
                              ...(+new Date(frame.lastupdated) > gameTime ? {
                                shot1: -1,
                                shot2: -1,
                                shot3: -1,
                                score: 0,
                              } : {})
                            }))}
                          />
                        </div>:null}
                        <div className={`player ${opponentPlayer.playerid !== currentPlayer.playerid ? 'owner' : null}`}>
                          <div className="player-head">
                            <div className="names">
                              <div className="fullname">{`${opponentPlayer.userfirstname} ${opponentPlayer.userlastname}`}</div>
                              <div className="login">{opponentPlayer.userlogin}</div>
                            </div>
                            <div className="lane">
                              {/* <Flag name={opponentPlayer.bowlingcenterflag}/>
                              {`${opponentPlayer.bowlingcenter_city_name} - ${opponentPlayer.bowlingcenter}, ${translates.trackN}${opponentPlayer.playerlinenumber}`} */}
															<div className="bowling"><Flag name={opponentPlayer.bowlingcenterflag}/>{opponentPlayer.bowlingcenter_city_name} - {opponentPlayer.bowlingcenter}</div>
															<div>{intl.formatMessage({ id: 'LaneNumber', defaultMessage: 'Lane №'})} {opponentPlayer.playerlinenumber}</div>
                            </div>
                          </div>
                          <GameScore
                            currentFrame={null}
                            isOwner
                            changingFrame={null}
                            previousGameScore={0}
                            onFrameSelect={this.onFrameSelect}
                            frames={game.frames.opponent.map(frame => ({
                              ...frame,
                              ...(+new Date(frame.lastupdated) > gameTime ? {
                                shot1: -1,
                                shot2: -1,
                                shot3: -1,
                                score: 0,
                              } : {}),
                            }))}
                          />
                        </div>
                      </div>
                    </TabPanel>
                  ))
                }
              </Tabs>
            </div>
            <div className="current-game">
              <div className="cameras">
                <Tabs className="tabs">
                  {opponentPlayer.playerid !== currentPlayer.playerid ? <TabList className="tablist">
                    <div className="tab">
                      {intl.formatMessage({ id: 'GameCameraList', defaultMessage: 'Cameras:' })}
                    </div>
                    <Tab
                      className="tab tab-reduction"
                      onClick={() => {
                        if (!videoOfCurrentPlayer) {
                          this.switchVideo();
                        }
                      }}
                    >
                      {
                        `${currentPlayer.userfirstname} ${currentPlayer.userlastname}`
                      }
                    </Tab>
                    <Tab
                      className="tab tab-reduction"
                      onClick={() => {
                        if (videoOfCurrentPlayer) {
                          this.switchVideo();
                        }
                      }}
                    >
                      {
                        `${opponentPlayer.userfirstname} ${opponentPlayer.userlastname}`
                      }
                    </Tab>
                  </TabList> : <TabList className="tablist">
                    <div className="tab">
                      {intl.formatMessage({ id: 'GameCameraList', defaultMessage: 'Cameras:' })}
                    </div>
                    <Tab className="tab tab-reduction" onClick={() => {}}>
                      {
                        `${opponentPlayer.userfirstname} ${opponentPlayer.userlastname}`
                      }
                    </Tab>
                  </TabList>}
                  <TabPanel className="tabpanel">
                      {
                        videoOfCurrentPlayer ?
                        <div className="video-container">
                          <div className="video">{this.renderVideoPlayer(streams.current.track, 2)}</div>
                          <div className="video">{this.renderVideoPlayer(streams.current.scoring, 0)}</div>
                          <div className="video">{this.renderVideoPlayer(streams.current.skittles, 1)}</div>
                          {streams.current.skittles2 ? <div className="video">{this.renderVideoPlayer(streams.current.skittles2, 3)}</div> : null}
                        </div>
                        : null
                      }
                  </TabPanel>
                  {opponentPlayer.playerid !== currentPlayer.playerid ? <TabPanel className="tabpanel">
                      {
                        !videoOfCurrentPlayer ?
                        <div className="video-container">
                          <div className="video">{this.renderVideoPlayer(streams.opponent.track, 2)}</div>
                          <div className="video">{this.renderVideoPlayer(streams.opponent.scoring, 0)}</div>
                          <div className="video">{this.renderVideoPlayer(streams.opponent.skittles, 1)}</div>
                          {streams.opponent.skittles2 ? <div className="video">{this.renderVideoPlayer(streams.opponent.skittles2, 3)}</div> : null}
                        </div>
                        : null
                      }
                  </TabPanel> : null}
                </Tabs>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="left">
              <div className="title">
                {intl.formatMessage({ id: 'VideoManagement', defaultMessage: 'Video management' })}
              </div> 
              <VideoController 
                className="timer" 
                ref={(c) => {this.controller = c}} 
                duration={games[currentGame].duration ? games[currentGame].duration + 7 : games[currentGame].duration}
                muted={videoPlayersMuted}
                onChangeTimer={this.onChangeTimer.bind(this)}
                onAfterChangeTimer={this.onAfterChangeTimer.bind(this)}
                onPlay={this.play.bind(this)}
                onPause={this.pause.bind(this)}
                onEndTimer={this.onEndTimer.bind(this)}
                prevGame={prevGame}
                nextGame={nextGame}
                onChangeGame={this.onChangeGame.bind(this)}
                onMute={this.onMute.bind(this)}
                onUnmute={this.onUnmute.bind(this)}
                onTick={this.onTick.bind(this)}
              />
            </div>
            <div className="right">
              <div className="title">
                {intl.formatMessage({ id: 'ChatTitle', defaultMessage: 'Chat' })}
              </div>
              <ul className="archive-chat">
                {
                  messages.map((data, index) => data.timestamp <= gameTime
                      ? <ChatMessage onTimeClick={this.changeGameTime.bind(this)} key={index} data={data}/>
                      : null)
                }
              </ul>
            </div>
          </div>
          {
            showModalSaveBattle ?
            <ModalSaveBattle
              history={this.props.history}
              visible
              owned={owned}
              squadid={squadid}
              onSubmit={()=>{ 
                this.setState({
                  data: null,
                  prevGame: null,
                  currentGame: null,
                  gameTime: null,
                  nextGame: null,
                  dataFetching: false,
                  dataLoaded: false,
                  videoPlayersState: [],
                  videoPlayersBinded: false,
                  videoPlayersReady: false,
                  videoPlayersSeeking: false,
                  videoOfCurrentPlayer: true,
                  videoPlayersMuted: false,
                  videoPlayersWaiting: false,
                  showModalSaveBattle: false
                });
                this.props.getVideoArchive({ squadid });
              }} 
              onCancel={()=>{ 
                this.setState({
                  showModalSaveBattle: false
                });
              }} 
            /> : null
          }
        </div>
      );
    }
    return (
      <div className="videoarchive"/>
    );
  }
}

export default injectIntl(withRouter(
  connect(
    state => ({
      videoarchive: state.videoarchive,
      lang: state.langs.lang,
      user: state.users.user,
    }),
    {
      getVideoArchive,
      ChangeLang
    }
  )(VideoArchivePage)
));