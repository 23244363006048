/* eslint-disable */
import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import '../../../style.scss';
import Input from '../../common/Input';
import { Button } from '../../common/Button';
import OBModal from '../../common/Modal';
import { Table, Pagination } from 'antd';
import {
  updateIBAN,
  applyIBANSort,
  getIBANrequests,
} from '../../../libs/reducers/adminReducer';


const IBAN = ({
  admin,
  intl,
  updateIBAN,
  getIBANrequests,
}) => {

  const [page, setPage] = useState(1)
  const [tablefilters, setTablefilters] = useState({})
  const [update, setUpdate] = useState(0)
  const [lastfilter, setLastfilter] = useState(null)
  const [filterorder, setFilterorder] = useState('asc')
  const [modals, setModals] = useState({})
  const [tmpfilterval, setTmpfilterval] = useState('')
  const [confirmation, setConfirmation] = useState(null)
  const [confirmationtext, setConfirmationtext] = useState('')

  useEffect(() => {
    getIBANrequests()
  }, [])
  
  const applySorting = (lastfilter, filterorder) => {

    let sortable = admin.ibanrequests

    applyIBANSort(sortable.sort((a, b) => {
      let vala = a;
      let valb = b;
      const datefields = ['ab_pend', 'created', 'processed'];
      lastfilter.split('.').forEach(filter => {
        if (!filter.includes('field-iso') && !datefields.includes(filter)) {
          vala = vala[filter];
          valb = valb[filter];
        } else if (datefields.includes(filter)) {
          vala = +new Date(vala[filter] || 0);
          valb = +new Date(valb[filter] || 0);
        } else {
          filter = filter.replace('field-iso', '');
          vala = (vala.find(wallet => wallet.isocode === filter) || {}).amount;
          valb = (valb.find(wallet => wallet.isocode === filter) || {}).amount;
        }
      })
      let res = 0;
      if (vala > valb) {
        res = 1;
      } else if (vala < valb) {
        res = -1;
      } else {
        res = 0;
      }
      if (filterorder === 'asc') {
        return res
      } else {
        return res * (-1)
      }
    }))
  }

  const createTitle = (title, field, sortable, filterable = true, alwaysActive) => {

    return <div className='adminTableHeader'>
      {filterable && <div className={`searchField${tablefilters[field] || alwaysActive ? ' active' : ''}`}>
        <svg onClick={() => (setModals({ ...modals, [field]: !modals[field] }), setTmpfilterval(''))} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.45313 13.0938C5.45313 13.3703 5.675 13.5938 5.95 13.5938H10.05C10.325 13.5938 10.5469 13.3703 10.5469 13.0938V10.0312H5.45313V13.0938ZM13.7516 2.40625H2.24844C1.86563 2.40625 1.62656 2.82344 1.81875 3.15625L5.27656 9.03125H10.7266L14.1844 3.15625C14.3734 2.82344 14.1344 2.40625 13.7516 2.40625Z" fill="#f65932" />
        </svg>
      </div>}
      <div className='filterBody' onClick={sortable ? () => lastfilter === field
          ? (setFilterorder(filterorder === 'asc' ? 'desc' : 'asc'), applySorting(field, filterorder === 'asc' ? 'desc' : 'asc'))
          : (setLastfilter(field), applySorting(field, filterorder))
        : () => {}}>
        <span>{title}</span>
        <div className='filterState'>{field === lastfilter ? <div className={filterorder === 'asc' ? 'up' : 'down'} /> : null}</div>
      </div>
    </div>
  }
  const ibancolumns = [
    {
      title: createTitle('#', 'id', true, true),
      dataIndex: 'requestid',
      col: '#',
      render: (data, record, index) => ({
        props: {},
        children: (
          <span class='innerText'>{data}</span>
        ),
      }),
    },
    {
      title: createTitle(intl.formatMessage({ id: 'IBANTableCreatedCol', defaultMessage: ' Created' }), 'created', true, false),
      dataIndex: 'created',
      col: intl.formatMessage({ id: 'IBANTableCreatedCol', defaultMessage: ' Created' }),
      render: (data, record) => <>
        {moment(record.created).format('DD.MM.YY HH:mm')}
      </>
    },
    {
      title: createTitle(intl.formatMessage({ id: 'IBANTableStatusCol', defaultMessage: 'Status' }), 'status', true, true),
      dataIndex: 'status',
      col: intl.formatMessage({ id: 'IBANTableStatusCol', defaultMessage: 'Status' }),
      render: (data, record) => <>
        {record.status}
      </>
    },
    {
      title: createTitle(intl.formatMessage({ id: 'IBANTypeCol', defaultMessage: 'Type' }), 'type', true, true),
      dataIndex: 'type',
      col: intl.formatMessage({ id: 'IBANTypeCol', defaultMessage: 'Type' }),
      render: (data, record) => <>
        {record.type}
      </>
    },
    {
      title: createTitle('IBAN', 'iban', true, true),
      dataIndex: 'iban',
      col: 'IBAN',
      render: (data, record) => <>
        {record.iban}
      </>
    },
    {
      title: createTitle(intl.formatMessage({ id: 'IBANAmountCol', defaultMessage: 'Amount' }), 'amount', true, true),
      dataIndex: 'amount',
      col: intl.formatMessage({ id: 'IBANAmountCol', defaultMessage: 'Amount' }),
      render: (data, record) => <>
        {record.amount} {record.currencysymbol}
      </>
    },
    {
      title: createTitle(intl.formatMessage({ id: 'CommissionHistoryTable', defaultMessage: 'Commission' }), 'commission', true, true),
      dataIndex: 'commission',
      col: intl.formatMessage({ id: 'CommissionHistoryTable', defaultMessage: 'Commission' }),
      render: (data, record) => <>
        {record.commission} {record.currencysymbol}
      </>
    },
    {
      title: createTitle(intl.formatMessage({ id: 'IBANNameCol', defaultMessage: 'Name' }), 'fullname', true, true),
      dataIndex: 'fullname',
      col: intl.formatMessage({ id: 'IBANNameCol', defaultMessage: 'Name' }),
      render: (data, record) => <>
        {record.fullname}
      </>
    },
    {
      title: createTitle(intl.formatMessage({ id: 'PersonalInfoDOB', defaultMessage: 'Date of Birth' }), 'dob', true, true),
      dataIndex: 'dob',
      col: intl.formatMessage({ id: 'PersonalInfoDOB', defaultMessage: 'Date of Birth' }),
      render: (data, record) => <>
        {record.dob ? moment.utc(record.dob).format('YYYY-MM-DD') : null}
      </>
    },
    {
      title: createTitle(intl.formatMessage({ id: 'LoginTableColumn', defaultMessage: 'Login' }), 'login', true, true),
      dataIndex: 'login',
      col: intl.formatMessage({ id: 'LoginTableColumn', defaultMessage: 'Login' }),
      render: (data, record) => <>
        {record.login}
      </>
    },
    {
      title: createTitle(intl.formatMessage({ id: 'email', defaultMessage: 'E-mail' }), 'email', true, true),
      dataIndex: 'email',
      col: intl.formatMessage({ id: 'email', defaultMessage: 'E-mail' }),
      render: (data, record) => <>
        <a href={`mailto:${record.email}`}>{record.email}</a>
      </>
    },
    {
      title: createTitle(intl.formatMessage({ id: 'IBANCurrentWallet', defaultMessage: 'Current savings' }), 'wallet', true, true),
      dataIndex: 'wallet',
      col: intl.formatMessage({ id: 'IBANCurrentWallet', defaultMessage: 'Current savings' }),
      render: (data, record) => <>
        {record.wallet} {record.currencysymbol}
      </>
    },
    {
      title: createTitle(intl.formatMessage({ id: 'IBANProcessedCol', defaultMessage: 'Processing date' }), 'processed', true, false),
      dataIndex: 'processed',
      col: intl.formatMessage({ id: 'IBANProcessedCol', defaultMessage: 'Processing date' }),
      render: (data, record) => <>
        {record.processed ? moment(record.processed).format('DD.MM.YY HH:mm') : ''}
      </>
    },
    {
      title: createTitle(intl.formatMessage({ id: 'IBANProcesserCol', defaultMessage: 'Accountant Name' }), 'accountantfullname', true, true),
      dataIndex: 'accountantfullname',
      col: intl.formatMessage({ id: 'IBANProcesserCol', defaultMessage: 'Accountant Name' }),
      render: (data, record) => <>
        {record.accountantfullname}
      </>
    },
    {
      title: createTitle(intl.formatMessage({ id: 'TitleAddressBowlingCenterList', defaultMessage: 'Address' }), 'address', true, true),
      dataIndex: 'address',
      col: intl.formatMessage({ id: 'TitleAddressBowlingCenterList', defaultMessage: 'Address' }),
      render: (data, record) => <>
        {record.address}
      </>
    },
    {
      title: createTitle(intl.formatMessage({ id: 'personalid', defaultMessage: 'Personal ID' }), 'personalid', true, true),
      dataIndex: 'personalid',
      col: intl.formatMessage({ id: 'personalid', defaultMessage: 'Personal ID' }),
      render: (data, record) => <>
        {record.personalid}
      </>
    },
    {
      title: createTitle('', 'button', false, false),
      dataIndex: 'button',
      render: (data, record) => {
        switch (record.status) {
          case 'new':
            return <Button
              key="okButton"
              kind="aquamarine"
              className='IBANbutton'
              onClick={() => (setConfirmationtext(
                record.type === 'in'
                  ? intl.formatMessage({ id: 'IBANConfirmationBodyIn', defaultMessage: '{amount} {iso} will be transferred to {name} account. Continue?' }, { amount: record.amount, iso: record.currencysymbol, name: `${record.firstname} ${record.lastname}` })
                  : intl.formatMessage({ id: 'IBANConfirmationBodyInBlocked', defaultMessage: '{amount} {iso} will be charged from {name} account. Continue?' }, { amount: record.amount, iso: record.currencysymbol, name: `${record.firstname} ${record.lastname}` })),
                setConfirmation({ call: () => updateIBAN({ intent: 'approve', ibanid: record.id }) }))
              }>
              {intl.formatMessage({ id: 'IBANPerformButton', defaultMessage: 'Perform' })}
            </Button>
          case 'processed':
            if (record.type === 'in' && record.wallet < record.amount) {
              return <Button
                key="okButton"
                disabled
                className='IBANbutton'
                onClick={() => { }}>
                {intl.formatMessage({ id: 'IBANNoFundsToRevert', defaultMessage: 'Not enought funds' })}
              </Button>
            } else {
              return <Button
                key="okButton"
                className='IBANbutton'
                onClick={() => (setConfirmationtext(
                  record.type === 'in'
                    ? intl.formatMessage({ id: 'IBANConfirmationBodyOut', defaultMessage: '{amount} {iso} will be charged from {name} account. Continue?' }, { amount: record.amount, iso: record.currencysymbol, name: `${record.firstname} ${record.lastname}` })
                    : intl.formatMessage({ id: 'IBANConfirmationBodyOutBlocked', defaultMessage: '{amount} {iso} will be refund to {name} account. Continue?' }, { amount: record.amount, iso: record.currencysymbol, name: `${record.firstname} ${record.lastname}` })),
                  setConfirmation({ call: () => updateIBAN({ intent: 'decline', ibanid: record.id }) }))
                }>
                {intl.formatMessage({ id: 'IBANRevertButton', defaultMessage: 'Revert' })}
              </Button>
            }
          default:
            return <Button
              key="okButton"
              kind="aquamarine"
              bordered="true"
              disabled
              className='IBANbutton'
              onClick={() => { }}>
              {intl.formatMessage({ id: 'IBANRevertedButton', defaultMessage: 'Reverted' })}
            </Button>
        }
      }
    },
  ]
  let ibans = admin.ibanrequests.filter(item => {
    let res = true;
    Object.keys(tablefilters).forEach(filter => {
      if (!tablefilters[filter] || tablefilters[filter] === '') return;
      if (tablefilters[filter].split(/[\s\,]/g).filter(f => !(f.match(/\s/) || f == '')).forEach(filterItem => {
        if (!item[filter] || !item[filter].toString().toLowerCase().includes(filterItem.toLowerCase())) res = false;
      }));
      return
    })
    return res;
  });

  return (
    <div className='admin_ibanList'>
      <Table
        className="table"
        columns={ibancolumns}
        dataSource={ibans.slice((page-1)*20, (page)*20)}
        pagination={false}
        rowKey="id"
        style={{ width: '100%' }}
        locale={{ emptyText: 'No iban requests' }}
        rowClassName={'requestRow'}
        onRow={(record, index) => {}}/>
        <Pagination defaultCurrent={1} defaultPageSize={20} total={ibans.length} onChange={value => setPage(value)} current={page}/>
        {ibancolumns.filter(col => modals[col.dataIndex]).map(col => <> 
        <OBModal
          title={col.col}
          visible
          closable={false}
          footer={
            <Button
              key="okButton"
              kind="aquamarine"
              onClick={() => (
                setModals({ ...modals, [col.dataIndex]: !modals[col.dataIndex] }),
                setTablefilters({ ...tablefilters, [col.dataIndex]: tmpfilterval }),
                setTmpfilterval(false),
                setUpdate(update + 1))
              }>
              OK
            </Button>}>
            <Input
            onChangeText={(e) => setTmpfilterval( e || '' )}
            value={tmpfilterval}
            placeholder={'Filter'}
            />
        </OBModal>
      </>)}
      <OBModal
        title={intl.formatMessage({ id: 'IBANConfirmation', defaultMessage: 'Confirmation' })}
        visible={confirmation}
        closable={false}
        footer={<div style={{ display: 'flex' }}>
          <Button
            style={{ flex: '1 1 50%', marginRight: 11 }}
            key="okButton"
            kind="aquamarine"
            bordered="true"
            onClick={() => (setConfirmation(false), setConfirmationtext(''))}>
            {intl.formatMessage({ id: 'CancelAsAbortAlias', defaultMessage: 'Cancel' })}
          </Button>
          <Button
            style={{ flex: '1 1 50%' }}
            key="okButton"
            kind="aquamarine"
            onClick={() => {
              if (confirmation && typeof confirmation.call === 'function') {
                confirmation.call()
              }
              setConfirmation(null)
              setConfirmationtext('')
            }}
          >
            OK
          </Button>
        </div>}>
        <div style={{ marginTop: 15 }}>{confirmationtext}</div>
      </OBModal>
    </div>
  )
}

export default injectIntl(withRouter(
  connect(
    state => ({
      admin: state.admin,
    }),
    {
      updateIBAN,
      applyIBANSort,
      getIBANrequests,
    },
  )(IBAN),
));
