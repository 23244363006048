import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { intlShape, injectIntl } from 'react-intl';
import OBModal from '../../Modal';
import { Button } from '../../Button';
import './modalTrial.css';

class ModalTrial extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    visible: PropTypes.bool,
    intl: intlShape,
    onClose: PropTypes.func,
    signUpWallet: PropTypes.number,
  }

  static defaultProps = {
    title: 'Trial 30 day',
    signUpWallet: 500,
  }

  closeModal = () => {
    const { onClose } = this.props;
    if (typeof onClose === 'function') {
      onClose();
    }
  }

  render() {
    const {
      title, visible, intl, signUpWallet,
    } = this.props;
    const formatedWallet = signUpWallet.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    const description = intl.formatMessage({
      id: 'trialDescription',
      defaultMessage: 'Congratulations! You have become a member of the TenPinCam platform. You are registered for a trial tariff plan. Now you have {amount} v on your virtual account. Have a nice game!',
    }, { amount: formatedWallet });
    const [first, second] = description.split('TenPinCam');
    return <OBModal
      key='main'
      title={null}
      visible={visible}
      width={690}
      onCancel={this.closeModal}
      className="modal-trial"
      footer={null}
      zIndex={10000}
    >
      <div className="modal-trial modal-trial-container">
        <div className="modal-trial-header">
          <span className="modal-trial-title">{title}</span>
        </div>
        <div className="modal-trial-content">
          <div className="modal-trial-wallet">
            <span className="modal-trial-50000v">
              <span className="modal-trial-50000">{formatedWallet}</span>
              <span className="modal-trial-v">v</span>
            </span>
            <span className="modal-trial-10Gbondisk">
              <span className="modal-trial-10Gb">
                +10Gb
              </span>
              <span className="modal-trial-ondisk">
                {intl.formatMessage({
                  id: 'OnDiskAsDislocationAlias',
                  defaultMessage: 'on disk',
                })}
              </span>
            </span>
          </div>
          <div className="modal-trial-description">
            <span className="modal-trial-description-text">
              <span className="modal-trial-description-1st">{first}</span>
              <span className="modal-trial-description-tenpincam">TenPinCam</span>
              <span className="modal-trial-description-2nd">{second}</span>
            </span>
          </div>
        </div>
        <div className="modal-trial-footer">
          <Button
            kind="aquamarine"
            size="s"
            className="modal-trial-footer-button"
            onClick={this.closeModal}
          >
            <span className="modal-trial-footer-button-text">
              {intl.formatMessage({ id: 'ModalDefaultOK', defaultMessage: 'OK' })}
            </span>
          </Button>
        </div>
      </div>
    </OBModal>;
  }
}

export default connect(
  state => ({
    signUpWallet: state.common.settings.data.sign_up_wallet || 500,
  }),
  {},
)(injectIntl(ModalTrial));
