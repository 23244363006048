import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.css';

export default class TextArea extends Component {
  static propTypes = {
    rows: PropTypes.number,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    rows: 2,
  };

  handleChange = (event) => {
    this.props.onChange({ value: event.target.value });
  };

  render() {
    const { rows, cols } = this.props;
    const classname = this.props.className ? `${this.props.className} textarea` : 'textarea';

    return <textarea {...this.props} rows={rows} cols={cols} className={classname} onChange={this.handleChange} />;
  }
}
