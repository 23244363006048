import Utils from './utils';
import OPTIONS from '../config';

const { getFramework } = Utils;

export const options = {
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Access-Control-Allow-Origin': '*',
  },
  withCredentials: !(OPTIONS.saveNativeCookies && getFramework() === 'ReactNative'),
};

export default {
  ...options,
};
