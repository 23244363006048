/* eslint-disable */
import React, {useEffect, useState} from 'react';
import { TEST_BANNERS } from './test-banners';

/**
 * @typedef {{
 *  title: string;
 *  name: string;
 *  link: string;
 *  link_with_domain: string;
 *  image: string;
 *  image_with_domain: string;
 * }} BannerDto
 */

/**
 * @param {number} [id]
 * @returns {{ banners: BannerDto[]; fetching: boolean; error: any }}
 */
export const useBanners = (id = 1) => {
  const url = 'https://tenpincam.com/?api=true&events=true'
  const [fetching, setFetching] = useState(false)
  const [error, setError] = useState(null)
  const [banners, setBanners] = useState([])
  /** @returns {Promise<Array<{ link: string; image: string; title: string }>>} */
  const fetchBanners = async () => {
    setFetching(true)
    setError(null)
    try {
      /** @type {BannerDto[]} */
      const res = await fetch(url).then(res => res.json())
      setBanners(res)
      return res;
    } catch (e) {
      setError(e)
      setBanners(TEST_BANNERS)
    } finally {
      setFetching(false)
    }
  }
  useEffect(() => {
    fetchBanners()
  }, [id])
  return { banners, fetching, error }
}
