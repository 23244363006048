import React, {useEffect, useState} from 'react';
import ModerationPage from "./components/ModerationPage";
import './style.scss'
import {store} from "../../../store";
import {getBCAdminBanners, uploadBCBanners} from "../../../libs/reducers/adminReducer";
import {connect} from "react-redux";
import config from "../../../config";
import Handlebars from 'handlebars'


const ModerationComponent = (props) => {
    const {getBCAdminBanners, bcBanners, bannersFetch} = props
    const [activePage, setActivePage] = useState('waiting');
    const [leftTextStyle, setLeftTextStyle] = useState('moderation_page_name orange');
    const [rightTextStyle, setRightTextStyle] = useState('moderation_page_name');
    const [waitingModerationBanners, setWaitingModerationBanners] = useState([]);
    const [publishedBanners, setPublishedBanners] = useState([]);




    // Получаем баннеры
    useEffect(()=> {
        getBCAdminBanners()
    },[])

    //-----------------------

    // Просто переключаме страницы
    useEffect(() => {
        if (activePage === 'waiting'){
            setLeftTextStyle('moderation_page_name orange')
            setRightTextStyle('moderation_page_name')
        }
        if (activePage === 'published'){
            setLeftTextStyle('moderation_page_name')
            setRightTextStyle('moderation_page_name orange')
        }
    }, [activePage]);

    //-----------------------

    useEffect(()=>{
        if (bcBanners){
            let tempWaitingModer = []
            let tempPublished= []
            bcBanners.forEach(banner => {
                if (banner.moderation_agreed){
                    tempPublished.push(banner)
                } else {
                    tempWaitingModer.push(banner)
                }
            })
            setWaitingModerationBanners(tempWaitingModer)
            setPublishedBanners(tempPublished)
        }
    },[bcBanners])


    return (
        <div className={'moderation_container'}>
            <div className={'moderation_page_name_container'}>
                <div className={leftTextStyle} onClick={() => setActivePage('waiting')}>Waiting for Moderation</div>
                <div className={rightTextStyle} onClick={() => setActivePage('published')}>Published</div>
            </div>
            {activePage === 'waiting' ?
                <ModerationPage
                    banners={waitingModerationBanners}
                    setBanners={setWaitingModerationBanners}
                    type={'waiting'}
                    activePage={activePage}
                />
                :
                <ModerationPage
                    banners={publishedBanners}
                    setBanners={setPublishedBanners}
                    type={'published'}
                    activePage={activePage}
                />
            }
        </div>
    );
};

export default connect((state) => ({
        bcBanners: state.admin.bcAdminBanners,
        bannersFetch: state.admin.bcAdminBannersFetch
    }),
    {
        getBCAdminBanners
    }
)(ModerationComponent);
