/**
 * @typedef {keyof typeof PinStateEnum} IPinState
 */

/**
 * @typedef {{
 *  gameid: number
 *  pins: { [framenumber: string]: IFramePins }
 *  tempPins: { [framenumber: string]: IFramePins }
 *  shots: { [framenumber: string]: IFrameShots }
 * }} IGameKeyboardState
 */

/**
 * @typedef {{
 *  number: number;
 *  shot1?: number;
 *  shot2?: number;
 *  shot3?: number;
 *  shot1PinStates: IPinState[]
 *  shot2PinStates: IPinState[]
 *  shot3PinStates: IPinState[]
 * }} IFramePins
 */

/**
 * @typedef {{
 *  number: number
 *  shot1: number
 *  shot2: number
 *  shot3: number
 * }} IFrameShots
 */

/**
 * @type {{
 *  KNOCKED: 'KNOCKED'
 *  SELECTED: 'SELECTED'
 *  UNKNOCKED: 'UNKNOCKED'
 * }}
 */
export const PinStateEnum = {
  KNOCKED: 'KNOCKED', // сбита
  SELECTED: 'SELECTED', // выбрана
  UNKNOCKED: 'UNKNOCKED', // не сбита
};

/** @type {typeof PinStateEnum['UNKNOCKED'][]} */
export const FramePinsUnknocked = []
for (let i = 0; i < 10; ++i) {
  FramePinsUnknocked.push(PinStateEnum.UNKNOCKED)
}

/** @type {typeof PinStateEnum['SELECTED'][]} */
export const FramePinsSelected = []
for (let i = 0; i < 10; ++i) {
  FramePinsSelected.push(PinStateEnum.SELECTED)
}

export const gameKeyboardResetAction = () => ({ type: 'GAME_KEYBOARD_RESET' })
/** @param {IFramePins} pins */
export const gameKeyboardPinsSetAction = (pins) => ({ type: 'GAME_KEYBOARD_PINS_SET', params: pins })
/** @param {IFramePins} pins */
export const gameKeyboardTempPinsSetAction = (pins) => ({ type: 'GAME_KEYBOARD_TEMP_PINS_SET', params: pins })
export const gameKeyboardTempPinsResetAction = () => ({ type: 'GAME_KEYBOARD_TEMP_PINS_RESET' })
/** @param {IFrameShots} shots */
export const gameKeyboardShotsSetAction = (shots) => ({ type: 'GAME_KEYBOARD_SHOTS_SET', params: shots })

/** @type {IGameKeyboardState} */
export const initialGameKeyboardsState = {
  gameid: 0,
  pins: {},
  tempPins: {},
  shots: {},
}

/**
 * @param {IGameKeyboardState} state
 * @param {{ type: string; params?: any }} action
 * @return {IGameKeyboardState}
 */
export const GameKeyboardsReducer = (state = initialGameKeyboardsState, action) => {
  switch (action.type) {
    case 'SOCKET_EVENT_GAME_START':
    case 'SOCKET_EVENT_GAME_NEXT':
    case 'SOCKET_EVENT_GAME_END':
    case 'GAME_KEYBOARD_RESET':
      return {
        ...initialGameKeyboardsState,
      }
    case 'GAME_KEYBOARD_TEMP_PINS_RESET':
      return {
        ...state,
        temPins: {},
      }
    case 'GAME_KEYBOARD_TEMP_PINS_SET':
      if (!action.params) {
        return state;
      }
      if (action.params.number){
        return {
          ...state,
          tempPins: {
            ...state.tempPins,
            [action.params.number]: {
              ...state.tempPins[action.params.number],
              ...action.params,
            },
          },
        }
      }
      return {
        ...state,
        tempPins: {
          ...state.tempPins,
          ...action.params,
        }
      }

    case 'GAME_KEYBOARD_PINS_SET':
      if (!action.params) {
        return state;
      }
      if (action.params.number){
        return {
          ...state,
          pins: {
            ...state.pins,
            [action.params.number]: {
              ...state.pins[action.params.number],
              ...action.params,
            },
          },
        }
      }
      return {
        ...state,
        pins: {
          ...state.pins,
          ...action.params,
        }
      }
    case 'GAME_KEYBOARD_SHOTS_SET':
      if (!action.params) {
        return state;
      }
      return {
        ...state,
        shots: {
          ...state.shots,
          [action.params.number]: {
            ...state.shots[action.params.number],
            ...action.params,
          },
        },
      }
    default:
      return state;
  }
}
