import { call, put, takeLatest } from 'redux-saga/effects';
import config from '../../config';
import { put as methodPUT } from '../Networking';

export const userCheckPasswordRequestAction = (password) => ({ type: 'USER_DELETE_CHECK_PASSWORD_REQUEST', params: { password } });
export const userCheckPasswordSuccessAction = () => ({ type: 'USER_DELETE_CHECK_PASSWORD_SUCCESS' })
export const userCheckPasswordFailureAction = (error) => ({ type: 'USER_DELETE_CHECK_PASSWORD_FAILURE', params: error });

export const userDeleteRequestAction = (password) => ({ type: 'USER_DELETE_REQUEST', params: { password } });
export const userDeleteSuccessAction = () => ({ type: 'USER_DELETE_SUCCESS' });
export const userDeleteFailureAction = (error) => ({ type: 'USER_DELETE_FAILURE', params: error });

export const initialUserDeleteState = {
  checkPassword: {
    fetching: false,
    error: false,
  },
  delete: {
    fetching: false,
    error: false,
  },
}

export const UserDeleteReducer = (state = initialUserDeleteState, action) => {
  switch (action.type) {
    case 'USER_DELETE_CHECK_PASSWORD_REQUEST':
      return {
        ...state,
        checkPassword: {
          ...state.checkPassword,
          fetching: true,
          error: false,
        },
      };
    case 'USER_DELETE_CHECK_PASSWORD_SUCCESS':
      return {
        ...state,
        checkPassword: {
          ...state.checkPassword,
          fetching: false,
        },
      };
    case 'USER_DELETE_CHECK_PASSWORD_FAILURE':
      return {
        ...state,
        checkPassword: {
          ...state.checkPassword,
          fetching: false,
          error: true,
        },
      };
    case 'USER_DELETE_REQUEST':
      return {
        ...state,
        delete: {
          ...state.delete,
          fetching: true,
          error: false,
        },
      };
    case 'USER_DELETE_SUCCESS':
      return {
        ...state,
        delete: {
          ...state.delete,
          fetching: false,
        },
      };
    case 'USER_DELETE_FAILURE':
      return {
        ...state,
        delete: {
          ...state.delete,
          fetching: false,
          error: true,
        },
      };
    default:
      return state;
  }
};

export function* userCheckPasswordSaga(api, action) {
  try {
    const { password } = action.params;
    const res = yield call(api.put, { url: `${config.FETCH.url}/user/check_password`, data: { password } })
    if (res.status === 200 && res.data.success) {
      yield put(userCheckPasswordSuccessAction())
    } else {
      yield put(userCheckPasswordFailureAction())
    }
  } catch (e) {
    yield put(userCheckPasswordFailureAction(e.message));
  }
}

export function* userDeleteSaga(api, action) {
  try {
    const { password } = action.params;
    const res = yield call(api.put, { url: `${config.FETCH.url}/user/delete`, data: { password } })
    if (res.status === 200 && res.data.success) {
      yield put(userDeleteSuccessAction())
    } else {
      yield put(userDeleteFailureAction())
    }
  } catch (e) {
    yield put(userDeleteFailureAction(e.message))
  }
}

export function* watchUserDeletion() {
  const api = { put: methodPUT };
  yield takeLatest('USER_DELETE_CHECK_PASSWORD_REQUEST', userCheckPasswordSaga, api);
  yield takeLatest('USER_DELETE_REQUEST', userDeleteSaga, api);
}