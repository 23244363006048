import React, { useState } from 'react';

function Radio({
  options,
  label,
  onChange,
}) {
  const [value, setValue] = useState(null);
  return (
    <div className="radio-container">
      <div className="s-label">{label}</div>
      <div className="l-radio-group">
        {options.map((elem, index) => (
          <div
            key={`${elem.value} ${index}`}
            onClick={() => {
              setValue(elem.value);
              onChange(elem.value);
            }}
            className={`l-radio ${value === elem.value ? 'selected' : ''}`}
          >
            <span className="l-hide-m">{elem.label}</span>
            <span className="l-hide-d">{elem.mobileLabel || elem.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Radio;
