import {call, put, select} from 'redux-saga/effects';
import config from '../../config';
import {get, post, put as putMethod} from '../Networking';

const {
  SET_STATISTIC_MAIN,
  SET_STATISTIC_AVERAGE,
  SET_STATISTIC_HIGH,
  SET_STATISTIC_CLEAN,
  SET_STATISTIC_SERIES,
  SET_STATISTIC_FRAMES_MAIN,
  SET_STATISTIC_FRAMES_FIRST,
  SET_STATISTIC_FRAMES_AVERAGE,
  SET_STATISTIC_FRAMES_STRIKES,
  SET_STATISTIC_FRAMES_SPLITS,
} = require('../reducers/actiontypes').default;

export function* _getStatisticMain({ params }) {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/statistic/game/`, params });
    const { data } = result;
    if (data.success) {
      yield put({ type: SET_STATISTIC_MAIN, params: data.data })
    } else {
      yield put({ type: SET_STATISTIC_MAIN, params: {} })
      console.error('_getStatisticMain', data);
    }
  } catch (error) {
    yield put({ type: SET_STATISTIC_MAIN, params: {} })
    console.error('_getStatisticMain', error.message);
  }
}

export function* _getStatisticAverage({ params }) {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/statistic/game/average/`, params });
    const { data } = result;
    if (data.success) {
      yield put({ type: SET_STATISTIC_AVERAGE, params: data.data })
    } else {
      yield put({ type: SET_STATISTIC_AVERAGE, params: {} })
      console.error('_getStatisticAverage', data);
    }
  } catch (error) {
    yield put({ type: SET_STATISTIC_AVERAGE, params: {} })
    console.error('_getStatisticAverage', error.message);
  }
}

export function* _getStatisticHigh({ params }) {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/statistic/game/high/`, params });
    const { data } = result;
    if (data.success) {
      yield put({ type: SET_STATISTIC_HIGH, params: data.data })
    } else {
      yield put({ type: SET_STATISTIC_HIGH, params: {} })
      console.error('_getStatisticHigh', data);
    }
  } catch (error) {
    yield put({ type: SET_STATISTIC_HIGH, params: {} })
    console.error('_getStatisticHigh', error.message);
  }
}

export function* _getStatisticClean({ params }) {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/statistic/game/clean/`, params });
    const { data } = result;
    if (data.success) {
      yield put({ type: SET_STATISTIC_CLEAN, params: data.data })
    } else {
      yield put({ type: SET_STATISTIC_CLEAN, params: {} })
      console.error('_getStatisticClean', data);
    }
  } catch (error) {
    yield put({ type: SET_STATISTIC_CLEAN, params: {} })
    console.error('_getStatisticClean', error.message);
  }
}

export function* _getStatisticSeries({ params }) {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/statistic/game/series/`, params });
    const { data } = result;
    if (data.success) {
      yield put({ type: SET_STATISTIC_SERIES, params: data.data })
    } else {
      yield put({ type: SET_STATISTIC_SERIES, params: {} })
      console.error('_getStatisticSeries', data);
    }
  } catch (error) {
    yield put({ type: SET_STATISTIC_SERIES, params: {} })
    console.error('_getStatisticSeries', error.message);
  }
}

export function* _getStatisticFramesMain({ params }) {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/statistic/frames/`, params });
    const { data } = result;
    if (data.success) {
      yield put({ type: SET_STATISTIC_FRAMES_MAIN, params: data.data })
    } else {
      yield put({ type: SET_STATISTIC_FRAMES_MAIN, params: {} })
      console.error('_getStatisticFramesMain', data);
    }
  } catch (error) {
    yield put({ type: SET_STATISTIC_FRAMES_MAIN, params: {} })
    console.error('_getStatisticFramesMain', error.message);
  }
}
export function* _getStatisticFramesFirst({ params }) {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/statistic/frames/first`, params });
    const { data } = result;
    if (data.success) {
      yield put({ type: SET_STATISTIC_FRAMES_FIRST, params: data.data })
    } else {
      yield put({ type: SET_STATISTIC_FRAMES_FIRST, params: {} })
      console.error('_getStatisticFramesFirst', data);
    }
  } catch (error) {
    yield put({ type: SET_STATISTIC_FRAMES_FIRST, params: {} })
    console.error('_getStatisticFramesFirst', error.message);
  }
}
export function* _getStatisticFramesAverage({ params }) {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/statistic/frames/average`, params });
    const { data } = result;
    if (data.success) {
      yield put({ type: SET_STATISTIC_FRAMES_AVERAGE, params: data.data })
    } else {
      yield put({ type: SET_STATISTIC_FRAMES_AVERAGE, params: {} })
      console.error('_getStatisticFramesAverage', data);
    }
  } catch (error) {
    yield put({ type: SET_STATISTIC_FRAMES_AVERAGE, params: {} })
    console.error('_getStatisticFramesAverage', error.message);
  }
}
export function* _getStatisticFramesStrikes({ params }) {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/statistic/frames/strikes`, params });
    const { data } = result;
    if (data.success) {
      yield put({ type: SET_STATISTIC_FRAMES_STRIKES, params: data.data })
    } else {
      yield put({ type: SET_STATISTIC_FRAMES_STRIKES, params: {} })
      console.error('_getStatisticFramesStrikes', data);
    }
  } catch (error) {
    yield put({ type: SET_STATISTIC_FRAMES_STRIKES, params: {} })
    console.error('_getStatisticFramesStrikes', error.message);
  }
}
export function* _getStatisticFramesSplits({ params }) {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/statistic/frames/splits`, params });
    const { data } = result;
    if (data.success) {
      yield put({ type: SET_STATISTIC_FRAMES_SPLITS, params: data.data })
    } else {
      yield put({ type: SET_STATISTIC_FRAMES_SPLITS, params: {} })
      console.error('_getStatisticFramesSplits', data);
    }
  } catch (error) {
    yield put({ type: SET_STATISTIC_FRAMES_SPLITS, params: {} })
    console.error('_getStatisticFramesSplits', error.message);
  }
}
