import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.css';

export default class FilterInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    onChangeText: PropTypes.func,
    value: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.number,
    invalid: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
  };

  static defaultProps = {
    value: '',
  };

  render() {
    const {
      value, placeholder, name, size, invalid,
    } = this.props;

    let classname = this.props.className ? `${this.props.className} filter-input__data` : 'filter-input__data';
    if (this.props.disabled) classname += ' filter-input__disabled';

    return (
      <div className="filter-input">
        <input {...this.props} className={classname} name={name} placeholder={placeholder} value={value} size={size} />
        {invalid ? <div className="input__error">{invalid}</div> : null}
      </div>
    );
  }
}
