import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RussiaFlag from '-!svg-react-loader!../../../images/flags/russia.svg'; // eslint-disable-line
import defaultAvatar from './images/default.png';
import Flag from '../Flag';

import './style.scss';

export default class Avatar extends Component {
  static propTypes = {
    uri: PropTypes.string,
    flag: PropTypes.string,
    isOnline: PropTypes.bool,
    tabled: PropTypes.bool,
    avgscore: PropTypes.number,
  };

  static defaultProps = {
    uri: defaultAvatar,
  };

  render() {
    // let Flag = null;
    // switch (this.props.flag) {
    //   case 'russia':
    //     Flag = RussiaFlag;
    //     break;
    //   default:
    //     break;
    // }
    const { uri, flag, tabled, isOnline, avgscore, checked, flagStyle, gamesTable } = this.props;

    return (
      <div className={`avatar${tabled ? ' tabled' : ''}${gamesTable ? ' gamesTable' : ''}`}>
        {isOnline && !tabled
          ? <svg className="circle" viewbox="0 0 50 50"><circle cx="50%" cy="50%" r="48%" stroke="#35C844" fill="transparent" strokeWidth="3"/></svg>
          : null}
        {isOnline && tabled
          ? <svg className="circle" height="calc(20% + 5px)" width="calc(20% + 5px)" viewbox="0 0 50 50"><circle cx="50%" cy="50%" r="45%" stroke="#35C844" fill="#35C844"/></svg>
          : null}
        {!checked
          ? <img src={uri || defaultAvatar} alt="avatar" />
          : <div className="finish__check-opponent">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 13">
              <path fill="#fff" fillRule="evenodd" d="M15.633.226a1 1 0 0 1 .14 1.407l-8.999 11a1 1 0 0 1-1.514.04l-5-5.5a1 1 0 0 1 1.48-1.346l4.22 4.643L14.226.367a1 1 0 0 1 1.407-.14z" clipRule="evenodd"/>
            </svg>
          </div>
        }
        {(avgscore && +avgscore > 0)
          ? <div className='avgScore'>{(+avgscore || 0).toFixed(0)}</div>
          : null
        }
        {flag && (
          <div style={flagStyle} className="avatar__flag">
            <Flag name={flag} />
          </div>
        )}
      </div>
    );
  }
}
