/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import TextInput from './fragments/text-input.fragment.jsx';

/**
 * @typedef {{
 *  onChange?: (value: string) => any;
 *  error?: string | boolean;
 *  value?: string;
 *  type?: 'iban' | 'paypal';
 *  setRef?: (ref: { reset: (string) => void }) => any;
 * }} IbanValueCreateFragmentProps
 */
/**
 * @type {React.FC<IbanValueCreateFragmentProps>}
 */
const IbanValueCreateFragmentFn = props => {
  const {
    intl, error, onChange, type, setRef,
  } = props;
  const [value, setValue] = useState(props.value);
  const ref = useRef({ reset: () => setValue('') });
  if (typeof setRef === 'function') {
    setRef(ref.current);
  }
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;
  useEffect(() => {
    if (onChangeRef.current) {
      onChangeRef.current(value);
    }
  }, [value]);
  return <>
    <div className="IBANmodal">
      <TextInput
        label={type === 'iban' ? 'IBAN' : 'PayPal'}
        value={value}
        onChange={setValue}
        error={error}
        intl={intl}
      />
    </div>
  </>;
};

class IbanValueCreateFragmentClass extends React.Component {
  render() {
    return <IbanValueCreateFragmentFn {...this.props} />;
  }
}

/** @type {typeof IbanValueCreateFragmentFn} */
const IbanValueCreateFragment = injectIntl(IbanValueCreateFragmentClass);
export default IbanValueCreateFragment;
