const { 
  SET_VIDEO_ARCHIVE, 
  GET_VIDEO_ARCHIVE,
  SET_VIDEO_ARCHIVE_SQUAD_ID,
  GET_VIDEO_ARCHIVE_PROTESTS,
  SET_VIDEO_ARCHIVE_PROTESTS,
  UNSET_VIDEO_ARCHIVE_SQUAD_ID,
} = require('./actiontypes').default;

const initialState = {
  squadid: null,
  protestid: null,
  resolved: null,
  data: {},
  error: '',
  isFetching: false
};

const videoArchiveReducer = (state = initialState, action) => {
  switch (action.type) {
    case UNSET_VIDEO_ARCHIVE_SQUAD_ID:
      return { ...initialState };
    case SET_VIDEO_ARCHIVE_SQUAD_ID:
      const { squadid, protestid, resolved = false } = action.payload;
      if (!squadid) {
        return { ...initialState, squadid: action.payload };
      } else {
        return { ...initialState, squadid, protestid, resolved };
      }
    case GET_VIDEO_ARCHIVE:
      const { squadid: actionSquadId } = action.params || {};
      const { squadid: stateSquadId } = state.data || {};
      let fetchingStatus = true;
      if (actionSquadId === stateSquadId) {
        fetchingStatus = false;
      }
      return {  ...state, isFetching: fetchingStatus };
    case SET_VIDEO_ARCHIVE:
      if (action.error) {
        return { ...initialState, error: action.error };
      }
      return { ...state, data: action.payload, isFetching: false };
    case GET_VIDEO_ARCHIVE_PROTESTS: 
      return { ...state, isFetchingProtests: true};
    case SET_VIDEO_ARCHIVE_PROTESTS:
      const data = state.data;
      if (action.error) {
        return { ...state, isFetchingProtests: false };
      }
      return { ...state, data: { ...data, protests: action.payload, }, isFetchingProtests: false };
    default:
      return state;
  }
};

export default videoArchiveReducer;