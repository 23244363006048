import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Button } from '../../common/Button';
import '../../../style.scss';
import { Debounce } from 'react-throttle';
import Input from '../../common/Input';
import Select from '../../common/Select';
import OBModal from '../../common/Modal';
import lodash from 'lodash';
import {
  getAlikeUsers,
  manualTransaction,
  setTransactionResponse,
  getCurrencies
} from '../../../libs/reducers/adminReducer';
import {
  UserByIdFetch,
} from '../../../libs/reducers/actions';


const Transactions = ({
                        admin,
                        UserByIdFetch,
                        manualTransaction,
                        intl,
                        setTransactionResponse,
                        getAlikeUsers,
                        getCurrencies,
                      }) => {

  const [userid, setUserid] = useState(null)
  const [amount, setAmount] = useState(null)
  const [currency, setCurrency] = useState(admin.currencyList)
  const [commentary, setCommentary] = useState(null)
  const [userquery, setUserquery] = useState(null)
  const [currencies, setCurrencies] = useState([])
  const [userlist, setUserlist] = useState([])
  const [formkey, setFormkey] = useState(Math.random())
  const [diffcurrency, setDiffcurrency] = useState(null)

  const usePrevious = (value) => {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current === undefined ? value : ref.current
  }
  const prevProps = usePrevious({admin})

  useEffect(() => {
    console.log('aaaaa', currency, admin.currencyList)
    setCurrencies(
      admin.currencyList.map(curr => {
        return {
          value: curr.id,
          label: `${curr.name}, ${curr.symbol}`,
        }
      })
    )
  }, [admin.currencyList]);

  useEffect(() => {
    setTransactionResponse([])
    getCurrencies()
    setUserlist(
      admin.userPicker.map(usr => {
        return {
          value: usr.id,
          label: `id: ${usr.id}, login: ${usr.login}, name: ${usr.firstname} ${usr.lastname}, email: ${usr.email}`,
        }
      })
    )
  }, [])

  useEffect(() => {
    if (!lodash.isEqual(prevProps.admin.userPicker, admin.userPicker)) {
      setUserlist(
        admin.userPicker.map(usr => {
          return {
            value: usr.id,
            label: `id: ${usr.id}, login: ${usr.login}, name: ${usr.firstname} ${usr.lastname}, email: ${usr.email}`,
          }
        })
      )
    }
    setCurrencies(
      admin.currencyList.map(curr => {
        return {
          value: curr.id,
          label: `${curr.name}, ${curr.symbol}`,
        }
      })
    )
  }, [admin])

  const handleTransaction = () => {
    if (!!userid && !!amount && !!currency) {
      manualTransaction({ userid: userid.value, amount, currency: currency.value, commentary });
      setCommentary('')
      setAmount(0)
      setFormkey(Math.random())
    }
  }
  return (
    <div className='admin_panelWrapper'>
      <OBModal
        title={'Manual transaction'}
        visible={admin.modalResponse && admin.modalResponse.length}
        footer={
          <Button
            key="okButton"
            kind="aquamarine"
            closable={false}
            onClick={() => {
              setTransactionResponse([])
              getAlikeUsers(userquery)
            }}
          >
            OK
          </Button>}
      >
        <div style={{ padding: '20px 0' }}>
          {admin.modalResponse}
        </div>
      </OBModal>
      {intl.formatMessage({
        id: 'AdminPageNegativeValues',
        defaultMessage: 'You must state negative amount value to perform withdraw from account'
      })}
      <div className='admin_createTransaction'>
        <div className='user-wrap'>
          <Debounce time="1000" handler="onChangeText">
            <Input
              onChangeText={(e) => (setUserquery(e), getAlikeUsers(e))}
              value={userquery}
              placeholder={intl.formatMessage({ id: 'AdminPageUserSearch', defaultMessage: 'User search' })}
            />
          </Debounce>
          <Select
            isSearchable
            value={userid}
            placeholder={intl.formatMessage({ id: 'AdminPageUserInput', defaultMessage: 'User' })}
            onChange={(e) => setUserid(e)}
            options={userlist}
          />
        </div>
        <div className='currency-wrap'>
          <Select
            isSearchable
            value={currency}
            placeholder={intl.formatMessage({ id: 'Currency', defaultMessage: 'Currency' })}
            onChange={(e) => setCurrency(e)}
            options={currencies}
          />
          <Input
            onChangeText={(e) => setAmount(e)}
            value={amount}
            key={formkey}
            placeholder={intl.formatMessage({ id: 'AmountAsMoneyMeasureAlias', defaultMessage: 'Amount' })}
          />
        </div>
        <div className='suffix-wrap'>
          <Input
            onChangeText={(e) => setCommentary(e)}
            value={commentary}
            key={formkey}
            placeholder={intl.formatMessage({ id: 'AdminPageTransactionComment', defaultMessage: 'Comment' })}
          />
          <Button
            onClick={handleTransaction}
          >
            {intl.formatMessage({ id: 'TransactionSubmit', defaultMessage: 'Perform transaction' })}
          </Button>
        </div>
      </div>
      <div className="admin_accountTables">
        <div className="header">
          <div className="login">login</div>
          <div className="accs">
            <div>account id</div>
            <div>currency</div>
            <div>amount</div>
          </div>
        </div>
        {admin && admin.userPicker &&
        admin.userPicker.map(user => {
          return <div className='userwrap'>
            <span onClick={() => {
              UserByIdFetch({ userid: user.id })
            }}>{user.login}</span>
            <div className='accwrap'>
              {user.accounts.map(account => {
                return <div className='singleaccount'>
                  <div>{account.id}</div>
                  <div>{(admin.currencyList.find(curr => {
                    return account.currencyid === curr.id
                  }) || {}).name}</div>
                  <div>{account.amount}</div>
                </div>
              })}
            </div>
          </div>
        })
        }
      </div>
    </div>
  )
}

export default injectIntl(withRouter(
  connect(
    state => ({
      admin: state.admin,
    }),
    {
      getAlikeUsers,
      manualTransaction,
      setTransactionResponse,
      UserByIdFetch,
      getCurrencies,
    },
  )(Transactions),
));
