import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import OBModal from '../../../common/Modal';
import { Button } from '../../../common/Button';
import sendDocs from '../../../../images/send.docs.png'

import './style.scss';

export default class ModalDocsAdded extends React.Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    intl: intlShape,
    errorsString: PropTypes.array,
  };

  renderFooter = () => (
    <div
      className='dialog__buttons'>
      <Button
        onClick={this.props.onSubmit}
        kind='aquamarine'
        className='dialog__button-accept'
      >
        Ok
      </Button>
    </div>
  )

  renderContent = () => {
    const { intl, errorsString = [] } = this.props;

    if (typeof errorsString === 'string') {
      return (
        <span className="docs-added-dialog__label">{errorsString}</span>
      )
    }

    return (
      <>
        {(errorsString === [] || !errorsString.length)
          ? (<>
            <div className="docs-added-dialog__image-container">
              <img
                className="docs-added-dialog__image"
                src={sendDocs}
              />
            </div>
            <span className="docs-added-dialog__label">
              {intl.formatMessage({ id: 'YourRequestWillReviewed', defaultMessage: 'Your request will be reviewed within 48 hours.' })}
            </span>
          </>)
          : !!errorsString.length && errorsString.map(t => (
            <span className="docs-added-dialog__label">
              {t}
            </span>
            ))
        }
      </>
    );
  }


  render() {
    const { visible } = this.props;

    return (
      <OBModal
        visible={visible}
        closable={false}
        width={395}
        className='docs-added-dialog'
        footer={this.renderFooter()}
      >
        {this.renderContent()}
      </OBModal>
    );
  }
}
