import React, {useEffect, useState} from 'react';
import UploadListModeration from "./UploadListModeration";
import CheckBox from "../../../common/Checkbox";
import DataBlock from "./DataBlock";
import {store} from '../../../../store'
import {publicationBCBanner} from "../../../../libs/reducers/adminReducer";
import config from "../../../../config";


const ModerationPage = (props) => {
    const {banners, setBanners, type, activePage} = props
    const [selectedImage, setSelectedImage] = useState(null);
    const dispatch = store.dispatch
    const [weightArray, setWeightArray] = useState([]);

    function Publication() {
        selectedImage.weight = banners.find((banner => banner.name === selectedImage.name)).weight
        selectedImage.moderation_agreed = 1
        if (selectedImage.status === 'changed'){
            selectedImage.bc_name = selectedImage.bc_name.filter(name => !selectedImage.deselect_bc.includes(name))
        }

        const urlArr = selectedImage.url.split('/');
        const lengthArr = urlArr.length;

        const nameFile = (lengthArr > 1)
            ? `${urlArr[lengthArr - 2]}/${urlArr[lengthArr - 1]}`
            : null;

        selectedImage.name = nameFile
        setSelectedImage(null)
        if (!selectedImage.weight) {
            selectedImage.weight = 999
        }

        dispatch(publicationBCBanner({...selectedImage}))
    }

    useEffect(()=> {
        setSelectedImage(null)
    },[activePage])

    async  function getWeightArr () {
        try {
            const url = `${config.FETCH.url}/file/get_weight_arr`;
            const result = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const {success, weightArr} = await result.json();
            if (success){
                setWeightArray(weightArr)
            }
        } catch(err) {
            console.error('getWeightArr ERROR',err);
        }
    }

    useEffect(()=> {
        getWeightArr()
    },[])

    return (
        <div>
            <UploadListModeration
                banners={banners}
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                setBanners={setBanners}
            />
            {selectedImage &&
            <DataBlock
                banners={banners}
                setBanners={setBanners}
                selectedImage={selectedImage}
                Publication={Publication}
                type={type}
                weightArray={weightArray}
            />
            }
        </div>
    );
};

export default ModerationPage;
