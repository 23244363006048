import React from 'react';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Input from '../../common/Input';
import Radio from '../../common/Radio';
import Checkbox from '../../common/Checkbox';
import { Button } from '../../common/Button';
import OBDatePicker from '../../common/DatePicker';
import TextArea from '../../common/TextArea';
import {
  sendNotificationUpdate,
} from '../../../libs/reducers/adminReducer';

import './style.scss';

const RadioGroup = Radio.Group;

class NewVersion extends React.Component {
  constructor(props) {
    super(props);

    // Данные для отправки для уведомлений об обновлении
    this.state = {
      notification: {
        version: '',
        platform: 'all',
        users: 'test',
        emails: '',
        date: '',
        description: '',
        check: '',
      },
      // Ошибки
      invalidVersion: '',
      invalidCheck: '',
      invalidEmails: '',
      isVisibleReport: false, // Открыт ли отчет
      isSendNotification: false, // Идет отправка
    };
  }

  onSetValueNotification(type, e) {
    let value = e;

    if (e.target) {
      value = e.target.value;
    } else if (e.hasOwnProperty('value')) {
      value = e.value;
    }

    if (this.state.notification.hasOwnProperty(type)) {
      this.setState((prevState) => {
        const notification = { ...prevState.notification };

        if (type === 'users' && value !== 'specific') {
          notification['emails'] = '';
        }
        notification[type] = value;

        return { notification };
      });
    }
  }

  onSendNotificationUpdate = () => {
    this.setState({
      invalidVersion: '',
      invalidCheck: '',
      invalidEmails: '',
    });

    // Проверка перед отправкой
    let errors = {};
    const { check, version, users, emails, platform, date, description } = this.state.notification;

    if (!version) {
      errors['invalidVersion'] = this.props.intl.formatMessage({ id: 'requiredField', defaultMessage: 'Required field' });
    }
    if (!check) {    
      errors['invalidCheck'] = this.props.intl.formatMessage({ id: 'requiredField', defaultMessage: 'Required field' });
    }
    if (users === 'specific' && !emails) {
      errors['invalidEmails'] = this.props.intl.formatMessage({ id: 'requiredField', defaultMessage: 'Required field' });
    }

    if (Object.keys(errors).length) {
      this.setState(errors);

      return;
    }

    this.setState({ isSendNotification: true });

    // Формируем данныее для отправки
    const param = {
      versionnumber: version,
      notTest: (users === 'all') ? true : false,
      platform,
      versiondate: date && moment(date).format('YY-MM-DD') || null,
      description: description.replace(/(?:\r\n|\r|\n)/g, '<br>'),
      emails: (emails) ? JSON.stringify(emails.replace(/\s+/g,'').split(',')) : null,
    };

    this.props.sendNotificationUpdate(param);
  }

  onOpenReport = () => {
    this.setState(prevState => ({
      isVisibleReport: !prevState.isVisibleReport,
    }));
  }

  render() {
    const {
      intl,
      reportNotificationUpdate,
    } = this.props;
    const {
      notification,
      isVisibleReport,
    } = this.state;
    const { report } = reportNotificationUpdate || {};

    return (
      <div className="new-version__container">
        <div className="ap-row">
          <div className="ap-col ap-shift">
            <Input
              className=""
              onChangeText={this.onSetValueNotification.bind(this, 'version')}
              value={notification.version}
              placeholder="Version*"
            />
            {this.state.invalidVersion ? <div className="input__error">{this.state.invalidVersion}</div> : null}
          </div>
          <div className="ap-input__col ap-col">
            <p className="ap-input__label">Os</p>
            <div className="ap-input__elements">
              <RadioGroup name="platform" defaultValue="all" onChange={this.onSetValueNotification.bind(this, 'platform')} value={notification.platform}>
                <Radio value="all">To all</Radio>
                <Radio value="android">Android</Radio>
                <Radio value="ios">iOS</Radio>
              </RadioGroup>
            </div>
          </div>
        </div>
        <hr />
        <div className="radio-group-users">
          <p className="ap-input__label">Users</p>
          <div className="ap-row">
            <div className="ap-col ap-input__elements">
              <RadioGroup name="users" defaultValue="test" onChange={this.onSetValueNotification.bind(this, 'users')} value={notification.users}>
                <Radio value="test">Test</Radio>
                <Radio value="all">To all</Radio>
                <Radio value="specific">Specific</Radio>
              </RadioGroup>
            </div>
          </div>
        </div>
        { (notification.users === 'specific')
          ? (
            <div className="ap-row">
              <div className="ap-col">
                <Input
                  onChangeText={this.onSetValueNotification.bind(this, 'emails')}
                  value={notification.emails}
                  placeholder="emails"
                  name="email"
                />
                <p className="ap-input__description">List of email users separated by commas</p>
                {this.state.invalidEmails ? <div className="input__error">{this.state.invalidEmails}</div> : null}
              </div>
            </div>
          ) : null
        }
        <hr />
        <p className="ap-input__description">Writing to the database is carried out if the following fields are filled</p>
        <div className="ap-row">
          <div className="ap-col">
            <OBDatePicker
              placeholder={intl.formatMessage({ id: 'date', defaultMessage: 'Date' })}
              value={notification.date}
              onChange={(date, dateString) => this.onSetValueNotification('date', date)}
            />
          </div>
        </div>
        <div className="ap-row">
          <div className="ap-col">
            <TextArea
              value={notification.description}
              placeholder="Description"
              onChange={this.onSetValueNotification.bind(this, 'description')}
              rows={15}
            />
            <p className="ap-input__description">Description with html tags</p>
          </div>
        </div>
        <hr />
        <div className="ap-row">
          <div className="ap-col">
            <Checkbox
              checked={notification.check}
              onChange={this.onSetValueNotification.bind(this, 'check')}
            >Send notifications</Checkbox>
            {this.state.invalidCheck ? <div className="input__error">{this.state.invalidCheck}</div> : null}
          </div>
        </div>
        <div className="ap-btn--center ap-btn--margin-top">
          <Button
            className={'submit'}
            onClick={this.onSendNotificationUpdate}
          >Send</Button>
        </div>

        { (reportNotificationUpdate)
          ? <>
              <div className="ap-btn--center">
                <Button className={'submit'} onClick={() => this.setState({ isVisibleReport: !isVisibleReport })}>
                  {isVisibleReport ? 'Close' : 'Open'} report
                </Button>
              </div>
              
              {isVisibleReport ? (
                <div className="ap-report">
                  <p className="ap-report__title">Report</p>
                  <div className="ap-row">
                    <div className="ap-col">
                      <p className="ap-report__p"><strong>Sent PUSH: </strong>{report.successLog.pushn.length} / {report.totalPush}</p>
                    </div>
                    <div className="ap-col">
                      <p className="ap-report__p"><strong>Sent Email: </strong>{report.successLog.email.length} / {report.totalEmail}</p>
                    </div>
                  </div>
                  <div className="ap-report__block">
                    <p className="ap-report__p"><strong>Success</strong></p>
                      <div className="ap-report__list">
                        {
                          report.successLog.pushn.map((u, idx) => (
                            <p key={u.id}>{idx + 1}. {u.user} ({u.email || 'not email'}) {(u.count && u.count + 'уст.') || ''} - {u.type.toUpperCase()}</p>
                          ))
                        }
                        {
                          report.successLog.email.map((u, idx) => (
                            <p key={u.id}>{idx + 1}. {u.user} ({u.email || 'not email'}) - {u.type.toUpperCase()}</p>
                          ))
                        }
                      </div>
                  </div>
                  <div className="ap-report__block">
                    <p className="ap-report__p"><strong>Errors</strong></p>
                    <div className="ap-report__list">
                      {
                        report.errorsLog.pushn.map((u, idx) => (
                          <p key={u.id}>{idx + 1}. {u.user} ({u.email || 'not email'}) {u.platform} - {u.type.toUpperCase()}</p>
                        ))
                      }
                      {
                        report.errorsLog.email.map((u, idx) => (
                          <p key={u.id}>{idx + 1}. {u.user} ({u.email || 'not email'}) - {u.type.toUpperCase()}</p>
                        ))
                      }
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          : null
        }

        {
          (this.state.isSendNotification && !reportNotificationUpdate)
            ? (<p>Sending in progress ...</p>)
            : null
        }
      </div>
    )
  }
}

export default connect(
  state => ({
    reportNotificationUpdate: state.admin.reportNotificationUpdate,
  }),
  {
    sendNotificationUpdate,
  }
) (injectIntl(NewVersion));
