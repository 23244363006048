import { call, put } from 'redux-saga/effects';
import types from '../reducers/actiontypes';
import config from '../../config';



export function* _fetchTimestamp(api) {
  try {
    const startTime = Date.now();
    const url = `${config.FETCH.url}/ping?timestamp=${startTime}`;
    const result = yield call(api.get, { url });
    const endTime = Date.now();
    if (result.status === 200 && result.data.success) {
      let timestamp;
      const { headers } = result;
      if (result.data.timestamp) {
        timestamp = result.data.timestamp;
      } else if (headers.date) {
        timestamp = +(new Date(headers.date));
      } else {
        yield put({ type: types.SET_TIMESTAMP, error: 'Response headers do not contain Date' });
        return;
      }
      const diffTime = (startTime + (endTime - startTime) / 2) - timestamp;
      const modTime = diffTime < 0 ? -diffTime : diffTime;
      // если разница времени между сервером и устройством больше 2 сек
      // то записываем эту разницу в redux
      // иначе заносим нуль
      yield put({ type: types.SET_TIMESTAMP, payload: modTime > 2000 ? diffTime : 0 });
    } else {
      const { response } = result.data.error || {};
      const data = response ? response.data : {};
      yield put({ type: types.SET_TIMESTAMP, error: data.error || 'error' });
    }
  } catch (e) {
    console.log('===== timestamp.error: ', e);
    yield put({ type: types.SET_TIMESTAMP, error: e.message });
  }
}
