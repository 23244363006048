/**
 * @param {import('../personal-data-add.fragment').IPersonalData} personalData
 * @param {Array<{ id: number; name: string }>} countries
 * @return {import('../personal-data-add.fragment').IPersonalData}
 */
const sanitizePersonalData = (personalData, countries = []) => {
  const country = countries.find(c => c.id === personalData.countryId);
  const isLatvia = country ? country.name === 'Latvia' : false;
  if (isLatvia) {
    return {
      ...personalData, province: null, postcode: null, address: null,
    };
  }
  return { ...personalData, personalId: null };
};

export default sanitizePersonalData;
