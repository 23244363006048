/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { getLeaderBoard } from '../../libs/reducers/actions'
import { store } from '../../store'
import { findLast, ordinals } from './tools'

/**
 * @param {{ intl: any }} props 
 */
const useLiveCut = (props) => {
  const { intl, playerSquad, observer, leaderBoard, tournamentInfo } = props
  const [squad, setSquad] = useState(null)
  const [isObserver, setIsObserver] = useState(false)
  const dispatch = store.dispatch

  useEffect(() => {
    if (squad && squad.id) {
      dispatch({ type: 'WATCH_TOURNAMENT', params: squad.tournamentid })
      dispatch(getLeaderBoard({ tournamentid: squad.tournamentid }))
    }
  }, [squad && squad.id])

  useEffect(() => {
    if (observer.connected && observer.squadstate) {
      setSquad(observer.squadstate)
      setIsObserver(true)
    }
  }, [observer.connected, observer.squadstate])
  useEffect(() => {
    if (playerSquad && playerSquad.id) {
      setSquad(playerSquad)
      setIsObserver(false)
    }
  }, [playerSquad, playerSquad && playerSquad.id])

  if (!squad || !squad.id) {
    return null;
  }
  if (!leaderBoard || !Array.isArray(leaderBoard)) {
    return null;
  }
  if (!tournamentInfo || tournamentInfo.id !== squad.tournamentid) {
    return null;
  }
  if (tournamentInfo && tournamentInfo.tournamenttypeid !== 3) {
    // если не DROP_IN, то не показываем
    return null;
  }
  const { settings } = tournamentInfo
  const settingCountPrizes = settings.find(s => s.tournament_setting_brief === 'COUNT_PRIZES_PLACES')
  const countPrizePlaces = settingCountPrizes ? settingCountPrizes.value_int : undefined
  const minPrizeScore = typeof countPrizePlaces === 'number' && leaderBoard.length >= countPrizePlaces
    ? (leaderBoard[countPrizePlaces - 1] ? leaderBoard[countPrizePlaces - 1].bestSquad.results : undefined)
    : 0
  const playerid = squad.players[0].id;
  const curScore = squad.games.reduce((score, game) => {
    const frames = game.frames[playerid]
    const last = findLast(f => (
      f.shot1 != -1
      && (
        typeof f.score_handicap === 'number'
        || f.lastupdated
      )
    ), frames)
    return score + (last ? (last.score_handicap || last.score) : 0);
  }, 0)
  const curIndex = leaderBoard.findIndex(player => player.bestSquad.results <= curScore)
  const curPlace = (curIndex === -1 ? leaderBoard.length : curIndex) + 1
  const nextScore = leaderBoard[curPlace - 2]
    ? (curScore - leaderBoard[curPlace - 2].bestSquad.results)
    : 0
  const cut = typeof countPrizePlaces === 'number' ? minPrizeScore - curScore : undefined

  /** @type {string[]} */
  const modalText = []
  if (typeof countPrizePlaces === 'number') {
    modalText.push(intl.formatMessage({
      id: 'LiveLeaderBoardCut',
      defaultMessage: `CUT to the ${countPrizePlaces}${ordinals(countPrizePlaces)} place of the Leaderboard is ${cut}`,
    }, {
      position: countPrizePlaces,
      value: cut,
    }))
    modalText.push('\n')
  }
  modalText.push(intl.formatMessage({
    id: isObserver ? 'LiveLeaderBoardCurrentPositionObserver' : 'LiveLeaderBoardCurrentPosition',
    defaultMessage: `${isObserver ? 'Current' : 'Your current'} position is #${curPlace}`,
  }, {
    position: curPlace,
  }))
  if (curPlace !== 1) {
    modalText.push('\n')
    modalText.push(intl.formatMessage({
      id: 'LiveLeaderBoardNextPosition',
      defaultMessage: `(CUT to #${(curPlace - 1)} is ${nextScore})`,
    }, {
      position: curPlace - 1,
      score: nextScore,
    }))
  }

  return { countPrizePlaces, curPlace, nextScore, modalText, cut }
}

export { useLiveCut }
