import { put, takeLatest, takeEvery } from 'redux-saga/effects';
// import cookieManager from 'react-native-cookies';
import setCookieParser from 'set-cookie-parser';
import bugsnagClient from '../../bugsnagClient';
import Utils from '../utils';

const { getOrigin } = Utils;

const { SET_COOKIES, SET_COOKIES_FETCH, CLEAR_COOKIES, CLEAR_COOKIES_FETCH } = require('../reducers/actiontypes').default;
const array2string = array => Array.isArray(array) ? array.map(array2string).join('; ') : array;

const expires2string = (cookies = {}) => {
  const names = Object.keys(cookies);
  for (const name of names) {
    const { expires } = cookies[name];
    if (expires instanceof Date) {
      cookies[name].expires = expires.toISOString();
    }
  }
}

export function* setCookiesFromResponse({ payload = {} } = {}) {
  const { setcookies, url } = payload;
  const splittedCookieHeaders = setCookieParser.splitCookiesString(array2string(setcookies));
  const cookies = setCookieParser.parse(splittedCookieHeaders, { map: true });
  try {
    expires2string(cookies);
    yield put({ type: SET_COOKIES, payload: { origin: getOrigin(url), cookies } });
  } catch (err) {
    console.log('error => ', err);
  }
}

/**
 *
 * @param {object} [param0]
 * @param {object} [param0.payload]
 * @param {string} [param0.payload.origin] - cookie origin
 * @param {string|string[]} [param0.payload.names] - cookie names
 */
export function* clearCookies({ payload } = {}) {
  yield put({ type: CLEAR_COOKIES, payload });
}

export function* watchCookies() {
  try {
    yield takeEvery(SET_COOKIES_FETCH, setCookiesFromResponse);
    yield takeLatest(CLEAR_COOKIES_FETCH, clearCookies);
  } catch (error) {
    bugsnagClient.notify(error, { context: 'saga-watchCookies' });
  }
}
