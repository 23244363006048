import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import '../../../style.scss';
import Select from '../../common/Select';
import { Table } from 'antd';
import Input from '../../common/Input';
import { Button } from '../../common/Button';
import {
  requestTransfer,
  getSystemAccounts,
} from '../../../libs/reducers/adminReducer';


const SystemAccounts = ({
  admin,
  intl,
  requestTransfer,
  getSystemAccounts,
}) => {

  const [transferin, setTransferin] = useState()
  const [transferout, setTransferout] = useState()
  const [transferamount, setTransferamount] = useState()
  const [transfercommentary, setTransfercommentary] = useState('')

  useEffect(() => {
    getSystemAccounts()
  }, [])

  const systemAccountsColumns = [
    {
      title: 'id',
      dataIndex: 'id',
      sorter: (a, b) => (a.id - b.id),
      render: (data, record, index) => ({
        props: {},
        children: (
          <span class='innerText'>#{data}</span>
        ),
      }),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      sorter: (a, b) => (a.amount - b.amount),
      render: (data, record, index) => ({
        props: {},
        children: (
          <span class='innerText'>{data} {record.currency}</span>
        ),
      }),
    },
    {
      title: 'Alias',
      dataIndex: 'alias',
      sorter: (a, b) => (a.alias - b.alias),
      render: (data, record, index) => ({
        props: {},
        children: (
          <span class='innerText'>{data}</span>
        ),
      }),
    },
  ];
  const systemAccountsHistoryColumns = [
    {
      title: 'id',
      dataIndex: 'id',
      sorter: (a, b) => (a.id - b.id),
      render: (data, record, index) => ({
        props: {},
        children: (
          <span class='innerText'>#{data}</span>
        ),
      }),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      sorter: (a, b) => (a.amount - b.amount),
      render: (data, record, index) => ({
        props: {},
        children: (
          <span class='innerText'>{data} {record.currency}</span>
        ),
      }),
    },
    {
      title: 'Accounts',
      dataIndex: '',
      render: (data, record, index) => ({
        props: {},
        children: (
          <div className='backlog'>{record.outcomeaccountid || 'SYSTEM'} <span style={{ color: '#f65932', margin: '15px 0' }}>{`=>`}</span> {record.incomeaccountid || 'SYSTEM'}</div>
        )
      })
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      render: (data, record, index) => ({
        props: {},
        children: (
          <span class='innerText'>{data}</span>
        ),
      }),
    },
    {
      title: 'Date',
      dataIndex: 'created',
      render: (data, record, index) => ({
        props: {},
        children: (
          <span class='innerText'>{moment(data).format('DD MMM YYYY HH:mm:ss')}</span>
        ),
      }),
    },
    {
      title: 'Initiator',
      dataIndex: 'createdby',
      sorter: (a, b) => (a.createdby - b.createdby),
      render: (data, record, index) => ({
        props: {},
        children: (
          <span class='innerText'>{data} ({record.createdbylogin})</span>
        ),
      }),
    },
  ];
  const accountsMap = (admin.systemAccounts || []).map(datum => ({
    value: datum.id,
    label: `id: ${datum.id} | ${datum.alias}`,
    currency: datum.currencyid,
  }))

  return (
    <div className='admin_panelWrapper'>
      <div className='systemAccountsWrapper' style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
        <Table
          className="table"
          columns={systemAccountsColumns}
          dataSource={(admin.systemAccounts || [])}
          pagination={false}
          rowKey="id"
          style={{ flex: '0 0 50%' }}
          locale={{ emptyText: 'No transactions' }}
          rowClassName={'transactionRow'}
          onRow={(record, index) => { }}
        />
        <div className='TransferForm' style={{ flex: '0 0 50%' }}>
          <Input
            className={'amount'}
            onChangeText={(e) => setTransferamount(e)}
            value={transferamount}
            placeholder={'Amount'}
          />
          <Select
            isSearchable
            className={'account'}
            value={transferout}
            placeholder={'Outlet account'}
            onChange={(e) => (setTransferout(e), setTransferin(null))}
            options={accountsMap}
          />
          <Select
            isSearchable
            className={'account'}
            value={transferin}
            placeholder={'Inbox account'}
            onChange={(e) => setTransferin(e)}
            options={accountsMap.filter(datum => datum.value !== (transferout || {}).value && datum.currency === (transferout || {}).currency)}
          />
          <Input
            className={'comment'}
            onChangeText={(e) => setTransfercommentary(e)}
            value={transfercommentary}
            placeholder={'Comment'}
          />
          <Button className={'submit'} onClick={() => requestTransfer({ accountidfrom: transferout.value, accountidto: transferin.value, amount: transferamount, commentary: transfercommentary })}>
            {intl.formatMessage({ id: 'TransactionSubmit', defaultMessage: 'Perform transaction' })}
          </Button>

        </div>
        <Table
          className="table"
          columns={systemAccountsHistoryColumns}
          dataSource={admin.systemAccountsHistory}
          pagination={false}
          rowKey="id"
          style={{ flex: '0 0 100%' }}
          locale={{ emptyText: 'No transactions' }}
          rowClassName={'transactionRow'}
          onRow={(record, index) => { }}
        />
      </div>
    </div>
  )
}

export default injectIntl(withRouter(
  connect(
    state => ({
      admin: state.admin,
    }),
    {
      requestTransfer,
      getSystemAccounts,
    },
  )(SystemAccounts),
));
