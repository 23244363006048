import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';

import { FilterInput } from '../../common/Input';
import Select from '../../common/Select';
import { statusTextDisplay, optionsTypes, optionsStatuses } from '../../tournament/tournaments/tournamentsTable';
import Avatar from '../../common/Avatar';
import './style.scss';

class TournamentsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tournaments: props.tournaments || [],
      filterType: '',
      filterInput: '',
      filterCostMin: '',
      filterCostMax: '',
      filterStatus: '',
      clickedRow: props.clickedRow || 0,
      clickedTournament: props.clickedTournament || 0,
      loadedData: false,
    };

    this.columns = [
      {
        title: props.intl.formatMessage({ id: 'date', defaultMessage: 'Date' }),
        dataIndex: 'createdDate',
        render: (data, record) => moment(record.lastupdated).format('DD MMM HH:mm'),
      },
      {
        title: props.intl.formatMessage({ id: 'NameAsIdentifier', defaultMessage: 'Name' }),
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: props.intl.formatMessage({ id: 'ParticipantsAsTournamentMembersAlias', defaultMessage: 'Participants' }),
        dataIndex: 'countplayers',
        className: 'tableColumnNumbers',
        sorter: (a, b) => a.countplayers - b.countplayers,
      },
    ];
  }

  static propTypes = {
    tournaments: PropTypes.arrayOf(PropTypes.shape({})),
    intl: intlShape,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = { ...prevState }
    if (nextProps.tournaments && !prevState.loadedData) {
      state = {
        ...state,
        tournaments: nextProps.tournaments,
        loadedData: true,
      }
    }
    if (
      prevState.filterInput
      || prevState.filterType
      || prevState.filterCostMin
      || prevState.filterCostMax
      || prevState.filterStatus
    ) {
      let tempTournaments = [...nextProps.tournaments];
      if (prevState.filterInput !== '') {
        tempTournaments = tempTournaments.filter(tournament => _.startsWith(tournament.name.toLowerCase(), prevState.filterInput.toLowerCase()));
      }
      if (prevState.filterType !== '') {
        tempTournaments = tempTournaments.filter(tournament => tournament.typename === prevState.filterType.label);
      }
      if (prevState.filterCostMin !== '') {
        tempTournaments = tempTournaments.filter(tournament => tournament.cost >= prevState.filterCostMin.value);
      }
      if (prevState.filterCostMax !== '') {
        tempTournaments = tempTournaments.filter(tournament => tournament.cost <= prevState.filterCostMax.value);
      }
      if (prevState.filterStatus !== '') {
        tempTournaments = tempTournaments.filter(
          tournament => statusTextDisplay(tournament).props.children === prevState.filterStatus.label,
        );
      }
      state = { ...state, tournaments: tempTournaments, clickedTournament: tempTournaments[0] }
    }
    if (nextProps.tournaments && nextProps.tournaments.length) {
      state = { ...state, tournaments: nextProps.tournaments }
    }
    return state;
  }

  onReset = (e) => {
    e.preventDefault();
    this.setState({
      filterInput: '',
      filterType: '',
      filterCostMin: '',
      filterCostMax: '',
      filterStatus: '',
    });
  };

  render() {
    let optionsCostMin = [];
    const {
      filterInput,
      filterType,
      filterCostMin,
      filterCostMax,
      filterStatus,
      tournaments,
      loadingTable,
    } = this.state;
    const { tournaments: tournamentsProps, intl } = this.props;
    if (tournaments && tournaments.length) {
      optionsCostMin = [...tournamentsProps];
      optionsCostMin = optionsCostMin.map(option => option.cost);
      optionsCostMin = _.uniq(optionsCostMin);
      optionsCostMin = _.sortBy(optionsCostMin, e => e);
      optionsCostMin = optionsCostMin.map(option => ({ value: option, label: `${option}$` }));
    }
    return (
      <div className="myTournaments">
        <div className="myTournaments__filters">
          <div className="myTournaments__filters-inputs">
            <div className="myTournaments__filters-input">
              <FilterInput
                value={filterInput}
                placeholder={intl.formatMessage({ id: 'MyTournamentsGameField', defaultMessage: 'Find a tournament' })}
                onChange={(e) => {
                  this.setState({
                    filterInput: e.target.value,
                  });
                }}
              />
            </div>
            <div className="myTournaments__filters-input myTournaments__filters-input_type">
              <Select
                value={filterType}
                options={optionsTypes}
                placeholder={intl.formatMessage({ id: 'TypeSelectPlaceholder', defaultMessage: 'Type' })}
                onChange={(e) => {
                  this.setState({
                    filterType: e,
                  });
                }}
              />
            </div>
            <div className="myTournaments__filters-input myTournaments__filters-input_fee">
             {/* <div className="myTournaments__label">{intl.formatMessage({ id: 'fee', defaultMessage: 'Fee' })}</div>*/}
              <div style={{ marginRight: 10 }} />
              <div className="myTournaments__filters-select-small">
                <div style={{ width: 74, marginRight: 10 }}>
                  <Select
                    value={filterCostMin}
                    options={optionsCostMin}
                    placeholder={intl.formatMessage({ id: 'from', defaultMessage: 'From' })}
                    onChange={(e) => {
                      this.setState({
                        filterCostMin: e,
                      });
                    }}
                  />
                </div>
                <div style={{ width: 74 }}>
                  <Select
                    value={filterCostMax}
                    options={optionsCostMin}
                    placeholder={intl.formatMessage({ id: 'to', defaultMessage: 'To' })}
                    onChange={(e) => {
                      this.setState({
                        filterCostMax: e,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="myTournaments__filters-input myTournaments__filters-input_status">
              <Select
                value={filterStatus}
                options={optionsStatuses}
                placeholder={intl.formatMessage({ id: 'StatusAsStateAlias', defaultMessage: 'Status' })}
                onChange={(e) => {
                  this.setState({
                    filterStatus: e,
                  });
                }}
              />
            </div>
          </div>
          <Link to="#" onClick={this.onReset} className="myTournaments__filters-reset">
            {intl.formatMessage({ id: 'reset', defaultMessage: 'Reset' })}
          </Link>
        </div>
        <div className="myTournaments__table">
          <Table
            key={this.props.langs}
            className="table"
            columns={this.columns}
            dataSource={tournaments.length ? (tournaments || []).filter(tournament => tournament.isbattle === 0) : []}
            pagination={false}
            rowKey="id"
            style={{ width: '100%' }}
            locale={{ emptyText: intl.formatMessage({ id: 'no_tournaments', defaultMessage: 'No tournaments' }) }}
            loading={loadingTable}
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(
  withRouter(
    connect(
      state => ({
        langs: state.langs.lang,
      }),
      {},
    )(TournamentsTable),
  ),
);
