import React from 'react';
import ComAvatar from '../../common/Avatar';
import config from '../../../config';
import './style.scss';

export function InfoText(props) {
  const { label, text, nounderline } = props;
  return (
    <div className={nounderline ? 'infoText' : 'infoText underline'}>
      <div className='label'>{label}</div>
      <div className='text'>{text}</div>
    </div>
  );
}

export function Avatar(props) {
  const { player, color, noScore } = props;
  const score = noScore ? null : player.avgscore;
  let borderColor = '';
  switch (color) {
    case 'green':
      borderColor = 'green__grm';
      break;
    case 'orange':
      borderColor = 'orange__grm';
      break;
    default:
      borderColor = '';
  }
  return (
    <div className={`avatar__grm ${borderColor}`}>
      <ComAvatar
        uri={player.photo ? `${config.FETCH.img}/${player.photo}` : null}
        avgscore={score}
        flag={player.country_live}
      />
    </div>
  );
}