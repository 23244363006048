import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import Avatar from '../common/Avatar';
import { Button } from '../common/Button';

import './style.css';

class UserInfoPanel extends Component {
  static propTypes = {
    avatarUri: PropTypes.string,
    flag: PropTypes.string,
    name: PropTypes.string,
    login: PropTypes.string,
    balance: PropTypes.array,
    isOnline: PropTypes.bool,
    // status: PropTypes.number.isRequired,
    intl: intlShape,
    avgscore: PropTypes.number,
  };

  static defaultProps = {
    avatarUri: null,
    flag: 'russia',
    name: 'name',
    login: 'login',
    balance: [],
  }

  render() {
    const { intl, isOnline, balance, avgscore } = this.props;
    return (
      <div id="user-info-panel">
        <Avatar uri={this.props.avatarUri} flag={this.props.flag} isOnline={isOnline} avgscore={(+avgscore && (+avgscore).toFixed(0)) || 0}/>
        <div className="user-info">
          <div className="user-info__name">{this.props.name}</div>
          <div className="user-info__login">{this.props.login}</div>
          <div className="user-info__balance">
            <div>
              <label>{intl.formatMessage({ id: 'userInfoBalance', defaultMessage: 'Balance' })}</label>
              {!balance.length
                ? intl.formatMessage({ id: 'userInfoNoBalance', defaultMessage: 'No active wallets' })
                : null}
              {balance.map((account, index) => {
                return `${account.amount}${account.currencysymbol}${( balance.length-1 === index) ? '' : ' | '}`
              })}
            </div>
            <a href='/account/cash'>
            <Button className="user-info__balance__button-cash" size="xs" onClick={() => {}}>
              {intl.formatMessage({ id: 'userInfoCash', defaultMessage: 'Cash' })}
            </Button>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(UserInfoPanel);
