import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { parse, DOM } from 'xml-parse';

import './style.scss';

export default class NotificationPopup extends Component {
  static propTypes = {
    message: PropTypes.element,
    created: PropTypes.string,
  };

  static defaultProps = {
    type: '',
    message: <span />,
    created: moment.now().toString(),
  };

  render() {
    const { created, message } = this.props;

    const notification = new DOM(parse(message));

    const type = notification.document.getElementsByTagName('type')[0].childNodes[0].text;
    let newType = type;

    const content = [];
    notification.document.getElementsByTagName('content')[0].childNodes.forEach((child, index) => {
      if (child.text) {
        content.push(child.text);
      } else {
        content.push(
          <span key={index} className={child.tagName}>
            {child.innerXML}
          </span>,
        );
      }
    });

    switch (newType) {
      case 'accept':
      case 'gameAccept':
      case 'battleCancelOwner':
      case 'battleExitOwner':
        newType = 'accept';
        break;
      case 'battleCancelCompetitor':
      case 'battleExitCompetitor':
      case 'decline':
        newType = 'decline';
        break;
      default:
        newType = '';
    }
    const title = 'Battle';
    return (
      <div className="notification">
        <div className={`notification__type ${newType}`} />
        <div className="notification__message">
          <div className="notification__header">
            <div className="notification__title">{title}</div>
            <div className="notification__date">
              {moment(created)
                .format('D MMM - h:mm')
                .toString()}
            </div>
          </div>
          <div className="notification__content">
            <div className="notification__text">
              {_.map(content, (child, index) => (
                <div key={index}>{child}</div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
