import React from 'react';
import { Table } from 'antd';
import Flag from '../../common/Flag';
import SvgCountry from '-!svg-react-loader!../../../images/country.svg'; // eslint-disable-line
import './style.css';

export default class BowlingCenterList extends React.Component {
  render() {
    const { bowlingCenterList, intl } = this.props;
    const columns = [
      {
        title: intl.formatMessage({ id: 'TitleNameBowlingCenterList', defaultMessage: 'Name BC' }),
        dataIndex: 'name',
        width: 133,
      },
      {
        title: <div className="bowlingcenter-flag__title"><SvgCountry /></div>,
        dataIndex: 'iso',
        width: 70,
        render: (data, record) => (

          <div className="bowlingcenter-flag">
            <Flag name={record.iso} />
          </div>
        ),
      },
      {
        title: intl.formatMessage({ id: 'TitleAddressBowlingCenterList', defaultMessage: 'Address' }),
        dataIndex: 'address',
        width: 379,
      },
      {
        title: intl.formatMessage({ id: 'TitleLinesBowlingCenterList', defaultMessage: 'Connected lanes' }),
        render: (data, record) => (
          <div className="bowlingcenter-lines">
            {(record.linesstructure.map(linestructure => linestructure.linenumber)).join(', ')}
          </div>
        ),
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={bowlingCenterList}
        pagination={false}
      />
    );
  }
}
