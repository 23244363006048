import { put, call, takeLatest } from 'redux-saga/effects';
import { put as methodPUT, post as methodPOST } from '../Networking';
import config from '../../config';

/**
 * @param {object} param0
 * @param {number} param0.tournamentid
 * @param {FormData} param0.formData
 */
export const reglamentCreateRequestAction = ({ tournamentid, formData }) => ({ type: 'REGLAMENT_CREATE_REQUEST', params: { tournamentid, formData } });
/**
 * @param {object} param0
 * @param {number} param0.reglamentid
 * @param {number} param0.tournamentid
 */
export const reglamentDeleteRequestAction = ({ reglamentid, tournamentid }) => ({ type: 'REGLAMENT_DELETE_REQUEST', params: { reglamentid, tournamentid } });
export const reglamentCreateSuccessAction = () => ({ type: 'REGLAMENT_CREATE_SUCCESS' });
export const reglamentDeleteSuccessAction = () => ({ type: 'REGLAMENT_DELETE_SUCCESS' });
/** @param {any} [error] */
export const reglamentCreateFailureAction = error => ({ type: 'REGLAMENT_CREATE_FAILURE', params: { error } });
/** @param {any} [error] */
export const reglamentDeleteFailureAction = error => ({ type: 'REGLAMENT_DELETE_FAILURE', params: { error } });

export const initialReglamentState = {
  fetching: false,
  error: false,
};

export const reglamentReducer = (state = initialReglamentState, action) => {
  switch (action.type) {
    case 'REGLAMENT_CREATE_REQUEST':
    case 'REGLAMENT_DELETE_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      };
    case 'REGLAMENT_CREATE_SUCCESS':
    case 'REGLAMENT_DELETE_SUCCESS':
      return {
        ...state,
        fetching: false,
      };
    case 'REGLAMENT_CREATE_FAILURE':
    case 'REGLAMENT_DELETE_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      };
    default:
      return state;
  }
};

export function* reglamentCreateSaga(api, action) {
  try {
    const { tournamentid, formData } = action.params;
    const res = yield call(api.post, { url: `${config.FETCH.url}/tournament/reglament/${tournamentid}`, data: formData });
    if (res.status === 200) {
      yield put(reglamentCreateSuccessAction());
      yield put({ type: 'WATCH_TOURNAMENT', params: +tournamentid });
    } else {
      yield put(reglamentCreateFailureAction(res.problem));
    }
  } catch (e) {
    yield put(reglamentCreateFailureAction(e.message));
  }
}

export function* reglamentDeleteSaga(api, action) {
  try {
    const { reglamentid, tournamentid } = action.params;
    const res = yield call(api.put, { url: `${config.FETCH.url}/tournament/reglament`, data: { reglamentid, tournamentid } });
    if (res.status === 200) {
      yield put(reglamentDeleteSuccessAction());
      yield put({ type: 'WATCH_TOURNAMENT', params: +tournamentid });
    } else {
      yield put(reglamentDeleteFailureAction(res.problem));
    }
  } catch (e) {
    yield put(reglamentDeleteFailureAction(e.message));
  }
}


export function* watchReglament() {
  const api = { post: methodPOST, put: methodPUT };
  yield takeLatest('REGLAMENT_CREATE_REQUEST', reglamentCreateSaga, api);
  yield takeLatest('REGLAMENT_DELETE_REQUEST', reglamentDeleteSaga, api);
}
