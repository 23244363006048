import {
  put, call, takeLatest, takeEvery, select,
} from 'redux-saga/effects';
import bugsnagClient from '../../bugsnagClient';
import _ from 'lodash';
// import moment from 'moment';
import config from '../../config';
import {
  SQUAD_CHAT_UPDATE,
  SOCKET_UPDATE_FRAMES,
  SOCKET_SEND_FRAMEEDIT,
  CHANGE_FRAME_SET,
  PROTEST_REPLY,
  SOCKET_EVENT_CONF_RESET,
  GAME_PAUSE_TOGGLE,
  SQUAD_FRAME_INPUT,
} from '../reducers/squads.reducer';
import { get, put as putMethod , post} from '../Networking';
import { _SocketEmit } from './Socket';
const { CHECKED_EXPECTED_GAMES } = require('../reducers/actiontypes').default

const getSquads = state => state.squads;
const getEvents = state => state.events;

const generateSquadData = ({
  frameKey,
  squads,
  score,
  flags,
}) => {
  const draftSquads = _.cloneDeep(squads);
  _.update(draftSquads, frameKey, frame => ({ ...frame, shot1: score.shot1, flags }));
  _.update(draftSquads, frameKey, frame => ({ ...frame, shot2: score.shot2, flags }));
  _.update(draftSquads, frameKey, frame => ({ ...frame, shot3: score.shot3, flags }));
  return draftSquads;
};

export function* _sendFrameEdit(params) {
  try {
    yield call(_SocketEmit, {
      params: {
        params: {
          params: params.params,
          action: 'frameChange',
          userid: [0],
        },
        eventName: 'request',
      },
    });
  } catch (err) {
    bugsnagClient.notify(err, { context: 'Edit Frame' });
    console.error('Edit Frame: ', err);
  }
}

export function* _updateChat({ params }) {
  const { fromServer } = params;
  const squads = yield select(getSquads);

  try {
    if (!fromServer) {
      yield call(_SocketEmit, {
        params: {
          params: {
            params,
            action: 'chatMessage',
            userid: [0],
          },
          eventName: 'request',
        },
      });
    }
  } catch (err) {
    bugsnagClient.notify(err, { context: 'Chat update' });
    console.error('Chat update: ', err);
  }
}

export function* _changeFrameConfirm(params) {
  try {
    // make confirmation via socket
    // yield call(putMethod, { url: `${config.FETCH.url}/change/frame`, data: { changeid: params.params.changeid, confirmed: params.params.confirmed } });
    yield call(_SocketEmit, {
      params: {
        eventName: 'request',
        params: {
          action: 'confirmFrameChanges',
          params: {
            changeid: params.params.changeid,
            confirmed: params.params.confirmed,
          },
          userid: [0],
        },
      },
    });
  } catch (error) {
    console.log('Change frame confirmation: ', error);
  }
}

export function* _protestReply(params) {
  try {
    yield call(_SocketEmit, {
      params: {
        params: {
          params: params.params,
          action: 'protestReply',
          userid: [0],
        },
        eventName: 'request',
      },
    });
  } catch (error) {
    console.log('Unable to send socket with protest reply: ', error);
  }
}

export function* _togglePause(params) {
  try {
    yield call(_SocketEmit, {
      params: {
        params: {
          params: params.params,
          action: 'pauseToggle',
          userid: [0],
        },
        eventName: 'request',
      },
    });
  } catch (error) {
    console.log('Unable to toggle pause due to socket fail');
  }
}

export function* _SquadFrameInput(params) {
  try {
    yield call(_SocketEmit, {
      params: {
        eventName: 'request',
        params: {
          action: 'squadFrameInput',
          userid: [0],
          params: params.params,
        },
      },
    });
  } catch (error) {
    console.error('Failed to send frame status');
  }
}

export function* _checkExpectedGames(params) {
  try {
    const { data } = yield  call(post, { url: `${config.FETCH.url}/tournament/checking_open` });
    if (data.error) return;
    yield put({ type: CHECKED_EXPECTED_GAMES, payload: data.result });
  } catch (error) {
    yield put({type: CHECKED_EXPECTED_GAMES, payload: {}});

  }
}

export function* _WatchSquads() {
  try {
    yield takeEvery(PROTEST_REPLY, _protestReply);
    yield takeEvery(SQUAD_CHAT_UPDATE, _updateChat);
    yield takeEvery(SOCKET_SEND_FRAMEEDIT, _sendFrameEdit);
    yield takeEvery(CHANGE_FRAME_SET, _changeFrameConfirm);
    yield takeEvery(GAME_PAUSE_TOGGLE, _togglePause);
    yield takeEvery(SQUAD_FRAME_INPUT, _SquadFrameInput);
  } catch (error) {
    bugsnagClient.notify(error, { context: 'saga WatchSquads' });
    console.error('===WatchSquads Error: ', error);
  }
}
