import {
  call, put, takeLatest, takeEvery,
} from 'redux-saga/effects';
import bugsnagClient from '../../bugsnagClient';
import config from '../../config';
import { get, put as PUT, post } from '../Networking';

import {
  FRIENDS_FETCH,
  FRIENDS_SET,
  FRIEND_DELETE,
  FRIEND_ADD_FETCH,
  FRIEND_ADD_SET,
  FRIEND_ACCEPT,
  FRIEND_DECLINE,
  FRIEND_SEARCH_FETCH,
  FRIEND_SEARCH_SET,
} from '../reducers/FriendsReducer';

import { COMMON_NOTIFICATION } from '../reducers/commonReducer';

export function* _GetFriends() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/user/friend` });
    if (result.status === 200 && result.data.success) {
      yield put({ type: FRIENDS_SET, data: result.data.data });
    } else {
      yield put({ type: FRIENDS_SET, error: result.data.error || result.status });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'GetFriends' });
    console.log('GetFriends: ', error);
  }
}

export function* _AddFriend({ params: user }) {
  try {
    const result = yield call(post, { url: `${config.FETCH.url}/user/friend`, data: { userid: user.id } });
    
    if (result.data.errortype === 'alreadyFriends') {
      // Уведомление что вы уже являетесь друзьями
      yield put({ type: COMMON_NOTIFICATION, data: result.data.errortype });
      //yield put({ type: FRIEND_ADD_SET, error: result.data.errortype });
    } else if (result.status === 200 && result.data.success) {
      //yield put({ type: FRIEND_ADD_SET, data: result.data.data });
      //yield put({ type: FRIENDS_FETCH });
    } else {
      //yield put({ type: FRIEND_ADD_SET, error: result.data.error || result.status });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'AddFriend' });
    console.log('AddFriend: ', error);
  }
}

export function* _DeleteFriend(params) {
  try {
    const result = yield call(PUT, { url: `${config.FETCH.url}/user/friend/delete`, data: { userid: params.params } });

    if (result.data.errortype === 'alreadyNotFriends') {
      // Уведомление, что вы уже не являетесь друзьями
      yield put({ type: COMMON_NOTIFICATION, data: result.data.errortype });
      //yield call(_GetFriends);
    } else if (result.status === 200 && result.data.success) {
      //yield call(_GetFriends);
    } else {
      // TODO
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'DeleteFriend' });
    console.log('DeleteFriend: ', error);
  }
}

export function* _AcceptFriend(params) {
  try {
    const result = yield call(PUT, { url: `${config.FETCH.url}/user/friend/confirm`, data: { userid: params.params } });

    if (result.data.errortype === 'alreadyNotFriends') {
      // Уведомление, что вы уже не являетесь друзьями
      yield put({ type: COMMON_NOTIFICATION, data: result.data.errortype });
      yield call(_GetFriends);
    } else if (result.status === 200 && result.data.success) {
      yield call(_GetFriends);
    } else {
      // TODO
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'DeleteFriend' });
    console.log('DeleteFriend: ', error);
  }
}

export function* _DeclineFriend(params) {
  try {
    const result = yield call(PUT, { url: `${config.FETCH.url}/user/friend/delete`, data: { userid: params.params } });

    if (result.status === 200 && result.data.success) {
      yield call(_GetFriends);
    } else {
      // TODO
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'DeleteFriend' });
    console.log('DeleteFriend: ', error);
  }
}

export function* _SearchPlayers(params) {
  try {
    const result = yield call(get, {
      url: `${config.FETCH.url}/user/by?${params.params}`,
    });
    if (result.status === 200 && result.data.success) {
      yield put({ type: FRIEND_SEARCH_SET, data: result.data.data });
    } else {
      yield put({ type: FRIEND_SEARCH_SET, error: result.data.error || result.status });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'DeleteFriend' });
    console.log('DeleteFriend: ', error);
  }
}

export function* _WatchFriends() {
  try {
    yield takeLatest(FRIENDS_FETCH, _GetFriends);
    yield takeEvery(FRIEND_ADD_FETCH, _AddFriend);
    yield takeLatest(FRIEND_DELETE, _DeleteFriend);
    yield takeLatest(FRIEND_ACCEPT, _AcceptFriend);
    yield takeLatest(FRIEND_DECLINE, _DeclineFriend);
    yield takeLatest(FRIEND_SEARCH_FETCH, _SearchPlayers);
  } catch (error) {
    bugsnagClient.notify(error, { context: 'saga WatchFriends' });
    console.error('===WatchFriends Error: ', error);
  }
}
