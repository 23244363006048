import React, { useEffect, useRef, useState } from 'react';
import logo from "./imgs/logo.png";
import player_icon from './imgs/player.svg'
import cup from './imgs/cup.svg';
import geo from './imgs/geo.svg';
import calendar from './imgs/calendar.svg'
import currency from './imgs/currency.svg'
import swords from './imgs/swords.svg'
import flag from './imgs/flag.svg'
import place_holder from './imgs/place_holder.svg';
import qr from './imgs/qrCode.png'
import './style.scss';
import Avatar from "../common/Avatar";
import config from "../../config";
import defaultAvatar from "../../images/defaultAvatar.png";
import Flag from "../common/Flag";
import moment from "moment";
import { get } from "../../libs/Networking";
import { useGameCostText } from '../GameCost/useGameCostText';

/**
 * @template T
 * @param {T[]} array - Массив перебираемых данных
 * @param {number} timeout - timeout
 * */
const useSliderArrayItemPicker = (array, timeout) => {
  const [t_id, set_t_id] = useState(array[0]);
  const index = useRef(0);

  useEffect(() => {
    const timer_id = setInterval(() => {
      set_t_id(array[index.current % array.length])
      index.current++;
    }, timeout);
    return () => clearInterval(timer_id)
  }, [array, timeout])

  return t_id
};


const TabletPage = (props) => {
  // const id_array = useRef(['1000000073']) // -- dev id's
  const id_array = useRef([{
    t_id: '2521',
    prizeFund: '€3300',
  }, {
    t_id: '2600',
    prizeFund: '€550',
  }])
  const t_info = useSliderArrayItemPicker(id_array.current, 15000);
  const [tournamentInfo, setTournamentInfo] = useState({});
  const [leaderboard, setLeaderboard] = useState([]);


  useEffect(() => {
    const init = async () => {
    setTournamentInfo(await getTInfo(t_info.t_id));
    setLeaderboard(await getLeaderboard(t_info.t_id));
    }
    init().then(() => console.log('Success!'));
  }, [t_info.t_id]);

  const getLeaderboard = async ( t_id ) => {
    try {
      const { data } = await get({
        url: `${config.FETCH.img}/leaderboard?token=6afedb7a8348eb4ebdbe0c77ef92db4c&tournamentid=${t_id}`
      });
      return data.data;
    } catch (error) {
      console.error('Error in Table Page leaderboard ', error);
    }
  }

  const getTInfo = async (t_id) => {
    try {
      const { data } = await get({
        url: `${config.FETCH.img}/tinfo?token=6afedb7a8348eb4ebdbe0c77ef92db4c&tournamentid=${t_id}`
      });
      return data.tournament;
    } catch (error) {
      console.error('Error in Table Page leaderboard ', error);
    }
  }

  return (
    <div className={"tablet__page"}>
      <div className={"tablet__left_section"}>
        <div className={"tablet__header"}>
          <img src={logo} alt="logo" style={{ margin: '10px 20px 10px 15px', width: 295, height: 110, objectFit: 'contain' }} />
          <h1>{(tournamentInfo && tournamentInfo.id && tournamentInfo.name || '').split('#')[0]}</h1>
        </div>
        <div className={"tablet__content"}>
          <div className={"tablet__table"}>
            {tournamentInfo && tournamentInfo.id && (leaderboard || []).length !== 0 && renderLeaderTable({ tournamentInfo, leaderboard })}
          </div>
          <div className={"tablet__gradient"}>
            <p>see more in tenpincam app</p>
          </div>
        </div>
      </div>
      <div className={"tablet__right_section"}>
        <div className={"tablet__info_section"}>
          <div className={"tablet__info_image"}>
            <img src={calendar} alt={"date"}/>
          </div>
          {tournamentInfo && tournamentInfo.id && tournamentInfo.regdatestart && tournamentInfo.regdateend && <div className={"tablet__info_text"}>
            <p>{moment(tournamentInfo.regdatestart).format("DD.MM.YYYY")}</p>
            <p>{moment(tournamentInfo.regdateend).format("DD.MM.YYYY")}</p>
          </div>}
        </div>
        <div className={"tablet__info_section"}>
          <div className={"tablet__info_image"}>
            <img src={currency} alt={"Entry Fee"}/>
          </div>
           <div className={"tablet__info_text"}>
            <p>{tournamentInfo && tournamentInfo.id ? useGameCostText(tournamentInfo) : 'FREE'}</p>
          </div>
        </div>
        <div className={"tablet__info_section"}>
          <div className={"tablet__info_image"}>
            <img src={swords} alt={"Number of Games"}/>
          </div>
          <div className={"tablet__info_text"}>
            <p>{tournamentInfo && tournamentInfo.id && tournamentInfo.wincount} {tournamentInfo && tournamentInfo.id && tournamentInfo.wincount === 1 ? "game" : "games"}</p>
          </div>
        </div>
        <div className={"tablet__info_section"}>
          <div className={"tablet__info_image"}>
            <img src={cup} alt={"Number of Games"}/>
          </div>
          <div className={"tablet__info_text"}>
            <p>{t_info.prizeFund}</p>
          </div>
        </div>
        <div className={"tablet__qr_section"}>
          <img src={qr} alt={"tenpincam.com"}/>
        </div>
      </div>


    </div>)
}


const renderLeaderTable  = (props) => {
  const { tournamentInfo, leaderboard } = props;
  let prizeCount;
  if (tournamentInfo && tournamentInfo.settings && tournamentInfo.settings.filter(setting => setting.tournament_setting_brief === "COUNT_PRIZES_PLACES").length !== 0) {
    prizeCount = tournamentInfo.settings.filter(setting => setting.tournament_setting_brief === "COUNT_PRIZES_PLACES")[0].value_int;
  }
  return (
    <>
    <div className={"tablet__table_row first_row"}>
      <div className={"tablet__table_col cup"}>
        <img src={cup} alt={"place"} />
      </div>
      <div className={"tablet__table_col people"}>
        <img src={player_icon} alt={"username"} />
      </div>
      <div className={"tablet__table_col flag"}>
        <img src={flag} alt={"place"} />
      </div>
      <div className={"tablet__table_col geo"}>
        <img src={geo} alt={"geo-data"} />
      </div>
      <div className={"tablet__table_col total"}>
        <p> Total </p>
      </div>
      <div className={"tablet__table_col avg"}>
        <p> AV </p>
      </div>
      <div className={"tablet__table_col cut"}>
        <p> Cut </p>
      </div>
    </div>
      {leaderboard.map((player, index) => {

        return (
        <div className={`tablet__table_row row_data ${index === leaderboard.length - 1 ? 'last_row' : ''} ${index < prizeCount ? 'prize_row' : ''}`} key={player.id}>
          <div className={"tablet__table_col cup"}>
            <img src={place_holder} className={"position_absolute"}/>
            <p>{index + 1}</p>
          </div>
          <div className={"tablet__table_col people"}>
            <div className="username__container">
              <Avatar
                uri={player.photo ? `${config.FETCH.img}/${player.photo}` : defaultAvatar}
                flag={null}
                tabled
                avgscore={player.avgscore}
                isOnline={player.active}
              />
              <div className={"username__name_container"}>
                <p>{`${player.firstname} ${player.lastname}`.trim() || '-'}</p>

              </div>
            </div>
          </div>
          <div className={"tablet__table_col flag"}>
            <div className={"flag_round_container"}>
              <Flag name={(((player.bestSquad || {}).bowling || {}).iso_code || '')} />
            </div>
            <p>{((player.bestSquad || {}).bowling || {}).country_name} - {((player.bestSquad || {}).bowling || {}).city_name}</p>
          </div>
          <div className={"tablet__table_col geo"}>
            <p>{((player.bestSquad || {}).bowling || {}).name}</p>
          </div>
          <div className={"tablet__table_col total"}>
            <p> {((player.bestSquad || {})).results} </p>
          </div>
          <div className={"tablet__table_col avg"}>
            <p> {Math.round(player.battles_avgscore * 100) / 100} </p>
          </div>
          <div className={"tablet__table_col cut"}>
            <p>{((player.bestSquad || {}).results || 0) - ((leaderboard[(prizeCount - 1 || 0)].bestSquad || {}).results || 0)}</p>
            {/*<p> {((player.bestSquad || {}).results || 0) - ((leaderboard[prizeCount-1 > leaderboard.length ? leaderboard.length - 1 : leaderboard.winners-1] || {}).bestSquad || {}).results || 0} </p>*/}
          </div>
        </div>
        )})
      }
      </>
  )
}

export default TabletPage;