import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from '../common/Modal';
import { Button } from '../common/Button';

import Config from '../../config';
import './style.css';
import { throws } from 'assert';

export default class ModalPauseCountdown extends Component {
  constructor(props) {
    super(props);

    this.primer = null;
  }

  static propTypes = {
    timespan: PropTypes.number,
    timestamp: PropTypes.number,
    onCancel: PropTypes.func,
    visible: PropTypes.bool.isRequired,
    momentFormat: PropTypes.string,
    pauseDuration: PropTypes.string,
    bodyText: PropTypes.string,
    onPauseStop: PropTypes.func,
    isOwner: PropTypes.bool,
  };

  static defaultProps = {
    timespan: 1000,
    momentFormat: 'mm:ss',
  };

  state = {
    ETA: 0,
    targetTime: +new Date(),
  }

  componentDidMount() {
    if (this.props.timestamp) {
      const tmpTime = this.props.timestamp + +this.props.pauseDuration;
      this.setState({ targetTime: tmpTime }, () => {
        this.PrimeTimer(this.props.timespan);
      });
    }
  }

  componentDidUpdate(oldprops) {
    if ((this.props.visible !== oldprops.visible)||(this.props.timestamp !== oldprops.timestamp)||(this.props.pauseDuration !== oldprops.pauseDuration)) {
      if (this.primer) {
        clearTimeout(this.primer);
      }
      if (this.props.visible) {
        const tmpTime = this.props.timestamp + +this.props.pauseDuration;
        this.setState({ targetTime: tmpTime }, () => {
          this.PrimeTimer(this.props.timespan);
        });
      } else {
        this.EndTimer();
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.primer);
  }

  UpdateETA() {
    this.setState({
      ETA: moment.utc(moment.duration(this.state.targetTime) - moment.duration(Date.now()))
    });
  }

  PrimeTimer(timespan) {
    this.primer = setTimeout(() => {
      this.UpdateETA(timespan);
      const curtime = +new Date();
      if (+this.state.targetTime <= curtime) {
        this.EndTimer();
      } else if (+this.state.targetTime < +timespan) {
        this.PrimeTimer(this.state.ETA);
      } else {
        this.PrimeTimer(timespan);
      }
    }, timespan);
  }

  EndTimer() {
    clearTimeout(this.primer);
  }

  render() {
    return (
      <Modal
        title={this.props.intl.formatMessage({ id: 'PauseAsTimebreakAlias', defaultMessage: 'Timeout' })}
        visible={this.props.visible}
        width={395}
        onCancel={this.props.onCancel}
        footer={[
          <div key="buttons" className="pause_buttons">
            <Button
              kind="aquamarine"
              bordered="true"
              onClick={this.props.onCancel}
              >
              {this.props.intl.formatMessage({ id: 'HidePauseModal', default: 'Hide' })}
            </Button>
            {this.props.isOwner ?
              <Button
                onClick={this.props.onPauseStop}
                kind="aquamarine"
                >
                {this.props.intl.formatMessage({ id: 'LiftPause', default: 'Continue' })}
              </Button>
            : null}
          </div>
        ]}
      >
        <p className="countdown--modal-timer">
          { this.state.ETA && this.state.ETA>0 ? moment(this.state.ETA).format(this.props.momentFormat) : '--:--' }
        </p>
        <p className="countdown--modal-tooltip">
          {this.props.bodyText}
        </p>
      </Modal>
    );
  }
}
