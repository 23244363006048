/**
 * @typedef {{  
 *  [routeName: string]: boolean
 * }} IFilterIconState
 */

/**
 * @typedef {{
 *  type: 'FILTER_ICON_SET'
 *  params: { routeName: string; active?: boolean }
 * }} IFilterIconAction
 */

/** @type {IFilterIconState} */
export const initialFilterIconState = {}

/**
 * 
 * @param {{ routeName: string; active?: boolean }} params
 * @returns {IFilterIconAction}
 */
export const filterIconSetAction = (params) => ({ type: 'FILTER_ICON_SET', params })

/**
 * 
 * @param {IFilterIconState} state
 * @param {IFilterIconAction} action
 * @returns 
 */
const FilterIconReducer = (state = initialFilterIconState, action) => {
  switch (action.type) {
    case 'FILTER_ICON_SET':
      if (!action.params || !action.params.routeName) {
        return state
      }
      return {
        ...state,
        [action.params.routeName]: action.params.active,
      }
    default:
      return state
  }
}

export default FilterIconReducer
