import React, { useMemo } from 'react';
import { Table } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button } from '../../common/Button';
import { statusTextDisplay } from '../tournaments/tournamentsTable';
import {
  requestTournamentInfo,
} from '../../../libs/reducers/actions';
import NoDataFound from '../../lobby/noDataFound';
import { useGameCostText } from '../../GameCost/useGameCostText';

function challangesTable({
  intl,
  tournaments,
  requestTournamentInfo,
  switchTournament,
  onBattleWillCreate,
}) {
  const dataSource = tournaments.filter(tournament => tournament.tournamenttypeid === 5);
  const columns = useMemo(() => [
    {
      title: intl.formatMessage({
        id: 'NameAsLabelAlias',
        defaultMessage: 'Name',
      }),
      render: (data, record) => ({
        props: {},
        children: (
          <>{`${record.name} ${record.id}`}</>
        ),
      }),
    },
    {
      title: intl.formatMessage({
        id: 'TournamentTableDateStart',
        defaultMessage: 'Date\nstart',
      }),
      render: (data, record) => ({
        props: {},
        children: (
          <div className="date">
            { record.regdateend ? `${moment(new Date(record.regdateend)).format('DD MMM - HH:mm')}` : '' }
          </div>
        ),
      }),
    },
    {
      title: intl.formatMessage({
        id: 'TournamentTableDateEnd',
        defaultMessage: 'Date\nend',
      }),
      render: (data, record) => ({
        props: {},
        children: (
          <div className="date">
            { record.regdateend ? `${moment(new Date(record.regdateend)).format('DD MMM - HH:mm')}` : '' }
          </div>
        ),
      }),
    },
    /*{
      title: intl.formatMessage({
        id: 'TournamentTableCost',
        defaultMessage: 'Cost',
      }),
      width: 80,
      render: (data, record) => ({
        props: {},
        children: (
          <>
            {useGameCostText(record)}
          </>
        ),
      }),
    },*/
    {
      title: intl.formatMessage({ id: 'StatusAsStateAlias', defaultMessage: 'Status' }),
      render: (data, record) => statusTextDisplay(record, intl),
    },
    {
      title: intl.formatMessage({ id: 'playerCount', defaultMessage: 'Player Count' }),
      render: (data, record) => ({
        props: {},
        children: (
          <>{`${record.players.length}/${record.maxplayers}`}</>
        ),
      }),
    },
    {
      title: '',
      width: 200,
      render: (data, record, index) => {
        return {
          children: (
            <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
              <Button
                kind="aquamarine"
                size="md"
                style={{ minWidth: 88 }}
                onClick={() => {
                  requestTournamentInfo(record.id);
                  switchTournament(record);
                }
              }>
                {intl.formatMessage({ id: 'ProceedToTournamentPage', defaultMessage: 'Go over' })}
              </Button>
            </div>
          ),
          props: {},
        };
      },
    },
  ], []);
  return (
    <div>
      {dataSource.length > 0
        ? <Table
            columns={columns}
            dataSource={dataSource.filter(item => item.name.toLowerCase() !== 'quick game')}
            pagination={false}
          />
        : <NoDataFound onBattleWillCreate={onBattleWillCreate}/>
      }
    </div>
  );
}

export default connect(
  state => ({
    tournaments: state.tournaments.tournaments,
  }),
  {
    requestTournamentInfo,
  },
)(challangesTable);
