import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { injectIntl, intlShape } from 'react-intl';

import Modal from '../../Modal';
import Opponent from '../../Opponent';
import './style.css';

class ProtestReportModal extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    visible: PropTypes.bool.isRequired,
    width: PropTypes.number,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    closable: PropTypes.bool,
    footer: PropTypes.shape().isRequired,
    info: PropTypes.shape(),
    intl: intlShape,
  };

  static defaultProps = {
    width: 395,
    closable: true,
  };

  render() {
    const {
      title, visible, width, onOk, onCancel, closable, footer, info, intl,
    } = this.props;

    const { reason, protestCreator } = info;
    const { 
      photo = '',
      firstname = '',
      lastname = '',
      login = '',
      country_live: flag = '',
      avgscore = 0,
      ratingsavg = 0,
    } = protestCreator || {};
    const ProtestInfo = (
      <div>
        <div className="report_protest_opponent_container">
          <div>{intl.formatMessage({ id: 'ProtestOwnerAlias', defaultMessage: 'Initiator' })}</div>
          <Opponent
            photo={photo}
            fullname={`${firstname} ${lastname}`}
            nickname={login}
            flag={flag}
            avgscore={avgscore}
            rating={ratingsavg}
          />
        </div>
        <div className="report_protest_reason_container">
          <div>
            <div>{intl.formatMessage({ id: 'ProtestReason', defaultMessage: 'Protest reason' })}</div>
            <div className="report_protest_reason_text">{intl.formatMessage({ id: reason || 'ProtestReason', defaultMessage: reason })}</div>
          </div>
          <div className="report_protest_reason_time_container">
            <div className="report_protest_reason_time_label">{intl.formatMessage({ id: 'ProtestCreationDate', defaultMessage: 'Created' })}</div>
            <div className="report_protest_reason_time">{moment(info.created).format('HH:mm')}</div>
          </div>
        </div>
        <div className="report_protest_context_container">
          <div>{intl.formatMessage({ id: 'ProtestOwnerCommentary', defaultMessage: 'Protest commentary' })}</div>
          <div className="report_protest_reason_context">{info.complaint}</div>
        </div>
      </div>
    );

    const OpponentComment = (
      <div className="report_protest_comment_container">
        <div>{intl.formatMessage({ id: 'ProtestAccusedCommentary', defaultMessage: 'Opponent commentary' })}</div>
        <div className="report_protest_reason_comment">{info.userreply}</div>
      </div>
    );

    const AdditionalInfo = (
      <div className="report_protest_additional_text_container">{intl.formatMessage({ id: 'ProtestRecallPreambula', defaultMessage: 'Canceled protest will not be handled by judge' })}</div>
    );

    const content = [ProtestInfo, OpponentComment, AdditionalInfo];

    return (
      <Modal
        title={title || intl.formatMessage({ id: 'ProtestWithNumber', defaultMessage: 'Protest #{protestid}' }, { protestid: info.id })}
        visible={visible}
        width={width}
        onOk={onOk}
        onCancel={onCancel}
        closable={closable}
        footer={footer}
      >
        {content}
      </Modal>
    );
  }
}

export default injectIntl(ProtestReportModal);
