import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Url from 'url';
import axios from 'axios';
import {
  Utils, FLUPlayer, HLSPlayer, WebRTCPlayer, VideoPlayer,
} from './index';
import bugsnagClient from '../../../bugsnagClient';
import VIDEO_URLS from '../videourls';
import config from '../../../config';

const { clog } = Utils;

function getPlayerByUrl(url) {
  if (url.includes('.m3u8')) {
    if (Utils.isIPhone()) {
      return VideoPlayer;
    }
    if (HLSPlayer.isSupported()) {
      return HLSPlayer;
    }
    const error = "Your browser does not support Media Source Extension (MSE), and you try to open '*.m3u8' source";
    throw new Error(error);
  } else if (url.includes('/mse_ld')) {
    if (FLUPlayer.isSupported()) {
      return FLUPlayer;
    }
    const error = "Your browser does not support Media Source Extension (MSE), and you try to open 'mse_ld' source";
    throw new Error(error);
  } else if (url.includes('/webrtc')) {
    if (WebRTCPlayer.isSupported()) {
      return WebRTCPlayer;
    }
    const error = "Your browser does not support WebRTC, and you try to open '/webrtc' source";
    throw new Error(error);
  }
  return VideoPlayer;
}
function getPlayerCompat() {
  if (Utils.isIPhone()) {
    return VideoPlayer;
  }
  if (FLUPlayer.isSupported() && !Utils.isAndroid()) {
    // except Android
    return FLUPlayer;
  }
  if (HLSPlayer.isSupported()) {
    // Android, and remaining
    return HLSPlayer;
  }
  console.log('this is may be an error');
  return VideoPlayer;
}
function getPlayerByName(name) {
  if (/auto/i.test(name)) {
    return getPlayerCompat();
  }
  if (/flu/i.test(name)) {
    return FLUPlayer;
  }
  if (/hls/i.test(name)) {
    return HLSPlayer;
  }
  if (/webrtc/i.test(name)) {
    return WebRTCPlayer;
  }
  return VideoPlayer;
}

class VPlayer extends Component {
  constructor(props) {
    clog('VPlayer::ctor', props.url, props.urls);
    super(props);
    const {
      url, urls, paused, volume, muted, controls, volumeStep, player,
    } = props;
    const { volumeStep: defStep } = VPlayer.defaultProps;
    this.state = {
      url,
      urls: urls.indexOf(url) == -1 && url ? (urls.splice(0, 0, url), urls) : urls,
      muted,
      paused,
      volume,
      controls,
      volumeStep: volumeStep >= 0.0 || volumeStep <= 0.5 ? volumeStep : defStep,
      _newUrl: url,
    };
    this.Player = getPlayerByName(player);
    this.name = this.Player.name;
    this.index = VPlayer.INDEX++;
    this.ref = React.createRef();
  }

  //   componentWillUnmount() {
  //     // clog('VPlayer::dtor');
  //   }

  _getID = () => `${this.name}-${this.index}`;

  isLive = () => {
    const { url } = this.state;
    return url ? !/video\-\d+\-\d+/.test(url) : true;
  };

  getVideoTime = () => {
    const { url } = this.state;
    const { media } = this;
    if (!media || !url || this.isLive()) {
      return Date.now() - 1000;
    }
    const match = url.match(/video\-(\d+)\-(\d+)/);
    const currentTime = this.ref.current.getCurrentTime();
    const start = +match[1];
    return Math.floor((start + currentTime) * 1000);
  };

  makeScreenShot = (e) => {
    const { url } = this.state;
    const { pathname } = Url.parse(url);
    const stm = pathname.match(/^\/([^\/]+)/)[1];
    const msec = this.getVideoTime();
    const date = new Date(msec);
    const {
      year, month, day, hours, minutes, seconds,
    } = Utils.getDate(date);
    const { pad } = Utils;
    this.setState({
      screenShotUrl: `${VIDEO_URLS.origin}/${stm}/${year}/${pad(month)}/${pad(day)}/${pad(hours)}/${pad(minutes)}/${pad(
        seconds,
      )}-preview.jpg`,
    });
  };

  _getBtn = (elm) => {
    let count = 0;
    while (elm.tagName.toLowerCase() != 'button' && ++count < 10) {
      elm = elm.parentNode;
    }
    return elm;
  };

  onChangeUrl = (e) => {
    const btn = this._getBtn(e.target);
    const url = btn.getAttribute('data-url');
    if (url && url != this.state.url) {
      this.setState({ _newUrl: url, url });
    }
  };

  onChange = e => this.setState({ _newUrl: e.target.value });

  setNewUrl = () => {
    const { _newUrl: url } = this.state;
    if (url && url != this.state.url) {
      this.setState({ url });
    }
  };

  play = () => this.setState({ paused: false });

  pause = () => this.setState({ paused: true });

  mute = () => {
    const { muted } = this.state;
    this.setState({ muted: !muted });
  };

  controls = () => {
    const { controls } = this.state;
    this.setState({ controls: !controls });
  };

  volumePlus = () => this._volumeChange(+this.state.volumeStep);

  volumeMinus = () => this._volumeChange(-this.state.volumeStep);

  _volumeChange = (step) => {
    const { volume } = this.state;
    const _result = volume + step;
    let result = _result;
    if (_result > 1.0) {
      result = 1.0;
    } else if (_result < 0.0) {
      result = 0.0;
    }
    if (result !== volume) {
      this.setState({ volume: result });
    }
  };

  onMuted = muted => (console.log('onMuted: ', muted), this.setState({ muted }));

  onPaused = paused => (console.log('onPaused: ', paused), this.setState({ paused }));

  onVolumeChange = volume => (console.log('onVolumeChange: ', volume), this.setState({ volume }));

  onControlsChange = controls => (console.log('onControlsChange: ', controls), this.setState({ controls }));

  onRangeChange = (value) => {
    this.setState({ range: value });
  }

  send = (method, options) => {
    axios({
      method: 'PUT',
      url: `${config.FETCH.url}/ptzcamera/4`,
      headers: { 'Access-Control-Allow-Origin': '*' },
      data: {
        method,
        options,
      },
    })
      .then((response) => {
        console.log('response:', response);
      })
      .catch(error => console.error('error:', error));
  };

  relativeMove = options => this.send('relativeMove', options);

  zoomIn = () => this.relativeMove({ zoom: 0.1 });

  zoomOut = () => this.relativeMove({ zoom: -0.1 });

  rotatePlusX = () => this.relativeMove({ x: 0.1 });

  rotatePlusY = () => this.relativeMove({ y: 0.1 });

  rotateMinusX = () => this.relativeMove({ x: -0.1 });

  rotateMinusY = () => this.relativeMove({ y: -0.1 });

  render() {
    const {
      url, urls, screenShotUrl, paused, volume, muted, controls, volumeStep, range = 100,
    } = this.state;
    const {
      autoPlay, style, styleDIV, styleCont,
    } = this.props;
    this.media = this.media || window.document.getElementById(this._getID());
    let _Player;
    let _error = null;
    try {
      _Player = getPlayerByUrl(url);
      this.Player = _Player;
    } catch (error) {
      bugsnagClient.notify(error, { context: 'VPlayer' });
      this.Player = {};
      _error = error.message;
    }
    return (
      <div style={styleCont}>
        {_error ? (
          <span>
            ERROR: {_error}, {url}
          </span>
        ) : (
          <this.Player
            id={this._getID()}
            url={url}
            paused={paused}
            autoPlay={autoPlay}
            range={range}
            muted={muted}
            volume={volume}
            controls={controls}
            style={style} // <video /> style
            styleDIV={{ ...styleDIV, width: '50%' }} // <div /> style
            onMuted={this.onMuted}
            onPaused={this.onPaused}
            onVolumeChange={this.onVolumeChange}
            onControlsChange={this.onControlsChange}
            onRangeChange={this.onRangeChange}
            onStreamEnd={this.props.onStreamEnd}
            onReadyForDisplay={this.props.onReadyForDisplay}
            ref={this.ref}
          />
        )}
        <div>
          <code>{this.Player.name}</code>
        </div>
        <div>
          <code>{Utils.getUA()}</code>
        </div>
        <div>
          <button onClick={this.pause}>pause</button>
          <button onClick={this.play}>play</button>
          <code> {paused ? 'paused' : 'playing'}</code>
        </div>
        <div>
          <button onClick={this.mute}>mute ({muted ? 'on ' : 'off'})</button>
          <button onClick={this.controls}>controls ({controls ? 'on ' : 'off'})</button>
        </div>
        <div>
          <button onClick={this.volumeMinus}>volume -{volumeStep}</button>
          <button onClick={this.volumePlus}>volume +{volumeStep}</button>
          <code> volume: {(volume || 0).toFixed(2)}</code>
        </div>
        <div className="PTZCamera-controls">
          <button onClick={this.zoomIn}>zoom in</button>
          <button onClick={this.zoomOut}>zoom out</button>
          <button onClick={this.rotatePlusX}>rotate +x</button>
          <button onClick={this.rotateMinusX}>rotate -x</button>
          <button onClick={this.rotatePlusY}>rotate +y</button>
          <button onClick={this.rotateMinusY}>rotate -y</button>
        </div>
        <div>
          <code>source: {url}</code>
        </div>
        <div>
          <input
            style={{ width: '50%' }}
            type="text"
            onChange={this.onChange}
            placeholder="Enter url"
            value={this.state._newUrl}
          />
          <button onClick={this.setNewUrl}>
            <code>submit</code>
          </button>
        </div>
        <div>
          <code>Screenshots</code>
        </div>
        <div>
          <button onClick={this.makeScreenShot}>
            <code>screenshot</code>
          </button>
          <code>
            {' '}
            <a href={screenShotUrl} style={{ textDecoration: 'none' }} download>
              {screenShotUrl}
            </a>
          </code>
        </div>
        <div>
          <code>Source urls</code>
        </div>
        {urls.map((u, i) => {
          let date = null;
          if (u.includes('/video-')) {
            date = new Date(+u.match(/video\-(\d+)/)[1] * 1000);
          }
          return (
            <div key={i}>
              <button onClick={this.onChangeUrl} data-url={u}>
                <code>source {i}</code>
              </button>
              {date && <code> {date.toLocaleString()}</code>}
              <code> {u}</code>
            </div>
          );
        })}
      </div>
    );
  }
}

VPlayer.INDEX = 0;
VPlayer.Player = getPlayerCompat();
VPlayer.isSupported = VPlayer.Player.isSupported;
const descriptor = {};
['name', 'isHLS', 'isHls', 'isFLU', 'isFlu', 'isWebRTC', 'isHTMLMedia'].forEach((key) => {
  descriptor[key] = {
    get() {
      return VPlayer.Player[key];
    },
    enumerable: true,
  };
});
Object.defineProperties(VPlayer, descriptor);

VPlayer.defaultProps = {
  urls: [],
  player: 'auto',
  paused: true,
  muted: false,
  volume: 0.5,
  volumeStep: 0.1,
  controls: true,
  autoPlay: true,
  style: { width: '50%' },
  styleDIV: {},
  styleCont: {},
};

VPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  urls: PropTypes.arrayOf(PropTypes.string),
  player: PropTypes.string,
  muted: PropTypes.bool,
  paused: PropTypes.bool,
  volume: PropTypes.number,
  volumeStep: PropTypes.number,
  controls: PropTypes.bool,
  autoPlay: PropTypes.bool,
  style: PropTypes.shape(),
  styleDIV: PropTypes.object,
  styleCont: PropTypes.object,
};

export default VPlayer;
