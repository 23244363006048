import './style.scss'
import React from 'react';

export default class PillBox extends React.Component {
	render() {
		const {
			className,
			value,
			options,
			placeholder,
			onOpen,
			noCog,
			noShowcase,
			color,
		} = this.props;

		return <>
			<div className={`pillBoxBody${className?` ${className}`:''}${!noShowcase?` noShowcase`:''}`}>
				{!noCog && <svg onClick={() => onOpen(options)} width="16" height="16" viewBox="0 0 16 16" fill="inherit" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M6.8046 0H9.1954V2.33037C9.90841 2.47995 10.5732 2.76064 11.1634 3.14605L12.8116 1.49784L14.5021 3.1884L12.8539 4.8366C13.2394 5.42678 13.52 6.09158 13.6696 6.8046H16V9.1954H13.6696C13.52 9.90842 13.2394 10.5732 12.8539 11.1634L14.5021 12.8116L12.8116 14.5022L11.1634 12.854C10.5732 13.2394 9.90841 13.5201 9.1954 13.6696V16H6.8046V13.6696C6.09158 13.52 5.42678 13.2394 4.8366 12.8539L3.18842 14.5021L1.49786 12.8116L3.14605 11.1634C2.76064 10.5732 2.47995 9.90841 2.33037 9.1954H0V6.8046H2.33037C2.47995 6.09159 2.76064 5.4268 3.14605 4.83662L1.49786 3.18844L3.18842 1.49788L4.8366 3.14607C5.42678 2.76064 6.09158 2.47995 6.8046 2.33037V0ZM11.0345 7.99987C11.0345 9.67576 9.67589 11.0343 7.99999 11.0343C6.32409 11.0343 4.9655 9.67576 4.9655 7.99987C4.9655 6.32397 6.32409 4.96538 7.99999 4.96538C9.67589 4.96538 11.0345 6.32397 11.0345 7.99987Z" fill="inherit"/>
				</svg>}
				{!noShowcase && <div className='content' onClick={noCog ? () => onOpen(options) : () => {}} style={{ cursor: noCog?'pointer':'default', borderBottomColor: color||'#f65932' }}>
					{value && value.length ? value.map(item => <span>{item.label}</span>) : placeholder}
				</div>}
			</div>
		</>
	}
}