import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Table, Spin, Icon } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import Avatar from '../common/Avatar';
import config from '../../config';
import defaultAvatar from '../../images/defaultAvatar.png';
import { ReactComponent as SvgCountry } from '../../images/country.svg';
import { ReactComponent as SvgGender } from '../../images/gender.svg';
import Flag from '../common/Flag';
import {
  UserByIdFetch,
  setTournamentPlayerResult
} from '../../libs/reducers/actions';
import './style.scss';
import { post } from '../../libs/Networking';

const antIcon = <Icon type="loading" style={{ fontSize: 14 }} spin />;

const Standings = ({
  intl,
  tournament,
  UserByIdFetch,
  setTournamentPlayerResult,
  permissions,
}) => {
  const columns = [
    {
      title: intl.formatMessage({ id: 'TournamentPlace', defaultMessage: 'Place' }),
      dataIndex: '',
      width: 77,
      align: 'center',
      render: (data, record, index) => ({
        props: {},
        children: (
          <>
            {index + 1}
          </>),
      }),
    },
    {
      title: intl.formatMessage({ id: 'FullnameTableColumn', defaultMessage: 'Fullname' }),
      dataIndex: '',
      width: 314,
      render: record => ({
        props: {},
        children: (<>
            <div className="username__container">
              <Avatar
                uri={record.photo ? `${config.FETCH.img}/${record.photo}` : defaultAvatar}
                avgscore={record.avgscore}
                flag={null}
                tabled
              />
              {`${record.firstname} ${record.lastname}`.trim() || '-'}
            </div>
          </>),
      }),
    },
    {
      title: intl.formatMessage({ id: 'LoginTableColumn', defaultMessage: 'Login' }),
      dataIndex: '',
      width: 112,
      render: (data, record, index) => ({
        props: {},
        children: (<>
            <div className="participant__login"><Link to="#" onClick={() => { UserByIdFetch({ userid: record.userid }); }}>{record.login}</Link></div>
          </>),
      }),
    },
  
    {
      title: <>
        <svg
          width="20"
          height="19"
          viewBox="0 0 20 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginTop: 3 }}>
          <path d="M10 1.04704L12.6699 5.95934L12.7836 6.16865L13.0179 6.21216L18.5148 7.23339L14.6679 11.2906L14.504 11.4634L14.535 11.6996L15.2624 17.2431L10.2151 14.8383L10 14.7358L9.78494 14.8383L4.73759 17.2431L5.46498 11.6996L5.49598 11.4634L5.33207 11.2906L1.48523 7.23339L6.98214 6.21216L7.21636 6.16865L7.33012 5.95934L10 1.04704Z" stroke="#818589"/>
        </svg>
      </>,
      width: 40,
      dataIndex: 'rating',
      sorter: (a, b) => ((a.rating == b.rating) ? 0 : (a.rating - b.rating) / Math.abs(a.rating - b.rating)),
      render: (data, record, index) => ({
        props: {},
        children: (<>
            {data
              ? <div className='ratingWrap'><svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 0L12.9389 5.95492L19.5106 6.90983L14.7553 11.5451L15.8779 18.0902L10 15L4.12215 18.0902L5.24472 11.5451L0.489435 6.90983L7.06107 5.95492L10 0Z" fill="#FFB31E"/>
              </svg>
              {data}</div>
              :								<div className='ratingWrap'><svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 1.12978L12.4906 6.1762L12.6069 6.41192L12.867 6.44972L18.4361 7.25895L14.4063 11.187L14.218 11.3705L14.2625 11.6296L15.2138 17.1762L10.2327 14.5574L10 14.4351L9.76733 14.5574L4.78621 17.1762L5.73752 11.6296L5.78196 11.3705L5.59372 11.187L1.56392 7.25895L7.13297 6.44972L7.39311 6.41192L7.50944 6.1762L10 1.12978Z" stroke="#FFB31E"/>
              </svg>
              0</div>
            }
          </>),
      }),
    },
    {
      title: <SvgCountry />,
      dataIndex: 'creatorcountry',
      width: 40,
      align: 'center',
      render: (data, record, index) => ({
        children: (
        <div className="battles__flag">
          <Flag name={record.country} />
        </div>
        ),
        props: {
        },
      }),
      sorter: (a, b) => (a.country_live || a.country).localeCompare(b.country_live || b.country),
    },
    {
      title: <SvgGender />,
      width: 40,
      dataIndex: 'gender',
    },
    {
      title: intl.formatMessage({ id: 'ResultTableColumn', formatMessage: 'Result' }),
      dataIndex: 'result',
      width: '25%',
      align: 'left',
      render: (data, record, index) => ({
        props: {},
        children: <EditableCell
          key={record.playerid}
          value={record.result || 0}
          intl={intl}
          onSave={value => console.log('HERE SAVE ', value)}
          playerId={record.playerid}
          setTournamentPlayerResult={setTournamentPlayerResult}
          canEdit={permissions.includes('TOURNAMENTS_MANAGE_PLAYERS')}
        />,
      }),
      editable: true,
    },
  ];

  const { players, settings = [] } = tournament;
  const numberPrizesPlace = Array.isArray(settings)
    ? (settings.find(s => s.tournament_setting_brief === 'COUNT_PRIZES_PLACES') || {}).value_int
    : null;

  const filteredPlayers = players.sort((a, b) => (b.result || 0) - (a.result || 0)).filter(p => p.isparticipant);

  return <Table
    columns={columns}
    dataSource={filteredPlayers}
    pagination={false}
    rowClassName={(record, index) => {
      let result = 'tournamentRow ';
      if (numberPrizesPlace && numberPrizesPlace >= (index + 1)) result += 'favoriteParticipant ';
      return result;
    }}
    locale={{
      emptyText: 'No players',
    }}
  />;
};

export default connect(
  state => ({
    tournament: state.tournaments.tournamentInfo,
    permissions: state.users.permissions ? state.users.permissions.keys : [],
  }),
  {
    UserByIdFetch,
    setTournamentPlayerResult,
  }
)(Standings);

const EditableCell = ({
  value,
  intl,
  onSave,
  playerId,
  setTournamentPlayerResult,
  canEdit,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [_value, setValue] = useState(value);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef();
  const _playerId = useRef();
  const debounceSave = useRef(
    _.debounce(val => setPlayerResult(val), 1000)
  );

  useEffect(() => { _playerId.current = playerId; }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [inputRef]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEdit]);

  useEffect(() => {
    setValue(value);
  }, [value]);

  useEffect(() => {
    if (+playerId !== _playerId.current) {
      setIsEdit(false);
      _playerId.current = +playerId;
    }
  }, [playerId]);

  const handleClickOutside = async (e) => {
    const input = inputRef.current;
    if (input && !input.contains(e.target)) {
      setIsEdit(false);
    }
  };

  const setPlayerResult = async (val) => {
    setLoading(true);
    try {
      const { data } = await post({
        url: `${config.FETCH.url}/tournament/set_player_result`,
        data: {
          player_id: playerId,
          value: val,
        },
      });
      if (data.success) {
        setTournamentPlayerResult(data.player);
      }
      console.log('Success save player result ', data);
    } catch (error) {
      console.error('Save player result error ', error);
    }
    setLoading(false);
  };

  const handleOnInput = async (e) => {
    let val = 0;
    if (e.target.validity.valid) {
      val = e.target.value;
    } else {
      val = _value;
    }
    setLoading(true);
    setValue(val);
    await debounceSave.current(val);
  };

  return (
    <div className="tournament-table-result__container">
      {(canEdit && (
        <>
          {isEdit ? (
            <input
              ref={inputRef}
              value={_value}
              onInput={handleOnInput}
              pattern="[0-9]*"
              className="tournament-table-result__input"
            />
          )
            : (
              <div
                onClick={() => setIsEdit(true)}
                className="tournament-table-result-value"
              >
                {value || 0}
              </div>
            )}
        </>
      )) || (
        <div
          className="tournament-table-result-value not-hover"
        >
          {value || 0}
        </div>
      )}
        {loading && (
          <Spin indicator={antIcon} style={{ color: '#F65932' }} />
        )}
    </div>
  );
};
