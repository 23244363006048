import React from 'react';
import { injectIntl } from 'react-intl';
import Modal from '../common/Modal';
import GameScore from '../common/GameScore';
import config from '../../config';
import defaultAvatar from '../../images/defaultAvatar.png';
import Flag from '../common/Flag';
import { Button } from '../common/Button';
import './style.scss';

class ModalObserverSquadResult extends React.Component {
  onRating = () => {
    const { ratingsOpen = false } = this.state;
    this.setState({ ratingsOpen: !ratingsOpen });
  };

  createRatingSVG = (rating) => {
    const poly = 'M14 1.12978L17.6661 8.55816L17.7825 8.79389L18.0426 8.83169L26.2403 10.0229L20.3084 15.8051L20.1202 15.9886L20.1646 16.2476L21.5649 24.4122L14.2327 20.5574L14 20.4351L13.7673 20.5574L6.43507 24.4122L7.83541 16.2476L7.87984 15.9886L7.69161 15.8051L1.75969 10.0229L9.9574 8.83169L10.2175 8.79389L10.3339 8.55816L14 1.12978Z';
    const numbers = [1, 2, 3, 4, 5];
    return numbers.map((num, idx) => {
      let fill = 'none';
      let isPartial = false;
      const entropy = Math.random();
      if (rating > num || rating == num) {
        fill = '#FFB31E';
      } else if (num - 1 < rating && rating < num) {
        isPartial = true;
        fill = `url(#partialGradient${entropy})`;
      }
      return (
        <svg
          key={idx}
          className={`${rating === num ? 'selected' : ''}`}
          width="20"
          height="20"
          viewBox="0 0 28 28"
          fill={fill}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d={poly} stroke="#FFB31E" />
          {isPartial ? (
            <linearGradient id={`partialGradient${entropy}`}>
              <stop stopOpacity="1" offset={`${(rating - num + 1) * 100}%`} stopColor="#FFB31E" />
              <stop stopOpacity="0" offset="0" />
            </linearGradient>
          ) : null}
        </svg>
      );
    });
  };

  renderPlayerRating = ({ ratings=[], ratingsavg=0 }) => {
    return (
      <div className="rating-container" onClick={this.onRating}>
        <div className="rating-stars">{this.createRatingSVG(+ratingsavg)}</div>
        <span className="rating-score">{`${(+ratingsavg || 0).toFixed(2)}`}</span>
      </div>
    );
  };

  renderPlayerItem = (player, index) => (
    <div className="player-item-container" key={index}>
      <div className="player-item">
        <div className="player-column-left">
          <div className="player-info">
            <div className="player-name">{`${player.lastname} ${player.firstname}`}</div>
            <div className="player-login">{player.login}</div>
            <div className="player-rating">{this.renderPlayerRating(player)}</div>
          </div>
        </div>
        <div className="player-column-right">
          <div className={`player-photo${player.self ? ' self' : ''}`}>
            <img src={player.photo ? `${config.FETCH.img}/${player.photo}` : defaultAvatar} />
            <div className='avgScore'>{(+player.avgscore || 0).toFixed(0)}</div>
            <div className="avatar__flag">
              <Flag name={player.country_live.toLowerCase()} />
            </div>
          </div>
          <div className={`player-score${player.self ? ' self' : ''}`}>{this.props.squad.players.length === 1 ? player.total_score : player.score}</div>
        </div>
      </div>
    </div>
  );

  renderSoloFooter() {
    const { intl } = this.props;
    const { shareText = intl.formatMessage({ id: 'ShareResult', defaultMessage: 'Share result' }) } = this.props;
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button bordered style={{ backgroundColor: 'transparent', border: '1px solid #00bfbf' }} key="okButton" kind="aquamarine" onClick={this.props.onClose}>
          {this.props.intl.formatMessage({ id: 'close', defaultMessage: 'Close' })}
        </Button>
				{/* Скрываем, пока нет функционало Поделиться */}
				{/*
        <Button key="cancelButton" kind="aquamarine" onClick={() => {}}>
          {shareText}
				</Button>
				*/}
      </div>
    )
  }

  render() {
    const {
      squad,
      visible,
      intl,
      onClose,
      onShare,
    } = this.props;
    const players = squad.players.map((player) => {
      const editedPlayer = { ...player };
      if (squad.games.length !== 1) {
        editedPlayer.score = player.gameswin;
      } else {
        editedPlayer.score = Math.max(...squad.games[0].frames[player.id].map(frame => frame.score));
      }
      return editedPlayer;
    });

    return (
      <Modal
        visible={visible}
				title={intl.formatMessage({ id: 'GameOverText', defaultMessage: 'Game over' })}
				// ширина меньше, пока нет функционала Поделиться
				// width={681}
				width={480}
        footer={this.renderSoloFooter()}
      >
        <div className="modal-players">
          <div className="players-container">{players.map(this.renderPlayerItem)}</div>
        </div>
        {
          squad.tournamenttypeid === 3 && squad.games.map((game, index) => (
            <div key={index} className="game-score__container">
              <div className="game-score__label">Game {index + 1}</div>
              {squad.players.map(player => (
                <GameScore
                  key={player.id}
                  frames={game.frames[player.id]}
                  frameScoreSumStyles={{
                    backgroundColor: '#692E25',
                    color: '#fff',
                    borderRight: '1px solid black'
                  }}
                  frameNumberStyles={{
                    color: '#fff',
                    borderRight: '1px solid black'
                  }}
                />
              ))}
            </div>
          ))
        }
      </Modal>
    );
  }
}

export default injectIntl(ModalObserverSquadResult);
