import React from 'react';
import { Dropdown, Menu } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import arrow from '../../../images/arrow-down.png';
import 'antd/lib/drawer/style/index.css';
import 'antd/lib/dropdown/style/index.css';
import './style.scss';


const NavMenu = ({ history, allowedTabs }) => {
  const currentPage = history.location.pathname.substring(10, history.location.pathname.length);
  const routs = [];
  const anotherName = name => {
    switch (name) {
      case 'iban':
        return 'IBAN/PayPal';

      default:
        return name;
    }
  };
  const renderDropDownMenu = (
    <Menu className="drop-menu">
      {allowedTabs.map((allowedTabs, index) => (
        <Menu.Item key={index}>
          <Link
            className="link-container"
            to={allowedTabs}
          >
            <span className={allowedTabs === currentPage ? 'nav-link-text-active' : 'nav-link-text'}>
              {anotherName(allowedTabs)}
            </span>
          </Link>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (

    <Dropdown overlay={renderDropDownMenu} trigger={['click']} overlayStyle={{ marginLeft: 100 }}>
      <div className="dropDownContainer">
        <div className="burgerMenu">
          <div className="burgerPart" />
          <div className="burgerPart" />
          <div className="burgerPart" />
        </div>
        <div className="currentPage">{anotherName(currentPage).toUpperCase()}</div>
        <div className="arrowContainer">
          <img src={arrow} alt="arrow" className="menuArrow" />
        </div>
      </div>
    </Dropdown>
  );
};

export default withRouter(NavMenu);
