import { takeEvery,  } from 'redux-saga/effects';
import {
  _unbindVideoFiles,
  _bindVideoFiles,
  _getVideoFiles,
} from './videofiles.handler';

const { 
  UNBIND_VIDEO_FILES,
  BIND_VIDEO_FILES,
  FETCH_VIDEO_FILES,
} = require('../../reducers/actiontypes').default;


export function* _WatchVideoFiles() {
  try {
    yield takeEvery(UNBIND_VIDEO_FILES, _unbindVideoFiles);
    yield takeEvery(BIND_VIDEO_FILES, _bindVideoFiles);
    yield takeEvery(FETCH_VIDEO_FILES, _getVideoFiles);

  } catch (error) {
    console.error('===WatchBattles Error: ', error);
  }
}
