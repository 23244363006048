import React from 'react';
// import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';
import parse from 'url-parse';
import { UserLogin, sendPasswordUpdateRequest } from '../../libs/reducers/actions';
import Title from '../common/Title';
import { Button } from '../common/Button';
import Input from '../common/Input';
import logo from '../../images/Logo1.png';

import '../registration/style.css';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
      formErrors: {
        password: '',
        confirmPassword: '',
        invalidPasswords: '',
        invalidToken: '',
      },
    };
  }

  static propTypes = {
    intl: intlShape,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = { ...prevState }
    if (nextProps.users.user && nextProps.users.accessed) {
      nextProps.history.push('/lobby');
      return null;
    }
    if (nextProps.users.passUpdateStatus === 'FAILED') {
      state =  { ...state, formErrors: { ...prevState.formErrors, invalidToken: nextProps.users.passUpdatePayload } };
    }
    if (nextProps.users.error) {
      state = { ...state, formErrors: { ...prevState.formErrors, invalidPasswords: nextProps.users.error } };
    }
    return state;
  }

  componentDidUpdate(prevProps) {
    const { passUpdateStatus } = this.props;
    if (prevProps.passUpdateStatus !== passUpdateStatus && passUpdateStatus === 'COMPLETE') {
      const { passUpdatePayload: login } = this.props;
      const { password } = this.state;
      this.props._login({ login, password });
    }
  }

  handleUserInput = (value, name) => this.setState({ [name]: value });

  handleSubmit = (e) => {
    e.preventDefault();

    const { password, confirmPassword } = this.state;
    const errors = this.validate(password, confirmPassword);
    let isFormValid = true;
    _.forIn(errors, (fieldErrorMsg) => {
      if (!_.isEmpty(fieldErrorMsg)) isFormValid = false;
    });

    this.setState({ formErrors: errors }, () => {
      if (isFormValid) {
        const { password: passwd, formErrors } = this.state;
        console.log('handleSubmint -> errors: ', formErrors);
        const url = parse(this.props.location.pathname + this.props.location.search, true);
        const { token } = url.query;
        const { passwordUpdateRequest } = this.props;
        passwordUpdateRequest({ password: passwd, token });
      }
    });
  };

  // eslint-disable-next-line
  validate = (password, confirmPassword) => {
    const fieldValidationErrors = {
      password: '',
      confirmPassword: '',
      invalidPasswords: '',
      invalidToken: '',
    };

    const { intl, location } = this.props;
    const url = parse(location.pathname + location.search, true);
    const { token } = url.query;
    let requiredField;
    if (!token) {
      console.log('token empty error');
      fieldValidationErrors.invalidToken = intl.formatMessage({
        id: 'invalidPasswordRecoveryToken',
        defaultMessage: 'Token not specifined',
      });
    }
    if (_.isEmpty(password)) {
      console.log('empty password error');
      requiredField = intl.formatMessage({ id: 'requiredField', defaultMessage: 'Required field' });
      fieldValidationErrors.password = requiredField;
      // 'Поле обязательно для заполнения';
    }
    if (_.isEmpty(confirmPassword)) {
      console.log('empty confirm-password error');
      requiredField = requiredField || intl.formatMessage({ id: 'requiredField', defaultMessage: 'Required field' });
      fieldValidationErrors.confirmPassword = requiredField;
    }
    if (confirmPassword !== password) {
      console.log('password did not match error');
      fieldValidationErrors.invalidPasswords = intl.formatMessage({
        id: 'PasswordResetConfirmErr',
        defaultMessage: "Passwords didn't match",
      });
    }

    return fieldValidationErrors;
  };

  render() {
    const { password, confirmPassword } = this.state;
    const { intl } = this.props;
    return (
      <div className="login-page">
        <div className="login-page__content">
          <div className="login-page__left-column">
            <div className="login-page__left-column__content">
              <img src={logo} alt="" className="leftcolumn__content__logo" />
              <Title subtitle="recovery">{intl.formatMessage({ id: 'password', defaultMessage: 'Password' })}</Title>
            </div>
          </div>
          <div className="login-page__right-column">
            <div className="login-page__right-column__content">
              <span className={'registration-page__right-title'}>
                  {intl.formatMessage({ id: 'PasswordRecovery', defaultMessage: 'Password recovery' })}
              </span>
              <form onSubmit={this.handleSubmit}>
                <div className="input-group">
                  <Input
                    type="password"
                    onChangeText={this.handleUserInput}
                    value={password}
                    placeholder={intl.formatMessage({ id: 'new_password', defaultMessage: 'Enter new password' })}
                    name="password"
                    error={this.state.formErrors.password}
                  />
                  <Input
                    type="password"
                    onChangeText={this.handleUserInput}
                    value={confirmPassword}
                    placeholder={intl.formatMessage({ id: 'password', defaultMessage: 'Password' })}
                    name="confirmPassword"
                    error={
                      this.state.formErrors.confirmPassword
                      || this.state.formErrors.invalidPasswords
                      || this.state.formErrors.invalidToken
                    }
                  />
                </div>
                <Button size="s" type="submit">
                  {intl.formatMessage({ id: 'submit', defaultMessage: 'Submit' })}
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(
  connect(
    state => ({
      users: state.users,
      langs: state.langs,
      passUpdateStatus: state.users.passUpdateStatus,
      passUpdatePayload: state.users.passUpdatePayload,
    }),
    dispatch => ({
      _login: bindActionCreators(UserLogin, dispatch),
      passwordUpdateRequest: bindActionCreators(sendPasswordUpdateRequest, dispatch),
    }),
  )(Login),
);
