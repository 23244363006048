import types from './actiontypes';

const initialState = {
  fetching: false,
  error: '',
  timestamp: 0,
}

const timestampReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_TIMESTAMP:
      return {
        ...state,
        fetching: true,
        error: '',
      };
    case types.SET_TIMESTAMP:
      if (action.error) {
        return {
          ...state,
          fetching: false,
          error: action.error,
        };
      }
      return {
        ...state,
        fetching: false,
        timestamp: action.payload,
        error: '',
      };
    case types.CLEAN_TIMESTAMP_ERROR:
      return {
        ...state,
        error: '',
      };
    default:
      return state;
  }
}

export default timestampReducer;
