import { call, put, select } from 'redux-saga/effects';
import bugsnagClient from '../../bugsnagClient';
import config from '../../config';
import { get, put as putMethod, post } from '../Networking';
const {
  SET_TOURNAMENT_SETTING,
  DELETE_TOURNAMENT_SETTING_SET
} = require('../reducers/actiontypes').default;

/**
 * Функция отправки настройки на сервер
 * @param {object} param
 * @param {number} param.tournamentid обязательный параметр id турнира
 * @param {string} param.setting_brief обязательный параметр, короткое наименование настройки
 */
export function* _AddTournamentSetting({ params }) {
  try {
    const result = yield call(post, { url: `${config.FETCH.url}/tournament/change_setting`, data: params });
    if (result && result.status === 200 && result.data.success && result.data.data && result.data.data.id) {
      console.log('Success add tournament setting. Setting = ', result.data);
      yield put({ type: SET_TOURNAMENT_SETTING, params: result.data.data });
    } else {
      throw new Error(`Failed set tournament setting ${JSON.stringify({ result })}`);
    }
  } catch (error) {
    bugsnagClient.notify(new Error(`Error. SetTournamentSetting(). Error = ${JSON.stringify({ error })}`));
    console.error('Error. SetTournamentSetting() ', error);
  }
};

/**
 * Функция отправки удаления настройки
 * @param {object} param
 * @param {number} param.setting_id обязательный параметр id настройки которую необходимо удалить
 */
export function* _DeleteTournamentSetting({ params }) {
  try {
    const result = yield call(post, { url: `${config.FETCH.url}/tournament/delete_setting`, data: params });
    if (result && result.status === 200 && result.data.success) {
      console.log('Success delete tournament setting');
      yield put({ type: DELETE_TOURNAMENT_SETTING_SET, params });
    } else {
      throw new Error(`Failed delete tournament setting ${JSON.stringify({ result })}`);
    }
  } catch (error) {
    bugsnagClient.notify(new Error(`Error. RemoveTournamentSetting(). Error = ${JSON.stringify({ error })}`));
    console.error('Error. RemoveTournamentSetting() ', error);
  }
}