import axios from 'axios';
import { store } from '../store';
import { UserLogout, setSessionEventModal } from './reducers/actions';
import { setCommonError } from './reducers/commonReducer';
import sharedOptions from './Networking.options';
import { myCookies, parseSearch, parseURL, toSearch, toURL } from './MyCookies';

import config from '../config'

/**
 * @param {string} url
 * @return {Promise.<void>}
 */
const fetchCookie = async (url) => {
  if (typeof document === 'undefined') {
    if (myCookies.isEmpty()) {
      try {
        await myCookies.setFromNativeCookies(url);
      } catch (e) {
        console.log('set from native cookies error: ', e);
      }
    }
    sharedOptions.headers['x-cookie'] = myCookies.toString(url);
    if (myCookies.token) {
      sharedOptions.headers['x-cookie-token'] = myCookies.token;
    }
  }
};

/**
 * @param {string} url
 * @param {Object.<string, string>} headers
 * @return {Promise.<void>}
 */
const setCookie = (url, headers = {}) => {
  if (typeof document === 'undefined' && typeof headers === 'object' && headers['set-cookie']) {
    myCookies.setFromResponse(url, headers['set-cookie']);
  }
};

const cleanCookie = async () => {
  if (typeof document === 'undefined') {
    await myCookies.cleanAll();
  }
}

const errorHandler = async (error, url, ignoreErrorStatus) => {
  if (error.response) {
    setCookie(url, error.response.headers);
  }
  const { status } = (error && error.response) || {};
  if ((status === 401 || status === 403) && ignoreErrorStatus.indexOf(status) === -1) {
    store.dispatch(UserLogout());
  }
  const data = {
    error,
    data: {
      error,
    },
  };
  return { status, response: { error, data }, data };
};

const handleErrorCode = (code, errorStack) => {
  if (code === 'super-safe') {
    return;
  }
  if (code === 'single-session') {
    if (Object.keys(store.getState().users.user || {}).length) {
      store.dispatch(setSessionEventModal({ visible: true, closed: true, reason: 'connection limit exceeded' }))
      store.dispatch({ type: 'USER_LOGOUT' });
    }
    return;
  }
  store.dispatch(setCommonError(errorStack));
};

const dispatchError = (response) => {
  const {
    success, error, data, transactionError, errortype, code, ...rest
  } = response;
  if ((success || !error) && !transactionError) {
    return;
  }
  const keys = Object.keys(rest);
  if (!keys.length) {
    const errorStack = [{ message: error, type: errortype || 'Error' }];
    errorStack.unshift({ message: 'Server respond with error:', type: 'Leading' });
    handleErrorCode(code, errorStack);
  } else {
    const errorStack = [{ message: keys.map(k => {return `${k}: ${rest[k]}`}), type: errortype }];
    errorStack.unshift({ message: 'Server respond with error:', type: 'Leading' });
    handleErrorCode(code, errorStack);
  }
  console.log('error:', error, response, ...keys.map(k => rest[k]));
};

const urlKey = (url, size = 8) => {
  const { session: { sessionkey = `${Math.random()}` } = {} } = store.getState().users.user;
  const parsedUrl = parseURL(url);
  const query = parseSearch(parsedUrl.search);
  query._sk = sessionkey.slice(0, size);
  query._type = config.mobile ? 'mobile' : ''
  parsedUrl.search = toSearch(query);
  return toURL(parsedUrl);
};

const toUpper = (value) => {
  return typeof value === 'string' ? value.toUpperCase() : value;
}
const AxiosLogger = {
  time: () => new Date(),
  /**
   * @param {import('axios').AxiosResponse<any>} res
   * @param {number} startTime
   * @param {Error} [error]
   */
  log: (res, startTime, error) => {
    const { config } = res;
    const duration = Date.now() - startTime;
    console.log(AxiosLogger.time(), '[HTTP]', toUpper(config.method), config.url, res.status, config.params, error ? error : null, `+${duration}ms`)
  },
  /**
   * @param {{ response?: import('axios').AxiosResponse<any>; request: XMLHttpRequest | import('http').ClientRequest } | Error} error 
   * @param {number} startTime 
   * @param {{ method: string; url: string; params?: any }} [config]
   */
  error: (error, startTime, config = {}) => {
    if (error.response) {
      AxiosLogger.log(error.response, startTime)
    } else if (error.request) {
      /** @type {XMLHttpRequest} */
      const req = error.request;
      AxiosLogger.log({ status: req.status, config }, startTime)
    } else {
      AxiosLogger.log({ config, status: null }, startTime, error)
    }
  },
}

export const get = async ({ url, ...params }, ignoreError = false, ignoreErrorStatus = []) => {
  const startTime = Date.now();
  const config = { method: 'GET', url, params };
  try {
    url = urlKey(url); // добавляет в поле "_sk={sessionkey}" в параметр search, это нужно для сброса кэша при разлогине
    config.url = url;
    await fetchCookie(url);
    //console.warn(`[${new Date().toISOString().slice(11, -1)}] GET `, parseURL(url).pathname);
    const req = await axios.get(url, { ...params, ...sharedOptions });
    AxiosLogger.log(req, startTime)
    //console.warn(`[${new Date().toISOString().slice(11, -1)}] GET [DONE]`, parseURL(url).pathname, { success: req.data.success });
    setCookie(url, req.headers);
    const { success } = req.data;
    if (req.status === 200 && !success && !ignoreError) {
      dispatchError(req.data);
    }
    return req;
  } catch (err) {
    console.log('error: GET ', url, err);
    AxiosLogger.error(err, startTime, config)
    return errorHandler(err, url, ignoreErrorStatus);
  }
};

export const post = async ({ url, data, ...requestParams }, ignoreError = false, ignoreErrorStatus = []) => {
  const startTime = Date.now();
  const config = { method: 'POST', url, data, params: requestParams }
  try {
    url = urlKey(url); // добавляет в поле "_sk={sessionkey}" в параметр search, это нужно для сброса кэша при разлогине
    config.url = url;
    await fetchCookie(url);
    const req = await axios.post(url, data, { ...requestParams, ...sharedOptions });
    AxiosLogger.log(req, startTime)
    setCookie(url, req.headers);
    const { success } = req.data;
    if (req.status === 200 && !success && !ignoreError) {
      dispatchError(req.data);
    }
    return req;
  } catch (err) {
    AxiosLogger.error(err, startTime, config);
    if (err.code === 'ECONNABORTED') {
      throw err;
    }
    return errorHandler(err, url, ignoreErrorStatus);
  }
};

export const put = async ({ url, data }, ignoreError = false, ignoreErrorStatus = []) => {
  const startTime = Date.now();
  const config = { method: 'PUT', url, data }
  try {
    url = urlKey(url); // добавляет в поле "_sk={sessionkey}" в параметр search, это нужно для сброса кэша при разлогине
    config.url = url;
    await fetchCookie(url);
    //console.warn(`[${new Date().toISOString().slice(11, -1)}] PUT `, parseURL(url).pathname, data);
    const req = await axios.put(url, data, { ...sharedOptions });
    AxiosLogger.log(req, startTime);
    //console.warn(`[${new Date().toISOString().slice(11, -1)}] PUT [DONE]`, parseURL(url).pathname, { success: req.data.success });
    setCookie(url, req.headers);
    const { success } = req.data;
    if (req.status === 200 && !success && !ignoreError) {
      dispatchError(req.data);
    }
    return req;
  } catch (err) {
    AxiosLogger.error(err, startTime, config);
    return errorHandler(err, url, ignoreErrorStatus);
  }
};

export const api = {
  get,
  post,
  put,
};
