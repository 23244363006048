import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../Button';
import Modal from '../../Modal';
import Frame from '../../Frame';
import './style.css';
import Arrow from '-!svg-react-loader!../../../../images/arrowright.svg'; // eslint-disable-line

class EditResultsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static propTypes = {
    declineButtonText: PropTypes.string.isRequired,
    acceptButtonText: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    additionalInfo: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    scores: PropTypes.object.isRequired,
    frameScores: PropTypes.object.isRequired,
    scoreNumber: PropTypes.number.isRequired,
  };

  handleOk = () => {
    this.props.onSubmit();
  };

  handleCancel = () => {
    this.props.onCancel();
  };

  render() {
    const {
      title, acceptButtonText, declineButtonText, visible,
    } = this.props;
    return (
      <Modal
        title={title}
        visible={visible}
        closable={false}
        width={395}
        onOk={this.handleOk}
        footer={[
          <div key="buttons" className="start-game__buttons">
            <Button
              kind="aquamarine"
              bordered="true"
              className="start-game__button-decline"
              onClick={this.handleCancel}
            >
              {declineButtonText}
            </Button>
            <Button kind="aquamarine" className="start-game__button-accept" onClick={this.handleOk}>
              {acceptButtonText}
            </Button>
          </div>,
        ]}
      >
        <div className="edit-modal_container">
          <Frame
            number={this.props.scoreNumber}
            scores={this.props.scores.prev}
            frameScore={this.props.frameScores.prev}
          />
          <Arrow style={{ padding: 30 }} />
          <Frame
            isOwner
            isCurrent
            number={this.props.scoreNumber}
            isEditing
            scores={this.props.scores.next}
            frameScore={this.props.frameScores.next}
          />
        </div>
        <div>{this.props.additionalInfo}</div>
      </Modal>
    );
  }
}

export default EditResultsModal;
