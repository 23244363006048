import React, { Component } from 'react'
import { Form, Input, Modal } from 'antd'
import { connect } from 'react-redux'
import { sendBowlingCenterRequest } from '../../libs/reducers/actions'

import './style.scss'
import './adaptive.scss'

const { TextArea } = Input

class EquipmentForm extends Component {

  constructor(props) {

    super(props)

    this.state = {
      error: false,
      sending: false,
      sended: false,
      name: '',
    }

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.sending && nextProps.request.sending) {
      return { sending: true }
    }
    if (prevState.sending && !nextProps.request.sending) {
      nextProps.form.resetFields()
      return { sending: false, sended: true }
    }
  }

  handleSubmit = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.sendBowlingCenterRequest({ message: values });
        this.setState({ error: false, errorEmail: false, name: '' });

        const ymKey = 52252669;
        if (window.ym) {
          window.ym(ymKey, 'reachGoal', 'Install_Equipment');
        }
      } else {
        const emailError= err['User Email'] && err['User Email'].errors[0].message === "User Email is not a valid email";
        if (Object.keys(err).length == 1 && emailError){
          this.setState({ error: false, errorEmail: emailError });
        } else {
          this.setState({ error: true, errorEmail: emailError });
        }
          
      }
    })
  }

  onFinishName = (sender, value) => {
    this.setState({name: sender.target.value})
  }

  onFocus = e => {
    e.target.offsetParent.setAttribute('focused', true)
  }

  onBlur = e => {
    e.target.offsetParent.removeAttribute('focused', true)
  }

  renderForm = () => {
    const { error, errorEmail } = this.state
    const { form, intl } = this.props
    const { getFieldDecorator } = form
    return (
      <>
        <div id="install_equipment" className="left">
          <h2>{intl.formatMessage({
            id: 'LandingInstallEquipmentTitle',
            defaultMessage: 'Install TenPinCam equipment',
          })}</h2>
          <p>{intl.formatMessage({
            id: 'LandingInstallEquipmentSubTitle',
            defaultMessage: 'Please indicate which bowling center you would like to see on our platform. Please also leave your contacts. Thank!'
          })}</p>
        </div>
        <div className="right">
          <Form layout="inline" onSubmit={this.handleSubmit}>
            <div className="row cols-2">
              <Form.Item>
                {
                  getFieldDecorator('User Name', { rules: [{ required: true }] })(
                    <Input
                      placeholder={intl.formatMessage({ id: 'YourName', defaultMessage: 'Your Name' })}
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onChange={this.onFinishName}
                    />
                  )
                }
              </Form.Item>
              <Form.Item>
                {
                  getFieldDecorator('User Phone', { rules: [{ required: true }] })(
                    <Input
                      placeholder={intl.formatMessage({ id: 'YourPhone', defaultMessage: 'Your Phone' })}
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                    />
                  )
                }
              </Form.Item>
            </div>
            <div className="row">
              <Form.Item>
                {
                  getFieldDecorator('User Email', { rules: [{ required: true, type:'email' }] })(
                    <Input
                      placeholder={intl.formatMessage({ id: 'YourEmail', defaultMessage: 'Your Email' })}
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                    />
                  )
                }
              </Form.Item>
            </div>
            {errorEmail && <span className="error" style={{position:"inherit"}}>This is not correct email</span>}
            <p>{intl.formatMessage({
              id: 'LandingInformationBowlingAlley',
              defaultMessage: 'Information on Bowling Alley',
            })}</p>
            <div className="row cols-2">
              <Form.Item>
                {
                  getFieldDecorator('Name of Bowling Alley', { rules: [{ required: true }] })(
                    <Input
                      placeholder={intl.formatMessage({ id: 'LandingNameBowlingAlley', defaultMessage: 'Name of Bowling Alley' })}
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                    />
                  )
                }
              </Form.Item>
              <Form.Item>
                {
                  getFieldDecorator('Contact Phone', { rules: [{ required: true }] })(
                    <Input
                      placeholder={intl.formatMessage({ id: 'LandingContactPhone', defaultMessage: 'Contact Phone' })}
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                    />
                  )
                }
              </Form.Item>
            </div>
            <div className="row">
              <Form.Item>
                {
                  getFieldDecorator('Contact Person', { rules: [{ required: true }] })(
                    <Input
                      placeholder={intl.formatMessage({ id: 'ContactPerson', defaultMessage: 'Contact person' })}
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                    />
                  )
                }
              </Form.Item>
            </div>
            <div className="row">
              <Form.Item>
                {
                  getFieldDecorator('Street', { rules: [{ required: true }] })(
                    <TextArea
                      placeholder={intl.formatMessage({ id: 'Street', defaultMessage: 'Street' })}
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                    />
                  )
                }
              </Form.Item>
            </div>
            <div className="row cols-3">
              <Form.Item>
                {
                  getFieldDecorator('Country', { rules: [{ required: true }] })(
                    <Input
                      placeholder={intl.formatMessage({ id: 'BowlingcenterFilterByCountry', defaultMessage: 'Country' })}
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                    />
                  )
                }
              </Form.Item>
              <Form.Item>
                {
                  getFieldDecorator('City', { rules: [{ required: true }] })(
                    <Input
                      placeholder={intl.formatMessage({ id: 'BowlingcenterFilterByCity', defaultMessage: 'City' })}
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                    />
                  )
                }
              </Form.Item>
              <Form.Item className="postcode" style={{ maxWidth: 113 }}>
                {
                  getFieldDecorator('Post Code', {})(
                    <Input
                      placeholder={intl.formatMessage({ id: 'LandingPostCode', defaultMessage: 'Post code' })}
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                    />
                  )
                }
              </Form.Item>
            </div>
          </Form>
          {error && <span className="error">Please, fill all required fields</span>}
          {this.state.name === '.<!test>' && <span>TEST MESSAGE</span>}
        </div>
      </>
    )
  }

  render() {
    const { intl } = this.props;
    const { sending, sended } = this.state
    return (
      <div id="formEquipment" className="landing__container">
        <div className="equipment__from">
          <div className="container">
            {this.renderForm()}
          </div>
          <div onClick={sending ? () => {} : this.handleSubmit} className={`btn big filled`}>
            {sending ? 'Sending...' : intl.formatMessage({
              id: 'LandingInstallEquipmentBtn',
              defaultMessage: 'Submit',
            })}
          </div>
          <p>{intl.formatMessage({
            id: 'LandingInstallEquipmentBottomText',
            defaultMessage: 'By pressing Submit you agree with our Personal Data usage policies and other Terms & Conditions',
          })}</p>
        </div>
        <Modal className="landing-modal"
          visible={sended}
          title="Thank you for your request!"
          width={785}
          maskClosable
          onCancel={() => {
            this.setState({ sended: false }) 
          }}
          maskStyle={{
            backgroundColor: 'rgba(0, 0, 0, .5)'
          }}
          footer={[
            <div style={{maxWidth: '33%'}} onClick={() => { this.setState({ sended: false }) }} className="btn big filled">
              Back
            </div>
          ]}
        >
          We will get in touch with you shortly, regarding the installation of the TenPinCam equipment in your alley.
        </Modal>
      </div>
    )
  }
}

const wrapper = Form.create({ name: 'equipment_from' })(EquipmentForm)

export default connect(
  state => ({
    request: state.bowlingcenters.request
  }),
  {
    sendBowlingCenterRequest
  }
)(wrapper)