import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import OBModal from '../../Modal';
import { Button } from '../../Button';

import './style.scss';
import { Input } from 'antd';

class ModalOpponentProtest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      protestProposition: null,
      textareaInstance: 0,
    };
  }

  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    protestText: PropTypes.string,
    protestReason: PropTypes.number,
    intl: intlShape,
  };

  handleSubmit = () => {
    this.props.onSubmit(this.state.protestProposition);
    this.setState(prevState => ({
      protestResolve: null,
      protestProposition: null,
      textareaInstance: prevState.textareaInstance + 1,
    }));
  }

  render() {
    const {
      visible,
      protestText,
      protestReason,
      protestId,
      intl,
      protestRequireFrame,
      protestFrameNumber
    } = this.props;
    const { protestProposition, textareaInstance } = this.state;
    let reason = null;
    if (protestRequireFrame) {
      const n = intl.formatMessage({ id: 'number', defaultMessage: '#{number}' }, { number: protestFrameNumber })
      reason = `${intl.formatMessage({ id: protestReason, defaultMessage: protestReason })} ${n}`;
    } else {
      reason = intl.formatMessage({ id: protestReason, defaultMessage: protestReason });
    }
    let handleButtonDisabled = null;
    if (!protestProposition || protestProposition === '') {
      handleButtonDisabled = 'disabled';
    }

    return (
      <OBModal
        title={intl.formatMessage({ id: 'ProtestSubmittedModalTitle', defaultMessage: 'Protest submitted #{protestid}' }, { protestid: protestId })}
        visible={visible}
        width={395}
        closable={false}
        footer={
          <Button
            disabled={handleButtonDisabled}
            onClick={this.handleSubmit}
            kind='aquamarine'
            className='protest__button-accept'
            >
            {intl.formatMessage({ id: 'ProtestSubmitButton', defaultMessage: 'Submit' })}
          </Button>
        }
        >
        <div className='protest__opponent'>
          <p className='protest__opponent__label'>{intl.formatMessage({ id: 'ProtestReason', defaultMessage: 'Protest reason' })}</p>
          <p className='protest__opponent__value'>{reason}</p>
          <p className='protest__opponent__label'>{intl.formatMessage({ id: 'ProtestOwnerCommentary', defaultMessage: 'Protest commentary' })}</p>
          <p className='protest__opponent__value'>{protestText}</p>
        </div>
        <div className='protest__opponent'>
          <textarea className={!protestProposition ? 'empty' : ''}
            key={textareaInstance}
            placeholder={intl.formatMessage({ id: 'ProtestAccusedCommentaryPlaceholder', defaultMessage: 'Describe your point of view' })}
            value={protestProposition}
            onChange={(e) => {
              this.setState({ protestProposition: e.target.value });
            }}
          />
        </div>
      </OBModal>
    );
  }
}

export default injectIntl(ModalOpponentProtest);
