import React, { Component } from "react";
import ReactWOW from "react-wow";
import { Modal } from "antd";
import titleBig from "./images/Logo-TenPinCam_big.png";
import titleSmall from "./images/Logo-TenPinCam_small.png";
import headerPhone from "./images/header-phone.png";
import PlaySvg from "-!svg-react-loader!./images/icons/play.svg"; // eslint-disable-line
// import EsFlag from '-!svg-react-loader!../../images/flags/1x1/es.svg'; // eslint-disable-line
// import GbFlag from '-!svg-react-loader!../../images/flags/1x1/gb.svg'; // eslint-disable-line
// import RuFlag from '-!svg-react-loader!../../images/flags/1x1/ru.svg'; // eslint-disable-line
import utils from "../../libs/utils";
import HeaderTop from './headerTop'

import game3webm from "./videos/game3.webm";
import game3mp4 from "./videos/game3.mp4";

import "./adaptive.scss";
import "./style.scss";
import InvestorsModal from "./investorsModal/investorsModal";

export default class LandingHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      isPlaying: false,
      showInvestorsModal: false,
    };
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {}


  switchInvestorModalVisible = () => {
    this.setState({ showInvestorsModal: !this.state.showInvestorsModal });
}

  renderContent = () => {
    const { intl, history } = this.props;

    return (
      <div className="landing__container">
        {/* <div className="landing__header__top">
          <div className="header-left__container">
            <div className="logo">
              <img src={logo} alt="" />
            </div>
            <div className="header-lang__container">
              <SelectLang  />
            </div>
          </div>
          <div className="links">
            <div
              onClick={() => {
                this.props.scrollToBenefits();
              }}
              className="btn"
            >
              {intl.formatMessage({
                id: "ForBowlingCenter",
                defaultMessage: "For bowling center"
              })}
            </div>
            <a className="sign-in btn small bordered" href="/signin">
              {intl.formatMessage({
                id: "SignIn",
                defaultMessage: "Sign in"
              })}
            </a>
            <div
              className="dropdown btn small filled"
              onClick={this.openDropDown}
            >
              {intl.formatMessage({
                id: "DownloadMobileApp",
                defaultMessage: "Download mobile app"
              })}
              {isDropDown && (
                <div className="dropdown__menu">
                  <a
                    className="dropdown__menu__item"
                    target="blank"
                    href="https://apps.apple.com/ru/app/tenpincam/id1481986359?l=en"
                    onClick={() => this.onClickDownload('ios')}
                  >
                    <img src={appStoreIcon} alt="" />
                    iOS
                  </a>
                  <div className="dropdown__menu__separator"></div>
                  <a
                    className="dropdown__menu__item"
                    target="blank"
                    href="/api/settings/mobile_android_app"
                    onClick={() => this.onClickDownload('android')}
                  >
                    <img src={googlePlayIcon} alt="" />
                    Android
                  </a>
                </div>
              )}
            </div>
          </div>
        </div> */}

        <HeaderTop
          scrollToBenefits={this.props.scrollToBenefits}
          scrollToApplicationShowCase={this.props.scrollToApplicationShowCase}
          scrollToAlleyList={this.props.scrollToAlleyList}
          scrollToEquipmentForm={this.props.scrollToEquipmentForm}
          switchInvestorModalVisible={this.switchInvestorModalVisible}
        />

        <div className="landing__header__bottom">
          <div className="left-side">
            {/* <img src={title} alt="TenPinCam" /> */}
            <div className="header__title">
              <picture>
                <source media="(min-width: 768px)" srcset={titleBig} />
                <img src={titleSmall} alt="TenPinCam" />
              </picture>
            </div>
            <p className="slogan">
              <div className="slogan__mask"></div>
              {intl.formatMessage({
                id: "LandingSlogan",
                defaultMessage: "The Revolution in Bowling!"
              })}
            </p>
            <p className="description">
              {intl.formatMessage({
                id: 'LandingSloganDescription',
                defaultMessage: 'TenPinCam is a unique platform for Bowling competitions in Real Time in different bowling centers across the world, using the web cameras & Internet connection.',
              })}
            </p>
            <div className="actions">
              <div
                onClick={() => this.props.scrollToApplicationsShowCase()}
                className="btn big bordered"
              >
                {intl.formatMessage({
                  id: "LandingHeaderAction1",
                  defaultMessage: "I want to know more!"
                })}
              </div>
              <div
                onClick={() => {
                  this.setState({ showModal: true });
                }}
                className="btn big filled"
              >
                {intl.formatMessage({
                  id: "LandingHeaderAction2",
                  defaultMessage: "Panic! I’m scared of revolutions"
                })}
              </div>
            </div>
          </div>
          <div className="right-side">
            <div className="phone">
              <div className="phone__play">
                <div className="phone__play__big_bubble"></div>
                <div className="phone__play__small_bubble"></div>
                <div
                  className="phone__play__button"
                  onClick={() => {
                    history.push("/login");
                  }}
                >
                  <PlaySvg />
                </div>
              </div>
              <img src={headerPhone} alt="" />
              <div className="phone__screen">
                <video
                  id="ID_of_video"
                  ref={v => {
                    this.video = v;
                  }}
                  muted
                  loop
                  webkit-playsinline="true"
                  playsinline="true"
                  autoplay
                  style={{ width: "100%", height: "100%" }}
                >
                  <source src={game3webm} />
                  <source src={game3mp4} />
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { intl } = this.props;

    return (
      <div className="landing__header">
        {utils.isMobile() ? (
          this.renderContent()
        ) : (
          <ReactWOW animation="customFadeInDown" duration="1.5s">
            {this.renderContent()}
          </ReactWOW>
        )}
        <link rel="prefetch" href="./images/bg_480.png" />
        <Modal
          className="landing-modal"
          visible={this.state.showModal}
          title={intl.formatMessage({ id: 'LandingDontWorryModalTitle', defaultMessage: 'Don’t worry!' })}
          width={785}
          maskClosable
          onCancel={() => {
            this.switchInvestorModalVisible()
          }}
          maskStyle={{
            backgroundColor: "rgba(0, 0, 0, .5)"
          }}
          footer={[
            <div
              key={1}
              style={{ maxWidth: '230px' }}
              onClick={() => {
                this.setState({ showModal: false });
                this.props.scrollToApplicationShowCase();
              }}
              className="btn big filled"
            >
              {intl.formatMessage({
                id: 'LandingDontWorryModalHeader',
                defaultMessage: 'Awesome! Tell me more'
              })}
            </div>
          ]}
        >
          {intl.formatMessage({
            id: 'LandingDontWorryModalBody',
            defaultMessage: 'It’s nothing scary it’s just a new way of playing with your friends from another countries or cities. We promise you will love it.',
          })}
        </Modal>
          <InvestorsModal
              isVisible={this.state.showInvestorsModal}
              onCancel={this.switchInvestorModalVisible}
          />
      </div>
    );
  }
}
