/* eslint-disable arrow-parens */
import moment from 'moment';

/**
 * @typedef {{
 *  id: number;
 *  iban: string;
 *  countryid: number;
 *  dob: string;
 *  firstname: string;
 *  lastname: string;
 *  personalid: string;
 *  postcode: string;
 *  province: string;
 *  address: string;
 * }} IbanEntity
 */


/**
 * @param {IbanEntity} iban
 * @return {import('../personal-data-add.fragment').IPersonalData}
 */
export const toPersonalData = (iban) => {
  if (!iban) {
    return {};
  }
  return {
    firstName: iban.firstname,
    lastName: iban.lastname,
    countryId: iban.countryid,
    dob: iban.dob ? moment(iban.dob) : iban.dob,
    personalId: iban.personalid,
    postcode: iban.postcode,
    province: iban.province,
    address: iban.address,
  };
};

/** @type {IbanEntity} */
const NULL_IBAN = Object.freeze({
  id: null,
  firstname: null,
  lastname: null,
  countryid: null,
  dob: null,
  personalid: null,
  postcode: null,
  province: null,
  address: null,
});

/**
 * @template T
 * @param {string} value
 * @param {import('../personal-data-add.fragment').IPersonalData} data
 * @param {Extract<T, import('../iban-value-create.fragment').IbanValueCreateFragmentProps['type']>} type
 * @returns {IbanEntity & { method: T }}
 */
export const toIbanEntity = (value, data, type) => {
  if (!data) {
    return { ...NULL_IBAN, iban: value, method: type };
  }
  /** @type {IbanEntity & { method: T }} */
  const res = {
    iban: value,
    method: type,
    firstname: data.firstName,
    lastname: data.lastName,
    countryid: data.countryId,
    dob: data.dob,
    personalid: data.personalId,
    postcode: data.postcode,
    province: data.province,
    address: data.address,
  };
  return Object.keys(res).reduce((acc, key) => {
    acc[key] = res[key];
    if (typeof res[key] === 'undefined') {
      acc[key] = null;
    }
    return acc;
  }, {});
};
