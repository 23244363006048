import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import defaultAvatar from '../../../../images/defaultAvatar.png';
import OBModal from '../../Modal';
import Flag from '../../Flag';
import { Button } from '../../Button';
import MatchScore from '../../../matchScore';
import config from '../../../../config';
import './style.scss';
import GameScore from '../../GameScore';
import { isSolo, isPinfall } from '../../../../libs/SquadTypeCheck';

class ModalPlayers extends Component {
  static propTypes = {
    intl: intlShape,
    visible: PropTypes.bool,
    title: PropTypes.string,
    titleID: PropTypes.string,
    titleDefault: PropTypes.string,
    text: PropTypes.string,
    userid: PropTypes.number,
    showStats: PropTypes.bool,
    status: PropTypes.string,
  };

  static defaultProps = {
    visible: false,
  };

  onRating = () => {
    const { ratingsOpen = false } = this.state;
    this.setState({ ratingsOpen: !ratingsOpen });
  };

  createRatingSVG = (rating) => {
    const poly = 'M14 1.12978L17.6661 8.55816L17.7825 8.79389L18.0426 8.83169L26.2403 10.0229L20.3084 15.8051L20.1202 15.9886L20.1646 16.2476L21.5649 24.4122L14.2327 20.5574L14 20.4351L13.7673 20.5574L6.43507 24.4122L7.83541 16.2476L7.87984 15.9886L7.69161 15.8051L1.75969 10.0229L9.9574 8.83169L10.2175 8.79389L10.3339 8.55816L14 1.12978Z';
    const numbers = [1, 2, 3, 4, 5];
    return numbers.map((num, idx) => {
      let fill = 'none';
      let isPartial = false;
      const entropy = Math.random();
      if (rating > num || rating == num) {
        fill = '#FFB31E';
      } else if (num - 1 < rating && rating < num) {
        isPartial = true;
        fill = `url(#partialGradient${entropy})`;
      }
      return (
        <svg
          key={idx}
          className={`${rating === num ? 'selected' : ''}`}
          width="20"
          height="20"
          viewBox="0 0 28 28"
          fill={fill}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d={poly} stroke="#FFB31E" />
          {isPartial ? (
            <linearGradient id={`partialGradient${entropy}`}>
              <stop stopOpacity="1" offset={`${(rating - num + 1) * 100}%`} stopColor="#FFB31E" />
              <stop stopOpacity="0" offset="0" />
            </linearGradient>
          ) : null}
        </svg>
      );
    });
  };

  renderPlayerRating = ({ ratings=[], ratingsavg=0 }) => {
    return (
      <div className="rating-container" onClick={this.onRating}>
        <div className="rating-stars">{this.createRatingSVG(+ratingsavg)}</div>
        <span className="rating-score">{`${(+ratingsavg || 0).toFixed(2)}`}</span>
      </div>
    );
  };

  renderPlayerItem = (player, index, tournamenttypebrief) => (
    <div className="player-item-container" key={index}>
      <div className="player-item">
        <div className="player-column-left">
          <div className="player-info">
            <div className="player-name">{`${player.lastname} ${player.firstname}`}</div>
            <div className="player-login">{player.login}</div>
            <div className="player-rating">{this.renderPlayerRating(player)}</div>
          </div>
        </div>
        <div className="player-column-right">
          <div className={`player-photo${player.self ? ' self' : ''}`}>
            <img src={player.photo ? `${config.FETCH.img}/${player.photo}` : defaultAvatar} />
            <div className='avgScore'>{(+player.avgscore || 0).toFixed(0)}</div>
            <div className="avatar__flag">
              <Flag name={player.country_live.toLowerCase()} />
            </div>
          </div>
          <div className={`player-score${player.self ? ' self' : ''}`}>{
            isPinfall(tournamenttypebrief)
              ? player.total_score : player.gameswin
          }</div>
        </div>
      </div>
    </div>
  );

  renderPlayers = () => {
    const { userid, squad, status, drawState } = this.props;
    const players = squad.players.map(player => {
      const editedPlayer = { ...player };
      if (editedPlayer.userid === userid) editedPlayer.self = true;
      if (squad.games.length !== 1) {
        editedPlayer.score = player.gameswin
      } else {
        editedPlayer.score = Math.max(...squad.games[0].frames[player.id].map(frame => frame.score));
      }
      if (status === 'draw') {
        editedPlayer.score = drawState;
      }
      return editedPlayer;
    })
    return (
      <div className="modal-players">
        <div className="players-container">{players.map((p, i) => this.renderPlayerItem(p, i, squad.tournamenttypebrief))}</div>
      </div>
    );
  };

  renderGameStats = () => {
    const { intl, squad } = this.props;
    const {
      cost = 0, datestart: start, wincount = 1, tournamentcurrencysymbol, tournamenttypeid,
      tournamenttypebrief,
    } = squad;
    let costText = '';
    if (cost) {
      costText = `${cost}${tournamentcurrencysymbol}`;
    } else {
      costText = intl.formatMessage({ id: 'free_game', defaultMessage: 'Fun game' });
    }
    return (
      <div className="game-stats">
        <div className="game-date">
          <span className="game-date-header stat-header">
            {intl.formatMessage({ id: 'GameDate', defaultMessage: 'Game date' })}
          </span>
          <span className="game-date-value stat-value">{moment(start).format('HH:mm')}</span>
        </div>
        {/*<div className="game-cost">
          <span className="game-cost-header stat-header">
            {intl.formatMessage({ id: 'fee', defaultMessage: 'Fee' })}
          </span>
          <span className="game-cost-value stat-value">{costText}</span>
        </div>*/}
        <div className="game-wincount">
          <span className="game-wincount-header stat-header">
          {(isSolo(tournamenttypebrief) && intl.formatMessage({ id: 'games', defaultMessage: 'Games' }))
            || (tournamenttypebrief === 'PINFALL_BATTLE' && 'Pinfall')
            || intl.formatMessage({ id: 'NumberOfWins', defaultMessage: 'Number of wins' })
          }
          </span>
          <span className="game-wincount-value stat-value">{wincount}</span>
        </div>
      </div>
    );
  };

  renderText = () => {
    const { intl } = this.props;
    const {
      text = intl.formatMessage({ id: 'ModalDefaultCancel', defaultMessage: 'Cancel' }),
      showStats = false,
    } = this.props;
    return (
      <div className="modal-text">
        <span>{text}</span>
        {showStats && this.renderGameStats()}
      </div>
    );
  };

  render() {
    const {
      intl,
      titleID = 'GameResultDraw',
      titleDefault = 'Draw!',
      footer,
      onOk = () => {},
      onCancel = () => {},
      zIndex,
      className,
      visible,
      ratingBody,
      hideText,
      hideRatingBody,
      squad,
    } = this.props;
    const {
      title = intl.formatMessage({ id: titleID, defaultMessage: titleDefault }),
      okText = intl.formatMessage({ id: 'ModalDefaultOK', defaultMessage: 'OK' }),
      cancelText = intl.formatMessage({ id: 'ModalDefaultCancel', defaultMessage: 'Cancel' }),
    } = this.props;
    return (
      <div className={className}>
        <OBModal
          className="modal-game"
          title={title}
          width={squad.tournamenttypeid !== 3 ? 450 : 681}
          visible={visible}
          onOk={onOk}
          okText={okText}
          cancelText={cancelText}
          onCancel={onCancel}
          footer={footer}
          zIndex={zIndex}
        >
          {this.renderPlayers()}
          {!hideRatingBody && squad.tournamenttypeid !== 3 && this.renderText()}
          {!hideText && squad.tournamenttypeid !== 3 && ratingBody}
          {
            squad.tournamenttypeid === 3 && squad.games.map((game, index) => (
              <div key={index} className="game-score__container">
                <div className="game-score__label">Game {index + 1}</div>
                {squad.players.map(player => (
                  <GameScore
                    key={player.id}
                    frames={game.frames[player.id]}
                    frameScoreSumStyles={{
                      backgroundColor: '#692E25',
                      color: '#fff',
                      borderRight: '1px solid black'
                    }}
                    frameNumberStyles={{
                      color: '#fff',
                      borderRight: '1px solid black'
                    }}
                  />
                ))}
              </div>
            ))
          }
        </OBModal>
      </div>
    );
  }
}

export default injectIntl(
  connect(
    state => ({
      userid: state.users.user.id,
      //players: state.squads.squad.data.players,
      squad: state.squadScreen.data,
      drawState: state.events.game.lastDraw,
    }),
    {},
  )(ModalPlayers),
);
