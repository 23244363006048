import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import DropDownArrow from "-!svg-react-loader!../images/dropdown_arrow.svg"; // eslint-disable-line

import './style.scss';

const DropDownItem = ({
  intl,
  tournament,
}) => {
  const [opened, setOpened] = useState(false);

  return (
    <div className="dropdown-item">
      <div className="dropdown-item-element dropdown-item-title" onClick={() => setOpened(!opened)}>
        <div className="dropdown-item-element-key title">{tournament.name}</div>
        <div className="dropdown-item-element-value">{tournament.date}</div>
        <div className="dropdown-item-arrow__container">
          <DropDownArrow className={`dropdown-item-arrow__image${(opened && ' opened') || ''}`} />
        </div>
      </div>
      <div className={`dropdown-item-info${(opened && ' opened') || ''}`}>
        <div className="dropdown-item-image__container">
          {(tournament.image && (
            <img className="dropdown-item-image" src={tournament.image} />
          )) || ''}
        </div>
        <div className="dropdown-item-info__container">
          <div className="dropdown-item-element">
            <div className="dropdown-item-element-key">
              {intl.formatMessage({
                id: 'BowlingCenter',
                defaultMessage: 'Bowling center',
              })}:
            </div>
            <div className="dropdown-item-element-value">{tournament.bowlingCenter}</div>
          </div>
          <div className="dropdown-item-element">
            <div className="dropdown-item-element-key">
              {intl.formatMessage({
                id: 'choose_fee',
                defaultMessage: 'Entry fee',
              })}:
            </div>
            <div className="dropdown-item-element-value">{tournament.entryFee}</div>
          </div>
          <div className="dropdown-item-element">
            <div className="dropdown-item-element-key">
              {intl.formatMessage({
                id: 'TournamentFormatLanding',
                defaultMessage: 'Tournament format',
              })}:
            </div>
            <div className="dropdown-item-element-value">{tournament.tournamentFormat}</div>
          </div>
          <div className="dropdown-item-element">
            <div className="dropdown-item-element-key">
              {intl.formatMessage({
                id: 'PrizesLanding',
                defaultMessage: 'Prizes',
              })}:
            </div>
            <div className="dropdown-item-element-value value__list">
              <ul>
                {tournament.prizes.map((p, index) => (
                  <li className="value__list-item" key={index.toString()}>
                    {p}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="dropdown-item-element">
            <div className="dropdown-item-element-key">
              {intl.formatMessage({
                id: 'registration',
                defaultMessage: 'Registration',
              })}:
            </div>
            <div className="dropdown-item-element-value">{tournament.registration}</div>
          </div>
          <div className="dropdown-item-element">
            <div className="dropdown-item-element-key">
              {intl.formatMessage({
                id: 'AdditionalTermsTournamentLanding',
                defaultMessage: 'Additional terms',
              })}:
            </div>
            <div className="dropdown-item-element-value value__list">
              <ul>
                {tournament.additionalTerms.map((item, index) => (
                  <li className="value__list-item" key={index.toString()}>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(DropDownItem);
