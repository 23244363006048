import React, { Component } from 'react';
import PropTypes from 'prop-types';

class InputPair extends Component {
    static defaultProps = {
        name: '',
        value: '',
    }
    static propTypes = {
        idx: PropTypes.number.isRequired,
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        onChange: PropTypes.func.isRequired,
    }
    static getDerivedStateFromProps(props, state) {
        const { name, value } = props;
        const nextState = {};
        if (name !== state.name) {
            nextState.name = name;
        }
        if (value !== state.value) {
            nextState.value = value;
        }
        return (nextState.name || nextState.value) ? nextState : null;
    }
    constructor(props) {
        super(props);
        this.state = {
            name: props.name,
            value: props.value,
        };
    }
    render() {
        const { name, value } = this.state;
        return (<div>
            <input 
                type="text"
                name="name"
                value={name}
                onChange={this.handleChange}
                placeholder="Enter key"
            />
            {" : "}
            <input
                type="text"
                name="value"
                value={value}
                onChange={this.handleChange}
                placeholder="Enter value"
            />
        </div>)
    }
    handleChange = (e) => {
        const { name, value } = e.target;
        const { idx } = this.props;
        const m = [idx, name, value];
        this.setState({ [name]: value });
        this.props.onChange(m);
    }
}

export default InputPair;
