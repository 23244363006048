import React from 'react';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import config from './config';
import { store } from './store';
import app from './package-alias';

const getUserIdFromStore = () => {
  try {
    const { users: { user } } = store.getState();
    return user.id || null;
  } catch (error) {
    return null;
  }
};

/* eslint-disable no-param-reassign, max-len */
const bugsnagOptions = {
  apiKey: config.bugsnag ? config.bugsnag.apiKey : 'bugsnag apiKey not found',
  appVersion: app.version, // опционально, но если указать appVersion, то обязательно указать appVersion и в файле upload-sourcemaps.js
  releaseStage: config.bugsnag.releaseStage || 'beta',
  collectUserIp: false,
  notifyReleaseStages: ['production', 'beta', 'development'],
  /*beforeSend: (report) => {
    const userId = getUserIdFromStore();
    report.user = { id: userId };
    if (
      report.releaseStage === 'development'
      || (report.errorMessage || '').toLowerCase() === 'invalid login or password'
    ) {
      report.ignore();
    }
  },*/
  metaData: {
    company: {
      name: 'Bokus',
      country: 'RU',
    },
  },
};
/* eslint-enable no-param-reassign, max-len */

const bugsnagClient = bugsnag(bugsnagOptions);
bugsnagClient.use(bugsnagReact, React);

export default bugsnagClient;
