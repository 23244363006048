import React from 'react';

const schedule = [
  {
    name: 'Friday',
    laneMaintenance: [
      [
        {
          group: 'Group A',
          time: '10:00 - 11:00',
          participants: '16 Boys',
          matches: '8 matches',
        },
        {
          group: 'Group B',
          time: '11:00 - 12:00',
          participants: '16 Boys',
          matches: '8 matches',
        },
      ],
      [
        {
          group: 'Group A',
          time: '12:30 - 13:30',
          participants: '8 Boys',
          matches: '4 matches',
        },
        {
          group: 'Group B',
          time: '13:30 - 14:30',
          participants: '8 Boys',
          matches: '4 matches',
        },
      ],
      [
        {
          group: 'Group A',
          time: '15:00 - 16:00',
          participants: '4 Boys quater-final',
          matches: '2 matches',
        },
        {
          group: 'Group B',
          time: '16:00 - 17:00',
          participants: '4 Boys quater-final',
          matches: '2 matches',
        },
      ],
    ],
  },
  {
    name: 'Saturday',
    laneMaintenance: [
      [
        {
          group: 'Group A',
          time: '10:00 - 11:00',
          participants: '16 Girls',
          matches: '8 matches',
        },
        {
          group: 'Group B',
          time: '11:00 - 12:00',
          participants: '16 Girls',
          matches: '8 matches',
        },
      ],
      [
        {
          group: 'Group A',
          time: '12:30 - 13:30',
          participants: '8 Girls',
          matches: '4 matches',
        },
        {
          group: 'Group B',
          time: '13:30 - 14:30',
          participants: '8 Girls',
          matches: '4 matches',
        },
      ],
      [
        {
          group: 'Group A',
          time: '15:00 - 16:00',
          participants: '4 Girls quater-final',
          matches: '2 matches',
        },
        {
          group: 'Group B',
          time: '16:00 - 17:00',
          participants: '4 Girls quater-final',
          matches: '2 matches',
        },
      ],
    ],
  },
  {
    name: 'Sunday',
    laneMaintenance: [
      [
        {
          group: 'Group A',
          time: '10:00 - 11:00',
          participants: '2 Boys semi-final',
          matches: '1 match',
        },
        {
          group: 'Group B',
          time: '11:00 - 12:00',
          participants: '2 Boys semi-final',
          matches: '1 match',
        },
      ],
      [
        {
          group: 'Group A',
          time: '12:30 - 13:30',
          participants: '2 Girls semi-final',
          matches: '1 match',
        },
        {
          group: 'Group B',
          time: '13:30 - 14:30',
          participants: '2 Girls semi-final',
          matches: '1 match',
        },
      ],
      [
        {
          group: 'Group A + B',
          time: '15:00 - 16:00',
          participants: 'Boys final',
          matches: '1 match',
        },
        {
          group: 'Group A + B',
          time: '16:00 - 17:00',
          participants: 'Girls final',
          matches: '1 match',
        },
      ],
    ],
  },
];


const Schedule = () => {
  return (
    <div className="shedule">
      {
        schedule.map(day => (
          <div className="s-day" key={day.name}>
            <div className="s-d-name">{day.name}</div>
            <div className="lms">
              {
                day.laneMaintenance.map((lane, index) => (
                  <div className="lm" key={index}>
                    <div className="lm-title">Lane Maintenance</div>
                    <div className="s-dot left"/>
                    <div className="s-dot right"/>
                    {
                      lane.map((stage, index) => (
                        <div className="lm-group" key={index}>
                          <div className="g-title">
                            <div>{stage.group}</div>
                            <div className="g-text">{stage.time}</div>
                          </div>
                          <div className="g-text-block">
                              <div className="g-text">{stage.participants}</div>
                              <div className="g-text">{stage.matches}</div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                ))
              }
            </div>
          </div>
        ))
      }
    </div>
  );
};

export default Schedule;
