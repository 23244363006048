import React, { useLayoutEffect } from 'react';
import Article from './components/common/article';
import Header from './components/header';
import Footer from './components/footer';
import AboutTournament from './components/aboutTournament';
import BowlingCenters from './components/bowlingCenters';
import Sponsors from './components/sponsors';
import Cometee from './components/cometee';
import NotAvailable from './components/common/notAvailable';
import AboutTenpincam from './components/aboutTenpincam';
import Participants from './components/participants';
import Forms from './components/forms';
import TOC from './components/tableOfContent';
import Shedule from './components/schedule';
import './styles.scss';

export default function EuropeanYouthTournament({
  history,
}) {
  useLayoutEffect(() => {
    document
      .getElementById('viewporttag')
      .setAttribute(
        'content',
        `width=auto, initial-scale=${1}`
      );
  }, []);
  return (
    <div className="container-tl">
      <Header/>
        <div>
          <TOC/>
          <Article
            color="dark"
            id="about-tournament"
            title="About tournament"
          >
            <AboutTournament/>
          </Article>
          <Article
            id="participants"
            color="orange"
            title="Participants"
            subtitle="of the tournament"
          >
            <Participants history={history}/>
          </Article>
          <Article
            id="shedule"
            color="dark"
            title="Shedule"
            className="smallPadding"
            subtitle="of the tournament"
          >
            <Shedule/>
          </Article>
          <Article
            id="results"
            color="dark"
            title="Results"
            className="smallPadding"
            subtitle="of the tournament"
          >
            <NotAvailable/>
          </Article>
          <Article
            id="forms"
            color="dark"
            title="Forms"
            className="smallPadding"
            subtitle="for registration"
          >
            <Forms/>
          </Article>
          <Article
            id="about-tenpincam"
            color="dark"
            className="smallPadding"
            title="About TenPinCam"
          >
            <AboutTenpincam history={history}/>
          </Article>
          <Article
            id="bowling-centers"
            color="dark"
            className="smallPadding"
            title="Where you can play"
          >
            <BowlingCenters history={history}/>
          </Article>
          <Article
            id="sponsors"
            color="dark"
            className="smallPadding"
            title="Sponsors"
          >
            <Sponsors/>
          </Article>
          <Article
            id="cometee"
            color="dark"
            className="smallPadding"
            title="Organizing committee"
          >
            <Cometee/>
          </Article>
        </div>
      <Footer/>
    </div>
  )
}
