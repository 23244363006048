import React, { Component } from 'react'
import screenshotbg from './images/screenshotbg.png';
import arrowleft from './images/arrowleft.png';
import arrowright from './images/arrowright.png';

import b1_webm from './videos/b1.webm';
import b1_mp4 from './videos/b1.mp4';
import b2_webm from './videos/b2.webm';
import b2_mp4 from './videos/b2.mp4';
import b3_webm from './videos/b3.webm';
import b3_mp4 from './videos/b3.mp4';

import './style.scss'

export default class ScreenshotSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: 0,
      isMobile: 1,
      touched: false, 
      beginTouch: 0, 
      endTouch: 0,
      moved: false
    }
  }

  componentDidMount() {
    window.addEventListener('resize', () => { this.isMobile() })
    this.isMobile()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => { this.isMobile() })
    clearInterval(this.interval)
  }

  isMobile() {
    const width = window.innerWidth
    if (width <= 768) {
      this.setState({ isMobile: 0.5 })
    } else {
      this.setState({ isMobile: 1 })
    }
  }

  beginTouch = e => {
    this.setState({ beginTouch: e.clientX || e.changedTouches[0].clientX, touched: true })
  }

  startTouch = e => {
    if (this.state.touched) {
      this.setState({ moved: true })
    }
  }

  endTouch = e => {
    const { beginTouch, active, touched, moved } = this.state
    if (!touched && !moved) return
    const endTouch = e.clientX || e.changedTouches[0].clientX
    if (Math.abs(beginTouch - endTouch) < 50) return 
    if (beginTouch > endTouch) {
      this.setState({ active: (this.state.active + 1) % 3 })
    }
    if (beginTouch < endTouch) {
      this.setState({ active: (this.state.active + 2) % 3 })
    }
    this.setState({ touched: false, moved: false })
  }

  render() {
    const { intl } = this.props;
    const { active, isMobile, touched } = this.state;
    const offset = isMobile * 100
    return (
      <div className={`slider fullcontainer${touched ? ' touched' : ''}`}
        onMouseDown={this.beginTouch}
        onMouseMove={this.startTouch}
        onMouseUp={this.endTouch}
        onMouseLeave={this.endTouch}
        onTouchStart={this.beginTouch}
        onTouchMove={this.startTouch}
        onTouchEnd={this.endTouch}
      >
        <h2>{intl.formatMessage({ id: 'LandingMobileApp', defaultMessage: 'Mobile app' })}</h2>
        <h3>{intl.formatMessage({ id: 'LandingMobileAppSubtitle', defaultMessage: 'How you see your opponent’s game' })}</h3>
        <div className='containerbox'>
          <div className='bubble' />
          <div className='bubble' />
          <div className='bubble' />
          <div className='bubble' />
          <img src={screenshotbg} />
          <div className='sliderbox'>
            <div className='images'>
              <div onClick={() => this.setState({ active: 0 })} className='elem' style={{ transform: `translateZ(${active === 0 ? 50 : -250}px) translateX(${active === 0 ? 0 : active === 1 ? -offset : offset}%)` }}>
                <video muted loop webkit-playsinline="true" playsinline="true" autoplay>
                  <source src={b1_webm} />
                  <source src={b1_mp4} />
                </video>
                <span style={{ color: active === 0 ? '#fff' : '#A9ADB3' }}>{intl.formatMessage({ id: 'LandingGeneralViewCamera', defaultMessage: 'General view Camera' })}</span>
              </div>
              <div onClick={() => this.setState({ active: 2 })} className='elem' style={{ transform: `translateZ(${active === 2 ? 50 : -250}px) translateX(${active === 2 ? 0 : active === 0 ? -offset : offset}%)` }}>
                <video muted loop webkit-playsinline="true" playsinline="true" autoplay>
                  <source src={b3_webm} />
                  <source src={b3_mp4} />
                </video>
                <span style={{ color: active === 2 ? '#fff' : '#A9ADB3' }}>{intl.formatMessage({ id: 'LandingPinsetterCamera', defaultMessage: 'Pinsetter Camera' })}</span>
              </div>
              <div onClick={() => this.setState({ active: 1 })} className='elem' style={{ transform: `translateZ(${active === 1 ? 50 : -250}px) translateX(${active === 1 ? 0 : active === 2 ? -offset : offset}%)` }}>
                <video muted loop webkit-playsinline="true" playsinline="true" autoplay>
                  <source src={b2_webm} />
                  <source src={b2_mp4} />
                </video>
                <span style={{ color: active === 1 ? '#fff' : '#A9ADB3' }}>{intl.formatMessage({ id: 'LandingScoringCamera', defaultMessage: 'Scoring camera (or integrated scoring)' })}</span>
              </div>
            </div>
            <div className='control'>
              <div className='left' onClick={() => { this.setState({ active: (this.state.active + 2) % 3 }) }}><img src={arrowleft} /></div>
              <div className="center">
                <span className='numcur'>{(active + 1).toString().padStart(2, '0')}</span><span className='numall'>/03</span>
              </div>
              <div className='right' onClick={() => this.setState({ active: (this.state.active + 1) % 3 })}><img src={arrowright} /></div>
            </div>
          </div>
          <div className="mobile-slider">
            <div className='control'>
              <div className='left' onClick={() => { this.setState({ active: (this.state.active + 2) % 3 }) }}><img src={arrowleft} /></div>
              <div className="center">
                <span className='numcur'>{(active + 1).toString().padStart(2, '0')}</span>
                <span className='numall'>/03</span>
              </div>
              <div className='right' onClick={() => this.setState({ active: (this.state.active + 1) % 3 })}><img src={arrowright} /></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}