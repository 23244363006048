const {
  SET_CHAT_ROOMS,
  SET_CUR_ROOM,
  FETCH_CHAT_ROOMS,
  FETCH_CHAT_ROOM,
  SET_CHAT_ROOM,
  SET_MESSAGES_SEEN,
  SET_MESSAGES_TOTAL,
  SET_MESSAGES_LAST,
  CHAT_ROOM_ERROR,
  SOCKET_EVENT_GAME_END,
  SYSTEM_SQUAD_CHAT,
} = require('./actiontypes').default;

const initialState = {
  currentRoom: null,
  rooms: [],
  isFetching: false,
  systemSquadChat: false, // Системные сообщения в чате
};

const updateRoom = (_rooms, room) => {
  let index;
  const rooms = [..._rooms];
  const { room_name: roomName } = room;
  const item = rooms.find((r, idx) => {
    const res = r.room_name === roomName;
    index = res ? idx : index;
    return res;
  });
  if (!item) {
    index = rooms.length;
    rooms.push(room);
  } else {
    rooms[index] = room;
  }
  return rooms;
};

const updateMessage = (_rooms, roomName, val, key, isSystem, systemSquadChat) => {
  let index;
  const rooms = [..._rooms];
  const item = rooms.find((r, idx) => {
    const res = r.room_name === roomName;
    index = res ? idx : index;
    return res;
  });

  if (item) {
    item.message[key] = val === undefined ? item.message[key] : val;
    if (key == 'total' && !systemSquadChat && isSystem) {
      item.message.total -= 1;
    }

    if (key == 'total' && isSystem) {
      if (item.message.system === undefined) item.message.system = 0;
      item.message.system += 1;
    }
    if (key == 'seen') {
      item.message.system = 0;
    }
  }

  return rooms;
};

const updateMessageSeen = (_rooms, roomName, count) => updateMessage(_rooms, roomName, count, 'seen');
const updateMessageTotal = (_rooms, roomName, count, isSystem, systemSquadChat) => updateMessage(_rooms, roomName, count, 'total', isSystem, systemSquadChat);
const updateMessageLast = (_rooms, roomName, message) => updateMessage(_rooms, roomName, message, 'last');

const chatRoomsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CHAT_ROOMS:
    case FETCH_CHAT_ROOM:
      return { ...state, error: '', isFetching: true };
    case SET_CHAT_ROOMS:
      // get all rooms (action.payload = rooms)
      if (action.error) {
        return { ...state, error: action.error, isFetching: false };
      }
      return { ...state, rooms: action.payload, isFetching: false };
    case SET_CHAT_ROOM:
      // set a room (action.payload = { room_name, message: { last: {}, seen: number, total: number }, users: [] })
      if (action.error) {
        return { ...state, error: action.error, isFetching: false };
      }
      return { ...state, rooms: updateRoom(state.rooms, action.payload), isFetching: false };
    case SET_MESSAGES_SEEN:
      // update messages seen (action.payload = roomName)
      if (action.error) {
        return { ...state, error: action.error };
      }
      return {
        ...state,
        rooms: updateMessageSeen(state.rooms, action.payload.roomName, action.payload.count),
        error: '',
      };
    case SET_MESSAGES_TOTAL:
      if (action.error) {
        return { ...state, error: action.error };
      }
      return {
        ...state,
        rooms: updateMessageTotal(state.rooms, action.payload.roomName, action.payload.count, action.payload.isSystem, state.systemSquadChat),
        error: '',
      };
    case SET_MESSAGES_LAST:
      if (action.error) {
        return { ...state, error: action.error };
      }
      return {
        ...state,
        rooms: updateMessageLast(state.rooms, action.payload.roomName, action.payload.message),
        error: '',
      };
    case SET_CUR_ROOM:
      return { ...state, currentRoom: action.payload };
    case CHAT_ROOM_ERROR:
      if (action.error) {
        return { ...state, error: action.error };
      }
      return state;
    case SOCKET_EVENT_GAME_END:
      return { ...state, rooms: state.rooms.filter(r => !r.room_name.includes('squad-chat-')) }

    case SYSTEM_SQUAD_CHAT:
      return { ...state, systemSquadChat: action.params }
    default:
      return state;
  }
};

export default chatRoomsReducer;


export const changeSystemSquadChat = params =>  ({ type: SYSTEM_SQUAD_CHAT, params });
