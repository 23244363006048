import React, {useEffect, useState} from 'react';
import gear from "../../../../images/GearWhite.png";
import Trash from "../../../../images/trash.png";
import moment from "moment";
import 'antd/lib/upload/style/index.css';
import '../style.scss'
import BowlingCentersTable from "../../../bowlingCentersView/bowlingCenterComponents/BowlingCentersTable";
import CheckBox from "../../../common/Checkbox";

const UploadListModeration = (props) => {
    const {
        banners = [],
        setBanners,
        selectedImage,
        setSelectedImage,
    } = props


    function onDelete(file) {
        setBanners(banners.map(banner => {
            if (banner.name === file.name){
                if (banner.status === 'done'){
                    banner.status = 'deleted'
                } else {
                    banner.status = 'done'
                }
            }
            return banner
        }))
    }




    return (
            <div className={'banners_list_container'}>
                {
                    banners.map((file) => {
                        let imageBorderStyle = 'upload-item'
                        if (file === selectedImage) imageBorderStyle += ' selected'
                        if (file.status === 'deleted') imageBorderStyle += ' deleted'
                        return(
                            <div>
                                <div className={imageBorderStyle} key={file.url || file.base64}>
                                    <img className="upload-item-image" src={file.url || file.base64}/>
                                    <div className="howerImage">
                                        <div
                                            className="howerImageButtom"
                                            onClick={() => {
                                                if (file === selectedImage) {
                                                    setSelectedImage(null)
                                                } else {
                                                    setSelectedImage(file)}
                                            }
                                            }
                                        >
                                            <img src={gear}/>
                                        </div>
                                        <div className="howerImageButtom" onClick={() => onDelete(file)}>
                                            <img src={Trash}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                        }
                    )
                }
            </div>
    );
};

export default UploadListModeration;
