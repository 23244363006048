import React, {useState} from 'react';
import BowlingCentersView from './bowlingCentersView'
import BowlingCenterBanners from "./BowlingCenterBanners";
import './bowlingCentersView.css';
import {connect} from "react-redux";

const BowlingCentersAdmin = (props) => {
  const {permissions} = props
  const [page, setPage] = useState('Stream');
  const [active, setActive] = useState({
    streaming: 'AdminActive',
    banners: 'AdminDisabled'
  });
  const bcAdminPermissions = permissions.keys.includes('MODIFY_BC_STREAM')

   function toggleActive(type) {
     switch (type){
       case 'Stream' :
         setActive({
           streaming: 'AdminActive',
           banners: 'AdminDisabled'
         })
         setPage(type)
         break;
       case 'Banner' :
         setActive({
           streaming: 'AdminDisabled',
           banners: 'AdminActive'
         })
         setPage(type)
         break;
     }
}

  return (
    <div className={'bc_admin_container'}>
      <div className={'bc_admin_buttons_container'}>
        <div
          id={active.streaming}
          className={'bc_admin_button'}
          onClick={() => toggleActive('Stream')}
        >
          ONLINE STREAMING
        </div>
        {bcAdminPermissions && <div
          id={active.banners}
          className={'bc_admin_button'}
          onClick={() => toggleActive('Banner')}
        >
          APP BANNERS
        </div>}
      </div>
      {page === 'Stream' && <BowlingCentersView/>}
      {page === 'Banner' && <BowlingCenterBanners/>}
    </div>
  );
};

export default connect((state) => ({
  permissions: state.users.permissions,
  bowlingCenters: state.bowlingcenters.bowlingcenters,
}))(BowlingCentersAdmin);
