import React, {useEffect, useState} from 'react';
import { Modal } from "antd";
import './style.scss'
import LeftWrapperInvestors from "./components/leftWrapper";
import RightWrapperInvestors from "./components/rightWrapper";

const InvestorsModal = (
    {
        isVisible = false,
        onCancel = () => {},
        onBack = () => {},
    }) => {
    const [isStateThanks, setIsStateThanks] = useState(false);

    function onClose() {
        onCancel();
        setIsStateThanks(false);
    }
    let wrapperContainerClass = 'wrapper-container'
    if (isStateThanks) wrapperContainerClass +=' wrapper-container-thanks'

    if (isVisible){
        document.querySelector('body').style.position = 'fixed'
    } else document.querySelector('body').style.position = ''
        document.querySelector('body').style.overflow = 'scroll'

    return (
        <Modal
            className="landing-modal-investors"
            visible={isVisible}
            width={1200}
            maskClosable
            onCancel={onClose}
            maskStyle={{
                backgroundColor: "rgba(0, 0, 0, .5)"
            }}
        >
            <div className={wrapperContainerClass}>
                {!isStateThanks ?
                    <>
                        <LeftWrapperInvestors/>
                        <RightWrapperInvestors setIsStateThanks={setIsStateThanks}/>
                    </>
                    :
                    <div className={'thanks-container'}>
                        <div className={'thanks-container__content'}>
                            <span className={'thanks-container__title'}>THANK YOU FOR YOUR REQUEST!</span>
                            <span className={'thanks-container__text'}>We will get in touch with you shortly.</span>
                            <button className={'thanks-container__btn'} onClick={() => setIsStateThanks(false)}>BACK</button>
                        </div>
                    </div>
                }

            </div>
        </Modal>
    );
};

export default InvestorsModal;
