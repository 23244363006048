import React, { Component } from 'react';
import PropTypes from 'prop-types';

import OBModal from '../../Modal';
import { Button } from '../../Button';

import './style.scss';

export default class ModalDialog extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    isAcceptButton: PropTypes.bool,
    declineButtonText: PropTypes.string,
    acceptButtonText: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: props.visible,
    };
  }

  static defaultProps = {
    onSubmit: () => { },
    onClose: () => { },
    isAcceptButton: true,
    declineButtonText: 'Decline',
    acceptButtonText: 'Accept',
    title: 'Dialog',
    children: (<div>DIALOG</div>),
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.visible !== prevState.visible) return { visible: nextProps.visible };
    return null;
  }

  showModal = () => this.setState({ visible: true });

  handleOk = () => {
    const { onSubmit } = this.props;
    onSubmit();
  };

  handleCancel = () => {
    const { onClose } = this.props;
    this.setState({
      visible: false,
    });
    onClose();
  }

  render() {
    const {
      title,
      children,
      declineButtonText,
      acceptButtonText,
      isAcceptButton,
    } = this.props;

    const { visible } = this.state;

    const Message = (
      <div
        key='message'
        className='dialog__message'>
        {children}
      </div>
    );


    const groups = [Message];

    return (
      <OBModal
        title={title}
        visible={visible}
        width={395}
        onOk={this.handleOk}
        closable={false}
        className='dialog'
        footer={[
          <div
            key='buttons'
            className='dialog__buttons'>
            <Button
              kind='aquamarine'
              bordered='true'
              onClick={this.handleCancel}
              className='dialog__button-decline'
            >
              {declineButtonText}
            </Button>
            {isAcceptButton &&
              < Button
                onClick={this.handleOk}
                kind='aquamarine'
                className='dialog__button-accept'
              >
                {acceptButtonText}
              </Button>
            }            
          </ div>,
      ]}
    >
        {groups}
      </OBModal>
    );
  }
}
