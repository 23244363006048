import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';
// import moment from 'moment';
import { get } from '../Networking';
import config from '../../config';
import { _UserSet } from './Users';

const { LANG_TRANSLATION_SET, LANG_SET_ALL, LANG_SET } = require('../reducers/actiontypes').default;

const getLangs = state => state.langs.locale.data;

export function* _GetTranslationsByLang(params) {
  const lang = params.params;
  const currentLangs = yield select(getLangs);
  const findedLang = {};
  Object.keys(currentLangs).forEach((key) => {
    if (key === lang) {
      findedLang[key] = currentLangs[key];
    }
  });
  const translate = {};
  translate[lang] = {};
  const translations = translate[lang];
  try {
    const result = yield call(get, {
      url: `${config.FETCH.url}/translations/translationsbylang?lang=${lang}`,
    });
    if (result.status === 200 && result.data.success) {
      result.data.data.forEach((elem) => {
        translations[elem.codestring] = elem.textvalue;
      });
      if (!_.isEqual(findedLang, translate)) {
        yield put({ type: LANG_TRANSLATION_SET, data: translate });
      } else {
        yield put({ type: LANG_TRANSLATION_SET, data: findedLang });
      }
    } else {
      yield put({ type: LANG_TRANSLATION_SET, error: result.data.error || result.status });
    }
  } catch (error) {
    console.log('GetTranslationsByLang error: ', error);
  }
}

export function* _GetAllTranslations() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/getalltranslations` });
    if (result.status === 200 && result.data.success) {
      yield put({ type: LANG_SET_ALL, data: result.data.data });
    } else {
      yield put({ type: LANG_SET_ALL, error: result.data.error || result.status });
    }
  } catch (error) {
    console.log('GetAllTranslations error: ', error);
  }
}

export function* _ChangeLang(params) {
  try {
    yield call(_GetTranslationsByLang, params);
    yield put({ type: LANG_SET, params: params.params });
    if (yield select(state => state.users.user.id)) {
      yield call(_UserSet, { params: { lang: params.params } });
    }
  } catch (error) {
    console.log('ChangeLang error: ', error);
  }
}
