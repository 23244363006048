import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter, Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';
import config from '../../../../config';
import { Button } from '../../Button';
import OBModal from '../../Modal';
import ModalDialog from '../modalDialog';
import { Table } from 'antd';
import { 
  UserByIdFetch, 
  getProtocol,
} from '../../../../libs/reducers/actions';
import SvgButton from '../../SvgButton';
import Checkbox from '../../Checkbox';
import Avatar from '../../Avatar';
import Utils from '../../../../libs/utils';
import SvgArrow from '-!svg-react-loader!./vector.svg'; // eslint-disable-line
import MatchScore from '../../../matchScore';

import './style.scss';

class ModalProtocol extends Component {

  constructor(props) {

    super(props);

    this.state = {
      isFailed: false,
      partyComment: ''
    }

  }

  showUser = function (userid) {
    this.props.UserByIdFetch({
      userid: userid,
    });
  }

  downloadFile = (filename) => {
    const url = `${config.FETCH.url}/protest/download/${filename}`;
    axios({
      url: url,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    });
  } 

  renderScoring = (scoring, frameid) => {
    return scoring.map(f => {
      const { id, shot1, shot2, shot3, flags, score, framenumber } = f;
      const result = Utils.getFrameResult(shot1, shot2, shot3, true);
      const isChanged = flags ? flags.indexOf('c') != -1 : false;
      const isFoul = flags ? flags.indexOf('f') != -1 : false;
      const isRefereed = flags ? flags.indexOf('r') != -1 : false;
      return (
        <div className={`frame${id == frameid ? ' selected' : ''}`}>
          <div className="framenumber">
            {framenumber}
          </div>
          <div className="result">
            { 
              flags ?
              <div className="flags">
                { isRefereed && <div className="refereed">{'R'}</div> }
                { isFoul && <div className="foul">{'F'}</div> }
                { isChanged && <div className="changed">{'C'}</div> }
              </div> : null
            }
            {
              result.map(shot => (
                <div className="shot">{shot}</div>
              ))
            }
          </div>
          <div className="score">
            {score}
          </div>
        </div>
      );
    })
  }

  render() {
    const { intl, protocol } = this.props;
    const { isFailed, partyComment } = this.state;
    const { resolved, failed, squad, protests, winner, loser, winner_score, loser_score } = protocol.data;
    const { squadid, startdate, tournamentid, tournamentprize, currencysymbol, games, players } = squad;
    let protestList = [];
    protests.forEach(p => {
      protestList.push(p);
      if (p.accepted) {
        const { gameid, frameid, player } = p;
        const game = games.find(g => g.gameid == gameid);
        const opponent = players.find(p => p.playerid != player.playerid);
        const original_scoring = game.original_scoring[opponent.playerid];
        const refereeing_scoring = game.refereeing_scoring[opponent.playerid];
        const scoring = {
          isScoring: true,
          player: {
            playerid: opponent.playerid,
            userid: opponent.userid,
            login: opponent.userlogin,
            firstname: opponent.userfirstname,
            lastname: opponent.userlastname
          },
          original_scoring,
          refereeing_scoring,
          frameid
        };
        protestList.push(scoring);
      } else {
        protestList.push({});
      }
    });
    protestList.push({});
    protestList.push({});
    const translate = {
      title: intl.formatMessage(
        { 
          id: 'ProtocolModalTitle', 
          defaultMessage: 'Refereeing protocol of party #{id} {date, date, short}'
        }, 
        { 
          id: squadid, 
          date: new Date(startdate),
        }
      ),
      prize: intl.formatMessage({ id: 'Prize', defaultMessage: 'Prize' }),
      close: intl.formatMessage({ id: 'CloseAsCancelAlias', defaultMessage: 'Close' }),
      resolve: intl.formatMessage({ id: 'MakeResolve', defaultMessage: 'Made decision' }),
      gameNumberTime: intl.formatMessage({ id: 'Number&Time', defaultMessage: '#, time' }),
      player: intl.formatMessage({ id: 'PlayerNoun', defaultMessage: 'Player' }),
      comment: intl.formatMessage({ id: 'ProtestComment', defaultMessage: 'Protest`s comment' }),
      discussion: intl.formatMessage({ id: 'RefereeComment', defaultMessage: 'Referee`s comment' }),
      file: intl.formatMessage({ id: 'File', defaultMessage: 'File' }),
      status: intl.formatMessage({ id: 'StatusAsStateAlias', defaultMessage: 'Status' }),
      accepted: intl.formatMessage({ id: 'ProtestAccepted', defaultMessage: 'Protest accepted by' }),
      rejected: intl.formatMessage({ id: 'ProtestRejected', defaultMessage: 'Protest rejected by' }),
    };
    const columns = [
      {
        title: translate.gameNumberTime,
        defaultSortOrder: 'ascend',
        sorter: (a, b) => {
          return a.gamenumber - b.gamenumber
        },       
        render: (record) => {
          if (_.isEmpty(record)) return {
            children: <span></span>,
            props: {
              className: 'empty'
            }
          };
          const { isScoring, gamenumber,  protestdate } = record;
          if (!isScoring) {
            return (
              <div>
                <div className="gamenumber">{`Game ${gamenumber}`}</div>
                <div className="protestdate">{moment(protestdate).format('hh:mm:ss')}</div>
              </div>
            )
          }
        }
      },
      {
        title: translate.player,       
        render: (record) => {
          if (_.isEmpty(record)) return {
            children: <span></span>,
            props: {
              className: 'empty'
            }
          };
          const { isScoring, player } = record;
          const { userid, login, firstname, lastname } = player;
          const { usercountry, userphoto } = winner.userid == userid ? winner : loser;
          return (
            <div className="player">
              <Avatar 
                uri={userphoto} 
                flag={usercountry}
              />
              <div>
                <div>{`${firstname} ${lastname}`}</div>
                <div>
                  <Link to="#" onClick={(e) => {
                      e.preventDefault();
                      this.showUser(userid)
                    }}
                  >
                    {login}
                  </Link>
                </div>
              </div>
            </div>
          )
        }
      },
      {
        title: translate.comment, 
        className: 'no-right-padding',
        width: 1,
        render: (record) => {
          if (_.isEmpty(record)) return {
            children: <span></span>,
            props: {
              className: 'empty'
            }
          };
          const { isScoring, complaint, category, original_scoring, frameid } = record;
          if (!isScoring) {
            const { name: reason } = category;
            return (
              <div className="protest_comment">
                <div className="reason">
                  {intl.formatMessage({ id: reason, defaultMessage: reason })}
                </div>
                <div className="complaint">{complaint}</div>
              </div>
            )
          } else {
            return (
              <div className="scoring original">
                { this.renderScoring(original_scoring, frameid) }
                <div class="vector">
                  <SvgArrow/>
                </div>
              </div>
            )
          }
        }
      },
      {
        title: translate.discussion,  
        width: 1,    
        render: (record) => {
          if (_.isEmpty(record)) return {
            children: <span></span>,
            props: {
              className: 'empty'
            }
          };
          const { isScoring, discussion, referee, refereeing_scoring, frameid } = record;
          if (!isScoring) {
            const { userid, firstname, lastname } = referee;
            return (
              <div className="protest_comment">
                <div className="referee">
                  <Link to="#" onClick={(e) => {
                      e.preventDefault();
                      this.showUser(userid)
                    }}
                  >
                    {`${firstname} ${lastname}`}
                  </Link>
                </div>
                <div className="discussion">{discussion}</div>
              </div>
            )
          } else {
            return (
              <div className="scoring">
                { this.renderScoring(refereeing_scoring, frameid) }
              </div>
            )
          }
        }
      },
      {
        title: translate.file,
        render: (record) => {
          if (_.isEmpty(record)) return {
            children: <span></span>,
            props: {
              className: 'empty'
            }
          };
          const { isScoring, filename } = record;
          if (!isScoring && filename) {
            return (
              <SvgButton icon="file" onClick={() => {
                this.downloadFile(filename);
              }}/>
            )
          }
        }
      },
      {
        title: translate.status,
        render: (record) => {
          if (_.isEmpty(record)) return {
            children: <span></span>,
            props: {
              className: 'empty'
            }
          };
          const { isScoring, accepted, referee } = record;
          if (!isScoring) {
            const { userid, firstname, lastname } = referee;
            return (
              <div className="protest_status">
                <div>
                  {accepted ? translate.accepted : translate.rejected}
                </div>
                <div>              
                  <Link to="#" onClick={(e) => {
                      e.preventDefault();
                      this.showUser(userid)
                    }}
                  >
                    {`${firstname} ${lastname}`}
                  </Link>
                </div>
              </div>
            )
          }
        }
      },

    ];
    return(
      <div>
        <OBModal
          title={translate.title}
          visible
          width={1350}
          height={700}
          zIndex={2000}
          onOk={() => {}}
          onCancel={() => {}}
          className="protocol_modal"
          closable={false}
          footer={[
            <div className="protocol_modal_footer">
              <div className="protocol_modal_footer_group">
                <div className="match-score">
                  <div className="game-result">
                    <div className="result">Результат игры</div>
                    { 
                      tournamentprize > 0 
                        ? <div className="prize">{`${translate.prize} - ${tournamentprize}${currencysymbol}`}</div>
                        : null
                    }
                  </div>
                  <MatchScore
                    players={[
                      {
                        login: winner.userlogin,
                        firstname: winner.userfirstname,
                        lastname: winner.userlastname,
                        country_live: winner.usercountry,
                        ratingsavg: +winner.userrating,
                        photo: winner.userphoto,
                        id: winner.userid,
                        userid: winner.userid,
                        gameswin: winner_score,
                        delays: 0,
                      },
                      {
                        login: loser.userlogin,
                        firstname: loser.userfirstname,
                        lastname: loser.userlastname,
                        country_live: loser.usercountry,
                        ratingsavg: +loser.userrating,
                        photo: loser.userphoto,
                        id: loser.userid,
                        userid: loser.userid,
                        gameswin: loser_score,
                        delays: 0,
                      },
                    ]}
                  />
                </div>
                <div className="party-comment">
                  <label for="party-comment">Комментарий к партии</label>
                  <textarea
                    id="party-comment"
                    className="textarea"
                    value={partyComment}
                    onChange={(e) => {
                      this.setState({ partyComment: e.target.value })
                    }}
                  />
                </div>
              </div>
              <div className="protocol_modal_footer_group">
                <div className="protocol_modal_footer_checkbox">
                  <Checkbox checked={isFailed} onChange={(e) => {
                    this.setState({ isFailed: e.target.checked })
                  }}>
                    Считать партию не состоявшейся по техническим или другим причинам
                  </Checkbox>
                </div>
                <div className="protocol_modal_footer_buttons">
                  <div style={{ display: 'flex' }}>
                    <Button style={{ width: '172px' }}
                      kind="aquamarine"
                      bordered="true"
                      onClick={() => { this.props.onCancel() }}
                      disabled={false}
                    >
                      {translate.close}
                    </Button>
                    { !resolved && 
                      <Button style={{ width: '172px' }}
                        kind="aquamarine"
                        onClick={() => { 
                          this.props.onDone()
                        }}
                      >
                        {translate.resolve}
                      </Button>
                    }
                  </div>
                </div>
              </div>
            </div>
          ]}
        >
        <Table
          key={this.props.langs}
          className="protocol_modal_table"
          rowClassName="protocol_modal_table_row"
          columns={columns}
          dataSource={protestList}
          pagination={false}
          rowKey={record => record.id}
        />
        </OBModal>
      </div>
    );
  }
}

export default injectIntl(withRouter(
  connect(
    state => ({
      langs: state.langs,
      protocol: state.protocol
    }),
    {
      UserByIdFetch,
      getProtocol,
    },
  )(ModalProtocol),
));
