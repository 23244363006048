export const FETCH_USER_DOCS = 'FETCH_USER_DOCS';
export const SET_USER_DOCS = 'SET_USER_DOCS';
export const SEND_USER_DOCS = 'SEND_USER_DOCS';
export const CLEAR_USER_DOCS = 'CLEAR_USER_DOCS'; 

const initialState = {
  data: [],
  error: '',
  isFetching: false,
  success: true,
};

const userDocsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_DOCS:
      return { ...state, error: '', isFetching: true };
    case SET_USER_DOCS:
      if (action.payload.error) {
        return { ...state, error: action.payload.error, isFetching: false, success: false };
      }
      return { ...state, data: action.payload.data || {}, isFetching: false, success: action.payload.success };

    case SEND_USER_DOCS:
      return { ...state, isFetching: true, error: '' }

    case CLEAR_USER_DOCS:
      return { ...state, ...initialState }
    default:
      return state;
  }
};

export default userDocsReducer;