import { call, put, takeLatest } from 'redux-saga/effects';
import bugsnagClient from '../../bugsnagClient';
import config from '../../config';
import { get, post } from '../Networking';

import {
  BOWLING_CENTERS_FETCH,
  BOWLING_CENTERS_SET,
  BOWLING_CENTERS_COUNTRY_SET,
  BOWLING_CENTERS_CITIES_SET,
  BOWLING_CENTERS_COUNTRY_FETCH,
  BOWLING_CENTERS_CITIES_FETCH,
  BOWLING_CENTER_BY_LOCATION_FETCH,
  BOWLING_CENTER_BY_LOCATION_SET,
} from '../reducers/bowlingCenters.reducer';

const {
  BOWLING_CENTER_AFTER_SEND_REQUEST
} = require('../reducers/actiontypes').default;

export function* _GetBowlingCenterByLocation() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/bowlingcenter/my` });
    if (result.status === 200 && result.data.success) {
      yield put({ type: BOWLING_CENTER_BY_LOCATION_SET, data: result.data.data });
    } else {
      yield put({ type: BOWLING_CENTER_BY_LOCATION_SET, error: result.data.error });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'GetBowlingCenterByLocation' });
  }
}

export function* _GetBowlingCenters() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/bowlingcenter` });
    if (result.status === 200 && result.data.success) {
      yield put({ type: BOWLING_CENTERS_SET, data: result.data.data });
    } else {
      yield put({ type: BOWLING_CENTERS_SET, error: result.data.error });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'GetBowlingCenters' });
  }
}

export function* _GetBowlingCentersCountry() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/geodata/bowlingcenters/country` });
    if (result.status === 200 && result.data.success) {
      yield put({ type: BOWLING_CENTERS_COUNTRY_SET, data: result.data.data });
    } else {
      yield put({ type: BOWLING_CENTERS_COUNTRY_SET, error: result.data.error || result.status });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'GetBowlingCentersCountry' });
  }
}

export function* _GetBowlingCentersCities(params) {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/geodata/bowlingcenters/${params.params}` });
    if (result.status === 200 && result.data.success) {
      yield put({ type: BOWLING_CENTERS_CITIES_SET, data: result.data.data });
    } else {
      yield put({ type: BOWLING_CENTERS_CITIES_SET, error: result.data.error || result.status });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'GetBowlingCentersCities' });
  }
}

export function* _WatchBowlingCenters() {
  try {
    yield takeLatest(BOWLING_CENTERS_FETCH, _GetBowlingCenters);
    yield takeLatest(BOWLING_CENTERS_COUNTRY_FETCH, _GetBowlingCentersCountry);
    yield takeLatest(BOWLING_CENTERS_CITIES_FETCH, _GetBowlingCentersCities);
    yield takeLatest(BOWLING_CENTER_BY_LOCATION_FETCH, _GetBowlingCenterByLocation);
  } catch (error) {
    bugsnagClient.notify(error, { context: 'WatchBowlingCenters' });
    console.error('===WatchBowlingCenters Error: ', error);
  }
}

export function* _sendBowlingCenterRequest (params) {
  try {
    const url = `${config.FETCH.url}/bowlingcenter/request`;
    const result = yield call(post, { url, data: params.payload });
    if (result.status === 200 && result.data.success) {
      yield put({ type: BOWLING_CENTER_AFTER_SEND_REQUEST });
    } else {
      yield put({ type: BOWLING_CENTER_AFTER_SEND_REQUEST, error: result.data.error || result.status });
    }
  } catch (err) {
    yield put({ type: BOWLING_CENTER_AFTER_SEND_REQUEST, error: err });
  }
}