import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { Button } from '../../../Button';
import OBModal from '../../../Modal';
import Checkbox from '../../../Checkbox';
import Utils from '../../../../../libs/utils';
import Opponent from '../../../Opponent';

import '../style.scss';

class ModalJoinBattleTutorial extends Component {

  constructor(props) {

    super(props);

    this.state = {
      suppress: false
    }
  }

  render() {
    const { intl, lang, visible, params = {} } = this.props;
    const { datestart, cost, currency, wincount, opponent = {} } = params || {};
    const { id, firstname, lastname, login, photo, rating, country } = opponent || {};
    const { suppress } = this.state;
    const translate = {
      title: intl.formatMessage({ id: 'JoinBattleTutotialModalTitle', defaultMessage: 'You responded' }),
      ok: intl.formatMessage({ id: 'ButtonOK', defaultMessage: 'OK' }),
      parameters: intl.formatMessage({ id: 'BattleParameters', defaultMessage: 'Parameters of Battle' }),
      battledate: intl.formatMessage({ id: 'date_of_battle', defaultMessage: 'Date of battle' }),
      fee: intl.formatMessage({ id: 'fee', defaultMessage: 'Fee' }),
      wins: intl.formatMessage({ id: 'table_wins_short', defaultMessage: 'Wins' }),
      description: intl.formatMessage({
        id: 'JoinBattleTutotialModalDescription',
        defaultMessage: `You have responded to Battle. 
        After confirmation player of your participation, 
        you will receive notification to start the game.`
      }),
      ignore: intl.formatMessage({ id: 'SuppressFurther', defaultMessage: 'Suppress further' }),
      now: intl.formatMessage({ id: 'now', defaultMessage: 'Now' }),
      free: intl.formatMessage({ id: 'without_bet', defaultMessage: 'Free' }),
    }
    return (
      <OBModal
        title={translate.title}
        visible={visible}
        width={395}
        onOk={() => { }}
        onCancel={() => { }}
        className="join_battle_tutotial_modal"
        closable={false}
        footer={[
          <Button style={{ width: '100%' }}
            kind="aquamarine"
            onClick={() => this.props.onClose(suppress)}
          >
            {translate.ok}
          </Button>
        ]}
      >
        <div className="join_battle_tutotial_modal__opponent">
          <Opponent
            userid={id}
            fullname={`${lastname} ${firstname}`}
            nickname={login}
            photo={photo}
            rating={+rating}
            flag={country}
            clickableLogin={false}
          />
        </div>
        <div className="join_battle_tutotial_modal__parameters">
          <div className="join_battle_tutotial_modal__subtitle">
            {translate.parameters}{':'}
          </div>
          <div className="join_battle_tutotial_modal__table">
            <div className="join_battle_tutotial_modal__parameter">
              <div className="join_battle_tutotial_modal__parameter__key">
                {translate.battledate}
              </div>
              <div className="join_battle_tutotial_modal__parameter__value">
                {datestart ? Utils.formatDateTime(+new Date(datestart), lang, 'DD MMM - HH:mm'): translate.now}
              </div>
            </div>
            {/*<div className="join_battle_tutotial_modal__parameter">
              <div className="join_battle_tutotial_modal__parameter__key">
                {translate.fee}
              </div>
              <div className="join_battle_tutotial_modal__parameter__value">
                {cost ? `${cost}${currency}` : translate.free}
              </div>
            </div>*/}
            <div className="join_battle_tutotial_modal__parameter">
              <div className="join_battle_tutotial_modal__parameter__key">
                {translate.wins}
              </div>
              <div className="join_battle_tutotial_modal__parameter__value">
                {wincount}
              </div>
            </div>
          </div>
        </div>
        <div className="join_battle_tutotial_modal__description">
          {translate.description}
        </div>
        <div className="join_battle_tutotial_modal__checkbox">
          <Checkbox checked={suppress}
            onChange={(checked) => {
              this.setState({ suppress: checked });
            }}
          >
            {translate.ignore}
          </Checkbox>
        </div>
      </OBModal>
    );
  }
}

export default injectIntl(
  connect(
    state => ({
      lang: state.langs.lang
    }),
    {},
  )(ModalJoinBattleTutorial),
);
