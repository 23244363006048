import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import OBModal from '../../../common/Modal';
import { Button } from '../../../common/Button';
import {
  SetUserPhone,
  SendVerificationCodePhone,
  CheckVerificationCodePhone,
} from '../../../../libs/reducers/actions';

import './style.scss';

class ModalPhoneVerify extends React.Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    intl: intlShape,
  };

  state = {
    verificationPhase: true,
    phoneinput: null,
    codeinput: null,
    highlight: false,
  };

  componentDidUpdate(oldprops) {
    if ((oldprops.visible !== this.props.visible)
         && (this.props.visible)) {
      this.setState({ verificationPhase: true });
    } else if ((oldprops.users.phonesuccess !== this.props.users.phonesuccess)
                && this.props.users.phonesuccess === 1) {
      this.setState({ verificationPhase: false });
      this.askForKey();
    } else if (oldprops.users.phonecodesuccess !== this.props.users.phonecodesuccess) {
      if (this.props.users.phonecodesuccess === 1) {
        this.handleSubmit();
      } else if (this.props.users.phonecodesuccess === 0) {
        this.setState({ highlight: true });
      }
    }
  }

  handleChangePhone = (event) => {
    this.setState({ phoneinput: event.target.value });
  }

  handleChangeCode = (event) => {
    this.setState({ codeinput: event.target.value });
  }

  askForKey = () => {
    this.props.SendVerificationCodePhone();
  }

  phaseRender = () => {
    const { intl } = this.props;
    if (this.state.verificationPhase) {
      return (
        [<div
          key='phoneinput'
          className='verification-modal-block phoneinput'
          >
          <p>{intl.formatMessage({ id: 'VerifyPhoneModalActionProvoke', defaultMessage: 'Enter your phone number' })}</p>
          <input className={this.state.highlight ? 'highlight' : ''} type="text" value={this.state.phoneinput} onChange={this.handleChangePhone}/>
          <div className='input_error'>{intl.formatMessage({ id: 'VerifyPhoneModalActionProvokeError', defaultMessage: 'This field must not be empty' })}</div>
          <p>{intl.formatMessage({ id: 'VerifyPhoneModalActionProvokeTooltip', defaultMessage: 'You will receive SMS with your verification code on phone entered above' })}</p>
        </div>]
      );
    }
    return (
      [<div
        key='phoneeshowcase'
        className='verification-modal-block phoneshowcase'
        >
        <p>{intl.formatMessage({ id: 'PhoneNumber', defaultMessage: 'Phone number' })}</p>
        <div className="phone-container">
          <span>{this.state.phoneinput}</span>
          <a onClick={() => this.setState({ verificationPhase: true })}>{intl.formatMessage({ id: 'EditAsChangeAlias', defaultMessage: 'Edit' })}</a>
        </div>
      </div>,
      <div
        key='codeinput'
        className='verification-modal-block codeinput'
        >
        <p>{intl.formatMessage({ id: 'VerifyPhoneCodeInputProvoke', defaultMessage: 'Enter code received in SMS' })}</p>
        <input className={this.state.highlight ? 'highlight' : ''} type="text" value={this.state.codeinput} onChange={this.handleChangeCode}/>
        <div className='input_error'>{intl.formatMessage({ id: 'VerifyPhoneCodeInputError', defaultMessage: 'Invalid code' })}</div>
        <span>{`${intl.formatMessage({ id: 'VerifyPhoneRetryText', defaultMessage: 'Didn\'t received message?' })} `}</span>
        <a onClick={this.askForKey}>{intl.formatMessage({ id: 'VerifyPhoneRetry', defaultMessage: 'Retry' })}</a>
      </div>]
    );
  }

  handleOk = () => {
    if (this.state.verificationPhase) {
      if (this.state.phoneinput) {
        this.setState({ highlight: false });
        this.props.SetUserPhone(this.state.phoneinput);
      } else {
        this.setState({ highlight: true });
      }
    } else {
      this.props.CheckVerificationCodePhone(this.state.codeinput);
    }
  }

  handleSubmit = () => {
    this.props.onSubmit();
  }

  handleCancel = (e = null) => {
    this.setState({ highlight: false });
    this.setState({ visible: false });
    this.props.onClose(e);
  }

  render() {
    const { intl } = this.props;

    return (
      <OBModal
        title={intl.formatMessage({ id: 'VerifyPhoneModalTitle', defaultMessage: 'Phone number verify' })}
        visible={this.props.visible}
        width={395}
        onOk={this.handleOk}
        onCancel={e => this.handleCancel(e)}
        className='phone_verification_dialog'
        footer={[
          <div
            key='buttons'
            className='dialog__buttons'>
            <Button
              kind='aquamarine'
              bordered='true'
              onClick={this.handleCancel}
              className='dialog__button-decline'
              >
              {intl.formatMessage({ id: 'CancelAsAbortAlias', defaultMessage: 'Cancel' })}
            </Button>
            <Button
              onClick={this.handleOk}
              kind='aquamarine'
              className='dialog__button-accept'
              >
              {intl.formatMessage({ id: 'Accept', defaultMessage: 'Accept' })}
            </Button>
          </div>,
        ]}
        >
        {this.phaseRender()}
      </OBModal>
    );
  }
}

export default injectIntl(connect(
  state => ({
    users: state.users,
  }),
  {
    SetUserPhone,
    SendVerificationCodePhone,
    CheckVerificationCodePhone,
  },
)(ModalPhoneVerify));
