import React, { useEffect, useState } from 'react'
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import {Tab, TabList, TabPanel, Tabs,} from 'react-tabs';
import {requestTournamentInfo} from '../../../libs/reducers/actions';
import TournamentInfo from '../tournamentInfo';
import {tournamentsActiveTabIndexSet} from '../../../libs/reducers/appSettings.reducer';
import CompetitionList from '../../tournamentRoot/competitionList';
import CompetitorList from '../../tournamentRoot/competitorList';
import CompetitorFilter from '../../tournamentRoot/competitorFilter';
import Leaderboard from '../../tournamentRoot/leaderboard';
import Settings from '../../tournamentRoot/tournamentSettings';
import Standings from '../../tournamentRoot/standings';
import StageStandings from '../../tournamentRoot/tournamentStageStandings/stageStandings';
import HeadBackArrow from '-!svg-react-loader!../../../images/headBackArrow.svg'; // eslint-disable-line


const TournamentsTabs = ({
  intl,
  tournamentsActiveTabIndex,
  requestTournamentInfo,
  tournamentInfo,
  permissions,
  users,
  timezoneList,
  countryList,
}) => {

  const STAGES_TOURNAMENT_TABLE = 'STAGES_TOURNAMENT_TABLE';
  const INDIVIDUAL_TOURNAMENT_TABLE = 'INDIVIDUAL_TOURNAMENT_TABLE';

  const standings = ((tournamentInfo.settings || []).find(s => s.tournament_setting_brief === 'DISPLAY_TOURNAMENT_TABLE') || {});
  const selectPlayers = (isadmin, tournamentInfo) => {
    if (tournamentInfo && tournamentInfo.players) {
      return isadmin ? tournamentInfo.players : tournamentInfo.players.filter(p => p.isparticipant);
    }
    return undefined;
  }
  
  const onFilter = (filtered) => {
    setCompetitorFilters(filtered);
  }

  const isAdmin = permissions.includes('TOURNAMENTS_MANAGE_PLAYERS')

  const [competitors, setCompetitors] = useState(selectPlayers(isAdmin, tournamentInfo))
  const [filteredCompetitors, setFilteredCompetitors] = useState(competitors)
  const [competitorFilters, setCompetitorFilters] = useState([])

  useEffect(() => {setCompetitors(selectPlayers(isAdmin, tournamentInfo))}, [tournamentInfo])

  useEffect(() => {setFilteredCompetitors(competitors)}, [competitors])
  return (
    <div
    className="tableintabs tournamentTable"
    style={{
      height: '100%',
      background: 'linear-gradient(to top, #0c131c, #0d141d)',
      flexWrap: 'nowrap',
      flexBasis: '75%',
      borderBottom: '1px solid rgba(100, 100, 100, 0.3)',
      overflow: 'auto',
    }}
  >
    <Tabs
      style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      defaultIndex={tournamentsActiveTabIndex}
      onSelect={index => tournamentsActiveTabIndexSet(index)}
    > 
      <div className='headBackButton' onClick={() => {requestTournamentInfo(); onFilter([])}}><HeadBackArrow/></div>
      <TabList>
        <Tab>{intl.formatMessage({ id: 'TournamentInformation', defaultMessage: 'Information' })}</Tab>
        <Tab>{intl.formatMessage({ id: 'GamesInfoScreenTitle', defaultMessage: 'Games' })}</Tab>
        <Tab>{intl.formatMessage({ id: 'TournamentParticipantsList', defaultMessage: 'Tournament players' })}</Tab>
        {['DROP_IN', 'OPEN_CHALLENGE'].includes(tournamentInfo.tournamenttypebrief)
          ? <Tab>{intl.formatMessage({ id: 'TournamentLeaderboard', defaultMessage: 'Leaderboard' })}</Tab>
          : null
        }
        {(tournamentInfo.tournamenttypebrief === 'BATTLES'
          && tournamentInfo.settings
          && Array.isArray(tournamentInfo.settings)
          && !!(tournamentInfo.settings.find(s => s.tournament_setting_brief === 'DISPLAY_TOURNAMENT_TABLE') || {}).value_bool)
          ? <Tab>{intl.formatMessage({ id: 'Standings', defaultMessage: 'Standings' })}</Tab>
          : <></>
        }
        {permissions.includes('TOURNAMENTS_MODIFY_TOURNAMENTS')
          ? <Tab>{intl.formatMessage({ id: 'settings', defaultMessage: 'Settings' })}</Tab>
          : null
        }
      </TabList>
      <TabPanel>
        <TournamentInfo
          intl={intl}
          user={users.user}
          timezoneList={timezoneList}
          countryList={countryList}
        />
      </TabPanel>
      <TabPanel>
        <CompetitionList
          intl={intl}
          competitions={tournamentInfo.battles}
          tournamenttypeid={tournamentInfo.tournamenttypeid}
          tournamentclosed={tournamentInfo.tournamentclosed}
        />
      </TabPanel>
      <TabPanel>
        <CompetitorFilter
          intl={intl}
          isadmin={permissions.includes('TOURNAMENTS_MANAGE_PLAYERS')}
          countryList={countryList}
          timezoneList={timezoneList}
          players={competitors}
          onChange={onFilter}
        />
        <CompetitorList
          tournamenttypeid={tournamentInfo.tournamenttypeid}
          intl={intl}
          competitors={filteredCompetitors}
          competitorFilters={competitorFilters}
        />
      </TabPanel>
      {[3, 5].includes(tournamentInfo.tournamenttypeid) &&
        <TabPanel>
          <Leaderboard
            intl={intl}
            user={users.user}
            tournament={tournamentInfo}
          />
        </TabPanel>
      }
      {(tournamentInfo.tournamenttypebrief === 'BATTLES'
          && tournamentInfo.settings
          && Array.isArray(tournamentInfo.settings)
          && !!(standings.value_bool))
        ? (
          <TabPanel>
            {
              standings.value_text === INDIVIDUAL_TOURNAMENT_TABLE && (
                <Standings
                  intl={intl}
                />
              )
            }
            {
              standings.value_text === STAGES_TOURNAMENT_TABLE && (
                <StageStandings
                  intl={intl}
                />
              )
            }
          </TabPanel>
        )
        : <></>
      }
      {permissions.includes('TOURNAMENTS_MODIFY_TOURNAMENTS') && (
        <TabPanel>
          <Settings/>
        </TabPanel>
      )}
    </Tabs>
  </div>
  )
}

export default injectIntl(
  withRouter(
    connect(
      state => ({
        users: state.users,
        tournamentsActiveTabIndex: state.appSettings.tournamentsActiveTabIndex,
        tournamentInfo: state.tournaments.tournamentInfo,
        countryList: state.common.geodata.data,
        timezoneList: state.common.timezones.data,
        permissions: state.users.permissions ? state.users.permissions.keys : [],
      }),
      {
        requestTournamentInfo,
      },
    )(TournamentsTabs),
  ),
);
