import Facebook from '-!svg-react-loader!./facebook.svg'; // eslint-disable-line
import Instagram from '-!svg-react-loader!./instagram.svg'; // eslint-disable-line
import Twitter from '-!svg-react-loader!./twitter.svg'; // eslint-disable-line
import Google from '-!svg-react-loader!./google.svg'; // eslint-disable-line
import Vk from '-!svg-react-loader!./vk.svg'; // eslint-disable-line
import Trash from '-!svg-react-loader!./trash.svg'; // eslint-disable-line
import Play from '-!svg-react-loader!./play.svg'; // eslint-disable-line
import Pause from '-!svg-react-loader!./pause.svg'; // eslint-disable-line
import Muted from '-!svg-react-loader!./muted.svg'; // eslint-disable-line
import Unmuted from '-!svg-react-loader!./unmuted.svg'; // eslint-disable-line
import File from '-!svg-react-loader!./file.svg'; // eslint-disable-line
import Edit from '-!svg-react-loader!./edit.svg'; // eslint-disable-line
import Send from '-!svg-react-loader!./send.svg'; // eslint-disable-line
import Apple from '-!svg-react-loader!./apple.svg'; // eslint-disable-line
import YouTube from '-!svg-react-loader!./youtube.svg'; // eslint-disable-line


export default {
  facebook: Facebook,
  instagram: Instagram,
  twitter: Twitter,
  google: Google,
  vk: Vk,
  trash: Trash,
  play: Play,
  pause: Pause,
  muted: Muted,
  unmuted: Unmuted,
  file: File,
  edit: Edit,
  send: Send,
  apple: Apple,
  youTube: YouTube,
};
