import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import Utils from '../../libs/utils';

class ChatMessage extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { data } = this.props;
    const {
      username, 
      login, 
      system, 
      timestamp,
      owned,
    } = data;
    const { intl } = this.props;
    const { messageText, messageComment } = Utils.parseChatMessage(intl, data);
    const chatComment = intl.formatMessage({ 
      id: 'comment', 
      defaultMessage: 'Comment' 
    });
    return (
      <li className={`message${owned ? ' owned' : ''}${system ? ' system' : ''}`}>
        <span className="time" onClick={() => { this.props.onTimeClick(timestamp) }} title={moment(timestamp).format('YYYY-MM-DD HH:mm:ss')}>{moment(timestamp).format('HH:mm')}</span>
        <span className="author">{`${username || login}: `}</span>
        <span className="text">{messageText}</span>
        {
          messageComment && 
          <span>
            <span className="comment-label">{' '}{chatComment}{': '}</span> 
            <span className="comment">{messageComment}</span>
          </span>
        }
      </li>
    );
  }

}

export default injectIntl(
  connect(state => ({}), {})(ChatMessage)
);