import { select, call, put } from 'redux-saga/effects';
import types from '../reducers/actiontypes';
import bugsnagClient from '../../bugsnagClient';
import config from '../../config';

const { modals: modalsConfig = { timeout: 2000 } } = config;

export const selectModals = state => state.modals;

export const delay = (timeout) => {
  let timerId;
  const promise = new Promise((resolve) => {
    timerId = setTimeout(resolve, timeout);
  });
  if (!promise.cancel) {
    promise.cancel = () => clearTimeout(timerId);
  }
  return promise;
};

export function* _onAddModal() {
  // этот метод вызывается через takeLatest (это важно), событие ADD_MODAL
  try {
    yield call(delay, modalsConfig.timeout); // ждем 2 сек, чтобы открытая модалка успела проставить флаг checking false
    const modals = yield select(selectModals);

    if (modals.checking && modals.name) { // если открытая модалка не отвечает (т.е. не проставила флаг checking false)
      yield put({ type: types.CLOSE_MODAL }); // то убираем ее из redux'а
    }
  } catch (error) {
    bugsnagClient.notify(error);
  }
}

export function* _onCloseModal() {
  // событие CLOSE_MODAL
  try {
    yield call(delay, modalsConfig.timeout); // ждем 2 сек, чтобы следующая модалка успела проставить флаг nextChecking false
    const modals = yield select(selectModals);
    if (!modals.name & modals.nextChecking && modals.stack[0]) { // если нет открытой модалки, а следующая модалка не отвечает
      yield put({ type: types.REMOVE_MODAL, payload: modals.stack[0] }); // то убираем ее из стека
    }
  } catch (error) {
    bugsnagClient.notify(error);
  }
}
