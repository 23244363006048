import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import moment from 'moment-timezone';

import Modal from '../../common/Modal';
import { requestGameInfo, UserByIdFetch } from '../../../libs/reducers/actions';
import { InfoText } from './common';
import Players from './playersInfo';
import SquadScore from './squadScore';
import GameScore from './gameScore';
import './style.scss';
import { isSolo, isPinfall as isPinfallFun } from '../../../libs/SquadTypeCheck';
import { useGameCostText } from '../../GameCost/useGameCostText';

class gameResultsModal extends React.Component {
  componentDidUpdate(prevProps) {
    const { tournament, requestGameInfo, visible } = this.props;
    if (prevProps.tournament !== tournament) {
      requestGameInfo({ squadid: tournament });
    }
    if (prevProps.visible !== visible && tournament && visible) {
      requestGameInfo({ squadid: tournament });
    }
  }

  getTournamentType(type) {
    switch (type) {
      case 1:
        return 'Battle';
      case 2:
        return '1x1';
      case 3:
        return 'Solo';
      case 4:
        return 'Pinfall Battle';
      default:
        return '';
    }
  }

  render() {
    const {
      visible,
      onClose,
      onOk,
      gameInfo,
      gameFetch,
      UserByIdFetch,
      intl,
    } = this.props;
    const {
      tournamenttypeid,
      tournamentname,
      tournamenttypebrief,
      isbattle,
      datestart,
      tournamentcost,
      tournamentcurrencysymbol,
      protestsCount,
      pro_tariff,
      players,
      games,
      archive,
    } = gameInfo;
    if (gameFetch || !gameInfo) {
      return null;
    }
    const isPinfall = isPinfallFun(tournamenttypebrief);
    return (
      <Modal
        width={isSolo(tournamenttypebrief) ? 681 : 750}
        visible={visible}
        title={intl.formatMessage({ id: 'GameResults', defaultMessage: 'Game Results' })}
        okText={archive
          ? intl.formatMessage({ id: 'watchVideo', defaultMessage: 'Watch video' })
          : intl.formatMessage({ id: 'record_deleted', defaultMessage: 'Record deleted' })
        }
        cancelText={intl.formatMessage({ id: 'close', defaultMessage: 'Close' })}
        onOk={() => (archive ? onOk() : null)}
        onCancel={onClose}
      >
        <div className="gameResultsModal">
          <Players
            intl={intl}
            games={games}
            onLoginClick={UserByIdFetch}
            players={players}
            isPinfall={isPinfall}
          />
          {!isbattle && <InfoText
            label= {intl.formatMessage({ id: 'Tournament', defaultMessage: 'Tournament' })}
            text={tournamentname}
          />}
          <InfoText
            label={intl.formatMessage({ id: 'GameType', defaultMessage: 'Type of game' })}
            text={this.getTournamentType(tournamenttypeid)}
          />
          <InfoText
            label={intl.formatMessage({ id: 'StartOfGame', defaultMessage: 'Start of game' })}
            text={ datestart
              ? `${moment(datestart).format('DD MMM - hh:mm')} (${moment(datestart).tz('Europe/London').format('hh:mm')} UK)`
              : 'Not started'
            }
          />
          {/*<InfoText
            label={intl.formatMessage({ id: 'TournamentGameCost', defaultMessage: 'Fee of Game' })}
            text={useGameCostText({ cost: tournamentcost, currencysymbol: tournamentcurrencysymbol, pro_tariff })}
          />*/}
          <InfoText
            label={intl.formatMessage({ id: 'Protests', defaultMessage: 'Protests' })}
            text={protestsCount}
          />
          {
            players.map((player) => {
              if (player.is_surrender) {
                return (
                  <div className="gaveUpStatus">{`Player ${player.firstname} ${player.lastname} has gave up`}</div>
                );
              }
              return null;
            })
          }
          <SquadScore
            isPinfall={isPinfall}
            games={games}
            players={players}
          />
          {
            games.map((game, index) => (
              <div key={index} className="gameScore">
                <div className={`gameLabel ${isSolo(tournamenttypebrief) ? 'margin' : ''}`}>Game {index + 1}</div>
                <GameScore
                  game={game}
                  players={players}
                  tournamentbrief={tournamenttypebrief}
                />
              </div>
            ))
          }
        </div>
      </Modal>
    );
  }
}

export default injectIntl(
  connect(
    state => ({
      gameInfo: state.gameInfo.data,
      gameFetch: state.gameInfo.fetch,
    }),
    {
      requestGameInfo,
      UserByIdFetch,
    },
  )(gameResultsModal),
);
