import React from 'react';
import SelectBase from 'react-select';

const arrow = require('../../../../images/elyt/arrow.png');

function LandingSelect({
  label,
  className,
  onChange,
  value,
  options,
  placeholder,
}) {
  return (
    <div className={`l-select ${className}`}>
      <div className="s-label">{label}</div>
      <SelectBase
        placeholder={placeholder || 'not selected'}
        options={(options || []).map(option => ({ ...option, label: <div>{option.label}</div> }))}
        onChange={option => onChange(option)}
        value={value}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => <img src={arrow}/>
        }}
        className={'l-react-select'}
        classNamePrefix="l-react-select"
        isSearchable={false}
      />
    </div>
  );
}

export default LandingSelect;
