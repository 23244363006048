import React from 'react';
import { Avatar } from './common';
import config from '../../../config';
import './style.scss';

const getResult = (shot1, shot2, shot3) => {
  let _shot1;
  let _shot2;
  let _shot3;
  /* eslint-disable no-param-reassign */
  shot1 = parseInt(shot1, 10);
  shot2 = parseInt(shot2, 10);
  shot3 = parseInt(shot3, 10);
  /* eslint-enable no-param-reassign */
  if (shot1 === 10) {
    _shot1 = 'X';
  } else if (shot1 === 0) {
    _shot1 = '-';
  } else {
    _shot1 = shot1;
  }
  if (shot2 !== -1) {
    if (shot2 === 10) {
      _shot2 = 'X';
    } else if (shot2 === 0) {
      _shot2 = '-';
    } else if (shot1 + shot2 === 10) {
      _shot2 = '/';
    } else {
      _shot2 = shot2;
    }
  }
  if (shot3 !== -1) {
    if (shot3 === 10) {
      _shot3 = 'X';
    } else if (shot3 === 0) {
      _shot3 = '-';
    } else if (shot2 + shot3 === 10) {
      _shot3 = '/';
    } else {
      _shot3 = shot3;
    }
  }
  if (shot1 === -1) {
    return '';
  }
  if (shot2 === -1) {
    return ` ${_shot1} `;
  }
  if (shot3 === -1) {
    return `${_shot1}   ${_shot2}`;
  }
  return `${_shot1}   ${_shot2}   ${_shot3}`;
};


function Frame(props) {
  const {
    isWide,
    isTotal,
    frameNum,
    playerA,
    playerB,
  } = props;


  return (
    <div
      className={`frame__gs ${isWide ? 'wide' : ''} ${isTotal ? 'total' : ''}`}
    >
      <div className="bordered">
        <div className="frameNum">{frameNum}</div>
        <div className="frameBody">{
          isTotal ? playerA.score : getResult(playerA.shot1, playerA.shot2, playerA.shot3)
        }</div>
        <div
          className={`frameScore ${!playerB ? 'orange' : 'green1'} ${(isTotal && (playerA.score_handicap >= playerA.score)) ? 'handicap' : ''}`}
        >
          {(isTotal && playerA.score_handicap) || playerA.score}
        </div>
      </div>
      {playerB && (
        <div className="opponent1 bordered">
          <div className="frameBody">{
            isTotal ? playerB.score : getResult(playerB.shot1, playerB.shot2, playerB.shot3)
          }</div>
          <div
            className={`frameScore orange ${(isTotal && (playerB.score_handicap >= playerB.score)) ? 'handicap' : ''}`}
          >
            {(isTotal && playerB.score_handicap) || playerB.score}
          </div>
        </div>
      )}
    </div>
  );
}

export default function gameScore(props) {
  const { game, players } = props;
  const { frames: framesData } = game;
  const isSolo = players.length === 1;
  const frames = [];
  const totalScores = {};

  players.map((player) => {
    const playerid = player.id;
    let score = framesData[playerid][9].score;
    const score_handicap = framesData[player.id][9].score_handicap;
    totalScores[playerid] = {
      score,
      score_handicap,
    };
  });

  for (let index = 0; index < 10; index += 1) {
    frames.push(
      <Frame
        playerA={framesData[players[0].id][index]}
        playerB={!isSolo ? framesData[players[1].id][index] : null}
        isWide={index === 9}
        frameNum={index + 1}
      />,
    );
  }

  return (
    <div className="frames">
      {!isSolo
        ? (
          <div className="avatar__grm avatars__grm">
            <Avatar
              player={players[0]}
              color="green"
            />
            <Avatar
              player={players[1]}
              color="orange"
            />
          </div>)
        : null}
      {frames}
      <Frame
        playerA={totalScores[players[0].id]}
        playerB={!isSolo ? totalScores[players[1].id] : null}
        isTotal
        isWide
        frameNum="TOTAL"
      />
    </div>
  );
}