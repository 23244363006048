const {
	SOCKET_YELLOW_CARD,
	SOCKET_YELLOW_CARD_MARKSEEN
} = require('./squads.reducer');
const {
	SET_SQUAD_STATE,
	LOGOUT,
	FETCH_JOIN_AS_PLAYER,
	SET_JOIN_AS_PLAYER,
	SET_RED_CARD,
	SET_RED_CARD_SEEN,
	SET_SHOW_HELP_TIMEOUT_MODAL,
	LAST_FRAME_UPDATED,
} = require('./actiontypes').default;

export const markRedCardAsSeen = () => ({ type: SET_RED_CARD_SEEN });
export const closeShowModalTimeout = () => ({ type: SET_SHOW_HELP_TIMEOUT_MODAL, params: false });

const initialState = {
	data: false,
	fetch: false,
	connected: false,
	yellowCardEvent: false,
	redCardEvent: false,
	showHelpModalTimeout: false,
	lastFrameUpdated: null,
}

const squadScreenReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOGOUT:
			return { ...initialState };
		case SET_SQUAD_STATE:
			return { ...state, data: action.params }
		case FETCH_JOIN_AS_PLAYER:
			return { ...initialState, fetch: true }
		case SET_JOIN_AS_PLAYER:
			return { ...state, fetch: false, connected: true, data: action.params }
		case SOCKET_YELLOW_CARD:
			state.data.players.forEach(player => {
				if (player.userid == action.params.userid && player.competitionid == action.params.squadid) {
					player.delays += 1; 
				}
			})
			if (state.data.id == action.params.squadid) {
				const gameIndex = state.data.games.findIndex(game => game.id === action.params.gameid);
				const player = state.data.players.find(player => player.userid === action.params.userid);
				const frameIndex = state.data.games[gameIndex].frames[player.id].findIndex(frame => frame.id === action.params.frameid);
				state.data.games[gameIndex].frames[player.id][frameIndex].deadline = action.params.deadline;
				if (action.params.useridFromRedux === action.params.userid) {
					return { ...state, yellowCardEvent: true };
				} 
			}
			return state;
		case SOCKET_YELLOW_CARD_MARKSEEN:
			return { ...state, yellowCardEvent: false }
		case SET_RED_CARD:
			return { ...state, redCardEvent: true }
		case SET_RED_CARD_SEEN:
			return { ...state, redCardEvent: false }
		case SET_SHOW_HELP_TIMEOUT_MODAL:
			return {
				...state,
				showHelpModalTimeout: action.params
			}
		case LAST_FRAME_UPDATED:
			return {
				...state,
				lastFrameUpdated: action.params
			}
		default:
			return state
	}
};

export default squadScreenReducer;