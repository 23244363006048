import React from 'react';
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import moment from 'moment';

import { Button } from '../../common/Button';
import OBDatePicker from '../../common/DatePicker';
import Select from '../../common/Select';
import { FilterInput } from '../../common/Input';
import ModalDocsAdded from './modalDocsAdded';

import docsPhoto from '../../../images/docsPhoto.png'
import userPhoto from '../../../images/userPhoto.png'

import './style.scss';
import PhotoList from '../../common/PhotosList';

export default class AddDocsScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: {
        value: props.form.name || '',
        error: '',
      },
      shelfLife: {
        value: moment(props.form.shelfLife) || moment(new Date()),
        error: '',
      },
      dob: {
        value: moment(props.form.dob) || moment(this.maxDate(18)),
        error: '',
      },
      docNumber: {
        value: props.form.docNumber ||  '',
        error: ''
      },
      country: {
        value: props.form.country || '',
        error: '',
      },
      visible: false,
      imagesError: '',
      errorsString: [],
      docImages: props.form.docImages || [],
      userImages: props.form.userImages || [],
    };
  }

  static propTypes = {
    intl: intlShape,
    goBack: PropTypes.func.isRequired,
    status: PropTypes.string.isRequired,
    comment: PropTypes.string,
  };

  componentDidUpdate(prevProps) {
    const { index } = this.props;
    if (
      prevProps.userdocs.isFetching
      && !this.props.userdocs.isFetching
      && !this.props.userdocs.error
      && (!prevProps.userdocs.data[index] || prevProps.userdocs.data[index].status !== this.props.userdocs.data[index].status)
      && this.props.userdocs.data[index].status === 'new'
    ) {
      this.setState({ visible: true, errorsString: [] });
    }
    if (prevProps.userdocs.isFetching && !this.props.userdocs.isFetching && this.props.userdocs.error) {
      this.setState({ visible: true, errorsString: this.props.userdocs.error });
    }
    // if (prevProps.form.country !== this.props.form.country) {
    //   this.setState({
    //     // ...this.state,
    //     country: {
    //       ...this.state.country,
    //       value: this.props.form.country || '',
    //     },
    //   })
    // }
    if (JSON.stringify(prevProps.form) != JSON.stringify(this.props.form)) {
      let state = { ...this.state };
      state = {
        ...state,
        name: {
          value: this.props.form.name || '',
          error: '',
        },
        shelfLife: {
          value: moment(this.props.form.shelfLife) || moment(new Date()),
          error: '',
        },
        dob: {
          value: moment(this.props.form.dob) || moment(this.maxDate(18)),
          error: '',
        },
        docNumber: {
          value: this.props.form.docNumber ||  '',
          error: ''
        },
        country: {
          value: this.props.form.country || '',
          error: '',
        },
        docImages: this.props.form.docImages || [],
        userImages: this.props.form.userImages || [],
      }
      this.setState({ ...state });
    }
  }

  onAddPhoto = (e, group) => {
    // e.preventDefault();
    // e.stopPropagation();

    const { intl } = this.props;
    const { docImages, userImages, imagesError } = this.state;
    const reader = new FileReader();
    try {
      const file = e.target.files[0];
      const allowedExtensions =  /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      if (!allowedExtensions.exec(file.name)) {
        return this.setState({
          errorsString: [intl.formatMessage({ id: 'ItIsNotImage', defaultMessage: 'It\'s not an Image' })],
          visible: true
        });
      }
      if (file.size > 10000000) {
        return this.setState({
          errorsString: [intl.formatMessage({ id: 'TooBigFile', defaultMessage: 'Uploaded image is too big. File size cannot exceed 10Mb.' })],
          visible: true
        });
      } 
      {
        reader.onloadend = () => {
          file.photo = reader.result;
          this.setState({ imagesError: '' });
          group === 'doc'
          ? this.setState({ docImages: [...docImages, file] })
          : this.setState({ userImages: [...userImages, file] })
        };
        reader.readAsDataURL(file);
      }
    } catch(error){
      console.error(error);
    }
  }

  onDeletePhoto = (index, group) => {
    const { docImages, userImages } = this.state;
    if (group === 'doc') {
      docImages.splice(index, 1)
      this.setState({ docImages })
    } else {
      userImages.splice(index, 1)
      this.setState({ userImages: userImages.splice(index, 1) })
    }
  }

  _firstnameChange = (e) => {
    this.setState({ name: { value: e.target.value, error: '' } });
  };

  _lastnameChange = (e) => {
    this.setState({ lastName: { value: e.target.value, error: '' } });
  };

  _dateChange = (date) => {
    this.setState({ dob: { value: date, error: '' } });
  };

  _shelfLifeChange = (date) => {
    this.setState({ shelfLife: { value: date, error: '' } });
  };
  _countryLiveChange = (e) => {
    this.setState({ country: { value: e, error: '' } });
  };

  _numberChange = (e) => {
    this.setState({ docNumber: { value: e.target.value, error: '' } })
  }

  minDate = (age) => {
    let date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    return new Date(year + age, month, day);
  }

  onSendDocs = () => {
    let state = {...this.state}
    const { docImages, userImages, imagesError, name, shelfLife, dob, docNumber, country } = state;
    const { intl, userdocs, index } = this.props;
    const docId = !!userdocs.data.length  && !!userdocs.data[index] ? userdocs.data[index].id : null;
    const noImageError = intl.formatMessage({ id: 'NeedAddPhotos', defaultMessage: 'Need to add photos' })
    const emptyFieldError = intl.formatMessage({ id: 'VerifyPhoneModalActionProvokeError', defaultMessage: 'This field must not be empty' })
    if (!name.value) {
      state.name.error = `${emptyFieldError}: ${intl.formatMessage({ id: 'Name', defaultMessage: 'Name' })} ${intl.formatMessage({ id: 'Surname', defaultMessage: 'Surname' })}\n`;
    }
    if (!dob.value) {
      state.dob.error = `${emptyFieldError}: ${intl.formatMessage({ id: 'PersonalInfoDOB', defaultMessage: 'Date of birth' })}\n`
    }
    if (!docNumber.value) {
      state.docNumber.error = `${emptyFieldError}: ${intl.formatMessage({ id: 'DocumentNumber', defaultMessage: 'Document Number' })}\n`
    }
    if (!country.value) {
      state.country.error = `${emptyFieldError}: ${intl.formatMessage({ id: 'IssuingCountry', defaultMessage: 'Issuing country' })}\n`
    }
    if (!shelfLife.value) {
      state.shelfLife.error = `${emptyFieldError}: ${intl.formatMessage({ id: 'DocumentsShelfLife', defaultMessage: 'Shelf life' })}\n`;
    }
    
    let errorsString = [];
    let stateList = [name, dob, docNumber, country, shelfLife, {images: { error: imagesError }}];
    stateList = stateList.filter(state => state.error)
    errorsString = stateList.map(state => state.error);
    if (!docImages || !docImages.length || !userImages || !userImages.length) {
      errorsString.push(noImageError)
    }
    if (errorsString && errorsString.length) {
      state.errorsString = errorsString;
      state.visible = true
      return this.setState({ ...state })
    } else {
      let data = new FormData();
      data.append('id', docId);
      data.append('name', name.value);
      data.append('dob', dob.value);
      data.append('docnum', docNumber.value);
      data.append('country', country.value.value);
      data.append('expirymonth', new Date(shelfLife.value).getMonth());
      data.append('expiryyear', new Date(shelfLife.value).getFullYear());
      for (let i = 0; i < docImages.length; i++) {
        data.append(`docImages${i}`, docImages[i]);
      }
      for (let i = 0; i < userImages.length; i++) {
        data.append(`userImages${i}`, userImages[i]);
      }
      this.props.SendDocs(data)
      return this.setState({ ...state })
    }
  }

  onCloseModal =() => {
    this.setState({ visible: false });
    // this.props.goBack();
  }

  render() {
    const { intl, geodata = [], userdocs, maxDate, data, index } = this.props;
    const { comment, status } = !!data.length && !!data[index] ? data[index] : {};
    const { name, dob, docNumber, country, visible, shelfLife, docImages, userImages, errorsString } = this.state;
    const isListEditable = !data || !data[index] || status !== 'new';
    return (
      <div>
        <div className="verification-block input-docs">
          {status === 'new' &&
            <div className="input-docs__status_wait">
              <span>
                {intl.formatMessage({ id: 'ApplicationOfIdentityIsPending', defaultMessage: 'Your application for verification of identity is pending. You will receive an email notification of the results.' })}
              </span>
            </div>
          }
          <h2>{intl.formatMessage({ id: 'EnterDocumentDetails', defaultMessage: 'Enter document details' })}</h2>
          <div  className="input-personal-data">
            <div className="input-personal-data__fields">
              <div className="verification-block input-docs-data">
                  <span>{intl.formatMessage({ id: 'Name', defaultMessage: 'Name' })} {intl.formatMessage({ id: 'Surname', defaultMessage: 'Surname' })}</span>
                  {isListEditable
                    ? <FilterInput
                      className="input-docs-data-fields"
                      placeholder={`${intl.formatMessage({ id: 'Name', defaultMessage: 'Name' })} ${intl.formatMessage({ id: 'Surname', defaultMessage: 'Surname' })}`}
                      value={name.value}
                      onChange={this._firstnameChange}
                      error={name.error}
                    />
                    : <p>{name.value}</p>
                  }
              </div>
              <div className="verification-block input-docs-data">
                <span>
                  {intl.formatMessage({ id: 'PersonalInfoDOB', defaultMessage: 'Date of birth' })}
                </span>
                {isListEditable
                  ? <OBDatePicker
                      icon
                      disabledDate={d => !d || d.isAfter(maxDate(18))}
                      placeholder={intl.formatMessage({ id: 'date', defaultMessage: 'Date' })}
                      value={dob.value}
                      onChange={this._dateChange}
                      dateFormat="D MMM YYYY"
                    />
                  : <p>{moment(dob.value).format("D MMM YYYY")}</p>
                }
              </div>
              <div className="verification-block input-docs-data">
                  <span>{intl.formatMessage({ id: 'DocumentNumber', defaultMessage: 'Document Number' })}</span>
                  {isListEditable
                    ? <FilterInput
                      className="input-docs-data-fields"
                      placeholder={intl.formatMessage({ id: 'DocumentNumber', defaultMessage: 'Document Number' })}
                      value={docNumber.value}
                      onChange={this._numberChange}
                    />
                  : <p>{docNumber.value}</p>
                  }
              </div>
              <div className="verification-block input-docs-data">
                <span>
                    {intl.formatMessage({ id: 'IssuingCountry', defaultMessage: 'Issuing country' })}
                </span>
                {isListEditable
                  ? <Select
                    isSearchable
                    placeholder={intl.formatMessage({ id: 'IssuingCountry', defaultMessage: 'Issuing country' })}
                    value={country.value}
                    options={_.map(geodata.data, item => ({ value: item.iso, label: item.name }))}
                    onChange={this._countryLiveChange}
                  />
                  : <p>{country.value.label}</p>
                }
              </div>
              <div className="verification-block input-docs-data">
                <span>
                  {intl.formatMessage({ id: 'DocumentsShelfLife', defaultMessage: 'Shelf life' })}
                </span>
                {isListEditable
                  ? <OBDatePicker
                      icon
                      disabledDate={d => !d || d.isAfter(this.minDate(20)) || d.isBefore(new Date())}
                      placeholder={intl.formatMessage({ id: 'date', defaultMessage: 'Date' })}
                      value={shelfLife.value}
                      onChange={this._shelfLifeChange}
                      dateFormat="D MMM YYYY"
                    />
                  : <p>{moment(shelfLife.value).format("MMM YYYY")}</p>
                }
              </div>
            </div>
            {comment && comment.length && status === 'bug' && <div className="docs__comments">
              <span className="docs__comments__title">
                {intl.formatMessage({ id: 'AdminComment', defaultMessage: 'Admin comment' })}
              </span>
                <br />
                <span className="docs__comments__comment">{comment}</span>
            </div>}
          </div>
        </div>
        <div className="verification-block images">
          <h2>{intl.formatMessage({ id: 'DocumentPhoto', defaultMessage: 'Document photo' })}</h2>
          <div className="_verification-field-images">
            {!docImages.length
              ? (
                <label className="_verification-field-photo">
                  <img src={docsPhoto}/>
                  <p>{intl.formatMessage({ id: 'DocsPhotoUpload', defaultMessage: 'Upload a photo of the document' })}</p>
                  <div 
                    className="_verification-field-btn"
                  >{intl.formatMessage({ id: 'UploadPhotoButtonText', defaultMessage: 'Upload photo' })}
                  </div>
                  <input
                    type="file"
                    onChange={(e) => {
                      this.onAddPhoto(e, 'doc')
                    }}
                  />
                </label>
              )
              : <div className="photo_container">
              <PhotoList
                intl={intl}
                photos={docImages}
                onDeletePhoto={this.onDeletePhoto}
                onAddPhoto={(e) => {
                  this.onAddPhoto(e, 'doc')
                }}
                isListEditable={isListEditable}
                limitNumber={5}
                type={'doc'}
              />
              </div>
            }
            {isListEditable && <span>
              {intl.formatMessage({ id: 'DocumentPhotoInstruction', defaultMessage: 'The photo should clearly show: Name, Surname, Date of birth, Document number, Country of issue, Expiration date. To avoid verification delays, try to make the photo of the documents distinct, all information is readable and complete.' })}
            </span>}
          </div>

          <h2>{intl.formatMessage({ id: 'YourPhoto', defaultMessage: 'Your photo' })}</h2>
          <div className="_verification-field-images">
            {!userImages.length
              ? (
                <label className="_verification-field-photo">
                  <img src={docsPhoto}/>
                  <p>{intl.formatMessage({ id: 'NoPhotoUploadPhoto', defaultMessage: 'Upload your photo ' })}</p>
                  <div 
                    className="_verification-field-btn"
                  >{intl.formatMessage({ id: 'UploadPhotoButtonText', defaultMessage: 'Upload photo' })}
                  </div>
                  <input
                    type="file"
                    onChange={(e) => {
                      this.onAddPhoto(e, 'user')
                    }}
                  />
                </label>
              )
              : <div className="photo_container">
                <PhotoList
                  intl={intl}
                  photos={userImages}
                  onDeletePhoto={this.onDeletePhoto}
                  onAddPhoto={(e) => {
                    this.onAddPhoto(e, 'user')
                  }}
                  isListEditable={isListEditable}
                  limitNumber={2}
                  type={'user'}
                />
              </div>
            }
            {isListEditable && <span>
              {intl.formatMessage({ id: 'YourPhotoInstruction', defaultMessage: 'To avoid verification delays, try to make your photo clear and easy to compare with the photo on your document.' })}
            </span>}
          </div>
          <div className="_verification-field-buttons">
            {isListEditable && <Button
              className="orange"
              bordered="false"
              size={150}
              onClick={this.onSendDocs}
            >
                <div>{intl.formatMessage({ id: 'SendAsOutgoneCommunicationAlias', defaultMessage: 'Send' })}</div>
            </Button>}
            <Button
              kind="transparent"
              bordered
              size={150}
              onClick={() => this.props.goBack()}
            >
                <div>{intl.formatMessage({ id: 'CloseAsCancelAlias', defaultMessage: 'Close' })}</div>
            </Button>
          </div>
        </div>
        <ModalDocsAdded
          intl={intl}
          visible={visible}
          onSubmit = {this.onCloseModal}
          errorsString={errorsString}
        />
      </div>
    );
  }
}
