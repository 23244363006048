import { EventEmitter } from './events'

class PEventEmitter extends EventEmitter {
  constructor() {
    super()
    /** @protected */
    this._events = {}
  }

  emit(event, ...args) {
    if (this.hasListener(event)) {
      super.emit(event, ...args)
    } else {
      this._events[event] = this._events[event] || []
      this._events[event].push(args)
    }
  }

  on(event, listener) {
    const res = super.on(event, listener)
    const events = this._events[event]
    if (Array.isArray(events) && events.length) {
      for (let args of events) {
        super.emit(event, ...args)
      }
      events.length = 0
      delete this._events[event]
    }
    return res;
  }

  log(...args) {
    console.log('[PEventEmitter]', ...args)
  }
}

export { PEventEmitter }