/* eslint-disable */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { Radio, Select } from 'antd';
import Button from '../../common/Button/Button';
//import Select from '../../common/Select';
import CheckBox from '../../common/Checkbox';

import {
  bindBowlingcenter,
  unbindBowlingcenter,
  addTournamentSetting,
  deleteTournamentSetting,
  SendInviteTournamentNotification
} from '../../../libs/reducers/actions';
import {EditableValue} from "../../adminRoot/handicapsTab";
import { reglamentCreateRequestAction, reglamentDeleteRequestAction } from '../../../libs/reducers/reglament.reducks';
import config from '../../../config';

const DISPLAY_TOURNAMENT_TABLE = 'DISPLAY_TOURNAMENT_TABLE';
const COUNT_PRIZES_PLACES = 'COUNT_PRIZES_PLACES';
const COUNT_REPLAYS = 'COUNT_REPLAYS';
const BY_INVITATION = 'BY_INVITATION';
const AGE_LIMIT = 'AGE_LIMIT';
const MIN_AGE = 'MIN_AGE';
const MAX_AGE = 'MAX_AGE';
const AVG_SCORE = 'AVG_SCORE';
const EXCLUDE_FROM_STATS = 'EXCLUDE_FROM_STATS';
const WITH_TARIFF_ONLY = 'WITH_TARIFF_ONLY';

const STAGES_TOURNAMENT_TABLE = 'STAGES_TOURNAMENT_TABLE';
const INDIVIDUAL_TOURNAMENT_TABLE = 'INDIVIDUAL_TOURNAMENT_TABLE';

/**
 * @param {Array<{ tournament_setting_brief: string; value_bool?: boolean; value_int?: number }>} settings
 * @param {string} name 
 * @param {'boolean' | 'number'} type
 */
const findSettingValue = (settings, name, type) => {
  const setting = settings.find(s => s.tournament_setting_brief === name)
  if (!setting) {
    return null;
  }
  switch (type) {
    case 'boolean':
      return setting.value_bool;
    case 'number':
      return setting.value_int;
    default:
      return null;
  }
}

const TournaentSettingsTab = ({
  intl,
  bowlingcenters,
  tournamentInfo,
  reglaments,
  bindBowlingcenter,
  unbindBowlingcenter,
  addTournamentSetting,
  deleteTournamentSetting,
  reglamentCreateRequestAction,
  reglamentDeleteRequestAction,
  SendInviteTournamentNotification,
  pushNotificationSending,
}) => {
  const [tournamentTable, setTournamentTable] = useState(false);
  const [leaderboardType, setLeaderboardType] = useState(null);
  const [numberPrizes, setNumberPrizes] = useState(null);
  const [isLimitReplays, setIsLimitReplays] = useState(false);
  const [limitReplaysBool, setLimitReplaysBool] = useState(null);
  const [limitReplays, setLimitReplays] = useState(null);
  const [byInvitation, setByInvitation] = useState(false);
  const [ageLimit, setAgeLimit] = useState(false);
  const [minAgeValue, setMinAgeValue] = useState(null)
  const [maxAgeValue, setMaxAgeValue] = useState(null)
  const [avScoreBool, setAvScoreBool] = useState(false)
  const [avInputValue, setAvInputValue] = useState(null)
  const [reglamentFile, setReglamentFile] = useState(null);
  const [sendPushBool, setSendPusBool] = useState(false)
  const [excludeFromStats, setExcludeFromStats] = useState(false)
  const [withTariffOnly, setWithTariffOnly] = useState(false)

  const buttonParameters = {
    buttonOnClick : () => pushNotificationSending || tournamentInfo.push_sent  ? null : sendPushNotification(),
    buttonClass : pushNotificationSending || !sendPushBool || tournamentInfo.push_sent ? 'disabled-button' : 'button',
    text: tournamentInfo.push_sent ? 'Push sent' : pushNotificationSending ? 'Sending push' : 'Send push'
  }

  useEffect(() => {
    const init = () => {
      const { settings } = tournamentInfo;
      if (!Array.isArray(settings) || !settings.length) return;
      const displayTournamentTable = settings.find(s => s.tournament_setting_brief === DISPLAY_TOURNAMENT_TABLE);
      if (displayTournamentTable) {
        setTournamentTable(displayTournamentTable.value_bool || false);
        setLeaderboardType(displayTournamentTable.value_text || null);
      }
      setNumberPrizes((settings.find(s => s.tournament_setting_brief === COUNT_PRIZES_PLACES) || {}).value_int || null);
      setLimitReplays((settings.find(s => s.tournament_setting_brief === COUNT_REPLAYS) || {}).value_int || null);
      setLimitReplaysBool((settings.find(s => s.tournament_setting_brief === COUNT_REPLAYS) || {}).value_bool || null);
      setByInvitation(!!(settings.find(s => s.tournament_setting_brief === BY_INVITATION) || {}).value_bool || false);
      setAgeLimit(!!(settings.find(s => s.tournament_setting_brief === AGE_LIMIT) || {}).value_bool || false);
      setMinAgeValue(findSettingValue(settings, MIN_AGE, 'number'));
      setMaxAgeValue(findSettingValue(settings, MAX_AGE, 'number'));
      setAvScoreBool((settings.find(s => s.tournament_setting_brief === AVG_SCORE) || {}).value_bool || null)
      setAvInputValue((settings.find(s => s.tournament_setting_brief === AVG_SCORE) || {}).value_int || null)
      setExcludeFromStats((settings.find(s => s.tournament_setting_brief === EXCLUDE_FROM_STATS) || {}).value_bool || false)
      setWithTariffOnly((settings.find(s => s.tournament_setting_brief === WITH_TARIFF_ONLY) || {}).value_bool || false)
    };

    init();
  }, [tournamentInfo.settings]);
  useEffect(() => {
    if (!reglaments.fetching && !reglaments.error && reglamentFile) {
      setReglamentFile(null);
    }
  }, [reglaments.fetching]);

  const fileRef = useRef();

  const onLeaderboardTypeChange = useCallback(
    (e) => {
      addTournamentSetting({
        tournamentid: tournamentInfo.id,
        value_bool: tournamentTable,
        setting_brief: DISPLAY_TOURNAMENT_TABLE,
        value_text: e.target.value,
      });
    },
    [tournamentInfo, tournamentTable, DISPLAY_TOURNAMENT_TABLE],
  );

  const options = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50];
  const notBindedBC = bowlingcenters
    .filter(item => !(tournamentInfo.bindedBowlingcenters || [])
      .find(item2 => item2.bowlingcenterid === item.id));

  const { tournamenttypebrief } = tournamentInfo;

  const handleEditValue = ({newValue, rawValue, id, setting_brief }, setValue) => {
    console.log({ newValue, rawValue })
    if (setValue) {
      if (rawValue === '') {
        setValue(null)
      } else {
        setValue(newValue)
      }
    } else {
      setAvInputValue(newValue)
    }
    addTournamentSetting({
      tournamentid: id,
      setting_brief: setting_brief,
      value_int: rawValue === '' ? null : newValue,
      value_bool: true,
    })
  };
  const sendPushNotification = () => {
    SendInviteTournamentNotification({
      createdby: tournamentInfo.createdby,
      tournamentname: tournamentInfo.name,
      datetimeend: tournamentInfo.regdateend,
      datetimestart: tournamentInfo.regdatestart,
      istest: tournamentInfo.is_test,
      tournamentID: tournamentInfo.id,
      byInvitation: tournamenttypebrief === 'BATTLES' ? true : byInvitation,
    });
  }

  const onSelectFile = (e) => {
    /** @type {File} */
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('reglament', file, file.name);
    reglamentCreateRequestAction({ tournamentid: tournamentInfo.id, formData });
  };

  const onSelectReglament = () => {
    fileRef.current.click();
  };

  const onDeleteReglament = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { id: tournamentid, reglaments: [{ id: reglamentid }] } = tournamentInfo;
    reglamentDeleteRequestAction({ tournamentid, reglamentid });
  };

  const onDownloadReglament = () => {
    const [reglament] = tournamentInfo.reglaments;
    const { filename } = reglament;
    const url = `${config.FETCH.img}/${filename}`;
    window.open(url);
  };

  return (
    <>
      <div className="label">{intl.formatMessage({ id: 'settings', defaulMessage: 'settings' })}</div>
      <div className="property">
        <div className="name">
          {intl.formatMessage({ id: 'BowlingCenterRestriction', defaulMessage: 'Bowling center restriction' })}
        </div>
        <div className="bowlingcenters">
          {
            (tournamentInfo.bindedBowlingcenters || []).map(item => (
              <div key={item.id} className="bc__block">
                {item.name}
                <Button
                  size="md"
                  icon="tray"
                  kind="aquamarine"
                  bordered="true"
                  onClick={() => {
                    unbindBowlingcenter({
                      bowlingcenterid: item.bowlingcenterid,
                      tournamentid: tournamentInfo.id,
                    });
                  }}
                />
              </div>
            ))
          }
        </div>
        <div className="select__container">
          <Select
            customStyle={styles}
            value={intl.formatMessage({ id: 'AddBowlingCenter', defaulMessage: 'Add Bowling Center' })}
            className="settings-select"
            onChange={(value) => {
              bindBowlingcenter({
                bowlingcenterid: value,
                tournamentid: tournamentInfo.id,
              });
            }}
            options={notBindedBC.filter(item => item.id).map(item => ({ value: item.id, label: item.name }))}
          >
            {
              notBindedBC
                .filter(item => item.id)
                .map((item, index) => <Select.Option key={index} value={item.id}>{item.name}</Select.Option>)
            }
          </Select>
        </div>
      </div>
      <div className="property">
        {(tournamenttypebrief !== 'DROP_IN' && (
          <>
            <div className="name">
              {intl.formatMessage({
                id: 'Standings',
                defaulMessage: 'Standings',
              })}
            </div>
              <CheckBox
                checked={tournamentTable}
                onChange={() => addTournamentSetting({
                  tournamentid: tournamentInfo.id,
                  setting_brief: DISPLAY_TOURNAMENT_TABLE,
                  value_bool: !tournamentTable,
                  value_text: leaderboardType ? null : INDIVIDUAL_TOURNAMENT_TABLE,
                })}
              >
                <div className="checkbox-value">
                  {intl.formatMessage({
                    id: 'AddStandings',
                    defaulMessage: 'Add standings',
                  })}
                </div>
              </CheckBox>
              {
                tournamentTable
                  ?
                    <Radio.Group
                      className="radio"
                      value={leaderboardType}
                      onChange={onLeaderboardTypeChange}
                    >
                      <Radio.Button value={INDIVIDUAL_TOURNAMENT_TABLE}>
                        {intl.formatMessage({ id: 'AccordingToTheIndividualStandings', defaultMessage: 'According to the individual standings' })}
                      </Radio.Button>
                      <Radio.Button value={STAGES_TOURNAMENT_TABLE}>
                        {intl.formatMessage({ id: 'ByStagesOfTheTournament', defaultMessage: 'By stages of the tournament' })}
                      </Radio.Button>
                    </Radio.Group>

                  : null
              }
          </>
        )) || <></>}
        {(((tournamentTable && leaderboardType === INDIVIDUAL_TOURNAMENT_TABLE) || tournamenttypebrief === 'DROP_IN') && (
          <div className="select__container checkbox__select">
            <div className="select__label">
              {intl.formatMessage({
                id: 'TournamentNumberOfPrizes',
                defaulMessage: 'Number of prizes',
              })}
            </div>
            <Select
              isSearchable={false}
              customStyle={styles}
              value={numberPrizes}
              placeholder=""
              onChange={value => addTournamentSetting({
                tournamentid: tournamentInfo.id,
                setting_brief: COUNT_PRIZES_PLACES,
                value_int: value,
              })}
            >
              {
                [...options].map((item, index) => <Select.Option key={index} value={item}>{item}</Select.Option>)
              }
            </Select>
          </div>
        )) || <></> }
      </div>
      <div className="property">
        <div className="name">
          {intl.formatMessage({
            id: 'TournamentNumberOfReplays',
            defaulMessage: 'Number of replays',
          })}
        </div>

        <CheckBox
          checked={limitReplaysBool}
          onChange={(checked) => {
              addTournamentSetting({
                tournamentid: tournamentInfo.id,
                setting_brief: COUNT_REPLAYS,
                value_bool: !!checked,
                value_int: avInputValue,
              });
          }}
        >
          <div className="checkbox-value">
            {intl.formatMessage({
              id: 'TournamentLimitReplays',
              defaulMessage: 'Limit replays',
            })}
          </div>
        </CheckBox>
        {(!!limitReplaysBool && (
          <div className="select__container checkbox__select">
            {/* <div className="select__label">
              {intl.formatMessage({
                id: 'TournamentNumberOfReplays',
                defaulMessage: 'Number of replays',
              })}
            </div> */}
            <Select
              isSearchable={false}
              customStyle={styles}
              value={limitReplays}
              placeholder=""
              onChange={value => addTournamentSetting({
                tournamentid: tournamentInfo.id,
                setting_brief: COUNT_REPLAYS,
                value_bool: true,
                value_int: value,
              })}
            >
              {
                [...options].map((item, index) => <Select.Option key={index} value={item}>{item}</Select.Option>)
              }
            </Select>
          </div>
        )) || <></> }
      </div>
      {tournamenttypebrief !== 'BATTLES' && <div className="property">
        <div className="name">
          {intl.formatMessage({
            id: 'TournamentAccessibility',
            defaulMessage: 'Accessibility',
          })}
        </div>
        <CheckBox
          checked={byInvitation}
          onChange={() => addTournamentSetting({
            tournamentid: tournamentInfo.id,
            setting_brief: BY_INVITATION,
            value_bool: !byInvitation,
          })}
        >
          <div className="checkbox-value">
            {intl.formatMessage({
              id: 'MakeAnInvitationTournament',
              defaulMessage: 'Make an invitation tournament',
            })}
          </div>
        </CheckBox>
        <div className='checkbox'>
          <CheckBox
            checked={withTariffOnly}
            onChange={(checked) => {
              addTournamentSetting({
                tournamentid: tournamentInfo.id,
                setting_brief: WITH_TARIFF_ONLY,
                value_bool: !withTariffOnly,
              });
            }}
          >
            <div className="checkbox-value2">
              {intl.formatMessage({ id: 'WithTariffOnly', defaultMessage: 'With the tariff only' })}
            </div>
          </CheckBox>
        </div>
      </div>}
      <div className="property">
        <div className="name">
          {intl.formatMessage({
            id: 'TournamentLimit',
            defaultMessage: 'Limit',
          })}
        </div>
        <CheckBox
          checked={ageLimit}
          onChange={() => addTournamentSetting({
            tournamentid: tournamentInfo.id,
            setting_brief: AGE_LIMIT,
            value_bool: !ageLimit,
          })}
        >
          <div className="checkbox-value">
            {intl.formatMessage({
              id: 'MakeAnTournamentWithAgeLimit',
              defaultMessage: 'Make the tournamentavailable only to users over the age of 18',
            })}
          </div>
        </CheckBox>
        {!!ageLimit && <div className="select__container checkbox__select">
          <div className="select__editable-input">
            <EditableValue
              value={minAgeValue}
              onEdit={(value, rawValue) => handleEditValue({
                preValue: minAgeValue,
                newValue: value,
                rawValue,
                id: tournamentInfo.id,
                setting_brief: MIN_AGE,
              }, setMinAgeValue)}
            />
          </div>
          <div className="including__label" title="если поле оставить пустым, это равносильно ограничению минимального возраста 18 лет">
            {'minimum age'}
          </div>
        </div>}
        {!!ageLimit && <div className="select__container checkbox__select">
          <div className="select__editable-input">
            <EditableValue
              value={maxAgeValue}
              onEdit={(value, rawValue) => handleEditValue({
                preValue: maxAgeValue,
                newValue: value,
                rawValue,
                id: tournamentInfo.id,
                setting_brief: MAX_AGE,
              }, setMaxAgeValue)}
            />
          </div>
          <div className="including__label">
            {'maximum age'}
          </div>
        </div>}

        <CheckBox
          checked={avScoreBool}
          onChange={(checked) => {
            addTournamentSetting({
              tournamentid: tournamentInfo.id,
              setting_brief: AVG_SCORE,
              value_bool: !!checked,
              value_int: avInputValue,
            });
          }}
        >
          <div className="checkbox-value2">
            {intl.formatMessage({
              id: 'TournamentMaxAvg',
              defaultMessage: 'Max Average for participation',
            })}
          </div>
        </CheckBox>
        {!!avScoreBool && <div className="select__container checkbox__select">
          <div className="select__editable-input">
            <EditableValue
              value={avInputValue}
              onEdit={value => handleEditValue({
                preValue: avInputValue,
                newValue: value,
                id: tournamentInfo.id,
                setting_brief: AVG_SCORE,
              })}
            />
          </div>
          <div className="including__label">
            {intl.formatMessage({
              id: 'TournamentAverageIncluding',
              defaultMessage: 'including',
            })}
          </div>
        </div>}
      </div>
      <div className="property">
        <div className="name">
          {intl.formatMessage({ id: 'PushNotification', defaultMessage: 'Push notificastion' })}
        </div>
        <div className='checkbox'>
          <CheckBox
            checked={sendPushBool}
            onChange={(checked) => {
              setSendPusBool(checked);
            }}
          >
            <div className="checkbox-value2">
              {intl.formatMessage({ id: 'PushNotification', defaultMessage: 'Push notificastion' })}
            </div>
          </CheckBox>
        </div>
        <div className={buttonParameters.buttonClass} onClick={buttonParameters.buttonOnClick}>
          {buttonParameters.text}
        </div>
      </div>
      <div className="property">
        <div className="name">
          {intl.formatMessage({ id: 'ExcludeFromStats', defaultMessage: 'Exclude from stat' })}
        </div>
        <div className='checkbox'>
          <CheckBox
            checked={excludeFromStats}
            onChange={(checked) => {
              addTournamentSetting({
                tournamentid: tournamentInfo.id,
                setting_brief: EXCLUDE_FROM_STATS,
                value_bool: !excludeFromStats,
              });
            }}
          >
            <div className="checkbox-value2">
              {intl.formatMessage({ id: 'ExcludeFromStats', defaultMessage: 'Exclude from stat' })}
            </div>
          </CheckBox>
        </div>
      </div>
      <div className="property">
        <div className="name">
          {intl.formatMessage({ id: 'TournamentReglament', defaultMessage: 'Reglament' })}
        </div>
        {Array.isArray(tournamentInfo.reglaments) && tournamentInfo.reglaments.length ? (
          <div className="bc__block clickable" title="download" onClick={onDownloadReglament}>
            {intl.formatMessage({ id: 'TournamentReglament', defaultMessage: 'Reglament' })}
            <Button
              title="delete"
              size="md"
              icon="tray"
              kind="aquamarine"
              bordered="true"
              onClick={onDeleteReglament}
            />
          </div>
        ) : (
          <>
            <input type="file" name="reglament" accept=".pdf" ref={fileRef} onChange={onSelectFile} hidden />
            <Button
              size="s"
              kind="aquamarine"
              onClick={onSelectReglament}
              loading={reglaments.fetching}
              loadingtext={intl.formatMessage({ id: 'loadin', defaultMessage: 'Loading..' })}
            >
                {`${intl.formatMessage({ id: 'SelectFile', defaultMessage: 'Select file' })} (pdf)`}
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default connect(
  state => ({
    tournamentInfo: state.tournaments.tournamentInfo,
    bowlingcenters: state.bowlingcenters.bowlingcenters.data,
    reglaments: state.reglaments,
    pushNotificationSending: state.tournaments.fetches.pushNotificationSending,
  }),
  {
    bindBowlingcenter,
    unbindBowlingcenter,
    addTournamentSetting,
    deleteTournamentSetting,
    reglamentCreateRequestAction,
    reglamentDeleteRequestAction,
    SendInviteTournamentNotification,
  },
)(TournaentSettingsTab);

const styles = {
  pushNotificationButton: {
    marginTop: 14,
    marginBottom: 20,
    marginLeft: 14,
    maxWidth: 120,
  },
  control: base => ({
    ...base,
    backgroundColor: 'transparent',
    borderColor: 'rgba(255,255,255,0.4)',
    ':hover': {
      borderColor: 'rgba(255,255,255,0.4)',
    },
    borderRadius: 0,
    minHeight: 40,
    maxHeight: 40,
    boxShadow: 'none',
    cursor: 'pointer',
    padding: '0 0 0 10px',
    width: '100%',
    minWidth: 80,
  }),
  placeholder: base => ({
    ...base,
    color: 'rgba(255,255,255',
    fontSize: 16,
    fontWeight: 400,
  }),
  option: (base, { isSelected }) => ({
    ...base,
    backgroundColor: isSelected ? '#20a39e' : '#6d7177',
    ':hover': {
      backgroundColor: '#20a39e',
    },
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    height: 40,
    fontSize: 16,
    fontWeight: 300,
    padding: '0 5px 0 18px',
    cursor: 'pointer',
  }),
};
