import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';

import _ from 'lodash';
import moment from 'moment';

import OBModal from '../../Modal';
import Opponent from '../../Opponent';
import { Button } from '../../Button';
import Select from '../../Select';
import Checkbox from '../../Checkbox';
import { getBowlingCenters, getBowlingCentersCoutry, getBowlingCenterFromLocation } from '../../../../libs/reducers/bowlingCenters.reducer';
import { resetFailedPause } from '../../../../libs/reducers/SocketEventReducer';
import { GetCities, getPermissions } from '../../../../libs/reducers/actions';
import speedChecker from '../../../../libs/speedChecker';
import { markYellowCardAsSeen } from '../../../../libs/reducers/squads.reducer';

import './style.scss';
import WiFiIcon from './../../../../images/WiFiIcon.png';
import tournamentInfo from '../../../tournament/tournamentInfo';
import { isSolo } from '../../../../libs/SquadTypeCheck';
class StartGame extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    tournament: PropTypes.shape(),
    opponent: PropTypes.shape().isRequired,
    title: PropTypes.string,
    declineButtonText: PropTypes.string,
    acceptButtonText: PropTypes.string,
    additionalInfo: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    intl: intlShape,
  };

  static defaultProps = {
    title: '',
    additionalInfo: '',
    declineButtonText: '',
    acceptButtonText: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: props.visible,
      countryOptions: [],
      citiesOptions: [],
      centersOptions: [],
      linesOptions: [],
      country: '',
      city: '',
      centerName: '',
      line: '',
      firstLoad: false,
      waiting: false,
      connectionSpeed: 0,
      observers: 'all',
    };
  }

  componentDidMount() {
    this.props.getPermissions();
    this.props.getBowlingCenters();
    this.props.markYellowCardAsSeen();
    // this.updateConnectionSpeed();
    this.props.getBowlingCenterFromLocation();
    this.props.resetFailedPause();
  }

  componentDidUpdate(prevProps) {
    let state = {};
    if (prevProps.location.fetching && prevProps.location.fetching !== this.props.location.fetching) {
      const bcLines = [];
      this.props.bowlingcenters.forEach((elem) => {
        if (this.props.location.countryiso === elem.iso && this.props.location.cityid === elem.city && this.props.location.id === elem.id) {
          for (let i = 0; i < elem.linesstructure.length; i += 1) {
            const { linenumber } = elem.linesstructure[i];
            bcLines.push({ value: linenumber, label: linenumber });
          }
        }
      });
      let selectedLine = null;
      if (!bcLines.length) {
        selectedLine = { label: 'no lines', value: 'no lines' }
      } else if (bcLines.length === 1) {
        selectedLine = bcLines[0];
      }
      state = {
        ...state,
        centerName: this.props.location.name ? { value: this.props.location.name, label: this.props.location.name } : '',
        country: this.props.location.country ? { value: this.props.location.countryiso, label: this.props.location.country } : '',
        city: this.props.location.city ? { value: this.props.location.cityid, label: this.props.location.city } : '',
        line: selectedLine,
      };
    }
    if (prevProps.visible !== this.props.visible) {
      state = {
        ...state,
        visible: this.props.visible,
        waiting: false,
      };
    }
    if (prevProps.bowlingcenters !== this.props.bowlingcenters) {
      this.buildBCList();
    }
    if (Object.keys(state).length > 0) {
      this.setState({ ...state });
    }
  }

  buildBCList = () => {
    let temp = '';
    this.props.bowlingcenters.forEach((elem) => {
      if (elem.id === this.props.users.user.bowlingcenterid) {
        temp = elem.iso;
      }
    });
    this.props.getBowlingCentersCoutry();
    if (temp) {
      this.props.GetCities(temp);
    }
    const countries = [];
    this.props.bowlingcenters.forEach(bc => {
      if (!countries.includes(bc.iso)) {
        countries.push(bc.iso);
      }
    });
    const countryOptions = [];
    countries.forEach(c => {
      const item = this.props.geodata.data.find(country => country.iso == c);
      if (item) {
        countryOptions.push({ label: item.name, value: item.iso })
      }
    });
    this.setState({ firstLoad: true, countryOptions });
  }

  showModal = () => this.setState({ visible: true });

  handleOk = () => {
    this.setState(
      {
        waiting: true,
      },
      () => {
        const {
          country, city, centerName, line, observers,
        } = this.state;

        if (country === '' || city === '' || centerName === '' || line === '') {
          console.log('Bowlingcenters variables is empty');
        } else {
          const { onSubmit, bowlingcenters } = this.props;
          let bcId;
          bowlingcenters.forEach((elem) => {
            if (country.value === elem.iso && city.value === elem.city && centerName.value === elem.name) {
              bcId = elem.id;
            }
          });
          if (bcId) {
            onSubmit({
              bowlingcenterid: bcId,
              country,
              city: city.value,
              name: centerName.value,
              linenumber: line.value,
              observers,
            });
          } else {
            console.log('BowlingCenter is undefined');
          }
        }
      },
    );
  };

  updateConnectionSpeed = () => speedChecker().then(connectionSpeed => this.setState({ connectionSpeed }));

  handleCancel = () => {
    const { onClose } = this.props;
    this.setState({
      visible: false,
    });
    onClose();
  };

  resetBCLane = (callback, ...args) => {
    if (typeof callback === 'function') {
      this.setState({ line: '', waiting: false }, () => callback(...args));
    } else {
      this.setState({ line: '', waiting: false });
    }
  }

  render() {
    const {
      tournament,
      opponent,
      title: titleDefault,
      additionalInfo: additionalInfoDefault,
      declineButtonText: declineButtonTextDefault,
      acceptButtonText: acceptButtonTextDefault,
      titleId,
      additionalInfoId,
      declineButtonTextId,
      acceptButtonTextId,
      bowlingcenters,
      cities,
      intl,
    } = this.props;
    const additionalInfo = intl.formatMessage({ id: additionalInfoId, defaultMessage: additionalInfoDefault });
    const declineButtonText = intl.formatMessage({ id: declineButtonTextId, defaultMessage: declineButtonTextDefault });
    const acceptButtonText = intl.formatMessage({ id: acceptButtonTextId, defaultMessage: acceptButtonTextDefault });
    const title = intl.formatMessage({ id: titleId, defaultMessage: titleDefault });
    const {
      visible, line, country, city, centerName, countryOptions, connectionSpeed,
    } = this.state;

    const tempCitiesOptions = [];
    const tempCentersOptions = [];
    const bcLines = [];
    const pushedCities = [];

    if (country !== '') {
      bowlingcenters.forEach((elem) => {
        if (country.value === elem.iso) {
          cities.data.forEach((el) => {
            if (el.id === elem.city && !pushedCities.includes(el.id)) {
              tempCitiesOptions.push({ value: el.id, label: el.name });
              pushedCities.push(el.id);
            }
          });
        }
      });
    }
    if (city !== '') {
      bowlingcenters.forEach((elem) => {
        if (city.value === elem.city) {
          tempCentersOptions.push({ value: elem.name, label: elem.name });
        }
      });
    }
    if (centerName !== '') {
      bowlingcenters.forEach((elem) => {
        if (country.value === elem.iso && city.value === elem.city && centerName.value === elem.name) {
          for (let i = 0; i < elem.linesstructure.length; i += 1) {
            const { linenumber } = elem.linesstructure[i];
            bcLines.push({ value: linenumber, label: linenumber });
          }
        }
      });
    }

    const TournamentType = (
      <div key="tournamentType" className="start-game__modal-group start-game__tournament-type">
        {tournament.type
          && tournament.type.toLowerCase() === 'battle' && (
            <div>
              <div className="start-game__header">
                {intl.formatMessage({ id: 'TournamentType', defaultMessage: 'Tournament type' })}
              </div>
              <div className="start-game__text">{tournament.type}</div>
            </div>
          )}
        {tournament.type
          && tournament.type.toLowerCase() === 'tournament' && (
            <div>
              <div className="start-game__header">
                {intl.formatMessage({ id: 'Tournament', defaultMessage: 'Tournament' })}
              </div>
              <div className="start-game__text">{tournament.type}</div>
            </div>
          )}
      </div>
    );

    const TournamentInfo = (
      <div key="tournamentInfo" className="start-game__modal-group start-game__tournament-info">
        {tournament.type
          && tournament.type.toLowerCase() === 'battle' && (
            <div>
              <div className="start-game__tournamentinfo">
                <div className="start-game__date">
                  <div className="start-game__header">
                    {intl.formatMessage({ id: 'date_of_battle', defaultMessage: 'Date of battle' })}
                  </div>
                  <div className="start-game__text">
                    {moment(tournament.date)
                      .format('D MMM - h:mm')
                      .toString()}
                  </div>
                </div>
                <div className="start-game__bet">
                  <div className="start-game__header">{intl.formatMessage({ id: 'fee', defaultMessage: 'Fee' })}</div>
                  <div className="start-game__text">
                    {tournament.bet
                      ? `${tournament.currencysymbol || ''}${tournament.bet}`
                      : intl.formatMessage({ id: 'without_bet', defaultMessage: 'Free' })}
                  </div>
                </div>
                <div className="start-game__numOfWins">
                  <div className="start-game__header">
                    {
                      (isSolo(tournament.tournamenttypebrief) && intl.formatMessage({ id: 'games', defaultMessage: 'Games' }))
                      || (tournament.tournamenttypebrief === 'PINFALL_BATTLE' && 'Pinfall')
                      || intl.formatMessage({ id: 'NumberOfWins', defaultMessage: 'Number of wins' })
                    }
                  </div>
                  <div className="start-game__text">{tournament.numOfWins}</div>
                </div>
              </div>
            </div>
          )}
        {tournament.type
          && tournament.type.toLowerCase() === 'tournament' && (
            <div>
              <div className="start-game__name">
                <div className="start-game__header">
                  {intl.formatMessage({ id: 'NameAsIdentifier', defaultMessage: 'Name' })}
                </div>
                <div className="start-game__text">{tournament.name}</div>
              </div>
              <div className="start-game__type">
                <div className="start-game__header">
                  {intl.formatMessage({ id: 'TournamentType', defaultMessage: 'Tournament type' })}
                </div>
                <div className="start-game__text">{tournament.typeOfTournament}</div>
              </div>
            </div>
          )}
        {tournament.prize &&
          <div className='prizeShowcase'>
            {intl.formatMessage({ id: 'PossiblePrize', defaultMessage: 'Possible prize' })} - {tournament.prize}{tournament.currencysymbol || ''}
          </div>}
      </div>
    );

    const GameOpponent = (
      <div key="gameOpponent" className="start-game__modal-group start-game__opponent">
        <Opponent
          userid={opponent.id}
          fullname={opponent.fullname}
          nickname={opponent.nickname}
          flag={opponent.flag}
          photo={opponent.photo}
          rating={+opponent.rating}
          avgscore={(+opponent.avgscore || 0).toFixed()}
          fromGameStart
          clickableLogin={tournament.tournamenttypeid === 3 ? false : true}
        />
      </div>
    );

    const AdditionalInfo = (
      <div key="additionalInfo" className="start-game__modal-group start-game__additional-info">
        {additionalInfo}
      </div>
    );

    const ConnectionSpeedInfo = (
      <div key="connectionSpeedInfo" className="start-game__modal-group star-game__connection-speed-info">
        <span>
          {intl.formatMessage({ id: 'connectionSpeed', defaultMessage: 'Your speed is %s% Mbit/s. Recommended 10 Mbit/s' }).replace('%s%', (connectionSpeed * 8 / (1024 * 1024)).toFixed(1))}
        </span>
        <Button
          size="s"
          onClick={this.updateConnectionSpeed}
          kind="aquamarine"
          className="start-game__button-update-connection-speed"
        >
          {intl.formatMessage({ id: 'update', defaultMessage: 'Update' })}
        </Button>
      </div>
    );

    const UserBowlingCenter = (
      <>
        <div className='start-game__bowlingcenter__usercontainer'>
          <h3>{intl.formatMessage({ id: 'YourBowlingCenter', defaultMessage: 'Your bowling center' })}</h3>
          <p>{this.props.location.country}, {this.props.location.city}, {this.props.location.name}</p>
          {!(bcLines.length > 1) && line && <p>{intl.formatMessage({ id: 'LineNumber', defaultMessage: 'Line number' })} {line.label}</p>}
          <OBModal
            title={intl.formatMessage({ id: 'WifiNetworkRequestTitle', defaultMessage: 'Connect to WiFi network' })}
            visible={(!this.props.location.id || !bcLines.length) && visible}
            width={395}
            onOk={this.props.getBowlingCenterFromLocation}
            onCancel={() => { }}
            closable={false}
            footer={[
              <div key="buttons" className="start-game__buttons">
                <Button
                  kind="aquamarine"
                  bordered="true"
                  onClick={this.handleCancel}
                  className="start-game__button-decline"
                >
                  {intl.formatMessage({ id: 'RefuseAsRespectfullyDisagreeAlias', defaultMessage: 'Refuse' })}
                </Button>
                <Button
                  loading={this.props.location.fetching}
                  loadingtext={intl.formatMessage({ id: 'ConnectivityCheck', defaultMessage: 'Check connectivity' })}
                  onClick={this.props.getBowlingCenterFromLocation}
                  kind="aquamarine"
                  className="start-game__button-accept"
                >
                  {intl.formatMessage({ id: 'ConnectivityCheck', defaultMessage: 'Check connectivity' })}
                </Button>
              </div>,
            ]}
          >
            <div className="wifiNetwork__preambula">
              <img src={WiFiIcon} />
              <p>{intl.formatMessage({ id: 'WifiNetworkPreambulaText', defaultMessage: 'To proceed further you must connect to «TenPinCam.com» or «TenPinCam.com 5Ghz» WiFi network. Playing while using different connection is unavailable.' })}</p>
            </div>
            <div className="wifiNetwork__list">
              <div className="wifiNetwork__item">
                <div className="wifiNetwork__name"><label>{intl.formatMessage({ id: 'WifiNetwork', defaultMessage: 'WiFi network' })}</label><span>TenPinCam.com</span></div>
                <div className="wifiNetwork__pass"><label>{intl.formatMessage({ id: 'password', defaultMessage: 'Password' })}</label><span>TenPinCam</span></div>
              </div>
              <div className="wifiNetwork__item">
                <div className="wifiNetwork__name"><label>{intl.formatMessage({ id: 'WifiNetwork', defaultMessage: 'WiFi network' })}</label><span>TenPinCam.com 5GHz</span></div>
                <div className="wifiNetwork__pass"><label>{intl.formatMessage({ id: 'password', defaultMessage: 'Password' })}</label><span>TenPinCam</span></div>
              </div>
            </div>
            <div className="wifiNetwork__postscriptum">{additionalInfo}</div>
          </OBModal>
        </div>
        {bcLines.length > 1 &&
          <div className="start-game__bowlingcenter__roadblock alt">
            <div className="start-game__bowlingcenter__road">
              {intl.formatMessage({ id: 'SelectLineAsChoosePlaySiteAlias', defaultMessage: 'Select bowling line' })}
            </div>
            <div className="start-game__bowlingcenter__roadselect">
              <Select
                value={line}
                placeholder={intl.formatMessage({ id: 'BowlingCenterLine', defaultMessage: 'Line' })}
                onChange={(e) => {
                  this.setState({
                    line: e,
                  });
                }}
                options={bcLines}
              />
            </div>
          </div>}
      </>
    )

    const AdminBowlingCenters = (
      <>
        <div className="start-game__bowlingcenter__header">
          {intl.formatMessage({ id: 'BowlingCenterSelectPreamubla', defaultMessage: 'Select bowling center' })}
        </div>
        <div className="start-game__bowlingcenter__container">
          <div className="start-game__bowlingcenter__block1">
            <div className="start-game__bowlingcenter__firstselectcontainer">
              <div className="start-game__bowlingcenter__countyselect">
                <div className="start-game__bowlingcenter__name">
                  {intl.formatMessage({ id: 'BowlingcenterFilterByCountry', defaultMessage: 'Country' })}
                </div>
                <Select
                  className="country-select"
                  isSearchable
                  value={country}
                  placeholder={intl.formatMessage({ id: 'BowlingcenterFilterByCountry', defaultMessage: 'Country' })} s
                  onChange={(e) => {
                    this.props.GetCities(e.value);
                    this.setState({
                      country: e,
                      city: '',
                      centerName: '',
                      line: '',
                      firstLoad: false,
                    });
                  }}
                  options={countryOptions}
                />
              </div>
              <div className="start-game__bowlingcenter__cityselect">
                <div className="start-game__bowlingcenter__name">
                  {intl.formatMessage({ id: 'City', defaultMessage: 'City' })}
                </div>
                <Select
                  isSearchable
                  value={city}
                  placeholder={intl.formatMessage({ id: 'City', defaultMessage: 'City' })}
                  onChange={(e) => {
                    this.setState({
                      city: e,
                      centerName: '',
                    });
                  }}
                  options={tempCitiesOptions}
                />
              </div>
            </div>
          </div>
          <div className="start-game__bowlingcenter__block2">
            <div className="start-game__bowlingcenter__nameblock">
              <div className="start-game__bowlingcenter__name">
                {intl.formatMessage({ id: 'NameAsIdentifier', defaultMessage: 'Name' })}
              </div>
              <div className="start-game__bowlingcenter__nameselect">
                <Select
                  isSearchable
                  value={centerName}
                  placeholder={intl.formatMessage({ id: 'NameAsIdentifier', defaultMessage: 'Name' })}
                  onChange={(e) => {
                    bcLines.length = 0;
                    bowlingcenters.forEach((elem) => {
                      if (country.value === elem.iso && city.value === elem.city && e.value === elem.name) {
                        for (let i = 0; i < elem.linesstructure.length; i += 1) {
                          const { linenumber } = elem.linesstructure[i];
                          bcLines.push({ value: linenumber, label: linenumber });
                        }
                      }
                    });
                    this.setState({
                      centerName: e,
                      line: bcLines.length === 1 ? bcLines[0] : '',
                    });
                  }}
                  options={tempCentersOptions}
                />
              </div>
            </div>
            <div className="start-game__bowlingcenter__roadblock">
              <div className="start-game__bowlingcenter__road">
                {intl.formatMessage({ id: 'BowlingCenterLine', defaultMessage: 'Line' })}
              </div>
              <div className="start-game__bowlingcenter__roadselect">
                <Select
                  value={line}
                  placeholder={intl.formatMessage({ id: 'BowlingCenterLine', defaultMessage: 'Line' })}
                  onChange={(e) => {
                    this.setState({
                      line: e,
                    });
                  }}
                  options={bcLines}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );

    const ObserversGroup = ['INDIVIDUAL', 'PINFALL_BATTLE'].includes(tournament.tournamenttypebrief)
      ? (
        <div key='observerGroup' className='observer-group'>
          <div className='observer-group__header'>
            {intl.formatMessage({ id: 'ObserverModeSelector', defaultMessage: 'Observers' })}
          </div>
          <div className='observer-group__elements'>
            <Checkbox
              checked={this.state.observers === 'all'}
              onChange={checked => this.setState({ observers: checked ? 'all' : null })}
            >
              {intl.formatMessage({ id: 'ObserverModeAll', defaultMessage: 'Everyone' })}
            </Checkbox>
            <Checkbox
              checked={this.state.observers === 'friends'}
              onChange={checked => this.setState({ observers: checked ? 'friends' : null })}
            >
              {intl.formatMessage({ id: 'ObserverModeFriends', defaultMessage: 'Friends only' })}
            </Checkbox>
            <Checkbox
              checked={this.state.observers === 'both-friends'}
              onChange={checked => this.setState({ observers: checked ? 'both-friends' : null })}
            >
              {intl.formatMessage({ id: 'ObserverModeOpponentFriends', defaultMessage: 'Friends - my and opponents' })}
            </Checkbox>
          </div>
        </div>)
      : null;

    const groups = [
      GameOpponent,
      TournamentInfo,
      this.props.permissions.includes('PLAY_ANYWHERE') ? AdminBowlingCenters : UserBowlingCenter,
      // ObserversGroup,
      isSolo(tournament.tournamenttypebrief) && AdditionalInfo
    ];

    return (
      <OBModal
        title={title}
        visible={visible}
        width={395}
        onOk={this.handleOk}
        closable={false}
        className="start-battle-modal"
        wrapClassName="start-battle-modal"
        footer={[
          <div key="buttons" className="start-game__buttons">
            <Button
              kind="aquamarine"
              bordered="true"
              onClick={this.handleCancel}
              className="start-game__button-decline"
            >
              {declineButtonText}
            </Button>
            <Button
              disabled={line == '' || !line || country == '' || !country || city == '' || !city || centerName == '' || !centerName}
              loading={this.state.waiting}
              loadingtext={intl.formatMessage({ id: 'WaitingForPlayers', defaultMessage: 'Waiting for players' })}
              onClick={this.handleOk}
              kind="aquamarine"
              className="start-game__button-accept"
            >
              {acceptButtonText}
            </Button>
          </div>,
        ]}
      >
        {groups}
      </OBModal>
    );
  }
}

export default connect(
  state => ({
    users: state.users,
    fetching: state.bowlingcenters.bowlingcenters.fetching,
    bowlingcenters: state.bowlingcenters.bowlingcenters.data,
    location: state.bowlingcenters.bowlingcenters.location,
    bccountry: state.bowlingcenters.country.data,
    geodata: state.common.geodata,
    cities: state.common.cities,
    permissions: state.users.permissions ? state.users.permissions.keys : [],
    isLaneInUse: state.common.code_error.code === 'LANE_IN_USE',
  }),
  {
    getBowlingCenters,
    GetCities,
    getBowlingCentersCoutry,
    getBowlingCenterFromLocation,
    markYellowCardAsSeen,
    resetFailedPause,
    getPermissions,
  },
  null,
  { withRef: true },
)(injectIntl(StartGame, { withRef: true }));
