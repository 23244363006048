import React from 'react';

const QR = require('../../../images/elyt/QR.png');
const ios = require('../../../images/elyt/App Store.png');
const android = require('../../../images/elyt/Android.png');
const huawei = require('../../../images/elyt/App Gallery.png');
const samsung = require('../../../images/elyt/App Gallery-1.png');

const facebook = require('../../../images/elyt/facebook.png');
const instagram = require('../../../images/elyt/instagram.png');

function Footer() {
  const onClickDownload = (type) => {
    if (!window.ym) {
      return;
    }

    const ymKey = 52252669;

    switch (type) {
      case 'ios':
        window.ym(ymKey, 'reachGoal', 'DownloadIOS');
        break;
      case 'android':
        window.ym(ymKey, 'reachGoal', 'DownloadAndroid');
        break;
      case 'huawei':
        window.ym(ymKey, 'reachGoal', 'DownloadHuawei');
        break;
      case 'samsung':
        window.ym(ymKey, 'reachGoal', 'DownloadSamsung');
        break;
      default:
        break;
    }
  }

  return (
    <div className="footer">
      <div className="footer-content">
        <div className="section">
          <div className="section-header">Download the app</div>
          <div className="download-app">
            <img className="QR" src={QR}/>
            <div className="sources">
              <a
                target="blank"
                href="https://apps.apple.com/ru/app/tenpincam/id1481986359?l=en"
                onClick={() => onClickDownload('ios')}
              >
                <img className="source" src={ios}/>
              </a>
              <a
                href="/howtoinstall" // оставляю как есть, т.к. нет картинки для GooglePlay
                onClick={() => onClickDownload('android')}
              >
                <img className="source" src={android}/>
              </a>
              <a
                target="blank"
                href="https://appgallery.huawei.com/#/app/C105386377"
                onClick={() => onClickDownload('huawei')}
              >
                <img className="source" src={huawei}/>
              </a>
              <a
                target="blank"
                href="https://galaxystore.samsung.com/detail/com.tenpincam.telepresence_bowling_1"
                onClick={() => onClickDownload('samsung')}
              >
                <img className="source" src={samsung}/>
              </a>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="section-header">Follow us</div>
          <div className="social">
            <div className="social-row">
              <div>Tenpincam</div>
              <div className="icons">
                <a
                  target="blank"
                  href="https://www.facebook.com/tenpincam/"
                >
                  <img className="icon" src={facebook}/>
                </a>
                <a
                  target="blank"
                  href="https://instagram.com/tenpincam"
                >
                  <img className="icon" src={instagram}/>
                </a>
              </div>
            </div>
            <div className="social-row">
              <div>ETBF</div>
              <div className="icons">
                <a
                  target="blank"
                  href="https://www.facebook.com/ETBF.Official"
                >
                  <img className="icon" src={facebook}/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
