import React, { Component } from 'react'
import currency from './images/icons/currency.png'
import drop from './images/icons/drop.png'
import whistle from './images/icons/whistle.png'
import sword from './images/icons/sword.png'

import './style.scss'
import './adaptive.scss'

export default class LandingRules extends Component {
  render() {
    const { intl } = this.props;

    return <div className='mainrules fullcontainer'>
      {/* <h2>{intl.formatMessage({ id: 'LandingMainRules', defaultMessage: 'Main rules' })}</h2> */}
      <h2>{intl.formatMessage({ id: 'LandingBowlingIsASport', defaultMessage: 'TenPinCam Bowling is a sport' })}</h2>
      <div className='ruleslist'>
        <div className='rule'>
          <div className='imgwrap'><img src={sword}/></div>
          <span>{intl.formatMessage({ id: 'LandingRuleOne', defaultMessage: 'Players play in real time against each other in different bowling alleys.' })}</span>
        </div>
        <div className='rule'>
          <div className='imgwrap'><img src={drop}/></div>
          <span>{intl.formatMessage({ id: 'LandingRuleTwo', defaultMessage: 'Each player plays on his favorite oil pattern.' })}</span>
        </div>
        <div className='rule'>
          <div className='imgwrap'><img src={currency}/></div>
          <span>{intl.formatMessage({ id: 'LandingRuleThree', defaultMessage: 'Free Battles, or Battles with Participation fees & Money prizes' })}</span>
        </div>
        <div className='rule'>
          <div className='imgwrap'><img src={whistle}/></div>
          <span>{intl.formatMessage({ id: 'LandingRuleFour', defaultMessage: 'Players can submit protests. Remote referee will review & submit decision.' })}</span>
        </div>
      </div>
      <div onClick={this.props.showModal} className='btn big filled'>
        {intl.formatMessage({ id: 'LandingMainRules', defaultMessage: 'Main rules' })}
      </div>
    </div>
  }
}