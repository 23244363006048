import openSocket from 'socket.io-client';
import { eventChannel } from 'redux-saga';
import {
  put, call, take, select,
} from 'redux-saga/effects';
import { put as putMethod } from '../Networking';
import { BattleInviteCanceled } from '../reducers/actions';
import config from '../../config';
import {
  SOCKET_UPDATE_FRAMES,
  SQUAD_CHAT_UPDATE,
  SOCKET_UPDATE_EDIT,
  SOCKET_PROTEST_CATCH,
  SOCKET_EVENT_CONF_RESET,
} from '../reducers/squads.reducer';
import { SOCKET_NOTIFICATIONS_SET } from '../reducers/notifications.reducer';
import Utils from '../utils';
import chatSocket from '../ChatSocketInstance';
import { store } from '../../store';
import { SETTINGS_FETCH } from '../reducers/commonReducer';
import { gameInviteRequestAction, setGameInviteModalAction } from '../reducers/gameInvite.reducks';
import { hasExpectedGamesSaga } from '../reducers/gamesExpected.reducks';
import { persistentEmitter } from '../persistentEmitter';
import { userPoolCleanAction } from '../reducers/userPool.reducks';

const { getFramework } = Utils;

const types = require('../reducers/actiontypes').default;

const debugChat = false;
const chatLogger = {
  log: debugChat ? console.log : function(){},
};

let parse;
let DOM;
const framework = getFramework();
switch (framework) {
  case 'ReactJS':
    ({ parse, DOM } = require('xml-parse'));
    break;
  case 'ReactNative':
    console.log('ho-ho-ho');
    break;
  case 'NodeJS':
    console.log('node ho-ho-ho');
    break;
  default:
    console.log('he-he');
}
// import { parse, DOM } from 'xml-parse';

let socket;
const getGame = state => state.events.game;
const getSquad = state => state.squadId;

const dummy = () => {};

const _getChatRooms = async (room, squadId) => {
  const chatsocket = chatSocket.getSocket();
  const roomName = 'default';
  const promise = {
    resolve: dummy,
    reject: dummy,
  };
  const textMessage = room === undefined ? `getRooms|squadId:${squadId}` : `getRoom|roomName:${room}`;
  console.log('getChatRooms:', textMessage);
  chatsocket.emit('roomJoin', roomName, (error) => {
    if (error) {
      return promise.reject(error);
    }
    chatsocket.emit('roomMessage', roomName, { textMessage, socketId: chatsocket.id }, (err, rooms) => {
      if (err) {
        return promise.reject(err);
      }
      promise.resolve(rooms);
    });
    return null;
  });
  const rooms = await new Promise((resolve, reject) => {
    promise.resolve = resolve;
    promise.reject = reject;
  });
  return rooms;
};

const _rpcMessagesSeen = async (room) => {
  const roomName = 'default';
  const chatsocket = chatSocket.getSocket();
  console.log('rpcMessagesSeen.room', room);
  const promise = {
    resolve: dummy,
    reject: dummy,
  };
  chatsocket.emit(
    'roomMessage',
    roomName,
    { textMessage: `updateMessagesSeen|roomName:${room}`, socketId: chatsocket.id },
    (error, count) => {
      if (error) {
        promise.reject(error);
        return;
      }
      promise.resolve(count);
    },
  );
  return new Promise((resolve, reject) => {
    promise.resolve = resolve;
    promise.reject = reject;
  });
};

const _inviteToPrivateRoom = async (userId, command) => {
  if (command !== 'createSupportRoom' && !userId) {
    return Promise.resolve();
  }
  const chatsocket = chatSocket.getSocket();
  const roomName = 'default';
  const promise = {
    resolve: dummy,
    reject: dummy,
  };
  chatsocket.emit(
    'roomMessage',
    roomName,
    { 
      textMessage: command !== 'createSupportRoom' ? `${command}|userid:${userId}` : command,
      socketId: chatsocket.id 
    },
    (error, data) => {
      if (error) {
        return promise.reject(error);
      }
      promise.resolve(data);
    },
  );
  return new Promise((resolve, reject) => {
    promise.resolve = resolve;
    promise.reject = reject;
  });
};

export function* _createSupportRoom() {
  try {
    const roomName = yield call(_inviteToPrivateRoom, null, 'createSupportRoom');
    yield put({ type: types.SET_CUR_ROOM, payload: roomName });
    console.log('creted support room: ', roomName);
  } catch (error) {
    yield put({ type: types.CHAT_ROOM_ERROR, error: error.message || error });
    console.log('create support room -> error:', error);
    // TODO: handle error
  }
}

export function* _createPrivateRoom({ payload: userid }) {
  try {
    const roomName = yield call(_inviteToPrivateRoom, userid, 'createPrivateRoom');
    yield put({ type: types.SET_CUR_ROOM, payload: roomName });
    console.log('creted private room: ', roomName);
  } catch (error) {
    yield put({ type: types.CHAT_ROOM_ERROR, error: error.message || error });
    console.log('create private room -> error:', error);
    // TODO: handle error
  }
}

export function* _fetchChatRoom({ payload: roomName }) {
  try {
    const squadId = yield select(getSquad);
    const observerSquadId = yield select(state => state.observer.squadid);
    const room = yield call(_getChatRooms, roomName, squadId || observerSquadId);
    if (!room) {
      throw new Error(`room (room_name = ${roomName}) is null`);
    }
    yield put({ type: types.SET_CHAT_ROOM, payload: room });
  } catch (error) {
    yield put({ type: types.SET_CHAT_ROOM, error: error.message || error });
  }
}

const _enterRooms = async (rooms) => {
  const chatsocket = chatSocket.getSocket();
  for (const { room_name: roomName } of rooms) {
    chatsocket.emit('roomJoin', roomName);
  }
};

export function* _fetchChatRooms() {
  try {
    const squadId = yield select(getSquad)
    const rooms = yield call(_getChatRooms, undefined, squadId);
    yield put({ type: types.SET_CHAT_ROOMS, payload: rooms });
    yield call(_enterRooms, rooms);
  } catch (error) {
    yield put({ type: types.SET_CHAT_ROOMS, error: error.message || error });
  }
}

export function* _updateMessagesSeen({ payload: roomName }) {
  try {
    const count = yield call(_rpcMessagesSeen, roomName);
    console.log('updateMessagesSeen', { count, roomName });
    yield put({ type: types.SET_MESSAGES_TOTAL, payload: { roomName, count } });
    yield put({ type: types.SET_MESSAGES_SEEN, payload: { roomName, count } });
  } catch (error) {
    yield put({ type: types.SET_MESSAGES_SEEN, error: error.message || error });
  }
}

export function _ConnectToSocket(params) {
  if (socket) {
    socket.close();
    if (typeof socket.destroy === 'function') {
      socket.destroy();
    }
    socket = null;
  }
  let socketUri;
  if (framework === 'ReactNative') {
    socketUri = (config.socketSecure ? 'wss' : 'ws') + '://' + config.FETCH.socketUrl;
  } else {
    socketUri = config.FETCH.socketUrl;
  }

  socket = openSocket(socketUri, {
    secure: !!config.socketSecure,
    rejectUnauthorized: false,
    reconnection: true,
    reconnectionDelay: 2000,
    transports: ['websocket'],
  });

  const { id: userid, sessionkey } = params.params;

  chatSocket.initUser({ userid, sessionkey });
  if (!chatSocket.isConnected()) {
    chatSocket.disconnect();
    chatLogger.log('chatsocket not connected, start connection...');
    chatSocket.connect();
  } else {
    chatLogger.log('chatsocket is already connected');
  }
  const chatsocket = chatSocket.getSocket();
  return eventChannel((emitter) => {
    chatLogger.log('chatsocket.isEqual: ', chatsocket === chatSocket.getSocket(), [typeof chatsocket, typeof chatSocket.getSocket()]);
    chatLogger.log('chatsocket.authorized: ', chatSocket.authorized);
    chatsocket.on('loginConfirmed', () => {
      chatLogger.log('chatsocket.loginConfirmed');
      chatSocket.authorized = true;
      emitter({ type: types.CHAT_SOCKET_AUTH, params: true });
      emitter({ type: types.FETCH_CHAT_ROOMS });
    });
    chatsocket.on('loginRejected', () => {
      chatSocket.authorized = false;
      chatLogger.log('chatsocket.loginRejected');
      emitter({ type: types.CHAT_SOCKET_AUTH, params: false });
    });
    chatsocket.on('roomJoinedEcho', async (roomName) => {
      // await _handleRoomJoined(emitter, roomName);
      console.log('roomJoinedEcho', roomName);
      emitter({ type: types.FETCH_CHAT_ROOM, payload: roomName });
    });
    chatsocket.on('roomMessage', (roomName, message) => {
      emitter({
        type: types.SET_MESSAGES_TOTAL,
        payload: {
          roomName,
          count: message.id,
          isSystem: +message.authorId == 0
        },
      });
      emitter({
        type: types.SET_MESSAGES_LAST,
        payload: {
          roomName,
          message,
        },
      });
    });
    socket.on('connect', () => {
      console.log('SOCKET CONNECTED');
      socket.emit('auth', params.params);
      persistentEmitter.emit('SOCKET_CONNECT', { socketid: socket.id })
    });
    socket.on('disconnect', (error) => {
      emitter({ type: types.SOCKET_EVENT_SOCKET_CONNECTED, params: false });
      console.log('SOCKET DISCONNECT: ',error)
      socket.io.reconnect();
      persistentEmitter.emit('SOCKET_DISCONNECT', { reason: error })
    });
    socket.on('connect_error', (error) => {
      emitter({ type: types.SOCKET_EVENT_SOCKET_CONNECTED, params: false });
      console.log('SOCKET ERROR CONNECT ', error);
      socket.io.reconnect();
    });
    socket.on('connect_timeout', (reason) => {
      console.log('SOCKET CONNECT TIMEOUT: ', reason);
    });
    socket.on('reconnect_failed', (reason) => {
      console.log('SOCKET RECONNECT FAILED: ', reason);
    })
    socket.on('user-ping', (data) => {
      socket.emit('user-pong', data)
    })
    socket.on('event', (data) => {
      let type = '';
      if (data.action === 'notification') {
        console.warn('notification');
        if (framework === 'ReactNative') {
          const match = data.params.message.match(/<type>(.*)<\/type>/);
          if (match) type = match[1];
          console.warn('RN', type);
        } else if (DOM){
          const notification = new DOM(parse(data.params.message));
          type = notification.document.getElementsByTagName('type')[0].childNodes[0].text;
        }
      }
      switch (data.action) {
        case 'logout':
          emitter({ type: 'USER_LOGOUT' });
          break;
        case 'exit':
          emitter({ type: 'USER_EXIT' });
          break;
        case 'userDeleted':
          emitter(userPoolCleanAction(data.params && data.params.userid))
          break;
        case 'gameInvite':
          console.log('gameInvite: ', data);
          const gameInviteAction = { type: types.SOCKET_EVENT_GAME_INVITE, params: data.params };
          emitter(gameInviteRequestAction(gameInviteAction))
          // emitter({ type: types.SOCKET_EVENT_GAME_INVITE, params: data.params });
          break;
        case 'gameCancel':
          console.log('gameCancel: ', data);
          // emitter({ type: types.SOCKET_EVENT_GAME_CANCEL, params: data.params });
          // Окончательно отменяем баттл только после сокетного события Отмены баттла
          emitter({ type: types.SOCKET_EVENT_GAME_CANCEL, params: { ...data.params, gameCancelSocket: false } });
          emitter({ type: types.UPDATE_USER_DATA });
          break;
        case 'gameStart':
          console.log('gameStart: ', data);
          emitter({ type: types.SOCKET_EVENT_GAME_START, params: data.params });
          break;
        case 'frameChange':
          console.log('frameChange: ', data);
          emitter({ type: SOCKET_UPDATE_EDIT, params: data.params });
          break;
        case 'updateProtest':
          console.log('opponentProtest: ', data);
          emitter({ type: SOCKET_PROTEST_CATCH, params: data.params });
          break;
        case 'confirmationReset':
          console.log('confirmationReset');
          emitter({ type: SOCKET_EVENT_CONF_RESET });
          break;
        case 'gameEnding':
          console.log('gameEnding: ', data);
          emitter({ type: types.SOCKET_EVENT_GAME_ENDING });
          break;
        case 'gameDraw':
          console.log('gameDraw', data);
          emitter({ type: types.SOCKET_EVENT_GAME_DRAW, params: data.params.score });
          break;
        case 'gameNext':
          console.log('gameNext', data);
          emitter({ type: types.SOCKET_EVENT_GAME_NEXT, params: { squadId: data.params.squadid } });
          break;
        case 'gameEnd':
          console.log('gameEnd: ', data);
          emitter({ type: types.SOCKET_EVENT_GAME_END, params: data.params });
          break;
        case 'chatMessage':
          console.log('squadChatMessage: ', data);
          emitter({ type: SQUAD_CHAT_UPDATE, params: { ...data, fromServer: true } });
          break;
        case 'supportMessage':
          console.log('supportMessage: ', data);
          break;
        case 'gameStatus':
          console.log('gameStatus: ', data);
          emitter({ type: types.SOCKET_EVENT_GAME_STATUS, params: data.params });
          break;
        case 'notification':
          console.log(data);
          emitter({ type: types.SOCKET_EVENT_NOTIFICATION, params: data.params });
          emitter({ type: SOCKET_NOTIFICATIONS_SET, data: data.params });
          if (
            [
              'gameInvite',
              'gameAccept',
              'battleCancelOwner',
              'battleCancelCompetitor',
              'battleExitOwner',
              'battleExitCompetitor',
              'decline',
              'cancelTournamentBattle',
            ].includes(type)
          ) {
            emitter({ type: types.TOURNAMENTS_FETCH });
            emitter({ type: types.BATTLES_FETCH });
            emitter({ type: types.OPENCHALLENGES_FETCH});
          } else if (
            [
              'noFundNotification',
            ].includes(type)
          ) {
            emitter({ type: types.SHOW_NOFUND });
          } else if (
            [
              'refereeingDone',
              'refereeingWinNotification',
              'refereeingFailNotification',
              'technicalVictory',
              'PaymentUpdate',
            ].includes(type)
          ) {
            emitter({ type: types.UPDATE_USER_DATA });
          } else if ('newVersion' === type) { // если пришло уведомление о том что вышла новая версия
            emitter({ type: SETTINGS_FETCH }); // запрашиваем настройки с номер новой версии
            emitter({ type: types.MOBILE_VERSION_FETCH }); // запршиваем номер версии Apk файла на сервере
          }
          socket.emit('request', {
            ...data,
            action: 'notificationView',
            userid: [0],
          });
          break;
        case 'gameCancelOpponent':
          emitter({ type: types.OPPONENT_GAME_REFUSE, params: true, tournament: data.params });
          break;
        case 'needFetchOpenChallenges':
          emitter({ type: types.TOURNAMENTS_FETCH });
          emitter({ type: types.BATTLES_FETCH });
          emitter({ type: types.OPENCHALLENGES_FETCH});
          break;
        case 'newBattles':
          console.log(data);
          //emitter({ type: types.SOCKET_EVENT_NEW_BATTLES, params: true });
          emitter({ type: types.TOURNAMENTS_FETCH });
          emitter({ type: types.BATTLES_FETCH });
          emitter({ type: types.SQUAD_LIST_FETCH });
          break;
        case 'gameOpponentConfirm':
          console.log(data);
          emitter({ type: types.SOCKET_EVENT_RESULTCONFIRM, params: data.params });
          break;
        case 'setPause':
          console.log(data);
          emitter({ type: types.SOCKET_EVENT_SET_PAUSE, params: data.params });
          break;
        case 'raisePause':
          console.log(data);
          emitter({ type: types.SOCKET_EVENT_RAISE_PAUSE, params: data.params });
          break;
        case 'failedPause':
          console.log(data);
          emitter({ type: types.SOCKET_EVENT_FAILED_PAUSE, params: data.params });
          break;
        case 'yellowCard':
          console.log(data);
          emitter({ type: types.SOCKET_YELLOW_CARD, params: { ...data.params, useridFromRedux: userid}});
          break;
        case 'redCard':
          console.log(data);
          emitter({ type: types.SET_RED_CARD, params: { ...data.params, useridFromRedux: userid } })
          break;
        case 'joinedAsObserver':
          emitter({ type: types.SET_JOIN_AS_OBSERVER, params: data.params });
        case 'disconnect':
          if (data.reason = 'connection limit exceeded') {
            // показать модалку о закрытии сессии
            emitter({
              type: types.SET_SESSION_EVENT,
              payload: {
                closed: true,
                reason: data.reason,
                visible: true,
              },
            });
            // закрыть сессию
            emitter({ type: types.USER_LOGOUT });
          }
          break;
        case 'auth-success':
          emitter({ type: types.SOCKET_EVENT_SOCKET_CONNECTED, params: true });
          break;
        case 'ObserverUpdateFrame':
          emitter({ type: types.OBSERVER_UPDATE_FRAME, params: data.params });
          break;
        case 'joinedAsPlayer':
          emitter({ type: types.SET_JOIN_AS_PLAYER, params: data.params });
          break;
        case 'GameStateUpdate':
          emitter({ type: types.SET_SQUAD_STATE, params: data.params });
          break;
        case 'SquadWatcherUpdate':
          emitter({ type: types.SET_SQUAD_WATCHER_COUNT, params: data.params });
          break;
        case 'ShowHelpModalTimeout':
          emitter({ type: types.SET_SHOW_HELP_TIMEOUT_MODAL, params: true });
          break;
        case 'TournamentGameEnded':
          emitter({ type: types.TOURNAMENT_GAME_ENDED, params: data.params });
          break;
        case 'UpdateTournamentInfo':
          emitter({ type: types.REFRESH_TOURNAMENT_INFO, params: data.params });
          break;
        case 'LastFrameUpdated':
          emitter({ type: types.LAST_FRAME_UPDATED, params: data.params });
          break;
        case 'WatchersQueuePosition':
          emitter({ type: types.QUEUE_POSITION_UPDATED, params: data.params });
          break;
        case 'PassUserToWatch':
          emitter({ type: types.PASS_USER_TO_WATCH, params: data.params });
          break;
        case 'EndGameKickObserver':
          emitter({ type: types.END_GAME_KICK_OBSERVER, params: true });
          break;
        case 'PlayersConnected':
          emitter({ type: types.PLAYERS_CONNECTED, params: true });
          break;
        default:
          console.warn('Unknown event', data);
      }
    });
    socket.on('testMessage', (data) => {
      emitter({ type: types.SOCKET_EVENT, data });
    });
    return unSubscribe;
  });
}

// unsubscribe function
export function unSubscribe() {
  // connection.close();
  console.log('Socket off');
}


export function* _socketSaga(params) {
  const channel = yield call(_ConnectToSocket, params);
  while (true) {
    const channelAction = yield take(channel);
    if (channelAction.type === types.SOCKET_EVENT_GAME_INVITE) {
      const { id: tournamentId, squadid } = channelAction.params.tournament;
      const game = yield select(getGame);
      const { invite, squadid: squadId } = game;
      // Не создавался батл при создании турниира со своим участием
      if (invite && squadid) {
        yield put(BattleInviteCanceled({ tournamentId, squadid, closeModal: false }));
        continue; // eslint-disable-line
      }
    }
    yield put(channelAction);
    // TODO: unsubscribe logout
  }
}

export function _SocketEmit(action) {
  const { params } = action;
  console.log('Socket func: ', action);
  socket.emit(params.eventName, params.params);
}

export function _SocketDisconnect() {
  if (socket && socket.disconnect) {
    socket.disconnect();
  }
  chatSocket.disconnect();
}

export function* _GameAccept(params) {
  try {
    const sendParams = {
      url: `${config.FETCH.url}/battle/confirm`,
      data: { tournamentid: params.params },
    };
    const result = yield call(putMethod, sendParams);
    if (result.status === 200) {
      console.log('accepted');
    }
  } catch (error) {
    // RogRocket.captureException('Game accept: ', error);
    console.error('Game accept: ', error);
  }
}

export function* _GameLeave(params) {
  try {
    yield call(_SocketEmit, {
      params: {
        eventName: 'request',
        params: {
          action: 'gameExit',
          userid: [0],
          params: {
            tournamentid: params.params.tournamentid,
            squadid: params.params.squadid,
          },
        },
      },
    });
  } catch (error) {
    console.log('Game Leave: ', error);
  }
}

export function* _GameAcceptedResults(params) {
  try {
    yield call(_SocketEmit, {
      params: {
        eventName: 'request',
        params: {
          action: 'gameConfirm',
          userid: [0],
          params: params.params,
        },
      },
    });
  } catch (error) {
    console.log('Game Accepted: ', error);
  }
}

export function* _SendProtest(params) {
  try {
    yield call(_SocketEmit, {
      params: {
        eventName: 'request',
        params: {
          action: 'frameProtest',
          userid: [0],
          params: params.params,
        },
      },
    });
  } catch (error) {
    console.log('Frame Protest: ', error);
  }
}

export function* _SendProtestReport(params) {
  try {
    yield call(_SocketEmit, {
      params: {
        eventName: 'request',
        params: {
          action: 'protestCancel',
          userid: [0],
          params: { id: params.params.id, frameid: params.params.frameid },
        },
      },
    });
  } catch (error) {
    console.log('Frame ProtestReport: ', error);
  }
}

export function* _joinAsObserver({ params }) {
  try {
    yield call(_SocketEmit, {
      params: {
        eventName: 'request',
        params: {
          action: 'joinAsObserver',
          userid: [0],
          params: { ...params, newapi: true },
        },
      },
    })
  } catch (error) {
    console.log('Join as observer: ', error);
  }
}

export function* _joinAsPlayer({ params }) {
  try {
    yield call(_SocketEmit, {
      params: {
        eventName: 'request',
        params: {
          action: 'joinAsPlayer',
          userid: [0],
          params,
        },
      },
    })
  } catch (error) {
    console.log('Join as player: ', error);
  }
}

export function* _exitGameObserver({ params }) {
  try {
    yield call(_SocketEmit, {
      params: {
        eventName: 'request',
        params: {
          action: 'exitGameObserver',
          userid: [0],
          params: { ...params, newapi: true },
        },
      },
    })
  } catch (error) {
    console.log('Exit Observer error: ', error);
  }
}

export { socket };
