import React from 'react';
import { Drawer, Dropdown, Menu } from 'antd';
import SelectLang from '../selectLang';
import { Button } from '../../common/Button';

import 'antd/lib/drawer/style/index.css';
import 'antd/lib/dropdown/style/index.css';
import './style.scss';

import AppleLogo from '-!svg-react-loader!../images/apple-logo-menu.svg'; // eslint-disable-line
import AndroidLogo from '-!svg-react-loader!../images/android-logo-menu.svg'; // eslint-disable-line
import HuaweiLogo from '-!svg-react-loader!../images/huawei-logo-menu.svg'; // eslint-disable-line
import SamsungLogo from '-!svg-react-loader!../images/samsung-logo-menu.svg'; // eslint-disable-line

import AppStoreBtn from '../../../images/appstore.png';
import GooglePlayBtn from '../../../images/googleplay.png';
import AppGalleryBtn from '../../../images/appgallery.png';
import GalaxyStoreBtn from '../../../images/galaxystore.png';

const CloseIcon = ({
  onClick = () => {}
}) => (
  <div onClick={onClick} className="close-icon">
    <div />
    <div />
  </div>
);

const MobileMenu = ({
  visible = false,
  intl,
  onClose = () => {},
  scrollToEquipmentForm,
  scrollToAlleyList,
  scrollToApplicationShowCase,
    switchInvestorModalVisible,
}) => {
  const links = [
    {
      name: intl.formatMessage({
        id: 'LandingTenPinCamEquipment',
        defaultMessage: 'TENPINCAM EQUIPMENT',
      }),
      onClick() {
        if (typeof scrollToEquipmentForm === 'function') {
          scrollToEquipmentForm();
        } else {
          window.location.replace('/#form_equipment');
        }
        onClose();
      },
    },
    {
      name: intl.formatMessage({
        id: 'LandingAlleysTitle',
        defaultMessage: 'List of Alleys',
      }),
      onClick() {
        if (typeof scrollToAlleyList === 'function') {
          scrollToAlleyList();
        } else {
          window.location.replace('/#list_alleys');
        }
        onClose();
      },
    },
    {
      name: intl.formatMessage({
        id: 'HowToPlay',
        defaultMessage: 'How to play',
      }),
      onClick() {
        if (typeof scrollToApplicationShowCase === 'function') {
          scrollToApplicationShowCase();
        } else {
          window.location.replace('#how_to_play');
        }
        onClose();
      }
    }
  ];
  const appLinks = [
    {
      btn: AppStoreBtn,
      link: 'https://apps.apple.com/ru/app/tenpincam/id1481986359?l=en',
    },
    {
      btn: GooglePlayBtn,
      link: 'https://play.google.com/store/apps/details?id=com.tenpincam.telepresence_bowling_1',
    },
    {
      btn: AppGalleryBtn,
      link: 'https://appgallery.huawei.com/#/app/C105386377',
    },
    {
      btn: GalaxyStoreBtn,
      link: 'https://galaxystore.samsung.com/detail/com.tenpincam.telepresence_bowling_1',
    },
  ];

  const renderDropDownMenu = (
    <div className="drawer-menu">
      {appLinks.map((appLink, index) => (
        <div key={index}>
          <a
            target="blank"
            href={appLink.link}
          >
            <img className="app-link__btn" src={appLink.btn} />
          </a>
        </div>
      ))}
    </div>
  );

  return (
    <Drawer
      visible={visible}
      onClose={onClose}
      closable={false}
      width={230}
      drawerStyle={{
        backgroundColor: '#151E2B',
      }}
      bodyStyle={{
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 22,
        paddingBottom: 20,
        height: '100%',
        boxSizing: 'border-box',
      }}
    >
      <div className="drawer">
        <div className="drawer-top">
          <div className="drawer__header">
            <CloseIcon onClick={onClose} />
            <SelectLang />
          </div>
          <div className="drawer__links">
            <div className={'link active'} onClick={() => {
              onClose()
              switchInvestorModalVisible()
            }}>
              FOR INVESTORS
            </div>
            {links.map((link, index) => (
              <div className="link" key={index} onClick={link.onClick}>{link.name}</div>
            ))}
          </div>
          <a href='/howtoinstall' onClick={onClose}>
            <div className={`link${(window.location.pathname.includes('/howtoinstall') && ' active') || ''}`}>
              HOW TO INSTALL THE APP
            </div>
          </a>
        </div>
        <div className="drawer-bottom">
          <Dropdown trigger={['click']} overlay={renderDropDownMenu}>
            <div className="drawer__btn-download">
              {intl.formatMessage({
                id: 'DownloadMobileApp',
                defaultMessage: 'Download mobile app',
              })}
            </div>
          </Dropdown>
          <a href="/signin">
            <div className="drawer__btn-signin">
              {intl.formatMessage({
                id: 'SignIn',
                defaultMessage: 'Sign in',
              })}
            </div>
          </a>
        </div>
      </div>
    </Drawer>
  );
};

export default MobileMenu;
