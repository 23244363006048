import React from 'react';
import '../syle.scss'
import {Radio} from "antd";

const ReferralFilterRadio = ({value, setValue, buttonArray}) => {
    return (
        <div className={'referral-table__radio-container'}>
            <Radio.Group
                value={value}
                className='referral-table__radio'
                onChange={(e) => {
                    setValue(e.target.value)
                }}
            >
                {buttonArray.map(button => <Radio.Button key={button} value={button}>{button}</Radio.Button>)}
            </Radio.Group>
        </div>
    );
};

export default ReferralFilterRadio;
