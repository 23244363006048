import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Button } from '../../../Button';
import OBModal from '../../../Modal';
import Checkbox from '../../../Checkbox';
import Utils from '../../../../../libs/utils';
import Opponent from '../../../Opponent';
import defaultAvatar from './images/default.png';
import config from '../../../../../config';
// import Flag from '../../../../Opponent/Flag';
import moment from 'moment';

import '../style.scss';
import { UserByIdFetch, UserSearchFetch } from '../../../../../libs/reducers/actions';
class FeedbackModal extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
    }
    constructor(props) {
        super(props);
        this.state = {
            visible: props.visible,
            isopen: false,
            suppress: false,
            loading: false,
        }
    }
    showModal = () => this.setState({ visible: true });
    componentDidMount() {
        if (this.props.params) {
          this.props.UserByIdFetch({ userid: this.props.params.competitor.userid, noModal: true });
        }
      }

    handleCancel = () => {
        const { onClose } = this.props;
        this.setState({
          visible: false,
          ratingsopen: false,
        });
        onClose();
    };
onRatingChange = (isopen) => {
    this.setState({ feedbackisopen: isopen });
};
render() {
    const {
      intl,
      ratings,
      title,
    } = this.props;
    const poly = "M14 1.12978L17.6661 8.55816L17.7825 8.79389L18.0426 8.83169L26.2403 10.0229L20.3084 15.8051L20.1202 15.9886L20.1646 16.2476L21.5649 24.4122L14.2327 20.5574L14 20.4351L13.7673 20.5574L6.43507 24.4122L7.83541 16.2476L7.87984 15.9886L7.69161 15.8051L1.75969 10.0229L9.9574 8.83169L10.2175 8.79389L10.3339 8.55816L14 1.12978Z";
    const { visible } = this.state;

return(
                <div className='modal-user_rating'>
                  {ratings && ratings.length ?
                  ratings.map((rate) => {return (
                    <div className='modal-user_singlerate'>
                      <div className='preambula'>
                        <div className="user">
                          <img className="user__photo" src={rate.createdbyavatar ? `${config.FETCH.img}/${rate.createdbyavatar}` : defaultAvatar} />
                          {rate.createdbycountry && (
                            <div className="user__flag_container">
                              <div className="user__flag">
                                {/* <Flag name={rate.createdbycountry} /> */}
                              </div>
                            </div>
                          )}
                          <div className="user__name">
                            <span className="fullname">{rate.createdbyusername}</span>
                            <span className="nickname">{rate.createdbyuserlogin}</span>
                          </div>
                        </div>
                        <div className='daterate'>
                          <span>{moment(rate.created).format('DD.MM.YY')}</span>
                          <div className="stars">
                          {
                            [1, 2, 3, 4, 5].map(num => {
                              let fill = 'none';
                              if (rate.rating >= num) {
                                fill = '#FFB31E';
                              }
                              return <svg width="14" height="14" viewBox="0 0 28 28" fill={fill} xmlns="http://www.w3.org/2000/svg">
                                      <path d={poly} stroke="#FFB31E"/>
                                    </svg>
                            })
                          }
                          </div>
                        </div>
                      </div>
                      {rate.comment ?
                        [<span key='label' className='ratelabel'>{intl.formatMessage({ id: 'Feedback', defaultMessage: 'Feedback' })}</span>,
                         <span key='content' className='content'>{rate.comment}</span>]
                      :null}
                    </div>
                  )})
                  : null}
                </div>
      );
    }}
export default injectIntl(
    connect(
        state => ({
        timezones: state.common.timezones,
        geodata: state.common.geodata,
        client: state.users.user,
        ratings: ((state.users.userById||{}).data||{}).ratings,
      }),
    )(FeedbackModal),
);