export const initialModalPremiumState = {
  visible: false
}

export const premiumTournamentAccessRestrictedAction = () => ({ type: 'PREMIUM_TOURNAMENT_ACCESS_RESTRICTED' })
export const premiumTournamentAccessRestrictedModalCloseAction = () => ({ type: 'PREMIUM_TOURNAMENT_ACCESS_RESTRICTED_CLOSE_MODAL' })

export const ModalPremiumReducer = (state = initialModalPremiumState, action) => {
  switch (action.type) {
    case 'PREMIUM_TOURNAMENT_ACCESS_RESTRICTED':
      return {
        ...state,
        visible: true,
      }
    case 'PREMIUM_TOURNAMENT_ACCESS_RESTRICTED_CLOSE_MODAL':
      return {
        ...state,
        visible: false,
      }
    default:
      return state
  }
}
