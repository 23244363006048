import React, { Component } from 'react';
import url from 'url';
import { Button } from '../common/Button';

export default class AuthErrorPage extends Component {
    render() {
        const { query } = url.parse(window.location.href, true);
        const keys = Object.keys(query);
        return (<div style={{ margin: 10 }}>
            <div><code>Authorization error</code></div>
            {keys.filter(key => !!key).map(key => (<div><code>{key}: {query[key]}</code></div>))}
            <Button style={{ width: 300, marginTop: 10, fontWeight: 600 }} onClick={this.onClick} children="Go to Login Page"/>
        </div>)
    }
    onClick = () => window.location.href = '/login';
}
