import React from 'react';
import Member from './memberCard';

const cometee = [
  {
    fullname: 'Marios Nicolaides',
    role: 'Coordinator',
    email: 'mn@etbf.eu',
    organization: 'ETBF',
    image: require('../../../../images/elyt/cometee/mn.png'),
  },
  {
    fullname: 'Kirill Hudjakov',
    role: 'Tournament Manager',
    email: 'kirill.h@tenpincam.com',
    organization: 'TenPinCam',
    image: require('../../../../images/elyt/cometee/kh.png'),
  },
  {
    fullname: 'Karina Maslova',
    role: 'Head referee',
    email: 'karina.maslova@tenpincam.com',
    organization: 'TenPinCam',
    image: require('../../../../images/elyt/cometee/km.png'),
  },
  {
    fullname: 'Olesya Voronina',
    role: 'Marketing manager',
    email: 'pr@tenpincam.com',
    organization: 'TenPinCam',
    image: require('../../../../images/elyt/cometee/ov.png'),
  },
];

function Cometee() {
  return (
    <div className="cometee">
      {cometee.map((elem, index) => <Member
        key={index}
        fullname={elem.fullname}
        role={elem.role}
        email={elem.email}
        organization={elem.organization}
        image={elem.image}
      />)}
    </div>
  )
}

export default Cometee;