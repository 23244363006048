import React from 'react';
import config from '../../../../../config'
import photo from '../../../../../images/defaultAvatar.png'
import '../../syle.scss'

const LoginTD = ({photo, login}) => {
    let photoUrl = `${config.FETCH.img}/${photo}`

    if (!photo) photoUrl = `${config.FETCH.img}/static/media/defaultAvatar.2d4c81eb.png`
    return (
        <div className={'referral-table__login-td'}>
            <img src={photoUrl} alt={'photo'} />
            <span>{login}</span>
        </div>
    );
};

export default LoginTD;
