import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import {
  UserLogout,
  ChangeLang,
  getPermissions,
  getUserTariff,
  fetchUserDisk,
} from '../libs/reducers/actions';
import UserInfoPanel from './userInfoPanel';
import config from '../config';
import { ActiveGame, PendingGame } from './common/GameCards';
import OBModal from './common/Modal';
import { Button } from './common/Button';
import { GetFriends } from '../libs/reducers/FriendsReducer';
import BattleInfoPanel from './battleInfoPanel';

import bg3 from '../images/bg3.png';
import logo from '../images/Logo1.png';

class CustomHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      login: '',
      textEnterButton: 'Login',
      textRegButton: 'Registration',
      userPhoto: '',
      squadId: null,
      versionModal: false,
    };
  }

  static propTypes = {
    logged: PropTypes.bool,
    _logout: PropTypes.func,
    login: PropTypes.string,
    intl: intlShape,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.user && prevState.login !== nextProps.users.user.login) {
      return {
        firstname: nextProps.users.user.firstname,
        lastname: nextProps.users.user.lastname,
        login: nextProps.users.user.login,
        userPhoto: nextProps.users.user.photo,
        balance: nextProps.users.user.currencies,
      };
    }
    return null;
  }

  componentDidMount() {
    if (this.props.users.user) {
      this.props._getUserTariff();
    }
    this.props._fetchUserDisk();
    if (this.props.users.reg) {
      window.location.href = '/postregdata';
    } else {
      this.props._getFriends();
      this.props._getPermissions();
    }
  }

  componentDidUpdate(oldProps, oldState) {
    const {
      game, users, squadId,
    } = this.props;
    if (oldProps.users.user !== users.user && oldProps.users.user.id !== users.user.id) {
      this.props._getUserTariff();
    }
    const temp = game.squadid || users.user.squadid || squadId;
    if (oldState.squadId !== temp) {
      this.setState({
        squadId: temp,
      });
    }
    if (this.props.users.reg) {
      window.location.href = '/postregdata';
    }
  }

  logout = (e) => {
    e.preventDefault();
    this.props._logout();
  };

  render() {
    const {
      game, users, squad, modaltime, intl, lang, squads,
    } = this.props;
    const opponent = {};
    if (!_.isEmpty(squad)) {
      opponent.cost = squad.tournamentcost;
      opponent.tournamentstart = squad.tournamentstart;
      squad.players.forEach((player) => {
        if (player.userid !== users.user.id) {
          opponent.login = player.login;
          opponent.photo = player.photo;
          opponent.fullname = `${player.firstname} ${player.lastname}`;
          opponent.flag = player.country_live;
        }
      });
    }

    const gameInfo = {};
    const tournament = game.gamedata.tournament;
    if (!_.isEmpty(game.gamedata) && tournament) {
      gameInfo.cost = tournament.cost;
      gameInfo.tournamentstart = tournament.datestart
        ? +moment(tournament.datestart).format('x')
        : +new Date();
      if (game.gamedata.players) {
        game.gamedata.players.forEach((player) => {
          if (player.id !== users.user.id) {
            gameInfo.login = player.login;
            gameInfo.photo = player.photo;
            gameInfo.fullname = `${player.firstname} ${player.lastname}`;
            gameInfo.flag = player.country_live;
            gameInfo.avgscore = (+player.avgscore || 0).toFixed();
          }
        });
      }
    }

    let friendBadge = this.props.friends.data && this.props.friends.data.filter(friend => !friend.confirmed && friend.ordertype === 'in').length;
    if (friendBadge) {
      // friendBadge = <div className='friend-badge'>{friendBadge}</div>;
    } else {
      friendBadge = null;
    }
    return (
      <div style={{     
        backgroundImage: `url(${bg3})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat', 
      }}>
        {this.props.logged ? (
          <div>
            <div className="usersHeader">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <NavLink to="/lobby" className="headerLink">
                  {intl.formatMessage({ id: 'lobby', defaultMessage: 'Play' })}
                </NavLink>
                <NavLink isActive={() => false} to="/" className="headerLink">
                  {intl.formatMessage({ id: 'landing', defaultMessage: 'Landing' })}
                </NavLink>
                <NavLink to="/tournaments" className="headerLink">
                  {intl.formatMessage({ id: 'tournaments', defaultMessage: 'Tournaments' })}
                </NavLink>
                {
                  this.props.users && this.props.users.user.referee ?
                  <NavLink to="/referee" className="headerLink">
                    {intl.formatMessage({ id: 'RefereeTabName', defaultMessage: 'Refereeing' })}
                  </NavLink>
                  : null
                }
                <NavLink to="/account" className="headerLink">
                  {intl.formatMessage({ id: 'my_account', defaultMessage: 'My account' })}
                </NavLink>
                {!!this.props.users
                && ((
                  !!this.props.users.permissions && Array.isArray(this.props.users.permissions.keys) && this.props.users.permissions.keys.includes('WATCH_ALL_BOWLINGCENTERS')
                ) || (
                  !!this.props.users.bowlingCenterPermissions && Array.isArray(this.props.users.bowlingCenterPermissions.ids) && !!this.props.users.bowlingCenterPermissions.ids.length
                ))
                && (<NavLink to="/bowlingcenters" className="headerLink">
                  {intl.formatMessage({ id: 'MyAccountRouterBowlingCentersTitle', defaultMessage: 'Bowling Centers' })}
                </NavLink>)}
                <NavLink to="/about" className="headerLink">
                  {intl.formatMessage({ id: 'AboutApplication', defaultMessage: 'About application' })}
                </NavLink>
                {/* <NavLink to="/settings" className="headerLink">
                  {intl.formatMessage({ id: 'settings', defaultMessage: 'Settings' })}
                </NavLink> */}
                {/*<NavLink to="#" className="headerLink">
                  {intl.formatMessage({ id: 'archive', defaultMessage: 'Archive' })}
                </NavLink>
                <NavLink to="#" className="headerLink">
                  {intl.formatMessage({ id: 'settings', defaultMessage: 'Settings' })}
                </NavLink>
                <NavLink to="#" className="headerLink">
                  {intl.formatMessage({ id: 'rules', defaultMessage: 'Rules' })}
                </NavLink>*/}
                <NavLink to="#" className="headerLink" onClick={this.logout}>
                  {intl.formatMessage({ id: 'log_out', defaultMessage: 'Log out' })}
                </NavLink>
                {/* <NavLink to="/videos" className="headerLink">
                Видео
              </NavLink>
              <NavLink to="/webapi" className="headerLink">
                Web API
              </NavLink>
              <NavLink to="/translations" className="headerLink">
                Переводы
              </NavLink>
              <NavLink to="/test" className="headerLink">
                Тест
              </NavLink>
              <NavLink to="/squad" className="headerLink">
                Squad Test
              </NavLink> */}
              {this.props.users && this.props.permissions.includes('ADMINISTRATE_PANEL_VISIBILITY')
              ? <NavLink to="/appadmin" className="headerLink">
                  Admin
                </NavLink>
              : null}
              </div>
              <div className="lang_button">
                <Button
                  size="xs"
                  className='tariffLink'
                  style={{
                    background: this.props.usertariff && !(this.props.userdisk && this.props.userdisk.freeSpace < 200*Math.pow(1024, 2)) ? this.props.usertariff.designscheme.split(',')[0] : '#D80027',
                  }}>
                    <NavLink to="/account/tariffs">
                      {this.props.userdisk && this.props.userdisk.freeSpace < 200*Math.pow(1024, 2) ? intl.formatMessage({ id: 'LowDiskSpaceweb', defaultMessage: 'Low disk space' }) : <>
                      {this.props.usertariff ? <>
                        <span>{this.props.usertariff.name}</span>
                        <span className='delimeter'/>
                        <span>{1+moment(this.props.usertariff.ab_pend).diff(moment(), 'days')} {intl.formatMessage({ id: 'DayPluralAlt', defaultMessage: '{count, plural, one {day} other {days}}' }, { count: 1+moment(this.props.usertariff.ab_pend).diff(moment(), 'days')})}</span>
                      </> : intl.formatMessage({ id: 'BuyPROweb', defaultMessage: 'Select PRO tariff' })}</>}
                    </NavLink>
                </Button>
                <Button
                  size="xs"
                  className="headerLink"
                  onClick={() => {
                    this.setState({ versionModal: true });
                  }}>
                  {intl.formatMessage({ id: 'VersionName', defaultMessage: 'Version' })}
                </Button>
                <Button
                  size="xs"
                  className="headerLink"
                  onClick={() => {
                    const choosenLang = lang === 'RU' ? 'EN' : 'RU';
                    this.props._changeLang(choosenLang);
                  }}
                >
                  {lang === 'RU' ? 'EN' : 'RU'}
                </Button>
                <OBModal
                  title={intl.formatMessage({ id: 'VersionName', defaultMessage: 'Version' })}
                  visible={this.state.versionModal}
                  width={395}
                  onCancel={() => { this.setState({ versionModal: false }); }}
                  className='email-verification-dialog'
                  footer={
                    <Button
                      kind='aquamarine'
                      bordered='true'
                      onClick={() => { this.setState({ versionModal: false }); }}
                      className='dialog__button-decline'
                    >
                      Ok
                    </Button>}
                >
                  <span className='version_desc'>{intl.formatMessage({ id: 'VersionName', defaultMessage: 'Version' })}: {this.props.ver}</span>
                  {this.props.verdate ? <span className='version_desc'>{moment(this.props.verdate).format('DD MMM YYYY')}</span> : null}
                  <span className='version_desc' dangerouslySetInnerHTML={{ __html: this.props.verdesc }}/>
                </OBModal>
              </div>
            </div>
            {this.props.history.location.pathname.includes('appadmin') && this.props.users && this.props.permissions.includes('ADMINISTRATE_PANEL_VISIBILITY')
              ? <></>
              :<div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  overflow: 'hidden',
                }}>
                <img src={logo} alt="" style={{ margin: '10px 20px 10px 15px', width: 277, height: 86 }} />
                {/* <Title subtitle="bowling">Online</Title> */}
                <div
                  style={{
                    display: 'flex',
                    flex: '1',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <BattleInfoPanel/>
                  {squads.filter(squad => squad.current).map(squad => {
                    const opponent = squad.players.find(player => player.id !== users.user.id) || squad.players[0]
                    return <ActiveGame
                      flag={(opponent.country_live||'').toLowerCase()}
                      photo={opponent.photo}
                      login={opponent.login}
                      fullname={`${opponent.firstname} ${opponent.lastname}`}
                      timestart={squad.datestart}
                      bet={squad.cost}
                      onTrigger={() => this.props.history.push('/squad')}
                      avgscore={(+opponent.avgscore || 0).toFixed()}
                    />})}
                  {game.invite ? (
                    <PendingGame
                      fullname={gameInfo.fullname}
                      login={gameInfo.login}
                      photo={gameInfo.photo}
                      flag={gameInfo.flag}
                      timestart={gameInfo.tournamentstart || gameInfo.datestart}
                      timespan={modaltime}
                      bet={gameInfo.cost}
                      avgscore={gameInfo.avgscore}
                      onTrigger={() => {
                        console.log('time is out');
                      }}
                    />
                  ) : null}

                  {this.props.users.user && <UserInfoPanel
                      isOnline = {this.props.users.user.active}
                      avatarUri={
                        this.props.users.user.photo !== '' && this.props.users.user.photo !== null
                          ? `${config.FETCH.img}/${this.props.users.user.photo}?v=${Math.random()}`
                          : null
                      }
                      name={
                        this.props.users.user.firstname === null || this.props.users.user.lastname === null
                          ? ''
                          : `${this.props.users.user.firstname} ${this.props.users.user.lastname}`
                      }
                      login={this.props.users.user.login}
                      balance={this.props.users.user.currencies}
                      flag={this.props.users.user.country_live || ''}
                      avgscore={(+this.props.users.user.avgscore || 0).toFixed()}
                    />
                  }
                </div>
              </div>
            }
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '50px',
              justifyContent: 'space-between',
            }}
          >
            <div />
            {this.props.location.pathname !== '/' ? null : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginRight: '20px',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <NavLink to="/login">
                  <Button type="primary" style={{ marginRight: '10px' }}>
                    Вход
                  </Button>
                </NavLink>
                <NavLink to="/registration">
                  <Button type="primary">{intl.formatMessage({ id: 'register', defaultMessage: 'Register' })}</Button>
                </NavLink>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(
  withRouter(
    connect(
      state => ({
        users: state.users,
        usertariff: state.billing.usertariff,
        userdisk: state.userdisk.data,
        game: state.events.game,
        squadId: state.squadId,
        modaltime: state.common.settings.data.modal_live_time,
        lang: state.langs.lang,
        ver: state.common.settings.data.version,
        verdesc: state.common.settings.data.versiondesc,
        verdate: state.common.settings.data.versiondate,
        friends: state.friends.friends,
        permissions: state.users.permissions ? state.users.permissions.keys : [],
        squads: state.battles.squads,
      }),
      dispatch => ({
        _logout: bindActionCreators(UserLogout, dispatch),
        _changeLang: bindActionCreators(ChangeLang, dispatch),
        _getFriends: bindActionCreators(GetFriends, dispatch),
        _getPermissions: bindActionCreators(getPermissions, dispatch),
        _getUserTariff: bindActionCreators(getUserTariff, dispatch),
        _fetchUserDisk: bindActionCreators(fetchUserDisk, dispatch),
      }),
    )(CustomHeader),
  ),
);
