import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Modal from '../../Modal';
import { Button } from '../../Button';

import Arrow from '../../../../images/FrameEditArrow.svg';

import './style.scss';

const getMsg = (intl, id, defaultMessage, props = {}) => intl.formatMessage({ id, defaultMessage }, { ...props });

class FrameEditModalConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.groupelem = React.createRef();
  }

  static propTypes = {
    visible: PropTypes.bool.isRequired,
    oldframe: PropTypes.string.isRequired,
    newframe: PropTypes.string.isRequired,
    username: PropTypes.string,
    framenumber: PropTypes.number,
    reason: PropTypes.string,
    explanation: PropTypes.string,
    onEdit: PropTypes.func.isRequired,
    changeid: PropTypes.number,
    isFree: PropTypes.bool,
    intl: intlShape,
  };

  onConfirm = (confirmed) => {
    const { onEdit, changeid, framenumber, frameid } = this.props;
    onEdit({ changeid, confirmed, framenumber, frameid });
  }

  render() {
    const {
      oldframe, visible, newframe, framenumber, username, reason, explanation, intl, isFree,
    } = this.props;

    let _reason = '';
    switch (reason) {
      case 'Ball from Gutter':
        _reason = intl.formatMessage({ id: 'BallFromGutter', defaultMessage: 'Ball from Gutter' });
        break;
      case 'Missings pins':
        _reason = intl.formatMessage({ id: 'PinsMissing', defaultMessage: 'Missings pins' });
        break;
      case 'Wrong scoring':
        _reason = intl.formatMessage({ id: 'WrongScoring', defaultMessage: 'Wrong scoring' });
        break;
      default:
        _reason = reason;
    }

    const _oldframe = { flags: '', shot1: -1, shot2: -1, shot3: -1 };
    const _newframe = { flags: '', shot1: -1, shot2: -1, shot3: -1 };
    oldframe.trim().split('/').forEach((shot, index) => {
      if (shot.includes('fl:')) {
        _oldframe.flags = shot.substring(3);
      } else {
        _oldframe[`shot${index + 1}`] = +shot;
      }
    });
    newframe.trim().split('/').forEach((shot, index) => {
      if (shot.includes('fl:')) {
        _newframe.flags = shot.substring(3);
      } else {
        _newframe[`shot${index + 1}`] = +shot;
      }
    });

    const skittlesold = [];
    if (_oldframe.shot1 !== -1) {
      if (_oldframe.shot1 === 10) {
        skittlesold.push(<span>X</span>);
      } else if (_oldframe.shot1 !== 0) {
        skittlesold.push(<span>{_oldframe.shot1}</span>);
      } else {
        skittlesold.push(<span>-</span>);
      }
    }
    if (_oldframe.shot2 !== -1) {
      if ((_oldframe.shot2 + _oldframe.shot1) === 10) {
        skittlesold.push(<span>/</span>);
      } else if (_oldframe.shot2 === 10) {
        skittlesold.push(<span>X</span>);
      } else if (_oldframe.shot2 !== 0) {
        skittlesold.push(<span>{_oldframe.shot2}</span>);
      } else {
        skittlesold.push(<span>-</span>);
      }
    }
    if (_oldframe.shot3 !== -1) {
      if (_oldframe.shot3 === 10) {
        skittlesold.push(<span>X</span>);
      } else if ((_oldframe.shot1 === 10) && (_oldframe.shot2 + _oldframe.shot3 === 10) && (_oldframe.shot3 !== 0)) {
        skittlesold.push(<span>/</span>);
      } else if (_oldframe.shot3 !== 0) {
        skittlesold.push(<span>{_oldframe.shot3}</span>);
      } else {
        skittlesold.push(<span>-</span>);
      }
    }

    const skittlesnew = [];
    if (_newframe.shot1 !== -1) {
      if (_newframe.shot1 === 10) {
        skittlesnew.push(<span>X</span>);
      } else if (_newframe.shot1 !== 0) {
        skittlesnew.push(<span>{_newframe.shot1}</span>);
      } else {
        skittlesnew.push(<span>-</span>);
      }
    }
    if (_newframe.shot2 !== -1) {
      if ((_newframe.shot2 + _newframe.shot1) === 10) {
        skittlesnew.push(<span>/</span>);
      } else if (_newframe.shot2 === 10) {
        skittlesnew.push(<span>X</span>);
      } else if (_newframe.shot2 !== 0) {
        skittlesnew.push(<span>{_newframe.shot2}</span>);
      } else {
        skittlesnew.push(<span>-</span>);
      }
    }
    if (_newframe.shot3 !== -1) {
      if (_newframe.shot3 === 10) {
        skittlesnew.push(<span>X</span>);
      } else if ((_newframe.shot1 === 10) && (_newframe.shot2 + _newframe.shot3 === 10) && (_newframe.shot3 !== 0)) {
        skittlesnew.push(<span>/</span>);
      } else if (_newframe.shot3 !== 0) {
        skittlesnew.push(<span>{_newframe.shot3}</span>);
      } else {
        skittlesnew.push(<span>-</span>);
      }
    }

    const title = getMsg(intl, 'OpponentChangedResultOfFrame', 'Opponent changed refult of frame #{framenumber}', { framenumber: framenumber });
    return <Modal
      closable={false}
      title={title}
      visible={visible}
      className="frame-edit__modal"
      width={450}
      zIndex={1001}
      footer={[
        <div key="diag" className='frame-edit__dialogue'>
          <Button
            kind="aquamarine"
            className='dialog__button-decline'
            onClick={() => {
              this.onConfirm(-1);
            }}
          >
            {getMsg(intl, 'ProtestAsGameComplaintAlias', 'Protest')}
          </Button>
          <Button
            kind="aquamarine"
            className='dialog__button-accept'
            onClick={() => {
              this.onConfirm(1);
            }}
          >
          {getMsg(intl, 'AcceptAtFrameEdit', 'Accept')}
          </Button>
        </div>,
      ]}
    >
      <div className="frame-edit__score confirmation">
        {/* eslint-disable */}
        <div className='frame-edit__framebox'>
          <div>{framenumber}</div>
          <div>
            {_oldframe.flags.includes('f')
              ? <div className='frame-edit__flag'>F</div>
              : null
            }
            {skittlesold}
          </div>
          <div>{_oldframe.sum}</div>
        </div>
        <img src={Arrow}/>
        <div className='frame-edit__framebox'>
          <div>{framenumber}</div>
          <div>
            {_newframe.flags.includes('f')
              ? <div className='frame-edit__flag'>F</div>
              : null
            }
            {skittlesnew}
          </div>
          <div>{_newframe.sum}</div>
        </div>
        {/* eslint-enable */}
      </div>
      <div className="frame-edit__initiator confirmation">
        <p><span>{username}</span>{` ${getMsg(intl, 'ChangedFrameResults', 'changed frame results.')}`}</p>
      </div>
      <div className="frame-edit__reason confirmation">
        <label>{getMsg(intl, 'FrameEditReason', 'Edit reason')}</label>
        <p><span>{_reason}</span></p>
        <label htmlFor='frame-edit__explanation__input'>{getMsg(intl, 'comment', 'Comment')}</label>
        <p><span>{explanation}</span></p>
      </div>
    </Modal>;
  }
}

export default injectIntl(FrameEditModalConfirmation);
