import React from 'react';
import PropTypes from 'prop-types';
import {
  Player, ControlBar, Shortcut, BigPlayButton,
} from 'video-react';
import { intlShape } from 'react-intl';
import Utils from '../../libs/utils';
import config from '../../config';
import HlsSource from './hlsSource';

const videoServer = `http${config.VIDEOS.secure ? 's' : ''}://${config.VIDEOS.host}`;

const _setInterval = (func, timeout = 10000, ...args) => {
  const timer = {};
  let callback = () => {};
  callback = () => {
    func(...args);
    timer.id = setTimeout(callback, timeout);
  };
  timer.id = setTimeout(callback, timeout);
  timer.remove = () => {
    clearTimeout(timer.id);
    timer.id = null;
  };
  return timer;
};

const CAMERA_TYPES = {
  track: {
    id: 'save_battle_track_camera',
    defaultMessage: 'Track',
  },
  skittles: {
    id: 'save_battle_skittles_camera',
    defaultMessage: 'Pin deck',
  },
  scoring: {
    id: 'save_battle_scoring_camera',
    defaultMessage: 'Scoring',
  },
};

const CAMERA_TYPE_IDS = {
  1: 'track',
  2: 'scoring',
  3: 'skittles',
};

class BowlingCentersCamera extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      readyState: 0,
      key: 0,
    };
  }

  static propTypes = {
    intl: intlShape,
    idx: PropTypes.number,
    bowlingcenterId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    laneId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    camera: PropTypes.shape({
      id: PropTypes.number,
      ip: PropTypes.string,
      type: PropTypes.string,
      cameravideotypeid: PropTypes.number,
    }),
    width: PropTypes.number,
    height: PropTypes.number,
    aspectRatio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    muted: PropTypes.bool,
    playsInline: PropTypes.bool,
  }

  static defaultProps = {
    camera: {},
    aspectRatio: 'auto',
    muted: Utils.iOS(),
    playsInline: Utils.iOS(),
  }

  // state = { readyState: 0 }

  componentDidMount() {
    this.setState({ uri: this.createUri() });
    this.timer = _setInterval(this._checkPlayerState);
    this.player.subscribeToStateChange(this.handleStateChange.bind(this));
  }

  componentDidUpdate(prevProps) {
    const { camera } = this.props;
    if (camera.id && prevProps.camera.id && camera.id !== prevProps.camera.id) {
      // camera has been changed
      this.timer.remove();
      this.timer = _setInterval(this._checkPlayerState);
      this.setState({
        key: this.state.key + 1,
      }, () => {
        if (this.isSafari && this.player) {
          this.player.load();
        }
      });
    }
  }

  componentWillUnmount() {
    this.timer.remove();
  }

  handleStateChange(state, prevState) {
    if (state.error && !prevState.error && !state.readyState) {
      this.setState({
        key: this.state.key + 1,
      });
    }
  }

  _checkPlayerState = () => {
    const { camera } = this.props;
    const { player } = this.player.getState();
    if (player.readyState === 0) {
      // restart camera if readyState still zero
      this.setState({
        key: this.state.key + 1,
      }, () => {
        if (this.isSafari) {
          this.player.load();
        }
      });
    }

    if (player.error && !player.readyState) {
      this.setState({
        key: this.state.key + 1,
      });
    }
  }

  isSafari = Utils.iOS() || Utils.isSafari();

  _getRefPlayer = (ref) => {
    this.player = ref;
  }

  createUri() {
    const { camera, user } = this.props;
    return `${videoServer}/cam${camera.id}-0/index.m3u8?token=${encodeURIComponent(config.FETCH.fullUrl)}_${((user || {}).session || {}).sessionkey}`;
  }

  render() {
    const {
      camera, width, height, aspectRatio, muted, playsInline, bowlingcenterId, laneId, intl, controls,
		} = this.props;
    const {
      uri = this.createUri(),
    } = this.state;
    const safariSource = this.isSafari ? { src: uri } : {};
    const cameraType = camera.type || CAMERA_TYPE_IDS[camera.cameravideotypeid];
    const cameraTypeText = CAMERA_TYPES[cameraType] ? intl.formatMessage(CAMERA_TYPES[cameraType]) : `camera-${camera.cameravideotypeid}`;
    return <div className={`bowlingcenters_camera bowlingcenters_camera_${camera.id || 'null'}`} key={`${bowlingcenterId || 'null'}-${laneId || 'null'}-${camera.id}` || 'null'}>
      {/*<div className="bowlingcenters_camera_title">{cameraTypeText}</div>*/}
      <div className="bowlingcenters_camera_player">
        <Player
          key={this.state.key}
          {...safariSource}
          ref={this._getRefPlayer}
          fluid={!width || !height}
          width={width}
          height={height}
          aspectRatio={aspectRatio}
          autoPlay
          volume={100}
          muted={muted}
          playsInline={playsInline}
        >
          <ControlBar disableCompletely={!controls}/>
          <Shortcut clickable={false}/>
          <BigPlayButton position="center"/>
          {!this.isSafari && <HlsSource
            isVideoChild
            src={uri}
            autoPlay
            type={'application/x-mpegURL'}
          />}
        </Player>
      </div>
    </div>;
  }
}

export default BowlingCentersCamera;
