/* eslint-disable */
import React from 'react'
import { Button } from '../common/Button'
import Modal from '../common/Modal'

/** @type {React.FC} */
const LiveCutModal = (props) => {
  const { visible, onClose, text, intl } = props
  return <Modal
    title="Info"
    visible={visible}
    onOk={onClose}
    closable={false}
    footer={[
      <div key="buttons" className="start-game__buttons">
        <Button kind="aquamarine" bordered="true" onClick={onClose} className="start-game__button-decline">
          {intl.formatMessage({ id: 'ModalDefaultOK', defaultMessage: 'OK' })}
        </Button>
      </div>,
    ]}
  >
    <div className="live-cut-modal-content">
      {text.map(t => t === '\n' ? <br></br> : <span className="text">{t}</span>)}
    </div>
  </Modal>
}

export { LiveCutModal }
