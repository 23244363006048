import React, {useEffect, useRef, useState} from 'react';
import CheckBox from "../../../common/Checkbox";
import moment from "moment";

const DataBlock = (props) => {
    const {
        selectedImage,
        Publication,
        type,
        weightArray,
        banners,
        setBanners
    } = props

    const [activeBC, setActiveBC] = useState({});


    useEffect(() => {
        if (selectedImage){
            let tempActiveBC = {}
            selectedImage.bc_name.forEach(name => tempActiveBC[name] = true)
            if (selectedImage.deselect_bc && selectedImage.deselect_bc.length){
                selectedImage.deselect_bc.forEach(name => tempActiveBC[name] = false)
            }
            setActiveBC({...tempActiveBC})
        } else {
            setActiveBC({})
        }
    }, [selectedImage]);


    function switchCheckBox(name) {
        if (activeBC[name]){
            selectedImage.deselect_bc = selectedImage.bc_name.filter(bcName => name === bcName )
        } else {
            selectedImage.deselect_bc = selectedImage.bc_name.filter(bcName => name != bcName )
        }
        selectedImage.status = 'changed'
        setActiveBC({...activeBC, [name]: !activeBC[name]})
    }

    const BowlingCenter = () => {
        return (
            <div>
                <div className={'text gray'}>Bowling Center</div>
                <div className={'selected_image_bc'}>
                    {selectedImage.bc_name.map(name => {
                        return (
                            <CheckBox
                                checked={activeBC[name]}
                                onChange={() => switchCheckBox(name)}
                            >
                                <div className={'text checkName'}>{name}</div>
                            </CheckBox>
                        )
                    })}
                </div>
            </div>
        )
    }


    const BannerLink = () => {
      return(
          <a href={selectedImage.link}>
              <div className={'link_container'}>
                  <div className={'text link'}>
                      {selectedImage.link}
                  </div>
              </div>
          </a>
      )
    }

    const BannerTable = () => {
      return(
          <table >
              <caption className={'text administrator'}>Administrator of BC</caption>
              <th>Name</th>
              <th>Surname</th>
              <th>Login</th>
              <th>Date</th>
              <tr>
                  <td>{selectedImage.creator.firstname}</td>
                  <td>{selectedImage.creator.lastname}</td>
                  <td>{selectedImage.creator.login}</td>
                  <td>{ moment(selectedImage.lastUpdated).format("DD MMM YYYY")}</td>
              </tr>
          </table>
      )
    }

    const BannerWeight = () => {
        let inputStyle = 'text bc_banners_input '
        let isInvalid = false
        if (weightArray.includes(Number(selectedImage.weight))){
            inputStyle += 'invalid_weight'
            isInvalid = true
        }

      return(
          <div>
              <div className={'text gray weight_label'}> Priority weight</div>
              <input
                  type='number'
                  value={selectedImage.weight ? selectedImage.weight : ''}
                  onChange={(e) => {
                      setBanners(banners.map(banner => {
                          if (banner.name === selectedImage.name){
                              banner.weight = Number(e.target.value)
                          }
                          return banner
                      }))
                  }}
                  className={inputStyle}
              />
              {isInvalid && <div className={'text invalid_weight_text'}>This weight is assigned to another banner</div>}
          </div>
      )
    }

    const PublicationButton = () => {
        let text = 'Publication'

        if (type === 'published'){
            text = 'Save'
        }

        if (selectedImage.status === 'deleted'){
            text = 'Delete'
        }
      return(
          <div className={'publication_button text publication'} onClick={() => Publication()}>
              {text}
          </div>
      )
    }

    return (
        <div className={'moderation_banner_data'}>
            <div className={'data_container'}>
                <BowlingCenter/>
                {selectedImage.link && <BannerLink/>}
                <BannerTable/>
                {BannerWeight()}
                <div className={'center_alignment'}>
                    <PublicationButton/>
                </div>
            </div>
        </div>
    );
};

export default DataBlock;
