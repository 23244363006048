export const isSolo = (value) => {
  return ['DROP_IN', 'OPEN_CHALLENGE'].includes(value);
};

export const isPinfall = (value) => {
  return ['DROP_IN', 'PINFALL_BATTLE','OPEN_CHALLENGE'].includes(value);
};

export const isBattle = (value) => {
  return ['BATTLES', 'INDIVIDUAL'].includes(value);
};

export const isTournament = (value) => {
  return ['DROP_IN', 'BATTLES'].includes(value);
}

export const isOpenChallenge = (value) => {
  return ['OPEN_CHALLENGE'].includes(value);
}