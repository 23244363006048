/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable arrow-parens */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment';
import {
  getUserCards,
  deleteCreditCard,
  saveIBAN,
  deleteIBAN,
} from '../../../libs/reducers/actions';
import Radio from '../Radio';
import TokenForm from '../../paymentGate/token';
import OBModal from '../Modal';
import { Button } from '../Button';
import { FilterInput } from '../Input';
import Select from '../Select';
import OBDatePicker from '../DatePicker';

import trashbinIcon from '../../../images/trashbin.svg';

import './style.scss';
import Checkbox from '../Checkbox';
import IbanCreateModal from './iban-create.modal.jsx';
import IbanDeleteModal from './ibanDeleteModal';

class CreditCardPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedcard: null,
      selectediban: null,
      iterator: false,
      cardid: false,
      method: null,
      ibancreate: false,

      ibancountry: false,
      ibandob: false,
      ibanfirstname: null,
      ibanlastname: null,
      iban: null,
      ibandelete: null,

      ibanerrors: null,
    };

    this.types = [
      {
        value: 'card',
        label: this.props.intl.formatMessage({
          id: 'PaymentMethodCard',
          defaultMessage: 'Credit card',
        }),
      },
      {
        value: 'iban',
        label: this.props.intl.formatMessage({
          id: 'PaymentMethodIBAN',
          defaultMessage: 'IBAN account',
        }),
      },
    ];
  }

  static propTypes = {
    onSelect: PropTypes.func,
    intl: intlShape,
    notNull: intlShape.bool,
  };

  componentDidMount() {
    const { getUserCards } = this.props;
    getUserCards();
    if (!this.props.withIban) {
      this.setState({ method: { value: 'card' } });
    }
    if (this.props.value) {
      const method = this.types.find(
        type => type.value === this.props.value.method,
      );
      if (this.props.value.method == 'card') {
        this.setState({ method, selectedcard: this.props.value.id });
      } else if (this.props.value.method == 'iban') {
        this.setState({ method, selectediban: this.props.value.id });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.withIban !== this.props.withIban && !this.props.withIban) {
      this.setState({ method: { value: 'card' } });
    }
    if (this.props.value !== prevProps.value) {
      const method = this.types.find(
        type => type.value === this.props.value.method,
      );
      if (this.props.value.method == 'card') {
        this.setState({ method, selectedcard: this.props.value.id });
      } else if (this.props.value.method == 'iban') {
        this.setState({ method, selectediban: this.props.value.id });
      }
    }
  }

  handleTokenSubmit() {
    this.setState({ iterator: true }, () => this.setState({ iterator: false }));
  }

  onDeleteIban = () => {
    this.props.deleteIBAN(this.state.ibandelete);
    this.setState({ ibandelete: false });
  };

  OnCandelDelteIban = () => this.setState({ ibandelete: false });


  render() {
    const {
      onSelect,
      creditCards = [],
      ibans = [],
      paypals = [],
      fetch,
      intl,
      notNull,
      customOrder,
      renew,
      countries,

      withIban,
      noCardSelect,
      withPayPal,
      disableCards,

      TCAgreement,
      onChangeTCAgreement = () => null,
      type,
    } = this.props;
    const {
      selectedcard,
      selectediban,
      iterator,
      cardid,
      method,
      ibancreate,
      ibandelete,
    } = this.state;

    this.types = [];

    // if (!disableCards) {
    //   this.types.push({ value: 'card', label: this.props.intl.formatMessage({ id: 'PaymentMethodCard', defaultMessage: 'Credit card' }) });
    // }
    if (withIban) {
      this.types.push({
        value: 'iban',
        label: this.props.intl.formatMessage({
          id: 'PaymentMethodIBAN',
          defaultMessage: 'IBAN account',
        }),
      });
    }
    if (withPayPal) {
      this.types.push({ value: 'paypal', label: 'PayPal' });
    }
    if (type === 'deposit') {
      this.types.push({
        value: 'paysera',
        label: this.props.intl.formatMessage({
          id: 'PayseraPaymentMethod',
          defaultMessage: 'Paysera',
        }),
      });
    }
    return (
      <div className="creditcard_picker">
        {!notNull && (
          <div className="title">
            {intl.formatMessage({
              id: 'PaymentAccountSelector',
              defaultMessage: 'Pick an account',
            })}
          </div>
        )}
        {withIban && (
          <div className="paymentMethodSelector">
            <Select
              value={method}
              placeholder={intl.formatMessage({
                id: 'PaymentMethodSelector',
                defaultMessage: 'Payment method',
              })}
              onChange={method => {
                this.setState({ method }, () => {
                  switch ((this.state.method || {}).value) {
                    case 'card':
                      onSelect({
                        value: creditCards.find(
                          card => card.id === this.state.selectedcard,
                        ),
                        type: 'card',
                      });
                      break;
                    case 'iban':
                      onSelect({
                        value: ibans.find(
                          iban => iban.id === this.state.selectediban,
                        ),
                        type: 'iban',
                      });
                      break;
                    case 'paypal':
                      onSelect({
                        value: paypals.find(
                          paypal => paypal.id === this.state.selectediban,
                        ),
                        type: 'paypal',
                      });
                      break;
                    case 'paysera':
                      onSelect({ type: 'paysera', value: 'Paysera' });
                      break;
                    default:
                      onSelect({ value: null, type: null });
                  }
                });
              }}
              options={this.types}
            />
          </div>
        )}
        {method
          && (() => {
            switch (method.value) {
              case 'paysera':
              case 'paypal':
                return (
                  <>
                    <Radio.Group
                      onChange={e => this.setState({ selectediban: e.target.value }, () => onSelect({
                        value: paypals.find(
                          paypal => paypal.id === this.state.selectediban,
                        ),
                        type: 'paypal',
                      }))
                      }
                      value={selectediban}
                      defaultValue={null}
                    >
                      {paypals.map(ibanElem => (
                        <>
                          <div
                            className={`creditcard_item${
                              ibanElem.id === selectediban ? ' active' : ''
                            }`}
                          >
                            <Radio value={ibanElem.id}>{ibanElem.iban}</Radio>
                            <a
                              onClick={() => this.setState({ ibandelete: ibanElem.id })
                              }
                            >
                              <img src={trashbinIcon} />
                            </a>
                          </div>
                        </>
                      ))}
                    </Radio.Group>
                    <a
                      className="addcard"
                      onClick={() => this.setState({ ibancreate: true })}
                    >
                      <span className="big">+</span>
                      {intl.formatMessage({ id: 'PayPalCreationLink', defaultMessage: 'Add new PayPal' })}
                    </a>
                    <IbanCreateModal
                      isVisible={!!ibancreate}
                      type={method.value}
                      onClose={() => {
                        this.setState({ ibancreate: false });
                      }}
                    />
                    <IbanDeleteModal
                      isVisible={!!ibandelete}
                      onSubmit={this.onDeleteIban}
                      onCancel={this.OnCandelDelteIban}
                      method={method.value}
                    />
                  </>
                );
              case 'card':
                return (
                  <>
                    <Radio.Group
                      onChange={e => this.setState({ selectedcard: e.target.value }, () => onSelect({
                        value: creditCards.find(
                          card => card.id === this.state.selectedcard,
                        ),
                        type: 'card',
                      }))
                      }
                      value={selectedcard}
                      defaultValue={null}
                    >
                      {!notNull && (
                        <div
                          className={`creditcard_item${
                            !selectedcard ? ' active' : ''
                          }`}
                        >
                          <Radio value={null}>
                            {intl.formatMessage({
                              id: 'NoTokenCard',
                              defaultMessage: 'Without card saving',
                            })}
                          </Radio>
                        </div>
                      )}
                      {!noCardSelect
                        && creditCards.map(card => (
                          <>
                            <div
                              className={`creditcard_item${
                                card.id === selectedcard ? ' active' : ''
                              }`}
                            >
                              <Radio value={card.id}>
                                {card.mask.replace(/X/g, '*')}
                              </Radio>
                              <a
                                onClick={() => this.setState({ cardid: card.id })
                                }
                              >
                                <img src={trashbinIcon} />
                              </a>
                            </div>
                          </>
                        ))}
                    </Radio.Group>
                    {!noCardSelect && (
                      <a
                        className="addcard"
                        onClick={() => this.handleTokenSubmit()}
                      >
                        <span className="big">+</span>
                        {intl.formatMessage({
                          id: 'AddTokenCard',
                          defaultMessage: 'Add new credit card',
                        })}
                      </a>
                    )}
                    {fetch && (
                      <div className="loader">
                        {intl.formatMessage({
                          id: 'LoadingTokenCard',
                          defaultMessage: 'Loading credit card list',
                        })}
                      </div>
                    )}
                    <TokenForm
                      iterator={iterator}
                      destination={this.props.destination}
                      customOrder={customOrder}
                      renew={renew}
                    />
                    <OBModal
                      title={intl.formatMessage({
                        id: 'CreditCardTokenDelete',
                        defaultMessage: 'Credit card deletion',
                      })}
                      visible={!!cardid}
                      onSubmit={() => {
                        this.props.deleteCreditCard(cardid);
                        this.setState({ cardid: false });
                      }}
                      onCancel={() => this.setState({ cardid: false })}
                      footer={[
                        <div key="buttons" className="dialog__buttons">
                          <Button
                            kind="aquamarine"
                            bordered="true"
                            onClick={() => this.setState({ cardid: false })}
                            className="dialog__button-decline"
                          >
                            {intl.formatMessage({
                              id: 'CancelAsAbortAlias',
                              defaultMessage: 'Cancel',
                            })}
                          </Button>
                          <Button
                            onClick={() => {
                              this.props.deleteCreditCard(cardid);
                              this.setState({ cardid: false });
                            }}
                            kind="aquamarine"
                            className="dialog__button-accept"
                          >
                            {intl.formatMessage({
                              id: 'Accept',
                              defaultMessage: 'Accept',
                            })}
                          </Button>
                        </div>,
                      ]}
                    >
                      <p className="CreditCardDeleteText">
                        {intl.formatMessage({
                          id: 'CreditCardtokenDeleteConfirmation',
                          defaultMessage:
                            'Are you sure you want to delete credit card from your account?',
                        })}
                      </p>
                    </OBModal>
                  </>
                );
              case 'iban':
                return (
                  <>
                    <Radio.Group
                      onChange={e => this.setState({ selectediban: e.target.value }, () => onSelect({
                        value: ibans.find(
                          iban => iban.id === this.state.selectediban,
                        ),
                        type: 'iban',
                      }))
                      }
                      value={selectediban}
                      defaultValue={null}
                    >
                      {ibans.map(ibanElem => (
                        <>
                          <div
                            className={`creditcard_item${
                              ibanElem.id === selectediban ? ' active' : ''
                            }`}
                          >
                            <Radio value={ibanElem.id}>{ibanElem.iban}</Radio>
                            <a
                              onClick={() => this.setState({ ibandelete: ibanElem.id })
                              }
                            >
                              <img src={trashbinIcon} />
                            </a>
                          </div>
                        </>
                      ))}
                    </Radio.Group>
                    <a
                      className="addcard"
                      onClick={() => this.setState({ ibancreate: true })}
                    >
                      <span className="big">+</span>
                      {intl.formatMessage({
                        id: 'IBANCreationLink',
                        defaultMessage: 'Add new IBAN',
                      })}
                    </a>
                    <IbanCreateModal
                      isVisible={!!ibancreate}
                      type={method.value}
                      onClose={() => {
                        this.setState({ ibancreate: false });
                      }}
                    />
                    <IbanDeleteModal
                      isVisible={!!ibandelete}
                      onSubmit={this.onDeleteIban}
                      onCancel={this.OnCandelDelteIban}
                      method={method.value}
                    />
                  </>
                );
              default:
                return null;
            }
          })()}
      </div>
    );
  }
}

export default injectIntl(
  connect(
    state => ({
      fetch: state.billing.fetchingCards,
      creditCards: state.billing.userCards,
      ibans: state.billing.userIbans,
      paypals: state.billing.userPaypals,
      countries: (state.common.geodata || {}).data || [],
      lang: state.langs.locale.lang,
      commissions: state.billing.commissions,
    }),
    {
      getUserCards,
      deleteCreditCard,
      saveIBAN,
      deleteIBAN,
    },
  )(CreditCardPicker),
);
