import React, { useState, useEffect } from 'react';
import moment from 'moment';
import config from '../../config';
import defaultAvatar from '../../images/defaultAvatar.png';
import { Button } from '../common/Button';
import SvgEye from '-!svg-react-loader!../../images/eye.svg'; // eslint-disable-line
import Flag from '../common/Flag';
import { isSolo } from '../../libs/SquadTypeCheck';
import './style.scss';

const AllBattlesTab = (props) => {
  const {
    squads,
    intl,
    participation,
    user,
    userSearch,
    betFrom,
    betTo,
    typefilter,
    translate,
    squadsWatcher,
    UserByIdFetch,
    setObservableSquad,
    TakePartBattle,
    onAcceptToBattle,
  } = props;
  const [filteredBattles, setFilteredBattles] = useState([]);

  useEffect(() => {
    let _filteredBattles = [...squads].filter(squad => !squad.current).sort((a, b) => {
      return new Date(a.datestart) - new Date(b.datestart);
    });

    if (userSearch) {
      const forSearch = userSearch.trim().toLowerCase().split(/\s|\,/gi);
      _filteredBattles = _filteredBattles.filter((b) => {
        return !!forSearch.find((string) => {
          return !!b.players.find((player) => {
            return `${player.firstname}_${player.lastname}_${player.login}`.includes(string);
          });
        });
      });
    }
    if (betFrom) {
      _filteredBattles = _filteredBattles
        .filter(b => (b.tournamenttypebrief === 'INDIVIDUAL' ? b.tournamentcost >= betFrom.value : b.squadcost >= betFrom.value));
    }
    if (betTo) {
      _filteredBattles = _filteredBattles
        .filter(b => (b.tournamenttypebrief === 'INDIVIDUAL' ? b.tournamentcost <= betTo.value : b.squadcost <= betFrom.value));
    }
    if (typefilter.length) {
      _filteredBattles = _filteredBattles
        .filter(b => typefilter.includes(b.tournamenttypebrief));
    }

    setFilteredBattles(_filteredBattles);
  }, [squads]);

  const getListPassedPlayer = (player) => {
    return player.fullname;
  };

  const createRatingSVG = (rating) => {
    const poly = 'M14 1.12978L17.6661 8.55816L17.7825 8.79389L18.0426 8.83169L26.2403 10.0229L20.3084 15.8051L20.1202 15.9886L20.1646 16.2476L21.5649 24.4122L14.2327 20.5574L14 20.4351L13.7673 20.5574L6.43507 24.4122L7.83541 16.2476L7.87984 15.9886L7.69161 15.8051L1.75969 10.0229L9.9574 8.83169L10.2175 8.79389L10.3339 8.55816L14 1.12978Z';
    const numbers = [1, 2, 3, 4, 5];
    return numbers.map((num, idx) => {
      let fill = 'none';
      let isPartial = false;
      const entropy = Math.random();
      if (rating > num || rating === num) {
        fill = '#FFB31E';
      } else if (num - 1 < rating && rating < num) {
        isPartial = true;
        fill = `url(#partialGradient${entropy})`;
      }
      return (
        <svg
          key={idx}
          className={`${rating === num ? 'selected' : ''}`}
          width="12"
          height="12"
          viewBox="0 0 28 28"
          fill={fill}
          stroke={1}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d={poly} stroke="#FFB31E" />
          {isPartial ? (
            <linearGradient id={`partialGradient${entropy}`}>
              <stop stopOpacity="1" offset={`${(rating - num + 1) * 100}%`} stopColor="#FFB31E" />
              <stop stopOpacity="0" offset="0" />
            </linearGradient>
          ) : null}
        </svg>
      );
    });
  };

  return (
    <div className="lobbyBattleList">
      {filteredBattles.map((battle, index) => {
        let cost = 'Free';
        const fakedate = new Date() > new Date(battle.datestart) ? new Date() : new Date(battle.datestart);
        const prevfakedate = !index || new Date() > new Date(filteredBattles[index - 1].datestart)
          ? new Date()
          : new Date(filteredBattles[index - 1].datestart);
        if (battle.tournamenttypeid === 1 && battle.tournamentcost) {
          cost = battle.tournamentcost + battle.currencysymbol;
        } else if (battle.squadcost) {
          cost = battle.squadcost + battle.currencysymbol;
        }
        return <>
          {!index || prevfakedate.getDay() !== fakedate.getDay() ? <>
            {(() => {
              const diff = moment(fakedate.toISOString().slice(0, 10)).diff(moment(new Date().toISOString().slice(0, 10)), 'days');
              if (diff < 1) {
                return <div className='date'>{intl.formatMessage({ id: 'BattleCardDateToday', defaultMessage: 'Today' })}</div>;
              } else if (diff < 2) {
                return <div className='date'>{intl.formatMessage({ id: 'BattleCardDateTomorrow', defaultMessage: 'Tomorrow' })}</div>;
              } else {
                return <div className='date'>{moment(battle.datestart).format('D MMMM')}</div>;
              }
            })()}
          </> : null}
          <div key={battle.id} className={`BattleItem${battle.players.find(p => p.id === user.id) ? ' my' : ''}`}>
            <div className={`title${!(['INDIVIDUAL', 'PINFALL_BATTLE'].includes(battle.tournamenttypebrief)) ? ' tournament' : ''}`}>
              #{battle.id}&nbsp;
              {!(['INDIVIDUAL', 'PINFALL_BATTLE'].includes(battle.tournamenttypebrief)) ? `${intl.formatMessage({ id: 'TournamentNamePrefix', defaultMessage: 'Tournament' })} - ` : ''}&nbsp;
              {battle.createdby === user.id ? `${intl.formatMessage({ id: 'TournamentMyPrefix', defaultMessage: 'My' })} ` : ''}
              {battle.tournamentname}&nbsp;
            </div>
            <div className='content'>
              <div className={`players ${battle.players.length > 2 ? 'short' : 'full'}`}>
                {(() => {
                  const players = [];
                  battle.players.forEach((player, index) => {
                    const classesPlayer = ['player'];
                    const jsx = <>
                      {/* <div className='player' onClick={() => props.UserByIdFetch({ userid: player.id })}> */}
                      <div className={classesPlayer.join(' ')} onClick={() => UserByIdFetch({ userid: player.id })}>
                        <div className={`playerAvatar ${player.active ? 'active' : 'inactive'}`}>
                          <img src={player.photo ? `${config.FETCH.img}/${player.photo}` : defaultAvatar} alt=""/>
                          {player.avgscore ? <div className='avgScore'>{(+player.avgscore).toFixed()}</div> : null}
                          <div className='flag'>
                            <Flag name={player.country}/>
                          </div>
                        </div>
                        {battle.players.length < 3 ? <>
                          <div className='name'>
                            {player.fullname}
                            <div className='rating'>{createRatingSVG(player.ratingsavg)}{player.ratingsavg ? (+player.ratingsavg).toFixed(1) : player.ratingsavg}</div>
                          </div>
                        </> : null}
                      </div>
                    </>
                    players.push(jsx);
                  })
                  let groups = [[], []];
                  players.forEach((player, index) => {
                    groups[index % 2].push(player);
                  });
                  groups = groups.filter(g => g.length);
                  return groups.map((g, index) => {
                    if (index + 1 !== groups.length) {
                      return <><div className='group'>{g}</div><div className='VSdelimeter'>VS</div></>
                    }

                    return <div key={index} className='group'>{g}</div>
                  })
                })()}
              </div>
              <div className='date'>
                <div className='caption'>{intl.formatMessage({ id: 'date_of_battle', defaultMessage: 'Battle date' })}</div>
                <div className='value'>
                  {battle.hasstarted
                    ? <span className='started'>{intl.formatMessage({ id: 'TournamentStartedMark', defaultMessage: 'GAME STARTED!' })}</span>
                    : battle.datestart ? moment(battle.datestart).format('D MMM - HH:mm') : translate.now
                  }
                  </div>
              </div>
              {/*<div className='fee'>
                <div className='caption'>{translate.fee}</div>
                <div className='value'>{cost}</div>
              </div>*/}
              <div className='wincount'>
                <div className='caption'>
                  {(battle.tournamenttypebrief === 'PINFALL_BATTLE' && 'Pinfall')
                    || (isSolo(battle.tournamenttypebrief) && 'Games')
                    || translate.wins
                  }
                </div>
                <div className='value'>{battle.wincount}</div>
              </div>
              <div className='action'>
                {(() => {
                  const { id, tournamentid } = battle;
                  let disabled = false;
                  let text = null;
                  const icon = null;
                  let noButton = false;
                  if (battle.joinable && !battle.joined) {
                    text = intl.formatMessage({ id: 'Accept', defaultMessage: 'Accept' });
                    disabled = false;
                  } else if (battle.observable) {
                    text = translate.observe;
                    const squadWatcher = squadsWatcher.find(squadWatcher => +squadWatcher.squadid === +battle.id);
                    const { countWatcher } = squadWatcher || {};
                    return <Button
                      size="md"
                      icon={icon}
                      bordered="true"
                      disabled={!battle.observable}
                      kind="aquamarine"
                      onClick={() => {
                        setObservableSquad(id);
                      }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {text}
                          {countWatcher
                            ? (
                              <div style={{ display: 'flex', alignItems: 'center', marginLeft: 8 }}>
                                <SvgEye style={{ marginRight: 3 }} />
                                {countWatcher}
                              </div>
                            )
                            : ''
                          }
                        </div>
                      </Button>;
                  } else {
                    noButton = true;
                  }
                  disabled = cost !== 'Free' && !user.is_adult;
                  if (!noButton) {
                    return (
                      <Button
                        disabled={disabled}
                        size="md"
                        icon={icon}
                        kind="aquamarine"
                        loading={participation}
                        onClick={() => TakePartBattle({ tournamentId: tournamentid, squadId: id })}>
                        {text}
                      </Button>
                    );
                  }

                  let expectationPlayers = [];
                  if (!Array.isArray(battle.expectation_players)) {
                    expectationPlayers = [battle.expectation_players];
                  }

                  if (
                    (['INDIVIDUAL', 'PINFALL_BATTLE'].includes(battle.tournamenttypebrief))
                    && battle.expectation_players
                    && (expectationPlayers || []).some(player => player.id === user.id)
                  ) {
                    const isUser = (battle.createdby === user.id);
                    return (
                      <Button
                        disabled={disabled}
                        size="md"
                        icon={icon}
                        kind="aquamarine"
                        loading={participation}
                        onClick={() => onAcceptToBattle(user.id, tournamentid, isUser)}
                      >
                        {intl.formatMessage({ id: 'Accept', defaultMessage: 'Accept' })}
                      </Button>
                    );
                  }

                  return (
                    <span>
                      {intl.formatMessage({ id: 'TournamentStartAwait', defaultMessage: 'Waiting for game start' })}
                    </span>
                  );
                })()}
              </div>
            </div>
            {
              battle.joined && battle.expectation_players
                ? (
                <div className="battle-item__passed">
                  <div>{intl.formatMessage({ id: 'MyBattlesWaitRival', formatMessage: 'Wait for opponent' })}:</div>
                  <div>{getListPassedPlayer(battle.expectation_players)}</div>
                </div>
                ) : null
            }
          </div>
        </>
      })}
    </div>
  )
};

export default AllBattlesTab;
