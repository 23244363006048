/* eslint-disable consistent-return */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter, Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';
import config from '../../config';
import { Button } from '../common/Button';
import { Table } from 'antd';
import { 
  UserByIdFetch,
  makeResolve,
  ChangeLang,
  getProtocol,
  setVideoArchiveSquadId,
  setProtocolSquadId,
  getTournamentTypeList,
} from '../../libs/reducers/actions';
import SvgButton from '../common/SvgButton';
import Checkbox from '../common/Checkbox';
import Avatar from '../common/Avatar';
import Utils from '../../libs/utils';
import SvgArrow from '-!svg-react-loader!./vector.svg'; // eslint-disable-line
import SvgBack from '-!svg-react-loader!./back.svg'; // eslint-disable-line
import ChangedFlag from '-!svg-react-loader!../../images/changed.svg'; // eslint-disable-line
import RefereedFlag from '-!svg-react-loader!../../images/refereed.svg'; // eslint-disable-line
import FoulFlag from '-!svg-react-loader!../../images/foul.svg'; // eslint-disable-line
import ModalProtocolDecision from './modal';

import MatchScore from '../matchScore';

import './style.scss';
import { mute } from 'video-react/lib/actions/player';

class ProtocolPage extends Component {

  constructor(props) {

    super(props);

    this.state = {
      squadid: null,
      data: null,
      resolved: false,
      failed: false,
      comment: null,
      showConfirmModal: false,
      inProgress: false,
      fetching: false,
      isReferee: false,
      collapse: {}
    }

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = { ...prevState }
    if (!prevState.squadid && nextProps.protocol.squadid) {
      nextProps.getProtocol({ squadid: nextProps.protocol.squadid });
      state = { ...state, squadid: nextProps.protocol.squadid }
    }
    if (!prevState.fetching && nextProps.protocol.isFetching) {
      state = { ...state, fetching: true }
    } 
    if (prevState.fetching && !nextProps.protocol.isFetching) {
      if (!nextProps.protocol.error) {
        const { data } = nextProps.protocol;
        const { failed, resolved, comment } = data;
        let _state = { failed, resolved, comment, data, collapse: {}, fetching: false };
        for (let i = 0; i < data.protests.length; i++) {
          _state.collapse[data.protests[i].id] = true;
        }
        const isReferee = data.reviewingRefereeId === nextProps.user.id;
        state = { ...state, ..._state, isReferee };
      } else {
        nextProps.history.goBack();
      }
    }
    if (prevState.inProgress && !prevState.resolved && nextProps.protocol.data.resolved) {
      nextProps.history.goBack();
    }
    return state;
  }

  componentDidMount () {
    const { squadid } = this.props.protocol;
    const { search } = this.props.location;

    if (!squadid && !search) {
      this.goBack();
    } else if (!squadid && search) {
      this.props.getTournamentTypeList();
      const params = search.substr(1).split('=');
      if (params[0] == 'squadid' && +params[1]) {
        this.props.setProtocolSquadId(+params[1]);
        this.props.history.replace('/protocol');
      } else {
        this.props.history.replace('/referee');
      }
    }
  }

  goBack = () => {
    this.props.history.goBack();
  }

  showUser = function (userid) {
    this.props.UserByIdFetch({
      userid: userid,
    });
  }

  downloadFile = (filename) => {
    const url = `${config.FETCH.url}/protest/download/${filename}`;
    axios({
      url: url,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    });
  } 

  onSubmit = (confirmed) => {
    if (!confirmed) {
      this.setState({ showConfirmModal: true })
    } else {
      this.setState({ showConfirmModal: false, inProgress: true });
      const { failed, comment, data } = this.state;
      const { squad, winner, winner_score, loser, loser_score } = data;
      const { tournamentid, squadid } = squad;
      this.props.makeResolve({ 
        tournamentid, 
        squadid, 
        failed, 
        comment, 
        winner, 
        winner_score, 
        loser, 
        loser_score
      });
    }
  }

  collapse = (protestid) => {
    const { collapse } = this.state;
    let _collapse = { ...collapse };
    _collapse[protestid] = !collapse[protestid];
    this.setState({ collapse: _collapse });
  }

  renderScoring = (scoring, frameid, onlyChangedFrame = false) => {
    const renderResult = scoring.map((f) => {
      const {
        id,
        shot1,
        shot2,
        shot3,
        flags,
        score,
        framenumber,
      } = f;
      const result = Utils.getFrameResult(shot1, shot2, shot3, true);
      const isChanged = flags ? flags.indexOf('c') !== -1 : false;
      const isFoul = flags ? flags.indexOf('f') !== -1 : false;
      const isRefereed = flags ? flags.indexOf('r') !== -1 : false;
      if (onlyChangedFrame && id !== frameid) return;
      return (
        <div key={frameid} className={`frame${id === frameid ? ' selected' : ''}`}>
          <div className="framenumber">
            {framenumber}
          </div>
          <div className="result">
            {
              flags
                ? <div className="flags">
                  { isRefereed && <div className="refereed"><RefereedFlag/></div> }
                  { isFoul && <div className="foul"><FoulFlag/></div> }
                  { isChanged && <div className="changed"><ChangedFlag/></div> }
                </div>
                : null
            }
            {
              result.map((shot, index) => (
                <div key={index} className="shot">{shot}</div>
              ))
            }
          </div>
          <div className="score">
            {score}
          </div>
        </div>
      );
    });
    if (!onlyChangedFrame) {
      renderResult.push(
        <div key={frameid} className="frame total">
          <div className="framenumber">
            TOTAL
          </div>
          <div className="result">
            <div className="shot">{scoring[9].score}</div>
          </div>
          <div className="score">
            {scoring[9].score_handicap && scoring[9].score_handicap !== scoring[9].score
              ? <div className="hdc">HDC</div>
              : null
            }
            <div>
              {
                scoring[9].score_handicap && (scoring[9].score_handicap - (Math.floor(scoring[9].score_handicap/300) && (scoring[9].score_handicap%300)))  
                || scoring[9].score
              }
            </div>
          </div>
        </div>,
      );
    } 
    return renderResult;
  }

  gotoProtest = function (squadid, protestid) {
    const { resolved } = this.state;
    this.props.setVideoArchiveSquadId({ squadid, protestid, resolved });
    this.props.history.push('/refereeing');
  }

  render() {
    const { intl } = this.props;
    const { failed, comment, data, showConfirmModal, inProgress, collapse, isReferee } = this.state;
    if (!data) {
      return (
        <div className="protocol"></div>
      );
    }
    const { resolved, squad, protests, winner, loser, winner_score, loser_score, refereeingSessions } = data;
    const { squadid, startdate, tournamentprize, currencysymbol, games, players, tournamenttypeid } = squad;
    let protestList = [];
    let closed = true;
    protests.forEach((p) => {
      if (closed && !p.closed) {
        closed = false;
      }
      const { gameid, frameid, protestCreator, frameOwnerPlayerid } = p;

      const row1 = { ...p, row: 1, protestCreator };
      let row2 = { ...p, row: 2, playerid: frameOwnerPlayerid };
      const frameOwner = players.find(p => p.playerid === frameOwnerPlayerid);
      let row3 = {};
      if (p.accepted && p.closed && (p.refereesId || (isReferee && p.onReview))) {
        const game = refereeingSessions.find(session => session.id === p.refereesId).games.find(game => game.gameid === gameid);
        const scoreBeforeSession = game.scoreBeforeSession[frameOwnerPlayerid];
        const scoreAfterSession = game.scoreAfterSession[frameOwnerPlayerid];
        row2 = {
          ...row2,
          scoreBeforeSession,
          scoreAfterSession,
          frameid,
        };
        if (!collapse[p.id]) {
          row3 = {
            row: 3,
            scoreBeforeSession,
            scoreAfterSession,
            frameid,
            frameOwner,
          };
        }
      }
      protestList.push(row1, row2, row3);
    });
    const translate = {
      title: intl.formatMessage(
        { 
          id: 'ProtocolTitle', 
          defaultMessage: 'Refereeing protocol of party #{id} {date, date, short}'
        }, 
        { 
          id: squadid, 
          date: new Date(startdate),
        }
      ),
      prize: intl.formatMessage({ id: 'Prize', defaultMessage: 'Prize' }),
      close: intl.formatMessage({ id: 'CloseAsCancelAlias', defaultMessage: 'Close' }),
      resolve: intl.formatMessage({ id: 'MakeResolve', defaultMessage: 'Made decision' }),
      gameFrameTime: intl.formatMessage({ id: 'GameFrameTime', defaultMessage: '#, frame, time' }),
      player: intl.formatMessage({ id: 'PlayerNoun', defaultMessage: 'Player' }),
      comment: intl.formatMessage({ id: 'ProtestComment', defaultMessage: 'Protest`s comment' }),
      discussion: intl.formatMessage({ id: 'RefereeComment', defaultMessage: 'Referee`s comment' }),
      file: intl.formatMessage({ id: 'File', defaultMessage: 'File' }),
      status: intl.formatMessage({ id: 'StatusAsStateAlias', defaultMessage: 'Status' }),
      accepted: intl.formatMessage({ id: 'ProtestAccepted', defaultMessage: 'Protest accepted by' }),
      rejected: intl.formatMessage({ id: 'ProtestRejected', defaultMessage: 'Protest rejected by' }),
      failed: intl.formatMessage({ id: 'PartyFailed', defaultMessage: 'Consider game as unhelded due technical or other reasons' }),
      failedText: intl.formatMessage({ id: 'PartyFailedText', defaultMessage: 'Game is unheld due to technical or other reason, so both players will receive their currency back.' }),
      decision: intl.formatMessage({ id: 'PartyComment', defaultMessage: 'Comment on decision' }),
      gameresult: intl.formatMessage({ id: 'GameResult', defaultMessage: 'Game result' }),
      referee: intl.formatMessage({ id: 'Referee', defaultMessage: 'Referee' }),
      goto: intl.formatMessage({ id: 'GoToProtest', defaultMessage: 'Go to protest' }),
      expand: intl.formatMessage({ id: 'ExpandScoring', defaultMessage: 'Expand scoring' }),
      collapse: intl.formatMessage({ id: 'CollapseScoring', defaultMessage: 'Collapse scoring' }),
      expand: intl.formatMessage({ id: 'ExpandScoring', defaultMessage: 'Expand scoring' }),
      collapse: intl.formatMessage({ id: 'CollapseScoring', defaultMessage: 'Collapse scoring' }),
      game: intl.formatMessage({ id: 'GameAtGameOrderContext', defaultMessage: 'Game' }),
      frame: intl.formatMessage({ id: 'Frame', defaultMessage: 'Frame' }),
    };
    const columns = [
      {
        title: translate.gameFrameTime,
        render: (record) => {
          const { row, gamenumber, framenumber, protestdate, id } = record;
          switch (row) {
            case 1:
              return {
                children: 
                  <div>
                    <div className="gamenumber">Protest #{id}</div>
                    <div className="gamenumber">{`${translate.game} ${gamenumber}`}</div>
                    <div className="framenumber">{`${translate.frame} ${intl.formatMessage({ id: 'number', defaultMessage: '#{number}' }, { number: framenumber })}`}</div>
                    <div className="protestdate">{moment(protestdate).format('HH:mm:ss')}</div>
                  </div>,
                props: {
                  className: 'row-1',
                  rowSpan: 2
                }
              }
            case 2:
              return {
                children: <span></span>,
                props: {
                  className: 'row-2',
                  rowSpan: 0
                }
              }
            case 3:
              return {
                children: <span></span>,
                props: {
                  className: 'row-3'
                }
              }
            default:
              return {
                children: <span></span>,
                props: {
                  className: 'empty'
                }
              }
          }
        }
      },
      {
        title: translate.player,       
        render: (record) => {
          const { row, protestCreator, frameOwnerPlayerid } = record;
          switch (row) {
            case 1:
              const { 
                userid, 
                login, 
                firstname, 
                lastname, 
                country_live,
                photo ,
                avgscore
              } = protestCreator;
              return {
                children: 
                  <div className="player">
                    <Avatar 
                      uri={photo ? `${config.FETCH.img}/${photo}` : null } 
                      flag={country_live}
                      avgscore={avgscore}
                    />
                    <div>
                      <div>{`${firstname} ${lastname}`}</div>
                      <div>
                        <Link to="#" onClick={(e) => {
                            e.preventDefault();
                            this.showUser(userid)
                          }}
                        >
                          {login}
                        </Link>
                      </div>
                    </div>
                  </div>,
                props: {
                  className: `row-${row}`
                }
              }
            case 2:
              const { 
                userid: playeruserid, 
                userlogin, 
                userfirstname, 
                userlastname, 
                usercountry,
                userphoto,
                avgscore: useravg,
              } = players.find(p => p.playerid === frameOwnerPlayerid);
              return {
                children: 
                  <div className="player">
                    <Avatar 
                      uri={userphoto ? `${config.FETCH.img}/${userphoto}` : null }
                      flag={usercountry}
                      avgscore={useravg}
                    />
                    <div>
                      <div>{`${userfirstname} ${userlastname}`}</div>
                      <div>
                        <Link to="#" onClick={(e) => {
                            e.preventDefault();
                            this.showUser(playeruserid)
                          }}
                        >
                          {userlogin}
                        </Link>
                      </div>
                    </div>
                  </div>,
                props: {
                  className: `row-${row}`
                }
              }
            case 3:
              return {
                children: <span></span>,
                props: {
                  className: 'row-3'
                }
              }
            default:
              return {
                children: <span></span>,
                props: {
                  className: 'empty'
                }
              }
          }
        }
      },
      {
        title: translate.comment,
        render: (record) => {
          const { row, complaint, reply, category, scoreBeforeSession, frameid, frameOwner } = record;
          switch (row) {
            case 1:
              const { name: reason } = category;
              return {
                children:                 
                  <div className="protest_comment">
                    <div className="reason">
                      {intl.formatMessage({ id: reason, defaultMessage: reason })}
                    </div>
                    <div className="complaint">{complaint}</div>
                  </div>,
                props: {
                  className: 'row-1'
                }
              }
            case 2:
              return {
                children: 
                  <div className="protest_comment">
                    <div className="reason">
                      {intl.formatMessage({ id: 'OpponentAnswer', defaultMessage: 'Player`s answer' })}
                    </div>
                    <div className="complaint">{reply}</div>
                  </div>,
                props: {
                  className: 'row-2'
                }
              }
            case 3:
              const { userid: opponentid, userfirstname, userlastname, userlogin } = frameOwner; 
              return {
                children: 
                  <div style={{ display: 'inline-block', textAlign: 'left' }}>
                    <div style={{ marginBottom: '5px' }}>
                      {`${userfirstname} ${userlastname}`}{' '}
                      <Link to="#" onClick={(e) => {
                          e.preventDefault();
                          this.showUser(opponentid)
                        }}
                      >
                        {userlogin}
                      </Link>
                    </div>
                    <div className="scoring original">
                      {this.renderScoring(scoreBeforeSession, frameid)}
                      <div class="vector">
                        <SvgArrow/>
                      </div>
                    </div>
                  </div>,
                props: {
                  className: 'no-right-padding row-3'
                }
              }
            default:
              return {
                children: <span></span>,
                props: {
                  className: 'empty'
                }
              }
          }
        }
      },
      {
        title: translate.discussion,
        render: (record) => {
          const { id, closed, row, accepted, discussion, referee, scoreBeforeSession, scoreAfterSession, frameid, frameOwner, refereesId, onReview } = record;
          switch (row) {
            case 1:
              const { userid, firstname, lastname } = referee;
              return {
                children:                 
                  <div className="protest_comment">
                    {
                      (closed && ((isReferee && onReview) || refereesId)) &&
                      <div>
                        <div className="referee">
                          {translate.referee}{' '}
                          <Link to="#" onClick={(e) => {
                              e.preventDefault();
                              this.showUser(userid)
                            }}
                          >
                            {`${firstname} ${lastname}`}
                          </Link>
                        </div>
                        <div className="discussion">{discussion}</div>
                      </div>
                    }
                  </div>,
                props: {
                  className: 'row-1'
                }
              }
            case 2:
              if (accepted && (refereesId || (isReferee && onReview))) {
                return {
                  children: 
                    <div>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div className="scoring original">
                          {this.renderScoring(scoreBeforeSession, frameid, true)}
                        </div>
                        <SvgArrow style={{ margin: '0 10px' }}/>
                        <div className="scoring">
                          {this.renderScoring(scoreAfterSession, frameid, true)}
                        </div>
                      </div>
                      <div className="collapse-link" onClick={() => { this.collapse(id) }}>
                        {
                          collapse[id] 
                          ? 
                          <div class="collapse">
                            <div>
                              {translate.expand}
                            </div>
                            <div>
                              <svg width="12" height="5" viewBox="0 0 12 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 5L0.803848 0.5L11.1962 0.499999L6 5Z" fill="#F65932"></path>
                              </svg>
                            </div>
                          </div>
                          :
                          <div class="collapse">
                            <div>
                              {translate.collapse}
                            </div>
                            <div>
                              <svg width="12" height="5" viewBox="0 0 12 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 0L11.1962 4.5L0.803847 4.5L6 0Z" fill="#F65932"/>
                              </svg>
                            </div>
                          </div>
                        }
                      </div>
                    </div>,
                  props: {
                    className: 'row-2'
                  }
                }
              } else {
                return {
                  children: <span></span>,
                  props: {
                    className: 'row-2'
                  }
                }
              }
            case 3:
              const { userid: opponentid, userfirstname, userlastname, userlogin } = frameOwner; 
              return {
                children: 
                  <div>
                    <div style={{ marginBottom: '5px' }}>
                      {`${userfirstname} ${userlastname}`}{' '}
                      <Link to="#" onClick={(e) => {
                          e.preventDefault();
                          this.showUser(opponentid)
                        }}
                      >
                        {userlogin}
                      </Link>
                    </div>
                    <div className="scoring">
                      { this.renderScoring(scoreAfterSession, frameid) }
                    </div>
                  </div>,
                props: {
                  className: 'row-3'
                }
              }
            default:
              return {
                children: <span></span>,
                props: {
                  className: 'empty'
                }
              }
          }
        }
      },
      {
        title: translate.file,
        width: 40,
        align: 'center',
        render: (record) => {
          const { row, filename } = record;
          switch (row) {
            case 1:
              if (filename) {
                return {
                  children: <SvgButton icon="file" onClick={() => { this.downloadFile(filename); }} />,
                  props: {
                    className: 'row-1'
                  }
                }
              } else {
                return {
                  children: <span></span>,
                  props: {
                    className: 'row-1'
                  }
                }
              }
            case 2:
              return {
                children: <span></span>,
                props: {
                  className: 'row-2'
                }
              }
            case 3:
              return {
                children: <span></span>,
                props: {
                  className: 'row-3'
                }
              }
            default:
              return {
                children: <span></span>,
                props: {
                  className: 'empty'
                }
              }
          }
        }
      },
      {
        title: translate.status,
        render: (record) => {
          const { id, row, accepted, closed, referee, revisedIn, onReview, refereesId } = record;
          switch (row) {
            case 1:
              const { userid, firstname, lastname } = referee;
              if (closed && (refereesId || isReferee)) {
                return {
                  children:
                    <div>
                      {revisedIn && `Revised in protest #${revisedIn}`}
                      <div className="protest_status">
                        {
                          !onReview ? isReferee &&
                          <div className="protest_status__button">
                            <SvgButton icon="play" onClick={() => { this.gotoProtest(squadid, id) }} />
                          </div>
                          :
                          <div className="protest_status__button">
                            <SvgButton icon="edit" onClick={() => { this.gotoProtest(squadid, id) }} />
                          </div>
                        }
                        <div className="protest_status__link">
                          <div>
                            {accepted ? translate.accepted : translate.rejected}
                          </div>
                          <div>              
                            <Link to="#" onClick={(e) => {
                                e.preventDefault();
                                this.showUser(userid)
                              }}
                            >
                              {`${firstname} ${lastname}`}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>,
                  props: {
                    className: 'row-1'
                  }
                }
              } else {
                if (record.refereesId) {
                  return {
                    children: 
                      <div className="protest_status">
                        <Button kind="aquamarine" size="md" 
                          onClick={() => {
                            this.gotoProtest(squadid, id);
                          }}
                        >
                          {translate.goto}
                        </Button>
                      </div>,
                    props: {
                      className: 'row-1'
                    }
                  }
                }
                return {
                  children: 
                    <div className="protest_status">
                      Not Reviewed
                    </div>,
                  props: {
                    className: 'row-1'
                  }
                }
              }
            case 2:
              return {
                children: <span></span>,
                props: {
                  className: 'row-2'
                }
              }
            case 3:
              return {
                children: <span></span>,
                props: {
                  className: 'row-3'
                }
              }
            default:
              return {
                children: <span></span>,
                props: {
                  className: 'empty'
                }
              }
          }
        }
      }
    ];
    return(
      <div className={`protocol${resolved ? ' resolved' : ''}`}>
        <div className="protocol_header">
          <div className="protocol_header_left">
            <div className="back-arrow" onClick={() => { this.goBack() }}>
              <SvgBack/>
            </div>
            <div className="protocol_title">
              <div>{translate.title}</div>
            </div>
          </div>
          <div className="protocol_header_center">
            <div className="match-score">
              <div className="game-result">
                <div className="result">{translate.gameresult}</div>
                { 
                  tournamentprize > 0 
                    ? <div className="prize">{`${translate.prize} - ${tournamentprize}${currencysymbol}`}</div>
                    : null
                }
              </div>
              <MatchScore
                isObserver
                isReferee
                players={
                  players.length === 1
                    ? [
                      {
                        login: winner.userlogin,
                        firstname: winner.userfirstname,
                        lastname: winner.userlastname,
                        country_live: winner.usercountry,
                        ratingsavg: +winner.userrating,
                        photo: winner.userphoto,
                        id: winner.userid,
                        userid: winner.userid,
                        gameswin: winner_score,
                        delays: 0,
                        avgscore: (+winner.avgscore || 0).toFixed(),
                      },
                    ]
                    : [
                      {
                        login: winner.userlogin,
                        firstname: winner.userfirstname,
                        lastname: winner.userlastname,
                        country_live: winner.usercountry,
                        ratingsavg: +winner.userrating,
                        photo: winner.userphoto,
                        id: winner.userid,
                        userid: winner.userid,
                        gameswin: winner_score,
                        delays: 0,
                        avgscore: (+winner.avgscore || 0).toFixed(),
                      },
                      {
                        login: loser.userlogin,
                        firstname: loser.userfirstname,
                        lastname: loser.userlastname,
                        country_live: loser.usercountry,
                        ratingsavg: +loser.userrating,
                        photo: loser.userphoto,
                        id: loser.userid,
                        userid: loser.userid,
                        gameswin: loser_score,
                        delays: 0,
                        avgscore: (+loser.avgscore || 0).toFixed(),
                      },
                    ].sort((a, b) => {
                      if (a.id > b.id) {
                        return 1; }
                      if (a.id < b.id) {
                        return -1; }
                      return 0;
                    })
                  }
              />
            </div>
          </div>
          <div className="protocol_header_right">
            <Button
              kind="aquamarine"
              size="md"
              onClick={() => {
                const choosenLang = this.props.lang === 'RU' ? 'EN' : 'RU';
                this.props.ChangeLang(choosenLang);
              }}
            >
              {this.props.lang === 'RU' ? 'EN' : 'RU'}
            </Button>
          </div>
        </div>
        <div className="protocol_body">
          <Table
            key={this.props.langs}
            className="protocol_table"
            rowClassName="protocol_table_row"
            columns={columns}
            dataSource={protestList}
            pagination={false}
            rowKey={record => record.id}
          />
        </div>
        {
          closed &&
          <div className="protocol_footer">
            {
              !resolved
              ? 
              <div className="protocol_footer_group">
                <div className="party-comment">
                  <label for="party-comment">{translate.decision}</label>
                  <textarea
                    id="party-comment"
                    className="textarea"
                    value={comment}
                    onChange={(e) => {
                      this.setState({ comment: e.target.value })
                    }}
                  />
                </div>
              </div>
              : (failed || comment) ?
                <div className="protocol_footer_group">
                  <div className="party-result">
                    <div className="party-result-title">
                      {translate.decision}
                    </div>
                    {
                      failed &&
                      <div>{translate.failedText}</div>
                    }
                    <div>{comment}</div>
                  </div>
                </div>
              : null
            }
            { !resolved && 
              <div className="protocol_footer_group">
                <div className="protocol_footer_checkbox">
                  <Checkbox checked={failed} onChange={(checked) => {
                    this.setState({ failed: checked })
                  }}>
                    {translate.failed}
                  </Checkbox>
                </div>
                <div className="protocol_footer_buttons">
                  <Button
                    kind="aquamarine"
                    size="md"
                    loading={inProgress}
                    onClick={() => { 
                      this.onSubmit(false)
                    }}
                  >
                    {translate.resolve}
                  </Button>
                </div>
              </div>
            }
          </div>
        }
        {
          showConfirmModal &&
          <ModalProtocolDecision
            visible
            winner={winner}
            loser={loser}
            winner_score={winner_score}
            loser_score={loser_score}
            failed={failed}
            onReject={() => {
              this.setState({ showConfirmModal: false })
            }}
            onAccept={() => {
              this.onSubmit(true)
            }}
          />
        }
      </div>
    );
  }
}

export default injectIntl(withRouter(
  connect(
    state => ({
      lang: state.langs.lang,
      protocol: state.protocol,
      user: state.users.user
    }),
    {
      UserByIdFetch,
      makeResolve,
      ChangeLang,
      getProtocol,
      setVideoArchiveSquadId,
      setProtocolSquadId,
      getTournamentTypeList,
    },
  )(ProtocolPage),
));