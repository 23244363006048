import React from 'react';
import moment from "moment";

const DateTD = ({date}) => {
    return (
        date ?
            <div className={'referral-table__check-td'}>
                { moment(date).format("DD.MM.YYYY") }
            </div>
            :
            <>
            </>

    );
};

export default DateTD;
