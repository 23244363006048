import Utils from './utils';

const framework = Utils.getFramework();

let Keyboard = null;
let deviceWidth = 0;
let deviceHeight = 0;
let Platform;
let platform = '';
// if (framework === 'ReactNative') {
//   ({ Keyboard, Platform } = require('react-native'));
//   ({ deviceHeight, deviceWidth } = require('../utils/helper'));
//   platform = Platform.OS;
// }

export default { Keyboard, deviceHeight, deviceWidth, platform };
