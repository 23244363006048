import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import OBModal from '../../Modal';
import './modalServerInactive.css';

class ModalServerInactive extends React.Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    intl: intlShape,
  }

  static defaultProps = {
    visible: false,
  }

  dummy = () => {}

  render() {
    const { intl, visible } = this.props;
    return (
      <OBModal
        title={intl.formatMessage({ id: 'server_inactive_title', defaultMessage: 'Server is not available' })}
        visible={visible}
        width={395}
        onOk={this.dummy}
        onCancel={this.dummy}
        className={'server_inactive_modal'}
        closable={false}
        footer={null}
      >
        <div className="server_inactive_modal_message">
          {intl.formatMessage({ id: 'server_inactive_message', defaultMessage: 'Unfortunately, the connection with the server is lost, please wait!' })}
        </div>
      </OBModal>
    );
  }
}

export default injectIntl(
  connect(
    state => ({
      visible: ((state.server||{}).httpServer||{}).status === 'inactive',
    }),
    {},
  )(ModalServerInactive),
);
