/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import axios from 'axios';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import BillingBadge from '../../common/BillingBadge';
import { Button } from '../../common/Button';
import Select from '../../common/Select';
import PaymentForm from '../../paymentGate/payform.js';
import CreditCardPicker from '../../common/CardPicker';
import config from '../../../config';
import WalletHistory from '../walletHistory';
import {
  getCommission,
  getPayPalOrder,
  clearPayPalOrder,
  createIBANpayment,
  payseraOrderRequest,
} from '../../../libs/reducers/actions';
import { post } from '../../../libs/Networking'
import OBModal from '../../common/Modal';
import envelop from '../../../images/envelop.png';

import './style.scss';
import PersonalDataAddModal from '../../common/CardPicker/personal-data-add.modal';
import { toIbanEntity, toPersonalData } from '../../common/CardPicker/helpers/iban-helpers';

/**
 * @param {string} [href]
 * @return {Record<string, string>}
 */
const getQuery = (href) => {
  const url = new URL(href || location.href);
  return [...url.searchParams.keys()].reduce((acc, key) => {
    acc[key] = url.searchParams.get(key);
    return acc;
  }, {})
}

/**
 * @param {number} [id]
 * @param {import('../../common/CardPicker/helpers/iban-helpers').IbanEntity[]} [ibans]
 * @return {import('../../common/CardPicker/helpers/iban-helpers').IbanEntity}
 */
const findIbanEntity = (id, ibans) => {
  if (!id || !ibans || !Array.isArray(ibans)) {
    return;
  }
  return ibans.find(v => v.id === id);
}

class CashOperations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: null,
      activeTab: 1,
      iterator: false,
      tokenIterator: false,
      walletid: null,
      ibanid: null,
      method: null,
      accountid: null,
      inputerror: false,
      undergoingPayment: false,
      ibanconfirm: false,
    };
  }

  handleSubmit() {
    let threshold = this.getThreshold();
    if ((!this.state.walletid && this.state.activeTab) || +this.state.amount < threshold || (this.state.activeTab && (+this.state.amount > this.props.accounts.find(acc => this.state.accountid.value === acc.accountid).amount))) {
      this.setState({ inputerror: true });
    } else {
      this.setState({ iterator: true, inputerror: false }, () => this.setState({ iterator: false }))
    }
  }

  handleIBANSubmit() {
    let threshold = this.getThreshold();
    if ((!this.state.ibanid) || +this.state.amount < threshold || (this.state.activeTab && (+this.state.amount > this.props.accounts.find(acc => this.state.accountid.value === acc.accountid).amount))) {
      this.setState({ inputerror: true });
    } else {
      this.setState({ inputerror: false, ibanpersonaldata: true });
    }
  }

  handlePayPalSubmit = () => {
    const { amount } = this.state;
    const { getPayPalOrder } = this.props;
    const threshold = this.getThreshold();
    if (+amount < +threshold) {
      this.setState({ inputerror: true });
    } else {
      this.setState({ inputerror: false });
      getPayPalOrder({ amount });
    }
  }

  handlePayseraSubmit = () => {
    const { amount } = this.state;
    const { payseraOrderRequest } = this.props;
    const threshold = this.getThreshold();
    if (+amount < +threshold) {
      this.setState({ inputerror: true });
    } else {
      this.setState({ inputerror: false });
      // eslint-disable-next-line no-unused-expressions
      payseraOrderRequest && payseraOrderRequest({ amount: +amount * 100 }); // сумма в центах
    }
  }

  getThreshold() {
    const system = ((this.props.commissions||[]).find(system => {
      switch (this.state.method) {
        case 'card':
          return system.name === 'LPB';
        case 'paysera':
          return system.name === 'Paysera';
        case 'iban':
          return system.name === 'IBAN';
        case 'paypal':
          return system.name === 'PayPal';
        default:
          return false;
      }
    })||{});
    if (this.state.activeTab) {
      return system.refundthreshold;
    } else {
      return system.intakethreshold;
    }
  }

  handleIBANConfirm() {
    const { amount, accountid, ibanid, method, personalData } = this.state;
    const { method: _ibanType, iban: _ibanValue, id: _ibanId, ...data } = toIbanEntity('', personalData, 'iban')
    this.props.createIBANpayment({ 
      ibanid,
      amount,
      accountid: accountid.value,
      type: this.state.activeTab ? 'out' : 'in',
      paySys: method,
      ...data,
    })
    this.setState({
      amount: 0,
      activeTab: 2,
      method: null,
      ibanid: null,
      walletid: null,
    });
  }

  componentDidMount() {
    this.props.getCommission();
    this.props.clearPayPalOrder();
    const query = getQuery()
    if (query.tab === 'history') {
      this.setState({
        activeTab: 2,
        method: null,
        ibanid: null,
        walletid: null,
        TCAgreement: false,
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.state.accountid && this.props.accounts) {
      const account = this.props.accounts.find(acc => !acc.currencyisvirtual);
      if (account) {
        this.setState({ accountid: { value: account.accountid, label: account.currencysymbol, amount: account.amount } })
      }
    }
    if (this.props.payPalOrder && !prevProps.payPalOrder) {
      const link = this.props.payPalOrder;
      window.location.href = link;
    }
    const { payseraOrder } = this.props;
    if (payseraOrder && !payseraOrder.fetching && !payseraOrder.error && payseraOrder.url && prevProps.payseraOrder.fetching) {
      window.location = payseraOrder.url;
    }
  }



  render() {
    const { intl, accounts, user, ibans, paypals } = this.props;
    const {
      amount,
      activeTab,
      iterator,
      walletid,
      accountid,
      inputerror,
      method,
      ibanid,
      ibanconfirm,
    } = this.state;
    let ibanEntity;
    switch (method) {
      case 'iban':
        ibanEntity = findIbanEntity(ibanid, ibans);
        break;
      case 'paypal':
        ibanEntity = findIbanEntity(ibanid, paypals);
        break;
    }
    const personalData = toPersonalData(ibanEntity)

    const findSystem = (system, idx) => {
      switch (method) {
        case 'card':
          return system.name === 'LPB';
        case 'paysera':
          return system.name === 'Paysera';
        case 'iban':
          return system.name === 'IBAN';
        case 'paypal':
          return system.name === 'PayPal';
        default:
          return false;
      }
    }

    const system = (this.props.commissions || []).find(findSystem) || {};
    const {
      intakethreshold,
      intakecommission,
      refundthreshold,
      refundcommission,
    } = system;

    let availableWithdraw;
    if (accounts && refundcommission){
      const commissionPercent = Number(refundcommission.replace('%', '')) / 100
      let euroAcc = accounts.find(acc => !acc.currencyisvirtual)
      availableWithdraw = euroAcc.amount 
    }
    return (
      <div className="transactionScreen">
        <Tabs
          selectedIndex={activeTab - 1}
          onSelect={index =>
            this.setState({
              activeTab: index + 1,
              method: null,
              ibanid: null,
              walletid: null,
              TCAgreement: false,
            })
          }
        >
          <TabList>
            {(user.is_adult && (
              <>
                {/* <Tab>
                <div>{intl.formatMessage({ id: 'CashDeposit', defaultMessage: 'Deposit' })}</div>
              </Tab> */}
                <Tab>
                  <div>
                    {intl.formatMessage({
                      id: 'CashWithdraw',
                      defaultMessage: 'Withdraw',
                    })}
                  </div>
                </Tab>
              </>
            )) ||
              ''}
            <Tab>
              <div>
                {intl.formatMessage({
                  id: 'HistoryTab',
                  defaultMessage: 'History',
                })}
              </div>
            </Tab>
          </TabList>
          {(user.is_adult && (
            <>
              {/* <TabPanel>
                <div className="transactionWrap">
                  <div className="transactionForm">
                    <div className="label">
                      {intl.formatMessage({
                        id: 'DepositAmountInputLabel',
                        defaultMessage: 'Input deposit amount',
                      })}
                    </div>
                    <div className="inputgroup">
                      <input
                        className={`${inputerror ? 'error' : ''}`}
                        onChange={e => {
                          if (
                            /^0/.test(e.target.value) &&
                            !/^0(\.|\,|$)/.test(e.target.value)
                          ) {
                            this.setState({
                              amount: e.target.value.replace('0', ''),
                            });
                          } else if (
                            /^((\d*)|((\d*)(\.|\,)(\d{0,2})))$/.test(
                              e.target.value,
                            )
                          ) {
                            this.setState({
                              amount: e.target.value.replace(',', '.'),
                            });
                          }
                        }}
                        min={0}
                        value={amount}
                        placeholder=""
                        type="number"
                        step={0.01}
                      />
                      <Select
                        value={accountid}
                        onChange={e => this.setState({ accountid: e })}
                        options={accounts
                          .filter(acc => !acc.currencyisvirtual)
                          .map(acc => ({
                            label: acc.currencysymbol,
                            value: acc.accountid,
                          }))}
                      />
                    </div>
                    // Блок отображения суммы комиссии 
                    {intakecommission && (
                      <div className="commission">
                        <span>
                          {intl.formatMessage({
                            id: 'BankCommissionIntake',
                            defaultMessage: 'Bank commission on cash deposit',
                          })}
                        </span>
                        <b>{intakecommission}</b>
                      </div>
                    )}
                    {intakethreshold && (
                      <div className="commission">
                        {' '}
                        // Блок отображения минимальной суммы
                        <span>
                          {intl.formatMessage({
                            id: 'MinimalIntakeAmount',
                            defaultMessage: 'Minimal deposit amount',
                          })}
                        </span>
                        <b>{intakethreshold} EUR</b>
                      </div>
                    )}
                    {(() => {
                      switch (method) {
                        case 'card':
                          return (
                            <Button
                              loading={this.props.fetchingData}
                              kind="aquamarine"
                              bordered={false}
                              onClick={() => this.handleSubmit()}
                            >
                              {intl.formatMessage({
                                id: 'PaymentInitButton',
                                defaultMessage: 'Proceed to payment',
                              })}
                            </Button>
                          );
                        case 'iban':
                          return (
                            <Button
                              loading={this.props.fetchingData}
                              kind="aquamarine"
                              bordered={false}
                              disabled={!ibanid}
                              onClick={() => this.handleIBANSubmit()}
                            >
                              {intl.formatMessage({
                                id: 'PaymentInitButton',
                                defaultMessage: 'Proceed to payment',
                              })}
                            </Button>
                          );
                        case 'paypal':
                          return (
                            <Button
                              loading={this.props.fetchingPayPal}
                              kind="aquamarine"
                              bordered={false}
                              disabled={!this.state.TCAgreement}
                              onClick={this.handlePayPalSubmit}
                            >
                              {intl.formatMessage({
                                id: 'PaymentInitButton',
                                defaultMessage: 'Proceed to payment',
                              })}
                            </Button>
                          );
                        case 'paysera':
                          return (
                            <Button
                              loading={this.props.payseraOrder.fetching}
                              kind="aquamarine"
                              bordered={false}
                              disabled={!this.state.TCAgreement}
                              onClick={this.handlePayseraSubmit}
                            >
                              {intl.formatMessage({
                                id: 'PaymentInitButton',
                                defaultMessage: 'Proceed to payment',
                              })}
                            </Button>
                          );
                        default:
                          return (
                            <Button
                              loading={this.props.fetchingData}
                              kind="aquamarine"
                              bordered={false}
                              disabled
                              onClick={() => {}}
                            >
                              {intl.formatMessage({
                                id: 'PaymentInitButton',
                                defaultMessage: 'Proceed to payment',
                              })}
                            </Button>
                          );
                      }
                    })()}
                    <OBModal
                      title={intl.formatMessage({
                        id: 'PaymentProcessingTitle',
                        defaultMessage: 'Payment processing',
                      })}
                      visible={this.state.undergoingPayment}
                      noClose
                      onOk={() => {}}
                      closable={false}
                      width={395}
                      onCancel={() => this.setState({ dropConfirm: false })}
                      footer={[]}
                    >
                      <div className="paymentText">
                        {intl.formatMessage({
                          id: 'PaymentProcessingText',
                          defaultMessage:
                            'Please wait, payment being under process',
                        })}
                      </div>
                    </OBModal>
                  </div>
                  <div className="transactionForm">
                    <CreditCardPicker
                      value={{ id: walletid || ibanid, method }}
                      type="deposit"
                      withIban
                      withPayPal
                      TCAgreement={this.state.TCAgreement}
                      onChangeTCAgreement={value =>
                        this.setState({ TCAgreement: value })
                      }
                      onSelect={e => {
                        if (e.type === 'card') {
                          this.setState({
                            walletid: e.value ? e.value.id : null,
                            ibanid: null,
                            method: 'card',
                          });
                        } else if (e.type === 'iban') {
                          this.setState({
                            ibanid: e.value ? e.value.id : null,
                            walletid: null,
                            method: 'iban',
                          });
                        } else if (e.type === 'PayPal') {
                          this.setState({ method: 'paypal' });
                        } else if (e.type === 'paysera') {
                          this.setState({ method: 'paysera' });
                        }
                        this.setState({ TCAgreement: false });
                      }}
                      destination={'cash'}
                    />
                  </div>
                </div>
                <BillingBadge />
              </TabPanel> */}
              <TabPanel>
                <div className="transactionWrap">
                  <div className="transactionForm">
                    <div className="label">
                      {intl.formatMessage({
                        id: 'WithdrawAmountInputLabel',
                        defaultMessage: 'Input withdraw amount',
                      })}
                    </div>
                    <div className="inputgroup">
                      <input
                        className={`${inputerror ? 'error' : ''}`}
                        onChange={e => {
                          if (
                            /^0/.test(e.target.value) &&
                            !/^0(\.|\,|$)/.test(e.target.value)
                          ) {
                            this.setState({
                              amount: e.target.value.replace('0', ''),
                            });
                          } else if (
                            /^((\d*)|((\d*)(\.|\,)(\d{0,2})))$/.test(
                              e.target.value,
                            )
                          ) {
                            this.setState({
                              amount: e.target.value.replace(',', '.'),
                            });
                          }
                        }}
                        min={0}
                        value={amount}
                        placeholder=""
                        type="number"
                        step={0.01}
                      />
                      <Select
                        value={accountid}
                        onChange={e => this.setState({ accountid: e })}
                        options={accounts
                          .filter(acc => !acc.currencyisvirtual)
                          .map(acc => ({
                            label: acc.currencysymbol,
                            value: acc.accountid,
                          }))}
                      />
                    </div>
                    {/* {refundcommission && (
                      <div className="commission">
                        {' '}
                        
                        <span>
                          {intl.formatMessage({
                            id: 'BankCommission',
                            defaultMessage: 'Bank commission on cash withdraw',
                          })}
                        </span>
                        <b>{refundcommission}</b>
                      </div>
                    )} */}
                    {/* {availableWithdraw && refundcommission && (
                      <div className="commission">
                        {' '}
                        <span>Available for withdrawal</span>
                        <b>{availableWithdraw.toFixed(2)} EUR</b>
                      </div>
                    )} */}
                    {refundthreshold && (
                      <div className="commission">
                        {' '}
                        {/*Блок отображения минимальной суммы*/}
                        <span>
                          {intl.formatMessage({
                            id: 'MinimalWithdrawAmount',
                            defaultMessage: 'Minimal withdraw amount',
                          })}
                        </span>
                        <b>{refundthreshold} EUR</b>
                      </div>
                    )}
                    {(() => {
                      switch (method) {
                        case 'card':
                          return (
                            <Button
                              loading={this.props.fetchingData}
                              kind="aquamarine"
                              bordered={false}
                              onClick={() => this.handleSubmit()}
                            >
                              {intl.formatMessage({
                                id: 'PaymentInitButton',
                                defaultMessage: 'Proceed to payment',
                              })}
                            </Button>
                          );
                        case 'paypal':
                        case 'iban':
                          return (
                            <Button
                              loading={this.props.fetchingData}
                              kind="aquamarine"
                              bordered={false}
                              disabled={
                                !ibanid ||
                                Number(availableWithdraw) < Number(amount) ||
                                !amount ||
                                refundthreshold > Number(amount)
                              }
                              onClick={() => this.handleIBANSubmit()}
                            >
                              {intl.formatMessage({
                                id: 'PaymentInitButton',
                                defaultMessage: 'Proceed to payment',
                              })}
                            </Button>
                          );
                        case 'paysera':
                          return (
                            <Button
                              loading={this.props.payseraOrder.fetching}
                              kind="aquamarine"
                              bordered={false}
                              disabled={!this.state.TCAgreement}
                              onClick={() => {
                                // TODO
                              }}
                            >
                              {intl.formatMessage({
                                id: 'PaymentInitButton',
                                defaultMessage: 'Proceed to payment',
                              })}
                            </Button>
                          );
                        default:
                          return (
                            <Button
                              loading={this.props.fetchingData}
                              kind="aquamarine"
                              bordered={false}
                              disabled
                              onClick={() => {}}
                            >
                              {intl.formatMessage({
                                id: 'PaymentInitButton',
                                defaultMessage: 'Proceed to payment',
                              })}
                            </Button>
                          );
                      }
                    })()}
                  </div>
                  <div className="transactionForm">
                    <CreditCardPicker
                      value={{ id: walletid || ibanid, method }}
                      type="withdraw"
                      noCardSelect
                      disableCards
                      withIban
                      withPayPal
                      TCAgreement={this.state.TCAgreement}
                      onChangeTCAgreement={value =>
                        this.setState({ TCAgreement: value })
                      }
                      onSelect={e => {
                        if (e.type === 'card') {
                          this.setState({
                            walletid: e.value ? e.value.id : null,
                            ibanid: null,
                            method: 'card',
                          });
                        } else if (e.type === 'iban') {
                          this.setState({
                            ibanid: e.value ? e.value.id : null,
                            walletid: null,
                            method: 'iban',
                          });
                        } else if (e.type === 'paypal') {
                          this.setState({
                            ibanid: e.value ? e.value.id : null,
                            walletid: null,
                            method: 'paypal',
                          });
                        } else if (e.type === 'paysera') {
                          this.setState({ method: 'paysera' });
                        }
                        this.setState({ TCAgreement: false });
                      }}
                      destination={'cash'}
                    />
                  </div>
                </div>
                <BillingBadge />
              </TabPanel>
            </>
          )) || <></>}
          <TabPanel className="unpadded">
            <div className="walletHistory">
              <WalletHistory />
            </div>
          </TabPanel>
        </Tabs>
        <PaymentForm
          tokenid={this.state.walletid}
          orderAmount={
            amount
              ? activeTab
                ? -Math.floor(amount * 100)
                : Math.floor(amount * 100)
              : 0
          }
          accountid={(accountid || { value: null }).value}
          notification={'I agree with the fact, that you operate with my money'}
          is3d
          descriptor={'TenPinCam'}
          description={activeTab ? 'TenPinCam withdrawal' : 'TenPinCam deposit'}
          iterator={iterator}
        />
          <PersonalDataAddModal
            key={`${method}-${ibanid}`}
            isVisible={this.state.ibanpersonaldata}
            {...personalData}
            onCancel={() => {
              this.setState({ ibanpersonaldata: false })
            }}
            onAdd={(data) => {
              this.setState({ personalData: data, ibanpersonaldata: false }, () => {
                this.setState({ ibanconfirm: true });
              })
            }}
          />
        <OBModal
          title={
            activeTab
              ? intl.formatMessage(
                  {
                    id: 'IBANConfirmationModalTitleOUT',
                    defaultMessage: 'Withdraw',
                  },
                  {
                    amount: this.state.depositAmount,
                  },
                )
              : intl.formatMessage(
                  {
                    id: 'IBANConfirmationModalTitleIN',
                    defaultMessage: 'Deposit',
                  },
                  {
                    amount: this.state.depositAmount,
                  },
                )
          }
          visible={ibanconfirm}
          noClose
          onOk={() =>
            this.setState({ ibanconfirm: false }, () =>
              this.handleIBANConfirm(),
            )
          }
          closable={false}
          footer={<>
            <div style={{ display: 'flex' }}>
              <Button
                style={{ flex: '1 1 50%', marginRight: 11 }}
                kind="aquamarine"
                bordered="true"
                onClick={() => this.setState({ ibanconfirm: false })}
              >
                {intl.formatMessage({
                  id: 'CancelAsAbortAlias',
                  defaultMessage: 'Cancel',
                })}
              </Button>
              <Button
                style={{ flex: '1 1 50%' }}
                kind="aquamarine"
                bordered={false}
                onClick={() =>
                  this.setState({ ibanconfirm: false }, () =>
                    this.handleIBANConfirm(),
                  )
                }
              >
                {intl.formatMessage({ id: 'Accept', defaultMessage: 'Accept' })}
              </Button>
            </div>
          </>}
          width={395}
        >
          <div className="paymentText" style={{ marginTop: 15 }}>
            <img src={envelop} />
            {activeTab
              ? intl.formatMessage(
                  {
                    id: 'IBANConfirmationModalTextOut',
                    defaultMessage:
                      'You are going to request transaction of {amount} EUR from your account. Continue?',
                  },
                  {
                    amount,
                  },
                )
              : intl.formatMessage(
                  {
                    id: 'IBANConfirmationModalTextIn',
                    defaultMessage:
                      'You are going to request transaction of {amount} EUR to your account. Continue?',
                  },
                  {
                    amount,
                  },
                )}
            <br />
            {activeTab
              ? intl.formatMessage({
                  id: 'IBANOperationFactModalText',
                  defaultMessage:
                    'Your payment will be processed in 3 bussiness days.',
                })
              : ''}
          </div>
        </OBModal>
      </div>
    );
  }
}

export default injectIntl(
  connect(
    state => ({
      accounts: state.users.user.currencies,
      commissions: state.billing.commissions,
      fetchingPayPal: state.billing.fetchingPayPal,
      payPalOrder: state.billing.payPalOrder,
      payseraOrder: state.billing.payseraOrder,
      ibans: state.billing.userIbans,
      paypals: state.billing.userPaypals,
      fetchingData: state.billing.fetchingData,
      user: state.users.user,
    }),
    {
      getCommission,
      createIBANpayment,
      getPayPalOrder,
      clearPayPalOrder,
      payseraOrderRequest,
    },
  )(CashOperations),
);
