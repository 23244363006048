import React, {useEffect, useState} from 'react';
import { withRouter } from 'react-router-dom';
import VisibleOn from '../../images/visibleOn.png';
import VisibleOf from '../../images/visibleOff.png';
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import './style.scss';
import { setVisibilityBattle } from '../../libs/reducers/actions';
import { Button } from "../common/Button";
import OBModal from "../common/Modal";

function VisibilityButton(props) {
  const { record, setVisibilityBattle, tournamentInfo, intl } = props;
  const { id } = tournamentInfo
  const [showModal, setShowModal] = useState(false);
  const [visibleIcon, setVisibleIcon] = useState(!!record.visible);

  useEffect(() => {
    setVisibleIcon(!!record.visible)
  }, [record.visible]);

  const imgProps = !visibleIcon ? {
    className: 'icon-sizer-on',
    src: VisibleOn,
  } : {
    className: 'icon-sizer-off',
    src: VisibleOf,
  }

  const text = !visibleIcon ? {
    modalHeader: intl.formatMessage({ id: 'modalTitleHideVisible', defaultMessage: 'Hide Game' }),
    modalOk: intl.formatMessage({ id: 'modalBtnHideVisibleGame', defaultMessage: 'Hide Game' }),
    modalDescribe: intl.formatMessage({
      id: 'modalHideVisibleText',
      defaultMessage: 'Are you sure you want to hide the game'
    }),
  } : {
    modalHeader: intl.formatMessage({ id: 'modalTitleShowVisible', defaultMessage: 'Show game' }),
    modalOk: intl.formatMessage({ id: 'modalBtnShowVisibleGame', defaultMessage: 'Show Game' }),
    modalDescribe: intl.formatMessage({
      id: 'modalShowVisibleText',
      defaultMessage: 'Are you sure you want to show the game'
    })
  }

  const modalFooter = () => (
    <div style={{ display: 'flex' }}>
      <Button
        kind='aquamarine'
        bordered='true'
        onClick={() => toggleModal()}>
        {intl.formatMessage({ id: 'BattleCancelModalOkText', defaultMessage: 'Cancel' })}
      </Button>
      <Button
        kind='aquamarine'
        onClick={() => postVisibilityState()}>
        {text.modalOk}
      </Button>
    </div>
  )

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const postVisibilityState = () => {
    setShowModal(!showModal)
    setVisibleIcon(!visibleIcon)
    setVisibilityBattle({ visible: visibleIcon ? null : '1', id: record.id, tournamentid: id ? id : record.tournamentid });
  }

  return (
    <>
      <button
        kind={false}
        className='visibility__button'
        onClick={() => toggleModal()}>
        <img { ...imgProps}/>
      </button>
      <OBModal
        title={text.modalHeader}
        visible={showModal}
        width={395}
        onCancel={() => toggleModal()}
        onClose={() => toggleModal()}
        okText={text.modalOk}
        footer={modalFooter()}
      >
        <p>{text.modalDescribe}
          <br/> id: <span className='idColor'>{record.id}</span> ?
        </p>
      </OBModal>
    </>

  )

}


export default injectIntl(withRouter(
  connect(state => ({ tournamentInfo: state.tournaments.tournamentInfo }
  ), { setVisibilityBattle })(VisibilityButton)
));



