import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputPair from './InputPair';

class InputPairList extends Component {
    static propTypes = {
        data: PropTypes.objectOf(PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ])),
        showSubmitBtn: PropTypes.bool,
        extraLine: PropTypes.bool,
        eventEmitter: PropTypes.object,
        eventType: PropTypes.string,
        onSubmit: PropTypes.func,
    }
    static defaultProps = {
        data: {},
        showSubmitBtn: false,
        extraLine: true,
    }
    constructor(props) {
        super(props);
        this.list = this.data2list(props.data);
        this.state = { iter: 0 };
    }
    componentDidMount() {
        const { eventEmitter, eventType } = this.props;
        if (eventEmitter && eventEmitter.on && eventType) {
            eventEmitter.on(eventType, this.submit);
        }
    }
    componentWillUnmount() {
        const { eventEmitter, eventType } = this.props;
        if (eventEmitter && eventEmitter.off && eventType) {
            eventEmitter.off(eventType, this.submit);
        }
    }
    update = () => {
        const { data } = this.props;
        this.list = this.data2list(data);
        const { iter } = this.state;
        this.setState({ iter: iter + 1 });
    }
    data2list = (data) => Object.keys(data).map(name => ({ name, value: data[name] }));
    render() {
        const { showSubmitBtn, extraLine } = this.props;
        const pairList = this.list.map((m, idx) => (
        <InputPair
            key={idx}
            idx={idx}
            name={m.name}
            value={m.value}
            onChange={this.onChange}
        />));
        if (extraLine) {
            pairList.push((
            <InputPair
                key={this.list.length}
                idx={this.list.length}
                onChange={this.onChange}
            />))
        }
        return (<div>
            {pairList}
            { showSubmitBtn && <button onClick={this.submit}>submit</button>}
        </div>)
    }
    onChange = (params) => {
        const idx = params[0];
        const name = params[1];
        const value = params[2];
        const elm = this.list[idx];
        let { iter } = this.state;
        if (elm) {
            iter += +(elm[name] !== value);
            elm[name] = value;
        } else {
            this.list.push({ [name]: value });
            ++iter;
        }
        if (elm && _isEmpty(elm.name) && _isEmpty(elm.value)) {
            this.list.splice(idx, 1);
        }
        this.setState({ iter });
    }
    submit = () => {
        if (typeof this.props.onSubmit === 'function') {
            this.props.onSubmit(this.list2data());
        }
    }
    list2data = (list) => {
        list = list || this.list || [];
        const data = {};
        let i, li;
        for (i = 0; i < list.length; ++i) {
            li = list[i];
            if (!_isEmpty(li.name)) {
                data[li.name] = li.value;
            }
        }
        return data;
    }
}

function _isEmpty(v) { return v === '' || v === undefined; }

export default InputPairList;
