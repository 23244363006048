/* eslint-disable */
import React, {useEffect, useReducer, useState} from 'react';
import {connect} from 'react-redux';
import config from '../../../config';
import {Button} from '../../common/Button';

import 'antd/lib/upload/style/index.css';
import './style.css';

import plus from '../../../images/whitePlus.png';
import gear from '../../../images/GearWhite.png';
import Trash from '../../../images/trash.png'; // eslint-disable-line
import {uploadMainBanners, uploadPoster} from '../../../libs/reducers/adminReducer';
import {getSettings} from '../../../libs/reducers/commonReducer';
import {Input, Radio, Select, Upload} from 'antd';
import { getOpenClosedChallenges } from '../../../libs/reducers/actions';


function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}


function MainBannerComponent(props) {
  const {
    process,
    uploadMainBanners,
    uploadPoster,
    getSettings,
    tournaments,
    poster,
    closedOpenChallanges,
  } = props;

  const [currentBanner, setCurrentBanner] = useState('Login');
  const [bannerImages, setBannerImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [isLoadList, setIsLoadList] = useState(true);
  const [timer, setTimer] = useState(0);
  const [prevProcess, setPrevProcess] = useState(process);
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  const [weightValue, setWeightValue] = useState( selectedImage ? selectedImage.weight : null);

  useEffect(() => {
    props.getOpenClosedChallenges()
  }, [])

  useEffect(() => {
    const getBannerImages = async () => {
      try {
        const url = `${config.FETCH.url}/file/get_banners`;
        const result = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ banner: currentBanner }),
        });

        if (result.ok) {
          const json = await result.json();

          if (json.error) {
            console.error(`Error. ${json.error}`);
            return;
          }
          const { files, timer } = json.result;
          setTimer(timer);
          const imgList = (files || []).map((img, idx) => {
            const name = img.name.match(/(banners\/)([\w-]+)(.\w+)/)[2];

            return {
              uid: '-' + (idx + 1),
              name,
              link: img.appScreen,
              clickable: img.clickable,
              linkType: img.linkType,
              appScreen: img.appScreen,
              appScreenNavOpt: img.appScreenNavOpt,
              navigationUrl: img.navigationUrl,
              status: 'done',
              url: `${config.FETCH.img}/${img.name}`,
              weight: img.weight,
            }
          });
          setBannerImages(imgList);
        } else {
          console.error('Error. Р’ РїСЂРѕС†РµСЃСЃРµ Р·Р°РіСЂСѓР·РєРё Р±Р°РЅРЅРµСЂРѕРІ РІРѕР·РЅРёРєР»Р° РѕС€РёР±РєР°');
        }
      } catch(err) {
        console.error(err);
      }
    }

    if (isLoadList) {
      setIsLoadList(false);
      getBannerImages();
    }
  }, [isLoadList]);

  useEffect(() => {
    if (currentBanner === 'Poster') {
      setBannerImages(
          (poster)
              ? [{
                poster,
                status: 'done',
                url: `${config.FETCH.img}/${poster}`
              }]
              : []
      );
      return;
    };
  }, [poster, isLoadList])

  useEffect(() => {
    if (process) {
      if (process.success && process.success !== prevProcess.success) {
        const message = 'Changes saved';
        if (currentBanner === 'Poster') {
          getSettings();
        }
        setIsLoadList(true);
        setError('');
        setSuccess(message);
        setIsUpdate(true);

        setTimeout(() => {
          setSuccess('');
        }, 3000);
      } else if (process.error && process.error !== prevProcess.error) {
        const message = 'Error';

        setError(message);
        setIsUpdate(true);
        console.error('Error. ', process.error);
      }
      setPrevProcess(process);
    }
  }, [process]);

  const onClearError = () => {
    setError('');
  }

  function clearStates() {
    setSelectedImage(null);
    setWeightValue(null)
  }

  const onChangeImage = async (info) => {
    onClearError();

    const { file, fileList } = info;

    if (file.status === 'uploading') {
      const types = ['image/jpeg', 'image/png'];

      if (!types.some(type => type === file.type)) {
        setError('It\'s not an Image');
        return;
      }
      if (file.size > 10000000) {
        setError('Uploaded image is too big.');
        return;
      }
      file.base64 = await getBase64(file.originFileObj);
    }
    setIsUpdate(true);
    setBannerImages(fileList);
  };

  const onPreviewFile = async (file) => {
    const thumb = await getBase64(file)
    return thumb;
  };

  const onSavePoster = async () => {
    setIsUpdate(false);
    let poster = bannerImages[0];
    if (poster && poster.status === 'uploading' || !poster) {
      uploadPoster({
        base64: poster ? await getBase64(poster.originFileObj) : null
      });
    }
  }

  const onSave = async () => {
    setIsUpdate(false);
    const filesNew = [];
    const filesOld = [];
    let base64List = [];

    bannerImages.map((banner) => {
      if (banner.status === 'uploading') {
        filesNew.push(banner);
      } else {
        const urlArr = banner.url.split('/');
        const lengthArr = urlArr.length;

        const nameFile = (lengthArr > 1)
            ? `${urlArr[lengthArr - 2]}/${urlArr[lengthArr - 1]}`
            : null;

        filesOld.push({
          name: nameFile,
          link: banner.link,
          clickable: banner.clickable,
          linkType: banner.linkType,
          appScreen: banner.appScreen,
          appScreenNavOpt: banner.appScreenNavOpt,
          navigationUrl: banner.navigationUrl,
          weight: Number(banner.weight)? Number(banner.weight) : 1000
        });
      }
    });

    if (filesNew.length) {
      const base64PromiseList = filesNew.map(async (file) => {
        const base64 = await getBase64(file.originFileObj);
        return {
          base64,
          link: file.link,
          clickable: file.clickable,
          linkType: file.linkType,
          appScreen: file.appScreen,
          appScreenNavOpt: file.appScreenNavOpt,
          navigationUrl: file.navigationUrl,
          weight: Number(file.weight)? Number(file.weight) : 1000
        };
      });

      base64List = await Promise.all(base64PromiseList);
    }

    uploadMainBanners({
      banner: currentBanner,
      base64: base64List,
      files: filesOld,
      timer,
    });
    clearStates();
  };

  const onChangeTimer = (e) => {
    setIsUpdate(true);
    setTimer(e.target.value);
  };

  const onDelete = (file) => {
    if (file === selectedImage) {
      clearStates();
    }
    setBannerImages(bannerImages.filter(item => item !== file));
  }
  const appPages = [
    { value: 'TopResults', label: 'Top bowlers and games' },
    { value: 'Tournaments', label: 'Tournaments' },
    { value: 'OpenChallenges', label: 'Open Challenge' },
    { value: 'Matches', label: 'Battles&Challenges' },
    { value: 'UserSettings', label: 'Settings' },
    { value: 'Play', label: 'Play' },
    { value: 'SelectingAnEventToCreate', label: 'Create Game' },
    { value: 'WatchBattles', label: 'Watch ' },
    { value: 'UserRequestNewBowlingCenter', label: 'Request BC' },
    { value: 'InviteFriends', label: 'Invite Friends' },
    { value: 'UserTariffs', label: 'Tarifs' },
  ];
  const mapItem = (item) => ({
    value: item.id,
    label: item.name && item.id ? `${item.name} - ${item.id}` : item.name,
  })
  let tournamentOptions = tournaments.map(mapItem)
  const appScreen = selectedImage && selectedImage.appScreen
  if (appScreen === 'Tournaments') {
    tournamentOptions = tournaments.filter((t) => {
      return !t.isbattle && t.typename !== 'Open Challenge';
    }).map(mapItem)
  } else if (appScreen === 'OpenChallenges') {
    tournamentOptions = closedOpenChallanges.map(mapItem)
  }
  const maxImages = currentBanner === 'Poster' ? 1 : 15;

  const BannerWeight = () => {
    if (!selectedImage.weight) selectedImage.weight = null

    return(
        <div>
          <div className={'text gray weight_label'}> Priority weight</div>
          <input
              type='number'
              value={selectedImage.weight}
              onChange={(e) => {
                setBannerImages(bannerImages.map(banner => {
                  if (banner.name === selectedImage.name){
                    banner.weight = e.target.value
                  }
                  return banner
                } ))
              }}
              className={'text bc_banners_input'}
          />
        </div>
    )
  }

  return (
      <>
        <div className="admin-upload">
          <div>
            <span className='radio-button-label'>Screen</span>
            <div className="admin-upload__input">
              <Radio.Group
                  disabled={isLoadList}
                  value={currentBanner}
                  className="admin-radio"
                  onChange={(e) => {
                    setIsLoadList(true);
                    clearStates();
                    setCurrentBanner(e.target.value);
                  }}
              >
                <Radio.Button value="Login">Login</Radio.Button>
                <Radio.Button value="Lobby">Lobby</Radio.Button>
                <Radio.Button value="Play">Play</Radio.Button>
                <Radio.Button value="Poster">Poster</Radio.Button>
              </Radio.Group>
            </div>
          </div>
          {
            currentBanner !== 'Poster' && (
                <div>
                  <div className='radio-button-label'>Rotation time</div>
                  <div className="admin-upload__input">
                    <Radio.Group value={timer} onChange={onChangeTimer}>
                      {[3, 5, 7].map(item => (
                          <Radio.Button value={item}>{`${item} seconds`}</Radio.Button>
                      ))}
                    </Radio.Group>
                  </div>
                </div>
            )
          }
          <div className="upload-list">
            {
              bannerImages.map((file) => {
                    return (
                        <div className={`upload-item ${file === selectedImage ? 'selected' : ''}`}>
                          <img className="upload-item-image" src={file.url || file.base64}/>
                          <div className="howerImage">
                            {currentBanner !== 'Poster' && (
                                <div
                                    className="howerImageButtom"
                                    onClick={() => {
                                      if (file === selectedImage) {
                                        setSelectedImage(null)
                                      } else {
                                        setSelectedImage(file)}
                                    }
                                    }
                                >
                                  <img src={gear}/>
                                </div>
                            )}
                            <div className="howerImageButtom" onClick={() => onDelete(file)}>
                              <img src={Trash}/>
                            </div>
                          </div>
                        </div>
                    );
                  }
              )}
            {
              bannerImages.length < maxImages
                  ? <Upload
                      listType="picture"
                      fileList={[ ...bannerImages ]}
                      className="admin-upload-conatiner"
                      customRequest={() => {}}
                      onChange={props => onChangeImage(props)}
                      previewFile={onPreviewFile}
                      showUploadList={false}
                  >
                    <div className="upload-item">
                      <img className="plus" src={plus}/>
                    </div>
                  </Upload>
                  : null
            }
          </div>
          {
            (selectedImage && currentBanner !== 'Poster') && (
                <div className="item-datail">
                  {BannerWeight()}
                  <div className="admin-upload__input">
                    <Radio.Group value={selectedImage.clickable || false} onChange={(e) => { selectedImage.clickable = e.target.value; forceUpdate(); }}>
                      <Radio.Button value={true}>Clickable</Radio.Button>
                      <Radio.Button value={false}>Unclickable</Radio.Button>
                    </Radio.Group>
                  </div>
                  {selectedImage.clickable && (
                      <div>
                        <div className="radio-button-label">Leads to</div>
                        <div className="admin-upload__input">
                          <Radio.Group value={selectedImage.linkType} onChange={(e) => {
                            selectedImage.linkType = e.target.value;
                            selectedImage.appScreen = null;
                            selectedImage.navigationUrl = null;
                            forceUpdate();
                          }}>
                            <Radio.Button disabled={currentBanner==='Login'} value={'InApp'}>App Page</Radio.Button>
                            <Radio.Button value={'URL'}>URL</Radio.Button>
                          </Radio.Group>
                        </div>
                        { selectedImage.linkType === 'InApp' &&
                        <div className="select-container">
                          <Select value={selectedImage.appScreen} onChange={value => {selectedImage.appScreen = value; forceUpdate();}} placeholder="Select page">
                            {appPages.map(item => <Select.Option value={item.value}>{item.label}</Select.Option>)}
                          </Select>
                        </div>
                        }
                        {
                          (selectedImage.appScreen === 'Tournaments' || selectedImage.appScreen === 'OpenChallenges') &&
                          <div className="select-container">
                            <Select value={selectedImage.appScreenNavOpt} onChange={value => {selectedImage.appScreenNavOpt = value; forceUpdate();}} placeholder="Tournament">
                              {tournamentOptions.map(item => <Select.Option value={item.value}>{item.label}</Select.Option>)}
                            </Select>
                          </div>
                        }
                        { selectedImage.linkType === 'URL' &&
                        <Input placeholder="enter URL" value={selectedImage.navigationUrl} onChange={(e) => { selectedImage.navigationUrl = e.target.value; forceUpdate(); }}/>
                        }
                      </div>
                  )}
                </div>
            )
          }

          <div className="admin-upload__message">
            {
              error
                  ? (
                      <div
                          className="admin-upload__message-block admin-upload__message-block_error"
                          onClick={onClearError}
                      >{error}</div>
                  ) : null
            }
            {
              success
                  ? (
                      <div className="admin-upload__message-block admin-upload__message-block_success">{success}</div>
                  )
                  : null
            }
          </div>

          <div className="admin-upload__save">
            <Button className="admin-upload-button"
                    onClick={currentBanner !== 'Poster' ? onSave : onSavePoster}
            >
              Save
            </Button>
          </div>
        </div>
      </>
  );
}

export default connect(
    (state) => {
      return {
        tournaments: state.tournaments.tournaments,
        poster: state.common.settings.data.video_poster,
        process: state.admin.processMainBanners,
        closedOpenChallanges: state.battles.closedOpenChallenges.other,
      }
    }, {
      uploadPoster,
      getSettings,
      uploadMainBanners,
      getOpenClosedChallenges,
    }
)(MainBannerComponent);