import Url from 'url';
import moment from 'moment';

function getOrigin(url) {
  const { protocol, host } = Url.parse(url);
  return `${protocol}//${host}`;
}

function getFramework() {
  if (typeof document !== 'undefined') {
    return 'ReactJS';
  }
  if (typeof navigator !== 'undefined' && navigator.product === 'ReactNative') {
    return 'ReactNative';
  }
  return 'NodeJS';
}
function getUA() {
  return window && window.navigator ? window.navigator.userAgent : '';
}
function isSafari() {
  return getUA().indexOf('Safari') !== -1 && !isChrome() && !isAndroid();
}
function isChrome() {
  return getUA().indexOf('Chrome') !== -1;
}
function isAndroid() {
  return getUA().indexOf('Android') !== -1;
}
function isIPhone() {
  return getUA().indexOf('iPhone') !== -1;
}
function getPlatform() {
  return window && window.navigator ? window.navigator.platform : '';
}
const iDEVICES = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'];
function iOS() {
  const platform = getPlatform();
  return iDEVICES.indexOf(platform) !== -1;
}
function isIE() {
  const ua = getUA();
  const idx = ua.indexOf('MSIE');
  if (idx > 0) {
    return parseInt(ua.substring(idx + 5, ua.indexOf('.', idx)), 10);
  }
  if (ua.match(/Trident\/7\./)) {
    return 11;
  }
  return 0;
}
function isMobile() {
  return isAndroid() || isIPhone();
}

function setDebug() {} // dummy

function clog() {} // dummy

function getDate(_date) {
  const date = _date || new Date();
  return {
    year: date.getUTCFullYear(),
    month: date.getUTCMonth() + 1,
    day: date.getUTCDate(),
    hours: date.getUTCHours(),
    minutes: date.getUTCMinutes(),
    seconds: date.getUTCSeconds(),
  };
}
function pad(val, len, _fill) {
  let i;
  let l;
  let s = '';
  const fill = _fill ? `${_fill}`.slice(0, 1) : '0';
  for (i = 0; i < 10; i += 1) {
    s += fill;
  }
  l = len && len > 0 ? +len : 2;
  const vlen = `${val}`.length;
  l = vlen < l ? l : vlen;
  return (s + val).slice(-l);
}
function makeHlsLiveUrl(secure, host, stream) {
  return `${secure ? 'https:' : 'http:'}//${host}/${stream}/index.m3u8`;
}
function makeMseLiveUrl(secure, host, stream) {
  return `${secure ? 'wss:' : 'ws:'}//${host}/${stream}/mse_ld`;
}
function makeArchiveUrl(secure, host, stream, start, _end) {
  const protocol = secure ? 'https:' : 'http:';
  const end = _end || Math.round(Date.now() / 1000);
  const duration = end - start;
  return `${protocol}//${host}/${stream}/video-${start}-${duration}.m3u8`;
}
function isLiveUrl(url) {
  return url ? !/video-\d+-\d+/.test(url) : true;
}
function getHlsLiveUrl(url) {
  const { protocol, pathname, host } = Url.parse(url);
  const pathPattern = pathname.match(/(.*)(:?\/[^/]+)$/)[1];
  const secure = protocol === 'https' || protocol === 'wss';
  const liveUrl = `${secure ? 'https' : 'http'}://${host}${pathPattern}/index.m3u8`;
  return liveUrl;
}
function getMseLiveUrl(url) {
  const { protocol, pathname, host } = Url.parse(url);
  const pathPattern = pathname.match(/(.*)(:?\/[^/]+)$/)[1];
  const secure = protocol === 'https' || protocol === 'wss';
  const liveUrl = `${secure ? 'wss' : 'ws'}://${host}${pathPattern}/mse_ld`;
  return liveUrl;
}
function getArchiveUrl(url, start, _end) {
  const { pathname, host } = Url.parse(url);
  const end = _end || Math.round(Date.now() / 1000);
  const duration = end - start;
  const pathPattern = pathname.match(/(.*)(?:\/[^/]+)$/)[1];
  const archiveUrl = `${this.secure ? 'https' : 'http'}://${host}${pathPattern}/video-${start}-${duration}.m3u8`;
  return archiveUrl;
}

function getFrameResult (shot1, shot2, shot3, returnArray = false) {
  if (shot1 == -1 && returnArray) return [];
  if (shot1 == -1 && !returnArray) return '';
  let _shot1, _shot2, _shot3;
  if (shot1 == 10) {
    _shot1 = 'X';
  } else if (shot1 == 0) {
    _shot1 = '-';
  } else {
    _shot1 = shot1;
  }
  if (shot2 != -1) {
    if (shot2 == 10 && shot1 != 0) {
      _shot2 = 'X';
    } else if (shot1 + shot2 == 10) {
      _shot2 = '/';
    } else if (shot2 == 0) {
      _shot2 = '-'; 
    } else {
      _shot2 = shot2;
    }
  }
  if (shot3 != -1) {
    if (shot3 == 10) {
      _shot3 = 'X';
    } else if (shot2 + shot3 == 10) {
      _shot3 = '/';
    } else if (shot3 == 0) {
      _shot3 = '-'; 
    } else {
      _shot3 = shot3;
    }
  }
  if (shot2 == -1) {
    return !returnArray ? `[ ${_shot1} ]` : [ _shot1 ];
  } else {
    if (shot3 == -1) {
      return !returnArray ? `[ ${_shot1} ${_shot2} ]` : [ _shot1, _shot2 ];
    } else {
      return !returnArray ? `[ ${_shot1} ${_shot2} ${_shot3} ]` : [ _shot1, _shot2, _shot3 ];
    }
  }
}

function parseChatMessage (intl, data) {
  const { 
    event = null, 
    framenumber, 
    shot1, 
    shot2, 
    shot3, 
    oldshot1, 
    oldshot2, 
    oldshot3, 
    text, 
    protestid, 
    reason, 
    require_frame,
    time = 5,
  } = data;
  const newresult = getFrameResult(parseInt(shot1), parseInt(shot2), parseInt(shot3));
  const oldresult = getFrameResult(parseInt(oldshot1), parseInt(oldshot2), parseInt(oldshot3));
  let messageText = null;
  let messageComment = null;
  let translates = {
    chatFrameSubmitText1: intl.formatMessage({ 
      id: 'chatFrameSubmitText1', 
      defaultMessage: 'made a shot, knocking out {shot1}, {shot2} and {shot3} pins in frame #{framenumber}' 
    }, { shot1: shot1, shot2: shot2, shot3: shot3, framenumber: framenumber }),
    chatFrameSubmitText2: intl.formatMessage({ 
      id: 'chatFrameSubmitText2', 
      defaultMessage: 'made a shot, knocking out {shot1} and {shot2} pins in frame #{framenumber}' 
    }, { shot1: shot1, shot2: shot2, framenumber: framenumber }),
    chatFrameSubmitText3: intl.formatMessage({ 
      id: 'chatFrameSubmitText3', 
      defaultMessage: 'made a shot, knocking out a strike in frame #{framenumber}' 
    }, { framenumber: framenumber }),
    chatFrameTimeoutText: intl.formatMessage({ 
      id: 'chatFrameTimeoutText', 
      defaultMessage: 'did not have time to make a throw in the allotted time, frame #{framenumber} is automatically counted as a miss.' 
    }, { framenumber: framenumber }),
    chatFramesReset: intl.formatMessage({ 
      id: 'chatFramesReset', 
      defaultMessage: 'The game ended in a draw, the result of the last frames canceled' 
    }),
    chatProtestCreate: intl.formatMessage({ 
      id: 'chatProtestCreate', 
      defaultMessage: 'created protest #{protestid}' 
    }, { protestid: protestid }),
    chatProtestCancel: intl.formatMessage({ 
      id: 'chatProtestCancel', 
      defaultMessage: 'canceled protest #{protestid}' 
    }, { protestid: protestid }),
    chatYellowCard: intl.formatMessage({ 
        id: 'chatYellowCard', 
        defaultMessage: 'received a yellow card for a game delay of more than {time} minutes.' 
      },
      { time: time}
    ),
    chatRedCard: intl.formatMessage({ 
        id: 'chatRedCard', 
        defaultMessage: 'received a red card for re-delaying the game for more than {time} minutes.' 
      },
      { time: time}
    ),
    chatRedCardLose: intl.formatMessage({ 
      id: 'chatRedCardLose', 
      defaultMessage: 'lost, becouse got red card!' 
    }),
    chatSurrenderLose: intl.formatMessage({ 
      id: 'chatSurrenderLose', 
      defaultMessage: 'surrendered!' 
    }),
    chatPause: intl.formatMessage({ 
      id: 'chatPause', 
      defaultMessage: 'paused for {time} minutes',
    }, { time }),
    chatFrameChangeText: intl.formatMessage({ 
      id: 'chatFrameChangeText', 
      defaultMessage: 'edited result of frame #{framenumber}, changing {oldresult} to {newresult}.' 
    }, { framenumber: framenumber, oldresult: oldresult, newresult: newresult }),
    chatJoin: intl.formatMessage({ 
      id: 'chatJoin', 
      defaultMessage: 'join chat' 
    }),
    chatLeave: intl.formatMessage({ 
      id: 'chatLeave', 
      defaultMessage: 'leave chat' 
    }),
    chatProtestReply: intl.formatMessage({ 
      id: 'chatProtestReply', 
      defaultMessage: 'replyed on protest #{protestid}'
    }, { protestid: protestid }),
  };
  if (protestid && reason) {
    const reasonTranslate = intl.formatMessage({ id: reason, defaultMessage: reason });
    if (parseInt(require_frame) == 1 && framenumber) {
      const n = intl.formatMessage({ id: 'number', defaultMessage: '#{number}' }, { number: framenumber });
      translates.chatProtestCreate += `${' '}(${reasonTranslate} ${n}).`
      translates.chatProtestCancel += `${' '}(${reasonTranslate} ${n}).`
      translates.chatProtestReply  += `${' '}(${reasonTranslate} ${n}).`
    } else {
      translates.chatProtestCreate += `${' '}(${reasonTranslate}).`
      translates.chatProtestCancel += `${' '}(${reasonTranslate}).`
      translates.chatProtestReply  += `${' '}(${reasonTranslate}).`
    }
  }
  switch (event) {
    case 'frameSubmitText':
      if (parseInt(shot3) != -1) {
        messageText = translates.chatFrameSubmitText1;
      } else if (parseInt(shot2) !== -1) {
        messageText = translates.chatFrameSubmitText2;
      } else {
        messageText = translates.chatFrameSubmitText3;
      }
      break;
    case 'frameTimeoutText':
      messageText = translates.chatFrameTimeoutText;
      break;
    case 'framesReset':
      messageText = translates.chatFramesReset;
      break;
    case 'protestCreate':
      messageText = translates.chatProtestCreate;
      messageComment = text;
      break;
    case 'protestCancel':
      messageText = translates.chatProtestCancel;
      break;
    case 'protestReply':
      messageText = translates.chatProtestReply;
      messageComment = text;
      break;
    case 'yellowCard':
      messageText = translates.chatYellowCard;
      break;
    case 'redCard':
      messageText = translates.chatRedCard;
      break;
    case 'redCardLose':
      messageText = translates.chatRedCardLose;
      break;
    case 'surrenderLose':
      messageText = translates.chatSurrenderLose;
      break;
    case 'chatPause':
      messageText = translates.chatPause;
      break;
    case 'frameChangeText':
      messageText = translates.chatFrameChangeText;
      messageComment = text;
      break;
    case 'joinRoom':
      messageText = translates.chatJoin;
      break;
    case 'leaveRoom':
      messageText = translates.chatLeave;
      break;
    case 'supportMessage':
      messageText = text;
      break;
    default:
      messageText = event || text;
  }
  return { messageText, messageComment };
};

function formatDateTime (timestamp, lang, format = 'DD MMM HH:mm') {
  switch (format) {
    case 'DD MMM HH:mm':
      return `${moment(timestamp).format('DD')}${' '}
        ${moment(timestamp).format('MMM').substr(0, 3).toLocaleLowerCase()}${' '}
        ${moment(timestamp).format('HH:mm')}`;
    case 'DD MMM':
      return `${moment(timestamp).format('DD')}${' '}
        ${moment(timestamp).format('MMM').substr(0, 3).toLocaleLowerCase()}`;
    case 'DD MMM YYYY':
      return `${moment(timestamp).format('DD')}${' '}
        ${moment(timestamp).format('MMM').substr(0, 3).toLocaleLowerCase()}${' '}
        ${moment(timestamp).format('YYYY')}`;
    case 'DD MMM YYYY HH:mm':
      return `${moment(timestamp).format('DD')}${' '}
        ${moment(timestamp).format('MMM').substr(0, 3).toLocaleLowerCase()}${' '}
        ${moment(timestamp).format('YYYY HH:mm')}`;
    case 'DD MMM - HH:mm':
      return `${moment(timestamp).format('DD')}${' '}
        ${moment(timestamp).format('MMM').substr(0, 3).toLocaleLowerCase()}${' '}
        ${moment(timestamp).format('– HH:mm')}`;
    default:
      return moment(timestamp).format(format);
  }
}

export default {
  isSafari,
  isChrome,
  isAndroid,
  isIPhone,
  iOS,
  isIE,
  isMobile,
  getUA,
  getPlatform,
  setDebug,
  clog,
  getDate,
  pad,
  getHlsLiveUrl,
  getMseLiveUrl,
  getArchiveUrl,
  isLiveUrl,
  makeArchiveUrl,
  makeHlsLiveUrl,
  makeMseLiveUrl,
  getFramework,
  getOrigin,
  getFrameResult,
  parseChatMessage,
  formatDateTime,
};
