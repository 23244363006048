import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import Modal from '../../Modal';
import { Button } from '../../Button';
import { frameEditReq } from '../../../../libs/reducers/squads.reducer';

import Arrow from '../../../../images/FrameEditArrow.svg';
import Select from '../../Select';

import './style.scss';

const getMsg = (intl, id, defaultMessage, props = {}) => intl.formatMessage({ id, defaultMessage }, { ...props });

class FrameEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: { label: getMsg(props.intl, 'WrongScoring', 'Wrong scoring'), value: 'Wrong scoring' },
      explanation: '',
    };
  }

  static propTypes = {
    visible: PropTypes.bool.isRequired,
    oldframe: PropTypes.object,
    newframe: PropTypes.object,
    framenumber: PropTypes.number,
    onEdit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    intl: intlShape,
  };

  onConfirm = () => {
    const { onEdit, newframe, oldframe } = this.props;
    const { reason, explanation } = this.state;
    onEdit();
    this.props.frameEditReq({
      framenumber: oldframe.framenumber,
      frameid: oldframe.id,
      gameid: oldframe.gameid,
      oldframe: {
        shot1: oldframe.shot1,
        shot2: oldframe.shot2,
        shot3: oldframe.shot3,
        flags: oldframe.flags,
      },
      newframe: {
        shot1: newframe.shot1,
        shot2: newframe.shot2,
        shot3: newframe.shot3,
        flags: `c${newframe.shot1Foal || newframe.shot2Foal || newframe.shot3Foal ? 'f' : ''}`,
      },
      reason: reason.value,
      comments: explanation,
    });
  };

  onCancel = () => {
    const { onClose } = this.props;
    onClose();
  };

  explanationChange = e => this.setState({ explanation: e.target.value });

  onReasonChange = e => this.setState({ reason: e });

  render() {
    const { reason } = this.state;
    const {
      oldframe, newframe, visible, framenumber, intl,
    } = this.props;

    if (!oldframe) {
      return <div />;
    }

    const skittlesold = [];
    if (oldframe.shot1 !== -1) {
      if (oldframe.shot1 === 10) {
        skittlesold.push(<span>X</span>);
      } else if (oldframe.shot1 !== 0) {
        skittlesold.push(<span>{oldframe.shot1}</span>);
      } else {
        skittlesold.push(<span>-</span>);
      }
    }
    if (oldframe.shot2 !== -1) {
      if (oldframe.shot2 + oldframe.shot1 === 10) {
        skittlesold.push(<span>/</span>);
      } else if (oldframe.shot2 === 10) {
        skittlesold.push(<span>X</span>);
      } else if (oldframe.shot2 !== 0) {
        skittlesold.push(<span>{oldframe.shot2}</span>);
      } else {
        skittlesold.push(<span>-</span>);
      }
    }
    if (oldframe.shot3 !== -1) {
      if (oldframe.shot3 === 10) {
        skittlesold.push(<span>X</span>);
      } else if (oldframe.shot1 === 10 && oldframe.shot2 + oldframe.shot3 === 10 && (oldframe.shot3 !== 0)) {
        skittlesold.push(<span>/</span>);
      } else if (oldframe.shot3 !== 0) {
        skittlesold.push(<span>{oldframe.shot3}</span>);
      } else {
        skittlesold.push(<span>-</span>);
      }
    }

    const skittlesnew = [];
    if (newframe.shot1 !== -1) {
      if (newframe.shot1 === 10) {
        skittlesnew.push(<span>X</span>);
      } else if (newframe.shot1 !== 0) {
        skittlesnew.push(<span>{newframe.shot1}</span>);
      } else {
        skittlesnew.push(<span>-</span>);
      }
    }
    if (newframe.shot2 !== -1) {
      if (newframe.shot2 + newframe.shot1 === 10) {
        skittlesnew.push(<span>/</span>);
      } else if (newframe.shot2 === 10) {
        skittlesnew.push(<span>X</span>);
      } else if (newframe.shot2 !== 0) {
        skittlesnew.push(<span>{newframe.shot2}</span>);
      } else {
        skittlesnew.push(<span>-</span>);
      }
    }
    if (newframe.shot3 !== -1) {
      if (newframe.shot3 === 10) {
        skittlesnew.push(<span>X</span>);
      } else if (newframe.shot1 === 10 && newframe.shot2 + newframe.shot3 === 10 && (newframe.shot3 !== 0)) {
        skittlesnew.push(<span>/</span>);
      } else if (newframe.shot3 !== 0) {
        skittlesnew.push(<span>{newframe.shot3}</span>);
      } else {
        skittlesnew.push(<span>-</span>);
      }
    }

    const title = getMsg(intl, 'EditResultOfFrame', 'Edit result of frame #{framenumber}', { framenumber: framenumber });
    const cancelText = getMsg(intl, 'ModalDefaultCancel', 'Cancel');
    const editText = getMsg(intl, 'Edit', 'Edit');
    const selectEditReasonText = getMsg(intl, 'SelectEditReason', 'Select edit reason');
    const ballFromGutterText = getMsg(intl, 'BallFromGutter', 'Ball from Gutter');
    const pinsMissingText = getMsg(intl, 'PinsMissing', 'Pins missing');
    const wrongScoringText = getMsg(intl, 'WrongScoring', 'Wrong scoring');
    const commentText = getMsg(intl, 'comment', 'Comment');
    const explainText = getMsg(intl, 'ExplainSituation', 'Explain situation');
    return (
      <Modal
        title={title}
        visible={visible}
        className="frame-edit__modal"
        closable={false}
        onClose={this.onCancel}
        width={420}
        footer={[
          <div key="diag" className="frame-edit__dialogue">
            <Button kind="aquamarine" className="dialog__button-decline" onClick={this.onCancel}>
              {cancelText}
            </Button>
            <Button disabled={!reason || !reason.value ? 'disabled' : '' } kind="aquamarine" className="dialog__button-accept" onClick={this.onConfirm}>
              {editText}
            </Button>
          </div>,
        ]}
      >
        <div className="frame-edit__score">
          {/* eslint-disable */}
          <div className="frame-edit__framebox">
            <div>{framenumber}</div>
            <div>
              {oldframe.flags.includes('f') ? <div className="frame-edit__flag">F</div> : null}
              {skittlesold}
            </div>
            <div>{oldframe.score}</div>
          </div>
          <img src={Arrow} />
          <div className="frame-edit__framebox">
            <div>{framenumber}</div>
            <div>
              {newframe.shot1Foal || newframe.shot2Foal || newframe.shot3Foal ? (
                <div className="frame-edit__flag">F</div>
              ) : null}
              {skittlesnew}
            </div>
            <div>{newframe.score}</div>
          </div>
          {/* eslint-enable */}
        </div>
        <div className="frame-edit__reason">
          <label>{selectEditReasonText}</label>
          <Select
            value={reason}
            placeholder={selectEditReasonText}
            options={[
              { label: ballFromGutterText, value: 'Ball from Gutter' },
              { label: pinsMissingText, value: 'Missings pins' },
              { label: wrongScoringText, value: 'Wrong scoring' },
            ]}
            onChange={(e) => {
              this.onReasonChange(e);
            }}
          />
        </div>
        <div className="frame-edit__explanation">
          <label htmlFor="frame-edit__explanation__input">{commentText}</label>
          <textarea
            id="frame-edit__explanation__input"
            className="textarea"
            placeholder={explainText}
            type="text"
            value={this.state.explanation}
            onChange={this.explanationChange}
          />
        </div>
      </Modal>
    );
  }
}

export default injectIntl(
  connect(
    state => ({}),
    { frameEditReq },
  )(FrameEditModal),
);
