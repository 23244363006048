import React from 'react';
import PropTypes from 'prop-types';
import Hls from 'hls.js';

export default class HLSSource extends React.Component {
  constructor(props) {
    super(props);
    this.hls = new Hls();
    this.hls.on(Hls.Events.MANIFEST_PARSED, this.onManifestParsed);
  }

  static propTypes = {
    src: PropTypes.string,
    video: PropTypes.instanceOf(HTMLMediaElement),
    type: PropTypes.string,
    autoPlay: PropTypes.bool,
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    const { src } = this.props;
    if (src !== prevProps.src && src) {
      this.destroy();
      this.init();
    }
  }

  componentWillUnmount() {
    this.destroy();
  }

  init = () => {
    const { src, video } = this.props;
    if (Hls.isSupported() && src) {
      this.hls.loadSource(src);
    }
    if (Hls.isSupported() && video) {
      this.hls.attachMedia(video);
    }
  }

  destroy = () => {
    if (this.hls) {
      this.hls.destroy();
    }
  }

  onManifestParsed = () => {
    this.autoPlay();
  }

  autoPlay = async () => {
    const { video, autoPlay } = this.props;
    let retval;
    if (video && autoPlay) {
      retval = video.play();
    }
    return retval;
  }

  render() {
    const { src, type = 'application/x-mpegURL' } = this.props;
    return <source src={src} type={type} />;
  }
}
