import { eventChannel } from 'redux-saga';
import { call, put, take } from 'redux-saga/effects';
import Utils from '../utils';
import RNKeyboard from '../RNKeyboard';

const { Keyboard, deviceHeight, platform } = RNKeyboard;
const framework = Utils.getFramework();

const { KEYBOARD_DID_HIDE, KEYBOARD_DID_SHOW } = require('../reducers/actiontypes').default;

let kbListenerShow;
let kbListenerHide;

export function subscribeForEvents() {
  return eventChannel((emitter) => {
    if (framework !== 'ReactNative' || !Keyboard) {
      return unSubscribe;
    }
    console.log('Keyboard: ', Keyboard);
    kbListenerShow = Keyboard.addListener(platform === 'ios' ? 'keyboardWillShow' : 'keyboardDidShow', (e) => {
      const height = deviceHeight - e.endCoordinates.screenY;
      emitter({ type: KEYBOARD_DID_SHOW, data: height });
    });
    kbListenerHide = Keyboard.addListener(platform === 'ios' ? 'keyboardWillHide' : 'keyboardDidHide', () => {
      emitter({ type: KEYBOARD_DID_HIDE });
    });
    return unSubscribe;
  });
}

export function unSubscribe() {
  if (framework === 'ReactNative' && kbListenerHide && kbListenerShow) {
    kbListenerShow.remove();
    kbListenerHide.remove();
  }
}

export function* keyboardSaga() {
  const channel = yield call(subscribeForEvents);
  while (true) {
    const channelAction = yield take(channel);
    yield put(channelAction);
  }
}
