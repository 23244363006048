import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import InputMask from 'react-input-mask';
import Modal from '../../common/Modal';
import { FilterInput } from '../../common/Input';
import Select from '../../common/Select';
import { Button } from '../../common/Button';

import { GetCities, sendBowlingCenterRequest } from '../../../libs/reducers/actions';

import './modal_style.scss';

class RequestBCModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      requestData: {
        personName: '',
        personPhone: '',
        personEmail: '',
        bcName: '',
        bcPhone: '',
        bcContactPerson: '',
        bcStreet: '',
        bcCountryId: '',
        bcCityId: '',
        bcPostcode: '',
      },
      invalidPersonEmail: false,
      invalidPersonPhone: false,
      invalidContactPhone: false,
      showError: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { sending, onSubmit } = this.props;
    if (prevProps.sending && !sending) {
      onSubmit();
      this.clearRequestData();
    }
  }

  /**
   * Функция фильтрации городов по выбранной стране
   * @param {{ value: number }} country выбранная страна из select box
   */
  selectCountry(country) {
    const { countries, GetCities } = this.props;
    const { requestData } = this.state;
    const _country = countries.find(_country => +_country.id === +country.value);
    GetCities(_country.iso);
    this.setState({ requestData: { ...requestData, ...{ bcCountryId: country.value } } });
  }

  /**
   * Проверка намера телефона по шаблону
   * @param {string} phone - номер телефона который необходимо проверить
   * @returns {boolean} возвращает true если номер телефона прошел проверку либо false
   */
  checkPhone(phone) {
    const regPhone = /^\+?[0-9] ?\(?\d{3}\)? ?\d{3}-?\d{2}-?\d{2}$/g;
    return regPhone.test(phone);
  }

  /**
   * Функция проверки email
   * @param {string} email
   * @returns {boolean} возвращает true если проверка пройдена либо false
   */
  checkEmail(email) {
    const regEmail = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return regEmail.test(email);
  }

  /**
   * Функция проверка введенных данных
   * @returns {boolean} возвращает true если все данные прошли проверку либо false
   */
  checkRequestData() {
    const { requestData } = this.state;
    const regEmail = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let isOkey = true;
    if (!requestData.personName.trim() || !requestData.personPhone.trim() || !requestData.personEmail.trim()
      || !requestData.bcName.trim() || !requestData.bcPhone.trim() || !requestData.bcContactPerson.trim()
      || !requestData.bcStreet.trim() || !requestData.bcCountryId || !requestData.bcCityId) {
      this.setState({ showError: true });
      isOkey = false;
    }

    if (!regEmail.test(requestData.personEmail)) {
      this.setState({ invalidPersonEmail: true });
      isOkey = false;
    } else {
      this.setState({ invalidPersonEmail: false });
    }

    if (!this.checkPhone(requestData.bcPhone)) {
      this.setState({ invalidContactPhone: true });
      isOkey = false;
    } else {
      this.setState({ invalidContactPhone: false });
    }

    if (!this.checkPhone(requestData.personPhone)) {
      this.setState({ invalidPersonPhone: true });
      isOkey = false;
    } else {
      this.setState({ invalidPersonPhone: false });
    }

    this.setState({
      showError: !isOkey,
    });

    return isOkey;
  }

  cancel() {
    this.setState({
      showError: false,
    });
    this.props.onCancel();
  }

  /**
   * Функция отправки запроса на добавление боулинг центра
   */
  sendRequest() {
    // Проверка корректности введенных данных
    if (!this.checkRequestData()) return;
    const { countries, cities, sendBowlingCenterRequest } = this.props;
    const { requestData } = this.state;
    const message = {
      'User Name': requestData.personName,
      'User Email': requestData.personEmail,
      'User Phone': requestData.personPhone,
      'Name of Bowling Alley': requestData.bcName,
      Country: (countries.find(country => country.id === requestData.bcCountryId)).name,
      City: (cities.find(city => +city.id === +requestData.bcCityId)).name,
      Street: requestData.bcStreet,
      'Contact Person': requestData.bcContactPerson,
      'Contact Phone': requestData.bcPhone,
      'Post Code': requestData.bcPostcode || '-',
    };
    sendBowlingCenterRequest({ message });
  }

  clearRequestData() {
    this.setState({
      requestData: {
        personName: '',
        personPhone: '',
        personEmail: '',
        bcName: '',
        bcPhone: '',
        bcContactPerson: '',
        bcStreet: '',
        bcCountryId: '',
        bcCityId: '',
        bcPostcode: '',
      },
      invalidPersonEmail: false,
      invalidPersonPhone: false,
      invalidContactPhone: false,
      showError: false,
    });
  }

  renderFooter() {
    const { intl, sending } = this.props;
    return (
      <div className="footer-btn">
        <Button onClick={() => this.cancel()} disabled={sending} kind="aquamarine">
          {intl.formatMessage({ id: 'close', defaultMessage: 'Close' })}
        </Button>
        <Button onClick={() => this.sendRequest()} loading={sending} kind="aquamarine">
          {intl.formatMessage({ id: 'SendAsOutgoneCommunicationAlias', defaultMessage: 'Send' })}
        </Button>
      </div>
    );
  }

  render() {
    const {
      visible,
      intl,
      countries,
      cities,
      sending,
    } = this.props;
    const {
      requestData,
      showError,
      invalidPersonEmail,
      invalidContactPhone,
      invalidPersonPhone,
    } = this.state;

    return (
      <Modal
        visible={visible}
        wrapClassName="requestbc-modal"
        className="requestbc-modal"
        onOk={() => this.sendRequest()}
        onCancel={() => this.cancel()}
        title={intl.formatMessage({ id: 'orderEquipmentInstall', defaultMessage: 'Order equipment for installation' }).toUpperCase()}
        width={521}
        okText={intl.formatMessage({ id: 'SendAsOutgoneCommunicationAlias', defaultMessage: 'Send' })}
        cancelText={intl.formatMessage({ id: 'close', defaultMessage: 'Close' })}
        footer={this.renderFooter()}
      >
        <div className="requestbc-form">
          <div className="requestbc-form__header">
            {intl.formatMessage({ id: 'OrderTenpincamInstallModalText', defaultMessage: 'Please indicate which bowling center you would like to see on our site. Also leave your contacts. Thanks!' })}
          </div>
          <div className="requestbc-form__body">
            <div className="row">
              <div className="requestbc-form-container-50 mr-15">
                <div className={`form__input-label ${showError && !requestData.personName.trim() ? 'error' : ''}`}>
                  {intl.formatMessage({ id: 'YourName', defaultMessage: 'Your name' })}
                </div>
                <FilterInput
                  disabled={sending}
                  name="name"
                  className={`form__input ${showError && !requestData.personName.trim() ? 'error' : ''}`}
                  value={requestData.personName}
                  onChange={
                    e => this.setState({ requestData: { ...requestData, ...{ personName: e.target.value } } })
                  }
                />
              </div>
              <div className="requestbc-form-container-50">
                <div className={`form__input-label ${showError && (!this.checkPhone(requestData.personPhone.trim()) && invalidPersonPhone) ? 'error' : ''}`}>
                  {intl.formatMessage({ id: 'YourPhone', defaultMessage: 'Your phone' })}
                </div>
                <InputMask
                  disabled={sending}
                  name="phone"
                  className={`form__input input ${showError && (!this.checkPhone(requestData.personPhone.trim()) && invalidPersonPhone) ? 'error' : ''}`}
                  mask="+9 (999) 999-99-99"
                  maskChar="_"
                  placeholder="+7(___)___-__-__"
                  value={requestData.personPhone}
                  onChange={
                    e => this.setState({ requestData: { ...requestData, ...{ personPhone: e.target.value } } })
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="requestbc-form-container-100">
                <div className={`form__input-label ${showError && (!this.checkEmail(requestData.personEmail.trim()) && invalidPersonEmail) ? 'error' : ''}`}>
                  {intl.formatMessage({ id: 'YourEmail', defaultMessage: 'Your email' })}
                </div>
                <FilterInput
                  disabled={sending}
                  name="email"
                  className={`form__input ${showError && (!this.checkEmail(requestData.personEmail.trim()) && invalidPersonEmail) ? 'error' : ''}`}
                  value={requestData.personEmail}
                  invalid={showError && (!this.checkEmail(requestData.personEmail.trim()) && invalidPersonEmail) ? intl.formatMessage({ id: 'UncorrectEmail', defaultMessage: 'Enter the correct E-mail' }) : undefined}
                  onChange={
                    e => this.setState({ requestData: { ...requestData, ...{ personEmail: e.target.value } } })
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="section__title">
                {intl.formatMessage({ id: 'BowlingCenterInformationTitle', defaultMessage: 'Bowling Center Information' })}
              </div>
            </div>
            <div className="row">
              <div className="requestbc-form-container-50 mr-15">
                <div className={`form__input-label ${showError && !requestData.bcName.trim() ? 'error' : ''}`}>
                  {intl.formatMessage({ id: 'NameAsIdentifier', defaultMessage: 'Name' })}
                </div>
                <FilterInput
                  disabled={sending}
                  className={`form__input ${showError && !requestData.bcName.trim() ? 'error' : ''}`}
                  value={requestData.bcName}
                  onChange={
                    e => this.setState({ requestData: { ...requestData, ...{ bcName: e.target.value } } })
                  }
                />
              </div>
              <div className="requestbc-form-container-50">
                <div className={`form__input-label ${showError && (!this.checkPhone(requestData.bcPhone.trim()) && invalidContactPhone) ? 'error' : ''}`}>
                  {intl.formatMessage({ id: 'ContactPhone', defaultMessage: 'Contact phone' })}
                </div>
                <InputMask
                  disabled={sending}
                  name="phone"
                  className={`form__input input ${showError && (!this.checkPhone(requestData.bcPhone.trim()) && invalidContactPhone) ? 'error' : ''}`}
                  mask="+9 (999) 999-99-99"
                  maskChar="_"
                  placeholder="+7(___)___-__-__"
                  value={requestData.bcPhone}
                  onChange={
                    e => this.setState({ requestData: { ...requestData, ...{ bcPhone: e.target.value } } })
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="requestbc-form-container-100">
                <div className={`form__input-label ${showError && !requestData.bcContactPerson.trim() ? 'error' : ''}`}>
                  {intl.formatMessage({ id: 'ContactPerson', defaultMessage: 'Contact person' })}
                </div>
                <FilterInput
                  disabled={sending}
                  name="name"
                  className={`form__input ${showError && !requestData.bcContactPerson.trim() ? 'error' : ''}`}
                  value={requestData.bcContactPerson}
                  onChange={
                    e => this.setState({ requestData: { ...requestData, ...{ bcContactPerson: e.target.value } } })
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="requestbc-form-container-100">
                <div className={`form__input-label ${showError && !requestData.bcStreet.trim() ? 'error' : ''}`}>
                  {intl.formatMessage({ id: 'Street', defaultMessage: 'Street' })}
                </div>
                <FilterInput
                  disabled={sending}
                  name="address"
                  className={`form__input ${showError && !requestData.bcStreet.trim() ? 'error' : ''}`}
                  value={requestData.bcStreet}
                  onChange={
                    e => this.setState({ requestData: { ...requestData, ...{ bcStreet: e.target.value } } })
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="requestbc-form-container-33 mr-15">
                <div className={`form__input-label ${showError && !requestData.bcCountryId ? 'error' : ''}`}>
                  {intl.formatMessage({ id: 'BowlingcenterFilterByCountry', defaultMessage: 'Country' })}
                </div>
                <Select
                  isDisabled={sending}
                  options={countries.map(country => ({ value: country.id, label: country.name }))}
                  className={`form__select ${showError && !requestData.bcCountryId ? 'error' : ''}`}
                  placeholder=""
                  onChange={
                    country => this.selectCountry(country)
                  }
                />
              </div>
              <div className="requestbc-form-container-33 mr-15">
                <div className={`form__input-label ${showError && !requestData.bcCityId ? 'error' : ''}`}>
                  {intl.formatMessage({ id: 'City', defaultMessage: 'City' })}
                </div>
                <Select
                  isDisabled={sending}
                  className={`form__select ${showError && !requestData.bcCityId ? 'error' : ''}`}
                  placeholder=""
                  options={
                    (requestData.bcCountryId && cities.map(city => ({ value: city.id, label: city.name }))) || []
                  }
                  onChange={
                    city => this.setState({ requestData: { ...requestData, ...{ bcCityId: city.value } } })
                  }
                />
              </div>
              <div className="requestbc-form-container-33">
                <div className="form__input-label">
                  {intl.formatMessage({ id: 'Postcode', defaultMessage: 'Postcode' })}
                </div>
                <FilterInput
                  disabled={sending}
                  name="postcode"
                  className="form__input"
                  value={requestData.bcPostcode}
                  onChange={
                    e => this.setState({ requestData: { ...requestData, ...{ bcPostcode: e.target.value } } })
                  }
                />
              </div>
            </div>
            {requestData.personName === '.<!test>' && <div style={{ marginTop: 10, color: '#fff' }}>TEST MESSAGE</div>}
          </div>
        </div>
      </Modal>
    );
  }
}

export default injectIntl(
  connect(
    state => ({
      countries: state.common.geodata.data,
      cities: state.common.cities.data,
      timezones: state.common.timezones.data,
      sending: state.bowlingcenters.request.sending,
    }),
    {
      GetCities,
      sendBowlingCenterRequest,
    }
  ) (RequestBCModal)
);
