import { call, put } from 'redux-saga/effects'
import config from '../../config'
import { post as POST, get as GET, put as PUT } from '../Networking'
import takeLeading from '../saga/tools/takeLeading'

// Статус (получение статуса)
export const tenpinToolkitStatusRequestAction = () => ({ type: 'TENPIN_TOOLKIT_STATUS_REQUEST' })
/** @param {boolean} enabled */
export const tenpinToolkitStatusSuccessAction = (enabled) => ({ type: 'TENPIN_TOOLKIT_STATUS_SUCCESS', params: { enabled } })
/** @param {any} [error] */
export const tenpinToolkitStatusFailureAction = (error) => ({ type: 'TENPIN_TOOLKIT_STATUS_FAILURE', params: error })

// Видео (получение ссылки на видео)
/** @param {{ bowlingcenterid: number; linenumber: number; }} bowlingCenter */
export const tenpinToolkitVideoLinkRequestAction = (bowlingCenter) => ({ type: 'TENPIN_TOOLKIT_VIDEO_LINK_REQUEST', params: bowlingCenter })
/** @param {string} link */
export const tenpinToolkitVideoLinkSuccessAction = (link) => ({ type: 'TENPIN_TOOLKIT_VIDEO_LINK_SUCCESS', params: { link } })
/** @param {any} [error] */
export const tenpinToolkitVideoLinkFailureAction = (error) => ({ type: 'TENPIN_TOOLKIT_VIDEO_LINK_FAILURE', params: error })
export const tenpinToolkitVideoLinkClearAction = () => ({ type: 'TENPIN_TOOLKIT_VIDEO_LINK_CLEAR' })

// Настройки (получение)
export const tenpinToolkitSettingsRequestAction = () => ({ type: 'TENPIN_TOOLKIT_SETTINGS_REQUEST' })
/** @param {{ dontshowagain?: boolean; }} settings */
export const tenpinToolkitSettingsSuccessAction = (settings) => ({ type: 'TENPIN_TOOLKIT_SETTINGS_SUCCESS', params: settings })
/** @param {any} [error] */
export const tenpinToolkitSettingsFailureAction = (error) => ({ type: 'TENPIN_TOOLKIT_SETTINGS_FAILURE', params: error })

// Настройки (обновление)
/** @param {{ dontshowagain: boolean; }} settings */
export const tenpinToolkitSettingsUpdateRequestAction = (settings) => ({ type: 'TENPIN_TOOLKIT_SETTINGS_UPDATE_REQUEST', params: settings })
/** @param {{ dontshowagain?: boolean; }} settings */
export const tenpinToolkitSettingsUpdateSuccessAction = (settings) => tenpinToolkitSettingsSuccessAction(settings)
/** @param {any} [error] */
export const tenpinToolkitSettingsUpdateFailureAction= (error) => tenpinToolkitSettingsFailureAction(error)

export const initialTenpinToolkitState = {
  status: {
    fetching: false,
    error: false,
    enabled: false,
  },
  video: {
    fetching: false,
    error: false,
    link: '',
  },
  settings: {
    fetching: false,
    error: false,
    data: {
      dontshowagain: false,
    },
  },
}

/**
 * @returns {typeof initialTenpinToolkitState}
 */
export const TenpinToolkitReducer = (state = initialTenpinToolkitState, action) => {
  switch (action.type) {
    case 'TENPIN_TOOLKIT_VIDEO_LINK_CLEAR':
      return {
        ...state,
        video: {
          ...state.video,
          link: '',
        },
      }
    case 'TENPIN_TOOLKIT_VIDEO_LINK_REQUEST':
      return {
        ...state,
        video: {
          ...state.video,
          fetching: true,
          error: false,
          link: '',
        },
      };
    case 'TENPIN_TOOLKIT_VIDEO_LINK_SUCCESS':
      return {
        ...state,
        video: {
          ...state.video,
          fetching: false,
          link: action.params.link,
        },
      }
    case 'TENPIN_TOOLKIT_VIDEO_LINK_FAILURE':
      return {
        ...state,
        video: {
          ...state.video,
          fetching: false,
          error: true,
        },
      }
    case 'TENPIN_TOOLKIT_STATUS_REQUEST':
      return {
        ...state,
        status: {
          ...state.status,
          fetching: true,
          error: false,
        },
      }
    case 'TENPIN_TOOLKIT_STATUS_SUCCESS':
      return {
        ...state,
        status: {
          ...state,
          fetching: false,
          enabled: action.params.enabled,
        },
      }
    case 'TENPIN_TOOLKIT_STATUS_FAILURE':
      return {
        ...state,
        status: {
          ...state.status,
          fetching: false,
          error: true,
        },
      }
    case 'TENPIN_TOOLKIT_SETTINGS_UPDATE_REQUEST':
    case 'TENPIN_TOOLKIT_SETTINGS_REQUEST':
      return {
        ...state,
        settings: {
          ...state.settings,
          fetching: true,
          error: false,
        },
      }
    case 'TENPIN_TOOLKIT_SETTINGS_SUCCESS':
      return {
        ...state,
        settings: {
          ...state.settings,
          fetching: false,
          data: action.params,
        },
      }
    case 'TENPIN_TOOLKIT_SETTINGS_FAILURE':
      return {
        ...state,
        settings: {
          ...state.settings,
          fetching: false,
          error: true,
        },
      }
    default:
      return state;
  }
}

export function* tenpinToolkitVideoLinkSaga(action) {
  try {
    const { bowlingcenterid, linenumber } = action.params;
    const res = yield call(POST, { url: `${config.FETCH.url}/tenpintoolkit/videolink`, data: { bowlingcenterid, linenumber } })
    if (res.status === 200 && res.data.success) {
      yield put(tenpinToolkitVideoLinkSuccessAction(res.data.link))
    } else {
      yield put(tenpinToolkitVideoLinkFailureAction())
    }
  } catch (e) {
    yield put(tenpinToolkitVideoLinkFailureAction(e.message))
  }
}

export function* tenpinToolkitStatusSaga() {
  try {
    const res = yield call(GET, { url: `${config.FETCH.url}/tenpintoolkit/status` })
    if (res.status === 200 && res.data.success) {
      yield put(tenpinToolkitStatusSuccessAction(res.data.enabled))
    } else {
      yield put(tenpinToolkitStatusFailureAction())
    }
  } catch (e) {
    yield put(tenpinToolkitStatusFailureAction(e.message))
  }
}

export function* tenpinToolkitSettingsSaga() {
  try {
    const res = yield call(GET, { url: `${config.FETCH.url}/tenpintoolkit/settings` })
    if (res.status === 200 && res.data.success) {
      yield put(tenpinToolkitSettingsSuccessAction(res.data.settings))
    } else {
      yield put(tenpinToolkitSettingsFailureAction())
    }
  } catch (e) {
    yield put(tenpinToolkitSettingsFailureAction(e.message))
  }
}

export function* tenpinToolkitSettingsUpdateSaga(action) {
  try {
    const res = yield call(PUT, { url: `${config.FETCH.url}/tenpintoolkit/settings`, data: action.params })
    if (res.status === 200 && res.data.success) {
      yield put(tenpinToolkitSettingsUpdateSuccessAction(res.data.settings))
    } else {
      yield put(tenpinToolkitSettingsUpdateFailureAction())
    }
  } catch (e) {
    yield put(tenpinToolkitSettingsUpdateFailureAction(e.message))
  }
}

export function* watchTenpinToolkitSaga() {
  yield takeLeading('TENPIN_TOOLKIT_STATUS_REQUEST', tenpinToolkitStatusSaga)
  yield takeLeading('TENPIN_TOOLKIT_VIDEO_LINK_REQUEST', tenpinToolkitVideoLinkSaga)
  yield takeLeading('TENPIN_TOOLKIT_SETTINGS_REQUEST', tenpinToolkitSettingsSaga)
  yield takeLeading('TENPIN_TOOLKIT_SETTINGS_UPDATE_REQUEST', tenpinToolkitSettingsUpdateSaga)
}