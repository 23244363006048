import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import Utils from '../../libs/utils';
import _ from 'lodash';
import moment from 'moment';
import { Button } from '../common/Button';
import MatchScore from '../matchScore';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import GameScore from '../common/GameScore';
import VideoArchivePlayer from '../videoArchiveRoot/player';
import { getVideoArchive, ChangeLang } from '../../libs/reducers/actions';
import VideoController from '../videoArchiveRoot/controller';
import ChatMessage from '../videoArchiveRoot/message';
import novideo from '../videoArchiveRoot/no-video.png';
import notexists from '../videoArchiveRoot/removed-video.png';
import ModalProtestDecision from './modal';
import Flag from '../common/Flag';
import { isPinfall } from '../../libs/SquadTypeCheck';
import './style.scss';
import '../../../node_modules/video-react/dist/video-react.css';

class RefereeingPage extends Component {

  constructor(props) {

    super(props);

    this.videoplayers = [];

    this.consider_start = new Date().toISOString();

    this.state = {
      data: null,
      prevGame: null,
      currentGame: null,
      gameTime: null,
      nextGame: null,
      dataFetching: false,
      dataLoaded: false,
      canPlay: true,
      cameras: [
        'scoring',
        'skittles',
        'track'
      ],
      needSeekPlayers: false,
      videoPlayersState: [],
      videoPlayersAligned: false,
      videoPlayersBinded: false,
      videoPlayersReady: false,
      videoPlayersSeeking: false,
      videoOfOpponent: false,
      videoPlayersMuted: false,
      videoPlayersWaiting: false,
      showModalSaveBattle: false,
      protestAccepted: false,
      partyResolved: false,
      editConfirmed: false,
      newFrameResult: {
        shot1: -1,
        shot2: -1,
        shot3: -1,
        shot1Strike: false,
        shot2Strike: false,
        shot3Strike: false,
        shot1Foul: false,
        shot2Foul: false,
        shot3Foul: false,
        shot2Spare: false,
        shot3Spare: false,
      },
      showModalProtestDecision: false,
      oldFrame: null,
      newFrame: null,
      result: null
    };
  }

  componentDidMount() {
    const { squadid, protestid } = this.props.videoarchive;
    if (!squadid) {
      this.props.history.goBack();
    } else {
      window.addEventListener('resize', () => { this.alignVideoPlayers() });
      this.props.getVideoArchive({ squadid, protestid });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => { this.alignVideoPlayers() });
  }

  alignVideoPlayers() {
    if (this.cameras) {
      this.cameras.style.width = '';
    }
    this.leftVideoContainer.style.height = '';
    this.rightVideoContainer.style.height = '';
    this.other.style.width = '';
    this.rightVideoContainer.style.display = 'none';
    this.leftVideoContainer.style.display = 'none';
    const { clientWidth: videoContainerWidth, clientHeight: videoContainerHeight } = this.videoContainer;
    let width = 0, height = 0, fullWidth = 0, fullHeight = 0, video = null;
    const ratio = 1.777777777777778;
    const margin = 3;

    const maxWidth = videoContainerWidth - margin;
    const maxHeight = videoContainerHeight - margin;

    const rightVideoContainerWidth = maxWidth * 0.7;

    height = videoContainerHeight;
    width = height * ratio;
    if (width > rightVideoContainerWidth) {
      width = rightVideoContainerWidth;
      height = width / ratio;
    }

    fullWidth += width;
    fullHeight = height;

    video = this.rightVideoContainer.getElementsByClassName('video')[0];

    video.style.width = width + 'px';
    video.style.height = height + 'px';
    video.style.marginLeft = margin + 'px';

    const leftVideoContainerWidth = maxWidth - width;

    width = leftVideoContainerWidth;
    height = width / ratio;

    if (height * 2 > maxHeight) {
      height = maxHeight / 2;
      width = height * ratio;
    }

    fullWidth += width;

    video = this.leftVideoContainer.getElementsByClassName('video');

    video[0].style.width = width + 'px';
    video[0].style.height = height + 'px';
    video[0].style.marginBottom = margin + 'px';
    video[1].style.width = width + 'px';
    video[1].style.height = height + 'px';

    fullWidth += margin;
    fullHeight += margin;

    this.cameras.style.width = fullWidth + 'px';
    this.leftVideoContainer.style.height = fullHeight + 'px';
    this.rightVideoContainer.style.height = fullHeight + 'px';
    this.other.style.width = '100%';
    this.rightVideoContainer.style.display = '';
    this.leftVideoContainer.style.display = '';
    this.setState({ videoPlayersAligned: true });
    this.forceUpdate();
  }

  componentDidUpdate(prevProps, prevState) {
    const nextProps = this.props;
    const nextState = this.state;
    if (
      !nextState.dataFetching &&
      nextProps.videoarchive.isFetching
    ) {
      this.setState({ dataFetching: true });
    }
    if (
      prevState.dataFetching &&
      prevProps.videoarchive.isFetching &&
      !nextProps.videoarchive.isFetching &&
      !prevState.dataLoaded
    ) {
      const { data, resolved } = nextProps.videoarchive;
      const videoPlayersState = [
        {
          exists: data.streams && (!!data.streams.current[nextState.cameras[0]] && data.streams.current[nextState.cameras[0]].saved),
          ready: false
        },
        {
          exists: data.streams && (!!data.streams.current[nextState.cameras[1]] && data.streams.current[nextState.cameras[1]].saved),
          ready: false
        },
        {
          exists: data.streams && (!!data.streams.current[nextState.cameras[2]] && data.streams.current[nextState.cameras[2]].saved),
          ready: false
        }
      ];

      console.log('===================data.games', data);


      const { protestGame } = data;
      const { protestFrame } = data;
      const gameTime = +new Date(data.games[protestGame].gamestarted);
      const frameTime = +new Date(protestFrame.created);
      const startTime = (frameTime - gameTime) / 1000;
      const pinfallGame = isPinfall(data.tournamenttypebrief);
      const players = Object.keys(data.players).sort().reverse().map(type => {
        const player = data.players[type];
        const score = data.games.reduce((sum, elem) => {
          const totalScore = elem.frames[type][9].score + +player.handicap_value; 
          return sum + (totalScore > 300 ? 300 : totalScore);
        }, 0) ;
        return ({
          id:            player.userid,
          userid:        player.userid,
          login:         player.userlogin,
          firstname:     player.userfirstname,
          lastname:      player.userlastname,
          country_live:  player.usercountry,
          ratingsavg:    +player.userrating,
          photo:         player.userphoto,
          gameswin: pinfallGame 
            ? score
            : player.playergameswin,
          delays:        0,
        });
      });

      this.setState({
        dataFetching: false,
        dataLoaded: true,
        data: data,
        partyResolved: resolved,
        currentGame: protestGame,
        prevGame: data.games[protestGame - 1] ? protestGame - 1 : null,
        nextGame: data.games[protestGame + 1] ? protestGame + 1 : null,
        videoPlayersState: videoPlayersState,
        gameTime: frameTime,
        startTime,
        needSeekPlayers: true,
        players,
      });
    }
    if (nextState.dataLoaded) {
      if (this.rightVideoContainer && this.leftVideoContainer) {
        if (!prevState.videoPlayersAligned) {
          this.alignVideoPlayers();
        }
      }
      if (
        (
          prevState.dataFetching &&
          !nextState.dataFetching &&
          !prevState.dataLoaded &&
          nextState.dataLoaded &&
          !prevState.videoPlayersBinded
        ) || (
          prevState.videoPlayersBinded &&
          !nextState.videoPlayersBinded
        )
      ) {
        this.bindPlayers();
      }
      if (
        prevState.videoOfOpponent != nextState.videoOfOpponent &&
        prevState.videoPlayersBinded && !nextState.videoPlayersBinded
      ) {
        this.bindPlayers();
      }
      if (
        prevState.cameras[2] != nextState.cameras[2] &&
        prevState.videoPlayersBinded && !nextState.videoPlayersBinded
      ) {
        this.bindPlayers();
      }
      if (prevState.needSeekPlayers && nextState.videoPlayersBinded && !prevState.videoPlayersReady) {
        this.seek();
      }
      if (
        prevState.videoPlayersBinded &&
        !prevState.videoPlayersReady &&
        !nextState.videoPlayersReady &&
        (nextState.videoPlayersState[0].ready || !prevState.videoPlayersState[0].exists) &&
        (nextState.videoPlayersState[1].ready || !prevState.videoPlayersState[1].exists) &&
        (nextState.videoPlayersState[2].ready || !prevState.videoPlayersState[2].exists)
      ) {
        this.setState({ videoPlayersReady: true, startTime: 0 });
      }
      if (!prevState.videoPlayersWaiting && !prevState.videoPlayersReady && nextState.videoPlayersReady) {
        this.play();
      }
      if (
        prevState.videoPlayersSeeking &&
        nextState.videoPlayersSeeking &&
        (!prevState.videoPlayersState[0].exists || nextState.videoPlayersState[0].ready) &&
        (!prevState.videoPlayersState[1].exists || nextState.videoPlayersState[1].ready) &&
        (!prevState.videoPlayersState[2].exists || nextState.videoPlayersState[2].ready)
      ) {
        this.setState({ videoPlayersSeeking: false, startTime: 0 });
      }
      if (
        !prevState.videoPlayersWaiting &&
        prevState.videoPlayersSeeking &&
        !nextState.videoPlayersSeeking
      ) {
        this.play();
      }
      if (
        prevState.videoPlayersWaiting &&
        !nextState.videoPlayersWaiting &&
        !nextState.videoPlayersSeeking
      ) {
        this.play();
      }
      if (prevState.currentGame != nextState.currentGame) {
        const index = nextState.currentGame;
        let time = 0;
        this.changeGameTime(new Date(nextState.data.games[index].gamestarted));
      }
    }
  }

  bindPlayers() {
    for (let i = 0; i < this.videoplayers.length; i++) {
      if (!this.videoplayers[i]) continue;
      if (this.state.videoPlayersState.length && this.state.videoPlayersState[i] && this.state.videoPlayersState[i].exists) {
        this.videoplayers[i].subscribeToStateChange(this.playerStateChangeHandler.bind(this, i));
      }
    }
    this.setState({ videoPlayersBinded: true });
  }

  playerStateChangeHandler(index, state, prevState) {
    if (state.readyState == 4 && prevState.readyState < 4) {
      let state = { ...this.state };
      state.videoPlayersState[index].ready = true;
      this.setState({ ...state });
    }
    if (!state.seeking && prevState.seeking) {
      let state = { ...this.state };
      state.videoPlayersState[index].ready = true;
      this.setState({ ...state });
    }
    if (!prevState.seeking && state.seeking || !prevState.waiting && state.waiting) {
      let state = { ...this.state };
      state.videoPlayersState[index].ready = false;
      if (this.controller) {
        this.controller.stopTimer();
      }
      this.setState({ ...state, videoPlayersReady: false });
    }
  }

  switchCamera (camera) {
    this.pause();
    const { cameras, videoOfOpponent } = this.state;
    const { streams } = this.state.data;
    const i = cameras.indexOf(camera);
    const j = 2;
    let _cameras = [ ...cameras ];
    _cameras[j] = cameras[i];
    _cameras[i] = cameras[j];
    const side = videoOfOpponent ? 'opponent' : 'current';
    let videoPlayersState = [];
    videoPlayersState[0] = {
      exists: !!streams[side][_cameras[0]] && streams[side][_cameras[0]].saved,
      ready: false,
      width: 0,
      height: 0,
      ratio: 1.777777777777778
    }
    videoPlayersState[1] = {
      exists: !!streams[side][_cameras[1]] && streams[side][_cameras[1]].saved,
      ready: false,
      width: 0,
      height: 0,
      ratio: 1.777777777777778
    }
    videoPlayersState[2] = {
      exists: !!streams[side][_cameras[2]] && streams[side][_cameras[2]].saved,
      ready: false,
      width: 0,
      height: 0,
      ratio: 1.777777777777778
    }
    this.setState({
      videoPlayersReady: false,
      videoPlayersBinded: false,
      videoPlayersAligned: false,
      videoPlayersState: videoPlayersState,
      cameras: _cameras,
      needSeekPlayers: true
    });
  }

  switchVideo() {
    this.pause();
    const { streams } = this.state.data;
    const { cameras, videoOfOpponent } = this.state;
    let videoPlayersState = [];
    videoPlayersState[0] = {
      exists: !videoOfOpponent ? !!streams.opponent[cameras[0]] && streams.opponent[cameras[0]].saved : !!streams.current[cameras[0]] && streams.current[cameras[0]].saved,
      ready: false,
      width: 0,
      height: 0,
      ratio: 1.777777777777778
    }
    videoPlayersState[1] = {
      exists: !videoOfOpponent ? !!streams.opponent[cameras[1]] && streams.opponent[cameras[1]].saved : !!streams.current[cameras[1]] && streams.current[cameras[1]].saved,
      ready: false,
      width: 0,
      height: 0,
      ratio: 1.777777777777778
    }
    videoPlayersState[2] = {
      exists: !videoOfOpponent ? !!streams.opponent[cameras[2]] && streams.opponent[cameras[2]].saved : !!streams.current[cameras[2]] && streams.current[cameras[2]].saved,
      ready: false,
      width: 0,
      height: 0,
      ratio: 1.777777777777778
    }
    this.setState({
      videoOfOpponent: !videoOfOpponent,
      videoPlayersReady: false,
      videoPlayersBinded: false,
      videoPlayersState: videoPlayersState,
      videoPlayersAligned: false,
      needSeekPlayers: true
    });
  }

  changeGameTime(timestamp) {
    const { data, currentGame } = this.state;
    const gamestarted = +new Date(data.games[currentGame].gamestarted);
    let time = 0;
    time += Math.round((+timestamp - gamestarted) / 1000);
    if (this.controller) {
      this.controller.setTimerValue(time, false);
    }
  }

  onChangeTimer(time, newGame = false, wait = true) {
    if (this.controller) {
      let state = { ...this.state };
      const index = state.currentGame || 0;
      let offset = 0;
      if (index > 0) {
        for (let i = 0; i < index; i += 1) {
          offset += state.data.games[i].duration;
        }
      }
      for (let i = 0; i < this.videoplayers.length; i++) {
        if (this.videoplayers[i]) {
          this.videoplayers[i].pause();
          this.videoplayers[i].seek(time + offset);
        }
        state.videoPlayersState[i].ready = false;
      }
      let gameTime = +new Date(state.data.games[index].gamestarted);
      if (!newGame) {
        if (wait) {
          this.pause();
        }
        this.setState({ ...state, videoPlayersSeeking: true, videoPlayersWaiting: wait, gameTime: gameTime + time * 1000 });
      } else {
        this.setState({ ...state, videoPlayersSeeking: true, gameTime: gameTime });
      }
    }
  }

  onAfterChangeTimer() {
    this.setState({ videoPlayersWaiting: false });
  }

  onMute() {
    this.setState({ videoPlayersMuted: true });
  }

  onUnmute() {
    this.setState({ videoPlayersMuted: false });
  }

  onTick() {
    const gameTime = this.state.gameTime + 1000;
    this.setState({ gameTime: gameTime });
  }

  play() {
    if (this.state.canPlay) {
      for (let vp of this.videoplayers) {
        if (vp) vp.play();
      }
      this.controller.startTimer();
    }
  }

  seek() {
    const time = this.controller.getTime();
    for (let vp of this.videoplayers) {
      if (vp) vp.seek(time);
    }
    this.setState({ videoPlayersSeeking: true, needSeekPlayers: false });
  }

  pause() {
    this.controller.pauseTimer();
    for (let vp of this.videoplayers) {
      if (vp) vp.pause();
    }
  }

  onEndTimer() {
    this.setState({ canPlay: false });
    this.pause();
  }

  onChangeGame(index) {
    const { games } = this.state.data;
    const currentGame = index;
    const prevGame = games[index - 1] ? index - 1 : null;
    const nextGame = games[index + 1] ? index + 1 : null;
    this.setState({
      currentGame: currentGame,
      prevGame: prevGame,
      nextGame: nextGame,
      canPlay: true
    });
  }

  renderVideoPlayer = (stream, index, camera) => {
    const { videoPlayersMuted, startTime } = this.state;
    const { intl } = this.props;
    let videoTypeText = null;
    switch (camera) {
      case 'scoring':
        videoTypeText = intl.formatMessage({ id: 'save_battle_scoring_camera', defaultMessage: 'Scoring' });
        break;
      case 'skittles':
        videoTypeText = intl.formatMessage({ id: 'save_battle_skittles_camera', defaultMessage: 'Pin deck' });
        break;
      case 'track':
        videoTypeText = intl.formatMessage({ id: 'save_battle_track_camera', defaultMessage: 'Track' });
        break;
    }
    const videoNotExistsText = intl.formatMessage({ id: 'VideoNotExists', defaultMessage: 'Sorry, but video "{videotype}"\nwas removed' }, { videotype: videoTypeText });
    const noSavedVideoText = intl.formatMessage({ id: 'NoSaveVideo', defaultMessage: 'Save video "{videotype}"?' }, { videotype: videoTypeText });
    if (stream) {
      if (stream.saved) {
        return (
          <VideoArchivePlayer
            width={0}
            height={0}
            ref={(c) => { this.videoplayers[index] = c ? c.refs.player || null : null }}
            src={stream.url}
            muted={videoPlayersMuted}
            user={this.props.user}
          />
        )
      } else {
        return (
          <div className="dummy" onClick={() => {
            this.pause();
            this.setState({ showModalSaveBattle: true });
          }}>
            <div className="no-video">
              <img src={novideo} />
              <div className="no-video-text">
                {noSavedVideoText}
              </div>
            </div>
          </div>
        )
      }
    } else {
      return (
        <div className="dummy">
          <div className="no-video">
            <img src={notexists} />
            <div className="no-video-text">
              {videoNotExistsText}
            </div>
          </div>
        </div>
      )
    }
  }

  setChangingFrame = (shot1, shot2, shot3, flags, score = null) => {
    let state = this.state;
    const { protest, protestFrame } = state.data;
    const { currentGame } = state;
    const { frames } = state.data.games[currentGame];
    const { frames: _frames } = this.props.videoarchive.data.games[currentGame];
    const side = protest.side !== 1 ? 'opponent' : 'current';
    const frameIndex = frames[side].findIndex(f => f.id == protestFrame.id);
    state.data.games[currentGame].frames[side][frameIndex] = { ...frames[side][frameIndex], shot1, shot2, shot3, score, flags };
    let scores = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    for (let i = 0; i < 10; i++) {
      const cf = _frames[side][i];
      scores[i] = (cf.shot1 == -1 ? 0 : cf.shot1) + (cf.shot2 == -1 ? 0 : cf.shot2) + (cf.shot3 == -1 ? 0 : cf.shot3);
      if (i < 9) {
        const nf = _frames[side][i + 1];
        if (cf.shot1 < 10 && cf.shot1 + cf.shot2 == 10) {
          scores[i] += nf.shot1;
        } else if (cf.shot1 == 10) {
          scores[i] += nf.shot1 + (nf.shot2 == -1 ? 0 : nf.shot2);
          if (nf.shot1 == 10 && _frames[side][i + 2]) {
            scores[i] += frames[side][i + 2].shot1;
          }
        }
      }
      if (i > 0) {
        scores[i] += scores[i - 1];
      }
      state.data.games[currentGame].frames[side][i] = { ..._frames[side][i], score: scores[i] };
    }
    return state;
  }

  onAccept = () => {
    const state = this.setChangingFrame(-1, -1, -1, null, 0);
    this.setState({ ...state, protestAccepted: true });
  }

  onReject = () => {
    this.setState({ showModalProtestDecision: true });
  }

  getNextShotNumber = () => {
    const { shot1, shot2, shot3 } = this.state.newFrameResult;
    if (shot1 == -1) return 1;
    if (shot2 == -1) return 2;
    if (shot3 == -1) return 3;
    return 0;
  }

  setShot = (value) => {
    const shotN = this.getNextShotNumber();
    let newFrameResult = { ...this.state.newFrameResult }; 
    const { 
      shot1, 
      shot2, 
      shot3,
      shot1Strike,
      shot2Strike, 
      shot3Strike,
      shot1Foul, 
      shot2Foul, 
      shot3Foul,
      shot2Spare,
      shot3Spare,
    } = newFrameResult;
    let isFoul = false;
    if (value == 'f') {
      isFoul = true;
      value = 0;
    }
    switch (shotN) {
      case 1 : 
        newFrameResult = { ...newFrameResult, shot1: value, shot1Strike: value == 10, shot1Foul: isFoul };
        break;
      case 2 : 
        newFrameResult = { ...newFrameResult, shot2: value, shot2Strike: value == 10, shot2Foul: isFoul, shot2Spare: !shot1Strike && shot1 + value == 10 };
        break;
      case 3 : 
        newFrameResult = { ...newFrameResult, shot3: value, shot3Strike: value == 10, shot3Foul: isFoul, shot3Spare: !shot2Strike && shot2 + value == 10 };
        break;
    }
    const { shot1: _shot1, shot2: _shot2, shot3: _shot3, shot1Foul: _shot1Foul, shot2Foul: _shot2Foul, shot3Foul: _shot3Foul } = newFrameResult;
    const state = this.setChangingFrame(_shot1, _shot2, _shot3, (_shot1Foul || _shot2Foul || _shot3Foul) ? 'rf' : 'r' );
    this.setState({ ...state, newFrameResult });
  }

  deleteShot = () => {
    const shotN = this.getNextShotNumber();
    let newFrameResult = { ...this.state.newFrameResult }; 
    switch (shotN) {
      case 2 : 
        newFrameResult = { ...newFrameResult, shot1: -1, shot1Strike: false, shot1Foul: false };
        break;
      case 3 : 
        newFrameResult = { ...newFrameResult, shot2: -1, shot2Strike: false, shot2Foul: false, shot2Spare: false };
        break;
      case 0 : 
        newFrameResult = { ...newFrameResult, shot3: -1, shot3Strike: false, shot3Foul: false, shot3Spare: false };
        break;
    }
    const { shot1, shot2, shot3, shot1Foul, shot2Foul, shot3Foul } = newFrameResult;
    const state = this.setChangingFrame(shot1, shot2, shot3, (shot1Foul || shot2Foul || shot3Foul) ? 'f' : null );
    this.setState({ ...state, newFrameResult });
  }

  setDigit = (digit) => {
    this.setShot(digit);
  }

  setStrike = () => {
    this.setShot(10);
  }

  setSpare = () => {
    const shotN = this.getNextShotNumber();
    const { 
      shot1, 
      shot2, 
      shot3,
      shot1Strike,
      shot2Strike, 
      shot3Strike,
      shot1Foul, 
      shot2Foul, 
      shot3Foul,
      shot2Spare,
      shot3Spare,
    } = this.state.newFrameResult;
    switch (shotN) {
      case 2 : 
        this.setShot(10 - shot1);
        break;
      case 3 : 
        this.setShot(10 - shot2);
        break;
    }
  }

  setFoul = () => {
    this.setShot('f');
  }

  canSetDigit = (digit) => {
    const { framenumber } = this.state.data.protestFrame;
    const shotN = this.getNextShotNumber();
    const { 
      shot1, 
      shot2, 
      shot3,
      shot1Strike,
      shot2Strike, 
      shot3Strike,
      shot1Foul, 
      shot2Foul, 
      shot3Foul,
      shot2Spare,
      shot3Spare,
    } = this.state.newFrameResult;
    if (framenumber == 10) {
      if (shotN == 0) return false;
      if (shotN == 2) {
        if (!shot1Strike) {
          if (shot1 + digit > 10) return false;
        }
      }
      if (shotN == 3) {
        if (!shot1Strike && !shot2Spare) {
          return false;
        }
        if (!shot2Strike && !shot2Spare) {
          if (shot2 + digit > 10) return false;
        }
      }
    } else {
      if (shotN == 2 && (shot1Strike || shot1 + digit > 10)) return false;
      if (shotN == 3) return false; 
    }
    return true;
  }

  canSetStrike = () => {
    const shotN = this.getNextShotNumber();
    const { framenumber } = this.state.data.protestFrame;
    const { 
      shot1, 
      shot2, 
      shot3,
      shot1Strike, 
      shot2Strike, 
      shot3Strike,
      shot1Foul, 
      shot2Foul, 
      shot3Foul,
      shot2Spare,
      shot3Spare,
    } = this.state.newFrameResult;
    if (framenumber == 10) {
      if (shotN == 0) return false;
      if (shotN == 2 && !shot1Strike) return false;
      if (shotN == 3 && !shot2Spare && !shot2Strike) return false;
    } else {
      if (shotN == 2 || shotN == 3) return false;
    }
    return true;
  }

  canSetSpare = () => {
    const shotN = this.getNextShotNumber();
    const { framenumber } = this.state.data.protestFrame;
    const { 
      shot1, 
      shot2, 
      shot3,
      shot1Strike, 
      shot2Strike, 
      shot3Strike,
      shot1Foul, 
      shot2Foul, 
      shot3Foul,
      shot2Spare,
      shot3Spare,
    } = this.state.newFrameResult;
    if (framenumber == 10) {
      if (shotN == 0 || shotN == 1) return false;
      if (shotN == 2 && shot1Strike) return false;
      if (shotN == 3 && (shot2Strike || shot2Spare)) return false;
      if (shotN == 3 && shot1 + shot2 < 10) return false;
    } else {
      if (shotN == 1 || shotN == 3) return false;
      if (shotN == 2 && shot1Strike) return false;
    }
    return true;
  }

  canSetFoul = () => {
    const shotN = this.getNextShotNumber();
    const { framenumber } = this.state.data.protestFrame;
    const { 
      shot1, 
      shot2, 
      shot3,
      shot1Strike, 
      shot2Strike, 
      shot3Strike,
      shot1Foul, 
      shot2Foul, 
      shot3Foul,
      shot2Spare,
      shot3Spare,
    } = this.state.newFrameResult;
    if (framenumber == 10) {
      if (shotN == 0) return false;
      if (shotN == 3 && shot1 + shot2 < 10) return false;
    } else {
      if (shotN == 2 && shot1Strike || shotN == 3) return false;
    }
    return true;
  }

  canSave = () => {
    const shotN = this.getNextShotNumber();
    const { framenumber } = this.state.data.protestFrame;
    const { 
      shot1, 
      shot2, 
      shot3,
      shot1Strike, 
      shot2Strike, 
      shot3Strike,
      shot1Foul, 
      shot2Foul, 
      shot3Foul,
      shot2Spare,
      shot3Spare,
    } = this.state.newFrameResult;
    if (framenumber == 10) {
      if (shotN == 3 && shot1 + shot2 < 10) return true;
      if (shotN == 0 && shot3 != -1) return true;
    } else {
      if (shot1Strike || shot2Spare) return true;
      if (shot2 != -1) return true;
    }
    return false;
  }

  canDelete = () => {
    const { shot1 } = this.state.newFrameResult;
    if (shot1 == -1) return false;
    return true;
  }

  resetResult = () => {
    const { protestFrame } = this.state.data;
    const {
      shot1,
      shot2,
      shot3,
      score,
      flags
    } = protestFrame;
    const state = this.setChangingFrame(shot1, shot2, shot3, flags, score);
    this.setState({ 
      ...state,
      protestAccepted: false,
      newFrameResult: { 
        shot1: -1,
        shot2: -1,
        shot3: -1,
        shot1Strike: false,
        shot2Strike: false,
        shot3Strike: false,
        shot1Foul: false,
        shot2Foul: false,
        shot3Foul: false,
        shot2Spare: false,
        shot3Spare: false,
      }
    });
  }

  saveResult = () => {
    const { data, currentGame } = this.state;
    const { protest, protestFrame: oldFrame } = data;
    const { frames } = data.games[currentGame];
    const side = protest.side !== 1 ? 'opponent' : 'current';
    const newFrame = frames[side].find(f => f.id === oldFrame.id);
    this.setState({ showModalProtestDecision: true, oldFrame, newFrame });
  }

  onCancelDecision = () => {
    this.setState({ showModalProtestDecision: false, oldFrame: null, newFrame: null, result: null })
  }

  onDoneDecision = () => {
    this.props.history.goBack();
  }

  onFrameSelect = (frame) => {
    if (!frame.lastupdated) return;
    
    const { currentGame, data } = this.state;
    const { frames } = data.games[currentGame];

    let frames1 = frames.current;
    let frames2 = frames.opponent || frames1;
    const curFrameIndex = frame.framenumber - 1;
    let time;
    if (frames.current[curFrameIndex].id !== frame.id) {
      [frames1, frames2] = [frames2, frames1];
    }
    if (frame.lastupdated > frames2[curFrameIndex].lastupdated) {
      time = frames2[curFrameIndex].lastupdated;
    } else if (curFrameIndex === 0) {
      time = data.games[currentGame].gamestarted;
    } else {
      time = frames2[curFrameIndex - 1].lastupdated;
    }
    this.changeGameTime(new Date(time));
  };

  renderFrameEditor = () => {
    const { intl } = this.props;
    return (
    <div className="frame-edit-container">
      <div className="frame-edit-title">
          {intl.formatMessage({ id: 'AdjustFrameResult', defaultMessage: 'Please adjust frame result' })}
      </div>
      <div className="digits">
        {
          ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].map(digit => (
            <div className="digit">
              <Button disabled={!this.canSetDigit(parseInt(digit))} kind="orange" size="md"
                onClick={() => {
                  this.setDigit(parseInt(digit))
                }}
              >{digit}</Button>
            </div>
          ))
        }
      </div>
      <div className="strike-foul">
        <div className="strike">
          <Button disabled={!this.canSetSpare() && !this.canSetStrike()} kind="orange" size="md"
            onClick={() => {
              this.canSetSpare() ? this.setSpare() : this.setStrike()
            }}
          >
            {
              this.canSetSpare()
                ? intl.formatMessage({ id: 'Spare', defaultMessage: 'Spare' })
                : intl.formatMessage({ id: 'Strike', defaultMessage: 'Strike' })
            }
          </Button>
        </div>
        <div className="foul">
          <Button disabled={!this.canSetFoul()} kind="orange" size="md"
            onClick={() => {
              this.setFoul()
            }}
          >
            {intl.formatMessage({ id: 'Foul', defaultMessage: 'Foul' })}
          </Button>
        </div>
      </div>
      <div className="save-cancel-delete">
        <div className="save">
          <Button disabled={!this.canSave()} kind="orange" size="md"
            onClick={() => {
              this.saveResult()
            }}
          >
            {intl.formatMessage({ id: 'SaveResults', defaultMessage: 'Save results' })}
          </Button>
        </div>
        <div className="cancel-delete">
          <div className="cancel">
            <Button kind="orange" size="md" 
              onClick={() => {
                this.resetResult();
              }}
            >
              {intl.formatMessage({ id: 'CancelAsAbortAlias', defaultMessage: 'Cancel' })}
            </Button>
          </div>
          <div className="delete">
            <Button disabled={!this.canDelete()} kind="orange" size="md"
              onClick={() => {
                this.deleteShot()
              }}
            >
              {intl.formatMessage({ id: 'DeleteAsDestroyAlias', defaultMessage: 'Delete' })}
            </Button>
          </div>
        </div>
      </div>
    </div>
    )
  }

  renderProtest = () => {
    const { intl, refereeSessionId } = this.props;
    const { data, partyResolved, editConfirmed } = this.state;
    const { players, protest, protestFrame } = data;
    const { current: currentPlayer, opponent: opponentPlayer } = players;
    const { reason, complaint, reply, accepted, closed, protestCreator, refereesId } = protest;
    if (closed && !editConfirmed) {
      const { framenumber = null } = protestFrame;
      const { shot1, shot2, shot3 } = protestFrame;
      const frameresult = Utils.getFrameResult(shot1, shot2, shot3);
      return (
        <div className="protest-container">
          <div className="protest">
            <div className="protest-category">
              <span>{intl.formatMessage({ id: 'ProtestCategory', defaultMessage: 'Protest`s category' })}:</span>{' '}
              {intl.formatMessage({ id: reason, defaultMessage: reason })}
            </div>
            <div className="protest-comment">
              <span>{`${protestCreator.firstname} ${protestCreator.lastname}`}:</span>{' '}{complaint}
            </div>
            <div className="protest-reply">
              <span>{`${currentPlayer.userfirstname} ${currentPlayer.userlastname}`}:</span>{' '}{reply}
            </div>
          </div>
          <div className="protest-footer">
            <div className="protest-info">
              {
                accepted 
                  ? intl.formatMessage({
                    id: 'ProtestAcceptedInfo', 
                    defaultMessage: 'Protest accepted by referee, frame #{framenumber} have score {frameresult} now' 
                  }, { framenumber, frameresult })
                  : intl.formatMessage({
                    id: 'ProtestRejectedInfo', 
                    defaultMessage: 'Protest rejected by referee'
                  })
              }
            </div>
            {
              (!partyResolved && (!refereesId || refereesId === refereeSessionId)) &&
              <div className="protest-buttons">
                <Button style={{ width: '100%', margin: '0px' }} size="md" kind="orange" onClick={() => {
                  this.setState({ editConfirmed: true })
                }}>
                  {intl.formatMessage({ id: 'EditAsCorrectionAlias', defaultMessage: 'Edit' })}
                </Button>
              </div>
            }
          </div>
        </div>
      )
    } else {
      return (
        <div className="protest-container">
          <div className="protest">
            <div className="protest-category">
              <span>{intl.formatMessage({ id: 'ProtestCategory', defaultMessage: 'Protest`s category' })}:</span>{' '}
              {intl.formatMessage({ id: reason, defaultMessage: reason })}
            </div>
            <div className="protest-comment">
              <span>{`${protestCreator.firstname} ${protestCreator.lastname}`}:</span>{' '}{complaint}
            </div>
            {
              opponentPlayer
                ? (
                  <div className="protest-reply">
                    <span>{`${opponentPlayer.userfirstname} ${opponentPlayer.userlastname}`}:</span>{' '}{reply}
                  </div>
                ) : null
            }
          </div>
          <div className="protest-footer">
            <div className="protest-buttons">
              <Button size="md" kind="orange" onClick={() => {
                this.onAccept()
              }}>
                {intl.formatMessage({ id: 'AcceptProtest', defaultMessage: 'Accept protest' })}
              </Button>
              <Button size="md" kind="orange" bordered="true" onClick={() => {
                this.onReject()
              }}>
                {intl.formatMessage({ id: 'RejectProtest', defaultMessage: 'Reject protest' })}
              </Button>
            </div>
          </div>
        </div>
      )
    }

  }

  render() {
    const {
      data,
      prevGame,
      currentGame,
      gameTime,
      startTime,
      nextGame,
      dataLoaded,
      videoOfOpponent,
      videoPlayersMuted,
      showModalSaveBattle,
      protestAccepted,
      cameras,
      showModalProtestDecision,
      oldFrame,
      newFrame, 
      result,
    } = this.state;
    const { intl, lang, videoarchive } = this.props;
    const { squadid: partyN } = videoarchive;
    const translates = {
      prize: intl.formatMessage({ id: 'Prize', defaultMessage: 'Prize' }),
      trackN: intl.formatMessage({ id: 'LaneNumber', defaultMessage: 'Lane №' }),
    };
    if (dataLoaded) {
      const { 
        streams, 
        players, 
        games, 
        tournamentcost, 
        tournamentname, 
        currencysymbol, 
        tournamentprize, 
        tournamentwincount,
        protest, 
        protestFrame, 
        protestGame,
        tournamenttypeid,
      } = data;
      const { current: currentPlayer, opponent: opponentPlayer } = players;
      const { id: protestN, reason, complaint, side } = protest;
      const gameN = protestGame + 1;
      const { framenumber: frameN } = protestFrame;
      const { messages } = games[currentGame];
      const date = games[0].gamestarted;

      return (
        <div className="refereeing">
          <div className="header">
            <div className="header-top">
              <div className="left-buttons">
                <Button kind="aquamarine" size="md" onClick={this.props.history.goBack}>
                  {intl.formatMessage({ id: 'Return', defaultMessage: 'Return' })}
                </Button>
              </div>
              <div className="tournament-info">
                <div className="tournament-name">
                  #{partyN} {tournamentname}
                </div>
                {
                  tournamentcost ?
                  <div className="tournament-fee">
                    {intl.formatMessage({ id: 'fee', defaultMessage: 'Fee' })} – {tournamentcost}{currencysymbol}
                  </div>
                  : null
                }
                <div className="tournament-wincount">
                  {tournamenttypeid !== 3
                    ? intl.formatMessage({ id: 'table_wins_short', defaultMessage: 'Wins' })
                    : intl.formatMessage({ id: 'games', defaultMessage: 'Games' })
                  } - {tournamentwincount}
                </div>
                {
                  tournamentcost && tournamenttypeid !== 3 ?
                  <div className="tournament-prize">
                    {intl.formatMessage({ id: 'Prize', defaultMessage: 'Prize' })} – {tournamentprize}{currencysymbol}
                  </div>
                  : null
                }
              </div>
              <div className="right-buttons">
                <span className="start-datetime">
                  {intl.formatMessage({ id: 'GameArchive', defaultMessage: 'Game archive from'})}
                  {' '}
                  {Utils.formatDateTime(date, lang, 'DD MMMM YYYY - HH:mm')}
                </span>
                <Button
                  kind="aquamarine"
                  size="md"
                  onClick={() => {
                    const choosenLang = this.props.lang === 'RU' ? 'EN' : 'RU';
                    this.props.ChangeLang(choosenLang);
                  }}
                >
                  {this.props.lang === 'RU' ? 'EN' : 'RU'}
                </Button>
              </div>
            </div>
            <div className="header-bottom">
              <MatchScore
                isObserver
                isReferee
                players={this.state.players}
              />
            </div>
          </div>
          <div className="content">
            <div className="games">
              <Tabs selectedIndex={currentGame}>
                <TabList>
                  {
                    games.map((game, index) => (
                      <Tab key={index} onClick={() => { this.onChangeGame(index) }}>{`Game ${index + 1}`}</Tab>
                    ))
                  }
                </TabList>
                {
                  games.map((game, index) => (
                    <TabPanel key={index}>
                      <div className="game">
                        {opponentPlayer 
                          ? (
                            <div className="player competitor">
                              <div className="player-head">
                                <div className="names">
                                  <div className="fullname">{`${opponentPlayer.userfirstname} ${opponentPlayer.userlastname}`}</div>
                                  <div className="login">{opponentPlayer.userlogin}</div>
                                </div>
                                <div className="lane">
                                  <div className="bowling"><Flag name={opponentPlayer.bowlingcenterflag}/> {opponentPlayer.bowlingcenter_city_name} - {opponentPlayer.bowlingcenter}</div>
                                  <div>{intl.formatMessage({ id: 'LaneNumber', defaultMessage: 'Lane №'})} {opponentPlayer.playerlinenumber}</div>
                                </div>
                              </div>
                              <GameScore
                                currentFrame={side == 0 && protestGame == currentGame ? protestFrame.framenumber : null}
                                isOwner={false}
                                previousGameScore={0}
                                frames={game.frames.opponent}
                                onFrameSelect={this.onFrameSelect}
                              />
                            </div>
                          ) : null}
                        <div className="player owner">
                          <div className="player-head">
                            <div className="names">
                              <div className="fullname">{`${currentPlayer.userfirstname} ${currentPlayer.userlastname}`}</div>
                              <div className="login">{currentPlayer.userlogin}</div>
                            </div>
                            <div className="lane">
															<div className="bowling"><Flag name={currentPlayer.bowlingcenterflag}/> {currentPlayer.bowlingcenter_city_name} - {currentPlayer.bowlingcenter}</div>
															<div>{intl.formatMessage({ id: 'LaneNumber', defaultMessage: 'Lane №'})} {currentPlayer.playerlinenumber}</div>
                            </div>
                          </div>
                          <GameScore
                            currentFrame={
                              tournamenttypeid === 3 
                                ? protestFrame.framenumber
                                : side == 1 && protestGame == currentGame ? protestFrame.framenumber : null}
                            isOwner={true}
                            previousGameScore={0}
                            onFrameSelect={this.onFrameSelect}
                            frames={game.frames.current}
                          />
                        </div>
                      </div>
                    </TabPanel>
                  ))
                }
              </Tabs>
            </div>
            <div className="current-game">
              <div className="cameras" ref={(c) => this.cameras = c}>
                <Tabs className="tabs" selectedIndex={!videoOfOpponent ? 0 : 1} onSelect={() => {
                    this.switchVideo()
                }}>
                  <TabList className="tablist">
                    <div className="tab">
                      {intl.formatMessage({ id: 'GameCameraList', defaultMessage: 'Cameras:' })}
                    </div>
                    <Tab className="tab">
                      {`${currentPlayer.userfirstname} ${currentPlayer.userlastname}`}
                    </Tab>
                    
                    {
                      opponentPlayer 
                        ? (
                          <Tab className="tab">
                            {`${opponentPlayer.userfirstname} ${opponentPlayer.userlastname}`}
                          </Tab>
                        ) : null
                    }
                  </TabList>
                  {
                    streams.opponent
                      ? (
                        <TabPanel className="tabpanel">
                          <div key={cameras} className="video-container" ref={(c) => this.videoContainer = c}>
                            <div className="left-video-container" ref={(c) => this.leftVideoContainer = c}>
                              <div
                                key={cameras[0]}
                                onClick={() => { this.switchCamera(cameras[0]) }}
                                className="video"
                              >
                                {this.renderVideoPlayer(streams.opponent[cameras[0]], 0, cameras[0])}
                              </div>
                              <div
                                key={cameras[1]}
                                onClick={() => { this.switchCamera(cameras[1]) }}
                                className="video"
                              >
                                {this.renderVideoPlayer(streams.opponent[cameras[1]], 1, cameras[1])}
                              </div>
                            </div>
                            <div className="right-video-container" ref={(c) => this.rightVideoContainer = c}>
                              <div key={cameras[2]} className="video">{this.renderVideoPlayer(streams.opponent[cameras[2]], 2, cameras[2])}</div>
                            </div>
                          </div>
                        </TabPanel>
                      ) : null
                  }
                  <TabPanel className="tabpanel">
                    <div key={cameras} className="video-container" ref={(c) => this.videoContainer = c}>
                      <div className="left-video-container" ref={(c) => this.leftVideoContainer = c}>
                        <div onClick={() => { this.switchCamera(cameras[0]) }} className="video">{this.renderVideoPlayer(streams.current[cameras[0]], 0, cameras[0])}</div>
                        <div onClick={() => { this.switchCamera(cameras[1]) }} className="video">{this.renderVideoPlayer(streams.current[cameras[1]], 1, cameras[1])}</div>
                      </div>
                      <div className="right-video-container" ref={(c) => this.rightVideoContainer = c}>
                        <div className="video">{this.renderVideoPlayer(streams.current[cameras[2]], 2, cameras[2])}</div>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
                <div className="footer">
                  <VideoController className="timer"
                    ref={(c) => this.controller = c}
                    time={startTime}
                    duration={games[currentGame].duration}
                    muted={videoPlayersMuted}
                    onChangeTimer={this.onChangeTimer.bind(this)}
                    onAfterChangeTimer={this.onAfterChangeTimer.bind(this)}
                    onPlay={this.play.bind(this)}
                    onPause={this.pause.bind(this)}
                    onEndTimer={this.onEndTimer.bind(this)}
                    prevGame={prevGame}
                    nextGame={nextGame}
                    onChangeGame={this.onChangeGame.bind(this)}
                    onMute={this.onMute.bind(this)}
                    onUnmute={this.onUnmute.bind(this)}
                    onTick={this.onTick.bind(this)}
                  />
                </div>
              </div>
              <div className="other" ref={(c) => this.other = c}>
                <div className="title">
                  {intl.formatMessage({ id: 'RefereeingTitle', defaultMessage: 'Refereeing of party #{partyN}/{gameN}, protest #{protestN}, frame #{frameN}' }, { 
                    partyN, gameN, protestN, frameN
                  })}
                </div>
                <div className="refereeing-container">
                  {
                    protestAccepted 
                      ? this.renderFrameEditor() 
                      : this.renderProtest()
                  }
                </div>
                <div className="title">
                  {intl.formatMessage({ id: 'ChatTitle', defaultMessage: 'Chat' })}
                </div>
                <ul className="archive-chat">
                  {
                    messages.map((data, index) => {
                      if (data.timestamp <= gameTime) {
                        return (
                          <ChatMessage onTimeClick={this.changeGameTime.bind(this)} key={index} data={data} />
                        )
                      }
                    })
                  }
                </ul>
              </div>
            </div>
          </div>
          {
            showModalProtestDecision &&
            <ModalProtestDecision
              visible
              complaint={complaint}
              reason={reason}
              oldFrame={oldFrame}
              newFrame={newFrame}
              protestid={protest.id}
              accepted={protestAccepted}
              consider_start={this.consider_start}
              onCancel={() => {
                this.onCancelDecision()
              }}
              onSubmit={() => {
                this.onDoneDecision()
              }}
            />
          }
        </div>
      );
    } else {
      return (
        <div className="videoarchive"></div>
      );
    }
  }
}

export default injectIntl(withRouter(
  connect(
    state => ({
      videoarchive: state.videoarchive,
      lang: state.langs.lang,
      user: state.users.user,
      refereeSessionId: state.protocol.refereeSessionId,
    }),
    {
      getVideoArchive,
      ChangeLang
    }
  )(RefereeingPage)
));