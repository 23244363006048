import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setCommonNotification } from '../../../../libs/reducers/commonReducer';

import OBModal from '../../Modal';
import { Button } from '../../Button';

import './style.scss';

const getNotificationData = (brief, intl) => {
  const notificationData = {
    alreadyFriends: {
      title: intl.formatMessage({ id: 'notice', defaultMessage: 'Notice' }),
      message: intl.formatMessage({ id: 'AlreadyFriends', defaultMessage: 'You are already friends or there is a friend request' })
    },
    alreadyNotFriends: {
      title: intl.formatMessage({ id: 'notice', defaultMessage: 'Notice' }),
      message: intl.formatMessage({ id: 'alreadyNotFriends', defaultMessage: 'This user is no longer on your friend list' })
    },
    withoutChanges: {
      title: 'Notice',
      message: 'No changes to save'
    },
    notMemory: {
      title: intl.formatMessage({ id: 'notice', defaultMessage: 'Notice' }),
      message: intl.formatMessage({ id: 'save_battle_overflow_space', defaultMessage: 'Not enough disk space' }),
    },
    weHaveSendYouEmail: {
      title: intl.formatMessage({ id: 'notice', defaultMessage: 'Notice' }),
      message: intl.formatMessage({ id: 'weHaveSendYouEmail', defaultMessage: 'We have sent you an email, please confirm your e-mail address' })
    },
    emailVerificationFailed: {
      title: intl.formatMessage({ id: 'notice', defaultMessage: 'Notice' }),
      message: intl.formatMessage({ id: 'EmailVerificationFailed', defaultMessage: 'Email verification failed' }),
    },
    waitUntilGame: {
      title: intl.formatMessage({ id: 'notice', defaultMessage: 'Notice' }),
      message: intl.formatMessage({ id: 'waitUntilGame', defaultMessage: 'Wait until the game is over to start a new one' }),
    },
    ageLimit: {
      title: intl.formatMessage({ id: 'TournamentAgeLimit', defaultMessage: 'Age limit' }),
      message: intl.formatMessage({ id: 'TournamentAgeLimitError', defaultMessage: 'There are age limit for this tournament, user must be over 18 age old' }),
    },
    default: {
      title: intl.formatMessage({ id: 'notice', defaultMessage: 'Notice' }),
      message: intl.formatMessage({ id: 'notice', defaultMessage: 'Notice' })
    }
  };

  return (notificationData[brief])
    ? notificationData[brief]
    : notificationData.default;
}


class ModalNotification extends Component {
  static propTypes = {
    notificationType: PropTypes.string,
    intl: intlShape,
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      title: '',
      message: '',
      notificationType: ''
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!!nextProps.notificationType && nextProps.notificationType !== prevState.notificationType) {
      const data = {
        ...getNotificationData(nextProps.notificationType, nextProps.intl),
        visible: true,
        notificationType: nextProps.notificationType
      }

      return data;
    }

    return null;
  }

  handleOk = () => {
    this.setState({
      visible: false,
      title: '',
      message: '',
      notificationType: ''
    });
    this.props.setCommonNotification('');
  };

  render() {
    const {
      visible,
      title,
      message
    } = this.state;

    return (
      <OBModal
        title={title}
        visible={visible}
        width={395}
        onOk={this.handleOk}
        closable={false}
        className='dialog'
        zIndex="1005"
        footer={[
          <div key='buttons' className='dialog__buttons'>
            <Button
              onClick={this.handleOk}
              kind='aquamarine'
              className='dialog__button-accept'
            >Ok</Button>
          </div>
        ]}
      >
        <div
          key='message'
          className='dialog__message'>
          {message}
        </div>
      </OBModal>
    );
  }
}


export default injectIntl(
  connect(
    state => ({ }),
    {setCommonNotification},
  )(ModalNotification),
);
