import socketio from 'socket.io-client';
import config from '../config';
import Utils from './utils';

const { getFramework } = Utils;
const framework = getFramework();

function dummy() {}
const logger = {
  log: console.log,
  error: console.error,
};

class ChatSocket {
  constructor(url, query) {
    this.socket = null;
    this.connected = false;
    this.url = url;
    this.query = query;
  }

  onConnect = () => {
    this.connected = true;
    logger.log('ChatSocket::onConnect()');
  };

  onDisconnect = () => {
    this.connected = false;
    logger.log('ChatSocket::onDisconnect()');
  };

  initUser = (user) => {
    const { userid, sessionkey } = user;
    if (framework === 'ReactNative') {
      this.url = (config.chatSocketSecure ? 'wss:' : 'ws:') + config.FETCH.chatUrl;
    } else {
      this.url = config.FETCH.chatUrl;
    }
    this.query = `userid=${userid}&token=${sessionkey}`;
  };

  reconnect = () => {
    logger.log('ChatSocket::reconnect()');
    const { connected, socket } = this;
    if (socket && connected) {
      socket.off('connect', this.onConnect);
      socket.off('disconnect', this.onDisconnect);
    }
    return this.connect();
  };

  connect = (callback = dummy) => {
    const { query } = this;
    this.socket = socketio.connect(
      this.url,
      { query, rejectUnauthorized: false, reconnection: true, reconnectionDelay: 2000 },
    );
    this.socket.on('connect', () => {
      callback();
      this.onConnect();
    });
    this.socket.on('disconnect', this.onDisconnect);
    return this.socket;
  };

  disconnect = () => {
    if (this.socket) {
      logger.log('ChatSocket::disconnect()');
      this.socket.disconnect();
      this.socket = null;
      this.connected = false;
    }
  };

  getSocket = () => this.socket;

  isConnected = () => this.connected;
}

export default ChatSocket;
