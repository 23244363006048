import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import './style.scss';

class ProtestList extends React.Component {
  static propTypes = {
    protests: PropTypes.array,
    onSelect: PropTypes.func.isRequired,
    onClickHandle: PropTypes.func.isRequired,
    intl: intlShape,
  };

  selectElement = (order) => {
    const { onSelect } = this.props;
    onSelect(order);
  }

  render() {
    const {
      protests, onClickHandle, players, userid, intl,
    } = this.props;

    return (
      <div className='protest__button'>
        <div
          className='protest_button-caption'
          onClick={onClickHandle}>
          {intl.formatMessage({ id: 'ProtestAsGameComplaintAlias', defaultMessage: 'Protest' })}
        </div>
        {protests.length
          ? <div
            className='protest__dropdown-toggle'
            tabIndex='0'>
              <svg height="9" width="16">
                <path d="M8 0 L0 8 L16 8 Z"/>
              </svg>
              <div className='protest__dropdown'>
                <span className='protest__container__header'>{intl.formatMessage({ id: 'PendingProtestsAsActiveProtestsAlias', defaultMessage: 'Pending protests' })}</span>
                <div className='protest__container'>
                  {protests.map((protest, index) => {
                    const { reason } = protest;
                    const _reason = intl.formatMessage({ id: reason, defaultMessage: reason });
                    let owner = 'unknown';
                    let isAntag = false;
                    const protestOwner = protest.protestCreator;
                    if (protestOwner) {
                      owner = `${protestOwner.firstname} ${protestOwner.lastname}`;
                      isAntag = (protestOwner.userid === userid);
                    }

                    return (
                    <div className='protest__element' key={index} onClick={() => { this.selectElement(protest.id); }}>
                      <div>
                        <div className={`protest__owner ${isAntag ? 'antag' : 'protag'}`}>{owner}</div>
                        <div className='protest__reason'>{_reason}</div>
                      </div>
                      <div>
                        <div className='protest__number'>{intl.formatMessage({ id: 'number', defaultMessage: '#{number}' }, { number: protest.id })}</div>
                        <div className='protest__time'>{moment(protest.created).format('HH:mm')}</div>
                      </div>
                    </div>
                    );
                  })}
                </div>
              </div>
              <div className='protest__amount'>
                {protests.length}
              </div>
            </div>
          : null}
      </div>
    );
  }
}

export default injectIntl(connect(
  state => ({
    userid: state.users.user.id,
  }),
  {},
)(ProtestList));
