const {
  GET_USER_SETTINGS,
  SET_USER_SETTINGS,
  SET_USER_SETTING_VALUE,
  SET_BC_NOT_PLATFORM_FETCHED,
} = require('./actiontypes').default;

const initialState = {
  data: {},
  fetching: false,
  saving: false,
  error: null,
  externalBCData: {
    id: null,
    name: null,
    userid: null,
  }
};

const UserSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_SETTINGS: {
      return { ...state, fetching: true };
    }
  case SET_BC_NOT_PLATFORM_FETCHED:
    return { ...state, externalBCData: action.payload}
    case SET_USER_SETTING_VALUE:
      return { ...state, saving: true };
    case SET_USER_SETTINGS:
      if (action.error) {
        return { ...state, fetching: false, saving: false, error: action.error };
      }
      return { ...state, fetching: false, saving: false, data: action.payload, error: null };
    default:
      return state;
  }
};

export default UserSettingsReducer;
