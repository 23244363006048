import React, {useEffect, useState} from 'react';
import ReferralTH from "./referralTH";
import {Button} from "../../../../common/Button";
import Input from "../../../../common/Input";
import OBModal from "../../../../common/Modal";
import {Radio} from "antd";
import ReferralFilterRadio from "../filterRadio";

const headerTitles = [
    {
        id: '#',
        title: '#',
    },
    {
        id: 'user',
        title: 'User',
        withFilter: true,
        isFiltered: false,
        withSort: true,
        modalVisible: false,
        sortMethod: 0,
    },
    {
        id: 'usersLogin',
        title: 'User\'s login',
        withFilter: true,
        isFiltered: false,
        withSort: true,
        modalVisible: false,
        sortMethod: 0,
    },
    {
        id: 'invitedPlayer',
        title: 'Invited player',
    },
    {
        id: 'invitedPlayerLogin',
        title: 'Invited player\'s login',
    },
    {
        id: 'registrationDate',
        title: 'Registration date',
        withSort: true,
        modalVisible: false,
        sortMethod: 0,
    },
    {
        id: 'firstGameDate',
        title: 'First game date',
        withSort: true,
        modalVisible: false,
        sortMethod: 0,
    },
    {
        id: 'bowlingCenter',
        title: 'Bowling center',
        withFilter: true,
        isFiltered: false,
        withSort: true,
        modalVisible: false,
        sortMethod: 0,
    },
    {
        id: 'totalNumber',
        title: 'Total number of matches',
        withFilter: true,
        isFiltered: false,
        withSort: true,
        modalVisible: false,
        sortMethod: 0,
    },
    {
        id: 'referralBonus',
        title: 'Referral bonus paid',
        withFilter: true,
        isFiltered: false,
        modalVisible: false,
    },
]

const HeaderTR = ({setRenderData, reserveData, renderData}) => {
    const [renderTitles, setRenderTitles] = useState(headerTitles);
    const [checkBoxFilterOption, setCheckBoxFilterOption] = useState('All');
    const [userFilterOption, setUserFilterOption] = useState('');
    const [loginFilterOption, setLoginFilterOption] = useState('');
    const [bcFilterOption, setBcFilterOption] = useState('');
    const [totalNumberFilterOption, setTotalNumberFilterOption] = useState('All');


    // Filter block
    function filterIconSwitch(id, current) {
        setRenderTitles(renderTitles.map(title => {
            if (title.id === id){
                title.isFiltered = !!current
                if (id === 'totalNumber' || id === 'referralBonus'){
                     title.isFiltered = true
                    if (current == 'All'){
                        title.isFiltered = false
                    }
                }
                return title
            }
            return title
        }))
    }

    useEffect(() => {
        filterIconSwitch('user', userFilterOption)
    }, [!!userFilterOption]);

    useEffect(() => {
        filterIconSwitch('usersLogin', loginFilterOption)
    }, [!!loginFilterOption]);

    useEffect(() => {
        filterIconSwitch('bowlingCenter', bcFilterOption)
    }, [!!bcFilterOption]);

    useEffect(() => {
        filterIconSwitch('totalNumber', totalNumberFilterOption)
    }, [totalNumberFilterOption]);

    useEffect(() => {
        filterIconSwitch('referralBonus', checkBoxFilterOption)
    }, [checkBoxFilterOption]);

    function modalVisibleSwitch(id) {
        setRenderTitles(renderTitles.map(title => {
            if (title.id === id){
                title.modalVisible = !title.modalVisible
                return title
            }
            return title
        }))
    }

    function onFilter(id) {
        setRenderTitles(renderTitles.map(title => {
            if (title.id === id){
                title.modalVisible = !title.modalVisible
                return title
            }
            return title
        }))

        function filterHandler(array, value) {
            return array.some((prop) => prop.toLowerCase().includes(value.toLowerCase()))
        }


        setRenderData(reserveData.filter((data => {
            // user filter
            let userValid = true;
            if (userFilterOption.length) userValid = filterHandler([data.refer.firstname, data.refer.lastname], userFilterOption)
            // login filter
            let loginValid = true;
            if (loginFilterOption.length) loginValid = filterHandler([data.refer.login], loginFilterOption)
            // Bowling center filter
            let bcValid = true;
            if (bcFilterOption.length) bcValid = filterHandler([data.invited.last_game.bc_name], bcFilterOption);
            // Referral bonus paid filter
            let checkBoxValid = true;
            if (checkBoxFilterOption == 'Paid') checkBoxValid = data.is_awarded;
            if (checkBoxFilterOption == 'Unpaid') checkBoxValid = !data.is_awarded;
            // total number of matches filter
            let totalCountValid = true
            if (totalNumberFilterOption == 'More 3') totalCountValid = Number(data.invited.games_count) >= 3
            if (totalNumberFilterOption == 'Less 3') totalCountValid = Number(data.invited.games_count) < 3

            return userValid && loginValid && bcValid && checkBoxValid && totalCountValid
        })))
    }

    //----------------------------------------

    // Sort block

    function sortIconSwitch(id) {
        setRenderTitles(renderTitles.map(title => {
            if (title.id == id) {
                if (title.sortMethod === 2) {
                    title.sortMethod = 0;
                    return title
                }
                title.sortMethod = title.sortMethod + 1;
                return title
            }
            title.sortMethod = 0
            return title
        }));
    }

    function onSort(id) {
        // Визуал кнопок сортировки
        sortIconSwitch(id)
        let {sortMethod} = renderTitles.find(title => title.id == id)

        //Сортировка данных
        function sortHandler(a, b) {
            // max => min
            if (sortMethod === 2){
                if (b < a ){
                    return -1
                }
                if (b > a) {
                    return 1
                }
            }
            // min => max
            if (sortMethod === 1){
                if (a > b) {
                    return 1
                }
                if (a < b ){
                    return -1
                }
            }
            // ===
            return 0
        }

        if (sortMethod === 0){
            setRenderData([...renderData.sort((a, b) => Number(b.id) - Number(a.id))])
        }
        setRenderData([...renderData.sort((dataA, dataB ) => {
            let a = ''
            let b = ''
            switch (id) {
                case 'user':
                     a = `${dataA.refer.firstname} ${dataA.refer.lastname}`.toLowerCase()
                     b = `${dataB.refer.firstname} ${dataB.refer.lastname}`.toLowerCase()
                    return sortHandler( a, b )
                    break
                case 'usersLogin':
                     a = dataA.refer.login.toLowerCase()
                     b = dataB.refer.login.toLowerCase()
                    return sortHandler(a, b)
                    break
                case 'registrationDate':
                    a = new Date(dataA.invited.register_date)
                    b = new Date(dataB.invited.register_date)
                    return sortHandler(a, b)
                    break
                case 'firstGameDate':
                    a = new Date(dataA.invited.first_game_date)
                    b = new Date(dataB.invited.first_game_date)
                    return sortHandler(a, b)
                    break
                case 'bowlingCenter':
                    a = dataA.invited.last_game.bc_name.toLowerCase()
                    b = dataB.invited.last_game.bc_name.toLowerCase()
                    return sortHandler(a, b)
                    break

                case 'totalNumber':
                    a = Number(dataA.invited.games_count)
                    b = Number(dataB.invited.games_count)
                    return sortHandler(a, b)
                    break

                default :
                    break
            }
        })])
    }

    // -----------------------------------------------


    return (
        <tr>
            {renderTitles.map((header) => {
                let inputValue = ''
                let setInputValue = () => { }

                if (header.id == 'user') {
                    inputValue = userFilterOption
                    setInputValue = setUserFilterOption
                }

                if (header.id == 'usersLogin'){
                    inputValue = loginFilterOption
                    setInputValue = setLoginFilterOption
                }

                if (header.id == 'bowlingCenter'){
                    inputValue = bcFilterOption
                    setInputValue = setBcFilterOption
                }

                return (
                    <>
                        <ReferralTH
                            key={header.id}
                            title={header.title}
                            withFilter={header.withFilter}
                            withSort={header.withSort}
                            isFiltered={header.isFiltered}
                            sortMethod={header.sortMethod}
                            header={header}
                            onClickFilter={() => modalVisibleSwitch(header.id)}
                            onClickSort={() => onSort(header.id)}
                            modal={ () => {
                                return <OBModal
                                    title={header.title}
                                    visible={header.modalVisible}
                                    closable={false}
                                    footer={
                                        <Button
                                            key="okButton"
                                            kind="aquamarine"
                                            onClick={() => onFilter(header.id)}>
                                            OK
                                        </Button>}>

                                    {
                                        header.id === 'referralBonus' ?
                                            <ReferralFilterRadio
                                                value={checkBoxFilterOption}
                                                setValue={setCheckBoxFilterOption}
                                                buttonArray={["All", "Paid", "Unpaid"]}
                                            />
                                            : header.id === 'totalNumber' ?
                                                <ReferralFilterRadio
                                                    value={totalNumberFilterOption}
                                                    setValue={setTotalNumberFilterOption}
                                                    buttonArray={["All", "More 3", "Less 3"]}
                                                />
                                                :
                                                <Input
                                                    onChangeText={(e) => setInputValue(e)}
                                                    value={inputValue}
                                                    placeholder={'Filter'}
                                                />
                                    }
                                </OBModal>
                            }}
                        />
                    </>
                )
            })}
        </tr>
    );
};

export default HeaderTR;
