import React from 'react';

const aboutTournamentImg = require('../../../images/elyt/aboutTenpincam.png');

function AboutTenpincam({
  history,
}) {
  return (
    <div className="aboutTenpincam">
      <div className="firstBlock">
        <div className="textBlock">With TenPinCam there are no more borders between the countries for bowlers!</div>
        <div className="textBlock">TenPinCam is a unique platform for games with your Friends & Bowling competitions in Real Time in different bowling centers across the world, using the web cameras & Internet connection.</div>
        <div>
          <img className="image" src={aboutTournamentImg}/>
        </div>
        <div className="secondBlock">
          <div className="textBlock">TenPinCam platform has been created, to enable bowlers to compete on International level without travelling and the expenses, related to it. We had removed all borders between the bowlers to make it truly worldwide with TenPinCam.</div>
          <div className="btn-container">
            <div className="btn" onClick={() => { history.push('/'); }}>
              Visit Website
              <svg viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M65 6V65M65 65H6M65 65L1 1" stroke="#F65932" strokeWidth="2"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutTenpincam;
