import {
  _BattlesGet,
  _BattlesResponseToUser,
  _BattlesResponseToOwner,
  _BattlesBattleCancel,
  _AccountBattlesGet,
  _GetAccountBattleHistory,
  _SquadListFetch,
  _GetGameInfo,
  _GetProtestCount,
  _OpenChallengesGet, _ClosedOpenChallengesGet, _ActiveSquadCount,
} from './battles.handler';
import { takeLatest } from 'redux-saga/effects';
import takeLeading from '../tools/takeLeading';

const {
  BATTLES_FETCH,
  SQUAD_LIST_FETCH,
  BATTLES_BATTLE_CANCEL_FETCH,
  BATTLES_RESPONSE_TO_USER_FETCH,
  BATTLES_RESPONSE_TO_OWNER_FETCH,
  ACCOUNT_BATTLES_FETCH,
  ACCOUNT_BATTLES_HISTORY_FETCH,
  GET_PROTEST_COUNT,
  OPENCHALLENGES_FETCH,
  CLOSED_OPENCHALLENGES_FETCH,
  ACTIVE_SQUADS_COUNT_GET,
} = require('../../reducers/actiontypes').default;


export function* _WatchBattles() {
  try {
    yield takeLatest(BATTLES_FETCH, _BattlesGet);
    yield takeLatest(SQUAD_LIST_FETCH, _SquadListFetch);
    yield takeLatest(BATTLES_BATTLE_CANCEL_FETCH, _BattlesBattleCancel);
    yield takeLeading(BATTLES_RESPONSE_TO_USER_FETCH, _BattlesResponseToUser);
    yield takeLatest(BATTLES_RESPONSE_TO_OWNER_FETCH, _BattlesResponseToOwner);
    yield takeLatest(ACCOUNT_BATTLES_FETCH, _AccountBattlesGet);
    yield takeLatest(ACCOUNT_BATTLES_HISTORY_FETCH, _GetAccountBattleHistory);
    yield takeLatest(GET_PROTEST_COUNT, _GetProtestCount);
    yield takeLatest(OPENCHALLENGES_FETCH, _OpenChallengesGet);
    yield takeLatest(CLOSED_OPENCHALLENGES_FETCH, _ClosedOpenChallengesGet);
    yield takeLatest(ACTIVE_SQUADS_COUNT_GET, _ActiveSquadCount);
  } catch (error) {
    console.error('===WatchBattles Error: ', error);
  }
}
