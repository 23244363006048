import { call, put, takeLatest, select } from 'redux-saga/effects';
import bugsnagClient from '../../bugsnagClient';
import config from '../../config';
import { get, post } from '../Networking';
import { payseraOrderFailure, payseraOrderSuccess, payseraWithdrawFailureAction, payseraWithdrawSuccessAction } from '../reducers/actions';
import {
  GEODATA_FETCH,
  GEODATA_SET,
  USERGEODATA_FETCH,
  USERGEODATA_SET,
  DEFAULTTIMEZONE_FETCH,
  DEFAULTTIMEZONE_SET,
  GEODATA_CITIES_SET,
  GEODATA_CITIES_FETCH,
  SETTINGS_FETCH,
  SETTINGS_SET,
  COMMON_ERROR,
  COMMON_ERROR_FETCH,
  AGREEMENTS_SET,
  MOBILE_VERSION_SET,
  SET_ACTIVE_HDC,
  FETCHED_ACTIVE_HDC,
  SET_ACTIVE_LANGS,
} from '../reducers/commonReducer';

const {
  GEODATA_TIMEZONES_SET,
  PAYMENTDATA_SET,
  PAYMENTTOKEN_SET,
  USERCARDS_SET,
  SET_WALLET_HISTORY,
  GET_TARIFF_MY,
  SET_TARIFF_MY,
  SET_TARIFFS,
  SET_COMMISSION,
  SET_COOKIE_STATE,
  SET_BILLING_LOANS,
  UPDATE_USER_DATA,
  FETCH_WALLET_HISTORY,
  SET_PAYPAL_ORDER,
  SET_PAYPAL_ERROR,
  SET_HANDICAP_LIST,
  SET_UPLOAD_APK
} = require('../reducers/actiontypes').default;

export const getUser = state => state.users.user;

export function* _DeleteCreditCard({ params }) {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/ecom/mycards/delete/${params}/card`});
    if (result.status === 200 && result.data.success) {
      yield put({ type: USERCARDS_SET, params: result.data });
    } else {
      yield put({ type: USERCARDS_SET, params: { cards: [], ibans: [] } });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'saga: DeleteCreditCard' });
  }
}

export function* _DeleteIBAN({ params }) {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/ecom/mycards/delete/${params}/iban`});
    if (result.status === 200 && result.data.success) {
      yield put({ type: USERCARDS_SET, params: result.data });
    } else {
      yield put({ type: USERCARDS_SET, params: { cards: [], ibans: [] } });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'saga: DeleteCreditCard' });
  }
}

export function* _GetCreditCards() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/ecom/mycards`});
    if (result.status === 200 && result.data.success) {
      yield put({ type: USERCARDS_SET, params: result.data });
    } else {
      yield put({ type: USERCARDS_SET, params: { cards: [], ibans: [] } });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'saga: CreditCards' });
  }
}

export function* _SaveIBAN({ params }) {
  try {
    const result = yield call(post, { url: `${config.FETCH.url}/ecom/iban/save`, data: params });
    if (result.status === 200 && result.data.success) {
      yield put({ type: USERCARDS_SET, params: result.data });
    } else {
      yield put({ type: USERCARDS_SET, params: { cards: [], ibans: [] } });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'saga: SaveIBAN' });
  }
}

export function* _createIBANpayment({ params }) {
  try {
    yield call(post, { url: `${config.FETCH.url}/ecom/ecom_create/iban`, data: params });
    yield put({ type: UPDATE_USER_DATA });
    yield put({ type: FETCH_WALLET_HISTORY });
  } catch (error) {
    bugsnagClient.notify(error, { context: 'saga: SaveIBAN' });
  }
}

export function* _GetDefaultTimezone({ params }) {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/geodata/timezones/${params}` });
    if (result.status === 200 && result.data.success) {
      yield put({ type: DEFAULTTIMEZONE_SET, data: result.data.data });
    } else {
      yield put({ type: DEFAULTTIMEZONE_SET, error: result.data.data.error || result.status });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'saga: GeoData' });
    yield put({ type: DEFAULTTIMEZONE_SET, error });
  }
}

export function* _GetUserCountry() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/geodata/mycountry` });
    if (result.status === 200 && result.data.success) {
      yield put({ type: USERGEODATA_SET, data: result.data.data });
    } else {
      yield put({ type: USERGEODATA_SET, error: result.data.error || result.status });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'saga: GeoData' });
    yield put({ type: USERGEODATA_SET, error });
  }
}

export function* _GetGeoData(params = {}) {
  const request = params.params || 'all';
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/geodata/${request}` });
    if (result.status === 200 && result.data.success) {
      yield put({ type: GEODATA_SET, data: result.data.data });
    } else {
      yield put({ type: GEODATA_SET, error: result.data.error || result.status });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'saga: GeoData' });
    yield put({ type: GEODATA_SET, error });
  }
}

export function* _GetCities(params) {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/geodata/${params.params}` });
    if (result.status === 200 && result.data.success) {
      yield put({ type: GEODATA_CITIES_SET, data: result.data.data.cities });
    } else {
      yield put({ type: GEODATA_CITIES_SET, error: result.data.error || result.status });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'saga: GetCities' });
  }
}

export function* _GetTimeZones() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/geodata/timezones` });
    if (result.status === 200 && result.data.success) {
      yield put({ type: GEODATA_TIMEZONES_SET, data: result.data.data });
    } else {
      yield put({ type: GEODATA_TIMEZONES_SET, error: result.data.error || result.status });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'saga: GetTimeZones' });
  }
}

export function* _GetAgreements({params}) {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/agreements/${params}` });
    if (result.status === 200 && result.data.success) {
      yield put({ type: AGREEMENTS_SET, data: result.data.data });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'saga: GetAgreements' });
  }
}

export function* _GetSettings() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/settings` });
    if (result.status === 200 && result.data.success) {
      yield put({ type: SETTINGS_SET, data: result.data.data });
    } else {
      yield put({ type: SETTINGS_SET, error: result.data.error || result.status });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'saga: GetSettings' });
  }
}

export function* _SetCommonError(params) {
  try {
    yield put({ type: COMMON_ERROR, data: params.params });
  } catch (error) {
    bugsnagClient.notify(error, { context: 'saga: SetCommonError' });
  }
}

export function* _WatchCommon() {
  try {
    yield takeLatest(GEODATA_FETCH, _GetGeoData);
    yield takeLatest(USERGEODATA_FETCH, _GetUserCountry);
    yield takeLatest(DEFAULTTIMEZONE_FETCH, _GetDefaultTimezone);
    yield takeLatest(GEODATA_CITIES_FETCH, _GetCities);
    yield takeLatest(SETTINGS_FETCH, _GetSettings);
    yield takeLatest(COMMON_ERROR_FETCH, _SetCommonError);
  } catch (error) {
    bugsnagClient.notify(error, { context: 'saga: WatchCommon' });
  }
}

export function* _GetWalletHistory() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/billing/myhistory` });
    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_WALLET_HISTORY, params: result.data.result });
    } else {
      yield put({ type: SET_WALLET_HISTORY, params: [] });
      throw new Error('Server failed to assemble wallet history');
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'getWalletHistory: Server failed to assemble wallet history' });
  }
}

export function* _retrivePaymentTokenRequest() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/ecom/ecom_token` });
    if (result.status === 200 && result.data.success) {
      yield put({ type: PAYMENTTOKEN_SET, params: result.data.data });
    } else {
      throw new Error('Server failed to encrypt data');
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'retrivePaymentTokenRequest: Failed to get encrypted token data' });
  }
}

export function* _encryptData({ params }) {
  try {
    const result = yield call(post, { url: `${config.FETCH.url}/ecom/ecom_encode`, data: {data: params} });
    if (result.status === 200 && result.data.success) {
      yield put({ type: PAYMENTDATA_SET, params: result.data.data });
    } else {
      yield put({ type: PAYMENTDATA_SET, params: { DATA: null, KEY: null, SIGNATURE: null } });
      throw new Error('Server failed to encrypt data');
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'encryptData: Failed to encrypt billing data' });
  }
}


export function* _GetUserTariff() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/user/tariffs/my` });
    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_TARIFF_MY, params: result.data.data });
    } else {
      yield put({ type: SET_TARIFF_MY, params: { tariff: false, renewal: false }});
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'Get user tariff' });
  }
}

export function* _GetTariffs() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/user/tariffs` });
    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_TARIFFS, params: result.data.data });
    } else {
      yield put({ type: SET_TARIFFS, params: [] });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'Get tariff list' });
  }
}

export function* _SwitchTariff() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/user/tariffs/my/renewal` });
    if (result.status === 200 && result.data.success) {
      yield put({ type: GET_TARIFF_MY });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'Tariff renewal switch' });
  }
}

export function* _DropTariff() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/ecom/disable_tariff` });
    yield put({ type: GET_TARIFF_MY });
  } catch (error) {
    bugsnagClient.notify(error, { context: 'Drop tariff' });
  }
}

export function* _GetCommission() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/billing/commissions` });
    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_COMMISSION, params: result.data.data });
    } else {
      yield put({ type: SET_COMMISSION, params: [] });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'Get commissions list' });
  }
}

export function* _GetBillingLoans() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/billing/loans` });
    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_BILLING_LOANS, params: result.data.data });
    } else {
      yield put({ type: SET_BILLING_LOANS, params: {} });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'Get loans list' });
  }
}

export function* _cookieGet() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/user/cookie` });
    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_COOKIE_STATE, params: { state: result.data.cookie, intent: result.data.intent } });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'Get cookie agreement state' });
  }
}

export function* _cookieAgree({ payload }) {
  try {
    const result = yield call(post, { url: `${config.FETCH.url}/user/cookie_agree`, data: payload });
    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_COOKIE_STATE, params: { state: 1, intent: payload.agreement } });
    } else {
      yield put({ type: SET_COOKIE_STATE, params: { state: 1, intent: payload.agreement } });
      throw new Error('Server failed to set agreement with cookies');
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'Set cookie agreement state' });
  }
}

export function* _GetMobileVersion() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/settings/get_mobile_version` });
    if (result.status === 200 && result.data) {
      yield put({ type: MOBILE_VERSION_SET, params: result.data });
    } 
  } catch (error) {
    bugsnagClient.notify(error, { context: 'Get mobile version state' });
  }
}

export function* _GetPayPalOrder({ payload }) {
  try {
    const result = yield call(post, { url: `${config.FETCH.url}/paypal/create_order`, data: payload });
    if (result.status === 200 && result.data.link) {
      yield put({ type: SET_PAYPAL_ORDER, params: result.data.link });
    } else if (result.data.error) {
      yield put({ type: SET_PAYPAL_ERROR });
    }
  } catch (error) {
    bugsnagClient.notify(error, { context: 'Create PayPal order' });
  }
}

export function* payseraOrderRequestSaga({ params }) {
  try {
    const headers = { 'X-Requested-With': 'XMLHttpRequest' };
    const res = yield call(post, { url: `${config.FETCH.url}/paysera/pay`, data: params, headers });
    if (res.status === 200 && res.data.url) {
      yield put(payseraOrderSuccess(res.data.url));
    } else {
      yield put(payseraOrderFailure());
    }
  } catch (error) {
    yield put(payseraOrderFailure());
    bugsnagClient.notify(error, { context: 'Create PaySera order' });
  }
}

export const findEURAccount = (state) => state.user.currencies.find(account => account.currencyisocode === 'EUR');

export function* payseraWithdrawRequestSaga(action) {
  try {
    const account = yield select(findEURAccount);
    if (!account) {
      yield put(payseraWithdrawFailureAction('account with EUR not found'))
      return;
    }
    const { params } = action;
    const data = {
      accountid: account.id,
      amount: Math.floor(params.amount * 100),
      type: params.type,
      beneficiaryid: params.beneficiaryid,
    }
    const res = yield call(post, { url: `${config.FETCH.url}/paysera/withdraw`, data });
    if (res.status === 200 && res.data.success) {
      yield put(payseraWithdrawSuccessAction())
    } else {
      yield put(payseraWithdrawFailureAction())
    }
  } catch (e) {
    yield put(payseraWithdrawFailureAction(e.message))
  }
}

export function* _getHandicapList({ params }) {
  try {
    const url = `${config.FETCH.url}/settings/handicap`;
    const result = yield call(get, { url, params });

    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_HANDICAP_LIST, payload: result.data.data });
    }
  } catch(error) {
    bugsnagClient.notify(new Error(`Error get handicap list. ${JSON.stringify({ error })}`), { context: 'getHandicapList' });
  }
}

export function* _saveHandicapList({ params }) {
  try {
    const url = `${config.FETCH.url}/settings/handicap_update`;
    const result = yield call(post, { url, data: params });

    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_HANDICAP_LIST, payload: result.data.data });
    }
  } catch (error) {
    bugsnagClient.notify(new Error(`Error update handicaps. ${JSON.stringify({ error })}`));
  }
}

export function* _uploadApkFile({ params }) {
  try {
    const url = `${config.FETCH.url}/settings/update_apk`;
    const result = yield call(post, { url, data: params });

    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_UPLOAD_APK });
    }
  } catch (error) {
    console.error('Error upload apk. ', error);
    bugsnagClient.notify(new Error(`Error upload apk. ${JSON.stringify({ error })}`));
  }
}


export function* _getActiveHDC({ params }) {
  try {
    const url = `${config.FETCH.url}/settings/activeHDC`;
    const result = yield call(get, { url });

    if (result.status === 200 && result.data.success) {
      yield put({ type: SET_ACTIVE_HDC, data: result.data.data });
    } else {
      console.error('Error in getting active HDC: ', result.data);
    }
  } catch (error) {
    console.error('Error in getting active HDC: ', error)
    bugsnagClient.notify(new Error(`Error in getting active HDC: ${JSON.stringify({ error })}`));
  }
}

export function* _setActiveHDC({ payload }) {
  try {
    const result = yield call(post, { url: `${config.FETCH.url}/settings/setHDC`, data: payload });
    if (result.status === 200 && result.data) {
      yield put({ type: FETCHED_ACTIVE_HDC });
    }
  } catch (error) {
    console.error('Error in getting active HDC: ', error)
    bugsnagClient.notify(new Error(`Error in getting active HDC: ${JSON.stringify({ error })}`));
  }
}

export function* _getActiveLangs() {
  try {
    const result = yield call(get, { url: `${config.FETCH.url}/settings/langs` });
    if (result.status === 200 && result.data) {
      yield put({ type: SET_ACTIVE_LANGS, params: result.data.data});
    }
  } catch (error) {
    console.error('Error in getting active langs: ', error)
    bugsnagClient.notify(new Error(`Error in getting active langs: ${JSON.stringify({ error })}`));
  }
}