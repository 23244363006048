import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

export default class BadgeIcon extends Component {
  static propTypes = {
    children: PropTypes.oneOfType(PropTypes.arrayOf(PropTypes.node), PropTypes.node),
    count: PropTypes.number,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    badgeColor: PropTypes.string,
    badgeTextColor: PropTypes.string,
    badgeSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    badgeTextSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  static defaultProps = {
    children: null,
    count: 0,
    width: 50,
    badgeColor: '#f65932',
    badgeTextColor: '#fff',
    badgeSize: 16,
    badgeTextSize: 10,
  };

  render() {
    const {
      children, count, width, height = width, badgeColor, badgeTextColor, badgeTextSize, badgeSize,
    } = this.props;

    return children ? (
      <div style={{ width, height }} className="badgeIcon">
        {count > 0 ? (
          <div
            className="badgeIcon__counter"
            style={{
              backgroundColor: badgeColor,
              color: badgeTextColor,
              height: badgeSize,
              fontSize: badgeTextSize,
              borderRadius: badgeSize / 2,
            }}
          >
            {count > 999 ? '999+' : count}
          </div>
        ) : null}
        {children}
      </div>
    ) : null;
  }
}
