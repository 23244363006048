import React from 'react'
import { injectIntl } from 'react-intl'
import DownloadBlock from './download'
import LandingFooter from './footer'
import HeaderTop from './headerTop'
import { withRouter } from 'react-router-dom';

import "./adaptive.scss";
import "./style.scss";
import './installGuide.scss';

import ios from './images/app-store3.png';
import huawei from './images/app-gallery-huawei.png';
import samsung from './images/galaxy-store.png';
import google from '../../images/google-play.png';
import downloadIcon from './images/download_icon.png';
// import SystemMenu from '-!svg-react-loader!./images/system_menu.svg'; // eslint-disable-line
import systemMenuIcon from './images/system_menu.png';

const InstallGuide = ({ intl, history }) => {

  const homeAndBenefits = () => {
    history.push('/#benefits')
  }

  const storeList = [
    {
      name: 'for iPhone',
      img: ios,
      link: 'https://apps.apple.com/ru/app/tenpincam/id1481986359?l=en',
    },
    {
      type: 'google',
      name: 'for Android',
      img: google,
      link: 'https://play.google.com/store/apps/details?id=com.tenpincam.telepresence_bowling_1',
    },
    {
      type: 'huawei',
      name: 'for Huawei',
      img: huawei,
      link: 'https://appgallery.huawei.com/#/app/C103559341',
    },
    {
      name: 'for Samsung',
      img: samsung,
      link: 'https://galaxystore.samsung.com/detail/com.tenpincam.telepresence_bowling_1',
    },
  ];

  const systemMenu = (
    <div className="system-menu__container">
      <div className="system-menu-img__container">
        <img src={systemMenuIcon} className="system-menu-img"/>
      </div>
      <span className="system-menu-text">
        The system's pop-up modal window appears after a long press on the "Download" button.
      </span>
    </div>
  );

  const mainList = [
    'In case the download didn’t start, please press the Download the APK FILE button again, holding it for a couple of seconds. In the pop-up menu, select Download link & allow to download the APK file (tenpincam_android_ver_{№ ХХХ}.apk)',
    'After downloading the file, open the notifications panel on your phone & click on the file to install it. You can also do this via the File Manager, finding the file in Downloads.',
    'If you see the message “Installation of the applications from unknown sources disabled” - please go to your phone Settings & enable the Installation of applications from unknown sources. Then find the file in the notification panel or File Manager & try to install again. After the installation it’s better to disable the Unknown sources setting.',
    'In some cases the apk file can be associated with another app on your phone - you will see this, once you click on the TenPinCam apk file, it will open a different app. To resolve this you would have to change the default file associations on your phone & try the steps above.',
    'Now the app is installed, and you can start using it.',
    <>If you still have questions please write to our <a onClick={() => window.TalkMe('openSupport')}>support chat</a>.</>
  ].map((item, index) => <div key={index}>{item}{index === 0 ? systemMenu : <></>}</div>);

  return (
    <>
      <meta name="viewport" content="width=device-width" />
      <div className='landing install'>
        <div className='landing__full_container install'>
          <div className='landing__header install'>
            <div className='landing__container'>
              <HeaderTop
                scrollToBenefits={homeAndBenefits}
                hiddenLang
                hiddenHeaderCenter
              />
              <div className='guideContainer'>

                <div className='installTitle'>How to install the TenPinCam app</div>

                <div className="text">You can install and update the application from the following Markets: App Store, Google Play, Huawei AppGallery & Galaxy Store:</div>
                <div className="store__container">
                  {storeList.map((store, index) => (
                    <div key={index} className="store__item">
                      <div className="store__item-name">
                        {store.name}:
                      </div>
                      <a
                        className="store__item-img__container"
                        target="blank"
                        href={store.link}
                      >
                        <img src={store.img} className="store__item-img" />
                      </a>
                    </div>
                  ))}
                </div>

                <div className="text">The Android version can be also installed by clicking the Download the APK FILE button.</div>

                <div className="download__container">
                  <a
                    target="blank"
                    href="/api/settings/mobile_android_app"
                    className="download__btn"
                  >
                    <img className="download__btn-img" src={downloadIcon} />
                    <span className="download__btn-text">
                      Download the apk file
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <DownloadBlock intl={intl} history={history} />
          <LandingFooter intl={intl} history={history} />
        </div>
      </div>
    </>
  )
}

export default withRouter(injectIntl((InstallGuide)))