import React from 'react';
import SponsorCard from './sponsorCard';


const sponsors = [
  {
    name: 'TenPinCam',
    href: '/',
    image: require('../../../../images/Logo-TenPinCam.png'),
  },
  {
    name: 'ETBF',
    href: 'http://etbf.eu/',
    image: require('../../../../images/elyt/etbf.png'),
  },
  {
    name: 'Brunswick',
    href: 'https://brunswickbowling.com',
    image: require('../../../../images/elyt/Brunswick_Bowling.png'),
  },
];

function Sponsors() {
  return (
    <div className="sponsors">
      {sponsors.map((elem, index) => <SponsorCard key={index} image={elem.image} href={elem.href}/>)}
      <a className="add-sponsor-a" href="mailto:karina.maslova@tenpincam.com?subject=I want to become a sponsor">
        <div className="add-sponsor">
          <span className="text">Do you want to be a sponsor? <span className="nowrap">Contact us</span></span>
          <span className="email">karina.maslova@tenpincam.com</span>
        </div>
      </a>
    </div>
  );
}

export default Sponsors;
