/* eslint-disable arrow-parens */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PersonalDataAddFragment from './personal-data-add.fragment.jsx';
import { validatePersonalDataItem } from './helpers/validate-personal-data';

/**
 * @typedef {import('./personal-data-add.fragment.jsx').IPersonalData} IPersonalData
 * @typedef {import('./personal-data-add.fragment.jsx').IPersonalDataErrors} IPersonalDataErrors
 * @typedef {import('./personal-data-add.fragment.jsx').PersonalDataAddFragmentProps} PersonalDataAddFragmentProps
 */
/**
 * @typedef {{
 *    onErrors?: (errors: IPersonalDataErrors) => any;
 *    errors?: IPersonalDataErrors;
 *  }
 *  & Omit<PersonalDataAddFragmentProps, 'countries' | 'intl'>
 * } PersonalDataAddProps
 */
/**
 * @type {React.FC<PersonalDataAddProps>}
 */
const PersonalDataAddComponentFn = props => {
  const {
    onErrors, onChange, onChangeProp, countries = [], setRef,
  } = props;
  const [errors, setErrors] = useState(props.errors || {});
  const refOnErrors = useRef(onErrors);
  const noop = () => null;
  refOnErrors.current = onErrors || noop;
  useEffect(() => {
    refOnErrors.current(errors);
  }, [errors]);
  useEffect(() => {
    setErrors(props.errors);
  }, [props.errors]);
  return <>
    <PersonalDataAddFragment
      setRef={setRef}
      firstName={props.firstName}
      lastName={props.lastName}
      countryId={props.countryId}
      dob={props.dob}
      personalId={props.personalId}
      province={props.province}
      postcode={props.postcode}
      address={props.address}
      onChange={onChange}
      onChangeProp={(name, value, data) => {
        const nextErrors = validatePersonalDataItem(name, value, data, { countries });
        const currErrors = errors || {};
        if (nextErrors[name] !== currErrors[name]) {
          setErrors({ ...currErrors, ...nextErrors });
        }
        return onChangeProp && onChangeProp(name, value, data);
      }}
      errors={errors}
    />
  </>;
};

class PersonalDataAddComponentClass extends React.Component {
  render() {
    return <PersonalDataAddComponentFn {...this.props} />;
  }
}

/** @type {typeof PersonalDataAddComponentFn} */
const PersonalDataAddComponent = connect(
  state => ({
    countries: (state.common.geodata || {}).data || [],
  }),
  {},
)(PersonalDataAddComponentClass);

export default PersonalDataAddComponent;
