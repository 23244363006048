import Hls from 'hls.js';
import axios from 'axios';
import HLS from 'hls-parser';
import config from '../config';
import Utils from './utils';
import { handleResponseCookies, isAppLoaded, getStateAsync, getStateSync, addCookiesToHeaders } from './nativeCookies';
import sharedOptions from './Networking.options';
const { VIDEOS } = config;
const { getFramework, getOrigin } = Utils;
const framework = getFramework();

const logger = {
  log: VIDEOS.debug ? console.log : function log() {},
};

const errorHandler = (error) => {
  const { status } = (error && error.response) || {};
  const data = {
    error,
    data: {
      error,
    },
  };
  return { status, response: { error, data }, data };
};

const get = async ({ url, ...params }) => {
  try {
    let state;
    if (framework !== 'ReactNative' || isAppLoaded()) {
      state = getStateSync();
    } else {
      state = await getStateAsync();
    }
    const requestOptions = addCookiesToHeaders({ state, origin: Utils.getOrigin(url), options: sharedOptions });
    const req = await axios.get(url, { params, ...requestOptions });
    handleResponseCookies(url, req);
    const { success, error } = req.data;
    if (req.status === 200 && !success) {
      logger.log('error:', error);
    }
    return req;
  } catch (err) {
    return errorHandler(err);
  }
};

const promiseEvent = (hls, event) => new Promise((resolve, reject) => {
  const listener = () => {
    hls.off(event, listener);
    resolve();
  };
  hls.on(event, listener);
  setTimeout(() => reject(new Error('timeout')), 5000);
});

const levelLoaded = hls => promiseEvent(hls, Hls.Events.LEVEL_LOADED);

const getHlsComponents = (hls) => {
  const {
    coreComponents: [,, , ,


      { segments = [] } = {},
      { levels: [{ details: { fragments = [], totalduration = 0 } = {} } = {}] = [] } = {},
    ] = [],
  } = hls || {};
  return { segments, fragments, totalduration };
};

const loadFragment = async (fragment) => {
  logger.log('loadFragment()');
  const { url } = fragment;
  logger.log('loading fragment', url);
  const startTime = Date.now();
  const { headers } = await get({ url: `${url}?a=${Date.now()}` });
  const endTime = Date.now();
  const { 'content-length': size } = headers;
  logger.log('fragment length:', size);
  if (size) {
    const loadTime = (endTime - startTime) / 1000;
    const speed = +size / loadTime; // bytes pre second
    return speed;
  }
  return 0;
};

const getSpeed = async (fragments) => {
  let promise = Promise.resolve(0);
  let speedSum = 0;
  let speedCount = 0;
  fragments.forEach((fragment) => {
    promise = promise.then((speed) => {
      speedSum += speed;
      speedCount += !!speed;
      return loadFragment(fragment);
    });
  });
  try {
    await promise;
    const speedAv = speedSum / (speedCount || 1);
    logger.log('speed av:', speedAv);
    return speedAv;
  } catch (error) {
    logger.log('getSpeed.error:', error);
    return 0;
  }
};

const getFragments = async () => {
  const {
    speedStream: stream = 'vod/bunny.mp4', speedFragMaxLength: maxLength = 3, host, secure,
  } = VIDEOS;
  const baseUrl = `${secure ? 'https' : 'http'}://${host}/${stream}`;
  if (Utils.iOS() || !Hls.isSupported()) {
    const url = `${baseUrl}/tracks-v1a1/mono.m3u8`;
    const { data: txt } = await get({ url });
    const playList = HLS.parse(txt);
    if (playList.isMasterPlaylist) {
      logger.log('MasterPlayList');
      return [];
    }
    logger.log('MediaPlaylist');
    const { segments } = playList;
    logger.log('segments:', segments.length, segments);
    return segments.slice(0, maxLength).map(({ uri }) => ({ url: `${baseUrl}/tracks-v1a1/${uri}` }));
  }
  const url = `${baseUrl}/index.m3u8`;
  const hls = new Hls();
  hls.loadSource(url);
  await levelLoaded(hls);
  const { fragments } = getHlsComponents(hls);
  hls.destroy();
  return fragments.slice(0, maxLength);
};

export default async ({ debug = false } = {}) => {
  logger.log('speedChecker()');
  const log = debug ? console.log : function lg() {};
  try {
    const fragments = await getFragments();
    const speed = await getSpeed(fragments);
    log('av speed:', speed, 'bytes / sec, ', (speed / (1024 * 1024)).toFixed(3), 'MB / sec');
    return speed;
  } catch (error) {
    log('speed.error:', error);
    return 0;
  }
};
