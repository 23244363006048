import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
// :TODO Заменить на применяемые элементы
import { Table, Pagination } from 'antd';
import moment from 'moment';
import { Button } from '../Button';
import Input from '../Input';
import Checkbox from '../Checkbox';
import OBDatePicker from '../DatePicker';
import icons from '../SvgIcons';
import './style.css';

import 'antd/lib/table/style';

// Компонент фильтра для таблицы (от ant не получилось запустить 8( )
function Filter({
  data = {},
  input,
  list = [],
  dateNow = false,
  textOk = 'Ok',
  textReset = 'Reset',
  visible,
  style,
  onClose,
  onSubmit,
}) {
  const [searchText, setSearchText] = useState('');
  const [checkedItems, setCheckedItems] = useState([]); // {}
  const [rangeArray, setRangeArray] = useState([]);

  useEffect(() => {
    if (input && data) {
      switch (data.type) {
        case 'search':
          setSearchText(input);
          break;
        case 'filter':
          if (Array.isArray(input)) {
            setCheckedItems(input);
          } else {
            setCheckedItems([]);
          }

          break;
        case 'range':
          if (Array.isArray(input)) {
            setRangeArray(input.map(i => +i || 0));
          } else {
            setRangeArray([]);
          }
          break;
        case 'date':
          if (Array.isArray(input)) {
            setRangeArray(input);
          } else {
            setRangeArray([]);
          }
          break;
      }
    }
    if (!visible) {
      setSearchText('');
      setCheckedItems([]);
      setRangeArray([]);
    }
  }, [visible]);

  const types = {
    search: () => (
      <div className="n-table-filter__input">
        <Input
          type="text"
          name={data.key}
          onChangeText={onChangeInput}
          value={searchText}
          placeholder={data.name || data.key}
        />
      </div>
    ),
    filter: () => {
      const options = Array.isArray(list) ? list : [];

      return (
        <div className="n-table-filter__options">
          <p className="n-table-filter__label">{data.name || data.key}</p>
          <ul>
            {options.map((item, idx) => (
              <li key={idx}>
                <Checkbox
                  small
                  checked={!!((checkedItems || []).indexOf(item.value) + 1)}
                  // checked={!!checkedItems[item.value]}
                  onChange={value => onChangeCheck(item.value, value)}
                >
                  {item.text}
                </Checkbox>
              </li>
            ))}
          </ul>
        </div>
      );
    },
    range: () => (
      <div className="n-table-filter__range">
        <p className="n-table-filter__label">{data.name || data.key}</p>
        <div>
          <Input
            type="number"
            name="from"
            onChangeText={onChangeRange}
            value={rangeArray[0]}
            placeholder="From"
          />
          <Input
            type="number"
            name="to"
            onChangeText={onChangeRange}
            value={rangeArray[1]}
            placeholder="To"
          />
        </div>
      </div>
    ),
    date: () => (
      <div className="n-table-filter__range">
        <p className="n-table-filter__label">{data.name || data.key}</p>
        <div>
          <OBDatePicker
            dateFormat="D MMM YYYY"
            className="date-group__elements__picker"
            placeholder="From"
            value={(rangeArray[0] && moment(rangeArray[0])) || null}
            onChange={(date, dateStr) => onChangeDate(date, 'from')}
          />
          <OBDatePicker
            dateFormat="D MMM YYYY"
            className="date-group__elements__picker"
            placeholder="To"
            value={(rangeArray[1] && moment(rangeArray[1])) || null}
            onChange={(date, dateStr) => onChangeDate(date, 'to')}
          />
        </div>
      </div>
    ),
  };

  const onOk = () => {
    if (!data) return;

    let dataSend = '';

    switch (data.type) {
      case 'search':
        dataSend = searchText;
        break;
      case 'filter':
        dataSend = checkedItems;
        break;
      case 'range':
        dataSend = rangeArray;
        break;
      case 'date':
        if (dateNow && rangeArray.length && !rangeArray[2]) {
          rangeArray[2] = moment(Date.now()).toISOString();
        }
        dataSend = rangeArray;
        break;
    }

    onSubmit({
      key: data.key,
      value: dataSend,
    });
  };

  const onReset = () => {
    if (!data) return;

    onSubmit({
      key: data.key,
      value: '',
    });
  };

  const onChangeInput = value => {
    setSearchText(value);
  };

  const onChangeCheck = (key, value) => {
    // setCheckedItems({ ...checkedItems, [key]: value });
    if (value) {
      const arr = [...checkedItems, key];
      setCheckedItems(arr);
    } else {
      const arr = checkedItems.filter(i => i != key);
      setCheckedItems(arr);
    }
  };

  const onChangeRange = (value, name) => {
    const arr = [...rangeArray];

    if (name == 'from') {
      arr[0] = value;
    } else if (name == 'to') {
      arr[1] = value;
    }
    setRangeArray(arr);
  };

  const onChangeDate = (date, name) => {
    const arr = [...rangeArray];

    if (name == 'from') {
      arr[0] = date.toISOString();
    } else if (name == 'to') {
      arr[1] = date.toISOString();
    }

    setRangeArray(arr);
  };

  return (
    <>
      <div
        className={`n-table-filter__mask ${visible ? 'active' : ''}`}
        onClick={onClose}
      />
      <div
        style={style || {}}
        className="n-table-filter"
        onClick={e => e.stopPropagation()}
      >
        {data && types[data.type] ? types[data.type]() || null : null}
        <div className="n-table-filter__footer">
          <Button key="okButton" kind="aquamarine" onClick={onOk}>
            {textOk}
          </Button>
          <Button
            key="resetButton"
            kind="aquamarine"
            bordered="true"
            onClick={onReset}
          >
            {textReset}
          </Button>
        </div>
      </div>
    </>
  );
}

function IconByFilter({ type, onChange, defaultActive }) {
  const SvgIcon = icons[type] ? icons[type] : icons.filter;
  const onFilter = e => {
    onChange(e);
  };
  const classes = defaultActive ? 'n-table__icon active' : 'n-table__icon';

  return (
    <a className="n-table-filter-icon" onClick={onFilter}>
      <div className={classes}>
        <SvgIcon />
      </div>
    </a>
  );
}

export default function TableComponent(props) {
  let SHIFT = 0; // Смещение фильтра (вычисляется 1 раз)

  // Table init
  const dataSource = props.dataSource || [];
  const className = `n-table ${props.className || ''}`; // String
  const rowKey = props.rowKey || 'id'; // Func || String
  const style = props.style || { width: '100%' }; // Object
  const locale = props.locale || { emptyText: 'No data' }; // Object
  const rowClassName = props.rowClassName || '';
  const loading = props.loading || false;
  const size = props.size || 'default';
  // Pagination init
  const defaultCurrent =
    props.defaultCurrent && props.defaultCurrent !== 0
      ? props.defaultCurrent
      : 1;
  const defaultPageSize = props.defaultPageSize || 10;
  const total = props.total || 0;
  const delay = props.delay || 500;
  const dateNow = props.dateNow;
  const defaultParam = props.defaultParam || {};

  // State
  const [columns, setColumns] = useState([]);
  const [activeFilter, setActiveFilter] = useState(null);
  const [activeFilterStyle, setActiveFilterStyle] = useState({});
  const [activeFilterOptions, setActiveFilterOptions] = useState([]);
  const [filterParam, setFilterParam] = useState({
    page: defaultCurrent,
    limit: defaultPageSize,
    ...defaultParam,
  });
  const [timerDelay, setTimerDelay] = useState(null);
  const [activeElem, setActiveElem] = useState(null);

  // Refs
  const refFilter = useRef(null);

  useEffect(() => {
    // Определяем отступ от родительского позиционирования
    const tempElement = document.createElement('DIV');

    tempElement.style = 'position: absolute; top: 0; left: 0; width: 100%;';
    refFilter.current.appendChild(tempElement);
    SHIFT =
      tempElement.getBoundingClientRect().top +
      document.documentElement.scrollTop;
    tempElement.parentElement.removeChild(tempElement);

    const columns = [...props.columns];

    columns.map(column => {
      if (column.hasOwnProperty('filter')) {
        const active = defaultParam.hasOwnProperty(column.key);

        column.title = (
          <div className="n-table-title">
            <p>{(typeof column.title === 'string' && column.title) || ''}</p>
            <IconByFilter
              type={column.filter}
              defaultActive={active}
              onChange={e => onOpenFilter(column, e)}
            />
          </div>
        );
      }
    });

    setColumns(columns);

    // Получаем список
    props.onChange(filterParam);
  }, []);

  const onOpenFilter = (data, e) => {
    // type, options
    // Определяем координаты для открытия фильтра
    const elemName = 'th'; // Стиль родительской ячейки
    const elem = e.target.closest(elemName);
    const icon = elem.querySelector('.n-table__icon');
    const elemCoords = (elem && elem.getBoundingClientRect()) || {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    };
    const windowCoords = {
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    };
    const elemFilter = refFilter.current.querySelector('.n-table-filter');
    const styleFilter = {};

    // Подсвечиваем иконку
    icon.classList.add('active');
    setActiveElem(icon);

    elemFilter.style = 'display: block; opacity: 0; z-index: -1000;';
    const sizeFilter = elemFilter.getBoundingClientRect();
    elemFilter.style = '';
    let isDown = true;
    let isLeft = true;

    if (elemCoords.bottom + sizeFilter.height > windowCoords.height) {
      isDown = false;
    }
    if (elemCoords.left - sizeFilter.width < 0) {
      isLeft = false;
    }

    styleFilter.top = isDown
      ? `${elemCoords.bottom + window.pageYOffset - SHIFT}px`
      : `${elemCoords.top + window.pageYOffset - sizeFilter.height - SHIFT}px`;
    styleFilter.left = isLeft
      ? `${elemCoords.right + window.pageXOffset - sizeFilter.width}px`
      : `${elemCoords.right + window.pageXOffset - 20}px`;

    if (data.filterList) setActiveFilterOptions(data.filterList);
    setActiveFilterStyle(styleFilter);
    setActiveFilter({
      type: data.filter,
      key: data.dataIndex,
      name: data.name,
    });
  };

  const onCloseFilter = () => {
    if (!filterParam[activeFilter.key]) {
      activeElem.classList.remove('active');
    }
    setActiveElem(null);

    setActiveFilterStyle({});
    setActiveFilter(null);
  };

  const onChangePage = value => {
    const page = parseInt(value);

    if (!page) {
      return;
    }

    const params = { ...filterParam };

    params.page = page;
    props.onChange(params);
    setFilterParam(params);
  };

  // Задержка перед запросами (не так часто отправлять запросы к БД за поиском)
  const delaySearch = param => {
    const timer = timerDelay;
    let newTimer;

    if (timer) {
      clearTimeout(timer);
    }
    newTimer = setTimeout(props.onChange(param), delay);
    setTimerDelay(timer);
  };

  const onChangeParam = ({ key, value }) => {
    const params = { ...filterParam };

    if (value) {
      params[key] = value;
    } else {
      delete params[key];
      // Подсвечивать ли иконку
      activeElem.classList.remove('active');
    }
    params.page = 1;
    setActiveElem(null);
    setActiveFilterStyle({});
    setActiveFilter(null);

    setFilterParam(params);
    delaySearch(params);
  };

  return (
    <>
      <Table
        className={className}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        rowKey={rowKey}
        style={style}
        locale={locale}
        rowClassName={rowClassName}
        onRow={(record, index) => {}}
        loading={loading}
        size={size}
      />
      {(props.pagination && total && (
        <Pagination
          defaultCurrent={defaultCurrent}
          defaultPageSize={defaultPageSize}
          total={total}
          onChange={onChangePage}
          current={filterParam.page || 1}
        />
      )) ||
        null}
      <div className="n-table-container" ref={refFilter}>
        <Filter
          data={activeFilter}
          input={activeFilter && filterParam[activeFilter.key]}
          list={activeFilterOptions}
          dateNow={dateNow}
          visible={!!activeFilter}
          style={activeFilterStyle}
          onClose={onCloseFilter}
          onSubmit={onChangeParam}
        />
      </div>
    </>
  );
}
