import React from 'react';
import './style.scss';
import { injectIntl } from 'react-intl';

import mclogo from '../../../images/visa_mastercard_logo.png';
import { getPolicyURL } from '../../policy';

class BillingBadge extends React.Component {
  render () {
    const { intl } = this.props;

    const aboutTC = getPolicyURL({ kind: 'TC' });
    return <>
      <div className='badges'>
        <div className='logos'>
          <div className='groupname'>{intl.formatMessage({ id: 'PaymentServicesLogoLabel', defaultMessage: 'Payment services' })}</div>
          <div className='group'>
            <img className='item' src={mclogo}/>
          </div>
        </div>
        <div className='sia'>
          <div className='groupname'>SIA "TenPinCams"</div>
          <div className='group'>
            <div className='item'>Address: Jūrmala, Konkordijas iela 25, LV-2015, Latvia</div>
            <div className='item'>Registration Nr. 42103088398</div>
            <div className='item'>E-mail: info@tenpincam.com Phone: +37129215590</div>
            <div className='item'><a target='_blank' href={aboutTC} rel="noreferrer">Terms and condition</a></div>
            <div className='item'><a target='_blank' href='/about/rc'>Cancellation Policy</a></div>
          </div>
        </div>
      </div>
    </>
  }
}

export default injectIntl(BillingBadge);
