import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { injectIntl } from 'react-intl';
import { Button } from '../common/Button';
import { getAgreements } from '../../libs/reducers/commonReducer';
// import Modal from '../common/Modal';

import './style.scss';
import { openPolicy } from '../policy';

class TermsAndComditionModal extends React.Component {
  componentDidMount() {
    if (!['EN', 'RU'].includes(this.props.langs.lang)) {
      this.props.getAgreements('EN');
    } else {
      this.props.getAgreements(this.props.langs.lang || 'EN');
    }
  }

  componentDidUpdate(prevProps) {
    const { langs: { lang }, visible, onClose } = this.props;
    if (prevProps.langs.lang !== lang) {
      if (['EN', 'RU'].includes(lang)) {
        this.props.getAgreements(lang);
      } else {
        this.props.getAgreements('EN');
      }
    }
    if (!prevProps.visible && visible) {
      if (onClose) {
        onClose();
      }
      openPolicy({ kind: 'TC' });
    }
  }

  renderFooter() {
    return (
      <div className="tc-content__footer-container">
        <Button onClick={() => this.props.onClose()} className="tc-content__footer-btn" kind="orange">
          {this.props.intl.formatMessage({ id: 'GotItThanks', defaultMessage: 'Got it, thanks' })}
        </Button>
      </div>
    );
  }

  render() {
    return null;
    // const {
    //   visible,
    //   onClose = () => {},
    //   agreements,
    //   langs,
    //   intl,
    // } = this.props;

    // let tc = agreements[`TC${(langs.lang || 'en').toLowerCase()}`];
    // if (!tc && !['EN', 'RU'].includes(this.props.langs.lang)) {
    //   tc = agreements.TCen;
    // }
    // const body = tc && (tc.split('body>')[1]);

    // return (
    //   <Modal
    //     title={null}
    //     footer={this.renderFooter()}
    //     visible={visible}
    //     onCancel={onClose}
    //     onOk={onClose}
    //     className="terms-and-condition__modal"
    //     bodyStyle={{ backgroundColor: '#fff', overflowY: 'auto', height: '100%' }}
    //     width={600}
    //     closeIcon={null}
    //     closable={false}
    //   >
    //     <div className="tc-content__container">
    //       <div className="tc-content__header">{intl.formatMessage({ id: 'TCagreeD', defaultMessage: 'Terms and condition' })}</div>
    //       <div className="tc-content__text" dangerouslySetInnerHTML={{ __html: body && body.slice(0, body.length - 2) }} />
    //     </div>
    //   </Modal>
    // );
  }
}

export default connect(
  state => ({
    agreements: state.common.agreements,
    langs: state.langs,
  }),
  { getAgreements }
) (injectIntl(TermsAndComditionModal));
