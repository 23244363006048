import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { components } from 'react-select';
import Select from '../common/Select';
import { ChangeLang } from '../../libs/reducers/actions';
import SelectArrowDown from './images/select_arrow_down.png';
import SelectArrowUp from './images/select_arrow_up.png';

import ESFlag from './images/lang_flags/ES.png';
import GBFlag from './images/lang_flags/GB.png';

const DropdownIndicator = (props) => {
  const { menuIsOpen } = (props && props.selectProps) || {};

  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <img src={!menuIsOpen ? SelectArrowDown : SelectArrowUp} alt="" />
      </components.DropdownIndicator>
    )
  );
};

const IndicatorSeparator = () => null;

const SelectLang = ({
  ChangeLang,
  lang,
}) => {
  const [locales, setLocales] = useState([
    { value: 'EN', clearLabel: 'ENG' },
    { value: 'ES', clearLabel: 'ESP' },
  ]);

  useEffect(() => {
    setLocales(locales.map(locale => ({ ...locale, ...{ label: renderOptionLabel(locale) } })));
  }, []);

  const renderOptionLabel = (locale) => {
    const flags = {
      EN: (props) => <img {...props} src={GBFlag} />,
      ES: (props) => <img {...props} src={ESFlag} />,
      // EN: () => <GbFlag style={{ height: '100%', width: '100%' }} />,
      // ES: () => <EsFlag />,
      // RU: () => <RuFlag />,
    };

    return (
      <div className="lang-option__container">
        <div className="lang-option__img">
          {locale && flags[locale.value.toUpperCase()] && flags[locale.value.toUpperCase()]({ style: { width: '100%', height: '100%', objectFit: 'cover' } })}
        </div>
        <span className="lang-option__text">
          {locale.clearLabel.toUpperCase()}
        </span>
      </div>
    );
  }

  return (
    <Select
      customStyle={{
        control: (base, { selectProps }) => ({
          ...base,
          backgroundColor: 'transparent',
          ':hover': {
            borderColor: 'rgba(255,255,255,0.4)',
          },
          height: 28,
          minHeight: 28,
          width: 98,
          border: '1px solid rgba(255, 255, 255, 0.4)',
          borderRadius: 2,
          boxShadow: 'none',
          cursor: 'pointer',
          borderBottom: selectProps && selectProps.menuIsOpen ? 'none' : undefined,
        }),
        valueContainer: base => ({
          ...base,
          height: 27,
          paddingLeft: 4,
          paddingTop: 4,
          paddingBottom: 4,
        }),
        singleValue: base => ({
          ...base,
          marginLeft: 0,
          marginTop: 0,
          marginBottom: 0,
          color: 'white',
        }),
        dropdownIndicator: base => ({
          ...base,
          paddingLeft: 1,
          paddingRight: 4,
        }),
        option: (base, { isSelected }) => ({
          ...base,
          backgroundColor: isSelected ? '#20a39e' : '#transparent',
          ':hover': {
            backgroundColor: '#20a39e',
          },
          paddingLeft: 5,
          paddingTop: 4,
          paddingBottom: 4,
          cursor: 'pointer',
        }),
        menu: base => ({
          ...base,
          borderRadius: 2,
          margin: 0,
          backgroundColor: 'transparent',
          borderRight: '1px solid rgba(255, 255, 255, 0.4)',
          borderLeft: '1px solid rgba(255, 255, 255, 0.4)',
          borderBottom: '1px solid rgba(255, 255, 255, 0.4)',
        }),
      }}
      className="header-lang__select"
      options={locales
        .filter(locale => locale.value.toLowerCase() !== (lang || 'EN').toLowerCase())
        .map(locale => ({ value: locale.value, label: renderOptionLabel(locale) }))
      }
      defaultValue={locales.find(l => l.value.toLowerCase() === (lang || 'EN').toLowerCase())}
      value={locales.find(l => l.value.toLowerCase() === (lang || 'EN').toLowerCase())}
      onChange={locale => ChangeLang(locale.value)}
      controlShouldRenderValue
      components={{ DropdownIndicator, IndicatorSeparator }}
      isSearchable={false}
    />
  )
};

export default connect(
  state => ({
    lang: state.langs.lang,
  }),
  {
    ChangeLang,
  }
) (SelectLang);
