import React, { Component } from 'react';
import { isArray } from 'lodash';
import PropTypes from 'prop-types';

import './style.css';

const Group = ({
  index,
  focusOptionId,
  children,
  onSelect,
}) => {
  const styles = ['select-block-group'];

  if (index === focusOptionId) {
    styles.push('move');
  }

  return (
    <div
      className={styles.join(' ')}
      onClick={onSelect}
    >{ children }</div>
  );
};

Group.propTypes = {
  children: PropTypes.element.isRequired,
};

class SelectBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowDropDown: false, // Раскрыт ли dropDown
      selectIdx: 0, // idx выбранного пункта
      countShowByList: 3, // количество отображаемых элементов в сиске
      heightItemList: 0, // Высота блока
      styleForList: null, // Стили для dropDown

      childArray: [], // Список

      focusSelect: false, // Фокус на select
      focusOptionId: null, // Выбранный idx с клавиатуры
      startFocusOptionId: 0, // Начальный индекс списка (зависит от upByDropDown)
      upByDropDown: false, // направления расскрытия dropDown

      slider: React.createRef(),
    };
  }

  componentDidMount() {
    const { children } = this.props;
    const childArray = isArray(children)
      ? children
      : [children];
    const countShow = +this.props.countShow

    if (this.props.countShow && typeof countShow === 'number') {
      let countShowByList = countShow < childArray.length
        ? countShow
        : childArray.length;

      this.setState({ countShowByList });
    }

    if (this.props.valueIdx && childArray.length >= this.props.valueIdx) {
      this.setState({ selectIdx: this.props.valueIdx });
    }

    // Направление dropDown
    if (this.props.upByDropDown === 'true') {
      this.setState({
        upByDropDown: true,
        startFocusOptionId: childArray.length - 1,
      });
    }

    this.setState({ childArray });
  }

  eventCloseDropDown = () => {
    const { isShowDropDown } = this.state;

    if (!isShowDropDown) {
      return;
    }

    this.setState({
      isShowDropDown: false,
      focusOptionId: null,
    });

    window.removeEventListener('click', this.eventCloseDropDown, true);
  }

  onToggleList = (event) => {
    if (event) {
      event.preventDefault();
    }

    const { isShowDropDown } = this.state;

    if (!isShowDropDown) {
      this.setState({ isShowDropDown: true });
      // Обработчик на закрытие при любом клике (т.к. с помощью фона не прокручивается модалка)
      window.addEventListener('click', this.eventCloseDropDown, true);

      // Высота блока (берется с select-block-select)
      const slider = this.state.slider.current;
      const maxHeightList = (slider.offsetHeight - 1) * this.state.countShowByList;
      const styleForList = {
        maxHeight: `${maxHeightList}px`,
        width: `${slider.offsetWidth}px`,
      };

      this.setState({ styleForList });
    }
  }

  onSelect = (selectIdx) => {
    this.props.onChange(selectIdx);
  }

  onFocusSelect = () => {
    this.setState({ focusSelect: true });
  }

  onBlurSelect = () => {
    this.setState({ focusSelect: false });
  }

  onKeyDownSelect = (event) => {
    event.preventDefault();

    const { focusOptionId } = this.state;

    switch (event.key) {
      case 'Enter':
        if (focusOptionId !== null) {
          this.onSelect(focusOptionId);
        } else {
          this.onToggleList();
        }

        break;
      case 'Escape':
        event.preventDefault();
        event.stopPropagation();

        this.setState({
          focusOptionId: null,
          isShowDropDown: false,
        });

        break;
      case 'ArrowUp':
        this.optionChange('up');

        break;
      case 'ArrowDown':
        this.optionChange('down');

        break;
      default:
        break;
    }
  }

  optionChange(direction) {
    if (!this.state.isShowDropDown) {
      return;
    }

    const { childArray, startFocusOptionId } = this.state;
    let focusId = this.state.focusOptionId;

    if (focusId === null) {
      focusId = startFocusOptionId;
    }

    if (direction === 'up' && focusId > 0) {
      focusId -= 1;
    } else if (direction === 'down' && focusId < childArray.length - 1) {
      focusId += 1;
    }

    this.setState({ focusOptionId: focusId });

    // Прокрутка (скролл) списка
    const slider = this.state.slider.current;
    const scroll = (slider.offsetHeight - 1) * (focusId + 1 - this.state.countShowByList);

    slider.querySelector('.select-block-list').scroll(0, scroll);
  }

  onMoveList = () => {
    this.setState({ focusOptionId: null });
  }

  render() {
    const {
      selectIdx,
      isShowDropDown,
      upByDropDown,
      childArray,
    } = this.state;
    const stylesSelectBlock = ['select-block'];
    const stylesSelectBlockList = ['select-block-list'];

    if (isShowDropDown) {
      stylesSelectBlock.push('open');
    }
    if (upByDropDown) {
      stylesSelectBlockList.push('top');
    }

    return (
      <div className={stylesSelectBlock.join(' ')} ref={this.state.slider}>
        <div
          className="select-block-select"
          onClick={this.onToggleList}
          tabIndex="0"
          onFocus={this.onFocusSelect}
          onBlur={this.onBlurSelect}
          onKeyDown={this.onKeyDownSelect}
        >
          <Group>{ childArray[selectIdx] }</Group>
        </div>
        <div
          className={stylesSelectBlockList.join(' ')}
          style={this.state.styleForList}
          onMouseMove={this.onMoveList}
        >
          {childArray.map((GroupItem, index) => (
            <Group
              key={index}
              index={index}
              focusOptionId={this.state.focusOptionId}
              onSelect={this.onSelect.bind(this, index)}
            >{ GroupItem }</Group>
          ))}
        </div>
      </div>
    );
  }
}

export default SelectBlock;
