const types = require('./actiontypes').default;

const initialState = {
  httpServer: {
    status: 'active',
    loading: false,
    inactiveCount: 0,
    stopMonitor: false,
  },
};

const serverStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_HTTP_SERVER_STATUS:
      return {
        ...state,
        httpServer: {
          ...state.httpServer,
          loading: true,
        },
      };
    case types.SET_HTTP_SERVER_STATUS:
      if (state.httpServer.stopMonitor) {
        return state;
      }
      if (action.payload === 'inactive') {
        return {
          ...state,
          httpServer: {
            ...state.httpServer,
            status: (state.httpServer.inactiveCount || 0) >= 4 ? action.payload : state.httpServer.status,
            inactiveCount: (state.httpServer.inactiveCount || 0) + 1,
            loading: false,
          },
        };
      }
      if (action.payload === 'active') {
        return {
          ...state,
          httpServer: {
            ...state.httpServer,
            status: action.payload,
            inactiveCount: 0,
            loading: false,
          },
        };
      }
      return state;
    case types.STOP_HTTP_SERVER_MONITOR:
      return {
        ...state,
        httpServer: {
          ...state.httpServer,
          stopMonitor: action.payload,
        },
      };
    default:
      return state;
  }
};

export default serverStatusReducer;
