const {
  LANG_TRANSLATION_SET, LOGOUT, LANG_SET, LANG_SET_ALL, TRANSLATIONS_FETCH,
} = require('./actiontypes').default;

const initialState = {
  locale: {
    data: {},
    error: '',
    fetching: false,
  },
};

const LangReducer = (state = initialState, action) => {
  switch (action.type) {
    case LANG_SET:
      return { ...state, lang: action.params };
    case TRANSLATIONS_FETCH:
      return {
        ...state,
        locale: {
          ...state.locale,
          fetching: true,
        },
      };
    case LANG_TRANSLATION_SET:
      return {
        ...state,
        locale: {
          ...state.locale,
          data: Object.assign({}, state.locale.data, action.data), // здесь нужно обязательно создавать новый объект, чтобы обновление redux-состояния "дошло" до ReactNative компонента
          error: action.error || '',
          fetching: false,
        },
      };
    case LANG_SET_ALL:
      return { ...state, all: action.params };
    default:
      return state;
  }
};

export default LangReducer;
