import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { Link } from 'react-router-dom';

import Flag from '../../common/Flag';
import Avatar from '../../common/Avatar';
import Checkbox from '../../common/Checkbox';
import OBModal from '../../common/Modal';

import {
  UserByIdFetch,
  SaveLeaderboardStage,
  DeleteLeaderboardStage,
} from '../../../libs/reducers/actions';

import config from '../../../config';
import defaultAvatar from '../../../images/defaultAvatar.png';
import { ReactComponent as SvgCountry } from '../../../images/country.svg';
import { ReactComponent as SvgGender } from '../../../images/gender.svg';

function Stage({
  intl,
  user,
  editable,
  stage,
  tournamnentParticipants,
  UserByIdFetch,
  SaveLeaderboardStage,
  DeleteLeaderboardStage,
}) {
  const [stageParticipants, setStageParticipants] = useState([]);
  const [stageName, setStageName] = useState(stage.name);
  const [changes, setChanges] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);

  const handleRowChange = (playerId, fieldName, newValue, oldValue) => {
    const newChanges = [...changes];
    const changeIndex = newChanges.findIndex(elem => elem.playerId === playerId);
    if (changeIndex === -1) {
      newChanges.push({
        playerId,
        fields: [
          { fieldName, newValue, oldValue },
        ],
      });
    } else {
      const change = newChanges[changeIndex];
      const { fields } = change;
      const changedFieldIndex = fields.findIndex(elem => elem.fieldName === fieldName);
      if (changedFieldIndex !== -1) {
        const changedField = fields[changedFieldIndex];
        if (changedField.oldValue === newValue) {
          fields.splice(changedFieldIndex, 1);
          if (fields.length === 0) {
            newChanges.splice(changeIndex, 1);
          }
        } else {
          changedField.newValue = newValue;
        }
      } else {
        fields.push({ fieldName, newValue, oldValue });
      }
    }
    setChanges(newChanges);
  };

  const getStageParticipants = () => {
    const data = stage.players.map((stageParticipant) => {
      const player = tournamnentParticipants.find(elem => elem.playerid === stageParticipant.playerid);
      return {
        ...player,
        inNextStage: stageParticipant.inNextStage,
        place: stageParticipant.place || '',
      };
    });
    setStageParticipants(data);
  };

  useEffect(() => {
    getStageParticipants();
    setStageName(stage.name);
  }, [stage, tournamnentParticipants]);

  const columns = [
    {
      title: intl.formatMessage({ id: 'Passing', defaultMessage: 'Passing' }),
      align: 'center',
      width: 77,
      render: (data, record, index) => ({
        props: {},
        children: (
          <div className={`checkbox-container ${!stage.nextStageId ? 'disabled' : ''}`}>
            <Checkbox
              disabled={!editable || !stage.nextStageId}
              checked={record.inNextStage}
              onChange={() => {
                const newData = [...stageParticipants];
                const index = stageParticipants.findIndex(player => player.playerid === record.playerid);
                const item = stageParticipants[index];
                item.inNextStage = !item.inNextStage;
                newData.splice(index, 1, item);
                setStageParticipants(newData);
                handleRowChange(record.playerid, 'inNextStage', item.inNextStage, !item.inNextStage);
              }}
            />
          </div>),
      }),
    },
    {
      title: intl.formatMessage({ id: 'TournamentPlace', defaultMessage: 'Place' }),
      dataIndex: '',
      width: 77,
      align: 'center',
      render: (data, record, index) => ({
        props: {},
        children: (
          <div>
            {editable
              ? <input
                  className="place-input"
                  value={record.place}
                  pattern="[0-9]*"
                  onChange={(e) => {
                    const newData = [...stageParticipants];
                    const index = stageParticipants.findIndex(player => player.playerid === record.playerid);
                    const item = stageParticipants[index];
                    const oldValue = item.place;
                    const newValue = e.target.value.replace(/\D/, ''); 
                    if (oldValue === newValue) {
                      return;
                    }
                    item.place = newValue;
                    newData.splice(index, 1, item);
                    setStageParticipants(newData);
                    handleRowChange(record.playerid, 'place', item.place, oldValue);
                  }}
              />
              : record.place
            }
          </div>),
      }),
    },
    {
      title: intl.formatMessage({ id: 'FullnameTableColumn', defaultMessage: 'Fullname' }),
      dataIndex: '',
      width: 314,
      render: record => ({
        props: {},
        children: (<>
            <div className="username__container">
              <Avatar
                uri={record.photo ? `${config.FETCH.img}/${record.photo}` : defaultAvatar}
                avgscore={record.avgscore}
                flag={null}
                tabled
              />
              {`${record.firstname} ${record.lastname}`.trim() || '-'}
            </div>
          </>),
      }),
    },
    {
      title: intl.formatMessage({ id: 'LoginTableColumn', defaultMessage: 'Login' }),
      dataIndex: '',
      width: 112,
      render: (data, record, index) => ({
        props: {},
        children: (<>
            <div className="participant__login"><Link to="#" onClick={() => { UserByIdFetch({ userid: record.userid }); }}>{record.login}</Link></div>
          </>),
      }),
    },
    {
      title: <>
        <svg
          width="20"
          height="19"
          viewBox="0 0 20 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginTop: 3 }}>
          <path d="M10 1.04704L12.6699 5.95934L12.7836 6.16865L13.0179 6.21216L18.5148 7.23339L14.6679 11.2906L14.504 11.4634L14.535 11.6996L15.2624 17.2431L10.2151 14.8383L10 14.7358L9.78494 14.8383L4.73759 17.2431L5.46498 11.6996L5.49598 11.4634L5.33207 11.2906L1.48523 7.23339L6.98214 6.21216L7.21636 6.16865L7.33012 5.95934L10 1.04704Z" stroke="#818589"/>
        </svg>
      </>,
      width: 40,
      dataIndex: 'rating',
      sorter: (a, b) => ((a.rating == b.rating) ? 0 : (a.rating - b.rating) / Math.abs(a.rating - b.rating)),
      render: (data, record, index) => ({
        props: {},
        children: (<>
            {data
              ? <div className='ratingWrap'><svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 0L12.9389 5.95492L19.5106 6.90983L14.7553 11.5451L15.8779 18.0902L10 15L4.12215 18.0902L5.24472 11.5451L0.489435 6.90983L7.06107 5.95492L10 0Z" fill="#FFB31E"/>
              </svg>
              {data}</div>
              :	<div className='ratingWrap'><svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 1.12978L12.4906 6.1762L12.6069 6.41192L12.867 6.44972L18.4361 7.25895L14.4063 11.187L14.218 11.3705L14.2625 11.6296L15.2138 17.1762L10.2327 14.5574L10 14.4351L9.76733 14.5574L4.78621 17.1762L5.73752 11.6296L5.78196 11.3705L5.59372 11.187L1.56392 7.25895L7.13297 6.44972L7.39311 6.41192L7.50944 6.1762L10 1.12978Z" stroke="#FFB31E"/>
              </svg>
              0</div>
            }
          </>),
      }),
    },
    {
      title: <SvgCountry />,
      dataIndex: 'creatorcountry',
      width: 40,
      align: 'center',
      render: (data, record, index) => ({
        children: (
        <div className="battles__flag">
          <Flag name={record.country} />
        </div>
        ),
        props: {
        },
      }),
      sorter: (a, b) => (a.country_live || a.country).localeCompare(b.country_live || b.country),
    },
    {
      title: <SvgGender />,
      width: 40,
      dataIndex: 'gender',
    },
  ];
  console.log(stageName, stage.name);
  return (
    <div className="stage">
      <div className="stage-controls">
        <input
          className="input"
          value={stageName}
          onChange={e => setStageName(e.target.value)}
          disabled={!editable}
        />
        {editable
          ? (
            <>
              <button
                className="delete-stage-button"
                onClick={() => {
                  setModalVisible(true);
                }}
              >
                {intl.formatMessage({ id: 'DeleteLeaderboardStage', defaultMessage: 'Delete stage' })}
              </button>
              {
                (changes.length !== 0 || stageName !== stage.name) && (
                  <>
                    <button
                      className="save-stage-button"
                      onClick={() => {
                        SaveLeaderboardStage({ stageId: stage.id, changes, stageName });
                        setChanges([]);
                      }}
                    >
                      {intl.formatMessage({ id: 'SaveLeaderboardStage', defaultMessage: 'Save changes' })}
                    </button>
                    <button
                      className="save-stage-button"
                      onClick={() => {
                        getStageParticipants();
                        setChanges([]);
                        setStageName(stage.name);
                      }}
                    >
                      {intl.formatMessage({ id: 'reset', defaultMessage: 'Reset' })}
                    </button>
                  </>
                )
              }
            </>
          )
          : null
        }
      </div>
      <Table
        bordered
        columns={columns}
        dataSource={stageParticipants}
        pagination={false}
        className="competitionsTable"
        rowClassName={(record, index) => {
          let result = 'tournamentRow ';
          if (record.place) result += 'favoriteParticipant ';
          if (user.id === record.userid) result += 'currentUser ';
          return result;
        }}
        locale={{
          emptyText: 'No players',
        }}
      />
      <OBModal
        title={intl.formatMessage({ id: 'DeleteLeaderboardStage', defaultMessage: 'Delete stage' })}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={() => {
          DeleteLeaderboardStage({ stageId: stage.id });
          setModalVisible(false);
        }}
        closable={false}
      >
        <div className="modal-content">
          {intl.formatMessage({ id: 'LeaderboardStageDeleteAlert', defaultMessage: 'Do you really want to delete this and all higher stages' })}
        </div>
      </OBModal>
    </div>
  );
};

export default connect(
  state => ({
    user: state.users.user,
  }), {
    UserByIdFetch,
    SaveLeaderboardStage,
    DeleteLeaderboardStage,
  },
)(Stage);
