const config = {
  FETCH: {
    hostname: 'dev.tenpincam.com',
    socketUrl: 'hostname:4005/client',
    url: 'https://hostname/api',
    fullUrl: 'https://hostname',
    img: 'https://hostname',
    chatUrl: '//hostname:8009/chat',
    xCookieToken: 'TlGC6zh43XAE5F2Bg3nfIVPIqNNp6dpo1oJgvfl0mjxeFjrNrZdkV2cVvSUglELI',
  },
  bugsnag: {
    apiKey: '13ab693e7a708641f7954249ef5aa915',
    releaseStage: 'development',
  },
  LANGS: [
    'EN',
    'RU',
  ],
  CONFIG: {
    pauseTime: 180000,
  },
  VIDEOS: {
    // web socket
    // host: 'tenpincam.com:8080',
    secure: true,
    host: 'tenpincam.com:8081',
    // secure: true,
    nativeHlsOnSafari: true, // play m3u8 playlist on.. if %nativeHlsOnSafari%; then HTMLMediaElement video tag; else HlsPlayer fi;
    mseOnSafari: false, // play live video on.. - if %mseOnSafari%; then FlussonicMsePlayer; else if %nativeHlsOnSafari%; then HTMLMediaElement video tag; else HlsPlayer fi;
    debug: false, // activate console log on src/components/videos
    showRangeActionsOnIOS: false, // show or hide seek buttons if iOS device
    showAllEvents: false, // set listener on all media events
    // hostname: 'bokus1.ru.fvds.ru:8080',
    // secured: false,
    // quality:
    // https
    // url: 'https://bokus1.ru.fvds.ru:443',
    // postfix: 'index.m3u8',
  },
  LANGS: ['EN', 'RU'],
  Medoro: {
    url: 'https://demo.ipsp.lv/form/v2/',
    MID: 3702594,
    KEYINDEX: 15,
    non3d: true,
  },
 platform: 0
};

for (const key of Object.keys(config.FETCH)) {
  if (key === 'hostname') {
    continue;
  }
  config.FETCH[key] = config.FETCH[key].replace('hostname', config.FETCH.hostname);
}

export default { ...config };

