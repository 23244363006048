const {
  KEYBOARD_DID_HIDE,
  KEYBOARD_DID_SHOW,
} = require('./actiontypes').default;

const initialState = {
  keyboardEvents: {
    keyboardIsShown: false,
  },
};

const KeyboardEventReducer = (state = initialState, action) => {
  switch (action.type) {
    case KEYBOARD_DID_HIDE:
      return { keyboardIsShown: false };
    case KEYBOARD_DID_SHOW:
      return { keyboardIsShown: true, keyboardHeight: action.data };
    default:
      return state;
  }
};

export default KeyboardEventReducer;
