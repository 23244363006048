export const SQUAD_ID_SET = 'SQUAD_ID_SET';

export const squadIdSet = params => ({ type: SQUAD_ID_SET, params });

const { LOGOUT, SOCKET_EVENT_GAME_START, SOCKET_EVENT_GAME_END } = require('./actiontypes').default;

const initialState = null;

const squadIdReduсer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return initialState;
    case SQUAD_ID_SET:
      return action.params || null;
    case SOCKET_EVENT_GAME_END:
      return null;
    case SOCKET_EVENT_GAME_START:
      return action.params || null;
    default:
      return state;
  }
};

export default squadIdReduсer;
