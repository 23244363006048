/* eslint-disable import/prefer-default-export */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { FilterInput } from '../../Input';

/**
 * @param {string | boolean} [error]
 */
const errorMessage = (error, defaultMessage) => {
  if (!error) {
    return false;
  }
  if (typeof error === 'string') {
    return error;
  }
  return defaultMessage;
};

/**
 * @template T
 * @typedef {{
 *  label?: string
 *  value?: T;
 *  onChange?: (value?: T) => any;
 *  error?: boolean;
 *  intl?: any;
 * }} InputProps
 */

/**
 * @template T
 * @type {React.FC<InputProps<T>>}
 */
const TextInput = props => {
  const {
    value, onChange, error, intl, label: labelText,
  } = props;
  const message = errorMessage(error, intl.formatMessage({ id: 'IBANEmptyFieldError', defaultMessage: 'Must be filled' }));
  return <div className="IBANinput">
    <label>{labelText}</label>
    <FilterInput
      invalid={message}
      value={value}
      onChange={e => onChange && onChange(e.target.value)}
    />
  </div>;
};

export default TextInput;
