import { put, call, select, takeLatest } from 'redux-saga/effects';
import config from '../../config';
import { get, post } from '../Networking';

const { SET_USER_DOCS } = require('../reducers/userDocs');

const getUser = state => state.users.user;

export function* _getUserDocs() {
  const { id: userid } = yield select(getUser);
  if (!userid) {
    yield put({ type: SET_USER_DOCS, payload: {} })
    return;
  }
  try {
    const url = `${config.FETCH.url}/userdocs/user/${userid}`;
    const result = yield call(get, { url });
    yield put({ type: SET_USER_DOCS, payload: { error: result.data.error || '', success: result.data.success, data: result.data.data }});
  } catch (error) {
    yield put({ type: SET_USER_DOCS, payload: { error, success: false }});
  }
}

export function* _sendUserDocs(params) {
  try {
    const url = `${config.FETCH.url}/userdocs/user/`;
    const result = yield call(post, { url, data: params.params });
    yield put({ type: SET_USER_DOCS, payload: { data: result.data.data, error: result.data.error || '', success: result.data.success }});
    if (result.data.success) {
      _getUserDocs();
    }
  } catch(error) {
    yield put({ type: SET_USER_DOCS, payload: { data: {}, error, success: false }});
  }
}