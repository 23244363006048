import isEmail from 'validator/lib/isEmail';

/**
 * @param {string} value
 */
const validateEmailValue = value => {
  if (typeof value !== 'string' || !value) {
    return false;
  }
  return isEmail(value);
};

export default validateEmailValue;
